<template>
  <LFXListView v-if="hasListLoaded" :definition="definition" :records="records" />
  <template v-else>
    <LDBLoader />
  </template>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXListView from '@/views/general/LFXListView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxBeneficiaryLogList',
  components: { LFXListView, LDBLoader },
  props: {},
  data() {
    return {
      hasListLoaded: false,
    };
  },
  async created() {
    console.log('test');
    await doPermissionAction(this, 'getBeneficiaryLogs', {
      accountId: this.accountId,
      beneficiaryId: this.beneficiaryId,
    });
    this.hasListLoaded = true;
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),
    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },
    beneficiaryId() {
      return +this.$route.params?.beneficiaryId;
    },
    beneficiaryRecord() {
      return this.accountRecord?.beneficiaries?.[this.beneficiaryId];
    },
    records() {
      return this.beneficiaryRecord?.beneficiaryLogs;
    },
    definition() {
      return getDefinition('bankAccountLog');
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
  },
};
</script>
