<template>
  <capitec-content-box>
    <div class="application-review-applications-table-container">
      <table-lite
        id="application-summary-view"
        :is-static-mode="true"
        :is-slot-mode="true"
        :has-checkbox="false"
        :is-loading="false"
        :is-re-search="false"
        :columns="tableColumns"
        :rows="sortedRecords"
      >
        <template v-for="(col, i) of tableColumns" v-slot:[col.field]="data" :key="i">
          <capitec-tag
            v-if="col.field === 'status'"
            :label="data.value[col.field].label"
            v-bind="data.value[col.field].optionType"
          ></capitec-tag>
          <div v-else>{{ data.value[col.field] }}</div>
        </template>
      </table-lite>
    </div>
    <template v-if="showUploadDocuments">
      <h1>Documents</h1>
      <p v-if="outstandingDocumentTypes.length === 0">All mandatory documents uploaded</p>
      <template v-else>
        <p>The following mandatory documents are outstanding</p>
        <ul>
          <li v-for="outstandingDocumentType in outstandingDocumentTypes" :key="outstandingDocumentType.id">
            {{ outstandingDocumentType.documentName }}
          </li>
        </ul>
      </template>
    </template>
    <template v-else>
      <h1>Documents</h1>
      <p v-if="!applicationRecord.documents">Documents have not been captured</p>
      <template v-else>
        <ul>
          <li v-for="documentObject in applicationRecord.documents" :key="documentObject.documentName">
            {{
              documentObject.count === 1
                ? documentObject.documentName
                : documentObject.count + 'x ' + documentObject.documentName
            }}
          </li>
        </ul>
      </template>
    </template>
    <h1>Signatories</h1>
    <div class="application-review-signatory-table-container" v-if="displaySignatories">
      <LFXListView :definition="signatoryDefinition" :records="signatories" />
    </div>
    <p v-else>Not Required</p>
    <!-- <capitec-action-bar>
      <LDBButton
        v-for="button in leftButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
      <capitec-spacer />
      <LDBButton
        v-for="button in rightButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </capitec-action-bar> -->
  </capitec-content-box>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import LDBField from '@/landobyte_vue/LDBField.vue';
import TableLite from '@/views/general/TableLite.vue';
import LFXListView from '@/views/general/LFXListView.vue';

import { getDefinition } from '@/db-interface';

export default {
  name: 'LfxClientApplicationReview',
  components: { LDBField, TableLite, LFXListView },
  props: {},
  emits: {},
  created() {},
  mounted() {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['permissions']),
    ...mapState(['account', 'systemSettings']),

    // ----- TABLE CONSTRUCTORS -----

    tableColumns() {
      let self = this;
      let res = [
        {
          col: 'applicationReferenceColumn',
          field: 'applicationReference',
          label: 'Application',
          isKey: false,
          sortable: false,
        },
        {
          col: 'applicationTypeColumn',
          field: 'applicationType',
          label: 'Type',
          isKey: false,
          sortable: false,
        },
        {
          col: 'statusColumn',
          field: 'status',
          label: 'Status',
          isKey: false,
          sortable: false,
        },
        {
          col: 'submittableColumn',
          field: 'submittable',
          label: 'Submittable?',
          isKey: false,
          sortable: false,
        },
        {
          col: 'infoColumn',
          field: 'info',
          label: 'Info',
          isKey: false,
          sortable: false,
        },
        // {
        //   col: "actionButtonsColumn",
        //   field: "actionButtons",
        //   label: "Actions",
        //   isKey: false,
        //   sortable: false,
        //   guards: false,
        // }
      ];
      return res;
    },

    sortedRecords() {
      let self = this;
      let res = [
        {
          applicationReference: this.applicationRecord.reference,
          applicationType: 'Overview',
          status: this.getStatusObject(
            this.applicationRecord.consolidatedStatus,
            this.applicationRecord.definition,
            'consolidatedStatus'
          ),
          submittable: this.getSubmittable(this.applicationRecord),
          info: this.getInfoForApplication(this.applicationRecord),
          actionButtons: 'TODO',
        },
      ];
      for (const cifApplicationId in this.applicationRecord.application_cif) {
        if (this.applicationRecord.application_cif[cifApplicationId].cifType === 'full')
          res.push({
            applicationReference: this.applicationRecord.application_cif[cifApplicationId].reference,
            applicationType: 'Client',
            status: this.getStatusObject(
              this.applicationRecord.application_cif[cifApplicationId].consolidatedStatus,
              this.applicationRecord.application_cif[cifApplicationId].definition,
              'consolidatedStatus'
            ),
            submittable: this.getSubmittable(this.applicationRecord.application_cif[cifApplicationId]),
            info: this.getInfoForCifApplication(this.applicationRecord.application_cif[cifApplicationId]),
            actionButtons: 'TODO',
          });
      }
      for (const cifApplicationId in this.applicationRecord.application_cif) {
        if (this.applicationRecord.application_cif[cifApplicationId].cifType !== 'full')
          res.push({
            applicationReference: this.applicationRecord.application_cif[cifApplicationId].reference,
            applicationType: 'Associated Party',
            status: this.getStatusObject(
              this.applicationRecord.application_cif[cifApplicationId].consolidatedStatus,
              this.applicationRecord.application_cif[cifApplicationId].definition,
              'consolidatedStatus'
            ),
            submittable: this.getSubmittable(this.applicationRecord.application_cif[cifApplicationId]),
            info: this.getInfoForCifApplication(this.applicationRecord.application_cif[cifApplicationId]),
            actionButtons: 'TODO',
          });
      }
      for (const accountApplicationId in this.applicationRecord.application_account) {
        res.push({
          applicationReference: this.applicationRecord.application_account[accountApplicationId].reference,
          applicationType: `${this.applicationRecord.application_account[accountApplicationId].currencyId} Account`,
          status: this.getStatusObject(
            this.applicationRecord.application_account[accountApplicationId].consolidatedStatus,
            this.applicationRecord.application_account[accountApplicationId].definition,
            'consolidatedStatus'
          ),
          submittable: this.getSubmittable(this.applicationRecord.application_account[accountApplicationId]),
          info: this.getInfoForAccountApplication(this.applicationRecord.application_account[accountApplicationId]),
          actionButtons: 'TODO',
        });
      }
      return res;
    },

    // ----- Buttons -----

    leftButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'back',
          label: 'Back',
          type: 'clear',
          guards: true,
          clickEvent() {
            self.onBack();
          },
        },
      ];
      return allButtons;
    },

    rightButtons() {
      let self = this;
      let allButtons = [
        // {
        //   name: 'cancelApplication',
        //   label: 'Cancel Application',
        //   type: '',
        //   params: { accountId: this.clientId, applicationId: this.applicationId },
        //   guards:true,
        //   enabledGuards: ['new', 'error'].includes(this.applicationRecord.status) && !!this.permissions.cancelApplication,
        //   clickEvent() {
        //     self.$router.push({ name: 'clientApplicationReviewCancel' });
        //   },
        // },
        // {
        //   name: 'submitApplication',
        //   label: 'Submit Application!!!',
        //   type: 'primary',
        //   action: 'submitApplication',
        //   modal: { header: `Submit Application`, cancelLabel: 'Cancel', confirmLabel: 'Submit', content:this.submitApplicationModalMessage },
        //   params:{accountId:this.clientId,applicationId:this.applicationId},
        //   guards:true,
        //   enabledGuards:
        //     this.applicationRecord.is_submittable &&
        //     !['inProgress', 'partiallyCompleted', 'completed'].includes(this.applicationRecord.status),
        //   clickEvent: 'onLDBButtonClick', // TODO remove this - avr
        // },
        // {
        //   name: 'backToClient',
        //   label: 'Back to Client',
        //   type: 'primary',
        //   guards: !this.permissions.submitApplication,
        //   clickEvent() {
        //     self.$router.replace({ name: 'accountDetailsView' });
        //   },
        // },
      ];
      return allButtons.filter((button) => button.guards);
    },

    // ----- Account Record -----

    clientId() {
      return +this.$route.params?.accountId;
    },
    clientRecord() {
      return this.account?.[this.clientId];
    },

    clientAccountType() {
      return this.clientRecord?.accountType;
    },

    // ----- Application Record -----

    applicationId() {
      return +this.$route.params?.applicationId;
    },
    applicationRecord() {
      return this.clientRecord?.application?.[this.applicationId];
    },
    submitApplicationModalMessage() {
      //TODO
      return `
        <ol>
          <li>Submit or resubmit the application and all sub applications</li>
        </ol>
      `;
    },
    // ----- Documents -----
    showUploadDocuments() {
      return this.applicationRecord?.thisApplicationDocumentUploadType === 'local';
    },
    outstandingDocumentTypes() {
      return this.applicationRecord.outstandingDocumentTypes;
    },
    // ----- Signatories Record -----
    displaySignatories() {
      return this.applicationRecord?.thisApplicationDocumentUploadType !== 'local';
    },
    signatoryDefinition() {
      return getDefinition('signatory');
    },
    signatories() {
      const unsortedSignatories = this.clientRecord?.applicationPortfolioSignatories || {};
      const sortedSignatories = [];
      for (const signatoryId of this.applicationRecord?.signatoryOrder || []) {
        for (const s in unsortedSignatories) {
          let signatory = unsortedSignatories[s];
          if (+signatory.id === +signatoryId) {
            sortedSignatories.push(signatory);
          }
        }
      }
      return sortedSignatories;
    },
  },
  methods: {
    onBack() {
      this.$emit('step-back');
    },
    onSave({ fields, options }) {},
    onNext() {
      this.$emit('step-next');
    },
    getStatusObject(status, definition, statusFieldName) {
      const option = definition.fields
        .filter((field) => field.field === statusFieldName)[0]
        .datatype.option.options.filter((option) => option.id === status)[0];
      return { value: status, label: option?.name || statusFieldName, optionType: this.optionType(option?.tagType || 'info') };
    },
    getSubmittable(record) {
      if (record.status === 'new' || record.status === 'rejected') {
        return record.is_submittable ? 'Yes' : 'No';
      }
      return 'N/A';
    },
    optionType(tagType) {
      if (!tagType) {
        return { type: 'custom', 'label-color': '#FFFFFF', color: '#AAAAAA' };
      }
      if (tagType.custom) {
        return { type: 'custom', 'label-color': tagType['label-color'], color: tagType.color };
      }
      return { type: tagType };
    },

    getInfoForApplication(application) {
      switch (application.applicationPortfolioStatus) {
        case 'new':
        case 'readyForSubmission':
        case 'signed':
          return application.is_submittable ? 'Ready to Submit' : 'In Progress';
        case 'submitted':
        case 'recallRequested':
          return 'Portfolio Submitted';
        case 'readyForSigning':
          return 'Ready for Signing';
        case 'notRequired':
          return;
      }
      return application.is_submittable ? 'Ready to Submit' : 'In Progress';
    },
    getInfoForCifApplication(cifApplication) {
      var returnString = '';
      switch (cifApplication.status) {
        case 'new':
          if (cifApplication.is_submittable) {
            returnString = 'Ready to Submit';
          } else {
            returnString = `Outstanding: ${cifApplication.OutstandingFields}`;
          }

          break;
        case 'error':
          returnString = `Cancelled: ${cifApplication.rejectedReason}`;
          break;
        case 'rejected':
          returnString = `Rejected: ${cifApplication.rejectedReason}`;
          break;
        case 'inProgress':
          returnString = 'In Progress';
          break;
        case 'completed':
          returnString = 'Completed';
          break;
        case 'cancelled':
          returnString = `Cancelled: ${cifApplication.rejectedReason}`;
          break;
      }
      return returnString;
    },
    getInfoForAccountApplication(accountApplication) {
      return this.getInfoForCifApplication(accountApplication); // Functionality is currently identical
    },
  },
};
</script>
<style>
.application-review-applications-table-container {
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
}
.application-review-signatory-table-container {
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
}
</style>
