<template>
  <LFXContent
    :titleBar="titleBar"
    :show_tabs="showTabs"
    :tabs="tabs"
    @tab-select="onTabSelect"
    :show_actions="showActions"
    :form_layout="formLayout"
    :show_label="showLabel"
    :label="contentLabel"
    :show_sidebar="false"
  >
    <template #actions>
      <LDBButton
        :xstyle="` max-height:32px; font-size:14px; --theme-button-font-size:14px ; ${
          button.style === undefined ? '' : button.style
        }`"
        v-for="button in actionBarButtons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        @click="button.clickEvent"
        :enabledGuards="button.enabledGuards"
      ></LDBButton
    ></template>

    <template #content>
      <router-view></router-view>
    </template>
  </LFXContent>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXContent from '@/components/layout/LfxContent.vue';

export default {
  name: 'LfxUploadAndDownload',
  components: { LFXContent },
  props: {},
  data() {
    return {};
  },
  created() {
    if (this.$route.name === 'uploadsAndDownloads') {
      this.selectFirstTab();
    }
  },
  mounted() {},
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'uploadsAndDownloads') {
      next(false);
      this.selectFirstTab();
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['authUser','dbIntermediarySettings']),

    // ----- Title Bar -----

    titleBar() {
      const titleBar = {
        label: 'Uploads and Downloads',
      };
      return titleBar;
    },

    // ----- Tabs -----

    showTabs() {
      return true;
    },
    tabs() {
      let tabs = [
        {
          label: 'Intermediary Downloads',
          routeName: 'itermedairyDownloads',
          tabName: 'itermedairyDownloads',
          guards: this.authUser.accountLevel === 'intermediary',
        },
      ];
      return tabs.filter((tab) => tab.guards);
    },
    selectedTabName() {
      const tabRouteDepth = this.componentRouteDepth + 1;
      return this.$route.fullPath.split('/')[tabRouteDepth];
    },
    selectedTabIndex() {
      let tabNames = this.tabs.map((t) => t.name);
      return tabNames.indexOf(this.selectedTabName);
    },
    firstAvailableTab() {
      return this.tabs[0];
    },
    areTabsAvailable() {
      if (this.tabs.length > 0) {
        return true;
      } else return false;
    },

    // ----- Action Bar -----

    showActions() {
      return true;
    },
    actionGroup() {
      return this.$route.meta?.content?.actionGroup;
    },
    actionBarButtons() {
      const self = this;
      let actions = {
        itermedairyDownloads: [
          {
            name: 'downloadClientList',
            label: 'Download Latest Client List',
            type: '',
            guards: this.authUser.accountLevel === 'intermediary',
            enabledGuards: (!!this.dbIntermediarySettings?.clientReportS3Key || !!this.myAccount?.clientReportS3Key) && !!this.dbIntermediarySettings.clientReportEnable,
            action: 'downloadClientList',
            params: {},
            onSuccess: { doStoreUpdate: false },
          },
          {
            name: 'downloadClientBalances',
            label: 'Download Latest Client Balance List',
            type: '',
            guards: this.authUser.accountLevel === 'intermediary',
            enabledGuards: (!!this.dbIntermediarySettings?.accountBalanceReportS3Key || !!this.myAccount?.accountBalanceReportS3Key) && !!this.dbIntermediarySettings.accountBalanceReportEnable,
            action: 'downloadClientBalances',
            params: {},
            onSuccess: { doStoreUpdate: false },
          },
          {
            name: 'downloadDealList',
            label: 'Download Latest Daily Deal List',
            type: '',
            guards: this.authUser.accountLevel === 'intermediary',
            enabledGuards: (!!this.dbIntermediarySettings?.dailyDealReportS3Key || !!this.myAccount?.dailyDealReportS3Key) && !!this.dbIntermediarySettings.dailyDealReportEnable,
            action: 'downloadDealList',
            params: {},
            onSuccess: { doStoreUpdate: false },
          },
        ],
      };
      return actions[this.actionGroup] ? actions[this.actionGroup].filter((action) => action.guards) : [];
    },

    // ----- Layout -----

    formLayout() {
      return this.$route.meta?.layout === 'list' ? false : true;
    },

    // ----- Content Label -----

    showLabel() {
      return this.contentLabel ? true : false;
    },
    contentLabel() {
      return this.$route.meta?.label ? this.$route.meta.label : 'TODO';
    },

    // ----- Guards -----

    // ----- Route -----

    componentRoutePath() {
      return 'uploadsAndDownloads';
    },

    componentRouteDepth() {
      let path = this.$route.fullPath;
      let pathSections = path.split('/');
      const componentRouteDepth = pathSections.indexOf(this.componentRoutePath);
      return componentRouteDepth;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Tabs -----

    selectFirstTab() {
      if (this.tabs.length) {
        this.$router.replace({ name: this.tabs[0].routeName });
      }
    },
    onTabSelect(tab) {
      this.$router.push({ name: tab.routeName });
    },
  },
};
</script>

<style scoped>
</style>
