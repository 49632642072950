//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2024 LandoByte (Pty) Ltd.
//  File        : LfxMakerCheckerUtils.ts
//  Author      : Bevan Timm
//  Description : This module contains helper utils Maker Checker functionality for the LandoFx System
//  Created     : 24 May 2024 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import { editAccount } from "@/definitions/actions/LfxAdminActions";
import { LfxAccountInt } from "../definitions/LfxAccountDef";

//----------------------------------------------------------------------------------------------------------------------

export const isMakerCheckerRequiredForMarkupEdit = (myAccount:LfxAccountInt,_editAccount:LfxAccountInt) => {
  // return myAccount.accountLevel === 'bank' && editAccount.accountLevel === 'intermediary' // 2024-09-13 maker checker now required for bank users editing all markups
  return myAccount.accountLevel === 'bank' 
}

//----------------------------------------------------------------------------------------------------------------------

export const isMakerCheckerRequiredForFecMarkupEdit = (myAccount:LfxAccountInt,editAccount:LfxAccountInt) => {
  return isMakerCheckerRequiredForMarkupEdit(myAccount,editAccount)
}

//----------------------------------------------------------------------------------------------------------------------

export const getUpdateAccountMakerCheckerKey = (markupId:number) => {
  return  'editAccountMarkup' + markupId;
}

//----------------------------------------------------------------------------------------------------------------------

export const getUpdateAccountFecMarkupMakerCheckerKey = (accountId:number) => {
  return  'editAccountFecMarkup' + accountId;
}

//----------------------------------------------------------------------------------------------------------------------
