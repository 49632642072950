//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2022 LandoByte (Pty) Ltd.
//  File        : LfxDealPaymentDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Deal Payment Link record for the LandoFX system
//  Created     : 5 March 2022 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import { intermediaryFieldViewGuard } from '../sharedUtils/LdbDefinitionUtils';
import type { LdbDefinition } from '../definitions/LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------

export type LfxDealPaymentStatus = "submitted"|"completed"|"cancelled";

//----------------------------------------------------------------------------------------------------------------------

export interface LfxDealPaymentInt {
  id?: number,
  uuid?: string,
  transactionFileId?: number,
  accountId?: number,
  intermediaryId?: number,
  branchId?: number,
  originaltransactionFileId?: number,
  dealId?: number,
  paymentId?: number,

  reference?:string,
  paymentCurrency?:string,
  paymentAmount?:number,

  status?:LfxDealPaymentStatus,
  
  createdAt?:Date,
  createdBy?:number,
  createdByName?: string;
  updatedAt?:Date,
  updatedBy?:number,
  updatedByName?: string;
  deletedAt?:Date,
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxDealPaymentDef:LdbDefinition = {
  title:"Deal Payment Link",
  table:'LfxDealPayment',
  collectionPath:"/transaction/{{transactionFileId}}/dealPayment",
  pagingType:'backEnd',
  view:{create:false,edit:false,item:true,list:true,delete:false,csv:false},
  indexes:['transactionFileId'],
  fields:{
    id:{
      label:'ID',
      datatype:{'id':{descriptionField:'name'}},
      default:'AUTOINC',
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    uuid:{
      label:'UUID',
      datatype:'uuid',
      default:'AUTOUUID',
      mandatory:true,
      views:{create:false,edit:false,item:true,list:false,delete:false,csv:false}
    },    
    transactionFileId:{
      label:'Transaction File',
      datatype:{
        foreignKey: {
          collection:'fk_transaction_files',

          linkTable:'LfxTransactionFile',
          linkField:'id',
          displayField:'reference',
        }
      },
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false},
    },
    accountId:{
      label:'Client',
      datatype:{
        foreignKey: {
          collection:'fk_clients',

          linkTable:'LfxAccount',
          linkField:'id',
          displayField:'name',
        }
      },
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false},
    },
    intermediaryId:{
      label:'Intermediary',
      datatype:{
        foreignKey: {
          collection:'fk_intermediaries',

          linkTable:'LfxAccount',
          linkField:'id',
          displayField:'name',
        }
      },
      default:null,
      mandatory:true,
      views: { create: false, edit: false, item: intermediaryFieldViewGuard, list: intermediaryFieldViewGuard, delete: false, csv: false },
    },
    branchId:{
      label:'Branch',
      datatype:{
        foreignKey: {
          collection:'fk_intermediary_branches',

          linkTable:'LfxAccount',
          linkField:'id',
          displayField:'name',
        }
      },
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false},
    },    
    originaltransactionFileId:{
      label:'Original Transaction File',
      datatype:{
        foreignKey: {
          collection:'fk_transaction_files',
          linkTable:'LfxTransactionFile',
          linkField:'id',
          displayField:'reference',
        }
      },
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:false,list:false,delete:false,csv:false},
    },
    dealId:{
      label:'Deal',
      datatype:{
        foreignKey: {
          linkTable:'LfxDeal',
          linkField:'id',
          displayField:'reference',
        }
      },
      default:null,
      mandatory:false,
      allowNullValues:true,
      views:{create:false,edit:false,item:false,list:false,delete:false,csv:false},
    },
    paymentId:{
      label:'Payment',
      datatype:{
        foreignKey: {
          linkTable:'LfxPayment',
          linkField:'id',
          displayField:'reference',
        }
      },
      default:null,
      mandatory:false,
      allowNullValues:true,
      views:{create:false,edit:false,item:false,list:false,delete:false,csv:false},
    },
    reference:{
      label:'Reference',
      datatype:'text',
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    paymentCurrency:{
      label:'Currency',
      datatype:{
        foreignKey: {
          collection:'fk_currencies',
          linkTable:'LdbCurrency',
          linkField:'id',
          displayField:'name',
          type:'text',
        }
      },
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    paymentAmount: {
      label:'Amount',
      datatype:{currency:{symbol:""}},
      default:null,
      mandatory:true,
      allowNullValues:false,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    status:{
      label:'Status',
      datatype:{option:{optionType:'text',
          options:[
            {id:'submitted',name:'Submitted'},
            {id:'completed',name:'Completed'},
            {id:'cancelled',name:'Cancelled'},
          ]
        }
      },
      default:'active',
      mandatory:true,
      views:{create:false,edit:false,item:true,list:false,delete:false,csv:false}
    },
    createdAt:{
      label:'Created At',
      datatype: {datetime: {
        type: 'datetime',
        format: 'human'
      }},
      default:null,
      mandatory:true,
      allowNullValues:true,
      views:{create:false,edit:false,item:true,list:false,delete:false,csv:false}
    },
    createdBy:{
      label:'Created By',
      datatype:{
        foreignKey: {
          linkTable:'LfxUser',
          linkField:'id',
          displayField:'username',
        }
      },
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:false,delete:false,csv:false}
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
  }
}