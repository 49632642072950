<template>
  <LDBModal result="info" :header="header" size="x-large">
    <template #body>
      <p>The following documents are configured in the system:</p>
      <!-- <ol>
        <li v-for="(doc, index) in activeBopCatDocs" :key="index">{{ doc }}</li>
      </ol> -->
      <table>
        <thead>
          <tr>
            <th>Number</th>
            <th>Document Name</th>
            <th>Required</th>
            <th>Optional</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(doc, index) in activeBopCatDocs" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ doc }}</td>
            <td>
              {{
                bopCatsSortedByDocsAndInfo.docs.requiredDocuments[doc].length > 0
                  ? bopCatsSortedByDocsAndInfo.docs.requiredDocuments[doc].length
                  : ''
              }}
            </td>
            <td>
              {{
                bopCatsSortedByDocsAndInfo.docs.optionalDocuments[doc].length > 0
                  ? bopCatsSortedByDocsAndInfo.docs.optionalDocuments[doc].length
                  : ''
              }}
            </td>
            <td>
              {{
                bopCatsSortedByDocsAndInfo.docs.requiredDocuments[doc].length +
                bopCatsSortedByDocsAndInfo.docs.optionalDocuments[doc].length
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxConfigBopCatDocuments',
  components: { LDBItemView },
  props: {},
  // data() {
  //   return { failedTreasuryDeal_retrievalStatus: 'retrieving' }; //'retrieved' | 'retrieving'
  // },
  created() {},
  mounted() {
    // if (this.failedTreasuryDeal?.[this.failedTreasuryDealId]) {
    //   this.failedTreasuryDeal_retrievalStatus = 'retrieved';
    // } else {
    //   this.retrieveFailedTreasuryDeal();
    // }
  },
  computed: {
    ...mapGetters(['config', 'activeBopCatDocs', 'bopCatsSortedByDocsAndInfo']),
    ...mapState([]),

    // ----- Modal -----

    header() {
      return `BOP Documents`;
    },

    // ----- View -----

    view() {
      return 'item';
    },

    // ----- BOP Documents -----

    // ----- API action -----
    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'close',
          label: 'Close',
          type: '',
          action: 'click',
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
      ];
      return allButtons;
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    // async retrieveFailedTreasuryDeal() {
    //   const response = await doPermissionAction(
    //     { onSuccess: { doStoreUpdate: true } },
    //     'getFailedTreasuryDeal',
    //     { failedTreasuryDealId: this.failedTreasuryDealId },
    //     {}
    //   );
    //   this.failedTreasuryDeal_retrievalStatus = 'retrieved';
    // },
  },
};
</script>
