<template>
  <div class="lfx-search-page">
    <div v-if="false" class="lfx-search-form">

      <!-- <LDBSearchBar
        :searchFields="searchFields"
        :options="options"
        :ranges="rangeFields"
        :searchHint="searchHint"
        @on-selector-change="doOnSearchSelectorChange"
        @on-search="doOnSearchEnterPress"
      /> -->
      
      <capitec-content-box v-if="false">
      
        <capitec-action-bar
          class="lfx-actions"
          style="--theme-actionbar-min-height: 32px; --theme-actionbar-padding: 5px"
        >
        <template v-if="false">
          <LDBButton
            v-for="button in formButtons"
            :buttonId="button.id"
            :key="button.name"
            :ref="button.name"
            :label="button.label"
            :action="button.action"
            :actionParams="button.actionParams"
            :params="button.params"
            :type="button.type"
            :tooltip="button.tooltip"
            :modal="button.modal"
            :viewGuards="button.guards"
            :enabledGuards="button.enabledGuards"
            :onSuccess="button.onSuccess"
            @beforeClick="button.beforeClick"
            @click="button.onClick"
            @on-api-success="button.onApiSuccess"
            @on-api-error="button.onApiError"
          >

            <template v-if="button.modal !== undefined" #modal>
              <div v-html="button.modal.content"></div>
            </template>

            </LDBButton>
            </template>

        </capitec-action-bar>
      </capitec-content-box>
    </div>
    <div class="advanced-search-table">

    <LFXPagedListView 
    :definition="definition" 
    :action="dataAction" 
    :actionParams="{}"
    :search="searchConfig"
    :defaultWhere="baseSelectors" 
    :searchButtonMode="true"
    @on-search-change="onSearchChange"
    />
    </div>

    <div class="lfx-search-result">
      <div
        v-if="dataStatus === 'retrieved' && reportData && Object.keys(reportData).length > 0"
        class="lfx-search-table-container"
      >
        {{ Object.keys(reportData).length }}



    <!-- <LFXListView :definition="definition" :records="reportData" :setTotal="recordCount" /> -->

      </div>
      <div v-else-if="dataStatus === 'retrieved'">No Data Returned for selectors</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBField from '@/landobyte_vue/LDBField.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
// import LFXListView from '@/views/general/LFXListView.vue';
import { evaluate_simple_guards } from '@/db-interface/db_interface_guard';
import LFXPagedListView from '@/views/general/LFXPagedListView.vue';

import lodash from 'lodash';
const merge = lodash.merge;

export default {
  name: 'LfxAdvancedSearch',
  components: { LDBField, LDBLoader , LFXPagedListView},
  props: {
    definition: { type: Object, required: true },
    dataAction: { type: String, required: true },
    downloadAction: { type: String, required: false },
    baseSelectors: { type: Object, required: false, default: undefined },
  },
  data() {
    return {
      selectorsUpdated: false,
      dataStatus: 'none',
      fieldRecord: {},
      selectorRecord: {},
      fieldsAndDataTypes: {},
      formSelectors: {},
      reportData: undefined,
      recordCount: 0,
    };
  },
  created() {},
  mounted() {

  },
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    searchConfig() {
      let res          = {};
      res.chips        = undefined;
      res.choices      = undefined;
      res.ranges       = this.rangeFields;
      res.options      = this.options;
      res.searchFields = this.searchFields;
      res.searchHint   = this.searchHint;
      return res;
    },
    searchHint() {
      let searchFieldLabels = this.allFields.filter((f) => f.datatype_name === 'text').map((f) => f.label);
      return `Search: ${searchFieldLabels.join(', ')}`;
    },
    rangeFields() {
      let rangeTypes = ['date', 'datetime', 'number', 'amount', 'float', 'currency'];
      // return Object.values(this.fields).filter((f) => rangeTypes.includes(f.datatype_name) && f.views.search);
      return Object.values(this.overridenFields).filter((f) => rangeTypes.includes(f.datatype_name) && f.views.search);
    },
    options() {
      let res = {};
      let optionFields = this.allFields.filter((f) => f.datatype_name === 'option');
      for (let o = 0; o < optionFields.length; o++) {
        let optionField = optionFields[o];
        let name = optionField.name;
        let filteredOptions = optionField.datatype.option.options.filter((o) => o.search !== false);
        res[name] = { label: optionField.label, options: filteredOptions, id: name };
      }
      return Object.values(res);
    },
    searchFields() {
      let res = this.allFields.filter((f) => f.datatype_name === 'text').map((f) => f.name);
      return res;
    },

    mergedSelectors() {
      let combinedSelectors = [];
      if (Object.keys(this.baseSelectors).length > 0) {
        combinedSelectors.push(this.baseSelectors);
      }

      // Convert between selectors into and gte and lte selectors.
      let formSelectors = {};
      for (let s in this.formSelectors) {
        let selector = this.formSelectors[s];
        let selectorType = Object.keys(selector)[0];
        let selectorValues = selector[selectorType];
        if (selectorType === 'between') {
          let greaterThan = {};
          greaterThan[s] = { gte: selectorValues[0] };
          let lessThan = {};
          lessThan[s] = { lte: selectorValues[1] };
          combinedSelectors.push(lessThan);
          combinedSelectors.push(greaterThan);
        } else {
          formSelectors[s] = selector;
        }
      }

      combinedSelectors.push(formSelectors);
      
      return combinedSelectors;
      
    },

    // ----- Buttons -----
    
   formButtons() {
      let self = this;
      let combinedSelectors = this.mergedSelectors;
      return [
        {
          id:'advanced-search-button',
          name: 'search',
          label: 'Search',
          type: 'primary',
          action: this.dataAction,
          onSuccess: { doStoreUpdate: false },
          enabledGuards: this.selectorsUpdated === true,
          actionParams: {
            additionalSelector: {
              where: {
                and: combinedSelectors,
              },
            },
          },
          beforeClick() {
            self.dataStatus = 'retrieving';
          },
          onApiSuccess: self.onApiSuccess,
          onApiError() {
            self.dataStatus = 'error';
          },
        },
        {
          id:'search-download-button',
          name: 'download',
          label: 'Download',
          type: 'primary',
          action: this.downloadAction,
          guards: !!this.downloadAction && this.dataStatus === 'retrieved' && this.selectorsUpdated === false,
          actionParams: {
            additionalSelector: {
              where: {
                // and: [this.baseSelectors, this.formSelectors],
                and: combinedSelectors
              },
            },
          },
        },
      ];
    },

    // ----- Fields -----

    fields() {
      return this.definition.fields;
    },

    overridenFields() {
      let overridenFields = [];
      for (const field of this.fields) {
        const updatedField = this.overrideField(field);
        overridenFields.push(updatedField);
      }
      return overridenFields;
    },

    viewFields() {
      const DATATYPE_SORT_VALUES = {
        string: 1,
        option: 2,
        date: 3,
        datetime: 3,
        currency: 4,
        unimplemented: 10,
      };
      const filteredFields = [];
      for (const field of this.allFields) {
        if (evaluate_simple_guards(field.guards, this.fieldRecord)) {
          filteredFields.push(field);
        }
      }
      return filteredFields.filter((a, b) => {
        let dataTypeA = a.datatype_name || 'unimplemented';
        let dataTypeB = b.datatype_name || 'unimplemented';
        let valA = DATATYPE_SORT_VALUES[dataTypeA] ? DATATYPE_SORT_VALUES[dataTypeA] : 10;
        let valB = DATATYPE_SORT_VALUES[dataTypeB] ? DATATYPE_SORT_VALUES[dataTypeA] : 10;
        return valA - valB;
      });
    },
    allFields() {
      return this.fields.filter((f) => f.views.search !== false && f.views.search !== undefined);
    },
    collectionPathTemplate() {
      return this.definition?.collectionPath;
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    onSearchChange(onSearchChangeData) {
      this.$emit('on-search-change',onSearchChangeData);
    },
    doOnSearchEnterPress() {
      let searchButton = document.getElementById('advanced-search-button');
      if (searchButton ) {
        if (searchButton.getAttribute('type') !== 'disabled') {
          searchButton.click();
        }
      }
    },

    doOnSearchSelectorChange(evt) {
      this.formSelectors = evt;

      this.selectorsUpdated = true;
    },
    on_change({ field, value }) {
      // this.fieldRecord[field] = value
      // switch (this.fieldsAndDataTypes[field]) {
      //   case undefined: break
      //   case 'text': this.formSelectors[field] = {like:value}; break
      //   case 'boolean': this.formSelectors[field] = {eq:value}; break
      //   case 'option': this.formSelectors[field] = {eq:value}; break
      //   case 'includer':
      //     if (!value) {
      //       this.formSelectors[field.split("|")[0]] = undefined;
      //     }
      //     break
      //   case 'dateFrom': this.formSelectors[field.split("|")[0]] = {gte:value}; break
      //   case 'dateTo': this.formSelectors[field.split("|")[0]] = {lte:value}; break
      //   case 'currencyFrom': this.formSelectors[field.split("|")[0]] = {gte:value}; break
      //   case 'currencyTo': this.formSelectors[field.split("|")[0]] = {lte:value}; break
      //   default:
      //     throw new Error (`Data Type ${this.fieldsAndDataTypes[field]} not yet implemented`)
      // }
      // this.selectorsUpdated = true
    },

    overrideField(field) {
      if (['originalValueDate', 'valueDate'].includes(field.name)) {
        const startingField = merge({}, field);
        const overrideObject = this.getFieldOverRideObject(field);
        const newField = merge(startingField, overrideObject);
        return newField;
      } else {
        return field;
      }
    },
    getFieldOverRideObject(field) {
      switch (field.name) {
        case 'originalValueDate':
        case 'valueDate':
          return {
            datatype: {
              datetime: {
                minDate: '2014/01/01',
                maxDate: { type: 'year', offset: 1 },
              },
            },
          };
        default:
          return {};
      }
    },
    onApiSuccess(response) {
      this.reportData = {};
      for (const row of response.response?.rows) {
        row.collection_path = Mustache.render(this.collectionPathTemplate, row);
        this.reportData[row.id] = this.definition.class(row, this.$store);
      }
      this.recordCount = response.response.count;
      this.dataStatus = 'retrieved';
      this.selectorsUpdated = false;
    },
  },
};
</script>

<style>
.lfx-search-form > capitec-content-box > .lfx-actions {
  max-height: 48px;
}

.lfx-search-form {
  flex: none;
  padding-bottom: 20px;
}

.lfx-search-result {
  flex: none;
  position: relative;
}

.advanced-search-table{
  flex:auto;
}

.lfx-search-page {  
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  /* border: 3px dotted blue; */
}
</style>
