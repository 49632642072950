<template>
  <LDBModal result="info" header="Deal Log" size="xx-large">
    <template #body>
        <LFXListView v-if="hasListLoaded" :definition="definition" :records="records" :defaultSort="defaultSort" />
        <template v-else>
          <LDBLoader />
        </template>
    </template>    
    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @on-api-success="button.onApiSuccess"
        @click="button.clickEvent"
      ></LDBButton>
    </template>    

  </LDBModal>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXListView from '@/views/general/LFXListView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxTransactionLogList',
  components: { LFXListView, LDBLoader },
  props: {},
  data() {
    return {
      hasListLoaded:false
    };
  },
  async created() {
    this.loadData()
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['transaction','deal']),
    transactionId() {
      return +this.$route.params.transactionId;
    },
    dealId() {
      return +this.$route.params?.dealId;
    },
    records() {
      return this.deal[this.dealId].deal_log || {}
    },
    definition() {
      return getDefinition('deal_log');
    },
    defaultSort() {
      return { order: 'releasedTimestamp', sort: 'desc' }
    },

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'refresh',
          label: 'Refresh',
          type: '',
          action: 'click',
          params: {},
          guards: true,
          enabledGuards: true,
          clickEvent() {
            self.loadData()
          },
        },
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          action: 'click',
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

      ];
      return allButtons;      
    },

  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    async loadData() {
      this.hasListLoaded = false;
      await doPermissionAction(this,'getDealLogs',{transactionId:this.transactionId,dealId:this.dealId});
      this.hasListLoaded = true;
    }
  },
};
</script>

<style>
/* div.container {
} */
</style>
