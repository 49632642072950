<template>
  <LFXPagedListView
    :definition="definition"
    action="getUsers"
    :actionParams="{}"
    :search="searchConfig"
    :defaultWhere="accountSelector"
  />
  <RouterView></RouterView>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXPagedListView from '@/views/general/LFXPagedListView.vue';

export default {
  name: 'LfxUsersList',
  components: { LFXPagedListView },
  props: {
    userVisibility: { type: String, required: false, default: 'visible' },
    includeSystemUsers: { type: Boolean, required: false, default: false },
    includeBranchesInLists: { type: Boolean, required: false, default: false },
  },
  data() {
    const searchConfig = {
      searchFields: [
        'reference',
        'firstName',
        'middleNames',
        'preferredName',
        'surname',
        'emailAddress',
        'msisdn',
        'idNumber',
      ],
      choices: [
        {
          id: 'status',
          title: 'Statuses',
          chips: [
            {
              id: 'all',
              label: 'All',
              selector: { status: { in: ['new', 'pending', 'active', 'disabled'] } },
              default: true,
            },
            { id: 'pending', label: 'Pending', selector: { status: { in: ['new', 'pending'] } } },
            { id: 'active', label: 'Active', selector: { status: { in: ['active'] } } },
            { id: 'disabled', label: 'Disabled', selector: { status: { in: ['disabled'] } } },
            { id: 'pendingReview', label: 'Awaiting Review', selector: { permissionStatus: { in: ['requested'] } } },
          ],
        },
        {
          id: 'hidden',
          title: 'Visibility',
          chips: [
            { id: 'visible', label: 'Visible', selector: { visibility: { eq: 'visible' } }, default: true },
            { id: 'hidden', label: 'Hidden', selector: { visibility: { eq: 'hidden' } } },
          ],
        },
      ],
    };
    return {
      searchConfig: searchConfig,
    };
  },
  created() {},
  mounted() {
    if (this.viewedAccountId === 1) {
      this.$route.meta.isBankUserListView = true
    } else {
      this.$route.meta.isBankUserListView = false
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['user', 'authUser', 'account']),
    viewedAccountId() {
      return +this.$route.params.accountId;
    },
    viewedAccountRecord() {
      return this.account[this.viewedAccountId];
    },
    accountSelector() {
      const selectorObject = { visibility: this.userVisibility, systemUser: this.includeSystemUsers };
      if (this.viewedAccountId) {
        if (this.includeBranchesInLists === true) {
          selectorObject.accountId = [this.viewedAccountId, ...this.viewedAccountRecord.childrenBranchIds];
        } else {
          selectorObject.accountId = this.viewedAccountId;
        }
      } else if (this.$route.name === 'adminPermissionRequests') {
        selectorObject.permissionStatus = 'requested';
      }
      return selectorObject;
    },

    definition() {
      return getDefinition('user');
    },

    authUserId() {
      return this.authUser.id;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    onSearchChange(evt) {
      this.definition.search_string = evt.detail.new;
    },
  },
};
</script>

<style></style>
