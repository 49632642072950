<template>
  <!-- <div class="tst-xxx"> -->
  <div class="ldb-field-container__currency">
    <capitec-text-field
      v-if="view === 'edit' || view === 'create'"
      type="currency"
      :symbol="symbol"
      @value-change="on_change"
      :label="fieldLabel"
      :value="value"
      :error="validationError"
      :separator="separator"
      :disabled="field.enableGuards === false"
    >
    </capitec-text-field>

    <LDBItemViewField
      v-else-if="view === 'item'"
      :view="view"
      :field="field"
      :displayValue="displayValue || ''"
      :definition="definition"
      :record="record"
    />

    <LDBListViewField
      v-else-if="view === 'list'"
      :view="view"
      :field="field"
      :displayValue="displayValue || ''"
      :definition="definition"
      :record="record"
    />
  </div>

  <!-- </div> -->
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

const he = require('he');

import LDBItemViewField from './LDBItemViewField.vue';
import LDBListViewField from './LDBListViewField.vue';

export default {
  mixins: [],
  components: {
    LDBItemViewField,
    LDBListViewField,
  },
  emits: ['change', 'focus', 'blur'],
  props: {
    field: {
      type: Object,
      required: true,
    },
    record: {
      type: Object,
      required: true,
    },
    definition: {
      type: Object,
      required: false,
    },
    formSubmitted: {
      type: Boolean,
      default: false,
    },
    view: {
      type: String,
      required: true,
    },
    validationError: {
      type: String,
      default: '',
    },
  },
  data() {
    return { _value: 0 };
  },
  created() {
    // console.log(this.field?.datatype?.currency?.decimalPlaces);
  },
  mounted() {
    this._value = this.value;
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    separator() {
      let sep = this?.field?.datatype?.currency?.separator ? this?.field?.datatype?.currency?.separator : ' ';
      return sep;
    },
    value: {
      get() {
        let val = this.record[this.field.name];
        if (val === 0) {
          return '0';
        } else if (!val) {
          val = '';
          return val;
        }

        // val = (val / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}).split('$').filter(s => s)[0].split(',').join(this.separator);

        val = `${Math.round(val) / 100}`
          .split(this.separator)
          .join('')
          .split('')
          .reverse()
          .join('')
          .replace(/([0-9]{3})/g, '$1,')
          .split('')
          .reverse()
          .join('')
          .replace(/^,/, '')
          .split(',')
          .join(this.separator);
        // console.log('val:',val);
        return val;
      },
      set(value) {
        this.record[this.field.name] = this.numValue(value);
      },
    },
    fieldIsRequired() {
      if (this.record.requiredFields === undefined) {
        return false;
      } else {
        return this.record.requiredFields.indexOf(this.field.name) >= 0;
      }
    },
    fieldLabel() {
      return this.field.mandatory || this.fieldIsRequired ? `*${this.field.label}` : this.field.label;
    },
    formatCurrency() {
      // console.log('1', this.value);
      let val = this.value ? this.numValue(this.value) : 0;
      return (val / 100)
        .toLocaleString('en-US', { style: 'currency', currency: 'USD' })
        .split('$')
        .join('')
        .split(',')
        .join(this.separator);
      // let sign = Math.sign(val);
      // val = Math.abs(val);
      // console.log('2', val);
      // let numValue = Number(val);
      // console.log('3', numValue);
      // let minor = Math.floor(numValue % 100);
      // console.log('4', minor);
      // let major = Math.floor(numValue / 100);
      // let major = Math.floor((numValue - minor) / 100);
      // console.log('5', major);
      // minor = `${minor}`;
      // console.log('6', minor);
      // if (minor.length < 2) {
      // if (minor < 10) {
      // minor = `${minor}0`;
      // minor = `0${minor}`;
      // }
      // console.log('7', `${major}.${minor}`);
      // return sign >= 0 ? `${major}.${minor}` : `-${major}.${minor}`;
    },
    displayValue() {
      return this.symbol + ' ' + this.thousandSeparator;
    },
    symbol() {
      let symbol = this.field.datatype.currency.symbol || '';
      return he.decode(symbol);
    },
    thousandSeparator() {
      return `${this.formatCurrency}`;
      // .split(this.separator)
      // .join('')
      // .split('')
      // .reverse()
      // .join('')
      // .replace(/([0-9]{3})/g, '$1,')
      // .split('')
      // .reverse()
      // .join('')
      // .replace(/^,/, '')
      // .split(',')
      // .join(this.separator);
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    numValue(value) {
      if (value || value === 0) {
        let val = `${value}`.split(' ').join('');
        val = Number(val);
        if (isNaN(val)) {
          val = 0;
        }
        // return val * 100; // avr was here
        return Math.round(val * 100); // avr was here
      } else {
        return undefined;
      }
    },
    on_change(evt) {
      this.value = evt.detail.new;
      this.$emit('change', this.numValue(evt.detail.new));
    },
  },
};
</script>

<style>
.ldb-field-container.list > .ldb-field-container__currency {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
}

.ldb-field-container__currency {
  flex: 1;
}
</style>
