<template>
  <LDBModal result="info" :header="header"  size="xx-large">
    <template #body>
      <div v-if="mounted">
        <LDBField
          v-for="(field, fieldname) in accountFieldList"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="undefined"
          :record="accountRecord"
          @change="on_change_account"
        />
        <br/>
        <LDBField
          v-for="(field, fieldname) in formFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="swiftDefinition"
          :record="newSwiftRecord"
          @change="on_change"
        />
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
        @on-api-success="onApiSuccess"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBField from '@/landobyte_vue/LDBField.vue';
import { getOptionLabel } from '@/sharedUtils/LdbDefinitionUtils';

export default {
  name: 'LfxNewInwardSwift',
  components: { LDBField },
  emits: ['on-api-success'],
  props: {},
  data() {
    return {
      bankAccountId: undefined,
      accountRecord: {},
      newSwiftRecord: {},
      mounted: true,
    };
  },
  created() {},
  mounted() {},
  beforeUnmount() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['swift','account','config']),
    header() {
      return `Create Inward SWIFT`;
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

        {
          name: 'createInwardSwift',
          label: 'Create Inward Swift',
          type: 'primary',
          action: 'createSwiftForClient',
          params: {
            accountId: this.clientId,
            bankAccountId: this.bankAccountId,
          },
          actionParams: this.newSwiftRecord,
          guards: true,
          clickEvent(response) {
            if (response.status === 'success') {
              self.$router.go(-1);
            }
          },
        },
      ];
      return allButtons !== undefined ? allButtons : [];
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : '/';
    },

    // ----- Definition -----

    swiftDefinition() {
      return getDefinition('inward_swift');
    },
    bankAccountDefinition() {
      return getDefinition('bankAccount');
    },

    // ----- Record -----

    clientId() {
      return +this.$route.params.accountId;
    },
    clientRecord() {
      return this.account[this.clientId]
    },

    // ----- Fields -----
    accountFieldList() {
      return [
        {
          name: 'bankAccountId',
          label: 'Select Bank Account to Assign SWIFT',
          datatype: {
            option: {
              optionType: 'number',
              options: this.ownBankAccountOptions,
            },
          },
          default: null,
        }
      ]
    },

    swiftFields() {
      return this.swiftDefinition.fields
    },
    swiftViewFieldNames() {
      if (!this.bankAccountId) {
        return []
      }
      return [
        'swiftNumber',
        'valueDate',
        'currencyId',
        'amount',
        'remitterBic',
        'correspondentBic',
        'remitterName',
        'remitterAccount',
        'remitterAddress1',
        'remitterAddress2',
        'remitterAddress3',
      ]
    },
    formFields() {
      const res = [];
      for (const fieldName of this.swiftViewFieldNames) {
        const fields = this.swiftFields?.filter((field) => field.field === fieldName);
        if (fields.length > 0) {
          res.push(fields[0]);
        }
      }
      return this.manualOverride(res);
    },
    // ----- Bank Accounts -----
    currencyOptions() {
      const res = [];
      const currencies = this.config[1].currency || {};
      for (const currencyId in currencies) {
        res.push(  { id: currencyId, name: currencyId },)
      }
      return res
    },
    ownBankAccountOptions () {
      const res = [];
      for (const bankAccountId in this.clientRecord?.own_bankAccounts || {}) {
        const bankAccount = this.clientRecord.own_bankAccounts[bankAccountId]
        const statusText = getOptionLabel(this.bankAccountDefinition,'status',bankAccount.status);
        const bankAccountLabel = bankAccount.accountNumber + " "+ bankAccount.currencyId + " ("+ statusText + ") - " + bankAccount.description
        res.push(  { id: bankAccount.id, name: bankAccountLabel },)
      }
      return res
    },
    remitterAccountOptions () {
      return []
    }
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    manualOverride(fieldsToFilter) {
      const res = [];
      for (const field of fieldsToFilter) {
        if (field.name === 'currencyId')  {
          res.push({
                  name: 'currencyId',
                  fieldName: "currencyId",
                  label: 'Currency',
                  datatype: {
                    option: {
                      optionType: 'text',
                      options: this.currencyOptions,
                    },
                  },
                  default: null,
                })
        } else {
          res.push(field)
        }
      }
      return res
    },


    on_change({ field, value }) {
      this.newSwiftRecord[field] = value;
    },
    on_change_account({ field, value }) {
      this.accountRecord[field] = value;
      if (field === 'bankAccountId') {
        this.bankAccountId = value
      }
      this.newSwiftRecord[field] = value;
    },

    // ----- API calls -----
    async onApiSuccess(_responseObject) {},
  },
};
</script>

<style></style>
