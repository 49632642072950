<template>
  <div class="container">
    <capitec-button-bar :style="`max-height:48px; min-height:48px`">
      <LDBButton
        label="UNLOCK USER"
        action="unlockUser"
        :params="{ userId: authUser.id }"
        modalSize="x-small"
        type="primary"
        :tooltip="{ message: 'Click this button to unlock a user' }"
        :modal="{ header: 'Unlock User?', cancelLabel: 'Cancel', confirmLabel: 'Unlock' }"
        @click="onLDBButtonClick"
      >
      <template #modal>
        <div>
          Are you sure you want to unlock user <b>{{ testUser.displayName }}</b> ?
        </div>
      </template>
      </LDBButton>

      <LDBButton
        label="OPEN LINK"
        action="url"
        :params="{ url: 'https://www.google.com' }"
        type="primary"
        modalSize="x-small"
        :tooltip="{ message: 'Click this button to go to www.google.com' }"
        :modal="{ header: 'Go to Google?', cancelLabel: 'Cancel', confirmLabel: 'Go' }"
        @click="onLDBButtonClick"
      >
        <template #modal>
          <div>Are you sure you want to go to <b>www.google.com</b>?</div>
        </template>

      </LDBButton>

      <LDBButton
        label="CLICK ACTION"
        action="click"
        :actionParams="{ url: 'https://www.google.com' }"
        type="secondary"
        :params="testGuards"
        :enabledGuards="{ counter: { gt: 6 } }"
        :viewGuards="{ counter: { gt: 4 } }"
        @click="onClickButtonClick"
      >
        <template #modal>
          <div>Are you sure you want to go to <b>www.google.com</b>?</div>
        </template>
      </LDBButton>

      <router-link :to="{ name: 'transactionsTransactionsList', query: { test: 1, query: 2 } }">Test query</router-link>

      <LDBButton
        label="ROUTER LINK"
        action="routerlink"
        :params="testGuards"
        :actionParams="{ url: '/test' }"
        type="secondary"
        :enabledGuards="{ counter: { between: [5, 10] } }"
        :viewGuards="{ counter: { gt: 3 } }"
        @click="onClickButtonClick"
      >
        <template #modal>
          <div>Are you sure you want to go to <b>www.google.com</b>?</div>
        </template>
      </LDBButton>
    </capitec-button-bar>
    <capitec-spacer />

     <input ref="fileupload" type="file" name="inputfile" id="inputfile" @change="onFileChange">

     <pre id="output">{{filedata}}</pre>


    <!-- <capitec-text-box :value="textField" @value-change="on_text_change" @key-input="on_text_change"> -->
    <!-- </capitec-text-box> -->
    <!-- <capitec-spacer /> -->
    <!-- <div>{{textField}}</div> -->

    <!-- <capitec-file-upload @selection-change="onSelectionChange">
    
    </capitec-file-upload>

    <capitec-file-upload-progress>

    </capitec-file-upload-progress> -->

    <!-- <LDBButton label="ACTION UPLOAD" action='testUpload' :params="testUploadParams" :actionParams="{}" />

    <input type="file" @change="uploadFile" ref="file">

    <capitec-button @click="submitFile" label="Do Upload">

    </capitec-button>

    <LDBFieldUpload /> -->

    <!-- <LfxSignatoryOrder :order="order" :records="records" @on-order-change="onOrderChange" :fields="[]" :nameFields="['firstName','surname']" /> -->

    <!-- <LFXContent
    :titleBar="false"
    :show_tabs="false"
    :tabs="[]"
    :show_actions="false"
    :show_label="true"
    label="test"
    :show_sidebar="false"
  >
  <template #content>
    <LFXPagedListView :definition="countryDefinition" action="getCountries" :actionParams="{configId:1}" :search="searchConfig" />
  </template>

  </LFXContent>  -->
     <!-- <LFXContent
      :titleBar="false"
      :show_tabs="false"
      :tabs="[]"
      :show_actions="false"
      :show_label="true"
      label="test"
      :show_sidebar="false"
    >
      <template #content>
        <LFXPagedListView
          :definition="countryDefinition"
          action="getCountries"
          :actionParams="{ configId: 1 }"
          :search="searchConfig"
        />
      </template>
    </LFXContent>  -->

    <LDBItemView :definition="definition" :record="record" :view="view" />

  </div>

</template>

<script>

import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import { getDefinition, getDefinitionByData } from '@/db-interface/db_definitions_store';
import { LdbTest } from '@/models/LdbTestMdl';
import LDBButton from '@/landobyte_vue/LDBButton.vue';
import { db_assign_object_to_state } from '@/db-interface/db_state'; // FIXME
import LFXPagedListView from '@/views/general/LFXPagedListView.vue';
import LfxSignatoryOrder from '@/views/signatories/LfxSignatoryOrder.vue';
import LFXContent from '@/components/layout/LfxContent.vue';

import axios from 'axios';

var Mustache = require('mustache');
require('@/definitions/actions/yaml-to-js');

export default {
  components: {
    LDBItemView,
    LDBButton,
    LFXPagedListView,
    LfxSignatoryOrder,
    LFXContent,
  },
  props: {},
  data() {
    return {
      filedata:'',
      order: [],
      xrecords: {
        1: { id: 1, name: 'One', checked: true },
        2: { id: 2, name: 'Two', checked: true },
        3: { id: 3, name: 'Three', checked: true },
        4: { id: 4, name: 'Four', checked: true },
        5: { id: 5, name: 'Five', checked: true },
        6: { id: 6, name: 'Six', checked: true },
        7: { id: 7, name: 'Seven', checked: true },
        8: { id: 8, name: 'Eight', checked: true },
        9: { id: 9, name: 'Nine', checked: true },
        10: { id: 10, name: 'Ten', checked: true },
      },
      testIntermediary: {
        accountLevel: 'intermediary',
        accountType: 'entity',
        identificationType: null,
        status: 'new',
        bopFormSignatoryOption: 'intermediary',
        signatoryOrder: null,
        visibility: 'visible',
        uuid: 'c379aa76-aaa4-49a3-8b6e-d0c49b35a1cf',
        id: 235,
        accountId: 1,
        name: 'Test 401',
        tradingEnabled: false,
        cifNumber: '12345678',
        externalReference: null,
        createdBy: 10,
        createdByName: 'Abraham Van Rooyen',
        updatedAt: '2022-03-15T09:37:54.752Z',
        createdAt: '2022-03-15T09:37:53.817Z',
        intermediaryId: 235,
        intermediarySettingsId: 137,
        reference: 'A-0000235',
        identification: null,
        lastTradeTimstamp: null,
        note: null,
        updatedBy: 10,
        updatedByName: 'Abraham Van Rooyen',
        deletedAt: null,
        Table: 'LfxAccount',
        deleted: false,
        selected: true,
      },
      textField: '',
      view: 'edit',
      data: {
        Table: 'LdbTest',
        id: 101,
        name: 'Jannie',
        collection_path: '/test',
        accountId: 110,
        intermediarySettingsId: 1,
        accountLevel: 'bank',
        accountType: 'intermediary',
        reference: 'REF123',
        identificationType: null,
        identification: '8206215008082',
        dateOfId: '2022-04-30T23:00:00.000Z',
        timeOfBirth: '2022-04-30T06:07:00.000Z',
        email: '',
        cifNumber: '',
        comissionAccount: '',
        organisation: '',
        externalReference: '',
        status: '',
        tradingEnabled: true,
        lastTradeTimstamp: '2021-10-18T06:33:51.325Z',
        bopFormSignatoryOption: 'client',
        note: 'This is a note, I need to test notes...',
        visibility: 'visible',
        restrictedView: '',
        createdAt: '2021-10-18T06:33:51.325Z',
        createdBy: 101,
        updatedAt: '2021-10-18T06:33:51.325Z',
        updatedBy: 101,
        deletedAt: '2021-10-18T06:33:51.325Z',
      },
      timer: undefined,
      counter: 1,
    };
  },
  created() {
    this.order = [102, 104, 105, 107, 108, 109];
  },
  mounted() {
    this.timer = setInterval(this.onTimeout, 1000);
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['authUser', 'user']),
    testUploadParams() {
      return { name: 'Jannie', fileName: this.files };
    },
    records() {
      return this.user;
    },
    countryDefinition() {
      return getDefinition('country');
    },
    testUser() {
      return this.user[this.authUser.id];
    },
    testGuards() {
      return { counter: this.counter };
    },
    record() {
      let test = new LdbTest(this.data, this.$store);
      this.add_test(test);
      return test;
    },
    definition() {
      return getDefinition('test');
    },
    lfxApplicationCif() {
      let app = {
        createdByName: 'Abraham Van Rooyen',
        releasedAt: {},
        uuid: '42bfda4f-17a1-4bdc-8f8c-0aade7ba9e5e',
        reference: 'APP-C-0000083',
        createdAt: {},
        requestedAt: null,
        rejectedAt: {},
        id: 83,
        intermediaryId: 108,
        updatedAt: {},
        cifType: 'full',
        cifNumber: '12345678',
        updatedBy: 147,
        Table: 'LfxApplicationCif',
        releasedBy: 10,
        accountType: 'individual',
        updatedByName: 'Abraham Van Rooyen',
        submissionCount: null,
        integrationId: null,
        firstSubmission: null,
        accountId: 229,
        requestedBy: null,
        rejectedBy: 147,
        deletedAt: null,
        deleted: false,
        cifAction: 'create',
        rejectedReason: 'fsdafdsa',
        createdBy: 147,
        integrationStatus: null,
        applicationId: 43,
        status: 'cancelled',
      };
      let def = getDefinitionByData(app);
      let collectionPath = Mustache.render(def.collectionPath, app);
      db_assign_object_to_state(this.$store, app, true, undefined);
      return def;
    },
  },
  methods: {
    ...mapActions(['add_test', 'lfx_auth_get_mfa_token', 'showToast']),
    ...mapMutations([]),
    onFileChange(evt) {
      this.fr = new FileReader();
      this.fr.onload = this.onFileLoad;
      console.log('onFileChange Event:',evt);
      let elm = evt.srcElement;
      let files = elm.files;
      let firstFile = files[0];
      this.fr.readAsText(firstFile);
    },
    onFileLoad(evt) {
      console.log('onFileLoad Event:',evt);
      this.filedata = this.fr.result;
      console.log('onFileLoad FileReader:',this.filedata);
    },
    uploadFile() {
      this.files = this.$refs.file.files[0];
      console.log('Files:', this.$refs.file.files);
    },
    submitFile() {
      const formData = new FormData();
      formData.append('fileName', this.files);
      const headers = { 'Content-Type': 'multipart/form-data' };
      axios.post('http://localhost:3000/uploadtest', formData, { headers }).then((res) => {
        res.data.files; // binary representation of the file
        res.status; // HTTP status
      });
    },

    onSelectionChange(evt) {
      this.files = evt.detail.files[0];
    },
    onOrderChange(order) {
      for (let o = 0; o < order.length; o++) {
        this.order[o] = order[o];
      }
    },
    on_text_change(evt) {
      this.textField = evt.detail.new;
      // this.record.note = evt.detail.new;
    },
    onTimeout() {
      this.counter = this.counter + 1;
      if (this.counter > 10) {
        clearInterval(this.timer);
        this.timer = undefined;
      }
    },
    onLDBButtonClick(event) {
      
    },
    onClickButtonClick(event) {
      let data = this.testIntermediary;
      let res = db_assign_object_to_state(this.$store, data, true);
      console.log('lfxApplicationCif:', this.lfxApplicationCif);
    },
    async doMFACall() {
      let MFAResult = await this.lfx_auth_get_mfa_token({ requestedAction: '', messageText: '', otp: '' });
      if (MFAResult.type === 'new') {
        this.showToast({
          type: 'success',
          header: 'MFA Success!',
          message: 'You have been successfully authenticated!',
          show: 5000,
        });
      }
    },
  },
};
</script>

<style>

pre#output{
  display:block;
  max-height:500px;
  overflow-y:auto;
}

</style>
