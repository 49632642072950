<template>
  <LDBModal type="result" size="xx-large" result="info" :header="header">
    <template #body>
      <div class="modal-table-container">
        <LFXListView v-if="hasListLoaded" :definition="definition" :records="records" :defaultSort="defaultSort" />
        <template v-else>
          <LDBLoader />
        </template>
      </div>
    </template>
    <template #footer>
      <LDBButton
        v-for="button in buttons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        :onSuccess="button.onSuccess"
        @click="button.clickEvent"
        @on-api-success="onApiSuccess"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXListView from '@/views/general/LFXListView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxBankAccountLogList',
  components: { LFXListView, LDBLoader },
  props: {},
  data() {
    return {
      hasListLoaded: false,
    };
  },
  async created() {
    await doPermissionAction(this, 'getBankAccountLogs', {
      accountId: this.accountId,
      bankAccountId: this.bankAccountId,
    });
    this.hasListLoaded = true;
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),
    header() {
      return `Logs For Bank Account - ${this.bankAccountRecord.accountNumber}`;
    },
    buttons() {
      let self = this;
      let allButtons = [
        {
          name: 'closeStatementModal',
          label: 'Close',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
      ];
      return allButtons.filter((button) => button.guards);
    },

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },
    bankAccountId() {
      return +this.$route.params?.bankAccountId;
    },
    bankAccountRecord() {
      return this.accountRecord?.bankAccounts?.[this.bankAccountId];
    },
    records() {
      return this.bankAccountRecord?.bankAccountLogs;
    },
    definition() {
      return getDefinition('bankAccountLog');
    },
    defaultSort() {
      return { order: 'timestamp', sort: 'desc' }
    }
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
  },
};
</script>
