<template>
  <LDBItemsView
    v-if="hasDealRecordLoaded"
    :records="dealRecords"
    :record="dealRecord"
    tileView="default"
    :view="view"
    @on-tile-click="onTileClick"
  />
  <template v-else>
    <LDBLoader />
  </template>

  <router-view></router-view>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBItemsView from '@/landobyte_vue/LDBItemsView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

export default {
  name: 'LfxTransactionDealDetails',
  components: { LDBItemsView, LDBLoader },
  data() {
    return {};
  },
  created() {
    this.dealRecord?.activate();
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['transaction', 'deal']),

    view() {
      return this.$route.meta?.content?.view || 'item';
    },

    // ----- Transaction Record -----

    transactionId() {
      return +this.$route.params?.transactionId;
    },
    transactionRecord() {
      return this.transaction?.[this.transactionId];
    },

    // ----- Deal Record -----

    dealRecords() {
      return this.transactionRecord.deals;
    },

    dealId() {
      return +this.$route.params?.dealId;
    },
    dealRecord() {
      return this.deal?.[this.dealId] || {};
    },

    hasDealRecordLoaded() {
      return this.dealRecord?.definition ? true : false;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    onTileClick(record) {
      this.$router.replace({ name: 'transactionDealDetailsView', params: { dealId: record.id } });
    },
  },
};
</script>
