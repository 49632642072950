//@ts-nocheck

import pluralize from 'pluralize';
import lodash from 'lodash';

import { validate, validate_field } from './db_validation';

//---------------------------------------------------------------------

import { objects_from_path } from './db_utils';

import { getDefinition, getDefinitionByData } from './db_definitions_store';

import { guard_fields } from './db_interface_guard';

//---------------------------------------------------------------------

export const db_proxy_handler = {
  get(target, property) {
    return target[property];
  },
  set(target, property, value) {
    target[property] = value;
    return true;
  },
};

//---------------------------------------------------------------------

const RESERVED_FIELDS = {
  start_date: true,
  sort_value: true,
  sort_field: true,
  hovering: true,
  definition: true,
  // description:true,
  search_string: true,
  db_fields: true,
  db_values: true,
  save_timer_duration: true,
  passes_search: true,
  state: true,
  risk: true,
  __state: true,
  sorted_countries: true,
  store: true,
  // covid19:true,
  active: true,
  edit_view_fields: true,
  collection_path_tokens: true,
  collection: true,
  document_name: true,
  _hovering: true,
  recordId: true,
  parents: true,
};

//---------------------------------------------------------------------

export class DBObject {
  constructor(data, store) {
    this.data = data;

    if (this.definition !== undefined) {
      for (let f = 0; f < this.definition.fields.length; f++) {
        let field = this.definition.fields[f];
        if (field.default !== undefined && !(field.property?.source === 'frontend' || field.property?.write === false)) {
          this[field.name] = field.default;
        }
      }
    }
    for (let f in data) {
      if (RESERVED_FIELDS[f] !== true) {
        // THIS CHECK WILL NOT BE NECESSARY IF THE FIELDS USE A RESERVED NAMING CONVENTION...
        if (data.hasOwnProperty(f)) {
          let value = data[f];
          if (Array.isArray(value)) {
            value = Array.from(value);
          }
          this[f] = value;
        }
      }
    }

    if (this.definition_name === undefined) {
    }

    // this.__state = store.state;
    this.store = store;
    this.collection_path_tokens = data.collection_path.split('/').filter(function (t) {
      return t.length > 0;
    });
    this.collection = this.collection_path_tokens[this.collection_path_tokens.length - 1];
    this.document_name = pluralize.singular(this.collection);
    // this.definition_name = definition_name;
    this._hovering = false;
    let parents = objects_from_path(data.collection_path, store.state);
    for (let p in parents) {
      let parent = parents[p];
      // ??? SHOULD THIS BE A PROP OR A FUNCTION ???
      this[p] = function () {
        return parent;
      };
    }
  }

  get has_validation_errors() {
    if (this.validation_errors) {
      return Object.keys(this.validation_errors).length > 0;
    } else {
      return false;
    }
  }

  get validation_errors() {
    let fields = this.changed_fields;
    let changed_values = {};
    for (let f = 0; f < fields.length; f++) {
      let field = fields[f];
      changed_values[field.name] = this[field.name];
    }
    return validate(changed_values, this);
  }

  get validations() {
    let res = {};
    for (let f in this.definition.fields) {
      let field = this.definition.fields[f];
      if (field.validators !== undefined) {
        res[field.name] = field.validators;
      }
    }
    return res;
  }

  get active() {
    // return (this.store !== undefined && this.store.state !== undefined && this.store.state.active !== undefined &&
    return this.store.state?.active?.[this.document_name] === this.id;
  }

  get sort_value() {
    return lodash.get(this, this.sort_field);
  }

  get sort_field() {
    return this.definition.sort_field;
  }

  get hovering() {
    return this._hovering === true;
  }

  set hovering(value) {
    this._hovering = value === true;
  }

  get definition() {
    let definition = getDefinitionByData(this.data);
    if (definition === undefined) {
      try {
        console.log('COULD NOT FIND DEFINITION FOR:', this.data);
        console.log("this.data.Table",this.data.Table)
        throw new Error('COULD NOT FIND DEFINITION FOR: ' + this.data);
      } catch (err) {
        console.log(err);
      }
    }
    return definition;
  }

  get record_description() {
    return this[this.definition.description_field_name];
  }

  get search_string() {
    let res = [];
    let search_fields = this.definition && this.definition.search_fields ? this.definition.search_fields : ['id'];
    for (let f = 0; f < search_fields.length; f++) {
      let field = search_fields[f];
      let value = lodash.get(this, field);
      res.push(`${value}`);
    }
    return res.join(' ').toLocaleLowerCase();
  }

  get remaining_mandatory_fields() {
    let res = [];
    if (this.guarded_fields !== undefined) {
      for (let f in this.guarded_fields) {
        let field = this.guarded_fields[f];
        if (
          this[field.name] !== this.data[field.name] &&
          field.mandatory === true &&
          this[field.name] !== false &&
          !this[field.name]
        ) {
          res.push(field.name);
        }
      }
    }
    return res;
  }

  get can_save() {
    return !this.has_remaining_mandatory_fields && !this.has_validation_errors;
  }

  get has_remaining_mandatory_fields() {
    return this.remaining_mandatory_fields.length > 0;
  }

  get required_fields_remaining() {
    return this.remaining_mandatory_fields;
  }

  get guarded_fields() {
    return guard_fields(this.definition.fields, this);
  }

  get db_fields() {
    return this.definition.db_fields;
  }

  get db_values() {
    let res = {};
    for (let f = 0; f < this.db_field_names; f++) {
      let field = this.db_field_names[f];
      res[field] = this.get_value(field);
    }
    return res;
  }

  get save_timer_duration() {
    return this.definition.save_timer_duration === undefined ? 3000 : this.definition.save_timer_duration;
  }

  get passes_search() {
    return this.definition.search_string === '' || this.search_string.includes(this.definition.search_string);
  }

  get edit_view_fields() {
    let self = this;
    return this.definition.edit_view_fields.filter(function (field) {
      return field.views.edit === true || (typeof field.views.edit === 'function' && field.views.edit(self, self.store));
    });
  }

  get changed_fields() {
    let fields = [];
    for (let f = 0; f < this.definition.db_write_fields.length; f++) {
      let field = this.definition.db_write_fields[f];
      let initial_value;
      let current_value;

      // RJVR: Add the code below back if necessary.
      // if (this.definition.indexed_fields?.[field.name]?.datatype?.json !== undefined) {
      //   initial_value = JSON.stringify(lodash.get(this.data, field.name));
      //   current_value = JSON.stringify(this[field.name]);
      // }
      // else
      // {
      initial_value = lodash.get(this.data, field.name);
      current_value = this[field.name];
      // }
      if (current_value !== initial_value) {
        fields.push(field);
      }
    }
    return fields;
  }

  validate_field(field, value) {
    let validate_field_res = validate_field(field, value, this);
    if (validate_field_res === undefined) {
      return true;
    } else {
      let errors = [];
      for (let e in validate_field_res) {
        let error = validate_field_res[e];
        errors.push(error);
      }
      return errors.join(' | ');
    }
  }

  sync_update(updated_fields) {
    for (let f in updated_fields) {
      let value = updated_fields[f];
      lodash.set(this.data, f, value);
      lodash.set(this, f, value);
      this.updatedAt = updated_fields.updatedAt;
    }
  }

  sync() {
    for (let f in this.data) {
      let value = lodash.get(this, f);
      let currentValue = lodash.get(this.data, f);
      if (currentValue !== value) {
        lodash.set(this.data, f, value);
      }
    }
  }

  revert() {
    this.definition.db_write_fields.map((f) => _.set(this, f.name, _.get(this.data, f.name)));
  }

  delay_save() {
    if (this.definition !== undefined) {
      if (!(this.__internal_timer === undefined)) {
        clearTimeout(this.__internal_timer);
        this.__internal_timer = undefined;
      }
      this.__internal_timer = setTimeout(this.do_delay_save, this.save_timer_duration);
    }
  }

  do_delay_save() {
    this.__internal_timer = undefined;
    this.save();
  }

  reset() {
    for (let f = 0; f < this.definition.fields.length; f++) {
      let field = this.definition.fields[f];
      this[field.name] = field.default;
      this.data[field.name] = field.default;
    }
  }

  get_value(field) {
    return this[field] === undefined ? this.definition[field].default : this[field];
  }

  create(document_name, record) {
    let nop = function (record) {
      return record;
    };
    let doc_definition = getDefinition(document_name);
    let before_create_function = doc_definition.on_before_create ? doc_definition.on_before_create : nop;
    let after_create_function = doc_definition.on_after_create ? doc_definition.on_after_create : nop;
    let mapped_record = before_create_function(record);
    let DocClass = doc_definition.class ? doc_definition.class : Definition;
    let NewDocument = new DocClass(mapped_record);
    let CreatedDocument = NewDocument.create();
    let UpdatedNewDocument = after_create_function(CreatedDocument);
    return UpdatedNewDocument;
  }

  bind_collection(collection_name) {
    if (this.is_bound_to_collection(collection_name) === false) {
      if (
        this.collection_state[collection_name] !== undefined &&
        this.collection_state[collection_name].bind !== undefined
      ) {
        this.collection_state[collection_name].bind();
      } else {
        let collection = this.definition?.collections?.[collection_name] ?? {
          live: true,
          collection_path: `${this.collection_path}/${this.id}/${collection_name}`,
        };
        this.store.dispatch('db_get_collection', {
          collection_path: collection.collection_path,
          options: { live: collection.live },
        });
      }
    }
  }

  unbind_collection(collection_name) {
    if (
      this.collection_state[collection_name] !== undefined &&
      this.collection_state[collection_name].unbind !== undefined
    ) {
      this.collection_state[collection_name].unbind();
    }
  }

  is_bound_to_collection(collection_name) {
    return (
      this.collection_state !== undefined &&
      this.collection_state[collection_name] !== undefined &&
      this.collection_state[collection_name].bound === true
    );
  }

  create() {
    this.store.dispatch('db_create_document', { record: this });
    return this;
  }

  select(selected) {
    let fields = { selected };
    this.selected = selected === true;
    this.update(fields);
  }

  save(options) {
    let opts = options ? {} : { ...options };
    if (this.definition !== undefined) {
      opts.definition = this.definition;
      let fields = this.changed_fields;
      this.update(fields, opts);
    }
  }
  update(fields, opts) {
    let use_opts = opts === undefined ? {} : opts;
    let only_changed_fields = {};
    for (let f in fields) {
      let new_value = lodash.get(fields, f);
      let old_value = lodash.get(this, f);
      if (new_value !== old_value) {
        lodash.set(only_changed_fields, f, new_value);
      }
    }
    this.store.dispatch('db_update_document', {
      record: this,
      fields: only_changed_fields,
      options: { definition: this.definition, ...use_opts },
    });
  }

  delete() {
    this.store.dispatch('db_flag_delete_document', { collection_path: this.collection_path, id: this.id });
    this.deleted = false;
  }

  activate(arg) {
    this.store.dispatch('db_global_set_active', this);
    this.on_after_activate?.(arg);
  }

  get_sort_field(collection) {
    return getDefinition(collection).sort_field;
  }

  get_sort_ascending(collection) {
    return getDefinition(collection).sort_ascending;
  }

  sort_searched_collection(collection) {
    return this.sort_collection(collection).filter(function (r) {
      return r.passes_search;
    });
  }

  sort_collection(collection) {
    let document_name = pluralize.singular(collection);
    let records = this[collection];
    let res = [];
    for (let r in records) {
      let record = records[r];
      res.push(record);
    }
    let ascending = this.get_sort_ascending(document_name);
    let sort_field = this.get_sort_field(document_name);
    return res.sort(function (a, b) {
      return internal_sort(a, b, sort_field, ascending);
    });
  }
}

//---------------------------------------------------------------------
//  INTERNAL FUNCTIONS
//---------------------------------------------------------------------

export function internal_sort(a, b, field, ascending) {
  let aval = lodash.get(a, field);
  let bval = lodash.get(b, field);
  let sort_dir = ascending ? 1 : -1;
  return aval < bval ? sort_dir : -1 * sort_dir;
}

//---------------------------------------------------------------------
