<template>
  <template v-if="viewRatesFeed && hasAllowedToViewRics">
    <div class="lfx-rates-feed-and-settings" @mouseleave="mouseLeave" @mouseenter="mouseEnter">
      <div class="lfx-rates-container">
        <div v-if="hasFavoriteRates" class="lfx-pinned-rates">
          <template v-for="rate in favoriteRates" :key="rate.ric">
            <LfxRate :rate="rate" />
          </template>
        </div>

        <div v-if="hasEnabledRates" class="marquee lfx-rates-feed-container">
          <div class="rates-feed-fade fade-in" />

          <div class="marquee__inner lfx-rates-feed-animation-1">
            <LfxRate v-for="rate in enabledRates" :key="rate.ric" :rate="rate" />
            <LfxRate v-for="rate in enabledRates" :key="rate.ric" :rate="rate" />
            <LfxRate v-for="rate in enabledRates" :key="rate.ric" :rate="rate" />
            <LfxRate v-for="rate in enabledRates" :key="rate.ric" :rate="rate" />
          </div>

          <div class="rates-feed-fade fade-out" />
        </div>
      </div>

      <div
        v-show="showSettingsButton"
        class="rates-feed-settings-button"
        @click="showRatesFeedSettings(!ratesFeedSettingsVisible)"
      >
        <capitec-icon icon="system/settings" size="small" />
      </div>

      <div v-if="ratesFeedSettingsVisible" class="rates-feed-settings-dropdown">
        <div class="rates-feed-settings-title" style="background-color: rgb(0, 157, 224); color: white">
          Rates Feed Settings
        </div>

        <draggable
          :list="sortedAllowedToViewRics"
          :disabled="false"
          item-key="name"
          class="list-group"
          ghost-class="ghost"
          @start="dragging = true"
          @end="dragEnd"
        >
          <template #item="{ element }">
            <LfxRateSetting :rate="getRateAtIndex(element)" :settings="userRatesFeedSettings" />
          </template>
        </draggable>
      </div>
    </div>
  </template>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import LfxRate from './LfxRate.vue';
import LfxRateSetting from './LfxRateSetting.vue';
import draggable from 'vuedraggable';

export default {
  components: { LfxRate, LfxRateSetting, draggable },
  props: {},
  data() {
    return {
      dragging: false,
      showSettingsButton: false,
      // showSettings: false,
      // milliSecondsPerRate: 3000,
      // windowWidth: 1000,
      // displayCurrencies: [],
      // currencies: {},
      // defaultOrder: [],
      // rateOrder: [],
    };
  },
  created() {},
  mounted() {
    // this.windowWidth = window.innerWidth;
    // this.getCurrencies();
    // setTimeout(this.moveRateToBack, this.milliSecondsPerRate);
    // setInterval(this.getCurrencies, 10 * 1000);
    // this.setupRateOrder();
  },
  beforeUnmount() {
    // this.connection.close();
  },
  computed: {
    ...mapGetters([
      'permissions',
      'currencyPairs',
      'allowedToViewRics',
      'currencyPairIdsWithClientMarkups',
      'sortedAllowedToViewRics',
    ]),
    ...mapState(['account', 'rates', 'authUser', 'ratesFeedSettingsVisible']),

    // ----- Permission -----

    viewRatesFeed() {
      return this.permissions?.ratesFeedView === true;
    },

    // ----- Rates -----

    hasRates() {
      return Object.keys(this.rates).length > 0;
    },

    // allowedToViewRics() {
    //   const allRics = Object?.values(this.rates)?.map((r) => r.ric) || [];
    //   if (this.authUser?.accountLevel === 'client') {
    //     const clientUserAllowedRics = [];
    //     for (const currencyPairId of this.currencyPairIdsWithClientMarkups) {
    //       const currencyPair = this.currencyPairs?.[currencyPairId];
    //       if (allRics.includes(currencyPair.instrument)) {
    //         clientUserAllowedRics.push(currencyPair.instrument);
    //       }
    //     }
    //     return clientUserAllowedRics;
    //   }
    //   return allRics;
    // },
    hasAllowedToViewRics() {
      return this.allowedToViewRics?.length > 0;
    },

    // sortedAllowedToViewRics() {
    //   const sortedRics = [];
    //   if (Array.isArray(this.authUser?.settings?.ratesFeedOrder)) {
    //     for (const savedRicId of this.authUser?.settings?.ratesFeedOrder) {
    //       if (this.allowedToViewRics.includes(savedRicId)) {
    //         sortedRics.push(savedRicId);
    //       }
    //     }
    //   }
    //   for (const allowedRicId of this.allowedToViewRics) {
    //     if (!sortedRics.includes(allowedRicId)) {
    //       sortedRics.push(allowedRicId);
    //     }
    //   }
    //   return sortedRics;
    // },

    enabledRics() {
      return this.sortedAllowedToViewRics?.filter((r) => this.rateSettingStatus(r) === 'enabled');
    },
    enabledRates() {
      const enabledRatesArray = [];
      for (const enabledRicId of this.enabledRics) {
        if (this.rates?.[enabledRicId]) {
          enabledRatesArray.push(this.rates[enabledRicId]);
        }
      }
      return enabledRatesArray;
    },
    hasEnabledRates() {
      return this.enabledRates?.length > 0;
    },

    favoriteRics() {
      return this.sortedAllowedToViewRics?.filter((r) => this.rateSettingStatus(r) === 'favorite');
    },
    favoriteRates() {
      const favoriteRatesArray = [];
      for (const favoriteRicId of this.favoriteRics) {
        if (this.rates?.[favoriteRicId]) {
          favoriteRatesArray.push(this.rates[favoriteRicId]);
        }
      }
      return favoriteRatesArray;
    },
    hasFavoriteRates() {
      return this.hasRates && this.favoriteRates.length > 0;
    },

    // ----- Currency Pairs -----

    // currencyPairIdsWithClientMarkups() {
    //   const res = [];
    //   if (this.authUser?.accountLevel === 'client') {
    //     for (const accountMarkupId in this.accountRecord?.markup) {
    //       const accountMarkup = this.accountRecord?.markup?.[accountMarkupId];
    //       if (accountMarkup.status === 'visible') {
    //         res.push(accountMarkup.currencyPair);
    //       }
    //     }
    //   }
    //   return res;
    // },

    // ----- Account record -----

    accountId() {
      return +this.authUser?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },

    // ----- Settings -----

    userRatesFeedSettings() {
      return this.authUser?.settings?.ratesFeedSettings || {};
    },

    // ratesFeedOrderIds() {
    //   return this.authUser?.settings?.ratesFeedOrder || this.allowedToViewRics;
    // },

    // ----- Existing -----

    // allAvailableCurrencies() {
    //   return this.currencyPairs ? Object.values(this.currencyPairs).map((c) => c.instrument) : [];
    // },
    // ratesFeedSettings() {
    //   let settings = {
    //     disabledRateIds,
    //     favoriteRateIds,
    //     scrollRateIds,
    //   };
    //   return settings;
    // },
  },

  watch: {
    // hasRates() {
    //   // this.setupRateOrder();
    // },
    // rates(oldRates, newRates) {
    //   // this.setupRateOrder();
    // },
    // ratesFeedOrderIds(newIds, oldIds) {
    //   // this.setupRateOrder();
    // },
    // currencyPairs(newPairs, oldPairs) {
    //   this.displayCurrencies = [];
    //   for (let p in newPairs) {
    //     let pair = newPairs[p];
    //     pair.id = p;
    //     pair.rate = this.getRate(pair.id);
    //     this.displayCurrencies.push(pair);
    //   }
    // },
  },

  methods: {
    ...mapActions(['showRatesFeedSettings', 'saveUserSettings', 'saveRatesFeedOrder']),
    ...mapMutations([]),

    // setupRateOrder() {
    //   this.rateOrder = [];
    //   for (let r = 0; r < this.ratesFeedOrderIds.length; r++) {
    //     let id = this.ratesFeedOrderIds[r];
    //     if (this.rates[id] !== undefined) {
    //       if (this.rateOrder.indexOf(id) === -1) {
    //         this.rateOrder.push(id);
    //       }
    //     }
    //   }
    //   for (let r = 0; r < this.allAvailableCurrencies.length; r++) {
    //     let id = this.allAvailableCurrencies[r];
    //     if (this.rates[id] !== undefined) {
    //       if (this.rateOrder.indexOf(id) === -1) {
    //         this.rateOrder.push(id);
    //       }
    //     }
    //   }
    // },
    getRateAtIndex(index) {
      return this.rates[index];
    },
    dragEnd(evt) {
      // this.saveRatesFeedOrder(this.rateOrder);
      this.saveRatesFeedOrder(this.sortedAllowedToViewRics);
    },
    mouseLeave() {
      this.showSettingsButton = false;
    },
    mouseEnter() {
      this.showSettingsButton = true;
    },
    rateSettingStatus(rateId) {
      return this.authUser?.settings?.ratesFeedSettings?.[rateId] === undefined
        ? 'enabled'
        : this.authUser?.settings?.ratesFeedSettings?.[rateId];
    },
    // onRatesFeedSettingChanged({ rateId, status }) {},
    // saveSettings() {
    //   let saveUserSettingsResponse = this.saveUserSettings({ ratesFeedSettings: this.ratesFeedSettings });
    // },
    // moveRateToBack() {
    //   let c = this.displayCurrencies.shift();
    //   this.displayCurrencies.push(c);
    // },
    // getCurrencies() {
    //   let self = this;
    //   let zar = this.rates.ZAR;
    //   for (let c in this.rates) {
    //     let rate = this.rates[c];
    //     self.currencies[c] = zar / rate;
    //   }
    //   self.displayCurrencies = [];
    //   for (let p in self.currencyPairs) {
    //     let pair = self.currencyPairs[p];
    //     pair.id = p;
    //     pair.rate = self.getRate(pair.id);
    //     self.displayCurrencies.push(pair);
    //   }
    // },
    // getRate(pair) {
    //   let currency_a = pair.split('_')[0];
    //   let currency_b = pair.split('_')[1];
    //   if (currency_b === 'ZAR') {
    //     return this.currencies[currency_a];
    //   } else {
    //     return this.currencies[currency_b];
    //   }
    // },
  },
};
</script>

<style>
.lfx-rates-feed-and-settings {
  /* border: 3px solid green; */
  flex: 1;
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
  position: relative;
  max-width: calc(100vw - 230px);
  position: relative;
  flex-direction: column;
  max-height: 50px;
  pointer-events: auto;
}

.rates-feed-settings-title {
  padding: 5px;
}

.rates-feed-settings-dropdown {
  border: 1px solid #ccc;
  position: relative;
  top: 40px;
  min-width: 100px;
  right: 20px;
  position: absolute;
  /* min-height: 300px; */
  z-index: 1000;
  background-color: white;
  border-radius: 3px;
  box-shadow: var(--theme-menu-box-shadow);
  padding-bottom: 5px;
}

.lfx-rates-container {
  overflow: hidden;
  flex: 1;
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
  position: relative;
  max-width: calc(100vw - 400px);
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  /* border: 1px dotted red; */
}

.marquee {
  position: relative;
  overflow: hidden;
  --offset: 20vw;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));
}

.marquee__inner {
  width: fit-content;
  display: flex;
  position: relative;
  transform: translateX(var(--move-initial));
  animation: marquee 60s linear infinite;
  animation-play-state: running;
  /* animation-play-state: paused; */
}

.marquee span {
  font-size: 10vw;
  padding: 0 2vw;
}

.marquee:hover .marquee__inner {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    transform: translateX(var(--move-initial));
  }

  100% {
    transform: translateX(var(--move-final));
  }
}

div.rates-feed-settings-button {
  position: absolute;
  min-height: 30px;
  min-width: 30px;
  max-height: 30px;
  max-width: 30px;
  top: 5px;
  right: 5px;
  z-index: 1000;
  cursor: pointer;
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.lfx-rates-feed-container {
  flex: 1;
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
  position: relative;
  max-width: calc(100vw - 400px);
  position: relative;
  /* border: 2px dotted blue; */
}

.lfx-pinned-rates {
  flex: 1;
  display: flex;
  margin-bottom: 2px;
  margin-top: 0px;
  /* border: 2px dotted green; */
  max-height: 24px;
}

div.rates-feed-fade {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100px;
  pointer-events: none;
  background: linear-gradient(
    var(--rate-fade-angle),
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 0.8) 40%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 100;
  flex: auto;
}

div.rates-feed-fade.fade-in {
  left: 0px;
  --rate-fade-angle: 90deg;
}

div.rates-feed-fade.fade-out {
  right: 0px;
  --rate-fade-angle: -90deg;
}

@keyframes scroll-currency {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(-100vw);
  }
}
</style>
