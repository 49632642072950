<template>
  <div class="lfx-event-notifications-container">

<!-- <div class="content-title"> -->
  <div class="lfx-content-title-bar event" style="background-color: rgb(0, 157, 224); color: white;">
  <div class="lfx-content-title-bar-icon icon">
    <capitec-icon size="default" icon="system/notify-action" style="filter: grayscale(1) brightness(100);"></capitec-icon>
    </div>
    <div class="lfx-content-title-bar-title event">Event Notifications</div>
     <div class="lfx-content-title-bar-icon close" @click="closeEvents">
     <capitec-icon size="small" icon="system/arrow-right"  style="filter: grayscale(1) brightness(100);"></capitec-icon>
    </div>

  </div>
      <transition-group name="list" class="lfx-event-notifications" tag="div" @scroll="onScroll" >
        <span v-for="event in eventList" :key="event.id">
          <LfxEventNotification :event="event" />
        </span>
      </transition-group>  
  </div>
</template>

<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";
import LfxEventNotification from './LfxEventNotification.vue';
import { getMoreHistoricEvents } from '@/webSockets/LdbEventsWebSocket.ts';

export default {
  components: {
    LfxEventNotification
  },
  props: {  
    
  },
  data() {
    return {
    }
  },
  created() {

  },
  mounted() {

  },
  computed:{
    ...mapGetters([]),
  	...mapState(['events','eventsVisible']),
    mostRecentEvent() {
      let res = [];
      for (let e in this.events) {
        let event = this.events[e];
        res.push(event);
      }
      let sortedEvents = res.sort(function(a,b) { 
        let aDate = Math.round((new Date(a.timeStamp).getTime()) / 1000);
        let bDate = Math.round((new Date(b.timeStamp).getTime()) / 1000);
        return ( aDate - bDate );
      });
      if (sortedEvents.length > 0) {
        return sortedEvents[0];
      }
    },
    mostRecentDate() {
      if (this.mostRecentEvent !== undefined) {
        return this.mostRecentEvent.timeStamp;
      }
    },
    eventList() {
      let res = [];
      for (let e in this.events) {
        let event = this.events[e];
        res.push(event);
      }
      return res.sort(function(a,b) { 
        let aPriority = a.eventType.priority * 10000000000000;
        let bPriority = b.eventType.priority * 10000000000000;
        let aDate = Math.round((new Date(a.timeStamp).getTime()) / 1000);
        let bDate = Math.round((new Date(b.timeStamp).getTime()) / 1000);
        return ( (  bDate ) - (  aDate ) );
      });
    }
  },
  methods:{
  	...mapActions(['showEvents']),
  	...mapMutations([]),
    onScroll(evt) {
      let myDiv = evt.target;
      if ( (myDiv.offsetHeight + myDiv.scrollTop + 4) >= myDiv.scrollHeight) {  
        getMoreHistoricEvents(this.mostRecentDate);
      }  
    },
    closeEvents() {
      this.showEvents(false);
    }
  }
 }
</script>
<style>


div.lfx-event-notifications-container{
  position:absolute;
  top:0px;
  bottom:0px;
  left:0px;
  right: 0px;
  display:flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  border-radius: 5px;
  overflow: hidden;
}

.lfx-event-notifications{
  overflow-y:auto;
  position:absolute;
  top:50px;
  bottom:0px;
  display:flex;
  flex-direction: column;
  right:0px;
  left:0px;
  flex:1;
  padding-bottom:10px;

}

.list-enter-active, .list-leave-active {
  opacity: 1;
  display: block;
  transition: all 0.5s;
  min-height: 100px;
}

.list-enter, .list-leave-to {
  display: block;
  opacity: 0;
  transform: translateX(500px) scale(0);
  min-height: 0px;
  transition: all 0.5s;  
}

.lfx-content-title-bar.event{
  margin-left:0px;
  margin-top:0px;
  margin-right: 0px;
  grid-template-areas: '. icon . title close .';
  grid-template-columns: 10px 32px 10px auto 32px 20px;
  grid-template-rows: 48px;
  min-height: 48px;
  /* border: 1px solid red; */
}

.lfx-content-title-bar.event:hover{
  border: none;
  box-shadow: none;
}

.lfx-content-title-bar-icon.close{
  grid-area: close;
  cursor: pointer;
}

.lfx-content-title-bar-title.event{

}

</style>


