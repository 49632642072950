<template>
  <div v-if="showcurrency" class="lfx-currency-container">
    <div class="capitec-tile">
    
    <div class="capitec-tile-color-line">
    
    </div>
    <div class="capitec-tile-avatar" :v-html="record.symbol">

    
      </div>
    <div class="capitec-tile-title">
    {{record.name}}
    </div>
    <div class="capitec-tile-subtitle">
    {{record.id}}
    </div>
    <div class="capitec-tile-info">
      <!-- {{record.id}} -->
    </div>
    </div>
  </div>
</template>


<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

export default {
  components: {},
  props: {  
    record:{type:Object,required:true}
  },
  data() {
    return {
      whishlist:[
        'TimeZone Info of currency',
        'Default currency code of currency',
        ''
      ]
    }
  },
  created() {
    
  },
  mounted() {
    
  },
  computed:{
    ...mapGetters([]),
  	...mapState([]),
    showcurrency() {
      return !((this.record.id === 'TP') || (this.record.id === 'AN') || (this.record.id === 'JE') || (this.record.id === 'KV') );
    },
    avatarStyle() {
      return {border: '1px solid red','background-image':`url(/images/square_flags/${this.record.id}.svg)`}
    }
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([])
  }
 }

</script>

<style>

div.lfx-currency-container{
  /* border: 1px solid red; */
  display:grid;
  min-width:400px;
  max-width:400px;
  min-height: 60px;
  margin-bottom:5px;
  margin-right: 5px;
}

div.capitec-tile-avatar{
  min-height: var(--theme-product-tile-height);
  min-width: 50px;
  background-size: 50px 50px;
  grid-area: avatar;
  /* border: 1px solid green; */
  display:grid;
  align-items: center;
  justify-content: center;
}

div.capitec-tile-color-line{
  grid-area: line;
  background-color:var(--theme-type-primary-background);
}

.capitec-tile-info{
  grid-area: info;
  /* border: 1px solid green; */
}

.capitec-tile{
  display:grid;
  grid-template-areas: 
  'line avatar title info' 
  'line avatar subtitle info' ;
  grid-template-columns: 4px 60px auto 30px;
  grid-template-rows: calc(var(--theme-product-tile-height) / 2) calc(var(--theme-product-tile-height) / 2);
  border-radius:var(--theme-product-tile-border-radius, 5.05px);
  margin-left:16px;
  margin-top:16px;
  cursor: pointer;
  border:var(--theme-product-tile-border, 1px solid #E1E1E1);

  /* --theme-product-tile-hover-save-border: 1px solid #00486D;
  --theme-product-tile-focus-save-border: 2px solid #00486D;
  --theme-product-tile-hover-credit-border: 1px solid #E61414;
  --theme-product-tile-focus-credit-border: 2px solid #E61414;
  --theme-product-tile-hover-insure-border: 1px solid #5D737E;
  --theme-product-tile-focus-insure-border: 2px solid #5D737E; */
  /* --theme-product-tile-colour-display-width: 57px; */
  /* --theme-product-tile-colour-border-radius: 5.05px 0 0 5.05px; */
  /* --theme-product-tile-transact-colour: #009DE0; */
  /* --theme-product-tile-save-colour: #00486D; */
    /* --theme-product-tile-credit-colour: #E61414;
    --theme-product-tile-insure-colour: #5D737E; */

    /* 
    

    --theme-product-tile-detail-right: 26px;
    --theme-product-tile-detail-top: 25px;
    --theme-product-tile-detail-height: 24px;
    --theme-product-tile-detail-font-size: 18px;
    --theme-product-tile-nav-right: 16px;

   
    --theme-product-tile-add-right: 16px; */

    
}

.capitec-tile:focus{
  box-shadow:var(--theme-product-tile-focus-shadow, 0 3px 6px 0 rgba(0,0,0,0.15));
  border:var(--theme-product-tile-focus-transact-border, 1px solid #009DE0);
}

.capitec-tile:hover{
  box-shadow:var(--theme-product-tile-hover-shadow, 0 1px 3px 0 rgba(0,0,0,0.25), 0 2px 4px 0 rgba(0,0,0,0.15));
  border: var(--theme-product-tile-hover-transact-border, 1px solid #009DE0);
}



.capitec-tile-title{
  grid-area: title;
  /* border: 1px solid green; */
  margin-left:var(--theme-product-tile-heading-margin-left, 16px);
  margin-top:var(--theme-product-tile-heading-margin-top, 13px);

  min-height:var(--theme-product-tile-title-height,24px);
  font-size:var(--theme-product-tile-title-font-size, 18px);

  font-weight:var(--theme-product-tile-title-font-weight, 500);
}

.capitec-tile-subtitle{
   grid-area: subtitle;
   /* border: 1px solid green; */
   color:var(--theme-product-tile-subtitle-color, var(--theme-system-font-color));
   margin-left:var(--theme-product-tile-heading-margin-left, 16px);
   min-height:var(--theme-product-tile-subtitle-height, 20px);
   font-size:var(--theme-product-tile-subtitle-font-size, 15px);
}

</style>


