<template>
  <template v-if="crucialDataHasLoaded"><slot /></template>
  <template v-else><LDBLoader /></template>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
// import { getDefinition } from '@/db-interface';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LDBDataLoader',
  TODO: [
    'make this more generic',
    'implement retry logic',
    'implement throwing error if retry fails',
    'get actions out of definitions, not hard-coded',
    'check if this causes flickering on fast networks, and maybe somehow add a 200 ms delay before undisplaying the loader',
  ],
  created() {
    this.loadUnloadedCrucialData();
  },
  computed: {
    ...mapGetters(['isDevelopment']),
    ...mapState(['account', 'transaction', 'user', 'swift']),

    // ----- General -----

    crucialDataHasLoaded() {
      return (
        this.hasAccountRecordLoaded &&
        this.hasTransactionRecordLoaded &&
        this.hasSwiftRecordLoaded &&
        this.hasApplicationRecordLoaded &&
        this.hasBeneficiaryRecordLoaded &&
        this.hasUserRecordLoaded
      );
    },

    // ----- Account record -----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },
    hasAccountRecordLoaded() {
      return this.accountId ? (this.accountRecord?.id ? true : false) : true;
    },

    // ----- Transaction File record -----

    transactionId() {
      return +this.$route.params?.transactionId;
    },
    transactionRecord() {
      return this.transaction?.[this.transactionId];
    },
    hasTransactionRecordLoaded() {
      return this.transactionId ? (this.transactionRecord?.id ? true : false) : true;
    },
    // transactionDefinition() {
    //   return getDefinition('transaction');
    // },

    // ----- Inward SWIFT record -----

    swiftId() {
      return +this.$route.params?.swiftId;
    },
    swiftRecord() {
      return this.swift?.[this.swiftId];
    },
    hasSwiftRecordLoaded() {
      return this.swiftId ? (this.swiftRecord?.id ? true : false) : true;
    },

    // ----- Application record -----

    applicationId() {
      return +this.$route.params?.applicationId;
    },
    applicationRecord() {
      return this.accountRecord?.application?.[this.applicationId];
    },
    hasApplicationRecordLoaded() {
      return this.applicationId ? (this.applicationRecord?.id ? true : false) : true;
    },

    // ----- Beneficiary record -----

    beneficiaryId() {
      return +this.$route.params?.beneficiaryId;
    },
    beneficiaryRecord() {
      return this.accountRecord?.beneficiaries?.[this.beneficiaryId];
    },
    hasBeneficiaryRecordLoaded() {
      return this.beneficiaryId ? (this.beneficiaryRecord?.id ? true : false) : true;
    },

    // ----- User record -----

    userId() {
      return +this.$route.params?.userId;
    },
    userRecord() {
      return this.user?.[this.userId];
    },
    hasUserRecordLoaded() {
      return this.userId ? (this.userRecord?.id ? true : false) : true;
    },
  },

  methods: {
    async loadUnloadedCrucialData() {
      if (this.accountId || this.applicationId || this.beneficiaryId) {
        if (!this.hasAccountRecordLoaded) {
          await this.loadAccountRecord(); // TODO handle failure case
        }
        if (this.hasAccountRecordLoaded) {
          if (this.applicationId && !this.hasApplicationRecordLoaded) {
            this.loadApplicationRecord();
          }
          if (this.beneficiaryId && !this.hasBeneficiaryRecordLoaded) {
            this.loadBeneficiaryRecord(); // TODO this probably causes a patch due to accountMdl beneficiaries logic as well as a duplicate get here
          }
        }
      }

      if (this.transactionId && !this.hasTransactionRecordLoaded) {
        this.loadTransactionFileRecord();
      }
      if (this.swiftId && !this.hasSwiftRecordLoaded) {
        this.loadSwiftRecord();
      }
      if (this.userId && !this.hasUserRecordLoaded) {
        this.loadUserRecord();
      }
    },

    async loadAccountRecord() {
      await doPermissionAction(this, 'getAccount', { accountId: this.accountId }, {});
      // TODO add logic to retry X number of times
    },
    loadTransactionFileRecord() {
      doPermissionAction(this, 'getTransactionFile', { transactionId: this.transactionId }, {});
      // TODO add logic to retry X number of times
    },
    loadSwiftRecord() {
      doPermissionAction(this, 'getInwardSwift', { swiftId: this.swiftId }, {});
      // TODO add logic to retry X number of times
    },
    loadApplicationRecord() {
      doPermissionAction(this, 'getApplication', { accountId: this.accountId, applicationId: this.applicationId }, {});
      // TODO add logic to retry X number of times
    },
    loadBeneficiaryRecord() {
      doPermissionAction(this, 'getBeneficiary', { accountId: this.accountId, beneficiaryId: this.beneficiaryId }, {});
      // TODO add logic to retry X number of times
    },
    loadUserRecord() {
      doPermissionAction(this, 'getUser', { userId: this.userId }, {});
      // TODO add logic to retry X number of times
    },
  },
};
</script>
