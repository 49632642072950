<template>
  <div class="ldb-item-view-container">
    <div v-if="mounted" class="lfx-form" :class="view">
      <div v-for="group in viewFieldsAndGroups" class="ldb-form-group" :key="group.name">
        <div class="ldb-field-group-label" :style="groupStyle(group)">
          <capitec-label type="subtitle" class="ldb-field-group" :label="group.group.label" />
        </div>
        <form autocomplete="on" :name="formName" :id="formName">
        <LDBField
          :key="field.name"
          v-for="field in group.fields"
          :field="field"
          :view="viewForFieldComponent"
          :fieldname="field.name"
          :definition="definition"
          :record="record"
          @change="on_change"
        />
        </form>
      </div>
    </div>
    <div v-if="view === 'edit' || showButtons === true" class="form-item-action-bar" :class="view">
      <LDBButton
        v-for="button in leftButtons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        :action="button.action"
        :params="button.params"
        :actionParams="record"
        @click="button.clickEvent"
        @on-api-success="button.onApiSuccess"
        @on-api-error="button.onApiError"
      />
      <capitec-spacer> </capitec-spacer>
      <LDBButton
        v-for="button in rightButtons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        :action="button.action"
        :params="button.params"
        :actionParams="record"
        @click="button.clickEvent"
        @on-api-success="button.onApiSuccess"
        @on-api-error="button.onApiError"
      />
    </div>

    <div class="empty-spacer" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import LDBField from './LDBField.vue';
import { STATUS_COLORS } from '@/landobyte_vue/ldb_utils.ts';

export default {

  components: { LDBField },
  props: {
    // definition: { type: Object, required: true },
    record: { type: Object, required: true },
    view: { type: String, default: 'item' },
    checkerMode: {type : Boolean, default: false, required: false},
    fieldView: { type: String, default: undefined },
    providedTitle: { type: String, required: false },
    providedFields: { type: Object, required: false, default: undefined },
    providedGroups: { type: Object, required: false, default: {} },
    action: { type: String, default: 'click' },
    params: {
      type: Object,
      default() {
        return {};
      },
    },
    cancelButtonCaption: { type: String, default: 'Cancel' },
    saveButtonCaption: { type: String, default: 'Save' },
    forceSaveButtonType: {type: String, default: undefined, required: false},
    showButtons: { type: Boolean, default: false }, // Used to enforce show buttons for non edit views
    showBackButton: { type: Boolean, default: false },
    showNextButton: { type: Boolean, default: false },
    checkMandatoryFields: { type: Boolean, default: true },
    unlinkedMode: { type: Boolean, default: false },
  },
  emits: ['on-back', 'on-cancel', 'on-save', 'on-next', 'on-api-success', 'on-api-error'],
  data() {
    return {
      formSubmitted: false,
      changedFields: {},
      mounted: false,
    };
  },
  created() {
  },
  mounted() {
    this.revert();
    for (let f = 0; f < this.viewFields.length; f++) {
      let field = this.viewFields[f];
      if (field.default !== undefined) {
        this.changedFields[field.name] = this.record[field.name] || '';
      }
    }

    this.mounted = true;
  },
  onBeforeUnmount() {
    this.revert();
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['authUser']),
    formName() {
      return this.definition?.name || "ldb-item-view-form";
    },
    // useView() {
    //   return this.record.view;
    // },
    viewForFieldComponent() {
      if (this.checkerMode) {
        return 'item'
      }
      return this.view
    },
    definitionGroups() {
      return this.definition?.groups || [];
    },
    defaultGroup() {
      for (let g in this.definitionGroups) {
        let group = this.definitionGroups[g];
        if (group.default === true) {
          return group;
        }
      }
      return { priority: 1, default: true, name: 'default', label: '' };
    },
    useFieldView() {
      return this.fieldView || this.view || 'item';
    },
    fieldnames() {
      return this.viewFields.map((field) => field.name);
    },

    requiredFieldsRemaining() {
      return this.record.required_fields_remaining?.filter((f) => this.fieldnames.indexOf(f) >= 0).length > 0 || false;
    },

    mandatoryFieldsRemainingList() {
      return this.record.remaining_mandatory_fields || [];
    },

    mandatoryFieldsRemaining() {
      return this.record.remaining_mandatory_fields?.length > 0 || false;
    },

    definition() {
      return this.record.definition;
    },
    title() {
      if (this.unlinkedMode) {
        return this.providedTitle;
      }
      return `View ${this.definition.title}`;
    },
    fields() {
      return this.record?.guarded_fields || {};
    },
    viewFields() {
      if (this.unlinkedMode) {
        return this.providedFields;
      }
      let res = [];
      for (let k in this.fields) {
        let field = this.fields[k];
        var fieldDisplayForView = field.views[this.useFieldView] || field.views.customViews?.[this.useFieldView];
        if (typeof fieldDisplayForView === 'function') {
          fieldDisplayForView = fieldDisplayForView({
            authUser: this.authUser,
            view: this.useFieldView,
            routeMetaData: this.$route.meta,
            record: this.record,
          });
        }
        if (fieldDisplayForView) {
          if (
            this.view === 'edit' ||
            field.showIfNull ||
            (this.record[field.name] !== null && this.record[field.name] !== undefined)
          ) {
            field.fieldname = k;
            res.push(field);
          }
        }
      }
      return res;
    },

    fieldsByGroup() {
      const defaultGroupName = this.defaultGroup.name;
      let res = {};
      for (let g in this.groups) {
        res[g] = 0;
      }
      if (res[defaultGroupName] === undefined) {
        res[defaultGroupName] = 0;
      }
      for (let f = 0; f < this.viewFields.length; f++) {
        let field = this.viewFields[f];
        if (field.group !== undefined && res[field.group] !== undefined) {
          res[field.group] = res[field.group] + 1;
        } else {
          res[defaultGroupName] += 1;
        }
      }
      return res;
    },
    groups() {
      let baseGroups;
      if (this.unlinkedMode) {
        baseGroups = this.providedGroups;
      } else {
        const defaultGroupName = this.defaultGroup.name;
        baseGroups = this.definitionGroups;
        if (!baseGroups[defaultGroupName]) {
          baseGroups[defaultGroupName] = this.defaultGroup;
        }
      }
      return baseGroups;
    },
    viewFieldsAndGroups() {
      let res = [];
      var groups = Object.entries(this.groups)
        .map(([k, v]) => {
          v.name = k;
          return v;
        })
        .sort((a, b) => a.prority - b.priority);
      for (let g = 0; g < groups.length; g++) {
        let group = groups[g];
        group.type = 'group';
        if (this.fieldsByGroup[group.name] > 0) {
          res.push(group);
        }
        for (let f = 0; f < this.viewFields.length; f++) {
          let field = this.viewFields[f];
          if (field.group === group.name) {
            res.push(field);
          } else if ((field.group === undefined || this.groups[field.group] === undefined) && group.default === true) {
            res.push(field);
          }
        }
      }
      let grouped = { default: { priority: this.defaultGroup.priority, fields: [], group: this.defaultGroup } };
      let priority = 1;
      for (let g = 0; g < res.length; g++) {
        let group = res[g];
        if (group.type === 'group') {
          grouped[group.name] = { ...group };
          grouped[group.name].group = group;
          grouped[group.name].fields = [];
          grouped[group.name].priority = priority;
          priority = priority + 1;
        }
      }

      for (let f = 0; f < res.length; f++) {
        let field = res[f];
        let groupName = field.group;
        if (groupName === undefined || this.groups[field.group] === undefined) {
          groupName = 'default';
        }
        if (field.type !== 'group') {
          grouped[groupName].fields.push(field);
        }
      }
      let groupedArray = Object.values(grouped).sort((a, b) => a.priority - b.priority);
      return groupedArray.filter((g) => g.fields.length > 0);
    },

    // ----- Form Buttons -----

    // Left buttons

    leftButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'back',
          label: 'Back',
          type: 'clear',
          action: 'click',
          get disabled() {
            return this.type === 'disabled';
          },
          guards: this.showBackButton,
          clickEvent() {
            self.onBackClick();
          },
        },
      ];
      return allButtons.filter((button) => button.guards);
    },

    // Right buttons

    rightButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          action: 'click',
          label: this.cancelButtonCaption || 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.onCancelClick();
          },
        },
        {
          name: 'save',
          label: this.saveButtonCaption,
          action: this.action,
          type: this.saveButtonType,
          params: this.params,
          get disabled() {
            return this.type === 'disabled';
          },
          guards: true,
          clickEvent(response) {
            if (response.status === 'success') {
              self.onSaveClick();
            }
          },
          onApiSuccess(evt) {
            self.$emit('on-api-success', evt);
          },
          onApiError(evt) {
            self.$emit('on-api-error', evt);
          },
        },
        {
          name: 'next',
          label: 'Next',
          action: this.action,
          type: this.nextButtonType,
          params: this.params,
          get disabled() {
            return this.type === 'disabled';
          },
          guards: this.showNextButton,
          clickEvent(response) {
            if (response.status === 'success') {
              self.onNextClick();
            }
          },
        },
      ];
      return allButtons.filter((button) => button.guards);
    },

    saveButtonType() {
      if (this.forceSaveButtonType) {
        return this.forceSaveButtonType
      }
      // TODO check mandatory fields and validations
      return this.record.has_validation_errors || (this.checkMandatoryFields && this.mandatoryFieldsRemaining > 0)
        ? 'disabled'
        : this.showNextButton
        ? ''
        : 'primary';
    },
    nextButtonType() {
      // TODO check mandatory fields and validations
      return this.record.has_validation_errors || this.requiredFieldsRemaining ? 'disabled' : 'primary';
    },
  },
  watch: {
    view(new_view, old_view) {
      this.revert();
    },
    // $route(to, from) {
    // this.useView = this.view;
    // this.changeView(this.view);
    // },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    groupStyle(group) {
      let groupColor = group.color === undefined ? 'info' : group.color;
      let actualColor = STATUS_COLORS[groupColor] === undefined ? groupColor : STATUS_COLORS[groupColor];
      return { '--group-color': actualColor };
    },
    revert() {
      if (!this.unlinkedMode) {
        this.record?.revert();
      }
    },
    // changeView(view) {
    //   if (view === 'edit') {
    //     this.record.editing = true;
    //   } else {
    //     this.record.editing = false;
    //   }
    // },
    on_change({ field, value }) {
      // console.log('FIELD, VALUE', field, value);
      this.changedFields[field] = value;
      this.record[field] = value;
      // console.log('RECORD.FIELD', this.record[field]);
      // this.$emit('on-field-change', { field: field, value: value, record: this.record });
    },

    // ----- Button -----

    onBackClick() {
      this.revert();
      this.$emit('on-back');
    },
    onCancelClick() {
      // this.$router.replace({ name: this.cancelRoute });
      this.$router.go(-1);
    },
    onSaveClick() {
      // this.showLoader(true);
      this.formSubmitted = true;
      // let self = this;
      this.$emit('on-save', {
        fields: this.changedFields,
      });
    },
    onNextClick() {
      this.formSubmitted = true;
      this.$emit('on-next', {
        fields: this.changedFields,
      });
    },
  },
};
</script>

<style>
.ldb-item-view-container {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  align-content: stretch;
  background-color: var(--theme-chip-background-color);
  flex-direction: column;
}

.form-item-action-bar {
  display: flex;
  background-color: white;
  padding: 8px;
  padding-right: 20px;
}

.form-item-action-bar > capitec-button {
  margin-left: 5px;
  margin-right: 5px;
}

.lfx-form {
  align-items: stretch;
  justify-content: stretch;
  align-content: flex-start;
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
  flex: auto;
}

.lfx-form.item {
  max-height: calc(100% - 10px);
}

.lfx-form.edit,
.lfx-form.create {
  max-height: calc(100% - 10px);
}

.ldb-form-group {
  background-color: white;
  flex: 2;
  margin: 3px;
  min-width: 600px;
  padding: 10px;
  border-radius: 4px;
}

div.ldb-field-group-label {
  margin-bottom: 0px;
  position: relative;
  padding-left: 14px;
  margin-top: -1px;
}

div.ldb-field-group-label > capitec-label {
  font-size: 18px;
}

div.ldb-field-group-label::before {
  content: '';
  position: absolute;
  top: 9px;
  bottom: 3px;
  --dot-size: 10px;
  min-width: var(--dot-size);
  max-width: var(--dot-size);
  min-height: var(--dot-size);
  max-height: var(--dot-size);
  left: 0px;
  background-color: var(--group-color);
  border-radius: 5px;
}

.empty-spacer {
  flex-grow: 100;
  flex-shrink: 10000;
}

.form-item-action-bar {
  flex: auto;
  max-height: 32px;
}

/* .form-item-action-bar.item { */
/* max-height: 0px; */
/* flex: 0; */
/* display: none; */
/* } */
</style>
