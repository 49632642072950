<template>
  <LFXPagedListView
    :definition="definition"
    action="getTransactionFiles"
    :actionParams="{}"
    :search="searchConfig"
    :defaultWhere="accountSelector"
    :defaultSort="defaultSort"
  />
  <router-view></router-view>
</template>
<script>

import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import { getDefinition } from '@/db-interface';

import LFXPagedListView from '@/views/general/LFXPagedListView.vue';

import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

export default {
  name: 'LfxTransactionFilesList',
  emits:['on-search-change'],
  components: { LFXPagedListView, LDBLoader },
  props: {
    includeBranchesInLists: {type: Boolean, required: false, default: false}
  },
  data() {
    const todayStart = new Date(new Date().setHours(0, 0, 0, 0));
    const todayEnd = new Date(new Date().setHours(23, 59, 59, 0));
    return {
      searchConfig: {
        searchFields: ['reference'],
        choices: [
          {
            id: 'status',
            title: '',
            chips: [
              { id: 'open', label: 'Open', selector: {status: { notIn: ['completed', 'cancelled', 'rejected', 'merged'] }} , default:true },
              {
                id: 'bookedToday',
                label: 'Booked Today',
                selector: { and: [{ createdAt: { gte: todayStart } }, { createdAt: { lte: todayEnd } }] },
              },
              {
                id: 'dueToday',
                label: 'Due Today',
                selector: { and: [{ valueDate: { gte: todayStart } }, { valueDate: { lte: todayEnd } }] },
              },
              {
                id: 'awaitingDealRelease',
                label: 'Awaiting Release',
                selector: { status: { in: ['awaitingDealRelease'] } },
              },
              {
                id: 'awaitingFecDrawdown',
                label: 'Awaiting Drawdown',
                selector: { status: { in: ['awaitingFecDrawdown'] } },
              },
              { id: 'awaitingPayment', label: 'Awaiting Payment', selector: { status: { in: ['awaitingPayment'] } }},
              { id: 'completed', label: 'Completed', selector: { status: { in: ['completed'] } } },
              { id: 'cancelled', label: 'Cancelled', selector: { status: { in: ['cancelled', 'rejected'] } } },
              {
                id: 'conflict',
                label: 'Conflicting',
                selector: { status: { in: ['dealConflict', 'paymentConflict'] } },
              },
              {
                id: 'overdue',
                label: 'Overdue',
                selector: { valueDate: { lte: todayStart }, status: { notIn: ['completed', 'cancelled', 'rejected','merged'] } },
              },
              { id: 'all', label: 'All', selector: {} },
            ],
          },
        ],
      },
    };
  },
  created() {
    if (this.isDevelopment) {
      console.log('ROUTE', this.$route);
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters(['isDevelopment']),
    ...mapState(['account', 'transaction']),
    defaultSort() {
      return { order: 'id', sort: 'desc' };
    },
    viewedAccountId() {
      return +this.$route.params.accountId;
    },
    viewedAccountRecord() {
      return this.account[this.viewedAccountId]
    },
    viewedAccountLevel() {
      return this.viewedAccountRecord?.accountLevel;
    },
    accountSelector() {
      if (this.viewedAccountId) {
        switch (this.viewedAccountLevel) {
          case 'client':
            this.$route.meta.hideClientAndBranchName = true
            return { accountId: this.viewedAccountId };
          default:
            if (this.includeBranchesInLists === true) {
              if (this.viewedAccountLevel === 'intermediary') {  
                return { intermediaryId: this.viewedAccountId };
              } else {
                return { branchId: [this.viewedAccountId,...this.viewedAccountRecord.childrenBranchIds] }
              }
            } else {
              return { branchId: this.viewedAccountId };
            }
        }
      }
      return undefined;
    },

    definition() {
      return getDefinition('transaction');
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>

<style>

</style>
