import VueStore from 'vue-class-store';

import { LdbDbObject, LdbDefinition } from '@/definitions/LdbInterfaces';
import { LfxDealPaymentInt, LfxDealPaymentDef } from '@/definitions/LfxDealPaymentDef';
import { LfxModel, LfxDefinition } from './LfxModel';

import { doPermissionAction } from '@/lfx_rest/lfx_action';

// @ts-expect-error
import { http_patch, http_get, http_put } from '@/lfx_rest';

@VueStore
// @ts-expect-error
class LfxDealPaymentMdl extends LfxDefinition {
  // @ts-expect-error
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
// @ts-expect-error
export class LfxDealPayment extends LfxModel {
  // @ts-expect-error
  constructor(data, state) {
    super({ definition_name: 'dealPayment', ...data }, state);
  }

  // --------------------
  // ----- General -----
  // --------------------

  get link() {
    // @ts-expect-error
    return `/transaction/${this.transactionFileId}/dealPayment/${this.id}`;
  }

  // --------------------
  // ----- API calls -----
  // --------------------

  // ----- General -----

  on_after_activate() {}

}

// @ts-expect-error
export function Definition(context, name: string) {
  return new LfxDealPaymentMdl(LfxDealPaymentDef, LfxDealPayment, context, name, {});
}
