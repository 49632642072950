<template>
  <lfx-theme>
    <Teleport to="body">
      <div
        v-if="loading"
        style="position: fixed; top: 0px; left: 0px; right: 0px; bottom: 0px; z-index: var(--lfx-z-index-loader)"
      >
        <capitec-loading-indicator style="z-index: var(--lfx-z-index-loader)" />
      </div>
    </Teleport>
    <lfx-layout></lfx-layout>
  </lfx-theme>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import LfxTheme from './components/theme/LfxTheme.vue';
import LfxLayout from './components/layout/LfxLayout.vue';

require('packages/ocwp-components/dist/templates/web-app-core.css');

export default {
  components: {
    LfxTheme,
    LfxLayout,
  },
  created() {
    this.window_size({ width: window.innerWidth, height: window.innerHeight });
    window.addEventListener('resize', this.onWindowResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.onWindowResize);
  },
  errorCaptured(err) {
    console.error('CAUGHT ERROR', err.message);
    // this.$router.replace({ name: 'todo' }); // Note: this causes issues with the max call stack size exceed on modalButtons
  },
  computed: {
    ...mapState(['loading']),
  },
  methods: {
    ...mapActions(['window_size']),
    onWindowResize(evt) {
      let width = evt.target.innerWidth;
      let height = evt.target.innerHeight;
      this.window_size({ width, height });
    },
  },
};
</script>

<style>

body {
  background-color: var(--theme-app-background-color, #f4f4f4);
  overflow: hidden;
}

h1 {
  font-size: 1.2em;
}

:root {
  --lfx-z-index-top-elements: 10000000;
  --lfx-z-index-loader: calc(var(--lfx-z-index-top-elements) + 110);
  --lfx-z-index-toast: calc(var(--lfx-z-index-top-elements) + 100);
  --lfx-z-index-otp: calc(var(--lfx-z-index-top-elements) + 90);
  --ldb-z-index-modal: calc(var(--lfx-z-index-top-elements) + 80);
  --lfx-z-index-popup: calc(var(--lfx-z-index-top-elements) + 70);
  --lfx-z-index-menu: calc(var(--lfx-z-index-top-elements) + 60);
  --lfx-z-index-notifications: calc(var(--lfx-z-index-top-elements) + 50);

  --theme-button-padding-left:10px;
  --theme-button-padding-right:10px;
  --theme-menu-item-min-height:46px;
}

:root {
  --lfx-header-height: 120px;
  --lfx-form-header-height: 100px;
  --lfx-form-spacing: 50px;
  --lfx-total-header-spacing: calc(var(--lfx-form-header-height) + var(--lfx-header-height) + var(--lfx-form-spacing));
  --lfx-form-container-height: calc(100vh - var(--lfx-total-header-spacing) - 10px);
  --theme-text-select: none;
  --theme-titlebar-shadow: none;
  --theme-rate-down: var(--theme-color-status--error);
  --them-rate-up: var(--theme-color-status--success);
  --theme-container-padding-left: 0px;
  --theme-container-padding-right: 0px;
  --theme-container-padding-top: 0px;
  --theme-container-padding-bottom: 0px;
  --theme-input-padding-top:4px;
}

capitec-label[type='title'] {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

div#app {
  overflow: hidden;
}

</style>
