//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2022 LandoByte (Pty) Ltd.
//  File        : LfxPaymentMessageLogDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Payment Portfolio Message Log record for LandoByte TS systems
//  Created     : 12 February 2022 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import { intermediaryFieldViewGuard } from '../sharedUtils/LdbDefinitionUtils';
import type { LdbDefinition } from '../definitions/LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------

export type LfxPaymentMessageLogStatus = 'active' | 'disabled';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxPaymentMessageLogInt {
  id?: string;

  transactionFileId?: number;
  paymentId?: number;
  portfolioId?: number;
  accountId?: number;
  intermediaryId?: number;
  branchId?: number;

  integrationId?: string;
  direction?: 'inward' | 'outward';
  category?: string;
  type?: string;
  status?: string;
  message?: string;
  landobyteAction?: string;

  actingUserEmail?: string;
  actingUserId?: number;
  timeStamp?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxPaymentMessageLogDef: LdbDefinition = {
  title: 'Payment Portfolio Message',
  table: 'LfxPaymentMessageLog',
  collectionPath: '/payment/{{paymentId}}/messageLog',
  database: 'dynamo',
  pagingType: 'backEnd',
  view: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
  indexes: ['transactionFileId', 'paymentId', 'portfolioId'],
  groups: {
    system: { priority: 1, label: 'System' },
    message: { priority: 2, label: 'Message' }
  },
  fields: {
    id: {
      label: 'Local Action',
      datatype: { id: { descriptionField: 'landobyteAction', type: 'text' } },
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
      group: 'system',
    },
    transactionFileId: {
      label: 'Transaction File',
      datatype: {
        foreignKey: {
          collection: 'fk_transaction_files',
          linkTable: 'LfxTransactionFile',
          linkField: 'id',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },
    paymentId: {
      label: 'Payment',
      datatype: {
        foreignKey: {
          collection: 'fk_payments',
          linkTable: 'LfxPayment',
          linkField: 'id',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },
    portfolioId: {
      label: 'Portfolio',
      datatype: {
        foreignKey: {
          collection: 'fk_payment_portfolios',
          linkTable: 'LfxPaymentPortfolio',
          linkField: 'id',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },
    accountId: {
      label: 'Account',
      datatype: {
        foreignKey: {
          collection: 'fk_accounts',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },
    intermediaryId: {
      label: 'Intermediary',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediaries',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: intermediaryFieldViewGuard, list: false, delete: false, csv: false },
      group: 'system',
    },
    branchId: {
      label: 'Branch',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediary_branches',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },
    integrationId: {
      label: 'Integration ID',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'message',
    },
    direction: {
      label: 'Direction',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'inward', name: 'Inward' },
            { id: 'outward', name: 'Outward' },
          ],
        },
      },
      default: 'outward',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'message',
    },
    category: {
      label: 'Category',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'message',
    },
    type: {
      label: 'Type',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'message',
    },
    status: {
      label: 'Status',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'message',
    },
    message: {
      label: 'Message',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'message',
    },

    landobyteAction: {
      label: 'Local Action',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'message',
    },
    actingUserEmail: {
      label: 'Acting User Email',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'message',
    },

    actingUserId: {
      label: 'Acting User',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'message',
    },

    timeStamp: {
      label: 'Timestamp',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'message',
    },
  },
};
