<template>
  <section id="login-content">
    <capitec-loading-indicator />
    <capitec-page-header  class="login-header" label="Forex"> </capitec-page-header>
    <capitec-card  class="login-form">
      <!-- {{mode}} -->
      <capitec-title-bar v-if="!refreshingExistingSession" :label="`Two Factor Authentication`" />
      <capitec-title-bar v-else :label="`Loading`" />

      <!-- <capitec-spacer> </capitec-spacer> -->
      <div class="login-instruction">

      <capitec-label v-if="!refreshingExistingSession" type="subtitle" label="Follow login instructions on your mobile phone." />
      <capitec-label v-else type="subtitle" label="Loading data..." />
      
      </div>
      
      <capitec-image v-if="refreshingExistingSession" src="/platform/illustrations/processing.svg" />
      <capitec-image v-else src="/platform/illustrations/authenticate.svg" />
            
    </capitec-card>
    
  </section>
  
</template>

<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

export default {
  components: {
    
  },
  props: {  
    
  },
  data() {
    return {
    }
  },
  created() {
    
  },
  mounted() {
    
  },
  computed:{
    ...mapGetters([]),
  	...mapState(['lfx_auth']),
    refreshingExistingSession() {
      let res = false;
      if (this.lfx_auth !== undefined)  {
        res = (this.lfx_auth?.isValidSession === true);
      }
      return res;
    }
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([])
  }
 }
</script>
<style>

	div.login-instruction{
    padding:30px;
  }

</style>


