import VueStore from 'vue-class-store';

import { LdbDbObject, LdbDefinition } from '@/definitions/LdbInterfaces';
import { LfxIncomingReportInt, LfxIncomingReportDef } from '@/definitions/LfxIncomingReportDef';
import { LfxModel, LfxDefinition } from './LfxModel';

//@ts-ignore
import { http_patch, http_get, http_put } from '@/lfx_rest';

@VueStore
//@ts-ignore
class LfxIncomingReportMdl extends LfxDefinition {
  //@ts-ignore
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
//@ts-ignore
export class LfxIncomingReport extends LfxModel {
  //@ts-ignore
  constructor(data, state) {
    super({definition_name:'incomingReport',...data}, state);
    //@ts-ignore
    
  }
  get displayFKLink() {
    return false
  }
  get downloadButtonParams() {
    //@ts-expect-error
    return {accountId:this.accountId,incomingReportId:this.id}
  }
}

//@ts-ignore
export function Definition(context, name: string) {
  return new LfxIncomingReportMdl(LfxIncomingReportDef, LfxIncomingReport, context, name, {});
}
