//@ts-nocheck

import {getDefinition} from './db_definitions_store';
import {db_interface_firestore} from '../js'; // THIS IS A BUG, THE LOADING DB SHOULD BE CONFIGURED, FIRESTORE CANNOT BE ASSUMED... !!!
import {do_db_load_document_collections} from './db_state';
import pluralize from 'pluralize';

let ROOT = {};
export let root = function(state) {return ROOT};

export function db_user_authenticated(context,user) {
  let rootDefinition = getDefinition('root');
  context.state.is_authenticated = true;
  let on_user_success = function(db_user) {
    let documents   = rootDefinition.documents;
    let collections = rootDefinition.collections;
    ROOT = new rootDefinition.class(db_user,context);
    context.state.ROOT = ROOT;
    do_db_load_document_collections(context,db_user,'load',documents,collections);
  } 
  let user_collection = rootDefinition.user_collection ?? '/users';
  let user_document_name = pluralize.singular(user_collection);
  context.state.active[user_document_name] = user.uid;
  context.state.user_collection = user_collection;

  let user_id = user.uid;
  let update_user = {};
  update_user.name            = user.displayName;
  update_user.email           = user.email;
  update_user.email_verified  = user.emailVerified;
  update_user.uid             = user.uid;
  update_user.photo_url       = user.photoURL;
  update_user.id              = user.uid;
  update_user.collection_path = user_collection;

  // db_interface_firestore.db_update_user(context,update_user);
  // db_interface_firestore.db_get_document(context,{collection_path:user_collection,id:user_id,options:{on_success:on_user_success,auto_load:false}});
}

