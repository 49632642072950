<template>
  <LDBModal result="info" :header="modalHeader" :size="modalSize">
    <template #body>
      <capitec-stepper
        v-if="showStepper"
        layout="horizontal"
        :steps="steps"
        :active-step-number="activeStepNumber"
        @step-click="onStepClick"
      >
      </capitec-stepper>

      <template v-if="activeStepNumber === 1">
        <capitec-spacer></capitec-spacer>
        <capitec-select label="Intermediary / Branch"></capitec-select>
        <capitec-spacer></capitec-spacer>
        <capitec-select label="Client" type="search"></capitec-select>
      </template>

      <template v-if="activeStepNumber === 2">
        <h1>Transaction Type</h1>

        <div class="flex-container">
          <div class="flex-item">
            <p><strong>Select Transaction Type:</strong></p>

            <LDBField
              :key="transactionTypeFieldKey"
              :field="transactionTypeField"
              view="edit"
              :fieldname="transactionTypeField.name"
              :definition="undefined"
              :record="transactionTypeRecord"
              :value="transactionTypeRecord.transactionType"
              @change="on_change"
            />
          </div>

          <div class="flex-item">
            <p><strong>OR Help Me Choose:</strong></p>

            <LDBField
              :key="sourceOfFundsFieldKey"
              :field="sourceOfFundsField"
              view="edit"
              :fieldname="sourceOfFundsField.name"
              :definition="undefined"
              :record="transactionTypeRecord"
              @change="on_change"
            />
            <LDBField
              :key="exchangeCurrenciesFieldKey"
              :field="exchangeCurrenciesField"
              view="edit"
              :fieldname="exchangeCurrenciesField.name"
              :definition="undefined"
              :record="transactionTypeRecord"
              @change="on_change"
            />
            <LDBField
              :key="destinationOfFundsFieldKey"
              :field="destinationOfFundsField"
              view="edit"
              :fieldname="destinationOfFundsField.name"
              :definition="undefined"
              :record="transactionTypeRecord"
              @change="on_change"
            />
          </div>
        </div>

        <p class="info">{{ infoText }}</p>
      </template>
    </template>

    <template #footer>
      <capitec-spacer />
      <LDBButton
        v-for="button in rightButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :enabledGuards="button.enabledGuards"
        :type="button.type"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

export default {
  name: 'LfxNewTransactionPicker',
  components: {},
  props: {},
  data() {
    return {
      activeStepNumber: 1,
      transactionTypeRecord: {
        sourceOfFunds: null,
        exchangeCurrencies: null,
        destinationOfFunds: null,
        transactionType: null,
      },
      userPickedTransactionType: true,
    };
  },
  created() {
    this.selectFirstStep();
    this.prepopulateTransactionType();
  },
  mounted() {},
  errorCaptured(err) {
    console.warn('Handled error:', err);
    return false;
  },
  computed: {
    ...mapGetters(['permissions', 'tradeSettings', 'isWithinTradingHours', 'isTodayWorkingDay', 'isTradingEnabled']),
    ...mapState(['transaction', 'account', 'authUser', 'dbIntermediarySettings']),

    // ----- Modal -----

    modalHeader() {
      return 'New Transaction Picker';
    },
    modalSize() {
      return 'large';
    },

    // ----- Stepper -----

    showStepper() {
      return this.isClientMetaRoute || this.isAuthUserClient ? false : true;
    },

    steps() {
      let steps = [
        { index: 1, title: 'Client', guards: true },
        { index: 2, title: 'Transaction Type', guards: true },
      ];
      return steps.filter((step) => step.guards); // TODO
    },

    // ----- Client record -----

    clientRouteId() {
      // return +this.$route.params?.accountId || this.transaction?.[this.$route.params?.transactionId]?.accountId;
      return +this.$route.params?.accountId;
    },
    isClientMetaRoute() {
      return this.clientRouteId ? true : false;
    },

    isAuthUserClient() {
      return this.authUser?.accountLevel === 'client';
    },
    authUserAccountId() {
      return +this.authUser?.accountId;
    },

    clientId() {
      return this.isClientMetaRoute
        ? this.clientRouteId
        : this.isAuthUserClient
        ? this.authUserAccountId
        : this.searchedAccountRecord?.id;
    },
    clientRecord() {
      return this.account?.[this.clientId];
    },

    hasOwnActive_bankAccount() {
      return this.clientRecord?.hasOwnActive_bankAccount;
    },
    hasBankAccountsForTransferDeals() {
      return this.clientRecord?.hasBankAccountsForTransferDeals;
    },
    hasBeneficiariesActive_bankAccounts() {
      if (this.intermediaryIsBranchClients) {
        return true
      }
      // return this.clientRecord?.hasBeneficiariesActive_bankAccounts;
      const bankAccounts = this.clientRecord?.filter_bankAccounts(this.clientRecord?.beneficiariesActive_bankAccounts, {
        currency: undefined,
        includeAuthUserBankAccounts: true,
        bankAccountTypes: 'beneficiary',
        status: 'active',
      });
      return Object.keys(bankAccounts).length > 0;
    },

    // ----- Intermediary -----

    intermediaryIsBranchClients() {
      return this.dbIntermediarySettings.bankDirectClients && !this.dbIntermediarySettings.allowOnboardingWithIncompleteData
    },

    // ----- User -----

    hasPermissionToBookDeals() {
      return this.permissions?.bookDeal;
    },
    hasPermissionToMakePayments() {
      return this.permissions?.bookTransfer;
    },

    // ----- Transaction record -----

    transactionId() {
      return this.$route.params?.transactionId;
    },
    transactionRecord() {
      return this.transaction?.[this.transactionId];
    },

    // ----- Transaction fields -----

    sourceOfFundsField() {
      let field = {
        name: 'sourceOfFunds',
        label: 'Source of Funds',
        datatype: {
          option: {
            optionType: 'text',
            options: [
              { id: 'client', name: "Client's Bank Account" },
              { id: 'inwardSwift', name: 'Inward SWIFT' },
            ],
          },
        },
        default: null,
        displayType: {
          datatype: 'option',
          display: 'radio',
        },
      };
      return field;
    },
    sourceOfFundsFieldKey() {
      return `sourceOfFunds-${this.transactionTypeRecord.transactionType}`;
    },
    exchangeCurrenciesField() {
      let field = {
        name: 'exchangeCurrencies',
        label: 'Exchange Currencies?',
        datatype: {
          option: {
            optionType: 'text',
            options: [
              { id: 'yes', name: 'Yes - Book a Deal' },
              { id: 'no', name: 'No - Make a Payment' },
            ],
          },
        },
        default: null,
        displayType: {
          datatype: 'option',
          display: 'radio',
        },
      };
      return field;
    },
    exchangeCurrenciesFieldKey() {
      return `exchangeCurrencies-${this.transactionTypeRecord.transactionType}`;
    },
    destinationOfFundsField() {
      let field = {
        name: 'destinationOfFunds',
        label: 'Destination of Funds',
        datatype: {
          option: {
            optionType: 'text',
            options: [
              {
                id: 'beneficiary',
                name:
                  this.transactionTypeRecord.sourceOfFunds === 'inwardSwift' &&
                  this.transactionTypeRecord.exchangeCurrencies === 'no'
                    ? "Beneficiary's Bank Account - N/A"
                    : "Beneficiary's Bank Account",
              },
              {
                id: 'client',
                name:
                  this.transactionTypeRecord.sourceOfFunds === 'client' &&
                  this.transactionTypeRecord.exchangeCurrencies === 'no'
                    ? "Client's Bank Account - N/A"
                    : "Client's Bank Account",
              },
            ],
          },
        },
        default: null,
        displayType: {
          datatype: 'option',
          display: 'radio',
        },
      };
      return field;
    },
    destinationOfFundsFieldKey() {
      return `destinationOfFunds-${this.transactionTypeRecord.transactionType}`;
    },
    transactionTypeField() {
      let field = {
        name: 'transactionType',
        label: 'Transaction Type',
        datatype: {
          option: {
            optionType: 'text',
            options: [
              {
                id: 'outwardDeal',
                name: this.outwardDealLabel,
                get enableGuards() {
                  return (
                    (this.userPickedTransactionType || this.enableGuards_basedOnChoices) &&
                    this.enableGuards_basedOnOther
                  );
                },
                userPickedTransactionType: this.userPickedTransactionType,
                enableGuards_basedOnChoices:
                  this.transactionTypeRecord.sourceOfFunds !== 'inwardSwift' &&
                  this.transactionTypeRecord.exchangeCurrencies !== 'no' &&
                  this.transactionTypeRecord.destinationOfFunds !== 'client',
                enableGuards_basedOnOther:
                  this.hasPermissionToBookDeals &&
                  this.tradeSettings.allowOutwardDeals &&
                  this.hasOwnActive_bankAccount &&
                  this.isWithinTradingHours &&
                  this.isTodayWorkingDay &&
                  this.isTradingEnabled,
              },
              {
                id: 'transferDeal',
                name: this.transferDealLabel,
                get enableGuards() {
                  return (
                    (this.userPickedTransactionType || this.enableGuards_basedOnChoices) &&
                    this.enableGuards_basedOnOther
                  );
                },
                userPickedTransactionType: this.userPickedTransactionType,
                enableGuards_basedOnChoices:
                  this.transactionTypeRecord.sourceOfFunds !== 'inwardSwift' &&
                  this.transactionTypeRecord.exchangeCurrencies !== 'no' &&
                  this.transactionTypeRecord.destinationOfFunds !== 'beneficiary',
                enableGuards_basedOnOther:
                  this.hasPermissionToBookDeals &&
                  this.tradeSettings.allowTransferDeals &&
                  this.hasBankAccountsForTransferDeals &&
                  this.isWithinTradingHours &&
                  this.isTodayWorkingDay &&
                  this.isTradingEnabled,
              },
              {
                id: 'inwardDeal',
                name: this.inwardDealLabel,
                get enableGuards() {
                  return (
                    (this.userPickedTransactionType || this.enableGuards_basedOnChoices) &&
                    this.enableGuards_basedOnOther
                  );
                },
                userPickedTransactionType: this.userPickedTransactionType,
                enableGuards_basedOnChoices:
                  this.transactionTypeRecord.sourceOfFunds !== 'client' &&
                  this.transactionTypeRecord.exchangeCurrencies !== 'no',
                enableGuards_basedOnOther:
                  this.hasPermissionToBookDeals &&
                  this.tradeSettings.allowInwardDeals &&
                  this.hasOwnActive_bankAccount &&
                  this.isWithinTradingHours &&
                  this.isTodayWorkingDay &&
                  this.isTradingEnabled,
              },
              {
                id: 'outwardPayment',
                name: this.outwardPaymentLabel,
                get enableGuards() {
                  return (
                    (this.userPickedTransactionType || this.enableGuards_basedOnChoices) &&
                    this.enableGuards_basedOnOther
                  );
                },
                userPickedTransactionType: this.userPickedTransactionType,
                enableGuards_basedOnChoices:
                  this.transactionTypeRecord.sourceOfFunds !== 'inwardSwift' &&
                  this.transactionTypeRecord.exchangeCurrencies !== 'yes' &&
                  this.transactionTypeRecord.destinationOfFunds !== 'client',
                enableGuards_basedOnOther:
                  this.hasPermissionToMakePayments &&
                  this.tradeSettings.allowOutwardPayments &&
                  this.hasOwnActive_bankAccount &&
                  this.hasBeneficiariesActive_bankAccounts,
              },
              {
                id: 'inwardPayment',
                name: this.inwardPaymentLabel,
                get enableGuards() {
                  return (
                    (this.userPickedTransactionType || this.enableGuards_basedOnChoices) &&
                    this.enableGuards_basedOnOther
                  );
                },
                userPickedTransactionType: this.userPickedTransactionType,
                enableGuards_basedOnChoices:
                  this.transactionTypeRecord.sourceOfFunds !== 'client' &&
                  this.transactionTypeRecord.exchangeCurrencies !== 'yes' &&
                  this.transactionTypeRecord.destinationOfFunds !== 'beneficiary',
                enableGuards_basedOnOther:
                  this.hasPermissionToMakePayments &&
                  this.tradeSettings.allowInwardPayments &&
                  this.hasOwnActive_bankAccount,
              },
            ],
          },
        },
        default: null,
        displayType: {
          datatype: 'option',
          display: 'radio',
        },
      };
      return field;
    },
    transactionTypeFieldKey() {
      return `transactionType-${this.transactionTypeRecord.sourceOfFunds}-${this.transactionTypeRecord.exchangeCurrencies}-${this.transactionTypeRecord.destinationOfFunds}`;
    },
    availableTransactionTypes() {
      return this.transactionTypeField.datatype.option.options.filter((opt) => opt.enableGuards);
    },
    availableTransactionTypes_basedOnChoices() {
      return this.transactionTypeField.datatype.option.options.filter((opt) => opt.enableGuards_basedOnChoices);
    },

    outwardDealLabel() {
      let label = 'Outward Deal';
      if (!this.hasPermissionToBookDeals) {
        label = 'Outward Deal - No Permission';
      } else if (!this.tradeSettings.allowOutwardDeals) {
        label = 'Outward Deal - Not Allowed by Settings';
      } else if (!this.hasOwnActive_bankAccount) {
        label = 'Outward Deal - No Bank Account';
      } else if (!this.isWithinTradingHours) {
        label = 'Outward Deal - Outside Trading Hours';
      } else if (!this.isTodayWorkingDay) {
        label = 'Outward Deal - Not Trading Day';
      } else if (!this.isTradingEnabled) {
        label = 'Outward Deal - Trading Disabled';
      }
      return label;
    },
    transferDealLabel() {
      let label = 'Transfer Deal';
      if (!this.hasPermissionToBookDeals) {
        label = 'Transfer Deal - No Permission';
      } else if (!this.tradeSettings.allowTransferDeals) {
        label = 'Transfer Deal - Not Allowed by Settings';
      } else if (!this.hasBankAccountsForTransferDeals) {
        label = 'Transfer Deal - No Bank Accounts';
      } else if (!this.isWithinTradingHours) {
        label = 'Transfer Deal - Outside Trading Hours';
      } else if (!this.isTodayWorkingDay) {
        label = 'Transfer Deal - Not Trading Day';
      } else if (!this.isTradingEnabled) {
        label = 'Transfer Deal - Trading Disabled';
      }
      return label;
    },
    inwardDealLabel() {
      let label = 'Inward Deal';
      if (!this.hasPermissionToBookDeals) {
        label = 'Inward Deal - No Permission';
      } else if (!this.tradeSettings.allowInwardDeals) {
        label = 'Inward Deal - Not Allowed by Settings';
      } else if (!this.hasOwnActive_bankAccount) {
        label = 'Inward Deal - No Bank Account';
      } else if (!this.isWithinTradingHours) {
        label = 'Inward Deal - Outside Trading Hours';
      } else if (!this.isTodayWorkingDay) {
        label = 'Inward Deal - Not Trading Day';
      } else if (!this.isTradingEnabled) {
        label = 'Inward Deal - Trading Disabled';
      }
      return label;
    },
    outwardPaymentLabel() {
      let label = 'Outward Payment';
      if (!this.hasPermissionToMakePayments) {
        label = 'Outward Payment - No Permission';
      } else if (!this.tradeSettings.allowOutwardPayments) {
        label = 'Outward Payment - Not Allowed by Settings';
      } else if (!this.hasOwnActive_bankAccount) {
        label = 'Outward Payment - No Bank Account';
      } else if (!this.hasBeneficiariesActive_bankAccounts) {
        label = 'Outward Payment - No Beneficiary';
      }
      return label;
    },
    inwardPaymentLabel() {
      let label = 'Inward Payment';
      if (!this.hasPermissionToMakePayments) {
        label = 'Inward Payment - No Permission';
      } else if (!this.tradeSettings.allowTransferDeals) {
        label = 'Inward Payment - Not Allowed by Settings';
      } else if (!this.hasOwnActive_bankAccount) {
        label = 'Inward Payment - No Bank Account';
      }
      return label;
    },

    infoText() {
      return (
        this.impossibleTransactionText ||
        this.noPermissionText ||
        this.notAllowedByTradeSettingsText ||
        this.noBankAccountText ||
        this.outsideTradingHoursText ||
        this.notTradingDayText ||
        this.systemTradingDisabledText
      );
    },

    impossibleTransactionText() {
      let message = undefined;
      if (
        this.transactionTypeRecord.sourceOfFunds === 'client' &&
        this.transactionTypeRecord.exchangeCurrencies === 'no' &&
        this.transactionTypeRecord.destinationOfFunds === 'client'
      ) {
        message =
          'This transaction type is not available through the Forex System.  Please use Internet Banking to transfer funds between Client bank accounts when not exchanging currencies.';
      }
      if (
        this.transactionTypeRecord.sourceOfFunds === 'inwardSwift' &&
        this.transactionTypeRecord.exchangeCurrencies === 'no' &&
        this.transactionTypeRecord.destinationOfFunds === 'beneficiary'
      ) {
        message =
          "This transaction type is not available through the Forex System as a one-step transaction.  Please transfer funds to the Client's bank account, then make a separate payment to transfer the funds to a Beneficiary.";
      }
      return message;
    },

    noPermissionText() {
      let message = undefined;
      if (
        ['outwardDeal', 'transferDeal', 'inwardDeal'].includes(this.transactionTypeRecord.transactionType) &&
        !this.hasPermissionToBookDeals
      ) {
        message = 'This transaction type requires a deal, but you do not have the required permission to book a deal.';
      } else if (
        ['outwardPayment', 'inwardPayment'].includes(this.transactionTypeRecord.transactionType) &&
        !this.hasPermissionToMakePayments
      ) {
        message =
          'This transaction type requires making a payment, but you do not have the required permission to make a payment without booking a deal.';
      }
      return message;
    },

    notAllowedByTradeSettingsText() {
      let message = undefined;
      switch (this.transactionTypeRecord.transactionType) {
        case 'outwardDeal':
          if (!this.tradeSettings.allowOutwardDeals) {
            message = 'Your trade settings prevent you from selecting an outward deal.';
          }
          break;
        case 'transferDeal':
          if (!this.tradeSettings.allowTransferDeals) {
            message = 'Your trade settings prevent you from selecting a transfer deal.';
          }
          break;
        case 'inwardDeal':
          if (!this.tradeSettings.allowInwardDeals) {
            message = 'Your trade settings prevent you from selecting an inward deal.';
          }
          break;
        case 'outwardPayment':
          if (!this.tradeSettings.allowOutwardPayments) {
            message = 'Your trade settings prevent you from selecting a transfer deal.';
          }
          break;
        case 'inwardPayment':
          if (!this.tradeSettings.allowInwardPayments) {
            message = 'Your trade settings prevent you from selecting an inward payment.';
          }
          break;
      }
      return message;
    },

    noBankAccountText() {
      let message = '';
      if (
        ['outwardDeal', 'inwardDeal', 'outwardPayment', 'inwardPayment'].includes(
          this.transactionTypeRecord.transactionType
        ) &&
        !this.hasOwnActive_bankAccount
      ) {
        message = 'The client must have at least one active bank account to transact.';
      } else if (
        this.transactionTypeRecord.transactionType === 'transferDeal' &&
        !this.hasBankAccountsForTransferDeals
      ) {
        message =
          'The client must have at least one active ZAR account and another active account in a foreign currency.';
      } else if (
        this.transactionTypeRecord.transactionType === 'outwardPayment' &&
        !this.hasBeneficiariesActive_bankAccounts
      ) {
        message = 'The client must have at least one active beneficiary.';
      }
      return message;
    },

    outsideTradingHoursText() {
      return ['outwardDeal', 'transferDeal', 'inwardDeal'].includes(this.transactionTypeRecord.transactionType) &&
        !this.isWithinTradingHours
        ? 'Not availabe outside Trading Hours.' // TODO - show trading hours
        : '';
    },
    notTradingDayText() {
      return ['outwardDeal', 'transferDeal', 'inwardDeal'].includes(this.transactionTypeRecord.transactionType) &&
        !this.isTodayWorkingDay
        ? 'Today is not a Trading Day.'
        : '';
    },
    systemTradingDisabledText() {
      return ['outwardDeal', 'transferDeal', 'inwardDeal'].includes(this.transactionTypeRecord.transactionType) &&
        !this.isTradingEnabled
        ? 'Trading has been Disabled.' // TODO - show reason
        : '';
    },

    // ----- Form buttons -----

    backButton() {
      let self = this;
      let button = {
        name: 'previousStep',
        label: 'Back',
        type: 'clear',
        guards: true, //TODO
        clickEvent() {
          self.onBackClick();
        },
      };
      return button;
    },
    cancelButton() {
      let self = this;
      let button = {
        name: 'cancelNewTransaction',
        label: 'Cancel',
        type: '',
        guards: true,
        clickEvent() {
          self.$router.go(-1);
        },
      };
      return button;
    },
    nextButton() {
      let self = this;
      let button = {
        name: 'nextStep',
        label: 'Next',
        type: 'primary',
        guards: true, //TODO
        clickEvent() {
          self.onNextClick();
        },
      };
      return button;
    },
    rightButtons() {
      let self = this;
      let allButtons = {
        1: [this.cancelButton, this.nextButton],
        2: [
          {
            name: 'reset',
            label: 'Reset',
            type: 'clear',
            guards: false, // Note - see method
            action: 'click',
            clickEvent() {
              self.resetTransactionTypeRecord();
            },
          },
          this.cancelButton,
          {
            name: 'newTransaction',
            label: this.newTransactionButtonLabel,
            type: 'primary',
            guards: true,
            enabledGuards:
              !!this.transactionTypeRecord.transactionType &&
              this.availableTransactionTypes.some((opt) => opt.id === this.transactionTypeRecord.transactionType),
            clickEvent() {
              let newRoute = self.newTransactionRoute;
              self.$router.replace(newRoute);
            },
          },
        ],
      };
      return allButtons?.[this.activeStepNumber].filter((button) => button.guards) !== undefined
        ? allButtons?.[this.activeStepNumber].filter((button) => button.guards)
        : [];
    },
    newTransactionButtonLabel() {
      switch (this.transactionTypeRecord.transactionType) {
        case 'outwardDeal':
          return 'Book Outward Deal';
        case 'transferDeal':
          return 'Book Transfer Deal';
        case 'inwardDeal':
          return 'Book Inward Deal';
        case 'outwardPayment':
          return 'Make Outward Payment';
        case 'inwardPayment':
          return 'Receive Inward Payment';
        default:
          return 'New Transaction';
      }
    },
    newTransactionRoute() {
      switch (this.transactionTypeRecord.transactionType) {
        case 'outwardDeal':
          return { name: 'newTransactionOutwardDealCapture', params: { accountId: this.clientId } };
        case 'transferDeal':
          return { name: 'newTransactionTransferDealCapture', params: { accountId: this.clientId } };
        case 'inwardDeal':
          return { name: 'newTransactionInwardDealCapture', params: { accountId: this.clientId } };
        case 'outwardPayment':
          return { name: 'newTransactionOutwardPaymentCapture', params: { accountId: this.clientId } };
        case 'inwardPayment':
          return { name: 'newTransactionInwardPaymentCapture', params: { accountId: this.clientId } };
        default:
          return { name: 'todo' };
      }
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Fields -----

    on_change({ field, value }) {
      this.transactionTypeRecord[field] = value;
      if (field !== 'transactionType') {
        // this.userPickedTransactionType = false;
        this.setTransactionTypeIfPossible();
      } else {
        this.setOptionsForTransactionType();
      }
      // console.log('TRANSACTION TYPE RECORD', this.transactionTypeRecord);
    },

    setTransactionTypeIfPossible() {
      if (this.availableTransactionTypes_basedOnChoices.length == 1) {
        this.transactionTypeRecord.transactionType = this.availableTransactionTypes_basedOnChoices[0].id;
      } else if (this.impossibleTransactionText) {
        this.transactionTypeRecord.transactionType = null;
      }
    },

    setOptionsForTransactionType() {
      switch (this.transactionTypeRecord.transactionType) {
        case 'outwardDeal':
          this.transactionTypeRecord.sourceOfFunds = 'client';
          this.transactionTypeRecord.exchangeCurrencies = 'yes';
          this.transactionTypeRecord.destinationOfFunds = 'beneficiary';
          break;
        case 'transferDeal':
          this.transactionTypeRecord.sourceOfFunds = 'client';
          this.transactionTypeRecord.exchangeCurrencies = 'yes';
          this.transactionTypeRecord.destinationOfFunds = 'client';
          break;
        case 'inwardDeal':
          this.transactionTypeRecord.sourceOfFunds = 'inwardSwift';
          this.transactionTypeRecord.exchangeCurrencies = 'yes';
          break;
        case 'outwardPayment':
          this.transactionTypeRecord.sourceOfFunds = 'client';
          this.transactionTypeRecord.exchangeCurrencies = 'no';
          this.transactionTypeRecord.destinationOfFunds = 'beneficiary';
          break;
        case 'inwardPayment':
          this.transactionTypeRecord.sourceOfFunds = 'inwardSwift';
          this.transactionTypeRecord.exchangeCurrencies = 'no';
          this.transactionTypeRecord.destinationOfFunds = 'client';
          break;
      }
    },

    prepopulateTransactionType() {
      if (this.transactionId) {
        this.transactionTypeRecord.transactionType = this.transactionRecord.transactionType;
        this.setOptionsForTransactionType();
      }
    },

    // resetTransactionTypeRecord() {
    //   // Note: this causes weird effect whereby old option is not clickable - capitec component probably not updated with new null value - avr
    //   this.transactionTypeRecord.sourceOfFunds = null;
    //   this.transactionTypeRecord.exchangeCurrencies = null;
    //   this.transactionTypeRecord.destinationOfFunds = null;
    //   this.transactionTypeRecord.transactionType = null;
    // },

    // ----- Stepper -----

    selectFirstStep() {
      let firstStep = 1;
      // if (this.isClientMetaRoute) {
      if (!this.showStepper) {
        firstStep = 2;
      }
      this.activeStepNumber = firstStep;
    },

    onBackClick() {
      let newStepNumber = this.activeStepNumber - 1;
      let evt = { detail: { stepNumber: newStepNumber } };
      this.onStepClick(evt);
    },
    onNextClick() {
      let newStepNumber = this.activeStepNumber + 1;
      let evt = { detail: { stepNumber: newStepNumber } };
      this.onStepClick(evt);
    },

    onStepClick(evt) {
      this.activeStepNumber = evt.detail.stepNumber;
    },
  },
};
</script>

<style scoped>
div.flex-container {
  /* border: 1px dotted green; */
  width: 40vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  /* background-color: rgb(242, 241, 241); */
}
div.flex-item {
  /* border: 1px dotted blue; */
  margin: 10px;
  padding: 10px;
  flex-grow: 1;
  flex-basis: 250px;
  /* background-color: white; */
}
p.info {
  padding: 0px 0px 0px 30px;
  width: 30vw;
}
</style>
