<template>
  <div class="lfx-widget-container applications-require-attention"  :style="spanStyle" :class="dynamicClass">
    <LfxWidgetHeader :header="`Client Applications (${recordCount})`" :subheader="dateRange" :link="allRecordsLink"/>
    <template v-if="recordCount > 0" >
      <LfxWidgetRow v-for="record in mostRecentRecords" 
          :key="record.id" 
          :title="`${record.clientName}`" 
          :subtitle="recordDate(record)"
          :avatar="record.clientName" 
          :avatarColor="avatarColor(record.id)" 
          :info="record.reference" 
          :link="recordLink(record)">
        <template #info>
           <LDBField
          :field="definition.indexed_fields.status"
          view="list"
          fieldname="status"
          :definition="definition"
          :record="record"
        />
        </template>
      </LfxWidgetRow>
       <LfxWidgetScroller
      :totalItems="items.length"
      :itemsPerPage="itemsPerPage"
      :currentPage="pageNumber"
      @on-page="onPage"
      />
    </template>
  </div>
</template>

<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";
import LfxWidgetHeader from './LfxWidgetHeader.vue';
import LfxWidgetRow from './LfxWidgetRow.vue';
import LfxWidgetScroller from './LfxWidgetScroller.vue';
import {colorFromId} from '@/landobyte_vue/ldb_utils.ts'

import {getDefinition} from '@/db-interface/db_definitions_store';

import lfx_widget_mixin from './lfx_widget_mixin';

export default {
  mixins:[lfx_widget_mixin],
  components: {
    LfxWidgetHeader,
    LfxWidgetRow,
    LfxWidgetScroller
  },
  props: {  
  },
  data() {
    return {
      itemsPerPage:4
    }
  },
  created() {
    
  },
  mounted() {
    
  },
  computed:{
    ...mapGetters([]),
  	...mapState(['account','authUser']),
    allRecordsLink() {
      return { name: '_applicationsRequireAttention' } 
    },
    definition() {
      return getDefinition('application');
    },
  	records() {
      return this.account
    },
    mostRecentRecords() {
      let res = [];
      let startItem = ( this.pageNumber -1 ) * this.itemsPerPage;
      for ( let r = 0 ; r < this.openRecords.length ; r ++  ) { 
        let record = this.openRecords[r];
        if (r >= startItem) {
          res.push(record);
        }
        if (res.length === this.itemsPerPage) {
          break;
        }
      }
      return res;
    },
    items() {
      return this.openRecords;
    },
    openRecords() {
      const req = [];
      let statusses;
      if ( this.authUser.accountLevel === 'bank') {
        statusses = ['applicationSubmitted','applicationInProgress']
      } else {
        statusses = ['awaitingDetailsCapture','readyForPortfolioSubmission','portfolioSubmitted','portfolioInProgress','portfolioSentBack','readyForApplicationSubmission','applicationSubmitted','applicationInProgress','applicationSentBack']
      } 
      
      for (const accountId in this.records) {
        const record = this.account[accountId];
        if (statusses.includes(record.currentApplicationStatus) && record.accountLevel === 'client' && record.visibility === 'visible') {
          if ((record.application || record.applications) && record.currentClientApplicationId && record.application[record.currentClientApplicationId] && record.application[record.currentClientApplicationId].visibility !== 'hidden') {
            req.push(record.application[record.currentClientApplicationId])
          }
        }
      };
      return req.sort( (a,b) => (a.requestedAt > b.requestedAt ? 1 : -1) )
    },
    recordCount() {
      return this.openRecords.length;
    },
    minDate() {
      if (this.openRecords.length > 0) {
        return this.openRecords[0]?.updatedAt?.split('T')[0];
      }
      return undefined
    },
    maxDate() {
      if (this.openRecords.length > 0) {
        return this.openRecords[ this.openRecords.length -1]?.updatedAt?.split('T')[0];
      }
      return undefined
    },
    dateRange() {
      if (this.minDate === this.maxDate) {
        return this.minDate;
      } 
      else 
      {
        return `${this.minDate} to ${this.maxDate}`;
      }
    }
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([]),
  	avatarColor(id) {
      let res = colorFromId(id);
      return res;
    },
    
    recordDate(record) {
      return `${record?.requestedAt?.split('T').join(' ').split('.')[0] || ''}`;
    },
  	recordLink(record) {
      return record.link;
    },

  }
 }
</script>
<style>

</style>


