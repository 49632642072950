//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2022 LandoByte (Pty) Ltd.
//  File        : LdbWebSocketsFe.ts
//  Author      : Bevan Timm
//  Description : This module handles all web socket connections for LandoByte Vue system
//  Created     : 7 March 2022 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import { ENV_VARIABLES } from '@/store/config_env';

import type { stateDef } from "@/store";
import type { Store } from "vuex";
import type { LdbGuard } from '@/definitions/LdbInterfaces';

import { handleRatesFeedMessage,initialiseRatesFeedWebsocket, onCloseRatesFeedWebsocket, onOpenRatesFeedWebsocket } from './LfxRatesFeedWebSocket';
import { handleEventsMessage, initialiseEventWebsocket } from './LdbEventsWebSocket';
import { DBGuard, evaluate_simple_guards } from '@/db-interface/db_interface_guard';

//----------------------------------------------------------------------------------------------------------------------

var doDebug = true;
const debug = (lable:string,value?:any) => {
  if (doDebug) {
    if (value !== undefined) {
      console.log(lable,value)
    } else {
      console.log(lable)
    }
  }
}

//----------------------------------------------------------------------------------------------------------------------

export type LdbWebSocketEvent = any; // TODO Type properly
export type LdbFeWebSocket = {
  name: string;
  path: string;
  guards: LdbGuard | LdbGuard[];
  action: undefined | string;
  messageHandler: (webSocket: LdbFeWebSocket, event: LdbWebSocketEvent, store:Store<stateDef>) => void;
  moduleInitialiser?: (webSocket: LdbFeWebSocket, store:Store<stateDef>) => void;
  moduleOnOpen?: (webSocket: LdbFeWebSocket, store:Store<stateDef>) => void;
  moduleOnClose?: (webSocket: LdbFeWebSocket, store:Store<stateDef>) => void;
  status: 'disconnected' | 'connecting' | 'connected' | 'error' | 'authFailure' | 'terminated';
  connection: undefined | WebSocket;
  connectionAttepts: number;
  connectedTimestamp?: Date;
  lastIncomingMessage?: Date;
};
export const webSockets: LdbFeWebSocket[] = [
  {
    name: 'Rates Feed',
    path: 'ratesFeed',
    guards: true,
    action: 'ratesFeedRetrieve',
    messageHandler: handleRatesFeedMessage,
    moduleInitialiser: initialiseRatesFeedWebsocket,
    moduleOnOpen: onOpenRatesFeedWebsocket,
    moduleOnClose: onCloseRatesFeedWebsocket,

    status: 'disconnected',
    connection: undefined,
    connectionAttepts: 0,
  },
  {
    name: 'Events',
    path: 'eventsfeed',
    guards: true,
    action: undefined,
    messageHandler: handleEventsMessage,
    moduleInitialiser: initialiseEventWebsocket,

    status: 'disconnected',
    connection: undefined,
    connectionAttepts: 0,
  },
];

const printWebocketStatus = async () => {
  for (const webSocket of webSockets) {
    debug("Web Socket  ",webSocket.name+": "+webSocket.status);
  }
  await new Promise((r) => setTimeout(r, 5 * 60 * 1000));
  printWebocketStatus();
};
printWebocketStatus();
//----------------------------------------------------------------------------------------------------------------------

export const initialiseWebSockets = (context:Store<stateDef>) => {
  for (const webSocket of webSockets) {
    var startWebsocket = true;
    if (webSocket.action && !context.getters.permissions[webSocket.action]) {
      startWebsocket = false;
    }
    if (!evaluate_simple_guards(webSocket.guards as DBGuard, {})) {
      startWebsocket = false;
    }
    if (startWebsocket) {
      connectWebSocket(context,webSocket);
      if (webSocket.moduleInitialiser) {
        webSocket.moduleInitialiser(webSocket,context);
      }
    }
  }
};

//----------------------------------------------------------------------------------------------------------------------

const connectWebSocket = async (context:any,webSocket: LdbFeWebSocket) => {
  if (webSocket.connection) {
    if (webSocket.status !== 'disconnected' && webSocket.status !== 'authFailure') {
      webSocket.connection.close(1000, 'terminate');
    }
    webSocket.connection = undefined;
  }
  webSocket.status = 'connecting';
  debug('Connecting Web Socket - ' + webSocket.name);
  webSocket.connectionAttepts++;
  const socketUrl = ENV_VARIABLES.VUE_RATES_FEED_SOCKET_URL + webSocket.path;
  const connection = new WebSocket(socketUrl);
  webSocket.connection = connection;
  connection.onclose = (event: CloseEvent) => {
    debug("Web Socket Closed - " + webSocket.name,event);
    if (webSocket.status !== 'authFailure' && event.reason !== 'terminate') {
      webSocket.status = 'disconnected';
      if (webSocket.moduleOnClose) {
        webSocket.moduleOnClose(webSocket,context);
      }
      tryReconnectWebSocket(context,webSocket);
    } else {
      webSocket.status = 'terminated';
    }
  };
  connection.onmessage = (event: LdbWebSocketEvent) => {
    webSocket.lastIncomingMessage = new Date();
    if (event.data === 'AUTH FAILED') {
      debug("Web Socket Auth Fail - " + webSocket.name,event);
      if (webSocket.moduleOnOpen) {
        webSocket.moduleOnOpen(webSocket,context);
      }
      webSocket.status = 'authFailure';
    }
    // console.log(webSocket.name+" - message",event)
    webSocket.messageHandler(webSocket, event, context);
  };
  connection.onopen = (event: LdbWebSocketEvent) => {
    debug("Authenticating Web Socket - " + webSocket.name);
    connection.send(
      `{"accessToken":"${context.getters.authHeaders.accessToken}","myUserId":"${context.getters.authHeaders.myUserId}","type":"auth"}`
    );
    webSocket.connectionAttepts = 0;
    webSocket.status = 'connected';
    webSocket.connectedTimestamp = new Date();
    if (webSocket.moduleOnOpen) {
      webSocket.moduleOnOpen(webSocket,context);
    }

  };
  connection.onerror = (event: LdbWebSocketEvent) => {
    debug("Web Socket Error - " + webSocket.name);
    if (webSocket.status !== 'authFailure') {
      webSocket.status = 'error';
    }
  };
};

//----------------------------------------------------------------------------------------------------------------------

export const closeWebSockets = async(context:any) => {
  for (const webSocket of webSockets) {
    if (webSocket.connection) {
      webSocket.connection.close(1000, 'terminate');
      webSocket.connection = undefined;
    }
  }
}

//----------------------------------------------------------------------------------------------------------------------

const tryReconnectWebSocket = async (context:any,webSocket: LdbFeWebSocket) => {
  if (webSocket.status === 'authFailure' || webSocket.status === 'terminated') {
    return;
  }
  const retryTimeSeconds = Math.min(30, Math.max(webSocket.connectionAttepts, 1) * 5);
  debug("Reconnecting - " + webSocket.name + " in " +retryTimeSeconds + " seconds");
  await new Promise((r) => setTimeout(r, retryTimeSeconds * 1000));
  connectWebSocket(context,webSocket);
};

//----------------------------------------------------------------------------------------------------------------------

const checkWebsocketState = async (webSocket: LdbFeWebSocket) => {
  try {
    if (webSocket.connection) {
      await new Promise((r) => setTimeout(r, 15000));
      webSocket.connection?.send;
      checkWebsocketState(webSocket);
    }
  } catch {
    await new Promise((r) => setTimeout(r, 15000));
    webSocket.connection?.send;
  }
};

//----------------------------------------------------------------------------------------------------------------------
