<template>
  <div v-if="view === 'edit' || view === 'create'" class="ldb-field-container__text">
    <capitec-text-field
      :disabled="field.enableGuards === false"
      @value-change="on_change"
      @key-input="on_change"
      @focus="on_focus"
      @blur="on_blur"
      :label="`${fieldLabel}`"
      :name="field.name"
      :autocomplete="`${field.autocomplete || field.name}`"
      :value="value"
      :error="validationError"
    ></capitec-text-field>

  </div>

  <template v-else-if="view === 'item'">
    <LDBItemViewField
      :view="view"
      :field="field"
      :displayValue="value ? value.toString() : ''"
      :definition="definition"
      :record="record"
    />
  </template>

  <template v-else-if="view === 'list'">
    <LDBListViewField
      :view="view"
      :field="field"
      :displayValue="value ? value.toString() : ''"
      :definition="definition"
      :record="record"
    />
  </template>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import { ldb_field_mixin } from './ldb_field_mixin.js';
import LDBItemViewField from './LDBItemViewField.vue';
import LDBListViewField from './LDBListViewField.vue';

export default {
  ...mapState(['authUser']),
  mixins: [ldb_field_mixin],
  components: { 
    LDBItemViewField,
    LDBListViewField
  },
  props: {},
  data() {
    return {
      focussed: false,
      newValue: '',
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    on_focus(evt) {
      this.focussed = true;
      this.$emit('focus', evt);
    },
    on_blur(evt) {
      this.focussed = false;
      this.$emit('blur', evt);
    },
    on_change(evt) {
      this.newValue = evt.detail.new;
      this.$emit('change', evt.detail.new);
    },
  },
};
</script>

<style>

div.ldb-field-container__text {
  /* border: 1px dotted grey; */
  /* display:flex; */
  flex:1;
}

div.ldb-field-container__text > capitec-memo{
  flex:1;
}

div.ldb-field-container__text > capitec-text-field{
  flex:1;
}

div.ldb-field-container__text > capitec-button{
  flex:1;  
  max-width: 50px;
  min-width: 50px;
  max-height: 48px;
  min-height: 48px;
  margin-left:10px;
}


.ldb-field-container.list > capitec-label{
  white-space: nowrap;
}

</style>


