//@ts-nocheck
import VueStore from 'vue-class-store';

import { LfxFailedTreasuryDealInt, LfxFailedTreasuryDealDef } from '@/definitions/LfxFailedTreasuryDealDef';

import { LfxModel, LfxDefinition } from './LfxModel';

@VueStore
class LfxFailedTreasuryDealMdl extends LfxDefinition {
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
export class LfxFailedTreasuryDeal extends LfxModel {
  constructor(data, state) {
    super({definition_name:'failedTreasuryDeal',...data}, state);    
  }
  get displayFKLink() {
    return true
  }
  get link() {
    // @ts-expect-error
    return `/transactions/failedTreasuryDeals/${this.id}`;
  }
}

export function Definition(context, name: string) {
  return new LfxFailedTreasuryDealMdl(LfxFailedTreasuryDealDef, LfxFailedTreasuryDeal, context, name, {});
}
