//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LdbPermissionGroupDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Permission Group record for LandoByte TS systems
//  Created     : 14 August 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import { isAlphaWhiteList, standardTextWhiteList } from '../sharedUtils/LdbValidations';
import type { LdbDefinition } from './LdbInterfaces';

import type { LfxAccountAccountLevel } from './LfxAccountDef';

//----------------------------------------------------------------------------------------------------------------------

export type LdbPermissionGroupStatus = "active"|"disabled";

//----------------------------------------------------------------------------------------------------------------------

export interface LdbPermissionGroupInt {
  id?: number,
  accountId?: number,
  name?:string,
  description?:string,
  accountLevels?:LfxAccountAccountLevel[];

  status?:LdbPermissionGroupStatus,
  
  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?:Date,
}

//----------------------------------------------------------------------------------------------------------------------

export const LdbPermissionGroupDef:LdbDefinition = {
  title:"Permission Group",
  table:'LdbPermissionGroup',
  collectionPath:"/config/1/permissionGroup",
  view:{create:true,edit:true,item:true,list:true,delete:false,csv:false},
  indexes:['accountId'],
  pagingType:'frontEnd',
  fields:{
    id:{
      label:'ID',
      datatype:{'id':{descriptionField:'name'}},
      default:'AUTOINC',
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    accountId:{
      label:'Account',
      datatype:{
        foreignKey: {
          collection:'fk_accounts',
          linkTable:'LfxAccount',
          linkField:'id',
          displayField:'name',
        }
      },
      default:null,
      mandatory:false,
      allowNullValues:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false},
    },
    name:{
      label:'Name',
      datatype:'text',
      default:null,
      mandatory:true,
      validators:{
        is:{msg:'Please use only alpha charaters',args:[isAlphaWhiteList]},
        len:{msg:'Name should be between 3 and 50 characters',args:[3,50]}
      },
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    description: {
      label: 'Description',
      datatype: 'text',
      default: null,
      mandatory: true,
      validators:{
        is:{msg:'Please use only alphanumeric charaters',args:standardTextWhiteList},
        len:{msg:'Description should be between 5 and 250 characters',args:[5,250]}
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
    },
    accountLevels: {
      label: 'Account Levels',
      datatype: {
        json: {},
      },
      displayType: {
        datatype: 'foreignKeyList',
        component: 'LDBFieldForeignKeyList',
        foreignKeyList: { linkField: 'id', displayField: 'name', linkTable: 'LdbAccountLevel', collection:'fk_account_levels' },
      },
      default: null,
      mandatory: true,
      validators:{
        is:{msg:'Please use only alphanumeric charaters',args:standardTextWhiteList},
        len:{msg:'Account Levels should be between 2 and 250 characters',args:[2,250]}
      },
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
    },
    status:{
      label:'Status',
      datatype:{option:{optionType:'text',
          options:[
            {id:'active',name:'active'},
            {id:'disabled',name:'Disabled'},
          ]
        }
      },
      default:'active',
      mandatory:true,
      views:{create:true,edit:false,item:true,list:false,delete:false,csv:false}
    },

    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
  },
};
