<template>
  <LFXContent
    :titleBar="titleBar"
    :show_tabs="showTabs"
    :show_actions="showActions"
    :show_banners="showBanners"
    :form_layout="formLayout"
    :show_stepper="showStepper"
    :show_label="showLabel"
    :label="contentLabel"
    :show_sidebar="false"
  >
    <template #actions>
      <LDBButton
        v-for="button in actionBarButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>

    <template #banners>
      <LfxBanner
        v-for="banner in banners"
        :key="banner.id"
        :title="banner.title"
        :status="banner.status"
        :closable="banner.closable"
        ><div v-html="banner.content"></div
      ></LfxBanner>
    </template>

    <template #content>
      <router-view></router-view>
    </template>
  </LFXContent>

  <router-view name="modal"></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXContent from '@/components/layout/LfxContent.vue';

export default {
  name: 'LfxSignatory',
  components: { LFXContent },
  emits: [],
  props: {},
  data() {
    return {};
  },
  created() {
    if (this.$route.name === 'accountSignatory') {
      this.$router.replace({ name: 'accountSignatoryDetailsView' });
    }
  },
  mounted() {
    this.setupFieldLables()
  },
  computed: {
    ...mapGetters(['permissions']),
    ...mapState(['account', 'signatory', 'authUser']),

    // ----- Title Bar -----

    signatoryOrContact() {
      return this.signatoryRecord?.signerLevel === 'contactOnly' ? 'Contact' : 'Signatory';
    },

    titleBar() {
      const titleBar = {
        icon: this.titleBarIcon,
        label: `${this.accountLabel}: ${this.accountRecord?.name} / ${this.signatoryOrContact}: ${this.signatoryName}`,
        buttons: [
          {
            name: 'backToAccount',
            label: `Back to ${this.accountLabel}`,
            type: 'white',
            routeName: 'accountSignatoriesList',
          },
        ],
      };
      return titleBar;
    },
    titleBarIcon() {
      return this.$route.meta?.icon?.[this.accountLevel];
    },

    // ----- Banners -----

    showBanners() {
      return this.banners?.length > 0 || false;
    },
    banners() {
      let availableBanners = [
        {
          id: `individualSignatory-${this.signatoryId}`,
          title: `Client's Own Signatory`,
          status: 'info',
          closable: false,
          content: `This is the individual client's own signatory. It cannot be edited directly. If any changes are required, please contact Treasury Maintain`,
          guards: this.signatoryRecord?.signatoryType === 'individual',
        },
        {
          id: `mainContact-${this.signatoryId}`,
          title: `Main Contact`,
          status: 'info',
          closable: false,
          content: `This is the client's main contact. It cannot be edited directly. If any changes are required, please contact Treasury Maintain<br/>This contact cannot be converted into a signatory. If this email address must sign, please create a new signatory`,
          guards: this.signatoryRecord?.signatoryType === 'mainContact',
        },
        {
          id: 'incompleteInformation',
          title: 'Information Incomplete',
          status: 'warning',
          closable: false,
          content: this.missingFieldsText,
          guards:
            this.signatoryRecord?.status === 'active' &&
            this.isActualSignatory &&
            this.missingFieldsForSignatory.length > 0,
        },
      ];

      return availableBanners.filter((banner) => banner.guards);
    },

    // ----- Tabs -----

    showTabs() {
      return false;
    },

    // ----- Stepper -----

    showStepper() {
      return false;
    },

    // ----- Content Label -----

    showLabel() {
      return this.contentLabel ? true : false;
    },
    contentLabel() {
      return `${this.signatoryOrContact} Details`;
    },

    // ----- Action Bar -----

    showActions() {
      return this.actionBarButtons.length !== 0 ? true : false;
    },
    actionBarButtons() {
      let self = this;
      let allButtons = {
        signatoryDetailsView: [
          {
            name: 'updateIndividualSignatory',
            label: `Update Individual Signatory`,
            type: 'primary',
            guards: this.signatoryRecord?.signatoryType === 'individual' && 
              this.canManageSignatories,
            clickEvent() {
              self.$router.push({ name: 'updateIndividualSignatory' });
            },
          },
          {
            name: 'updateEntityMainConctact',
            label: `Update Main Contact`,
            type: 'primary',
            guards: this.signatoryRecord?.signatoryType === 'mainContact'  && 
              this.canManageSignatories,
            clickEvent() {
              self.$router.push({ name: 'updateIndividualSignatory' });
            },
          },
          {
            name: 'accountSignatoryEdit',
            label: `Edit ${this.signatoryOrContact}`,
            type: '',
            guards: this.permissions?.editSignatory && this.signatoryRecord?.status === 'active' && 
              this.canManageSignatories,
            clickEvent() {
              self.$router.push({ name: 'accountSignatoryDetailsEdit' });
            },
          },
          {
            name: 'accountSignatoryToContact',
            label: 'Convert to Contact',
            type: '',
            modal: {
              header: `Convert to Contact`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Convert',
              content: this.convertToContactModalMessage,
            },
            params: { accountId: this.signatoryRecord.accountId, signatoryId: this.signatoryRecord.id },
            action: 'setSignatoryToContact',
            guards: this.signatoryToContactGuard && 
              this.canManageSignatories,
          },
          {
            name: 'accountContactToSignatory',
            label: 'Convert to Signatory',
            type: '',
            modal: {
              header: `Convert to Signatory`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Convert',
              content: this.convertToSignatoryModalMessage,
            },
            params: { accountId: this.signatoryRecord.accountId, signatoryId: this.signatoryRecord.id },
            action: 'setContactToSignatory',
            guards: this.contactToSignatoryGuard && 
              this.canManageSignatories,
          },
          {
            name: 'accountSignatoryDisable',
            label: `Disable ${this.signatoryOrContact}`,
            type: '',
            modal: {
              header: `Disable ${this.signatoryOrContact}: ${this.signatoryRecord?.name}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Disable',
              content: this.disableSignatoryModalMessage,
            },
            params: { accountId: this.signatoryRecord.accountId, signatoryId: this.signatoryRecord.id },
            action: 'disableSignatory',
            guards: this.signatoryRecord.status === 'active' && 
              this.canManageSignatories,
          },
          {
            name: 'accountSignatoryEnable',
            label: `Enable ${this.signatoryOrContact}`,
            type: 'primary',
            modal: {
              header: `Enabled ${this.signatoryOrContact}: ${this.signatoryRecord?.name}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Enable',
              content: this.enableSignatoryModalMessage,
            },
            params: { accountId: this.signatoryRecord.accountId, signatoryId: this.signatoryRecord.id },
            action: 'enableSignatory',
            guards: this.signatoryRecord.status === 'disabled' && 
              this.canManageSignatories,
          },
          {
            name: 'setSignatoryAsFecReviewSignatory',
            label: 'Set as FEC Review Signatory',
            type: '',
            modal: {
              header: `Set as FEC Review Signatory`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Update',
              content: this.setSignatoryAsFecReviewSignatoryModalMessage,
            },
            params: { accountId: this.signatoryRecord.accountId, signatoryId: this.signatoryRecord.id },
            action: 'setSignatoryAsFecReviewSignatory',
            guards: this.setSignatoryAsFecReviewSignatoryGuard
          },
          {
            name: 'logs',
            label: 'Logs',
            type: '',
            guards: !!this.signatoryRecord,
            clickEvent() {
              self.$router.push({
                name: 'signatoryLogView',
              });
            },
          },
          {
            name: 'signatoryUser',
            label: 'Back to User',
            type: '',
            guards: !!this.signatoryRecord?.userId,
            clickEvent() {
              self.$router.push({
                name: 'userSignatoryView',
                params: { userId: self.signatoryRecord.userId, signatoryId: self.signatoryRecord.id },
              });
            },
          },
        ],
        signatoryDetailsEdit: [
          {
            name: 'cancelSignatoryEdit',
            label: 'Cancel',
            type: '',
            guards: true,
            clickEvent() {
              self.$router.go(-1);
            },
          },
        ],
      };
      return allButtons[this.buttonGroup] !== undefined
        ? allButtons[this.buttonGroup].filter((button) => button.guards)
        : [];
    },
    canManageSignatories() {
      return this.authUser?.accountLevel !== 'bank' || this.accountRecord?.accountIsBankDirectClient
    },

    signatoryToContactGuard() {
      return (
        this.permissions?.editSignatory &&
        this.signatoryRecord?.status === 'active' &&
        this.signatoryRecord?.signerLevel !== 'contactOnly'
      );
    },
    contactToSignatoryGuard() {
      return (
        this.permissions?.editSignatory &&
        this.signatoryRecord?.status === 'active' &&
        this.signatoryRecord?.signerLevel === 'contactOnly' &&
        // !(this.accountRecord?.accountLevel !== 'client' && signatory.signatoryType !== 'user') &&
        !(this.accountRecord?.accountLevel !== 'client' && this.signatoryRecord?.signatoryType !== 'user') &&
        !this.signatoryRecord?.isGroupMailBox &&
        !!(this.signatoryRecord?.msisdn && this.signatoryRecord?.idNumber)
      );
    },
    setSignatoryAsFecReviewSignatoryGuard() {
      return (
        this.permissions?.setSignatoryAsFecReviewSignatory &&
        this.signatoryRecord?.status === 'active' &&
        this.signatoryRecord?.signerLevel !== 'contactOnly' &&
        this.signatoryRecord?.fecReviewSignatory !== true &&
        this.accountRecord?.accountLevel === 'client' &&
        this.accountRecord?.mayBookFec === true
      );
    },
    buttonGroup() {
      return this.$route.meta?.content?.buttonGroup;
    },

    // ----- Layout -----

    formLayout() {
      return this.$route.meta?.layout === 'list' ? false : true;
    },

    // ----- Account Record -----

    accountId() {
      return +this.$route.params.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },
    accountLevel() {
      return this.accountRecord?.accountLevel;
    },
    accountLabel() {
      if (this.accountLevel === 'bank') {
        return 'Bank';
      } else if (this.accountLevel === 'intermediary') {
        return 'Intermediary';
      } else if (this.accountLevel === 'intermediaryBranch') {
        return 'Branch';
      } else if (this.accountLevel === 'client') {
        return 'Client';
      } else {
        return 'Account';
      }
    },

    // ----- Signatory Record -----

    signatoryId() {
      return +this.$route.params?.signatoryId;
    },
    signatoryRecord() {
      return this.signatory?.[this.signatoryId] || {};
    },

    signatoryName() {
      return `${this.signatoryRecord?.surname}, ${this.signatoryRecord?.firstName}`;
    },

    isActualSignatory() {
      return this.signatoryRecord?.signerLevel !== 'contactOnly';
    },
    isOnlyContact() {
      return !this.isActualSignatory;
    },

    requiredFieldsForSignatory() {
      return [
        'firstName',
        'surname',
        'idNumber',
        'capacity',
        'msisdn',
        'emailAddress',
        'paymentSignatory',
        'signerLevel',
        'signerRequired',
      ];
    },
    missingFieldsForSignatory() {
      const missingFields = [];
      for (const fieldName of this.requiredFieldsForSignatory) {
        if (!this.signatoryRecord[fieldName]) {
          missingFields.push(fieldName);
        }
      }
      return missingFields;
    },
    missingFieldsLabels() {
      const fieldLabels = this.missingFieldsForSignatory.map((fieldName) => this.getFieldLabels(fieldName));
      return fieldLabels;
    },
    missingFieldsText() {
      const formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });
      const formattedFieldNames = formatter.format(this.missingFieldsLabels);
      return `The following fields are outstanding: ${formattedFieldNames}`;
    },

    convertToContactModalMessage() {
      return `
      <ol>
        <li>The Signatory will be converted to a Contact.</li>
        <li>They will no longer be able to sign portfolios but will be able to receive communication</li>
      </ol>
      `;
    },
    convertToSignatoryModalMessage() {
      return `
      <ol>
        <li>The Contact will be converted to a Signatory.</li>
        <li>They will be able to sign portfolios and receive communication</li>
      </ol>
      `;
    },
    disableSignatoryModalMessage() {
      return `
      <ol>
        <li>The ${this.signatoryOrContact} will be disabled.</li>
      </ol>
      `;
    },
    enableSignatoryModalMessage() {
      return `
      <ol>
        <li>The ${this.signatoryOrContact} will be disabled.</li>
      </ol>
      `;
    },
    setSignatoryAsFecReviewSignatoryModalMessage() {
      return `
      <ol>
        <li>The Signatory will be set as the FEC Review Signatory for this client.</li>
        <li>Any existing FEC Review Signatories for this client will be removed.</li>
      </ol>
      `;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    getFieldLabels(fieldName) {
      const field = this.signatoryRecord?.definition?.fields?.find((field) => field.name === fieldName);
      return field?.label || "";
    },
    setupFieldLables() {
      for (const field in this.signatoryRecord?.definition?.fields || {}) {
        if (this.signatoryRecord?.definition?.fields[field]?.name === 'isDirector') {
          this.signatoryRecord.definition.fields[field].label = (this.accountRecord?.signatoryIsDirectorFieldLabel(field.label) || 'Is the Signatory a Director, Member, Trustee or Partner?')
        }
        if (this.signatoryRecord?.definition?.fields[field]?.name === 'isSigningDirector') {
          this.signatoryRecord.definition.fields[field].label = (this.accountRecord?.signatoryIsSigningDirectorFieldLabel(field.label) || 'Will the director sign the application?')
        }
      }
    }

  },
};
</script>
