<template>
  <LFXPagedListView  
    :definition="definition" 
    action="getDeals" 
    :actionParams="{}"  
    :search="searchConfig"
    :defaultWhere="accountSelector"
    :defaultSort="defaultSort" />
  <!-- <LFXListView v-if="hasListLoaded" :definition="definition" :records="records" /> -->
  <!-- <template v-else>
    <LDBLoader />
  </template> -->
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXPagedListView from '@/views/general/LFXPagedListView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

export default {
  name: 'LfxDealList',
  components: { LFXPagedListView, LDBLoader },
  props: {
    includeBranchesInLists: {type: Boolean, required: false, default: false}
  },
  data() {
    return {
    }
  },
  async created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account', 'deal']),
    
    searchConfig() {
      let ranges = [];
      ranges.push(this.definition.indexed_fields.valueDate);
      ranges.push(this.definition.indexed_fields.requestedTimestamp);
      const todayStart = new Date((new Date()).setHours(0,0,0,0));
      const todayEnd = new Date((new Date()).setHours(23,59,59,0));
      let options = [];
      // options.push(this.definition.indexed_fields.deliveryType);
            
      return {
        searchFields:['reference', 'externalReference', 'dealNumber', 'currencyPair', 'integrationId' ],
        options:options,
        ranges:ranges,
        choices:[
          {id:'status',
          title:'',
          chips:[
            {id:'open',         label:'Open',          selector:{ status:{notIn:['tfCompleted','cancelled','rejected','fecDrawnDown','superseded']} },default:true},
            {id:'bookedToday',  label:"Booked Today",  selector:{ createdAt: {gte:todayStart} }},
            {id:'dueToday',     label:"Due Today",     selector:{ and: [{valueDate: {gte:todayStart}},{valueDate: {lte:todayEnd}}]}},
            {id:'released',     label:'Released',      selector:{ status:{in:['released']} }},
            {id:'completed',    label:'Completed',     selector:{ status:{in:['tfCompleted']} }},
            {id:'cancelled',    label:'Cancelled',     selector:{ status:{in:['cancelled','rejected']} }},
            {id:'overdue',      label:'Overdue',       selector:{ valueDate: {lte:todayStart},status:{notIn:['tfCompleted','cancelled','rejected','superseded','fecDrawnDown']}}},
            {id:'all',          label:'All',           selector:{ }},
          ]
        }
        ],
      }
    },
    defaultSort() {
      return { order: 'id', sort: 'desc' }
    },
    viewedAccountId() {
      return +this.$route.params.accountId;
    },
    viewedAccountRecord() {
      return this.account[this.viewedAccountId]
    },
    viewedAccountLevel() {
      return this.viewedAccountRecord?.accountLevel;
    },
    viewedTransactionId() {
      return +this.$route.params.transactionId;
    },

    accountSelector() {
      if (this.viewedAccountId) {
        switch (this.viewedAccountLevel) {
          case 'client':
            this.$route.meta.hideClientAndBranchName = true
            return { accountId: this.viewedAccountId };
          default:
            if (this.includeBranchesInLists === true) {
              if (this.viewedAccountLevel === 'intermediary') {  
                return { intermediaryId: this.viewedAccountId };
              } else {
                return { branchId: [this.viewedAccountId,...this.viewedAccountRecord.childrenBranchIds] }
              }
            } else {
              return { branchId: this.viewedAccountId };
            }
        }
      }
      return undefined;
    },

    // ----- Records -----

    records() {
      let res = undefined;
      if (this.viewedAccountId) {
        res = {}
        for (let d in this.deal) {
          let deal = this.deal[t];
          if (this.viewedAccountLevel === 'client' && deal.accountId === this.viewedAccountId) {
            res[deal.id] = deal;
          } else if (
            (this.viewedAccountLevel === 'intermediary' || this.viewedAccountLevel === 'intermediaryBranch') &&
            deal.branchId === this.viewedAccountId
          ) {
            res[deal.id] = deal;
          }
        }
      } else {
        res = this.deal || {};
      }
      let returnRes = undefined;
      if (this.viewedTransactionId) {
        returnRes = {};
        for (const d in res) {
          if (res[d].transactionFileId === this.viewedTransactionId) {
            returnRes[d] = res[d]
          }
        }
      }
      return returnRes || res;
    },
    definition() {
      return getDefinition('deal');
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    // onSearchChange(evt) {
    //   this.definition.search_string = evt.detail.new;
    // },
  },
};
</script>

<style>
/* div.container {
} */
</style>
