<template>
  <!-- <div class="tst-xxx"> -->

  <div v-if="view === 'edit' || view === 'create'" class="ldb-field-container__boolean">
    <capitec-toggle
      v-if="componentType === 'toggle'"
      :label="fieldLabel"
      @value-change="on_change"
      :checked="value"
    ></capitec-toggle>
  </div>
  <LDBItemViewField
    v-else-if="value === true && view === 'item'"
    :view="view"
    :field="field"
    displayValue="Yes"
    :definition="definition"
    :record="record"
  />
  <LDBItemViewField
    v-else-if="value === false && view === 'item'"
    :view="view"
    :field="field"
    displayValue="No"
    :definition="definition"
    :record="record"
  />
  <LDBItemViewField
    v-else-if="value === null && view === 'item' && field.forceDisplay === true"
    :view="view"
    :field="field"
    displayValue="Not Captured"
    :definition="definition"
    :record="record"
  />

  <LDBListViewField
    v-else-if="value === true && view === 'list'"
    :view="view"
    :field="field"
    displayValue="Yes"
    :definition="definition"
    :record="record"
  />
  <LDBListViewField
    v-else-if="value === false && view === 'list'"
    :view="view"
    :field="field"
    displayValue="No"
    :definition="definition"
    :record="record"
  />
  <!-- <LDBListViewField v-else-if="view === 'list'" :view="view" :field="field" displayValue="displayValue" :definition="definition" :record="record"/> -->

  <!-- </div> -->
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import { ldb_field_mixin } from './ldb_field_mixin.js';
import LDBItemViewField from './LDBItemViewField.vue';
import LDBListViewField from './LDBListViewField.vue';

export default {
  mixins: [ldb_field_mixin],
  components: { LDBItemViewField, LDBListViewField },
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    componentType() {
      return 'toggle'; // || check || dropdown || radio
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    on_change(evt) {
      this.$emit('change', evt.detail.new);
    },
  },
};
</script>
<style>
.tst-xxx {
  position: relative;
  min-height: 50px;
}

.tst-contaiers {
  /* position: relative; */
}

.tst-xxx > div {
  position: absolute;
  /* opacity: 0.5; */
}

div.tst-container > div {
}

div.ldb-field-container__boolean {
  flex: 1;
}
</style>
