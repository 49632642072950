<template>
  <div class="lfx-deal-summary-report">
    <capitec-content-box>
      <div>
        <LDBField
          v-for="(field, fieldname) in formFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="undefined"
          :record="searchRecord"
          @change="on_change"
        />
      </div>
      <LFXListView v-if="hasListLoaded" :definition="definition" :records="records" :defaultSort="defaultSort" />
      <template v-else-if="isListLoading">
        <LDBLoader />
      </template>
    </capitec-content-box>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LFXListView from '@/views/general/LFXListView.vue';
import LDBField from '@/landobyte_vue/LDBField.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

import { getDefinition } from '@/db-interface';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxDealSummaryReport',
  components: { LFXListView, LDBField, LDBLoader },
  props: {},
  data() {
    return {
      records:[],
      hasListLoaded:false,
      isListLoading:false,
      searchRecord:{}
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),

    definition() {
      return getDefinition('emailLog');
    },
    defaultSort() {
      return { order: 'sentTime', sort: 'desc' }
    },
    // ----- Buttons -----

    formButtons() {
      let self = this;
      return [
      ];
    },

    // ----- Fields -----

    formFields() {
      const res = {
        emailAddress: {
          name: 'emailAddress',
          label: 'Email Address',
          datatype: 'text',
          view: 'edit',
          guards: true,
        },
        timestamp: {
          name: 'timestamp',
          label: 'Timestamp',
          datatype: {
            datetime: {
              type: 'date',
              format: 'human',
              minDate: '2023/09/01',
              maxDate: { type: 'day', offset: 0 },
            },
          },
          view: 'edit',
          guards: true,
        },
      };
      return res;
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    async loadData() {
      this.hasListLoaded = false;
      this.isListLoading = true;
      const result = await doPermissionAction(
        this,
        'seachEmailLogs',
        {},
        {
          searchType: 'email',
          emailAddress: this.searchRecord.emailAddress,
          timestamp:this.searchRecord.timestamp
        });
      if (result.status === 'success') {
        this.records = result.response.rows
      }
      this.hasListLoaded = true;
      this.isListLoading = false;
    },
    on_change({ field, value }) {
      this.searchRecord[field] = value;
      if (this.searchRecord.timestamp && this.searchRecord.emailAddress) {
        this.loadData()
      }
    },
  },
};
</script>

<style>
.lfx-deal-summary-report-view-table-container {
  position: relative;
  min-height: 320px;
}

#lfx-deal-summary-report-view-table {
  min-width: 100%;
}

div.lfx-deal-summary-report {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
</style>
