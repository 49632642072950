//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2022 LandoByte (Pty) Ltd.
//  File        : LfxBulkFeeBatchDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a LfxBulkFeeBatch record for LandoByte TS systems
//  Created     : 14 August 2022 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition } from './LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------

export type LfxBulkFeeBatchStatus = "new"|"inProgress"|"completed"|"error";

//----------------------------------------------------------------------------------------------------------------------

export interface LfxBulkFeeBatchInt {
  id?: number,
  uuid?: string,
  accountId?: number,
  reference?:string,

  status?:LfxBulkFeeBatchStatus,
  originalFileName?:string,
  s3Bucket?:string;
  uploadFileS3Key?:string,
  resultFileS3Key?:string,

  originalFileSize?:number,
  lineCount?: number,
  
  createdAt?:Date,
  createdBy?:number,
  createdByName?: string;
  completedAt?:Date,
  deletedAt?:Date,
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxBulkFeeBatchDef:LdbDefinition = {
  title:"Bulk Fee Batch",
  table:'LfxBulkFeeBatch',
  view:{create:true,edit:true,item:true,list:true,delete:false,csv:false},
  actions:{
    list:'getAccountBulkFeeBatches',
    item:'getAccountBulkFeeBatch',
  },
  collectionPath: '/account/{{accountId}}/bulkFeeBatch',
  pagingType: 'backEnd',
  indexes:['accountId'],
  fields:{
    id:{
      label:'Reference',
      datatype:{'id':{descriptionField:'reference'}},
      default:'AUTOINC',
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    uuid:{
      label:'UUID',
      datatype:'uuid',
      default:'AUTOUUID',
      mandatory:true,
      views:{create:false,edit:false,item:false,list:false,delete:false,csv:false}
    },    
    accountId:{
      label:'Account',
      datatype:{
        foreignKey: {
          linkTable:'LfxAccount',
          linkField:'id',
          displayField:'name',
        }
      },
      default:0,
      mandatory:true,
      views:{create:false,edit:false,item:false,list:false,delete:false,csv:false},
    },
    reference:{
      label:'Reference',
      datatype:'text',
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:false,list:false,delete:false,csv:false}
    },
    status:{
      label:'Status',
      datatype:{option:{optionType:'text',
          options:[
            {id:'new',name:'New'},
            {id:'inProgress',name:'In Progress'},
            {id:'completed',name:'Completed'},
            {id:'error',name:'Error'},
          ]
        }
      },
      default:'new',
      mandatory:true,
      views:{create:true,edit:false,item:true,list:true,delete:false,csv:false}
    },
    originalFileName:{
      label:'Original File Name',
      datatype:'text',
      default:null,
      mandatory:true,
      allowNullValues:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    s3Bucket:{
      label:'Specified S3 Bucket',
      datatype:'text',
      default:null,
      mandatory:true,
      allowNullValues:true,
      views:{create:false,edit:false,item:false,list:false,delete:false,csv:false}
    },
    uploadFileS3Key:{
      label:'Upload File S3Key',
      datatype:'text',
      default:null,
      mandatory:true,
      allowNullValues:true,
      views:{create:false,edit:false,item:true,list:false,delete:false,csv:false}
    },
    resultFileS3Key:{
      label:'Result File S3Key',
      datatype:'text',
      default:null,
      mandatory:true,
      allowNullValues:true,
      views:{create:false,edit:false,item:true,list:false,delete:false,csv:false}
    },
    originalFileSize:{
      label:'Original File Size',
      datatype:'integer',
      default:null,
      mandatory:true,
      allowNullValues:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    lineCount:{
      label:'Line Count',
      datatype:'integer',
      default:null,
      mandatory:true,
      allowNullValues:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    createdAt:{
      label:'Created At',
      datatype: {datetime: {
        type: 'datetime',
        format: 'human'
      }},
      default:null,
      mandatory:true,
      allowNullValues:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    createdBy:{
      label:'Created By',
      datatype:{
        foreignKey: {
          linkTable:'LfxUser',
          linkField:'id',
          displayField:'username',
        }
      },
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:false,delete:false,csv:false}
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: '',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    completedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
  }
}