<template>
  <LFXListView :definition="definition" :records="records" />
  <router-view></router-view>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXListView from '@/views/general/LFXListView.vue';

export default {
  name: 'LfxUserPermissionsList',
  components: { LFXListView },
  props: {},
  data() {
    return {};
  },
  created() {
    this.user[this.userId].activate();
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['user']),
    userId() {
      return +this.$route.params.userId;
    },
    records() {
      // return this.user?.[this.userId]?.permission || {};
      return this.user?.[this.userId]?.userPermissions || {};
    },
    definition() {
      return getDefinition('user_permission');
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
