<template>
  <LDBModal result="info" :header="header">
    <template #body>
        <capitec-spacer />
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="undefined"
          :record="newRecord"
          @change="on_change"
        />
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :enabledGuards="button.enabledGuards"
        :definitionName="button.definitionName"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBField from '@/landobyte_vue/LDBField.vue';

//TODO 
//BIG WARNING NOTE

export default {
  name: 'LfxBulkEnableBranch',
  components: { LDBField },
  props: {},
  data() {
    return {
      newRecord: {
        confirmSubmit:false
      },
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters(['permissions']),
    ...mapState(['account']),
    header() {
      return 'Bulk Enable Branch';
    },

    accountId() {
      return +(this.$route.params.accountId);
    },
    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelBulkEnableBranch',
          label: 'Cancel',
          type: '',
          action: 'click',
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'bulkEnableBranch',
          label: 'Bulk Enable Branch',
          type: 'primary',
          action: 'bulkEnableBranch',
          enabledGuards: this.newRecord?.confirmSubmit || false,
          actionParams: this.newRecord,
          params: {accountId:this.accountId},
          clickEvent(response) {
            self.$router.go(-1);
          },
        },
      ];
      return allButtons;
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute
        ? this.$route.meta.modal.cancelRoute
        : this.$route.meta.cancelRoute
        ? this.$route.meta.cancelRoute
        : '/'; //TODO: go to user's home as fallback
    },

    // ----- Definition -----
    // ----- Fields -----

    viewFields() {
      let res = [
        { fieldName: 'confirmSubmit',name: 'confirmSubmit', label: 'Confirm? Are you sure you wish to continue? This cannot be undone.', datatype: 'boolean' },
      ];
      return res;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Fields -----

    on_change({ field, value }) {
      this.newRecord[field] = value;
    },

    // ----- API calls -----

  },
};
</script>

<style></style>
