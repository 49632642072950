<template>
  <capitec-content-box>
    <div>
      <h1>Required Documents</h1>
          <p v-if="requiredDocumentFields?.length === 0">
            There are no Required Documents for this client
          </p>
      <LDBField v-else
        v-for="(field, fieldname) in requiredDocumentFields"
        :key="fieldname"
        :field="field"
        view="edit"
        :fieldname="field.name"
        :record=documentNumbers
        @change="on_change"
      />
      <h1>Optional Documents</h1>
          <p v-if="optionalDocumentFields?.length === 0">
            There are no Optional Documents for this client
          </p>
      <LDBField v-else
        v-for="(field, fieldname) in optionalDocumentFields"
        :key="fieldname"
        :field="field"
        view="edit"
        :fieldname="field.name"
        :record=documentNumbers
        @change="on_change"
      />
    </div>
    <capitec-action-bar>
        <LDBButton
          v-for="button in leftButtons"
          :key="button.name"
          :label="button.label"
          :action="button.action"
          :actionParams="button.actionParams"
          :params="button.params"
          :type="button.type"
          :tooltip="button.tooltip"
          :modal="button.modal"
          :viewGuards="button.guards"
          :enabledGuards="button.enabledGuards"
          @click="button.clickEvent"
        >
          <template v-if="button.modal !== undefined" #modal>
            <div v-html="button.modal.content"></div>
          </template>
        </LDBButton>
        <capitec-spacer />
        <LDBButton
          v-for="button in rightButtons"
          :key="button.name"
          :label="button.label"
          :action="button.action"
          :actionParams="button.actionParams"
          :params="button.params"
          :type="button.type"
          :tooltip="button.tooltip"
          :modal="button.modal"
          :viewGuards="button.guards"
          :enabledGuards="button.enabledGuards"
          @click="button.clickEvent"
        >
          <template v-if="button.modal !== undefined" #modal>
            <div v-html="button.modal.content"></div>
          </template>
        </LDBButton>
      </capitec-action-bar>
  </capitec-content-box>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import lodash from 'lodash';
const merge = lodash.merge;

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxClientApplicationPortolioDocuments',
  components: { LDBField },
  emits: ['on-back', 'on-next'],
  props: {},
  data() {
    return {
      documentNumbers: {},
    };
  },
  created() {
  },
  mounted() {
    const initialDocuments = this.applicationRecord?.documents || [];
    for (const initialDocument of initialDocuments) {
      if (this.requiredDocumentNames.includes(initialDocument.documentName) || this.optionalDocumentNames.includes(initialDocument.documentName)) {
        this.documentNumbers[initialDocument.documentName] = initialDocument.count
      }
    }
  },
  beforeUnmount() {
    this.applicationRecord?.revert();
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),
    header() {
        return 'Request Extension';
      },

    // ----- Buttons -----

    leftButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'back',
          label: 'Back',
          type: 'clear',
          action: 'click',
          clickEvent() {
            self.$emit('on-back');
          },
        },
      ];
      return allButtons;
    },

    rightButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'updateDocumentsButton',
          label: 'Save',
          type: '',
          action: 'setApplicationPortfolioDocuments',
          guards: this.applicationRecord?.applicationIsEditable,
          enabledGuards: this.allRequiredDocumentsSet,
          params: {
            accountId: this.accountId,
            applicationId: this.applicationId,
          },
          actionParams: {
            documents: this.documentsPost,
          },
          clickEvent(evt) {},
        },
        {
          name: 'next',
          label: 'Next',
          action: 'setApplicationPortfolioDocuments',
          type: 'primary',
          enabledGuards: this.allRequiredDocumentsSet,
          params: {
            accountId: this.accountId,
            applicationId: this.applicationId,
          },
          actionParams: {
            documents: this.documentsPost,
          },
          get disabled() {
            return this.type === 'disabled';
          },
          guards: true,
          clickEvent() {
            self.onNextClick();
          },
        },
      ];
      return allButtons !== undefined ? allButtons : [];
    },

    documentsPost() {
      const res = [];
      for (const documentName in this.documentNumbers) {
        res.push(
          {
            documentName: documentName,
            count: this.documentNumbers[documentName],
          },
        )
      }
      return res
    },
    // ----- Definition -----

    definition() {
      return getDefinition('application');
    },

    // ----- Record -----
    accountId() {
      return +this.$route.params.accountId;
    },
    accountRecord() {
      let rec = this.account[this.accountId] || undefined ;
      return rec;
    },
    applicationId() {
      return +this.$route.params.applicationId
    },
    applicationRecord() {
      for (const applicationId in this.accountRecord?.application || {}) {
        if (+applicationId === +this.applicationId) {
          return this.accountRecord.application[applicationId]
        }
      }
      return {};
    },
    clientDueDilligence() {
      return this.applicationRecord.application_cif?.[this.applicationRecord.fullCifId]?.cifInfo?.clientDueDilligence
    },
    
    // ----- Fields -----

    allRequiredDocumentsSet() {
      let allCaptured = true;
      for (const documentName of this.requiredDocumentNames) {
        if (!this.documentNumbers[documentName]) {
          allCaptured = false
        }
      }
      return allCaptured
    },
    requiredDocumentNames() {
      const res = [];
      for (const documentType of this.applicationRecord.availableDocumentTypes) {
        if (documentType.isMandatoryForDueDilligence(this.clientDueDilligence)) {
          res.push(documentType.documentName)
        }
      }
      return res
    },
    optionalDocumentNames() {
      const res = [];
      for (const documentType of this.applicationRecord.availableDocumentTypes) {
        if (!documentType.isMandatoryForDueDilligence(this.clientDueDilligence)) {
          res.push(documentType.documentName)
        }
      }
      return res
    },
    requiredDocumentFields() {
      const res = [];
      for (const documentName of this.requiredDocumentNames) {
        res.push({
          name: documentName,
          label: documentName + " - Number of Documents",
          datatype: 'integer',
          group: 'required',
        })
        if ( this.documentNumbers[documentName] === undefined) {
          this.documentNumbers[documentName] = 1
        }
      }
      return res;
    },
    optionalDocumentFields() {
      const res = [];
      for (const documentName of this.optionalDocumentNames) {
        res.push({
          name: documentName,
          label: documentName + " - Number of Documents",
          datatype: 'integer',
          group: 'required',
        })
      }
      return res;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    on_change({ field, value }) {
      if (+value < 0) {
      } else if (+value === 0 && !this.requiredDocumentNames.includes(field)) {
          delete this.documentNumbers[field]
      } else {
        this.documentNumbers[field] = +value;
      }
    },

    // ----- API calls -----
    onNextClick() {
      this.$emit('on-next', {
        fields: this.changedFields,
      });
    },

    async onApiSuccess(_responseObject) {},
  },
};
</script>

<style></style>
