<template>
  <LDBModal result="info" :header="header" :size="modalSize">
    <template #body>
      <div class="lfx-update-individual-signatory-table-container" v-if="clientRetrievedStatus === 'retrieved'">
        <table-lite
          id="lfx-update-individual-signatory-table"
          :is-static-mode="true"
          :is-slot-mode="true"
          :has-checkbox="false"
          :is-loading="false"
          :is-re-search="false"
          :columns="tableColumns"
          :rows="sortedRecords"
        >
          <template v-for="(col, i) of tableColumns" v-slot:[col.field]="data" :key="i">
            {{ data.value[col.field] }}
          </template>
        </table-lite>

        <div v-if="!allDataComplete">
          <br />
          The new <strong>{{ formattedInvalidFieldLabels }}</strong> {{ singleOrMultipleFields }} invalid. Please contact
          TreasuryMaintain to update the information, then try again.
        </div>
        <div v-if="!somthingHasChanged">
          <br />
          No information has changed, there is nothing to update.
        </div>
      </div>
      <div v-else-if="clientRetrievedStatus === 'error'">
        <br />
        Failed to Load Client Data.
        <br />
        {{ clientData.errorMessage }}
      </div>
      <template v-else>
        <br />
        Loading client data from the Bank - This may take some time
        <LDBLoader />
      </template>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import TableLite from '@/views/general/TableLite.vue';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxUpdateIndividualSignatory',
  components: { LDBLoader, TableLite },
  props: {},
  data() {
    return {
      clientRetrievedStatus: 'none', // 'retrieving' | 'retrieved' | 'error'
      clientData: {},
    };
  },
  created() {
    this.retrieveClientData();
  },
  computed: {
    ...mapGetters(['permissions', 'config', 'isDevEnv']),
    ...mapState(['account', 'signatory']),
    header() {
      return this.clientIsIndividual
        ? 'Update Client and Signatory for Individual from Bank Information'
        : 'Update Client and Main Contact for Entity from Bank Information';
    },
    modalSize() {
      return this.clientRetrievedStatus === 'retrieved' ? 'xx-large' : 'medium';
    },

    clientIsIndividual() {
      return this.accountRecord?.accountType === 'individual';
    },

    fields() {
      const res = ['token', 'emailAddress', 'idType'];
      if (this.clientIsIndividual) {
        res.push('firstName');
        res.push('surname');
        res.push('idNumber');
        res.push('msisdn');
      } else {
        res.push('identification');
        res.push('contactNo');
        res.push('contactPersonName');
      }
      return res;
    },

    allDataComplete() {
      for (const field of this.fields) {
        if (!this.clientData[field]) {
          return false;
        }
      }
      return true;
    },
    invalidFields() {
      const invalidFields = [];
      // for (const field of this.fields) {
      //   if (!this.clientData[field]) {
      //     invalidFields.push(field);
      //   }
      // }
      for (const field of this.sortedRecords) {
        console.log(field);
        if (field.newValueValid !== 'Yes') {
          invalidFields.push(field.field);
        }
      }
      return invalidFields;
    },
    invalidFieldLabels() {
      const labels = this.invalidFields.map((field) => this.getFieldLabel(field));
      return labels;
    },
    formattedInvalidFieldLabels() {
      const formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });
      return formatter.format(this.invalidFieldLabels);
    },
    singleOrMultipleFields() {
      return this.invalidFields.length > 1 ? 'fields are' : 'field is';
    },
    somthingHasChanged() {
      for (const row of this.sortedRecords) {
        if (row.valueChanged === 'Yes') {
          return true;
        }
      }
      return false;
    },
    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          action: 'click',
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'updateIndividualSignatory',
          label: this.clientIsIndividual ? 'Update Individual Signatory' : 'Update Main Contact',
          type: 'primary',
          action: this.clientIsIndividual ? 'updateIndividualSignatory' : 'updateEntityMainContact',
          params: { accountId: this.accountId, signatoryId: this.signatoryId },
          actionParams: this.clientData,
          enabledGuards: this.allDataComplete, // && this.somthingHasChanged,
          clickEvent(response) {
            if (response.status === 'success') {
              self.$router.go(-1);
            }
          },
        },
      ];
      return allButtons;
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : 'todo';
    },

    // ----- Definition -----

    // ----- Record -----

    accountId() {
      return +this.$route.params.accountId;
    },
    accountRecord() {
      return this.account[this.accountId];
    },
    signatoryId() {
      return +this.$route.params.signatoryId;
    },
    signatoryRecord() {
      return this.signatory[this.signatoryId];
    },

    // ----- Table -----

    tableColumns() {
      return [
        {
          col: 'fieldColumn',
          field: 'label',
          label: 'Field',
          isKey: false,
          sortable: false,
        },
        {
          col: 'oldValueColumn',
          field: 'oldValue',
          label: 'Current Value',
          isKey: false,
          sortable: false,
        },
        {
          col: 'newValueColumn',
          field: 'newValue',
          label: 'New Value',
          isKey: false,
          sortable: false,
        },
        {
          col: 'valueChangedColumn',
          field: 'valueChanged',
          label: 'Value has Changed?',
          isKey: false,
          sortable: false,
        },
        {
          col: 'newValueValidColumn',
          field: 'newValueValid',
          label: 'New Value is Valid?',
          isKey: false,
          sortable: false,
        },
      ];
    },
    sortedRecords() {
      const self = this;
      const res = [
        {
          field: 'idType',
          label: 'Identification Type',
          oldValue: this.getLabel(this.accountRecord?.identificationType),
          newValue: this.getNewLabel(this.clientData.idType),
          valueChanged: this.clientData?.idType === this.accountRecord?.identificationType ? 'No' : 'Yes',
          newValueValid: this.clientData.idType ? 'Yes' : 'No',
        },
        {
          field: 'idNumber',
          label: 'ID Number/Passport',
          oldValue: this.oldIdNumberLabel,
          newValue: this.getNewLabel(this.clientData.idNumber),
          valueChanged: this.idNumberHasChanged,
          newValueValid: this.clientData.idNumber ? 'Yes' : 'No',
        },
        {
          field: 'identification',
          label: 'Company Registration',
          oldValue: this.accountIdLabel,
          newValue: this.getNewLabel(this.clientData.identification),
          valueChanged: this.accountIdHasChanged,
          newValueValid: this.clientData.identification ? 'Yes' : 'No',
        },
        {
          field: 'firstName',
          label: 'First Name',
          oldValue: this.getLabel(this.signatoryRecord?.firstName),
          newValue: this.getNewLabel(this.clientData.firstName),
          valueChanged: this.clientData.firstName === this.signatoryRecord?.firstName ? 'No' : 'Yes',
          newValueValid: this.clientData.firstName ? 'Yes' : 'No',
        },
        {
          field: 'surname',
          label: 'Surname',
          oldValue: this.getLabel(this.signatoryRecord?.surname),
          newValue: this.getNewLabel(this.clientData.surname),
          valueChanged: this.clientData.surname === this.signatoryRecord?.surname ? 'No' : 'Yes',
          newValueValid: this.clientData.surname ? 'Yes' : 'No',
        },
        {
          field: 'contactPersonName',
          label: 'Contact Person Name',
          oldValue: this.oldContactPersonNameLabel,
          newValue: this.getNewLabel(this.clientData.contactPersonName),
          valueChanged: this.contactPersonHasChanged,
          newValueValid: this.newContactPersonNameIsValid,
        },
        {
          field: 'emailAddress',
          label: 'Email Address',
          oldValue: this.oldEmailLabel,
          newValue: this.getNewLabel(this.clientData.emailAddress),
          valueChanged: this.emailHasChanged,
          newValueValid: this.clientData.emailAddress ? 'Yes' : 'No',
        },
        {
          field: 'msisdn',
          label: 'Mobile Number',
          oldValue: this.oldMobileNumberLabel,
          newValue: this.getNewLabel(this.clientData.msisdn),
          valueChanged: this.mobileNumberHasChanged,
          newValueValid: this.clientData.msisdn ? 'Yes' : 'No',
        },
        {
          field: 'contactNo',
          label: 'Contact Number',
          oldValue: this.oldMobileNumberLabel,
          newValue: this.getNewLabel(this.clientData.contactNo),
          valueChanged: this.contactNumberHasChanged,
          newValueValid: this.clientData.contactNo ? 'Yes' : 'No',
        },
      ];
      return res.filter((f) => self.fields.includes(f.field));
    },

    signatoryIdLabel() {
      return this.getLabel(this.signatoryRecord?.idNumber);
    },
    accountIdLabel() {
      return this.getLabel(this.accountRecord?.identification);
    },
    oldIdNumberLabel() {
      if (this.signatoryRecord) {
        if (this.accountRecord?.identification == this.signatoryRecord?.idNumber) {
          return this.accountIdLabel;
        }
        return `${this.accountIdLabel} / ${this.signatoryIdLabel}`;
      }
      return this.accountIdLabel;
    },
    idNumberHasChanged() {
      if (this.signatoryRecord) {
        if (
          this.clientData.idNumber == this.accountRecord?.identification &&
          this.clientData.idNumber == this.signatoryRecord.idNumber
        ) {
          return 'No';
        }
        return 'Yes';
      }
      return this.clientData.idNumber == this.accountRecord?.identification ? 'No' : 'Yes';
    },
    accountIdHasChanged() {
      return this.clientData.identification == this.accountRecord?.identification ? 'No' : 'Yes';
    },

    signatoryContactPersonNameLabel() {
      return this.getLabel(this.signatoryRecord?.surname);
    },
    accountContactPersonNameLabel() {
      return this.getLabel(this.accountRecord?.contactName);
    },
    oldContactPersonNameLabel() {
      if (this.signatoryRecord) {
        if (this.accountRecord?.contactName == this.signatoryRecord?.surname) {
          return this.accountContactPersonNameLabel;
        }
        return `${this.accountContactPersonNameLabel} / ${this.signatoryContactPersonNameLabel}`;
      }
      return this.accountContactPersonNameLabel;
    },
    contactPersonHasChanged() {
      if (this.signatoryRecord) {
        if (
          this.clientData.contactPersonName == this.accountRecord?.contactName &&
          this.clientData.contactPersonName == this.signatoryRecord.surname
        ) {
          return 'No';
        }
        return 'Yes';
      }
      return this.clientData.contactPersonName == this.accountRecord?.contactName ? 'No' : 'Yes';
    },
    newContactPersonNameIsValid() {
      if (!this.clientData.contactPersonName) {
        return 'No';
      }
      if (this.newContactPersonNameContainsNameAndSurname) {
        return 'Yes';
      }
      return 'No';
    },
    newContactPersonNameContainsNameAndSurname() {
      if (typeof this.clientData.contactPersonName === 'string') {
        const names = this.clientData.contactPersonName.split(' ');
        if (names.length > 1) {
          return true;
        }
      }
      return false;
    },

    signatoryEmailLabel() {
      return this.getLabel(this.signatoryRecord?.emailAddress);
    },
    accountEmailLabel() {
      return this.getLabel(this.accountRecord?.emailAddress);
    },
    oldEmailLabel() {
      if (this.signatoryRecord) {
        if (this.accountRecord?.emailAddress == this.signatoryRecord?.emailAddress) {
          return this.accountEmailLabel;
        }
        return `${this.accountEmailLabel} / ${this.signatoryEmailLabel}`;
      }
      return this.accountEmailLabel;
    },
    emailHasChanged() {
      if (this.signatoryRecord) {
        if (
          this.clientData.emailAddress == this.accountRecord?.emailAddress &&
          this.clientData.emailAddress == this.signatoryRecord.emailAddress
        ) {
          return 'No';
        }
        return 'Yes';
      }
      return this.clientData.emailAddress == this.accountRecord?.emailAddress ? 'No' : 'Yes';
    },

    signatoryMobileLabel() {
      return this.getLabel(this.signatoryRecord?.msisdn);
    },
    accountMobileLabel() {
      return this.getLabel(this.accountRecord?.contactNumber);
    },
    oldMobileNumberLabel() {
      if (this.signatoryRecord) {
        if (this.accountRecord?.contactNumber == this.signatoryRecord?.msisdn) {
          return this.accountMobileLabel;
        }
        return `${this.accountMobileLabel} / ${this.signatoryMobileLabel}`;
      }
      return this.accountMobileLabel;
    },
    mobileNumberHasChanged() {
      if (this.signatoryRecord) {
        if (
          this.clientData.msisdn == this.accountRecord?.contactNumber &&
          this.clientData.msisdn == this.signatoryRecord.msisdn
        ) {
          return 'No';
        }
        return 'Yes';
      }
      return this.clientData.msisdn == this.accountRecord?.contactNumber ? 'No' : 'Yes';
    },
    contactNumberHasChanged() {
      if (this.signatoryRecord) {
        if (
          this.clientData.contactNo == this.accountRecord?.contactNumber &&
          this.clientData.contactNo == this.signatoryRecord.msisdn
        ) {
          return 'No';
        }
        return 'Yes';
      }
      return this.clientData.contactNo == this.accountRecord?.contactNumber ? 'No' : 'Yes';
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),

    async retrieveClientData() {
      this.clientRetrievedStatus = 'retrieving';
      const clientDataResult = await doPermissionAction({ onSuccess: { doStoreUpdate: false } }, 'getBankInformation', {
        accountId: this.accountId,
      });
      if (clientDataResult.status === 'success') {
        this.clientData.token = clientDataResult.response.token;
        this.clientData.firstName = clientDataResult.response.firstName;
        this.clientData.idType = clientDataResult.response.idType;
        this.clientData.idNumber = clientDataResult.response.identification;
        this.clientData.identification = clientDataResult.response.identification;
        this.clientData.surname = clientDataResult.response.lastName;
        this.clientData.contactPersonName = clientDataResult.response.contactPersonName;
        this.clientData.emailAddress = clientDataResult.response.email;
        this.clientData.msisdn = clientDataResult.response.mobileNo;
        this.clientData.contactNo = clientDataResult.response.contactNo;
        this.clientRetrievedStatus = 'retrieved';
      } else {
        this.clientData.errorMessage = clientDataResult.message;
        this.clientRetrievedStatus = 'error';
      }
    },

    getLabel(value) {
      if (!value) {
        return 'Not Captured';
      }
      switch (value) {
        case 'rsaId':
          return 'RSA ID Number';
        case 'passport':
          return 'Passport';
        case 'rsaRegistrationNumber':
          return 'RSA Company Registration Number';
        case 'internationalRegistrationNumber':
          return 'International Company Registration Number';
        case 'rsaNonCompanyRegistrationNumber':
          return 'Other RSA Registration Number';
        default:
          return value;
      }
    },
    getNewLabel(value) {
      if (!value) {
        return 'Not Provided';
      }
      return this.getLabel(value);
    },

    getFieldLabel(fieldName) {
      const fullField = this.sortedRecords.find((field) => field.field === fieldName);
      return fullField.label;
    },
    // ----- Fields -----

    // ----- API calls -----
  },
};
</script>

<style>
.lfx-update-individual-signatory-table-container {
  position: relative;
  min-height: 320px;
}

#lfx-update-individual-signatory-table {
  min-width: 100%;
  position: initial;
}
</style>
