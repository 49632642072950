<template>
  <LDBModal result="info" header="Recache Value Dates" size="medium" :closable="false">
    <template #body>
      <div>
        <p>This will recalculate all Value Dates for all Currency Pairs.</p>
        <p>This may take a while.</p>
        <p>Refresh the browswer to load new data.</p>
      </div>
    </template>

    <template #footer>
      <capitec-spacer />
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'LfxRecacheValueDates',
  components: {},
  props: {},
  emits: [],
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters(['configId']),
    ...mapState([]),

    // ----- Buttons -----

    formButtons() {
      let self = this;
      return [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          action: 'click',
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'recacheValueDates',
          label: 'Recache Value Dates',
          type: 'primary',
          action: 'recacheValueDates',
          actionParams: {},
          params: { configId: +this.configId },
          guards: true,
          enabledGuards: true,
          clickEvent(response) {
            if (response.status === 'success') {
              self.$router.go(-1);
            }
          },
        },
      ];
    },
  },
  methods: {},
};
</script>
