<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <div v-if="mounted">
        <p>This will manually mark the Client or Associated Party as CRA Declined.</p>
        <p><b>NOTE:</b>This will not update the CRA status in other systems.</p>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="definition"
          :record="record"
          @change="on_change"
        />
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
        @on-api-success="onApiSuccess"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxCifApplicationDeclineCra',
  components: { LDBField },
  emits: ['on-api-success'],
  props: {},
  data() {
    return {
      mounted: true,
      params:{declinedReason:undefined}
    };
  },
  created() {
  },
  mounted() {
    this.params.declinedReason = this.record.craDeclinedReason
  },
  beforeUnmount() {
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),
    header() {
      return `Decline CIF CRA Application`;
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.record.craDeclinedReason = self.oldDeclineReason;
            self.$router.go(-1);
          },
        },

        {
          name: 'declineCifCra',
          label: 'Decline',
          type: 'primary',
          action: 'declineCifCra',
          params: { accountId: this.record.accountId, applicationId: this.record.applicationId, cifId: this.record.id },
          actionParams: this.params,
          guards: true,
          clickEvent() {
            self.$router.replace({ name: 'clientApplication' });
          },
        },
      ];
      return allButtons !== undefined ? allButtons : [];
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : '/';
    },

    // ----- Definition -----

    definition() {
      return getDefinition('application_cif');
    },

    // ----- Record -----
    clientId() {
      return this.$route.params.accountId;
    },
    applicationId() {
      return this.$route.params.applicationId;
    },

    recordName() {
      return 'application_cif';
    },
    recordId() {
      return +this.$route.params.cifId;
    },
    record() {
      let rec =
        this.$store.state?.account[this.clientId].application[this.applicationId][this.recordName]?.[this.recordId];
      return rec;
    },

    // ----- Fields -----
    
    viewFields() {
      return [
        {
          fieldName: 'declinedReason',
          name: 'declinedReason',
          label: 'CRA Declined Reason',
          datatype: 'memo',
          default: null,
          mandatory: true,
          allowNullValues: true,
        },
      ];
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Fields -----

    // setFieldToCurrentValue() {
    //   for (let f = 0; f < this.viewFields.length; f++) {
    //     let field = this.viewFields[f];
    //     this.newRecord[field.name] = this.record[field.name];
    //     this.mounted = true;
    //   }
    // },

    on_change({ field, value }) {
      this.params[field] = value;
    },

    // ----- API calls -----
    async onApiSuccess(_responseObject) {},
  },
};
</script>

<style></style>
