<template>
  <capitec-menu
    style="overflow-x: hidden; min-width: 275px"
    :header="userDisplayName"
    :sub-header="userAccountName"
    :header-avatar-text="avatarLetters"
    :header-avatar-color="avatarColor"
    hidefooter="true"
  >
    <template v-for="section in menu" :key="section.title">
      <template v-if="section.menuItems?.filter((menuItem) => menuItem.guards).length">
        <capitec-menu-separator></capitec-menu-separator>
        <template v-for="item in section.menuItems" :key="item.routeName">
          <capitec-menu-item
            v-if="item.guards"
            :icon="item.icon"
            :label="item.title"
            :badge-number="item.badgeNumber"
            :badge-color="item.badgeColor"
            @click="onMenuItemClick(item)"
          >
          </capitec-menu-item>
        </template>
      </template>
    </template>
  </capitec-menu>
</template>

<script>
import Menu from '@/router/menu';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { colorFromId } from '@/landobyte_vue/ldb_utils.ts';

export default {
  emits: ['onMenuItemClick'],
  data() {
    return {
      overflowMenuItems: null,
    };
  },
  computed: {
    ...mapGetters(['permissions', 'userPermissionRequests', 'myAccount', 'isDevelopment', 'isDevEnv']),
    ...mapState(['authUser', 'user', 'account', 'auth']),

    // ----- Menu -----

    myAccountIsLocked() {
      // return (this.account?.[this.authUser?.accountId]?.userReviewStatus === 'nonCompliant' && this.myAccount.accountLevel !== 'bank') ? true : false; //2024-09-19 Bank clients should be disabled if no review done
      return (this.account?.[this.authUser?.accountId]?.userReviewStatus === 'nonCompliant') ? true : false; 
    },

    menu() {
      const self = this;
      const menu = [
        {
          title: 'Home',
          menuItems: [{ title: 'Home', routeName: '', routePath: '/', icon: 'system/home', guards: true }],
        },
        {
          title: 'Operations',
          menuItems: [
            {
              title: 'Dashboards',
              routeName: 'dashboards',
              routePath: '/dashboards',
              icon: 'material/dashboard',
              guards: false && this.isDevelopment, // TODO  - LEAVE FOR NOW
            },
            {
              title: 'Transactions',
              routeName: 'transactions',
              routePath: '/transactions',
              icon: 'product/transact-grey',
              guards: !this.myAccountIsLocked,
            },
            {
              title: 'Clients',
              routeName: 'clients',
              routePath: '/clients',
              icon: 'system/client-insights',
              guards: !this.myAccountIsLocked && this.permissions.clientsMenu,
            },
            {
              title: 'Beneficiaries',
              routeName: 'beneficiaries',
              routePath: '/beneficiaries',
              icon: 'system/client-insights',
              guards: false && this.permissions.beneficiariesMenu, // TODO: this seems duplicate functionality to My Org / Beneficiaries - avr 2023-02-08
            },
            // {
            //   title: 'Search',
            //   routeName: 'search',
            //   routePath: '/search',
            //   icon: 'system/search',
            //   guards: true,
            // },
          ],
        },
        {
          title: 'Finding',
          menuItems: [
            {
              title: 'Search',
              routeName: 'search',
              routePath: '/search',
              icon: 'system/search',
              guards: !this.myAccountIsLocked,
            },
            {
              title: 'Reports',
              routeName: 'reports',
              routePath: '/reports',
              icon: 'system/notes',
              guards: true,
            },
            {
              title: 'Uploads and Downloads',
              routeName: 'uploadsAndDownloads',
              routePath: '/uploadsAndDownloads',
              icon: 'system/download',
              guards: !this.myAccountIsLocked && this.permissions.downloadMenu && this.isDevEnv,
            },
          ],
        },
        {
          title: 'Secondary',
          menuItems: [
            {
              title: 'Intermediaries',
              routeName: 'intermediaries',
              routePath: '/intermediaries',
              icon: 'material/folder_shared',
              guards: !this.myAccountIsLocked && this.permissions.intermediariesMenu,
            },
            {
              title: 'Users',
              routeName: 'users',
              routePath: '/users',
              icon: 'material/local_library',
              guards: this.permissions.usersMenu,
            },
            // {
            //   title: 'Reports',
            //   routeName: 'reports',
            //   routePath: '/reports',
            //   icon: 'system/notes',
            //   guards: true,
            // },
          ],
        },
        {
          title: 'System',
          menuItems: [
            {
              title: 'System Admin',
              routeName: 'admin',
              routePath: '/admin',
              icon: 'system/access',
              badgeNumber: this.permissionRequestsNumber,
              badgeColor: '#08A6B5',
              guards: !this.myAccountIsLocked && this.permissions.adminMenu,
            },
            {
              title: 'System Config',
              routeName: 'config',
              routePath: '/config',
              icon: 'system/filter',
              guards: !this.myAccountIsLocked && this.permissions.configMenu,
            },
          ],
        },
        {
          title: 'Settings',
          menuItems: [
            {
              title: 'My Organisation',
              routePath: this.myAccountPath,
              icon: 'system/settings',
              guards: !this.myAccountIsLocked,
            },
            { title: 'My User', routePath: this.myUserPath, icon: 'system/profile', guards: !this.myAccountIsLocked },
          ],
        },
        {
          title: 'Information',
          menuItems: [
            {
              title: 'Help',
              routeName: 'help',
              routePath: '/help',
              icon: 'system/knowledge',
              guards: true && this.isDevelopment, // TODO - LEAVE FOR NOW
            },
          ],
        },
        {
          title: 'Auth',
          menuItems: [
            {
              title: 'Switch Organisation',
              routeName: 'switchAccount',
              routePath: '/auth/switch_account',
              icon: 'material/sync_alt',
              guards: false,
            },
            {
              title: 'Sign Out',
              routeName: 'signout',
              routePath: '/auth/logout',
              icon: 'material/logout',
              guards: true,
            },
          ],
        },
      ];
      return menu;
    },

    permissionRequestsNumber() {
      return this.permissions.grantUserPermissions || this.permissions.rejectUserPermissions
        ? Object.keys(this.userPermissionRequests).length
        : undefined;
    },

    // ----- User -----

    authUserAccountId() {
      return this.authUser?.accountId;
    },
    authUserAccountLevel() {
      return this.authUser?.accountLevel;
    },
    authUserId() {
      return this.authUser?.id;
    },

    userDisplayName() {
      return this.user?.[this.authUserId]?.userDisplayName;
    },
    userAccountName() {
      return this?.account?.[this.authUserAccountId]?.name;
    },

    myHomePath() {
      // TODO: get from user settings
      return '/dashboards';
    },
    myAccountPath() {
      return `/account/${this.authUserAccountId}`;
    },
    myUserPath() {
      return `/user/${this.authUserId}`;
    },

    upperLetters() {
      return 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    },
    unimportantWords() {
      return ['van', 'von', 'du', 'de', 'janse', 'jansen', 'le', 'of', 'and'];
    },
    avatarLetters() {
      let self = this;
      let allLetters = this.userDisplayName
        .split(' ')
        .map((l) => l[0])
        .filter((l) => l)
        .join('');
      let upperLetters = this.userDisplayName
        .split(' ')
        .filter((w) => self.unimportantWords.indexOf(w.toLowerCase()) === -1)
        .map((l) => l[0])
        .filter((l) => self.upperLetters.indexOf(l) > -1)
        .join('');
      if (allLetters.length < 3) {
        return allLetters.toUpperCase();
      } else {
        return `${upperLetters[0]}${upperLetters[1]}`;
      }
    },
    avatarColor() {
      return colorFromId(this.authUserId);
    },
  },
  methods: {
    onMenuItemClick(item) {
      // if (item.items.length === 0) {
      if (!item.subItems?.length) {
        // this.$router.push({ path: `/${item.name}` });  avr 2021-08-26 - path name and path route not neccessarily the same
        // this.$router.push({ name: item.routeName });
        this.$router.push({ path: item.routePath });
      }
      this.$emit('onMenuItemClick', item);
    },
    onMenuSubItemClick(item, subItem) {
      // this.$router.push({ name: subItem.routeName });
      this.$router.push({ path: subItem.routePath });
    },
    // getIcon(item) {
    //   return item.icon || 'material/remove';
    // },
    goToMyAccount() {
      // this.$router.push({ path: `/${this.authUserAccountLevel}/${this.authUserAccountId}` });
      this.$router.push({ name: 'account', params: { accountId: this.authUserAccountId } });
    },
    goToMyUser() {
      this.$router.push({ path: `/user/${this.authUserId}` });
    },
  },
};
</script>

<style>
capitec-search-field.search {
  margin-top: 10px;
}
</style>
