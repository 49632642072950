<template>
  <LDBModal result="info" :header="header" size="x-large">
    <template #body>
      <div v-if="mounted">
        <ol>
          <li>The user will perform multi factor authentication using an OTP over SMS instead of USSD.</li>
          <li>This is required when a user is not on a South African mobile network.</li>
        </ol>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="definition"
          :record="submitRecord"
          @change="on_change"
        />
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBField from '@/landobyte_vue/LDBField.vue';
import { incrementDate, startOfTodayAtUtcZero } from '@/sharedUtils/LdbTimeUtils';

export default {
  name: 'LfxAddOtpExclusion',
  components: { LDBField },
  props: {},
  data() {
    return {
      mounted: true,
      submitRecord: {
        otpType: null,
        mfaExclusionExpiry: 
          incrementDate({
            type: 'month',
            offset: 6
          },
          startOfTodayAtUtcZero()
        ).toDateString(),
      }
    };
  },
  created() {},
  mounted() {
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),
    header() {
      return `Change to OTP for ${this.userDisplayName}`;
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.replace({ name: self.cancelRoute });
          },
        },

        {
          name: 'addOtpExclusion',
          label: 'Change',
          type: 'primary',
          action: 'addOtpExclusion',
          params: { userId: this.record.id },
          actionParams: this.submitRecord,
          guards: self.record.editUserLogin_buttonGuard,
          enabledGuards: !this.has_validation_errors,
          clickEvent() {
            self.$router.go(-1);
          },
        },
      ];
      return allButtons !== undefined //.filter((button) => button.guards)
        ? allButtons // .filter((button) => button.guards)
        : [];
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : '/';
    },

    // ----- Definition -----

    definition() {
      return getDefinition('user');
    },

    // ----- Record -----

    recordName() {
      return 'user';
    },
    recordId() {
      return this.$route.params.userId;
    },
    record() {
      let rec = this.$store.state?.[this.recordName]?.[this.recordId];
      return rec;
    },

    userDisplayName() {
      return this.record?.userDisplayName;
    },

    has_validation_errors() {
      return this.record.has_validation_errors;
    },
    // ----- Fields -----

    fields() {
      return this.definition.fields;
    },
    view() {
      return 'addOtpExclusion';
    },
    urgViewFields() {
      let res = [];
      for (let k in this.fields) {
        let field = this.fields[k];
        if (field.views[this.view] === true) {
          field.fieldname = k;
          res.push(field);
        }
      }
      return res;
    },
    viewFields() {
      return { 
        otpType: {
            name: 'otpType',
            fieldname: 'otpType',
            label: 'OTP Type',
            datatype: {
              option: {
                optionType: 'text',
                options: [
                  { id: 'sms', name: 'SMS' },
                  { id: 'email', name: 'Email'}
                ],
              },
            },
            default: null,
        },
          mfaExclusionExpiry: {
            name: 'mfaExclusionExpiry',
            fieldname: 'mfaExclusionExpiry',
            label: 'MFA Exclusion Expiry',
            datatype: {
              datetime: {
                type: 'date',
                format: 'human',
                input: 'calendar',
                minDate: { type: 'day', offset: 1 },
                maxDate: { type: 'month', offset: 6 },
              },
            },
            default: null,
        },
      }
    }
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Fields -----

    on_change({ field, value }) {
      this.submitRecord[field] = value;
    },

    // ----- API calls -----
  },
};
</script>

<style></style>
