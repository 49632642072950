<template>
  <template v-if="['none', 'retrieving'].includes(applications_retrievalStatus)">
    <LDBLoader />
    <p v-if="applications_retrievalAttemptsFailed == 0">Loading...</p>
    <p v-else>Load failed. Retrying...{{ applications_retrievalAttemptsFailed }}</p>
  </template>

  <template v-else-if="applications_retrievalStatus === 'retrieved'">
    <div v-if="accountRecord.currentApplicationStatus === 'retrievedFromBank'">
      The Client was retrieved from the Bank and therefore no Applications are associated with the Client.
    </div>
    <LFXListView v-else :definition="definition" :records="applicationRecords" />
  </template>

  <template v-else-if="applications_retrievalStatus === 'failed'">
    <p>Failed to load Applications after {{ applications_retrievalAttemptsFailed + 1 }} attempts.</p>
  </template>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXListView from '@/views/general/LFXListView.vue';

export default {
  name: 'LfxClientApplicationsList',
  components: { LFXListView },
  props: {},
  data() {
    return {};
  },
  created() {
    // this.account?.[this.accountId]?.getApplications();
    this.accountRecord?.activate('applications');
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    // ----- Account Record -----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },

    // ----- Application Records -----

    applicationRecords() {
      return this.accountRecord?.applications;
    },

    applications_retrievalStatus() {
      return this.accountRecord?.applications_retrievalStatus;
    },
    applications_retrievalAttemptsFailed() {
      return this.accountRecord?.applications_retrievalAttemptsFailed;
    },

    // records() {
    //   let res = {};
    //   for (let b in this.account?.[this.accountId]?.application) {
    //     let ben = this.account?.[this.accountId]?.application[b];
    //     res[ben.id] = ben;
    //   }
    //   return res;
    // },

    definition() {
      return getDefinition('application');
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
