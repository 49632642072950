export default {
  props: {  
    widget:{type:Object,required:true},
    changingSettings:{type:Boolean,default:false}
  }, 
  data() {
    return {
      pageNumber:1,
      itemsPerPage:5
    }  
  },
  computed:{
    dynamicClass() {
      let res = [];
      if (this.changingSettings) {
        res.push('currently-setting');
      }
      return res.join(' ');
    },
    spanStyle() {
      let res = {'grid-column':`span ${this.widget.size}`};
      return res;
      
    },
    pagedItems() {  
      let res = [];
      let startItem = ( this.pageNumber -1 ) * this.itemsPerPage;
      for ( let d = 0 ; d < this.items.length ; d ++  ) { 
        let record = this.items[d];
        if (d >= startItem) {
          res.push(record);
        }
        if (res.length === this.itemsPerPage) {
          break;
        }
      }
      return res;
    },

  },
  methods:{
    formatCurrency(amount) {
      return `${amount/100}`
        .split(' ')
        .join('')
        .split('')
        .reverse()
        .join('')
        .replace(/([0-9]{3})/g, '$1,')
        .split('')
        .reverse()
        .join('')
        .replace(/^,/, '')
        .split(',')
        .join(' ');
    },
    onPage(page) {
      this.pageNumber = page;
    },
  }

}