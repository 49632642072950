//@ts-nocheck
import VueStore from 'vue-class-store';

import { LdbEmailLogInt, LdbEmailLogDef } from '@/definitions/LdbEmailLogDef';

import { LfxModel, LfxDefinition } from './LfxModel';

@VueStore
class LdbEmailLogMdl extends LfxDefinition {
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
export class LdbEmailLog extends LfxModel {
  constructor(data, state) {
    super({definition_name:'emailLog',...data}, state);    
  }
  get displayFKLink() {
    return false
  }
}

export function Definition(context, name: string) {
  return new LdbEmailLogMdl(LdbEmailLogDef, LdbEmailLog, context, name, {});
}
