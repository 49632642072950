<template>
  <div class="lfx-signatory-order-container">
    <draggable
      :list="order"
      :disabled="false"
      item-key="name"
      class="list-group"
      ghost-class="ghost"
      @start="dragging = true"
      @end="dragEnd"
    >
      <template #item="{ element }">
        <LfxSignatoryTile
          v-if="!!getRecord(element)"
          :position="order.indexOf(element)"
          :record="getRecord(element)"
          :nameFields="nameFields"
          :recordCount="recordCount"
          :fields="fields"
          :showCheckbox="showCheckbox"
          :checkEnabled="getRecord(element)?.signerRequired !== 'mandatory'"
          @on-check="onCheck"
          @arrow-down-click="arrowDownClick"
          @arrow-up-click="arrowUpClick"
        />
      </template>
    </draggable>
  </div>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import draggable from 'vuedraggable';

import LfxSignatoryTile from './LfxSignatoryTile.vue';

export default {
  components: { draggable, LfxSignatoryTile },
  props: {
    order: { type: Array, required: true },
    records: { type: Object, required: true },
    fields: { type: Object, required: true },
    nameFields: {
      type: Array,
      default() {
        return ['name'];
      },
    },
    showCheckbox: { type: Boolean, default: false },
  },
  emits: ['on-order-change', 'on-record-check'],
  data() {
    return {
      dragging: false,
      drag: false,
      internalArray: [],
    };
  },
  created() {},
  mounted() {
    for (let o = 0; o < this.order.length; o++) {
      let order = this.order[o];
      let rec = this.records[order];
      this.internalArray.push(rec);
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    recordCount() {
      return Object.keys(this.records).length;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    getPosition(element) {
      return this.order.indexOf(element);
    },
    // checkedRecord(evt, record) {
    //   record.checked = evt.detail.new;
    // },
    onCheck(payload) {
      // this.$emit('on-order-change', this.order);
      this.$emit('on-record-check', payload);
    },
    getRecord(element) {
      return this.records[element];
    },
    dragEnd() {
      this.dragging = false;
      let array = Array.from(this.order);
      this.$emit('on-order-change', array);
    },
    arrowUpClick(index) {
      let prev = this.order[index - 1];
      let current = this.order[index];
      let array = Array.from(this.order);
      array[index] = prev;
      array[index - 1] = current;
      this.$emit('on-order-change', array);
    },
    arrowDownClick(index) {
      let next = this.order[index + 1];
      let current = this.order[index];
      let array = Array.from(this.order);
      array[index] = next;
      array[index + 1] = current;
      this.$emit('on-order-change', array);
    },
  },
};
</script>


<style>
.list-group-item {
  /* border: 3px solid red; */
  /* min-height:50px; */
}
</style>
