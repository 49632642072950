<template>
  <div class="capitec-item-view-field">
    <capitec-label class="capitec-item-view-field-label" :label="`${fieldLabel}`"></capitec-label>
    <template v-if="slotMode === true">
      <slot> </slot>
    </template>
    <template v-else>
      <capitec-tag
        v-if="datatypeName === 'option' && displayValue"
        :label="optionLabel"
        v-bind="optionType"
        :style="optionStyle"
      ></capitec-tag>
      <capitec-tag v-else-if="displayAsTag" :label="`${displayValue}`" v-bind="tagType" :style="tagStyle"></capitec-tag>
      <capitec-label
        v-else
        class="capitec-item-view-field-value"
        :label="`${displayValue}`"
        type="strong"
      ></capitec-label>
    </template>
  </div>
</template>

<script>
import { ldb_field_mixin } from './ldb_field_mixin.js';
import { LDB_GRAPH_COLORS } from './ldb_colors.js';

export default {
  mixins: [ldb_field_mixin],
  components: {},
  props: {
    slotMode: {
      type: Boolean,
      default: false,
    },
    displayValue: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    fieldLabel() {
      if (typeof this.field.label === 'function') {
        return this.field.label();
      }
      return this.field.label;
    },
    optionStyle() {
      return {};
    },
    datatype() {
      return this.field.datatype;
    },
    datatypeName() {
      let dt = this.datatype;
      if (typeof dt === 'string') {
        return dt;
      } else if (typeof dt === 'object') {
        return Object.keys(dt)[0];
      }
    },
    test() {
      // return this.datatype.option.options.length
    },
    optionLabel() {
      let res = '';
      for (let o = 0; o < this.datatype.option.options.length; o++) {
        let option = this.datatype.option.options[o];
        if (option.id === this.value) {
          res = option.name;
          break;
        }
      }
      return res;
    },

    optionType() {
      let tagType;
      for (let o = 0; o < this.datatype.option.options.length; o++) {
        let option = this.datatype.option.options[o];
        if (option.id === this.value) {
          tagType = option.tagType;
          break;
        }
      }
      return this.propertiesForTagType(tagType);
    },

    displayAsTag() {
      if (typeof this.field.displayType?.displayAsTag === 'string') {
        return this.record[this.field.displayType?.displayAsTag];
      }
      return !!this.field.displayType?.displayAsTag;
    },
    tagStyle() {
      let tagType = undefined;
      if (this.field.displayType?.tagTypeFunctionName) {
        tagType = this.record[this.field.displayType.tagTypeFunctionName];
      }
      return tagType;
    },
    tagType() {
      return this.propertiesForTagType(this.tagStyle);
    },
  },
  methods: {
    propertiesForTagType(tagType) {
      if (!tagType) {
        return { type: 'custom', 'label-color': '#7C7C7C', color: '#F5F5F5' };
      }
      if (tagType.graph) {
        let tag = { type: 'custom', 'label-color': '#7C7C7C', color: '#F5F5F5' };
        if (LDB_GRAPH_COLORS[tagType.graph]) {
          tag.color = LDB_GRAPH_COLORS[tagType.graph].color;
          tag['label-color'] = LDB_GRAPH_COLORS[tagType.graph]['label-color'];
        }
        return tag;
      }
      if (tagType.custom) {
        return { type: 'custom', 'label-color': tagType.custom.labelColor, color: tagType.custom.color };
      }
      if (tagType === 'cancelled') {
        return { type: 'custom', 'label-color': '#FFFFFF', color: '#AAAAAA' };
      }
      return { type: tagType };
    },
  },
};
</script>
<style>
.capitec-item-view-field-value {
  flex: 1;
  white-space: nowrap !important;
}

.capitec-item-view-field-label {
  flex: 1;
  white-space: nowrap !important;
  max-width: 300px;
}

.capitec-item-view-field {
  display: flex;
  grid-template-columns: minmax(200px, auto) minmax(auto, 300px);
  border-bottom: 1px solid #eeeeee;
  padding: 5px 0px 5px 0px;
  align-items: flex-start;
  flex: 1;
}

div.ldb-item-view-field {
  /* border: 1px solid #DDD; */
  border-radius: var(--theme-input-border-radius, 4px);
}

.container {
  width: 100%;
  position: relative;
}

.container > .label {
  position: absolute;
  left: var(--theme-input-padding-left, 16px);
  top: var(--theme-input-label-padding-top, 16px);
  z-index: 10;
  padding: 0px;
  transition: transform 150ms ease-out 0s, font-size 150ms ease-out 0s, padding 150ms ease-out 0s;
  background-color: var(--theme-input-background-color, #ffffff);
  border-radius: var(--theme-input-border-radius, 4px);
  color: var(--theme-input-label-font-color, #4e6066);
  font-family: var(--theme-input-label-font-family, Arial, Helvetica, sans-serif);
  font-size: var(--theme-input-label-font-size, 14px);
  line-height: 14px;
  font-weight: var(--theme-input-label-font-weight, normal);
}

.container.completed > .label {
  transform: translateX(var(--theme-input-label-transform-x)) translateY(var(--theme-input-label-transform-y));
  font-size: var(--theme-input-label-font-size, 14px);
  padding: 0px var(--theme-input-label-padding-sides, 4px);
  visibility: var(--theme-floating-label-visibility);
}

.container > .field {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container > .field {
  box-sizing: border-box;
  outline: 0px;
  width: 100%;
  transition: border 150ms ease-out 0s;
  background-color: var(--theme-input-background-color, #ffffff);
  border-width: var(--theme-input-border-width);
  border-style: solid;
  border-color: transparent;
  /* var(--theme-input-border-color, #E1E1E1); */
  border-radius: var(--theme-input-border-radius, 4px);
  color: var(--theme-input-font-color, #4e6066);
  font-family: var(--theme-input-font-family, Arial, Helvetica, sans-serif);
  font-size: var(--theme-input-font-size, 16px);
  font-weight: var(--theme-input-font-weight, normal);
  padding-top: var(--theme-input-padding-top, 8px);
  padding-bottom: var(--theme-input-padding-bottom, 14px);
  padding-left: var(--theme-input-padding-left, 10px);
  padding-right: var(--theme-input-padding-right, 10px);
}

.container.valueSelected > .label {
  transform: translateY(var(--theme-input-label-transform-y));
  font-size: var(--theme-date-time-picker-focussed-label-font-size, 0.75em);
  font-size: 0.86em;
  padding: var(--theme-date-time-picker-focussed-label-padding, 0px 2px);
}

.ldb-item-view-field__value.field {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

div.ldb-item-view-field__value {
  /* margin-top:4px; */
  /* padding-top:2px; */
  transform: translateY(2px);
  border: 1px solid transparent !important;
}

label.ldb-item-view-field__label {
  opacity: 0.5;
}
</style>
