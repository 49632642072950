<template>
  <div v-if="records !== undefined && recordsExist" class="lfx-list-view-container paged-table-wrapper">
    <template v-if="showSearchBar">
      <div class="paged-table-search-bar">
        <LDBSearchBar
          @on-selector-change="onSearchChange"
          @on-search-bar-loaded="onSearchBarLoaded"
          :chips="search.chips"
          :choices="search.choices"
          :ranges="search.ranges"
          :searchFields="search.searchFields"
        />
      </div>
    </template>
    <div class="paged-table">
      <table-lite
        v-if="definition.view.list === true"
        id="lfx-list-view"
        :is-static-mode="true"
        :is-slot-mode="true"
        :has-checkbox="false"
        :is-loading="false"
        :is-re-search="false"
        :pageSize="pageSize"
        :sortable="sortable"
        :columns="tableColumns"
        :rows="sortedRecords"
        :total="setTotal || sortedRecords.length"
      >
        <template v-for="(col, i) of tableColumns" v-slot:[col.field]="data" :key="i">
          <LDBField view="list" :field="col.col" :fieldname="col.field" :definition="definition" :record="data.value" />
        </template>
      </table-lite>

      <div v-else class="lfx-list-tile-container">
        <component
          v-for="record in sortedRecords"
          :key="record.id"
          :is="definition.view.list"
          :definition="definition"
          :record="record"
        />
      </div>
    </div>
  </div>
  <p v-if="!recordsExist && !isLoading">No items to display</p>
  <p v-if="!recordsExist && isLoading">Loading...</p>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { internal_sort } from '@/db-interface/db_object';
import LDBField from '@/landobyte_vue/LDBField.vue';
import TableLite from './TableLite.vue';
import { populateLabelFields } from '@/utils/index'

export default {
  components: { TableLite, LDBField },
  props: {
    titleBar: { type: Object, required: false },
    definition: { type: Object, required: true },
    records: { type: Object, required: true },
    search: { type: Object },
    pageSize: { type: Number, default: 100 },
    defaultSort: { type: Object, required: false },
    setTotal: {type: Number, required: false, default: 0},
  },
  data() {
    return {
      sortable: this.defaultSort || { order: 'id', sort: 'asc' },
      isLoading: false,
      searchBarLoaded:false,
      searchSelectors: {},
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account', 'country', 'user', 'currency', 'authUser']),
    showSearchBar() {
      return this?.search?.searchFields || this?.search?.chips !== undefined || this?.search?.choices;
    },
    searchTerms() {
      let res = {};
      let count = 0;
      if (this?.search?.searchFields !== undefined) {
        for (let f = 0; f < this.search.searchFields.length; f++) {
          let field = this.search.searchFields[f];
          let selector = this.searchSelectors[field];
          if (selector !== undefined && selector.like !== undefined) {
            count = count + 1;
            res[field] = selector.like;
          }
        }
      }
      if (count === 0) {
        return undefined;
      } else {
        return res;
      }
    },
    search_string() {
      return this.search_text.toLowerCase();
    },
    recordsExist() {
      const keyLength = Object.keys(this.records).length;
      if (keyLength === 0) {
        return false;
      } else {
        return true;
      }
    },

    sortDirection() {
      if (this.defaultSort) {
        return this.defaultSort.sort;
      }
      return this.definition.sortAscending ? 'asc' : 'desc';
    },
    sortField() {
      if (this.defaultSort) {
        return this.defaultSort.order;
      }
      return this.definition.sortField;
    },
    sortedRecords() {
      let recordList = [];
      if (this.records !== undefined) {
        for (let r in this.records) {
          let record = this.records[r];
          if (this.passesSearch(record) !== false) {
            populateLabelFields(record,'list')
            recordList.push(record);
          }
        }
      }
      // let sortField = this.definition.sortField;
      // let sortAscending = this.definition.sortAscending;
      let sortField = this.sortField;
      let sortAscending = this.sortDirection;
      const sortFunction = (a, b) => {
        return internal_sort(a, b, sortField, sortAscending);
      };
      let sortedRecords = recordList.sort(sortFunction);
      return sortedRecords;
    },
    renderFunctions() {
      return {
        id: this.renderId,
        option: this.renderOption,
        foreignKey: this.renderForeignKey,
        action: this.renderAction,
        currency: this.renderCurrency,
        datetime: this.renderDatetime,
        map: this.renderMap,
        text: this.renderText,
        memo: this.renderMemo,
        integer: this.renderInteger,
        percentage: this.renderPercentage,
        boolean: this.renderBoolean,
        json: this.renderJson,
        uuid: this.renderUuid,
      };
    },
    tableColumns() {
      let self = this;
      let res = [];
      let key = true;
      for (let f = 0; f < this.definition.list_view_fields.length; f++) {
        let field = this.definition.list_view_fields[f];
        var fieldDisplayForView = field.views.list;
        if (typeof fieldDisplayForView === 'function') {
          fieldDisplayForView = fieldDisplayForView({
            authUser: this.authUser,
            view: 'list',
            routeMetaData: this.$route.meta,
          });
        }
        if (fieldDisplayForView) {
          let column = {
            col: field,
            field: field.name,
            label: field.label,
            isKey: key,
            sortable: true,
          };
          key = false;
          res.push(column);
        }
      }
      return res;
    },
    tableData() {
      return this.sortedRecords;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    evaluateSelector(field, selector, record) {
      let operator = Object.keys(selector)[0];
      let recordValue = record[field];
      let selectorValue = selector[operator];
      if (operator === 'eq') {
        return recordValue === selectorValue;
      } else if (operator === 'in') {
        return selectorValue.includes(recordValue);
      } else if (operator === 'neq') {
        return recordValue === selectorValue;
      } else if (operator === 'notIn') {
        return !selectorValue.includes(recordValue);
      } else if (operator === 'like') {
        return true; // likes are handled in passesSearch
      } else return false; // this might not be correct, it means that an unimplemented selector was set in a chip.
    },
    evaluateSelectors(record) {
      let res = true;
      for (let f in this.searchSelectors) {
        let selector = this.searchSelectors[f];
        res = res && this.evaluateSelector(f, selector, record);
        if (res === false) {
          break;
        }
      }
      return res;
    },
    onSearchBarLoaded(searchSelector) {
      this.searchBarLoaded = true;
      this.onSearchChange(searchSelector);
    },
    onSearchChange(search) {
      this.searchSelectors = search;
    },
    passesSearch(record) {
      let res = false;
      if (this.searchTerms === undefined) {
        res = true;
      } else {
        for (let f in this.searchTerms) {
          let value = this.searchTerms[f];
          res = res || record[f].includes(value);
        }
      }
      if (res) {
        return this.evaluateSelectors(record);
      } else {
        return res;
      }
    },

    getRecord(data, col) {
      return data.value;
    },
    getComponent(data, field) {
      return 'LDBField';
    },
    tableLoadingFinish(elements) {
      this.isLoading = false;
    },
    onTitleBarButtonClick(button) {
      this.$router.push({ name: button.routeName });
    },

    renderId(item, fieldName, value, field) {
      let self = this;
      let accountLevelLink = 'not set';
      let link = '/this/did/not/work';
      if (item.definition.name === 'account') {
        let accountLevelLink = item.accountLevel;
        if (item.accountLevel === 'intermediaryBranch') {
          accountLevelLink = 'intermediary';
        }
        link = `/${accountLevelLink}/${item.id}/info`;
      } else {
        link = `/${item.definition.name}/${item.id}`;
      }

      let clickFunction = function () {
        self.$router.push(link);
      };

      return `<router-link to='${link}'> 
      ${item[field.datatype.id.descriptionField]}
      </router-link>`;
      let elm = document.createElement('capitec-hyperlink');
      elm.setAttribute('label', item[field.datatype.id.descriptionField]);

      return elm;
    },
    renderOption(item, fieldName, value, field) {
      let displayValue = field.options[item[value]];
      let elm = document.createElement('capitec-label');
      elm.setAttribute('label', displayValue);
      return elm;
    },
    renderForeignKey(item, fieldName, value, field) {
      let self = this;
      let foreignKey = field.datatype.foreignKey;
      let linkField = foreignKey.linkField;
      let linkTable = foreignKey.linkTable;
      let cutOffAndThrowAwayPrefixFixForNow = linkTable.substr(3, linkTable.length).toLowerCase();
      let displayField = foreignKey.displayField;
      let linkRecord = this.$store.state[cutOffAndThrowAwayPrefixFixForNow][value];
      let displayValue = linkRecord[displayField];
      let elm = document.createElement('capitec-hyperlink');
      elm.setAttribute('label', displayValue);
      elm.addEventListener('click', function () {
        self.$router.push(`/itemview/${self.definition.name}/${item.id}`);
      });
      return elm;
    },
    renderAction(item, fieldName, value, field) {
      return value;
    },
    renderCurrency(item, fieldName, value, field) {
      return value;
    },
    renderDatetime(item, fieldName, value, field) {
      return value;
    },
    renderMap(item, fieldName, value, field) {
      return value;
    },
    renderText(item, fieldName, value, field) {
      return value;
    },
    renderMemo(item, fieldName, value, field) {
      return value;
    },
    renderInteger(item, fieldName, value, field) {
      return value;
    },
    renderPercentage(item, fieldName, value, field) {
      return value;
    },
    renderBoolean(item, fieldName, value, field) {
      return value;
    },
    renderJson(item, fieldName, value, field) {
      return value;
    },
    renderUuid(item, fieldName, value, field) {
      return value;
    },
    onItemClick(evt, item, fieldName, value) {},
    datatypeName(field) {
      let dt = field.datatype;
      if (typeof dt === 'string') {
        return dt;
      } else if (typeof dt === 'object') {
        return Object.keys(dt)[0];
      }
    },
    columnRenderer(item, fieldName, value) {
      let field = this.definition.indexed_fields[fieldName];
      // 'text' | 'memo' | 'integer' | 'percentage' | 'boolean' | 'json' | 'uuid'
      let datatypeName = this.datatypeName(field);
      let renderFunction = this.renderFunctions[datatypeName];
      return renderFunction(item, fieldName, value, field);
    },
  },
};
</script>

<style>
/* div.lfx-list-view-container {
  
} */

.lfx-list-tile-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  overflow-y: auto;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}

.lfx-list-view-container {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  /* border: 3px dotted black; */
}

div.list-view-title {
  font-weight: bold;
  font-size: 24px;
  margin: 20px 20px 20px 20px;
}
</style>
