<template>
  <LFXListView v-if="hasListLoaded" :definition="definition" :records="records" :defaultSort="defaultSort" />
  <template v-else>
    <LDBLoader />
  </template>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXListView from '@/views/general/LFXListView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxTransactionLogList',
  components: { LFXListView, LDBLoader },
  props:  { refreshCounter: { type: Number, required: false, default: 0}},
  data() {
    return {
      hasListLoaded:false
    };
  },
  async created() {
    this.loadData()
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['transaction']),
    transactionId() {
      return +this.$route.params.transactionId;
    },
    records() {
      return this.transaction?.[this.transactionId]?.transaction_file_log || {};
    },
    definition() {
      return getDefinition('transaction_file_log');
    },
    defaultSort() {
      return { order: 'timestamp', sort: 'desc' }
    }
  },
  watch:{
    refreshCounter(newCount,oldCount) {
      if (newCount !== oldCount) {
        this.loadData();
      }
    }
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    async loadData() {
      this.hasListLoaded = false;
      await doPermissionAction(this,'getTransactionFileLogs',{transactionId:this.transactionId});
      this.hasListLoaded = true;
    }
  },
};
</script>

<style>
/* div.container {
} */
</style>
