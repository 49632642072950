<template>
  <LDBModal type="result" result="info" :header="header">
    <template #body>
      <LDBItemView
        :record="importBatchRecord"
        :definition="definition"
        view="item"
        :showNextButton="false"
        :action="undefined"
        :params="{}"
      />
    </template>
    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :viewGuards="button.guards" 
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import LDBButton from '@/landobyte_vue/LDBButton.vue';

export default {
  name: 'LfxImportBatchDetails',
  components: { LDBItemView,LDBButton },
  props: {},
  data() {
    return {
      mounted: false
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters(['permissions']),
    ...mapState(['importBatch']),
    header() {
      return 'Data Import Batch';
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Close',
          type: '',
          action: 'click',
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'downloadSourceFile',
          label: 'Download Source File',
          type: '',
          action: 'downloadImportBatchUploadFile',
          enabledGuards: !!this.importBatchRecord.uploadFileS3Key,
          params: { dataImportId: this.importBatchId },
          actionParams: {},
        },
        {
          name: 'downloadResultFile',
          label: 'Download Error File',
          type: '',
          action: 'downloadImportBatchErrorFile',
          enabledGuards: !!this.importBatchRecord.errorFileS3Key && this.importBatchRecord.errorLineCount > 0,
          params: { dataImportId: this.importBatchId },
          actionParams: {},
        },
        {
          name: 'updateStatusButton',
          label: 'Update Batch Status',
          type: '',
          action: 'click',
          guards: this.permissions.updateImpotBatchStatus,
          clickEvent() {
            self.$router.push({ name: 'adminDataImportBatchEditStatus' });
          },
        },
        {
          name: 'resumeProcess',
          label: 'Resume Processing File',
          type: 'primary',
          action: 'resumeImportBatch',
          guards: this.importBatchRecord.status === 'error' || this.importBatchRecord.status === 'new',
          params: { dataImportId: this.importBatchId },
          actionParams: {},
        },
      ];
      return allButtons;
    },

    // ----- Definition -----

    definition() {
      return getDefinition('importBatch');
    },

    // ----- Record -----

    importBatchId() {
    return +this.$route.params.importBatchId;
    },
    importBatchRecord() {
      return this.importBatch[this.importBatchId]
    },
    // ----- Fields -----

  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),

    // ----- Fields -----


    // ----- API calls -----

  },
};
</script>
