<template>
  <div  class="lfx-widget-container holidays" :style="spanStyle" :class="dynamicClass">
       <LfxWidgetHeader :header="`Upcoming Holidays (${holidaysByDate.length})`" :subheader="dateRange" />
       <LfxHolidayRow v-for="holiday in pagedItems" :key="holiday.date" :holiday="holiday" />
     
        <LfxWidgetScroller
        :totalItems="items.length"
        :itemsPerPage="itemsPerPage"
        :currentPage="pageNumber"
        @on-page="onPage"
        />
  </div>
</template>


<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";
import LfxWidgetHeader from './LfxWidgetHeader.vue';
import LfxHolidayRow from './LfxHolidayRow.vue';
import LfxWidgetScroller from './LfxWidgetScroller.vue';

import lfx_widget_mixin from './lfx_widget_mixin';

export default {
  mixins:[lfx_widget_mixin],
  components: {LfxWidgetHeader,LfxHolidayRow,LfxWidgetScroller},
  props: {  
  },
  data() {
    return {
      pageNumber:1,
      itemsPerPage:5
    }
  },
  created() {

  },
  mounted() {
  },
  computed:{
    ...mapGetters(['upcomingHolidays']),
  	...mapState([]),
    // pagedHolidays() {  
    //   let res = [];
    //   let startItem = ( this.pageNumber -1 ) * this.itemsPerPage;
    //   for ( let d = 0 ; d < this.holidaysByDate.length ; d ++  ) { 
    //     let record = this.holidaysByDate[d];
    //     if (d >= startItem) {
    //       res.push(record);
    //     }
    //     if (res.length === this.itemsPerPage) {
    //       break;
    //     }
    //   }
    //   return res;
    // },
    items() {
      return this.holidaysByDate;
    },
  	holidaysByDate() {
      return Object.values(this.upcomingHolidays).sort( (a,b) => a.date > b.date ? 1 : -1 );
    },
  	minDate() {
      return this.holidaysByDate[0]?.date;
    },
    maxDate() {
      return this.holidaysByDate[this.holidaysByDate.length -1]?.date;
    },
    dateRange() {
      if (this.minDate === this.maxDate) {
        return this.minDate;
      } 
      else 
      {
        return `${this.minDate} to ${this.maxDate}`;
      }
    }
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([]),
  	onPage(page) {
      this.pageNumber = page;
    },
    
  }
 }
</script>

<style>

div.lfx-widget-container.holidays{
  min-height: 398px;
}

</style>

