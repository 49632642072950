<template>
  <div :class="`lfx-rate-container ${direction}`" :style="getRatesFeedStyle">
    <div class="rate">
      {{ pair }}
    </div>
    <div class="buy-flag currency-flag"></div>
    <div class="sell-flag currency-flag"></div>
    <div class="buy">
      {{ useRate.bid }}
    </div>
    <div class="sell">
      {{ useRate.ask }}
    </div>
    <div :class="`direction ${direction}`"></div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  components: {},
  props: {
    rate: { type: Object, required: true },
  },
  // data() {
  //   return {
  //     spread: 0,
  //   };
  // },
  created() {},
  mounted() {
    // this.spread = (Math.random() / 1000) * this.rate.rate;
    // let odd_or_even = Math.floor(Math.random() * 100) % 2;
    // this.direction = (odd_or_even === 0) ? 'up' : 'down';
  },
  watch: {
    // midRate(newRate,oldRate) {
    //   let direction = 'none';
    //   if (newRate > oldRate) {
    //     direction = 'up'
    //   } else
    //   if (newRate < oldRate) {
    //     direction = 'down'
    //   } else
    //   if (newRate === oldRate) {
    //     direction = 'none'
    //   }
    //   this.direction = direction;
    // }
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['authUser', 'account']),

    // ----- Account -----

    myAccount() {
      return this.account?.[this.authUser?.accountId];
    },

    // ----- Markup -----

    currencyPairId() {
      return this.pair?.split('/')?.join('_');
    },
    markup() {
      const markups = this.myAccount?.markup || {};
      for (const markupId in markups) {
        const markup = markups[markupId];
        if (markup.currencyPair === this.currencyPairId) {
          return markup;
        }
      }
      return undefined;
    },

    // ----- Rate -----

    useRate() {
      if (this.authUser?.accountLevel === 'client') {
        return {
          bid: this.markup ? this.markup?.bidRate : 'Loading...',
          ask: this.markup ? this.markup?.askRate : 'Loading...',
        };
      }
      return this.rate;
    },

    clientBidRate() {
      return 'todo...';
    },
    clientAskRate() {
      return 'Loading...';
    },

    // ----- Misc -----

    getRatesFeedStyle() {
      return {
        '--sell-currency-image': `url(/images/square_flags/${this.sellCurrencyCountry}.svg)`,
        '--buy-currency-image': `url(/images/square_flags/${this.buyCurrencyCountry}.svg)`,
      };
    },
    buyCurrencyCountry() {
      return this.buyCurrency.substr(0, 2).toLowerCase();
    },
    sellCurrencyCountry() {
      return this.sellCurrency.substr(0, 2).toLowerCase();
    },
    direction() {
      if (this.midRate < this.firstMidRate) {
        return 'down';
      } else {
        return 'up';
      }
    },
    midRate() {
      return (this.rate.ask + this.rate.bid) / 2;
    },
    firstMidRate() {
      return (this.rate.todayFirstTickBid + this.rate.todayFirstTickAsk) / 2;
    },
    pair() {
      let rateCurrencies = this.rate.ric.split('=')[0];
      if (rateCurrencies.length === 3) {
        rateCurrencies = `USD${rateCurrencies}`;
      }
      let buy = rateCurrencies.substr(0, 3);
      let sell = rateCurrencies.substr(3, 6);
      return `${buy}/${sell}`;
    },
    buyCurrency() {
      return this.pair.split('/')[0];
    },
    sellCurrency() {
      return this.pair.split('/')[1];
    },
    // buy() {
    //   let buy = `${Math.floor((this.rate.rate + this.spread) * 1000) / 1000}`;
    //   while (buy.length < 6) {
    //     buy = buy + '0';
    //   }
    //   return buy;
    // },
    // sell() {
    //   let sell = `${Math.floor((this.rate.rate - this.spread) * 1000) / 1000}`;
    //   while (sell.length < 6) {
    //     sell = sell + '0';
    //   }
    //   return sell;
    // },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
<style>
div.lfx-rate-container {
  white-space: nowrap;
  max-width: 125px;
  min-width: 125px;
  padding-left: 10px;
  text-align: center;
  display: grid;
  grid-template-areas:
    '. pair pair .'
    '. buy sell .';
  grid-template-columns: 3px 55px 55px 3px;
  grid-template-rows: 11px 11px;
  cursor: pointer;
  background-color: white;
  color: var(--theme-button-clear-font-color);
  margin-left: 2px;
  margin-right: 2px;
  position: relative;
  border-radius: 22px;
  transform: translateZ(1px);
}

div.lfx-rate-container.up {
  /* border: var(--theme-button-primary-border-width) solid var(--theme-color-status--success) ;  */
  color: var(--theme-color-status--success);
  border: 1px solid var(--theme-color-status--success);
}

div.lfx-rate-container.down {
  /* border: var(--theme-button-primary-border-width) solid var(--theme-color-status--error) ;  */
  color: var(--theme-color-status--error);
  border: 1px solid var(--theme-color-status--error);
}

div.lfx-rate-container {
  border: 1px solid #ccc;
}

div.lfx-rate-container.up:hover {
  background-color: var(--theme-color-status--success);
  color: white;
}

div.lfx-rate-container.down:hover {
  background-color: var(--theme-color-status--error);
  color: white;
}

div.lfx-rate-container:hover > div.direction {
  filter: grayscale(100) brightness(100);
}

div.buy {
  grid-area: buy;
  font-size: 11px;
  line-height: 11px;
  text-align: right;
  padding-right: 3px;
}

div.sell {
  grid-area: sell;
  font-size: 11px;
  line-height: 11px;
  text-align: left;
  padding-left: 3px;
}

div.currency-flag {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 22px;
  background-repeat: no-repeat;
  border-radius: 100px;
}

div.buy-flag {
  left: 0px;
  background-image: var(--buy-currency-image);
  transform: translateZ(1px);
}

div.sell-flag {
  right: 0px;
  background-image: var(--sell-currency-image);
  transform: translateZ(1px);
}

div.rate {
  font-size: 13px;
  grid-area: pair;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.direction {
  display: none;
  grid-area: direction;
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
