<template>
  <div class="ldb-search-range-container">
    <LDBFieldBoolean :field="includeField" view="create" :record="includeRecord" @change="onIncludeChange" />
    <template v-if="includeRecord.include">
      <template v-if="field.datatype_name === 'date'">
        <LDBFieldDate :field="field" view="edit" :record="includeRecord" @change="onDateRangeChange" :value="range"/>
      </template>
      <template v-if="field.datatype_name === 'datetime'">
        <LDBFieldDate :field="fieldAsDateRange" view="create" :record="includeRecord" @change="onDateRangeChange" />
      </template>
      <template v-else-if="field.datatype_name === 'number'"> NUMBER:{{ field.name }} </template>
      <template v-else-if="field.datatype_name === 'currency'">
        <LDBFieldCurrency :field="fromField" view="create" :record="dataRecord" @change="onFromCurrencyChange" />

        <LDBFieldCurrency :field="toField" view="create" :record="dataRecord" @change="onToCurrencyChange" />
      </template>
      <template v-else-if="field.datatype_name === 'float'"> FLOAT:{{ field.name }} </template>

      <div class="ldb-search-range-preset">
        <capitec-chip
          v-for="preset in presets"
          class="search-chip"
          :style="selectedChipStyle(preset)"
          :class="`${presetSelected(preset) ? 'selected' : 'not-selected'} option-chip`"
          :label="`${preset.label}`"
          @click.stop="applyPreset(preset)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBFieldBoolean from '@/landobyte_vue/LDBFieldBoolean.vue';
import LDBFieldDate from '@/landobyte_vue/LDBFieldDate.vue';
import LDBFieldCurrency from '@/landobyte_vue/LDBFieldCurrency.vue';

export default {
  emits: ['on-range-change'],
  components: { LDBFieldBoolean, LDBFieldDate, LDBFieldCurrency },
  props: {
    field: { type: Object, required: true },
    initialState:{type:Object}
  },
  data() {
    return {
      selectedPreset: '',
      dataRecord: {},
      range: { from: undefined, to: undefined },
      includeRecord: { include: false },
    };
  },
  created() {

  },

  mounted() {
    this.includeRecord[this.field.name] = {};
    if (this.initialState) {
      this.selectedPreset = this.initialState?.preset;
      this.range.from = this.initialState?.from;
      this.range.to = this.initialState?.to;
      this.includeRecord.include = this.initialState?.include;
      this.includeRecord[this.field.name].startDate = this.range.from;
      this.includeRecord[this.field.name].endDate = this.range.to;
      if (this.selectedPreset !== undefined) {
        this.applyPreset(this.selectedPreset);
      }
    }
  },
  computed: {
    ...mapGetters([  ]),
    ...mapState(['authUser']),
    presets() {
      let presets = this.field?.search?.presets;
      return presets;
    },
    fromField() {
      let field = { ...this.field };
      field.mandatory = false;
      field.name = `${field.name}|from`;
      field.label = `From ${field.label}`;
      return field;
    },
    toField() {
      let field = {...this.field};
      field.mandatory = false;
      field.name = `${field.name}|to`;
      field.label = `To ${field.label}`;
      return field;
    },
    fieldAsDateRange() {
      let field = { ...this.field };
      field.mandatory = false;
      field.datatype.datetime.type = 'range';
      field.datatype.datetime.range = true;
      field.datatype.datetime.input = 'calendar';
      return field;
    },
    includeField() {
      let copyField = { ...this.field };
      return {
        ...copyField,
        field: 'include',
        group: this.field.group,
        label: 'Filter by ' + this.field.label + '?',
        name: 'include',
        mandatory: false,
        datatype: 'boolean',
        datatype_name: 'boolean',
      };
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    selectedChipStyle(preset) {
      let res = {};
      if (this.presetSelected(preset)) {
        res['--theme-chip-background-color'] = 'var(--theme-status-info-background)';
        res['--theme-chip-label-color'] = 'white';
      }
      return res;
    },
    presetSelected(preset) {
      return preset.name === this.selectedPreset;
    },
    applyPreset(preset) {
      if (preset.range !== undefined) {
        let range = typeof preset.range === 'function' ? preset.range({ authUser: this.authUser }) : preset.range;
        this.range.from = range.from;
        this.range.to = range.to;
        if (this.includeRecord[this.field.name] === undefined) {
          this.includeRecord[this.field.name] = {};
        }
        this.includeRecord[this.field.name].startDate = this.range.from;
        this.includeRecord[this.field.name].endDate = this.range.to;
        
        this.selectedPreset = preset.name;

        this.sendEvent();
      }

      // console.log('includeRecord:',this.includeRecord,this.field.name);

      // else
      // {
      //   this.range.from = (typeof preset.from === 'function') ? preset.from({authUser:this.authUser}) : preset.from ;
      //   this.range.to   = (typeof preset.to === 'function') ? preset.to({authUser:this.authUser}) : preset.to ;
      //   this.sendEvent();
      // }
    },
    sendEvent() {
      if (this.range.from !== undefined && this.range.to !== undefined) {
        this.$emit('on-range-change', {
          id: this.field.name,
          include: this.includeRecord.include,
          from: this.range.from,
          to: this.range.to,
          preset:this.selectedPreset
        });
      }
    },
    onDateRangeChange(evt) {
      // console.log('onDateRangeChange:',evt);
      this.range.from = evt.startDate;
      this.range.to = evt.endDate;
      this.sendEvent();
      // console.log('includeRecord:',this.includeRecord);
    },
    onFromCurrencyChange(evt) {
      // console.log('onFromCurrencyChange evt:',evt);
      this.range.from = evt;
      this.sendEvent();
    },
    onToCurrencyChange(evt) {
      // console.log('onToCurrencyChange evt:',evt);
      this.range.to = evt;
      this.sendEvent();
    },
    onIncludeChange(evt) {
      // console.log('evt:',evt);
      this.includeRecord.include = evt;
      this.sendEvent();
    },
  },
};
</script>
<style>
div.ldb-search-range-container {
  display: flex;
  min-height: 60px;
  /* border: 1px solid red; */
  align-items: center;
}

div.ldb-search-range-container > div.ldb-field-container__currency {
  flex: 1;
  max-width: 345px;
  margin-right: 10px;
}

div.ldb-search-range-container > div.ldb-field-container__date {
  flex: 1;
  max-width: 700px;
}

div.ldb-search-range-container > div.ldb-field-container__date > capitec-date-time-picker {
  z-index: 1;
}

div.ldb-search-range-container > div.ldb-field-container__date > capitec-date-time-picker[_isfocussed] {
  z-index: 100;
}

div.ldb-search-range-container > div.ldb-field-container {
  flex: 1;
}

div.ldb-search-range-container > div.ldb-field-container__boolean {
  flex: 1;
  max-width: 250px;
  margin-left: 10px;
}

.ldb-search-range-preset > .search-chip {
  margin-left: 10px;
}
</style>
