//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2023 LandoByte (Pty) Ltd.
//  File        : LfxIncomingReportDef.ts
//  Author      : Bevan Timm
//  Description : This module defines an Incoming Report record for the LandoFX System
//  Created     : 03 August 2023 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition } from './LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------

export type LfxIncomingReportType = 'detailedCustomer' | 'dailyDealList' | 'accountBalance';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxIncomingReportInt {
  id?: string;
  accountId?: number;
  reportType?: LfxIncomingReportType;
  timestamp?: Date;
  accountIdReportType?: string;
  filename?: string;
  fileSize?: number;
  s3Key?: string;
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxIncomingReportDef: LdbDefinition = {
  title: 'Incoming Report',
  table: 'LfxIncomingReport',
  collectionPath: '/account/{{accountId}}/incomingReport',
  database: 'dynamo',
  view: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
  indexes: ['accountId-timestamp-index', 'accountIdReportType-timestamp-index'],
  pagingType: 'backEnd',
  groups: {
    details: { priority: 1, label: 'Details' },
  },
  fields: {
    id: {
      label: 'Name',
      datatype: { id: { descriptionField: 'name', type: 'text' } },
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'details',
    },
    accountId: {
      label: 'Account',
      datatype: {
        foreignKey: {
          collection: 'fk_clients',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'details',
    },
    reportType: {
      label: 'Report Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'detailedCustomer', name: 'Detailed Customer Report', tagType: 'info' },
            { id: 'dailyDealList', name: 'Daily Deal List', tagType: 'info' },
            { id: 'accountBalance', name: 'Account Balance Report', tagType: 'info' },
          ],
        },
      },
      default: 'active',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'details',
    },
    timestamp: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'details',
    },
    accountIdReportType: {
      label: 'Account ID and Report Type',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'details',
    },
    filename: {
      label: 'File Name',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'details',
    },
    fileSize: {
      label: 'File Size',
      datatype: 'integer',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'details',
    },
    s3Key: {
      label: 'S3 Key',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'details',
    },
    downloadButton: {
      label: 'Download',
      datatype: {
        action: {
          action: 'downloadIncomingReport',
          label: 'Download',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
      property: {source:'frontend',read:true,write:false,sort:'filename'},
      group: 'details',
    },
  },
};
