<template>
  <template v-if="beneficiaryId">
    <LDBCreateView
      :table="table"
      :definition="definition"
      :collection_path="collectionPath"
      :showBackButton="showBackButton"
      action="createBeneficiaryBankAccount"
      :params="{ accountId: accountId, beneficiaryId: beneficiaryId }"
      @on-back="onBack"
      :createButtonLabel="createButtonLabel"
      @on-create="onCreateBeneficiaryBankAccount"
      @on-api-success="onApiSuccess"
    />
  </template>

  <template v-else>
    <capitec-content-box>
      <capitec-label
        type="subTitle"
        label="Please capture the Beneficiary's details under Step 1 - Details first."
      ></capitec-label>
      <capitec-action-bar>
        <capitec-button label="Back" type="clear" @click="onBackClick"></capitec-button>
        <capitec-spacer></capitec-spacer>
        <capitec-button label="Cancel" type="default" @click="onCancelClick"></capitec-button>
        <capitec-button label="Done" type="disabled" disabled></capitec-button>
      </capitec-action-bar>
    </capitec-content-box>
  </template>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBCreateView from '@/landobyte_vue/LDBCreateView.vue';

export default {
  name: 'LfxAddBeneficiaryBankAccount',
  components: { LDBCreateView },
  props: {},
  emits: ['step-back'],
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    // ----- Account record -----

    accountId() {
      return +this.$route.params.accountId;
    },
    accountRecord() {
      return this.account[this.accountId];
    },

    // ----- Beneficiary record -----

    beneficiaryId() {
      return +this.$route.params.beneficiaryId;
    },
    beneficiaryRecord() {
      return this.accountRecord.beneficiary[this.beneficiaryId] || {};
    },

    // ----- Bank account definition -----

    definition() {
      return getDefinition('bankAccount');
    },
    collectionPath() {
      return `/account/${this.accountId}/bank_account`;
    },
    table() {
      return 'LfxBankAccount';
    },

    // ----- Form Buttons -----

    showBackButton() {
      return this.$route.meta?.content?.formButtons?.showBackButton;
    },
    createButtonLabel() {
      return this.$route.meta?.content?.formButtons?.createButtonLabel;
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    onCreateBeneficiaryBankAccount(response) {
      if (this.$route.name === 'completeBeneficiaryBankAccount') {
        this.$router.replace({ name: 'beneficiaryDetailsView', params: { beneficiaryId: this.beneficiaryId } });
      } else if (this.$route.name === 'beneficiaryAddBankAccount') {
        let bankAccountId = response.response.id;
        this.$router.replace({
          name: 'beneficiaryBankAccountView',
          params: { beneficiaryId: this.beneficiaryId, bankAccountId: bankAccountId },
        });
      }
    },
    async onSaveAccount(payload) {
      // let response = await this.beneficiaryRecord.addBankAccount(payload);
      // if (response !== undefined) {
      // if (this.$route.matched[0].name === 'beneficiary') {
      //   let bankAccountId = response.id;
      //   this.$router.replace({ name: 'beneficiaryBankAccountView', params: { bankAccountId: bankAccountId } });
      // } else {
      //   this.$router.replace({ name: 'beneficiaryDetailsView' });
      // }
      // }
    },
    async onApiSuccess(responseObject) {
      if (this.$route.matched[0].name === 'beneficiary') {
        let bankAccountId = responseObject.response.id;
        this.$router.replace({ name: 'beneficiaryBankAccountView', params: { bankAccountId: bankAccountId } });
      } else {
        this.$router.replace({ name: 'beneficiaryDetailsView' });
      }
    },

    onBack() {
      this.$emit('step-back');
    },
  },
};
</script>

<style>
/* div.container {
} */
</style>
