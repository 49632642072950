<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <div v-if="mounted">
        <p><b>{{ accountRecord.name }}</b></p>
        <p><b>{{ currencyPairRecord.name }}</b></p>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
      </div>
    </template>
    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxAccountUpdateMarkupForNewDeal',
  components: { LDBField },
  props: {},
  data() {
    return {
      mounted: false,
      newRecord: {},
    };
  },
  created() {
  },
  mounted() {
    if (this.markupRecord) {
      this.newRecord = this.markupRecord
      this.mounted = true;
    } else {
      this.newRecord = this.definition.class(
        { Table: 'LfxAccountMarkup', collection_path: `/account/${this.accountId}/markup` },
        this.$store
      );
      this.populateFieldDefaults();
      this.newRecord.currencyPair = this.currencyPairId
    }
  },
  unmounted() {
    this.newRecord.revert()
  },
  computed: {
    ...mapGetters(['permissions', 'config']),
    ...mapState(['account']),
    header() {
      if (this.markupRecord) {
        return "Update Client Markup - " + this.currencyPairRecord.name
      } else {
        return "Add Client Markup - " + this.currencyPairRecord.name
      };
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelAddMarkup',
          label: 'Cancel',
          type: '',
          action: 'click',
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'addMarkup',
          label: !this.markupRecord ? 'Add Markup' : 'Update Markup',
          type: 'primary',
          action: !this.markupRecord ? 'createAccountMarkup' : 'editAccountMarkups',
          params: { accountId: this.accountId, markupId:this.markupRecord?.id },
          guards: true,
          actionParams: this.newRecord,
          clickEvent(response) {
            if (response.status === 'success') {
              // self.accountRecord.doRetrieveParentMarkups()
              self.$router.go(-1);
            }
          },
        },
      ];
      return allButtons;
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : 'todo'; //TODO: go to user's home as fallback
    },

    // ----- Definition -----

    definition() {
      return getDefinition('account_markup');
    },

    // ----- Record -----

    accountId() {
      return +this.$route.params.accountId;
    },
    accountRecord() {
      return this.account[this.accountId];
    },
    currencyPairId() {
      return this.$route.params.currencyPairId;
    },
    currencyPairRecord() {
      return this.config?.currencyPair?.[this.currencyPairId]
    },
    markupRecord() {
      for (const markupId in this.accountRecord?.markup || {}) {
        if (this.accountRecord.markup[markupId].currencyPair === this.currencyPairId) {
          return this.accountRecord.markup[markupId]
        }
      }
      return undefined
    },
    // ----- Fields -----

    fields() {
      return this.newRecord.guarded_fields;
    },

    viewFields() {
      let res = [];
      for (let k in this.fields) {
        let field = this.fields[k];
        if (field.views.edit === true) {
          field.fieldname = k;
          res.push(field);
        }
      }
      return res;
    },

    currencyPairOptions() {
      let systemCurrencyPairs = this.config.currencyPair;
      let accountMarkups = this.accountRecord.markup || {};
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),

    // ----- Fields -----

    populateFieldDefaults() {
      for (let f = 0; f < this.viewFields.length; f++) {
        let field = this.viewFields[f];
        if (field.default) {
          this.newRecord[field.name] = field.default;
        }
      }
      // this.presetAccountId();
      this.mounted = true;
    },
    // presetAccountId() {
    //   if (this.initiatingAccountId) {
    //     this.newRecord.accountId = this.initiatingAccountId;
    //   }
    // },
    on_change({ field, value }) {
      this.newRecord[field] = value;
      const newRec = this.newRecord;
    },

    // ----- API calls -----

    // async onAddMarkupClick() {
    //   let response = await this.accountRecord.addAccountMarkup(this.newRecord);
    //   if (response.id) {
    //     this.showLoader(true);
    //     await this.accountRecord.getMarkups();
    //     this.showLoader(false);
    //     this.$router.replace({ name: 'accountMarkupsList' });
    //   }

    // let userId = await this.addUser();
    // if (userId) {
    //   this.$router.replace({ name: 'configureUserPermissionsEdit', params: { userId: userId } });
    // }
    // },
    // async addUser() {
    //   const accountId = this.newRecord.accountId;
    //   const response = await this.account[accountId].createUser(this.newRecord);
    //   const userId = response.id;
    //   return userId;
    // },
  },
};
</script>
