<template>
  <LDBModal :result="modal.result" :size="modal.size" header="Add a Client">
    <template #body>
      <capitec-stepper
        layout="horizontal"
        :steps="steps"
        :active-step-number="activeStepNumber"
        @step-click="onStepClick"
      >
      </capitec-stepper>

      <template v-if="activeStepNumber === 1">
        <h1>Step 1: Check Client ID</h1>
        <p>Please complete the fields below to verify the Client's ID.</p>

        <div class="flex-container">
          <div class="flex-item">
            <LDBField
              :key="newIndividualField_key"
              :field="newIndividualField"
              view="edit"
              :fieldname="newIndividualField.name"
              :definition="undefined"
              :record="checkClientIdRecord"
              @change="onCheckClientId_change"
            />
          </div>
          <div class="flex-item">
            <LDBField
              :key="newEntityField_key"
              :field="newEntityField"
              view="edit"
              :fieldname="newEntityField.name"
              :definition="undefined"
              :record="checkClientIdRecord"
              @change="onCheckClientId_change"
            />
          </div>
          <div class="flex-item" v-if="mandatesDatabaseDisabled">
            <LDBField
              :key="existingClientField_key"
              :field="existingClientField"
              view="edit"
              :fieldname="existingClientField.name"
              :definition="undefined"
              :record="checkClientIdRecord"
              @change="onCheckClientId_change"
            />
          </div>
        </div>

        <LDBField
          :key="identificationField_key"
          :field="identificationField"
          view="edit"
          :fieldname="identificationField.name"
          :definition="undefined"
          :record="checkClientIdRecord"
          @change="onCheckClientId_change"
        />

        <!-- <LDBField
          v-for="(field, fieldname) in checkClientIdViewFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="definition"
          :record="checkClientIdRecord"
          :overrideOptions="overrideOptions(field.name)"
          @change="onCheckClientId_change"
        /> -->
        <p v-if="checkClientIdReturn.message && isDevelopment">Error: {{ checkClientIdReturn.message }}</p>
      </template>

      <!-- <p>----------------------------------------------------------------------------------------------------------------------------------------</p> -->
      <!-- <p>----------------------------------------------------------------------------------------------------------------------------------------</p> -->
      <!-- <p>----------------------------------------------------------------------------------------------------------------------------------------</p> -->

      <template v-if="activeStepNumber === 2">
        <template v-if="!hasReturn">
          <h1>Step 2: Complete Step 1 first</h1>
          <p>Please complete "Step 1: Check Client ID" first and click the "Next" button before returning to Step 2.</p>
        </template>

        <template v-else>
          <h1>{{ modal.resultLabel }}</h1>

          <LDBField
            v-for="(field, fieldname) in checkClientIdViewOnlyFields"
            :key="fieldname"
            :field="field"
            view="item"
            :fieldname="field.name"
            :definition="definition"
            :record="addClientRecord"
          />

          <template v-if="checkClientIdReturnType === 'hasAccessToClient'">
            <p>The Client has already been registered under your Intermediary.</p>
            <p>
              <span>Go to Client: </span>
              <span>
                <router-link
                  class="capitec-hyperlink"
                  :to="recordLink"
                  replacetag="capitec-hyperlink"
                  :label="displayValue"
                >
                  {{ displayValue }}
                </router-link>
              </span>
            </p>
          </template>

          <template v-else-if="checkClientIdReturnType === 'noAccessInMyIntermediary'">
            <p>The Client has already been registered under your Intermediary.</p>
            <p>However, you do not have access to the account that the Client has been registered under.</p>
            <p>
              Please contact someone at your organisation who has access to the Client if you wish the Client to be moved
              to your account.
            </p>
          </template>

          <template v-else-if="checkClientIdReturnType === 'badClientInfo'">
            <p>The Client has already been registered under your Intermediary.</p>
            <p>However, due to a System error the Client information could not be retrieved.</p>
          </template>

          <template v-else-if="checkClientIdReturnType === 'otherIntermediary'">
            <p>The Client has already been loaded on the Forex system, but is mandated with another Intermediary.</p>
            <p>
              Please contact FX Suite and provide the appropriate documentation to have the Client mandate moved
              to your organisation.
            </p>
          </template>

          <template v-else-if="checkClientIdReturnType === 'cifFoundAtBank'">
            <template v-if="mandatesDatabaseDisabled">
              <p>The Client is already registered at the Bank, but is not yet registered on the Forex system.</p>
              <p>You may import the Client from the Bank to add it to your account.</p>
              <LDBField
                v-for="(field, fieldname) in importClientReadOnlyViewFields"
                :key="fieldname"
                :field="field"
                view="item"
                :fieldname="field.name"
                :definition="definition"
                :record="importClientRecord"
                @change="importClientRecord_change"
              />
              <LDBField
                v-for="(field, fieldname) in importClientViewFields"
                :key="fieldname"
                :field="field"
                view="edit"
                :fieldname="field.name"
                :definition="definition"
                :record="importClientRecord"
                :overrideOptions="overrideOptions(field.name)"
                @change="importClientRecord_change"
              />
            </template>
            <template v-else>
              <p>The Client is already registered at the Bank, but is not yet registered on the Forex system.</p>
              <p>Please contact Treasury Maintain to Import the Client.</p>
              <LDBField
                v-for="(field, fieldname) in importClientReadOnlyViewFields"
                :key="fieldname"
                :field="field"
                view="item"
                :fieldname="field.name"
                :definition="definition"
                :record="importClientRecord"
                @change="importClientRecord_change"
              />
            </template>
          </template>

          <template v-else-if="checkClientIdReturnType === 'foundAtBankBadCif'">
            <p>The Client is already registered at the Bank, but is not yet registered on the Forex system.</p>
            <p>Unfortunately, the Client's CIF number coud not be retrieved.</p>
            <p>Please contact FX Suite to provide the CIF number in order to import the Client.</p>
            <!-- <LDBField
              v-for="(field, fieldname) in importClientViewFields"
              :key="fieldname"
              :field="field"
              view="edit"
              :fieldname="field.name"
              :definition="definition"
              :record="importClientRecord"
              :overrideOptions="overrideOptions(field.name)"
              @change="importClientRecord_change"
            /> -->
          </template>

          <template v-else-if="checkClientIdReturnType === 'bankSearchError'">
            <p>Due to a system error the bank search could not be completed.</p>
            <p>Please retry the search when the error has been resolved.</p>
            <p v-if="checkClientIdReturn.responseDetail.codeReason">
              <strong>Reason:</strong> {{ checkClientIdReturn.responseDetail.codeReason }}
            </p>
            <p v-if="checkClientIdReturn.responseDetail.message">
              <strong>Message:</strong> {{ checkClientIdReturn.responseDetail.message }}
            </p>
          </template>

          <template v-else-if="checkClientIdReturnType === 'cantCheckExternally'">
            <p>The Client is not registered at the Bank, and has an ID type that cannot be verified further.</p>
            <p>Please proceed to capture the Client's information.</p>
            <LDBField
              v-for="(field, fieldname) in addClientViewFields"
              :key="fieldname"
              :field="field"
              view="edit"
              :fieldname="field.name"
              :definition="definition"
              :record="addClientRecord"
              :overrideOptions="overrideOptions(field.name)"
              @change="addClientRecord_change"
            />
          </template>

          <template v-else-if="checkClientIdReturnType === 'cipcSuccess'">
            <p>The Client was successfully verfied at CIPC.</p>
            <p>Please capture the remaining information required to add the Client.</p>
            <capitec-spacer />
            <LDBField
              v-for="(field, fieldname) in addClientViewFields"
              :key="fieldname"
              :field="field"
              view="edit"
              :fieldname="field.name"
              :definition="definition"
              :record="addClientRecord"
              :overrideOptions="overrideOptions(field.name)"
              @change="addClientRecord_change"
            />
          </template>

          <template v-else-if="checkClientIdReturnType === 'cipcError'">
            <p>The Client could not be verfied at CIPC at this time.</p>
            <p>
              If you are satisfied with the Client's CIPC credentials, you may proceed to add the Client by capturing the
              remaining information.
            </p>
            <p>The Client's CIPC status will be verified by the Bank before it is accepted as a Client.</p>
            <LDBField
              v-for="(field, fieldname) in addClientViewFields"
              :key="fieldname"
              :field="field"
              view="edit"
              :fieldname="field.name"
              :definition="definition"
              :record="addClientRecord"
              :overrideOptions="overrideOptions(field.name)"
              @change="addClientRecord_change"
            />
          </template>

          <template v-else-if="checkClientIdReturnType === 'cipcNotFound'">
            <p>The CIPC search was successful, but the Client could not be verfied at CIPC.</p>
            <p>A South African company must be verified at CIPC before it can be added as Client.</p>
          </template>

          <template v-else-if="checkClientIdReturnType === 'homeAffairsSuccess'">
            <p>The Client was successfully verified at Home Affairs with the below information:</p>
            <ul>
              <li>
                First Names: <strong> {{ checkClientIdReturn.responseDetail.individual.firstNames }}</strong>
              </li>
              <li>
                Surname: <strong> {{ checkClientIdReturn.responseDetail.individual.surname }}</strong>
              </li>
              <li>
                Gender: <strong> {{ checkClientIdReturn.responseDetail.individual.gender }}</strong>
              </li>
              <li>
                Citizenship: <strong> {{ checkClientIdReturn.responseDetail.individual.citizenship }}</strong>
              </li>
              <li>
                Status: <strong> {{ checkClientIdReturn.responseDetail.individual.homeAffairsStatus }}</strong>
              </li>
              <li>
                Date of Birth: <strong> {{ checkClientIdReturn.responseDetail.individual.dateOfBirth }}</strong>
              </li>
            </ul>
            <p>Please capture the following information to add the Client:</p>
            <LDBField
              v-for="(field, fieldname) in addClientViewFields"
              :key="fieldname"
              :field="field"
              view="edit"
              :fieldname="field.name"
              :definition="definition"
              :record="addClientRecord"
              :overrideOptions="overrideOptions(field.name)"
              @change="addClientRecord_change"
            />
          </template>

          <template v-else-if="checkClientIdReturnType === 'homeAffairsDeceased'">
            <p>The Client was successfully verified at Home Affairs with the below information:</p>
            <li>
              First Names: <strong> {{ checkClientIdReturn.responseDetail.individual.firstNames }}</strong>
            </li>
            <li>
              Surname: <strong> {{ checkClientIdReturn.responseDetail.individual.surname }}</strong>
            </li>
            <li>
              Gender: <strong> {{ checkClientIdReturn.responseDetail.individual.gender }}</strong>
            </li>
            <li>
              Citizenship: <strong> {{ checkClientIdReturn.responseDetail.individual.citizenship }}</strong>
            </li>
            <li>
              Status: <strong> {{ checkClientIdReturn.responseDetail.individual.homeAffairsStatus }}</strong>
            </li>
            <li>
              Date of Birth: <strong> {{ checkClientIdReturn.responseDetail.individual.dateOfBirth }}</strong>
            </li>
            <li>
              Date of Death: <strong> {{ checkClientIdReturn.responseDetail.individual.dateOfDeath }}</strong>
            </li>
            <p>However, a Deceased Estate can not be registered as an Individual Client.</p>
            <p>
              Please return to Step 1 and set the "Account Type" to "Entity" and select the "Identification Type" as
              "Other RSA Registration Number", then click "Next".
            </p>
          </template>

          <template v-else-if="checkClientIdReturnType === 'homeAffairsPartial'">
            <p>The Client was <strong>partially</strong> verified at Home Affairs with the below information:</p>
            <ul>
              <li>
                First Names: <strong> {{ checkClientIdReturn.responseDetail.individual.firstNames }}</strong>
              </li>
              <li>
                Surname: <strong> {{ checkClientIdReturn.responseDetail.individual.surname }}</strong>
              </li>
              <li>
                Gender: <strong> {{ checkClientIdReturn.responseDetail.individual.gender }}</strong>
              </li>
              <li>
                Citizenship: <strong> {{ checkClientIdReturn.responseDetail.individual.citizenship }}</strong>
              </li>
              <li>
                Status: <strong> {{ checkClientIdReturn.responseDetail.individual.homeAffairsStatus }}</strong>
              </li>
              <li>
                Date of Birth: <strong> {{ checkClientIdReturn.responseDetail.individual.dateOfBirth }}</strong>
              </li>
            </ul>
            <p>
              Should the Individual be Deceased, please return to Step 1 and set the "Account Type" to "Entity" and
              select the "Identification Type" as "Other RSA Registration Number", then click "Next".
            </p>
            <p>Otherwise please capture the following information to add the Client:</p>
            <LDBField
              v-for="(field, fieldname) in addClientViewFields"
              :key="fieldname"
              :field="field"
              view="edit"
              :fieldname="field.name"
              :definition="definition"
              :record="addClientRecord"
              :overrideOptions="overrideOptions(field.name)"
              @change="addClientRecord_change"
            />
          </template>

          <template v-else-if="checkClientIdReturnType === 'homeAffairsError'">
            <p>The Client could not be verfied at Home Affairs at this time.</p>
            <p>
              If you are satisfied with the Client's RSA ID credentials, you may proceed to add the Client by capturing
              the remaining information.
            </p>
            <p>The Client's RSA ID status will be verified by the Bank before they are accepted as a Client.</p>
            <LDBField
              v-for="(field, fieldname) in addClientViewFields"
              :key="fieldname"
              :field="field"
              view="edit"
              :fieldname="field.name"
              :definition="definition"
              :record="addClientRecord"
              :overrideOptions="overrideOptions(field.name)"
              @change="addClientRecord_change"
            />
          </template>

          <template v-else-if="checkClientIdReturnType === 'homeAffairsNotFound'">
            <p>The Home Affairs search was successful, but the Client could not be verfied at Home Affairs.</p>
            <p>A South African individual must be verified at Home Affairs before they can be added as a Client.</p>
          </template>

          <template v-else-if="checkClientIdReturnType === 'unexpectedReturn'">
            <p>An unexpected error occurred.</p>
          </template>
        </template>
      </template>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in leftButtons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        :action="button.action"
        :disabled="button.disabled"
        @click="button.clickEvent"
      ></LDBButton>
      <capitec-spacer />
      <LDBButton
        v-for="button in rightButtons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :onSuccess="button.onSuccess"
        :enabledGuards="button.enabledGuards"
        :viewGuards="button.viewGuards"
        @click="button.clickEvent"
      />
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import { checkClientId } from '@/utils';

import lodash from 'lodash';
const merge = lodash.merge;

import LDBField from '@/landobyte_vue/LDBField.vue';
import LDBButton from '@/landobyte_vue/LDBButton.vue';

export default {
  name: 'LfxAddClient',
  components: { LDBField, LDBButton },
  props: {},
  data() {
    return {
      activeStepNumber: 1,
      checkClientIdRecord: {
        // accountType: null, // individual | entity | existing
        // identificationType: null, // | 'rsaId' | 'passport' | 'rsaRegistrationNumber' | 'rsaNonCompanyRegistrationNumber' | 'internationalRegistrationNumber' | 'cifNumber'
        // clientIdentification: null, //
        newIndividual: null,
        newEntity: null,
        existingClient: null,
        identification: null, // string
      },
      checkClientIdReturn: {},
      addClientRecord: {},
      importClientRecord: {},
    };
  },
  created() {
    // this.checkClientIdRecord = this.definition.class(
    //   { Table: 'LfxAccount', definition_name: 'client', collection_path: '/account', ...this.definition.default_values },
    //   this.$store
    // );
    this.addClientRecord = this.definition.class(
      { Table: 'LfxAccount', definition_name: 'client', collection_path: '/account', ...this.definition.default_values },
      this.$store
    );
    this.addClientRecord.additionalInfo = {};
    this.importClientRecord = this.definition.class(
      { Table: 'LfxAccount', definition_name: 'client', collection_path: '/account', ...this.definition.default_values },
      this.$store
    );
  },
  mounted() {},
  computed: {
    ...mapGetters(['permissions', 'isDevelopment']),
    ...mapState(['account', 'authUser', 'systemSettings']),

    // ----- Check Client Id Return -----

    mandatesDatabaseDisabled() {
      return this.systemSettings?.featureEnable?.mandatesDatabase !== true || !!this.ownerAccountRecord?.bankDirectClients;
    },

    hasReturn() {
      return Object.keys(this.checkClientIdReturn).length > 0;
    },

    checkClientIdReturnType() {
      let check = this.checkClientIdReturn;
      let checkClientReturnType = 'unexpectedReturn';
      if (check.foundLocally === true) {
        if (check.responseDetail?.belongsToMyIntermediary === false) {
          checkClientReturnType = 'otherIntermediary';
        } else if (check.responseDetail?.belongsToMyIntermediary === true) {
          if (check.responseDetail?.client?.restrictedView) {
            checkClientReturnType = 'noAccessInMyIntermediary';
          } else if (check.responseDetail?.client?.id && check.responseDetail?.client?.name) {
            checkClientReturnType = 'hasAccessToClient';
          } else {
            return 'badClientInfo';
          }
        }
      } else if (check.foundLocally === false) {
        if (check.foundAtBank === true) {
          if (check.responseDetail?.cifNumber) {
            checkClientReturnType = 'cifFoundAtBank';
          } else {
            return 'foundAtBankBadCif';
          }
        } else if (check.foundAtBank === 'error') {
          checkClientReturnType = 'bankSearchError';
        } else if (check.foundAtBank === false) {
          if (check.foundExternally === 'na') {
            checkClientReturnType = 'cantCheckExternally';
          } else if (this.addClientRecord.accountType === 'entity') {
            if (check.foundExternally === true) {
              checkClientReturnType = 'cipcSuccess';
            } else if (check.foundExternally === 'error') {
              checkClientReturnType = 'cipcError';
            } else if (check.foundExternally === false) {
              checkClientReturnType = 'cipcNotFound';
            }
          } else if (this.addClientRecord.accountType === 'individual') {
            if (check.foundExternally === true) {
              if (check.responseDetail?.individual?.homeAffairsStatus === 'Alive') {
                checkClientReturnType = 'homeAffairsSuccess';
              } else if (
                check.responseDetail?.individual?.homeAffairsStatus === 'Deceased' ||
                (check.responseDetail?.individual?.dateOfDeath &&
                  typeof check.responseDetail?.individual?.dateOfDeath === 'string')
              ) {
                checkClientReturnType = 'homeAffairsDeceased';
              } else if (check.responseDetail?.individual?.firstNames && check.responseDetail?.individual?.surname) {
                checkClientReturnType = 'homeAffairsPartial';
              }
            } else if (check.foundExternally === 'error') {
              checkClientReturnType = 'homeAffairsError';
            } else if (check.foundExternally === false) {
              checkClientReturnType = 'homeAffairsNotFound';
            }
          }
        }
      }
      return checkClientReturnType;
    },

    // ----- Modal -----

    modal() {
      let modal = { result: 'info', size: 'x-large', resultLabel: 'Step 2: ERROR' };

      let rt = this.checkClientIdReturnType;
      if (this.activeStepNumber === 1) {
        modal.result = 'info';
      } else if (this.activeStepNumber === 2 && this.hasReturn) {
        modal.size = 'x-large';
        // modal.size = 'xx-large'; // TODO: switch to this when changing the layout to two columns
        if (rt === 'hasAccessToClient') {
          (modal.result = 'success'), (modal.resultLabel = 'Step 2: Found Forex Client');
        } else if (rt === 'noAccessInMyIntermediary') {
          (modal.result = 'warning'), (modal.resultLabel = 'Step 2: Found Forex Client');
        } else if (rt === 'badClientInfo') {
          (modal.result = 'error'), (modal.resultLabel = 'Step 2: Found Forex Client');
        } else if (rt === 'otherIntermediary') {
          (modal.result = 'warning'), (modal.resultLabel = 'Step 2: Found Forex Client');
        } else if (rt === 'cifFoundAtBank') {
          (modal.result = 'info'), (modal.resultLabel = 'Step 2: Found Bank Client');
        } else if (rt === 'foundAtBankBadCif') {
          (modal.result = 'error'), (modal.resultLabel = 'Step 2: Found Bank Client');
        } else if (rt === 'bankSearchError') {
          (modal.result = 'error'), (modal.resultLabel = 'Step 2: Bank Search Failed');
        } else if (rt === 'cantCheckExternally') {
          (modal.result = 'info'), (modal.resultLabel = 'Step 2: Add New Client');
        } else if (rt === 'cipcSuccess') {
          (modal.result = 'info'), (modal.resultLabel = 'Step 2: Add New Client');
        } else if (rt === 'cipcError') {
          (modal.result = 'warning'), (modal.resultLabel = 'Step 2: Add New Client');
        } else if (rt === 'cipcNotFound') {
          (modal.result = 'error'), (modal.resultLabel = 'Step 2: Add New Client');
        } else if (rt === 'homeAffairsSuccess') {
          (modal.result = 'info'), (modal.resultLabel = 'Step 2: Add New Client');
        } else if (rt === 'homeAffairsDeceased') {
          (modal.result = 'warning'), (modal.resultLabel = 'Step 2: Add New Client');
        } else if (rt === 'homeAffairsPartial') {
          (modal.result = 'warning'), (modal.resultLabel = 'Step 2: Add New Client');
        } else if (rt === 'homeAffairsError') {
          (modal.result = 'warning'), (modal.resultLabel = 'Step 2: Add New Client');
        } else if (rt === 'homeAffairsNotFound') {
          (modal.result = 'error'), (modal.resultLabel = 'Step 2: Add New Client');
        } else if (rt === 'unexpectedReturn') {
          (modal.result = 'error'), (modal.resultLabel = 'Step 2: Unexpected Error');
        }
      } else {
        modal.result = 'warning';
      }
      return modal;
    },

    // ----- Stepper -----

    steps() {
      let steps = [
        { index: 1, title: 'Check ID', isError: this.stepOneIsError },
        { index: 2, title: 'Add Client' },
      ];
      return steps; // TODO
    },

    stepOneIsError() {
      return this.hasReturn ? false : true;
    },

    // ----- Existing record -----

    recordLink() {
      let id = this.checkClientIdReturn?.responseDetail?.client?.id;
      let recordLink = { name: 'account', params: { accountId: id } };
      return recordLink;
    },
    displayValue() {
      return this.checkClientIdReturn?.responseDetail?.client?.name;
    },

    // ----- Definition -----

    addAccountLevel() {
      return 'client';
    },
    definition() {
      return getDefinition('client');
    },

    // ----- Fields -----

    fields() {
      return this.definition.fields;
    },
    checkClientIdViewOnlyFields() {
      let res = [];
      for (let k in this.fields) {
        let field = this.fields[k];
        if (field.views.checkId === true || field.views.checkIdReadOnly === true) {
          field.fieldname = k;
          res.push(field);
        }
      }
      return res;
    },
    checkClientIdViewFields() {
      let res = [];
      for (let k in this.fields) {
        let field = this.fields[k];
        if (field.views.checkId === true) {
          field.fieldname = k;
          res.push(field);
        }
      }
      return res;
    },
    fieldnames_checkClientId() {
      return this.checkClientIdViewFields.map((field) => field.name);
    },
    required_fields_remaining_checkClientId() {
      return this.checkClientIdRecord.required_fields_remaining;
    },
    requiredFieldsRemaining_checkClientId() {
      return (
        this.required_fields_remaining_checkClientId.filter((f) => this.fieldnames_checkClientId.indexOf(f) >= 0)
          .length > 0
      );
    },

    additionalFieldNames() {
      return ['firstName', 'middleName', 'surname'];
    },

    addClientViewFields() {
      let res = [];
      for (let k in this.fields) {
        let field = this.fields[k];
        if (field.views.addClient === true) {
          field.fieldname = k;
          // res.push(field);
          const updatedField = this.overrideField(field);
          res.push(updatedField);
        }
      }

      if (
        this.addClientRecord?.identificationType === 'passport' ||
        (this.addClientRecord?.identificationType === 'rsaId' &&
          ['cantCheckExternally', 'homeAffairsError'].includes(this.checkClientIdReturnType))
      ) {
        // let additionalFieldNames = ['firstName', 'middleName', 'surname'];
        for (let fn of this.additionalFieldNames) {
          let field = this.fields.find((field) => field.field === fn);
          field.fieldname = fn;
          let index = res.findIndex((field) => field.fieldname === 'name');
          res.splice(index - 1, 0, field);
        }
      }
      return res;
    },
    fieldnames_addClient() {
      return this.addClientViewFields.map((field) => field.name);
    },
    required_fields_remaining_addClient() {
      return this.addClientRecord.required_fields_remaining.filter((f) => this.fieldnames_addClient.indexOf(f) >= 0);
    },
    requiredFieldsRemaining_addClient() {
      return this.required_fields_remaining_addClient.length > 0;
    },

    importClientReadOnlyViewFields() {
      let res = [];
      for (let k in this.fields) {
        let field = this.fields[k];
        if (field.views.importClientReadOnly === true) {
          field.fieldname = k;
          res.push(field);
        }
      }
      return res;
    },
    importClientViewFields() {
      let res = [];
      for (let k in this.fields) {
        let field = this.fields[k];
        if (field.views.importClient === true) {
          field.fieldname = k;
          res.push(field);
        }
      }
      return res;
    },
    fieldnames_importClient() {
      return this.importClientViewFields.map((field) => field.name);
    },
    required_fields_remaining_importClient() {
      return this.importClientRecord.required_fields_remaining.filter(
        (f) => this.fieldnames_importClient.indexOf(f) >= 0
      );
    },
    requiredFieldsRemaining_importClient() {
      return this.required_fields_remaining_addClient.length > 0;
    },

    newIndividualField() {
      let field = {
        label: 'New Individual',
        name: 'newIndividual',
        datatype: {
          option: {
            optionType: 'text',
            options: [
              {
                id: 'rsaId',
                name: 'RSA ID Number',
              },
              {
                id: 'passport',
                name: 'Passport',
              },
            ],
          },
        },
        default: null,
        displayType: {
          datatype: 'option',
          display: 'radio',
        },
      };
      return field;
    },
    newIndividualField_key() {
      return `newIndividual-${this.checkClientIdRecord.newEntity}-${this.checkClientIdRecord.existingClient}`;
    },

    newEntityField() {
      let field = {
        label: 'New Entity',
        name: 'newEntity',
        datatype: {
          option: {
            optionType: 'text',
            options: [
              {
                id: 'rsaRegistrationNumber',
                name: 'RSA Company',
              },
              {
                id: 'rsaNonCompanyRegistrationNumber',
                name: 'Other RSA Entity (e.g. Trust)',
              },
              {
                id: 'internationalRegistrationNumber',
                name: 'International Company ',
              },
            ],
          },
        },
        default: null,
        displayType: {
          datatype: 'option',
          display: 'radio',
        },
      };
      return field;
    },
    newEntityField_key() {
      return `newEntity-${this.checkClientIdRecord.newIndividual}-${this.checkClientIdRecord.existingClient}`;
    },

    existingClientField() {
      let field = {
        label: 'Existing Client',
        name: 'existingClient',
        datatype: {
          option: {
            optionType: 'text',
            options: [
              {
                id: 'cifNumber',
                name: 'CIF Number',
              },
            ],
          },
        },
        default: null,
        displayType: {
          datatype: 'option',
          display: 'radio',
        },
      };
      return field;
    },
    existingClientField_key() {
      return `existingClient-${this.checkClientIdRecord.newIndividual}-${this.checkClientIdRecord.newEntity}`;
    },

    identificationField() {
      let field = {
        name: 'identification',
        label: this.identificationField_label,
        datatype: 'text',
        mandatory: true,
        enableGuards: this.hasSelectedClientOption,
        // TODO: add validator as applicable to identification selected
        // validators: {
        //   isNumeric: { msg: 'Please use only numeric charaters' },
        //   len: { msg: 'CIF Number should be 8 characters', args: [8, 8] },
        // },
      };
      return field;
    },
    identificationField_label() {
      let label = 'Identification';
      if (this.checkClientIdRecord.newIndividual === 'rsaId') {
        label = 'RSA ID Number';
      } else if (this.checkClientIdRecord.newIndividual === 'passport') {
        label = 'Passport Number';
      } else if (this.checkClientIdRecord.newEntity === 'rsaRegistrationNumber') {
        label = 'RSA Company Registration Number';
      } else if (this.checkClientIdRecord.newEntity === 'rsaNonCompanyRegistrationNumber') {
        label = 'RSA Non-Company Registration Number';
      } else if (this.checkClientIdRecord.newEntity === 'internationalRegistrationNumber') {
        label = 'International Company Registration Number';
      } else if (this.checkClientIdRecord.existingClient === 'cifNumber') {
        label = 'CIF Number';
      }
      return label;
    },
    hasSelectedClientOption() {
      return this.checkClientIdRecord.newIndividual ||
        this.checkClientIdRecord.newEntity ||
        this.checkClientIdRecord.existingClient
        ? true
        : false;
    },
    identificationField_key() {
      return `${this.checkClientIdRecord.newIndividual}-${this.checkClientIdRecord.newEntity}-${this.checkClientIdRecord.existingClient}`;
    },

    // ----- Parent account -----

    initiatingAccountId() {
      return +this.$route.params.accountId;
    },
    ownerAccountRecord() {
      return this.account?.[this.initiatingAccountId || this.authUser.accountId]
    },

    // ----- Form Buttons -----

    backButton() {
      let self = this;
      let button = {
        name: 'previousStep',
        label: 'Back',
        type: 'clear',
        guards: true,
        clickEvent() {
          self.onBackClick();
        },
      };
      return button;
    },
    cancelButton() {
      let self = this;
      let button = {
        name: 'cancelAddAccount',
        label: 'Cancel',
        type: '',
        action: 'click',
        guards: true,
        clickEvent() {
          self.$router.go(-1);
        },
      };
      return button;
    },

    leftButtons() {
      let self = this;
      let allButtons = {
        1: [],
        2: [this.backButton],
      };
      return allButtons[this.activeStepNumber]?.filter((button) => button.guards);
    },

    rightButtons() {
      let self = this;
      let allButtons = {
        1: [
          this.cancelButton,
          {
            name: 'checkClientId',
            label: 'Next',
            type: 'primary',
            action: 'checkNewClientID',
            // actionParams: this.checkClientIdRecord, // TODO
            actionParams: this.checkNewClientID_actionParams, // TODO
            enabledGuards:
              // !this.checkClientIdRecord.has_validation_errors && !this.requiredFieldsRemaining_checkClientId,
              this.checkClientIdRecord.identification ? true : false, // TODO - check validation on identificationField
            guards: true,
            onSuccess: { doStoreUpdate: false },
            clickEvent(response) {
              self.handleCheckClientIdResponse(response);
            },
          },
        ],
        2: [
          this.cancelButton,
          {
            name: 'addClient',
            label: 'Add Client',
            type: 'primary',
            action: 'createClientForAccount',
            actionParams: this.addClientRecord,
            params: { accountId: +this.addClientRecord.accountId },
            guards:
              this.checkClientIdReturnType === 'cantCheckExternally' ||
              this.checkClientIdReturnType === 'cipcSuccess' ||
              // this.checkClientIdReturnType === 'cipcNotFound' ||
              this.checkClientIdReturnType === 'cipcError' ||
              this.checkClientIdReturnType === 'homeAffairsSuccess' ||
              // this.checkClientIdReturnType === 'homeAffairsNotFound',
              this.checkClientIdReturnType === 'homeAffairsError',
            enabledGuards: !this.addClientRecord.has_validation_errors && !this.requiredFieldsRemaining_addClient,
            clickEvent(response) {
              if (response.status === 'success') {
                self.goToApplication(response);
              }
            },
          },
          {
            name: 'importClient',
            label: 'Import Client',
            type: 'primary',
            action: 'importClient',
            actionParams: this.importClientRecord,
            params: { accountId: +this.importClientRecord.accountId },
            guards: this.checkClientIdReturnType === 'cifFoundAtBank' && this.mandatesDatabaseDisabled,
            enabledGuards: true,
            clickEvent(response) {
              if (response.status === 'success') {
                self.$router.replace({ name: 'account', params: { accountId: response.response.id } });
              }
            },
          },
        ],
      };
      return allButtons[this.activeStepNumber]?.filter((button) => button.guards);
    },
    checkNewClientID_actionParams() {
      let actionParams = {
        accountType: '',
        identificationType: '',
        identification: this.checkClientIdRecord.identification,
      };
      if (this.checkClientIdRecord.newIndividual === 'rsaId') {
        actionParams.accountType = 'individual';
        actionParams.identificationType = 'rsaId';
      } else if (this.checkClientIdRecord.newIndividual === 'passport') {
        actionParams.accountType = 'individual';
        actionParams.identificationType = 'passport';
      } else if (this.checkClientIdRecord.newEntity === 'rsaRegistrationNumber') {
        actionParams.accountType = 'entity';
        actionParams.identificationType = 'rsaRegistrationNumber';
      } else if (this.checkClientIdRecord.newEntity === 'rsaNonCompanyRegistrationNumber') {
        actionParams.accountType = 'entity';
        actionParams.identificationType = 'rsaNonCompanyRegistrationNumber';
      } else if (this.checkClientIdRecord.newEntity === 'internationalRegistrationNumber') {
        actionParams.accountType = 'entity';
        actionParams.identificationType = 'internationalRegistrationNumber';
      } else if (this.checkClientIdRecord.existingClient === 'cifNumber') {
        actionParams.accountType = 'existing';
        actionParams.identificationType = 'cifNumber';
      }
      return actionParams;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Stepper -----

    onBackClick() {
      let newStepNumber = this.activeStepNumber - 1;
      let evt = { detail: { stepNumber: newStepNumber } };
      this.onStepClick(evt);
    },
    onStepClick(evt) {
      this.activeStepNumber = evt.detail.stepNumber;
    },

    // ----- Fields -----

    overrideField(field) {
      if (['name'].includes(field.name)) {
        const startingField = merge({}, field);
        const overrideObject = this.getFieldOverRideObject(field);
        const newField = merge(startingField, overrideObject);
        return newField;
      } else {
        return field;
      }
    },
    getFieldOverRideObject(field) {
      switch (field.name) {
        case 'name':
          return {
            label: 'Display Name',
            // help: 'This is the name that will be displayed for the Client throughout the Forex system.',
          };

        default:
          return {};
      }
    },

    // ----- Check Client Id -----

    onCheckClientId_change({ field, value }) {
      this.checkClientIdRecord[field] = value;

      switch (field) {
        case 'newIndividual':
          this.checkClientIdRecord.newEntity = null;
          this.checkClientIdRecord.existingClient = null;
          this.checkClientIdRecord.identification = null;
          break;
        case 'newEntity':
          this.checkClientIdRecord.newIndividual = null;
          this.checkClientIdRecord.existingClient = null;
          this.checkClientIdRecord.identification = null;
          break;
        case 'existingClient':
          this.checkClientIdRecord.newIndividual = null;
          this.checkClientIdRecord.newEntity = null;
          this.checkClientIdRecord.identification = null;
          break;
      }
    },

    overrideOptions(fieldName) {
      switch (fieldName) {
        case 'accountId':
          const res = {};
          for (const accountId in this.account) {
            if (
              !this.account[accountId].restrictedView &&
              ['intermediary', 'intermediaryBranch'].includes(this.account[accountId].accountLevel)
            ) {
              res[accountId] = this.account[accountId];
            }
          }
          return res;
        default:
          return undefined;
      }
    },

    // ----- Handle CheckClientId Response -----

    handleCheckClientIdResponse(response) {
      this.checkClientIdReturn = {}; // clear the existing checkClientIdReturn result when doing a new check
      this.addClientRecord.reset(); // = {}; // clear the existing addClientRecord when doing a new check
      this.importClientRecord.reset(); // = {}; // clear the existing importClientRecord when doing a new check

      this.populateAddClientRecordFromCheckClientIdRecord();

      this.addClientRecord.additionalInfo = {};
      this.addClientRecord.clientPublicInfoToken = response.response?.responseDetail?.clientPublicInfoToken;

      this.presetAccountId(); // Pre-populate accountId

      if (response.status === 'success') {
        this.checkClientIdReturn = response.response;
        if (this.checkClientIdReturnType === 'hasAccessToClient') {
          // TODO update db_state with existing client
        }
        if (this.checkClientIdReturnType === 'cipcSuccess' || this.checkClientIdReturnType === 'homeAffairsSuccess') {
          this.setClientNameFromResult(); // Pre-populate client name
        }
        if (this.checkClientIdReturnType === 'cifFoundAtBank') {
          this.setCifFromResult();
          this.setNameFromResult();
          this.setIdFromResult();
        }

        let newStepNumber = this.activeStepNumber + 1;
        let evt = { detail: { stepNumber: newStepNumber } };
        this.onStepClick(evt);
      }
    },

    // ----- Pre-set field values -----

    populateAddClientRecordFromCheckClientIdRecord() {
      for (let f in this.definition.indexed_fields) {
        this.addClientRecord[f] = this.checkClientIdRecord[f];
      }

      if (this.checkClientIdRecord.newIndividual === 'rsaId') {
        this.addClientRecord.accountType = 'individual';
        this.addClientRecord.identificationType = 'rsaId';
      } else if (this.checkClientIdRecord.newIndividual === 'passport') {
        this.addClientRecord.accountType = 'individual';
        this.addClientRecord.identificationType = 'passport';
      } else if (this.checkClientIdRecord.newEntity === 'rsaRegistrationNumber') {
        this.addClientRecord.accountType = 'entity';
        this.addClientRecord.identificationType = 'rsaRegistrationNumber';
      } else if (this.checkClientIdRecord.newEntity === 'rsaNonCompanyRegistrationNumber') {
        this.addClientRecord.accountType = 'entity';
        this.addClientRecord.identificationType = 'rsaNonCompanyRegistrationNumber';
      } else if (this.checkClientIdRecord.newEntity === 'internationalRegistrationNumber') {
        this.addClientRecord.accountType = 'entity';
        this.addClientRecord.identificationType = 'internationalRegistrationNumber';
      } else if (this.checkClientIdRecord.existingClient === 'cifNumber') {
        this.addClientRecord.accountType = 'existing';
        this.addClientRecord.identificationType = 'cifNumber';
      }
    },

    setClientNameFromResult() {
      let clientName = '';
      if (this.checkClientIdReturn.responseDetail?.individual) {
        let surname = this.checkClientIdReturn.responseDetail.individual.surname;
        let firstNames = this.checkClientIdReturn.responseDetail.individual.firstNames;
        clientName = this.toTitleCase(`${surname}, ${firstNames}`);
      } else {
        clientName = this.checkClientIdReturn.responseDetail.company.businessName;
      }
      this.addClientRecord.name = clientName;
    },

    toTitleCase(str) {
      return str.replace(/([^\W_]+[^\s-]*) */g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    },

    setCifFromResult() {
      let cif = this.checkClientIdReturn.responseDetail?.cifNumber;
      this.importClientRecord.cifNumber = cif;
    },

    setNameFromResult() {
      this.addClientRecord.name =
        this.checkClientIdReturn.responseDetail?.clientName ||
        this.checkClientIdReturn.responseDetail?.businessName ||
        this.checkClientIdReturn.responseDetail?.surname + ', ' + this.checkClientIdReturn.responseDetail?.firstNames;
      this.importClientRecord.clientName = this.addClientRecord.name;
      this.importClientRecord.name = this.addClientRecord.name;
    },
    setIdFromResult() {
      if (this.checkClientIdReturn?.responseDetail?.identification) {
        this.addClientRecord.identification = this.checkClientIdReturn.responseDetail.identification;
      }
    },

    presetAccountId() {
      if (this.initiatingAccountId) {
        this.addClientRecord.accountId = this.initiatingAccountId;
        this.importClientRecord.accountId = this.initiatingAccountId;
      } else if (this.authUser.accountId) {
        this.addClientRecord.accountId = this.authUser.accountId;
        this.importClientRecord.accountId = this.authUser.accountId;
      }
    },

    // ----- Add New Client -----

    addClientRecord_change({ field, value }) {
      if (this.additionalFieldNames.includes(field)) {
        this.addClientRecord.additionalInfo[field] = value;
        this.addClientRecord[field] = value;
        this.updateName();
      } else {
        this.addClientRecord[field] = value;
      }
    },

    updateName() {
      if (this.addClientRecord.additionalInfo.surname && this.addClientRecord.additionalInfo.firstName) {
        this.addClientRecord.name =
          this.addClientRecord.additionalInfo.surname + ', ' + this.addClientRecord.additionalInfo.firstName;
      } else if (this.addClientRecord.additionalInfo.firstName) {
        this.addClientRecord.name = this.addClientRecord.additionalInfo.firstName;
      } else if (this.addClientRecord.additionalInfo.surname) {
        this.addClientRecord.name = this.addClientRecord.additionalInfo.surname;
      } else {
        this.addClientRecord.name = '';
      }
    },

    async addClient() {
      let record = this.addClientRecord;
      // let response = await this.account[record.accountId].addClient(record);
      // if (response.id) {
      //   this.$router.push({ name: 'account', params: { accountId: response.id } });
      // }
    },

    goToApplication(response) {
      let accountId = response.response.id;
      let applicationId = this.account[accountId].currentClientApplicationId;
      this.$router.replace({
        // name: 'clientApplication',
        name: 'clientApplicationWizard',
        params: { accountId: accountId, applicationId: applicationId },
      });
    },

    // ----- Import Client -----

    importClientRecord_change({ field, value }) {
      this.importClientRecord[field] = value;
    },

    async importClient() {
      let record = this.importClientRecord;
      let accountId = this.initiatingAccountId || this.authUser.accountId;
      let response = await this.account[accountId].importClient(record);
    },
  },
};
</script>

<style scoped>
div.flex-container {
  /* border: 1px dotted green; */
  width: 40vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  /* background-color: rgb(242, 241, 241); */
}
div.flex-item {
  /* border: 1px dotted blue; */
  /* margin: 10px; */
  /* padding: 10px; */
  flex-grow: 1;
  flex-basis: 250px;
  /* background-color: white; */
}
</style>
