<template>
<capitec-avatar class="lfx-avatar" type="strong" :value="avatarLetters" :color="avatarColor" size="small" />  
</template>


<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";
import {colorFromId} from '@/landobyte_vue/ldb_utils.ts';

export default {
  components: {},
  props: {  
    record:{type:Object,required:true},
    fields:{type:Array,required:true},
    colorField:{type:String}
  },
  data() {
    return {
      
    }
  },
  created() {

  },
  mounted() {
  },
  computed:{
    ...mapGetters([]),
  	...mapState([]),
    avatarColor() {
      return (this.colorField === undefined || this.record[this.colorField] === undefined) ? colorFromId(this.record.id) : this.record[this.colorField];
    },
    description() {
      return this.fields.map(f => this.record[f]).join(' ');
    },
    upperLetters() {
      return 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    },
    unimportantWords() {
      return ['van','von','du','de','janse','jansen','le','of','and'];
    },
    avatarLetters() {    
      let self = this;
      let allLetters =  this.description.split(' ').map(l => l[0]).filter(l => l).join('');
      let upperLetters = this.description.split(' ').filter(w => (self.unimportantWords.indexOf(w.toLowerCase()) ===  -1) ).map(l => l[0]).filter(l => self.upperLetters.indexOf(l) > -1).join('');
      if (allLetters.length < 3) {
        return allLetters.toUpperCase()
      } else 
      {
        return `${upperLetters[0]}${upperLetters[1]}`;
      }
    }
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([])
  }
 }
</script>
<style>

capitec-avatar.lfx-avatar{
  /* transform:scale(0.8); */
  font-size:11px;
  max-width: 20px;
  max-height: 20px;
  border-radius: 100px;
  overflow:hidden;
}

</style>


