<template>
  <LDBCreateView
    :table="table"
    :definition="definition"
    :collection_path="collectionPath"
    :createButtonLabel="createButtonLabel"
    action="createBeneficiary"
    :params="{ accountId: accountId }"
    @on-create="onCreateBeneficiary"
    @on-api-success="onApiSuccess"
  />
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBCreateView from '@/landobyte_vue/LDBCreateView.vue';

export default {
  name: 'LfxAddBeneficiaryDetails',
  components: { LDBCreateView },
  props: {},
  emits: [],
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    // ----- Account record -----

    accountId() {
      return +this.$route.params.accountId;
    },
    accountRecord() {
      return this.account[this.accountId];
    },

    // ----- Beneficiary definition -----

    definition() {
      return getDefinition('beneficiary');
    },
    collectionPath() {
      return `/account/${this.accountId}/beneficiary`;
    },
    table() {
      return 'LfxBeneficiary';
    },

    // ----- Form Buttons -----

    createButtonLabel() {
      return this.$route.meta?.content?.formButtons?.createButtonLabel;
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    async onCreateBeneficiary(payload) {
      // let response = await this.accountRecord.addBeneficiary(payload);
      // if (response !== undefined) {
      // this.$router.replace({ name: 'completeBeneficiaryBankAccount', params: { beneficiaryId: response.id } });
      // }
      console.log('PAYLOAD', payload);
      this.$router.replace({
        name: 'completeBeneficiaryBankAccount',
        params: { beneficiaryId: payload.response.id },
      });
    },
    async onApiSuccess(responseObject) {
      console.log('RESPONSE OBJECT', responseObject);
      this.$router.replace({
        name: 'completeBeneficiaryBankAccount',
        params: { beneficiaryId: responseObject.response.id },
      });
    },
  },
};
</script>
