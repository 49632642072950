<template>

  <div v-if="view === 'edit' || view === 'create'" class="ldb-field-container__text">
    <capitec-text-field
      :disabled="field.enableGuards === false"
      @value-change="on_change"
      type="numeric"
      @input="on_key_input"
      @focus="on_focus"
      @blur="on_blur"
      :label="`${fieldLabel}`"
      :value="`${inputBoxText}`"
      :error="validationError"
    >
    </capitec-text-field>
  </div>

  <template v-else-if="view === 'item'">
    <LDBItemViewField
      :view="view"
      :field="field"
      :displayValue="displayWithPercentage"
      :definition="definition"
      :record="record"
    />
  </template>

  <template v-else-if="view === 'list'">
    <LDBListViewField
      :view="view"
      :field="field"
      :displayValue="`${displayWithPercentage}`"
      :definition="definition"
      :record="record"
    />
  </template>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import { ldb_field_mixin } from './ldb_field_mixin.js';
import LDBItemViewField from './LDBItemViewField.vue';
import LDBListViewField from './LDBListViewField.vue';

export default {
  mixins: [ldb_field_mixin],
  components: {
    LDBItemViewField,
    LDBListViewField,
  },
  props: {},
  data() {
    return {
      inputBoxText:'',
      focussed: false,
    };
  },
  created() {},
  mounted() {
    this.inputBoxText = this.displayWithoutPercentage || '0';
  },
  watch: {
    value(newValue, oldValue) {
      let textNumValue = this.numValue(this.inputBoxText);
      let newNumValue = this.numValue(newValue);
      if (newNumValue !== textNumValue) {
        if (this.focussed === false) {
          this.inputBoxText = this.percentageText(newNumValue);
        }
      }
    },
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    percentageValueAsDecimal() {
      return (this.value) ? this.value : 0;
    },    
    displayWithoutPercentage() {
      return this.displayWithPercentage.split('%').join('');
    },
    displayWithPercentage() {
      if (this.value === undefined || this.value === null || isNaN(this.value)) {
        return ''
      }
      else {
        let localeDisplayPercentage =  Intl.NumberFormat('en-US',{style:'percent', minimumFractionDigits: 0,  maximumFractionDigits: 2}).format(  this.percentageValueAsDecimal ).split(',').join('');
        return localeDisplayPercentage;
      }
    }
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    isValidInputText(text) {
      const  regex = new RegExp(/^\.[0-9]{0,4}$|^[0-9]{0,4}\.{0,1}[0-9]{0,4}$/);
      return regex.test(text);
    },
    on_focus(evt) {
      this.focussed = true;
    },
    on_blur(evt) {
      this.focussed = false;
      this.inputBoxText = this.displayWithoutPercentage;
    },
    numValue(value) {
      let str = `${value}`.split(',').join('');
      return Number(str || '0');
    },
    percentageText(inputNumber) {
      if (inputNumber === undefined || inputNumber === null || isNaN(inputNumber)) {
        return ''
      }    
      let out =  Intl.NumberFormat('en-US',{style:'percent', minimumFractionDigits: 0,  maximumFractionDigits: 2}).format(  parseFloat(inputNumber  || 0)  ).split(',').join('').split('%')[0];
      return out;
 },
    on_key_input(evt) {
      if ( (this.value === null || this.value === undefined || this.value === '' || this.value === 0) && (evt.data === '.') ) {
        this.inputBoxText = '0.';
      }
      return true;
    },
    on_change(evt) {
      if (this.isValidInputText(evt.detail.new || '')) {
        this.inputBoxText = evt.detail.new || '';
      }
      let newValue = parseFloat((evt.detail.new || 0)) / 100;
      this.$emit('change', newValue);
    },
  },
};
</script>

<style>
</style>
