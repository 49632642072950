<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <ol>
        <li>The user will be disabled.</li>
        <li>They will be unable to log in or perform any actions.</li>
        <li>They will no longer receive any communication.</li>
        <li>They will be disabled as a signatory.</li>
        <li>A user does not need to be disabled to be moved.</li>
        <li>Disabling a user will not allow the same email address to be used elsewhere.</li>
      </ol>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in buttons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

export default {
  name: 'LfxDisableUser',
  components: {},
  props: {},
  emits: {},
  created() {},
  mounted() {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['user']),
    userId() {
      return this.$route.params.userId;
    },
    record() {
      return this.user?.[this.userId];
    },
    header() {
      return `Disable User: ${this.user[this.userId].userDisplayName}`;
    },
    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta?.modal?.cancelRoute : 'todo';
      //TODO: go to user's home as fallback
    },
    buttons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelDisableUser',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.replace({ name: self.cancelRoute });
          },
        },
        {
          name: 'disableUser',
          label: 'Disable User',
          type: 'primary',
          guards: self.record.disableUser_buttonGuard, //TODO
          clickEvent() {
            self.disableUser();
          },
        },
      ];
      return allButtons;
    },
  },
  methods: {
    async disableUser() {
      let response = await this.user[this.userId].disableUser();
      if (response.status === 'success') {
        this.$router.replace({ name: 'userDetailsView' });
      }
    },
  },
};
</script>
