<template>
  <LDBModal result="info" :header="header" size="x-large"  class="lfx-account-consolidated-balance">
    <template #body>
      <h3>{{accountRecord?.name}}</h3>
        <div class="lfx-account-consolidated-balance-view-table-container">
          <table-lite
            id="lfx-account-consolidated-balance-view-table"
            :is-static-mode="true"
            :is-slot-mode="true"
            :has-checkbox="false"
            :is-loading="false"
            :is-re-search="true"      
            :columns="tableColumns"
            :rows="sortedRecords"
            :pageSize="100"
            :total="sortedRecords.length"
          >
            <template v-for="(col, i) of tableColumns" v-slot:[col.field]="data" :key="i">
              <template v-if="col.showAsCurrency">{{ data.value[col.field] ? +data.value[col.field] : '' }}</template>
              <template v-else>{{ data.value[col.field] }}</template>
            </template>
          </table-lite>
        </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in buttons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import TableLite from '@/views/general/TableLite.vue';
import { doPermissionAction } from '@/lfx_rest/lfx_action';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import { formatCurrencyString } from '@/sharedUtils/LdbSharedUtils';

export default {
  name: 'LfxConsolidatedBankAccountBalance',
  components: { LDBLoader,TableLite },
  props: {},
  emits: {},
  async created() {},
  mounted() {
    for (const accountId in this.accountRecord?.bankAccounts) {
      const account = this.accountRecord?.bankAccounts[accountId];
      if (account.isBusinessBankAccount) {
        this.balanceAccounts[accountId] = {
          bankAccountId: accountId,
          accountNumber: account.accountNumber,
          currencyId: account.currencyId,
          decimalPlaces: this.config?.currency?.[account.currencyId].decimalPlaces,

          balanceStatus: 'none',
          balanceError: '',
          currentBalance: undefined,
          availableBalance: undefined,
          accountStatus: undefined,
          holdAmount: undefined,
        }
      }
    }
    this.triggerBalanceRequests();
  },
  data() {
    return {
      onSuccess: {
        doStoreUpdate: false,
      },
      balanceAccounts:{}
    };
  },
  computed: {
    ...mapGetters(['config']),
    ...mapState(['account']),

    // ----- Account record -----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },

    // ----- Markup record ----
    header() {
      return `Consolidated Bank Account Balances for ${this.accountRecord?.name}`;
    },
    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta?.modal?.cancelRoute : 'todo';
    },
    buttons() {
      let self = this;
      let allButtons = [
        {
          name: 'closeBalanceModal',
          label: 'Close',
          type: 'primary',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
      ];
      return allButtons.filter((button) => button.guards);
    },
        //----- Table -----
    tableColumns() {
      let self = this;
      let res = [
        {
          col: 'accountNumberColumn',
          field: 'accountNumber',
          label: 'Account Number',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'currencyIdColumn',
          field: 'currencyId',
          label: 'Currency',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'balanceStatusColumn',
          field: 'balanceStatus',
          label: 'Balance Status',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'accountStatusColumn',
          field: 'accountStatus',
          label: 'Account Status',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'currentBalanceColumn',
          field: 'currentBalance',
          label: 'Current Balance',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'availableBalanceColumn',
          field: 'availableBalance',
          label: 'Available Balance',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'holdAmountColumn',
          field: 'holdAmount',
          label: 'Hold Amount',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
      ];
      return res.filter((f) => f.guards === true);
    },
    sortedRecords() {
      const accountArray = [];
      for (const balanceAccountId in this.balanceAccounts) {
        const balanceAccount = this.balanceAccounts[balanceAccountId];
        accountArray.push({
          accountNumber: balanceAccount.accountNumber,
          currencyId: balanceAccount.currencyId,
          accountStatus: balanceAccount.balanceStatus === 'failed' ? balanceAccount.balanceError?.displayMessage : balanceAccount.accountStatus,

          balanceStatus: balanceAccount.balanceStatus.charAt(0).toUpperCase() + balanceAccount.balanceStatus.slice(1),
          currentBalance: formatCurrencyString(balanceAccount.currentBalance),
          availableBalance: formatCurrencyString(balanceAccount.availableBalance),
          holdAmount: formatCurrencyString(balanceAccount.holdAmount),
        }
        );
      }
      return accountArray;
    },
  },
  methods: {
    async triggerBalanceRequests() {
      for (const balanceAccountId in this.balanceAccounts) {
        const balanceAccount = this.balanceAccounts[balanceAccountId];
        await this.triggerBalanceRequest(balanceAccount) // If you want to make this parallel rather than series change await to void here
      }
    },
    async triggerBalanceRequest(balanceAccount) {
      balanceAccount.balanceStatus = 'requested';
      const balanceResult = await doPermissionAction(this, 'balanceEnquiry', {
        accountId: this.accountId,
        bankAccountId: balanceAccount.bankAccountId,
      });
      if (balanceResult.status === 'success') {
        balanceAccount.balanceStatus = 'success';
        balanceAccount.currentBalance = balanceResult.response.currentBalance * 100;
        balanceAccount.availableBalance = balanceResult.response.availableBalance * 100;
        balanceAccount.accountStatus = balanceResult.response.accountStatus;
        balanceAccount.holdAmount = balanceResult.response.holdAmount * 100;
      } else {
        balanceAccount.balanceStatus = 'failed';
        balanceAccount.balanceError = balanceResult?.response?.response?.data?.error || balanceResult?.response?.response?.data;

        if (balanceAccount.balanceError.message) {
          balanceAccount.balanceError.displayMessage = balanceAccount.balanceError.message
        if (balanceResult?.response?.response?.data?.error.codeReason === 'BANCS-1018') {
          balanceAccount.balanceError.displayMessage = "BaNCS Access Failure"
        }
        if (balanceResult?.response?.response?.data?.error.codeReason) {
          balanceAccount.balanceError.displayMessage = balanceResult?.response?.response?.data?.error.codeReason + " - " + balanceAccount.balanceError.displayMessage
        }
      } else {
        balanceAccount.balanceError.displayMessage = 'An Unknown Error has Occured';
      }


      }
    }
  },
};
</script>
<style>
.lfx-account-consolidated-balance-view-table-container {
  position: relative;
  min-height: 100%;
}

#lfx-account-consolidated-balance-view-table {
  min-width: 100%;
}

capitec-modal.lfx-account-consolidated-balance {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
</style>
