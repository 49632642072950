//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LdbCountryDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Country record for LandoByte TS systems
//  Created     : 30 August 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import { standardTextWhiteList } from '../sharedUtils/LdbValidations';
import type { LdbDefinition } from './LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------

export type LdbCountryStatus = 'enabled' | 'disabled';

//----------------------------------------------------------------------------------------------------------------------

export interface LdbCountryInt {
  id?:string,
  name?:string,
  code3?:string,
  codeM49?:string,
  status?: LdbCountryStatus
}

//----------------------------------------------------------------------------------------------------------------------

export const LdbCountryDef:LdbDefinition = {
  apiUrl() {
    return '/country'
  },
  title:"Country",
  table:'LdbCountry',
  collectionPath:"/config/1/country",
  view:{create:true,edit:true,item:true,list:'LfxCountryTile',delete:false,csv:false},
  indexes:[],
  pagingType:'frontEnd',
  fields:{
    id:{
      label:'Code',
      datatype:{'id':{descriptionField:'name',type:'text'}},
      default:null,
      mandatory:false,
      validators:{
        isAlpha:{msg:'Please use only alpha charaters'},
        len:{msg:'Code should be 2 characters long',args:[2,2]}
      },
      views:{create:true,edit:true,item:true,list:true,delete:false,csv:false}
    },
    name:{
      label:'Name',
      datatype:'text',
      default:null,
      mandatory:true,
      validators:{
        is:{msg:'Please use only alphanumeric charaters',args:standardTextWhiteList},
        len:{msg:'Name should be between 3 and 50 characters',args:[3,50]}
      },
      views:{create:true,edit:true,item:true,list:false,delete:false,csv:false}
    },
    code3:{
      label:'Code Alpha 3',
      datatype:'text',
      default:null,
      mandatory:false,
      validators:{
        isAlpha:{msg:'Please use only alpha charaters'},
        len:{msg:'Code Alpha 3 should be 3 characters long',args:[3,3]}
      },
      views:{create:true,edit:true,item:true,list:true,delete:false,csv:false}
    },
    codeM49:{
      label:'M49',
      datatype:'text',
      default:null,
      mandatory:false,
      validators:{
        isNumeric:{msg:'Please use only numeric charaters'},
        len:{msg:'M49 Code should be between 1 and 4 characters long',args:[1,4]}
      },
      views:{create:true,edit:true,item:true,list:true,delete:false,csv:false}
    },
    status: {
      label:'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'enabled', name: 'Enabled', tagType: 'info' },
            { id: 'disabled', name: 'Disabled', tagType: 'cancelled' },
          ],
        },
      },
      default: 'enabled',
      search: true,
      mandatory: true,
      views: { create: false, edit: true, item: true, list: true, delete: false, csv: false },
    }
  }
}
