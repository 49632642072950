//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LdbValidations.ts
//  Author      : Bevan Timm
//  Description : This module contains shared validation logic for LandoByte TS Systems
//  Created     : 26 August 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import { isValidIdNumber, isValidTaxNumber, isValidVatNumber } from './south-africa-regex';

import type { LfxAccountIdenticationType } from '../definitions/LfxAccountDef';

import { LdbValidators } from '../definitions/LdbInterfaces';

//@ts-ignore
const validator = require('validator');

//----------------------------------------------------------------------------------------------------------------------

export const isValidId = (idType: LfxAccountIdenticationType | 'cifNumber', identification: string) => {
  if (!identification) {
    return true;
  }
  var idValid = false;
  switch (idType) {
    case 'rsaRegistrationNumber':
      idValid = isValidRsaRegistration(identification);
      break;
    case 'internationalRegistrationNumber':
      idValid = true;
      break;
    case 'rsaId':
      idValid = isValidRsaId(identification);
      break;
    case 'passport':
      idValid = true;
      break;
    case 'rsaNonCompanyRegistrationNumber':
      idValid = true;
      break;
    case 'cifNumber':
      idValid = true;
      break;
  }
  return idValid;
};

export const isValidRsaId = (idNumber: string | undefined) => {
  if (idNumber === undefined || idNumber === null) {
    return false;
  }
  return !isValidIdNumber(idNumber).error;
};

export const isValidRsaRegistration = (registrationNumber: string) => {
  const pattern = '[0-9]{4}/[0-9]{6,7}/(06|07|08|09|10|12|21|22|23|24|25|26|30|31)';
  return new RegExp(`^${pattern}$`).test(registrationNumber);
};

export const isValidCifNumber = (cifNumber:string) => {
  return isNumericWhiteList.test(cifNumber) && cifNumber.length === 8;
}
//----------------------------------------------------------------------------------------------------------------------

export const isValidRsaTaxNumber = (taxNumber: string) => {
  if (!taxNumber) {
    return true;
  }
  return !isValidTaxNumber(taxNumber).error;
};

export const isValidRsaVatNumber = (vatNumber: string) => {
  if (!vatNumber) {
    return true;
  }
  return !isValidVatNumber(vatNumber).error;
};

//----------------------------------------------------------------------------------------------------------------------

export const msisdnValidator = (msisdn: string | undefined) => {
  if (!msisdn) {
    return true;
  }
  msisdn = cleanMsisdn(msisdn);
  if (!isValidMsisdn(msisdn)) {
    throw new Error('Please enter a valid phone number');
  } else {
    return true;
  }
};
export const cleanMsisdn = (msisdn: string) => {
  msisdn = msisdn.replace(/\D/g, '');
  if (msisdn[0] === '0') {
    msisdn = msisdn.substring(1);
    if (msisdn[0] === '0') {
      msisdn = msisdn.substring(1);
    }
    if (msisdn.length === 9) {
      msisdn = '27' + msisdn;
    }
  }
  return msisdn;
};
export const isValidMsisdn = (msisdn: string) => {
  if (!/^\d+$/.test(msisdn)) {
    return false;
  }
  var minLength: number;
  var maxLength: number;
  if (msisdn.substring(0, 2) === '27') {
    minLength = 11;
    maxLength = 11;
  } else {
    minLength = 7;
    maxLength = 15;
  }
  if (msisdn.length >= minLength && msisdn.length <= maxLength) {
    return true;
  } else {
    return false;
  }
};

//----------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------

export const extendedValidator = {
  ...validator,
  notEmpty(str: string) {
    return !str.match(/^[\s\t\r\n]*$/);
  },
  len(str: string, min: number, max: number | undefined) {
    return validator.isLength(str, { min: min, max: max });
  },
  isUrl(str: string) {
    return validator.isURL(str);
  },
  isIPv6(str: string) {
    return validator.isIP(str, 6);
  },
  isIPv4(str: string) {
    return validator.isIP(str, 4);
  },
  isValidRsaId(str: string) {
    return isValidRsaId(str);
  },
  notIn(str: string, values: any[]) {
    return !validator.isIn(str, values);
  },
  regex(str: string, pattern: any, modifiers: any) {
    str += '';
    if (Object.prototype.toString.call(pattern).slice(8, -1) !== 'RegExp') {
      pattern = new RegExp(pattern, modifiers);
    }
    return str.match(pattern);
  },
  notRegex(str: string, pattern: any, modifiers: any) {
    return !this.regex(str, pattern, modifiers);
  },
  isDecimal(str: string) {
    return str !== '' && !!str.match(/^(?:-?(?:[0-9]+))?(?:\.[0-9]*)?(?:[eE][+-]?(?:[0-9]+))?$/);
  },
  min(str: string, val: number) {
    const number = parseFloat(str);
    return isNaN(number) || number >= val;
  },
  max(str: string, val: number) {
    const number = parseFloat(str);
    return isNaN(number) || number <= val;
  },
  not(str: string, pattern: any, modifiers: any) {
    return this.notRegex(str, pattern, modifiers);
  },
  contains(str: string, elem: any) {
    return !!elem && str.includes(elem);
  },
  notContains(str: string, elem: any) {
    return !validator.contains(str, elem);
  },
  is(str: string, pattern: any, modifiers: any) {
    return this.regex(str, pattern, modifiers);
  },
  isDate(date: string) {
    return validator.isDate(new Date(date));
  },
  isBefore(date: string, elem: any) {
    return validator.isAfter(date, elem?.args ? elem.args[0] : undefined);
  },
  isAfter(date: string, elem: any) {
    return validator.isAfter(date, elem?.args ? elem.args[0] : undefined);
  },
};

//----------------------------------------------------------------------------------------------------------------------

export function validateValue(value: any, validators: LdbValidators) {
  if (value !== undefined && value !== '') {
    if (validators !== undefined) {
      for (const validationName in validators) {
        const validator = validators[validationName];
        const validationResult = testValidation(validationName as keyof typeof extendedValidator, validator, value);
        if (validationResult !== true) {
          return validationResult;
        }
      }
    }
  }
  return true;
}

const testValidation = (validationName: keyof typeof extendedValidator, validation: any, value: any): true | string => {
  const args = validation.args === undefined ? validation : validation.args;
  let validationResult: boolean | string;
  if (typeof args === 'function') {
    validationResult = args(value);
  } else if (Array.isArray(args)) {
    if (args.length === 1) {
      //@ts-ignore
      validationResult = extendedValidator[validationName](value, args[0]);
    } else if (args.length === 2) {
      //@ts-ignore
      validationResult = extendedValidator[validationName](value, args[0], args[1]);
    } else {
      //@ts-ignore
      validationResult = extendedValidator[validationName](value, args[0], args[1], args[2]);
    }
  } else {
    if (args === undefined) {
      //@ts-ignore
      validationResult = extendedValidator[validationName](value);
    } else {
      //@ts-ignore
      validationResult = extendedValidator[validationName](value, args);
    }
  }
  if (!validationResult) {
    return validation.msg ? validation.msg : 'Validation failed';
  }
  return true;
};

//----------------------------------------------------------------------------------------------------------------------

export const isValidDealPrefixForFecModification = (value: string | null | undefined, object?: object) => {
  if (typeof value === 'string') {
    const prefix = value.split('-')?.[0];
    return ['3', '171'].includes(prefix) ? true : false;
  } else {
    return false;
  }
};

export const isValidDealNumber = (value:string| null |undefined,_object?: any) => {
  if (typeof value === 'string') {
    const splitValue = value.split('-');
    if (splitValue.length !== 2) return false
    if (!['3', '169', '171'].includes(splitValue[0])) return false
    if (!(new RegExp("^[0-9]*$")).test(splitValue[1])) return false
    return true
  } else {
    return false;
  }
}
//----------------------------------------------------------------------------------------------------------------------
// VALIDATION REGEX
//----------------------------------------------------------------------------------------------------------------------

export const extendedTextWhiteList = new RegExp('^[a-zA-Z0-9&=_+%?!.,;:/\'`@*#$[()\\\\"\\]\\- \n\r]*$');
export const standardTextWhiteList = new RegExp('^[a-zA-Z0-9&=_+%?!.,;:/\'`@*#$[()\\\\"\\]\\- ]*$');
export const swiftTextWhiteList = new RegExp("^[a-zA-Z0-9 /?:().,+'-]*$");
export const isAlphaWhiteList = new RegExp("^[a-zA-Z_\\- ']*$");
export const isNumericWhiteList = new RegExp('^[0-9+.,%]*$');

//----------------------------------------------------------------------------------------------------------------------
