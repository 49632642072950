import store from '@/store/';

// @ts-ignore
export async function checkClientId(data) {
  // @ts-ignore
  store.dispatch('showLoader', true);
  let payload = data;
  let options = {};
  // @ts-ignore
  let response = await store.dispatch('db_put_action', {
    //@ts-ignore
    path: `/client/checkId`,
    payload,
    options,
  });
  //@ts-ignore
  store.dispatch('showLoader', false);
  return response;
}

export function populateLabelFields(record:any,view:any) {
  if (record.definition) {
    for (const field of record.definition.fields) {
      if (field.views[view]) {
        let saveLabel = false;
        let labelValue = record[field.name];
        if (field.datatype.id?.descriptionField) {
          saveLabel = true
          const fkLabel = record[field.datatype.id.descriptionField];
          if (fkLabel) {
            labelValue = fkLabel
          }
        } else if (field.datatype.foreignKey?.collection) {
          saveLabel = true
          if (record[field.name])  {
            const fkLabel = record[field.datatype.foreignKey.collection]?.[record[field.name]]?.[field.datatype.foreignKey.displayField];
            if (fkLabel) {
              labelValue = fkLabel
            }
          }
        } else if (field.datatype?.option?.options) {
          saveLabel = true
          if (record[field.name])  {
            let optionLabel = labelValue;
            for (const option of field.datatype?.option?.options) {
              if (option.id === optionLabel) {
                optionLabel = option.name
              }
            }
            labelValue = optionLabel
          }

        }
        if (saveLabel) {
          record[field.name+"_label"] = labelValue
        }
      }
    }
  }
}