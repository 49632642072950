<template>
  <LFXContent
    :titleBar="titleBar"
    :show_tabs="showTabs"
    :tabs="tabs"
    :show_stepper="showStepper"
    :show_actions="showActions"
    :show_banners="showBanners"
    :form_layout="formLayout"
    :show_label="showLabel"
    :label="contentLabel"
    :show_sidebar="false"
  >
    <template #actions>
      <LDBButton
        v-for="button in actionBarButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
    <template #banners>
      <LfxBanner
        v-for="banner in banners"
        :key="banner.id"
        :title="banner.title"
        :status="banner.status"
        :closable="banner.closable"
        ><div v-html="banner.content"></div
      ></LfxBanner>
    </template>


    <template #stepper
      ><capitec-stepper
        layout="horizontal"
        :steps="steps"
        :active-step-number="activeStepNumber"
        @step-click="onStepClick"
      >
      </capitec-stepper
    ></template>

    <template #content>
      <router-view @step-back="stepBack" @step-next="stepNext"></router-view>
    </template>
  </LFXContent>

  <router-view name="modal"></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import LFXContent from '@/components/layout/LfxContent.vue';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxSwift',
  components: { LFXContent },
  props: {},
  data() {
    return {};
  },
  created() {
    this.accountRecord.activate('swift');
    this.loadPayment();

    if (this.$route.name === 'swift') {
      this.selectFirstTab();
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'swift' && to.params.swiftId === from.params.swiftId) {
      next(false);
      this.selectFirstTab();
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters(['permissions', 'hasAllSystemRequirementsForTradingBeenMet','iAmAnUnmigratedIntermediary']),
    ...mapState(['authUser', 'account', 'swift', 'transaction', 'payment']),

    // ----- Title Bar -----

    titleBar() {
      const titleBar = {
        label: this.titleBarLabel,
        buttons: [],
      };
      return titleBar;
    },
    // titleBarLabel() {
    //   return this.showTabs
    //     ? `Client: ${this.accountRecord?.name} / SWIFT: ${this.swiftRecord.swiftNumber}`
    //     : `Client: ${this.accountRecord?.name} / SWIFT: ${this.swiftRecord.swiftNumber} / ${this.$route.meta?.titleBar?.label}`;
    // },
    titleBarLabel() {
      return this.showTabs
        ? `${this.accountRecord?.name} / SWIFT: ${this.swiftRecord.swiftNumber}`
        : `${this.accountRecord?.name} / SWIFT: ${this.swiftRecord.swiftNumber} / ${this.$route.meta?.titleBar?.label}`;
    },

    // ----- Banners -----

    showBanners() {
      return this.banners?.length > 0 || false;
    },
    banners() {
      let allBanners =  [
        {
          id: `remitterAccountCouldBeRounded-${this.swiftId}`,
          title: 'Remitter Account could be Rounded',
          status: 'warning',
          closable: false,
          content: `The remitter's bank account in this SWIFT is very long and may have been rounded during import. Please check to confirm that it is correct and contact support@landobyte.com if it is not.`,
          guards: this.swiftRecord.warnAboutPotentialRemitterAccountRounding
        }
      ]
      return allBanners.filter((banner) => banner.guards);
    },

    // ----- Tabs -----

    showTabs() {
      return this.$route.meta?.content?.showTabs === false ? false : true;
    },
    tabs() {
      let tabs = [
        {
          tabName: 'swift',
          routeName: 'swiftDetails',
          guards: true,
          label: 'SWIFT Details',
        },
        {
          tabName: 'linkedPayment',
          routeName: 'linkedPaymentDetailsView',
          routeParams: { transactionId: this.transactionId, paymentId: this.paymentId },
          guards: !!this.swiftRecord.paymentId,
          // guards: true,
          label: 'Linked Payment',
        },
        {
          tabName: 'logs',
          routeName: 'swiftLogs',
          guards: true,
          label: 'Logs',
        },
      ];
      return tabs.filter((tab) => tab.guards);
    },

    // ----- Stepper -----

    showStepper() {
      return !this.showTabs;
    },
    steps() {
      let steps = [
        {
          stepName: 'swiftDealCapture',
          routeName: 'swiftDealCapture',
          guards: true,
          isError: false, // TODO
          title: 'Capture Deal',
        },
        {
          stepName: 'swiftDealReview',
          routeName: 'swiftDealReview',
          guards: true,
          isError: false,
          title: 'Review and Submit',
        },
      ];
      return steps.filter((step) => step.guards);
    },

    routeStepName() {
      return this.$route.meta?.content?.stepName;
    },
    activeStepNumber() {
      let activeStepIndex = this.steps.findIndex((step) => step.stepName === this.routeStepName);
      let activeStepNumber = activeStepIndex + 1; // stepnumber starts at 1
      return activeStepNumber;
    },

    // ----- Action Bar -----

    showActions() {
      return this.showStepper ? false : true;
    },
    actionBarButtons() {
      if (this.iAmAnUnmigratedIntermediary) { return [] }
      let self = this;
      let actions = {
        swiftDetails: [
          {
            name: 'asignToClient',
            label: 'Assign to Client',
            type: '',
            action: 'click',
            guards: !['complete','cancelled'].includes(self.swiftRecord.status) &&
                    !!this.permissions.assignSwiftToClient &&
                    (this.accountRecord.accountLevel === 'bank' || this.accountRecord.bankDirectClients || this.accountRecord.allowClientsToSubmitPortfolios),
            enabledGuards: true,
            clickEvent() {
              self.$router.push({ name: 'assignSwiftToClient' });
            },
          },
          {
            name: 'asignToBankAccount',
            label: 'Assign to Bank Account',
            type: '',
            action: 'click',
            guards: !['complete','cancelled','noClient'].includes(self.swiftRecord.status) &&
                    !!this.permissions.assignSwiftToBankAccount,
            enabledGuards: true,
            clickEvent() {
              self.$router.push({ name: 'assignSwiftToBankAccount', params: { accountId: self.accountId } });
            },
          },
          {
            name: 'markAsAvailableButton',
            label: 'Mark as Available',
            type: '',
            modal: {
              header: `Mark: ${this.swiftRecord.swiftNumber} as Available`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Update',
              content: this.markAsAvailableModalMessage,
            },
            action: 'updateInwardSwiftStatus',
            params: { swiftId: self.swiftId },
            actionParams: { newStatus: 'available' },
            guards: !['available','noClient','noBankAccount'].includes(self.swiftRecord.status),
          },
          {
            name: 'markAsCompleteButton',
            label: 'Mark as Complete',
            type: '',
            modal: {
              header: `Mark: ${this.swiftRecord.swiftNumber} as Complete`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Update',
              content: this.markAsCompleteModalMessage,
            },
            action: 'updateInwardSwiftStatus',
            params: { swiftId: self.swiftId },
            actionParams: { newStatus: 'complete' },
            guards: self.swiftRecord.status !== 'complete' && self.swiftRecord.status !== 'cancelled',
          },
          {
            name: 'markAsCancelledButton',
            label: 'Mark as Cancelled',
            type: '',
            modal: {
              header: `Mark: ${this.swiftRecord.swiftNumber} as Cancelled`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Update',
              content: this.markAsCancelModalMessage,
            },
            action: 'updateInwardSwiftStatus',
            params: { swiftId: self.swiftId },
            actionParams: { newStatus: 'cancelled' },
            guards: self.swiftRecord.status !== 'cancelled',
          },

          {
            name: 'bookDeal',
            label: 'Book Inward Deal',
            type: '',
            action: 'click',
            guards:
              this.swiftRecord.status === 'available' &&
              this.accountRecord?.accountIsVisible &&
              !!this.permissions.bookDeal,
            enabledGuards:
              this.accountRecord?.activeForTrading &&
              this.hasAllSystemRequirementsForTradingBeenMet &&
              this.authUser.accountIsActive,
            clickEvent() {
              self.$router.push({ name: 'swiftDealCapture', params: { accountId: self.accountId } });
            },
          },
          {
            name: 'bookPayment',
            label: 'Receive Inward Payment',
            type: '',
            action: 'click',
            guards: self.swiftRecord.status === 'available' && !!self.permissions.bookTransfer,
            clickEvent() {
              self.$router.push({ name: 'swiftPaymentCapture', params: { accountId: self.accountId } });
            },
          },
        ],
      };
      return actions[this.actionGroup] ? actions[self.actionGroup] : [];
    },
    actionGroup() {
      return this.$route.meta?.content?.actionGroup;
    },

    // ----- Content Label -----

    showLabel() {
      return this.contentLabel ? true : false;
    },
    contentLabel() {
      return this.$route.meta?.content?.label
        ? this.$route.meta.content.label
        : "Navigation didn't complete, please click tab above to continue";
    },

    // ----- Layout -----

    formLayout() {
      return this.$route.meta?.content?.layout === 'list' ? false : true;
    },

    // ----- Account record -----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },

    // ----- SWIFT Record -----

    swiftId() {
      return +this.$route.params?.swiftId;
    },
    swiftRecord() {
      return this.swift?.[this.swiftId];
    },

    // ----- Transaction record -----

    transactionId() {
      return +this.swiftRecord?.transactionFileId;
    },
    transactionRecord() {
      return this.transaction?.[this.transactionId];
    },

    // ----- Payment record -----

    paymentId() {
      return +this.swiftRecord?.paymentId;
    },
    paymentRecord() {
      return this.payment?.[this.paymentId];
    },

    hasPaymentRecordLoaded() {
      return this.paymentRecord?.id ? true : false;
    },

    // --------------- Modal Message ---------

    markAsAvailableModalMessage() {
      return `
      <ol>
        <li>The SWIFT will be marked as available.</li>
        <li>It will be available for deal booking or to be assigned to a payment.</li>
      </ol>`;
    },
    markAsCompleteModalMessage() {
      return `
      <ol>
        <li>The SWIFT will be marked as complete.</li>
      </ol>`;
    },
    markAsCancelModalMessage() {
      return `
      <ol>
        <li>The SWIFT will be marked as cancelled.</li>
      </ol>`;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    //
    // ----- Title Bar -----

    onTitleBarButtonClick(button) {
      this.$router.push({ name: button.routeName });
    },

    // ----- Tabs -----

    selectFirstTab() {
      if (this.tabs.length) {
        this.$router.replace({ name: this.tabs[0].routeName });
      }
    },

    // ----- Stepper -----

    onStepClick(evt) {
      let newStepNumber = evt.detail.stepNumber;
      let newStepNumberIndex = newStepNumber - 1; // stepnumber starts at 1
      let newStep = this.steps[newStepNumberIndex];
      this.$router.replace({ name: newStep.routeName });
    },
    stepBack() {
      let newStepNumber = this.activeStepNumber - 1;
      let newStepNumberIndex = newStepNumber - 1; // stepnumber starts at 1
      let newStep = this.steps[newStepNumberIndex];
      this.$router.replace({ name: newStep.routeName });
    },
    stepNext() {
      let newStepNumber = this.activeStepNumber + 1;
      let newStepNumberIndex = newStepNumber - 1; // stepnumber starts at 1
      let newStep = this.steps[newStepNumberIndex];
      this.$router.replace({ name: newStep.routeName });
    },

    // ----- Payment -----

    loadPayment() {
      if (this.paymentId && !this.hasPaymentRecordLoaded) {
        doPermissionAction(this, 'getPayment', { transactionId: this.transactionId, paymentId: this.paymentId });
      }
    },
  },
};
</script>

<style>
/* div.container {
} */
</style>
