//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxBeneficiaryDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Beneficiary or Remitter record for LandoByte TS systems
//  Created     : 13 September 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import { standardTextWhiteList, swiftTextWhiteList } from '../sharedUtils/LdbValidations';
import type { LdbAuthUser, LdbDefinition, LdbFieldLabelFunction } from './LdbInterfaces';

import type { LfxAccountAccountType } from './LfxAccountDef';

//----------------------------------------------------------------------------------------------------------------------

export type LfxBeneficiaryStatus = 'active' | 'disabled';
export type LfxBeneficiaryGender = 'male' | 'female';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxBeneficiaryInt {
  id?: number;
  uuid?: string;
  accountId?: number;
  reference?: string;
  status?: LfxBeneficiaryStatus;

  beneficiaryType?: LfxAccountAccountType;
  name?: string;
  individualFirstname?: string;
  individualGender?: LfxBeneficiaryGender;
  rsaResident?: boolean;
  passportNumber?: string;
  passportCountryId?: string;
  address?: string;
  city?: string;
  province?: string;
  postalCode?: string;
  countryId?: string;
  externalReference?: string;
  notes?: string;

  restrictedView?: boolean;

  lastEditedAt?: Date;
  lastEditedBy?: number;
  lastEditedByName?: string;

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

const nameFieldLabel:LdbFieldLabelFunction = (viewFunctionObject) => {
  if (viewFunctionObject.view !== 'list' && viewFunctionObject.record) {
    if ((viewFunctionObject.record as LfxBeneficiaryInt).beneficiaryType === 'entity') {
      return "Name"
    } else if ((viewFunctionObject.record as LfxBeneficiaryInt).beneficiaryType === 'individual') {
      return "Surname"
    }

  }
  return 'Name or Individual Surname'
}

//----------------------------------------------------------------------------------------------------------------------

const addToStoreIfNotExist = (_dbObject: LfxBeneficiaryInt, authUser: LdbAuthUser) => {
  return authUser.accountLevel !== 'bank';
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxBeneficiaryDef: LdbDefinition = {
  title: 'Beneficiary/Remitter',
  table: 'LfxBeneficiary',
  // collectionPath: '/account/{{accountId}}/beneficiary',
  collectionPath: '/beneficiary',
  addToStoreIfNotExist: addToStoreIfNotExist,
  view: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
  indexes: ['accountId'],
  pagingType: 'backEnd',
  groups: {
    system: { priority: 1, label: 'System' },
    general: { priority: 2, label: 'General' },
    individual: { priority: 3, label: 'Individual' },
    contact: { priority: 4, label: 'Contact' },
    other: { priority: 5, label: 'Notes' },
    changes: { priority: 6, label: 'Changes' },
  },

  fields: {
    id: {
      label: 'Name',
      datatype: { id: { descriptionField: 'fullName' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },
    uuid: {
      label: 'UUID',
      datatype: 'uuid',
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    accountId: {
      label: 'Account',
      datatype: {
        foreignKey: {
          collection: 'fk_accounts',

          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },
    reference: {
      label: 'Reference',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },
    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'active', name: 'Active', tagType: 'success' },
            { id: 'disabled', name: 'Disabled', tagType: 'error' },
          ],
        },
      },
      default: 'active',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },

    beneficiaryType: {
      label: 'Beneficiary Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'entity', name: 'Entity' },
            { id: 'individual', name: 'Individual' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: null,
      mandatory: true,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'general',
    },
    name: {
      label: nameFieldLabel,
      datatype: 'text',
      default: null,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alpha charaters', args: [swiftTextWhiteList] },
        // len: { msg: 'Name should be between 1 and 35 characters', args: [1, 35] }, //SWIFTValidationRemoved
      },
      views: { create: true, edit: true, item: false, list: false, delete: false, csv: false },
      group: 'general',
    },
    individualFirstname: {
      label: 'Individual First Name',
      datatype: 'text',
      default: null,
      mandatory: false,
      validators: {
        is: { msg: 'Please use only alpha charaters', args: [swiftTextWhiteList] },
        // len: { msg: 'First Name should be between 1 and 35 characters', args: [1, 35] }, //SWIFTValidationRemoved
        // custom: { //SWIFTValidationRemoved
        //   vFunction: (value: any, object: any) => {
        //     return (object.fullName?.length || 0) <= 35
        //   },
        //   msg: 'Full name cannot be longer than 35 characters',
        //   validatingInterface: 'frontend',
        // },
      },
      guards: { beneficiaryType: { eq: 'individual' } },
      views: { create: true, edit: true, item: false, list: false, delete: false, csv: false },
      group: 'individual',
    },
    fullName: {
      label: 'Name',
      datatype: 'text',
      default: '',
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      mandatory: false,
      property: { read: true, write: false, source: 'frontend', sort: 'name' },
      group: 'general',
    },
    individualGender: {
      label: 'Individual Gender',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'male', name: 'Male' },
            { id: 'female', name: 'Female' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      guards: { beneficiaryType: { eq: 'individual' } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'individual',
    },
    rsaResident: {
      label: 'Individual RSA Resident',
      datatype: {
        option: {
          optionType: 'boolean',
          options: [
            { id: false, name: 'No' },
            { id: true, name: 'Yes' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      guards: { beneficiaryType: { eq: 'individual' } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'individual',
    },
    passportNumber: {
      label: 'Passport Number',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: swiftTextWhiteList },
        len: { msg: 'Passport Number should be between 5 and 50 characters', args: [5, 50] },
      },
      guards: { beneficiaryType: { eq: 'individual' }, rsaResident: { eq: false } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'individual',
    },
    passportCountryId: {
      label: 'Passport Country',
      datatype: {
        foreignKey: {
          collection: 'fk_countries',

          linkTable: 'LdbCountry',
          linkField: 'id',
          displayField: 'name',
          type: 'text',
          guards: {status: {eq:"enabled"}}
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      guards: { beneficiaryType: { eq: 'individual' }, rsaResident: { eq: false } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'individual',
    },

    address: {
      label: 'Address',
      datatype: 'text',
      default: null,
      mandatory: true,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: swiftTextWhiteList },
        len: { msg: 'Address should be between 5 and 35 characters (SWIFT Standard)', args: [5, 35] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    city: {
      label: 'City',
      datatype: 'text',
      default: null,
      mandatory: true,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: swiftTextWhiteList },
        len: { msg: 'City should be between 1 and 35 characters (SWIFT Standard)', args: [1, 35] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    province: {
      label: 'Province',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: swiftTextWhiteList },
        len: { msg: 'Province should be between 1 and 35 characters (SWIFT Standard)', args: [1, 35] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    postalCode: {
      label: 'Postal Code',
      datatype: 'text',
      default: null,
      mandatory: true,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: swiftTextWhiteList },
        len: { msg: 'Postal Code should be between 1 and 20 characters', args: [1, 20] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    countryId: {
      label: 'Country',
      datatype: {
        foreignKey: {
          collection: 'fk_countries',

          linkTable: 'LdbCountry',
          linkField: 'id',
          displayField: 'name',
          type: 'text',
          guards: {status: {eq:"enabled"}}
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    accountCurrencies: {
      label: 'Account Currencies',
      datatype: 'text',
      default: null,
      mandatory: true,
      property: {source:'frontend',read:true,write:false, sort: 'none'},
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
      group: 'system',
    },
    externalReference: {
      label: 'External Reference',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'External Reference should be between 0 and 250 characters', args: [0, 250] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'other',
    },
    notes: {
      label: 'Notes',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Notes should be between 0 and 250 characters', args: [0, 250] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'other',
    },

    restrictedView: {
      label: 'Restricted View',
      datatype: 'text',
      search: true,
      property: { source: 'backend', read: true, write: false, sort: 'none' },
      default: false,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },

    lastEditedAt: {
      label: 'Details Last Edited At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: true },
      group: 'changes',
    },
    lastEditedBy: {
      label: 'Details Last Edited By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    lastEditedByName: {
      label: 'Details Last Edited By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'changes',
    },

    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
  },
};

export const beneficiaryIndividualFields = () => {
  return ['individualFirstname','individualGender','rsaResident','passportNumber','passportCountryId']
}