<template>
  <div  class="container">
  TODO:{{datatypeName}}:{{value}}
  </div>
</template>


<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";
import {ldb_field_mixin} from './ldb_field_mixin.js';

export default {
  mixins:[ldb_field_mixin],
  components: {},
  props: {  
  },
  data() {
    return {
    }
  },
  created() {
    
  },
  mounted() {

  },
  computed:{
    ...mapGetters([]),
  	...mapState([]),
  	datatypeName() {
      let dt = this.field.datatype;
      if (typeof dt === 'string') {
        return dt
      } else 
      if (typeof dt === 'object' ) {
        return Object.keys(dt)[0]
      }
    },
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([])
  }
 }
</script>
<style>

	div.container{

  }

</style>


