import VueStore from 'vue-class-store';

import { LdbDbObject, LdbDefinition } from '@/definitions/LdbInterfaces';
import { LfxTransactionFileInt, LfxTransactionFileDef } from '@/definitions/LfxTransactionFileDef';
import { LfxModel, LfxDefinition } from './LfxModel';

import { doPermissionAction } from '@/lfx_rest/lfx_action';

// @ts-expect-error
import { http_patch, http_get, http_put } from '@/lfx_rest';
import { getLowestPaymentStatus } from './LfxPaymentMdl';

@VueStore
// @ts-expect-error
class LfxTransactionFileMdl extends LfxDefinition {
  // @ts-expect-error
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
// @ts-expect-error
export class LfxTransactionFile extends LfxModel {
  // @ts-expect-error
  constructor(data, state) {
    super({ definition_name: 'transaction', ...data }, state);
  }

  // --------------------
  // ----- General -----
  // --------------------

  get link() {
    // @ts-expect-error
    return `/account/${this.accountId}/transaction/${this.id}`;
  }

  // get orgininalValueDateAsDateString() {
  //   // @ts-expect-error
  //   if (typeof this.originalValueDate !== 'string') {
  //     // @ts-expect-error
  //     console.log(this.id, this.originalValueDate);
  //   }
  //   // @ts-expect-error
  //   return this.originalValueDate.substring(0, 10).replaceAll('/', '-');
  // }
  get currencySpotAsString() {
    // @ts-expect-error
    const spotDate = this.store.state.config[1].currencyPair['USD_ZAR'].spotDate;
    return spotDate.substring(0, 10).replaceAll('/', '-');
  }
  get valueDateAsDateString() {
    // @ts-expect-error
    if (typeof this.valueDate !== 'string') {
      // @ts-expect-error
      console.log(this.id, this.valueDate);
    }
    // @ts-expect-error
    return this.valueDate?.substring(0, 10).replaceAll('/', '-') || '';
  }
  get isValueDateToday() {
    // @ts-expect-error
    return this.store.state.systemTodayAsString === this.valueDateAsDateString ? true : false;
  }
  // get isValueDateNotOriginalValueDate(){
  //   return this.valueDateAsDateString !== this.orgininalValueDateAsDateString ? true : false
  // }
  get isValueDateInThePast() {
    // @ts-expect-error
    return new Date(this.store.state.systemTodayAsString) > new Date(this.valueDateAsDateString) ? true : false;
  }
  get isValueDateAfterSpotDate() {
    return this.valueDateAsDateString <= this.currencySpotAsString;
  }

  get valueDateTagType() {
    // @ts-expect-error
    switch (this.status) {
      case 'completed':
        return 'success';
      case 'rejected':
      case 'cancelled':
      case 'merged':
        return 'cancelled';
      case 'dealConflict':
      case 'paymentConflict':
        return 'warning';
      default:
        // // @ts-expect-error
        if (this.isValueDateInThePast) {
          return 'error';
        } else if (this.isValueDateToday) {
          return 'info';
        } else {
          return undefined;
        }
    }
  }

  get activeDealsTagType() {
    // @ts-expect-error
    switch (this.activeDeals) {
      case 0:
        return undefined;
      case 1:
        return 'success';
      default:
        return 'info';
    }
  }
  get activePaymentsTagType() {
    // @ts-expect-error
    switch (this.activePayments) {
      case 0:
        return undefined;
      case 1:
        return 'success';
      default:
        return 'info';
    }
  }
  get checkIfDealCanBeSplit () { // This is the same as checkIfManyToManyFunctionCanTakePlace but allows dealConflict state
    let check = true;
    // @ts-expect-error
    if ((this.status !== 'awaitingPayment' && this.status !== 'dealConflict') || this.transactionLocked === true || this.transactionType !== 'outwardDeal') {
      check = false;
    }
    if (this.isValueDateInThePast) {
      check = false;
    }

    // @ts-expect-error
    let balancePayment = this.payments[this.balancePaymentId];
    if (
      // @ts-expect-error
      !this.balancePaymentId ||
      !['new', 'readyForSubmission'].includes(balancePayment?.status!) ||
      balancePayment.paymentLocked! === true
    ) {
      check = false;
    }
    return check;
  }
  get checkIfManyToManyFunctionCanTakePlace() {// This is the same as checkIfDealCanBeSplit but does not allow dealConflict state
    let check = true;
    // @ts-expect-error
    if (this.status !== 'awaitingPayment' || this.transactionLocked === true || this.transactionType !== 'outwardDeal') {
      check = false;
    }
    if (this.isValueDateInThePast) {
      check = false;
    }

    // @ts-expect-error
    let balancePayment = this.payments[this.balancePaymentId];
    if (
      // @ts-expect-error
      !this.balancePaymentId ||
      !['new', 'readyForSubmission',].includes(balancePayment?.status!) ||
      balancePayment?.paymentLocked! === true
    ) {
      check = false;
    }
    return check;
  }
  get noPaymentsHaveBeenSubmitted() {
    for (const paymentId in this.payments || {}) {
      if (!['new', 'readyForSubmission','cancelled','deleted'].includes(this.payments[paymentId].status)) {
        return false
      }
    }
    return true
  }

  get consolidatedStatus() {
    //@ts-expect-error
    let consolidatedStatus = this.status;
    switch (consolidatedStatus) {
      case 'awaitingPayment':
        const paymentStatuses = [];
        if (this.payments) {
          for (const paymentId in this.payments) {
            paymentStatuses.push(this.payments[paymentId].consolidatedStatus);
          }
          //@ts-expect-error
        } else if (this.paymentSummary) {
          //@ts-expect-error
          for (const paymentItem of this.paymentSummary) {
            paymentStatuses.push(paymentItem.status);
          }
        }
        if (paymentStatuses.length !== 0) {
          const lowestPaymentStatus = getLowestPaymentStatus(paymentStatuses);
          if (lowestPaymentStatus === 'new') {
            return 'paymentNew';
          }
          return lowestPaymentStatus;
        }
    }
    return consolidatedStatus;
  }

  // ----- Deals -----
  get dealNumbers() {
    //@ts-expect-error
    if (this.dealSummary) {
      const activeDealSummaryArray = [];
      const activeDealNumbersArray = [];
      //@ts-expect-error
      for (const dealSummaryItem of this.dealSummary) {
        if (
          [
            'prepared',
            'requested',
            'processed',
            'booked',
            'released',
            'fecModificationRequested',
            'tfCompleted',
          ].includes(dealSummaryItem.status) &&
          dealSummaryItem.dealNumber
        ) {
          activeDealSummaryArray.push(dealSummaryItem);
        }
      }
      if (activeDealSummaryArray.length === 0) {
        return 'None';
      }
      for (const dealSummaryItem of activeDealSummaryArray) {
        if (dealSummaryItem.dealNumber) {
          activeDealNumbersArray.push(dealSummaryItem.dealNumber);
        }
      }
      if (activeDealNumbersArray.length !== 0) {
        let activeDealNumberCount = activeDealNumbersArray.length;
        let dealNumberReturn = '';
        dealNumberReturn = dealNumberReturn + activeDealNumbersArray.splice(0, 3).join(', ');
        if (activeDealNumberCount > 3) {
          dealNumberReturn = dealNumberReturn + '...';
        }
        return dealNumberReturn;
      }
    }
    return 'Not Available';
  }

  get deals() {
    // TODO change this to similar pattern as for bankAccounts in LfxAccountMdl
    // @ts-expect-error
    return this.store.getters.dealsByTransactionFileId(this.id);
  }

  get dealIds() {
    return Object.keys(this.deals);
  }
  get numberOfDeals() {
    return this.dealIds.length;
  }
  get hasAnyDeals() {
    return this.numberOfDeals > 0;
  }
  get hasMultipleDeals() {
    return this.numberOfDeals > 1;
  }

  get feActiveDeals() {
    let res = {};
    for (let dealId in this.deals) {
      let deal = this.deals[dealId];
      if (!['rejected', 'cancelled', 'superseded'].includes(deal.status)) {
        // @ts-expect-error
        res[dealId] = deal;
      }
    }
    return res;
  }

  get releasedDeals() {
    let res = {};
    for (let dealId in this.deals) {
      let deal = this.deals[dealId];
      if (deal.status === 'released') {
        // @ts-expect-error
        res[dealId] = deal;
      }
    }
    return res;
  }
  get numberOfReleasedDeals() {
    return Object.keys(this.releasedDeals).length;
  }
  get hasMultipleReleasedDeals() {
    return this.numberOfReleasedDeals > 1;
  }

  get firstDealId() {
    if (!this.hasAnyDeals) {
      return undefined;
    }
    var firstDealId;
    var firstNonSupersededDealId;
    var firstOpenDealId;
    for (const dId in this.deals) {
      if (firstDealId === undefined || dId < firstDealId) {
        firstDealId = dId;
      }
      if (
        this.deals[dId].status !== 'superseded' &&
        (firstNonSupersededDealId === undefined || dId < firstNonSupersededDealId)
      ) {
        firstNonSupersededDealId = dId;
      }
      if (
        this.deals[dId].status !== 'superseded' &&
        this.deals[dId].status !== 'rejected' &&
        (firstOpenDealId === undefined || dId < firstOpenDealId)
      ) {
        firstOpenDealId = dId;
      }
    }
    return firstOpenDealId ? firstOpenDealId : firstNonSupersededDealId ? firstNonSupersededDealId : firstDealId;
  }

  // ----- Payments -----

  get payments() {
    // @ts-expect-error
    return this.store.getters.paymentsByTransactionFileId(this.id);
  }

  get paymentIds() {
    return Object.keys(this.payments);
  }
  get numberOfPayments() {
    return this.paymentIds.length;
  }
  get hasAnyPayments() {
    return this.numberOfPayments > 0;
  }
  get hasMultiplePayments() {
    return this.numberOfPayments > 1;
  }

  get feActivePayments() {
    let res = {};
    for (let paymentId in this.payments) {
      let payment = this.payments[paymentId];
      if (!['cancelled', 'deleted'].includes(payment.status)) {
        // @ts-expect-error
        res[paymentId] = payment;
      }
    }
    return res;
  }
  get numberOf_activePayments() {
    return Object.keys(this.feActivePayments).length;
  }
  get hasMultiple_activePayments() {
    return this.numberOf_activePayments > 1;
  }

  get unsubmitted_activePayments() {
    let res = {};
    for (let paymentId in this.feActivePayments) {
      // @ts-expect-error
      let payment = this.feActivePayments[paymentId];
      if (['new', 'readyForSubmission'].includes(payment.status)) {
        // @ts-expect-error
        res[paymentId] = payment;
      }
    }
    return res;
  }
  get numberOf_unsubmitted_activePayments() {
    return Object.keys(this.unsubmitted_activePayments).length;
  }

  get areAllActivePaymentsUnsubmitted() {
    return this.numberOf_activePayments === this.numberOf_unsubmitted_activePayments;
  }

  get firstPaymentId() {
    return this.hasAnyPayments ? this.paymentIds[0] : undefined;
  }

  get submitted_activePayments() {
    let res = {};
    for (let paymentId in this.feActivePayments) {
      // @ts-expect-error
      let payment = this.feActivePayments[paymentId];
      if (!['new', 'readyForSubmission'].includes(payment.status)) {
        // @ts-expect-error
        res[paymentId] = payment;
      }
    }
    return res;
  }
  get numberOf_submitted_activePayments() {
    return Object.keys(this.submitted_activePayments).length;
  }
  get hasSubmitted_activePayments() {
    return this.numberOf_submitted_activePayments > 0;
  }

  // --------------------
  // ----- API calls -----
  // --------------------

  // ----- General -----

  on_after_activate() {}

  // ----- Deals -----

  async getDeals() {
    // @ts-expect-error
    let response = await this.store.dispatch('db_get_collection', {
      // @ts-expect-error
      collection_path: `/transaction/${this.id}/deal`,
      options: { return_collection_path: `/deal`, definition_name: 'deal' },
      // options: { return_collection_path: `/transaction/${this.id}/deal`, definition_name: 'deal' },
    });
    return response;
  }

  async getTransactionPayments() {
    // @ts-expect-error
    let response = await doPermissionAction(this, 'getTransactionPayments', { transactionId: this.id }, {});
    return response;
  }

  async getTransactionDealPayments() {
    // @ts-expect-error
    if (!this.dealPayment) {
      // @ts-expect-error
      let response = await doPermissionAction({}, 'getTransactionDealPayments', { transactionId: this.id }, {});
      return response;
    }
  }
}

// @ts-expect-error
export function Definition(context, name: string) {
  return new LfxTransactionFileMdl(LfxTransactionFileDef, LfxTransactionFile, context, name, {});
}
