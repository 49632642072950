//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2022 LandoByte (Pty) Ltd.
//  File        : LfxSignatoryLogDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Signatory Log record for LandoByte TS systems
//  Created     : 22 July 2022 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition } from '../definitions/LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------------------------------

export interface LfxSignatoryLogInt {
  id?: string;
  accountId?: number;
  signatoryId?: number;
  action?: string;
  actionDetails?: {};
  timestamp?: Date;
  userId?: number;
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxSignatoryLogDef: LdbDefinition = {
  title: 'Signatory Log',
  table: 'LfxSignatoryLog',
  database: 'dynamo',
  collectionPath: '/signatory/{{signatoryId}}/log',
  view: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
  indexes: ['accountId', 'signatoryId'],
  pagingType: 'backEnd',
  fields: {
    id: {
      label: 'ID',
      datatype: { id: { descriptionField: 'action', type: 'text' } },
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    accountId: {
      label: 'Account',
      datatype: {
        foreignKey: {
          collection: 'fk_accounts',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    signatoryId: {
      label: 'Signatory',
      datatype: {
        foreignKey: {
          collection: 'fk_signatories',
          linkTable: 'LfxSignatory',
          linkField: 'id',
          displayField: 'fullName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    action: {
      label: 'Action',
      datatype: 'text',
      default: '',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    actionDetails: {
      label: 'Action Details',
      datatype: { json: {} },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    timestamp: {
      label: 'Timestamp',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    userId: {
      label: 'User',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
  },
};
