//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxDealDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Deal record for LandoByte TS systems
//  Created     : 14 August 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import {
  intermediaryFieldViewGuard,
  fieldsAreNotTheSameViewGuard,
  monthToDate,
  yearToDate,
  previousMonth,
} from '../sharedUtils/LdbDefinitionUtils';
import type { LdbAuthUser, LdbDefinition } from '../definitions/LdbInterfaces';
import { standardTextWhiteList } from '../sharedUtils/LdbValidations';
import { isBankUser } from './LfxAccountDef';
import {
  transactionBranchFieldGuard,
  transactionClientFieldGuard,
  transactionIntermediaryFieldGuard,
} from './LfxTransactionFileDef';

//----------------------------------------------------------------------------------------------------------------------

export type LfxDealStatus =
  | 'prepared'
  | 'requested'
  | 'processed'
  | 'booked'
  | 'released'
  | 'rejected'
  | 'cancelled'
  | 'fecDrawnDown'
  | 'fecModificationRequested'
  | 'superseded'
  | 'tfCompleted';
export type LfxDealAction = 'Buy' | 'Sell';
export type LfxDealDeliveryType = 'Cash' | 'Tom' | 'Spot' | 'FWD';
export type LfxDealInitiatingInterface = 'client' | 'intermediary' | 'bank' | 'treasury';
export type LfxDealMarkupType = 'fixedRate' | 'fixedSpread';
export type LfxDealForwardContractType = 'fixed' | 'optional' | 'partiallyOptional';
export type LfxDealForwardModificationType = 'drawdown' | 'partialDrawdown' | 'balanceCancelation' | 'extension';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxDealInt {
  id?: number;
  uuid?: string;
  transactionFileId?: number;
  accountId?: number;
  intermediaryId?: number;
  branchId?: number;
  originaltransactionFileId?: number;
  supersedesDealId?: number;
  originalDealId?: number;

  reference?: string;
  externalReference?: string;
  dealNumber?: string;

  status?: LfxDealStatus;

  currencyPair?: string;
  action?: LfxDealAction;
  currency?: string;
  counterCurrency?: string;
  originalAmount?: number;
  originalCounterAmount?: number;
  amount?: number;
  counterAmount?: number;
  committedPaymentAmount?: number;
  deliveryType?: LfxDealDeliveryType;
  forwardContractType?: LfxDealForwardContractType;
  forwardModificationType?: LfxDealForwardModificationType;

  optionStartDate?: Date;
  originalValueDate?: Date;
  valueDate?: Date;

  markupType?: LfxDealMarkupType;
  spotRate?: number;
  bench?: number;
  bankRate?: number;
  clientRate?: number;
  forwardPoints?: number;

  fromSettlementAccountId?: number;
  toSettlementAccountId?: number;
  integrationId?: string;
  confirmationS3Key?: string;

  initiatingUserId?: number;
  initiatingInterface?: LfxDealInitiatingInterface;
  bankDealerUserId?: number | null;
  rejectionReason?: string;

  requestedTimestamp?: Date;
  releasedTimestamp?: Date;
  rejectedTimestamp?: Date;
  mostRecent?: Date;

  dealLocked?: boolean;

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

const addToStoreIfNotExist = (_dbObject: LfxDealInt, authUser: LdbAuthUser) => {
  if (authUser.accountLevel !== 'bank') {
    return true;
  }
  const requriedPermissions = ['releaseDeal'];
  var hasPermission = false;
  for (const permission of requriedPermissions) {
    if (authUser.permissions.includes(permission)) {
      hasPermission = true;
    }
  }
  return hasPermission;
};

//----------------------------------------------------------------------------------------------------------------------

export const LfxDealDef: LdbDefinition = {
  title: 'Deals',
  table: 'LfxDeal',
  collectionPath: '/deal',
  addToStoreIfNotExist: addToStoreIfNotExist,
  view: {
    create: false,
    edit: false,
    item: true,
    list: true,
    delete: false,
    csv: false,
    tiles: {
      default: {
        layout: [
          ['title', 'arrow'],
          ['subtitle', '.'],
          ['status', 'infoValue'],
          ['status', 'infoLabel'],
        ],
        color: { type: 'field', field: 'status', optionField: 'tagType', colorType: 'class' },
      },
    },
  },
  actions: {
    list: 'getDeals',
    item: 'getDeal',
  },
  indexes: ['transactionFileId', 'accountId', 'branchId'],
  pagingType: 'backEnd',
  groups: {
    system: { priority: 1, label: 'General' },
    treasury: { priority: 2, label: 'Treasury' },
    rates: { priority: 3, label: 'Rates' },
    fec: { priority: 4, label: 'FEC' },
    integration: { priority: 5, label: 'Integration' },

    changes: { priority: 7, label: 'Changes' },

    // other: { priority: 6, default: true, label: 'Notes' },
    // general: { priority: 2, label: 'General' },
  },
  fields: {
    id: {
      label: 'Reference',
      datatype: { id: { descriptionField: 'reference' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
      group: 'system',
    },
    uuid: {
      label: 'UUID',
      datatype: 'uuid',
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    transactionFileId: {
      label: 'Transaction File',
      datatype: {
        foreignKey: {
          collection: 'fk_transaction_files',
          linkTable: 'LfxTransactionFile',
          linkField: 'id',
          displayField: 'reference',
          sequelizeBelongsTo: 'TransactionFile',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'system',
    },
    accountId: {
      label: 'Client',
      datatype: {
        foreignKey: {
          collection: 'fk_clients',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
          sequelizeBelongsTo: 'Client',
        },
      },
      default: null,
      mandatory: true,
      views: { create: true, edit: false, item: true, list: transactionClientFieldGuard, delete: false, csv: true },
      group: 'system',
    },
    clientCif: {
      label: 'Client CIF',
      datatype: {
        foreignKey: {
          collection: 'fk_clients',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'cifNumber',
          localForeignKeyLinkField: 'accountId',
          sequelizeBelongsTo: 'Client',
        },
      },
      default: null,
      mandatory: false,
      property: { read: true, write: false, source: 'backend', sort: 'none' },
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: true },
      group: 'system',
    },
    clientExternalReference: {
      label: 'Client External Reference',
      datatype: {
        foreignKey: {
          collection: 'fk_clients',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'externalReference',
          localForeignKeyLinkField: 'accountId',
          sequelizeBelongsTo: 'Client',
        },
      },
      default: null,
      mandatory: false,
      property: { read: true, write: false, source: 'backend', sort: 'none' },
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: true },
      group: 'system',
    },
    intermediaryId: {
      label: 'Intermediary',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediaries',

          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
          sequelizeBelongsTo: 'Intermediary',
        },
      },
      default: null,
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: intermediaryFieldViewGuard,
        list: transactionIntermediaryFieldGuard,
        delete: false,
        csv: true,
      },
      group: 'system',
    },
    branchId: {
      label: 'Branch',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediary_branches',

          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
          sequelizeBelongsTo: 'Branch',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: transactionBranchFieldGuard, delete: false, csv: true },
      group: 'system',
    },
    originaltransactionFileId: {
      label: 'Original Transaction File',
      datatype: {
        foreignKey: {
          collection: 'fk_transaction_files',
          linkTable: 'LfxTransactionFile',
          linkField: 'id',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    supersedesDealId: {
      label: 'Supersedes Deal Id',
      datatype: {
        foreignKey: {
          linkTable: 'LfxDeal',
          linkField: 'id',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    originalDealId: {
      label: 'Original FEC Deal Id',
      datatype: {
        foreignKey: {
          linkTable: 'LfxDeal',
          linkField: 'id',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },

    reference: {
      label: 'Reference',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: true,
        list: false,
        delete: false,
        csv: true,
        search: true,
        tiles: { default: 'title' },
      },
      group: 'system',
    },
    externalReference: {
      label: 'External Reference',
      datatype: 'text',
      default: null,
      mandatory: false,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'External Reference should be between 0 and 250 characters', args: [0, 250] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: true, search: true },
      group: 'system',
    },
    dealNumber: {
      label: 'Deal Number',
      datatype: 'text',
      default: null,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Deal Number should be between 5 and 20 characters', args: [5, 20] },
      },
      views: {
        create: false,
        edit: false,
        item: true,
        list: true,
        delete: false,
        search: true,
        csv: true,
        tiles: { default: 'subtitle' },
      },
      write: true,
      group: 'treasury',
    },

    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'prepared', name: 'Prepared', tagType: 'info' },
            { id: 'requested', name: 'Requested', tagType: 'warning' },
            { id: 'processed', name: 'Processed', tagType: 'warning' },
            { id: 'booked', name: 'Booked', tagType: 'warning' },
            { id: 'released', name: 'Released', tagType: 'success' },
            { id: 'rejected', name: 'Rejected', tagType: 'error' },
            { id: 'cancelled', name: 'Cancelled', tagType: undefined },
            { id: 'fecDrawnDown', name: 'FEC Drawn Down', tagType: undefined },
            { id: 'fecModificationRequested', name: 'FEC Modification Requested', tagType: 'warning' },
            { id: 'superseded', name: 'Superseded', tagType: undefined },
            { id: 'tfCompleted', name: 'TF Completed', tagType: 'success' },
          ],
        },
      },
      default: 'prepared',
      mandatory: true,
      allowNullValues: false,
      views: {
        create: false,
        edit: false,
        item: false,
        list: true,
        delete: false,
        search: true,
        csv: true,
      },
      group: 'system',
    },

    consolidatedStatus: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'prepared', name: 'Prepared', tagType: 'info' },
            { id: 'requested', name: 'Requested', tagType: 'warning' },
            { id: 'processed', name: 'Processed', tagType: 'warning' },
            { id: 'booked', name: 'Booked', tagType: 'warning' },
            { id: 'released', name: 'Released', tagType: 'success' },
            { id: 'rejected', name: 'Rejected', tagType: 'error' },
            { id: 'cancelled', name: 'Cancelled', tagType: undefined },
            { id: 'fecDrawnDown', name: 'FEC Drawn Down', tagType: undefined },
            { id: 'fecModificationRequested', name: 'FEC Modification Requested', tagType: 'warning' },
            { id: 'superseded', name: 'Superseded', tagType: undefined },
            { id: 'tfCompleted', name: 'TF Completed', tagType: 'success' },
          ],
        },
      },
      property: { read: true, write: false, source: 'frontend', sort: 'status' },
      default: 'prepared',
      mandatory: true,
      allowNullValues: false,
      views: {
        create: false,
        edit: false,
        item: true,
        list: false,
        delete: false,
        csv: false,
        tiles: { default: 'status' },
      },
      group: 'system',
    },

    currencyPair: {
      label: 'Currency Pair',
      datatype: {
        foreignKey: {
          collection: 'fk_currency_pairs',

          linkTable: 'LfxCurrencyPair',
          linkField: 'id',
          displayField: 'name',
          type: 'text',
        },
      },
      default: null,
      mandatory: true,
      views: {
        create: true,
        edit: false,
        item: true,
        list: false,
        delete: false,
        csv: true,
        customViews: { offlineDealCapture: true },
      },
      group: 'treasury',
    },
    action: {
      label: 'Client Action',
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'Buy', name: 'Client Buys' },
            { id: 'Sell', name: 'Client Sells' },
          ],
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: false,
      views: { create: true, edit: false, item: true, list: false, delete: false, search: true, csv: true },
      group: 'treasury',
    },
    bankAction: {
      label: 'Bank Action',
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'Buy', name: 'Bank Buys' },
            { id: 'Sell', name: 'Bank Sells' },
          ],
        },
      },
      property: { read: true, write: false, source: 'frontend', sort: 'action' },
      default: null,
      mandatory: true,
      allowNullValues: false,
      views: { create: true, edit: false, item: true, list: false, delete: false, search: false, csv: false },
      group: 'treasury',
    },
    actionDisplay: {
      label: 'My Action',
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'Buy', name: 'Buy' },
            { id: 'Sell', name: 'Sell' },
          ],
        },
      },
      property: { read: true, write: false, source: 'frontend', sort: 'action' },
      default: null,
      mandatory: true,
      allowNullValues: false,
      views: { create: true, edit: false, item: false, list: true, delete: false, search: false, csv: false },
      group: 'treasury',
    },
    currency: {
      label: 'Currency',
      datatype: {
        foreignKey: {
          collection: 'fk_currencies',

          linkTable: 'LdbCurrency',
          linkField: 'id',
          displayField: 'id',
          type: 'text',
        },
      },
      default: null,
      mandatory: true,
      views: {
        create: true,
        edit: false,
        item: true,
        list: true,
        delete: false,
        csv: true,
        tiles: { default: 'infoLabel' },
      },
      group: 'treasury',
    },
    counterCurrency: {
      label: 'Counter Currency',
      datatype: {
        foreignKey: {
          collection: 'fk_currencies',

          linkTable: 'LdbCurrency',
          linkField: 'id',
          displayField: 'id',
          type: 'text',
        },
      },
      default: null,
      mandatory: true,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: true },
      group: 'treasury',
    },
    originalAmount: {
      label: 'Original Amount',
      datatype: { currency: { symbol: '' } },
      default: null,
      mandatory: true,
      allowNullValues: true,
      //@ts-ignore
      views: {
        create: false,
        edit: false,
        item: fieldsAreNotTheSameViewGuard('amount', 'originalAmount'),
        list: false,
        delete: false,
        csv: true,
      },
      group: 'treasury',
    },
    originalCounterAmount: {
      label: 'Original Counter Amount',
      datatype: { currency: { symbol: '' } },
      default: null,
      mandatory: false,
      allowNullValues: true,
      //@ts-ignore
      views: {
        create: false,
        edit: false,
        item: fieldsAreNotTheSameViewGuard('counterAmount', 'originalCounterAmount'),
        list: false,
        delete: false,
        csv: true,
      },
      group: 'treasury',
    },
    amount: {
      label: 'Amount',
      datatype: { currency: { symbol: '' } },
      default: null,
      mandatory: true,
      allowNullValues: false,

      views: {
        create: true,
        edit: false,
        item: true,
        list: true,
        delete: false,
        search: true,
        csv: true,
        tiles: { default: 'infoValue' },
      },
      group: 'treasury',
    },
    counterAmount: {
      label: 'Counter Amount',
      datatype: { currency: { symbol: '' } },
      default: null,
      mandatory: false,
      allowNullValues: false,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: true },
      group: 'treasury',
    },
    committedPaymentAmount: {
      label: 'Committed Payment Amount',
      datatype: { currency: { symbol: '' } },
      default: 0,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'treasury',
    },
    deliveryType: {
      label: 'Delivery Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'Cash', name: 'Value Today', tagType: 'info' },
            { id: 'Tom', name: 'Value Tomorrow', tagType: 'success' },
            { id: 'Spot', name: 'Spot', tagType: 'info' },
            { id: 'FWD', name: 'Forward', tagType: 'warning' },
          ],
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: false,
      views: { create: true, edit: false, item: true, list: true, delete: false, search: true, csv: true },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      group: 'treasury',
    },
    forwardContractType: {
      label: 'Forward Contract Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'fixed', name: 'Fixed' },
            { id: 'optional', name: 'Optional' },
            { id: 'partiallyOptional', name: 'Partially Optional' },
          ],
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      guards: { deliveryType: { eq: 'FWD' } },
      views: { create: true, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'fec',
    },
    forwardModificationType: {
      label: 'Forward Modification Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'drawdown', name: 'Drawdown' },
            { id: 'partialDrawdown', name: 'Partial Drawdown' },
            { id: 'balanceCancelation', name: 'Balance Cancelation' },
            { id: 'extension', name: 'Extension' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      guards: { deliveryType: { eq: 'FWD' } },
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'fec',
    },
    optionStartDate: {
      label: 'Option Start Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      guards: { deliveryType: { eq: 'FWD' }, forwardContractType: { eq: 'partiallyOptional' } },
      views: { create: true, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'fec',
    },
    originalValueDate: {
      label: 'Original Value Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: {
        create: false,
        edit: false,
        item: fieldsAreNotTheSameViewGuard('valueDate', 'originalValueDate'),
        list: false,
        delete: false,
        search: true,
        csv: true,
      },

      search: {
        presets: [
          {
            name: 'monthToDate',
            label: 'Month to Date',
            range: monthToDate,
          },
          {
            name: 'yearToDate',
            label: 'Year to Date',
            range: yearToDate,
          },
          {
            name: 'previousMonth',
            label: 'Previous Month',
            range: previousMonth,
          },
        ],
      },

      group: 'treasury',
    },
    valueDate: {
      label: 'Value Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
        },
      },
      displayType: {
        displayAsTag: true,
        tagTypeFunctionName: 'valueDateTagType',
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: true, edit: false, item: true, list: true, delete: false, search: true, csv: true },
      group: 'treasury',
      search: {
        presets: [
          {
            name: 'monthToDate',
            label: 'Month to Date',
            range: monthToDate,
          },
          {
            name: 'yearToDate',
            label: 'Year to Date',
            range: yearToDate,
          },
          {
            name: 'previousMonth',
            label: 'Previous Month',
            range: previousMonth,
          },
        ],
      },
    },
    markupType: {
      label: 'Markup Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'fixedRate', name: 'Fixed Rate' },
            { id: 'fixedSpread', name: 'Fixed Markup' },
          ],
        },
      },
      default: 'fixedSpread',
      mandatory: true,
      allowNullValues: false,
      views: { create: true, edit: false, item: true, list: false, delete: false, csv: true },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      group: 'rates',
    },
    spotRate: {
      label: 'Spot Rate',
      datatype: 'float',
      displayType: { float: { decimalPlaces: 7 } },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'rates',
    },
    bench: {
      label: 'Bench Rate',
      datatype: 'float',
      displayType: { float: { decimalPlaces: 7 } },
      default: null,
      mandatory: true,
      allowNullValues: false,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: true },
      group: 'rates',
    },
    bankRate: {
      label: 'Bank Rate',
      datatype: 'float',
      displayType: { float: { decimalPlaces: 7 } },
      default: null,
      mandatory: true,
      allowNullValues: false,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: true },
      group: 'rates',
    },
    clientRate: {
      label: 'Deal Rate',
      datatype: 'float',
      displayType: { float: { decimalPlaces: 7 } },
      default: null,
      mandatory: true,
      allowNullValues: false,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: true },
      group: 'rates',
    },
    forwardPoints: {
      label: 'Forward Points',
      datatype: 'float',
      default: null,
      mandatory: false,
      allowNullValues: true,
      guards: { deliveryType: { eq: 'FWD' } },
      views: { create: true, edit: false, item: isBankUser, list: false, delete: false, csv: true },
      group: 'fec',
    },
    bankMarkup: {
      label: 'Bank Markup',
      datatype: 'float',
      default: null,
      mandatory: false,
      allowNullValues: true,
      property: { write: false, read: true, source: 'frontend', sort: 'none' },
      views: { create: true, edit: false, item: isBankUser, list: false, delete: false, csv: true },
      group: 'rates',
    },

    intermediaryMarkup: {
      label: 'Intermediary Markup',
      datatype: 'float',
      default: null,
      mandatory: false,
      allowNullValues: true,
      property: { write: false, read: true, source: 'frontend', sort: 'none' },
      views: { create: true, edit: false, item: isBankUser, list: false, delete: false, csv: true },
      group: 'rates',
    },
    fromSettlementAccountId: {
      label: 'From Settlement Account',
      datatype: {
        foreignKey: {
          collection: 'fk_bank_accounts',

          linkTable: 'LfxBankAccount',
          linkField: 'id',
          displayField: 'description',
          sequelizeBelongsTo: 'FromSettlementAccount',
        },
      },
      default: null,
      mandatory: true,
      views: { create: true, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'treasury',
    },
    toSettlementAccountId: {
      label: 'To Settlement Account',
      datatype: {
        foreignKey: {
          collection: 'fk_bank_accounts',
          linkTable: 'LfxBankAccount',
          linkField: 'id',
          displayField: 'description',
          sequelizeBelongsTo: 'ToSettlementAccount',
        },
      },
      default: null,
      mandatory: true,
      views: { create: true, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'treasury',
    },
    integrationId: {
      label: 'Integration ID',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, search: true, csv: true },
      group: 'integration',
    },
    confirmationS3Key: {
      label: 'Deal Confirmation',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },

    initiatingUserId: {
      label: 'Initiating User ID',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
          sequelizeBelongsTo: 'InitiatingUser',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    initiatingUserEmail: {
      label: 'Initiating User',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'emailAddress',
          localForeignKeyLinkField: 'initiatingUserId',
          sequelizeBelongsTo: 'InitiatingUser',
        },
      },
      default: null,
      mandatory: true,
      property: { read: true, write: false, source: 'backend', sort: 'none' },
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: true },
      group: 'integration',
    },
    initiatingInterface: {
      label: 'Initiating Interface',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'client', name: 'Client' },
            { id: 'intermediary', name: 'Intermediary' },
            { id: 'bank', name: 'Bank' },
            { id: 'treasury', name: 'Treasury' },
          ],
        },
      },
      default: 'requested',
      mandatory: true,
      allowNullValues: false,
      views: { create: false, edit: false, item: true, list: false, delete: false, search: true, csv: true },
      group: 'integration',
    },
    bankDealerUserId: {
      label: 'Dealer ID',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },

    rejectionReason: {
      label: 'Rejection Reason',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'integration',
    },
    requestedTimestamp: {
      label: 'Requested Timestamp',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: true, search: true },
      search: {
        presets: [
          {
            name: 'monthToDate',
            label: 'Month to Date',
            range: monthToDate,
          },
          {
            name: 'yearToDate',
            label: 'Year to Date',
            range: yearToDate,
          },
          {
            name: 'previousMonth',
            label: 'Previous Month',
            range: previousMonth,
          },
        ],
      },
      group: 'integration',
    },
    releasedTimestamp: {
      label: 'Released Timestamp',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'integration',
    },
    rejectedTimestamp: {
      label: 'Rejected Timestamp',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'integration',
    },
    mostRecent: {
      label: 'Most Recent',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: true },
      group: 'changes',
    },
    dealLocked: {
      label: 'Deal Locked',
      datatype: 'boolean',
      default: false,
      mandatory: true,
      allowNullValues: false,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },
    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },

    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },

    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
  },
};

//----------------------------------------------------------------------------------------------------------------------
