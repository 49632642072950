<template>
  <LFXContent
    :titleBar="titleBar"
    :show_tabs="showTabs"
    :show_actions="showActions"
    :form_layout="formLayout"
    :show_stepper="showStepper"
    :show_label="showLabel"
    :label="contentLabel"
    :show_sidebar="false"
  >
    <template #actions>
      <LDBButton
        v-for="button in actionBarButtons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        @click="button.clickEvent"
      ></LDBButton
    ></template>

    <!-- <template #stepper
      ><capitec-stepper
        layout="horizontal"
        :steps="steps"
        :active-step-number="activeStepNumber"
        @step-click="onStepClick"
      >
      </capitec-stepper
    ></template> -->

    <template #content>
      <router-view></router-view>
    </template>
  </LFXContent>

  <router-view name="modal"></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXContent from '@/components/layout/LfxContent.vue';

export default {
  name: 'LfxAccountMarkup',
  components: { LFXContent },
  props: {},
  data() {
    return {
      // activeStepNumber: 1,
    };
  },
  async created() {
    // await this.getApplication();
    // if (this.$route.name === 'clientApplication') {
    //   this.$router.replace({ name: 'clientApplicationCifEdit' });
    // }
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account','authUser']),

    // ----- Title Bar -----

    titleBar() {
      const titleBar = {
        icon: this.titleBarIcon,
        label: `${this.accountLabel} Markup: ${this.accountRecord.name}`,
        buttons: [
          {
            name: 'backToAccount',
            label: `Back to ${this.accountLabel}`,
            type: 'white',
            routeName: 'accountMarkupsList',
            // clickEvent: null, //TODO
            // permission: //TODO
          },
        ],
      };
      return titleBar;
    },
    // titleBarLabel() {
    //   return `${this.accountLabel} Markup: ${this.accountRecord.name}`;
    // },
    titleBarIcon() {
      return this.$route.meta?.icon?.[this.accountLevel];
    },

    // ----- Tabs -----

    showTabs() {
      return false;
    },

    // ----- Action Bar -----

    showActions() {
      return this.actionBarButtons.length !== 0 ? true : false;
    },
    actionBarButtons() {
      let self = this;
      let allButtons = {
        accountMarkupView: [
          {
            name: 'accountMarkupUnhide',
            label: 'Unhide Markup',
            type: 'primary',
            guards: this.markupRecord.accountMarkupUnhide_buttonGuard, // TODO
            clickEvent() {
              self.$router.push({ name: 'accountMarkupViewUnhide' });
            },
          },
          {
            name: 'accountMarkupEdit',
            label: 'Edit Markup',
            type: '',
            guards: this.markupRecord.accountMarkupEdit_buttonGuard && (this.clientIsNotIntermediary || this.authUser.accountLevel === 'bank'),
            clickEvent() {
              self.$router.push({ name: 'accountMarkupEdit' });
            },
          },
          {
            name: 'accountMarkupHide',
            label: 'Hide Markup',
            type: '',
            guards: this.markupRecord.accountMarkupHide_buttonGuard, // TODO
            clickEvent() {
              self.$router.push({ name: 'accountMarkupViewHide' });
            },
          },
        ],
        accountMarkupEdit: [
        ]
      };
      return allButtons[this.buttonGroup] !== undefined
        ? allButtons[this.buttonGroup].filter((button) => button.guards)
        : [];
    },

    buttonGroup() {
      return this.$route.meta?.content?.buttonGroup;
    },

    // ----- Layout -----

    formLayout() {
      return this.$route.meta?.layout === 'list' ? false : true;
    },

    // ----- Stepper -----

    showStepper() {
      return false;
    },
    // steps() {
    //   let steps = [
    //     { index: 1, title: 'Client Info', isError: false, routeName: 'clientApplicationCifEdit' }, //TODO
    //     { index: 2, title: 'Associated CIFs', isError: false, routeName: 'clientApplicationAssociatedCifsEdit' }, //TODO
    //     { index: 3, title: 'Bank Account', isError: false, routeName: 'clientApplicationBankAccountEdit' }, //TODO
    //     { index: 4, title: 'Review Application', routeName: 'clientApplicationReview' }, //TODO
    //   ];
    //   return steps; // TODO
    // },

    // ----- Content Label -----

    showLabel() {
      return this.contentLabel ? true : false;
    },
    contentLabel() {
      return `Markup for ${this.markupRecord.currencyPair}`;
    },

    // ----- Account Record -----

    accountId() {
      return +this.$route.params.accountId;
    },
    accountRecord() {
      return this.account[this.accountId];
    },
    accountLevel() {
      return this.accountRecord.accountLevel;
    },
    accountLabel() {
      if (this.accountLevel === 'bank') {
        return 'Bank';
      } else if (this.accountLevel === 'intermediary') {
        return 'Intermediary';
      } else if (this.accountLevel === 'intermediaryBranch') {
        return 'Branch';
      } else if (this.accountLevel === 'client') {
        return 'Client';
      } else {
        return 'Account';
      }
    },

    // ----- Markup Record -----

    markupId() {
      return +this.$route.params.markupId;
    },
    markupRecord() {
      return this.accountRecord.markup[this.markupId];
    },

    clientId() {
      return this.$route.params.accountId;
    },
    clientRecord() {
      return this.account[this.clientId];
    },
    // clientDefinition() {
    //   return getDefinition('client');
    // },

    clientIsNotIntermediary() {
      if (this.clientRecord?.accountLevel !== 'client') {
        return true
      }
      const clientCif = this.clientRecord.cifNumber
      const intermediaryCifNumber =  this.account?.[this.clientRecord.intermediaryId]?.cifNumber;
      return !clientCif || !intermediaryCifNumber || clientCif!== intermediaryCifNumber
    },
    // ----- Application Record -----

    // applicationId() {
    //   return this.$route.params.applicationId;
    // },
    // applicationRecord() {
    //   return this.clientRecord?.application?.applicationId;
    // },
    // applicationDefinition() {
    //   return getDefinition('application');
    // },

    // ----- CIF Record -----
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // async getApplication() {
    //   let collection_path = `/client/${this.clientId}/application`;
    //   let options = { return_collection_path: `/account/${this.clientId}/application` };
    //   let id = this.applicationId;
    //   this.$store.dispatch('showLoader', true);
    //   let response = await this.$store.dispatch('db_get_document', { collection_path, id, options });
    //   this.$store.dispatch('showLoader', false);
    // },

    // ----- Stepper -----

    // onStepClick(evt) {
    //   this.activeStepNumber = evt.detail.stepNumber;
    //   const activeStep = this.steps.find((step) => step.index === this.activeStepNumber);
    //   this.$router.push({ name: activeStep.routeName });
    // },
  },
};
</script>
