<template>
  <LDBModal result="info" :header="headerText" size="xx-large">
    <template #body>
    <div>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          :view="field.view"
          :fieldname="field.name"
          :definition="undefined"
          :record="summaryRecord"
        />
      </div>
    <capitec-file-upload v-if="makerCheckerMode === 'maker'" label="Select CSV File" @selection-change="onSelectionChange" :disabled="false" type="" fileTypes="text/csv" hint="Jannie">
    
    </capitec-file-upload>

    <template v-if="fileStatus === 'processing'">
        <LDBLoader />
      </template>
    <template v-else-if="fileStatus === 'missingHeaders'">
      <h1>Some headers are missing:</h1>
      <ul>
        <li v-for="item in missingHeaders" :key="item">{{ item }}</li>
      </ul>
    </template>
    <template v-else-if="fileStatus === 'tooManyLines'">
      <h1>File has more than 100 lines</h1>
    </template>
    <template v-else-if="fileStatus === 'processed' || fileStatus === 'makerCheckerLoaded' || fileStatus === 'batchStarted' || fileStatus === 'batchCompleted'">
        <div class="lfx-change-intermediary-import-view-table-container">
          <table-lite
            id="lfx-change-intermediary-import-view-table"
            :is-static-mode="true"
            :is-slot-mode="true"
            :has-checkbox="false"
            :is-loading="false"
            :is-re-search="false"
            :columns="tableColumns"
            :rows="sortedRecords"
            :pageSize="sortedRecords.length"
            :total="sortedRecords.length"
          >
            <template v-for="(col, i) of tableColumns" v-slot:[col.field]="data" :key="i">
              <template v-if="col.showAsCurrency">{{ formatCurrency(data.value[col.field]) }}</template>
              <template v-else-if="col.col === 'status'">{{ statusLabel(data.value[col.field]) }}</template>
              <!-- <template v-if="col.field?.amount">ZAR {{data.value[col.field].amount}}</template> -->
              <template v-else>{{ data.value[col.field] }}</template>
            </template>
          </table-lite>
        </div>
      </template>

    </template>
    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @on-api-success="button.onApiSuccess"
        @click="button.clickEvent"
      ></LDBButton>
    </template>    
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import TableLite from '@/views/general/TableLite.vue';
import LDBField from '@/landobyte_vue/LDBField.vue';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxBulkChangeClientIntermediary',
  components: { LDBLoader, TableLite, LDBField },
  data() {
    return {
      makerCheckerMode: 'maker', // 'maker' | 'checker'
      makingUserId: undefined,
      makingTimestamp: undefined,
      cacheKey: 'saveBulkChangeClientIntermediaryStructure',
      fileStatus:'none',
      missingHeaders: [],
      dataLines: [],
      file:null,
      missingCifs: [],
    };
  },
  created() {},
  mounted() {
    this.getPreviouslyCachedObject()
  },
  computed: {
    ...mapGetters(['permissions']),
    ...mapState(['account','authUser','user']),
    headerText() {
      return "Bulk Change Client Intermediary - " + this.makerCheckerMode.toUpperCase() + " Mode"
    },
    intermediaries() {
      const intermediaries = [];
      for (const accountId in this.account) {
        const account = this.account[accountId];
        if (account.accountLevel === 'intermediary') {
          intermediaries.push(account)
        }
      }
      return intermediaries
    },
        // ----- Fields -----

    viewFields() {
      const showPreProcessLines = this.fileStatus === "processed" || this.fileStatus === 'makerCheckerLoaded' || this.fileStatus === 'batchStarted' || this.fileStatus === 'batchCompleted';
      const showProcessingLines = showPreProcessLines && (this.fileStatus === "batchStarted" || this.fileStatus === 'batchCompleted');
      const allFields = {
        status: {
          name: 'status',
          label: 'Status',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'none', name: 'No File Uploaded' },
                { id: 'processing', name: 'Processing File' },
                { id: 'missingHeaders', name: 'Some Headers Missing' },
                { id: 'tooManyLines', name: 'Too Many Lines'},
                { id: 'processed', name: 'File Processed' },
                { id: 'makerCheckerLoaded', name: 'Maker Checker Request Loaded'},
                { id: 'batchStarted', name: 'Batch Started' },
                { id: 'batchCompleted', name: 'Batch Completed' },
              ],
            },
          },
          view: 'item',
          guards: true,
        },
        requestingUser: {
          name: 'requestingUser',
          label: 'Requesting User',
          datatype: 'text',
          view: 'item',
          guards: this.makerCheckerMode === 'checker',
        },
        totalLines: {
          name: 'totalLines',
          label: 'Total Lines',
          datatype: 'integer',
          view: 'item',
          guards: showPreProcessLines,
        },
        okayLines: {
          name: 'okayLines',
          label: 'Ready to Move Lines',
          datatype: 'integer',
          view: 'item',
          guards: showPreProcessLines,
        },
        errorLines: {
          name: 'errorLines',
          label: 'Error Lines',
          datatype: 'integer',
          view: 'item',
          guards: showPreProcessLines,
        },
        existingLines: {
          name: 'existingLines',
          label: 'Existing Lines',
          datatype: 'integer',
          view: 'item',
          guards: showPreProcessLines,
        },
        submittedLines: {
          name: 'submittedLines',
          label: 'Sumitted Lines',
          datatype: 'integer',
          view: 'item',
          guards: showProcessingLines,
        },
        movedLines: {
          name: 'movedLines',
          label: 'Moved Lines',
          datatype: 'integer',
          view: 'item',
          guards: showProcessingLines,
        },
        movedErrorLines: {
          name: 'movedErrorLines',
          label: 'Move Error Lines',
          datatype: 'integer',
          view: 'item',
          guards: showProcessingLines,
        },
      };
      const returnFields = {};
      for (const f in allFields) {
        if (allFields[f].guards !== false) {
          returnFields[f] = allFields[f];
        }
      }
      return returnFields;
    },
    makingUserDetails() {
      return this.user?.[this.makingUserId]?.displayName || 'Not Available'
    },
    summaryRecord() {
      const totalLines = this.dataLines.length;
      let okayLines = 0;
      let errorLines = 0;
      let existingLines = 0;
      let submittedLines = 0;
      let movedLines = 0;
      let movedErrorLines = 0;
      for (const line of this.dataLines) { 
        switch (line.status) {
          case "okay": okayLines++; break;
          case "dataError": 
          case "cantFindClient":
          case "clientNotActive":
          case "clientNoId":
          case "multipleActiveClients":
          case "cantFindNewIntermediary":
          case "multipleIntermediaries":
            errorLines++; break;
          case "clientAlreadyWithIntermediary": existingLines++; break;
          case "submitted": submittedLines++; break;
          case "moved": movedLines++; break;
          case "error": movedErrorLines++; break;
        }
      }
      return {
        status: this.fileStatus,
        requestingUser: this.makingUserDetails,
        totalLines: totalLines,
        okayLines: okayLines,
        errorLines: errorLines,
        existingLines: existingLines,
        submittedLines: submittedLines,
        movedLines: movedLines,
        movedErrorLines: movedErrorLines,
      }
    },
    formButtons() {
      let self = this;
      let allButtons = [
        {
            name: 'cancelMakerCheckerEdit',
            label: 'Cancel Requested Change',
            type: '',
            action: 'clearMakerCheckerToken',
            params: {configId:1},
            actionParams: {cacheKey:this.cacheKey},
            guards: this.makerCheckerMode === 'checker' && this.fileStatus !== 'batchStarted' && this.fileStatus !== 'batchCompleted',
            onApiSuccess: () => {
              void this.getPreviouslyCachedObject();
              this.fileStatus = 'none';
              this.missingHeaders = [];
              this.dataLines = [];
              this.file = null;
            }
        },
        {
            name: 'saveBulkChangeClientIntermediaryStructure',
            label: 'Request Batch Move',
            type: '',
            action: 'saveBulkChangeClientIntermediaryStructure',
            params: {configId:1},
            actionParams: {bulkChangeInterStructure:this.submitableLines},
            guards: this.makerCheckerMode === 'maker',
            enabledGuards:this.submitableLines?.length !== 0,
            onApiSuccess: () => {
              void this.getPreviouslyCachedObject()
            }
        },
        {
          name: 'processBatch',
          label: 'Approve Batch Move',
          type: '',
          action: 'click',
          params: {},
          guards: this.makerCheckerMode === 'checker' && this.iAmtryingToCheckAnotherUsersChange && this.permissions?.changeClientIntermediary && this.fileStatus !== 'batchStarted' && this.fileStatus !== 'batchCompleted',
          enabledGuards: this.fileStatus === "makerCheckerLoaded" && this.summaryRecord.okayLines > 0,
          clickEvent() {
            self.triggerBatchCreate()
          },
        },
        {
          name: 'cancel',
          label: 'Back',
          type: '',
          action: 'click',
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

      ];
      return allButtons;      
    },
    tableColumns() {
      let self = this;
      let res = [
        {
          col: 'index',
          field: 'index',
          label: '#',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'clientCif',
          field: 'clientCif',
          label: 'Client CIF',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'clientReference',
          field: 'clientReference',
          label: 'Client Reference',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'clientName',
          field: 'clientName',
          label: 'Client Name',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'clientStatus',
          field: 'clientStatus',
          label: 'Client Status',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'currentIntermediaryName',
          field: 'currentIntermediaryName',
          label: 'Current Intermediary',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'newIntermediaryCif',
          field: 'newIntermediaryCif',
          label: 'New Intermediary',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'newIntermediaryName',
          field: 'newIntermediaryName',
          label: 'New Intermediary',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'status',
          field: 'status',
          label: 'Status',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'error',
          field: 'error',
          label: 'Error',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
      ];
      return res.filter((f) => f.guards === true);
    },
    sortedRecords() {
      return this.dataLines
    },
    submitableLines() {
      const res = [];
      for (const line of this.dataLines) {
        if (line.status === 'okay') {
          res.push(line)
        }
      }
      return res
    },

    iAmtryingToCheckMyOwnChange() {
      return this.makerCheckerMode === 'checker' && this.makingUserId === this.authUser.id
    },
    iAmtryingToCheckAnotherUsersChange() {
      return this.makerCheckerMode === 'checker' && this.makingUserId !== this.authUser.id
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    onSelectionChange(evt) {
      self = this;
      this.fileStatus = "processing";
      this.missingHeaders = [];
      this.dataLines = [];

      this.file = evt.detail.files[0];
      let reader = new FileReader();
      reader.readAsText(this.file);
      reader.onload = function() {
        self.processUploadedFile(reader.result);
      };

    },
    processUploadedFile(fileContents) {
      this.missingCifs = [];
      fileContents = fileContents.replaceAll("\r","\n");
      fileContents = fileContents.replaceAll("\n\n","\n");
      fileContents = fileContents.replaceAll(";",",");
      const lines = fileContents.split("\n");
      if (lines.length > 101) {
        this.fileStatus = "tooManyLines"
      } else {
        const headers = lines[0].split(",");
        this.checkHeaders(headers);
        if (this.fileStatus === "processing") {
          for (const lineId in lines) {
            if (lineId > 0) {
              this.processLine(lines[lineId],headers,lineId)
            }
          }
          this.fileStatus = "processed"
        }
        if (this.missingCifs.length !== 0) {
          this.retrieveMissingCifs()
        }
      }
    },
    checkHeaders(headers) {
      const requiredHeaders = ['clientCif', 'newIntermediaryCif'];
      for (const requiredHeader of requiredHeaders) {
        if (!headers.includes(requiredHeader)) {
          this.fileStatus = 'missingHeaders';
          this.missingHeaders.push(requiredHeader);
        }
      }
    },
    processLine(line,headers,index) {
      const lineArray = line.split(",");
      const object = {
        index: index,
        clientAccountId: undefined,
        clientCif: lineArray[headers.indexOf('clientCif')],
        clientReference: undefined,
        clientName: undefined,
        clientStatus: undefined,
        currentIntermediaryId: undefined,
        currentIntermediaryName: undefined,
        newIntermediaryId: undefined,
        newIntermediaryCif: lineArray[headers.indexOf('newIntermediaryCif')],
        newIntermediaryName: undefined,
        status: undefined,
        error: undefined,
      };
      this.populateFullLineObject(object)
      this.dataLines.push(object)
    },
    reprocessLine(savedLine) {
      this.populateFullLineObject(savedLine)
    },
    populateFullLineObject(object) {
      if(!object.clientCif || !object.newIntermediaryCif) {
        object.status = "dataError"
      } else {
        const clients = [];
        for (const accountId in this.account) {
          const account = this.account[accountId];
          if (account.cifNumber === object.clientCif && account.accountLevel === 'client') {
            clients.push(account)
          }
        }
        if (clients.length === 0) {
          this.missingCifs.push(object.clientCif)
          object.status = 'cantFindClient'
        } else {
          const activeClients = []
          for (const client of clients) {
            if (client.visibility === 'visible' && client.status === 'active') {
              activeClients.push(client)
            }
          }
          let client;
          if (activeClients.length === 0) {
            object.status = 'clientNotActive',
            client = clients[0]
          } else {
            object.status = "okay"
            client = activeClients[0]
          }
          if (!client.identification) {
            object.status = 'clientNoId'
          }
          if (activeClients.length > 1) {
            object.status = 'multipleActiveClients'
          }
          let oldIntermediary;
          let newIntermediary
          const newIntermediaries =[];
          for (const intermediary of this.intermediaries) {
            if (intermediary.id === client?.intermediaryId) {
              oldIntermediary = intermediary
            }
            if (intermediary.cifNumber === object.newIntermediaryCif) {
              newIntermediaries.push(intermediary)
            }
          }
          if (newIntermediaries.length === 0) {
            object.status = "cantFindNewIntermediary"
          } else {
            newIntermediary = newIntermediaries[0]
            if (newIntermediaries.length > 1) {
              object.status = "multipleIntermediaries"
            }
          }
          if (newIntermediary?.id === client?.intermediaryId) {
            object.status = "clientAlreadyWithIntermediary"
          }
          if (client) {
            object.clientAccountId = client.id;
            object.clientReference = client.reference,
            object.clientName = client.name,
            object.clientStatus = client.status
          }
          if (oldIntermediary) {
            object.currentIntermediaryId = oldIntermediary.id,
            object.currentIntermediaryName = oldIntermediary.name
          }
          if (newIntermediary) {
            object.newIntermediaryId = newIntermediary.id;
            object.newIntermediaryName = newIntermediary.name;
          }
        }
      }
    },

    async triggerBatchCreate() {
      this.fileStatus = 'batchStarted';
      for (const line of this.dataLines) {
        if (line.status === 'okay') {
          await this.submitLine(line)
        }
      }
      this.fileStatus = 'batchCompleted',
      await doPermissionAction(this,'clearMakerCheckerToken', { configId: 1 }, {cacheKey:this.cacheKey});
    },
    async submitLine(line) {
      line.status = 'submitted';
      await new Promise((r) => setTimeout(r,100))
      try {
        const result = await doPermissionAction(this,'changeClientIntermediary',{accountId:line.clientAccountId},{accountId:line.newIntermediaryId})
        if (result.status === 'success') {
          line.status = 'moved'
        } else {
          line.status = 'error';
          line.error = result.message || "Unknown Error"
        }
      } catch {
        line.status = 'error';
        line.error = result.message || "Unknown Error"
      }
    },
    statusLabel(status) {
      switch (status) {
        case "okay": return "Ready to Move";
        case "dataError": return "Data Error";
        case "cantFindClient": return "Can't Find Client";
        case "clientNotActive": return "Client not active";
        case "clientNoId": return "Client does not have an ID Captured";
        case "multipleActiveClients": return "Multiple Active Clients Found"
        case "cantFindNewIntermediary": return "Can't Find New Intermediary";
        case "multipleIntermediaries": return "Multiple Intermediaries Found";
        case "clientAlreadyWithIntermediary": return "Client Already With Intermediary";
        case "moved": return "Moved";
        case "error": return "Move Error"
        default: return status
      }
    },
    async retrieveMissingCifs() {
      const clientResult = await doPermissionAction(this, 'getAccounts',{}, { 
        additionalSelector:{where:{
          cifNumber:this.missingCifs
        }}
      });
      if(clientResult.status === 'success') {
        this.missingCifs = [];
        for (const savedLine of this.dataLines) {
          if (savedLine.status === 'cantFindClient') {
            this.reprocessLine(savedLine)
          }
        }
      }
    },
    async getPreviouslyCachedObject() {
      const response = await doPermissionAction(this, 'getMakerCheckerToken', { configId: 1 }, {cacheKey:this.cacheKey});
      if (+response.code === 204) {
        this.makerCheckerMode = 'maker'
      } else if (+response.code === 200) {
        this.makerCheckerMode = 'checker'
        this.makingUserId = response.response.makingUserId
        this.makingTimestamp = new Date(response.response.makingTimestamp)
        this.dataLines = response.response.cachedObject
        this.fileStatus = 'makerCheckerLoaded'
      }
    },
  },
};
</script>
<style>
.lfx-change-intermediary-import-view-table-container {
  position: relative;
  min-height: 320px;
}

#lfx-change-intermediary-import-view-table {
  min-width: 100%;
}

div.lfx-deal-summary-report {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
</style>

