//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxBopCatDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a BOP Category record for LandoByte TS systems
//  Created     : 11 November 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition } from './LdbInterfaces';
import type { LfxPaymentDirection } from './LfxPaymentDef';
import type { LfxBopCatClientType } from './LfxBopCatGroupDef';
import { standardTextWhiteList } from '../sharedUtils/LdbValidations';

//----------------------------------------------------------------------------------------------------------------------

export type LfxBopCatStatus = 'active' | 'disabled';
export type LfxBopCatAllowanceIndicator = 'none' | 'sdaOnly' | 'either' | 'sdaOnlyRequired' | 'eitherRequired';
export type LfxBopCatAllowanceType = 'none' | 'SDA' | 'FIA'

export type LfxBopCatDocument = {
  documentName:string,
  guardDescription:string
}
//----------------------------------------------------------------------------------------------------------------------

export interface LfxBopCatInt {
  id?: string;
  groupId?: number;
  name?: string;
  help?: string;

  category?: string;
  subCategory?: string;

  requiredInfo?: string[];
  optionalInfo?: string[];
  requiredDocuments?: (string|LfxBopCatDocument)[];
  optionalDocuments?: (string|LfxBopCatDocument)[];

  direction?: LfxPaymentDirection;
  clientType?: LfxBopCatClientType;
  status?: LfxBopCatStatus;

  hotList?: boolean;

  allowanceIndicators?: LfxBopCatAllowanceIndicator;
  allowCryptoTrades?: boolean;
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxBopCatDef: LdbDefinition = {
  title: 'BOP Category',
  table: 'LfxBopCat',
  collectionPath: '/config/1/bopCat',
  view: { create: false, edit: true, item: true, list: true, delete: false, csv: false },
  indexes: [],
  pagingType: 'frontEnd',
  groups: {
    bopCat: { priority: 1, label: 'BOP Category' },
    info: { priority: 2, label: 'BOP Information' },
    documents: { priority: 3, label: 'Documents' },
    configuration: { priority: 4, label: 'Configuration' },
    system: { priority: 5, label: 'System' },
    changes: { priority: 6, label: 'Changes' },
  },
  fields: {
    id: {
      label: 'Name',
      datatype: { id: { descriptionField: 'name', type: 'text' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
      group: 'system',
    },
    groupId: {
      label: 'Group',
      datatype: {
        foreignKey: {
          collection: 'fk_bop_cat_groups',
          linkTable: 'LfxBopCatGroup',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },
    name: {
      label: 'Name',
      datatype: 'text',
      default: null,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alpha charaters', args: [standardTextWhiteList] },
        len: { msg: 'Name should be between 5 and 250 characters', args: [5, 250] },
      },
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'bopCat',
    },
    help: {
      label: 'Help',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Help should be between 0 and 250 characters', args: [0, 250] },
      },
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'bopCat',
    },
    category: {
      label: 'Category',
      datatype: 'text',
      default: null,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Category should be between 2 and 20 characters', args: [2, 20] },
      },
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'bopCat',
    },
    subCategory: {
      label: 'Subcategory',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Subcategory should be between 0 and 20 characters', args: [0, 20] },
      },
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'bopCat',
    },
    requiredInfo: {
      label: 'Required Information',
      datatype: {
        json: {},
      },
      default: null,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Required Information should be between 2 and 250 characters', args: [2, 250] },
      },
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'info',
    },
    optionalInfo: {
      label: 'Optional Information',
      datatype: {
        json: {},
      },
      default: null,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Optional Information should be between 2 and 250 characters', args: [2, 250] },
      },
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'info',
    },
    requiredDocuments: {
      label: 'Required Documents',
      datatype: {
        json: {},
      },
      default: null,
      mandatory: true,
      // validators: {
      //   is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
      //   len: { msg: 'Required Documents should be between 2 and 250 characters', args: [2, 250] },
      // },
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'documents',
    },
    optionalDocuments: {
      label: 'Optional Documents',
      datatype: {
        json: {},
      },
      default: null,
      mandatory: true,
      // validators: {
      //   is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
      //   len: { msg: 'Optional Documents should be between 2 and 250 characters', args: [2, 250] },
      // },
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'documents',
    },
    direction: {
      label: 'Direction',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'inward', name: 'inward' },
            { id: 'outward', name: 'outward' },
            { id: 'transfer', name: 'transfer' },
          ],
        },
      },
      default: 'inward',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'configuration',
    },
    clientType: {
      label: 'Client Types',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'individual', name: 'Individual' },
            { id: 'company', name: 'Company' },
            { id: 'either', name: 'Either' },
          ],
        },
      },
      default: 'either',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'configuration',
    },
    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'active', name: 'Active', tagType: 'success' },
            { id: 'disabled', name: 'Disabled', tagType: 'cancelled' },
          ],
        },
      },
      default: 'new',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },
    hotList: {
      label: 'Hot List',
      datatype: 'boolean',
      default: false,
      mandatory: true,
      allowNullValues: false,
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'configuration',
    },

    allowanceIndicators: {
      label: 'Allowance Indicators',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'none', name: 'None' },
            { id: 'sdaOnly', name: 'SDA Only' },
            { id: 'either', name: 'SDA or FIA' },
            { id: 'sdaOnlyRequired', name: 'SDA Only - Required'},
            { id: 'eitherRequired', name: 'SDA or FIA - Required'}
          ],
        },
      },
      default: 'inward',
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'configuration',
    },

    allowCryptoTrades: {
      label: 'Allow Crypto Trades',
      datatype: 'boolean',
      default: false,
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'configuration',
    },
  },
};
