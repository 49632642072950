import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
// import Home from '@/views/home/Home.vue'; // This is the old temp homescreen

import transactionRoutes from './transactionRoutes';
import accountRoutes from './accountRoutes';
import clientApplicationRoutes from './clientApplicationRoutes';
import userRoutes from './userRoutes';
import reportRoutes from './reportRoutes';
import searchRoutes from './searchRoutes';
import uploadAndDownloadRoutes from './uploadAndDownloadRoutes';
import adminRoutes from './adminRoutes';
import configRoutes from './configRoutes';
import helpRoutes from './helpRoutes';

import Auth from '@/views/auth/Auth.vue';
import Login from '@/views/auth/Login.vue';
import Signup from '@/views/auth/Signup.vue';
import Confirm from '@/views/auth/Confirm.vue';
import Success from '@/views/auth/Success.vue';

// import LDBView from '@/landobyte_vue/LDBView.vue';
import LFXItemView from '@/views/general/LFXItemView.vue';
import LfxTodo from '@/components/todo/LfxTodo.vue';
import NotFound from '@/views/notFound/NotFound.vue';

import LDBTest from '@/views/test/LDBTestForm.vue';

import LfxLogout from '@/components/auth/LfxAuthLogout.vue';

import LfxEventNotifications from '@/components/events/LfxEventNotifications.vue';

import Home from '@/components/home/Home.vue';

const baseRoutes: RouteRecordRaw[] = [
  { path: '/test', name: 'test', component: LDBTest },
  { path: '/', name: 'home', component: Home },

  // ----- Dashboards -----

  { path: '/dashboards', name: 'dashboards', component: LfxTodo, meta: { label: 'Dashboards' } },
  { path: '/notifications', name: 'notifications', component: LfxEventNotifications, meta: { label: 'Notifications' } },
  // ----- Auth -----

  // is this currently used? - avr 2021-09-30

  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
    children: [
      { path: 'login', name: 'Login', component: Login },
      { path: 'switch_account', name: 'authSwitchAccount', component: LfxTodo, meta: { label: 'Switch Account' } },
      { path: 'logout', name: 'authLogout', component: LfxLogout, meta: { label: 'Sign Out' } },
      { path: 'signup', name: 'Signup', component: Signup },
      { path: 'confirm-signup', name: 'Confirm Signup', component: Confirm },
      { path: 'success', name: 'Success', component: Success },
    ],
  },

  // ----- Generic -----

  { path: '/itemview/:record/:id', name: 'LFXItemView', component: LFXItemView },
  { path: '/todo', name: 'todo', component: LfxTodo },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },

  { path: '/:notFound(.*)', name: 'NotFound', component: NotFound },
];

const routes = baseRoutes.concat(
  transactionRoutes,
  accountRoutes,
  clientApplicationRoutes,
  userRoutes,
  reportRoutes,
  searchRoutes,
  uploadAndDownloadRoutes,
  adminRoutes,
  configRoutes,
  helpRoutes
);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   if (to.name === from.name) {
  //     return { top: 0 };
  //   }
  // },
});

export default router;
