<template>
  <div class="lfx-bop-portfolio-report">
    <capitec-content-box>
      <div>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          :view="field.view"
          :fieldname="field.name"
          :definition="undefined"
          :record="optionsRecord"
          @change="on_change"
        />
      </div>
      <capitec-action-bar>
        <LDBButton
          v-for="button in formButtons"
          :key="button.name"
          :label="button.label"
          :action="button.action"
          :actionParams="button.actionParams"
          :params="button.params"
          :type="button.type"
          :tooltip="button.tooltip"
          :modal="button.modal"
          :viewGuards="button.guards"
          :enabledGuards="button.enabledGuards"
          :onSuccess="button.onSuccess"
          @beforeClick="button.beforeClick"
          @click="button.onClick"
          @on-api-success="button.onApiSuccess"
          @on-api-error="button.onApiError"
        >
          <template v-if="button.modal !== undefined" #modal>
            <div v-html="button.modal.content"></div>
          </template>
        </LDBButton>
      </capitec-action-bar>
      <template v-if="dataStatus === 'retrieving'">
        <LDBLoader />
      </template>
      <div
        v-else-if="dataStatus === 'retrieved' && reportData && Object.keys(reportData).length > 0"
        class="lfx-bop-portfolio-table-container"
      >
        <LFXListView :definition="definition" :records="reportData" />
      </div>
      <div v-else-if="dataStatus === 'retrieved'">No Data Returned for this Date Range</div>
    </capitec-content-box>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBField from '@/landobyte_vue/LDBField.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import LFXListView from '@/views/general/LFXListView.vue';

export default {
  name: 'LfxBopPortfolioReport',
  components: { LDBField, LDBLoader, LFXListView },
  props: {},
  data() {
    return {
      selectorsUpdated: true,
      dataStatus: 'none',
      optionsRecord: {
        datePreset: 'today',
        dateFrom: new Date().toISOString(),
        dateTo: new Date().toISOString(),
        portfolioStatuses: 'all',
        statusSelector: undefined,
      },
      reportData: undefined,
      rollUpBranches: true,
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),

    // ----- Buttons -----

    formButtons() {
      let self = this;
      return [
        {
          name: 'generateReport',
          label: 'Generate Report',
          type: 'primary',
          action: 'getPayments',
          onSuccess: { doStoreUpdate: false },
          enabledGuards: this.selectorsUpdated === true,
          actionParams: {
            additionalSelector: {
              where: {
                and: [
                  {
                    valueDate: { gte: this.optionsRecord.dateFrom },
                  },
                  {
                    valueDate: { lte: this.optionsRecord.dateTo },
                  },
                  {
                    status: this.optionsRecord.statusSelector,
                  },
                  {
                    currentPortfolioId: { neq: null },
                  },
                ],
              },
            },
          },
          beforeClick() {
            self.dataStatus = 'retrieving';
          },
          onApiSuccess: self.onApiSuccess,
          onApiError() {
            self.dataStatus = 'error';
          },
        },
      ];
    },

    tableButtons() {
      self = this;
      return [
        {
          name: 'toggleRollUpBranches',
          label: this.rollUpBranches ? 'Unroll Branches' : 'Roll Up Branches',
          type: 'primary',
          action: 'click',
          onClick() {
            self.rollUpBranches = !self.rollUpBranches;
          },
        },
      ];
    },

    // ----- Fields -----

    viewFields() {
      const allFields = {
        datePresets: {
          name: 'datePreset',
          label: 'Date Preset',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'today', name: 'Today' },
                { id: 'yesterday', name: 'Yesterday' },
                { id: 'thisWeek', name: 'This Week' },
                { id: 'lastWeek', name: 'Last Week' },
                { id: 'thisMonth', name: 'This Month' },
                { id: 'lastMonth', name: 'Last Month' },
                { id: 'other', name: 'Other' },
              ],
            },
          },
          view: 'edit',
          guards: true,
        },
        dateFrom: {
          name: 'dateFrom',
          label: 'Date From',
          datatype: {
            datetime: {
              type: 'date',
              format: 'human',
              minDate: '2014/01/01',
              maxDate: { type: 'day', offset: 0 },
            },
          },
          enableGuards: this.optionsRecord.datePreset === 'other',
          view: this.optionsRecord.datePreset === 'other' ? 'edit' : 'item',
          guards: true,
        },
        dateTo: {
          name: 'dateTo',
          label: 'Date To',
          datatype: {
            datetime: {
              type: 'date',
              format: 'human',
              minDate: '2014/01/01',
              maxDate: { type: 'year', offset: 1 },
            },
          },
          enableGuards: this.optionsRecord.datePreset === 'other',
          view: this.optionsRecord.datePreset === 'other' ? 'edit' : 'item',
          guards: true,
        },
        portfolioStatuses: {
          name: 'portfolioStatuses',
          label: 'Portfolio Status',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'all', name: 'All' },
                { id: 'completed', name: 'Completed' },
                { id: 'notCompleted', name: 'Not Completed' },
              ],
            },
          },
          view: 'edit',
          guards: true,
        },
      };
      const returnFields = {};
      for (const f in allFields) {
        if (allFields[f].guards !== false) {
          returnFields[f] = allFields[f];
        }
      }
      return returnFields;
    },

    // ----- TABLE -----
    showBankMarkup() {
      return state.authUser.accountLevel === 'bank';
    },
    tableColumns() {
      let self = this;
      let res = [
        {
          col: 'intermediaryColumn',
          field: 'intermediaryName',
          label: 'Intermediary Name',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'branchColumn',
          field: 'branchName',
          label: 'Branch Name',
          isKey: false,
          sortable: false,
          guards: this.rollUpBranches === false,
          showAsCurrency: false,
        },
        {
          col: 'dealCountColumn',
          field: 'dealCount',
          label: 'Count',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'totalZarValueColumn',
          field: 'totalZarValue',
          label: 'ZAR Value',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: true,
        },
        {
          col: 'bankMarkupColumn',
          field: 'bankMarkup',
          label: 'Bank Markup',
          isKey: false,
          sortable: false,
          guards: this.showBankMarkup,
          showAsCurrency: true,
        },
        {
          col: 'systemMarkupColumn',
          field: 'systemMarkup',
          label: 'System Markup',
          isKey: false,
          sortable: false,
          guards: this.showBankMarkup,
          showAsCurrency: true,
        },
        {
          col: 'intermediaryMarkupColumn',
          field: 'intermediaryMarkup',
          label: 'Intermediary Markup',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: true,
        },
      ];
      return res.filter((f) => f.guards === true);
    },
    definitionName() {
      return 'payment';
    },
    definition() {
      return getDefinition(this.definitionName);
    },
    collectionPathTemplate() {
      return this.definition?.collectionPath;
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    on_change({ field, value }) {
      this.optionsRecord[field] = value;
      if (field === 'datePreset') {
        this.updateFromPreset(value);
      }
      if (field === 'portfolioStatuses') {
        switch (value) {
          case 'all':
            this.optionsRecord.statusSelector = undefined;
            break;
          case 'completed':
            this.optionsRecord.statusSelector = { eq: 'complete' };
            break;
          case 'notCompleted':
            this.optionsRecord.statusSelector = { neq: 'complete' };
            break;
        }
      }
      this.selectorsUpdated = true;
    },
    onApiSuccess(response) {
      this.reportData = {};
      for (const row of response.response?.rows) {
        row.collection_path = Mustache.render(this.collectionPathTemplate, row);
        this.reportData[row.id] = this.definition.class(row, this.$store);
      }
      this.dataStatus = 'retrieved';
      this.selectorsUpdated = false;
    },
    updateFromPreset(newPreset) {
      const today = new Date();
      switch (newPreset) {
        case 'today':
          this.optionsRecord.dateFrom = today.toISOString();
          this.optionsRecord.dateTo = today.toISOString();
          break;
        case 'yesterday':
          this.optionsRecord.dateFrom = new Date(new Date(today).setDate(today.getDate() - 1)).toISOString();
          this.optionsRecord.dateTo = this.optionsRecord.dateFrom;
          break;
        case 'thisWeek':
          this.optionsRecord.dateFrom = new Date(
            new Date(today).setDate(today.getDate() - ((today.getDay() + 6) % 7))
          ).toISOString();
          this.optionsRecord.dateTo = new Date(
            new Date(today).setDate(today.getDate() - ((today.getDay() + 6) % 7) + 4)
          ).toISOString();
          break;
        case 'lastWeek':
          this.optionsRecord.dateFrom = new Date(
            new Date(today).setDate(today.getDate() - ((today.getDay() + 6) % 7) - 7)
          ).toISOString();
          this.optionsRecord.dateTo = new Date(
            new Date(today).setDate(today.getDate() - ((today.getDay() + 6) % 7) - 3)
          ).toISOString();
          break;
        case 'thisMonth':
          this.optionsRecord.dateFrom = new Date(today.getFullYear(), today.getMonth(), 1).toISOString();
          this.optionsRecord.dateTo = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString();
          break;
        case 'lastMonth':
          this.optionsRecord.dateFrom = new Date(today.getFullYear(), today.getMonth() - 1, 1).toISOString();
          this.optionsRecord.dateTo = new Date(today.getFullYear(), today.getMonth(), 0).toISOString();
          break;
      }
    },
  },
};
</script>

<style>
.lfx-bop-portfolio-table-container {
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
}

.lfx-bop-portfolio-report {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}
</style>
