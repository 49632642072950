<template>
  <LDBModal result="info" size="large" :header="header">
    <template #body>
      <div v-if="clientRetrievedStatus === 'retrieved'">
        <!-- <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="item"
          :fieldname="field.name"
          :record="clientData"
          @change="on_change"
        />
        <br/> -->
        <p>To edit the Client's details, please contact TreasuryMaintain.</p>
        <br />
        <LDBItemView
          :record="clientData"
          view="item"
          :showButtons="false"
          :showBackButton="false"
          :checkMandatoryFields="false"
          :unlinkedMode="true"
          providedTitle="Client Details"
          :providedFields="viewFields"
          :providedGroups="gropus"
        />
      </div>
      <div v-else-if="clientRetrievedStatus === 'error'">
        <br />
        Failed to Load Client Data.
        <br />
        {{ clientData.errorMessage }}
      </div>
      <template v-else>
        <br />
        Loading client data from the Bank - This may take some time
        <LDBLoader />
      </template>
    </template>
    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import LDBField from '@/landobyte_vue/LDBField.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxClientBankInfoView',
  components: { LDBField, LDBLoader, LDBItemView },
  props: {},
  data() {
    return {
      clientRetrievedStatus: 'none',
      clientData: {},
    };
  },
  created() {
    this.retrieveClientData();
  },
  computed: {
    ...mapGetters(['permissions', 'config']),
    ...mapState(['account']),
    header() {
      return 'Bank Client Information';
    },
    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          action: 'click',
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
      ];
      return allButtons;
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : 'todo';
    },

    // ----- Definition -----

    // ----- Record -----

    accountId() {
      return +this.$route.params.accountId;
    },
    accountRecord() {
      return this.account[this.accountId];
    },
    // ----- Fields -----

    fields() {
      return this.newRecord.guarded_fields;
    },

    gropus() {
      return {
        details: { priority: 1, label: 'Details' },
        contact: { priority: 2, label: 'Contact' },
        identification: { priority: 3, label: 'Identification' },
        settings: { priority: 4, label: 'Bank Settings' },
        income: { priority: 5, label: 'Income' },
      };
    },

    viewFields() {
      const addressJsonType = {
        json: {
          fieldMap:{
            address1:'Address',
            address2:'Suburb',
            address3:'City',
            address4:'Province',
            postCode:'Postal Code',
            cityCode:'City Code',
            countryCode:'Country Code'
          }
        }
      }
      const allFields = [
        { name: 'cifNumber', label: 'CIF Number', datatype: 'text', group: 'details' },
        { name: 'clientName', label: 'Client Name', datatype: 'text', group: 'details' },
        { name: 'firstName', label: 'First Name', datatype: 'text', group: 'details' },
        { name: 'middleName', label: 'Middle Name', datatype: 'text', group: 'details' },
        { name: 'lastName', label: 'Surname', datatype: 'text', group: 'details' },
        { name: 'title', label: 'Title', datatype: 'text', group: 'details' },
        {
          name: 'gender',
          label: 'Gender',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'M', name: 'Male' },
                { id: 'F', name: 'Female' },
              ],
            },
          },
          group: 'details',
        },
        { name: 'dateOfBirth', label: 'Date of Birth', datatype: {datetime: {type: 'date',format: 'human',},}, group: 'details' },
        { name: 'placeOfBirth', label: 'Place of Birth', datatype: 'text', group: 'details' },
        { name: 'nationality', label: 'Nationality', datatype: 'text', group: 'details' },
        { name: 'address', label: 'Address', datatype: addressJsonType, group: 'contact' },
        { name: 'geoCode', label: 'Geo Code', datatype: 'text', group: 'contact' },
        { name: 'mailingAddress', label: 'Mailing Address', datatype: addressJsonType, group: 'contact' },
        { name: 'homePhoneNo', label: 'Home Phone Number', datatype: 'text', group: 'contact' },
        { name: 'businessPhoneNo', label: 'Business Phone Number', datatype: 'text', group: 'contact' },
        { name: 'faxNo', label: 'Fax Number', datatype: 'text', group: 'contact' },
        { name: 'mobileNo', label: 'Mobile Number', datatype: 'text', group: 'contact' },
        { name: 'email', label: 'Email Address', datatype: 'text', group: 'contact' },
        { name: 'contactPersonName', label: 'Contact Name', datatype: 'text', group: 'contact' },
        { name: 'contactNo', label: 'Contact Number', datatype: 'text', group: 'contact' },
        { name: 'businessRegAddress', label: 'Business Address', datatype: addressJsonType, group: 'contact' },
        { name: 'idType', label: 'ID Type', datatype: 'text', group: 'identification' },
        { name: 'idTypeDetail', label: 'ID Type Details', datatype: 'text', group: 'identification' },
        { name: 'identification', label: 'Identification', datatype: 'text', group: 'identification' },
        { name: 'idIssueDate', label: 'ID Issue Date', datatype: {datetime: {type: 'date',format: 'human',},}, group: 'identification' },
        { name: 'idIssuePlace', label: 'ID Issue Place', datatype: 'text', group: 'identification' },
        { name: 'taxNumber', label: 'Tax Number', datatype: 'text', group: 'identification' },
        { name: 'permitNumber', label: 'Permit Number', datatype: 'text', group: 'identification' },
        { name: 'permitExpiry', label: 'Permit Expirty', datatype: {datetime: {type: 'date',format: 'human',},}, group: 'identification' },
        { name: 'notifyMethod', label: 'Notify Method', datatype: 'text', group: 'settings' },
        { name: 'customsClientNumber', label: 'Customs Client Number', datatype: 'text', group: 'settings' },
        {
          name: 'thirdPartyAllocatedCustNumber',
          label: 'Third Party Allocated Customer Number',
          datatype: 'text',
          group: 'settings',
        },
        { name: 'treasuryShortName', label: 'Treasury ShortName', datatype: 'text', group: 'settings' },
        { name: 'internetBanking', label: 'Internet Banking', datatype: 'boolean', group: 'settings' },
        { name: 'treasuryService', label: 'Treasury Service', datatype: 'boolean', group: 'settings' },
        { name: 'accountType', label: 'Account Type', datatype: 'text', group: 'settings' },
        { name: 'emigratingBlock', label: 'Emigration Block', datatype: 'boolean', group: 'settings' },
        {
          name: 'incomeFrequency',
          label: 'Income Frequency',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'W', name: 'Weekly' },
                { id: 'M', name: 'Monthly' },
                { id: 'A', name: 'Annual' },
              ],
            },
          },
          group: 'income',
        },
        { name: 'incomeAmount', label: 'Income Amount', datatype: 'integer', group: 'income' },
        {
          name: 'rentalIncomeFrequency',
          label: 'Rental Income Frequency',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'W', name: 'Weekly' },
                { id: 'M', name: 'Monthly' },
                { id: 'A', name: 'Annual' },
              ],
            },
          },
          group: 'income',
        },
        { name: 'rentalIncomeAmount', label: 'Frental Income Amount', datatype: 'integer', group: 'income' },
        { name: 'annualTurnover', label: 'Annual Turnover', datatype: 'integer', group: 'income' },
        { name: 'sourceOfIncome', label: 'Source of Income', datatype: 'text', group: 'income' },
        { name: 'sourceOfIncomeDetail', label: 'Source of Income Detail', datatype: 'text', group: 'income' },
        { name: 'turnOverCash', label: 'Turn over Cash %', datatype: 'float', group: 'income' },
      ];
      const res = [];
      for (const field of allFields) {
        if (this.clientData[field.name]) {
          res.push(field);
        }
      }
      return res;
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),

    async retrieveClientData() {
      this.clientRetrievedStatus = 'retrieving';
      const clientDataResult = await doPermissionAction({ onSuccess: { doStoreUpdate: false } }, 'getBankInformation', {
        accountId: this.accountId,
      });
      if (clientDataResult.status === 'success') {
        this.clientData = clientDataResult.response;
        this.clientRetrievedStatus = 'retrieved';
      } else {
        this.clientData.errorMessage = clientDataResult.message || clientDataResult.response.message;
        this.clientRetrievedStatus = 'error';
      }
    },
    // ----- Fields -----

    // ----- API calls -----
  },
};

// type replyObject = {
//   // GENERAL
//       cifNumber: string,
//       clientName: string,
//       nationality: string,
//       address: {
//         address1: string
//         address2: string
//         address3: string
//         address4: string
//         postCode: string
//         cityCode: string
//         countryCode: string
//       },
//       geoCode: string,
//       mailingAddress: {
//         address1: string
//         address2: string
//         address3: string
//         address4: string
//         postCode: string
//         cityCode: string
//         countryCode: string
//       },
//       homePhoneNo: string,
//       businessPhoneNo: string,
//       faxNo: string,
//       mobileNo: string,
//       email: string,
//       contactNo: string,
//       notifyMethod: string,
//       customsClientNumber: string,
//       thirdPartyAllocatedCustNumber: string,
//       treasuryShortName: string,
//       internetBanking: string,
//       treasuryService: string,

//       accountType: string,
//       idType: string,
//       idTypeDetail: string,
//       identification: string,
//       idIssueDate: Date,
//       idIssuePlace: string,
//       taxNumber: string,

//     // INDIVIDUAL

//       firstName: string,
//       middleName: string,
//       lastName: string,
//       permitNumber: string,
//       permitExpiry: string,
//       title: string,
//       gender: string,
//       dateOfBirth: Date,
//       placeOfBirth: string,
//       incomeFrequency: string,
//       incomeAmount: number,
//       rentalIncomeFrequency: string,
//       rentalIncomeAmount: number,
//       emigratingBlock: string,

// // ENTITY
//       annualTurnover: number,
//       sourceOfIncome: string,
//       sourceOfIncomeDetail: string,
//       turnOverCash: number,
//       businessRegAddress: {
//         address1: string
//         address2: string
//         address3: string
//         address4: string
//         postCode: string
//         cityCode: string
//         countryCode: string
//       },
// }
</script>
