<template>
  <LFXPagedListView
    :definition="definition"
    action="getPayments"
    :actionParams="{}"
    :search="searchConfig"
    :defaultSort="defaultSort"
    :defaultWhere="defaultWhere"
  />
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXPagedListView from '@/views/general/LFXPagedListView.vue';

export default {
  name: 'LfxBeneficiaryPaymentList',
  components: { LFXPagedListView },
  data() {
    const todayStart = new Date(new Date().setHours(0, 0, 0, 0));
    const todayEnd = new Date(new Date().setHours(23, 59, 59, 0));
    return {
      searchConfig: {
        searchFields: [
          'reference',
          'externalReference',
          'paymentCurrency',
          'foreignCurrency',
          'beneficiaryReference',
          'specialInstructions',
          'purposeOfPayment',
          'invoiceNumber',
          'integrationId',
        ],
        choices: [
          {
            id: 'status',
            title: '',
            chips: [
              { id: 'all', label: 'All', selector: {}, default: true },
              { id: 'complete', label: 'Completed', selector: { status: { in: ['complete'] } } },
              {
                id: 'open',
                label: 'Open',
                selector: { status: { notIn: ['complete', 'cancelled', 'deleted'] } },
              },
              { id: 'cancelled', label: 'Cancelled', selector: { status: { in: ['cancelled', 'deleted'] } } },
              {
                id: 'overdue',
                label: 'Overdue',
                selector: { valueDate: { lte: todayStart }, status: { notIn: ['complete', 'cancelled', 'deleted'] } },
              },
            ],
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account', 'payment']),
    defaultSort() {
      return { order: 'valueDate', sort: 'desc' };
    },
    defaultWhere() {
      return { destinationAccount: { in: this.beneficiarysBankAccountIds } };
    },
    viewedAccountId() {
      return +this.$route.params.accountId;
    },
    viewedAccountRecord() {
      return this.account?.[this.viewedAccountId];
    },
    viewedAccountLevel() {
      return this.account[this.viewedAccountId]?.accountLevel;
    },
    beneficiaryId() {
      return +this.$route.params?.beneficiaryId;
    },
    beneficiaryRecord() {
      return this.viewedAccountRecord?.beneficiaries?.[this.beneficiaryId] || {};
    },

    beneficiarysBankAccountIds() {
      const res = [];
      for (const bankAccount in this.beneficiaryRecord.beneficiary_bankAccounts) {
        res.push(bankAccount);
      }
      return res;
    },
    definition() {
      return getDefinition('payment');
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
