import VueStore from 'vue-class-store';

import { LfxBopCatInt, LfxBopCatDef } from '@/definitions/LfxBopCatDef';
import { LfxModel, LfxDefinition } from './LfxModel';

@VueStore
// @ts-expect-error
class LfxBopCatMdl extends LfxDefinition {
  // @ts-expect-error
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
// @ts-expect-error
export class LfxBopCat extends LfxModel {
  // @ts-expect-error
  constructor(data, state) {
    super({ definition_name: 'bopCat', ...data }, state);
  }

  get link() {
    // @ts-expect-error
    return `/config/bop_categories/${this.id}`;
  }
}

// @ts-expect-error
export function Definition(context, name: string) {
  return new LfxBopCatMdl(LfxBopCatDef, LfxBopCat, context, name, {});
}
