<template>
  <div class="lfx-user-review-report">
    <capitec-content-box>
      <div>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          :view="field.view"
          :fieldname="field.name"
          :definition="undefined"
          :record="optionsRecord"
          @change="on_change"
        />
      </div>
      <template v-if="!!optionsRecord.reportingType">
        <br/>
        <div class="lfx-user-review-report-view-table-container">
          <table-lite
            id="lfx-user-review-report-view-table"
            :is-static-mode="true"
            :is-slot-mode="true"
            :has-checkbox="false"
            :is-loading="false"
            :is-re-search="false"
            :columns="tableColumns"
            :rows="sortedRecords"
            :pageSize="sortedRecords.length"
            :total="sortedRecords.length"
          >
            <template v-for="(col, i) of tableColumns" v-slot:[col.field]="data" :key="i">
              <template v-if="col.showAsCurrency">{{ formatCurrency(data.value[col.field]) }}</template>
              <!-- <template v-if="col.field?.amount">ZAR {{data.value[col.field].amount}}</template> -->
              <template v-else>{{ data.value[col.field] }}</template>
            </template>
          </table-lite>
        </div>
      </template>
    </capitec-content-box>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBField from '@/landobyte_vue/LDBField.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import TableLite from '@/views/general/TableLite.vue';
import { getOptionLabel } from '@/sharedUtils/LdbDefinitionUtils';
import { getDefinition } from '@/models/LdbDefinitions';

export default {
  name: 'LfxUserReviewReport',
  components: { LDBField, LDBLoader, TableLite },
  props: {},
  data() {
    return {
      selectorsUpdated: true,
      optionsRecord: {
        reportingType: undefined, // 'intermediary' | 'userTeam' | 'directClient'
        recordStatuses: 'active', // 'all' | 'active' | 'inactive'
        complianceStatuses:'all', // 'all' | 'compliant' | 'nonCompliant'
        previousReviewDate: undefined,
        nextReviewBeginsDate: undefined,
        nextReviewDate: undefined,
      },
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters(['config']),
    ...mapState(['IntermediarySettings','account','user']),
    accountDefinition() {
      return getDefinition('intermediary')
    },

    // ----- Fields -----

    viewFields() {
      const allFields = {
        reportingType: {
          name: 'reportingType',
          label: 'Reporting Type',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'intermediary', name: 'Intermediaries' },
                { id: 'userTeam', name: 'Bank Teams' },
                { id: 'directClient', name: 'Direct Clients'}
              ],
            },
          },
          view: 'edit',
          guards: true,
        },
        recordStatuses: {
          name: 'recordStatuses',
          label: this.optionsRecord.reportingType === 'directClient' ? 'Client Statuses' : (this.optionsRecord.reportingType === 'userTeam' ? 'Team Statuses' : 'Intermediary Statuses'),
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'all', name: 'All' },
                { id: 'active', name: 'Active' },
                { id: 'inactive', name: 'Inactive' },
              ],
            },
          },
          view: 'edit',
          guards: !!this.optionsRecord.reportingType,
        },
        complianceStatuses: {
          name: 'complianceStatuses',
          label: 'Compliance Statuses',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'all', name: 'All' },
                { id: 'compliant', name: 'Compliant' },
                { id: 'nonCompliant', name: 'Non-Compliant' },
              ],
            },
          },
          view: 'edit',
          guards: !!this.optionsRecord.reportingType,
        },
        previousReviewDate: {
          name: 'previousReviewDate',
          label: 'Previous Review Date',
          datatype: {
            datetime: {
              type: 'date',
              format: 'human',
            },
          },
          view: 'item',
          guards: !!this.optionsRecord.reportingType && !!this.optionsRecord.previousReviewDate,
        },
        
        nextReviewBeginsDate: {
          name: 'nextReviewBeginsDate',
          label: 'Next Review Begins',
          datatype: {
            datetime: {
              type: 'date',
              format: 'human',
            },
          },
          view: 'item',
          guards: !!this.optionsRecord.reportingType,
        },
        nextReviewDate: {
          name: 'nextReviewDate',
          label: 'Next Review Due',
          datatype: {
            datetime: {
              type: 'date',
              format: 'human',
            },
          },
          view: 'item',
          guards: !!this.optionsRecord.reportingType,
        },
      };
      const returnFields = {};
      for (const f in allFields) {
        if (allFields[f].guards !== false) {
          returnFields[f] = allFields[f];
        }
      }
      return returnFields;
    },

    // ----- TABLE -----

    tableColumns() {
      let self = this;
      let res = [
        {
          col: 'nameColumn',
          field: 'name',
          label: 'Name',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'statusColumn',
          field: 'status',
          label: 'Status',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'lastUserReviewDateColumn',
          field: 'lastUserReviewDateDisplay',
          label: 'Last Reviewed',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'lastUserReviewByColumn',
          field: 'lastUserReviewBy',
          label: 'Last Reviewed',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'complianceStatusColumn',
          field: 'complianceStatusText',
          label: 'Compliance Status',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
      ];
      return res.filter((f) => f.guards === true);
    },
    allRecords() {
      const res = []
      switch (this.optionsRecord.reportingType) {
        case 'intermediary':
          for (const intermediarySettingsId in this.IntermediarySettings) {
              const intermdiarySettings = this.IntermediarySettings[intermediarySettingsId]
              const intermediary = this.account[intermdiarySettings.accountId]
              if (intermediary.accountLevel !== 'bank') {
                res.push({
                name:intermediary.name,
                recordStatus: intermediary.status === 'active' ? 'active' : 'inactive',
                status:getOptionLabel(this.accountDefinition,'status',intermediary.status),
                lastUserReviewDate:intermdiarySettings.lastUserReviewDate ? new Date(intermdiarySettings.lastUserReviewDate) : undefined,
                lastUserReviewById:intermdiarySettings.lastUserReviewBy,
                complianceStatus:intermediary.userReviewStatus,
              })
            }
          }
          break        
        case 'userTeam':
          for (const userTeamId in this.config.userTeam) {
            const userTeam = this.config.userTeam[userTeamId]
            if (userTeam.teamType === 'bankUserTeam') {
              res.push({
                name:userTeam.description,
                recordStatus: userTeam.status === 'active' ? 'active' : 'inactive',
                status:userTeam.status === 'active' ? 'Active' : 'Disabled',
                lastUserReviewDate:userTeam.lastUserReviewDate ? new Date(userTeam.lastUserReviewDate): undefined,
                lastUserReviewById:userTeam.lastUserReviewBy,
                complianceStatus:userTeam.userReviewStatus,
              })
            }
          }
          break
        case 'directClient':
          const directClientInterIds = [];
          for (const accountId in this.account) {
            const account = this.account[accountId]
            if (account.accountLevel === 'intermediary' && account.allowClientsToSubmitPortfolios || account.IntermediarySettings?.allowClientsToSubmitPortfolios) {
              directClientInterIds.push(account.id)
            }
          }
          for (const accountId in this.account) {
            const account = this.account[accountId]
            if (account.accountLevel === 'client' && directClientInterIds.includes(account.intermediaryId)) {
              let selectedUserTeam;
              for (const userTeamId in this.config.userTeam) {
                const userTeam = this.config.userTeam[userTeamId]
                  if (+userTeam.accountId === +accountId) {
                    selectedUserTeam = userTeam
                  }
                }
              res.push({
                name:account.name,
                recordStatus: account.status === 'active' ? 'active' : 'inactive',
                status:getOptionLabel(this.accountDefinition,'status',account.status),
                lastUserReviewDate:selectedUserTeam?.lastUserReviewDate ? new Date(selectedUserTeam.lastUserReviewDate): undefined,
                lastUserReviewById:selectedUserTeam?.lastUserReviewBy,
                complianceStatus:account.userReviewStatus,
              })
            }
          }

          break
      }
      return res;
    },

    sortedRecords() {
      const res = []
      for (const record of this.allRecords) {
        let includeRecord = true;
        if (
          this.optionsRecord.recordStatuses !== 'all' &&
          (this.optionsRecord.recordStatuses !== record.recordStatus)
        )
        {
          includeRecord = false
        }
        if (
            this.optionsRecord.complianceStatuses !== 'all' &&
            !(this.optionsRecord.complianceStatuses === 'compliant' && this.statusIsCompliant(record.complianceStatus)) &&
            !(this.optionsRecord.complianceStatuses === 'nonCompliant' && !this.statusIsCompliant(record.complianceStatus))
        ) 
        {
          includeRecord = false
        }
        if (includeRecord) {
          record.lastUserReviewBy = record.lastUserReviewById ? this.user[record.lastUserReviewById]?.displayName : '';
          record.complianceStatusText = this.getComplianceStatusText(record.complianceStatus);
          record.lastUserReviewDateDisplay = record.lastUserReviewDate?.toLocaleString('en-ZA',{ timeZone: 'Africa/Johannesburg' }).replaceAll(',', '')
          res.push(record)
        }
      }
      return res
    }
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    on_change({ field, value }) {
      this.optionsRecord[field] = value;
      if (field === 'reportingType') {
        if (value === 'intermediary' || value === 'directClient') {
          this.optionsRecord.previousReviewDate = this.$store.state.systemSettings.userReview.previousIntermediaryReviewDate && new Date(this.$store.state.systemSettings.userReview.previousIntermediaryReviewDate);
          this.optionsRecord.nextReviewDate = new Date(this.$store.state.systemSettings.userReview.nextIntermediaryReviewDate);
        } else {
          this.optionsRecord.previousReviewDate = this.$store.state.systemSettings.userReview.previousBankReviewDate && new Date(this.$store.state.systemSettings.userReview.previousBankReviewDate);
          this.optionsRecord.nextReviewDate = new Date(this.$store.state.systemSettings.userReview.nextBankReviewDate);
        }
        this.optionsRecord.nextReviewBeginsDate = new Date((new Date(this.optionsRecord.nextReviewDate)).setDate(this.optionsRecord.nextReviewDate.getDate() - this.$store.state.systemSettings.userReview.daysBeforeButtonEnable))
      }
    },
      
    getComplianceStatusText(complianceStatus) {
      switch (complianceStatus) {
        case 'compliant': return "Compliant"
        case 'nonCompliant': return "Non Compliant"
        case 'due': return 'Review Due'
        default: return complianceStatus
      }
    },
    statusIsCompliant(status) {
      return ['compliant'].includes(status)
    }
  },
};
</script>

<style>
.lfx-user-review-report-view-table-container {
  position: relative;
  min-height: 320px;
}

#lfx-user-review-report-view-table {
  min-width: 100%;
}

div.lfx-user-review-report {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
</style>
