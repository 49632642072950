<template>
  <LDBModal result="warning" :header="header" size="x-large">
    <template #body>
      <LDBField
        v-for="(field, fieldname) in viewFields"
        :key="fieldname"
        :field="field"
        view="item"
        :fieldname="field.name"
        :definition="definition"
        :record="accountRecord"
      />
      <LDBField
        :field="selectDisableActionField"
        view="edit"
        :fieldname="selectDisableActionField.name"
        :definition="undefined"
        :record="record"
        @change="selectedDisableAction_change"
      />
      <ol v-if="selectedDisableAction === 'disableIntermediaryTrading'">
        <li>No new Deals can be booked.</li>
        <li>Existing Transactions can still be completed.</li>
        <li>New Clients can still be on-boarded.</li>
        <li>All other functionality remains available.</li>
      </ol>
      <ol v-if="selectedDisableAction === 'disableIntermediary'">
        <li>No new Transactions can be done.</li>
        <li>Existing Transactions can't be completed.</li>
        <li>New Clients can't be on-boarded.</li>
        <li>Users can still log in and access historic information.</li>
      </ol>
      <template v-if="selectedDisableAction">
        <p v-if="selectedDisableAction === 'disableIntermediaryTrading'">
          Are you sure you wish to <strong>Disable Trading</strong> for {{ accountRecord?.name }}?
        </p>
        <p v-if="selectedDisableAction === 'disableIntermediary'">
          Are you sure you wish to <strong>Close</strong> {{ accountRecord?.name }}?
        </p>
        <capitec-toggle
          label="Yes, I'm sure"
          :checked="wantToDisableIntermediary"
          @value-change="switch_wantToDisableIntermediary"
        ></capitec-toggle>
      </template>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :enabledGuards="button.enabledGuards"
        :definitionName="button.definitionName"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import lodash from 'lodash';
const merge = lodash.merge;

export default {
  name: 'LfxDisableIntermediary',
  data() {
    return { wantToDisableIntermediary: false, selectedDisableAction: false, record: {} };
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    // ----- Header ----

    header() {
      return `Disable Intermediary: ${this.accountRecord?.name}`;
    },

    // ----- Definition -----

    definition() {
      return getDefinition('intermediary');
    },

    // ----- Intermediary record -----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },

    // ----- Fields -----

    fields() {
      return this.accountRecord.guarded_fields;
    },
    includeFieldNames() {
      return [
        'name',
        'bpNumber',
        'cifNumber',
        // 'commissionAccount',
        'organisation',
        'numberOf_active_intermediaryUsers',
        // 'numberOf_active_intermediaryUsers_withActiveManageUser',
        // 'numberOf_active_systemUser',
      ]; // TODO add 'mayCompleteOwnKyc' - how to handle null vs false? - avr 2022-12-12
    },
    viewFields() {
      let res = [];
      for (let k in this.fields) {
        let field = this.fields[k];
        if (this.includeFieldNames.includes(field.name)) {
          field.fieldname = k;
          const updatedField = this.overrideField(field);
          // res.push(field);
          res.push(updatedField);
        }
      }
      return res;
    },
    fieldnames() {
      return this.viewFields.map((field) => field.name);
    },
    requiredFieldsRemaining() {
      return this.accountRecord.required_fields_remaining.filter((f) => this.fieldnames.indexOf(f) >= 0).length > 0;
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelEnableIntermediary',
          label: 'Cancel',
          type: '',
          action: 'click',
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'disableIntermediary',
          label: this.disableLabel, // TODO make dynamic for chosen action
          type: 'primary',
          action: this.selectedDisableAction || 'click',
          actionParams: this.accountRecord,
          params: { accountId: this.accountId },
          enabledGuards: this.wantToDisableIntermediary && this.has_selectedDisableAction,
          clickEvent(response) {
            if (response.status === 'success') {
              self.$router.go(-1);
            }
            // if (response.response.id) {
            //   self.$router.replace({ name: 'accountDetailsView', params: { accountId: response.response.id } });
            // }
            // self.addIntermediary();
          },
        },
      ];
      // return allButtons?.filter((button) => button.guards) !== undefined
      //   ? allButtons?.filter((button) => button.guards)
      //   : [];
      return allButtons;
    },
    // enableIntermediary_enableGuards() {
    //   return this.accountRecord.name &&
    //     this.accountRecord.cifNumber &&
    //     this.accountRecord.bpNumber &&
    //     this.accountRecord.commissionAccount &&
    //     this.accountRecord.organisation
    //     ? true
    //     : false;
    // },

    has_selectedDisableAction() {
      return this.selectedDisableAction ? true : false;
    },

    disableLabel() {
      switch (this.selectedDisableAction) {
        case 'disableIntermediaryTrading':
          return 'Disable Trading';
        case 'disableIntermediary':
          return 'Close Intermediary';
        default:
          return 'Disable Intermediary';
      }
    },

    selectDisableActionField() {
      let field = {
        label: 'Select Disable Action',
        name: 'selectDisableAction',
        datatype: {
          option: {
            optionType: 'text',
            options: [
              {
                id: 'disableIntermediaryTrading',
                name: this.accountRecord?.tradingEnabled ? 'Disable Trading' : 'Disable Trading - already disabled',
              },
              {
                id: 'disableIntermediary',
                name: 'Close Intermediary',
              },
            ],
          },
        },
        default: null,
        displayType: {
          datatype: 'option',
          display: 'radio',
        },
      };
      return field;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    switch_wantToDisableIntermediary() {
      this.wantToDisableIntermediary = !this.wantToDisableIntermediary;
    },

    selectedDisableAction_change({ field, value }) {
      // console.log('field', field);
      // console.log('value', value);
      this.selectedDisableAction = value;
    },

    // ----- Fields -----

    overrideField(field) {
      if (
        [
          'name',
          'cifNumber',
          'bpNumber',
          'commissionAccount',
          'organisation',
          'numberOf_active_intermediaryUsers',
          'numberOf_active_intermediaryUsers_withActiveManageUser',
          'numberOf_active_systemUser',
        ].includes(field.name) // TODO 'mayCompleteOwnKyc'
      ) {
        const startingField = merge({}, field);
        const overrideObject = this.getFieldOverRideObject(field);
        const newField = merge(startingField, overrideObject);
        return newField;
      } else {
        return field;
      }
    },
    getFieldOverRideObject(field) {
      switch (field.name) {
        // case 'name':
        //   return { displayType: { displayAsTag: true, tagTypeFunctionName: 'enableIntermediaryNameTag' } };
        // case 'cifNumber':
        //   return { displayType: { displayAsTag: true, tagTypeFunctionName: 'enableIntermediaryCifTag' } };
        // case 'cifNumber':
        //   return { displayType: { displayAsTag: true, tagTypeFunctionName: 'enableIntermediaryCifTag' } };
        // case 'bpNumber':
        //   return { displayType: { displayAsTag: true, tagTypeFunctionName: 'enableIntermediaryBpTag' } };
        // case 'commissionAccount':
        //   return { displayType: { displayAsTag: true, tagTypeFunctionName: 'enableIntermediaryCommissionAccountTag' } };
        // case 'organisation':
        //   return { displayType: { displayAsTag: true, tagTypeFunctionName: 'enableIntermediaryOrganisationTag' } };
        // // case 'mayCompleteOwnKyc': // TODO
        // //   return { forceDisplay: true };
        case 'numberOf_active_intermediaryUsers':
          return {
            displayType: {
              displayAsTag: true,
              tagTypeFunctionName: 'disableIntermediaryNumberOfActiveIntermediaryUsers',
            },
          };
        // case 'numberOf_active_intermediaryUsers_withActiveManageUser':
        //   return {
        //     displayType: {
        //       displayAsTag: true,
        //       tagTypeFunctionName: 'enableIntermediaryNumberOf_active_intermediaryUsers_withActiveManageUser',
        //     },
        //   };
        // case 'numberOf_active_systemUser':
        //   return {
        //     displayType: {
        //       displayAsTag: true,
        //       tagTypeFunctionName: 'enableIntermediaryNumberOf_active_systemUser',
        //     },
        //   };
        default:
          return {};
      }
    },
  },
};
</script>
