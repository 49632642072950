<template>
  <LFXContent
    :titleBar="titleBar"
    :show_tabs="showTabs"
    :tabs="tabs"
    :show_label="showLabel"
    :label="contentLabel"
    :show_actions="showActions"
    :form_layout="formLayout"
    :show_sidebar="false"
  >
    <template #actions>
      <LDBButton
        v-for="button in actionBarButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>

    <template #content>
      <router-view v-if="includeRefreshCounter" :refreshCounter="refreshCounter"></router-view>
      <router-view v-else></router-view>    </template>
  </LFXContent>

  <router-view name="modal"></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXContent from '@/components/layout/LfxContent.vue';

export default {
  name: 'LfxAdmin',
  components: { LFXContent },
  props: {},
  data() {
    return {
      refreshCounter: 0,
    };
  },
  created() {
    if (this.$route.name === 'admin') {
      this.selectFirstTab();
    }
  },
  mounted() {},
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'admin') {
      next(false);
      this.selectFirstTab();
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters(['permissions', 'isDevelopment']),
    ...mapState([]),

    // ----- Title Bar -----
    includeRefreshCounter() {
      return ['noUserAccessLogs'].includes(this.buttonGroup);
    },

    titleBar() {
      const titleBar = {
        label: 'System Admin',
      };
      return titleBar;
    },
    titleBarButtons() {
      let self = this;
      let buttons = [
        {
          name: 'disableTradingDealingRoom',
          label: 'Disable Trading - DR',
          type: 'white',
          guards: false, // TODO
          clickEvent: null, //TODO
        },
        {
          name: 'disableTradingSystem',
          label: 'Disable Trading - Sys',
          type: 'white',
          guards: false, // TODO
          clickEvent: null, //TODO
        },
      ];
      return buttons.filter((button) => button.guards);
    },

    // ----- Tabs -----

    showTabs() {
      return true;
    },
    tabs() {
      let tabs = [
        { tabName: 'trading', routeName: 'adminTradingView', guards: false && this.isDevelopment, label: 'Trading' },
        {
          tabName: 'permissionRequests',
          routeName: 'adminPermissionRequests',
          guards: this.permissions.grantUserPermissions || this.permissions.rejectUserPermissions,
          label: 'Permission Requests',
        },
        {
          tabName: 'noUserLogsView',
          routeName: 'noUserLogsView',
          guards: true,
          label: 'No User Access Logs',
        },
        {
          tabName: 'dataImportBatches',
          routeName: 'adminDataImportBatches',
          guards: this.permissions.getImportBatches,
          label: 'Data Import',
        },
        {
          tabName: 'communication',
          routeName: 'adminCommunicationView',
          guards: false && this.isDevelopment, //  - LEAVE FOR NOW
          label: 'Communication',
        },
        {
          tabName: 'pagerDuty',
          routeName: 'adminPagerDutyView',
          guards: false && this.isDevelopment, //  - LEAVE FOR NOW
          label: 'Pager Duty',
        },
      ];
      return tabs.filter((tab) => tab.guards);
    },

    // ----- Content Label -----

    showLabel() {
      return this.contentLabel ? true : false;
    },
    contentLabel() {
      return this.$route.meta?.content?.label
        ? this.$route.meta.content.label
        : "Navigation didn't complete, please click tab above to continue";
    },

    // ----- Action Bar -----

    showActions() {
      return this.actionBarButtons.length > 0 ? true : false;
    },
    actionBarButtons() {
      const self = this;
      const allButtons = {
        dataImportBatches: [
          {
            name: 'postProcessBatch',
            label: 'Post Process Batches',
            type: '',
            action: 'postProcessBatchedImports',
            guards: false,
            params: {  },
            actionParams: {},
          },
          {
            name: 'uploadDataImport',
            label: 'Upload Batch',
            type: 'primary',
            action: 'click',
            guards: this.permissions.uploadImportBatch,
            clickEvent() {
              self.$router.push({ name: 'adminDataImportBatchImport' });
            },
          },
        ],
      noUserAccessLogs: [
        {
          name: 'refreshLogs',
          label: 'Refresh',
          type: '',
          guards: this.includeRefreshCounter,
          action: 'click',
          params: {},
          httpParams: {},
          clickEvent: self.incrementRefreshCounter,
        }],
      };
      return allButtons[this.buttonGroup] ? allButtons[this.buttonGroup] : []; //.filter((button) => button.guards)
    },
    buttonGroup() {
      return this.$route.meta?.content?.buttonGroup;
    },

    // ----- Layout -----

    formLayout() {
      return this.$route.meta?.layout === 'list' ? false : true;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Title Bar -----

    onTitleBarButtonClick(button) {
      this.$router.push({ name: button.routeName });
    },

    // ----- Tabs -----

    selectFirstTab() {
      if (this.tabs.length) {
        this.$router.replace({ name: this.tabs[0].routeName });
      }
    },
    incrementRefreshCounter() {
      this.refreshCounter++;
    },

  },
};
</script>
