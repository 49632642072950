<template>
  <!-- <LDBItemView
    :record="bankAccountRecord"
    :definition="bankAccountDefinition"
    action="updateBeneficiaryBankAccount"
    :params="{ accountId: accountId, beneficiaryId: beneficiaryId, bankAccountId: bankAccountId }"
    @on-save="onSaveAccount"
    :view="view"
  /> -->

  <LDBLoader v-if="bankAccounts_retrievalStatus === 'retrieving'" />
  <LDBItemsView
    v-if="bankAccounts_retrievalStatus !== 'failed' && hasBankAccountRecordLoaded"
    tileView="default"
    :records="bankAccounts"
    :record="bankAccountRecord"
    :view="view"
    :action="action"
    :params="params"
    @on-save="onSaveBankAccount"
    @on-tile-click="onTileClick"
  />
  <p v-if="bankAccounts_retrievalStatus === 'failed'">Failed to retrieve Bank Accounts.</p>

  <router-view></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import LDBItemsView from '@/landobyte_vue/LDBItemsView.vue';

export default {
  name: 'LfxBankAccountDetails',
  TODO: ['Incorporate Itemsview and functionality from LfxBankAccountsItemList'],
  components: { LDBItemView, LDBItemsView },
  props: {},
  data() {
    return {};
  },
  created() {
    void this.accountRecord?.bankAccounts;

    if (this.bankAccountRecord) {
      this.bankAccountRecord?.activate();
    }
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    // ----- Component -----

    action() {
      return this.beneficiaryId ? 'updateBeneficiaryBankAccount' : 'updateClientBankAccount';
    },
    params() {
      return this.beneficiaryId
        ? { accountId: this.accountId, beneficiaryId: this.beneficiaryId, bankAccountId: this.bankAccountId }
        : { accountId: this.accountId, bankAccountId: this.bankAccountId };
    },

    // ----- Account record -----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },

    bankAccounts_retrievalStatus() {
      return this.accountRecord?.bankAccounts_retrievalStatus;
    },

    // ----- Beneficiary record -----

    beneficiaryId() {
      return +this.$route.params?.beneficiaryId;
    },
    beneficiaryRecord() {
      return this.accountRecord?.beneficiaries?.[this.beneficiaryId];
    },

    // ----- Bank Account record ----

    bankAccountId() {
      return +this.$route.params?.bankAccountId;
    },
    bankAccountRecord() {
      return this.accountRecord?.bankAccounts?.[this.bankAccountId];
    },

    hasBankAccountRecordLoaded() {
      return this.bankAccountRecord?.definition ? true : false;
    },

    bankAccountDefinition() {
      return getDefinition('bankAccount');
    },

    // ----- Bank Account Records -----

    bankAccounts() {
      return this.beneficiaryId
        ? this.beneficiaryRecord?.beneficiary_bankAccounts
        : this.accountRecord?.own_bankAccounts;
    },

    // ----- View -----

    view() {
      return this.$route.meta?.content?.view ? this.$route.meta.content.view : 'item';
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),

    onTileClick(record) {
      if (this.beneficiaryId) {
        this.$router.replace({
          name: 'beneficiaryBankAccountView',
          params: { accountId: this.accountId, beneficiaryId: this.beneficiaryId, bankAccountId: record.id },
        });
      } else {
        this.$router.replace({
          name: 'accountBankAccountView',
          params: { accountId: this.accountId, bankAccountId: record.id },
        });
      }
    },

    onSaveBankAccount() {
      // let record = this.bankAccountRecord;
      // if (this.beneficiaryId) {
      //   this.$router.replace({ name: 'beneficiaryBankAccountView' });
      // } else {
      //   this.$router.replace({ name: 'accountBankAccountView' });
      // }
      // }
      this.$router.go(-1);
    },
  },
};
</script>
