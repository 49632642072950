<template>
  <LDBModal result="info" :header="header" size="large">
    <template #body>
      <p>The following BOP Information field is always mandatory:</p>
      <ol>
        <li>Purpose of Payment</li>
      </ol>
      <p>The following BOP Information fields are configured and used in the system:</p>
      <!-- <ol>
        <li v-for="(info, index) in activeBopCatInfo" :key="index">{{ getFieldLabel(info) }}</li>
      </ol> -->

      <table>
        <thead>
          <tr>
            <th>Number</th>
            <th>Field Name</th>
            <th>Required</th>
            <th>Optional</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(inf, index) in activeBopCatInfo" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ getFieldLabel(inf) }}</td>
            <td>
              {{
                bopCatsSortedByDocsAndInfo.info.requiredInfo[inf].length > 0
                  ? bopCatsSortedByDocsAndInfo.info.requiredInfo[inf].length
                  : ''
              }}
            </td>
            <td>
              {{
                bopCatsSortedByDocsAndInfo.info.optionalInfo[inf].length > 0
                  ? bopCatsSortedByDocsAndInfo.info.optionalInfo[inf].length
                  : ''
              }}
            </td>
            <td>
              {{
                bopCatsSortedByDocsAndInfo.info.requiredInfo[inf].length +
                bopCatsSortedByDocsAndInfo.info.optionalInfo[inf].length
              }}
            </td>
          </tr>
        </tbody>
      </table>
      <template v-if="!areAllPaymentFieldsUsed">
        <p>The following BOP Information fields are available but not configured against any BOP Category:</p>
        <ol>
          <li v-for="(field, index) in unusedPaymentInfoFields" :key="index">{{ field.label }}</li>
        </ol>
      </template>
    </template>
    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxConfigBopCatInfo',
  components: { LDBItemView },
  props: {},
  // data() {
  //   return { failedTreasuryDeal_retrievalStatus: 'retrieving' }; //'retrieved' | 'retrieving'
  // },
  created() {
    // console.log('bopInfoFields', this.bopInfoFields);
  },
  mounted() {
    // if (this.failedTreasuryDeal?.[this.failedTreasuryDealId]) {
    //   this.failedTreasuryDeal_retrievalStatus = 'retrieved';
    // } else {
    //   this.retrieveFailedTreasuryDeal();
    // }
  },
  computed: {
    ...mapGetters(['config', 'activeBopCatInfo', 'bopCatsSortedByDocsAndInfo']),
    ...mapState([]),

    // ----- Modal -----

    header() {
      return `BOP Information`;
    },

    // ----- View -----

    view() {
      return 'item';
    },

    // ----- Payment fields -----

    bopInfoFields() {
      return getDefinition('payment')?.fields?.filter((field) => field.group === 'bopInfo');
    },

    areAllPaymentFieldsUsed() {
      return this.activeBopCatInfo?.length === this.bopInfoFields?.length - 1;
    },
    unusedPaymentInfoFields() {
      return this.bopInfoFields?.filter(
        (field) => !this.activeBopCatInfo.includes(field.name) && field.name !== 'purposeOfPayment'
      );
    },

    // ----- API action -----
    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'close',
          label: 'Close',
          type: '',
          action: 'click',
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
      ];
      return allButtons;
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),

    getFieldLabel(info) {
      return this.bopInfoFields?.find((field) => field.name === info)?.label;
    },
  },
};
</script>
