<template>
  <LDBModal size="large" :header="`${header}`">
    <template #body>
      <div>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import { getDefinition } from '@/db-interface';

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxAddSignatory',
  components: { LDBField },
  props: {},
  data() {
    return {
      signatoryType: String,
      newRecord: {},
    };
  },
  created() {
    this.signatoryType = this.$route.meta?.modal?.signatoryType || 'signer';
    let default_values = { ...this.definition.default_values };
    delete default_values.fullName;

    this.newRecord = this.definition.class(
      {
        ...default_values,
        collection_path: this.collectionPath,
        definition_name: this.definitionName,
        Table: this.tableName,
      },
      this.$store
    );
    if (this.$route.meta?.accountOpeningSignatory === true) {
      this.newRecord.accountOpeningSignatory = true;
    }
    if (this.signatoryType === 'contact') {
      this.newRecord.signerLevel = 'contactOnly';
    }
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['authUser', 'account']),
    //

    // ----- New record -----

    definitionName() {
      return 'signatory';
    },
    definition() {
      return getDefinition(this.definitionName);
    },
    tableName() {
      return 'LfxSignatory';
    },
    collectionPath() {
      return `account/${this.accountId}/signatory`;
    },

    // ----- Account record ----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId] || {};
    },

    // ----- Header -----

    header() {
      return this.signatoryType === 'contact' ? 'Add a Contact' : 'Add a Signatory';
    },

    // ----- Fields -----

    fields() {
      return this.newRecord.guarded_fields;
    },

    view() {
      return 'create';
    },

    viewFields() {
      let res = [];
      for (let k in this.fields) {
        let field = this.fields[k];
        if (field.views[this.view] !== false) {
          if (typeof field.views[this.view] === 'function') {
            if (
              field.views[this.view]({
                authUser: this.authUser,
                view: this.view,
                routeMetaData: this.$route.meta,
                record: this.newRecord,
              })
            ) {
              field.fieldname = k;
            }
          } else {
            field.fieldname = k;
          }
          if (field.name === 'isDirector') {
            field.label = this.accountRecord?.signatoryIsDirectorFieldLabel(field.label) || field.label
          }
          if (field.name === 'isSigningDirector') {
            field.label = this.accountRecord?.signatoryIsSigningDirectorFieldLabel(field.label) || field.label
          }
          res.push(field);
        }
      }
      return res;
    },

    fieldnames() {
      return this.viewFields.map((field) => field.name);
    },

    requiredFieldsRemaining() {
      return this.newRecord.required_fields_remaining.filter((f) => this.fieldnames.indexOf(f) >= 0).length > 0;
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelAddSignatory',
          label: 'Cancel',
          type: '',
          action: 'click',
          guards: true,
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

        {
          name: 'addSignatory',
          label: this.signatoryType === 'contact' ? 'Add a Contact' : 'Add a Signatory',
          type: 'primary',
          action: 'createSignatory',
          actionParams: this.newRecord,
          params: { accountId: this.accountId },
          enabledGuards: !this.newRecord.has_validation_errors && !this.requiredFieldsRemaining,
          clickEvent(response) {
            if (response.status === 'success') {
              self.$router.go(-1);
            }
          },
        },
      ];
      return allButtons;
    },

    // ----- Fields -----
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Fields -----

    on_change({ field, value }) {
      this.newRecord[field] = value;
    },
  },
};
</script>
