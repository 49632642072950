<template>
  <div class="lfx-invoice-report">
    <capitec-content-box>
      <div>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          :view="field.view"
          :fieldname="field.name"
          :definition="undefined"
          :record="optionsRecord"
          @change="on_change"
        />
      </div>
      <capitec-action-bar>
        <LDBButton
          v-for="button in formButtons"
          :key="button.name"
          :label="button.label"
          :action="button.action"
          :actionParams="button.actionParams"
          :params="button.params"
          :type="button.type"
          :tooltip="button.tooltip"
          :modal="button.modal"
          :viewGuards="button.guards"
          :enabledGuards="button.enabledGuards"
          :onSuccess="button.onSuccess"
          @beforeClick="button.beforeClick"
          @click="button.onClick"
          @on-api-success="button.onApiSuccess"
          @on-api-error="button.onApiError"
        >
          <template v-if="button.modal !== undefined" #modal>
            <div v-html="button.modal.content"></div>
          </template>
        </LDBButton>
      </capitec-action-bar>
      <p>Note: If the report contains more than 1000 deals, it will be emailed to you instead of downloaded.</p>
      <template v-if="dataStatus === 'retrieving'">
        <LDBLoader />
      </template>
    </capitec-content-box>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBField from '@/landobyte_vue/LDBField.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import TableLite from '@/views/general/TableLite.vue';

export default {
  name: 'LfxInvoiceReport',
  components: { LDBField, LDBLoader, TableLite },
  props: {},
  data() {
    return {
      selectorsUpdated: true,
      dataStatus: 'none',
      optionsRecord: {
        datePreset: 'bookedToday',
        dateType: 'capture',
        dateFrom: new Date().toISOString(),
        dateTo: new Date().toISOString(),
        dealStatuses: 'open',
        branchId: 0
      },
      reportData: undefined,
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['authUser','account']),

    // ----- Buttons -----

    formButtons() {
      let self = this;
      return [
        {
          name: 'generateReport',
          label: 'Download',
          type: 'primary',
          action: 'invoiceReport',
          onSuccess: { doStoreUpdate: false },
          actionParams: this.optionsRecord,
          enabledGuards: this.selectorsUpdated === true,
          beforeClick() {
            self.dataStatus = 'retrieving';
          },
          onApiSuccess: self.onApiSuccess,
          onApiError() {
            self.dataStatus = 'error';
          },
        },
      ];
    },

    // ----- Fields -----

    viewFields() {
      const allFields = {
        branchId: {
          name: 'branchId',
          label: 'Branch',
          datatype: {
            option: {
              optionType: 'text',
              options: this.branchOptions,
            },
          },
          view: 'edit',
          guards: ['intermediary','intermediaryBranch'].includes(this.authUser.accountLevel),
        },
        datePresets: {
          name: 'datePreset',
          label: 'Date Preset',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'bookedToday', name: 'Booked Today' },
                { id: 'bookedThisWeek', name: 'Booked This Week' },
                { id: 'bookedThisMonth', name: 'Booked This Month' },
                { id: 'completedLastMonth', name: 'Completed Last Month' },
                { id: 'completingThisMonth', name: 'Completing This Month' },
                { id: 'other', name: 'Other' },
              ],
            },
          },
          view: 'edit',
          guards: true,
        },
        dateType: {
          name: 'dateType',
          label: 'Date Type',
          // displayType: {
          //   display: 'radio',
          // },
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'capture', name: 'Capture Date' },
                { id: 'value', name: 'Value Date' },
              ],
            },
          },
          enableGuards: this.optionsRecord.datePreset === 'other',
          view: 'edit',
          view: this.optionsRecord.datePreset === 'other' ? 'edit' : 'item',
          guards: true,
        },
        dateFrom: {
          name: 'dateFrom',
          label: 'Date From',
          datatype: {
            datetime: {
              type: 'date',
              format: 'human',
              minDate: '2014/01/01',
              maxDate: { type: 'day', offset: 0 },
            },
          },
          enableGuards: this.optionsRecord.datePreset === 'other',
          view: this.optionsRecord.datePreset === 'other' ? 'edit' : 'item',
          guards: true,
        },
        dateTo: {
          name: 'dateTo',
          label: 'Date To',
          datatype: {
            datetime: {
              type: 'date',
              format: 'human',
              minDate: '2014/01/01',
              maxDate: this.toMaxDate,
            },
          },
          enableGuards: this.optionsRecord.datePreset === 'other',
          view: this.optionsRecord.datePreset === 'other' ? 'edit' : 'item',
          guards: true,
        },
        dealStatuses: {
          name: 'dealStatuses',
          label: 'Deal Statuses',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'all', name: 'All Deals (including Cancelled)' },
                { id: 'open', name: 'Open and Complete Deals Only' },
                { id: 'complete', name: 'Complete Deals Only' },
              ],
            },
          },
          view: 'edit',
          guards: true,
        },
      };
      const returnFields = {};
      for (const f in allFields) {
        if (allFields[f].guards !== false) {
          returnFields[f] = allFields[f];
        }
      }
      return returnFields;
    },
    toMaxDate() {
      return this.optionsRecord.dateType === 'capture' ? { type: 'day', offset: 0 } : { type: 'year', offset: 1 };
    },
    branchOptions() {
      if (!['intermediary','intermediaryBranch'].includes(this.authUser.accountLevel)) {
        return []
      }
      const myAccount = this.account[this.authUser.accountId];
      const res = [{id:0,name:"All"}]
      const childrenBranches = myAccount.childrenBranches || []
      for (const childBranch of childrenBranches) {
        res.push({id:childBranch.id,name:childBranch.name})
      }
      return res
    }
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    on_change({ field, value }) {
      this.optionsRecord[field] = value;
      if (field === 'datePreset') {
        this.updateFromPreset(value);
      }
      this.selectorsUpdated = true;
    },
    onApiSuccess(response) {
      this.reportData = response.response;
      this.dataStatus = 'retrieved';
      this.selectorsUpdated = false;
    },
    updateFromPreset(newPreset) {
      const today = new Date();
      switch (newPreset) {
        case 'bookedToday':
          this.optionsRecord.dateType = 'capture';
          this.optionsRecord.dateFrom = today.toISOString();
          this.optionsRecord.dateTo = today.toISOString();
          break;
        case 'bookedThisWeek':
          this.optionsRecord.dateType = 'capture';
          this.optionsRecord.dateFrom = new Date(
            new Date(today).setDate(today.getDate() - ((today.getDay() + 6) % 7))
          ).toISOString();
          this.optionsRecord.dateTo = today.toISOString();
          break;
        case 'bookedThisMonth':
          this.optionsRecord.dateType = 'capture';
          this.optionsRecord.dateFrom = new Date(today.getFullYear(), today.getMonth(), 1,12).toISOString();
          this.optionsRecord.dateTo = today.toISOString();
          break;
        case 'completedLastMonth':
          this.optionsRecord.dateType = 'value';
          this.optionsRecord.dateFrom = new Date(today.getFullYear(), today.getMonth() - 1, 1,12).toISOString();
          this.optionsRecord.dateTo = new Date(today.getFullYear(), today.getMonth(), 0,12).toISOString();
          break;
        case 'completingThisMonth':
          this.optionsRecord.dateType = 'value';
          this.optionsRecord.dateFrom = new Date(today.getFullYear(), today.getMonth(), 1,12).toISOString();
          this.optionsRecord.dateTo = new Date(today.getFullYear(), today.getMonth() + 1, 0,12).toISOString();
          break;
      }
    },
  },
};
</script>

<style>
div.lfx-invoice-report {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
</style>
