<template>
  <LDBModal type="result" result="info" header="MFA Required" style="z-index: var(--lfx-z-index-otp)">
    <template #body>
      <template v-if="mfa.requesting && !mfa.otpSent">
        <div>
          Please follow the instructions on your phone in order to complete the Multi-Factor Authentication (MFA)
          process.
        </div>
      </template>

      <template v-if="mfa.otpSent">
        <div v-if="mfa.otpType === 'email'">
          Please enter the OTP sent to your email address to complete the Multi-Factor Authentication (MFA) process.
        </div>
        <div v-else>
          Please enter the OTP sent to your phone via SMS to complete the Multi-Factor Authentication (MFA) process.
        </div>
        <div>&nbsp;</div>
        <capitec-text-field
          v-if="mfa.otpSent"
          :focussed="true"
          label="OTP"
          type="text"
          @value-change="on_change"
          @key-input="on_change"
          @keyup.enter="doSendOTP"
        >
        </capitec-text-field>
      </template>
    </template>

    <template #footer>
      <capitec-button type="" label="Cancel" @click="doCancelOTP"></capitec-button>
      <capitec-button v-if="mfa.otpSent" type="" label="Resend OTP" @click="doReSendOTP"></capitec-button>
      <capitec-button v-if="mfa.otpSent" type="primary" label="Submit OTP" @click="doSendOTP"></capitec-button>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['mfa']),
  },
  methods: {
    ...mapMutations([]),
    ...mapActions(['lfx_auth_dont_send_mfa_otp', 'lfx_auth_send_mfa_otp', 'lfx_auth_get_mfa_token']),
    doReSendOTP() {
      this.lfx_auth_get_mfa_token(store, {
        // requestedAction: action,
        messageText: 'string', //TODO
      });
    },
    doSendOTP() {
      this.lfx_auth_send_mfa_otp({ otp: this.otp });
    },
    doCancelOTP() {
      this.lfx_auth_dont_send_mfa_otp({});
    },
    on_change(evt) {
      this.otp = evt.detail.new;
    },
  },
};
</script>

<style></style>
