//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2022 LandoByte (Pty) Ltd.
//  File        : LdbWebSocketsFe.ts
//  Author      : Bevan Timm
//  Description : This module handles the functioning of the rates feed web socket connections for the LandoFX system
//  Created     : 7 March 2022 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------
import type { stateDef } from '@/store';
import type { Store } from 'vuex';
import type { LdbFeWebSocket, LdbWebSocketEvent } from './LdbWebSocketsFe';

//----------------------------------------------------------------------------------------------------------------------

export type ratesFeedItem = {
  ric: string;
  bid: number;
  ask: number;
  lastTick: Date;
  todayFirstTickAsk: number;
  todayFirstTickBid: number;
  todayFirstTickDay: Date;
};

var ratesfeedwebsocket: LdbFeWebSocket | undefined;

//----------------------------------------------------------------------------------------------------------------------

export const initialiseRatesFeedWebsocket = (webSocket: LdbFeWebSocket, store: Store<stateDef>) => {
  ratesfeedwebsocket = webSocket;
  store.state.ratesFeedStatus = 'connecting';
};

export const onOpenRatesFeedWebsocket = (webSocket: LdbFeWebSocket, store: Store<stateDef>) => {
  ratesfeedwebsocket = webSocket;
  store.state.ratesFeedStatus = 'normal';
};
export const onCloseRatesFeedWebsocket = (webSocket: LdbFeWebSocket, store: Store<stateDef>) => {
  store.state.ratesFeedStatus = 'disconnected';
};
//----------------------------------------------------------------------------------------------------------------------

export const handleRatesFeedMessage = (_webSocket: LdbFeWebSocket, event: LdbWebSocketEvent, store: Store<stateDef>) => {
  if (event.data !== 'AUTH FAILED') {
    const data = JSON.parse(event.data);
    if (data.type === 'connectionDowngrade') {
      // console.log('Rates Feed Downgraded');
      store.state.ratesFeedStatus = 'normal';
    } else {
      saveRates(data, store);
    }
  }
};

//----------------------------------------------------------------------------------------------------------------------

const saveRates = (rates: ratesFeedItem[], store: Store<stateDef>) => {
  for (let r = 0; r < rates.length; r++) {
    let rate = rates[r];
    if (rate.bid > 0 && rate.ask > 0) {
      saveRate(rate, store);
    }
  }
};

const saveRate = (rate: ratesFeedItem, store: Store<stateDef>) => {
  if (rate.ric !== undefined) {
    if (store.state.rates[rate.ric] === undefined) {
      store.state.rates[rate.ric] = rate as ratesFeedItem;
    } else {
      store.state.rates[rate.ric].ask = rate.ask;
      store.state.rates[rate.ric].bid = rate.bid;
      store.state.rates[rate.ric].ric = rate.ric;
      store.state.rates[rate.ric].todayFirstTickAsk = rate.todayFirstTickAsk;
      store.state.rates[rate.ric].todayFirstTickBid = rate.todayFirstTickBid;
      store.state.rates[rate.ric].todayFirstTickDay = rate.todayFirstTickDay;
      store.state.rates[rate.ric].lastTick = rate.lastTick;
    }
  }
};

//----------------------------------------------------------------------------------------------------------------------

export const upgradeRatesFeed = (store: Store<stateDef>) => {
  // console.log('Upgrading Rates Feed');
  if (store.state.ratesFeedStatus === 'normal' && ratesfeedwebsocket?.connection?.readyState === 1) {
    ratesfeedwebsocket?.connection?.send(
      JSON.stringify({
        type: 'upgrade',
      })
    );
    store.state.ratesFeedStatus = 'upgraded';
  } else {
    console.log('Could not upgrade Rates Feed');
  }
};
