<template>
  <LDBModal result="info" :header="header" size="x-large">
    <template #body>
      <div v-if="mounted">
        <p>The deal value date will be changed and deal number updated</p>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          :view="field.view"
          :fieldname="field.name"
          :record="record"
          :definition="definition"
          @change="on_change"
        />
      </div>
      <p v-if="modificationIsForAnFecWithNonFecOptionSelected">FECs can not be settled through this form. Please use the Draw Down button on the deal view.</p>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
        @on-api-success="onApiSuccess"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxDealModifyValueDate',
  components: { LDBField },
  emits: ['on-api-success'],
  props: {},
  data() {
    return {
      mounted: true,
      record: {},
    };
  },
  created() {},
  mounted() {},
  beforeUnmount() {},
  computed: {
    ...mapGetters(['config']),
    ...mapState(['deal']),
    header() {
      return `Modify Value Date`;
    },
    modificationIsForAnFecWithNonFecOptionSelected() {
      return this.dealRecord.deliveryType === 'FWD' && ['Cash','Tom','Spot'].includes(this.record.deliveryType)
    },
    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

        {
          name: 'modifyValueDate',
          label: 'Modify Value Date',
          type: 'primary',
          action: 'modifyDealValueDate',
          params: {
            transactionId: this.dealRecord.transactionFileId,
            dealId: this.dealRecord.id,
          },
          actionParams: this.record,
          guards: true,
          enabledGuards: !this.modificationIsForAnFecWithNonFecOptionSelected,
          clickEvent() {
            self.$router.replace({ name: 'transactionDealDetailsView' });
          },
        },
      ];
      return allButtons !== undefined ? allButtons : [];
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : '/';
    },

    // ----- Definition -----

    definition() {
      return getDefinition('deal');
    },

    // ----- Record -----
    recordName() {
      return 'deal';
    },
    recordId() {
      return +this.$route.params.dealId;
    },
    dealRecord() {
      return this.deal[this.recordId] || {};
    },
    currencyPair() {
      return this.config.currencyPair?.[this.dealRecord.currencyPair];
    },
    // ----- Fields -----

    fields() {
      return this.definition.fields;
    },
    view() {
      return 'modifyValueDate';
    },
    viewFields() {
      let res = [
        {
          name: 'deliveryType',
          label: 'Delivery Type',
          view: 'edit',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'Cash', name: 'Value Today', tagType: 'info' },
                { id: 'Tom', name: 'Value Tomorrow', tagType: 'success' },
                { id: 'Spot', name: 'Spot', tagType: 'info' },
                { id: 'FWD', name: 'Forward', tagType: 'warning' },
              ],
            },
          },
          displayType: {
            datatype: 'option',
            display: 'radio',
          },
          group: 'treasury',
        },
      ];
      if (!this.modificationIsForAnFecWithNonFecOptionSelected) {
        if (this.record.deliveryType) {
        res.push({
          name: 'valueDate',
          label: 'Value Date',
          view: this.record.deliveryType === 'FWD' ? 'edit' : 'item',
          datatype: {
            datetime: {
              type: 'date',
              format: 'human',
              minDate: { type: 'day', offset: 3 }, // TODO: this can be smarter based on spotDate, weekend, public holidays etc
              maxDate: { type: 'month', offset: 6 },
            },
          },
          displayType: {
            displayAsTag: true,
            tagTypeFunctionName: 'valueDateTagType',
          },
          group: 'treasury',
        });
        }
        res.push({
          name: 'dealNumber',
          label: 'Deal Number',
          view: 'edit',
          datatype: 'text',
          group: 'treasury',
        });
      }
      return res;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    on_change({ field, value }) {
      this.record[field] = value;
      if ((field = 'deliveryType')) {
        switch (value) {
          case 'Cash':
            this.record.valueDate = new Date(new Date().setHours(0, 0, 0, 0));
            break;
          case 'Tom':
            this.record.valueDate = this.currencyPair?.tomDate;
            break;
          case 'Spot':
            this.record.valueDate = this.currencyPair?.spotDate;
            break;
          case 'FWD':
            this.record.valueDate = undefined;
        }
      }
    },

    // ----- API calls -----
    async onApiSuccess(_responseObject) {},
  },
};
</script>

<style></style>
