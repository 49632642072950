<template>
  <div class="lfx-deal-summary-report">
    <capitec-content-box>
      <div>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          :view="field.view"
          :fieldname="field.name"
          :definition="undefined"
          :record="optionsRecord"
          @change="on_change"
        />
      </div>
      <capitec-action-bar>
        <LDBButton
          v-for="button in formButtons"
          :key="button.name"
          :label="button.label"
          :action="button.action"
          :actionParams="button.actionParams"
          :params="button.params"
          :type="button.type"
          :tooltip="button.tooltip"
          :modal="button.modal"
          :viewGuards="button.guards"
          :enabledGuards="button.enabledGuards"
          :onSuccess="button.onSuccess"
          @beforeClick="button.beforeClick"
          @click="button.onClick"
          @on-api-success="button.onApiSuccess"
          @on-api-error="button.onApiError"
        >
          <template v-if="button.modal !== undefined" #modal>
            <div v-html="button.modal.content"></div>
          </template>
        </LDBButton>
      </capitec-action-bar>
      <template v-if="dataStatus === 'retrieving'">
        <LDBLoader />
      </template>
      <template v-else-if="dataStatus === 'retrieved'">
        <div class="lfx-deal-summary-report-view-table-container">
          <table-lite
            id="lfx-deal-summary-report-view-table"
            :is-static-mode="true"
            :is-slot-mode="true"
            :has-checkbox="false"
            :is-loading="false"
            :is-re-search="false"
            :columns="tableColumns"
            :rows="sortedRecords"
            :pageSize="sortedRecords.length"
            :total="sortedRecords.length"
          >
            <template v-for="(col, i) of tableColumns" v-slot:[col.field]="data" :key="i">
              <template v-if="col.showAsCurrency">{{ formatCurrency(data.value[col.field]) }}</template>
              <!-- <template v-if="col.field?.amount">ZAR {{data.value[col.field].amount}}</template> -->
              <template v-else>{{ data.value[col.field] }}</template>
            </template>
          </table-lite>
        </div>
      </template>
    </capitec-content-box>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBField from '@/landobyte_vue/LDBField.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import TableLite from '@/views/general/TableLite.vue';
import { formatCurrencyString } from '@/sharedUtils/LdbSharedUtils';

export default {
  name: 'LfxDealSummaryReport',
  components: { LDBField, LDBLoader, TableLite },
  props: {},
  data() {
    return {
      selectorsUpdated: true,
      dataStatus: 'none',
      optionsRecord: {
        datePreset: 'bookedToday',
        dateType: 'capture',
        dateFrom: new Date().toISOString(),
        dateTo: new Date().toISOString(),
        dealStatuses: 'open',
      },
      reportData: undefined,
      rollUpBranches: true,
      includeNewIntermediaries: true,
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters(['currencies']),
    ...mapState(['account']),

    zarCurrency() {
      return this.currencies['ZAR']
    },
    // ----- Buttons -----

    formButtons() {
      let self = this;
      return [
        {
          name: 'generateReport',
          label: 'Generate Report',
          type: 'primary',
          action: 'dealSummaryReport',
          onSuccess: { doStoreUpdate: false },
          actionParams: this.optionsRecord,
          enabledGuards: this.selectorsUpdated === true,
          beforeClick() {
            self.dataStatus = 'retrieving';
          },
          onApiSuccess: self.onApiSuccess,
          onApiError() {
            self.dataStatus = 'error';
          },
        },
        {
          name: 'toggleRollUpBranches',
          label: this.rollUpBranches ? 'Unroll Branches' : 'Roll Up Branches',
          type: 'primary',
          action: 'click',
          guards: this.dataStatus == 'retrieved',
          onClick() {
            self.rollUpBranches = !self.rollUpBranches;
          },
        },
        {
          name: 'toggleIncludeNewIntermediaries',
          label: this.includeNewIntermediaries ? 'Exclude Inactive Inters' : 'Include Inactive Inters',
          type: 'primary',
          action: 'click',
          guards: this.dataStatus == 'retrieved' && this.showBankMarkup,
          onClick() {
            self.includeNewIntermediaries = !self.includeNewIntermediaries;
          },
        },
      ];
    },

    // ----- Fields -----

    viewFields() {
      const allFields = {
        datePresets: {
          name: 'datePreset',
          label: 'Date Preset',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'bookedToday', name: 'Booked Today' },
                { id: 'bookedYesterday', name: 'Booked Yesterday' },
                { id: 'bookedThisWeek', name: 'Booked This Week' },
                { id: 'bookedThisMonth', name: 'Booked This Month' },
                { id: 'completedLastMonth', name: 'Completed Last Month' },
                { id: 'completingThisMonth', name: 'Completing This Month' },
                { id: 'other', name: 'Other' },
              ],
            },
          },
          view: 'edit',
          guards: true,
        },
        dateType: {
          name: 'dateType',
          label: 'Date Type',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'capture', name: 'Capture Date' },
                { id: 'value', name: 'Value Date' },
              ],
            },
          },
          enableGuards: this.optionsRecord.datePreset === 'other',
          view: 'edit',
          // view: this.optionsRecord.datePreset === 'other' ? 'edit' : 'item',
          guards: true,
        },
        dateFrom: {
          name: 'dateFrom',
          label: 'Date From',
          datatype: {
            datetime: {
              type: 'date',
              format: 'human',
              minDate: '2014/01/01',
              maxDate: { type: 'day', offset: 0 },
            },
          },
          enableGuards: this.optionsRecord.datePreset === 'other',
          view: this.optionsRecord.datePreset === 'other' ? 'edit' : 'item',
          guards: true,
        },
        dateTo: {
          name: 'dateTo',
          label: 'Date To',
          datatype: {
            datetime: {
              type: 'date',
              format: 'human',
              minDate: '2014/01/01',
              maxDate: this.toMaxDate,
            },
          },
          enableGuards: this.optionsRecord.datePreset === 'other',
          view: this.optionsRecord.datePreset === 'other' ? 'edit' : 'item',
          guards: true,
        },
        dealStatuses: {
          name: 'dealStatuses',
          label: 'Deal Statuses',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'all', name: 'All Deals (including Cancelled)' },
                { id: 'open', name: 'Open and Complete Deals Only' },
              ],
            },
          },
          view: 'edit',
          guards: true,
        },
      };
      const returnFields = {};
      for (const f in allFields) {
        if (allFields[f].guards !== false) {
          returnFields[f] = allFields[f];
        }
      }
      return returnFields;
    },
    toMaxDate() {
      return this.optionsRecord.dateType === 'capture' ? { type: 'day', offset: 0 } : { type: 'year', offset: 1 };
    },

    // ----- TABLE -----
    showBankMarkup() {
      return this.$store.state.authUser.accountLevel === 'bank';
    },
    tableColumns() {
      let self = this;
      let res = [
        {
          col: 'intermediaryColumn',
          field: 'intermediaryName',
          label: 'Intermediary Name',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'branchColumn',
          field: 'branchName',
          label: 'Branch Name',
          isKey: false,
          sortable: false,
          guards: this.rollUpBranches === false,
          showAsCurrency: false,
        },
        {
          col: 'dealCountColumn',
          field: 'dealCount',
          label: 'Count',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'totalZarValueColumn',
          field: 'totalZarValue',
          label: 'ZAR Value',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: true,
        },
        {
          col: 'bankMarkupColumn',
          field: 'bankMarkup',
          label: 'Bank Markup',
          isKey: false,
          sortable: false,
          guards: this.showBankMarkup,
          showAsCurrency: true,
        },
        {
          col: 'systemMarkupColumn',
          field: 'systemMarkup',
          label: 'System Markup',
          isKey: false,
          sortable: false,
          guards: this.showBankMarkup,
          showAsCurrency: true,
        },
        {
          col: 'intermediaryMarkupColumn',
          field: 'intermediaryMarkup',
          label: 'Intermediary Markup',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: true,
        },
      ];
      return res.filter((f) => f.guards === true);
    },
    sortedRecords() {
      var res;
      if (!this.rollUpBranches) {
        res = [];
        for (const reportRow of this.reportData || []) {
          let includeRow = true;
          if (!this.includeNewIntermediaries) {
            if (this.account[reportRow.intermediaryId].status === 'new') {
              includeRow = false
            }
          }
          if (includeRow) {
            res.push(reportRow)
          }
        };
      } else {
        const interIds = [];
        for (const row of this.reportData) {
          if (!interIds.includes(row.intermediaryId)) {
            interIds.push(row.intermediaryId);
          }
        }
        res = [];
        for (const interId of interIds) {
          let includeInter = true;
          if (!this.includeNewIntermediaries) {
            if (this.account[interId].status === 'new') {
              includeInter = false
            }
          }
          if (includeInter) { 
            const interRecord = {
              intermediaryId: interId,
              intermediaryName: undefined,
              dealCount: 0,
              totalZarValue: 0,
              intermediaryMarkup: 0,
              bankMarkup: 0,
              systemMarkup: 0,
            };
            for (const row of this.reportData) {
              if (row.intermediaryId === interRecord.intermediaryId) {
                if (interRecord.intermediaryName === undefined) {
                  interRecord.intermediaryName = row.intermediaryName;
                }
                interRecord.dealCount += +row.dealCount;
                interRecord.totalZarValue += +row.totalZarValue;
                interRecord.intermediaryMarkup += +row.intermediaryMarkup;
                interRecord.bankMarkup += +row.bankMarkup || 0;
                interRecord.systemMarkup += +row.systemMarkup || 0;
              }
            }
            res.push(interRecord);
          }
        }
      }
      const totalRecord = {
        branchID: 0,
        branchName: '-',
        intermediaryId: 0,
        intermediaryName: 'Total',
        dealCount: 0,
        totalZarValue: 0,
        intermediaryMarkup: 0,
        bankMarkup: 0,
        systemMarkup: 0,
      };
      for (const row of res) {
        totalRecord.dealCount += +row.dealCount;
        totalRecord.totalZarValue += +row.totalZarValue;
        totalRecord.intermediaryMarkup += +row.intermediaryMarkup;
        totalRecord.bankMarkup += +row.bankMarkup || 0;
        totalRecord.systemMarkup += +row.systemMarkup || 0;
      }
      return [totalRecord, ...res];
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    on_change({ field, value }) {
      this.optionsRecord[field] = value;
      if (field === 'datePreset') {
        this.updateFromPreset(value);
      }
      this.selectorsUpdated = true;
    },
    onApiSuccess(response) {
      this.reportData = response.response;
      this.dataStatus = 'retrieved';
      this.selectorsUpdated = false;
    },
    updateFromPreset(newPreset) {
      const today = new Date();
      switch (newPreset) {
        case 'bookedToday':
          this.optionsRecord.dateType = 'capture';
          this.optionsRecord.dateFrom = today.toISOString();
          this.optionsRecord.dateTo = today.toISOString();
          break;
        case 'bookedYesterday':
          this.optionsRecord.dateType = 'capture';
          const previousValidTradingDate = this.zarCurrency.previousValidValueDate(today);
          this.optionsRecord.dateFrom = previousValidTradingDate.toISOString();
          this.optionsRecord.dateTo = previousValidTradingDate.toISOString();
          break;
        case 'bookedThisWeek':
          this.optionsRecord.dateType = 'capture';
          this.optionsRecord.dateFrom = new Date(
            new Date(today).setDate(today.getDate() - ((today.getDay() + 6) % 7))
          ).toISOString();
          this.optionsRecord.dateTo = today.toISOString();
          break;
        case 'bookedThisMonth':
          this.optionsRecord.dateType = 'capture';
          this.optionsRecord.dateFrom = new Date(today.getFullYear(), today.getMonth(), 1,12).toISOString();
          this.optionsRecord.dateTo = today.toISOString();
          break;
        case 'completedLastMonth':
          this.optionsRecord.dateType = 'value';
          this.optionsRecord.dateFrom = new Date(today.getFullYear(), today.getMonth() - 1, 1,12).toISOString();
          this.optionsRecord.dateTo = new Date(today.getFullYear(), today.getMonth(), 0,12).toISOString();
          break;
        case 'completingThisMonth':
          this.optionsRecord.dateType = 'value';
          this.optionsRecord.dateFrom = new Date(today.getFullYear(), today.getMonth(), 1,12).toISOString();
          this.optionsRecord.dateTo = new Date(today.getFullYear(), today.getMonth() + 1, 0,12).toISOString();
          break;
      }
    },
    formatCurrency(currencyString) {
      return formatCurrencyString(+currencyString)
    }
  },
};
</script>

<style>
.lfx-deal-summary-report-view-table-container {
  position: relative;
  min-height: 320px;
}

#lfx-deal-summary-report-view-table {
  min-width: 100%;
}

div.lfx-deal-summary-report {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
</style>
