//@ts-nocheck
import VueStore from 'vue-class-store';

import { LfxDealLogInt, LfxDealLogDef } from '@/definitions/LfxDealLogDef';

import { LfxModel, LfxDefinition } from './LfxModel';

@VueStore
class LfxDealLogMdl extends LfxDefinition {
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
export class LfxDealLog extends LfxModel {
  constructor(data, state) {
    super({definition_name:'deal_log',...data}, state);    
  }
  get displayFKLink() {
    return false
  }
}

export function Definition(context, name: string) {
  return new LfxDealLogMdl(LfxDealLogDef, LfxDealLog, context, name, {});
}
