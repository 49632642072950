<template>
  <template v-if="['none', 'retrieving'].includes(signatoriesAndContacts_retrievalStatus)">
    <LDBLoader />
    <p v-if="signatoriesAndContacts_retrievalAttemptsFailed == 0">Loading...</p>
    <p v-else>Load failed. Retrying...{{ signatoriesAndContacts_retrievalAttemptsFailed }}</p>
  </template>

  <template v-else-if="signatoriesAndContacts_retrievalStatus === 'failed'">
    <p>Failed to load Signatories after {{ signatoriesAndContacts_retrievalAttemptsFailed + 1 }} attempts.</p>
  </template>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

export default {
  name: 'LfxSignatories',
  description: 'Placeholder while loading signatories then redirect',
  components: {},
  created() {
    void this.accountRecord?.signatoriesAndContacts;

    if (this.signatoriesAndContacts_retrievalStatus === 'retrieved') {
      this.redirect();
    }
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    // ----- Account Record ----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },

    signatoriesAndContacts_retrievalStatus() {
      return this.accountRecord?.signatoriesAndContacts_retrievalStatus;
    },
    signatoriesAndContacts_retrievalAttemptsFailed() {
      return this.accountRecord?.signatoriesAndContacts_retrievalAttemptsFailed;
    },

    first_signatoryId() {
      return this.accountRecord?.first_signatoryId;
    },
  },
  watch: {
    signatoriesAndContacts_retrievalStatus(newValue, oldValue) {
      if (newValue === 'retrieved') {
        this.redirect();
      }
    },
  },
  methods: {
    redirect() {
      this.$router.replace({ name: 'accountSignatoriesList', params: { accountId: this.accountId } });

      // TODO: redirect to itemsviewlist after adding that to signatories
    },
  },
};
</script>
