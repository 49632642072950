<template>
  <div class="ldb-view-container">
   
<capitec-card>
  
  <capitec-title-bar type="primary" :label="title"></capitec-title-bar>
   <capitec-action-bar>
 
  <capitec-spacer></capitec-spacer>
  <template  v-if="useView === 'item'">
  <capitec-button type="primary" label="Edit" @click="useView = 'edit'"></capitec-button>
  </template>
  <template v-if="useView === 'edit'">
  <capitec-button  type="primary" label="Save" @click="useView = 'item'"></capitec-button>
  </template>
  
</capitec-action-bar>
  <capitec-content-box layout="vertical" halign="stretch" valign="top" gap="clear">
    <template    v-for="(field,fieldname) in viewFields" :key="fieldname" >
    <LDBField  
    :field="field" 
    :view="useView" 
    :fieldname="field.name" 
    :definition="definition" 
    :record="record" 
    />
    </template>
  </capitec-content-box>
</capitec-card>
  </div>
</template>

<script>

import LDBField from './LDBField.vue';

export default {
  components: { LDBField },
  props: {
    view:{
      type:String,
      required:true
    },
    definition: {
      type: Object,
      required: true,
    },
    record:{
      type:Object,
      required:true
    }
  },
  data() {
    return {
      useView:'item'
    };
  },
  computed: {
    title() {
      return `${this.definition.title}: ${this.record.name}`;
    },
    viewFields() {
      let res = [];
      for (let k in this.fields) {
        let field = this.fields[k];
        if (field.views[this.useView] === true) {
          field.fieldname = k;
          res.push(field)
        }
      }
      return res;
    },
    fields() {
      return this.definition.fields;
    },
  },
  methods: {
  },
};
</script>

<style>

div.ldb-view-container {
  /* border: 1px solid red; */
  /* padding:10px; */
  flex:1;
}

</style>
