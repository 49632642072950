import { Component, css, html } from '../../elements';

import '../core/Label';

/**
 * Container layer that attaches to the title area of a display zone, e.g. card, popup, etc.
 * 
 * Can also be used as a standalone bar component.
 * 
 * ```js
 * import 'platform/components/layers/TitleBar';
 * ```
 * 
 * ```html
 * <capitec-title-bar type="primary" label="Hello World">
 *   <capitec-spacer></capitec-spacer>
 *   <capitec-button label="Log Out"></capitec-button>
 * </capitec-title-bar>
 * ```
 * @prop {"default"|"clear"|"primary"|"transact"|"save"|"credit"|"insure"|String} [type="default"] - The title bar display type.
 */
export class TitleBar extends Component {

	// --------------
	// INITIALISATION
	// --------------

	/**
	 * Initialises the component.
	 *
	 * @hideconstructor
	 */
	constructor() {

		super();

		// Set the default property values.
		this.type = `default`;
		this.label = null;
	}

	// ----------
	// PROPERTIES
	// ----------

	/**
	 * Registry of all properties defined by the component.
	 * 
	 * @property {String} [type="default"] - The title bar display type:
	 * - `default` White background.
	 * - `clear` Transparent background
	 * - `primary` GlobalOne style variation background.
	 * - `transact` GlobalOne style variation background.
	 * - `save` GlobalOne style variation background.
	 * - `credit` GlobalOne style variation background.
	 * - `insure` GlobalOne style variation background.
	 * 
	 * @property {String} [label] - The label string to display.
	 */
	static get properties() {

		return {
			type: { type: String },
			label: { type: String }
		};
	}

	// -------------------
	// LIFECYCLE OVERRIDES
	// -------------------

	// n/a	

	// --------------
	// EVENT HANDLERS
	// --------------

	// n/a

	// --------------
	// PUBLIC METHODS
	// --------------

	// n/a

	// ---------------
	// PRIVATE METHODS
	// ---------------

	// n/a

	// ---------
	// RENDERING
	// ---------

	/**
	 * Generates the component stylesheet.
	 * 
	 * @returns {css} The css content of the component.
	 */
	static get styles() {

		return [
			super.styles,

			/* ---------- LAYOUT POLICY ---------- */
			css`
				:host {
					width: 100%;

					min-height: var(--theme-titlebar-min-height, 50px);

					flex-shrink: 0;
				}
			`,

			/* ---------- COMPONENT STYLING ---------- */
			css`
				:host {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;

					background: var(--theme-titlebar-background, white);
					
					border-radius: 0px;
					border-bottom: var(--theme-titlebar-border, 1px solid grey);
					box-shadow: var(--theme-titlebar-shadow, 0px 1px #000000);
					
					padding: var(--theme-titlebar-padding, 10px);

					margin-bottom: 1px;
				}
				
				:host > capitec-label {
					color: var(--theme-titlebar-font-color, white);
					
					margin-right: var(--theme-titlebar-item-gap, 10px);
				}
				
				:host > .wrapper {
					flex-grow: 1;

					display: flex;
					flex-wrap: wrap;
					flex-direction: row;
					justify-content: var(--theme-titlebar-justify-content, flex-start);
					align-items: center;

					margin: calc(var(--theme-titlebar-item-gap, 10px) / 2 * -1);
				}

				:host > .wrapper > ::slotted(*:not(capitec-spacer)) {
					margin: calc(var(--theme-titlebar-item-gap, 10px) / 2);
				}
			`,

			/* ---------- THEMES ---------- */
			css`
				:host([type=default]) {
					background: var(--theme-type-default-background, white);
					--theme-label-font-color: var(--theme-type-default-font-color, black);
				}

				:host([type=default]) > capitec-label {
					color: var(--theme-type-default-font-color, black);
				}

				:host([type=clear]) {
					background: var(--theme-type-clear-background, white);
					--theme-label-font-color: var(--theme-type-clear-font-color, black);
				}

				:host([type=clear]) > capitec-label {
					color: var(--theme-type-clear-font-color, black);
				}

				:host([type=primary]) {
					background: var(--theme-type-primary-background, blue);
					--theme-label-font-color: var(--theme-type-primary-font-color, white);
				}

				:host([type=primary]) > capitec-label {
					color: var(--theme-type-primary-font-color, white);
				}
				
				:host([type=transact]) {
					background: var(--theme-type-transact-background, lightblue);
					--theme-label-font-color: var(--theme-type-transact-font-color, white);
				}

				:host([type=transact]) > capitec-label {
					color: var(--theme-type-transact-font-color, white);
				}

				:host([type=save]) {
					background: var(--theme-type-save-background, darkblue);
					--theme-label-font-color: var(--theme-type-save-font-color, white);
				}

				:host([type=save]) > capitec-label {
					color: var(--theme-type-save-font-color, white);
				}

				:host([type=insure]) {
					background: var(--theme-type-insure-background, darkgreen);
					--theme-label-font-color: var(--theme-type-insure-font-color, white);
				}

				:host([type=insure]) > capitec-label {
					color: var(--theme-type-insure-font-color, white);
				}

				:host([type=credit]) {
					background: var(--theme-type-credit-background, darkred);
					--theme-label-font-color: var(--theme-type-credit-font-color, white);
				}

				:host([type=credit]) > capitec-label {
					color: var(--theme-type-credit-font-color, white);
				}
			`
		];
	}

	/**
	 * Generates the component template for mobile mode.
	 * 
	 * @returns {html} The html content of the component.
	 */
	_mobileTemplate() {

		return this._webTemplate();
	}

	/**
	 * Generates the component template for kiosk mode.
	 * 
	 * @returns {html} The html content of the component.
	 */
	_kioskTemplate() {

		return this._webTemplate();
	}

	/**
	 * Generates the component template for web mode.
	 * 
	 * @returns {html} The html content of the component.
	 */
	_webTemplate() {

		return html`
			${this.label ? html`<capitec-label type="title" label="${this.label}"></capitec-label>` : null}
			<div class="wrapper">
				<slot></slot>
			</div>
		`;
	}
}

window.customElements.define(`capitec-title-bar`, TitleBar);
