//@ts-nocheck
type DBGuardOperator =
  | boolean
  | { in: DBGuardValue[] }
  | { notIn: DBGuardValue[] }
  | { eq: DBGuardValue }
  | { notEq: DBGuardValue }
  | { gt: DBGuardValue }
  | { lt: DBGuardValue }
  | { gteq: DBGuardValue }
  | { lteq: DBGuardValue };
type DBGuardValue = number | string | string[] | boolean | ((record: DBObject) => boolean) | { field: string };
export type DBGuard = boolean | { [field: string]: DBGuardOperator | string };
interface DBObject {
  id: number;
}

type DBField = {
  name: string;
  guards: DBGuard;
};

export type DBUnguardedFields = {
  [field: string]: boolean;
};

export function normalize_guards(guards: DBGuard, record: DBObject) {
  if (guards === true) {
    return true;
  } else if (guards === undefined) {
    return true;
  } else {
    let res = {};
    for (let g in guards) {
      let guard = guards[g];
      if (typeof guard === 'function') {
        let normalizedGuard = guard(record);
        res[g] = normalizedGuard;
      } else {
        res[g] = guard;
      }
    }
    return res;
  }
}

export function evaluate_guard(guards: DBGuard, record: DBObject) {
  if (guards === true || !record.guarded_fields) {
    return true;
  } else {
    let unguardedFields: DBUnguardedFields = {};
    for (let f = 0; f < record.guarded_fields.length; f++) {
      let field = record.guarded_fields[f];
      unguardedFields[field.name] = true;
    }
    return evaluate_guards(guards, record, unguardedFields);
  }
}

export function guard_fields(fields: DBField[], record: DBObject) {
  let res = [];
  let unguardedFields: DBUnguardedFields = {};
  for (let f = 0; f < fields.length; f++) {
    let field = fields[f];
    unguardedFields[field.name] = true;
  }
  do_guard_fields(fields, record, unguardedFields);
  for (let f = 0; f < fields.length; f++) {
    let field = fields[f];
    if (unguardedFields[field.name] === true) {
      res.push(field);
    }
  }
  return res;
}

function do_guard_fields(fields: DBField[], record: DBObject, unguardedFields: DBUnguardedFields) {
  for (let f = 0; f < fields.length; f++) {
    let field = fields[f];
    if (field.guards !== undefined) {
      if (evaluate_guards(field.guards, record, unguardedFields)) {
        unguardedFields[field.name] = true;
      } else {
        delete unguardedFields[field.name];
      }
    }
  }
}

function fieldIsVisible(field: string, unguardedFields: DBUnguardedFields) {
  return unguardedFields[field] === true;
}
function evaluate_object_guard(field: string, guard: DBGuard, record: DBObject, unguardedFields: DBUnguardedFields) {
  if (!fieldIsVisible(field, unguardedFields)) {
    return false;
  }

  let operator = Object.keys(guard)[0];

  let compare = guard[operator];
  let value = record[field];
  let res = false;
  if (operator === 'in') {
    res = compare.indexOf(value) >= 0;
  } else if (operator === 'notIn') {
    res = compare.indexOf(value) === -1;
  } else if (operator === 'eq') {
    res = compare === value;
  } else if (operator === 'notEq') {
    res = compare !== value;
  } else if (operator === 'gt') {
    res = value > compare;
  } else if (operator === 'between') {
    res = value > compare[0] && value < compare[1];
  } else if (operator === 'lt') {
    res = value < compare;
  } else if (operator === 'gteq') {
    res = value >= compare;
  } else if (operator === 'lteq') {
    res = value <= compare;
  } else {
    res = false;
  }
  return res;
}

export function evaluate_simple_guards(guards: DBGuard, params: object) {
  let res = true;
  if (typeof guards === 'boolean') {
    res = guards;
  } else {
    let unguardedFields = {};
    for (let g in guards) {
      unguardedFields[g] = true;
    }
    res = evaluate_guards(guards, params, unguardedFields);
  }
  return res;
}

export function evaluate_guards(guards: DBGuard, record: DBObject, unguardedFields: DBUnguardedFields) {
  let res = true;
  if (typeof guards === 'boolean') {
    res = guards;
  } else
    for (let f in guards) {
      if (res === false) {
        break;
      }
      let guard = guards[f];
      if (typeof guard === 'boolean') {
        res = res && guard;
      } else if (typeof guard === 'string') {
        //@ts-ignore
        res = res && record[guard];
        // res = res && (record[f] === guard)
      } else if (typeof guard === 'object') {
        let thisRes = evaluate_object_guard(f, guard, record, unguardedFields);
        res = res && thisRes;
      }
    }
  return res;
}

// const test_guard:DBGuard = {
//   name:{in:['sannie']},
//   surname:'van der Merwe',
//   age:{gt:20},
//   available:true,
//   status:'online'
// }

const test_fields = [
  { name: 'id' },
  { name: 'online_since', guards: { status: { eq: 'online' } } },
  { name: 'status', guards: { age: { gt: 20 } } },
  { name: 'age' },
  { name: 'name' },
  { name: 'surname' },
  { name: 'available', guards: { age: { gt: 25 }, status: { eq: 'online' } } },
];

const test_rec = {
  id: 1,
  name: 'sannie',
  surname: 'van der Merwe',
  age: 20,
  status: 'offline',
  online_since: 'Yestarday',
};

window.test_guards = function () {
  return guard_fields(test_fields, test_rec);
};
