<template>
  <LFXContent
    :titleBar="titleBar"
    :show_tabs="showTabs"
    :tabs="tabs"
    :show_stepper="showStepper"
    :show_label="showLabel"
    :label="contentLabel"
    :show_actions="showActions"
    :show_banners="showBanners"
    :form_layout="formLayout"
    :show_sidebar="false"
  >
    <template #actions>
      <LDBButton
        v-for="button in actionBarButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>

    <template #stepper
      ><capitec-stepper
        layout="horizontal"
        :steps="steps"
        :active-step-number="activeStepNumber"
        @step-click="onStepClick"
      >
      </capitec-stepper
    ></template>

    <template #banners>
      <LfxBanner
        v-for="banner in banners"
        :key="banner.id"
        :title="banner.title"
        :status="banner.status"
        :closable="banner.closable"
        ><div v-html="banner.content"></div
      ></LfxBanner>
    </template>

    <template #content>
      <router-view @step-back="stepBack" @step-next="stepNext" @allow-review="doAllowReview" @update-currency-pair="updateCurrencyPair" @update-markup-object="updateMarkupObject" @update-delivery-type="updateDeliveryType"></router-view>
    </template>
  </LFXContent>

  <router-view name="modal"></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXContent from '@/components/layout/LfxContent.vue';

export default {
  name: 'LfxNewTransaction',
  components: { LFXContent },
  data() {
    return {
      allowReview: false,
      currencyPair: undefined,
      markupLoaded: false,
      totalIntermediaryMarkup: undefined,
      deliveryType: undefined,
    };
  },
  computed: {
    ...mapGetters(['permissions','activeCurrencyPairs',]),
    ...mapState(['account', 'transaction', 'authUser']),

    // ----- Title Bar -----

    titleBar() {
      const titleBar = {
        icon: this.$route.meta?.icon,
        label: this.titleBarLabel,
        buttons: this.titleBarButtons,
      };
      return titleBar;
    },

    titleBarLabel() {
      // return `${this.accountLabel}: ${this.accountName} > ${this.$route.meta?.titleBar?.label}`;
      return this.transactionId
        ? `${this.accountName} / ${this.transactionTypeLabel}: ${this.transactionReference} / ${this.$route.meta?.titleBar?.label}`
        : `${this.accountName} / ${this.$route.meta?.titleBar?.label}`;
    },
    transactionTypeLabel() {
      let options = {
        outwardDeal: 'Outward Deal',
        transferDeal: 'Transfer Deal',
        inwardDeal: 'Inward Deal',
        outwardPayment: 'Outward Payment',
        inwardPayment: 'Inward Payment',
      };
      return options[this.transactionRecord?.transactionType];
    },
    transactionReference() {
      return this.transactionRecord?.reference;
    },

    titleBarButtons() {
      let allButtons = [
        {
          name: 'goToAccount',
          label: `Go To ${this.accountLabel}`,
          type: 'white',
          routeName: 'account',
          guards: true,
        },
        {
          name: 'backToTransaction',
          label: `Back To Transaction`,
          type: 'white',
          routeName: 'transaction',
          guards: this.transactionId ? true : false,
        },
      ];
      return allButtons.filter((button) => button.guards);
    },

    // ----- Tabs -----

    showTabs() {
      return false;
    },
    tabs() {
      return [];
    },

    // ----- Stepper -----

    showStepper() {
      return true;
    },
    steps() {
      let steps = [
        //
        // Inward Deal

        {
          stepName: 'captureInwardDeal',
          routeName: 'newTransactionInwardDealCapture',
          guards: this.$route.meta?.content?.transactionType === 'inwardDeal',
          isError: false, // TODO
          title: 'Capture Deal',
        },
        {
          stepName: 'reviewInwardDeal',
          routeName: 'newTransactionInwardDealReview',
          guards: this.$route.meta?.content?.transactionType === 'inwardDeal',
          isError: false,
          title: 'Review and Submit',
        },

        // Transfer Deal

        {
          stepName: 'captureTransferDeal',
          routeName: 'newTransactionTransferDealCapture',
          guards: this.$route.meta?.content?.transactionType === 'transferDeal',
          isError: false, // TODO
          title: 'Capture Deal',
        },
        {
          stepName: 'reviewTransferDeal',
          routeName: 'newTransactionTransferDealReview',
          guards: this.$route.meta?.content?.transactionType === 'transferDeal',
          isError: false,
          title: 'Review and Submit',
        },

        // Outward Deal

        {
          stepName: 'captureOutwardDeal',
          routeName: 'newTransactionOutwardDealCapture',
          guards: this.$route.meta?.content?.transactionType === 'outwardDeal',
          isError: false, // TODO
          title: 'Capture Deal',
        },
        {
          stepName: 'reviewOutwardDeal',
          routeName: 'newTransactionOutwardDealReview',
          guards: this.$route.meta?.content?.transactionType === 'outwardDeal',
          isError: false,
          title: 'Review and Submit',
        },

        // Add Deal

        {
          stepName: 'captureAddDeal',
          routeName: 'addDealToTransactionFileCapture',
          guards: this.$route.meta?.content?.transactionType === 'addDeal',
          isError: false, // TODO
          title: 'Capture Deal',
        },
        {
          stepName: 'reviewAddDeal',
          routeName: 'addDealToTransactionFileReview',
          guards: this.$route.meta?.content?.transactionType === 'addDeal',
          isError: false,
          title: 'Review and Submit',
        },

        //  Offline Deal

        {
          stepName: 'captureOfflineDeal',
          routeName: 'newTransactionOfflineDealCapture',
          guards: this.$route.meta?.content?.transactionType === 'offlineDeal',
          isError: !this.allowReview,
          title: 'Capture',
        },
        {
          stepName: 'reviewOfflineDeal',
          routeName: 'newTransactionOfflineDealReview',
          guards: this.$route.meta?.content?.transactionType === 'offlineDeal',
          isError: false,
          title: 'Review',
        },
      ];
      return steps.filter((step) => step.guards);
    },

    routeStepName() {
      return this.$route.meta?.content?.stepName;
    },
    activeStepNumber() {
      let activeStepIndex = this.steps.findIndex((step) => step.stepName === this.routeStepName);
      let activeStepNumber = activeStepIndex + 1; // stepnumber starts at 1
      return activeStepNumber;
    },

    // ----- Action Bar -----

    showActions() {
      return this.actionBarButtons.length !== 0 ? true : false;
    },
    actionBarButtons() {
      let self = this;
      let allButtons = {
        dealCapture: [
          {
            name: 'editClientAccountFecMarkup',
            label: 'Edit Client FEC Markup',
            type: '',
            guards: this.permissions?.createAccountMarkup && this.clientIsNotIntermediary && !this.currencyPairRecord?.parityPair && this.deliveryType === 'FWD',
            action: 'click',
            enabledGuards: !!this.currencyPair,
            clickEvent() {
              let routeName;
              switch (self.transactionType) {
                case 'inwardDeal':
                  routeName = 'newTransactionInwardDealUpdateFecMarkup';
                  break;
                case 'outwardDeal':
                  routeName = 'newTransactionOutwardDealUpdateFecMarkup';
                  break;
                case 'transferDeal':
                  routeName = 'newTransactionTransferDealUpdateFecMarkup';
                  break;
                case 'addDeal':
                  routeName = 'addDealToTransactionFileUpdateFecMarkup';
                  break;
                default:
                  routeName = 'NotFound';
                  break;
              }
              self.$router.push({ name: routeName, params: {currencyPairId:self.currencyPair }});
            },
          },
          {
            name: 'editClientAccountMarkup',
            label: 'Edit Client Account Markup',
            type: '',
            guards: this.permissions?.createAccountMarkup && this.clientIsNotIntermediary && !this.currencyPairRecord?.parityPair,
            action: 'click',
            enabledGuards: !!this.currencyPair,
            clickEvent() {
              let routeName;
              switch (self.transactionType) {
                case 'inwardDeal':
                  routeName = 'newTransactionInwardDealUpdateMarkup';
                  break;
                case 'outwardDeal':
                  routeName = 'newTransactionOutwardDealUpdateMarkup';
                  break;
                case 'transferDeal':
                  routeName = 'newTransactionTransferDealUpdateMarkup';
                  break;
                case 'addDeal':
                  routeName = 'addDealToTransactionFileUpdateMarkup';
                  break;
                default:
                  routeName = 'NotFound';
                  break;
              }
              self.$router.push({ name: routeName, params: {currencyPairId:self.currencyPair }});
            },
          },
        ],
        dealReview: [
          {
            name: 'editOpenDealLimit',
            label: 'Edit Open Deal Limit',
            type: '',
            guards: this.permissions?.editAccount,
            action: 'click',
            clickEvent() {
              let routeName;
              switch (self.transactionType) {
                case 'inwardDeal':
                  routeName = 'newTransactionInwardDealReviewEditLimit';
                  break;
                case 'outwardDeal':
                  routeName = 'newTransactionOutwardDealReviewEditLimit';
                  break;
                case 'transferDeal':
                  routeName = 'newTransactionTransferDealReviewEditLimit';
                  break;
                case 'addDeal':
                  routeName = 'addDealToTransactionFileReviewEditLimit';
                  break;
                default:
                  routeName = 'NotFound';
                  break;
              }
              self.$router.push({ name: routeName });
            },
          },
        ],
      };
      return allButtons[this.actionGroup] !== undefined
        ? allButtons[this.actionGroup].filter((button) => button.guards)
        : [];
    },

    actionGroup() {
      return this.$route.meta?.content?.actionGroup;
    },

    // ----- Layout -----

    formLayout() {
      return this.$route.meta?.layout === 'list' ? false : true;
    },

    // ----- Content Label -----

    showLabel() {
      return this.contentLabel ? true : false;
    },
    contentLabel() {
      return this.$route.meta?.content?.label || 'TODO';
    },

    // ----- Banners -----

    showBanners() {
      return this.banners?.length > 0 || false;
    },
    banners() {
      let allBanners = {
        all: [
          {
            id: `noInterMarkupLoaded-${this.currencyPair}`,
            title: 'No Intermediary Markup Captured',
            status: this.clientHasMarkup ? 'info' : 'warning',
            closable: false,
            content: `The total intermediary markup for this client and currency pair is 0. ${ this.clientHasMarkup ? `The client's markup is set to 0.` : `No markup has been captured for this client.` }` ,
            guards: this.markupLoaded && (this.totalIntermediaryMarkup === 0 || this.totalIntermediaryMarkup === 0) && this.authUser.accountLevel !== 'client' && this.clientIsNotIntermediary && !this.currencyPairRecord?.parityPair,
          },
          {
            id: `clientIsIntermediaryWiithMarkup-${this.currencyPair}`,
            title: 'Client has same CIF as Intermediary',
            status: 'error',
            closable: false,
            content: `This client has the same CIF as its intermediary. It may not have an intermediary markup configured. Please contact support@landobyte.com to have this rectified.` ,
            guards: this.markupLoaded && (this.totalIntermediaryMarkup !== 0) && !this.clientIsNotIntermediary,
          }
        ]
        };
        const availableBanners = [...(allBanners[this.actionGroup] || []), ...allBanners.all];
        return availableBanners.filter((banner) => banner.guards);
      },
      clientHasMarkup() {
        if (this.markupLoaded && (this.totalIntermediaryMarkup === 0 || this.totalIntermediaryMarkup === 0)) {
          for (const markupId in this.accountRecord?.markup || {}) {
            if (this.accountRecord.markup[markupId].currencyPair === this.currencyPair) {
              return true
            }
        }
        return false
        }
        return undefined
      },

    clientIsNotIntermediary() {
      if (this.accountRecord?.accountLevel !== 'client') {
        return true
      }
      const clientCif = this.accountRecord.cifNumber
      const intermediaryCifNumber =  this.account?.[this.accountRecord.intermediaryId]?.cifNumber;
      return !clientCif || !intermediaryCifNumber || clientCif!== intermediaryCifNumber
    },

    // ----- Account Record -----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account[this.accountId];
    },

    accountLevel() {
      return this.accountRecord.accountLevel;
    },
    accountName() {
      return this.accountRecord.name;
    },
    accountLabel() {
      if (this.accountLevel === 'bank') {
        return 'Bank';
      } else if (this.accountLevel === 'intermediary') {
        return 'Intermediary';
      } else if (this.accountLevel === 'intermediaryBranch') {
        return 'Branch';
      } else if (this.accountLevel === 'client') {
        return 'Client';
      } else {
        return 'Account';
      }
    },

    // ----- Transaction Record -----

    transactionId() {
      return +this.$route.params?.transactionId;
    },
    transactionRecord() {
      return this.transaction?.[this.transactionId];
    },

    // ----- Transaction -----

    transactionType() {
      return this.$route.meta?.content?.transactionType;
    },

    currencyPairRecord() {
      return this.activeCurrencyPairs?.[this.currencyPair];
    }
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),

    // ----- Stepper -----

    // onBackClick() {
    //   let newStepNumber = this.activeStepNumber - 1;
    //   let evt = { detail: { stepNumber: newStepNumber } };
    //   this.onStepClick(evt);
    // },
    // onStepClick(evt) {
    //   this.activeStepNumber = evt.detail.stepNumber;
    // },

    // selectFirstStep() {
    //   this.$router.replace({ name: this.steps[0].routeName });
    // },

    onStepClick(evt) {
      if (this.allowReview) {
        let newStepNumber = evt.detail.stepNumber;
        let newStepNumberIndex = newStepNumber - 1; // stepnumber starts at 1
        let newStep = this.steps[newStepNumberIndex];
        this.$router.replace({ name: newStep.routeName });
      }
    },
    stepBack() {
      let newStepNumber = this.activeStepNumber - 1;
      let newStepNumberIndex = newStepNumber - 1; // stepnumber starts at 1
      let newStep = this.steps[newStepNumberIndex];
      this.$router.replace({ name: newStep.routeName });
    },
    stepNext() {
      let newStepNumber = this.activeStepNumber + 1;
      let newStepNumberIndex = newStepNumber - 1; // stepnumber starts at 1
      let newStep = this.steps[newStepNumberIndex];
      this.$router.replace({ name: newStep.routeName });
    },
    updateCurrencyPair(value) {
      this.currencyPair = value
    },
    updateDeliveryType(value) {
      this.deliveryType = value
    },
    updateMarkupObject(value) {
      if (value) {
        this.markupLoaded = true
        this.totalIntermediaryMarkup = value.interPips;
      } else {
        this.markupLoaded = false
        this.totalIntermediaryMarkup = undefined;
      }
    },
    doAllowReview() {
      this.allowReview = true;
    },
  },
};
</script>
