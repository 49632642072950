<template>
  <div class="lfx-forward-reval-report">
    <capitec-content-box>
      <LFXListView :definition="definition" :records="records" />
    </capitec-content-box>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXContent from '@/components/layout/LfxContent.vue';
import LFXListView from '@/views/general/LFXListView.vue';

export default {
  name: 'LfxDealValueDateChangeReport',
  components: { LFXListView },
  props: {},
  data() {
    return {
    };
  },
  async created() {
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['deal']),

    titleBar() {
      const titleBar = {
        icon: this.titleBarIcon,
        label: this.titleBarLabel,
        buttons: this.titleBarButtons,
      };
      return titleBar;
    },

    baseRecords() {
      let baseRecords =  this.deal;
      return baseRecords
    },
    records() {
      const records = {};
      const statusses = ['requested','processed','booked','released'];
      for (const dealId in this.baseRecords) {
        const deal = this.baseRecords[dealId];
        if (statusses.includes(deal.status) && (new Date(deal.originalValueDate)).getTime() !== (new Date(deal.valueDate).getTime())) {
          records[dealId] = this.baseRecords[dealId]
        }
      }
      return records
    },
    definitionName() {return 'deal'},
    definition() {
      return getDefinition(this.definitionName);
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>

<style>
/* div.container {
} */
</style>
