<template>
  <LFXContent
    :titleBar="titleBar"
    :show_tabs="showTabs"
    :tabs="tabs"
    :show_stepper="showStepper"
    :show_label="showLabel"
    :label="contentLabel"
    :show_actions="showActions"
    :show_banners="showBanners"
    :form_layout="formLayout"
    :show_sidebar="false"
  >
    <template #actions>
      <LDBButton
        v-for="button in actionBarButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
    <template #banners>
      <LfxBanner
        v-for="banner in banners"
        :key="banner.id"
        :title="banner.title"
        :status="banner.status"
        :closable="banner.closable"
        ><div v-html="banner.content"></div
      ></LfxBanner>
    </template>

    <template #stepper
      ><capitec-stepper
        layout="horizontal"
        :steps="steps"
        :active-step-number="activeStepNumber"
        @step-click="onStepClick"
      >
      </capitec-stepper
    ></template>

    <template #content>
      <router-view
        @on-back="onBack"
        @step-back="onBack"
        @on-next="onNext"
        @step-next="onNext"
        :allowModification="allowModificationOnForms"
      ></router-view>
      <router-view
        name="associatedCifs"
        :key="cifId || undefined"
        @on-back="onBack"
        @on-next="onNext"
        :allowModification="allowModificationOnForms"
      ></router-view>
      <router-view
        name="documents"
        :key="applicationDocumentId || undefined"
        @on-back="onBack"
        @on-next="onNext"
        :allowModification="allowModificationOnForms"
      ></router-view>
    </template>
  </LFXContent>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXContent from '@/components/layout/LfxContent.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxClientApplicationWizard',
  components: { LFXContent, LDBLoader },
  props: {},
  data() {
    return {};
  },
  created() {
    // if (!this.applicationRecord?.isDataComplete) {
    //   await doPermissionAction(this, 'getApplication', { accountId: this.clientId, applicationId: this.applicationId });
    // }
    doPermissionAction(this, 'getClientApplicationDocuments', {
      accountId: this.clientId,
      applicationId: this.applicationId,
    });
    if (this.$route.name === 'clientApplicationWizard') {
      this.selectFirstStep();
    }
  },
  mounted() {},
  // beforeRouteUpdate(to, from, next) {
  //   // if (to.name === 'clientApplication' && to.params.applicationId === from.params.applicationId) {
  //   //   next(false);
  //   //   this.selectFirstTab();
  //   // } else {
  //   //   next();
  //   // }
  // },
  computed: {
    ...mapGetters(['permissions', 'isDevelopment', 'sdlc']),
    ...mapState(['authUser','account', 'systemSettings']),

    // hasLoaded() {
    //   return this.applicationRecord?.isDataComplete;
    // },

    // ----- Title Bar -----

    titleBar() {
      const titleBar = {
        label: this.titleBarLabel,
        buttons: [
          {
            name: 'exitWizard',
            label: 'Exit Wizard',
            type: 'white',
            routeName: 'clientApplication',
            routeParams: { accountId: this.clientId, applicationId: this.applicationId },
          },
          {
            name: 'backToClient',
            label: 'Back to Client',
            type: 'white',
            routeName: 'accountDetailsView',
            routeParams: { accountId: this.clientId },
            guards: true,
          },
        ],
      };
      return titleBar;
    },
    titleBarLabel() {
      // return `Client: ${this.clientRecord.name} > ${this.applicationTypeLabel} Wizard`;
      return `${this.clientRecord.name} / ${this.applicationTypeLabel} Wizard: ${this.applicationRecord.reference}`;
    },

    applicationTypeLabel() {
      switch (this.applicationRecord.applicationType) {
        case 'newClient':
          return 'New Client Application';
        case 'newAccount':
          return 'New Bank Account Application';
        case 'amendClient':
          return 'Amend Client Application';
        case 'amendAccount':
          return 'Amend Bank Account Application';
        default:
          return 'Application';
      }
    },

    // ----- Tabs -----

    showTabs() {
      return false;
    },
    tabs() {
      return [];
    },

    // ----- Stepper -----

    showStepper() {
      return true;
    },
    steps() {
      let steps = [
        {
          stepName: 'clientCif',
          routeName: 'clientApplicationWizardClientCifDetailsEdit',
          routeParams: { cifId: this.fullCifId },
          isError: !this.mainCifIsComplete || !this.mainCifIsSaved,
          title: 'Client Details',
          guards: true,
        },
        // {
        //   stepName: 'associatedCifs',
        //   routeName: this.applicationRecord?.hasActiveAssociatedCifs
        //     ? 'clientApplicationWizardAssociatedCifDetailsEdit'
        //     : 'clientApplicationWizardNoAssociatedCifs',
        //   routeParams: this.applicationRecord?.hasActiveAssociatedCifs ? { cifId: this.firstAssCifId } : undefined,
        //   isError: !this.assocCifsAreComplete || !this.assocCifsAreSaved,
        //   title: 'Associated Parties',
        //   guards: true,
        // },
        {
          stepName: 'associatedCifs',
          routeName: this.applicationRecord?.hasAssociatedCifs
            ? 'clientApplicationWizardAssociatedCifDetailsEdit'
            : 'clientApplicationWizardNoAssociatedCifs',
          routeParams: this.applicationRecord?.hasAssociatedCifs ? { cifId: this.firstAssCifId } : undefined,
          isError: !this.assocCifsAreComplete || !this.assocCifsAreSaved,
          title: 'Associated Parties',
          guards: true,
        },
        {
          stepName: 'bankAccounts',
          routeName: 'clientApplicationWizardBankAccountDetailsEdit',
          routeParams: { applicationAccountId: this.firstBankAccountApplicationId },
          isError: !this.bankAccountsAreComplete || !this.bankAccountssAreSaved,
          title: 'Bank Account',
          guards: true,
        },
        {
          stepName: 'signatories',
          routeName: 'clientApplicationWizardSignatories',
          routeParams: undefined,
          isError: this.applicationRecord?.signatoryOrder?.length > 0 ? false : true, // TODO check if signatories are 'complete', e.g. has email, cellphone etc
          title: 'Signatories',
          guards: this.applicationRecord?.thisApplicationDocumentUploadType !== 'local',
        },
        {
          stepName: 'uploadDocuments',
          routeName: 'clientApplicationWizardUploadDocuments',
          routeParams: { applicationDocumentId: this.firstDocumentId },
          isError: this.applicationRecord?.outstandingDocumentTypes?.length == 0 ? false : true,
          title: this.applicationRecord?.thisApplicationDocumentUploadType === 'signiFlow'? 'EDD Documents':'Documents',
          guards: this.applicationRecord?.thisApplicationDocumentUploadType === 'local' || this.applicationRecord.craStatus === 'eddRequested',
        },
        {
          stepName: 'portfolioDocuments',
          routeName: 'clientApplicationWizardPortfolioDocuments',
          routeParams: {},
          isError: !this.applicationRecord.documents, //|| this.applicationRecord.documents.length === 0 ? true : false, //There are valid scenarios with no additional required documents
          title: 'Documents',
          guards: this.applicationRecord?.thisApplicationDocumentUploadType === 'signiFlow',
        },
        {
          stepName: 'reviewAndSubmit',
          routeName: 'clientApplicationWizardReview', // TODO
          routeParams: undefined, // TODO
          isError: false, // TODO
          title: 'Review and Submit', // TODO make dynamic
          guards: true,
        },
      ];

      return steps.filter((step) => step.guards); // TODO
    },

    routeStepName() {
      return this.$route.meta?.content?.stepName;
    },
    activeStepNumber() {
      let activeStepIndex = this.steps.findIndex((step) => step.stepName === this.routeStepName);
      let activeStepNumber = activeStepIndex + 1; // stepNumber starts at 1
      return activeStepNumber;
    },

    // ----- Content Label -----

    showLabel() {
      return this.contentLabel ? true : false;
    },
    contentLabel() {
      return this.$route.meta?.content?.label || "Navigation didn't complete, please click tab above to continue";
    },

    // ----- Step Is Error -----

    mainCifIsComplete() {
      return this.fullCifRecord?.is_submittable;
    },
    mainCifIsSaved() {
      return this.fullCifRecord?.info_changed_fields?.length === 0;
    },
    assocCifsAreComplete() {
      // for (const cifId in this.applicationRecord?.application_cif || {}) {
      for (const cifId in this.applicationRecord?.activeAssociatedCifs || {}) {
        // if (!this.applicationRecord?.application_cif[cifId]?.is_submittable) {
        if (!this.applicationRecord?.activeAssociatedCifs[cifId]?.is_submittable) {
          return false;
        }
      }
      return true;
    },
    assocCifsAreSaved() {
      // for (const cifId in this.applicationRecord?.application_cif || {}) {
      for (const cifId in this.applicationRecord?.activeAssociatedCifs || {}) {
        // if (!(this.applicationRecord?.application_cif[cifId]?.info_changed_fields?.length === 0)) {
        if (!(this.applicationRecord?.activeAssociatedCifs[cifId]?.info_changed_fields?.length === 0)) {
          return false;
        }
      }
      return true;
    },
    bankAccountsAreComplete() {
      for (const bankAccountId in this.applicationRecord?.application_account || {}) {
        if (!this.applicationRecord?.application_account[bankAccountId]?.is_submittable) {
          return false;
        }
      }
      return true;
    },
    bankAccountssAreSaved() {
      for (const bankAccountId in this.applicationRecord?.application_account || {}) {
        if (!(this.applicationRecord?.application_account[bankAccountId]?.changed_fields?.length === 0)) {
          return false;
        }
      }
      return true;
    },
    // ----- Action Bar -----

    showActions() {
      return this.actionBarButtons.length !== 0 ? true : false;
    },

    addAssociatedCifButton() {
      let self = this;
      return {
        name: 'addAssociatedCif',
        label: 'Add Associated Party',
        type: this.cifRecord?.cifType === 'entity' && !this.applicationRecord?.hasActiveAssociatedCifs ? 'primary' : '', // TODO test - make dynamic for main cif application = entity | individual
        guards: this.applicationRecord?.applicationIsEditable && this.$route.meta?.content?.cifType === 'associated',
        clickEvent() {
          let routeName = self.applicationRecord?.hasActiveAssociatedCifs
            ? 'clientApplicationWizardAssociatedCifDetailsEditAdd'
            : 'clientApplicationWizardNoAssociatedCifsAdd';
          self.$router.push({ name: routeName });
        },
      };
    },
    cancelCifButton() {
      let self = this;
      return {
        name: 'cancelCif',
        label: 'Cancel Associated Party',
        type: '',
        params: { accountId: this.clientId, applicationId: this.applicationId, cifId: this.cifId },
        guards:
          this.applicationRecord?.applicationIsEditable &&
          ['new', 'error'].includes(this.cifRecord?.status) &&
          this.cifRecord?.cifType === 'associated' &&
          !!this.permissions.cancelCifApplication,
        clickEvent() {
          self.$router.push({ name: 'clientApplicationWizardAssociatedCifDetailsEditCancel' });
        },
      };
    },

    actionBarButtons() {
      let self = this;
      let allButtons = {
        //

        // CIF Details (Full or Associated)

        clientApplicationWizard_CifDetailsEdit: [this.addAssociatedCifButton, this.cancelCifButton],

        clientApplicationWizardNoAssociatedCifs: [this.addAssociatedCifButton],

        // ------------------------------------------------------------------ old ------------------------

        // Bank Account Details Edit

        clientApplicationBankAccountDetailsEdit: [
          {
            name: 'edit_bankAccountApplicationHistory',
            label: 'View History',
            type: '',
            guards: true, //TODO
            clickEvent() {
              self.$router.push({ name: 'clientApplicationBankAccountDetailsEditHistory' });
            },
          },
        ],

        // Signatories

        clientApplicationWizardSignatories: [
          {
            name: 'addSignatory',
            label: 'Add Signatory',
            type: '',
            guards: !!this.applicationRecord?.applicationIsEditable && !!this.permissions.createSignatory,
            clickEvent() {
              self.$router.push({ name: 'clientApplicationWizardAddSignatory' });
            },
          },
        ],
        // Documents
        clientApplicationUploadDocuments: [
          {
            name: 'cancelDocument',
            label: 'Cancel Document',
            action: 'cancelApplicationDocument',
            params: {
              accountId: this.clientId,
              applicationId: this.applicationId,
              applicationDocumentId: this.applicationDocumentRecord.id,
            },
            modal: {
              header: `Cancel Application Document`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Submit',
              content: this.cancelApplicationDocumentModalMessage,
            },
            type: '',
            guards:
              this.applicationRecord.applicationIsEditable && this.applicationDocumentRecord.status !== 'cancelled',
          },

          {
            name: 'submitCraEddButton',
            label: `Submit CRA EDD`,
            type: 'primary',
            modal: {
              header: `Submit CRA EDD`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Submit',
              content: this.submitCifCraEddModalMessage,
            },
            action: 'submitCraEdd',
            params: { accountId: this.clientId, applicationId: this.applicationId, cifId: this.fullCifRecord.id },
            guards: this.submitCifCraEdd_buttonGuard,
          },
          {
            name: 'downloadDocument',
            label: 'Download Document',
            type: '',
            guards: true,
            enabledGuards: !!this.applicationDocumentRecord.s3Key,
            action: 'downloadApplicationDocument',
            params: {
              accountId: this.clientId,
              applicationId: this.applicationId,
              applicationDocumentId: this.applicationDocumentRecord.id,
            },
            onSuccess: { doStoreUpdate: false },
          },

          {
            name: 'uploadDocuent',
            label: 'Upload Document',
            type: 'primary',
            guards: this.applicationRecord.applicationIsEditable || ['rejected','error'].includes(this.applicationRecord.integrationStatus) || this.applicationRecord.craStatus === 'eddRequested',
            clickEvent() {
              self.$router.push({ name: 'clientApplicationUploadDocument' });
            },
          },
        ],

        // Review and Submit

        clientApplicationReview: [

        {
            name: 'submitApplicationCra',
            label: 'Submit Application for CRA',
            type: 'primary',
            action: 'submitApplicationCra',
            modal: {
              header: `Submit Application for CRA`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Submit',
              content: this.submitApplicationCraModalMessage,
            },
            params: { accountId: this.clientId, applicationId: this.applicationId },
            // guards: this.applicationRecord?.applicationPortfolioStatus === 'signed',
            guards: this.applicationRecord.craEnabled && !!this.applicationRecord?.applicationIsCraSubmitable && 
              this.applicationRecord.visibility !== 'hidden',
            enabledGuards:
              this.applicationRecord.is_submittable &&
              ['notStarted','error','reOpened'].includes(this.applicationRecord.craStatus) &&
              !['completed'].includes(this.applicationRecord.status) && //'inProgress', 'partiallyCompleted'
              (this.applicationRecord?.thisApplicationDocumentUploadType !== 'local' || this.applicationRecord?.outstandingDocumentTypes?.length === 0) &&
              this.authUser?.accountIsActive,
          },
          {
            name: 'submitApplication',
            label: 'Submit Application',
            type: 'primary',
            action: 'submitApplication',
            modal: {
              header: `Submit Application`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Submit',
              content: this.submitApplicationModalMessage,
            },
            params: { accountId: this.clientId, applicationId: this.applicationId },
            guards: this.applicationRecord?.thisApplicationDocumentUploadType === 'local',
            enabledGuards:
              this.applicationRecord?.applicationIsSubmitable &&
              this.applicationRecord.is_submittable &&
              (!['partiallyCompleted', 'completed'].includes(this.applicationRecord.status)  && 
              !(this.applicationRecord.status === 'inProgress' && !['none',null,undefined].includes(this.applicationRecord.integrationStatus))) &&
              this.applicationRecord?.outstandingDocumentTypes?.length === 0,
            // clickEvent: 'onLDBButtonClick', // TODO remove this - avr
          },
          {
            name: 'submitApplicationPortfolio',
            label:
              this.applicationRecord?.thisApplicationDocumentUploadType === 'local'
                ? 'TEST - Submit to SigniFlow - TEST'
                : 'Submit Application Portfolio',
            type: 'primary',
            action: 'submitApplicationPortfolio',
            modal: {
              header: `Submit Application Portfolio`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Submit',
              content: this.submitApplicationPortfolioModalMessage,
            },
            params: { accountId: this.clientId, applicationId: this.applicationId },
            guards:
              (!this.applicationRecord.craEnabled && this.applicationRecord?.applicationIsEditable || 
              this.applicationRecord.craEnabled && this.applicationRecord.craStatus === 'approved'
              ) &&
              (['LOCAL', 'DEV', 'INT'].includes(this.sdlc) ||
                this.applicationRecord?.thisApplicationDocumentUploadType !== 'local'),
            enabledGuards:
              this.applicationRecord.is_submittable &&
              (!['inProgress', 'partiallyCompleted', 'completed'].includes(this.applicationRecord.status) || (this.applicationRecord.status === 'inProgress' &&  this.applicationRecord.integrationStatus === 'none')),
            // clickEvent: 'onLDBButtonClick', // TODO remove this - avr
            clickEvent(response) {
              if (response.status === 'success') {
                self.$router.replace({ name: 'clientApplication' });
              }
            },
          },
        ],
      };
      return allButtons[this.actionGroup]?.filter((button) => button.guards) || [];
    },

    actionGroup() {
      return this.$route.meta?.content?.actionGroup;
    },

    // ----- Layout -----

    formLayout() {
      return this.$route.meta?.layout === 'list' ? false : true;
    },

    // ----- Banners -----

    showBanners() {
      return false;
    },
    bannerGroup() {
      return this.$route.meta?.content?.actionGroup; // TODO
    },
    banners() {
      let allBanners = {
        all: [
          {
            id: `fieldsOutstanding`,
            title: 'Outstanding Fields',
            status: 'info',
            closable: false,
            content: `The following mandatory fields are outstanding, they will need to be completed before the application can be submitted.<br/> ${this.thisStepOutstandingFieldText}`,
            guards: this.thisStepOutstandingFieldText && this.thisStepOutstandingFieldText.length !== 0,
          },
        ],
        clientApplicationWizard_CifDetailsEdit: [
          {
            id: 'noAssociatedPartyForEntityClient',
            title: 'No Active Associated Parties',
            status: 'warning',
            closable: false,
            content:
              'There are no Active Associated Parties.  An Entity Client must have at least one Active Associated Party.',
            guards:
              this.applicationRecord?.hasAssociatedCifs &&
              !this.applicationRecord?.hasActiveAssociatedCifs &&
              this.$route.meta?.content?.cifType === 'associated' &&
              this.clientRecord?.accountType === 'entity', // TODO add guards for client is entity and viewing the associated cifs
          },
        ],
        clientApplicationUploadDocuments: [
          {
            id: `documentsOutstanding`,
            title: 'Mandatory Documents Outstanding',
            status: 'info',
            closable: false,
            content: `The following mandatory documents are outstanding: ${this.applicationRecord?.outstandingDocumentTypeText}`,
            guards: this.applicationRecord?.outstandingDocumentTypes?.length !== 0,
          },
          {
            id: `noDocumentsOutstanding`,
            title: 'All Documents Uploaded',
            status: 'success',
            closable: false,
            content: `All mandatory documents have been uploaded.`,
            guards: this.applicationRecord?.outstandingDocumentTypes?.length === 0,
          },
        ],
      };
      const availableBanners = [...(allBanners[this.bannerGroup] || []), ...allBanners.all];
      return availableBanners.filter((banner) => banner.guards);
    },

    // ----- Account Record -----

    clientId() {
      return +this.$route.params.accountId;
    },
    clientRecord() {
      return this.account[this.clientId];
    },

    // ----- Application Record -----

    applicationId() {
      return +this.$route.params.applicationId;
    },
    applicationRecord() {
      return this.clientRecord?.application?.[this.applicationId];
    },

    // ----- Viewed CIF Record -----

    cifId() {
      return +this.$route.params.cifId;
    },
    cifRecord() {
      return this.applicationRecord?.application_cif?.[this.cifId];
    },

    // ----- Full CIF -----

    fullCifId() {
      return this.applicationRecord?.fullCifId;
    },
    isFullCifRecord() {
      return this.applicationRecord?.fullCifId === this.cifId;
    },
    fullCifRecord() {
      return this.applicationRecord?.application_cif?.[this.fullCifId];
    },

    // ----- Associated CIFs -----

    firstAssCifId() {
      return +Object.keys(this.associatedCifIds)[0] || 0;
    },

    associatedCifIds() {
      return this.applicationRecord?.associatedCifIds;
    },
    numberOfAssociatedCifIds() {
      return Object.keys(this.associatedCifIds).length;
    },

    hasActiveAssociatedCifs() {
      return this.applicationRecord?.hasActiveAssociatedCifs;
    },
    activeAssociatedCifIds() {
      return this.applicationRecord?.activeAssociatedCifIds;
    },

    // ----- Bank Account -----

    bankAccountApplicationIds() {
      return this.applicationRecord?.bankAccountApplicationIds;
    },
    firstBankAccountApplicationId() {
      return Object.keys(this.bankAccountApplicationIds)[0];
    },

    applicationBankAccountId() {
      return +this.$route.params.applicationAccountId;
    },
    bankAccountRecord() {
      return this.applicationRecord?.application_account?.[this.applicationBankAccountId];
    },

    // ----- Documents -----
    applicationDocuments() {
      return this.applicationRecord.localDocuments || {};
    },
    firstDocumentId() {
      if (typeof this.applicationDocuments === 'object' && Object.keys(this.applicationDocuments).length > 0) {
        return Object.keys(this.applicationDocuments)[0];
      }
      return;
    },
    applicationDocumentId() {
      return +this.$route.params?.applicationDocumentId;
    },
    applicationDocumentRecord() {
      return this.applicationDocuments?.[this.applicationDocumentId] || {};
    },

    thisStepOutstandingFieldText() {
      switch (this.$route.meta?.content?.stepName) {
        case 'clientCif':
          return this.cifRecord?.OutstandingFields;
        case 'associatedCifs':
          return this.cifRecord?.OutstandingFields;
        case 'bankAccounts':
          return this.bankAccountRecord?.OutstandingFields;
        case 'signatories':
        case 'uploadDocuments':
        case 'portfolioDocuments':
        case 'reviewAndSubmit':
        default:
          return undefined;
      }
    },
    // ----- Button Guards -----
    submitCifCraEdd_buttonGuard() {
      return this.applicationRecord.craEnabled &&
      ['new','inProgress'].includes(this.applicationRecord?.status) &&
      ['new','inProgress'].includes(this.fullCifRecord?.status) && 
      ['eddRequested'].includes(this.fullCifRecord?.craStatus)
    },
    // ----- Modeal Messages -----

    // submitCifModalMessage() {
    //   //TODO
    //   return `
    //     <ol>
    //       <li>Submit or resubmit the CIF application</li>
    //     </ol>
    //   `;
    // },
    // submitAccountModalMessage() {
    //   //TODO
    //   return `
    //     <ol>
    //       <li>Submit or resubmit the Bank Account application</li>
    //     </ol>
    //   `;
    // },

    submitCifCraEddModalMessage() {
      return `Submits or resubmits the Client or Associated party for CRA Enhanced Due Diligence review.`
    },

    submitApplicationCraModalMessage() {
      return `Submits or resubmits CRA applications for the client and all associated parties`
    },
    submitApplicationModalMessage() {
      //TODO
      return `
        <ol>
          <li>Submit or resubmit the application and all sub applications</li>
        </ol>
      `;
    },
    submitApplicationPortfolioModalMessage() {
      //TODO
      return `
        <ol>
          <li>Submit or resubmit the application portfolio for Signing</li>
        </ol>
      `;
    },
    // updateStatusModalMessage() {
    //   //TODO
    //   return `
    //     <ol>
    //       <li>Recalculate the status of the applicatin and all sub applications</li>
    //     </ol>
    //   `;
    // },
    cancelApplicationDocumentModalMessage() {
      return `
        <ol>
          <li>Cancels an uploaded document</li>
        </ol>
      `;
    },
    allowModificationOnForms() {
      // return !['inProgress', 'partiallyCompleted', 'completed'].includes(this.applicationRecord.status);
      return this.applicationRecord.applicationIsEditable
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // async getApplication() {
    //   let collection_path = `/client/${this.clientId}/application`;
    //   let options = { return_collection_path: `/account/${this.clientId}/application` };
    //   let id = this.applicationId;
    //   this.$store.dispatch('showLoader', true);
    //   let response = await this.$store.dispatch('db_get_document', { collection_path, id, options });
    //   this.$store.dispatch('showLoader', false);
    // },

    onBack() {
      switch (this.routeStepName) {
        case 'associatedCifs':
          if (this.cifId) {
            if (this.cifId == this.activeAssociatedCifIds?.[0]) {
              this.stepBack();
            } else {
              let currentIndex = this.activeAssociatedCifIds.findIndex((id) => id == this.cifId);
              let newIndex = currentIndex - 1;
              let newId = +this.activeAssociatedCifIds.at(newIndex);
              this.$router.replace({
                name: 'clientApplicationWizardAssociatedCifDetailsEdit',
                params: { cifId: newId },
              });
            }
          } else {
            this.stepBack();
          }
          break;
        case 'signatories':
          this.stepBack(); // TODO - check what signatory we are viewing
          break;
        default:
          this.stepBack();
      }
    },
    onNext() {
      switch (this.routeStepName) {
        case 'associatedCifs':
          if (this.cifId) {
            if (this.cifId == this.activeAssociatedCifIds.at(-1)) {
              this.stepNext();
            } else {
              let currentIndex = this.activeAssociatedCifIds.findIndex((id) => id == this.cifId);
              let newIndex = currentIndex + 1;
              let newId = +this.activeAssociatedCifIds.at(newIndex);
              this.$router.replace({
                name: 'clientApplicationWizardAssociatedCifDetailsEdit',
                params: { cifId: newId },
              });
            }
          } else {
            this.stepNext();
          }
          break;
        case 'signatories':
          this.stepNext(); // TODO - check what signatory we are viewing
          break;
        default:
          this.stepNext();
      }
    },

    // ----- Stepper -----

    selectFirstStep() {
      this.$router.replace({ name: this.steps[0].routeName, params: this.steps[0].routeParams }); // TODO - go to first item requiring attention
    },

    onStepClick(evt) {
      let newStepNumber = evt.detail.stepNumber;
      let newStepNumberIndex = newStepNumber - 1; // stepNumber starts at 1
      let newStep = this.steps[newStepNumberIndex];
      this.$router.replace({ name: newStep.routeName, params: newStep.routeParams }); // TODO
    },
    stepBack() {
      let newStepNumber = this.activeStepNumber - 1;
      let newStepNumberIndex = newStepNumber - 1; // stepNumber starts at 1
      let newStep = this.steps[newStepNumberIndex];
      this.$router.replace({ name: newStep.routeName, params: newStep.routeParams }); // TODO
      // TODO - maybe go to last item in list for Associated CIFs, others with multiple items
    },
    stepNext() {
      let newStepNumber = this.activeStepNumber + 1;
      let newStepNumberIndex = newStepNumber - 1; // stepNumber starts at 1
      let newStep = this.steps[newStepNumberIndex];
      this.$router.replace({ name: newStep.routeName, params: newStep.routeParams }); // TODO
    },
  },
};
</script>
