<template>
  <capitec-chip v-if="selected" class="search-chip" :label="label" avatarImage="/platform/icons/status/success.svg" @click.stop="onClick" @remove="onRemove" :closable="closable ? 'closable' : null" />
  <capitec-chip v-else          class="search-chip" :label="label" @click.stop="onClick" @remove="onRemove" :closable="closable ? 'closable' : null" />
</template>

<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

export default {
  components: {
    
  },
  props: {  
    id:{type:String,required:true},
    label:{type:String,required:true},
    field:{type:String,required:true},
    operator:{type:String,required:true},
    value:{type:String,required:true},
    selected:{type:Boolean,required:true},
    closable:{type:Boolean,default:false}
  },
  data() {
    return {
      _selected:false
    }
  },
  created() {
    
  },
  mounted() {
    this._selected = this.selected;
  },
  computed:{
    ...mapGetters([]),
  	...mapState([]),
    selector() {
      return {field:this.field,operator:this.operator,value:this.value};
    },
    avatarImage() {
      return this.selected ? "system/client-insights-action.svg" : "system/client-insights-action.svg";
    }
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([]),
    onRemove() {
      this.$emit('on-remove',this.id);
    },
    onClick(evt) {
      this._selected = !this._selected;
      this.$emit('on-click',{id:this.id,selected:this._selected,selector:this.selector});
    }
  }
 }
</script>

<style>

capitec-chip.search-chip{
  margin-left:3px;
  margin-right:3px;
}

</style>

