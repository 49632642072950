//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxUserAccessLogDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a User Access Log record for LandoByte TS systems
//  Created     : 28 October 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition } from './LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------------------------------

export interface LfxUserAccessLogInt {
  id?: string;
  userId?: number;
  userName?: string;
  action?: string;
  actionDetails?: {};
  result?: string;
  timestamp?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxUserAccessLogDef: LdbDefinition = {
  title: 'User Access Log',
  table: 'LfxUserAccessLog',
  collectionPath: '/user/{{userId}}/user_access_log',
  database: 'dynamo',
  view: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
  indexes: ['userId'],
  pagingType: 'backEnd',
  fields: {
    id: {
      label: 'ID',
      datatype: { id: { descriptionField: 'action', type: 'text' } },
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    userId: {
      label: 'User',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    userName: {
      label: 'User Name',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    action: {
      label: 'Action',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },

    actionDetails: {
      label: 'Action Details',
      datatype: { json: {} },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },

    errorMessage: {
      label: 'Error Message',
      property: { read: true, source: 'frontend', write: false, sort: 'none' },
      datatype: 'text',
      views: { item: true, list: true, create: false, edit: false, csv: false, delete: false },
      mandatory: false,
      default: null,
    },

    result: {
      label: 'Result',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },

    timestamp: {
      label: 'Timestamp',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
  },
};
