<template>
  <LFXListView v-if="hasListLoaded" :definition="definition" :records="records" :defaultSort="defaultSort" :searchConfig="searchConfig"/>
  <template v-else>
    <LDBLoader />
  </template>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXListView from '@/views/general/LFXListView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxTransactionLogList',
  components: { LFXListView, LDBLoader },
  props:  { refreshCounter: { type: Number, required: false, default: 0}},
  data() {
    return {
      hasListLoaded:false
    };
  },
  async created() {
    this.loadData()
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account','authUser']),
    accountId() {
      return +this.$route.params.accountId || this.authUser.accountId;
    },
    records() {
      return this.account?.[this.accountId]?.incomingReport || {}
    },
    definition() {
      return getDefinition('incomingReport');
    },
    searchConfig() {
      return {
        searchFields:['filename'],
        options:[],
        ranges:[],
        choices:[
          {id:'status',
          title:'',
          chips:[
            {id:'all',label:'All',selector:{},default:true},
            {id:'detailedCustomer',label:'Detailed Customer Report',selector:{ reportType:'detailedCustomer' }},
            {id:'dailyDealList',   label:'Daily Deal List',         selector:{ reportType:'dailyDealList' }},
            {id:'accountBalance',  label:'Account Balance Report',  selector:{ reportType:'accountBalance' }},
          ]
        }
        ],
      }
    },

  },
  watch:{
    refreshCounter(newCount,oldCount) {
      if (newCount !== oldCount) {
        this.loadData();
      }
    }
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    async loadData() {
      this.hasListLoaded = false;
      await doPermissionAction(this,'getIncomingReports',{accountId:this.accountId});
      this.hasListLoaded = true;
    }
  },
};
</script>

<style>
/* div.container {
} */
</style>
