import axios from 'axios';
import store from '@/store';

import { ENV_VARIABLES } from '@/store/config_env';

const REFRESH_INTERVAL = 20 * 60 * 1000;

const AUTH_SESSION_EXPIRY = 'AUTH_SESSION_EXPIRY';
const AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN';
const AUTH_LAST_USERNAME = 'AUTH_LAST_USERNAME';
const AUTH_LAST_USER = 'AUTH_LAST_USER';
const AUTH_ACCESS_TOKEN = 'AUTH_ACCESS_TOKEN';

export function saveValue(key: string, value: string) {
  localStorage.setItem(key, value);
}

export function getValue(key: string, defaultValue?: string): string | null {
  let currentValue = localStorage.getItem(key);
  if (currentValue === null) {
    if (defaultValue === undefined) {
      return null;
    } else {
      return defaultValue;
    }
  } else {
    return currentValue;
  }
}

export function deleteValue(key: string) {
  localStorage.removeItem(key);
}

type httpPutRes = {
  status: 'success' | 'failed' | 'otp';
  data: {
    accessToken?: string;
    expiration: number;
    refreshToken?: string;
  };
  response: Object;
};

type VuexContext = {
  dispatch: (method: string, paylooad: object) => any;
  getters: { authHeaders: object };
  state: {
    lfx_auth: LfxAuth;
    otp: { username: string; password: string; status: string; otpToken: string };
    mfa: {
      expiry: number;
      mfaToken: string;
      otpSent: boolean;
      otpType: 'email' | 'sms';
      requesting: boolean;
      resolve: (value: any) => void;
      reject: (value: any) => void;
    };
  };
};

type LfxAuthUserDef = {
  id: number;
  uuid: string;
  accountId: number;
  accountUuid: string;
  accountLevel: 'bank' | 'intermediary' | 'client';
};

export async function http_rest_put(context: VuexContext, path: string, params: object): Promise<httpPutRes | any> {
  const APIURL = ENV_VARIABLES.VUE_REST_API_URL;
  let url = `${APIURL}${path}`;

  let headers = { ...context.getters.authHeaders };

  // ignore body for now...
  let config = { headers: headers };

  try {
    const http_res = await axios.put(url, params, config);
    let data = http_res.data;
    let status = http_res.status;
    return { status, response: data };
  } catch (err) {
    console.log('err:',err);
    //@ts-ignore
    //@ts-ignore
    return { status: err.response.status, response: err.response.data };
    // return err;
  }
}

//@ts-ignore
class LfxAuth {
  context: VuexContext;
  // username:string;
  refreshInterval: number;
  refreshTimer: number;
  // user:LfxAuthUserDef;
  constructor(
    context: VuexContext,
    username: string,
    accessToken: string,
    refreshToken: string,
    user: LfxAuthUserDef,
    expiration: number
  ) {
    this.context = context;
    this.username = username;
    this.user = user;
    this.refreshToken = refreshToken;
    this.refreshInterval = REFRESH_INTERVAL;
    this.accessToken = accessToken;
    this.setExpirationDate(expiration);
    let self = this;
    let refreshSessionOnTimer = function() {
      self.refreshSession();
    };
    //@ts-ignore
    this.refreshTimer = setInterval(refreshSessionOnTimer, REFRESH_INTERVAL);
  }
  setExpirationDate(expiration: number) {
    let now = this.sse;
    let expiryDate = now + expiration;
    saveValue(AUTH_SESSION_EXPIRY, String(expiryDate));
  }

  get isValidSession() {
    let isValid = this.getExpirationDate > this.sse;
    if (isValid === false) {
      this.logout()      
    }
    return isValid;
  }

  get getExpirationDate(): number {
    let now = `${this.sse}`;
    return Number(getValue(AUTH_SESSION_EXPIRY, now));
  }

  get refreshToken(): string | null {
    let res = getValue(AUTH_REFRESH_TOKEN);
    return res;
  }

  set refreshToken(value: string | null) {
    if (value === null) {
      deleteValue(AUTH_REFRESH_TOKEN);
    } else {
      saveValue(AUTH_REFRESH_TOKEN, value);
    }
  }

  get username(): string | null {
    return getValue(AUTH_LAST_USERNAME);
  }

  set username(value: string | null) {
    if (value === null) {
      deleteValue(AUTH_LAST_USERNAME);
    } else {
      saveValue(AUTH_LAST_USERNAME, value);
    }
  }

  get user(): LfxAuthUserDef | null {
    return JSON.parse(String(getValue(AUTH_LAST_USER)));
  }

  set user(value: LfxAuthUserDef | null) {
    if (value === null) {
      deleteValue(AUTH_LAST_USER);
    } else {
      saveValue(AUTH_LAST_USER, JSON.stringify(value));
    }
  }

  get sse() {
    return Math.floor(new Date().getTime() / 1000);
  }

  get accessToken() {
    return getValue(AUTH_ACCESS_TOKEN);
  }

  set accessToken(value: string | null) {
    if (value === null) {
      deleteValue(AUTH_ACCESS_TOKEN);
    } else {
      saveValue(AUTH_ACCESS_TOKEN, value);
    }
  }
  async refreshSession() {
    let refreshSessionResult = await http_rest_put(this.context, '/auth/refreshSession', {
      refreshToken: this.refreshToken,
      username: this.username
    });
    if (refreshSessionResult.status === 201 || refreshSessionResult.status === 200) {
      let newAccessToken = refreshSessionResult.response.accessToken;
      this.accessToken = newAccessToken;
      this.setExpirationDate(refreshSessionResult.response.expiration);
    } else 
    {
      if (refreshSessionResult.status === 524) {
        console.log("Session Refresh Timed Out")
        void http_rest_put(this.context, '/admin/1/logError', {
          errorType: 'refreshTokenTimeout',
          errorReport: refreshSessionResult
        });
        if (store.getters.isTestingSdlc) {
          await this.logout()
        }
      } else {
        console.log("Failed Refresh Session, logging out",refreshSessionResult)
        await this.logout()
      }      
    }
  }

  async logout() {
    let username = this.username;
    let logout_response = await http_rest_put(this.context, '/auth/logout', { username });
    clearInterval(this.refreshTimer);
    this.refreshToken = null;
    this.username = null;
    this.accessToken = null;
    this.user = null;
    deleteValue(AUTH_SESSION_EXPIRY);
    if (logout_response.status === 200) {
      this.context.dispatch('auth_logout', { logout: 'success' });
      return { logout: 'success' };
    } else {
      return { logout: 'failed' };
    }
  }
}


export async function LfxAuthUser(context: VuexContext) {
  // if there is a token
  let nowSSE = Math.floor(new Date().getTime() / 1000);
  let nowSSEString = String(nowSSE);
  let currentExpiryTime = Number(getValue(AUTH_SESSION_EXPIRY, nowSSEString));
  if (currentExpiryTime >= nowSSE) {
    let currentAccessToken = getValue(AUTH_ACCESS_TOKEN);
    let currentRefreshToken = getValue(AUTH_REFRESH_TOKEN);
    let currentUsername = getValue(AUTH_LAST_USERNAME);
    if (currentUsername !== null && currentRefreshToken !== null && currentAccessToken !== null) {
      let user = JSON.parse(String(getValue(AUTH_LAST_USER)));
      let expiration = currentExpiryTime - nowSSE;
      let lfxAuthUser = new LfxAuth(context, currentUsername, currentAccessToken, currentRefreshToken, user, expiration);
      context.state.lfx_auth = lfxAuthUser;
      context.dispatch('auth_login_success', { user: lfxAuthUser });
      return lfxAuthUser;
    }
  } else {
    context.dispatch('auth_login_required', {});
    return null;
  }
}

//@ts-ignore
export async function LfxAuthFirstLogin(context: VuexContext, username: string, response: loginResponse) {
  //@ts-ignore
  let user = response.lfxUsers[0];
  //@ts-ignore
  let expiration = response.expiration;
  //@ts-ignore
  let refreshToken = response.refreshToken;
  //@ts-ignore
  let accessToken = response.accessToken;
  //@ts-ignore
  let lfxAuthUser = new LfxAuth(context, username, accessToken, refreshToken, user, expiration);
  context.state.lfx_auth = lfxAuthUser;
  context.dispatch('auth_login_success', { user: lfxAuthUser });
  return { status: 'success', user: lfxAuthUser };
}

export async function LfxAuthLogin(context: VuexContext, username: string, password: string) {
  let loginResponse = await http_rest_put(context, '/auth/login', { username, password });
  if (loginResponse.response.loginResult === 'success') {
    let user = loginResponse.response.lfxUsers[0];
    let expiration = loginResponse.response.expiration;
    let refreshToken = loginResponse.response.refreshToken;
    let accessToken = loginResponse.response.accessToken;
    let lfxAuthUser = new LfxAuth(context, username, accessToken, refreshToken, user, expiration);
    context.state.lfx_auth = lfxAuthUser;
    context.dispatch('auth_login_success', { user: lfxAuthUser });
    return { status: 'success', user: lfxAuthUser };
  } else if (loginResponse.response.loginResult === 'passwordResetRequired') {
    context.state.otp.username = username;
    context.state.otp.password = password;
    context.state.otp.status = 'pending';
    context.dispatch('auth_password_required', { username, password });
    return { status: 'passwordResetRequired', session: loginResponse.response.session };
  } else if (loginResponse.response.loginResult === 'otpSent') {
    context.state.otp.username = username;
    context.state.otp.password = password;
    context.state.otp.status = 'pending';
    context.state.otp.otpToken = loginResponse.response.otpToken;
    context.dispatch('auth_otp_required', { username, password });
    return { status: 'otpSent', otpType: loginResponse.response.otpType,response: loginResponse.response };
  } else if (loginResponse.response.loginResult === 'failed') {
    context.dispatch('auth_login_failed', {});
    return { status: 'failed' };
  } else if (loginResponse.status >= 400) {
    context.dispatch('auth_login_failed', {});
    return { status: 'failed', response: loginResponse.response };
  }
}

export async function lfx_auth_login(context: VuexContext, payload: { username: string; password: string }) {
  let username = payload.username;
  let password = payload.password;
  context.dispatch('auth_logging_in', {});
  return await LfxAuthLogin(context, username, password);
}

export async function lfx_auth_logout(context: VuexContext, payload: {}) {
  await context.state.lfx_auth?.logout();
}
export async function lfx_auth_forceRefreshSession(context: VuexContext, payload: {}) {
  await context.state.lfx_auth?.refreshSession()
}

export async function lfx_check_current_session(context: VuexContext, payload: {}) {
  let un = getValue(AUTH_LAST_USERNAME);

  if (getValue(AUTH_LAST_USERNAME) === null) {
    context.dispatch('auth_login_required', {});
  } else {
    let currentSessionRes = await LfxAuthUser(context);
    return currentSessionRes;
  }
}

type forgotPasswordParams = {
  username: string;
};

export async function lfx_auth_forgot_password(context: VuexContext, payload: forgotPasswordParams) {
  let forgotResponse = await http_rest_put(context, '/auth/forgotPassword', payload);
  return forgotResponse;
}

type confirmForgotPasswordParams = {
  username: string;
  password: string;
  confirmationCode: string;
};

export async function lfx_auth_confirm_forgot_password(context: VuexContext, payload: confirmForgotPasswordParams) {
  let resetResponse = await http_rest_put(context, '/auth/confirmForgotPassword', payload);
  return resetResponse;
}

type completeFirstLoginParams = {
  username: string;
  newPassword: string;
  session: string;
};

export async function lfx_auth_complete_first_login(context: VuexContext, payload: completeFirstLoginParams) {
  let firstLoginResponse = await http_rest_put(context, '/auth/completeFirstLogin', payload);
  if (firstLoginResponse.status === 200) {
    return await LfxAuthFirstLogin(context, payload.username, firstLoginResponse.response);
  } else {
    return firstLoginResponse;
  }
}

type completeOtpLoginParams = {
  otp: 'string';
  otpToken: 'string';
  username: 'string';
};

export async function lfx_auth_complete_otp_login(context: VuexContext, payload: completeOtpLoginParams) {
  let otpPayload = { otp: payload.otp, otpToken: payload.otpToken };
  let otpLoginResponse = await http_rest_put(context, '/auth/completeOtpLogin', otpPayload);
  if (otpLoginResponse.status === 200) {
    return LfxAuthFirstLogin(context, payload.username, otpLoginResponse.response);
  } else return otpLoginResponse;
}

export async function lfx_auth_resend_otp(context: VuexContext, payload: {otpToken: 'string'}) {
  let otpPayload = { otpToken: payload.otpToken };
  await http_rest_put(context, '/auth/resendOtp', otpPayload);
}

type mfaParams = {
  requestedAction: string;
  messageText: string;
  otp?: string;
};
type MfaResult ={
  result:'success'|'otpSent'|'error',
  type: 'new'|'existing'
  mfaToken?: string
}

// export async function lfx_auth_check_mfa(context:VuexContext,{userId,}) {
// if (context.state.mfa.expiry > now)  {
//   let mfaResult = http_rest_put(context,`/user/${userId}/mfa`,{requestedAction": "string",
//   "messageText": "string",
//   "otp": "string"})
// }
// }

export async function lfx_auth_dont_send_mfa_otp(context: VuexContext, payload: any) {
  if (typeof context.state.mfa?.reject === 'function') {
    context.state.mfa.reject(payload);
  }
  context.state.mfa.otpSent = false;
  context.state.mfa.requesting = false
}

export async function lfx_auth_send_mfa_otp(context: VuexContext, payload: { otp: string }) {
  if (context.state.lfx_auth !== null && context.state.lfx_auth.user !== null) {
    let confirmOtpResult = await http_rest_put(
      context,
      `/user/${context.state.lfx_auth.user.id}/confirmMfaOtp`,
      payload
    );
    if (confirmOtpResult.response.result === 'success') {
      context.state.mfa.expiry = Math.floor(new Date(confirmOtpResult.response.mfaTokenExpiry).getTime() / 1000);
      context.state.mfa.mfaToken = confirmOtpResult.response.mfaToken;
      context.state.mfa.otpSent = false;
      context.state.mfa.resolve({ ...confirmOtpResult.response, type: 'new' });
    } else {
      context.state.mfa.reject(confirmOtpResult.response);
      let message = confirmOtpResult.response.message || confirmOtpResult.response.error;
      let type = 'error';
      let header = 'Login Failed';
      let show = true;
      context.dispatch('showToast',{ type, header, message,show });
    }
  }
}

async function lfx_auth_get_mfa_from_server(
  context: VuexContext,
  payload: mfaParams,
  resolve: (value: any) => void,
  reject: (value: any) => void
):Promise<MfaResult|void> {
  if (context.state.lfx_auth !== null && context.state.lfx_auth.user !== null) {
    context.state.mfa.requesting = true;
    context.state.mfa.reject = reject;
    let mfaResult = await http_rest_put(context, `/user/${context.state.lfx_auth.user.id}/mfa`, {
      requestedAction: 'mfa',
      messageText: 'Please give me an mfa',
      otp: ''
    });
    context.state.mfa.requesting = false;
    if (mfaResult.response.result === 'success') {
      context.state.mfa.expiry = Math.floor(new Date(mfaResult.response.mfaTokenExpiry).getTime() / 1000);
      context.state.mfa.mfaToken = mfaResult.response.mfaToken;
      resolve({ ...mfaResult.response, type: 'new' });
    } else if ((mfaResult.response.result === 'otpSent')) {
      // context.state.mfa.reject = reject;
      context.state.mfa.otpSent = true;
      context.state.mfa.otpType = mfaResult.response.otpType;
      context.state.mfa.resolve = resolve;
    } else if ((mfaResult.response.result === 'error')) {
      reject(mfaResult.response);
    } else {
      resolve({...mfaResult})
    }
  }
}

export async function lfx_auth_get_mfa_token(context: VuexContext, payload: mfaParams):Promise<MfaResult> {
  let now = Math.floor(new Date().getTime() / 1000);
  if (context.state.mfa.expiry > now) {
    return { result: 'success', type: 'existing', mfaToken: context.state.mfa.mfaToken };
  } else {
    let promise = new Promise(async (resolve, reject) => {
      await lfx_auth_get_mfa_from_server(context, payload, resolve, reject);
    });
    let promiseResult = await promise;
    return promiseResult as MfaResult;
  }
}

//@ts-ignore

const lfx_auth = {
  LfxAuthLogin,
  LfxAuthUser,
  getValue,
  lfx_auth_get_mfa_token,
  lfx_auth_send_mfa_otp,
  lfx_auth_dont_send_mfa_otp,
  lfx_check_current_session,
  lfx_auth_login,
  lfx_auth_logout,
  lfx_auth_forgot_password,
  lfx_auth_complete_first_login,
  lfx_auth_complete_otp_login,
  lfx_auth_resend_otp,
  lfx_auth_confirm_forgot_password
};

//@ts-ignore
window.lfx_auth = lfx_auth;

export default lfx_auth;
