//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxDealMarkupDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Deal Markup record for LandoByte TS systems
//  Created     : 25 October 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition } from '../definitions/LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------


export interface LfxDealMarkupInt {
  id?: number,
  uuid?: string,
  transactionFileId?: number,
  dealId?: number,
  accountId?: number,
  pips?:number,

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?:Date,
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxDealMarkupDef:LdbDefinition = {
  title:"Deal Markup",
  table:'LfxDealMarkup',
  // collectionPath:"/transaction/{{transactionFileId}}/deal/{{dealId}}/markup",
  collectionPath:"/deal/{{dealId}}/markup",
  view:{create:false,edit:false,item:true,list:true,delete:false,csv:false},
  indexes:['dealId','accountId'],
  pagingType:'frontEnd',
  fields:{
    id:{
      label:'ID',
      datatype:{'id':{descriptionField:'name'}},
      default:'AUTOINC',
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    uuid:{
      label:'UUID',
      datatype:'uuid',
      default:'AUTOUUID',
      mandatory:true,
      views:{create:false,edit:false,item:false,list:true,delete:false,csv:false}
    },    
    transactionFileId:{
      label:'Transaction File',
      datatype:{
        foreignKey: {
          collection:'fk_transaction_files',

          linkTable:'LfxTransactionFile',
          linkField:'id',
          displayField:'reference',
        }
      },
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false},
    },
    dealId:{
      label:'Deal',
      datatype:{
        foreignKey: {
          collection:'fk_deals',

          linkTable:'LfxDeal',
          linkField:'id',
          displayField:'reference',
        }
      },
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false},
    },
    accountId:{
      label:'Account',
      datatype:{
        foreignKey: {
          collection:'fk_accounts',

          linkTable:'LfxAccount',
          linkField:'id',
          displayField:'name',
        }
      },
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false},
    },
    pips:{
      label:'Pips',
      datatype:'float',
      default:0,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false},
    },
    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username'
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
  },
};
