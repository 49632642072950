<template>
  <div v-if="systemNotification.show && show && !this.sessionSettings.systemNotificationHasBeenDismissed" :class="`lfx-system-notification-container system-notification-${systemNotification.status} system-notification-${systemNotification.show}`">
    <capitec-icon class="system-notification-icon" size="default" :icon="`status/${systemNotification.status}-16px-white`"></capitec-icon>
    <capitec-label class="system-notification-title" type="subtitle" :label="`${systemNotification.title}`" />
    <div v-if="systemNotification.closable" class="close-system-notification" @click="onCloseClick">
      <capitec-icon icon="system/close" class="system-notification-close-button" size="small" />
    </div>
    <div class="lfx-system-notification-content">
      {{systemNotification.message}}
    </div>
  </div>
</template>


<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

export default {
  emits:['on-close'],
  components: {},
  props: {  
  },
  data() {
    return {
      show:true
    }
  },
  created() {
  },
  mounted() {
    
  },
  computed:{
    ...mapGetters(['systemNotification']),
  	...mapState(['sessionSettings']),
    showing() {
      return this.show ? 'show' : 'hide';
    }
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([]),
    onCloseClick() {
      this.$emit('on-close',this.id);
      this.sessionSettings.systemNotificationHasBeenDismissed = true
    }
  }
 }
</script>
<style>

.system-notification-icon{
  position:absolute;
  transform:scale(0.7);
  margin-left:5px;
}

.lfx-system-notification-container.system-notification-error{
  --dark-color:var(--theme-status-error-background);
  --light-color:var(--theme-alert-banner-unsuccessful-red-background-color);
}

.lfx-system-notification-container.system-notification-warning{
  --dark-color:var(--theme-status-warn-background);
  --light-color:var(--theme-alert-banner-warning-background-color);
}

.lfx-system-notification-container.system-notification-info{
  --dark-color:var(--theme-status-info-background);
  --light-color:var(--theme-alert-banner-info-background-color);
}

.lfx-system-notification-container.system-notification-success{
  --dark-color:var(--theme-status-success-background);
  --light-color:var(--theme-alert-system-notification-success-background-color);
}

.lfx-system-notification-container{
  flex:1;
  overflow: hidden;
  border-radius: 5px;
  background-color:var(--light-color);
  color:var(--dark-color);
  position:relative;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  /* border: 3px dotted orange; */
  position: fixed;
  left:300px;
  z-index: 1000;
  box-shadow: var(--theme-toast-box-shadow);
}

.lfx-system-notification-container.system-notification-hide{
  transition:all 0.5s linear;
  opacity: 0;
  transform:scaleY(0);
  max-height: 0px;
  margin:0px;
}

.lfx-system-notification-content{
  padding:10px;
  overflow:auto;
}

div.lfx-system-notification-container > capitec-label{
  color:var(--dark-color);
  background-color:var(--dark-color);
  color:white;
  padding-left:10px;
}

.system-notification-close-button{
  position: absolute;
  top:0px;
  right: 0px;
  height: 25px;
  width: 25px;
  filter:brightness(100);
  cursor: pointer;
}

capitec-label.system-notification-title{
  padding-left:35px !important;
}

</style>


