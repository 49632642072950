//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxBankAccountCommissionLevelDef.ts
//  Author      : Bevan Timm
//  Description : This module defines an Account Commission Level record for the LandoFX system
//  Created     : 10 September 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import { standardTextWhiteList, isNumericWhiteList } from '../sharedUtils/LdbValidations';
import type { LdbDefinition } from './LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------

export type LfxBankAccountCommissionLevelStatus = 'active' | 'disabled';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxBankAccountCommissionLevelInt {
  id?: number;
  name?: string;
  label?: string;
  status?: LfxBankAccountCommissionLevelStatus;
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxBankAccountCommissionLevelDef: LdbDefinition = {
  apiUrl() {
    return `/config/1/commissionLevels`;
  },
  title: 'Commission Level',
  table: 'LfxBankAccountCommissionLevel',
  collectionPath: "config/1/commissionLevel",
  view: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
  indexes: [],
  pagingType:'frontEnd',
  fields: {
    id: {
      label: 'ID',
      datatype: { id: { descriptionField: 'name' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    name: {
      label: 'Name',
      datatype: 'text',
      default: null,
      mandatory: true,
      validators:{
        is:{msg:'Please use only alpha charaters',args:[standardTextWhiteList]},
        len:{msg:'Name should be between 2 and 20 characters',args:[2,20]}
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
    },
    label: {
      label: 'Label',
      datatype: 'text',
      default: null,
      mandatory: true,
      validators:{
        is:{msg:'Please use only alphanumeric charaters',args:isNumericWhiteList},
        len:{msg:'Category should be between 2 and 50 characters',args:[2,50]}
      },
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
    },
    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'active', name: 'Active' },
            { id: 'disabled', name: 'Disabled' },
          ],
        },
      },
      default: 'active',
      allowNullValues: false,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
  },
};
