<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <ol>
        <li>This bank account of the beneficiary will be disabled.</li>
        <li>The bank account will no longer be shown as an option when booking deals or making payments.</li>
        <li>The bank account can be enabled again.</li>
        <li>Deals and payments that are in progress with this bank account will not be affected.</li>
        <li>Disabling the bank account will not affect the status of the beneficiary.</li>
      </ol>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in buttons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

export default {
  name: 'LfxDisableBeneficiaryBankAccount',
  components: {},
  props: {},
  emits: {},
  created() {},
  mounted() {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),
    header() {
      return `Disable Beneficiary Bank Account: ${this.bankAccountRecord.accountNumber} - ${this.bankAccountRecord.description}`;
    },

    // ----- Account record -----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },

    // ----- Beneficiary record ----

    beneficiaryId() {
      return +this.$route.params?.beneficiaryId;
    },
    beneficiaryRecord() {
      return this.accountRecord?.beneficiaries?.[this.beneficiaryId];
    },
    beneficiaryName() {
      return this.beneficiaryRecord?.beneficiaryType === 'entity'
        ? this.beneficiaryRecord?.name
        : `${this.beneficiaryRecord?.individualFirstname} ${this.beneficiaryRecord?.name}`;
    },

    // ----- Beneficiary Bank Account Record -----

    bankAccountId() {
      return +this.$route.params?.bankAccountId;
    },
    bankAccountRecord() {
      return this.accountRecord?.bankAccounts?.[this.bankAccountId];
    },

    // ----- Form buttons -----

    buttons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelDisableBeneficiaryBankAccount',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            // self.$router.replace({ name: self.cancelRoute });
            self.$router.go(-1);
          },
        },
        {
          name: 'disableBeneficiaryBankAccount',
          label: 'Disable Bank Account',
          type: 'primary',
          guards: this.bankAccountRecord.disableBeneficiaryBankAccount_buttonGuard, //TODO
          clickEvent() {
            self.disableBeneficiaryBankAccount();
          },
        },
      ];
      return allButtons;
    },
  },
  methods: {
    async disableBeneficiaryBankAccount() {
      let response = await this.bankAccountRecord.disableBeneficiaryBankAccount();
      if (response.status === 'success') {
        this.$router.replace({ name: 'beneficiaryBankAccountView' });
      }
    },
  },
};
</script>
