//-----------------------------------------------------------------------------------------------

import { db_path, db_collection_and_document, db_collection_and_document_name, db_tokens } from './db_utils';

//-----------------------------------------------------------------------------------------------

var Mustache = require('mustache');
// var mitt = require('mitt');

//@ts-ignore
import mitt from 'mitt';
export const dbObjectEmitter = mitt();

import { DBObject, db_proxy_handler } from './db_object';
import { getDefinition, getDefinitionByTable, getDefinitionByData } from './db_definitions_store';
import type { Store } from 'vuex';
import type { stateDef } from '@/store';

//-----------------------------------------------------------------------------------------------

//@ts-expect-error
export function db_load_active_collections(context, record) {
  return db_load_document_collections(context, record, 'active');
}

//-----------------------------------------------------------------------------------------------

//@ts-expect-error
export function db_recurse_to_object(store, path, parent_path, is_collection) {
  return do_db_recurse_to_object(store.state, path, parent_path, is_collection, store);
}

//@ts-expect-error
export function do_db_recurse_to_object(object, path, parent_path, is_collection, store) {
  //   path, parent_path, is_collection
  // })

  is_collection = is_collection !== false;
  let fields = db_tokens(path, '/');
  if (fields.length === 0) {
    return object;
  } else {
    let f = fields[0];
    if (is_collection !== false) {
    } else {
    }
    let result;
    if (object[f] === undefined) {
      if (!is_collection) {
        let path_tokens = [...fields];
        // let doc_id = path_tokens.pop();
        // let collection_path = `${parent_path}/${f}`;

        object[f] = new DBObject({ collection_path: parent_path, id: f }, store);
      } else {
        object[f] = {};
      }
      let remainingFields = fields.splice(1);
      result = do_db_recurse_to_object(
        object[f],
        db_path(remainingFields),
        `${parent_path ? parent_path : ''}/${f}`,
        !is_collection,
        store
      );
    } else {
      let remainingFields = fields.splice(1);
      result = do_db_recurse_to_object(
        object[f],
        db_path(remainingFields),
        `${parent_path ? parent_path : ''}/${f}`,
        !is_collection,
        store
      );
    }
    if (object[f] && object[f].processAfterEvent) {
      object[f].processAfterEvent()
    }
    return result
  }
}

//-----------------------------------------------------------------------------------------------

export function db_check_if_item_exists(store: any, path: string, parent_path?: string): Object | undefined {
  if (!parent_path) {
    parent_path = '';
  }
  return do_db_check_if_item_exists(store.state, path, parent_path, store);
}
export function do_db_check_if_item_exists(
  object: any,
  path: string,
  parent_path: string,
  store: any
): Object | undefined {
  let fields = db_tokens(path, '/');
  if (fields.length === 0) {
    return object;
  } else {
    let field = fields[0];
    if (object[field] === undefined) {
      return undefined;
    } else {
      let remainingFields = fields.splice(1);
      return do_db_check_if_item_exists(
        object[field],
        db_path(remainingFields),
        `${parent_path ? parent_path : ''}/${field}`,
        store
      );
    }
  }
}

//-----------------------------------------------------------------------------------------------

// export function db_assign_document_through_store(context, payload) {
//   let r = db_assign_document_to_state(context, payload.data, payload.collection_path, payload.id, payload.options);
//   return r;
// }

//-----------------------------------------------------------------------------------------------

const debugState = (name: string, print: any) => {
  var printDebug = false;
  if (printDebug) {
    console.log(name, print);
  }
};

export const db_assign_object_to_state = (
  vueStore: Store<stateDef>,
  dbObject: any,
  forceCreate?: boolean,
  options?: any
) => {
  if (dbObject.Table) {
    try {
      dbObjectEmitter.emit(dbObject.Table, dbObject);
    } catch (err) {
      console.log(err);
    }
  }
  debugState('--------------------', '');
  debugState('Table', dbObject.Table);
  debugState('dbObject', dbObject);
  let definition: any;
  if (options !== undefined && options.definition_name !== undefined) {
    definition = getDefinition(options.definition);
  } else {
    definition = getDefinitionByData(dbObject);
  }
  debugState('definition', definition);
  // if (definition !== undefined && definition.getDefinitionName !== undefined) {
  //   definition = getDefinition(definition.getDefinitionName(dbObject));
  // }
  // if (definition === undefined) {
  // }
  var collectionPathTemplate = definition?.collectionPath;
  if (!collectionPathTemplate) {
    return;
  }
  if (typeof collectionPathTemplate === 'function') {
    collectionPathTemplate = collectionPathTemplate(dbObject);
  }
  debugState('collectionPathTemplate', collectionPathTemplate);
  const collectionPath = build_full_path(dbObject, collectionPathTemplate);
  collectionPath.replaceAll('/null/', '/0/');
  debugState('collectionPath', collectionPath);
  const existingObject: any = db_check_if_item_exists(vueStore, collectionPath + '/' + dbObject.id, '');

  var itemExists = !!existingObject;
  var newObjectNewerThanStored = true;
  if (existingObject) {
    itemExists = true;
    // console.log('existingObject.updatedAt', existingObject.updatedAt);
    // console.log('dbObject.updatedAt', dbObject.updatedAt);
    // console.log('dbObject.updatedAt > existingObject.updatedAt', dbObject.updatedAt > existingObject.updatedAt);
    // console.log('existingObject.updatedAt', new Date(existingObject.updatedAt).toISOString());
    // console.log('dbObject.updatedAt', new Date(dbObject.updatedAt).toISOString());
    // console.log('dbObject.updatedAt > existingObject.updatedAt', dbObject.updatedAt > existingObject.updatedAt);

    // if (existingObject.updatedAt && dbObject.updatedAt) {
    // newObjectNewerThanStored = dbObject.updatedAt > existingObject.updatedAt;
    // }
    if (!isNaN(new Date(dbObject.updatedAt).getTime())) {
      if (!isNaN(new Date(existingObject.updatedAt).getTime())) {
        newObjectNewerThanStored = dbObject.updatedAt > existingObject.updatedAt;
      }
    } else {
      newObjectNewerThanStored = false;
    }
  }
  debugState('itemExists', itemExists);

  var addToStoreIfNotExist: boolean | undefined | Function = true;
  if (!forceCreate) {
    addToStoreIfNotExist = definition.addToStoreIfNotExist;
    if (typeof addToStoreIfNotExist === 'function') {
      addToStoreIfNotExist = addToStoreIfNotExist(dbObject, vueStore.state.authUser);
    }
  }
  debugState('addToStoreIfNotExist', addToStoreIfNotExist);
  var changeType: string = 'modified';
  if (addToStoreIfNotExist && !itemExists) {
    changeType = 'added';
  }
  debugState('changeType', changeType);

  const opts = { ...options, change: { type: changeType }, definition: definition };
  if (itemExists || changeType === 'added') {
    if (newObjectNewerThanStored) {
      debugState('Saving object to state', '');
      const r = db_assign_document_to_state(vueStore, dbObject!, collectionPath, dbObject.id, opts);
      debugState('Object saved to state', '');
    }
    if (!(options?.supressNestedCreation === true)) {
      check_and_create_nested_objects_in_object(vueStore, dbObject, forceCreate, options);
    }
  }
};

const build_full_path = (dbObject: any, pathTemplate: string) => {
  return Mustache.render(pathTemplate, dbObject);
};

const check_and_create_nested_objects_in_object = (
  vueStore: Store<stateDef>,
  dbObject: any,
  forceCreate?: boolean,
  options?: any
) => {
  debugState('  ------------------', '');
  debugState('  Checking For Nested Objects', '');
  for (const key in dbObject) {
    check_and_create_nested_objects(vueStore, dbObject[key], forceCreate, options);
  }
};
const check_and_create_nested_objects = (
  vueStore: Store<stateDef>,
  value: any,
  forceCreate?: boolean,
  options?: any
) => {
  if (Array.isArray(value)) {
    debugState('  Found Nested Array', value);
    for (const e of value) {
      check_and_create_nested_objects(vueStore, e, forceCreate, options);
    }
  } else if (typeof value === 'object' && value !== null && value.id && value.Table) {
    debugState('  Found Nested Object', value);
    db_assign_object_to_state(vueStore, value, forceCreate, options);
  }
};

//-----------------------------------------------------------------------------------------------

//@ts-expect-error
export function db_assign_document_to_state(context, data, collection_path, id, options) {
  debugState('>>>>>>>>', '');
  debugState('data', data);
  debugState('collection_path', collection_path);
  debugState('id',id);
  debugState('options',options)
  options.collection_path = collection_path;
  let document_data = map_object(data, id, options);
  let path = `${collection_path}/${id}`;
  let fixed_path = db_path(path);
  let path_obj = db_collection_and_document(fixed_path);
  //@ts-expect-error
  let object = db_recurse_to_object(context, path_obj.collection);
  //@ts-expect-error
  let key = path_obj.document_id;
  if (
    false
    // object !== undefined &&
    // object[key] !== undefined &&
    // object[key] !== undefined // avr

    // object[key].__fs !== undefined &&
    // object[key].__fs.version === options.version
    // object[key].updatedAt === data.updatedAt

    // object[key].updatedAt &&
    // data.updatedAt &&
    // object[key].updatedAt === data.updatedAt //avr - also causes problem with sub-records not updating if main record hasn't updated
  ) {
    return object[key];
  } else {
    //@ts-expect-error
    options.collection_path = path_obj.collection;
    if (document_data.selected === undefined) {
      document_data.selected = true;
    }
    let res = db_assign_fields_to_object(context, object, id, document_data, options);
    return res;
  }
}

//-----------------------------------------------------------------------------------------------
//@ts-expect-error
export function db_update_state(context, collection_path, id, record, options) {
  return db_assign_document_to_state(context, record, collection_path, id, options);
}

//   let object = db_recurse_to_object(context.state,collection_path);
//   db_assign_fields_to_object(context,)
//   if (object[id] === undefined) {
//     object[id] = {}
//   }
//   for (var af in record) {
//     if (record.hasOwnProperty(af)) {
//       let val = record[af];
//       object[id][af] = val
//     }
//   }
// }

//-----------------------------------------------------------------------------------------------
//@ts-expect-error
export function db_delete_state(context, collection, id) {
  //@ts-expect-error
  let object = db_recurse_to_object(context, collection);
  delete object[id];
}

//-----------------------------------------------------------------------------------------------
//@ts-expect-error
export function db_clone(obj) {
  let res = {};
  for (var f in obj) {
    if (obj.hasOwnProperty(f)) {
      if (obj[f] !== undefined) {
        if (
          f !== '.key' &&
          f !== '__fs' &&
          f !== 'id' &&
          f !== 'collection_path' &&
          f !== '_change_from_db' &&
          f !== '__db_path' &&
          f !== '__st_path'
        ) {
          let fld = obj[f];
          if (typeof fld === 'object' && fld instanceof Date === false) {
            if (!(fld instanceof Array)) {
              //@ts-expect-error
              res[f] = db_clone(fld);
            } else if (fld instanceof Array) {
              let arr = [];
              for (var e = 0; e < fld.length; e++) {
                let elm = fld[e];
                if (elm instanceof Object && !(elm instanceof Array)) {
                  elm = db_clone(elm);
                }
                arr.push(elm);
              } //@ts-expect-error
              res[f] = arr;
            }
          } else {
            //@ts-expect-error
            res[f] = obj[f];
          }
        }
      }
    }
  }
  return res;
}

//-----------------------------------------------------------------------------------------------
// INTERNAL FUNCTIONS
//-----------------------------------------------------------------------------------------------
//@ts-expect-error
function db_assign_fields_to_object(context, object, key, data, options) {
  let state = context.state;
  data.id = key;
  if (data.selected === undefined) {
    data.selected = true;
  }
  let opts: any = { ...options };
  let result_object;
  data.id = key;
  data.__fs = {};
  data.__fs.loaded = new Date().getTime();
  data.__fs.ref = opts.ref;
  data.__fs.version = opts.version;
  let change = opts.change;
  if (data.Table) {
    opts.table_name = data.Table;
    opts.data = data;
  }
  let definition = getDefinitionByData(data);
  if (definition === undefined) {
  }
  if (change.type === 'added') {
    if (object[key] === undefined) {
      if (opts.hook !== undefined) {
        opts.hook(data);
      }
      let DefaultClass = DBObject;
      let defaultName = 'NONE';
      data.collection_path = opts.collection_path; //@xts-expect-error
      let objClass = definition?.class ?? DefaultClass; //@xts-expect-error
      let objName = definition?.table ?? defaultName;
      //@ts-expect-error
      let objInstance = new objClass(data, context, objName);
      let use_data = objInstance;
      // Proxy(objInstance, db_proxy_handler,objName);
      object[key] = use_data;
      result_object = use_data;
      if (opts.auto_load !== false) {
        db_get_on_load_collections(context, use_data);
      }
    } else {
      for (let af in data) {
        if (data.hasOwnProperty(af)) {
          object[key][af] = data[af];
          object[key].data[af] = data[af];
        }
      }
    }
    let result_object_path = options.collection_path + '/' + key; //@ts-expect-error
    result_object = db_recurse_to_object(context, result_object_path);
  } else if (change.type === 'modified' && data.deleted === false) {
    if (options.hook !== undefined) {
      options.hook(data);
    }
    if (object[key] === undefined) {
      object[key] = data;
    } else {
      let obj_to_update = object[key];
      object[key]._change_from_db = true;
      for (var mf in data) {
        if (data.hasOwnProperty(mf)) {
          if (object[key].data === undefined) {
            object[key].data = {};
          }
          object[key][mf] = data[mf];
          object[key].data[mf] = data[mf];
        }
        object[key]._change_from_db = false;
      }
    }

    let result_object_path = options.collection_path + '/' + key;
    //@ts-expect-error
    result_object = db_recurse_to_object(context, result_object_path);
  } else if (change.type === 'removed' || data.deleted === true) {
    delete object[key];
  }
  // if (definition !== undefined && change.type !== 'removed') {
  //   for (let f in definition.collection_fields) {
  //     let field = definition.collection_fields[f];
  //     let fieldname = field.name;
  //     let collection_records = data[fieldname];
  //     if (collection_records !== undefined && collection_records !== null) {
  //       let sub_collection_path = field.datatype.json.path(object[key]);
  //       let isCollectionRecordsAnArray = Array.isArray(collection_records);
  //       if (isCollectionRecordsAnArray) {
  //         for (let r in collection_records) {
  //           let sub_record = collection_records[r];
  //           delete options.definition;
  //           let sub_options = db_clone(options);//@ts-expect-error
  //           sub_options.collection_path = sub_collection_path;//@ts-expect-error
  //           delete sub_options.return_collection_path;
  //           db_assign_document_to_state(context, sub_record, sub_collection_path, sub_record.id, sub_options);
  //         }
  //       } else {
  //         let sub_record = collection_records;
  //         let sub_options = db_clone(options);//@ts-expect-error
  //         sub_options.collection_path = sub_collection_path;//@ts-expect-error
  //         delete sub_options.return_collection_path;
  //         db_assign_document_to_state(context, sub_record, sub_collection_path, sub_record.id, sub_options);
  //       }
  //     }
  //   }
  // }

  if (options.callback !== undefined) {
    options.callback(data, options);
  }
  return result_object;
}

//-----------------------------------------------------------------------------------------------
//@ts-expect-error
function map_object(doc, id, options) {
  return doc;
}

//-----------------------------------------------------------------------------------------------
//@ts-expect-error
function db_get_on_load_collections(context, record) {
  return db_load_document_collections(context, record, 'load');
}

//-----------------------------------------------------------------------------------------------
//@ts-expect-error
function on_sub_document_loaded_success(sub_document, options) {
  let field = options.field;
  let original_record = options.record;
  original_record[field] = sub_document;
}

//-----------------------------------------------------------------------------------------------
//@ts-expect-error
export function db_load_document_collections(context, record, load_event) {
  let document_name = record.document_name; //@ts-expect-error
  let documents = getDefinitionByData(record)?.documents ?? {}; //@ts-expect-error
  let collections = getDefinitionByData(record)?.collections ?? {};
  do_db_load_document_collections(context, record, load_event, documents, collections);
}

//@ts-expect-error
export function do_db_load_document_collections(context, record, load_event, documents, collections) {
  for (let d in documents) {
    let doc = documents[d]; //@ts-expect-error
    let doc_id = _.get(record, doc.id_field);
    if (doc_id === undefined) {
    }
    let doc_path;
    if (typeof doc.collection === 'undefined') {
      //@ts-expect-error
      doc_path = `${record.collection_path}/${record.id}/${c}`;
    } else if (typeof doc.collection === 'string') {
      doc_path = doc.collection;
    } else if (typeof doc.collection === 'function') {
      doc_path = doc.collection(record, doc);
    }
    if (doc.load === load_event) {
      let opts = {}; //@ts-expect-error
      // if (doc.local_field === true) {
      opts.on_success = on_sub_document_loaded_success; //@ts-expect-error
      opts.record = record; //@ts-expect-error
      opts.field = d;
      // }
      context.dispatch('db_get_document', { collection_path: doc_path, id: doc_id, options: opts });
    }
  }

  for (let c in collections) {
    let collection = collections[c];
    if (collection.load === load_event) {
      let collection_path;
      if (typeof collection.collection === 'undefined') {
        collection_path = `${record.collection_path}/${record.id}/${c}`;
      } else if (typeof collection.collection === 'string') {
        collection_path = collection.collection;
      } else if (typeof collection.collection === 'function') {
        collection_path = collection.collection(record);
      }
      let options = {}; //@ts-expect-error
      options.live = collection.live !== false;
      let current_where = collection.where === undefined ? [['deleted', '==', false]] : collection.where;
      let use_where = [];
      for (let w = 0; w < current_where.length; w++) {
        let where = map_where(current_where[w], record, context);
        // [null,null,null];
        // where[0] = current_where[w][0];
        // where[1] = current_where[w][1];
        // where[2] = map_where(record,current_where[w][2]);
        use_where.push(where);
      } //@ts-expect-error
      options.where = use_where; //@ts-expect-error
      options.record = record;
      context.dispatch('db_get_collection', { collection_path: collection_path, options });
    }
  }
}
//@ts-expect-error
function map_where(where, record, context) {
  if (typeof where === 'function') {
    return where(record, context);
  } else if (typeof where === 'object' && where[2].field !== undefined) {
    return [where[0], where[1], record[where[2].field]];
  } else {
    return [where[0], where[1], where[2]];
  }
}

//-----------------------------------------------------------------------------------------------
