<template>
  <!-- <LFXPagedListView
    :definition="definition"
    action="getInwardSwifts"
    :actionParams="{}"
    :search="searchConfig"
    :defaultSort="defaultSort"
    :defaultWhere="accountSelector"
  > -->
  <LFXPagedListView
    :definition="definition"
    action="getInwardSwifts"
    :actionParams="{}"
    :search="searchConfig"
    :defaultSort="defaultSort"
    :defaultWhere="combinedSelector"
  >
  </LFXPagedListView>
  <router-view></router-view>
  <!-- <LFXListView v-if="hasListLoaded" :definition="definition" :records="records" /> -->
  <!-- <template v-else>
    <LDBLoader />
  </template> -->
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXPagedListView from '@/views/general/LFXPagedListView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

export default {
  name: 'LfxSwiftList',
  components: { LFXPagedListView, LDBLoader },
  props: {
    swiftFilter: { type: String, required: false, default: 'open' },
  },
  data() {
    return {
      searchConfig: {
        searchFields: ['swiftNumber'],
        choices: [
          {
            id: 'status',
            title: 'Statuses',
            chips: [
              {
                id: 'all',
                label: 'All',
                selector: {
                  status: { in: ['new', 'available', 'assigned', 'complete', 'cancelled', 'noClient', 'noBankAccount'] },
                },
              },
              { id: 'open', label: 'Open', selector: { status: { in: ['new', 'available'] } } },
              { id: 'available', label: 'Available', selector: { status: { in: ['available'] } }, default: true },
              { id: 'assigned', label: 'Assigned', selector: { status: { in: ['assigned'] } } },
              { id: 'complete', label: 'Completed', selector: { status: { in: ['complete'] } } },
              {
                id: 'noAccount',
                label: 'Missing Client or Account',
                selector: { status: { in: ['noClient', 'noBankAccount'] } },
              },
            ],
          },
        ],
      },
    };
  },
  async created() {},
  mounted() {},
  watch: {
    swiftFilter(newValue, old) {
      console.log('swiftFilter:', newValue);
    },
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account', 'swift']),
    defaultSort() {
      return { order: 'valueDate', sort: 'desc' };
    },
    viewedAccountId() {
      return +this.$route.params.accountId;
    },
    viewedAccountRecord() {
      return this.account[this.viewedAccountId];
    },
    viewedAccountLevel() {
      return this.viewedAccountRecord?.accountLevel;
    },
    accountSelector() {
      if (this.viewedAccountId) {
        switch (this.viewedAccountLevel) {
          case 'client':
            this.$route.meta.hideClientAndBranchName = true;
            return { accountId: this.viewedAccountId };
          default:
            if (this.includeBranchesInLists === true) {
              if (this.viewedAccountLevel === 'intermediary') {
                return { intermediaryId: this.viewedAccountId };
              } else {
                return { branchId: [this.viewedAccountId, ...this.viewedAccountRecord.childrenBranchIds] };
              }
            } else {
              return { branchId: this.viewedAccountId };
            }
        }
      }
      return undefined;
    },

    ageSelector() {
      const cutOffDate = new Date();
      // console.log('10 --', cutOffDate);
      const day = cutOffDate.getDate();
      cutOffDate.setDate(day - 45);
      // console.log('11 --', cutOffDate);
      return { valueDate: { gte: cutOffDate } };
    },

    combinedSelector() {
      // console.log('1', { ...this.accountSelector, ...this.ageSelector });
      return { ...this.accountSelector, ...this.ageSelector };
    },

    visibleStatus() {
      switch (this.swiftFilter) {
        case 'all':
          return ['new', 'available', 'assigned', 'complete', 'cancelled'];
        case 'open':
          return ['new', 'available'];
        case 'assigned':
          return ['assigned'];
        case 'complete':
          return ['complete'];
      }
    },
    // ----- Records -----
    swiftFilterWhere() {
      return { status: { in: this.visibleStatus } };
    },
    records() {
      let res = {};
      if (this.viewedAccountId) {
        for (let s in this.swift) {
          let swift = this.swift[s];
          if (this.viewedAccountLevel === 'client' && swift.accountId === this.viewedAccountId) {
            res[swift.id] = swift;
          } else if (
            (this.viewedAccountLevel === 'intermediary' || this.viewedAccountLevel === 'intermediaryBranch') &&
            swift.branchId === this.viewedAccountId
          ) {
            res[swift.id] = swift;
          }
        }
      } else {
        res = this.swift || {};
      }
      const returnRes = {};
      for (let swiftId in res) {
        if (this.swiftFilter === 'all' || this.visibleStatus.includes(res[swiftId].status)) {
          returnRes[swiftId] = res[swiftId];
        }
      }
      return returnRes;
    },
    definition() {
      return getDefinition('inward_swift');
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    // onSearchChange(evt) {
    //   this.definition.search_string = evt.detail.new;
    // },
  },
};
</script>

<style>
/* div.container {
} */
</style>
