<template>
  <LfxAdvancedSearch
    :definition="definition"
    :dataAction="dataAction"
    :baseSelectors="baseSelectors"
    @on-search-change="onSearchChange"
  />
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LfxAdvancedSearch from '@/views/search/LfxAdvancedSearch.vue';

export default {
  name: 'LfxClientSearch',
  emits:['on-search-change'],

  components: { LfxAdvancedSearch },
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),

    // ----- View -----

    definition() {
      return getDefinition('client');
    },
    dataAction() {
      return 'getAccounts'
    },
    baseSelectors() {
      return {accountLevel:'client'}
    }

  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    onSearchChange(searchChangeData) {
      this.$emit('on-search-change',{downloadAction:false,searchCriteria:searchChangeData});
    }

  },
};
</script>
