<template>
  <div class="lfx-edit-user-permissions-container">
    <div class="lfx-permission-group-container lfx-permission-category-name permissions header">
      <div class="lfx-permissions-group">
        <div class="lfx-permission-heading permissions-heading">
          <capitec-label label="Permissions" type="subtitle"></capitec-label>
        </div>
      </div>

      <div v-if="false" class="lfx-permissions-group">
        <div class="lfx-permission-heading">
          <capitec-label class="category-name-label" label="Requires Review" type="subtitle"></capitec-label>
        </div>
      </div>
      
      <div v-for="group in permissionGroups" :key="group.id" class="lfx-permission-group">
        <div class="lfx-permission-category-name column">
          <capitec-check
            :checked="userHasAllPermissionsForGroup(group)"
            @value-change="giveAllPermissionsInGroup(group, $event)"
          ></capitec-check>

          <capitec-label :label="`${group.name}`" class="category-name-label" type="subtitle"></capitec-label>
        </div>
      </div>
    </div>
    
    <div class="lfx-permission-group-container body">
      <div class="lfx-permissions-group">
        <div class="lfx-permission-heading">
          <!-- <capitec-label label="Permission" type="subtitle"></capitec-label> -->
        </div>
        <template v-for="(categoryName, category) in sortedCategories" :key="categoryName">
          <div v-if="categoryPermissions[categoryName] !== undefined" class="lfx-permission-category">
            <div class="lfx-permission-category-name row">
              <capitec-check v-if="false"
                :checked="userHasAllPermissionsInCategory(categoryName, $event)"
                @value-change="setAllPermissionsInCategory(categoryName, $event)"
              ></capitec-check>
              <capitec-label :label="`${categoryName}`" class="category-name-label" type="subtitle"></capitec-label>
            </div>
            <template v-for="permission in categoryPermissions[categoryName]" :key="permission.id">
              <div class="permission-check-wrapper column-header">
                <capitec-check
                  :checked="userHasPermission(permission)"
                  class="permission-check"
                  :label="`${permission.description} ${permission.requiresReview || specialCaseRequiresReview(permission) ? '*' : ''}`"
                  :disabled="inReview(permission)"
                  @value-change="onPermissionCheck(permission, $event)"
                >
                </capitec-check>
              </div>
            </template>
          </div>
        </template>
      </div>

  <template v-if="false">
      <div class="lfx-permissions-group">
        <div class="lfx-permission-heading">
          <!-- <capitec-label class="category-name-label" label="Requires Review" type="subtitle"></capitec-label> -->
        </div>
        
      
        <template v-for="(categoryName, category) in sortedCategories" :key="categoryName">
          <div v-if="categoryPermissions[categoryName] !== undefined" class="lfx-permission-category">
            <div class="lfx-permission-heading padding">&nbsp;</div>
            <template v-for="permission in categoryPermissions[categoryName]" :key="permission.id">
              <div class="permission-check-wrapper">
                <capitec-check
                  v-if="permission.requiresReview"
                  :checked="true"
                  class="permission-check"
                  :disabled="true"
                >
                </capitec-check>
                
              </div>
            </template>
          </div>
        </template>

      </div>
        </template>

      <div v-for="group in permissionGroups" :key="group.id" class="lfx-permission-group">
        <div class="lfx-permission-category-name">
          <!-- <capitec-check :checked="userHasAllPermissionsForGroup(group)" @value-change="giveAllPermissionsInGroup(group,$event)"></capitec-check> -->
          <!-- <capitec-label :label="`${group.name}`" class="category-name-label" type="subtitle"></capitec-label> -->
        </div>

        <template v-for="(categoryName, category) in sortedCategories" :key="categoryName">
          <div v-if="categoryPermissions[categoryName] !== undefined" class="lfx-permission-category">
            <div class="lfx-permission-heading padding">&nbsp;</div>
            <template v-for="permission in categoryPermissions[categoryName]" :key="permission.id">
              <div class="permission-check-wrapper" v-if="groupProvidesPermission(group, permission)">
                <div :class="`cell-${inReview(permission) ? 'in-review' : 'not-in-review'} cell-${userHasPermission(permission) ? 'has-permission' : 'no-permission'}`"></div>
                <capitec-check v-if="false"
                  class="permission-check"
                  :checked="userHasPermission(permission)"
                  :disabled="inReview(permission)"
                  @value-change="onPermissionCheck(permission, $event)"
                >
                </capitec-check>
              </div>

              <div class="permission-check-wrapper" v-else>&nbsp;</div>

            </template>
          </div>
        </template>
        
      </div>
    </div>

    <div v-if="false && toBeCompleted" class="lfx-permission-headings">
    
      <div class="lfx-permission-heading">Permissions</div>
      
      <!-- <div class="lfx-permission-heading">Requires Review</div> -->

      <div v-for="group in permissionGroups" :key="group.id" class="lfx-permission-group">

        <!-- <capitec-check v-if="userHasAllPermissionsForGroup(group)" :label="`(ALL) ${group.name}`" :checked="true" /> -->

        <!-- <capitec-check
          v-else-if="userHasAllPermissionsInReview(group)"
          :label="`(ALL, SOME PENDING) ${group.name}`"
          :indeterminate="true"
        /> -->

        <!-- <capitec-check v-else :label="`${group.name}`" :checked="false" /> -->
      </div>
    </div>
    <template>
      <template v-for="category in sortedCategories" :key="category">
        <div v-if="categoryPermissions[category] !== undefined" class="lfx-permission-category">
          <div class="lfx-category-label">{{ category }}</div>

          <div class="lfx-category-permissions">
            <template v-for="permission in categoryPermissions[category]" :key="permission.id">
              <div class="lfx-category-permission">
                
                <div class="lfx-category-permission-check">
                
                  <capitec-check
                    v-if="userHasPendingPermission(permission)"
                    :label="permission.description"
                    :indeterminate="true"
                    :disabled="inReview(permission)"
                    @value-change="on_change(permission, $event)"
                  />
                  
                  <capitec-check
                    v-else
                    :label="permission.description"
                    :checked="userHasPermission(permission)"
                    :disabled="inReview(permission)"
                    @value-change="on_change(permission, $event)"
                  />
                </div>

                <!-- <template v-if="toBeCompleted">
                <div v-if="permission.requiresReview">

                </div>
              </template> -->
                test
                <template v-if="toBeCompleted">
                  <div v-for="group in permissionGroups" :key="group.id" class="lfx-permission-group">
                    <div v-if="groupProvidesPermission(group, permission)" class="group-provides-permission-indicator">
                      pong
                      <capitec-check :checked="true" />
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </template>
    </template>
    <!-- <div v-for="permission in permissions" :key="permission.id">
      <div>{{ permission.description }}</div>
      <div class="description">{{ getActionsForPermission(permission.id) }}</div>
    </div> -->
    <div class="requires-review-note">
      <br/>
      <capitec-label label="Permissions marked (*) require review and approval from the bank. They will go into a Pending state when requested and may take a few days to be approved.">
      </capitec-label>
    </div>

    <div class="form-item-action-bar">
      <capitec-button v-if="showBackButton" label="Back" type="clear" @click="goBack" />

      <capitec-spacer />
      <LDBButton
        v-for="button in formButtonsRight"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.params"
        :params="button.params"
        :type="button.type"
        :enabledGuards="button.enabledGuards"
        :viewGuards="button.guards"
        @click="button.clickEvent"
      >
      </LDBButton>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'LfxEditUserPermissions',
  components: {},
  props: {},
  emits: ['step-back', 'step-next'],
  data() {
    return {
      requiredPermissions: {},
    };
  },
  created() {
    this.user[this.userId].activate();
  },
  mounted() {
    this.resetPermissions();
  },
  computed: {
    ...mapGetters(['config']),
    ...mapState(['user', 'account', 'authUser']),

    formButtonsRight() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelEditPermissions',
          label: 'Cancel',
          type: '',
          action: 'click',
          guards: true,
          params: {},
          enabledGuards: true,
          clickEvent: this.cancelPermissionsEdit,
        },

        {
          name: 'save',
          label: 'Save',
          type: this.saveButtonType,
          action: 'updateUserPermissions',
          params: { userId: +this.userId, requiredPermissions: this.requiredPermissionsArray },
          clickEvent(response) {
            if (response.status === 'success' && !self.$route.meta.wizard) {
              // self.$router.replace({ name: 'userPermissionsView' });
              self.$router.go(-1);
            }
          },
        },
        {
          name: 'next',
          label: 'Next',
          type: 'primary',
          action: 'updateUserPermissions',
          params: { userId: +this.userId, requiredPermissions: this.requiredPermissionsArray },
          guards: !!this.showNextButton,
          clickEvent(response) {
            if (response.status === 'success') {
              self.$emit('step-next');
            }
          },
        },
      ];
      return allButtons;
    },

    toBeCompleted() {
      return true;
    },
    userId() {
      return +this.$route.params.userId;
    },
    routeUser() {
      return this.user[this.userId];
    },
    userAccount() {
      return this.account[this.routeUser.accountId];
    },
    userAccountLevel() {
      return this.userAccount.accountLevel;
    },
    userPermissions() {
      return this.user?.[this.userId]?.userPermissions;
    },

    loggedInUserPermissions() {
      return this.authUser.permissions;
    },
    permissionGroups() {
      let res = [];
      for (let g in this.config.permissionGroup) {
        let permissionGroup = this.config.permissionGroup[g];
        if (permissionGroup.accountLevels.includes(this.userAccountLevel)) {
          let group = {};
          group.name = permissionGroup.name;
          group.id = permissionGroup.id;
          group.status = permissionGroup.status;
          group.accountLevels = permissionGroup.accountLevels;
          let members = {};
          for (let m = 0; m < permissionGroup.Members.length; m++) {
            let member = permissionGroup.Members[m];
            let memberName = member.permissionId;
            members[memberName] = true;
          }
          group.members = members;
          if (group.name) {
            res.push(group);
          }
        }
      }
      return res;
    },
    sortedCategories() {
      return ['Basic', 'Clients', 'Transactions', 'Organisation Management', 'System Admin', 'System Config'];
    },
    permissionCategories() {
      let res = new Set();
      for (let p = 0; p < this.permissions.length; p++) {
        let permission = this.permissions[p];
        res.add(permission.category);
      }
      return Array.from(res);
    },
    categoryPermissions() {
      let res = {};
      for (let p in this.permissions) {
        let perm = this.permissions[p];
        if (perm.category) {
          if (res[perm.category] === undefined) {
            res[perm.category] = {};
          }
          res[perm.category][perm.id] = perm;
        }
      }
      return res;
    },
    systemPermissions() {
      return this.config.permission;
    },
    permissions() {
      let self = this;
      let res = {};
      for (let p in this.config.permission) {
        let perm = this.config.permission[p];
        if (
          perm.requestPermissions.length === 0 ||
          perm.requestPermissions.some((p) => self.loggedInUserPermissions.includes(p))
        ) {
          if (perm.accountLevels.includes(this.userAccountLevel) && perm.status === 'active' && !this.specialCaseExcludePermission(perm)) {
            res[perm.id] = perm;
          }
        }
      }
      return res;
    },
    cancelRoute() {},
    requiredPermissionsArray() {
      let requiredPermissions = [];
      for (let p in this.requiredPermissions) {
        if (this.requiredPermissions[p] === true) {
          requiredPermissions.push(p);
        }
      }
      return requiredPermissions;
    },

    // ----- Form buttons -----

    showBackButton() {
      return this.$route.meta?.content?.formButtons?.showBackButton;
    },
    saveButtonType() {
      return this.showNextButton ? '' : 'primary';
    },
    showNextButton() {
      return this.$route.meta?.content?.formButtons?.showNextButton;
    },
  },
  watch: {
    userPermissions(newValue, oldValue) {
      if (newValue) {
        this.resetPermissions();
      }
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    permissionInReview(permissionId) {},
    setAllPermissionsInCategory(categoryName, event) {
      let permissions = this.categoryPermissions[categoryName];
      let checked = event.detail.new;
      for (let p in permissions) {
        if (this.loggedInUserHasPermissionToAssign(p)) {
          this.requiredPermissions[p] = checked;
        }
      }
    },
    userHasAllPermissionsInCategory(categoryName) {
      let permissions = this.categoryPermissions[categoryName];
      let res = true;
      for (let p in permissions) {
        if (this.requiredPermissions[p] !== true) {
          res = false;
          break;
        }
      }
      return res;
    },

    giveAllPermissionsInGroup(group, event) {
      let checked = event.detail.new;
      for (let p in group.members) {
        if (this.permissions[p] && this.loggedInUserHasPermissionToAssign(p)) {
          this.requiredPermissions[p] = checked;
        }
      }
    },
    onPermissionCheck(permission, event) {
      let checked = event.detail.new;
      let permissionId = permission.id;
      this.requiredPermissions[permissionId] = checked;
    },
    resetPermissions() {
      for (let p in this.routeUser.permissions) {
        this.requiredPermissions[p] =
          this.routeUser.permissions[p] === 'pending' || this.routeUser.permissions[p] === 'active';
      }
    },
    cancelPermissionsEdit() {
      this.resetPermissions();
      this.$router.go(-1);
      // this.$router.replace({ name: this.cancelRoute });
    },
    on_change(permission, evt) {
      this.requiredPermissions[permission.id] = evt.detail.new;
    },
    // async savePermissions(evt) {
    //   let requiredPermissions = [];
    //   for (let p in this.requiredPermissions) {
    //     if (this.requiredPermissions[p] === true) {
    //       requiredPermissions.push(p);
    //     }
    //   }
    //   let response = await this.routeUser.setPermissions(requiredPermissions);
    //   if (response.status === 'success' && this.$route.matched[0].name === 'user') {
    //     this.$router.replace({ name: 'userPermissionsView' });
    //   }
    //   return response;
    // },
    // async savePermissionsAndNext() {
    //   let response = await this.savePermissions(); // TODO error handling
    //   if (response.status === 'success') {
    //     this.$emit('step-next');
    //   }
    // },
    goBack() {
      this.$emit('step-back');
    },
    
    on_permissions_updated(permissionUpdateResponse) {},
    
    userHasAllPermissionsForGroup(group) {
      let res = true;
      for (let p in group.members) {
        if (this.permissions[p] && this.requiredPermissions[p] !== true && this.loggedInUserHasPermissionToAssign(p)) {
          res = false;
          break;
        }
      }
      return res;
    },
    
    userHasAllPermissionsInReview(group) {
      let res = true;
      for (let p in group.members) {
        res = res && (this.routeUser.permissions[p] === 'active' || this.routeUser.permissions[p] === 'pending');
      }
      return res;
    },

    groupProvidesPermission(group, permission) {
      return group.members[permission.id] === true;
    },

    inReview(permission) {
      return this.routeUser.hasPendingPermissions && this.systemPermissions[permission.id].requiresReview === true;
    },

    userHasPermission(permission) {
      return this.requiredPermissions[permission.id] === true || this.userHasPendingPermission(permission);
    },

    userHasPendingPermission(permission) {
      return this.routeUser.permissions[permission.id] === 'pending';
    },
    loggedInUserHasPermissionToAssign(permissionName) {
      const permission = this.systemPermissions[permissionName];
      if (!permission.requestPermissions || permission.requestPermissions.length === 0) {
        return true
      }
      if (permission.requestPermissions.some((p) => this.loggedInUserPermissions.includes(p))) {
        return true
      }
      return false
    },
    specialCaseExcludePermission(permission) {
      if (permission.id === 'clientSubmitPayment') {
        const intermediary = this.account[this.routeUser.intermediaryId];
        return !(intermediary?.allowClientsToSubmitPortfolios === true)
      }
      if (permission.id === 'processBukFees') {
        const intermediary = this.account[this.routeUser.intermediaryId];
        return !(intermediary?.mayProcessBulkFees === true)        
      }
      return false
    },
    specialCaseRequiresReview(permission) {
      if (permission.id === 'clientBookDeal') {
        const intermediary = this.account[this.routeUser.intermediaryId];
        return (intermediary?.allowClientsToSubmitPortfolios === true)
      }
      return false
    }
  },
};
</script>

<style>

.category-name-label {
  white-space: nowrap;  
}

.requires-review-note{
  margin-left:15px;
  margin-right:15px;
  font-style: italic;
}

.lfx-permission-heading.permissions-heading{
  min-height: 46px;
  flex:1;
  padding-left:10px;
}

div.lfx-permission-heading,
div.lfx-permission-category-name {
  align-content: stretch;
  white-space: nowrap;
  background-color: #eee;
}


div.lfx-permission-category-name.column
{
  display:flex;
  flex-direction: column-reverse;
  align-items:center;
  justify-content: center;
  background-color:#DDD;
  min-height: 54px;
}

div.lfx-permission-category-name.permissions{
  background-color:#DDD;
}

.cell-in-review,
.cell-not-in-review,
.cell-has-permission,
.cell-no-permission{
  --dot-color:var(--theme-status-info-background);
  border: 1px solid var(--dot-color);
  background-color:transparent;
  border-radius:30px;
  min-width:15px;
  min-height:15px;
  margin-left:7px;
}

.cell-has-permission.cell-in-review{
  --dot-color:var(--theme-status-graph-yellow-background);
  background-color:var(--dot-color);
}

.cell-has-permission.cell-not-in-review{
  --dot-color:var(--theme-status-info-background);
  background-color:var(--dot-color);
}

div.lfx-permission-category-name {
  display: flex;  
  padding-left: 10px;
}

.permission-check-wrapper.column-header {
  padding-left: 20px;
}

div.lfx-permission-category-name > capitec-check > div.container > div#content > label.label {
  margin-left:0px;
  --theme-check-label-spacing: 8px;
  display:none;
}

div.lfx-permission-category-name.column > capitec-check {
  display:flex;
  flex-direction: column;
  padding-left:12px;
}

.container > .label{
  --theme-check-label-spacing: 0px !important;
}


div.lfx-permission-category-name.column > capitec-check {
  margin-right: 0px;
}

div.permission-check-wrapper {
  min-height: 32px;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.permission-check-wrapper.column-header {
  justify-content: flex-start;
}

div.permission-check-wrapper:nth-child(odd) {
  background-color: #f9f9f9;
}

.lfx-permission-group-container {
  display: flex;
}

.lfx-permission-group-container.header{
  margin-right:15px;
}

.lfx-permission-group-container.body {
  overflow-y: scroll;
}

.permission-check {
  white-space: nowrap;
}

div.lfx-edit-user-permissions-container {
  user-select: auto;
  overflow-y: auto;
}

div.lfx-permission-headings {
  display: flex;
}

div.lfx-permission-heading {
  flex: 1;
}

div.lfx-permission-category {
  border-left: 1px dotted #ccc;
}

div.lfx-category-label {
  flex: 1;
}

div.lfx-category-permission {
  display: flex;
}

div.lfx-category-permission-check {
  flex: 1;
}

div.lfx-permission-group {
  flex: 1;
}

div.lfx-permissions-group {
  flex: 1;
}

div.lfx-category-permissions {
  flex: 1;
}

div.description {
  font-size: 12px;
  color: #999;
  font-style: italic;
}

.form-item-action-bar {
  flex: auto;
  max-height: 32px;
}

div.lfx-permission-heading.permissions-heading{
  background-color:#DDD;
  padding-bottom:10px;
  padding-top:3px;
}

.lfx-permission-category-name.column > .category-name-label{
  margin-bottom:5px;
}

</style>
