//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxPublicHolidayDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Public Holiday record for the LandoFX System
//  Created     : 25 October 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import { standardTextWhiteList } from '../sharedUtils/LdbValidations';
import type { LdbDefinition } from './LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------

export type LfxPublicHolidayStatus = 'active' | 'disabled';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxPublicHolidayInt {
  id?: string;
  currencyId?: string;
  name?: string;
  date?: Date;
  status?: LfxPublicHolidayStatus;

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxPublicHolidayDef: LdbDefinition = {
  title: 'Public Holiday',
  table: 'LfxPublicHoliday',
  collectionPath: '/config/1/publicHoliday',
  database: 'dynamo',
  view: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
  indexes: ['currencyId', 'date'],
  pagingType: 'backEnd',
  groups: {
    details: { priority: 1, label: 'Details' },
    changes: { priority: 2, label: 'Changes' },
  },
  fields: {
    id: {
      label: 'Name',
      datatype: { id: { descriptionField: 'name', type: 'text' } },
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'details',
    },
    currencyId: {
      label: 'Currency',
      datatype: {
        foreignKey: {
          linkTable: 'LdbCurrency',
          linkField: 'id',
          displayField: 'id',
          type: 'text',

          collection: 'fk_currencies',
        },
      },
      default: null,
      mandatory: true,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'details',
    },
    name: {
      label: 'Name',
      datatype: 'text',
      default: null,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Name should be between 5 and 50 characters', args: [5, 50] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'details',
    },
    date: {
      label: 'Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
          minDate: { type: 'day', offset: 0 },
          maxDate: { type: 'year', offset: 2 },
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'details',
    },

    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'active', name: 'Active', tagType: 'success' },
            { id: 'disabled', name: 'Disabled', tagType: 'error' },
          ],
        },
      },
      default: 'active',
      mandatory: true,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'details',
    },

    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
  },
};
