<template>
  <!-- <div class="lfx-intermediary-markup-report"> -->
    <capitec-action-bar class="lfx-intermediary-markup-report">
        <LDBButton
          v-for="button in leftButtons"
          :key="button.name"
          :label="button.label"
          :action="button.action"
          :actionParams="button.actionParams"
          :params="button.params"
          :type="button.type"
          :tooltip="button.tooltip"
          :modal="button.modal"
          :viewGuards="button.guards"
          :enabledGuards="button.enabledGuards"
          :onSuccess="button.onSuccess"
          @beforeClick="button.beforeClick"
          @click="button.onClick"
          @on-api-success="button.onApiSuccess"
          @on-api-error="button.onApiError"
        >
          <template v-if="button.modal !== undefined" #modal>
            <div v-html="button.modal.content"></div>
          </template>
        </LDBButton>
        <capitec-spacer> </capitec-spacer>
        <LDBButton
          v-for="button in rightButtons"
          :key="button.name"
          :label="button.label"
          :action="button.action"
          :actionParams="button.actionParams"
          :params="button.params"
          :type="button.type"
          :tooltip="button.tooltip"
          :modal="button.modal"
          :viewGuards="button.guards"
          :enabledGuards="button.enabledGuards"
          :onSuccess="button.onSuccess"
          @beforeClick="button.beforeClick"
          @click="button.onClick"
          @on-api-success="button.onApiSuccess"
          @on-api-error="button.onApiError"
        >
          <template v-if="button.modal !== undefined" #modal>
            <div v-html="button.modal.content"></div>
          </template>
        </LDBButton>
    </capitec-action-bar>
    <capitec-content-box>
      <div class="lfx-intermediary-markup-report-view-table-container">
        <table-lite
          id="lfx-intermediary-markup-report-view-table"
          :is-static-mode="true"
          :is-slot-mode="true"
          :has-checkbox="false"
          :is-loading="false"
          :is-re-search="false"
          :columns="tableColumns"
          :rows="sortedRecords"
          :pageSize="sortedRecords.length"
          :total="sortedRecords.length"
        >
          <template v-for="(col, i) of tableColumns" v-slot:[col.field]="data" :key="i">
            <template v-if="col.showAsCurrency">{{ formatCurrency(data.value[col.field]) }}</template>
            <!-- <template v-if="col.field?.amount">ZAR {{data.value[col.field].amount}}</template> -->
            <template v-else>{{ data.value[col.field] }}</template>
          </template>
        </table-lite>
      </div>
    </capitec-content-box>
  <!-- </div> -->
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBField from '@/landobyte_vue/LDBField.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import TableLite from '@/views/general/TableLite.vue';
import { getOptionLabel } from '@/sharedUtils/LdbDefinitionUtils';
import { getDefinition } from '@/models/LdbDefinitions';
import { createWorkSheet,createWorkbookAndWriteToFile } from '@/utils/LfbFrontEndSpreadSheets'

export default {
  name: 'LfxIntermediaryMarkupReport',
  components: { LDBField, LDBLoader, TableLite },
  props: {},
  data() {
    return {
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters(['config']),
    ...mapState(['IntermediarySettings','account',]),
    accountDefinition() {
      return getDefinition('intermediary')
    },

    // ----- Buttons -----
    leftButtons() {
      return []
    },
    rightButtons() {
      let self = this;
      return [
        {
          name: 'exportData',
          label: 'Download',
          type: 'primary',
          action: 'click',
          onClick() {
            self.createAndServeFile()
          },
        },
      ];
    },

    // ----- TABLE -----

    tableColumns() {
      let self = this;
      let res = [
        {
          col: 'nameColumn',
          field: 'name',
          label: 'Name',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'statusColumn',
          field: 'status',
          label: 'Status',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
      ];
      for (const currencyPairId in this.currencyPairs) {
        const currencyPair = this.currencyPairs[currencyPairId]
        res.push({
          col: currencyPair.id+'Column',
          field: currencyPair.id,
          label: currencyPair.name,
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        })
      }
      return res.filter((f) => f.guards === true);
    },

    // ---- DATA ----
    currencyPairs() {
      return this.config.currencyPair
    },

    allRecords() {
      const res = []
      for (const intermediarySettingsId in this.IntermediarySettings) {
          const intermdiarySettings = this.IntermediarySettings[intermediarySettingsId]
          const intermediary = this.account[intermdiarySettings.accountId]
          intermediary.parentMarkups
          if (intermediary.accountLevel !== 'bank') {
            const intermediaryObject = {
              name:intermediary.name,
              status:getOptionLabel(this.accountDefinition,'status',intermediary.status),
            }
          for (const markupId in intermediary.markup || {}) {
            intermediaryObject[intermediary?.markup?.[markupId]?.currencyPair] = intermediary?.markup?.[markupId]?.calculatedMarkup 
          }
          res.push(intermediaryObject)
        }
      }
      return res;
    },

    sortedRecords() {
      return this.allRecords
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    on_change({ field, value }) {
    },
    createAndServeFile() {
      const fileArray = [];
      for (const dataLine of this.sortedRecords) {
        const dataLineObject = {};
        for (const column of this.tableColumns) {
          if (column.type !== 'button') {
            dataLineObject[column.label] = dataLine[column.field] || ""
          }
        }
        fileArray.push(dataLineObject)
      }
      const worksheet = createWorkSheet(fileArray);
      createWorkbookAndWriteToFile("IntermediaryMarkupReport-" + (new Date).toISOString().substring(0,19) + ".xlsx",worksheet)
    }      
  },
}
</script>

<style>

.lfx-intermediary-markup-report-view-table-container{
  position:relative;
  --table-container-height:calc(100% - 70px);
  min-height: var(--table-container-height);
  max-height: var(--table-container-height); 
}
</style>
