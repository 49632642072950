<template>
  <LDBModal result="info" :header="header"  size="x-large">
    <template #body>
      <div v-if="mounted">
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="item"
          :fieldname="field.name"
          :definition="definition"
          :record="swiftRecord"
          @change="on_change"
        />
        <br/>
        <h3 v-if="accountStatus === 'retrieving'">Retriving accounts for CIF {{this.clientCif}} and account {{ this.accountNumber }}</h3>
        <h3 v-else-if="accountStatus === 'error'">Error retriving accounts for CIF {{this.clientCif}} and account {{ this.accountNumber }} - {{accountError}}</h3>
        <template v-else-if="accountStatus==='retrieved'">
          <template v-if="foundAccounts.length > 0">
            <LDBField
              v-for="(field, fieldname) in formFields"
              :key="fieldname"
              :field="field"
              view="edit"
              :fieldname="field.name"
              :definition="definition"
              :record="submitRecord"
              :guards="field.guards"
              @change="on_change"
            />
          </template>
          <h3 v-else>No accounts found for CIF {{this.clientCif}} and account {{ this.accountNumber }}</h3>
        </template>
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
        @on-api-success="onApiSuccess"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBField from '@/landobyte_vue/LDBField.vue';
import { doPermissionAction } from '@/lfx_rest/lfx_action';
import { getOptionLabel } from '@/sharedUtils/LdbDefinitionUtils';

export default {
  name: 'LfxSwiftAssignToBankAccount',
  components: { LDBField },
  emits: ['on-api-success'],
  props: {},
  data() {
    return {
      submitRecord: {bankAccountId:undefined},
      accountStatus: 'none',
      accountError: undefined,
      foundAccounts: [],
      mounted: true,
    };
  },
  created() {},
  mounted() {
    this.retrieveAccounts()
  },
  beforeUnmount() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['swift']),
    header() {
      return `Assign SWIFT to Bank Account`;
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

        {
          name: 'assignSwiftToBankAccount',
          label: 'Assign SWIFT to Bank Account',
          type: 'primary',
          action: 'assignSwiftToBankAccount',
          params: {
            swiftId: this.swiftRecord.id,
          },
          actionParams: this.submitRecord,
          guards: true,
          clickEvent(response) {
            if (response.status === 'success') {
              self.$router.go(-1);
            }
          },
        },
      ];
      return allButtons !== undefined ? allButtons : [];
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : '/';
    },

    // ----- Definition -----

    definition() {
      return getDefinition('inward_swift');
    },
    bankAccountDefinition() {
      return getDefinition('bankAccount');
    },

    // ----- Record -----
    recordName() {
      return 'swift';
    },
    swiftId() {
      return +this.$route.params.swiftId;
    },
    swiftRecord() {
      let rec = this.swift[this.swiftId];
      return rec;
    },

    // ----- Fields -----

    swiftFields() {
      return this.definition.fields;
    },
    viewFieldNames() {
      return [
        'swiftNumber',
        'currencyId',
        'amount',
        'messageCifNumber',
        'messageAccountNumber'
      ]
    },
    viewFields() {
      const res = [];
      for (const fieldName of this.viewFieldNames) {
        const fields = this.swiftFields.filter((field) => field.field === fieldName);
        if (fields.length > 0) {
          res.push(fields[0]);
        }
      }
      return res;
    },

    formFields() {
      let res = [
      {
        name: 'bankAccountId',
        label: 'Select Bank Account to Assign SWIFT',
        datatype: {
          option: {
            optionType: 'number',
            options: this.bankAccountOptions,
          },
        },
        default: null,
        displayType: {
          datatype: 'option',
          display: 'radio',
        },
      }
      ];
      return res;
    },
    clientCif() {
      return this.swiftRecord.messageCifNumber
    },
    accountNumber() {
      return this.swiftRecord.messageAccountNumber
    },
    bankAccountOptions() {
      const res = [];
      for (const bankAccount of this.foundAccounts) {
        const statusText = getOptionLabel(this.bankAccountDefinition,'status',bankAccount.status);
        const bankAccountLabel = bankAccount.accountNumber + " "+ bankAccount.currencyId + " ("+ statusText + ") - " + bankAccount.description
        res.push(  { id: bankAccount.id, name: bankAccountLabel },)
      }
      return res
    }
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    on_change({ field, value }) {
      this.submitRecord[field] = value;
    },
    async retrieveAccounts() {
    this.accountStatus = "retrieving"
      const clientResult = await doPermissionAction(this, 'getBankAccounts',{accountId:this.swiftRecord.accountId}, { 
        additionalSelector:{where:{
          isBusinessBankAccount:true,
          accountNumber:this.accountNumber
        }}
    });
    if (clientResult.status === 'success') {
      this.foundAccounts = clientResult.response.rows
      this.accountStatus = "retrieved"
    } else {
      this.clientStatus = "error"
      this.clientError = clientResult.error
    }

  },
    // ----- API calls -----
    async onApiSuccess(_responseObject) {},
  },
};
</script>

<style></style>
