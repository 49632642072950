<template>
<slot> </slot>
</template>

<style>

:root {


  /* MENU */

  --theme-menu-item-hover-background-color:#EAEAEA;
  --theme-menu-item-font-color-selected:#141414;
  

  --theme-color--base: rgb(0, 72, 109);
  --theme-color--highlight: rgb(0, 157, 224);
  --theme-color--accent: rgb(230, 20, 20);
  --theme-color--flat: rgb(225, 225, 225);
  --theme-color--dark: rgb(93, 115, 126);
  --theme-color--text: rgb(58, 58, 58);
  --theme-color-status--success: rgb(112, 175, 0);
  --theme-color-status--info: rgb(8, 166, 181);
  --theme-color-status--warning: rgb(212, 120, 28);
  --theme-color-status--error: rgb(165, 19, 42);
  --theme-font-size: 12pt;
  font-size: var(--theme-font-size);
  color: var(--theme-color--text);
  

}

</style>

