<template>
  <div :class="`ldb-tile-container ${colorClass} ${activeClass}`" :style="gridStyle" @click="onTileClick">
    <div class="tile-cell arrow">
      <!-- <capitec-icon icon="system/chevron-right" size="small" /> -->
    </div>

    <div v-for="field in renderFields" :key="field" :class="field" :style="fieldStyle(field)" class="tile-cell">
      <slot :name="slotName(field)" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  emits: ['on-tile-click'],
  components: {},
  props: {
    layout: {
      type: Array,
      default() {
        return [
          ['title', 'arrow'],
          ['subtitle', '.'],
          ['info', 'infoLabel'],
        ];
      },
    },
    color: {
      type: Object,
      default() {
        return {};
      },
    },
    layoutFields: {
      type: Object,
      default() {
        return { title: 'name', subtitle: 'reference', info: 'timestamp', infoLabel: 'date' };
      },
    },
    active: { type: Boolean, default: false },
    values: { type: Object, required: true },
    useIcon: { type: Boolean, default: false },
    icon: { type: String },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    activeClass() {
      return this.active ? 'active' : 'not-active';
    },
    renderFields() {
      return Array.from(new Set(this.layout.flatMap((a) => a))).filter((s) => s !== '.' && s !== 'arrow');
    },
    colorClass() {
      if (this.color.colorType === 'class') {
        if (this.color.type == 'field') {
          let fieldName = this.color.field;
          let colorValue = this.values[fieldName];
          let colorClass = '';
          for (let o in this.color.config) {
            let conf = this.color.config[o];
            if (conf.id === colorValue) {
              let res = conf[this.color.optionField];
              return res;
            }
          }
          return colorClass;
        }
      }
      return '';
    },
    gridStyle() {
      let areas = this.layout.map((r) => r.join(' '));
      areas = areas.join("' '");
      areas = `'${areas}'`;
      let res = {
        display: 'grid',
        'grid-template-areas': areas,
        'grid-template-rows': `repeat(${this.layout.length},auto)`,
        'grid-template-columns': `repeat(${this.layout[0].length},auto)`,
      };
      return res;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    onTileClick(evt) {
      this.$emit('on-tile-click', this.values);
    },
    slotName(field) {
      if (this.layoutFields[field] === undefined) {
        return field;
      } else {
        return this.layoutFields[field];
      }
    },
    fieldStyle(area) {
      return { 'grid-area': area };
    },
  },
};
</script>
<style>
.ldb-tile-container {
  display: grid;
  margin-bottom: 10px;
  border-radius: 5px;
  /* grid-template-areas:
    'title arrow'
    'subtitle .'
    '. info'
    '. infoLabel'; */
  grid-template-columns: auto auto;
  cursor: pointer;
  position: relative;
  min-width: 230px;
  --tile-color: var(--theme-status-info-background);
  --light-color: var(--theme-tag-info-background-color);
  padding: 5px;
}

.tile-cell {
  display: grid;
  align-items: center;
  justify-content: left;
  position: relative;
  padding-left: 10px;
  pointer-events: none;
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tile-cell:nth-child(even) {
}

.tile-cell.status {
  justify-content: right;
}

.tile-cell {
}

.tile-cell.arrow {
  /* position: absolute;
  right: 10px;
  top: 10px;
  text-align: right;
  align-content: right;
  display: flex;
  align-content: center;
  justify-content: flex-end; */
  grid-area: arrow;
  max-width: 10px;
  min-width: 10px;
  align-self:stretch;
  justify-self: end;
  display:flex;
  align-items:center;
  justify-content:center;
  position:relative;
}

.tile-cell.arrow::after{
  content:'';
  border-top-width: 3px;
  border-top-style: solid;
  border-top-color: var(--tile-color);

  border-right-width: 3px;
  border-right-style: solid;
  border-right-color: var(--tile-color);

  min-width: 10px;
  max-width: 10px;
  min-height: 10px;
  max-height: 10px;
  position:absolute;
  transform-origin:center center;
  transform: rotateZ(45deg);
  left:2px;
}

.undefined {
  --tile-color: #777;
  --light-color: #aaa;
}

:root {
  --theme-status-graph-darkgreen-background: #006f3c;
  --theme-tag-graph-darkgreen-background-color: #006f3c26;

  --theme-status-graph-blue-background: #01bbf1;
  --theme-tag-graph-blue-background-color: #01bbf126;

  --theme-status-graph-green-background: #71b62c;
  --theme-tag-graph-green-background-color: #71b62c26;

  --theme-status-graph-orange-background: #f63e02;
  --theme-tag-graph-orange-background-color: #f63e0226;

  --theme-status-graph-velvet-background: #aa1580;
  --theme-tag-graph-velvet-background-color: #aa158026;

  --theme-status-graph-purple-background: #852cb3;
  --theme-tag-graph-purple-background-color: #852cb326;

  --theme-status-graph-red-background: #f63e02;
  --theme-tag-graph-red-background-color: #f63e0226;

  --theme-status-graph-yellow-background: #ffc122;
  --theme-tag-graph-yellow-background: #ffc12226;

  --theme-status-graph-grey-background: #f5f5f5;
  --theme-tag-graph-grey-background-color: #7c7c7c;
}

.graph-darkgreen {
  --tile-color: var(--theme-status-graph-darkgreen-background);
  --light-color: var(--theme-tag-graph-darkgreen-background-color);
}

.graph-blue {
  --tile-color: var(--theme-status-graph-blue-background);
  --light-color: var(--theme-tag-graph-blue-background-color);
}

.graph-green {
  --tile-color: var(--theme-status-graph-green-background);
  --light-color: var(--theme-tag-graph-green-background-color);
}

.graph-orange {
  --tile-color: var(--theme-status-graph-orange-background);
  --light-color: var(--theme-tag-graph-orange-background-color);
}

.graph-velvet {
  --tile-color: var(--theme-status-graph-velvet-background);
  --light-color: var(--theme-tag-graph-velvet-background-color);
}

.graph-purple {
  --tile-color: var(--theme-status-graph-purple-background);
  --light-color: var(--theme-tag-graph-purple-background-color);
}

.graph-red {
  --tile-color: var(--theme-status-graph-red-background);
  --light-color: var(--theme-tag-graph-red-background-color);
}

.graph-yellow {
  --tile-color: var(--theme-status-graph-yellow-background);
  --light-color: var(--theme-tag-graph-yellow-background-color);
}

.graph-grey {
  --tile-color: var(--theme-status-graph-grey-background);
  --light-color: var(--theme-tag-graph-grey-background-color);
}

.success {
  --tile-color: var(--theme-status-success-background);
  --light-color: var(--theme-tag-success-background-color);
}

.warn {
  --tile-color: var(--theme-tag-warning-font-color);
  --light-color: var(--theme-tag-warning-background-color);
}

.info {
  --tile-color: var(--theme-status-info-background);
  --light-color: var(--theme-tag-info-background-color);
}

.error {
  --tile-color: var(--theme-status-error-background);
  --light-color: var(--theme-tag-error-background-color);
}

.ldb-tile-container {
  border: 1px solid var(--tile-color);
  border-left: 5px solid var(--tile-color);
}

.title > .ldb-field-container > capitec-label {
  font-weight: bold;
  color: var(--tile-color);
}

.title {
  align-content: left;
}

.subtitle {
  align-content: left;
}

.infoLabel > .ldb-field-container > a {
  font-style: italic;
  text-decoration: none;
  color: var(--theme-label-font-color);
  pointer-events: none;
  font-size: 12px;
  align-content: right;
}

.infoValue > .ldb-field-container > capitec-label {
  font-weight: bold;
  text-decoration: none;
  color: var(--theme-label-font-color);
  pointer-events: none;
  font-size: 15px;
  align-content: right;
}

.ldb-tile-container.active {
  background-color: var(--light-color);
  color: var(--tile-color);
  --theme-label-font-color: var(--tile-color);
}

.ldb-tile-container.not-active {
  /* background-color:var(--light-color); */
  color: #555;
}
</style>
