<template>
    <div  v-if="view === 'edit' || view === 'create'" class="ldb-field-container__text">
    <capitec-file-upload  >
    
    </capitec-file-upload>
    </div>
    <LDBItemViewField v-else-if="view === 'item'" :view="view" :field="field" :displayValue="value || ''" :definition="definition" :record="record" />
    <LDBListViewField v-else-if="view === 'list'" :view="view" :field="field" :displayValue="value || ''" :definition="definition" :record="record" />
</template>

<script>

import {
  mapState,
  mapActions,
  mapGetters,
  mapMutations
} from "vuex";

import {ldb_field_mixin} from './ldb_field_mixin.js';
import LDBItemViewField from './LDBItemViewField.vue';
import LDBListViewField from './LDBListViewField.vue';


export default {
  mixins:[ldb_field_mixin],
  components: {LDBItemViewField,LDBListViewField},
  props: {  
    
  },
  data() {
    return {
    }
  },
  created() {

  },
  mounted() {

  },
  computed:{
    ...mapGetters([]),
  	...mapState([]),
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([]),
    on_change(evt) {
      this.$emit('change',evt.detail.new);
    }
  }
 }
</script>
<style>

</style>


