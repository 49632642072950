<template>
  <p>The User has not been enabled as a Signatory.</p>
  <p v-if="userStatus !== 'active'">A user can only be enabled as a signatory if they are in the Active status.</p>
  <p v-else-if="!userHasSignatoryInformation">The user cannot be enabled as it is missing required information.</p>
  <RouterView />
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'LfxUserNoSignatory',
  components: {},
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account', 'user']),

    // ----- User record -----

    userId() {
      return +this.$route.params?.userId;
    },
    userRecord() {
      return this.user?.[this.userId];
    },

    userStatus() {
      return this.userRecord?.status;
    },
    userHasSignatoryInformation() {
      return this.userRecord?.hasSignatoryInformation
    }
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
  },
};
</script>
