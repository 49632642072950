<template>
  <LFXContent
    :titleBar="titleBar"
    :show_tabs="showTabs"
    :tabs="tabs"
    :show_actions="showActions"
    :show_label="showLabel"
    :label="contentLabel"
    :show_sidebar="false"
  >
    <template #actions>
      <LDBButton
        v-for="button in actionBarButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>

    <template #content>
      <router-view @on-search-change="onSearchChange"></router-view>    </template>
  </LFXContent>

  <router-view name="modal"></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXContent from '@/components/layout/LfxContent.vue';
import LFXListView from '@/views/general/LFXListView.vue';

export default {
  name: 'LfxAccounts',
  components: { LFXListView, LFXContent },
  props: {},
  data() {
    return {
      tableSearchCriteria:{},
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters(['permissions','iAmAnUnmigratedIntermediary','isTestingSdlc',]),
    ...mapState(['account', 'authUser','dbIntermediarySettings']),

    // ----- Title Bar -----

    titleBar() {
      const titleBar = {
        label: this.titleBarLabel,
        buttons: this.titleBarButtons,
      };
      return titleBar;
    },
    titleBarLabel() {
      return this.$route.meta?.label ? this.$route.meta.label : 'TODO';
    },
    titleBarButtons() {
      let self = this;
      let allButtons = {
        client: [
          {
            name: 'addClient',
            label: 'Add Client',
            type: 'white',
            routeName: 'clientsAddClients',
            guards: this.permissions.checkNewClientID && this.authUser.accountIsActive,
          },
        ],
        intermediary: [
          {
            name: 'addIntermediary',
            label: 'Add Intermediary',
            type: 'white',
            routeName: 'intermediariesAddIntermediary',
            guards: this.permissions.createIntermediary,
          },
        ],
      };
      return allButtons[this.$route.meta?.content?.recordsAccountLevel] !== undefined
        ? allButtons[this.$route.meta.content.recordsAccountLevel].filter((button) => button.guards)
        : [];
    },

    // ----- Tabs -----

    showTabs() {
      return true;
    },
    tabs() {
      let tabs = [
        {
          tabName: 'clients',
          routeName: 'clients',
          guards: true,
          label: 'Clients',
        },
        {
          tabName: 'applications',
          routeName: 'applications',
          guards: true,
          label: 'Applications',
        },
        {
          tabName: 'intermediaries',
          routeName: 'intermediaries',
          guards: this.authUser.accountLevel === 'bank',
          label: 'Intermediaries',
        },
        {
          tabName: 'branches',
          routeName: 'branches',
          guards: true,
          label: 'Branches',
        },
      ];
      return tabs.filter((tab) => tab.guards);
    },

    // ----- Content Label -----

    showLabel() {
      return this.contentLabel ? true : false;
    },
    contentLabel() {
      return this.$route.meta?.content?.label
        ? this.$route.meta.content.label
        : "Navigation didn't complete, please click tab above to continue";
    },

    // ----- Action Bar -----

    showActions() {
      return true;
    },
    actionBarButtons() {
      const self = this;
      let actions = {
        clients: [
          {
            name: 'bulkChangeClientIntermediary',
            label: 'Bulk Change Intermediary',
            type: '',
            guards: !!this.permissions.changeClientIntermediary && this.authUser?.permissions?.includes('bulkChangeClientIntermediary'),
            clickEvent() {
              self.$router.push({ name: 'bulkChangeClientIntermediary' });
            },
          },
          {
            name: 'downloadClientList',
            label: 'Download Latest Client List',
            type: '',
            guards: this.authUser.accountLevel === 'intermediary',
            enabledGuards: (!!this.dbIntermediarySettings?.clientReportS3Key || !!this.myAccount?.clientReportS3Key) && !!this.dbIntermediarySettings.clientReportEnable,
            action: 'downloadClientList',
            params: {},
            onSuccess: { doStoreUpdate: false },
          },
          {
            name: 'downloadClientBalances',
            label: 'Download Latest Client Balance List',
            type: '',
            guards: this.authUser.accountLevel === 'intermediary',
            enabledGuards: (!!this.dbIntermediarySettings?.accountBalanceReportS3Key || !!this.myAccount?.accountBalanceReportS3Key) && !!this.dbIntermediarySettings.accountBalanceReportEnable,
            action: 'downloadClientBalances',
            params: {},
            onSuccess: { doStoreUpdate: false },
          },
          {
            name: 'downloadDealList',
            label: 'Download Latest Daily Deal List',
            type: '',
            guards: this.authUser.accountLevel === 'intermediary',
            enabledGuards: false && (!!this.dbIntermediarySettings?.dailyDealReportS3Key || !!this.myAccount?.dailyDealReportS3Key) && !!this.dbIntermediarySettings.dailyDealReportEnable, // Disabled on 2024-08-12
            action: 'downloadDealList',
            params: {},
            onSuccess: { doStoreUpdate: false },
          },
          {
            name: 'downloadClients',
            label: 'Download',
            type: '',
            guards: false,
            action: 'downloadAccounts',
            params: {},
            onSuccess: { doStoreUpdate: false },
            actionParams: { additionalSelector: this.tableSelectors },
          },
        ],
        branches: [
          {
            name: 'addBranch',
            label: 'Add Branch',
            type: 'primary',
            guards: this.permissions.createBranch && !this.iAmAnUnmigratedIntermediary,
            clickEvent() {
              self.$router.push({ name: 'branchesBranchAdd' });
            },
          },
        ],
        intermediaries: [
          {
            name: 'bulkEnableIntermediaryReports',
            label: 'Enable Reports',
            type: '',
            guards: !!this.permissions.bulkEnableIntermediaryReports,
            clickEvent() {
              self.$router.push({ name: 'bulkEnableIntermediaryReports' });
            },
          },
          {
            name: 'bulkEnableIntermediary',
            label: 'Bulk Enable Intermediary',
            type: '',
            guards: false && !!this.permissions.bulkEnableIntermediaries,
            clickEvent() {
              self.$router.push({ name: 'intermediariesBulkEnable' });
            },
          },
        ],
      };
      return actions[this.actionGroup] ? actions[this.actionGroup].filter((action) => action.guards) : [];
    },
    actionGroup() {
      return this.$route.meta?.content?.actionGroup;
    },
     tableSelectors() {
       return {...this.tableSearchCriteria} //TODO - Get this from the table
     },
    myAccount() {
      return this.account[this.authUser.accountId];
    },

    // ----- Content Label -----

    showLabel() {
      return false;
    },

    // ----- Records -----

    recordsAccountLevel() {
      return this.$route.meta.content.recordsAccountLevel;
    },

    definition() {
      return getDefinition(this.recordsAccountLevel);
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    onSearchChange(tableSearchCriteria) {
      // this.definition.search_string = evt.detail.new;
      this.tableSearchCriteria = tableSearchCriteria;
    },
  },
};
</script>

<style></style>
