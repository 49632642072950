<template>
  <div class="lfx-advanced-payment-page">
    <capitec-content-box>
      <div>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          :view="field.view"
          :fieldname="field.name"
          :definition="undefined"
          :record="optionsRecord"
          @change="on_change"
        />
      </div>
      <capitec-action-bar>
        <LDBButton
          v-for="button in formButtons"
          :key="button.name"
          :label="button.label"
          :action="button.action"
          :actionParams="button.actionParams"
          :params="button.params"
          :type="button.type"
          :tooltip="button.tooltip"
          :modal="button.modal"
          :viewGuards="button.guards"
          :enabledGuards="button.enabledGuards"
          :onSuccess="button.onSuccess"
          @beforeClick="button.beforeClick"
          @click="button.onClick"
          @on-api-success="button.onApiSuccess"
          @on-api-error="button.onApiError"
        >
          <template v-if="button.modal !== undefined" #modal>
            <div v-html="button.modal.content"></div>
          </template>
        </LDBButton>
      </capitec-action-bar>
      <template v-if="dataStatus === 'retrieving'">
        <LDBLoader />
      </template>
      <div
        v-else-if="dataStatus === 'retrieved' && reportData && Object.keys(reportData).length > 0"
        class="lfx-advanced-payment-table-container"
      >
        <LFXListView :definition="definition" :records="reportData" />
      </div>
      <div v-else-if="dataStatus === 'retrieved'">No Data Returned for this Date Range</div>
    </capitec-content-box>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBField from '@/landobyte_vue/LDBField.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import LFXListView from '@/views/general/LFXListView.vue';

export default {
  name: 'LfxAdvancedPaymentReport',
  components: { LDBField, LDBLoader, LFXListView },
  props: {},
  data() {
    return {
      selectorsUpdated: true,
      dataStatus: 'none',
      optionsRecord: {
        dateFrom: new Date().toISOString(),
        dateTo: new Date().toISOString(),
      },
      reportData: undefined,
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),

    // ----- Buttons -----

    formButtons() {
      let self = this;
      return [
        {
          name: 'generateReport',
          label: 'Generate Report',
          type: 'primary',
          action: 'getPayments',
          onSuccess: { doStoreUpdate: false },
          enabledGuards: this.selectorsUpdated === true,
          actionParams: {
            additionalSelector: this.additionalSelector,
          },
          beforeClick() {
            self.dataStatus = 'retrieving';
          },
          onApiSuccess: self.onApiSuccess,
          onApiError() {
            self.dataStatus = 'error';
          },
        },
        {
          name: 'downloadPayments',
          label: 'Download',
          type: '',
          guards: self.dataStatus === 'retrieved',
          action: 'downloadPayments',
          params: {},
          onSuccess: { doStoreUpdate: false },
          actionParams: { additionalSelector: this.additionalSelector },
        },
      ];
    },
    additionalSelector() {
      return {
        where: {
          and: [
            { valueDate: { gte: this.optionsRecord.dateFrom } },
            { valueDate: { lte: this.optionsRecord.dateTo } },
            { or: [{ bopCategories: { like: 'Out-101-0' } }, { bopCategories: { like: 'Out-101-10' } }] },
            { status: { notIn: ['cancelled', 'deleted'] } },
          ],
        },
      };
    },

    // ----- Fields -----

    viewFields() {
      const allFields = {
        dateFrom: {
          name: 'dateFrom',
          label: 'Date From',
          datatype: {
            datetime: {
              type: 'date',
              format: 'human',
              minDate: '2014/01/01',
              maxDate: { type: 'day', offset: 0 },
            },
          },
          enableGuards: true,
          view: 'edit',
          guards: true,
        },
        dateTo: {
          name: 'dateTo',
          label: 'Date To',
          datatype: {
            datetime: {
              type: 'date',
              format: 'human',
              minDate: '2014/01/01',
              maxDate: { type: 'day', offset: 7 },
            },
          },
          enableGuards: true,
          view: 'edit',
          guards: true,
        },
      };
      const returnFields = {};
      for (const f in allFields) {
        if (allFields[f].guards !== false) {
          returnFields[f] = allFields[f];
        }
      }
      return returnFields;
    },
    definitionName() {
      return 'payment';
    },
    definition() {
      return getDefinition(this.definitionName);
    },
    collectionPathTemplate() {
      return this.definition?.collectionPath;
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    on_change({ field, value }) {
      this.optionsRecord[field] = value;
      this.selectorsUpdated = true;
    },
    onApiSuccess(response) {
      this.reportData = {};
      for (const row of response.response?.rows) {
        row.collection_path = Mustache.render(this.collectionPathTemplate, row);
        this.reportData[row.id] = this.definition.class(row, this.$store);
      }
      this.dataStatus = 'retrieved';
      this.selectorsUpdated = false;
    },
  },
};
</script>

<style>
.lfx-advanced-payment-table-container {
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
}

.lfx-advanced-payment-page {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
</style>
