//@ts-nocheck
//------------------------------------------------------------------------------

const _ = require('lodash');
import axios from 'axios';

import { ENV_VARIABLES } from '@/store/config_env';

const APIURL = () => ENV_VARIABLES.VUE_REST_API_URL;

import lodash from 'lodash';
import { fs_update_document } from './db_interface_firestore';

//------------------------------------------------------------------------------

import {
  db_load_active_collections,
  db_assign_document_to_state,
  db_recurse_to_object,
  db_clone,
  db_update_state,
  db_delete_state,
} from './db_state';

import { db_tokens, db_path } from './db_utils';

const mapResponse = <T extends LdbDbObject>(response: T) => {
  return response;
};

function notify_of_error(context, error) {
  context.dispatch('db_on_error', error);
}

async function wait_for_mfa(context, options) {
  if (options.requiresMFA === true) {
    if (context.getters.hasMFA === true) {
      return context.state.mfa.mfaToken;
    } else await context.dispatch('lfx_auth_get_mfa_token', {});
  } else return false;
}

export async function http_rest_patch(context, path: string, body: object, options) {
  let headers = { ...context.getters.authHeaders };
  let url = `${APIURL()}${path}`;
  // ignore body for now...
  let config = { headers: headers };
  const http_res = await axios.patch(url, body, config);
  let data = http_res.data.rows ?? http_res.data;
  for (let d = 0; d < data.length; d++) {
    let r = data[d];
  }
  let mappedResponse = mapResponse(data);
  return mappedResponse;
}

export async function http_rest_get(context, path: string, body: object) {
  let headers = { ...context.getters.authHeaders };
  let url = `${APIURL()}${path}`;
  let config = { headers: headers };
  const http_res = await axios.get(url, config);
  let data = http_res.data;
  for (let d = 0; d < data.length; d++) {
    let r = data[d];
  }
  let mappedResponse = mapResponse(data);
  return mappedResponse;
}

export async function http_rest_post(context, path: string, body: object) {
  let headers = { ...context.getters.authHeaders };
  let url = `${APIURL()}${path}`;
  let params = db_clone(body);
  let config = { headers: headers };
  try {
    const http_res = await axios.post(url, params, config);
    let data = http_res.data;
    let status = http_res.status === 200 ? 'success' : 'failed';
    return { status, response: data };
  } catch (err) {
    notify_of_error(context, err);
    if (err.response) {
    }
    return err;
  }
}

export async function http_rest_put(context, path: string, body: object) {
  let headers = { ...context.getters.authHeaders };
  let url = `${APIURL()}${path}`;
  let params = db_clone(body);
  let config = { headers: headers };
  try {
    const http_res = await axios.put(url, params, config);
    let data = http_res.data;
    let status = http_res.status === 200 ? 'success' : 'failed';
    return { status, response: data };
  } catch (err) {
    notify_of_error(context, err);
    if (err.response) {
      let status = 'failed';
      return { status, response: err.response };
    }
    return err;
  }
}

export async function db_get_collection(context, { collection_path, options }) {
  let get_collection_path = collection_path;
  let res = {};
  let opt = options === undefined ? {} : options;
  let return_collection_path = opt.return_collection_path ? opt.return_collection_path : collection_path; //avr
  opt.return_collection_path = return_collection_path;
  let path_tokens = db_tokens(collection_path, '/');
  let cbf = opt.on_receive_document === undefined ? function () {} : opt.on_receive_document;
  let collection_name = path_tokens.pop();
  let collection_parent_path = path_tokens.join('/');
  let collection_parent = db_recurse_to_object(context, collection_parent_path);
  let conditions = opt.where === undefined ? [['deleted', '==', false]] : opt.where;

  // if (collection_parent.collection_state === undefined) {
  //   collection_parent.collection_state = {};
  // }
  let updatedOptions = { ...opt, conditions };
  updatedOptions.change = { type: 'added' };
  let query; 
  if (updatedOptions?.additionalSelector === undefined) {
    query = {additionalSelector:{limit:500}};
  }
  else 
  {
    query = updatedOptions?.additionalSelector ? {additionalSelector:updatedOptions?.additionalSelector} : {};
  }
  let collection = await http_rest_patch(context, get_collection_path,query, updatedOptions);
  for (let d in collection) {
    let doc = collection[d];
    let assignedDoc = db_assign_document_to_state(context, doc, return_collection_path, doc.id, updatedOptions);
    res[assignedDoc.id] = assignedDoc;
  }
  // if (collection_parent.collection_state[collection_name] === undefined) {
  //   collection_parent.collection_state[collection_name] = {};
  // }
  return res;
}

//------------------------------------------------------------------------------
//  FS GET DOCUMENT
//------------------------------------------------------------------------------

export async function db_get_document(context, { collection_path, id, options }) {
  let res = {};
  let opt = options === undefined ? {} : options;
  let path_tokens = db_tokens(collection_path, '/');
  let cbf = opt.on_receive_document === undefined ? function () {} : opt.on_receive_document;
  let collection_parent_path = path_tokens.join('/');
  let collection_parent = db_recurse_to_object(context, collection_parent_path);
  let conditions = opt.where === undefined ? [['deleted', '==', false]] : opt.where;
  // if (collection_parent.collection_state === undefined) {
  //   collection_parent.collection_state = {};
  // }
  let updatedOptions = { ...opt, conditions };
  updatedOptions.change = { type: 'added' };
  let doc = await http_rest_get(context, `${collection_path}/${id}`, updatedOptions);
  let assignedDoc = db_assign_document_to_state(context, doc, collection_path, doc.id, updatedOptions);
  cbf(assignedDoc);
  return assignedDoc;
}

//------------------------------------------------------------------------------
// UPDATE FIELD
//------------------------------------------------------------------------------

export async function db_update_field(context, { record, field, value, options }) {
  let update_field = {};
  update_field[field] = value;
  return await db_update_document(context, { record, fields: update_field, options });
}

//------------------------------------------------------------------------------
// FS FLAG DELETED RECORD
//------------------------------------------------------------------------------

export function fs_flag_delete_document(context, { collection_path, id, options }) {
  let opts = options === undefined ? {} : options;
  let cbf = opts.on_success === undefined ? function () {} : opts.on_success;
  let err = opts.on_error === undefined ? function () {} : opts.on_error;
  let cln = { deleted: true };
  if (context.state !== undefined && context.state.user !== undefined && context.state.user.email !== undefined) {
    cln.deleted_by_email = context.state.user.email;
  }
  cln.deleted_timestamp = firebase.firestore.FieldValue.serverTimestamp();
  let addRef = db.collection(db_path(collection_path)).doc(id).update(cln).then(cbf).catch(err);
  db_delete_state(context, collection_path, id);
  return addRef;
}

//------------------------------------------------------------------------------
// CREATE RECORD
//------------------------------------------------------------------------------

export async function db_create_document(context, { record, options }) {
  // let id = record.id;
  let opts = options === undefined ? {} : options;
  let return_collection_path = opts.return_collection_path ? opts.return_collection_path : record.collection_path; //avr
  // let merge = opts.merge === true;
  let action = opts.action ? opts.action : '';
  let parentId = options.parentId;
  let cbf = opts.on_success === undefined ? function () {} : opts.on_success;
  let err = opts.on_error === undefined ? function () {} : opts.on_error;
  let create_fields = record.definition.create_view_fields.map((f) => f.name);
  let copy_rec = {};
  copy_rec.id = record.id;
  for (let f = 0; f < create_fields.length; f++) {
    let field_name = create_fields[f];
    copy_rec[field_name] = record[field_name];
  }
  let cln = db_clone(copy_rec);
  opts.change = opts.change ?? {};
  opts.change.type = 'added';
  // let collection_path = record.collection_path;
  cln.deleted = false;
  cln.selected = true;
  let create_response = await http_rest_post(context, `${record.collection_path}/${parentId}/${action}`, cln);
  if (create_response.status === 'success') {
    let data = create_response.response;
    let update_res = db_update_state(context, return_collection_path, data.id, data, opts); //avr
    cbf(update_res);
    return update_res;
  }
  // TODO: handle error case
}

export async function db_create_client(context, { record, options }) {
  let opts = options === undefined ? {} : options;
  let return_collection_path = 'account'; //avr
  let cln = db_clone(record);
  opts.change = opts.change ?? {};
  opts.change.type = 'added';
  cln.deleted = false;
  cln.selected = true;
  let create_response = await http_rest_post(context, `/client/`, record);
  if (create_response.status === 'success') {
    let data = create_response.response;
    let update_res = db_update_state(context, return_collection_path, data.id, data, opts); //avr
    return update_res;
  }
}

//------------------------------------------------------------------------------
//  UPDATE FIELDS OF A RECORD IN FIREBASE
//------------------------------------------------------------------------------

export function fs_update_user(context, user, options) {
  let opts = options === undefined ? {} : options;
  let collection_path = user.collection_path;
  let id = user.id;
  let cbf = opts.on_success === undefined ? function () {} : opts.on_success;
  let addRef = db
    .collection(db_path(collection_path))
    .doc(id)
    .update(user)
    .then(function (data) {
      cbf(data, opts);
    });
}

export async function db_update_document(context, { record, fields, options }) {
  let opts = options === undefined ? {} : options;
  let return_collection_path = opts.return_collection_path ? opts.return_collection_path : record.collection_path;
  let cbf = opts.on_success === undefined ? function () {} : opts.on_success;
  let err = opts.on_error === undefined ? function () {} : opts.on_error;
  let copy_record = {};
  for (let f in fields) {
    let value = lodash.get(fields, f);
    lodash.set(copy_record, f, value);
  }
  let cln = db_clone(copy_record);
  opts.change = opts.change ?? {};
  opts.change.type = 'added';
  cln.deleted = false;
  cln.selected = true;
  let update_response = await http_rest_post(context, `${record.collection_path}/${record.id}`, copy_record);
  if (update_response.status === 'success') {
    let data = update_response.response;
    record.sync_update(data);
    let update_res = db_update_state(context, return_collection_path, data.id, data, opts);
    cbf(update_res);
  } else {
  }
  return update_response.response;
}

export async function db_put_action(context, { path, payload, options }) {
  let pld = payload === undefined ? {} : payload; // avr
  let opts = options === undefined ? {} : options;
  let cbf = opts.on_success === undefined ? function () {} : opts.on_success;
  let err = opts.on_error === undefined ? function () {} : opts.on_error;
  // let cln = db_clone(payload); // avr
  let cln = db_clone(pld); // avr
  let put_response = await http_rest_put(context, `${path}`, cln);
  if (put_response.status === 'success' && opts.update_state) {
    let id = put_response.response.id;
    db_update_state(
      context,
      opts.return_collection_path,
      id,
      { ...put_response.response, deleted: false },
      { ...opts, change: { type: 'modified' } }
    )
    cbf(put_response);
  } else {
    err(put_response);
  }
  return put_response; //avr
}

//------------------------------------------------------------------------------
//                                INTERNAL FUNCTIONS
//------------------------------------------------------------------------------

function handle_doc(context, doc, collection_path, document_id, options) {
  if (doc.exists) {
    if (!(doc.metadata.hasPendingWrites === true && options.change.type === 'modified')) {
      let id = doc.id;
      let data = doc.data();
      let res = db_assign_document_to_state(context, data, collection_path, id, options);
      return res;
    } else {
      return undefined;
    }
  }
}

//------------------------------------------------------------------------------
// EXPORTS
//------------------------------------------------------------------------------

export const db_interface_firestore = {
  db_update_user: fs_update_user,
  db_get_collection,
  db_update_field,
  db_get_document,
  db_flag_delete_document: fs_flag_delete_document,
  db_create_document,
  db_update_document,
  db_load_active_collections,
  db_put_action,
};

//------------------------------------------------------------------------------
