<template>
  <LDBModal result="info" size="large" :header="header">
    <template #body>
      <template v-if="userStatus === 'new'">
        <ol>
          <li>Enable the User to log in.</li>
          <li>A Welcome Email will be sent to the User with instructions on how to log in.</li>
          <li>
            User's email address: <strong>{{ userEmailAddress }}</strong>
          </li>
        </ol>
      </template>
      <template v-if="userStatus === 'disabled'">
        <capitec-label type="subtitle" label="If the user was previously in a New status:"></capitec-label>
        <ol>
          <li>User will be set to Pending status.</li>
          <li>A Welcome Email will be automatically sent to the User with instructions on how to log in.</li>
          <li>
            User's email address: <strong>{{ userEmailAddress }}</strong>
          </li>
        </ol>
        <capitec-label type="subtitle" label="If the user was previously in a Pending status:"></capitec-label>
        <ol>
          <li>User will be set to Pending status.</li>
          <li>
            A Welcome Email will NOT be automatically sent to the User, but may be sent manually by using the Resend
            Welcome Email button.
          </li>
        </ol>
        <capitec-label type="subtitle" label="If the user was previously in an Active status:"></capitec-label>
        <ol>
          <li>User will be set to Active status.</li>
          <li>
            A Welcome Email will NOT be automatically sent to the User. You will have to let the user know manually
            outside the system.
          </li>
        </ol>
      </template>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in buttons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

export default {
  name: 'LfxEnableUser',
  components: {},
  props: {},
  emits: {},
  created() {},
  mounted() {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['user']),
    userId() {
      return this.$route.params.userId;
    },
    record() {
      return this.user?.[this.userId];
    },
    userStatus() {
      return this.record?.status;
    },
    userEmailAddress() {
      return this.record?.emailAddress;
    },
    header() {
      return `Enable User: ${this.user[this.userId].userDisplayName}`;
    },
    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta?.modal?.cancelRoute : 'todo';
      //TODO: go to user's home as fallback
    },
    buttons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelResendWelcomeEmail',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.replace({ name: self.cancelRoute });
          },
        },
        {
          name: 'enableUser',
          label: 'Enable User',
          type: 'primary',
          guards: self.record.enableUser_buttonGuard,
          clickEvent() {
            self.do_enableUser();
          },
        },
      ];
      return allButtons;
    },
  },
  methods: {
    async do_enableUser() {
      let response = await this.record.enableUser();
      if (response.status === 'success') {
        this.$router.replace({ name: 'userDetailsView' });
      }
    },
  },
};
</script>
