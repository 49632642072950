//@ts-nocheck

import { LfxBopCatGroupInt, LfxBopCatGroupDef } from '@/definitions/LfxBopCatGroupDef';

import { LfxModel, LfxDefinition } from './LfxModel';

class LfxBopCatGroupMdl extends LfxDefinition {
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

export class LfxBopCatGroup extends LfxModel {
  constructor(data, state) {
    super({ definition_name: 'bopCatGroup', ...data }, state);
  }
}

export function Definition(context, name: string) {
  return new LfxBopCatGroupMdl(LfxBopCatGroupDef, LfxBopCatGroup, context, name, {});
}
