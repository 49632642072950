<template>
  <LDBModal result="info" header="Email Log" size="xx-large">
    <template #body>
      <div v-if="listType === 'user'">
        <LDBField
          v-for="(field, fieldname) in formFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="undefined"
          :record="searchRecord"
          @change="on_change"
        />
      </div>
      <LFXListView v-if="hasListLoaded" :definition="definition" :records="records" :defaultSort="defaultSort" />
      <template v-else-if="isListLoading">
        <LDBLoader />
      </template>
    </template>    
    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @on-api-success="button.onApiSuccess"
        @click="button.clickEvent"
      ></LDBButton>
    </template>    

  </LDBModal>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXListView from '@/views/general/LFXListView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import LDBField from '@/landobyte_vue/LDBField.vue';

import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LdbEmailLogList',
  components: { LFXListView, LDBLoader, LDBField },
  props:  { refreshCounter: { type: Number, required: false, default: 0}},
  data() {
    return {
      records:[],
      hasListLoaded:false,
      isListLoading:false,
      searchRecord:{}
    };
  },
  async created() {
    if (this.listType !== 'user') {
      this.loadData()
    }
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    listType() {
      return this.$route.meta.listType
    },
    definition() {
      return getDefinition('emailLog');
    },
    defaultSort() {
      return { order: 'sentTime', sort: 'desc' }
    },
    formFields() {
      const res = {
        timestamp: {
            name: 'timestamp',
            label: 'Timestamp',
            datatype: {
              datetime: {
                type: 'date',
                format: 'human',
                minDate: '2023/09/01',
                maxDate: { type: 'day', offset: 0 },
              },
            },
            view: 'edit',
            guards: true,
          },
      };
      return res;
    },

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'refresh',
          label: 'Refresh',
          type: '',
          action: 'click',
          params: {},
          guards: this.hasListLoaded,
          enabledGuards: true,
          clickEvent() {
            self.loadData()
          },
        },
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          action: 'click',
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

      ];
      return allButtons;      
    },

  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    async loadData() {
      this.hasListLoaded = false;
      this.isListLoading = true;
      let result;
      switch (this.listType) {
        case 'deal':
          result = await doPermissionAction(this,'getDealEmailLogs',{transactionId:+this.$route.params.transactionId,dealId:+this.$route.params?.dealId});
          break
        case 'payment':
          result = await doPermissionAction(this,'getPaymentEmailLogs',{transactionId:+this.$route.params.transactionId,paymentId:+this.$route.params?.paymentId});
          break
        case 'user':
          result = await doPermissionAction(this,'getUserEmailLogs',{userId:+this.$route.params.userId},{timestamp:this.searchRecord.timestamp});
          break
      }
      if (result.status === 'success') {
        this.records = result.response.rows
      }
      this.hasListLoaded = true;
      this.isListLoading = false;
    },
    on_change({ field, value }) {
      this.searchRecord[field] = value;
      this.loadData()
    },
  },
};
</script>

<style>
/* div.container {
} */
</style>
