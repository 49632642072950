<template>
  <LFXPagedListView
    :definition="definition"
    action="getImportBatches"
    :actionParams="{}"
    :search="searchConfig"
    :defaultWhere="accountSelector"
    :defaultSort="defaultSort"
  />
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXPagedListView from '@/views/general/LFXPagedListView.vue';

export default {
  name: 'LfxImportBatchList',
  components: { LFXPagedListView },
  props: {
    includeBranchesInLists: {type: Boolean, required: false, default: false}
  },
  data() {
    return {
      searchConfig: {
        searchFields:[],
        choices:[]
      },
    };
  },
  async created() {
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account','importBatch']),
    defaultSort() {
      return { order: 'id', sort: 'desc' }
    },
    records() {
      let res = {};
      for (let b in this.importBatch) {
        let batch = this.importBatch[b];
        res[batch.id] = batch;
      }
      return res;
    },

    definition() {
      return getDefinition('importBatch');
    },
    
    viewedAccountId() {
      return +this.$route.params.accountId;
    },
    viewedAccountRecord() {
      return this.account[this.viewedAccountId]
    },
    viewedAccountLevel() {
      return this.viewedAccountRecord?.accountLevel;
    },
    accountSelector() {
      if (this.viewedAccountId) {
        if (this.includeBranchesInLists === true) {
          if (this.viewedAccountLevel === 'intermediary') {  
            return { intermediaryId: this.viewedAccountId };
          } else {
            return { branchId: [this.viewedAccountId,...this.viewedAccountRecord.childrenBranchIds] }
          }
        } else {
          return { accountId: this.viewedAccountId };
        }
      }
      return undefined;
    },

  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
