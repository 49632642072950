//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LdbUserPermissionDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a User Permission record for LandoByte TS systems
//  Created     : 15 August 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition } from './LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------

export type LdbUserPermissionStatus = 'active' | 'pending' | 'disabled' | 'rejected';

//----------------------------------------------------------------------------------------------------------------------

export interface LdbUserPermissionInt {
  id?: number;
  uuid?: string;
  userId?: number;
  permissionId?: string;

  status?: LdbUserPermissionStatus;

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

export const LdbUserPermissionDef: LdbDefinition = {
  title: 'User Permission',
  table: 'LdbUserPermission',
  collectionPath: '/user/{{userId}}/permission',
  view: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
  indexes: [],
  pagingType: 'frontEnd',
  fields: {
    id: {
      label: 'Permission',
      datatype: { id: { descriptionField: 'permissionId' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    uuid: {
      label: 'UUID',
      datatype: 'uuid',
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    userId: {
      label: 'User',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: true, edit: false, item: true, list: false, delete: false, csv: false },
    },
    permissionId: {
      label: 'Permission',
      datatype: {
        foreignKey: {
          collection: 'fk_permissions',
          linkTable: 'LdbPermission',
          linkField: 'id',
          displayField: 'description',
          type: 'text',
        },
      },
      default: null,
      mandatory: true,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: false },
    },
    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'active', name: 'Active', tagType: 'success' },
            { id: 'pending', name: 'Pending', tagType: 'warning' },
            { id: 'disabled', name: 'Disabled', tagType: undefined },
            { id: 'rejected', name: 'Rejected', tagType: 'error' },
          ],
        },
      },
      default: 'active',
      mandatory: true,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: false },
    },

    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
  },
};
