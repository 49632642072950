import axios from 'axios';

type ENV_VARIABLES_DEF = {
  INITILIASED:boolean;
  VUE_CONFIG_ID:number;
  VUE_REST_API_URL:string;
  VUE_RATES_FEED_SOCKET_URL:string;
  SEARCH_TERM_URL_ENCRYPTION_KEY:string;
  SDLC:'LOCAL'|"DEV"|"INT"|"QA"|"PROD"
}

export const ENV_VARIABLES: ENV_VARIABLES_DEF = {
  INITILIASED:false,
  VUE_CONFIG_ID:1,
  VUE_REST_API_URL:'',
  VUE_RATES_FEED_SOCKET_URL:'',
  SEARCH_TERM_URL_ENCRYPTION_KEY:'',
  SDLC:'PROD'
}; 
//@ts-ignore
export async function load_env_variables(context) {
  //@ts-ignore
  let http_res = await axios.get('/config/config.json');
  //@ts-ignore
  let data = http_res.data;
  let VUE_CONFIG_ID = data.VUE_CONFIG_ID;
  let VUE_REST_API_URL = data.VUE_REST_API_URL;
  let VUE_RATES_FEED_SOCKET_URL = data.VUE_RATES_FEED_SOCKET_URL;
  let SEARCH_TERM_URL_ENCRYPTION_KEY = data.SEARCH_TERM_URL_ENCRYPTION_KEY;
  let SDLC = data.SDLC || "PROD";
  let INITILIASED = true;

  ENV_VARIABLES.VUE_CONFIG_ID = VUE_CONFIG_ID;
  ENV_VARIABLES.VUE_REST_API_URL = VUE_REST_API_URL;
  ENV_VARIABLES.VUE_RATES_FEED_SOCKET_URL = VUE_RATES_FEED_SOCKET_URL;
  ENV_VARIABLES.SDLC = SDLC;
  ENV_VARIABLES.INITILIASED = INITILIASED;
  ENV_VARIABLES.SEARCH_TERM_URL_ENCRYPTION_KEY = SEARCH_TERM_URL_ENCRYPTION_KEY;
  context.dispatch('environment_loaded',ENV_VARIABLES);
}


const env = {
  //@ts-ignore
  async install(stuff) { 
    let context = stuff._context.provides.store;
    await load_env_variables(context);
  }
}

export default env;


if (process.env.NODE_ENV === 'development') {
  // window.ENV_VARIABLES = ENV_VARIABLES;
}
