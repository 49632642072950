<template>
<Teleport to="body">
  <div v-if="show" :class="`ldb-modal-container modal-${modalStatus}`" @click="onContainerClick" lightbox="lightbox">
    <div :class="`ldb-modal-window  ${size}`">
      <div class="ldb-modal-header">
        <capitec-icon class="modal-icon" size="default" :icon="`status/${modalStatus}-24px-white`" />
        <capitec-label class="modal-title" type="title" :label="`${header}`" />
      </div>
      <div class="ldb-modal-content">
        <slot name="body" />
      </div>
      <div class="ldb-modal-buttons">
        <slot name="footer" />
      </div>
    </div>
  </div>
</Teleport>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  emits: ['on-close'],
  components: {},
  props: {
    status: { type: String, default: 'info' },
    result: { type: String },
    header: { type: String, default: 'Title' },
    size: { type: String, default: 'large' }, // small | medium | large | x-large | xx-large
    closable: { type: Boolean, default: false },
    class: { type: String, }
  },
  data() {
    return {
      show: true,
    };
  },
  created() {},
  mounted() {
    this.show = true;
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    modalStatus() {
      return this.result === undefined ? this.status : this.result;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    onContainerClick(evt) {
      if (this.closable === true) {
        let lightbox = evt.srcElement.getAttribute('lightbox');
        if (lightbox === 'lightbox') {
          this.$emit('on-close', evt);
          this.show = false;
        }
      }
    },
  },
};
</script>

<style>

.ldb-modal-container{
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: var(--theme-modal-screen-background-color, rgba(0, 0, 0, 0.6));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 100px;
  z-index:var(--ldb-z-index-modal);
}

.ldb-modal-window {
  background-color: white;
  border-radius: var(--theme-modal-border-radius, 4px);
  overflow: visible;
  max-height: calc(100vh - 200px);
  max-width: calc(100vw - 200px);
  display: flex;
  flex-direction: column;
}

.ldb-modal-window.xx-large {
  max-width: calc(100vw - 100px);
  min-width: calc(100vw - 100px);
  max-height: calc(100vh - 200px);
  min-height: calc(100vh - 200px);
  margin-left: 100px;
  margin-right: 100px;
}

.ldb-modal-window.x-large {
  max-width: calc(100vw - 200px);
  min-width: calc(100vw - 200px);
  max-height: calc(100vh - 200px);
  min-height: calc(100vh - 200px);
  margin-left: 100px;
  margin-right: 100px;
  min-width: 1000px;
}

.ldb-modal-window.large {
  max-width: calc(100vw - 200px);
  max-height: calc(100vh - 200px);
  min-width: 600px;
}

.ldb-modal-window.medium {
  margin-left: 300px;
  margin-right: 300px;
  min-width: 600px;
}

.ldb-modal-window.small {
  max-width: calc(500px);
  min-width: calc(500px);
  max-height: calc(400px);
  min-height: calc(400px);
  margin-left: 200px;
  margin-right: 200px;
}

.ldb-modal-window.x-small {
  min-width: calc(500px);
  max-height: calc(170px);
  min-height: calc(170px);
  margin-left: 200px;
  margin-right: 200px;
}

.ldb-modal-window.x-small > .ldb-modal-content{
  max-height: 30px;
  min-height: 30px;
}


.ldb-modal-container.modal-error {
  --dark-color: var(--theme-status-error-background);
  --light-color: var(--theme-alert-banner-unsuccessful-red-background-color);
}

.ldb-modal-container.modal-warning {
  --dark-color: var(--theme-status-warn-background);
  --light-color: var(--theme-alert-banner-warning-background-color);
}

.ldb-modal-container.modal-info {
  --dark-color: var(--theme-status-info-background);
  --light-color: var(--theme-alert-banner-info-background-color);
}

.ldb-modal-container.modal-success {
  --dark-color: var(--theme-status-success-background);
  --light-color: var(--theme-alert-banner-success-background-color);
}

capitec-label.modal-title {
  color: white;
  flex: 1;
  padding-left: 10px;
}

capitec-icon.modal-icon {
  margin-left: 5px;
}

.ldb-modal-header {
  display: flex;
  background-color: var(--dark-color);
  padding: 5px;
  border-top-left-radius: var(--theme-modal-border-radius, 4px);
  border-top-right-radius: var(--theme-modal-border-radius, 4px);
  flex: none;
}

.ldb-modal-content {
  --theme-modal-body-padding: 24px 24px 24px 24px;
  padding: var(--theme-modal-body-padding, 24px 24px 40px 24px);
  min-height: 200px;
  overflow: auto;
  flex: auto;
  position:relative;
}

.ldb-modal-buttons {
  display: flex;
  min-height: 32px;
  align-items: center;
  justify-content: flex-end;
  padding-right: 32px;
  padding-top: 0px;
  padding-bottom: 20px;
  flex: none;
}

.ldb-modal-buttons > * {
  margin-left: 10px;
}
</style>


