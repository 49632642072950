import type { LdbAPIAction } from './LdbActionTypes';

export const bookDeal: LdbAPIAction = {
  accountLevels: ['client', 'intermediary', 'intermediaryBranch'],
  permissions: ['bookDeal', 'clientBookDeal'],
  description: 'Books a new deal',
  method: 'put',
  collectionPath: '/trade',
  path: '/trade/bookDeal',
  tags: ['trade'],
  headers: {},
  responses: {
    200: {
      description: 'LfxTransactionFile',
      message: 'Deal successfully submitted',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    clientId: { type: 'integer', required: true },
    transactionId: { type: 'integer' },
    currencyPair: { type: 'string', required: true },
    currency: { type: 'string', required: true },
    action: { type: 'string', required: true },
    deliveryType: { type: 'string', required: true },
    forwardContractType: { type: 'string' },
    optionStartDate: { type: 'string', format: 'date' },
    valueDate: { type: 'string', format: 'date' },
    amount: { type: 'number', required: true },
    markupType: { type: 'string' },
    clientRate: { type: 'number' },
    setMarkup: { type: 'number' },
    externalReference: { type: 'string' },
    sourceBankAccountID: { type: 'number' },
    destinationBankAccountID: { type: 'number' },
    inwardSwiftId: { type: 'number' },
    detailsOfCharge: { type: 'string' },
    bookingToken: { type: 'string', required: true },
  },
  pathParams: {},
  implementedByFrontEnd: true,
};

export const captureOfflineDeal: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['captureOfflineDeal'],
  description: 'Captures an offline deal',
  method: 'put',
  collectionPath: '/trade',
  path: '/trade/captureOfflineDeal',
  tags: ['trade'],
  headers: {},
  responses: {
    200: {
      description: 'LfxTransactionFile',
      message: 'Offline deal successfully captured',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    accountId: { type: 'integer', required: true },
    currencyPair: { type: 'string', required: true },
    currency: { type: 'string', required: true },
    dealNumber: { type: 'string', required: true },
    action: { type: 'string', required: true },
    deliveryType: { type: 'string', required: true },
    forwardContractType: { type: 'string' },
    optionStartDate: { type: 'string', format: 'date' },
    valueDate: { type: 'string', format: 'date' },
    amount: { type: 'number', required: true },
    bankRate: { type: 'number', required: true },
    clientRate: { type: 'number', required: true },
    // fromAccountNumber: { type: 'string' }, // DEFINITELY SHOULDN'T BE fromSettlementAccountId
    // toAccountNumber: { type: 'string' }, // DEFINITELY SHOULDN'T BE toSettlementAccountId
    fromSettlementAccountId: { type: 'number' },
    toSettlementAccountId: { type: 'number' },
  },
  pathParams: {},
  implementedByFrontEnd: true,
};

export const bookTransfer: LdbAPIAction = {
  accountLevels: ['client', 'intermediary', 'intermediaryBranch'],
  permissions: ['managePayment'],
  description: 'Books a new cross border transfer',
  method: 'put',
  collectionPath: '/trade',
  path: '/trade/bookTransfer',
  tags: ['trade'],
  headers: {},
  responses: {
    200: {
      description: 'LfxTransactionFile',
      message: 'Transfer successfully submitted',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    clientId: { type: 'integer', required: true },
    currency: { type: 'string', required: true },
    valueDate: { type: 'string', format: 'date', required: true },
    amount: { type: 'number', required: true },
    externalReference: { type: 'string' },
    sourceBankAccountID: { type: 'number' },
    destinationBankAccountID: { type: 'number' },
    inwardSwiftId: { type: 'number' },
  },
  pathParams: {},
  implementedByFrontEnd: true,
};

export const uploadBulkFees: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['processBukFees'],
  description: 'Upload a csv for bulk fees processing',
  method: 'post',
  path: '/trade/bulkFees',
  tags: ['trade'],
  summary: '',
  params: {
    fileName: {
      type: 'object',
      format: 'binary',
      required: true,
    },
  },
  responses: {
    200: { description: 'Bulk Fees Result' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  reportAction: true,
};

export const getFailedTreasuryDeals: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: [],
  description: 'Gets a list of Failed Treasury Deals',
  method: 'patch',
  collectionPath: '/failedTreasuryDeal',
  path: '/transaction/failedTreasuryDeal',
  tags: ['transaction'],
  headers: {},
  responses: {
    200: { description: 'A list of LfxFailedTreasuryDeal' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: {},
  implementedByFrontEnd: true,
};

export const getFailedTreasuryDeal: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a Transaction File by ID',
  method: 'get',
  collectionPath: '/failedTreasuryDeal',
  path: '/transaction/failedTreasuryDeal/{failedTreasuryDealId}',
  tags: ['transaction'],
  headers: {},
  responses: {
    200: { description: 'LfxFailedTreasuryDeal' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { failedTreasuryDealId: { type: 'integer', required: true } },
};

export const getTransactionFiles: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a list of transactions available to the logged in user',
  method: 'patch',
  collectionPath: '/transaction',
  path: '/transaction',
  tags: ['transaction'],
  headers: {},
  responses: {
    200: { description: 'A list of LfxTransactionFile' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: {},
  implementedByFrontEnd: true,
};

export const getTransactionFile: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a Transaction File by ID',
  method: 'get',
  collectionPath: '/transaction',
  path: '/transaction/{transactionId}',
  tags: ['transaction'],
  headers: {},
  responses: {
    200: { description: 'LfxTransactionFile' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { transactionId: { type: 'integer', required: true } },
};

export const getTransactionFileLogs: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets logs for a transactionId',
  method: 'patch',
  collectionPath: '/transaction/{transactionId}/log',
  path: '/transaction/{transactionId}/log',
  tags: ['transaction'],
  headers: {},
  responses: {
    200: { description: 'Array of LfxTransactionFileLog' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { transactionId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const mergeTransactionFiles: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['managePayment'],
  description: 'Merges an array of other transaction files into this transaction file',
  method: 'put',
  collectionPath: '/transaction/',
  path: '/transaction/{transactionId}/merge',
  tags: ['dealpayment'],
  headers: {},
  responses: {
    200: {
      description: 'LfxPayment',
      message: 'Transaction files successfully merged',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    otherTransactionFileIds: {
      type: 'array',
      items: { type: 'number' },
      required: true,
    },
    paymentAction: {
      type: 'string', //'import'|'combine'
      required: true,
    },
  },
  pathParams: {
    transactionId: { type: 'integer', required: true },
  },
};

export const getDeals: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a list of deals available to the logged in user',
  method: 'patch',
  collectionPath: '/deal',
  path: '/deal',
  tags: ['deal'],
  headers: {},
  responses: {
    200: { description: 'A list of LfxDeal' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: {},
  implementedByFrontEnd: true,
};
export const downloadDeals: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Generates a CSV of deals available to the logged in user',
  method: 'put',
  collectionPath: '/deal/download',
  path: '/deal/download',
  tags: ['deal'],
  headers: {},
  responses: {
    200: { type: 'download', description: 'File Stream' },
    202: {
      description: 'In Progress',
      message:
        'Your report is being generated and will be emailed to you when it is complete. Note that this report will be in .csv format.',
      header: 'Report in Progress',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: {},
  implementedByFrontEnd: true,
  reportAction: true,
};

export const getTransactionDeals: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a list of deals for a transaction',
  method: 'patch',
  collectionPath: '/transaction/{transactionId}/deal',
  path: '/transaction/{transactionId}/deal',
  tags: ['deal'],
  headers: {},
  responses: {
    200: { description: 'A list of LfxDeal' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: { transactionId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const getDeal: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a Deal by ID',
  method: 'get',
  collectionPath: '/transaction/{transactionId}/deal',
  path: '/transaction/{transactionId}/deal/{dealId}',
  tags: ['deal'],
  headers: {},
  responses: {
    200: { description: 'LfxDeal' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    transactionId: { type: 'integer', required: true },
    dealId: { type: 'integer', required: true },
  },
};
export const splitDealToNewTf: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['managePayment'],
  description: 'Splits a deal out of an exiting TF into a new TF',
  method: 'put',
  collectionPath: '/deal/',
  path: '/transaction/{transactionId}/deal/{dealId}/splitToNewTf',
  tags: ['deal'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment', message: 'Deal successfully split', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: {
    transactionId: { type: 'integer', required: true },
    dealId: { type: 'integer', required: true },
  },
};

export const getDealMarkups: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a list of makrups for a deal',
  method: 'patch',
  collectionPath: '/transaction/{transactionId}/deal/{dealId}/markup',
  path: '/transaction/{transactionId}/deal/{dealId}/markup',
  tags: ['deal'],
  headers: {},
  responses: {
    200: { description: 'A list of LfxDealMarkup' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    dealId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const getDealMarkup: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a Deal Markup by ID',
  method: 'get',
  collectionPath: '/transaction/{transactionId}/deal/{dealId}/markup',
  path: '/transaction/{transactionId}/deal/{dealId}/markup/{markupId}',
  tags: ['deal'],
  headers: {},
  responses: {
    200: { description: 'LfxDealMarkup' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    transactionId: { type: 'integer', required: true },
    dealId: { type: 'integer', required: true },
    markupId: { type: 'integer', required: true },
  },
};
export const getDealLogs: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets logs for a dealId',
  method: 'patch',
  collectionPath: '/transaction/{transactionId}/deal/{dealId}/logs',
  path: '/transaction/{transactionId}/deal/{dealId}/logs',
  tags: ['deal'],
  headers: {},
  responses: {
    200: { description: 'Array of LfxTransactionFileLog' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { transactionId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const getDealEmailLogs: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets email logs for a dealId',
  method: 'patch',
  collectionPath: '/transaction/{transactionId}/deal/{dealId}/emailLogs',
  path: '/transaction/{transactionId}/deal/{dealId}/emailLogs',
  tags: ['deal'],
  headers: {},
  responses: {
    200: { description: 'Array of LfxTransactionFileLog' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { transactionId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const claimDeal: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseDeal'],
  description: 'Claims a deal',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/deal/{dealId}/claim',
  path: '/transaction/{transactionId}/deal/{dealId}/claim',
  tags: ['trade'],
  headers: {},
  responses: {
    200: { description: 'LfxDeal', message: 'Deal successfully claimed', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: {
    transactionId: { type: 'integer', required: true },
    dealId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const releaseDeal: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseDeal'],
  description: 'Releases a deal',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/deal/{dealId}/release',
  path: '/transaction/{transactionId}/deal/{dealId}/release',
  tags: ['trade'],
  headers: {},
  responses: {
    200: { description: 'LfxDeal', message: 'Deal successfully released', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    dealNumber: { type: 'string', required: true },
    deliveryType: { type: 'string' },
    valueDate: { type: 'string', format: 'date' },
    amount: { type: 'number' },
    markupType: { type: 'string' },
    bench: { type: 'number' },
    clientRate: { type: 'number' },
    bankRate: { type: 'number' },
    fromSettlementAccountNumber: { type: 'string' },
    toSettlementAccountNumber: { type: 'string' },
  },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    dealId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const updateDealExternalReference: LdbAPIAction = {
  accountLevels: [],
  permissions: ['bookDeal', 'clientBookDeal'],
  description: 'Updates the External Reference for a deal',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/deal/{dealId}/updateDealExternalReference',
  path: '/transaction/{transactionId}/deal/{dealId}/updateDealExternalReference',
  tags: ['trade'],
  headers: {},
  responses: {
    200: { description: 'LfxDeal', message: 'Deal External Reference successfully updated', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    externalReference: { type: 'string' },
  },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    dealId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const updateDealSettlementAccount: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseDeal'],
  description: 'Updates the Settlement Accounts for a Bank deal',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/deal/{dealId}/updateDealSettlementAccount',
  path: '/transaction/{transactionId}/deal/{dealId}/updateDealSettlementAccount',
  tags: ['trade'],
  headers: {},
  responses: {
    200: { description: 'LfxDeal', message: 'Deal Settlement Accounts successfully updated', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    fromSettlementAccountId: { type: 'number' },
    toSettlementAccountId: { type: 'number' },
  },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    dealId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const rejectDeal: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseDeal'],
  description: 'Rejects or Cancels a deal',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/deal/{dealId}/reject',
  path: '/transaction/{transactionId}/deal/{dealId}/reject',
  tags: ['trade'],
  headers: {},
  responses: {
    200: { description: 'LfxDeal', message: 'Deal successfully rejected', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { rejectionReason: { type: 'string', required: true } },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    dealId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const resendDeal: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseDeal'],
  description: 'Retriggers the send to bank action',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/deal/{dealId}/resend',
  path: '/transaction/{transactionId}/deal/{dealId}/resend',
  tags: ['trade'],
  headers: {},
  responses: {
    200: { description: 'LfxDeal', message: 'Deal successfully re-sent', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    transactionId: { type: 'integer', required: true },
    dealId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const modifyDealValueDate: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseDeal'],
  description: 'Modify the value date of a released deal',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/deal/{dealId}/modifyValueDate',
  path: '/transaction/{transactionId}/deal/{dealId}/modifyValueDate',
  tags: ['trade'],
  headers: {},
  responses: {
    200: { description: 'LfxDeal', message: 'Deal Value Date successfully updated', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    deliveryType: { type: 'string', required: true },
    valueDate: { type: 'string', format: 'date' },
    dealNumber: { type: 'string' },
  },
  implementedByFrontEnd: true,
};

export const drawdownFec: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseDeal'],
  description: 'Draws down an FEC and captures its 171 number',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/deal/{dealId}/drawdownFec',
  path: '/transaction/{transactionId}/deal/{dealId}/drawdownFec',
  tags: ['trade'],
  headers: {},
  responses: {
    200: { description: 'LfxDeal', message: 'FEC drawdown successful', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { dealNumber: { type: 'string' } },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    dealId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const fecRequestDrawdown: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['bookDeal', 'clientBookDeal'],
  description: 'Requests a Complete Drawdown for an FEC Contract',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/deal/{dealId}/fecRequestDrawdown',
  path: '/transaction/{transactionId}/deal/{dealId}/fecRequestDrawdown',
  tags: ['trade'],
  headers: {},
  responses: {
    200: {
      description: 'LfxDeal',
      message: 'FEC drawdown request successfully submitted',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    deliveryType: { type: 'string', required: true },
    setMarkup: { type: 'number' }, //Only for Fixed Contracts
  },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    dealId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const fecRequestPartialDrawdown: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['bookDeal', 'clientBookDeal'],
  description: 'Requests a Partial Drawdown for an FEC Contract',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/deal/{dealId}/fecRequestPartialDrawdown',
  path: '/transaction/{transactionId}/deal/{dealId}/fecRequestPartialDrawdown',
  tags: ['trade'],
  headers: {},
  responses: {
    200: {
      description: 'LfxDeal',
      message: 'FEC drawdown request successfully submitted',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    amount: { type: 'number', required: true },
    deliveryType: { type: 'string', required: true },
    setMarkup: { type: 'number' }, //Only for Fixed Contracts
  },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    dealId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const fecRequestBalanceCancelation: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['bookDeal', 'clientBookDeal'],
  description: 'Requests a Balance Cancelation for an FEC Contract',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/deal/{dealId}/fecRequestBalanceCancelation',
  path: '/transaction/{transactionId}/deal/{dealId}/fecRequestBalanceCancelation',
  tags: ['trade'],
  headers: {},
  responses: {
    200: {
      description: 'LfxDeal',
      message: 'FEC balance cancellation request successfully submitted',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    transactionId: { type: 'integer', required: true },
    dealId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const fecRequestExtension: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['bookDeal', 'clientBookDeal'],
  description: 'Requests an Extensionfor an FEC Contract',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/deal/{dealId}/fecRequestExtension',
  path: '/transaction/{transactionId}/deal/{dealId}/fecRequestExtension',
  tags: ['trade'],
  headers: {},
  responses: {
    200: {
      description: 'LfxDeal',
      message: 'FEC extension request successfully submitted',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    forwardContractType: { type: 'string', required: true },
    optionStartDate: { type: 'string', format: 'date' }, //Required for partiallyOptional
    valueDate: { type: 'string', format: 'date', required: true },
    setMarkup: { type: 'number' }, //For either contract type
  },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    dealId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const fecReleaseModification: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseDeal'],
  description: 'Grants and releases an FEC Modification Request',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/deal/{dealId}/fecReleaseModification',
  path: '/transaction/{transactionId}/deal/{dealId}/fecReleaseModification',
  tags: ['trade'],
  headers: {},
  responses: {
    200: {
      description: 'LfxDeal',
      message: 'FEC modification successfully released',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { dealNumber: { type: 'string' } },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    dealId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const fecRejectModification: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseDeal'],
  description: 'Rejects an FEC Modification Request',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/deal/{dealId}/fecRejectModification',
  path: '/transaction/{transactionId}/deal/{dealId}/fecRejectModification',
  tags: ['trade'],
  headers: {},
  responses: {
    200: {
      description: 'LfxDeal',
      message: 'FEC modification request successfully rejected',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { rejectionReason: { type: 'string', required: true } },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    dealId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const downloadDealConfirmation: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Downloads the deal confirmation attached to a deal',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/deal/{dealId}/downloadConfirmation',
  path: '/transaction/{transactionId}/deal/{dealId}/downloadConfirmation',
  tags: ['deal'],
  headers: {},
  responses: {
    200: { type: 'download', description: 'File Stream' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    transactionId: { type: 'integer', required: true },
    dealId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
  reportAction: true,
};

export const getPayments: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a list of payments available to the logged in user',
  method: 'patch',
  collectionPath: '/payment',
  path: '/payment',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'A list of LfxPayment' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true },
    includeDealNumbers: { type: 'boolean' },
  }, //FIXME: do includeDealNumbers generically
  pathParams: {},
  implementedByFrontEnd: true,
};
export const downloadPayments: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Generates a CSV of payments available to the logged in user',
  method: 'put',
  collectionPath: '/payment/download',
  path: '/payment/download',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { type: 'download', description: 'File Stream' },
    202: {
      description: 'In Progress',
      message: 'Your report is being generated and will be emailed to you when it is complete',
      header: 'Report in Progress',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: {},
  implementedByFrontEnd: true,
  reportAction: true,
};

export const addTransactionFilePayment: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['managePayment'],
  description: 'Adds a payment to a transaction file',
  method: 'put',
  collectionPath: '/transaction/',
  path: '/transaction/{transactionId}/addPayment',
  tags: ['payment'],
  headers: {},
  responses: {
    200: {
      description: 'LfxTransactionFile',
      message: 'Payment successfully added',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    paymentAmount: {
      type: 'number',
      required: true,
    },
  },
  pathParams: {
    transactionId: { type: 'integer', required: true },
  },
};

export const getTransactionPayments: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a list of payments for a transaction',
  method: 'patch',
  collectionPath: '/transaction/{transactionId}/payment',
  path: '/transaction/{transactionId}/payment',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'A list of LfxPayment' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: { transactionId: { type: 'integer', required: true } },
};

export const updatePayment: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['managePayment'],
  description: 'Edits a LfxPayment',
  method: 'post',
  collectionPath: '/transaction/{transactionId}/payment',
  path: '/transaction/{transactionId}/payment/{paymentId}',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment', message: 'Payment successfully updated', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    externalReference: { type: 'string' },
    sourceAccount: { type: 'number' },
    destinationAccount: { type: 'number' },
    isCrypto: { type: 'boolean' },
    detailsOfCharge: { type: 'string' },
    beneficiaryReference: { type: 'string' },
    specialInstructions: { type: 'string' },
    purposeOfPayment: { type: 'string' },
    sarbApprovalNumber: { type: 'string' },
    sarbAuthReferenceNumber: { type: 'string' },
    sarbLoanReferenceNumber: { type: 'string' },
    ucrNumber: { type: 'string' },
    importPermitConfirmation: { type: 'string' },
    invoiceNumber: { type: 'string' },
    mrn: { type: 'string' },
    sourceOfFunds: { type: 'string' },
    reason: { type: 'string' },
    transportDocumentNumber: { type: 'string' },
    sarsApnNumber: { type: 'string' },
  },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const getPayment: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a Payment by ID',
  method: 'get',
  collectionPath: '/transaction/{transactionId}/payment',
  path: '/transaction/{transactionId}/payment/{paymentId}',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
};
export const editTransactionFilePaymentAmount: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['managePayment'],
  description: 'Edits the amount of a payment in a transaction file',
  method: 'put',
  collectionPath: '/transaction/',
  path: '/transaction/{transactionId}/payment/{paymentId}/editPaymentAmount',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxTransactionFile', message: 'Payment amount successfully updated', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    paymentAmount: {
      type: 'number',
      required: true,
    },
  },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const deleteTransactionFilePayment: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['managePayment'],
  description: 'Deletes a payment from a transaction file',
  method: 'put',
  collectionPath: '/transaction/',
  path: '/transaction/{transactionId}/payment/{paymentId}/deletePayment',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxTransactionFile' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const checkAndUpdateTfStatus: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Ensures that the TF is in the correct status and that the payment amount is correct',
  method: 'put',
  path: '/transaction/{transactionId}/checkAndUpdate',
  tags: ['transaction'],
  summary: '',
  pathParams: {
    transactionId: { type: 'integer', required: true },
  },
  responses: {
    200: {
      description: 'LfxTransactionFile',
      message: 'Transaction File status successfully updated',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
};

export const updatePaymentBopCats: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['managePayment'],
  description: 'Updates BOP Categories for a LfxPayment',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/updateBopCats',
  path: '/transaction/{transactionId}/payment/{paymentId}/updateBopCats',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment', message: 'BOP Categories successfully updated', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    bopCategories: {
      type: 'array',
      items: { type: 'string' },
      required: true,
    },
  },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const updatePaymentSignatories: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['managePayment'],
  description: 'Updates Signatories for a LfxPayment',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/updateSignatories',
  path: '/transaction/{transactionId}/payment/{paymentId}/updateSignatories',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment', message: 'Signatories successfully updated', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    signatoryOption: { type: 'string', required: true },
    signatories: {
      type: 'array',
      items: { type: 'number' },
    },
  },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const updatePaymentDocuments: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['managePayment'],
  description: 'Updates Documents for a LfxPayment',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/updateDocuments',
  path: '/transaction/{transactionId}/payment/{paymentId}/updateDocuments',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment', message: 'Documents successfully updated', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    documents: {
      type: 'array',
      items: { type: 'string' },
      required: true,
    },
  },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const assignSwiftToPayment: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['managePayment'],
  description: 'Assigns a SWIFT to a payment',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/assignSwift',
  path: '/transaction/{transactionId}/payment/{paymentId}/assignSwift',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment', message: 'SWIFT successfully assigned to Payment', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { inwardSwiftId: { type: 'number', required: true } },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const getPaymentSignatories: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Returns all LfxSignatory for a payment',
  method: 'patch',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/signatory',
  path: '/transaction/{transactionId}/payment/{paymentId}/signatory',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'List of LfxSignatory' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
};

export const getPaymentSignatory: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets an LfxSignatory for a Payment by Id',
  method: 'get',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/signatory',
  path: '/transaction/{transactionId}/payment/{paymentId}/signatory/{signatoryId}',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxSignatory' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
    signatoryId: { type: 'integer', required: true },
  },
};
export const getPaymentSignatoryLogs: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets an LfxSignatoryLogs for a Payment by Id',
  method: 'patch',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/signatory/{signatoryId}/log',
  path: '/transaction/{transactionId}/payment/{paymentId}/signatory/{signatoryId}/log',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxSignatoryLog' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
    signatoryId: { type: 'integer', required: true },
  },
};

export const editTransferPayment: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['managePayment'],
  description: 'Edits the amount and value date of a transfer payment',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/editTransfer',
  path: '/transaction/{transactionId}/payment/{paymentId}/editTransfer',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    valueDate: { type: 'string', format: 'date', required: true },
    paymentAmount: { type: 'number', required: true },
  },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const cancelTransferPayment: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['managePayment'],
  description: 'Cancels a transfer payment',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/cancelTransfer',
  path: '/transaction/{transactionId}/payment/{paymentId}/cancelTransfer',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const submitPaymentPortfolio: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['submitPayment', 'clientSubmitPayment'],
  description: 'Submit a portfolio for a payment',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/submit',
  path: '/transaction/{transactionId}/payment/{paymentId}/submit',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment', message: 'Payment successfully submitted', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { supersededPortfolioId: { type: 'number' } },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const addPaymentPortfolioDocument: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['submitPayment', 'clientSubmitPayment'],
  description: 'Adds a document to an existing portfolio',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/addDocument',
  path: '/transaction/{transactionId}/payment/{paymentId}/addDocument',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment', message: 'Portfolio document successfully updated', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    documentIdentifier: { type: 'string', required: true },
    reason: { type: 'string', required: true },
  },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const removePaymentPortfolioDocument: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['submitPayment', 'clientSubmitPayment'],
  description: 'Removes a document from an existing portfolio',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/removeDocument',
  path: '/transaction/{transactionId}/payment/{paymentId}/removeDocument',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment', message: 'Remove Document successfully requested', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    documentIdentifier: { type: 'string', required: true },
    reason: { type: 'string', required: true },
  },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const resendPaymentPortfolioEmail: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['submitPayment', 'clientSubmitPayment'],
  description: 'Resends an email to a signatory',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/signatory/{signatoryId}/resendEmail',
  path: '/transaction/{transactionId}/payment/{paymentId}/signatory/{signatoryId}/resendEmail',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment', message: 'Resend Email successfully requested', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
    signatoryId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const getPaymentPortfolioStatus: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['submitPayment', 'clientSubmitPayment'],
  description: 'Gets the complete status of a portfolio',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/portfolioStatus',
  path: '/transaction/{transactionId}/payment/{paymentId}/portfolioStatus',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPaymentStatus' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { supersededPortfolioId: { type: 'string', required: true } },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const cancelPaymentPortfolio: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['submitPayment', 'clientSubmitPayment'],
  description: 'Requests the cancellation of a portfolio',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/cancelPortfolio',
  path: '/transaction/{transactionId}/payment/{paymentId}/cancelPortfolio',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { rejectionReason: { type: 'string', required: true } },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const sharePaymentPortfolioInstruction: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['submitPayment', 'clientSubmitPayment'],
  description: 'Shares the payment instruction for the portfolio',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/sharePortfolio',
  path: '/transaction/{transactionId}/payment/{paymentId}/sharePortfolio',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const getPortfolioLink: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: [],
  description: "Gets an encoded link to the payment's portfolio",
  method: 'get',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/getPortfolioLink/{paymentPortfolioId}',
  path: '/transaction/{transactionId}/payment/{paymentId}/getPortfolioLink/{paymentPortfolioId}',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
    paymentPortfolioId: { type: 'integer' },
  },
  implementedByFrontEnd: true,
};

export const downloadArchivedPortfolio: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Downloads the archived portfolio for the payment',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/downloadArchivedPortfolio',
  path: '/transaction/{transactionId}/payment/{paymentId}/downloadArchivedPortfolio',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { type: 'download', description: 'File Stream' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
  reportAction: true,
};

export const rejectPaymentPortfolio: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releasePayment'],
  description: 'Rejects a submitted portfolio',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/rejectPaymentPortfolio',
  path: '/transaction/{transactionId}/payment/{paymentId}/rejectPaymentPortfolio',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    rejectionReason: { type: 'string', required: true },
  },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const paymentPortfolioReadyForSignature: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releasePayment'],
  description: 'Marks a portfolio as ready for signature',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/paymentPortfolioReadyForSignature',
  path: '/transaction/{transactionId}/payment/{paymentId}/paymentPortfolioReadyForSignature',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const paymentPortfolioSentBack: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releasePayment'],
  description: 'Marks a portfolio as ready for signature again after it has been signed',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/paymentPortfolioSentBack',
  path: '/transaction/{transactionId}/payment/{paymentId}/paymentPortfolioSentBack',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    rejectionReason: { type: 'string', required: true },
  },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const paymentPortfolioQueryInformation: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releasePayment'],
  description: 'Marks a portfolio as information queried',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/paymentPortfolioQueryInformation',
  path: '/transaction/{transactionId}/payment/{paymentId}/paymentPortfolioQueryInformation',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    rejectionReason: { type: 'string', required: true },
  },
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const paymentPortfolioSigned: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releasePayment'],
  description: 'Marks a portfolio as signed',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/paymentPortfolioSigned',
  path: '/transaction/{transactionId}/payment/{paymentId}/paymentPortfolioSigned',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const paymentAwaitingFunds: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releasePayment'],
  description: 'Marks a portfolio as Awaiting Funds',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/paymentAwaitingFunds',
  path: '/transaction/{transactionId}/payment/{paymentId}/paymentAwaitingFunds',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const paymentAwaitingSettlement: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releasePayment'],
  description: 'Marks a portfolio as awaiting settlement',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/paymentAwaitingSettlement',
  path: '/transaction/{transactionId}/payment/{paymentId}/paymentAwaitingSettlement',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const paymentSettlementPostponed: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releasePayment'],
  description: 'Marks a portfolio as settlement postponed',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/paymentSettlementPostponed',
  path: '/transaction/{transactionId}/payment/{paymentId}/paymentSettlementPostponed',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const paymentComplete: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releasePayment'],
  description: 'Marks a payment as complete',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/paymentComplete',
  path: '/transaction/{transactionId}/payment/{paymentId}/paymentComplete',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const paymentConfirmationUploaded: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releasePayment'],
  description: 'Triggers the share portfolio on upload workflow',
  method: 'put',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/paymentConfirmationUploaded',
  path: '/transaction/{transactionId}/payment/{paymentId}/paymentConfirmationUploaded',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    transactionId: { type: 'integer', required: true },
    paymentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const getPaymentMessageLogs: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets logs for a paymentId',
  method: 'patch',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/logs',
  path: '/transaction/{transactionId}/payment/{paymentId}/logs',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'Array of LfxPaymentMessageLog' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { transactionId: { type: 'integer', required: true }, paymentId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const getPaymentPortfolioHistory: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets portfolio history for a paymentId',
  method: 'patch',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/history',
  path: '/transaction/{transactionId}/payment/{paymentId}/history',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'Array of LfxPaymentPortfolio' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { transactionId: { type: 'integer', required: true }, paymentId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const getPaymentEmailLogs: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets email logs for a paymentId',
  method: 'patch',
  collectionPath: '/transaction/{transactionId}/payment/{paymentId}/emailLogs',
  path: '/transaction/{transactionId}/payment/{paymentId}/emailLogs',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'Array of LdbEmailLogs' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { transactionId: { type: 'integer', required: true }, paymentId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const getTransactionDealPayments: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a list of deal payments for a transaction',
  method: 'patch',
  collectionPath: '/transaction/{transactionId}/dealPayment',
  path: '/transaction/{transactionId}/dealPayment',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'A list of LfxDealPayment' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: { transactionId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const getInwardSwifts: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a list of Inward SWIFTs available to the logged in user',
  method: 'patch',
  collectionPath: '/swift',
  path: '/swift',
  tags: ['swift'],
  headers: {},
  responses: {
    200: { description: 'A list of LfxInwardSwift' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: {},
  implementedByFrontEnd: true,
};

export const getInwardSwift: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a Inward SWIFT by ID',
  method: 'get',
  collectionPath: '/swift',
  path: '/swift/{swiftId}',
  tags: ['swift'],
  headers: {},
  responses: {
    200: { description: 'LfxInwardSwift' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { swiftId: { type: 'integer', required: true } },
};

export const updateInwardSwiftStatus: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['manageSWIFT'],
  description: 'Updates the status of an Inward SWIFT',
  method: 'put',
  collectionPath: '/swift/{swiftId}/updateStatus',
  path: '/swift/{swiftId}/updateStatus',
  tags: ['swift'],
  headers: {},
  responses: {
    200: { description: 'LfxInwardSwift' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { newStatus: { type: 'string' } },
  pathParams: { swiftId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const getInwardSwiftLogs: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets logs for a inwardSwiftId',
  method: 'patch',
  collectionPath: '/swift/{swiftId}/logs',
  path: '/swift/{swiftId}/logs',
  tags: ['swift'],
  headers: {},
  responses: {
    200: { description: 'Array of LfxTransactionFileLog' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    swiftId: {
      type: 'integer',
      required: true,
    },
  },
  implementedByFrontEnd: true,
};
// FROM HERE

export const assignSwiftToClient: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary'],
  permissions: ['manageSWIFT'],
  description: 'Assigns an Unassigned SWIFT to a client',
  method: 'put',
  collectionPath: '/swift/{swiftId}/assignToClient',
  path: '/swift/{swiftId}/assignToClient',
  tags: ['swift'],
  headers: {},
  responses: {
    200: { description: 'LfxInwardSwift' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { clientId: { type: 'integer', required: true } },
  pathParams: { swiftId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const assignSwiftToBankAccount: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageSWIFT', 'managePayment'],
  description: 'Assigns an Unassigned SWIFT to an account',
  method: 'put',
  collectionPath: '/swift/{swiftId}/assignToBankAccount',
  path: '/swift/{swiftId}/assignToBankAccount',
  tags: ['swift'],
  headers: {},
  responses: {
    200: { description: 'LfxInwardSwift' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { bankAccountId: { type: 'integer', required: true } },
  pathParams: { swiftId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const createSwiftForClient: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['manageSWIFT'],
  description: 'Creates a LfxInwardSwift for a client',
  method: 'post',
  collectionPath: '/account/{accountId}/bankAccount/{bankAccountId}/swift',
  path: '/account/{accountId}/bankAccount/{bankAccountId}/swift',
  tags: ['payment'],
  headers: {},
  responses: {
    200: { description: 'LfxInwardSwift', message: 'Inward SWIFT successfully created', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    paymentId: { type: 'integer' },
    swiftNumber: { type: 'string', required: true },
    currencyId: { type: 'string', required: true },
    amount: { type: 'number', required: true },
    remitterBic: { type: 'string', required: true },
    correspondentBic: { type: 'string' },
    remitterName: { type: 'string', required: true },
    remitterAccount: { type: 'string', required: true },
    remitterAddress1: { type: 'string', required: true },
    remitterAddress2: { type: 'string', required: true },
    remitterAddress3: { type: 'string', required: true },
    valueDate: { type: 'string', format: 'date', required: true },
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    bankAccountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
