import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import definitions from '@/definitions';

import env from './store/config_env';

import 'packages';

import LfxCurrencyPairTile from '@/views/config/LfxCurrencyPairTile.vue';
import LfxCountryTile from '@/components/system/LfxCountryTile.vue';
import LfxCurrencyTile from '@/views/config/LfxCurrencyTile.vue';
import LDBButton from '@/landobyte_vue/LDBButton.vue';
import LfxBanner from '@/components/layout/LfxBanner.vue';
import LDBModal from '@/landobyte_vue/LDBModal.vue';
import LDBFieldUpload from '@/landobyte_vue/LDBFieldUpload.vue';
import LDBField from '@/landobyte_vue/LDBField.vue';
import LDBFieldJSONGeneric from '@/landobyte_vue/LDBFieldJSONGeneric.vue';
import LfxFieldBOPCategories from '@/components/system/LfxFieldBOPCategories.vue';
import LfxFieldSignatories from '@/components/system/LfxFieldSignatories.vue';
import LDBJSONObject from '@/landobyte_vue/LDBJSONObject.vue';
import LfxAvatar from '@/components/system/LfxAvatar.vue';
import LDBSearchBar from '@/landobyte_vue/LDBSearchBar.vue';
import LDBSearchChips from '@/landobyte_vue/LDBSearchChips.vue';
import LDBOptionChip from '@/landobyte_vue/LDBOptionChip.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import LDBSearchRange from '@/landobyte_vue/LDBSearchRange.vue';

import LDBForm from '@/landobyte_vue/LDBForm.vue';
import LDBDataLoader from '@/landobyte_vue/LDBDataLoader.vue';
import LDBErrorHandler from '@/landobyte_vue/LDBErrorHandler.vue';

const validator = require('validator');
//@ts-ignore
window.validator = validator;
//@ts-ignore
window.breakpoints = {
  xs: 600,
  sm: 960,
  md: 1264,
  lg: 1904,
};

//@ts-ignore
const app = createApp(App);
app.use(store).use(router).use(env);
app.component('LfxCurrencyPairTile', LfxCurrencyPairTile);
app.component('LfxCountryTile', LfxCountryTile);
app.component('LfxCurrencyTile', LfxCurrencyTile);
app.component('LDBButton', LDBButton);
app.component('LfxBanner', LfxBanner);
app.component('LfxModal', LDBModal);
app.component('LDBModal', LDBModal);
app.component('LDBFieldUpload', LDBFieldUpload);
app.component('LDBField', LDBField);
app.component('LfxFieldBOPCategories', LfxFieldBOPCategories);
app.component('LfxFieldSignatories', LfxFieldSignatories);
app.component('LfxAvatar', LfxAvatar);
app.component('LDBFieldJSONGeneric', LDBFieldJSONGeneric);
app.component('LDBJSONObject', LDBJSONObject);
app.component('LDBSearchBar', LDBSearchBar);
app.component('LDBSearchChips', LDBSearchChips);
app.component('LDBOptionChip', LDBOptionChip);
app.component('LDBLoader', LDBLoader);
app.component('LDBSearchRange',LDBSearchRange);

// app.component('LDBForm',LDBForm);
app.component('LDBDataLoader', LDBDataLoader);
app.component('LDBErrorHandler', LDBErrorHandler);
app.mount('#app');

// load_env_variables();
store.dispatch('db_load_definitions', definitions);

