<template>
  <LDBModal result="info" :header="`${header}`" :size="modalSize" :closable="false">
    <template #body>
      <capitec-stepper
        v-if="!userCanOnlySubmit"
        layout="horizontal"
        :steps="steps"
        :active-step-number="activeStepNumber"
        @step-click="onStepClick"
      >
      </capitec-stepper>

      <h1 v-if="activeStepName !== 'bopInfo' && activeStepName !== 'documents'">{{ activeStepLabel }}</h1>

      <p v-if="isDevelopment">{{ bopCatsComplete }}</p>
      <p v-if="isDevelopment">{{ bopCatsAreSaved }}</p>

      <div>
        <LDBField
          v-for="field in viewFields"
          :key="field.name"
          :field="field"
          :view="field.view || 'edit'"
          :fieldname="field.name"
          :definition="definition"
          :record="paymentRecord"
          :overrideOptions="overrideOptions(field.name)"
          :overrideOptionsSearchBarNumber="overrideOptionsSearchBarNumber(field.name)"
          @change="on_change"
        />

        <!-- detailsOfCharge, beneficiaryReference, specialInstructions -->

        <template v-if="showSwiftFields">
          <LDBField
            v-for="field in inwardSwiftViewFields"
            :key="field.name"
            :field="field"
            view="item"
            :fieldname="field.name"
            :definition="swiftDefinition"
            :record="inwardSwiftRecord"
          />
        </template>

        <template v-if="activeStepName === 'bopCategories'">
          <template v-for="bopCatIndex in numberOfBopCats" :key="bopCatIndex">
            <capitec-spacer />
            <div class="flex-container">
              <div v-if="numberOfBopCats > 1" class="flex-item-number">BOP Category #{{ bopCatIndex }}</div>
              <div class="flex-item">
                <LDBField
                  :key="bopCatGroupField(bopCatIndex).name"
                  :field="bopCatGroupField(bopCatIndex)"
                  :view="view"
                  :fieldname="bopCatGroupField(bopCatIndex).name"
                  :definition="undefined"
                  :record="bopCatDisplayRecord"
                  :overrideOptions="bopCatGroupOptions"
                  @change="
                    on_change_bopCatGroup({ fieldname: bopCatGroupField(bopCatIndex).name, index: bopCatIndex }, $event)
                  "
                />
              </div>
              <div class="flex-item">
                <LDBField
                  :key="bopCatField(bopCatIndex).name"
                  :field="bopCatField(bopCatIndex)"
                  :view="view"
                  :fieldname="bopCatField(bopCatIndex).name"
                  :definition="undefined"
                  :record="bopCatDisplayRecord"
                  :overrideOptions="bopCatOptions(bopCatIndex)"
                  @change="on_change_bopCat({ fieldname: bopCatField(bopCatIndex).name, index: bopCatIndex }, $event)"
                />
              </div>
              <div class="flex-item-amount">
                <LDBField
                  :key="`${bopCatIndex}-${foreignAmountField.name}`"
                  :field="foreignAmountField(bopCatIndex)"
                  :view="bopCatIndex === numberOfBopCats ? 'item' : 'edit'"
                  :fieldname="foreignAmountField(bopCatIndex).name"
                  :definition="undefined"
                  :record="bopCatDisplayRecord"
                  @change="
                    on_change_foreignAmount(
                      { fieldname: foreignAmountField(bopCatIndex).name, index: bopCatIndex },
                      $event
                    )
                  "
                />
              </div>
              <div class="flex-item" v-if="displayAllowanceIndicatorField(bopCatIndex)">
                <LDBField
                  :key="allowanceIndicatorField(bopCatIndex).name"
                  :field="allowanceIndicatorField(bopCatIndex)"
                  :view="view"
                  :fieldname="allowanceIndicatorField(bopCatIndex).name"
                  :definition="undefined"
                  :record="bopCatDisplayRecord"
                  @change="
                    on_change_bopAlloanceIndicator(
                      { fieldname: allowanceIndicatorField(bopCatIndex).name, index: bopCatIndex },
                      $event
                    )
                  "
                />
              </div>
              <div class="flex-item" v-if="displayFiaPinField(bopCatIndex)">
                <LDBField
                  :key="fiaPinField(bopCatIndex).name"
                  :field="fiaPinField(bopCatIndex)"
                  :view="view"
                  :fieldname="fiaPinField(bopCatIndex).name"
                  :definition="undefined"
                  :record="bopCatDisplayRecord"
                  @change="on_change_bopFiaPin({ fieldname: fiaPinField(bopCatIndex).name, index: bopCatIndex }, $event)"
                />
              </div>
            </div>
          </template>

          <template v-if="!bopCatTotalIsCorrect">
            <p class="error">
              The sum of the Foreign Amounts captured for the BOP Categories ({{
                paymentRecord?.bopCatForeignAmountTotal / 100
              }}) don't match the Total Foreign Amount for the Payment ({{ paymentRecord?.foreignAmount / 100 }}).
            </p>
            <p v-if="bopCatTotalDelta > 0" class="error">
              Reduce the Foreign Amounts captured here by a total of {{ -bopCatTotalDelta / 100 }}.
            </p>
            <p v-if="bopCatTotalDelta < 0" class="error">
              Increase the Foreign Amounts captured here by a total of {{ -bopCatTotalDelta / 100 }}.
            </p>
          </template>

          <template v-if="numberOfBopCats > 1">
            <p>Capture the BOP Category Group, BOP Category, and Foreign Amount for each entry.</p>
            <p>
              The Foreign Amount for the last entry is not editable and is calculated as the Total Foreign Amount less
              the Foreign Amount for each category.
            </p>
          </template>
        </template>

        <template v-if="activeStepName === 'bopInfo'">
          <template v-if="bopCategoryStepIsError">
            <p>
              Please complete
              <strong
                >Step {{ findStepIndex('bopCategories') }}:
                {{ steps?.[findStepIndex('bopCategories') - 1]?.title }}</strong
              >
              first before returning to this step.
            </p>
          </template>

          <template v-else>
            <h1>Required BOP Info</h1>
            <p v-if="requiredBopInfoFields.length === 0">
              There are no Required Fields for the selected BOP Categories.
            </p>
            <LDBField
              v-for="field in requiredBopInfoFields"
              :key="field.name"
              :field="field"
              :view="view"
              :fieldname="field.name"
              :definition="definition"
              :record="paymentRecord"
              @change="on_change"
            />
            <h1>Optional BOP Info</h1>
            <p v-if="optionalBopInfoFields.length === 0">
              There are no Optional Fields for the selected BOP Categories.
            </p>
            <LDBField
              v-for="field in optionalBopInfoFields"
              :key="field.name"
              :field="field"
              :view="view"
              :fieldname="field.name"
              :definition="definition"
              :record="paymentRecord"
              @change="on_change"
            />
            <template v-if="additionalBopInfoFields.length > 0">
              <h1>Additional BOP Info</h1>
              <LDBField
                v-for="field in additionalBopInfoFields"
                :key="field.name"
                :field="field"
                :view="view"
                :fieldname="field.name"
                :definition="definition"
                :record="paymentRecord"
                @change="on_change"
              />
            </template>
          </template>
        </template>

        <template v-if="activeStepName === 'documents'">
          <template v-if="bopCategoryStepIsError">
            <p>
              Please complete
              <strong
                >Step {{ findStepIndex('bopCategories') }}:
                {{ steps?.[findStepIndex('bopCategories') - 1]?.title }}</strong
              >
              first before returning to this step.
            </p>
          </template>

          <template v-else>
            <h1>Required Documents</h1>
            <p v-if="documents?.required?.length === 0">
              There are no Required Documents for the selected BOP Categories.
            </p>
            <LDBField
              v-for="fieldName in documents.required"
              :key="`doc-${fieldName}`"
              :field="documentField(fieldName)"
              :view="view"
              :fieldname="getDocument_fieldname(fieldName)"
              :definition="definition"
              :record="documentDisplayRecord"
              @change="on_change_docCount(fieldName, $event)"
            />
            <h1>Optional Documents</h1>
            <p v-if="documents?.optional?.length === 0">
              There are no Optional Documents for the selected BOP Categories.
            </p>
            <LDBField
              v-for="fieldName in documents.optional"
              :key="`doc-${fieldName}`"
              :field="documentField(fieldName)"
              :view="view"
              :fieldname="getDocument_fieldname(fieldName)"
              :definition="definition"
              :record="documentDisplayRecord"
              @change="on_change_docCount(fieldName, $event)"
            />
            <template v-if="documents?.additional?.length > 0">
              <h1>Additional Documents</h1>
              <LDBField
                v-for="fieldName in documents.additional"
                :key="`doc-${fieldName}`"
                :field="documentField(fieldName)"
                :view="view"
                :fieldname="getDocument_fieldname(fieldName)"
                :definition="definition"
                :record="documentDisplayRecord"
                @change="on_change_docCount(fieldName, $event)"
              />
            </template>
          </template>
        </template>

        <template v-if="activeStepName === 'signatories'">
          <template v-if="paymentRecord?.signatoryOption === 'submittingUser'">
            <p>The user that submits the payment will be set as the Signatory.</p>

            <template v-if="permissions.submitPaymentPortfolio">
              <template v-if="!user_hasSignatory">
                <p>
                  While you do normally have permission to submit payments, your user has not been activated as a
                  Signatory.
                </p>
                <p>
                  You will therefore not be able to submit this payment, as the submitting user will be set as the
                  signatory.
                </p>
              </template>

              <template v-else-if="userSignatory_retrievalStatus === 'retrieving'">
                <LDBLoader />
              </template>

              <template v-else-if="userSignatory_retrievalStatus === 'retrieved'">
                <template v-if="!userSignatoryIsActive">
                  <p>While you do normally have permission to submit payments, your Signatory has been disabled.</p>
                  <p>
                    You will therefore not be able to submit this payment, as the submitting user will be set as the
                    signatory.
                  </p>
                </template>

                <template v-else>
                  <p>Your Signatory is Active.</p>
                </template>
              </template>

              <template v-else-if="userSignatory_retrievalStatus === 'failed'">
                <p>Your Signatory could not be retrieved.</p>
              </template>
            </template>
          </template>

          <template
            v-else-if="['client', 'intermediary', 'intermediaryAndClient'].includes(paymentRecord?.signatoryOption)"
          >
            <template v-if="signatories_retrievalStatus === 'retrieving'">
              <LDBLoader />
            </template>

            <template v-else-if="signatories_retrievalStatus === 'retrieved'">
              <LfxSignatoryOrder
                :order="signatoryPoolOrder"
                :records="signatoryPool"
                :fields="{ info: 'emailAddress', subtitle: 'capacity', description: 'signerLevel' }"
                :nameFields="['firstName', 'surname']"
                :showCheckbox="showCheckbox"
                @on-order-change="onSignatoryOrderChange"
                @on-record-check="onRecordCheck"
              />
            </template>

            <template v-else-if="signatories_retrievalStatus === 'failed'">
              <p>The account signatories could not be retrieved.</p>
              <!-- TODO action if failed to retrieve -->
            </template>
          </template>
        </template>

        <template v-if="activeStepName === 'review'">
          <template v-if="thereIsSomethingWrong">
            <h1>Outstanding Information</h1>
            <p v-if="transactionType === 'inwardDeal' && !inwardSwiftId">
              <!-- TODO check inwardPayment -->
              There is oustanding information in
              <strong
                >Step {{ findStepIndex('inwardSwift') }}: {{ steps?.[findStepIndex('inwardSwift') - 1]?.title }}.</strong
              >
            </p>
            <p v-if="transactionType === 'inwardDeal' && inwardSwiftId && !isInwardSwiftAssignedToPayment">
              There is unsaved information in
              <strong
                >Step {{ findStepIndex('inwardSwift') }}: {{ steps?.[findStepIndex('inwardSwift') - 1]?.title }}.</strong
              >
            </p>
            <p v-if="!paymentComplete">
              There is outstanding information in
              <strong>Step {{ findStepIndex('payment') }}: {{ steps?.[findStepIndex('payment') - 1]?.title }}.</strong>
            </p>
            <p v-if="paymentComplete && !paymentSaved">
              There is unsaved information in
              <strong>Step {{ findStepIndex('payment') }}: {{ steps?.[findStepIndex('payment') - 1]?.title }}.</strong>
            </p>
            <p v-if="paymentComplete && !!destinationBankAccountRecord?.beneficiaryIsNonCompliant">
              The selected Beneficiary has non-compliant information. Please check length of fields and special characters.
            </p>
            <p v-if="!bopCatsAreSaved">
              There is unsaved information in
              <strong
                >Step {{ findStepIndex('bopCategories') }}:
                {{ steps?.[findStepIndex('bopCategories') - 1]?.title }}.</strong
              >
            </p>
            <p v-if="!bopInfoIsSaved">
              There is unsaved information in
              <strong>Step {{ findStepIndex('bopInfo') }}: {{ steps?.[findStepIndex('bopInfo') - 1]?.title }}.</strong>
            </p>
            <p v-if="!requiredBopInfoComplete">
              There is outstanding information in
              <strong>Step {{ findStepIndex('bopInfo') }}: {{ steps?.[findStepIndex('bopInfo') - 1]?.title }}.</strong>
            </p>
            <p v-if="!documentsAreSaved">
              There is unsaved information in
              <strong
                >Step {{ findStepIndex('documents') }}: {{ steps?.[findStepIndex('documents') - 1]?.title }}.</strong
              >
            </p>
            <p v-if="!signatoriesAreSaved || !signatoriesAreComplete">
              There is unsaved information in
              <strong
                >Step {{ findStepIndex('signatories') }}: {{ steps?.[findStepIndex('signatories') - 1]?.title }}.</strong
              >
            </p>
            <p v-if="paymentRecord?.signatoryOption === 'submittingUser' && !userSignatoryIsActive">
              A user with an active signatory will have to submit this payment.
            </p>
            <p v-if="!transactionFileAwaitingPayment">The Transaction File has deals that have not been released.</p>
            <p v-if="paymentRecord?.isValueDateInThePast">The value date for this Payment is in the past.</p>
            <p v-if="outstandingInformation.length > 0">
              Outstanding information: {{ outstandingInformation }}
            </p>
          </template>

          <template v-for="recordName in recordNames" :key="recordName">
            <h1>{{ headingForRecordName(recordName) }}</h1>
            <LDBField
              v-for="recordFieldName in recordFieldNames(recordName)"
              :key="`${recordName}-${recordFieldName}`"
              :field="fieldByRecordAndFieldName(recordName, recordFieldName)"
              view="item"
              :fieldname="recordFieldName"
              :definition="definitionByRecordName(recordName)"
              :record="recordByRecordName(recordName)"
              @change="on_change"
            />
          </template>

          <template v-if="hasReusablePreviousPortfolios">
            <h1>Re-use Previous Portfolio</h1>
            <LDBField
              key="reusePortfolio"
              :field="reusePortfolioField"
              view="edit"
              :fieldname="reusePortfolioField.name"
              :definition="undefined"
              :record="reusePortfolioRecord"
              :overrideOptions="previousPortfolioOptions"
              @change="on_change_previous_portfolio"
            />
          </template>
        </template>
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in leftButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :viewGuards="button.viewGuards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
      </LDBButton>
      <capitec-spacer />
      <LDBButton
        v-for="button in rightButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :viewGuards="button.viewGuards"
        :enabledGuards="button.enabledGuards"
        :tooltip="button.tooltip"
        @click="button.clickEvent"
      >
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import { getDefinition } from '@/db-interface';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

import lodash from 'lodash';
const merge = lodash.merge;

import LDBField from '@/landobyte_vue/LDBField.vue';
import TableLite from '@/views/general/TableLite.vue';

import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

import LfxSignatoryOrder from '@/views/signatories/LfxSignatoryOrder.vue';

export default {
  name: 'LfxCapturePayment',
  components: { LDBField, TableLite, LDBLoader, LfxSignatoryOrder },
  props: {},
  data() {
    return {
      activeStepNumber: 1,
      signatoryOptionForPool: '',
      signatoryPool: {},
      signatoryOptionForPoolOrder: '',
      signatoryPoolOrder: [],
      showCheckbox: true,
      // previousPortfolioId: null,
      reusePortfolioRecord: { reusePortfolio: null },
    };
  },
  created() {
    // this.initialiseBopCats();
    this.ensureDocumentsIsArray();

    if (this.transactionType === 'inwardDeal') {
      this.paymentRecord.detailsOfCharge = 'OUR';
    }

    // this.initialisePaymentSignatoryOption();
    void this.accountRecord?.configuredSignatories;
  },
  mounted() {
    this.activeStepNumber = this.getInitialActiveStep();
  },
  beforeUnmount() {
    this.paymentRecord.revert();
  },
  computed: {
    ...mapGetters(['activeBopCatDocs', 'permissions', 'isDevelopment', 'bankDirectClients', 'tradeSettings']),
    ...mapState([
      'account',
      'bankAccount',
      'transaction',
      'payment',
      'user',
      'signatory',
      'authUser',
      'beneficiary',
      'swift',
      'systemSettings',
      'dbIntermediarySettings',
    ]),
    // ----- Temporary Feature Enable Code -----
    showCryptoAndSdaFields() {
      return !!this.systemSettings?.featureEnable?.sdaOnBop && this.accountRecord.accountType === 'individual';
    },
    //
    // ----- Capitec hackiness -----

    isBranchClient() {
      // this is to distinguish between Capitec Business Bank Clients and Capitec Retail
      return this.bankDirectClients && (this.dbIntermediarySettings?.enforceSimplifiedTrading || this.account?.[this.authUser?.accountId]?.enforceSimplifiedTrading) !== 'fixedRate'
        ? true
        : false;
    },

    // ----- Header -----

    header() {
      return 'Capture Payment Details';
    },

    modalSize() {
      switch (this.activeStepName) {
        case 'bopCategories':
          return 'xx-large';
        default:
          return 'x-large';
      }
    },

    userCanOnlySubmit() {
      return this.permissions?.submitPaymentPortfolio &&
        !(
          this.permissions?.updatePayment ||
          this.permissions?.updatePaymentBopCats ||
          this.permissions?.updatePaymentDocuments ||
          this.permissions?.updatePaymentSignatories
        )
        ? true
        : false;
    },

    // ----- Stepper -----

    steps() {
      let steps = [
        {
          stepName: 'inwardSwift',
          stepLabel: 'Inward SWIFT',
          isError: this.inwardSwiftStepIsError,
          title: 'SWIFT',
          // guards: this.transactionType === 'inwardDeal',
          guards: ['inwardDeal', 'inwardPayment'].includes(this.transactionType),
        },
        {
          stepName: 'payment',
          stepLabel: 'Payment Details',
          isError: this.paymentStepIsError,
          title: 'Payment',
          guards: true,
        },
        {
          stepName: 'bopCategories',
          stepLabel: 'BOP Categories',
          isError: this.bopCategoryStepIsError,
          title: 'Categories',
          guards: true,
        },
        {
          stepName: 'bopInfo',
          stepLabel: 'BOP Info',
          isError: this.bopInfoStepIsError,
          title: 'BOP Info',
          guards: true,
        },
        {
          stepName: 'documents',
          stepLabel: 'Documents',
          isError: this.documentsStepIsError,
          title: 'Documents',
          guards: true,
        },
        {
          stepName: 'signatories',
          stepLabel: 'Signatories',
          isError: this.signatoryStepIsError,
          title: 'Signatories',
          guards: this.signatoryStepGuard,
        },
        {
          stepName: 'review',
          stepLabel: 'Review and Submit',
          isError: true,
          title: this.reviewStepTitle,
          guards: true,
        },
      ];
      return steps.filter((step) => step.guards);
    },

    activeStepIndex() {
      return this.activeStepNumber - 1; // Steps start at 1
    },
    activeStepName() {
      // let activeStepIndex = this.activeStepNumber - 1; // Steps start at 1
      return this.steps?.[this.activeStepIndex].stepName;
    },
    activeStepLabel() {
      // let activeStepIndex = this.activeStepNumber - 1; // Steps start at 1
      return this.steps?.[this.activeStepIndex].stepLabel;
    },
    signatoryStepGuard() {
      return (
        this.authUser.accountLevel !== 'client' ||
        (this.paymentRecord?.signatoryOption || this.accountRecord?.bopFormSignatoryOption) === 'client'
      );
    },
    inwardSwiftStepIsError() {
      return this.transactionType !== 'inwardDeal'
        ? false
        : (!this.inwardSwiftId) ||
            (!this.isInwardSwiftAssignedToPayment); // TODO what about inwardPayment
    },
    paymentStepIsError() {
      return this.inwardSwiftStepIsError || !this.paymentComplete || !this.paymentSaved || !!this.destinationBankAccountRecord?.beneficiaryIsNonCompliant; // TODO
    },
    bopCategoryStepIsError() {
      return this.inwardSwiftStepIsError || !this.bopCatsComplete || !this.bopCatsAreSaved;
    },
    bopInfoStepIsError() {
      return (
        this.inwardSwiftStepIsError ||
        this.bopCategoryStepIsError ||
        !this.requiredBopInfoComplete ||
        !this.bopInfoIsSaved
      );
    },
    documentsStepIsError() {
      return (
        this.inwardSwiftStepIsError ||
        this.bopCategoryStepIsError ||
        !this.requireDocumentsComplete ||
        !this.documentsAreSaved
      );
    },
    signatoryStepIsError() {
      return this.inwardSwiftStepIsError || !this.signatoriesAreComplete || !this.signatoriesAreSaved;
    },
    reviewStepTitle() {
      return this.permissions.submitPaymentPortfolio ? 'Submit' : 'Review';
    },

    // ----- Transaction file record -----

    transactionId() {
      return +this.$route.params?.transactionId;
    },
    transactionRecord() {
      return this.transaction?.[this.transactionId];
    },

    transactionType() {
      return this.transactionRecord?.transactionType;
    },

    // ----- Account record ----

    accountId() {
      // return +this.transactionRecord?.accountId;
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },

    accountType() {
      return this.accountRecord?.accountType;
    },
    signatories_retrievalStatus() {
      switch (this.paymentRecord?.signatoryOption) {
        case 'submittingUser':
          return this.userSignatory_retrievalStatus;
        case 'client':
          return this.accountRecord?.signatoriesAndContacts_retrievalStatus;
        case 'intermediary':
          return this.accountRecord?.parentSignatories_retrievalStatus;
        case 'intermediaryAndClient':
          let statusArray = [
            this.accountRecord?.signatoriesAndContacts_retrievalStatus,
            this.accountRecord?.parentSignatories_retrievalStatus,
          ];
          if (statusArray.includes('retrieving')) {
            return 'retrieving';
          } else if (statusArray.includes('failed')) {
            return 'failed';
          } else if (statusArray.every((element) => element === 'retrieved')) {
            return 'retrieved';
          } else {
            return 'none';
          }
        default:
          return undefined;
      }
    },

    // ----- Payment record -----

    paymentId() {
      return +this.$route.params?.paymentId;
    },
    paymentRecord() {
      return this.payment?.[this.paymentId];
    },

    foreignAmount() {
      return this.paymentRecord?.foreignAmount;
    },
    destinationBankAccountId() {
      return +this.paymentRecord?.destinationAccount;
    },
    paymentSignatory_optionStatus() {
      return `${this.paymentRecord?.signatoryOption}-${this.signatories_retrievalStatus}`;
    },

    // ----- Destination Bank Account Record -----

    destinationBankAccountRecord() {
      return this.bankAccount?.[this.destinationBankAccountId];
    },

    beneficiaryId() {
      return +this.destinationBankAccountRecord?.beneficiaryId;
    },
    beneficiaryAccountId() {
      return +this.destinationBankAccountRecord?.accountId;
    },

    // ----- Beneficiary Record -----

    beneficiaryRecord() {
      // return this.beneficiary?.[this.beneficiaryId];
      return this.accountRecord?.beneficiaries?.[this.beneficiaryId];
    },

    beneficiaries_retrievalStatus() {
      return this.accountRecord?.beneficiaries_retrievalStatus;
    },

    // ----- User Signatory Record -----

    authUserRecord() {
      return this.user?.[this.authUser?.id];
    },
    user_hasSignatory() {
      return this.authUserRecord?.hasSignatory;
    },
    userSignatory_retrievalStatus() {
      return this.authUserRecord?.userSignatory_retrievalStatus;
    },
    userSignatoryRecord() {
      return this.authUserRecord?.userSignatory;
    },
    userSignatoryIsActive() {
      return this.authUserRecord?.userSignatoryIsActive;
    },

    // ----- Definition -----

    definition() {
      return getDefinition('payment');
    },
    swiftDefinition() {
      return getDefinition('inward_swift');
    },

    // ----- View -----

    view() {
      return 'edit';
    },

    // ----- Fields -----

    // General

    fields() {
      return this.paymentRecord.guarded_fields;
    },

    // Inward SWIFT

    inwardSwiftFieldNames() {
      return ['inwardSwiftId'];
    },

    inwardSwiftRecordFieldNames() {
      return ['swiftNumber', 'currencyId', 'amount', 'remitterName', 'status', 'messageStatus', 'valueDate'];
    },
    inwardSwiftFields() {
      return this.inwardSwiftRecord?.guarded_fields;
    },
    inwardSwiftViewFields() {
      const res = [];
      for (const fieldName of this.inwardSwiftRecordFieldNames) {
        const fields = this.inwardSwiftFields.filter((field) => field.field === fieldName);
        if (fields.length > 0) {
          const updatedField = this.overrideField(fields[0]);
          res.push(updatedField);
        }
      }
      return res;
    },
    showSwiftFields() {
      return this.activeStepName === 'inwardSwift' && this.inwardSwiftId;
    },

    // Payment

    paymentFieldNames() {
      let fields = ['paymentAmount'];
      switch (this.transactionType) {
        // case 'inwardDeal':
        //   fields.push('inwardSwiftId');
        //   break;
        case 'outwardDeal':
        case 'transferDeal':
        case 'outwardPayment':
          fields.push('sourceAccount');
          break;
      }
      
      fields.push('destinationAccount');
      // if (this.destinationAccountId) {
      if (!['inwardDeal', 'inwardPayment'].includes(this.transactionType)) {
        fields.push('detailsOfCharge');
      }
      fields.push('beneficiaryReference', 'specialInstructions');
      // }
      fields.push('externalReference');
      if (this.showCryptoAndSdaFields) {
        fields.push('isCrypto');
      }
      return fields;
    },

    inwardSwiftId() {
      return this.paymentRecord?.inwardSwiftId;
    },
    inwardSwiftRecord() {
      return this.swift?.[this.inwardSwiftId];
    },
    inwardSwiftPaymentId() {
      return this.inwardSwiftId ? this.inwardSwiftRecord?.paymentId : undefined;
    },
    isInwardSwiftAssignedToPayment() {
      return (
        (this.inwardSwiftId &&
          this.inwardSwiftRecord?.status === 'assigned' &&
          this.inwardSwiftPaymentId === this.paymentId) ||
        false
      );
    },

    inwardSwiftOptions() {
      switch (this.isInwardSwiftAssignedToPayment) {
        // case true:
        //   return this.swift?.inwardSwiftId;
        case false:
          return this.accountRecord?.clientAvailableSwifts;
      }
    },

    destinationAccountId() {
      return +this.paymentRecord?.destinationAccount;
    },
    destinationAccountRecord() {
      return this.bankAccount?.[this.destinationAccountId];
    },
    destinationAccountIsBeneficiaryAccount() {
      return this.destinationBankAccountRecord?.beneficiaryId ? true : false;
    },
    filteredDestinationAccounts() {
      let filteredDestinationAccounts = {};
      switch (this.transactionType) {
        case 'outwardDeal':
          filteredDestinationAccounts = this.accountRecord?.filter_bankAccounts(
            this.accountRecord?.beneficiariesActive_bankAccounts,
            {
              currency: this.paymentRecord?.paymentCurrency,
              includeAuthUserBankAccounts: true,
              bankAccountTypes: 'beneficiary',
              status: 'active',
            }
          );
          break;
        case 'transferDeal':
          // Note: this is for completeness as field should not be editable - see getFieldOverRideObject(field) method
          filteredDestinationAccounts = this.accountRecord?.filter_bankAccounts(
            this.accountRecord?.ownActive_bankAccounts,
            {
              currency: this.paymentRecord?.paymentCurrency,
              includeAuthUserBankAccounts: false,
              bankAccountTypes: 'own',
              status: 'active',
            }
          );
          break;
        case 'inwardDeal':
          filteredDestinationAccounts = this.accountRecord?.filter_bankAccounts(this.accountRecord?.bankAccounts, {
            currency: this.paymentRecord?.paymentCurrency,
            includeAuthUserBankAccounts: true,
            bankAccountTypes: 'both',
            status: 'active',
          });
          break;
        case 'outwardPayment':
          let prefilteredDestinationAccounts = this.accountRecord?.filter_bankAccounts(
            this.accountRecord?.beneficiariesActive_bankAccounts,
            {
              currency: this.paymentRecord?.paymentCurrency,
              includeAuthUserBankAccounts: true,
              bankAccountTypes: 'beneficiary',
              status: 'active',
            }
          );

          if (this.paymentRecord?.paymentCurrency === 'ZAR') {
            let additionallyFilteredBankAccounts = {};
            for (let bankAccountId in prefilteredDestinationAccounts) {
              let bankAccount = prefilteredDestinationAccounts[bankAccountId];
              if (bankAccount?.bankCountryId !== 'ZA') {
                additionallyFilteredBankAccounts[bankAccountId] = bankAccount;
              }
            }
            filteredDestinationAccounts = additionallyFilteredBankAccounts;
          } else {
            filteredDestinationAccounts = prefilteredDestinationAccounts;
          }
          break;
        case 'transferPayment':
          // Note: this isn't a transaction type that is implemented yet, so below is untested
          filteredDestinationAccounts = this.accountRecord?.filter_bankAccounts(
            this.accountRecord?.ownActive_bankAccounts,
            {
              currency: this.paymentRecord?.paymentCurrency,
              includeAuthUserBankAccounts: false,
              bankAccountTypes: 'own',
              status: 'active',
            }
          );
          break;
        case 'inwardPayment':
          filteredDestinationAccounts = this.accountRecord?.filter_bankAccounts(
            this.accountRecord?.ownActive_bankAccounts,
            {
              currency: this.paymentRecord?.paymentCurrency,
              includeAuthUserBankAccounts: false,
              bankAccountTypes: 'own',
              status: 'active',
            }
          );
          break;
      }

      const filteredDestinationAccountsArray = [];
      for (const bankAccountId in filteredDestinationAccounts) {
        filteredDestinationAccountsArray.push(filteredDestinationAccounts[bankAccountId]);
      }
      filteredDestinationAccountsArray.sort((bankAccount1, bankAccount2) => {
        const desc1 = bankAccount1.description?.toUpperCase();
        const desc2 = bankAccount2.description?.toUpperCase();
        if (desc1 < desc2) {
          return -1;
        }
        if (desc1 > desc2) {
          return 1;
        }
        return 0;
      });

      // const test = {};
      // for (const i of filteredDestinationAccountsArray) {
      //   test[i.id] = i;
      // }
      // return test;
      return filteredDestinationAccountsArray;
    },

    // BOP Categories

    bopCatDisplayRecord() {
      this.ensureOneBopCat();
      const bopCategories = [...this.paymentRecord.bopCategories];
      const bopCatDisplayRecord = {};
      for (let i = 0; i < (this.numberOfBopCats || 1); i++) {
        bopCatDisplayRecord[this.bopCatGroupField(i + 1).name] = this.getGroupId(bopCategories[i]);
        bopCatDisplayRecord[this.bopCatField(i + 1).name] = bopCategories[i]?.bopCat;
        bopCatDisplayRecord[this.foreignAmountField(i + 1).name] = bopCategories[i]?.foreignAmount;
        bopCatDisplayRecord[this.allowanceIndicatorField(i + 1).name] = bopCategories[i]?.allowanceIndicators;
        bopCatDisplayRecord[this.fiaPinField(i + 1).name] = bopCategories[i]?.fiaPin;
      }
      return bopCatDisplayRecord;
    },

    bopCategoriesFieldNames() {
      // let fields = ['numberOfBopCats', 'bopCategories'];
      let fields = ['numberOfBopCats'];
      return fields;
    },

    numberOfBopCats() {
      return +this.paymentRecord?.numberOfBopCats;
    },
    actualNumberOfBopCats() {
      return +this.paymentRecord?.bopCategories?.length;
    },
    recordBopCats() {
      return this.paymentRecord?.bopCategories;
    },

    bopFilters() {
      let clientTypes = ['either'];
      switch (this.accountType) {
        case 'entity':
          clientTypes.push('company');
          break;
        case 'individual':
          clientTypes.push('individual');
          break;
      }

      let direction;
      switch (this.transactionType) {
        case 'outwardDeal':
          direction = 'outward';
          break;
        case 'inwardDeal':
          direction = 'inward';
          break;
        case 'transferDeal':
          if (this.paymentRecord?.paymentCurrency === 'ZAR') {
            direction = 'inward';
          } else {
            direction = 'outward';
          }
          break;
        case 'outwardPayment':
          direction = 'outward';
          break;
        case 'inwardPayment':
          direction = 'inward';
          break;
        // TODO check this logic w/ Sunisha - or is it related to countr
      }
      return { status: 'active', clientTypes: clientTypes, direction: direction };
    },

    bopCatGroupOptions() {
      let options = {};
      options[0] = { id: 'hotList', name: 'Hot List' };
      let bopCatGroups = this.$store.getters.config?.bopCatGroup;
      for (let bcg in bopCatGroups) {
        let bopCatGroup = bopCatGroups[bcg];
        if (
          bopCatGroup.status === this.bopFilters?.status &&
          bopCatGroup.direction === this.bopFilters?.direction &&
          this.bopFilters?.clientTypes.includes(bopCatGroup.clientType)
        ) {
          options[bcg] = bopCatGroup;
        }
      }
      return options;
    },

    intermediateBopCatOptions() {
      let options = {};
      let bopCats = this.$store.getters.config.bopCat;
      for (let bc in bopCats) {
        let bopCat = bopCats[bc];
        if (
          bopCat.status === this.bopFilters?.status &&
          bopCat.direction === this.bopFilters?.direction &&
          this.bopFilters?.clientTypes.includes(bopCat.clientType)
        ) {
          options[bc] = bopCat;
        }
      }
      return options;
    },
    cryptoBopCats() {
      let options = {};
      let bopCats = this.intermediateBopCatOptions;
      for (let bc in bopCats) {
        let bopCat = bopCats[bc];
        if (bopCat.allowCryptoTrades) {
          options[bc] = bopCat;
        }
      }
      return options;
    },
    sumOfAdjustableForeignAmounts() {
      let sum = 0;
      // if (this.numberOfBopCats > 1 && Array.isArray(this.paymentRecord?.bopCategories)) {
      if (this.actualNumberOfBopCats > 1) {
        // for (let i = 0; i < this.numberOfBopCats - 1; i++) {
        for (let i = 0; i < this.actualNumberOfBopCats - 1; i++) {
          let bopCatObject = this.paymentRecord?.bopCategories[i];
          let foreignAmount = +bopCatObject.foreignAmount;
          if (foreignAmount) {
            sum = sum + foreignAmount;
          }
        }
      }
      return sum;
    },
    balanceOfForeignAmount() {
      let balance = this.foreignAmount - this.sumOfAdjustableForeignAmounts;
      return balance;
    },
    bopCatTotalIsCorrect() {
      return this.paymentRecord?.bopCatTotalIsCorrect;
    },
    bopCatTotalDelta() {
      return this.paymentRecord?.bopCatForeignAmountTotal - this.paymentRecord?.foreignAmount;
    },

    selectedBopCatIds() {
      let bopCatIds = [];
      let bCatsArr = this.paymentRecord?.bopCategories;
      if (Array.isArray(bCatsArr)) {
        for (let bopCategoryObj of bCatsArr) {
          if (bopCategoryObj.bopCat) {
            bopCatIds.push(bopCategoryObj.bopCat);
          }
        }
      }
      return bopCatIds;
    },

    // BOP Info

    bopInfo() {
      let bopInfo = { included: [], required: [], optional: [], additional: [] };
      
      let requiredArray = ['purposeOfPayment'];
      let optionalArray = [];
      if (this.bankDirectClients) {
        requiredArray = []
        optionalArray = ['purposeOfPayment'];
      }

      let bopCatIds = this.selectedBopCatIds;
      for (let bopCatId of bopCatIds) {
        let bopCatRecord = this.$store.getters.config.bopCat[bopCatId];
        if (this.bankDirectClients) {
          optionalArray.push(...bopCatRecord.requiredInfo);
        } else {
          requiredArray.push(...bopCatRecord.requiredInfo);
        }
        optionalArray.push(...bopCatRecord.optionalInfo);
      }
      let requiredSet = new Set(requiredArray);
      let optionalSet = new Set(optionalArray);
      for (let fieldName of this.bopInfoFieldNames) {
        if (requiredSet.has(fieldName)) {
          bopInfo.included.push(fieldName);
          bopInfo.required.push(fieldName);
        } else if (optionalSet.has(fieldName)) {
          bopInfo.included.push(fieldName);
          bopInfo.optional.push(fieldName);
        }
        if (this.paymentRecord?.[fieldName] && !bopInfo.included.includes(fieldName)) {
          bopInfo.additional.push(fieldName);
        }
      }

      return bopInfo;
    },

    bopInfoFieldNames() {
      let fields = [
        'purposeOfPayment',
        'sarbApprovalNumber',
        'sarbAuthReferenceNumber',
        'sarbLoanReferenceNumber',
        'ucrNumber',
        'taxClearanceCertificateRef',
        'loanTenor',
        'loanInterestRate',
        'importPermitConfirmation',
        'invoiceNumber',
        'mrn',
        'sourceOfFunds',
        'reason',
        'transportDocumentNumber',
        'sarsApnNumber',
      ];
      return fields;
    },

    includedBopInfoFieldNames() {
      return this.bopInfo?.included;
    },

    requiredBopInfoFieldNames() {
      return this.bopInfo?.required;
    },
    requiredBopInfoFields() {
      const res = [];
      for (const fieldName of this.requiredBopInfoFieldNames) {
        const fields = this.fields.filter((field) => field.field === fieldName);
        if (fields.length > 0) {
          const updatedField = this.overrideField(fields[0]);
          res.push(updatedField);
        }
      }
      return res;
    },

    optionalBopInfoFieldNames() {
      return this.bopInfo?.optional;
    },
    optionalBopInfoFields() {
      const res = [];
      for (const fieldName of this.optionalBopInfoFieldNames) {
        const fields = this.fields.filter((field) => field.field === fieldName);
        if (fields.length > 0) {
          const updatedField = this.overrideField(fields[0]);
          res.push(updatedField);
        }
      }
      return res;
    },

    additionalBopInfoFieldNames() {
      return this.bopInfo?.additional;
    },
    additionalBopInfoFields() {
      const res = [];
      for (const fieldName of this.additionalBopInfoFieldNames) {
        const fields = this.fields.filter((field) => field.field === fieldName);
        if (fields.length > 0) {
          const updatedField = this.overrideField(fields[0]);
          res.push(updatedField);
        }
      }
      return res;
    },

    // Documents

    documentDisplayRecord() {
      const documentDisplayRecord = {};
      for (const d of this.recordDocuments) {
        documentDisplayRecord[d.documentName] = d.count;
      }

      return documentDisplayRecord;
    },
    documentFieldNames() {
      return this.activeBopCatDocs;
    },

    recordDocuments() {
      return this.paymentRecord?.documents || [];
    },

    documents() {
      let documents = { included: [], required: [], optional: [], additional: [] };

      let requiredArray = [];
      let optionalArray = [];

      let bopCatIds = this.selectedBopCatIds;
      for (let bopCatId of bopCatIds) {
        let bopCatRecord = this.$store.getters.config.bopCat[bopCatId];
        requiredArray.push(...bopCatRecord.requiredDocuments);
        optionalArray.push(...bopCatRecord.optionalDocuments);
      }
      const requiredArrayFiltered = [];
      const optionalArrayFiltered = [];
      for (const documentConfig of requiredArray) {
        if (typeof documentConfig === 'string') {
          requiredArrayFiltered.push(documentConfig)
        } else {
          if (this.testIncludeDocument(documentConfig)) {
            requiredArrayFiltered.push(documentConfig.documentName)
          }
        }
      }
      for (const documentConfig of optionalArray) {
        if (typeof documentConfig === 'string') {
          optionalArrayFiltered.push(documentConfig)
        } else {
          if (this.testIncludeDocument(documentConfig)) {
            optionalArrayFiltered.push(documentConfig.documentName)
          }
        }
      }

      let requiredSet = new Set(requiredArrayFiltered);
      let optionalSet = new Set(optionalArrayFiltered);

      for (let fieldName of this.documentFieldNames) {
        let index = this.recordDocuments.findIndex((doc) => doc.documentName === fieldName);
        if (requiredSet.has(fieldName)) {
          documents.included.push(fieldName);
          documents.required.push(fieldName);

          if (index < 0) {
            this.paymentRecord?.documents?.push({ documentName: fieldName, count: 1 });
          } else if (!(this.recordDocuments[index].count > 0)) {
            this.paymentRecord.documents[index].count = 1;
          }
        } else if (optionalSet.has(fieldName)) {
          documents.included.push(fieldName);
          documents.optional.push(fieldName);

          if (index < 0) {
            this.paymentRecord.documents.push({ documentName: fieldName, count: 0 });
          }
        }

        if (index > 0 && !documents.included.includes(fieldName)) {
          if (this.recordDocuments[index].count > 0) {
            documents.additional.push(fieldName);
          } else {
            this.paymentRecord.documents.splice(index, 1);
          }
        }
      }

      return documents;
    },

    // Review

    recordNames() {
      switch (this.activeStepName) {
        case 'review':
          let fields = ['paymentRecord'];
          if (this.beneficiaryId && this.beneficiaries_retrievalStatus === 'retrieved') {
            fields.push('beneficiaryRecord');
          }
          // TODO get loading of beneficiaryRecord right
          fields.push('destinationBankAccountRecord');
          return fields;
        default:
          return undefined;
      }
    },

    reusePortfolioField() {
      return {
        name: 'reusePortfolio',
        label: 'Re-use Previous Portfolio?',
        // displayType: 'option',
        datatype: {
          foreignKey: {
            collection: 'fk_payment_portfolios',

            linkTable: 'LfxPaymentPortfolio',
            linkField: 'id',
            displayField: 'integrationId',
          },
        },
        default: null,
        // mandatory: false,
      };
    },

    hasReusablePreviousPortfolios() {
      return this.paymentRecord?.hasReusablePreviousPortfolios;
    },
    previousPortfolioOptions() {
      let options = this.paymentRecord?.reusablePreviousPortfolios;
      options['X'] = { id: 'X', integrationId: "Don't re-use previous portfolio" };
      return options;
    },

    // View

    viewFieldNames() {
      switch (this.activeStepName) {
        case 'inwardSwift':
          return this.inwardSwiftFieldNames;
        case 'payment':
          return this.paymentFieldNames;
        case 'bopCategories':
          return this.bopCategoriesFieldNames;
        // case 'bopInfo':
        //   return this.bopInfoFieldNames;
        case 'signatories':
          return this.signatoryFieldNames;
        default:
          return [];
      }
    },

    viewFields() {
      const res = [];
      for (const fieldName of this.viewFieldNames) {
        const fields = this.fields.filter((field) => field.field === fieldName);
        if (fields.length > 0) {
          const updatedField = this.overrideField(fields[0]);
          res.push(updatedField);
        }
      }
      return res;
    },

    // ----- Signatories -----

    signatoryFieldNames() {
      let fields = ['signatoryOption'];
      return fields;
    },

    signatoryOptions() {
      let signatoryOptions = {};

      switch (this.paymentRecord?.signatoryOption) {
        case 'intermediary':
          signatoryOptions = this.accountRecord?.activeParentSignatories;
          break;
        case 'client':
          signatoryOptions = this.accountRecord?.signatories_activeStatus;
          break;
        case 'intermediaryAndClient':
          signatoryOptions = {
            ...this.accountRecord?.signatories_activeStatus,
            ...this.accountRecord?.activeParentSignatories,
          };
          break;
      }
      return signatoryOptions;
    },
    sortedRecords() {
      const signatoriesArray = [];
      for (const signatoryId in this.signatoryPool) {
        signatoriesArray.push(this.signatoryPool[signatoryId]);
      }
      return signatoriesArray;
    },

    accountSingatoryOption() {
      return this.accountRecord?.bopFormSignatoryOption;
    },

    // ----- Buttons -----

    leftButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'back',
          label: 'Back',
          type: 'clear',
          action: 'click',
          guards: self.activeStepNumber > 1 && !this.userCanOnlySubmit,
          clickEvent() {
            self.onBackClick();
          },
        },
      ];
      return allButtons.filter((button) => button.guards);
    },

    rightButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          action: 'click',
          guards: true,
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

        {
          name: 'save',
          label: 'Save',
          type: '',
          action: this.saveActionForStep,
          params: { transactionId: this.transactionId, paymentId: this.paymentId },
          actionParams: this.saveActionParamsForStep,
          viewGuards: this.activeStepName !== 'review',
          enabledGuards: this.enableSaveButtonForStep, // TODO
          tooltip: this.saveTooltip,
          clickEvent(response) {},
        },
        {
          name: 'next',
          label: 'Next',
          type: 'primary',
          action: this.saveActionForStep,
          params: { transactionId: this.transactionId, paymentId: this.paymentId },
          actionParams: this.saveActionParamsForStep,
          viewGuards: this.activeStepName !== 'review',
          // enabledGuards: this.activeStepName === 'payment' ? !this.payment_requiredFieldsRemaining : true, // TODO
          enabledGuards: this.enableNextButtonForStep, // TODO
          tooltip: this.nextTooltip,
          clickEvent(response) {
            if (response.status === 'success') {
              self.onNextClick();
            }
          },
        },
        {
          name: 'submit',
          label: 'Submit',
          type: 'primary',
          action: 'submitPaymentPortfolio',
          params: { transactionId: this.transactionId, paymentId: this.paymentId },
          actionParams:
            this.reusePortfolioRecord.reusePortfolio && this.reusePortfolioRecord.reusePortfolio !== 'X'
              ? { supersededPortfolioId: this.reusePortfolioRecord.reusePortfolio }
              : {},
          viewGuards: this.activeStepName === 'review',
          enabledGuards: this.paymentRecord.submitPayment_buttonGuard && this.signatoriesAreComplete && !this.destinationBankAccountRecord?.beneficiaryIsNonCompliant,
          // TODO remember supersededPortfolioId - Ask Bevan
          clickEvent(response) {
            if (response.status === 'success') {
              self.$router.go(-1);
            }
          },
        },
      ];
      return allButtons;
    },
    saveActionForStep() {
      switch (this.activeStepName) {
        case 'inwardSwift':
          return 'assignSwiftToPayment';
        case 'payment':
          return 'updatePayment';
        case 'bopInfo':
          return 'updatePayment';
        case 'bopCategories':
          return 'updatePaymentBopCats';
        case 'documents':
          return 'updatePaymentDocuments';
        case 'signatories':
          return 'updatePaymentSignatories';
      }
    },
    saveActionParamsForStep() {
      switch (this.activeStepName) {
        case 'documents':
          let docs = [];
          for (let doc of this.paymentRecord?.documents) {
            if (doc.count > 0 || this.documents.required.includes(doc.documentName)) {
              docs.push(doc);
            }
          }
          return { documents: docs };
        default:
          return this.paymentRecord;
        // let submitObject = {};
        // let filteredFields = this.fields.filter((field) => field.group === this.activeStepName);
        // for (let field of filteredFields) {
        //   submitObject[field.name] = this.paymentRecord?.[field.name];
        // }
        // return submitObject;
      }
    },
    saveTooltip() {
      switch (this.activeStepName) {
        case 'payment':
          return undefined;
        case 'bopCategories':
          if (!this.bopCatsComplete) {
            return {
              message: 'Each entry must have a BOP Category and a Foreign Amount great than zero. Any FIA BOP Category must have an FIA PIN',
              position: 'top',
            };
          } else {
            return undefined;
          }
        case 'signatories':
          if (!this.signatoriesAreComplete) {
            return {
              message: 'At least one signatory must be selected.',
              position: 'top',
            };
          } else {
            return undefined;
          }
        default:
          return undefined;
      }
    },
    nextTooltip() {
      switch (this.activeStepName) {
        case 'inwardSwift':
          if (!this.inwardSwiftId) {
            return { message: 'Please capture an Inward SWIFT', position: 'top' };
          } else {
            return undefined;
          }
        case 'payment':
          let field_array = ['destinationAccount', 'beneficiaryReference'];
          if (['inwardDeal', 'inwardPayment'].includes(this.transactionType)) {
            field_array.push('inwardSwiftId');
          } else {
            field_array.push('sourceAccount', 'detailsOfCharge');
          }

          if (this.showCryptoAndSdaFields) {
            field_array.push('isCrypto');
          }
          let oustandingPaymentStepFields = [];
          for (let fieldName of field_array) {
            if (!this.paymentRecord?.[fieldName]) {
              oustandingPaymentStepFields.push(fieldName); // TODO: push field label rather than name
            }
          }
          return oustandingPaymentStepFields.length > 0
            ? {
                message: `The following fields are outstanding: ${oustandingPaymentStepFields}`,
                position: 'top',
                width: '500px',
              }
            : undefined;
        case 'bopInfo':
          if (!this.requiredBopInfoComplete) {
            return {
              message: 'Please complete all Required BOP Info fields.',
              position: 'top',
            };
          } else {
            return undefined;
          }

        default:
          return this.saveTooltip;
      }
    },

    requiredFieldsForStep() {
      let requiredFields = [];
      switch (this.activeStepName) {
        case 'payment':
          if (!this.bankDirectClients) {
            requiredFields.push('destinationAccount');
          }
          if (this.paymentRecord?.direction === 'inward') {
            requiredFields.push('inwardSwiftId');
          } else {
            requiredFields.push('sourceAccount', 'detailsOfCharge');
          }
      }
      return requiredFields;
    },
    enableSaveButtonForStep() {
      switch (this.activeStepName) {
        case 'payment':
          return true; // TODO
        case 'bopInfo':
        case 'documents':
          return this.bopCatsComplete && this.bopCatsAreSaved;
        default:
          return this.enableNextButtonForStep;
      }
    },
    enableNextButtonForStep() {
      switch (this.activeStepName) {
        case 'inwardSwift':
          return this.inwardSwiftId ? true : false;
        case 'payment':
          return this.paymentComplete;
        case 'bopCategories':
          return this.bopCatsComplete;
        case 'bopInfo':
          return this.bopCatsComplete && this.bopCatsAreSaved && this.requiredBopInfoComplete;
        case 'documents':
          return this.bopCatsComplete && this.bopCatsAreSaved && this.requireDocumentsComplete;
        case 'signatories':
          return this.signatoriesAreComplete;
        default:
          return true;
      }
    },
    paymentIsSubmittable() {
      return this.paymentRecord?.isSubmittable;
    },
    outstandingInformation() {
      return this.paymentRecord?.outstandingFields;
    },
    thereIsSomethingWrong() {
      return (this.transactionType === 'inwardDeal' && !this.inwardSwiftId) ||
        (this.transactionType === 'inwardDeal' && this.inwardSwiftId && !this.isInwardSwiftAssignedToPayment) ||
        !this.paymentComplete ||
        (this.paymentComplete && !this.paymentSaved) ||
        (this.paymentComplete && !!this.destinationBankAccountRecord?.beneficiaryIsNonCompliant) ||
        !this.bopCatsAreSaved ||
        !this.bopInfoIsSaved ||
        !this.requiredBopInfoComplete ||
        !this.documentsAreSaved ||
        !this.signatoriesAreSaved ||
        !this.signatoriesAreComplete ||
        (this.paymentRecord?.signatoryOption === 'submittingUser' && !this.userSignatoryIsActive) ||
        this.paymentRecord?.isValueDateInThePast ||
        !this.transactionFileAwaitingPayment ||
        (this.outstandingInformation.length > 0)
        ? true
        : false;
    },

    // TODO add check for validation errors

    paymentComplete() {
      // if (this.isBranchClient) {
      //   return true;
      // }
      let field_array = ['destinationAccount'];
      if (this.bankDirectClients) {
        field_array = []
      }
      if (['inwardDeal', 'inwardPayment'].includes(this.transactionType)) {
        // field_array.push('inwardSwiftId');
      } else {
        field_array.push('sourceAccount', 'detailsOfCharge');
      }
      return field_array.every((fieldName) => this.paymentRecord?.[fieldName]);
    },
    paymentSaved() {
      let field_array = ['destinationAccount', 'beneficiaryReference'];
      if (['inwardDeal', 'inwardPayment'].includes(this.transactionType)) {
        field_array.push('inwardSwiftId');
      } else {
        field_array.push('sourceAccount', 'detailsOfCharge');
      }
      if (this.showCryptoAndSdaFields) {
        field_array.push('isCrypto');
      }
      return !this.paymentRecord?.changed_fields.some((field) => field_array.includes(field.name));
    },

    bopCatsComplete() {
      // if (this.isBranchClient) {
      //   return true;
      // }
      if (
        Array.isArray(this.paymentRecord?.bopCategories) &&
        this.paymentRecord?.bopCategories?.every((bopCat) => bopCat?.bopCat && bopCat?.foreignAmount > 0) &&
        this.paymentRecord?.bopCatTotalIsCorrect
      ) {
        for (const bopCat of this.paymentRecord?.bopCategories || []) {
          if (bopCat.allowanceIndicators === 'FIA' && !bopCat.fiaPin) {
            return false
          }
        }
        return true;
      }
      return false;
    },
    bopCatsAreSaved() {
      return !this.paymentRecord?.changed_fields.some((field) =>
        ['numberOfBopCats', 'bopCategories'].includes(field.name)
      );
    },

    requiredBopInfoComplete() {
      return (this.isBranchClient && false)
        ? true
        : this.requiredBopInfoFieldNames.every((fieldName) => this.paymentRecord?.[fieldName]);
    },
    bopInfoIsSaved() {
      return !this.paymentRecord?.changed_fields.some((field) => this.bopInfoFieldNames?.includes(field.name));
    },

    requireDocumentsComplete() {
      return (this.bankDirectClients && false)
        ? true
        : this.documents?.required?.every((docName) =>
            this.paymentRecord?.documents?.some((document) => document.documentName === docName && document.count > 0)
          );
    },
    documentsAreSaved() {
      return (this.bankDirectClients && false)
        ? true
        : !this.paymentRecord?.changed_fields?.some((field) => ['documents'].includes(field.name));
    },

    signatoriesAreComplete() {
      switch (this.paymentRecord?.signatoryOption) {
        case 'submittingUser':
          return true;
        case 'client':
        case 'intermediary':
        case 'intermediaryAndClient':
          if (Array.isArray(this.paymentRecord?.signatories)) {
            const signerSignatories = [];
            for (const selectedSignatory of this.paymentRecord?.signatories || []) {
              if (this.signatory[selectedSignatory]?.signerLevel === 'signer') {
                signerSignatories.push(selectedSignatory);
              }
            }
            return signerSignatories.length > 0;
          }
          return false;
        default:
          return false;
      }
    },
    signatoriesAreSaved() {
      return !this.paymentRecord?.changed_fields?.some((field) =>
        ['signatoryOption', 'signatories'].includes(field.name)
      );
    },
    transactionFileAwaitingPayment() {
      return this.paymentRecord?.transactionFileAwaitingPayment;
    },
  },

  watch: {
    activeStepName(newValue, oldValue) {
      if (newValue === 'bopCategories') {
        this.initialiseBopCats();
      } else if (newValue === 'signatories') {
        this.initialisePaymentSignatoryOption();
      }
    },
    destinationAccountId(newValue, oldValue) {
      if (newValue) {
        this.paymentRecord.detailsOfCharge = this.destinationAccountRecord?.detailsOfCharge?.toUpperCase();
        this.paymentRecord.beneficiaryReference = this.destinationAccountRecord?.beneficyaryReference;
        this.paymentRecord.specialInstructions = this.destinationAccountRecord?.specialInstructions;
        if (this.showCryptoAndSdaFields) {
          this.paymentRecord.isCrypto = !!this.destinationAccountRecord?.isCrypto;
        }
      }
    },
    beneficiaryId(newValue, oldValue) {},
    numberOfBopCats(newValue, oldValue) {
      // if (!oldValue && !this.actualNumberOfBopCats) {
      //   this.paymentRecord.bopCategories = [{ bopCat: '', foreignAmount: this.balanceOfForeignAmount }];
      // } else

      if (newValue < this.actualNumberOfBopCats) {
        let difference = this.actualNumberOfBopCats - newValue;
        this.paymentRecord.bopCategories.splice(newValue, difference);
        this.paymentRecord.bopCategories[newValue - 1].foreignAmount = this.balanceOfForeignAmount;
      } else if (newValue > this.actualNumberOfBopCats) {
        let difference = newValue - this.actualNumberOfBopCats;
        for (let i = 0; i < difference; i++) {
          this.paymentRecord.bopCategories.push({ bopCat: '', foreignAmount: 0 });
        }
        this.paymentRecord.bopCategories[newValue - 1].foreignAmount = this.balanceOfForeignAmount;
      }
    },
    sumOfAdjustableForeignAmounts(newValue, oldValue) {
      if (Array.isArray(this.paymentRecord?.bopCategories)) {
        if (this.paymentRecord?.bopCategories[this.numberOfBopCats - 1]) {
          this.paymentRecord.bopCategories[this.numberOfBopCats - 1].foreignAmount = this.balanceOfForeignAmount;
        }
      }
    },
    accountSingatoryOption(newValue, oldValue) {
      this.updatePaymentSignatoryOption();
    },
    paymentSignatory_optionStatus(newValue, oldValue) {
      let newOption = newValue.split('-')[0];
      let newStatus = newValue.split('-')[1];
      let oldOption = oldValue.split('-')[0];
      let oldStatus = oldValue.split('-')[1];
      if (newOption === oldOption && newStatus === 'retrieved') {
        this.populateSignatoryPool();
      }
    },
    recordDocuments(newValue, oldValue) {
      this.ensureDocumentsIsArray();
    },
  },

  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Stepper -----

    getInitialActiveStep() {
      // if (this.paymentRecord.isSubmittable) {
      //   return this.findStepIndex('review');
      // } else {
      //   return 1;
      // }
      if (this.inwardSwiftStepIsError) {
        return this.findStepIndex('inwardSwift');
      } else if (this.paymentStepIsError) {
        return this.findStepIndex('payment');
      } else if (this.bopCategoryStepIsError) {
        return this.findStepIndex('bopCategories');
      } else if (this.bopInfoStepIsError) {
        return this.findStepIndex('bopInfo');
      } else if (this.documentsStepIsError) {
        return this.findStepIndex('documents');
      } else if (this.signatoryStepIsError && this.signatoryStepGuard) {
        return this.findStepIndex('signatories');
      } else {
        return this.findStepIndex('review');
      }
    },

    findStepIndex(nameOfStep) {
      return this.steps.findIndex((step) => step.stepName === nameOfStep) + 1;
    },

    onBackClick() {
      let newStepNumber = this.activeStepNumber - 1;
      let evt = { detail: { stepNumber: newStepNumber } };
      this.onStepClick(evt);
    },
    onNextClick() {
      let newStepNumber = this.activeStepNumber + 1;
      let evt = { detail: { stepNumber: newStepNumber } };
      this.onStepClick(evt);
    },
    onStepClick(evt) {
      this.activeStepNumber = evt.detail.stepNumber;
    },

    // ----- Fields -----

    overrideOptions(fieldName) {
      switch (fieldName) {
        case 'inwardSwiftId':
          return this.inwardSwiftOptions;
        case 'destinationAccount':
          // const filteredAccounts = this.filteredDestinationAccounts;
          return this.filteredDestinationAccounts;
        default:
          return undefined;
      }
    },
    overrideOptionsSearchBarNumber(fieldName) {
      switch (fieldName) {
        case 'destinationAccount':
          return 6;
        default:
          return false;
      }
    },

    on_change({ field, value }) {
      this.paymentRecord[field] = value;
      if (field === 'signatoryOption') {
        this.populateSignatoryPool();
      }
    },
    on_change_bopCatGroup(info, evt) {
      this.paymentRecord.bopCategories[info.index - 1].bopCatGroupId = evt.value;
      this.paymentRecord.bopCategories[info.index - 1].bopCat = '';
    },
    on_change_bopCat(info, evt) {
      this.paymentRecord.bopCategories[info.index - 1].bopCat = evt.value;
    },
    on_change_bopAlloanceIndicator(info, evt) {
      this.paymentRecord.bopCategories[info.index - 1].allowanceIndicators = evt.value;
      if (evt.value !== 'FIA') {
        this.paymentRecord.bopCategories[info.index - 1].fiaPin = '';
      }
    },
    on_change_bopFiaPin(info, evt) {
      this.paymentRecord.bopCategories[info.index - 1].fiaPin = evt.value;
    },
    on_change_foreignAmount(info, evt) {
      this.paymentRecord.bopCategories[info.index - 1].foreignAmount = evt.value;
    },
    on_change_docCount(fieldName, evt) {
      let index = this.recordDocuments.findIndex((doc) => doc.documentName === fieldName);
      if (index > -1) {
        this.paymentRecord.documents[index].count = +evt.value;
      }
    },
    on_change_previous_portfolio({ field, value }) {
      this.reusePortfolioRecord[field] = value;
    },

    overrideField(field) {
      if (
        [
          'inwardSwiftId',
          'paymentAmount',
          'sourceAccount',
          'destinationAccount',
          'detailsOfCharge',
          'numberOfBopCats',
          'signatoryOption',
        ].includes(field.name)
      ) {
        const startingField = merge({}, field);
        const overrideObject = this.getFieldOverRideObject(field);
        const newField = merge(startingField, overrideObject);
        return newField;
      } else {
        return field;
      }
    },
    getFieldOverRideObject(field) {
      switch (field.name) {
        case 'inwardSwiftId':
          // switch (this.isInwardSwiftAssignedToPayment) {
          switch (!!this.inwardSwiftId) {
            case true:
              // return { enableGuards: false };
              return { view: 'item' };
            case false:
              return { enableGuards: true };
          }

        case 'paymentAmount':
          return {
            datatype: { currency: { symbol: this.paymentRecord?.paymentCurrency || '' } },
            enableGuards: false,
            view: 'item',
          };

        case 'sourceAccount':
          switch (this.transactionType) {
            case 'inwardDeal':
              return {}; // TODO: remove this - same as default
            case 'outwardDeal':
            case 'transferDeal':
              return {
                enableGuards: false,
                view: 'item',
              };
            case 'outwardPayment':
              return { view: 'item' };
            default:
              return {};
          }

        case 'destinationAccount':
          switch (this.transactionType) {
            case 'transferDeal':
              return { view: 'item' };
            case 'outwardDeal':
            case 'inwardDeal':
            case 'outwardPayment':
              if (this.permissions?.createBeneficiary) {
                let self = this;
                return {
                  button: {
                    action: 'click',
                    icon: 'material/add_circle',
                    views: { edit: true, item: false },
                    clickFunction() {
                      self.$router.push({ name: 'addBeneficiaryDetails' });
                    },
                  },
                };
              } else {
                return {};
              }
            case 'inwardPayment':
              return {};
            default:
              return {};
          }

        case 'detailsOfCharge':
          return {
            displayType: {
              datatype: 'option',
              display: 'radio',
            },
          };

        case 'numberOfBopCats':
          return {
            datatype: {
              option: {
                optionType: 'text',
                options: [
                  { id: 1, name: '1' },
                  { id: 2, name: '2' },
                  { id: 3, name: '3' },
                  { id: 4, name: '4' },
                  { id: 5, name: '5' },
                ],
              },
            },
            // displayType: {
            //   datatype: 'option',
            //   display: 'radio',
            // },  // TODO: If radio buttons can be horizontal, otherwise leave as dropdown
          };

        case 'signatoryOption':
          return {
            enableGuards: !!this.permissions?.createSignatory,
            view: !!this.permissions?.createSignatory ? 'edit' : 'item',
          };
        default:
          return {};
      }
    },
    getGroupId(bopCategoryObject) {
      if (bopCategoryObject.bopCatGroupId) {
        return bopCategoryObject.bopCatGroupId;
      }
      if (bopCategoryObject.bopCat) {
        return this.$store?.getters?.config?.bopCat?.[bopCategoryObject.bopCat]?.groupId;
      }
      return undefined;
    },
    bopCatGroupField(bopCatIndex) {
      let field = {
        name: `${bopCatIndex}-bopCatGroup`,
        label: 'BOP Category Group',
        datatype: {
          foreignKey: {
            collection: 'fk_bop_cat_groups',

            linkTable: 'LfxBopCatGroup',
            linkField: 'id',
            displayField: 'name',
          },
        },
      };
      return field;
    },
    bopCatField(bopCatIndex) {
      let field = {
        name: `${bopCatIndex}-bopCat`,
        label: 'BOP Category',
        datatype: {
          foreignKey: {
            collection: 'fk_bopCat',

            linkTable: 'LfxBopCat',
            linkField: 'id',
            displayField: 'name',
          },
        },
      };
      return field;
    },

    foreignAmountField(bopCatIndex) {
      let field = {
        name: `${bopCatIndex}-foreignAmount`,
        label: 'Foreign Amount',
        datatype: { currency: { symbol: this.paymentRecord?.foreignCurrency } },
        enableGuards: +bopCatIndex === this.numberOfBopCats ? false : true,
      };
      return field;
    },
    allowanceIndicatorField(bopCatIndex) {
      const options = [];
      const allowanceIndicatorConfig = this.$store.getters.config.bopCat[this.paymentRecord?.bopCategories?.[bopCatIndex - 1]?.bopCat]?.allowanceIndicators;
      if (['none','sdaOnly','either'].includes(allowanceIndicatorConfig)) {
        options.push({ id: 'none', name: 'None' },)
      }
      options.push({ id: 'SDA', name: 'SDA' })
      if (['either','eitherRequired'].includes(allowanceIndicatorConfig)) {
        options.push({ id: 'FIA', name: 'FIA' });
      }
      let field = {
        name: `${bopCatIndex}-allowanceIndicators`,
        label: 'Allowance Indicator',
        datatype: {
          option: {
            optionType: 'text',
            options: options,
          },
        },
        enableGuards: true,
      };
      return field;
    },
    fiaPinField(bopCatIndex) {
      let field = {
        name: `${bopCatIndex}-fiaPin`,
        label: 'FIA PIN',
        datatype: 'text',
        enableGuards: true,
      };
      return field;
    },
    displayAllowanceIndicatorField(bopCatIndex) {
      if (!this.showCryptoAndSdaFields) {
        return false;
      }
      if (!this.paymentRecord?.bopCategories?.[bopCatIndex - 1]?.bopCat) {
        return false;
      }
      return ['sdaOnly', 'either','sdaOnlyRequired','eitherRequired'].includes(
        this.$store.getters.config.bopCat[this.paymentRecord?.bopCategories?.[bopCatIndex - 1]?.bopCat]
          ?.allowanceIndicators
      );
    },
    displayFiaPinField(bopCatIndex) {
      if (!this.displayAllowanceIndicatorField(bopCatIndex)) {
        return false;
      }
      return this.paymentRecord?.bopCategories?.[bopCatIndex - 1]?.allowanceIndicators === 'FIA';
    },

    // ----- BOP Cat Options -----

    initialiseBopCats() {
      if (
        this.paymentRecord.isCrypto &&
        (!this.paymentRecord.bopCategories || this.paymentRecord.bopCategories.length === 0)
      ) {
        // {bopCat: 'Out-513', foreignAmount: 123100, bopCatGroupId: 32}
        let firstCryptoCategory;
        for (const cryptoBopCatId in this.cryptoBopCats) {
          firstCryptoCategory = this.cryptoBopCats[cryptoBopCatId];
          break;
        }
        if (firstCryptoCategory) {
          this.paymentRecord.numberOfBopCats = 1;
          this.paymentRecord.bopCategories = [
            {
              bopCatGroupId: firstCryptoCategory.groupId,
              bopCat: firstCryptoCategory.id,
              foreignAmount: this.balanceOfForeignAmount,
            },
          ];
        }
      }
      if (!this.numberOfBopCats && !this.actualNumberOfBopCats) {
        this.paymentRecord.numberOfBopCats = 1;
        this.paymentRecord.bopCategories = [{ bopCat: '', foreignAmount: this.balanceOfForeignAmount }];
      } else if (!this.numberOfBopCats && this.actualNumberOfBopCats) {
        this.paymentRecord.numberOfBopCats = this.actualNumberOfBopCats;
      }
    },

    ensureOneBopCat() {
      if (!this.actualNumberOfBopCats) {
        this.paymentRecord.numberOfBopCats = 1;
        this.paymentRecord.bopCategories = [{ bopCat: '', foreignAmount: this.balanceOfForeignAmount }];
      } else {
        this.paymentRecord.numberOfBopCats = this.actualNumberOfBopCats;
      }
    },

    ensureDocumentsIsArray() {
      if (!Array.isArray(this.paymentRecord?.documents)) {
        this.paymentRecord.documents = [];
      }
    },

    bopCatOptions(bopCatIndex) {
      //HERE
      // let bopCatGroupId = this.paymentRecord.bopCategories[bopCatIndex - 1].bopCatGroupId;
      const bopCatGroupId = this.getGroupId(this.paymentRecord.bopCategories[bopCatIndex - 1]);
      return bopCatGroupId ? this.filterBopCatsByBopCatGroupId(bopCatGroupId) : {};
    },

    filterBopCatsByBopCatGroupId(bopCatGroupId) {
      let options = {};
      let bopCats = this.intermediateBopCatOptions;
      for (let bc in bopCats) {
        let bopCat = bopCats[bc];
        if ((bopCatGroupId === 'hotList' && bopCat.hotList) || bopCat.groupId === +bopCatGroupId) {
          options[bc] = bopCat;
        }
      }
      return options;
    },

    // ----- Document field -----

    documentField(fieldName) {
      let field = {
        name: `${fieldName}`,
        label: `${fieldName} - Number of Documents`,
        datatype: 'text',
      };
      return field;
    },

    getDocument_fieldname(fieldName) {
      let index = this.recordDocuments.findIndex((doc) => doc.documentName === fieldName);
      return `${this.paymentRecord.documents[index].count}`;
    },

    // ----- Signatories

    initialisePaymentSignatoryOption() {
      if (!this.paymentRecord?.signatoryOption) {
        if (this.accountRecord?.bopFormSignatoryOption) {
          this.updatePaymentSignatoryOption();
        } else {
          void this.loadAccountRecord();
        }
      } else {
        this.populateSignatoryPool();
      }
    },
    async loadAccountRecord() {
      const response = await doPermissionAction(this, 'getAccount', { accountId: this.accountId }, {});
      if (this.accountRecord?.bopFormSignatoryOption !== response?.response?.bopFormSignatoryOption) {
        this.accountRecord.bopFormSignatoryOption = response.response.bopFormSignatoryOption;
      }
    },
    updatePaymentSignatoryOption() {
      this.paymentRecord.signatoryOption = this.accountRecord.bopFormSignatoryOption;
      this.populateSignatoryPool();
    },

    populateSignatoryPool() {
      // Note: poke the signatoryOptions to make sure they're up to date
      void this.signatoryOptions;

      // Note: only try to update the singatoryPool if the current paymentRecord.signatoryOption isn't the same
      // as used to populate the signatories in this component and an option has been set on the payment
      if (this.paymentRecord?.signatoryOption && this.signatoryOptionForPool !== this.paymentRecord?.signatoryOption) {
        if (this.paymentRecord?.signatoryOption === 'submittingUser') {
          // Note: set the signatoryPool to emptyObject unless it is already emptyObject
          if (Object.keys(this.signatoryPool)?.length > 0) {
            this.signatoryPool = {};
          }
          this.signatoryOptionForPool = this.paymentRecord?.signatoryOption;
          this.populateSignatoryPoolOrder();
        } else if (this.signatories_retrievalStatus === 'retrieved') {
          // Note: loop through signatoryPool and remove record if not included in signatoryOptions
          for (let signatoryId in this.signatoryPool) {
            // if (!Object.keys(this.signatoryOptions).includes(signatoryId)) {
            if (!this.signatoryOptions[signatoryId]) {
              delete this.signatoryPool[signatoryId];
            }
          }

          // Note: loop through signatoryOptions and add record to signatoryPool if not already included
          for (let signatoryId in this.signatoryOptions) {
            // if (!Object.keys(this.signatoryPool).includes(signatoryId)) {
            if (!this.signatoryPool[signatoryId]) {
              this.signatoryPool[signatoryId] = { ...this.signatoryOptions?.[signatoryId] };
            }
          }
          this.signatoryOptionForPool = this.paymentRecord?.signatoryOption;
          this.populateSignatoryPoolOrder();
        }
      } else {
      }
    },

    populateSignatoryPoolOrder() {
      // only populate signatoryPoolOrder if paymentRecord.signatoryOption && signatoryOptionForPoolOrder !== paymentRecord.signatoryOption
      //
      //  set signatoryPoolOrder to [] if not already []
      //
      //  if paymentRecord.signatoryOption !== submittingUser
      //
      //     if signatories exist on payment record -> loop through saved signatories and add them to signatoryPoolOrder if included in signatoryOptions
      //     then loop through signatoryOptions and add them to signatoryPoolOrder if not included already
      //
      //     else if signatoryOrder exists on account record -> loop through saved signatories and add them to signatoryPoolOrder if included in signatoryOptions
      //     then loop through signatoryOptions and add them to signatoryPoolOrder if not included already
      //
      //     else loop through signatoryOptions and add them to signatoryPoolOrder
      //
      //  set signatoryOptionForPoolOrder !== paymentRecord.signatoryOption
      //  update paymentRecord.signatories

      if (
        this.paymentRecord?.signatoryOption &&
        this.signatoryOptionForPoolOrder !== this.paymentRecord.signatoryOption
      ) {
        if (!Array.isArray(this.signatoryPoolOrder) || this.signatoryPoolOrder?.length > 0) {
          this.signatoryPoolOrder = [];
        }
        if (this.paymentRecord?.signatoryOption !== 'submittingUser') {
          if (Array.isArray(this.paymentRecord?.signatories) && this.paymentRecord?.signatories?.length > 0) {
            for (let signatoryId of this.paymentRecord?.signatories) {
              // if (Object.keys(this.signatoryPool).includes(signatoryId)) { // Warning for others - Object.keys turns the keys into string, and '1' !== 1 --- avr
              if (this.signatoryPool[signatoryId]) {
                this.signatoryPoolOrder.push(+signatoryId);
                this.signatoryPool[signatoryId].checked = true;
              }
            }
            for (let signatoryId in this.signatoryPool) {
              if (!this.signatoryPoolOrder.includes(+signatoryId)) {
                this.signatoryPoolOrder.push(+signatoryId);
                this.signatoryPool[signatoryId].checked = false;
              }
            }
          } else if (
            Array.isArray(this.accountRecord?.signatoryOrder) &&
            this.accountRecord?.signatoryOrder?.length > 0
          ) {
            for (let signatoryId of this.accountRecord?.signatoryOrder) {
              // if (Object.keys(this.signatoryPool).includes(signatoryId)) {
              if (this.signatoryPool[signatoryId]) {
                this.signatoryPoolOrder.push(+signatoryId);
              }
            }
            for (let signatoryId in this.signatoryPool) {
              if (!this.signatoryPoolOrder.includes(+signatoryId)) {
                this.signatoryPoolOrder.push(+signatoryId);
              }
            }
          } else {
            for (let signatoryId in this.signatoryPool) {
              this.signatoryPoolOrder.push(+signatoryId);
            }
          }
        }
        this.signatoryOptionForPoolOrder = this.paymentRecord.signatoryOption;
        this.populatePaymentSignatories();
      } else {
      }
    },

    onSignatoryOrderChange(newOrder) {
      this.signatoryPoolOrder = newOrder;
      this.populatePaymentSignatories();
    },
    onRecordCheck(payload) {
      let signatoryId = payload.record.id;
      this.signatoryPool[signatoryId].checked = payload.evt.detail.new;
      this.populatePaymentSignatories();
    },

    populatePaymentSignatories() {
      // // Note: If paymentRecord signatories is not an Array, or if the Array has signatories saved in it, set the Array to empty -
      //         // signatories are not saved for submittingUser until the payment is submitted, but make sure it is an Array,
      //         // but don't override an empty array to an empty array or it will show up as a changed_field and require saving again.
      //         if (!Array.isArray(this.paymentRecord?.signatories) || this.paymentRecord?.signatories?.length > 0) {
      //           this.paymentRecord.signatories = [];
      //         }

      // this.paymentRecord.signatories = [];
      let newPaymentSignatories = [];
      for (let signatoryId of this.signatoryPoolOrder) {
        let signatory = this.signatoryPool?.[signatoryId];
        if (signatory.signerRequired === 'mandatory' || signatory.checked === true) {
          // this.paymentRecord.signatories.push(signatoryId);
          newPaymentSignatories.push(signatoryId);
        }
      }

      if (
        !Array.isArray(this.paymentRecord?.signatories) ||
        this.paymentRecord?.signatories?.length !== newPaymentSignatories.length
      ) {
        this.paymentRecord.signatories = newPaymentSignatories;
      } else if (!this.checkArraysHaveSameElements(this.paymentRecord?.signatories, newPaymentSignatories)) {
        this.paymentRecord.signatories = newPaymentSignatories;
      } else {
      }
    },

    checkArraysHaveSameElements(arr1, arr2) {
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          return false;
        }
      }
      return true;
    },

    // ----- Review -----

    headingForRecordName(recordName) {
      switch (recordName) {
        case 'paymentRecord':
          return 'Payment Summary';
        case 'beneficiaryRecord':
          return 'Beneficiary Summary';
        case 'destinationBankAccountRecord':
          return 'Destination Bank Account Summary';
      }
    },

    recordFieldNames(recordName) {
      let actualFieldNames = [];
      let potentialFieldNames = [];
      switch (recordName) {
        case 'paymentRecord':
          potentialFieldNames = [
            'paymentCurrency',
            'paymentAmount',
            'detailsOfCharge',
            'beneficiaryReference',
            'specialInstructions',
            'isCrypto',
          ];
          break;
        case 'beneficiaryRecord':
          potentialFieldNames = ['fullName', 'beneficiaryType', 'status'];
          break;
        case 'destinationBankAccountRecord':
          if (this.destinationAccountIsBeneficiaryAccount) {
            potentialFieldNames = [
              'description',
              'accountNumber',
              'bankName',
              'branch',
              'branchCode',
              'bankCountryId',
              'swiftCode',
              'correspondentCode',
            ];
          } else {
            potentialFieldNames = ['description', 'id'];
          }
          break;
      }
      for (let potentialFieldName of potentialFieldNames) {
        let field = this.fieldByRecordAndFieldName(recordName, potentialFieldName);
        if (field) {
          actualFieldNames.push(potentialFieldName);
        }
      }
      return actualFieldNames;
    },

    fieldByRecordAndFieldName(recordName, recordFieldName) {
      let recordFields = this[recordName]?.guarded_fields || [];
      const fields = recordFields.filter((field) => field.field === recordFieldName);
      if (fields.length > 0) {
        // const updatedField = this.overrideField(fields[0]);
        const field = fields[0];
        return field;
      }
      return undefined;
    },

    definitionByRecordName(recordName) {
      switch (recordName) {
        case 'paymentRecord':
          return getDefinition('payment');
        case 'beneficiaryRecord':
          return getDefinition('beneficiary');
        case 'destinationBankAccountRecord':
          return getDefinition('bankAccount');
      }
    },

    recordByRecordName(recordName) {
      return this[recordName];
    },
    testIncludeDocument(documentConfig) {
      if (!documentConfig.documentName || ! documentConfig.guardDescription) {
        return false
      }
      switch (documentConfig.guardDescription) {
        case 'fiaSelected': return this.paymentRecord.fiaSelectedOnABopCat;
        case 'fiaNotSelected': return !this.paymentRecord.fiaSelectedOnABopCat;
      }
      return true
    }
  },
};
</script>

<style scoped>
p.error {
  color: red;
}

div.flex-container {
  border: 1px solid rgb(227, 227, 227);
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  background-color: rgb(242, 241, 241);
}
div.nested-flex-container {
  /* border: 1px dotted lightgreen; */
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

div.flex-item {
  /* border: 1px dotted blue; */
  margin: 5px;
  padding: 0px 10px 10px 10px;
  flex-grow: 1;
  flex-basis: 2000px;
  background-color: white;
}
div.flex-item-amount {
  /* border: 1px dotted blue; */
  margin: 5px;
  padding: 0px 10px 10px 10px;
  flex-grow: 1;
  flex-basis: 2000px;
  background-color: white;
}
div.flex-item-number {
  border: 1px solid rgb(227, 227, 227);
  /* border: 1px dotted blue; */
  border-radius: 2px;
  font-weight: 700;
  margin: 5px;
  padding: 5px 10px;
  flex-grow: auto;
  flex-basis: initial;
  background-color: white;
}
.lfx-payment-configure-signatory-table-container {
  position: relative;
  min-height: 200px;
}

#lfx-payment-configure-signatory-table {
  min-width: 100%;
}
</style>
