<template>
  <capitec-chip :class="`${selected ? 'selected' : 'not-selected'} option-chip`" :label="label" @click.stop="onClick" />
</template>


<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

export default {
  components: {
    
  },
  props: {  
    id:{type:String,required:true},
    group:{type:String,required:true},
    label:{type:String,required:true},
    selector:{type:Object,required:true},
    selected:{type:Boolean,required:true},
  },
  data() {
    return {
      
    }
  },
  created() {
    
  },
  mounted() {
    
  },
  computed:{
    ...mapGetters([]),
  	...mapState([]),
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([]),
    onClick() {
      this.$emit('on-select',{id:this.id,group:this.group,selector:this.selector});
    }
  }
 }

</script>

<style>

capitec-chip.option-chip.selected{
  /* border: 3px solid red; */
}

</style>


