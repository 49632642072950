<template>
  <LDBModal type="result" result="info" :header="header">
    <template #body>
      <div>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition=undefined
          :record="dataObject"
          @change="on_change"
        />
      </div>
    </template>
    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
        @on-api-success="onApiSuccess"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxImportBatchUpdateStatus',
  components: { LDBField },
  emits: ['on-api-success'],
  props: {},
  data() {
    return {
      dataObject: {

      },
    };
  },
  created() {
  },
  mounted() {
  },
  beforeUnmount() {
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['payment']),
    header() {
      return `Update Import Batch Status`;
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

        {
          name: 'updateStatus',
          label: 'Update',
          type: 'primary',
          action: 'updateImpotBatchStatus',
          params: { dataImportId: this.importBatchId },
          actionParams: this.dataObject,
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
      ];
      return allButtons !== undefined ? allButtons : [];
    },
    // ----- IDs -----
    importBatchId() {
      return +this.$route.params.importBatchId;
    },
    importBatchRecord() {
      return this.importBatch[this.importBatchId]
    },

    // ----- Fields -----

    viewFields() {
      const fields = [
         {
          name: 'status',
          label: 'New Status',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'new', name: 'New' },
                { id: 'inProgress', name: 'In Progress' },
                { id: 'completed', name: 'Completed' },
                { id: 'error', name: 'Error' },
                { id: 'cancelled', name: 'Cancelled' },
              ],
            },
          }
         },
      ]
      return fields
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Fields -----
    on_change({ field, value }) {
      this.dataObject[field] = value;
    },

    // ----- API calls -----
    async onApiSuccess(_responseObject) {},
  },
};
</script>


<style>
</style>
