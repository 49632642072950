import { RouteRecordRaw } from 'vue-router';

import LfxTodo from '@/components/todo/LfxTodo.vue';

import LfxReports from '@/views/reports/LfxReports.vue';
import LfxDealSummaryReport from '@/views/reports/LfxDealSummaryReport.vue';
import LfxDealValueDateChangeReport from '@/views/reports/LfxDealValueDateChangeReport.vue';
import LfxInvoiceReport from '@/views/reports/LfxInvoiceReport.vue';
import LfxAdvancedPaymentReport from '@/views/reports/LfxAdvancedPaymentReport.vue';
import LfxPaymentProcessingReport from '@/views/reports/LfxPaymentProcessingReport.vue';
import LfxForwardRevalReport from '@/views/reports/LfxForwardRevalReport.vue';
import LfxBopPortfolioReport from '@/views/reports/LfxBopPortfolioReport.vue';
import LfxUserAccessReport from '@/views/reports/LfxUserAccessReport.vue';
import LfxUserAccessReportReviewConfirmation from '@/views/reports/LfxUserAccessReportReviewConfirmation.vue';
import LfxUserReviewReport from '@/views/reports/LfxUserReviewReport.vue';
import LfxIntermediaryMarkupReport from '@/views/reports/LfxIntermediaryMarkupReport.vue'
import LdbEmailSearch from '@/views/reports/LdbEmailSearch.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/reports',
    name: 'reports',
    meta: { icon: 'system/notes' },
    component: LfxReports,
    children: [
      {
        path: 'deal_summary',
        name: 'reportsDealSummary',
        component: LfxDealSummaryReport,
        meta: { label: 'Deal Summary', content: { tabName: 'reportsDealSummary' } },
      },
      {
        path: 'rolledDealReport',
        name: 'rolledDealReport',
        component: LfxDealValueDateChangeReport,
        meta: { label: 'Rolled Deals', content: { tabName: 'rolledDealReport' } },
      },
      {
        path: 'invoice_report',
        name: 'reportsInvoiceReport',
        component: LfxInvoiceReport,
        meta: { label: 'Invoice Report', content: { tabName: 'reportsInvoiceReport' } },
      },
      {
        path: 'invoice_number_report',
        name: 'reportsInvoiceNumberReport',
        component: LfxTodo,
        meta: { label: 'Invoice Number Report', content: { tabName: 'reportsInvoiceNumberReport' } },
      },
      {
        path: 'forward_reval_report',
        name: 'reportsForwardRevalReport',
        component: LfxForwardRevalReport,
        meta: { label: 'Forward Reval Report', content: { tabName: 'reportsForwardRevalReport' } },
      },
      {
        path: 'payment_processing',
        name: 'reportsPaymentProcessing',
        component: LfxPaymentProcessingReport,
        meta: { label: 'Payment Processing', content: { tabName: 'reportsPaymentProcessing' } },
      },
      {
        path: 'advanced_payments',
        name: 'reportsAdvancedPayments',
        component: LfxAdvancedPaymentReport,
        meta: { label: 'Advanced Payments', content: { tabName: 'reportsAdvancedPayments' } },
      },
      {
        path: 'bop_portfolios',
        name: 'reportsBOPPortfolios',
        component: LfxBopPortfolioReport,
        meta: { label: 'BOP Portfolios', content: { tabName: 'reportsBOPPortfolios' } },
      },
      {
        path: 'user_access',
        name: 'reportsUserAccess',
        component: LfxUserAccessReport,
        meta: { label: 'User Access', content: { tabName: 'reportsUserAccess' } },
        children: [
          {
            name: 'userReviewConfirmView',
            path: 'userReviewConfirmView',
            component: LfxUserAccessReportReviewConfirmation,
          },
        ],
      },
      {
        path: 'user_review',
        name: 'reportsUserReview',
        component: LfxUserReviewReport,
        meta: { label: 'User Review Report', content: { tabName: 'reportsUserReview' } },
      },
      {
        path: 'emailSearch',
        name: 'generalEmailSearch',
        component: LdbEmailSearch,
        meta: { label: 'Email Search', content: { tabName: 'generalEmailSearch' } },
      },
      {
        path: 'intermediaryMarkupReport',
        name: 'intermediaryMarkupReport',
        component: LfxIntermediaryMarkupReport,
        meta: { label: 'Intermediary Markup Report', content: { tabName: 'intermediaryMarkupReport' } },
      },
      {
        path: 'user_activity_deals',
        name: 'reportsUserActivityDeals',
        component: LfxTodo,
        meta: { label: 'User Activity - Deals', content: { tabName: 'reportsUserActivityDeals' } },
      },
      {
        path: 'other',
        name: 'reportsOther',
        component: LfxTodo,
        meta: { label: 'Other', content: { tabName: 'reportsOther' } },
      },
    ],
  },
];

export default routes;
