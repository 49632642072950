//@ts-nocheck
import VueStore from 'vue-class-store';

import { LfxApplicationDocumentInt,LfxApplicationDocumentDef } from '@/definitions/LfxApplicationDocumentDef';

import { LfxModel,LfxDefinition } from './LfxModel';

@VueStore

class LfxApplicationDocumentMdl extends LfxDefinition {
  constructor(def,dbclass,context,name,config) {
    super(def,dbclass,context,name,config)
  }
}

@VueStore

export class LfxApplicationDocument extends LfxModel {
  constructor(data,state) {
    super({definition_name:'applicationDocument',...data},state);
  }
}

export function Definition(context,name:string) {
  return new LfxApplicationDocumentMdl(LfxApplicationDocumentDef,LfxApplicationDocument,context,name,{});
}

