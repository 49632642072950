<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <template v-if="['none', 'retrieving'].includes(accountSettings_retrievalStatus)">
        <p>Loading...</p>
      </template>

      <template v-else-if="accountSettings_retrievalStatus === 'retrieved'">
        <p>Allow Client Traders to Book Deals for:</p>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          :view="field.view"
          :fieldname="field.name"
          :definition="undefined"
          :record="tradeSettings"
          @change="on_change"
        />
        <template v-if="!atLeastOneSelected">
          <p>You must select as least one option.</p>
          <p>If you don't want Client Users to trade, edit their Permissions instead.</p>
        </template>
      </template>

      <template v-else>
        <p>Failed to load Settings.</p>
      </template>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in buttons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { doPermissionAction } from '@/lfx_rest/lfx_action';
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'LfxAccountUsersSettings',
  components: {},
  props: {},
  emits: {},
  created() {
    void this.accountSettings;
    if (this.accountSettings_retrievalStatus === 'retrieved') {
      this.populateSettings();
    }
  },
  data() {
    return {
      defaultTradeSettingValue: true,
      tradeSettings: {
        Cash: true,
        Tom: true,
        Spot: true,
        FWD: true,
      },
    };
  },
  computed: {
    ...mapState(['account']),

    // ----- Header -----

    header() {
      return `Client User Settings`;
    },

    // ----- Account record -----

    accountId() {
      return +this.$route.params.accountId;
    },
    accountRecord() {
      return this.account[this.accountId];
    },

    accountSettings_retrievalStatus() {
      return this.accountRecord?.accountSettings_retrievalStatus;
    },
    accountSettings() {
      return this.accountRecord?.accountSettings;
    },

    savedTradeSettings() {
      return this.accountSettings?.generalSettings?.tradeSettings;
    },

    // ----- Fields -----

    viewFields() {
      const res = [
        {
          fieldname: 'Cash',
          name: 'Cash',
          label: 'Value Today',
          view: 'edit',
          datatype: 'boolean',
        },
        {
          fieldname: 'Tom',
          name: 'Tom',
          label: 'Value Tomorrow',
          view: 'edit',
          datatype: 'boolean',
        },
        {
          fieldname: 'Spot',
          name: 'Spot',
          label: 'Spot',
          view: 'edit',
          datatype: 'boolean',
        },
        {
          fieldname: 'FWD',
          name: 'FWD',
          label: 'Forward',
          view: 'edit',
          datatype: 'boolean',
        },
      ];
      return res;
    },
    buttons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelUnhideMarkup',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'updateMarkup',
          label: `Update Settings`,
          type: 'primary',
          guards: true,
          enabledGuards: this.atLeastOneSelected,
          action: 'updateAccountGeneralSettings',
          actionParams: { newSettings: { tradeSettings: this.tradeSettings } },
          params: { accountId: this.accountId },
          clickEvent(response) {
            if (response.status === 'success') {
              self.accountRecord.retrieveAccountStatus();
              self.$router.go(-1);
            }
          },
        },
      ];
      return allButtons.filter((button) => button.guards);
    },

    atLeastOneSelected() {
      for (const deliveryType in this.tradeSettings) {
        if (this.tradeSettings[deliveryType]) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    accountSettings_retrievalStatus(newValue, oldValue) {
      if (newValue === 'retrieved') {
        this.populateSettings();
      }
    },
  },
  methods: {
    populateSettings() {
      for (const deliveryType in this.tradeSettings) {
        this.tradeSettings[deliveryType] = this.savedTradeSettings?.[deliveryType] ?? this.defaultTradeSettingValue;
      }
    },

    on_change({ field, value }) {
      this.tradeSettings[field] = value;
    },
    // async submitMarkups() {
    //   this.accountRecord.addToSettings('generalSettings', { fecMarkups: this.fecMarkupsRecord });
    // },
  },
};
</script>
