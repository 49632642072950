<template>
  <LFXContent
    :titleBar="titleBar"
    :show_label="false"
    :show_actions="false"
    >
    <template #content>
      <LFXListView :definition="definition" :records="records" :search="searchConfig" />
    </template>
  </LFXContent>
</template>


<script>


// {
//   searchFields:['reference'],
//   choices:[
//     {
//       id:'status',
//       title:'Status',      
//       chips:[
//         {id:'all',label:'All',selector:{status:{in:['new','readyForSubmission']}},  default:true},
//         {id:'new',label:'New',selector:{status:{eq:'new'}}},
//         {id:'readyForSubmission',label:'Ready For Submission',selector:{status:{eq:'readyForSubmission'}}}
//       ]
//     }
//   ],
//   chips:[ {id:'integration-test',label:'Is Test',field:'integrationId',operator:'eq', value:'test',selected:false}]
// }

import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinitionByTable } from '@/db-interface';

import LFXContent from '@/components/layout/LfxContent.vue';
import LFXListView from '@/views/general/LFXListView.vue';

export default {
  name: 'LfxDashboardList',
  components: { LFXListView, LFXContent },
  props: {},
  data() {
    return {
    };
  },
  async created() {
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['authUser','account','deal','payment']),

    titleBar() {
      const titleBar = {
        icon: this.titleBarIcon,
        label: this.titleBarLabel,
        buttons: this.titleBarButtons,
      };
      return titleBar;
    },

    titleBarIcon() {
      return undefined;
    },
    titleBarLabel() {
      return this.$route.meta?.label;
    },
    titleBarButtons() {
      return []
    },
    baseRecords() {
      let baseRecords = {};
      switch (this.$route.meta.content.table) {
        case 'LfxAccount': baseRecords = this.account; break
        case 'LfxDeal': baseRecords = this.deal; break
        case 'LfxPayment': baseRecords = this.payment; break
        case 'LfxApplication': baseRecords = this.openApplications; break
      }
      return baseRecords
    },
    searchConfig() {
      let config = {};
      switch (this.$route.meta.content.type) {
         case 'clientsRequireAttention':
          config = this.searchConfigClientsRequireAttention;
         break;         
         case 'openDeals':
          config = this.searchConfigOpenDeals;
         break;
         case 'openPayments':
          config = this.searchConfigOpenPayments;
         break
         case 'sentBackPayments':
          config = this.searchConfigSentBackPayments;
         break;
         case 'unreleasedDeals':
          config = this.searchConfigUnreleasedDeals;
         break;
         case 'unreleasedFecs':
          config = this.searchConfigUnreleasedDeals;
         break;
         case 'unreleasedFecModifications':
          config = this.searchConfigUnreleasedDeals;
         break;
         case 'readyForDrawdownFec':
          config = this.searchConfigUnreleasedDeals;
         break;
         case 'paymentsRequireAttention':
          config = this.searchConfigPaymentsRequireAttention;
         break;
      }
      return config; 
    },
    searchConfigClientsRequireAttention() {
      if ( this.authUser.accountLevel === 'bank') {
        return {searchFields:['name']}
      }
      else 
      {
        return {
          searchFields:['name'],
          choices:[{
            id:'status',
            title:'Status',      
            chips:[
              {id:'all',label:'All',selector:{status:{in:['new','submitted','rejected']}},  default:true},
              {id:'new',label:'New',selector:{status:{eq:'new'}}},
              {id:'submitted',label:'Submitted',selector:{status:{eq:'submitted'}}},
              {id:'rejected',label:'Rejected',selector:{status:{eq:'rejected'}}}
            ]
          }]
        }
      }
    },
    searchConfigSentBackPayments() {
      if ( this.authUser.accountLevel === 'bank') {
        return {searchFields:['name']}
      }
      else 
      {
        return {
          searchFields:['name'],
          choices:[{
            id:'status',
            title:'Status',      
            chips:[
              {id:'all',label:'All',selector:{status:{in:['new','submitted','rejected']}},  default:true},
              {id:'new',label:'New',selector:{status:{eq:'new'}}},
              {id:'submitted',label:'Submitted',selector:{status:{eq:'submitted'}}},
              {id:'rejected',label:'Rejected',selector:{status:{eq:'rejected'}}}
            ]
          }]
        }
      }
    },
    searchConfigOpenDeals() {
      return {searchFields:['reference']}
    },
    searchConfigOpenPayments() {
      return {
        searchFields:['reference'],
        choices:[{
          id:'status',
          title:'Status',     
          chips:[
            {id:'all',label:'All',selector:{status:{in: ['submitted','signed','awaitingSettlement','settled','awaitingFunds','recallRequested']}},  default:true},
            {id:'submitted',label:'Submitted',selector:{status:{eq:'submitted'}}},
            {id:'signed',label:'Signed',selector:{status:{eq:'signed'}}},
            {id:'settled',label:'Settled',selector:{status:{eq:'settled'}}},
            {id:'awaitingFunds',label:'Awaiting Funds',selector:{status:{eq:'awaitingFunds'}}},
            {id:'awaitingSettlement',label:'Awaiting Settlement',selector:{status:{eq:'awaitingSettlement'}}},
            {id:'recallRequested',label:'Recall Requested',selector:{status:{eq:'recallRequested'}}},
          ]
        }
      ]
    }
    },
    searchConfigUnreleasedDeals() {
      return {
        searchFields:['reference'],
        choices:[{
          id:'status',
          title:'Status',     
          chips:[
            {id:'all',label:'All',selector:{},  default:true},
            {id:'requested',label:'Requested',selector:{status:{eq:'requested'}}},
            {id:'processed',label:'Processed',selector:{status:{eq:'processed'}}},
            {id:'booked',label:'Booked',selector:{status:{eq:'booked'}}}
          ]
        }
      ]
    }
    },
    searchConfigPaymentsRequireAttention() {
      if ( this.authUser.accountLevel === 'bank') {
        return {
          searchFields:['reference'],
          choices:[{
            id:'status',
            title:'Status',     
            chips:[
              {id:'all',label:'All',selector:{status:{in: ['signed','awaitingSettlement','settled','awaitingFunds']}},  default:true},
              {id:'signed',label:'Signed',selector:{status:{eq:'signed'}}},
              {id:'settled',label:'Settled',selector:{status:{eq:'settled'}}},
              {id:'awaitingFunds',label:'Awaiting Funds',selector:{status:{eq:'awaitingFunds'}}},
              {id:'awaitingSettlement',label:'Awaiting Settlement',selector:{status:{eq:'awaitingSettlement'}}},
            ]
          }
          ]
        }
      }
      else 
      {
        return {
          searchFields:['reference'],
          choices:[{
            id:'status',
            title:'Status',     
            chips:[
              {id:'all',label:'All',selector:{status:{in:['new','readyForSubmission','readyForSigning','informationQuery']}},  default:true},
              {id:'new',label:'New',selector:{status:{eq:'new'}}},
              {id:'readyForSubmission',label:'Ready For Submission',selector:{status:{eq:'readyForSubmission'}}},
              {id:'readyForSigning',label:'Ready For Signing',selector:{status:{eq:'readyForSigning'}}}
            ]
          }]
      }
     }
    },
    records() {
      const records = {};
      let statusses;
      switch (this.$route.meta.content.type) {
        case 'clientsRequireAttention':
          if ( this.authUser.accountLevel === 'bank') {
            statusses = ['submitted']
          } else {
            statusses = ['new','submitted','rejected']
          }
          for (const accountId in this.baseRecords) {
            if (statusses.includes(this.baseRecords[accountId].status) && this.baseRecords[accountId].accountLevel === 'client' && this.baseRecords[accountId].visibility === 'visible') {
              records[accountId] = this.baseRecords[accountId]
            }
          }
          break
        case 'openDeals': 
        case 'unreleasedDeals':
          statusses = ['released'];
          if (this.$route.meta.content.type === 'unreleasedDeals') {
            statusses = ['requested','processed','booked'];
          }
          for (const dealId in this.baseRecords) {
            if (statusses.includes(this.baseRecords[dealId].status)) {
              records[dealId] = this.baseRecords[dealId]
            }
          }
          break
        case 'unreleasedFecs':
        case 'unreleasedFecModifications':
          statusses = ['fecModificationRequested'];
          if (this.$route.meta.content.type === 'unreleasedFecs') {
            statusses = ['requested','processed','booked'];
          }
          for (const dealId in this.baseRecords) {
            if (statusses.includes(this.baseRecords[dealId].status) && this.baseRecords[dealId].deliveryType === 'FWD') {
              records[dealId] = this.baseRecords[dealId]
            }
          }
          break
        case 'readyForDrawdownFec':
        for (const dealId in this.baseRecords) {
            if (this.baseRecords[dealId].fecReadyForDrawdown) {
              records[dealId] = this.baseRecords[dealId]
            }
          }
          break
        case 'openPayments':
        case 'paymentsRequireAttention':
          statusses = ['submitted','signed','awaitingSettlement','settled','awaitingFunds','recallRequested'];
          if (this.$route.meta.content.type === 'paymentsRequireAttention') {
               if ( this.authUser.accountLevel === 'bank') {
                  statusses = ['signed','awaitingSettlement','settled','awaitingFunds']
                } else {
                  statusses = ['new','readyForSubmission','readyForSigning','informationQuery']
                }
          }
          for (const dealId in this.baseRecords) {
            if (statusses.includes(this.baseRecords[dealId].status)) {
              records[dealId] = this.baseRecords[dealId]
            }
          }
          break
        case 'sentBackPayments':
          statusses = ['readyForSubmission','informationQuery' ];
          for (const dealId in this.baseRecords) {
            const payment = this.baseRecords[dealId];
            if (statusses.includes(payment.status) && payment.submittedCount > 0) {
              records[dealId] = this.baseRecords[dealId]
            }
          }
          break
        case 'applicationRequireAttention':
          for (const applicationId in this.baseRecords) {
            records[applicationId] = this.baseRecords[applicationId]            
          }
          break
      }
      return records
    },
    definition() {     
      return getDefinitionByTable(this.$route.meta.content.table);
    },
    openApplications() {
      if (this.$route.meta.content.table !== 'LfxApplication') {
        return {}
      }
      const req = [];
      let statusses;
      if ( this.authUser.accountLevel === 'bank') {
        statusses = ['applicationSubmitted','applicationInProgress']
      } else {
        statusses = ['awaitingDetailsCapture','readyForPortfolioSubmission','portfolioSubmitted','portfolioInProgress','portfolioSentBack','readyForApplicationSubmission','applicationSubmitted','applicationInProgress','applicationSentBack']
      } 
      for (const accountId in this.account) {
        const record = this.account[accountId];
        if (statusses.includes(record.currentApplicationStatus) && record.accountLevel === 'client' && record.visibility === 'visible') {
          if ((record.application || record.applications) && record.currentClientApplicationId && record.application[record.currentClientApplicationId] && record.application[record.currentClientApplicationId].visibility !== 'hidden') {
            req.push(record.application[record.currentClientApplicationId])
          }
        }
      };
      return req.sort( (a,b) => (a.requestedAt > b.requestedAt ? 1 : -1) )

    }
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>

<style>
/* div.container {
} */
</style>
