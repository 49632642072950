
export const LDB_GRAPH_COLORS = {
  darkgreen:{color:'#006F3C','label-color':'#006F3C26'},
  blue:{     color:'#01BBF1','label-color':'#01BBF126'},
  green:{    color:'#71B62C','label-color':'#71B62C26'},
  orange:{   color:'#F63E02','label-color':'#F63E0226'},
  velvet:{   color:'#AA1580','label-color':'#AA158026'},
  purple:{   color:'#852CB3','label-color':'#852CB326'},
  red:{      color:'#F63E02','label-color':'#F63E0226'},
  yellow:{   color:'#FFC122','label-color':'#FFC12226'},
  grey:{     color:'#F5F5F5','label-color':'#7C7C7C'}
};

