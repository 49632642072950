<template>
  <div class="ldb-item-view-field container valueSelected completed">
   <label v-if="view !== 'list' " class="ldb-item-view-field__label label">
    {{ field.label }}:{{view}}
    </label>
   
      <template v-if="view === 'item' || view === 'list'">
         <ul :class="`ldb-item-view-field__value bullet ${view}`">
      <template v-for="item in value" :key="item">
      <li>
        <capitec-label :label="getLabel(item)" type="subtitle"> </capitec-label> 
      </li>
      </template>
      </ul>
      </template>
       <template v-if="view === 'edit' || view === 'create'">
         <template v-if="foreignRecordCount <= 3">
            <ul :class="`ldb-item-view-field__value bullet ${view}`">
           <template v-for="item in foreignRecords" :key="item.id">
            <li class="ldb-foreign-key-checklist">
            <!-- <capitec-label :label="item.id" type="subtitle"> </capitec-label>  -->
            <capitec-check :label="item[displayField]"></capitec-check>
            </li>
        </template> 
        </ul>
       </template>
       <template v-else>
         <capitec-select 
         :label="fieldLabel" 
         :items="foreignRecords" 
         :display-field="displayField"
         :value="mappedValue"
         id-field="id"
         select-mode="multi"
         @value-change="onChecklistChange"
         @updateValueCallback="updateValueCallback"
         hint="">
         </capitec-select>
       </template>
       
       </template>
      <!-- {{value}} -->
    
  </div>

  <!-- <div class="tst-xxx"> -->
  <!-- <div  v-if="view === 'edit' || view === 'create'" class="ldb-field-container__text"> -->
  <!-- <capitec-text-field  @value-change='on_change' @key-input="on_change" :label="field.label" :value="value" ></capitec-text-field> -->
  <!-- </div> -->
  <!-- <LDBItemViewField v-else-if="view === 'item'" :view="view" :field="field" :displayValue="value" :definition="definition" :record="record" /> -->
  <!-- </div> -->

</template>

<script>

import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import { ldb_field_mixin } from './ldb_field_mixin.js';

import LDBItemViewField from './LDBItemViewField.vue';

export default {
  mixins: [ldb_field_mixin],
  components: { LDBItemViewField },
  props: {},
  data() {
    return {
      internalValue:[],
      _value:[]
    };
  },
  created() {},
  mounted() {
  },
  watch:{
    value(newValue,oldValue) {
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    mappedValue() {
      let res = [];
      if (this.value !== undefined) {
        for (let v = 0 ; v < this.value.length ; v ++ ) {
          let val = this.value[v];
          for (let r = 0 ; r < this.foreignRecords.length ; r ++ ) {
            let rec = this.foreignRecords[r];
            if (rec.id === val) {
              res.push(rec);
              break;
            }
          }
        }
      }
      return res;
    },
    displayValue() {
      return this.value;
    },
    displayField() {
      return this.field.displayType.foreignKeyList.displayField;
    },
    foreignRecordCount() {
      return this.foreignRecords ? this.foreignRecords.length : 0;
    },
    foreignRecords() {
      let foreignListFunction = this.field.displayType.foreignKeyList.collection;
      let foreignRecords = this.record[foreignListFunction]; 
      return Object.values(foreignRecords).map(v => { let res = {}; res.id = v.id,res[this.displayField] = v[this.displayField]; return res});
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    onChecklistChange(change) { 
      let changeList = change.detail.new.map( o => o.id);
      this.$emit('change',changeList);
    },
    getLabel(item) {
      if (
        this.foreignRecords !== undefined &&
        this.foreignRecords[item] !== undefined &&
        this.foreignRecords[item][this.displayField] !== undefined
      ) { 
        return this.foreignRecords[item][this.displayField];
      } else {
        return item;
      }
    },
    updateValueCallback(data) {
    },
    on_change(evt) {
      this.$emit('change', evt.detail.new.map(v => v.id));
    },
  },
};
</script>
<style>
.ldb-item-view-field__value.bullet {
  /* border: 1px solid red; */
  padding-top: 10px;
}

.ldb-foreign-key-checklist{
  /* list-style:none; */
  /* padding:0px; */
  /* margin:0px; */
}

.ldb-item-view-field__value.bullet.edit,
.ldb-item-view-field__value.bullet.create{
  list-style-type: none;
  margin:0px;
  padding-left:24px;
}


</style>


