import { RouteRecordRaw } from 'vue-router';

import LfxSearchViews from '@/views/search/LfxSearchViews.vue';
import LfxDealSearch from '@/views/search/LfxDealSearch.vue';
import LfxPaymentSearch from '@/views/search/LfxPaymentSearch.vue';
import LfxSwiftSearch from '@/views/search/LfxSwiftSearch.vue';
import LfxClientSearch from '@/views/search/LfxClientSearch.vue';
import LfxClientAccountSearch from '@/views/search/LfxClientAccountSearch.vue';
import LfxUserSearch from '@/views/search/LfxUserSearch.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/search',
    name: 'search',
    meta: { icon: 'system/search' },
    component: LfxSearchViews,
    children: [
      { path: 'dealSearch', name: 'searchDeals', component: LfxDealSearch, meta: { label: 'Deal Search' } },
      { path: 'paymentSearch', name: 'searchPayments', component: LfxPaymentSearch, meta: { label: 'Payment Search' } },
      { path: 'swiftSearch', name: 'searchSwifts', component: LfxSwiftSearch, meta: { label: 'SWIFT Search' } },
      { path: 'clientSearch', name: 'searchClients', component: LfxClientSearch, meta: { label: 'Client Search' } },
      {
        path: 'clientAccountSearch',
        name: 'searchClientAccounts',
        component: LfxClientAccountSearch,
        meta: { label: 'Client Account Search' },
      },
      { path: 'searchUsers', name: 'searchUsers', component: LfxUserSearch, meta: { label: 'User Search' } },
    ],
  },
];

export default routes;
