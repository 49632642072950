const ldb_field_mixin = {
  emits:['change','focus','blur'],
  props:{
    field:{
      type:Object,
      required:true
    },
    record:{
      type:Object,
      required:true
    },
    definition:{
      type:Object,
      required:false
    },
    formSubmitted:{
      type:Boolean,
      default:false
    },
    view:{
      type:String,
      required:true
    },
    validationError:{
      type:String,
      default:''
    }
  },
  computed:{
    value() {
      return this.record[this.field.name];
    },
    fieldIsRequired() {
      if (this.record.requiredFields === undefined) {
        return false;
      } 
      else {
        return (this.record.requiredFields.indexOf(this.field.name) >= 0);
      }
    },
    fieldLabel() {
      let labelText = this.field.label;
      if (typeof labelText === 'function') {
        labelText = labelText({
          authUser: this.authUser,
          view: this.view,
          routeMetaData: this.$route.meta,
          record: this.record,
        })
      }
      return (this.field.mandatory || this.fieldIsRequired) ? `*${labelText}` : labelText;
    }
  },
  methods:{
    on_change(evt) {
      
    }
  }
}

export {ldb_field_mixin};

