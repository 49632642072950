<template>
  <div class="lfx-rate-setting-container" :class="status">
    <div class="rate-grabber">
      <div class="grabber-dot" />
      <div class="grabber-dot" />
      <div class="grabber-dot" />
    </div>

    <div class="enable-rate" @click="enableRate">
      <capitec-toggle :checked="status !== 'disabled'" />
    </div>
    <LfxRate :rate="rate" />
    <div class="pin-rate" @click="pinRate">
      <capitec-icon class="rate-pin-icon" icon="material/push_pin" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import LfxRate from './LfxRate.vue';

export default {
  components: { LfxRate },
  props: {
    settings: { type: Object, required: true },
    rate: { type: Object, required: true },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    status() {
      return this.settings[this.rate.ric] === undefined ? 'enabled' : this.settings[this.rate.ric];
    },
  },
  methods: {
    ...mapActions(['saveRatesFeedSetting']),
    ...mapMutations([]),
    pinRate() {
      let status = this.status === 'favorite' ? 'enabled' : 'favorite';
      this.saveRatesFeedSetting({ rateId: this.rate.ric, status });
    },
    enableRate() {
      let status = this.status === 'disabled' ? 'enabled' : 'disabled';
      this.saveRatesFeedSetting({ rateId: this.rate.ric, status });
    },
  },
};
</script>

<style>
div.lfx-rate-setting-container {
  display: flex;
  margin-top: 3px;
  padding-top: 3px;
  padding-right: 3px;
  justify-content: space-around;
  transition: all 0.5s linear;
  border-top: 1px solid #ccc;
}

div.lfx-rate-setting-container.disabled {
  filter: grayscale(1);
  opacity: 0.5;
}

/* div.rate-grabber{
  border-radius: 4px;
  margin-right: 3px;
  margin-left:3px;
  min-width: 30px;
  min-height:20px;
  flex:1;
  align-self:stretch;
  justify-content: stretch;
  display:flex;  
  background-color:var(--theme-toggle-checked-track-background-color, #009DE0);
  cursor:grab;
  cursor:-moz-grab;
  flex-direction:row;
  align-content: center;
  align-items:center;
  justify-content: center;
} */

div.rate-grabber {
  display: flex;
  flex: 1;
  min-height: 14px;
  max-width: 15px;
  min-width: 15px;
  border-radius: 4px;
  margin-right: 8px;
  margin-left: 3px;
  background-color: white;
  cursor: grab;
  cursor: -moz-grab;
  flex-direction: row;
  justify-content: space-around;
  flex-direction: column;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  align-items: center;
  align-content: space-around;
  padding-top: 3px;
  padding-bottom: 3px;
}

div.rate-grabber > div.grabber-dot {
  min-width: 10px;
  max-width: 10px;
  min-height: 5px;
  max-height: 5px;
  flex: 1;
  pointer-events: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid red; */
}

div.rate-grabber > div.grabber-dot::before {
  flex: 1;
  content: '';
  top: 0px;
  left: 0px;
  min-width: 3px;
  max-width: 3px;
  width: 3px;
  height: 3px;
  min-height: 3px;
  max-height: 3px;
  border-radius: 5px;
  background-color: var(--theme-status-info-background);
}

div.rate-grabber > div.grabber-dot::after {
  flex: 1;
  content: '';
  /* position:absolute; */
  /* top:0px; */
  /* right:0px; */
  min-width: 3px;
  max-width: 3px;
  width: 3px;
  height: 3px;
  min-height: 3px;
  max-height: 3px;
  border-radius: 5px;
  background-color: var(--theme-status-info-background);
}

/* 
div.rate-grabber > div.grabber-dot{
  background-color:white;
  min-width:3px;
  max-width: 3px;
  min-height: 3px;
  max-height: 3px;
  flex:1;
  border-radius: 4px;
  margin-left:2px;
} */

div.enable-rate {
  flex: 1;
  max-width: 40px;
  min-width: 40px;
  cursor: pointer;
  pointer-events: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.pin-rate {
  flex: 1;
  max-width: 40px;
  min-width: 40px;
  cursor: pointer;
  pointer-events: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--theme-toggle-checked-track-background-color, #009de0);
  opacity: 0.5;
}

div.lfx-rate-setting-container.favorite > div.pin-rate {
  color: var(--theme-toggle-checked-knob-background-color, #009de0);
  opacity: 1;
}

.rate-pin-icon {
  text-align: center;
}
</style>
