<template>
  <LDBModal type="result" result="info" :header="header">
    <template #body>
      <LDBItemView
        :record="bulkFeeBatchRecord"
        :definition="definition"
        view="item"
        :showNextButton="false"
        :action="undefined"
        :params="{}"
      />
    </template>
    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import LDBButton from '@/landobyte_vue/LDBButton.vue';

export default {
  name: 'LfxAddAccountMarkup',
  components: { LDBItemView,LDBButton },
  props: {},
  data() {
    return {
      mounted: false
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),
    header() {
      return 'Bulk Fee Batch';
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          action: 'click',
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'downloadSourceFile',
          label: 'Download Source File',
          type: 'primary',
          action: 'downloadBulkFeeBatchSource',
          enabledGuards: !!this.bulkFeeBatchRecord.uploadFileS3Key,
          params: { accountId: this.accountId, bulkFeeBatchId: this.bulkFeeBatchId },
          actionParams: {},
        },
        {
          name: 'downloadResultFile',
          label: 'Download Result File',
          type: 'primary',
          action: 'downloadBulkFeeBatchResult',
          enabledGuards: !!this.bulkFeeBatchRecord.uploadFileS3Key,
          params: { accountId: this.accountId, bulkFeeBatchId: this.bulkFeeBatchId },
          actionParams: {},
        },
      ];
      return allButtons;
    },

    // ----- Definition -----

    definition() {
      return getDefinition('bulkFeeBatch');
    },

    // ----- Record -----

    accountId() {
      return +this.$route.params.accountId;
    },
    accountRecord() {
      return this.account[this.accountId];
    },
    bulkFeeBatchId() {
      return +this.$route.params.bulkFeeBatchId;
    },
    bulkFeeBatchRecord() {
      return this.accountRecord.bulkFeeBatch[this.bulkFeeBatchId]
    },
    // ----- Fields -----

  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),

    // ----- Fields -----


    // ----- API calls -----

  },
};
</script>
