<template>
  <LDBModal result="info" header="Payment Portfolio Message" size="xx-large">
    <template #body>
      <LDBItemView
        :record="messageRcord"
        view="item"
        :showNextButton="false"
        :action="undefined"
        :params="{}"
      />
    </template>
    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @on-api-success="button.onApiSuccess"
        @click="button.clickEvent"
      ></LDBButton>
    </template>    
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';

export default {
  name: 'LfxPaymentPortfolioLogItem',
  components: { LDBItemView },
  props: {},
  emits: ['step-next'],
  data() {
    return {};
  },
  created() {},
  mounted() {
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['payment']),
    paymentId() {
      return +this.$route.params?.paymentId;
    },
    messageId() {
      return this.$route.params?.messageId
    },
    messageRcord() {
      return this.payment[this.paymentId].messageLog[this.messageId] || {}
    },
    definition() {
      return getDefinition('payment_message_log');
    },


    // ----- Form Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Back',
          type: '',
          action: 'click',
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

      ];
      return allButtons;      
    },

  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
