//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2022 LandoByte (Pty) Ltd.
//  File        : LfxAccountMarkupLogDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Account Markup Log record for LandoByte TS systems
//  Created     : 22 July 2022 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition } from '../definitions/LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------------------------------

export interface LfxAccountMarkupLogInt {
  id?: string;
  accountId?: number;
  markupId?: number;
  action?: string;
  actionDetails?: {};
  timestamp?: Date;
  userId?: number;
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxAccountMarkupLogDef: LdbDefinition = {
  title: 'Account Markup Log',
  table: 'LfxAccountMarkupLog',
  database: 'dynamo',
  // collectionPath: '/account/{{accountId}}/markup/{{markupId}}/log',
  collectionPath: '/account/{{accountId}}/markupLogs', // Removed - 2024-09-16 because markupLogs are now stored directly under LfxAccount
  view: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
  indexes: ['accountId', 'markupId'],
  pagingType: 'backEnd',
  fields: {
    id: {
      label: 'ID',
      datatype: { id: { descriptionField: 'action', type: 'text' } },
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    accountId: {
      label: 'Account',
      datatype: {
        foreignKey: {
          collection: 'fk_accounts',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    markupId: {
      label: 'Markup',
      datatype: {
        foreignKey: {
          collection: 'fk_account_markup',
          linkTable: 'LfxAccountMarkup',
          linkField: 'id',
          displayField: 'currencyPairName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    action: {
      label: 'Action',
      datatype: 'text',
      default: '',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    actionDetails: {
      label: 'Action Details',
      datatype: { json: {} },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    timestamp: {
      label: 'Timestamp',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    userId: {
      label: 'User',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
  },
};
