<template>
  <LFXContent
    :titleBar="titleBar"
    :show_tabs="showTabs"
    :show_stepper="showStepper"
    :show_actions="showActions"
    :show_label="showLabel"
    :label="contentLabel"
    :form_layout="formLayout"
    :show_buttons="false"
    :show_sidebar="false"
  >
    <template #actions>
      <LDBButton
        v-for="button in actionBarButtons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        @click="button.clickEvent"
      ></LDBButton
    ></template>

    <template #stepper
      ><capitec-stepper
        layout="horizontal"
        :steps="steps"
        :active-step-number="activeStepNumber"
        @step-click="onStepClick"
      >
      </capitec-stepper
    ></template>

    <template #content>
      <router-view @step-back="stepBack" @step-next="stepNext"></router-view>
    </template>
  </LFXContent>

  <router-view name="modal"></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXContent from '@/components/layout/LfxContent.vue';

export default {
  name: 'LfxConfigureUser',
  components: { LFXContent },
  props: {},
  data() {
    return {};
  },
  created() {
    if (this.$route.name === 'configureUser') {
      this.selectFirstStep();
    }
  },
  mounted() {},
  computed: {
    ...mapGetters(['isDevelopment']),
    ...mapState(['account']),

    // ----- Title Bar -----

    titleBar() {
      const titleBar = {
        label: this.titleBarLabel,
        buttons: [
          {
            name: 'backToUser',
            label: 'Back to User',
            type: 'white',
            routeName: 'userDetailsView',
            // clickEvent: null, //TODO
            // permission: //TODO
          },
        ],
      };
      return titleBar;
    },
    titleBarLabel() {
      return `Configure User: ${this.userDisplayName}`;
    },

    // ----- Tabs -----

    showTabs() {
      return false;
    },

    // ----- Stepper -----

    showStepper() {
      return true;
    },
    steps() {
      let steps = [
        { stepName: 'details', routeName: 'configureUserDetailsEdit', guards: true, isError: false, title: 'Details' },
        {
          stepName: 'permissions',
          routeName: 'configureUserPermissionsEdit',
          guards: true,
          isError: false,
          title: 'Permissions',
        },
        {
          stepName: 'settings',
          routeName: 'configureUserSettingsEdit',
          guards: true && this.isDevelopment, //- LEAVE FOR NOW
          isError: false,
          title: 'Settings',
        },
        { stepName: 'userTeam', routeName: 'configureUserUserTeam', guards: this.accountRecord?.accountLevel === 'bank', isError: false, title: 'User Team' },
        { stepName: 'enable', routeName: 'configureUserEnable', guards: true, isError: false, title: 'Enable User' },
      ];
      return steps.filter((step) => step.guards);
    },

    routeStepName() {
      return this.$route.meta?.content.stepName;
    },
    activeStepNumber() {
      let activeStepIndex = this.steps.findIndex((step) => step.stepName === this.routeStepName);
      let activeStepNumber = activeStepIndex + 1; // stepnumber starts at 1
      return activeStepNumber;
    },

    // ----- Action Bar -----

    showActions() {
      return this.actionBarButtons.length !== 0 ? true : false;
    },
    actionBarButtons() {
      let self = this;
      let allButtons = {
        configureUserDetailsEdit: [
          {
            name: 'editUserLogin',
            label: 'Edit Login Details',
            type: '',
            guards: self.record.editUserLogin_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'configureUserDetailsEditAuth' });
            },
          },
        ],
      };
      return allButtons[this.actionGroup] !== undefined
        ? allButtons[this.actionGroup].filter((button) => button.guards)
        : [];
    },

    actionGroup() {
      return this.$route.meta?.content?.actionGroup;
    },

    // ----- Layout -----

    formLayout() {
      return this.$route.meta?.layout === 'list' ? false : true;
    },

    // ----- Content Label -----

    showLabel() {
      return this.contentLabel ? true : false;
    },
    contentLabel() {
      return this.$route.meta?.content?.label ? this.$route.meta.content.label : 'TODO';
    },

    // ----- Record -----

    recordName() {
      return 'user';
    },
    recordId() {
      return this.$route.params.userId;
    },
    record() {
      let rec = this.$store.state?.[this.recordName]?.[this.recordId];
      return rec;
    },
    accountRecord() {
      return this.account?.[this.record?.accountId]
    },
    definition() {
      return getDefinition(this.recordName);
    },
    userStatus() {
      return this.record.status;
    },

    userDisplayName() {
      return this.record?.preferredName
        ? `${this.record?.preferredName} ${this.record?.surname}`
        : `${this.record?.firstName} ${this.record?.surname}`;
    },

    // ----- Buttons -----

    showButtons() {
      return true;
    },

    flowButtonsLeft() {
      let self = this;
      let allButtons = {
        configureUserDetailsEdit: [],
        configureUserPermissionsEdit: [self.flowBackButton],
        configureUserEnable: [self.flowBackButton],
      };
      return allButtons[this.buttonGroup] !== undefined
        ? allButtons[this.buttonGroup].filter((button) => button.guards)
        : [];
    },

    flowButtonsRight() {
      let self = this;
      let allButtons = {
        configureUserDetailsEdit: [
          {
            name: 'save',
            label: 'Save',
            type: '',
            guards: true,
            clickEvent() {
              self.$router.push({ name: 'todo' });
            },
          },
          {
            name: 'next',
            label: 'Next',
            type: 'primary',
            guards: true,
            clickEvent() {
              self.stepNext();
            },
          },
        ],
        configureUserPermissionsEdit: [
          {
            name: 'save',
            label: 'Save',
            type: '',
            guards: true,
            clickEvent() {},
          },
          {
            name: 'next',
            label: 'Next',
            type: 'primary',
            guards: true,
            clickEvent() {
              self.stepNext();
            },
          },
        ],
        configureUserEnable: [
          {
            name: 'enable',
            label: 'Enable User',
            type: 'primary',
            guards: true,
            clickEvent() {},
          },
        ],
      };
      return allButtons[this.buttonGroup] !== undefined
        ? allButtons[this.buttonGroup].filter((button) => button.guards)
        : [];
    },

    buttonGroup() {
      return this.$route.meta?.content?.buttonGroup;
    },

    flowBackButton() {
      let self = this;
      let backButton = {
        name: 'back',
        label: 'Back',
        type: 'clear',
        guards: true,
        clickEvent() {
          self.stepBack();
        },
      };
      return backButton;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    saveDetails({ fields, options }) {},

    onFieldChange({ field, value, record }) {},

    // ----- Stepper -----

    selectFirstStep() {
      this.$router.replace({ name: this.steps[0].routeName });
    },

    onStepClick(evt) {
      let newStepNumber = evt.detail.stepNumber;
      let newStepNumberIndex = newStepNumber - 1; // stepnumber starts at 1
      let newStep = this.steps[newStepNumberIndex];
      this.$router.replace({ name: newStep.routeName });
    },
    stepBack() {
      let newStepNumber = this.activeStepNumber - 1;
      let newStepNumberIndex = newStepNumber - 1; // stepnumber starts at 1
      let newStep = this.steps[newStepNumberIndex];
      this.$router.replace({ name: newStep.routeName });
    },
    stepNext() {
      let newStepNumber = this.activeStepNumber + 1;
      let newStepNumberIndex = newStepNumber - 1; // stepnumber starts at 1
      let newStep = this.steps[newStepNumberIndex];
      this.$router.replace({ name: newStep.routeName });
    },
  },
};
</script>
