<template>
  <div v-if="view === 'edit' || view === 'create'" class="ldb-field-container__text">
    <capitec-text-field
      @value-change="on_change"
      type="numeric"
      @key-input="on_change"
      @focus="on_focus"
      @blur="on_blur"
      :label="fieldLabel"
      :value="value"
      :decimalPlaces="decimalPlaces"
      :error="validationError"
      :disabled="field.enableGuards === false"
    ></capitec-text-field>
  </div>

  <template v-else-if="view === 'item'">
    <LDBItemViewField
      :view="view"
      :field="field"
      :displayValue="displayValue"
      :definition="definition"
      :record="record"
    />
  </template>

  <template v-else-if="view === 'list'">
    <LDBListViewField
      :view="view"
      :field="field"
      :displayValue="displayValue"
      :definition="definition"
      :record="record"
    />
  </template>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import { ldb_field_mixin } from './ldb_field_mixin.js';
import LDBItemViewField from './LDBItemViewField.vue';
import LDBListViewField from './LDBListViewField.vue';

export default {
  mixins: [ldb_field_mixin],
  components: { LDBItemViewField, LDBListViewField },
  props: {},
  data() {
    return {
      focussed: false,
      newValue: '',
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    decimalPlaces() {
      let res = 2;
      if (this.field.datatype.float !== undefined) {
        res = this.field.datatype.float.decimalPlaces === undefined ? 2 : this.field.datatype.float.decimalPlaces;
      } else if (this.field?.displayType?.float?.decimalPlaces) {
        res = this.field.displayType.float.decimalPlaces;
      }
      return res;
    },
    displayValue() {
      let res = '';
      if (this.value !== undefined && this.value !== null) {
        if (false && this.field?.displayType?.float?.decimalPlaces) {
          // TODO: should rates be padded to 4 decimals if less than 4?  this doesn't work if more than 4 decimals
          res = this.value.toFixed(this.field.displayType.float.decimalPlaces);
        } else {
          res = this.value.toString();
        }
      }
      return res;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    on_focus(evt) {
      this.focussed = true;
    },
    on_blur(evt) {
      this.focussed = false;
    },
    on_change(evt) {
      this.newValue = evt.detail.new;
      this.$emit('change', evt.detail.new);
    },
  },
};
</script>
<style>
div.ldb-field-container__text {
  /* border: 3px solid green; */
}
</style>
