<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <ul v-if="clientIsIndividual">
        <li>This individual client will be enabled as a signatory.</li>
        <li>This will only work while the client's "BOP Form Signatory Option" is set to "Client".</li>
        <li>To edit the client's signatory details, please contact TreasuryMaintain.</li>
      </ul>
      <ul v-else>
        <li>This main contact will be created for the entity client.</li>
        <li>To edit the client's contact details, please contact TreasuryMaintain.</li>
      </ul>      <div v-if="clientRetrievedStatus === 'retrieved'">
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="item"
          :fieldname="field.name"
          :record="clientData"
        />
        <div v-if="!allDataComplete">
          <br />
          Some information is incomplete. Please update at the bank and try again.
        </div>
      </div>
      <div v-else-if="clientRetrievedStatus === 'error'">
        <br />
        Failed to Load Client Data.
        <br />
        {{ clientData.errorMessage }}
      </div>
      <template v-else>
        <br />
        Loading client data from the Bank - This may take some time
        <LDBLoader />
      </template>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import LDBField from '@/landobyte_vue/LDBField.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import { doPermissionAction, formatReturnedError } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxEnableIndividualSignatory',
  components: { LDBField, LDBLoader },
  props: {},
  data() {
    return {
      clientRetrievedStatus: 'none',
      clientData: {},
    };
  },
  created() {
    this.retrieveClientData();
  },
  computed: {
    ...mapGetters(['permissions', 'config']),
    ...mapState(['account']),
    header() {
       return this.clientIsIndividual ? 'Enable Signatory for Individual' : 'Create Main Contact for Entity'
    },
    clientIsIndividual() {
      return this.accountRecord?.accountType === 'individual'
    },
    fields() {
      const res = ['token', 'emailAddress']
      if (this.clientIsIndividual) {
        res.push('firstName');
        res.push('surname');
        res.push('idNumber');
        res.push('msisdn');
      } else {
        res.push('contactNo');
        res.push('contactPersonName')
      }
      return res
    },
    allDataComplete() {
      for (const field of this.fields) {
        if (!this.clientData[field]) {
          return false;
        }
      }
      return true;
    },
    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          action: 'click',
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'enableIndividual',
          label: this.clientIsIndividual ? 'Enable Individual Signatory' : 'Create Main Contact',
          type: 'primary',
          action: this.clientIsIndividual ? 'enableIndividualSignatory' : 'enableEntityMainContact',
          params: { accountId: this.accountId },
          actionParams: this.clientData,
          enabledGuards: this.allDataComplete,
          clickEvent(response) {
            if (response.status === 'success') {
              self.$router.go(-1);
            }
          },
        },
      ];
      return allButtons;
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : 'todo';
    },

    // ----- Definition -----

    // ----- Record -----

    accountId() {
      return +this.$route.params.accountId;
    },
    accountRecord() {
      return this.account[this.accountId];
    },
    // ----- Fields -----

    viewFields() {
      const self = this;
      let res = [
        {
          label: 'First Name',
          name: 'firstName',
          datatype: 'text',
        },
        {
          label: 'Id Number/Passport',
          name: 'idNumber',
          datatype: 'text',
        },
        {
          label: 'Surname',
          name: 'surname',
          datatype: 'text',
        },
        {
          label: 'Contact Person Name',
          name: 'contactPersonName',
          datatype: 'text',
        },
        {
          label: 'Email Address',
          name: 'emailAddress',
          datatype: 'text',
        },
        {
          label: 'Mobile Number',
          name: 'msisdn',
          datatype: 'text',
        },
        {
          label: 'Contact Number',
          name: 'contactNo',
          datatype: 'text',
        },
      ];
      return res.filter((f) => self.fields.includes(f.name));;
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),

    async retrieveClientData() {
      this.clientRetrievedStatus = 'retrieving';
      const clientDataResult = await doPermissionAction({ onSuccess: { doStoreUpdate: false } }, 'getBankInformation', {
        accountId: this.accountId,
      });
      if (clientDataResult.status === 'success') {
        this.clientData.token = clientDataResult.response.token;
        this.clientData.firstName = clientDataResult.response.firstName;
        this.clientData.idNumber = clientDataResult.response.identification;
        this.clientData.surname = clientDataResult.response.lastName;
        this.clientData.emailAddress = clientDataResult.response.email;
        this.clientData.msisdn = clientDataResult.response.mobileNo || clientDataResult.response.contactNo;
        this.clientData.contactNo = clientDataResult.response.contactNo;
        this.clientData.contactPersonName = clientDataResult.response.contactPersonName;
        this.clientRetrievedStatus = 'retrieved';
      } else {
        this.clientData.errorMessage = formatReturnedError(clientDataResult);
        this.clientRetrievedStatus = 'error';
      }
    },
    // ----- Fields -----

    // ----- API calls -----
  },
};
</script>
