<template>
  <div class="lfx-widget-row-container">
  <capitec-avatar v-if="avatar !== ''" class="widget-row-avatar" type="strong" :value="avatarLetters" :color="avatarColor" size="small" />  
  <capitec-label class="widget-row-title" type="strong" :label="title" /> 
  <capitec-label class="widget-row-subtitle" type="default" :label="subtitle" /> 
  <div class="widget-row-info" v-if="info === ''" >
    <slot name="info" />
  </div>
  <capitec-label v-else class="widget-row-info" type="strong" :label="info" /> 
  <router-link   class="widget-row-arrow capitec-hyperlink widget"  :to="`${link}`" tag="capitec-hyperlink"  >
  <capitec-icon icon="system/chevron-right-action"  size="small"/>
  </router-link>
  </div>
</template>


<script>

import {
  mapState,
  mapActions,
  mapGetters,
  mapMutations
} from "vuex";

export default {
  components: {

  },
  props:{  
    link:{type:String,required:true},
    title:{type:String,required:true},
    subtitle:{type:String,default:''},
    info:{type:String,default:''},
    avatar:{type:String,default:''},
    avatarColor:{type:String,default:'#CCC'}
  },
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  computed:{
    ...mapGetters([]),
  	...mapState([]),
    avatarLetters() {
      let res =  this.avatar.split(' ').map(s => s[0]).join('').toUpperCase();
      return res;
    }
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([])
  }
 }
</script>
<style>

div.lfx-widget-row-container{
  border-top:1px solid #EEE;
  display:grid;
  grid-template-areas:
  'avatar title . info arrow'
  'avatar subtitle . info arrow'
  'avatar subtitle . info arrow';
  grid-template-columns:auto 1fr 20px 1fr 20px;
  grid-template-rows: 15px 15px 5px;
  padding-left:10px;
  padding-right: 10px;
  padding-top:5px;
}

.widget-row-avatar{
  grid-area: avatar;
  padding-right: 10px;
}

.widget-row-title{
  grid-area:title;
  white-space: nowrap;

}

.widget-row-subtitle{
  grid-area:subtitle;
  white-space: nowrap;
}

.widget-row-info{
  grid-area:info;
  padding-right:10px;
  display:flex !important;
  align-items: flex-end !important;
  justify-content: center !important;
  white-space: nowrap;
}

.widget-row-arrow{
  grid-area:arrow;
  display:flex;
  align-items:center;
  justify-content: center;
}

div.widget-row-info > div.ldb-field-container.list > capitec-tag{
  /* border: 1px dotted black; */
  /* margin-top:-5px !important; */
  margin-bottom:5px;
}

</style>


