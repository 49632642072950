<template>
  <LDBItemView v-if="fullCifResidencyDetailsAreCaptured"
    :record="applicationBankAccountRecord"
    :view="view"
    :showBackButton="showBackButton"
    @on-back="onBack"
    :action="action"
    :params="params"
    @on-save="onSave"
    :showNextButton="showNextButton"
    @on-next="onNext"
  />
  <h3 v-else>Client Residential Status and Country must be captured before the account can be edited</h3>
  <router-view></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';

export default {
  name: 'LfxClientApplicationBankAccountDetails',
  components: { LDBItemView },
  emits: ['step-back', 'step-next'],
  props: {
    allowModification: {type: Boolean, default: true, requied: false}
  },
  data() {
    return {};
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    isWizard() {
      return this.$route.meta?.wizard;
    },

    view() {
      if (!this.allowModification || ['inProgress','completed' ].includes(this.applicationBankAccountRecord?.status)) {
        return 'item'
      }
      return this.$route.meta?.content?.view || 'item';
    },
    fullCifResidencyDetailsAreCaptured() {
      return this.fullCifRecord?.fullCifResidencyDetailsAreCaptured || false
    },

    // ----- Account Record -----

    clientId() {
      return +this.$route.params.accountId;
    },
    clientRecord() {
      return this.account[this.clientId];
    },

    // ----- Application Record -----

    applicationId() {
      return +this.$route.params.applicationId;
    },
    applicationRecord() {
      return this.clientRecord?.application?.[this.applicationId];
    },

    // ----- Bank Account Record -----

    applicationBankAccountId() {
      return +this.$route.params.applicationAccountId;
    },
    applicationBankAccountRecord() {
      return this.applicationRecord?.application_account?.[this.applicationBankAccountId];
    },
    // ----- Full CIF -----

    fullCifId() {
      return this.applicationRecord?.fullCifId;
    },
    fullCifRecord() {
      return this.applicationRecord?.application_cif?.[this.fullCifId];
    },

    // ----- Form Buttons -----

    showBackButton() {
      return this.isWizard || false;
    },
    showNextButton() {
      return this.isWizard || false;
    },

    // ----- Action -----

    action() {
      return 'updateApplicationAccount';
    },
    params() {
      let params = {
        accountId: this.clientId,
        applicationId: this.applicationId,
        applicationAccountId: this.applicationBankAccountId,
      };
      return params;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    onBack() {
      this.$emit('step-back');
    },
    onSave({ fields, options }) {
      if (!this.isWizard) {
        this.$router.go(-1);
      }
    },
    onNext() {
      this.$emit('step-next');
    },
  },
};
</script>
