<template>
  <LDBModal result="info" :header="header"  size="x-large">
    <template #body>
      <div v-if="mounted">
        <LDBField
          v-for="(field, fieldname) in clientViewFields"
          :key="fieldname"
          :field="field"
          view="item"
          :fieldname="field.name"
          :definition="clientDefinition"
          :record="clientRecord"
          @change="on_change"
        />
        <br/>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="item"
          :fieldname="field.name"
          :definition="dealDefinition"
          :record="dealRecord"
          @change="on_change"
        />
        <br/>
        <LDBField
          v-for="(field, fieldname) in formFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="dealDefinition"
          :record="dealRecord"
          @change="on_change"
        />
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
        @on-api-success="onApiSuccess"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxDealRelease',
  components: { LDBField },
  emits: ['on-api-success'],
  props: {},
  data() {
    return {
      mounted: true,
    };
  },
  created() {},
  mounted() {},
  beforeUnmount() {
    this.dealRecord.revert();
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['deal','account']),
    header() {
      return `Release Deal`;
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

        {
          name: 'releaseDeal',
          label: 'Release Deal',
          type: 'primary',
          action: 'releaseDeal',
          params: {
            transactionId: this.dealRecord.transactionFileId,
            dealId: this.dealRecord.id,
          },
          actionParams: this.dealRecord,
          guards: true,
          clickEvent(response) {
            // self.$router.replace({ name: 'transactionDealDetailsView' });
            if (response.status === 'success') {
              self.$router.go(-1);
            }
          },
        },
      ];
      return allButtons !== undefined ? allButtons : [];
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : '/';
    },

    // ----- Definition -----

    dealDefinition() {
      return getDefinition('deal');
    },
    clientDefinition() {
      return getDefinition('client')
    },

    // ----- Record -----
    recordName() {
      return 'deal';
    },
    dealId() {
      return +this.$route.params.dealId;
    },
    dealRecord() {
      let rec = this.deal[this.dealId];
      return rec;
    },
    clientId() {
      return +this.$route.params.accountId;
    },
    clientRecord() {
      return this.account[this.clientId]
    },

    // ----- Fields -----

    clientFields() {
      return this.clientDefinition.fields
    },
    clientViewFieldNames() {
      return [
        'name',
        'cifNumber',
        this.clientRecord.bpNumber ? 'bpNumber' : undefined,
        'intermediaryId'
      ]
    },
    clientViewFields() {
      const res = [];
      for (const fieldName of this.clientViewFieldNames) {
        const fields = this.clientFields?.filter((field) => field.field === fieldName);
        if (fields.length > 0) {
          res.push(fields[0]);
        }
      }
      return res;
    },


    dealFields() {
      return this.dealDefinition.fields;
    },
    viewFieldNames() {
      return [
        'bankAction',
        'currencyPair',
        'currency',
        'amount',
        'counterCurrency',
        'counterAmount',
        'clientRate',
        this.dealRecord.forwardContractType === 'partiallyOptional'? 'optionStartDate' : undefined,
        'valueDate',
        'deliveryType',
        this.dealRecord.deliveryType === 'FWD'? 'forwardContractType' : undefined,
        'spotRate',
        'bench',
        'bankRate',
        'bankMarkup',
        'intermediaryMarkup',
        this.dealRecord.deliveryType === 'FWD'? 'forwardPoints' :undefined,
      ]
    },
    viewFields() {
      const res = [];
      for (const fieldName of this.viewFieldNames) {
        const fields = this.dealFields.filter((field) => field.field === fieldName);
        if (fields.length > 0) {
          res.push(fields[0]);
        }
      }
      return res;
    },

    formView() {
      return 'releaseDeal';
    },
    formFields() {
      let res = [];
      for (let k in this.dealFields) {
        let field = this.dealFields[k];
        if (field.views[this.formView] === true) {
          field.fieldname = k;
          res.push(field);
        }
      }
      return res;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    on_change({ field, value }) {
      this.dealRecord[field] = value;
    },

    // ----- API calls -----
    async onApiSuccess(_responseObject) {},
  },
};
</script>

<style></style>
