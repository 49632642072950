<template>
  <LDBModal :size="modalSize" result="info" :header="header" class="lfx-inter-report">
    <template #body>
      <div>
        <h3>{{modeText}}</h3>
        <LDBField
        v-if="allowModeSelection"
        key="modeSelection"
        :field="modeSelectionField"
        view="edit"
        fieldname="modeSelection"
        :definition="undefined"
        :record="{}"
        @change="onModeChange"
      />
      <template v-if="!!reportEnableMode">
        <h3>Report Upload</h3>
        <capitec-file-upload label="Select CSV File" @selection-change="onSelectionChange" :disabled="false" type="" fileTypes="text/csv" hint="Jannie"/>
        <br/>
      </template>
        <template v-if="fileStatus === 'processing'">
          <LDBLoader />
        </template>
        <template v-else-if="fileStatus === 'missingHeaders'">
          <h1>Some headers are missing:</h1>
          <ul>
            <li v-for="item in missingHeaders" :key="item">{{ item }}</li>
          </ul>
        </template>
        <h3>Intermediaries</h3>
        <div class="lfx-inter-report-view-table-container">
          <table-lite
            id="lfx-inter-report-view-table"
            :is-static-mode="true"
            :is-slot-mode="true"
            :has-checkbox="false"
            :is-loading="false"
            :is-re-search="false"
            :columns="tableColumns"
            :rows="sortedRecords"
            :pageSize="sortedRecords.length"
            :total="sortedRecords.length"
          >
            <template v-for="(col, i) of tableColumns" v-slot:[col.field]="data" :key="i">
              <template v-if="col.type === 'checkbox'">
                <LDBField
                  :key="col.field+i"
                  :field="checkboxField(data.value,col.field)"
                  view="edit"
                  datatype="boolean"
                  :fieldname="col.field"
                  :definition="undefined"
                  :record="data.value"
                  @change="on_change"
                />
              </template>
              <template v-else>{{ data.value[col.field] }}</template>
            </template>
          </table-lite>
        </div>
      </div>
    </template>
    <template #footer>
      <LDBButton
        v-for="button in buttons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        :onSuccess="button.onSuccess"
        @click="button.clickEvent"
        @on-api-success="onApiSuccess"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapGetters, } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBField from '@/landobyte_vue/LDBField.vue';
import TableLite from '@/views/general/TableLite.vue';

export default {
  name: 'LfxBankAccountStatement',
  components: { LDBField, TableLite },
  emits: ['on-api-success'],
  props: {},
  emits: {},
  async created() {
    this.populateIntermediaryData()
  },
  mounted() {
    if (this.iMayEditClientReports && !this.iMayEditDealReports) {
      this.reportEnableMode = 'client'
    } else if (!this.iMayEditClientReports && this.iMayEditDealReports) {
      this.reportEnableMode = 'deal'
    }
  },
  data() {
    return {
      reportEnableMode: undefined, //'client' | 'deal' 

      mounted: false,
      allowSubmit: false,
      intermediaryData:{},

      fileStatus:'none',
      missingHeaders: [],
      dataLines: [],
      file:null,
      errorCifs:[]
    };
  },
  computed: {
    ...mapGetters(['intermediaries']),
    ...mapState(['account','authUser']),
    modalSize() {
        return 'xx-large';
    },
    // ----- Definition -----

    definition() {
      return getDefinition('bankAccount');
    },

    header() {
      return `Enable Intermediary Reports`;
    },
    allowModeSelection() {
      return this.iMayEditClientReports && this.iMayEditDealReports
    },
    modeText() {
      if (this.iMayEditClientReports && !this.iMayEditDealReports) {
        return 'Client Report Mode'
      } else if (!this.iMayEditClientReports && this.iMayEditDealReports) {
        return 'Deal Report Mode'
      }
      return 'Mode Selection'
    },
    modeSelectionField() {
      return {
        label: 'Report Mode',
        datatype: {
          option: {
            optionType: 'text',
            options: [
              { id: 'client', name: 'Client and Balance' },
              { id: 'deal', name: 'Deal' },
            ],
          },
        },
      }
    },
    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta?.modal?.cancelRoute : 'todo';
    },
    buttons() {
      let self = this;
      let allButtons = [
        {
          name: 'closeStatementModal',
          label: 'Close',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'update',
          label: 'Save Update',
          type: 'primary',
          action: 'bulkEnableIntermediaryReports',
          params: { accountId:1 },
          actionParams: {intermedariesConfig:this.intermediaryData},
          guards: true,
          enabledGuards: this.allowSubmit,
          onSuccess: { doStoreUpdate: false },
        },
      ];
      return allButtons.filter((button) => button.guards);
    },
    // ----- Fields -----

    iMayEditClientReports() {
      return !!this.authUser?.permissions?.includes('releaseInterClientReports')
    },
    iMayEditDealReports() {
      return !!this.authUser?.permissions?.includes('releaseInterClientReports')
    },
    allowClientReportEdit() {
      return this.iMayEditClientReports && this.reportEnableMode === 'client'
    },
    allowDealReportEdit() {
      return this.iMayEditDealReports && this.reportEnableMode === 'deal'
    },
    otherIntermediaryFields() {
      switch (this.reportEnableMode) {
        case 'client': return ['trs_intermediary_cif','cfs_intermediary_cif','bancs_intermediary_cif_accountlevel'];
        case 'deal': return ['broker_cif'];
        default: return 'noModeSelected'
      } 
    },
    //----- Table -----
    tableColumns() {
      let self = this;
      let res = [
        {
          col: 'intermediaryNameColumn',
          field: 'intermediaryName',
          label: 'Name',
          isKey: false,
          sortable: false,
          guards: true,
        },
        {
          col: 'cifColumn',
          field: 'intermediaryCifNumber',
          label: 'CIF Number',
          isKey: false,
          sortable: false,
          guards: true,
        },
        {
          col: 'clientReportEnableColumn',
          field: 'clientReportEnable',
          label: 'Client Report',
          isKey: false,
          sortable: false,
          guards: this.allowClientReportEdit,
          type: 'checkbox'
        },
        {
          col: 'accountBalanceReportEnableColumn',
          field: 'accountBalanceReportEnable',
          label: 'Account Balance Report',
          isKey: false,
          sortable: false,
          guards: this.allowClientReportEdit,
          type: 'checkbox'
        },
        {
          col: 'dailyDealReportEnableColumn',
          field: 'dailyDealReportEnable',
          label: 'Deal Report',
          isKey: false,
          sortable: false,
          guards: this.allowDealReportEdit,
          type: 'checkbox'
        },
      ];
      return res.filter((f) => f.guards === true);
    },

    sortedRecords() {
      const res = [];
      for (const intermediaryId in this.intermediaryData) {
        const intermediary = this.intermediaryData[intermediaryId];
        res.push(        {
          id:intermediaryId,
          intermediaryName:intermediary.intermediaryName,
          intermediaryCifNumber:intermediary.intermediaryCifNumber,
          intermediarySettingsId:intermediary.intermediarySettingsId,
          [intermediaryId.toString()+"-clientReportEnable"]: intermediary.clientReportEnable,
          [intermediaryId.toString()+"-accountBalanceReportEnable"]: intermediary.accountBalanceReportEnable,
          [intermediaryId.toString()+"-dailyDealReportEnable"]: intermediary.dailyDealReportEnable,
        },
)
      }
      return res;
    },
  },
  methods: {
    populateIntermediaryData() {
      this.intermediaryData = {};
      for (const intermediaryId in this.intermediaries) {
        const intermediary = this.intermediaries[intermediaryId];
        if (intermediary.visibility === 'visible') {
          this.intermediaryData[intermediaryId] = {
            intermediaryName:intermediary.name,
            intermediaryCifNumber:intermediary.cifNumber,
            intermediarySettingsId:intermediary.intermediarySettingsId,
            clientReportEnable: this.allowClientReportEdit ? !!intermediary.clientReportEnable : undefined,
            accountBalanceReportEnable: this.allowClientReportEdit ? !!intermediary.accountBalanceReportEnable : undefined,
            dailyDealReportEnable: this.allowDealReportEdit ? !!intermediary.dailyDealReportEnable: undefined,
          }
        }
      }

    },
    // ----- Fields -----
    onModeChange({ field, value }) {
      this.reportEnableMode = value;
      this.allowSubmit = false;
      this.populateIntermediaryData()
    },
    checkboxField(fieldItem,colField) {
      return {
        name: fieldItem.id.toString()+"-"+colField,
        label: '',
        datatype: 'boolean',
        default: !!fieldItem.colField,
        mandatory: false,
      }
    },

    on_change({ field, value }) {
      const splitFieldArray = field.split("-")
      if (
        (['clientReportEnable','accountBalanceReportEnable'].includes(splitFieldArray[1]) && !!this.allowClientReportEdit || 
        (splitFieldArray[1] === 'dailyDealReportEnable' && !!this.allowDealReportEdit))
        ) 
        {
          this.intermediaryData[+splitFieldArray[0]][splitFieldArray[1]] = value;
          this.allowSubmit = true;
        }
    },
    onApiSuccess() {
      this.allowSubmit = false
    },
    // ----- File -----
    onSelectionChange(evt) {
      self = this;
      this.fileStatus = "processing";
      this.missingHeaders = [];
      this.dataLines = [];
      this.errorCifs = [];

      this.file = evt.detail.files[0];
      let reader = new FileReader();
      reader.readAsText(this.file);
      reader.onload = function() {
        self.processUploadedFile(reader.result);
      };
    },
    processUploadedFile(fileContents) {
      fileContents = fileContents.replaceAll("\r","\n");
      fileContents = fileContents.replaceAll("\n\n","\n");
      fileContents = fileContents.replaceAll(";",",");
      const lines = fileContents.split("\n");
      const headers = this.stripQuotes(lines[0].split(","));
      this.checkHeaders(headers);
      if (this.fileStatus === "processing") {
        for (const lineId in lines) {
          if (lineId > 0) {
            this.processLine(lines[lineId],headers,lineId)
          }
        }
        for (const intermediaryId in this.intermediaryData) {
          const intermediary = this.intermediaryData[intermediaryId]
          if (this.errorCifs.includes(intermediary.intermediaryCifNumber)) {
            intermediary.clientReportEnable = this.allowClientReportEdit ? false: undefined;
            intermediary.accountBalanceReportEnable = this.allowClientReportEdit ? false: undefined;
            intermediary.dailyDealReportEnable = this.allowDealReportEdit ? false: undefined;
          } else {
            intermediary.clientReportEnable = this.allowClientReportEdit ? true: undefined;
            intermediary.accountBalanceReportEnable = this.allowClientReportEdit ? true: undefined;
            intermediary.dailyDealReportEnable = this.allowDealReportEdit ? true: undefined;
          }
        }
        this.fileStatus = "processed"
        this.allowSubmit = true
      }
    },
    checkHeaders(headers) {
      const requiredHeaders = [...this.otherIntermediaryFields,'bancs_intermediary_cif'];
      for (const requiredHeader of requiredHeaders) {
        if (!headers.includes(requiredHeader)) {
          this.fileStatus = 'missingHeaders';
          this.missingHeaders.push(requiredHeader);
        }
      }
    },
    processLine(line,headers,_index) {
      const lineArray = this.stripQuotes(line.split(","));
      const bancsCif = lineArray[headers.indexOf('bancs_intermediary_cif')]?.toString().trim();
      const allCifs = [bancsCif]
      let conflictInCifs = false;
      for (const otherCifField of this.otherIntermediaryFields) {
        const otherCif = lineArray[headers.indexOf(otherCifField)]?.toString().trim();
        allCifs.push(otherCif)
        if (bancsCif !== otherCif) {
          conflictInCifs = true
        }
      }
      if (conflictInCifs) {
        for (const conflictCif of allCifs) {
          if (!this.errorCifs.includes(conflictCif)) {
          this.errorCifs.push(conflictCif)
        }
        }
      }
    },
    stripQuotes(lineArray) {
      const returnArray = [];
      for (const fieldItem of lineArray)  {
        if (fieldItem[0] === `"` && fieldItem[fieldItem.length - 1] === `"`)  {
          returnArray.push(fieldItem.substring(1,fieldItem.length - 1))
        } else {
          returnArray.push(fieldItem)
        }
      }
      return returnArray
    }
  },
};
</script>
<style>
.lfx-inter-report-view-table-container {
  position: relative;
  min-height: 640px;
}

#lfx-inter-report-view-table {
  min-width: 100%;
}

capitec-modal.lfx-inter-report {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
</style>
