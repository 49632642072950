<template>
  <div v-if="view === 'edit' || view === 'create'" class="ldb-field-container__option">
    <capitec-select
      v-if="displayAs === 'dropdown'"
      id-field="id"
      display-field="name"
      :label="fieldLabel"
      :value="selectedValue"
      :items="options"
      :error="mandatoryError"
      :disabled="field.enableGuards === false"
      @value-change="on_change"
    >
    </capitec-select>

    <capitec-radio-group
      v-else-if="displayAs === 'radio'"
      layout="horizontal"
      :label="fieldLabel"
      :value="selectedValue"
    >
      <capitec-label :label="fieldLabel" type="subtitle"> </capitec-label>

      <capitec-radio
        v-for="item in options"
        :key="item.id"
        :data="item.id"
        :label="item.label || item.name"
        :checked="item.id == value"
        :disabled="item.enableGuards === false"
        @value-change="onRadioValueChange(item, $event)"
      ></capitec-radio>
    </capitec-radio-group>
  </div>
  <LDBItemViewField
    v-else-if="view === 'item'"
    :view="view"
    :field="field"
    :displayValue="displayValue"
    :definition="definition"
    :record="record"
  />
  <LDBListViewField
    v-else-if="view === 'list'"
    :view="view"
    :field="field"
    :displayValue="value || ''"
    :definition="definition"
    :record="record"
  />
  <!-- {{displayValue}} -->
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBItemViewField from './LDBItemViewField.vue';
import LDBListViewField from './LDBListViewField.vue';

import { evaluate_guard } from '@/db-interface/db_interface_guard';

import { ldb_field_mixin } from './ldb_field_mixin.js';

export default {
  mixins: [ldb_field_mixin],
  components: { LDBItemViewField, LDBListViewField },
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    displayAs() {
      return this?.field?.displayType?.display === undefined ? 'dropdown' : this.field.displayType.display;
    },
    options() {
      let res = [];
      let self = this;
      if (this.field?.datatype?.option?.options) {
        res = this.field.datatype.option.options.filter(
          (o) => { 
            let guards = o.guards;
            if (typeof(guards) === 'function') {
              guards = guards(this.record)
            }
            return evaluate_guard(guards, self.record) 
          }
          );
      } else if (this.field?.datatype?.foreignKey?.collection === 'fk_intermediary_commissions') {
        // TODO: this could be WAY more generic - avr 2022-11-21
        const opts = this.record?.[this.field.datatype.foreignKey.collection];
        for (const id in opts) {
          let opt = opts[id];
          if (opt.status === 'active') {
            res.push({ id: id, label: opt.label, name: opt.name });
          }
        }
      }
      return res;
    },
    displayValue() {
      return this.selectedValue === undefined ? '' : this.selectedValue.name;
    },
    mandatoryError() {
      if (!this.value && this.field.mandatory && this.formSubmitted) {
        return 'Required field!';
      }
    },
    selectedValue() {
      let res = undefined;
      for (let o = 0; o < this.options.length; o++) {
        if (this.value === this.options[o].id) {
          res = this.options[o];
          break;
        }
      }
      return res;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    on_change(evt) {
      this.$emit('change', evt.detail.new.id);
    },
    onRadioValueChange(item, evt) {
      if (evt.detail.new === true) {
        this.$emit('change', item.id);
      }
    },
  },
};
</script>

<style>
.ldb-field-container__option {
  /* max-width: 540px; */
  flex: 1;
  /* min-width: 700px; */
}

/* http://localhost:8080/client/1000001/application/19/wizard/client_cif/34/oc */
</style>
