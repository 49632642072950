<template>
  <LFXContent
    :titleBar="titleBar"
    :show_tabs="showTabs"
    :tabs="tabs"
    :show_actions="showActions"
    :form_layout="formLayout"
    :show_label="showLabel"
    :label="contentLabel"
    :show_sidebar="false"
    @tab-select="onTabSelect"
  >
    <template #actions>
      <LDBButton
        v-for="button in actionBarButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :type="button.type"
        @click="button.clickEvent"
      ></LDBButton
    ></template>

    <template #content>
      <router-view @on-search-change="onSearchChange"></router-view>
    </template>
  </LFXContent>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXContent from '@/components/layout/LfxContent.vue';

export default {
  name: 'LfxSearchViews',
  components: { LFXContent },
  props: {},
  data() {
    return {
      dataDownLoadable:false,
      downloadAction:'',
      searchCriteria:undefined
    };
  },
  created() {
    if (this.$route.name === 'search') {
      this.$router.replace({ name: 'searchDeals' });
    }
  },
  mounted() {},
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'search') {
      next(false);
    } else {
      next();
    }
  },

  computed: {
    ...mapGetters([]),
    ...mapState(['authUser']),

    // ----- Title Bar -----
    actionBarButtons() {
      return [{
        id:'search-download-button',
        name: 'download',
        label: `Download`,
        type: 'primary',
        action: this.downloadAction,
        guards: true,
        actionParams: {additionalSelector:this.searchCriteria},
      }];
    },
    titleBar() {
      const titleBar = {
        label: 'Search',
      };
      return titleBar;
    },

    // ----- Tabs -----

    showTabs() {
      return true;
    },
    tabs() {
      let tabs = [
        { index: 1, label: 'Deal Search', routeName: 'searchDeals', guards: true },
        { index: 2, label: 'Payment Search', routeName: 'searchPayments', guards: true },
        { index: 3, label: 'SWIFT Search', routeName: 'searchSwifts', guards: true },
        {
          index: 4,
          label: 'Client Search',
          routeName: 'searchClients',
          guards: this.authUser.accountLevel !== 'client',
        },
        {
          index: 5,
          label: 'Client Account Search',
          routeName: 'searchClientAccounts',
          guards: this.authUser.accountLevel !== 'client',
        },
        { index: 5, label: 'User Search', routeName: 'searchUsers', guards: true },
      ];
      return tabs.filter((tab) => tab.guards);
    },
    selectedTabName() {
      const tabRouteDepth = this.componentRouteDepth + 1;
      return this.$route.fullPath.split('/')[tabRouteDepth];
    },
    selectedTabIndex() {
      let tabNames = this.tabs.map((t) => t.name);
      return tabNames.indexOf(this.selectedTabName);
    },
    firstAvailableTab() {
      return this.tabs[0];
    },
    areTabsAvailable() {
      if (this.tabs.length > 0) {
        return true;
      } else return false;
    },

    // ----- Action Bar -----

    showActions() {
      return this.dataDownLoadable;
    },

    // ----- Layout -----

    formLayout() {
      return this.$route.meta?.layout === 'list' ? false : true;
    },

    // ----- Content Label -----

    showLabel() {
      return this.contentLabel ? true : false;
    },
    contentLabel() {
      return this.$route.meta?.label ? this.$route.meta.label : 'TODO';
    },

    // ----- Guards -----

    canViewInfo() {
      // TODO
      return true;
    },
    canViewPermissions() {
      // TODO
      return true;
    },
    canViewSettings() {
      // TODO
      return true;
    },
    canViewPreferences() {
      // TODO
      return true;
    },
    canViewLogs() {
      // TODO
      return true;
    },

    // ----- Record -----

    // recordName() {
    //   // return this.$route.params.record;
    //   return 'user';
    // },
    // recordId() {
    //   return this.$route.params.userId;
    // },
    // record() {
    //   return this.$store.state?.[this.recordName]?.[this.recordId];
    // },
    // definition() {
    //   return getDefinition(this.recordName);
    // },

    // ----- Route -----

    componentRoutePath() {
      return 'search';
    },

    componentRouteDepth() {
      let path = this.$route.fullPath;
      let pathSections = path.split('/');
      const componentRouteDepth = pathSections.indexOf(this.componentRoutePath);
      return componentRouteDepth;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Tabs -----
    onSearchChange(onSearchChange) {
      this.dataDownLoadable = (onSearchChange.downloadAction !== false);
      this.downloadAction = onSearchChange.downloadAction;
      this.searchCriteria = onSearchChange.searchCriteria;
    },
    tabName(index) {
      return this.tabs[index].name;
    },
    onTitleBarButtonClick(button) {
      this.$router.push({ name: button.routeName });
    },
    onTabClick(evt) {
      let tabIndex = evt.detail.selectedIndex;
      let tabName = this.tabName(tabIndex);
      let path = this.$route.fullPath;
      let pathSections = path.split('/');
      const tabRouteDepth = this.componentRouteDepth + 1;
      pathSections[tabRouteDepth] = tabName;
      let newPathSections = [];
      for ( let i = 0; i <= tabRouteDepth; i++ ) {
        newPathSections.push(pathSections[i]);
      }
      let newPath = newPathSections.join('/');
      this.$router.push(newPath);
      // added logic for handling sub-tabs - avr 2021-10-08
    },
    onTabSelect(tab) {
      this.dataDownLoadable = false;
      this.$router.push({ name: tab.routeName });
    },
  },
};
</script>

<style scoped>
/* capitec-title-bar {
  background-color: rgb(127, 197, 255);
} */
</style>
