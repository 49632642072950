<template>
  <div>
    <LFXListView :titleBar="titleBar" :definition="definition" :records="records" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXListView from '@/views/general/LFXListView.vue';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxApplicationDocumentTypesList',
  components: { LFXListView },
  props: {},
  data() {
    return {
      titleBar: {
        label: 'Application Document Types',
        type: 'default',
        buttons: {},
      },
    };
  },
  created() {
    if (!this.$store.state.config[1].applicationDocumentType) {
      doPermissionAction({},'getClientApplicationDocumentTypes',{configId:1})
    }
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    records() {
      return this.$store.state.config[1].applicationDocumentType || {};
    },
    definition() {
      return getDefinition('applicationDocumentType');
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>

<style>
/* div.container{  } */
</style>
