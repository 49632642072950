<template>
  <LDBModal :result="headerType" size="large" :header="header">
    <template #body>
      <div>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="definition"
          :record="record"
          @change="on_change"
        />
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in buttons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import lodash from 'lodash';
const merge = lodash.merge;

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxDisableClientTrading',
  components: { LDBField },
  props: {},
  emits: {},
  created() {},
  mounted() {},
  beforeUnmount() {
    this.record.revert();
  },
  data() {
  },

  computed: {
    ...mapGetters([]),
    ...mapState(['account']),
    header() {
      return `Disable Client Trading: ${this.record.name}`
    },
    headerType() {
      return 'info';
    },

    buttons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelDisableClientTrading',
          label: 'Cancel',
          type: '',
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'moveAccount',
          label: 'Disable Trading',
          type: 'primary',
          action: 'disableClientTrading',
          actionParams: this.record,
          params: { accountId: this.recordId },
          clickEvent(response) {
            if (response.status === 'success') {
              self.$router.go(-1);
            }
          },
        },
      ];
      return allButtons;
    },

    // ----- Definition -----

    definition() {
      return getDefinition(this.accountLevel);
    },

    // ----- Record -----

    recordName() {
      return 'account';
    },
    recordId() {
      return +this.$route.params.accountId;
    },
    record() {
      let rec = this.$store.state?.[this.recordName]?.[this.recordId];
      return rec;
    },

    accountLevel() {
      return this.record?.accountLevel;
    },

    // ----- Fields -----

    fields() {
      return this.definition.fields;
    },
    includedFields() {return ['tradingDisabledReason']},
    viewFields() {
      let res = [];
      for (let k in this.fields) {
        let field = this.fields[k];
        if (this.includedFields.includes(field.name)) {
          field.fieldname = k;
          res.push(this.overrideField(field));
        }
      }
      return res;
    },
  },

  methods: {
    //
    // ----- Fields ----

    on_change({ field, value }) {
      this.record[field] = value;
    },
    overrideField(field) {
      return field;
    },
  },
};
</script>
