import VueStore from 'vue-class-store';

import { LdbDbObject, LdbDefinition } from '@/definitions/LdbInterfaces';
import { LfxApplicationCifInt, LfxApplicationCifDef } from '@/definitions/LfxApplicationCifDef';
import { LfxModel, LfxDefinition } from './LfxModel';

//@ts-ignore
import { http_patch, http_get, http_put } from '@/lfx_rest';

@VueStore
//@ts-ignore
class LfxApplicationCifMdl extends LfxDefinition {
  //@ts-ignore
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }

  get lfx_account_applications() {
    let res = {};
    //@ts-ignore
    res[this.applictionId] = { id: this.applicationId, name: 'TODO' };
    return res;
  }
}

@VueStore
//@ts-ignore
export class LfxApplicationCif extends LfxModel {
  //@ts-ignore
  constructor(data, state) {
    super({ definition_name: 'application_cif', ...data }, state);
    //@ts-ignore
  }
  get link() {
    // @ts-expect-error
    return `/client/${this.accountId}/application/${this.applicationId}`;
  }
  detailFieldLabel(field: string) {
    // @ts-expect-error
    for (const fieldRecord of this[this.cifDetailsKey][this.cifDetailsId].definition.fields) {
      if (fieldRecord.field === field) {
        return fieldRecord.label;
      }
    }
    return field;
  }
  get owningClient() {
    // @ts-expect-error
    return this.store.state.account[this.accountId];
  }
  get owningApplication() {
    if (this.owningClient) {
      // @ts-expect-error
      return this.owningClient.application?.[this.applicationId];
    }
    return undefined
  }
  get OutstandingFields() {
    if (this.outstanding_fields.length > 0) {
      var returnString = '';
      for (const field of this.outstanding_fields) {
        returnString += ` ${this.detailFieldLabel(field)}, `;
      }
      return returnString;
    } else {
      return null;
    }
  }
  get outstanding_fields() {
    // @ts-expect-error
    return this[this.cifDetailsKey]?.[this.cifDetailsId]?.outstanding_fields || [];
  }
  get is_submittable() {
    // @ts-expect-error
    return this[this.cifDetailsKey]?.[this.cifDetailsId]?.is_submittable || false;
  }
  get info_changed_fields() {
    // @ts-expect-error
    return this[this.cifDetailsKey][this.cifDetailsId].changed_fields;
  }

  get cifDetailsKey() {
    // return `application_cif_${this.accountType}` as keyof this;

    // @ts-ignore
    return `application_cif_${this.accountType}`;
  }
  get cifDetailsId() {
    let cifDetailsKey = this.cifDetailsKey;
    // @ts-expect-error
    let obj = this[cifDetailsKey] || {};
    let key = Object.keys(obj)[0];
    return +key;
  }
  get cifInfo() {
    // @ts-expect-error
    if (this.application_cif_entity) {
      // @ts-expect-error
      if (Object.keys(this.application_cif_entity).length !== 0) {
        // @ts-expect-error
        return this.application_cif_entity[Object.keys(this.application_cif_entity)[0]]
      }
    }
    // @ts-expect-error
    if (this.application_cif_individual) {
      // @ts-expect-error
      if (Object.keys(this.application_cif_individual).length !== 0) {
        // @ts-expect-error
        return this.application_cif_individual[Object.keys(this.application_cif_individual)[0]]
      }
    }
    // @ts-expect-error
    if (this.EntityInfo) {
      // @ts-expect-error
      return this.EntityInfo;
    }
    // @ts-expect-error
    return this.IndividualInfo;
  }

  get displayName() {
    // @ts-expect-error
    return this.accountType === 'entity'
      ? // @ts-expect-error
        this[this.cifDetailsKey][this.cifDetailsId].name
      : // @ts-expect-error
        `${this[this.cifDetailsKey][this.cifDetailsId].surname}, ${
          // @ts-expect-error
          this[this.cifDetailsKey][this.cifDetailsId].firstName
        }`;
  }
  get craEnabled() {
    //@ts-expect-error
    return !!this.store.state.systemSettings?.featureEnable?.craOnboarding
  }
  get craApprovalHasExpired() {
    // @ts-expect-error
    if (this.cifNumber) {
      return false
    }
    // @ts-expect-error
    const expireDays:number = this.store.state.systemSettings?.onboarding?.craApprovalTimeout || 30
    // @ts-expect-error
    const craApprovedAtDate = new Date(this.craApprovedAt)
    const incedApprovedDate = new Date(craApprovedAtDate.setDate(craApprovedAtDate.getDate() + expireDays));
    // @ts-expect-error
    return this.craStatus === 'approved' && incedApprovedDate < new Date()
  }
  get consolidatedStatus() {
    // console.log({// @ts-expect-error
    //   status:this.status,
    //   is_submittable:this.is_submittable,// @ts-expect-error
    //   craStatus:this.craStatus,
    //   craApprovalHasExpired:this.craApprovalHasExpired,
    //   applicationPortfolioStatus:this.owningApplication.applicationPortfolioStatus,
    //   craEnabled:this.craEnabled,// @ts-expect-error
    //   integrationStatus:this.integrationStatus
    // })
    // @ts-expect-error
    switch (this.status) {
      case 'new': 
        if(this.is_submittable) {
          // @ts-expect-error
          switch (this.craStatus) {
            case 'requested':
            case 'complianceReview':
              return 'craInProgress'
            case 'declined':
              return 'craDeclined'
            case 'error':
              return 'craError'
            case 'eddRequested': 
              return 'craEddRequested'
            case 'eddSubmitted':
              return 'craEddSubmitted'
            case 'approved':
              if (this.craApprovalHasExpired) {
                return 'craExpired'
              }
            case 'none':
            case null:
            case undefined:
            default:
              break
          }
          // @ts-expect-error
          if (['signed','notRequired'].includes(this.owningApplication.applicationPortfolioStatus) || (this.craEnabled && this.craStatus !== 'approved')) {
            return 'readyToSubmit'
          } else {
            return 'awaitingPortfolio'
          }
        } else {
          return 'informationOutstanding'
        };
      case 'inProgress':
        // @ts-expect-error
        return this.integrationStatus
    }
    // @ts-expect-error
    return this.status
  }
  get fullCifResidencyDetailsAreCaptured() {
    // @ts-expect-error
    if (this.cifType === 'associated') {
      return true
    }
    // @ts-expect-error
    if (this.accountType === 'entity') {
      return true
    } else {
      // @ts-expect-error
      return !!this[this.cifDetailsKey][this.cifDetailsId]?.country && !!this[this.cifDetailsKey][this.cifDetailsId]?.residentialStatus
    }
  }
}

//@ts-ignore
export function Definition(context, name: string) {
  return new LfxApplicationCifMdl(LfxApplicationCifDef, LfxApplicationCif, context, name, {
    fields: {
      cifNumber: { views: { releaseCifApplication: true } },
      rejectedReason: { views: { rejectOrCancelApplication: true, item: true } },
    },
  });
}
