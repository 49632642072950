import VueStore from 'vue-class-store';

import { LdbDbObject, LdbDefinition } from '@/definitions/LdbInterfaces';
import {
  LfxApplicationAccountInt,
  LfxApplicationAccountDef,
  requiredFields,
} from '@/definitions/LfxApplicationAccountDef';
import { LfxModel, LfxDefinition } from './LfxModel';

//@ts-ignore
import { http_patch, http_get, http_put } from '@/lfx_rest';
import { residentalAccountOptions } from '@/sharedUtils/LdbSharedUtils';

@VueStore
//@ts-ignore
class LfxApplicationAccountMdl extends LfxDefinition {
  //@ts-ignore
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
  //@ts-ignore
}

@VueStore
//@ts-ignore
export class LfxApplicationAccount extends LfxModel {
  //@ts-ignore
  constructor(data, state) {
    super({ definition_name: 'application_account', ...data }, state);
    //@ts-ignore
  }
  get link() {
    // @ts-expect-error
    return `/client/${this.accountId}/application/${this.applicationId}`;
  }

  get fk_intermediary_commissions() {
    //@ts-ignore
    return this.store.getters.config.commissionLevel;
  }
  get required_fields() {
    return requiredFields(this as LfxApplicationAccountInt) as any as (keyof this)[];
  }
  detailFieldLabel(field: string) {
    // @ts-expect-error
    for (const fieldRecord of this.definition.fields) {
      if (fieldRecord.field === field) {
        return fieldRecord.label;
      }
    }
    return field;
  }

  get OutstandingFields() {
    if (this.outstanding_fields.length > 0) {
      var returnString = '';
      for (const field of this.outstanding_fields as string[]) {
        returnString += ` ${this.detailFieldLabel(field)}, `;
      }
      return returnString;
    } else {
      return null;
    }
  }

  get outstanding_fields() {
    const outstandingFields = [];
    for (const field of this.required_fields!) {
      if (this[field] === null || this[field] === undefined) {
        outstandingFields.push(field);
      }
    }
    return outstandingFields;
  }

  get is_submittable() {
    return this.outstanding_fields.length === 0 && !this.has_validation_errors;
  }

  get owningClient() {
    // @ts-expect-error
    return this.store.state.account[this.accountId];
  }
  get owningApplication() {
    if (this.owningClient) {
      // @ts-expect-error
      return this.owningClient.application?.[this.applicationId];
    }
    return undefined
  }
  get residentOptions() {
    var accountType = undefined;
    var country = undefined;
    var residentialStatus = undefined;
    var emigratingBlock =undefined;
    const application = this.owningApplication
    if (application) {
      accountType = this.owningClient.accountType;;
      if (application) {
        const applicationCif = application.application_cif[application.fullCifId];
        if (applicationCif) {
          var infoObjects = undefined;
          if (accountType === 'entity') {
            infoObjects = applicationCif.application_cif_entity;
          } else {
            infoObjects = applicationCif.application_cif_individual;
          }
          if (infoObjects) {
            country = infoObjects[Object.keys(infoObjects)[0]].country;
            emigratingBlock = infoObjects[Object.keys(infoObjects)[0]].emigratingBlock;
            residentialStatus = infoObjects[Object.keys(infoObjects)[0]].residentialStatus;
          }
        }
      }
    }
    if (accountType && country) {
      return residentalAccountOptions(accountType, country, residentialStatus, emigratingBlock);
    } else {
      return undefined;
    }
  }
  get mayOpenResAccount() {
    if (this.residentOptions) {
      return this.residentOptions.allowTrue;
    }
  }
  get mayOpenNonResAccount() {
    if (this.residentOptions) {
      return this.residentOptions.allowFalse;
    }
  }
  get consolidatedStatus() {
    // @ts-expect-error
    switch (this.status) {
      case 'new': 
        if(this.is_submittable) {
          if (['signed','notRequired'].includes(this.owningApplication.applicationPortfolioStatus)) {
            return 'readyToSubmit'
          } else {
            return 'awaitingPortfolio'
          }
        } else {
          return 'informationOutstanding'
        };

      case 'inProgress':
        // @ts-expect-error
        return this.integrationStatus
    }
    // @ts-expect-error
    return this.status
  }
}

//@ts-ignore
export function Definition(context, name: string) {
  return new LfxApplicationAccountMdl(LfxApplicationAccountDef, LfxApplicationAccount, context, name, {
    fields: {
      rejectedReason: { views: { rejectOrCancelApplication: true } },
      accountNumber: { views: { releaseAccountApplication: true } },
    },
  });
}
