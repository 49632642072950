//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2022 LandoByte (Pty) Ltd.
//  File        : LfxPaymentPortfolioDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Payment Portfolio record for LandoByte TS systems
//  Created     : 12 February 2022 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import { intermediaryFieldViewGuard } from '../sharedUtils/LdbDefinitionUtils';
import type { LdbDefinition } from './LdbInterfaces';
import { isBankUser } from './LfxAccountDef';

//----------------------------------------------------------------------------------------------------------------------

export type LfxPaymentPortfolioStatus = 'inProgress' | 'rejected' | 'completed' | 'superseded';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxPaymentPortfolioInt {
  id?: number;
  uuid?: string;
  transactionFileId?: number;
  paymentId?: number;
  accountId?: number;
  intermediaryId?: number;
  branchId?: number;
  reference?: string;

  integrationId?: string;
  rejectionReason?: string;
  archivedPortflioS3Key?: string;

  submittedAt?: Date;
  submittedBy?: number;
  readyForSigningAt?: Date;
  signedAt?: Date;
  completedAt?: Date;
  rejectedAt?: Date;
  supersededAt?: Date;

  status?: LfxPaymentPortfolioStatus;
  isCurrent?: boolean;

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxPaymentPortfolioDef: LdbDefinition = {
  title: 'Portfolio',
  table: 'LfxPaymentPortfolio',
  collectionPath: '/payment/{{paymentId}}/portfolio',
  pagingType: 'backEnd',
  view: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
  indexes: ['paymentId', 'accountId', 'branchId'],
  fields: {
    id: {
      label: 'Reference',
      datatype: { id: { descriptionField: 'reference' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    uuid: {
      label: 'UUID',
      datatype: 'uuid',
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    transactionFileId: {
      label: 'Transaction File',
      datatype: {
        foreignKey: {
          collection: 'fk_transaction_files',
          linkTable: 'LfxTransactionFile',
          linkField: 'id',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    paymentId: {
      label: 'Payment',
      datatype: {
        foreignKey: {
          collection: 'fk_payments',
          linkTable: 'LfxPayment',
          linkField: 'id',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },

    accountId: {
      label: 'Account',
      datatype: {
        foreignKey: {
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
          collection: 'fk_accounts',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    intermediaryId: {
      label: 'Intermediary',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediaries',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: intermediaryFieldViewGuard,
        list: false,
        delete: false,
        csv: false,
      },
    },
    branchId: {
      label: 'Branch',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediary_branches',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },

    reference: {
      label: 'Reference',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    integrationId: {
      label: 'Integration ID',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
    },
    rejectionReason: {
      label: 'Rejection Reason',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      order: { default: undefined, list: 1000 }, // FIXME doesn't seem to do anything
    },

    archivedPortflioS3Key: {
      label: 'Archived portfolio',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: isBankUser, list: isBankUser, delete: false, csv: false },
      group: 'integration',
    },
    portfolioButtons: {
      label: 'Portfolio',
      datatype: 'text',
      displayType: {renderLink: true}, 
      default: null,
      mandatory: false,
      allowNullValues: true,
      property: {source: 'frontend',read:true,write:false, sort: 'none'},
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
    },
    submittedAt: {
      label: 'Submitted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    submittedBy: {
      label: 'Submitted By',
      datatype: {
        foreignKey: {
          collection: 'fk_users',

          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    readyForSigningAt: {
      label: 'Ready for Signing At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    signedAt: {
      label: 'Signed At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    completedAt: {
      label: 'Completed At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    rejectedAt: {
      label: 'Rejected At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    supersededAt: {
      label: 'Superseded At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'inProgress', name: 'In Progress', tagType: 'info' },
            { id: 'rejected', name: 'Rejected', tagType: 'error' },
            { id: 'completed', name: 'Completed', tagType: 'success' },
            { id: 'superseded', name: 'Superseded', tagType: 'cancelled' },
          ],
        },
      },
      default: 'active',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    isCurrent: {
      label: 'Is Current',
      datatype: 'boolean',
      default: false,
      mandatory: true,
      allowNullValues: false,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },

    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
  },
};
