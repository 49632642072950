<template>
  <div v-if="view === 'edit' || view === 'create'" class="ldb-field-container__option">
    <capitec-select
      :label="fieldLabel"
      :value="selectedValue"
      :items="options"
      id-field="id"
      :display-field="displayField"
      :disabled="field.enableGuards === false"
      @value-change="on_change"
      :searchable="countJustifiesSearchField"
    >
    </capitec-select>
  </div>

  <LDBItemViewField
    v-if="view === 'item'"
    :view="view"
    :field="field"
    :displayValue="displayValue"
    :definition="definition"
    :record="record"
    :slotMode="true"
  >
    <router-link
      v-if="renderLink"
      class="capitec-hyperlink"
      :to="`${recordLink}`"
      tag="capitec-hyperlink"
      :label="displayValue"
    >
      {{ displayValue }}
    </router-link>
    <capitec-label v-else class="capitec-item-view-field-value" :label="`${displayValue}`" type="strong">
    </capitec-label>
  </LDBItemViewField>

  <LDBListViewField
    v-if="view === 'list'"
    :view="view"
    :field="field"
    :displayValue="displayValue"
    :definition="definition"
    :record="record"
    :slotMode="true"
    :renderLink="renderLink"
  >
  </LDBListViewField>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBItemViewField from './LDBItemViewField.vue';
import LDBListViewField from './LDBListViewField.vue';
import { ldb_field_mixin } from './ldb_field_mixin.js';
import { evaluate_guard, normalize_guards } from '@/db-interface/db_interface_guard';
import { getDefinitionByTable } from '@/db-interface';

export default {
  mixins: [ldb_field_mixin],
  components: {
    LDBItemViewField,
    LDBListViewField,
  },
  props: {
    overrideOptions: {
      type: [Object, Array],
      required: false,
      default() {
        return undefined;
      },
    },
    overrideOptionsSearchBarNumber: {
      type: [Number, Boolean],
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  created() {
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    countJustifiesSearchField() {
      return this.options.length >= 10 || this.overrideOptionsSearchBarNumber
        ? this.options.length >= this.overrideOptionsSearchBarNumber
        : false;
    },
    foreignKeyTable() {
      return this.field.datatype.foreignKey.linkTable
        .substr(3, this.field.datatype.foreignKey.linkTable.length)
        .toLowerCase();
    },
    foreignKeyTableName() {
      return this.field.datatype.foreignKey.linkTable;
    },
    renderLink() {
      if (!this.selectedValue) {
        return false;
      }
      if (this.selectedValue.displayFKLink === undefined) {
        return true;
      }
      return this.selectedValue.displayFKLink;
    },
    recordLink() {
      let res =
        this.selectedValue?.link === undefined
          ? `/${this.foreignKeyTable}/${this.selectedValue?.id}`
          : `${this.selectedValue?.link}`;
      return res;
    },
    allOptions() {
      let allForeignRecords = {};
      if (this.overrideOptions !== undefined) {
        allForeignRecords = this.overrideOptions;
      } else {
        let foreignKeyCollection = this.field.datatype.foreignKey.collection;
        allForeignRecords = this.record[foreignKeyCollection];
      }
      return allForeignRecords;
    },
    options() {
      let res = [];
      let allForeignRecords = this.allOptions;
      let guards = normalize_guards(
        this.field.datatype.foreignKey.guards === undefined ? true : this.field.datatype.foreignKey.guards,
        this.record
      );
      let self = this;
      if (allForeignRecords !== undefined) {
        if (Array.isArray(allForeignRecords)) {
          res = allForeignRecords
            .filter((r) => evaluate_guard(guards, r))
            .map(function (r) {
              return { id: r.id, name: r[self.displayField], [self.displayField]: r[self.displayField] };
            })
            .filter((i) => i.name);
        } else {
          res = Object.values(allForeignRecords)
            .filter((r) => evaluate_guard(guards, r))
            .map(function (r) {
              return { id: r.id, name: r[self.displayField], [self.displayField]: r[self.displayField] };
            })
            .filter((i) => i.name);
        }
      }

      return res;
    },
    displayField() {
      return this.field.datatype.foreignKey.displayField || 'name';
    },
    displayValue() {
      if (this.selectedValue === undefined || (!this.selectedValue[this.displayValue] && !this.selectedValue.definition)) {
        this.tryToLoadItem();
        return this.value ? this.value.toString() : '';
      } else {
        return this.selectedValue[this.displayField] || (this.value ? this.value.toString() : '');
      }
    },
    recordSelectors() {
      let res = {};
      if (this.field.datatype.foreignKey.additionalSelectors === undefined) {
        res = {};
      } else if (typeof this.field.datatype.foreignKey.additionalSelectors === 'function') {
        res = this.field.datatype.foreignKey.additionalSelectors(this.record);
      } else {
        res = this.field.datatype.foreignKey.additionalSelectors;
      }
      if (res.where !== undefined) {
        return res.where;
      } else {
        return res;
      }
    },
    selectedValue() {
      let res = undefined;
      if (Array.isArray(this.allOptions) && this.value) {
        // console.log('allOptions', this.allOptions);
        res = this.allOptions.find((opt) => opt.id == this.value);
        // console.log('opt', opt);
        // res = opt.id;
      } else if (this.allOptions !== undefined && this.allOptions[this.value] !== undefined) {
        res = this.allOptions[this.value];
      }
      // console.log('res', res);
      return res;
    },
    foreignKeyDefinition() {
      return getDefinitionByTable(this.foreignKeyTableName);
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    on_change(evt) {
      this.$emit('change', evt.detail.new.id);
    },
    async tryToLoadItem() {
      if (!this.value) {
        return;
      }
      if (this.record['getFk' + this.field.name]) {
        console.log('Trying to load ' + this.field.name + ' from ' + this.record.Table + ' id ' + this.record.id);
        this.record['getFk' + this.field.name]();
      } else if (this.foreignKeyDefinition?.retrieveItem) {
        console.log('Trying to load ' + this.foreignKeyTableName + ' id ' + this.value);
        this.foreignKeyDefinition?.retrieveItem(this.value);
      }
    },
  },
};
</script>

<style>
.capitec-hyperlink {
  white-space: nowrap;
}
.capitec-item-view-field-value {
  flex: 1;
  white-space: nowrap !important;
}
</style>
