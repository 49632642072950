<template>
  <LDBModal result="info" :header="header" size="x-large">
    <template #body>
      <LDBItemView :record="currencyPairRecord" :view="view" action="updateCurrencyPair" :params="submitParams" />
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

export default {
  name: 'LfxConfigCurrencyPairDetails',
  components: { LDBItemView, LDBLoader },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters(['config']),
    ...mapState([]),

    header() {
      return `Edit Currency Pair: ${this.currencyPairRecord?.name}`;
    },

    view() {
      return this.$route.meta?.modal?.view || 'item';
    },
    submitParams() {
      return {
        configId: 1,
        currencyPairId: this.currencyPairId,
      };
    },

    // ----- Currency Pair Record -----

    currencyPairId() {
      return this.$route.params?.currencyPairId;
    },
    currencyPairRecord() {
      return this.config?.currencyPair?.[this.currencyPairId];
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
