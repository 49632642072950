//@ts-nocheck

import { LfxBulkFeeBatchInt, LfxBulkFeeBatchDef } from '@/definitions/LfxBulkFeeBatchDef';

import { LfxModel, LfxDefinition } from './LfxModel';

class LfxBulkFeeBatchMdl extends LfxDefinition {
  constructor(def, dbclass, context, name,config) {
    super(def, dbclass, context, name,config);
  }
}

export class LfxBulkFeeBatch extends LfxModel {
  constructor(data, state) {
    super({definition_name:'bulkFeeBatch',...data}, state);
  }
  get link() {
    // @ts-expect-error
    return `/account/${this.accountId}/bulkFee/${this.id}`;
  }
}

export function Definition(context, name: string) {
  return new LfxBulkFeeBatchMdl(LfxBulkFeeBatchDef, LfxBulkFeeBatch, context, name,{});
}
