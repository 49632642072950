<template>
  <LDBModal result="info" :header="header"  size="x-large">
    <template #body>
      <div v-if="mounted">
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="item"
          :fieldname="field.name"
          :definition="definition"
          :record="swiftRecord"
          @change="on_change"
        />
        <br/>
        <h3 v-if="clientStatus === 'retrieving'">Retriving clients for CIF {{this.clientCif}}...</h3>
        <h3 v-else-if="clientStatus === 'error'">Error retriving clients for CIF {{this.clientCif}} - {{clientError}}</h3>
        <template v-else-if="clientStatus==='retrieved'">
          <template v-if="foundClients.length > 0">
            <LDBField
              v-for="(field, fieldname) in formFields"
              :key="fieldname"
              :field="field"
              view="edit"
              :fieldname="field.name"
              :definition="definition"
              :record="submitRecord"
              :guards="field.guards"
              @change="on_change"
            />
          </template>
          <h3 v-else>No clients found for CIF {{this.clientCif}}</h3>
        </template>
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
        @on-api-success="onApiSuccess"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBField from '@/landobyte_vue/LDBField.vue';
import { doPermissionAction } from '@/lfx_rest/lfx_action';
import { getOptionLabel } from '@/sharedUtils/LdbDefinitionUtils';

export default {
  name: 'LfxSwiftAssignToClient',
  components: { LDBField },
  emits: ['on-api-success'],
  props: {},
  data() {
    return {
      submitRecord: {clientId:undefined},
      clientStatus: 'none',
      clientError: undefined,
      foundClients: [],
      mounted: true,
    };
  },
  created() {},
  mounted() {
    this.retrieveClients()
  },
  beforeUnmount() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account','swift']),
    header() {
      return `Assign SWIFT to Client`;
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

        {
          name: 'assignSwiftToClient',
          label: 'Assign SWIFT to Client',
          type: 'primary',
          action: 'assignSwiftToClient',
          params: {
            swiftId: this.swiftRecord.id,
          },
          actionParams: this.submitRecord,
          guards: true,
          clickEvent(response) {
            if (response.status === 'success') {
              self.$router.go(-1);
            }
          },
        },
      ];
      return allButtons !== undefined ? allButtons : [];
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : '/';
    },

    // ----- Definition -----

    definition() {
      return getDefinition('inward_swift');
    },
    accountDefinition() {
      return getDefinition('account');
    },

    // ----- Record -----
    recordName() {
      return 'swift';
    },
    swiftId() {
      return +this.$route.params.swiftId;
    },
    swiftRecord() {
      let rec = this.swift[this.swiftId];
      return rec;
    },

    // ----- Fields -----

    swiftFields() {
      return this.definition.fields;
    },
    viewFieldNames() {
      return [
        'swiftNumber',
        'currencyId',
        'amount',
        'messageCifNumber',
        'messageAccountNumber'
      ]
    },
    viewFields() {
      const res = [];
      for (const fieldName of this.viewFieldNames) {
        const fields = this.swiftFields.filter((field) => field.field === fieldName);
        if (fields.length > 0) {
          res.push(fields[0]);
        }
      }
      return res;
    },

    formFields() {
      let res = [
      {
        name: 'clientId',
        label: 'Select Client to Assign SWIFT',
        datatype: {
          option: {
            optionType: 'number',
            options: this.clientOptions,
          },
        },
        default: null,
        displayType: {
          datatype: 'option',
          display: 'radio',
        },
      }
      ];
      return res;
    },
    clientCif() {
      return this.swiftRecord.messageCifNumber
    },
    clientOptions() {
      const res = [];
      for (const client of this.foundClients) {
        const statusText = getOptionLabel(this.accountDefinition,'status',client.status);
        const intermediaryName = this.account[client.intermediaryId]?.name;
        const clientLabel = client.name + " ("+ statusText + ") - " + intermediaryName
        res.push(  { id: client.id, name: clientLabel },)
      }
      return res
    }
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    on_change({ field, value }) {
      this.submitRecord[field] = value;
    },
    async retrieveClients() {
    this.clientStatus = "retrieving"
      const clientResult = await doPermissionAction(this, 'getAccounts',{}, { 
        additionalSelector:{where:{
          cifNumber:this.clientCif
        }}
    });
    if (clientResult.status === 'success') {
      this.foundClients = clientResult.response.rows
      this.clientStatus = "retrieved"
    } else {
      this.clientStatus = "error"
      this.clientError = clientResult.error
    }

  },
    // ----- API calls -----
    async onApiSuccess(_responseObject) {},
  },
};
</script>

<style></style>
