<template>
    <div  class="lfx-widget-header-container">
      <div class="lfx-widget-titles">

       <capitec-label type="subtitle" :style="{'white-space':'nowrap','overflow':'hidden','text-overflow':'ellipsis'}" :label="`${header}`"  />
       <capitec-label type="default" :label="subheader"  />
       
       </div>

       <capitec-spacer />
       <router-link v-if="link !== undefined" class="capitec-hyperlink widget" :to="link" tag="capitec-hyperlink"  >
       {{linkText}} 
       </router-link>
    </div>
</template>

<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

export default {
  components: {},
  props: {  
    header:{type:String,required:true},
    subheader:{type:String,default:''},
    linkText:{type:String,default:'View All'},
    link:{type:[String,Object]},
  },
  data() {
    return {
      
    }
  },
  created() {
    
  },
  mounted() {
    
  },
  computed:{
    ...mapGetters([]),
  	...mapState([]),
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([])
  }
 }
</script>
<style>

div.lfx-widget-header-container{
    display:flex;
    margin-bottom:10px;
    padding-top:5px;
    padding-left:10px;
    padding-right:10px;
}


.capitec-hyperlink.widget{
  text-decoration-line: none;
}

.lfx-widget-container.currently-setting{
  position:relative;
}

.lfx-widget-container.currently-setting::after{
  content:'';
  position:absolute;
  top:0px;
  left:0px;
  bottom:0px;
  right:0px;
  background-color:#999999;  
  opacity:0.5;
  animation-name:settings-indicator;
  animation-timing-function: ease-in-out;
  animation-duration:1500ms;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-delay: 1000ms;
}

@keyframes settings-indicator {
  0% {
    opacity:0.5;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.5;
  }
}





</style>


