<template>
  <div class="capitec-item-view-field bop-categories">
    <capitec-label class="capitec-item-view-field-label bop-category" :label="`${field.label}`"></capitec-label>
    <div class="bop-cat">
      <template v-for="bopCat in value" :key="bopCat">
        <capitec-label
          type="strong"
          class="capitec-item-view-field-value bop-currency"
          :label="record.foreignCurrency"
        />
        &nbsp;
        <capitec-label
          type="strong"
          class="capitec-item-view-field-value bop-amount"
          :label="formatCurrency(bopCat.foreignAmount)"
        />
        &nbsp;
        <capitec-label
          type="strong"
          class="not-capitec-item-view-field-value bop-cat-name"
          :label="`${bopCatName(bopCat.bopCat)}`"
        />
        <!-- <div>
          <br />
          <p>test</p>
          <p>{{ bopCat.allowanceIndicators }} - {{ bopCat.fiaPin }}</p>
        </div> -->
      </template>

      <capitec-label type="strong" class="capitec-item-view-field-value bop-currency" :label="record.foreignCurrency" />
      &nbsp;
      <capitec-label
        type="strong"
        class="capitec-item-view-field-value bop-amount"
        :label="formatCurrency(totalAmount)"
      />
      &nbsp;
      <capitec-label
        type="strong"
        class="capitec-item-view-field-value bop-cat-name"
        :label="`Total | ( Remaining: ${record.foreignCurrency} ${formatCurrency(remainingAmount)} )`"
      />
    </div>
  </div>
</template>

<script>
import { ldb_field_mixin } from '@/landobyte_vue/ldb_field_mixin.js';
import { thousandSeparate } from '@/landobyte_vue/ldb_utils.ts';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  mixins: [ldb_field_mixin],
  components: {},
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters(['config']),
    ...mapState([]),
    bopCats() {
      return this.config.bopCat;
    },
    separator() {
      let sep = this?.field?.datatype?.currency?.separator ? this?.field?.datatype?.currency?.separator : ' ';
      return sep;
    },

    remainingAmount() {
      return this.record.foreignAmount - this.totalAmount;
    },
    totalAmount() {
      let res = 0;
      for (let c = 0; c < this.value.length; c++) {
        let cat = this.value[c];
        res = res + cat.foreignAmount;
      }
      return res;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    bopCatName(id) {
      return this?.bopCats?.[id]?.name || '-';
    },
    thousandSep(val) {
      return thousandSeparate(val);
    },
    formatCurrency(val) {
      let safeVal = val ? this.numValue(val) : 0;
      return (safeVal / 100)
        .toLocaleString('en-US', { style: 'currency', currency: 'USD' })
        .split('$')
        .join('')
        .split(',')
        .join(this.separator);
    },
    numValue(value) {
      if (value || value === 0) {
        let val = `${value}`.split(' ').join('');
        val = Number(val);
        if (isNaN(val)) {
          val = 0;
        }
        // return Math.round(val * 100);
        return Math.round(val);
      } else {
        return undefined;
      }
    },
  },
};
</script>
<style>
div.bop-cat {
  /* border: 1px solid red; */
  display: grid;
  grid-template-columns: max-content 5px max-content 10px auto;
  grid-auto-rows: max-content;
}

.bop-currency {
  font-weight: bold;
}

.bop-amount {
  text-align: right;
  font-weight: bold;

  /* font-weight: bold; */
}

.bop-cat-name {
  /* overflow: auto; */
  /* text-overflow: ellipsis; */
  /* overflow: hidden; */
  white-space: normal;
  display: inline-block;
  /* border: 1px solid green; */
  padding-right: 5px;
  font-style: italic;
  opacity: 0.7;
}

.capitec-item-view-field.bop-categories {
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
}

capitec-label.capitec-item-view-field-label.bop-category {
  margin-bottom: 5px;
}
</style>
