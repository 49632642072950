<template>
  <LDBModal result="info" :header="`${header}`" :size="modalSize" :closable="closable">
    <template #body>
      <h1>Split Deal from TF</h1>

      <ul>
        <li>The selected deal will be split out of this Transaction File into a new Transaction File.</li>
        <li>
          The payment amounts in this Transaction File will be adjusted to accommodate for the deal that is split out.
        </li>
      </ul>
    </template>

    <template #footer>
      <capitec-spacer />
      <LDBButton
        v-for="button in rightButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :viewGuards="button.viewGuards"
        :enabledGuards="button.enabledGuards"
        :tooltip="button.tooltip"
        @click="button.clickEvent"
      >
      </LDBButton>
    </template>
  </LDBModal>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

export default {
  name: 'LfxSplitDealFromTransaction',
  components: {},
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['transaction', 'deal']),
    //
    // ----- Modal -----

    header() {
      return 'Split Deal To New Transaction File';
    },
    modalSize() {
      return 'large'; // TODO
    },
    closable() {
      return false;
    },

    // ----- Transaction Record -----

    transactionId() {
      return +this.$route.params?.transactionId;
    },
    transactionRecord() {
      return this.transaction?.[this.transactionId];
    },

    // ----- Deal Record -----

    dealId() {
      return +this.$route.params?.dealId;
    },
    dealRecord() {
      return this.deal?.[this.dealId];
    },

    // ----- Buttons -----

    rightButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          action: 'click',
          guards: true,
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'splitDealFromTransaction',
          label: 'Split Deal',
          type: 'primary',
          action: 'splitDealToNewTf',
          guards: true,
          enabledGuards: true, // TODO
          params: { transactionId: this.transactionId, dealId: this.dealId },
          actionParams: {},
          clickEvent(response) {
            console.log('RESPONSE', response);
            if (response.status === 'success') {
              self.$router.push({ name: 'transaction', params: { transactionId: response.response.id } });
            }
          },
        },
      ];
      return allButtons;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
