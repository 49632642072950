//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LdbInterfaces.ts
//  Author      : Bevan Timm
//  Description : This module defines the interface used to generate LandoByte object classes
//  Created     : 14 July 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

// Validations - https://github.com/validatorjs/validator.js

//----------------------------------------------------------------------------------------------------------------------

import ACTIONS from './actions/index';

import { LdbView } from './LdbViewInterfaces';

type LdbIdType = {
  id: {
    descriptionField: string;
    type?: 'number' | 'text'; //Datatype of the ID field, default is "number"
  };
}; //
type LdbOption = {
  id: string | number | boolean; // Database value of the option
  name: string; // Display value of the option
  guards?: LdbGuard;
  enableGuards?: LdbGuard;
  // tagType?: 'info' | 'warning' | 'success' | 'error' | { custom: { color: string; 'label-color': string } };
  tagType?:
    | 'info'
    | 'warning'
    | 'success'
    | 'error'
    | 'cancelled'
    | { graph: 'green' | 'blue' | 'lightGreen' | 'purple' | 'magenta' | 'lightOrange' | 'orange' | 'yellow' }
    | { custom: { color: string; labelColor: string } };
};

export type LdbOptionType = {
  option: {
    optionType: 'boolean' | 'number' | 'text';
    options: LdbOption[];
  };
};

type LdbForeignKeyType = {
  foreignKey: {
    linkTable: string; // Foreign table linked
    linkField: string; // Field in the foreign table linked to
    displayField: string; // Display field from the foreign table
    localForeignKeyLinkField?: string; // Optional local field to use to link to foreign item. If not captured it will use the field's name.
    sequelizeBelongsTo?: string; // The belongsTo field name used in Sequelize, used for generating automated reports
    collection?: string;
    guards?: LdbGuard;
    type?: 'number' | 'text'; //Datatype of the Foreign Key field, default is "number"
    additionalSelectors?: LdbWhere | ((self: LdbDbObject, record: LdbDbObject) => boolean);
    linkInSql?: boolean;
    overrideOptions?: LdbOption[];
  };
};

type LdbActionType = {
  action: {
    action: string; // defines the action to be taken
    label: string; // defines the label of the action
  };
};

type LdbCurrencyType = {
  currency: {
    symbol: string;
    decimalPlaces?: number;
  };
};
export type LdbTimeIncrement = { type: 'day' | 'month' | 'year'; offset: number };
type LdbDateTimeType = {
  datetime: {
    type: 'date' | 'time' | 'datetime' | 'ago';
    format: 'sse' | 'human';
    input?: 'calendar' | 'input';
    minDate?: string | ((record: LdbDbObject) => string) | LdbTimeIncrement;
    maxDate?: string | ((record: LdbDbObject) => string) | LdbTimeIncrement;
  };
};
type LdbMapType = {
  map: {
    latitudeField: string;
    longitudeField: string;
    zoomField: string;
  };
};
type LdbJsonType = {
  json: {
    component?: string;
    structure?: {};
    type?: 'collection' | 'json';
    path?: (record: LdbDbObject) => string;
  };
};

type LdbFloatType = {
  float: {
    decimalPlaces: number;
  };
};

export type LdbDatatype =
  | 'text'
  | 'memo'
  | 'integer'
  | 'float'
  | 'percentage'
  | 'boolean'
  | 'uuid'
  | 'object'
  // | LdbFloatType // might blow up DB as not catered for in Sequelize // avr 2023-03-14
  | LdbIdType
  | LdbOptionType
  | LdbForeignKeyType
  | LdbActionType
  | LdbCurrencyType
  | LdbDateTimeType
  | LdbMapType
  | LdbJsonType; // The list of available datatypes

export type LdbButton = {
  icon?: string;
  action: 'click' | 'url' | 'routerlink' | keyof typeof ACTIONS;
  actionName: string;
  label?: string;
  iconSize?: string;
  iconPlacement?: string;
  type?: 'primary' | 'default' | 'clear' | 'disabled' | '';
  views?: LdbFieldViews;
};

export interface LdbVueDatatype {
  type?: LdbDatatype;
  required?: boolean;
}

export interface LdbDbObject {
  id: number;
}

export type LdbAuthUser = {
  accountId: number;
  id: number;
  permissions: string[];
  accountLevel: LdbAccountLevel;
};

export type LdbUser = {
  accountId: number;
  id: number;
  permissions: string[];
  accountLevel: LdbAccountLevel;
  lastLogin: string;
};

//----------------------------------------------------------------------------------------------------------------------

export type LdbDefault = null | string | number | boolean | []; // Types available as a default for a field
export type LdbDefaultFunction = () => LdbDefault; // Function that returns the default for a field //TODO define variables

//----------------------------------------------------------------------------------------------------------------------

export type LdbViewNames = 'create' | 'edit' | 'item' | 'list' | 'delete' | 'csv' | 'tiles' | 'search';

export type LdbTile = {
  layout: string[][];
  color: { type: 'field' | 'string'; field?: string; optionField?: string; colorType: 'class' | 'color' };
};
export type LdbTiles = { [tile: string]: LdbTile };
export type LdbTileValue = { [tile: string]: {} };

export type LdbViewFunction = (viewFunctionObject: {
  authUser: { id: number; accountId: number; accountLevel: string; permissions: string[] };
  view: string;
  routeMetaData: { [field: string]: any };
  record?: object;
}) => boolean;

type LdbViewValue = boolean | LdbViewFunction;
type LdbViews = {
  create?: LdbViewValue;
  edit?: LdbViewValue;
  item?: LdbViewValue;
  list?: LdbViewValue | string;
  delete?: LdbViewValue;
  csv?: LdbViewValue;
  search?: LdbViewValue;
  tiles?: LdbTiles;
  customViews?: { [field: string]: boolean };
};

export type LdbFieldViewTileValue = {};

type LdbFieldViews = {
  create?: LdbViewValue;
  edit?: LdbViewValue;
  item?: LdbViewValue;
  list?: LdbViewValue | string;
  delete?: LdbViewValue;
  csv?: LdbViewValue;
  search?: LdbViewValue;
  tiles?: LdbFieldViewTileValue;
  customViews?: { [field: string]: boolean };
};

type LdbIndex = string | { unique: string };

type LdbActions = {
  list?: string;
  item?: string;
  edit?: string;
  create?: string;
};

export type LdbFieldLabelFunction = (viewFunctionObject: {
  authUser: { id: number; accountId: number; accountLevel: string; permissions: string[] };
  view: string;
  routeMetaData: { [field: string]: any };
  record?: object;
}) => string;

//----------------------------------------------------------------------------------------------------------------------

export interface LdbValidators {
  // Copied from Sequelize
  is?:
    | string
    | readonly (string | RegExp)[]
    | RegExp
    | { msg: string; args: string | readonly (string | RegExp)[] | RegExp };
  not?:
    | string
    | readonly (string | RegExp)[]
    | RegExp
    | { msg: string; args: string | readonly (string | RegExp)[] | RegExp };
  isEmail?: boolean | { msg: string };
  isUrl?: boolean | { msg: string };
  isIP?: boolean | { msg: string };
  isIPv4?: boolean | { msg: string };
  isIPv6?: boolean | { msg: string };
  isAlpha?: boolean | { msg: string };
  isAlphanumeric?: boolean | { msg: string };
  isNumeric?: boolean | { msg: string };
  isInt?: boolean | { msg: string };
  isFloat?: boolean | { msg: string };
  isDecimal?: boolean | { msg: string };
  isLowercase?: boolean | { msg: string };
  isUppercase?: boolean | { msg: string };
  notNull?: boolean | { msg: string };
  isNull?: boolean | { msg: string };
  notEmpty?: boolean | { msg: string };
  equals?: string | { msg: string };
  contains?: string | { msg: string };
  notIn?: ReadonlyArray<readonly any[]> | { msg: string; args: ReadonlyArray<readonly any[]> };
  isIn?: ReadonlyArray<readonly any[]> | { msg: string; args: ReadonlyArray<readonly any[]> };
  notContains?: readonly string[] | string | { msg: string; args: readonly string[] | string };
  len?: readonly [number, number | undefined] | { msg: string; args: readonly [number, number | undefined] };
  isUUID?: number | { msg: string; args: number };
  isDate?: boolean | { msg: string; args: boolean };
  isAfter?: string | { msg: string; args: string | undefined | (string | undefined[]) };
  isBefore?: string | { msg: string; args: string | undefined | (string | undefined[]) };
  max?: number | { msg: string; args: readonly [number] };
  min?: number | { msg: string; args: readonly [number] };
  isArray?: boolean | { msg: string; args: boolean };
  isCreditCard?: boolean | { msg: string; args: boolean };

  custom?: {
    vFunction: (value: any, object?: object) => boolean | string;
    msg?: string;
    validatingInterface: 'frontend' | 'backend' | 'both';
  };
  // [name: string]: ( (value:string) => boolean);
  [name: string]: unknown | ((value: string) => boolean) | { msg: string; args: (value: string) => boolean };
}

//----------------------------------------------------------------------------------------------------------------------

type LdbProperty = {
  source: 'backend' | 'frontend';
  read: boolean;
  write: boolean;
  sort: LdbPropetySort;
  // | LdbPropetySort[]; // Not yet implemented
};

type LdbPropetySort = 'none' | string;
// | { foreignKeyField: string, linkTable: string, linkField: string, collection: string, displayField: string } // Not yet implemented
// | ((record: any) => LdbPropetySort); // Not yet implemented

//----------------------------------------------------------------------------------------------------------------------

type LdbGuardValue = number | string | string[] | boolean | ((record: LdbIdType) => boolean) | { field: string };
type LdbGuardOperator =
  | { in: LdbGuardValue[] }
  | { notIn: LdbGuardValue[] }
  | { eq: LdbGuardValue }
  | { notEq: LdbGuardValue }
  | { gt: LdbGuardValue }
  | { lt: LdbGuardValue }
  | { gteq: LdbGuardValue }
  | { lteq: LdbGuardValue };
export type LdbGuard =
  | boolean
  | ((record: LdbDbObject) => LdbGuardOperator|LdbGuardValue)
  | { [field: string]: LdbGuardOperator | string | ((record: LdbDbObject) => LdbGuardOperator|LdbGuardValue) };

//----------------------------------------------------------------------------------------------------------------------

export type LdbDisplayForeignKeyList = {
  datatype: string;
  component?: string;
  foreignKeyList: { linkTable: string; linkField: string; displayField: string; collection: string };
};
export type LdbDisplayBoolean = {
  datatype: string;
  component?: string;
  boolean: {
    display: 'checkbox' | 'toggle' | 'dropdown' | 'radio' | 'custom';
    trueLabel: string;
    falseLabel: string;
    trueClass?: string;
    falseClass?: string;
  };
};
export type LdbDisplayOption = { datatype: string; component?: string; display: 'dropdown' | 'radio' | 'custom' };

export type LdbDisplayFloat = { float: { decimalPlaces: number } };

export type LdbDisplayMemo = {
  datatype: string;
  component?: string;
  size?: 'full' | 'half';
  characterCount: { max: false | number };
};
export type LdbDisplayGeneral = {
  displayAsTag?: boolean | string;
  tagTypeFunctionName?: string;
  renderLink?: boolean;
};

export type LdbSearchRangeFunction = (viewFunctionObject: {
  authUser: { id: number; accountId: number; accountLevel: string; permissions: string[] };
  view: string;
  routeMetaData: { [field: string]: any };
  record?: object;
}) => { from: number | string; to: number | string };

export type LdbFromTo = { from: number | string; to: number | string } | LdbSearchRangeFunction;

export type LdbSearchRange = {
  name: string;
  label: string;
  range?: LdbFromTo;
  default?: boolean;
};

export type LdbSearch = {
  presets?: LdbSearchRange[];
  default?: LdbFromTo;
};

export type LdbDisplayType =
  | LdbDisplayGeneral
  | LdbDisplayForeignKeyList
  | LdbDisplayBoolean
  | LdbDisplayMemo
  | LdbDisplayOption
  | LdbDisplayFloat;

export type LdbAutoComplete =
  | 'on'
  | 'off'
  | 'additional-name'
  | 'address-level1'
  | 'address-level2'
  | 'address-level3'
  | 'address-level4'
  | 'address-line1'
  | 'address-line2'
  | 'address-line3'
  | 'bday'
  | 'bday-day'
  | 'bday-month'
  | 'bday-year'
  | 'cc-additional-name'
  | 'cc-csc'
  | 'cc-exp'
  | 'cc-exp-month'
  | 'cc-exp-year'
  | 'cc-family-name'
  | 'cc-given-name'
  | 'cc-name'
  | 'cc-number'
  | 'cc-type'
  | 'country'
  | 'country-name'
  | 'current-password'
  | 'email'
  | 'family-name'
  | 'fax'
  | 'given-name'
  | 'home'
  | 'honorific-prefix'
  | 'honorific-suffix'
  | 'impp'
  | 'language'
  | 'mobile'
  | 'name'
  | 'new-password'
  | 'nickname'
  | 'one-time-code'
  | 'organization'
  | 'organization-title'
  | 'pager'
  | 'photo'
  | 'postal-code'
  | 'sex'
  | 'street-address'
  | 'tel'
  | 'tel-area-code'
  | 'tel-country-code'
  | 'tel-extension'
  | 'tel-local'
  | 'tel-local-prefix'
  | 'tel-local-suffix'
  | 'tel-national'
  | 'transaction-amount'
  | 'transaction-currency'
  | 'url'
  | 'username'
  | 'work';

export interface LdbField {
  label: string | LdbFieldLabelFunction; // Default label of field on LdbInterfaces
  autocomplete?: LdbAutoComplete | string;
  datatype: LdbDatatype; // Datatype for DB and web LdbInterfaces
  displayType?: LdbDisplayType;
  default: LdbDefault | LdbDefaultFunction; // The default value when populating a form or creating a DB item
  views: LdbFieldViews; // Defines which default views the field should be included in
  group?: string; // This group will be used to group fields
  order?: { default: undefined | number; create?: number; edit?: number; item?: number; list?: number; delete?: number }; // Declairs that order in which fields should be shown on the front end
  button?: LdbButton;
  mandatory: boolean | { [field: string]: string | string[] } | ((record: LdbDbObject) => boolean); // Defined whether the field is mandatory on create and edit forms
  allowNullValues?: boolean; // Sets Allow Null Values in Database
  showIfNull?: boolean; // Shows the field on item views even if it is nullish
  validators?: LdbValidators; // Define any validations to be peformed on the field (front end and back end)
  unique?: boolean; // Set to TRUE if the database should enforce uniqueness
  search?: LdbSearch | boolean;
  property?: undefined | LdbProperty;
  guards?: LdbGuard;
  write?: boolean;
  enableGuards?: LdbGuard;

  help?: string;
}

// TODO: Ask Rhyno from here
// formatters?: string[],
// classes?: string[],
// sort?: boolean,

// read?: boolean,
// write?: boolean

// TODO
// viewGuards
// permissions

type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HEX = `#${string}`;
type color = RGB | RGBA | HEX;

export type LdbFields = { [fieldName: string]: LdbField };

export type LdbFieldGroup = {
  priority: number;
  label: string;
  color?: 'info' | 'warning' | 'success' | 'error' | 'disabled' | color;
  default?: true;
};

export type LdbFieldGroups = { [group: string]: LdbFieldGroup };

export interface LdbDefinition {
  title: string;
  class?: (data: any, context: any, name: string) => LdbDbObject;
  database?: 'sql' | 'dynamo'; // Assumed to be SQL if undefined
  groups?: LdbFieldGroups;
  table: string; // Name of the table
  collectionPath: null | string | ((dbObject: LdbDbObject) => string);
  addToStoreIfNotExist?: boolean | ((dbObject: LdbDbObject, authUser: LdbAuthUser) => boolean);
  pagingType: 'frontEnd' | 'backEnd';
  fields: LdbFields; // fieldName in the database
  view: LdbViews;
  indexes: LdbIndex[];
  apiUrl?: (parent: LdbDefinition | null) => string;
  views?: LdbView[];
  actions?: LdbActions;
  load?: LdbLoad;
  // TODO: Ask Rhyno from here
}

// TODO
// Default Selectors
// Names?
// viewGuards/permissions?
// buttons?
// Audit log?
// Table type - business, log ???

//----------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------

export type LdbAccountLevel = 'bank' | 'intermediary' | 'intermediaryBranch' | 'client';
export type LdbAccountLevels = LdbAccountLevel[];

export type LdbPermission =
  | 'administrator'
  | 'bankClientRelease'
  | 'clientAdmin'
  | 'clientBeneficiary'
  | 'intermediaryAccountImport'
  | 'intermediaryAdmin'
  | 'intermediaryBalanceEnquiry'
  | 'intermediaryBeneficiary'
  | 'intermediaryClientCreate'
  | 'intermediaryClientQuery'
  | 'intermediaryClientSubmit'
  | 'superAdministrator';
export type LdbPermissions = LdbPermission[];

export type LdbConditionFunction = (state: object, record: object) => boolean;

export type LdbCondition = LdbConditionFunction | boolean; // TODO: Add |LdbSelector at a later stage;
export type LdbConditions = LdbCondition[] | LdbCondition;

export type LdbPermissionGuard = {
  accountLevels?: LdbAccountLevels;
  permissions?: LdbPermissions;
  mfa?: boolean;
  conditions?: LdbConditions;
};

export type LdbSelectValue = number | string | Date | boolean;
export type LdbSelector =
  | LdbSelectValue // eq
  | LdbSelectValue[] // in
  | {
      eq?: LdbSelectValue;
      neq?: LdbSelectValue;
      gt?: number | Date;
      gte?: number | Date;
      lt?: number | Date;
      lte?: number | Date;
      bewteen?: [number, number];
      notBewteen?: [number, number];
      in?: LdbSelectValue[];
      notIn?: LdbSelectValue[];
      like?: string;
      notLike?: string;
    }
  | LdbWhere[]; //or, not

export interface LdbWhere {
  // not?:LdbWhere[],
  // or?:LdbWhere[],
  // and?:LdbWhere[],
  [fieldName: string]: LdbSelector;
}

export interface LdbSelect {
  fields?: string[];
  where: LdbWhere;
  orderBy?: { [fieldName: string]: 'asc' | 'desc' };
  limit?: number;
  offset?: number;
}

export type LdbLoad = { [fieldName: string]: LdbLoadCollection | LdbLoadDocument };

export type LdbLoadCollection = {
  type: 'collection';
  collection_path: string | ((record: LdbDbObject) => string);
  load: 'active' | 'load' | 'custom';
  where?: LdbSelect;
};

export type LdbLoadDocument = {
  type: 'document';
  collection_path: string | ((record: LdbDbObject) => string);
  document_id: string | number | ((record: LdbDbObject) => string);
  load: 'active' | 'load' | 'custom';
};

//----------------------------------------------------------------------------------------------------------------------
// Utility functions
//----------------------------------------------------------------------------------------------------------------------

export function assertCannotReach(x: never) {
  throw new Error("Shouldn't be able to reach this place in the code.");
}

//----------------------------------------------------------------------------------------------------------------------

// const testSelect:LdbSelect = {
//   fields: ['name','suname'],
//   where: {
//     age:{gt:10},
//     eyes:{eq:'blue'},
//     car:['mazda','vw'],
//     or:[{
//       name:{like:'john'}
//     },
//     {
//       surname:{like:'smith'},
//     }],
//     not:{
//       hair:'blonde'
//     }
//   },
//   orderBy: {name:'asc'},
//   limit: 10,
//   offest: 30
// }
