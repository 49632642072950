//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LdbConfigDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Config record for LandoByte TS systems
//  Created     : 30 August 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition } from './LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------

export interface LdbConfigInt {
  id?:string,
  name?:string,
  code3?:string,
  codeM49?:string,
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxConfigDef:LdbDefinition = {
  apiUrl() {
    return '/config'
  },
  title:"Config",
  table:'LfxConfig',
  collectionPath:'/config',
  view:{create:true,edit:true,item:true,list:'LfxConfigTile',delete:false,csv:false},
  indexes:[],
  pagingType:'frontEnd',
  fields:{
    id:{
      label:'Code',
      datatype:{'id':{descriptionField:'id',type:'text'}},
      default:null,
      mandatory:false,
      views:{create:true,edit:true,item:true,list:true,delete:false,csv:false}
    }
  }
}
