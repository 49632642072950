<template>
  <section v-if="!isAuthenticating" id="login-content">
    <capitec-toast
      v-if="showToast"
      :type="toastType"
      :header="toastHeader"
      :detail="toastMessage"
      closable
      style="position: fixed; top: 10px; width: 500px; left: calc(50% - 250px)"
      @click="hideToast"
    ></capitec-toast>

    <capitec-loading-indicator v-if="showLoadingIndicator" />
    <capitec-page-header v-if="!loginSuccess" class="login-header" label="Forex"> </capitec-page-header>
    <capitec-card v-if="!loginSuccess" class="login-form">
      <!-- {{mode}} -->

      <capitec-title-bar :label="`Login`"></capitec-title-bar>
      
      <capitec-spacer> </capitec-spacer>

      <capitec-text-field
        class="login-field"
        v-if="showUsername"
        label="Username"
        :focussed="true"
        @key-input="onUsernameChange"
        @value-change="onUsernameChange"
        :value="username"
      >
      </capitec-text-field>
      
      <capitec-text-field
        class="login-field"
        v-if="showConfirmationCode"
        label="Confirmation Code"
        :focussed="true"
        @key-input="onConfirmationCodeChange"
        @value-change="onConfirmationCodeChange"
        @keypress.enter="doSendConfirmationCode"
        :value="confirmation_code"
      >

      </capitec-text-field>

      <capitec-text-field
        class="login-field"
        v-if="showOtp"
        label="OTP"
        :focussed="true"
        @key-input="onOtpChange"
        @value-change="onOtpChange"
        @keypress.enter="doSendOtp"
        :value="otp"
      >
      </capitec-text-field>

      <capitec-text-field
        class="login-field"
        v-if="showPassword"
        label="Password"
        type="password"
        @key-input="onPasswordChange"
        @value-change="onPasswordChange"
        @keypress.enter="doLogin"
        :value="password"
      ></capitec-text-field>

      <capitec-text-field
        class="login-field"
        v-if="showNewPassword"
        label="New Password"
        type="password"
        @key-input="onNewPasswordChange"
        @value-change="onNewPasswordChange"
        :value="new_password"
      ></capitec-text-field>

      <capitec-text-field
        class="login-field"
        v-if="showConfirmPassword"
        label="Confirm Password"
        type="password"
        :error="confirmPasswordError"
        @key-input="onConfirmPasswordChange"
        @value-change="onConfirmPasswordChange"
        @blur="passwordConfirmBlur"
        @focus="passwordConfirmFocus"
        :value="confirm_password"
      ></capitec-text-field>

      <capitec-button-bar>
        <capitec-button
          label="Back"
          v-if="showBackButton"
          :disabled="!backButtonEnabled"
          type="secondary"
          @click="goBack"
        />
        <capitec-button
          label="Reset Password"
          v-if="showResetPasswordButton"
          :disabled="!resetPasswordButtonEnabled"
          type="primary"
          @click="doResetRequestUsername"
        />
        <capitec-button
          label="Reset Password"
          v-if="showSubmitFirstLoginButton"
          :disabled="!submitFirstLoginButtonEnabled"
          type="primary"
          @click="doResetFirstLoginPassword"
        />
        <capitec-button
          label="Send Confirmation"
          v-if="showSendConfirmationButton"
          :disabled="!sendConfirmationButtonEnabled"
          type="primary"
          @click="doSendConfirmationCode"
        />
        <capitec-button
          label="Forgot Password"
          v-if="showForgotPasswordButton"
          :disabled="!forgotPasswordButtonEnabled"
          type="secondary"
          @click="doForgotPassword"
        />
        <capitec-button
          label="Resend OTP"
          v-if="showSendOtpButton"
          :disabled="false && !sendOtpButtonEnabled"
          type=""
          @click="doReSendOtp"
        />

        <capitec-button
          label="Submit OTP"
          v-if="showSendOtpButton"
          :disabled="!sendOtpButtonEnabled"
          type="primary"
          @click="doSendOtp"
        />
        <capitec-button
          label="Login"
          v-if="showLoginButton"
          :disabled="!loginButtonEnabled"
          type="primary"
          @click="doLogin"
        />
      </capitec-button-bar>
    </capitec-card>
  </section>
  <template  v-else>
  <LfxLoginPhoneInstruction />
  </template>
</template>

<script>

import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import LfxLoginPhoneInstruction from '@/components/layout/LfxLoginPhoneInstruction.vue';

const PASSWORD_LENGTH = 5;
const USERNAME_LENGTH = 5;
const CONFIRMATION_CODE_LENGTH = 5;
const INTER_MODE_TIMEOUT = 5 * 1000;

export default {
  components: {LfxLoginPhoneInstruction},
  props: {},
  data() {
    return {
      session: '',
      confirm_password_focus: false,
      mode: 'login',
      username: '',
      confirmation_code: '',
      password: '',
      new_password: '',
      confirm_password: '',
      otp: '',
      showToast: false,
      toastHeader: '',
      toastMessage: '',
      toastType: '',
      otpToken: '',
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['isAuthenticating']),
    loggingIn() {
      return this.isInModes(['logging_in']);
    },
    loginSuccess() {
      return this.isInModes([
        'login_success', 
        'otp_success'
      ]);
    },
    passwordIsExactMatch() {
      return this.new_password === this.confirm_password;
    },
    passwordIsPartialMatch() {
      let confirmPasswordLength = this.confirm_password.length;
      let newPasswordLength = this.new_password.length;
      let partialPassword = this.new_password.substr(0, confirmPasswordLength);
      return (
        this.passwordIsExactMatch ||
        confirmPasswordLength === 0 ||
        (this.confirm_password_focus === true &&
          partialPassword === this.confirm_password &&
          !(confirmPasswordLength > newPasswordLength))
      );
    },
    confirmPasswordStatus() {
      if (this.new_password === this.confirm_password) {
        return 'success';
      } else {
        return 'error';
      }
    },
    confirmPasswordError() {
      if (!this.passwordIsPartialMatch) {
        return 'The confirmation password does not match the new password.';
      } else {
        return '';
      }
    },
    showLoadingIndicator() {
      return this.isInModes([
        'logging_in',
        'resetting_password',
        'sending_confirmation_code',
        'submitting_otp',
        'submitting_first_login',
      ]);
    },
    // SHOW FIELDS BUTTON
    showUsername() {
      return this.isInModes([
        'login',
        'logging_in',
        'forgot_password',
        'resetting_password',
        'login_failed',
        'confirmation_code_success',
      ]);
    },
    showConfirmationCode() {
      return this.isInModes(['require_confirmation_code', 'sending_confirmation_code', 'confirmation_code_failed']);
    },
    showOtp() {
      return this.isInModes(['otpSent', 'submitting_otp', 'otp_failed']);
    },
    showPassword() {
      return this.isInModes(['login', 'logging_in', 'login_failed', 'confirmation_code_success']);
    },
    showNewPassword() {
      return this.isInModes([
        'passwordResetRequired',
        'require_confirmation_code',
        'confirmation_code_failed',
        'sending_confirmation_code',
        'submitting_first_login',
      ]);
    },
    showConfirmPassword() {
      return this.isInModes([
        'passwordResetRequired',
        'require_confirmation_code',
        'confirmation_code_failed',
        'sending_confirmation_code',
        'submitting_first_login',
      ]);
    },
    // SHOW BUTTONS
    showBackButton() {
      return !this.isInModes(['login', 'login_failed', 'logging_in', 'confirmation_code_success']);
    },
    showResetPasswordButton() {
      return this.isInModes(['forgot_password', 'resetting_password']);
    },
    showForgotPasswordButton() {
      return this.isInModes(['login', 'logging_in', 'login_failed', 'confirmation_code_success']);
    },
    showSendOtpButton() {
      return this.isInModes(['otpSent', 'otp_failed', 'submitting_otp']);
    },
    showLoginButton() {
      return this.isInModes(['login', 'login_failed', 'logging_in', 'confirmation_code_success']);
    },
    showSubmitFirstLoginButton() {
      return this.isInModes(['passwordResetRequired', 'submitting_first_login']);
    },
    showSendConfirmationButton() {
      return this.isInModes(['require_confirmation_code', 'sending_confirmation_code', 'confirmation_code_failed']);
    },
    // ENABLE BUTTONS
    backButtonEnabled() {
      return !this.isInModes([
        'logging_in',
        'resetting_password',
        'sending_confirmation_code',
        'submitting_otp',
        'submitting_first_login',
      ]);
    },
    resetPasswordButtonEnabled() {
      return (
        this.isInModes(['login', 'forgot_password', 'login_failed', 'confirmation_code_success']) &&
        this.username.length >= USERNAME_LENGTH
      );
      //  && (this.confirm_password === this.new_password);
    },
    doResetPasswordButtonEnabled() {
      return this.username.length >= USERNAME_LENGTH;
    },
    forgotPasswordButtonEnabled() {
      return this.isInModes(['login', 'logging_in', 'login_failed', 'confirmation_code_success']);
    },
    sendOtpButtonEnabled() {
      return this.isInModes(['otpSent', 'otp_failed']);
    },
    sendConfirmationButtonEnabled() {
      return (
        !this.isInModes(['sending_confirmation_code']) &&
        this.confirmation_code.length >= CONFIRMATION_CODE_LENGTH &&
        this.new_password === this.confirm_password &&
        this.new_password.length >= PASSWORD_LENGTH
      );
    },
    submitFirstLoginButtonEnabled() {
      return this.new_password === this.confirm_password && this.new_password.length >= PASSWORD_LENGTH;
    },
    loginButtonEnabled() {
      return this.username.length >= USERNAME_LENGTH && this.password.length >= PASSWORD_LENGTH;
    },
  },
  methods: {
    ...mapActions([
      'lfx_auth_login',
      'lfx_auth_complete_otp_login',
      'lfx_auth_complete_first_login',
      'lfx_auth_forgot_password',
      'lfx_auth_confirm_forgot_password',
      'lfx_auth_resend_otp'
    ]),
    ...mapMutations([]),

    hideToast() {
      this.showToast = false;
    },
    passwordConfirmBlur() {
      this.confirm_password_focus = false;
    },
    passwordConfirmFocus() {
      this.confirm_password_focus = true;
    },
    doShowToast({ type, header, message }) {
      this.toastType = type;
      this.toastHeader = header;
      this.toastMessage = message;
      this.showToast = true;
      setTimeout(this.hideToast, 20000);
    },
    tmpOtpResponse() {
      if (this.otp === '00000') {
        this.mode = 'otp_failed';
      } else {
        this.mode = 'otp_success';
      }
    },
    tmpFirstLoginResponse() {
      this.mode = 'login';
      this.username = '';
      this.password = '';
    },
    tmpResetResponse() {
      this.mode = 'require_confirmation_code';
    },
    tmpConfirmResponse() {
      if (this.confirmation_code === '00000') {
        this.mode = 'confirmation_code_failed';
      } else {
        this.mode = 'confirmation_code_success';
      }
    },
    tmpLoginResponse() {
      if (this.password === 'correct') {
        this.mode = 'success';
      } else if (this.password === 'reset') {
        this.mode = 'passwordResetRequired';
      } else if (this.password === 'sendotp') {
        this.mode = 'otpSent';
      } else {
        this.mode = 'login_failed';
      }
    },
    // ON CHANGE EVENTS
    onUsernameChange(evt) {
      this.username = evt.detail.new;
    },
    onConfirmationCodeChange(evt) {
      this.confirmation_code = evt.detail.new;
    },
    onOtpChange(evt) {
      this.otp = evt.detail.new;
    },
    onPasswordChange(evt) {
      this.password = evt.detail.new;
    },
    onNewPasswordChange(evt) {
      this.new_password = evt.detail.new;
    },
    onConfirmPasswordChange(evt) {
      this.confirm_password = evt.detail.new;
    },
    // BUTTON METHODS
    goBack() {
      if (this.mode === 'forgot_password') {
        this.mode = 'login';
      } else if (this.mode === 'require_confirmation_code') {
        this.mode = 'forgot_password';
      } else if (this.mode === 'passwordResetRequired') {
        this.mode = 'login';
      } else if (this.mode === 'otpSent') {
        this.mode = 'login';
      } else if (this.mode === 'otp_failed') {
        this.mode = 'login';
      } else if (this.mode === 'confirmation_code_failed') {
        this.mode = 'login';
      }
    },
    doForgotPassword() {
      this.mode = 'forgot_password';
    },
    async doReSendOtp() {
      this.lfx_auth_resend_otp({otpToken: this.otpToken})
    },
    async doSendOtp() {
      this.mode = 'submitting_otp';
      let otpResponse = await this.lfx_auth_complete_otp_login({
        otp: this.otp,
        username: this.username,
        otpToken: this.otpToken,
      });
      if (otpResponse.status === 200 || otpResponse.status === 'success') {
        this.mode = 'otp_success';
        this.$router.push('/');
      } else {
        let message = otpResponse.response.message;
        let type = 'error';
        let header = 'OTP Error';
        this.mode = 'otp_failed'
        this.doShowToast({ type, header, message });
      }
      // setTimeout(this.tmpOtpResponse,INTER_MODE_TIMEOUT);
    },
    async doLogin() {
      this.mode = 'logging_in';
      let login_response = await this.lfx_auth_login({ username: this.username, password: this.password });
      if (login_response.status === 'success') {
        this.mode = 'login_success';
        this.$router.push('/');
      } else if (login_response.status === 'passwordResetRequired') {
        this.session = login_response.session;
        this.mode = 'passwordResetRequired';
        this.password = '';
      } else if (login_response.status === 'otpSent') {
        this.otpToken = login_response.response.otpToken;
        this.mode = 'otpSent';
      } else if (login_response.status === 'failed') {
        let message = login_response.response.message;
        let type = 'error';
        let header = 'Login Failed';
        this.mode = 'login_failed';
        if (login_response.response.loginResult === "loginDisabled") {
          header = "Login Disabled"
          message = login_response.response.loginResult.error
        }
        this.doShowToast({ type, header, message });
      }
      // setTimeout(this.tmpLoginResponse,INTER_MODE_TIMEOUT);
    },
    async doResetRequestUsername() {
      this.mode = 'resetting_password';
      let forgot_password_response = await this.lfx_auth_forgot_password({ username: this.username });
      if (forgot_password_response.status === 200) {
        this.doShowToast({
          type: 'info',
          header: 'Confirmation Code Sent',
          message: 'Please enter the confirmation code sent to you via email.',
        });
        this.mode = 'require_confirmation_code';
      } else {
        //THIS CODE WILL ONLY EXECUTE IN THE EVENT OF A SYSTEM OR NETWORK FAILURE.
        this.doShowToast({
          type: 'error',
          header: 'Password Reset Error',
          message: 'We were unable to reset your password.',
        });
        this.mode = 'forgot_password';
      }
      //  setTimeout(this.tmpResetResponse,INTER_MODE_TIMEOUT);
    },
    async doResetFirstLoginPassword() { //HERE
      this.mode = 'submitting_first_login';
      let complete_first_login_response = await this.lfx_auth_complete_first_login({
        username: this.username,
        newPassword: this.new_password,
        session: this.session,
      });
      if (complete_first_login_response.status === 200 || complete_first_login_response.status === 202 || complete_first_login_response.status === "success") {
        // this.mode = 'login';
        // this.username = '';
        // this.password = '';
      } else {
        this.doShowToast({
          type: 'error',
          header: 'First Login Password Reset Failed',
          message: complete_first_login_response?.response?.errorMessage || 'Password could not be reset. Please consult User Access Log for details.',
        });
        this.mode = 'passwordResetRequired';
      }
      // setTimeout(this.tmpFirstLoginResponse,INTER_MODE_TIMEOUT);
    },
    async doSendConfirmationCode() {
      this.mode = 'sending_confirmation_code';
      let confirm_forgot_password_response = await this.lfx_auth_confirm_forgot_password({
        username: this.username,
        password: this.new_password,
        confirmationCode: this.confirmation_code,
      });
      this.password = '';
      if (confirm_forgot_password_response.status === 200) {
        this.doShowToast({
          type: 'success',
          header: 'Password Reset Success',
          message: 'Your password has been successfully reset.',
        });
        this.mode = 'confirmation_code_success';
      } else {
        this.doShowToast({
          type: 'error',
          header: 'Password Reset Failed',
          message: confirm_forgot_password_response?.response?.error || 'Password could not be reset. Please consult User Access Log for details.',
        });
        this.mode = 'confirmation_code_failed';
      }
      // setTimeout(this.tmpConfirmResponse,INTER_MODE_TIMEOUT);
    },
    // INTERNAL FUNCTIONS
    isInModes(modes) {
      return modes.indexOf(this.mode) > -1;
    },
  },
};
</script>
<style>
section#login-content {
  display: grid;
  grid-template-areas:
    'header header header'
    '. . .'
    '. form .';
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 70px 24px auto;
}

.login-form {
  max-width: 1000px;
  min-width: 500px;
  align-self: center;
  justify-self: center;
  grid-area: form;
}

.login-form > capitec-text-field {
  margin-left: 16px;
  margin-right: 16px;
}

.login-header {
  grid-area: header;
  align-self: stretch;
  justify-self: stretch;
}

.login-field {
  margin-bottom: 20px;
}
</style>


