<template>
  <LDBModal result="info" :header="`${header}`" :size="modalSize" :closable="closable">
    <template #body>
      <h1>Merge Transaction Files</h1>

      <template v-if="numberOfOtherTransactionOptions > 0">
        <p>The Transaction Files selected below will be merged into this Transaction File.</p>
        <p>All Deals and Payments of the selected Transaction Files will be copied to this Transaction File.</p>
        <p>The old Transaction Files will be marked as "Merged".</p>
        <br/>
        <p>Would you like the payments from the other Transaction Files to be imported separately or combined into this Transaction File's Balance Payment?</p>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          :view="field.view"
          :fieldname="field.name"
          :record="configRecord"
          :definition="undefined"
          @change="on_change"
        />
        <br/>
        <table>
          <thead>
            <tr>
              <th>Include?</th>
              <th>Reference</th>
              <th v-if="hasAnyExternalReferences">External Reference</th>
              <th>Number of Deals</th>
              <th>Deal Numbers</th>
              <th>Number of Payments</th>
              <!-- <th>Payment Value</th> -->
            </tr>
          </thead>
          <tr v-for="otherTransactionOption in otherTransactionsPool" :key="otherTransactionOption">
            <td><capitec-check @value-change="onCheck(otherTransactionOption, $event)" /></td>
            <td>{{ otherTransactionOption.reference }}</td>
            <td v-if="hasAnyExternalReferences">{{ otherTransactionOption.externalReference }}</td>
            <td>{{ otherTransactionOption.numberOfDeals }}</td>
            <td>
              <ul>
                <li v-for="dealId in otherTransactionOption.dealIds" :key="dealId">
                  {{ deal[dealId].dealNumber }}
                </li>
              </ul>
            </td>
            <td>{{ otherTransactionOption.numberOfPayments }}</td>
            <!-- <td></td> -->
          </tr>
        </table>
      </template>

      <template v-else>
        <p>There are no eligble Transaction Files to merge into this one.</p>
        <p>To be eligible, a Transaction File must:</p>
        <ul>
          <li>Have the same Client.</li>
          <li>May not have any Payments submitted yet.</li>
          <li>Must be a transaction type "Outward Deal".</li>
          <li>Must have the same Payment Currency.</li>
          <li>Must have the same Value Date.</li>
          <li>Must not be Locked.</li>
        </ul>
      </template>
    </template>

    <template #footer>
      <capitec-spacer />
      <LDBButton
        v-for="button in rightButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :viewGuards="button.viewGuards"
        :enabledGuards="button.enabledGuards"
        :tooltip="button.tooltip"
        @click="button.clickEvent"
      >
      </LDBButton>
    </template>
  </LDBModal>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

export default {
  name: 'LfxMergeTransactions',
  components: {},
  props: {},
  data() {
    return {
      otherTransactionsPool: {},
      selectedTransactions: [],
      configRecord:{
        paymentAction:undefined
      }
    };
  },
  created() {
    void this.populateOtherTransactionsPool();
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['transaction', 'deal']),
    //

    viewFields() {
      let res = [{
        name: 'paymentAction',
        label: '',
        view: 'edit',
        displayType: {
          datatype: 'option',
          display: 'radio',
        },
        datatype: {
          option: {
            optionType: 'text',
            options: [
              { id: 'import', name: 'Import Separate Payments' },
              { id: 'combine', name: 'Combine Payments' },
            ],
          },
        },
      }];
      return res;
    },

    // ----- Modal -----

    header() {
      return 'Merge Transaction Files';
    },
    modalSize() {
      return 'x-large'; // TODO
    },
    closable() {
      return false;
    },

    // ----- Transaction Record -----

    transactionId() {
      return +this.$route.params?.transactionId;
    },
    transactionRecord() {
      return this.transaction?.[this.transactionId];
    },

    // ----- Options -----

    otherTransactionOptions() {
      let res = {};
      for (let otherTransactionId in this.transaction) {
        let otherTransaction = this.transaction?.[otherTransactionId];
        if (
          otherTransaction.accountId === this.transactionRecord?.accountId &&
          ['awaitingPayment'].includes(otherTransaction.status) &&
          otherTransaction.transactionType === 'outwardDeal' &&
          otherTransaction.paymentCurrency === this.transactionRecord?.paymentCurrency &&
          otherTransaction.valueDate.substring(0, 10) === this.transactionRecord?.valueDate.substring(0, 10) &&
          +otherTransaction.id !== this.transactionId &&
          !otherTransaction.transactionLocked &&
          otherTransaction.checkIfManyToManyFunctionCanTakePlace &&
          otherTransaction.noPaymentsHaveBeenSubmitted &&
          otherTransaction.intermediaryId === this.transactionRecord?.intermediaryId
        ) {
          res[otherTransaction.id] = otherTransaction;
        }
      }
      console.log('OTHER TRANSACTION OPTIONS', res);
      return res;
    },
    otherTransactionOptionIds() {
      let res = [];
      for (let otherTransactionId in this.otherTransactionOptions) {
        let transaction = this.otherTransactionOptions[otherTransactionId];
        res.push(transaction.id);
      }
      return res;
    },
    numberOfOtherTransactionOptions() {
      return this.otherTransactionOptionIds?.length;
    },

    hasAnyExternalReferences() {
      let res = false;
      for (let otherTransactionId in this.otherTransactionOptions) {
        let otherTransaction = this.otherTransactionOptions[otherTransactionId];
        if (otherTransaction.externalReference) {
          return true;
        }
      }
      return res;
    },

    // ----- Buttons -----

    rightButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          action: 'click',
          guards: true,
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'mergeTransactionFiles',
          label: 'Merge Transactions',
          type: 'primary',
          action: 'mergeTransactionFiles',
          guards: true,
          enabledGuards: this.selectedTransactions.length > 0, // TODO
          params: { transactionId: this.transactionId },
          actionParams: { otherTransactionFileIds: this.selectedTransactions, paymentAction:this.configRecord.paymentAction }, // [otherTransacitonFileIds]
          clickEvent(response) {
            console.log('RESPONSE', response);
            if (response.status === 'success') {
              self.$router.go(-1);
            }
          },
        },
      ];
      return allButtons;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    //
    //

    on_change({ field, value }) {
      this.configRecord[field] = value;
    },

    populateOtherTransactionsPool() {
      for (let otherTransactionId in this.otherTransactionOptions) {
        if (!this.otherTransactionsPool[otherTransactionId]) {
          this.otherTransactionsPool[otherTransactionId] = { ...this.otherTransactionOptions?.[otherTransactionId] };
        }
      }
    },

    onCheck(otherTransactionOption, evt) {
      console.log(otherTransactionOption, evt);
      let transactionId = otherTransactionOption.id;
      this.otherTransactionsPool[transactionId].checked = evt.detail.new;
      this.updateSelectedTransactions();
    },

    updateSelectedTransactions() {
      this.selectedTransactions = [];
      for (let otherTransactionId in this.otherTransactionsPool) {
        let otherTransaction = this.otherTransactionsPool[otherTransactionId];
        if (otherTransaction.checked) {
          this.selectedTransactions.push(+otherTransactionId);
        }
      }
    },
  },
};
</script>
