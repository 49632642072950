//@ts-nocheck
import VueStore from 'vue-class-store';

import { LfxDealMarkupInt, LfxDealMarkupDef } from '@/definitions/LfxDealMarkupDef';

import { LfxModel, LfxDefinition } from './LfxModel';

@VueStore
class LfxDealMarkupMdl extends LfxDefinition {
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
export class LfxDealMarkup extends LfxModel {
  constructor(data, state) {
    super({definition_name:'deal_markup',...data}, state);
  }
}

export function Definition(context, name: string) {
  return new LfxDealMarkupMdl(LfxDealMarkupDef, LfxDealMarkup, context, name, {});
}
