//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LdbCurrencyDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Currency record for LandoByte TS systems
//  Created     : 31 August 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition } from '../definitions/LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------

export interface LdbCurrencyInt {
  id?:string,
  symbol?:string,
  decimalPlaces?:number,
}

//----------------------------------------------------------------------------------------------------------------------

export const LdbCurrencyDef:LdbDefinition = {
  apiUrl() {
    return '/currency'
  },
  title:"Currency",
  table:'LdbCurrency',
  collectionPath:"/config/1/currency",
  view:{create:true,edit:true,item:true,delete:false,csv:false,list:'LfxCurrencyTile'},
  indexes:[],
  pagingType:'frontEnd',
  fields:{
    id:{
      label:'Name',
      datatype:{'id':{descriptionField:'id',type:'text'}},
      default:null,
      mandatory:true,
      validators:{
        isAlpha:{msg:'Please use only alphanumeric charaters'},
        len:{msg:'Name should be 3 characters',args:[3,3]}
      },
      views:{create:true,edit:true,item:true,list:true,delete:false,csv:false}
    },
    symbol:{
      label:'Symbol',
      datatype:'text',
      default:null,
      mandatory:false,    
      validators:{
        len:{msg:'Symbol should be between 1 and 5 characters',args:[1,5]}
      },
      
      views:{create:true,edit:true,item:true,list:true,delete:false,csv:false}
    },
    decimalPlaces:{
      label:'Decimal Places',
      datatype:'integer',
      default:0,
      mandatory:false,
      validators:{
        isNumeric:{msg:'Decimal Place should be numeric'},
        max:{msg:'Decimal Places can not be greater than 3',args:[3]}
      },
      views:{create:true,edit:true,item:true,list:true,delete:false,csv:false}
    }
 }
}
