<template>
  <LDBModal :result="headerType" size="x-large" :header="header">
    <template #body>
      <div>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="definition"
          :record="record"
          @change="on_change"
        />
      </div>
      <template v-if="action === 'changeClientIntermediary' && newAccountIsDirectClientsIntermediary">
        <template v-if="record.accountType === 'individual'">
          <p>Individual Clients cannot be moved to the Direct Clients Intermediary.</p>
          <p>Did you perhaps mean to move the Client to another Bank Intermediary?</p>
        </template>
        <template v-else>
          <p>The new Intermediary selected is for the select handful of Direct Clients.</p>
          <p>
            Are you sure you wish to move the client to this Intermediary, and not one of the other Bank Intermediaries?
          </p>
          <capitec-toggle label="Yes, I'm sure" :checked="yesImSure" @value-change="switch_yesImSure"></capitec-toggle>
          <p>Have you obtained the required approvals to move this Client to the Direct Client Intermediary?</p>
          <capitec-toggle
            label="Yes, I have approval"
            :checked="yesIHaveApproval"
            @value-change="switch_yesIHaveApproval"
          ></capitec-toggle>
        </template>
      </template>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in buttons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import lodash from 'lodash';
const merge = lodash.merge;

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxMoveAccount',
  components: { LDBField },
  props: {},
  emits: {},
  created() {},
  mounted() {},
  beforeUnmount() {
    this.record.revert();
  },
  data() {
    return {
      yesImSure: false,
      yesIHaveApproval: false,
    };
  },

  computed: {
    ...mapGetters([]),
    ...mapState(['account']),
    header() {
      return this.action === 'changeClientIntermediary'
        ? `Change Client Intermediary: ${this.record.name}`
        : `Move ${this.accountLabel}: ${this.record.name}`;
    },
    headerType() {
      if (this.action === 'changeClientIntermediary' && this.newAccountIsDirectClientsIntermediary) {
        if (this.record?.accountType === 'individual') {
          return 'error';
        } else if (!this.succeedsDirectClientsTest) {
          return 'warning';
        } else {
          return 'info';
        }
      }
      return 'info';
    },

    buttons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelMoveAccount',
          label: 'Cancel',
          type: '',
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'moveAccount',
          label: this.moveAccountButtonLabel,
          type: 'primary',
          action: this.action,
          actionParams: this.record,
          params: { accountId: this.recordId },
          enabledGuards: this.accountIdHasChanged && this.succeedsDirectClientsTest,
          clickEvent(response) {
            if (response.status === 'success') {
              self.$router.go(-1);
            }
          },
        },
      ];
      return allButtons;
    },

    moveAccountButtonLabel() {
      return this.$route.meta?.modal?.buttonLabel;
    },
    action() {
      return this.$route.meta?.modal?.action;
    },

    accountIdHasChanged() {
      return this.record?.changed_fields?.some((field) => field.name === 'accountId');
    },

    succeedsDirectClientsTest() {
      if (this.newAccountIsDirectClientsIntermediary) {
        if (this.record?.accountType === 'individual') {
          return false;
        } else if (this.yesImSure && this.yesIHaveApproval) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },

    // ----- Definition -----

    definition() {
      return getDefinition(this.accountLevel);
    },

    // ----- Record -----

    recordName() {
      return 'account';
    },
    recordId() {
      return +this.$route.params.accountId;
    },
    record() {
      let rec = this.$store.state?.[this.recordName]?.[this.recordId];
      return rec;
    },

    accountLevel() {
      return this.record?.accountLevel;
    },
    accountLabel() {
      if (this.record?.accountLevel === 'bank') {
        return 'Bank';
      } else if (this.record?.accountLevel === 'intermediary') {
        return 'Intermediary';
      } else if (this.record?.accountLevel === 'intermediaryBranch') {
        return 'Branch';
      } else if (this.record?.accountLevel === 'client') {
        return 'Client';
      } else {
        return 'Account';
      }
    },

    newAccountIsDirectClientsIntermediary() {
      let res = false;
      if (this.accountIdHasChanged && this.account?.[this.record?.accountId]?.allowClientsToSubmitPortfolios === true) {
        res = true;
      }
      return res;
    },

    // ----- Fields -----

    fields() {
      return this.definition.fields;
    },
    view() {
      return 'moveAccount';
    },
    viewFields() {
      let res = [];
      for (let k in this.fields) {
        let field = this.fields[k];
        if (field.views[this.view] === true) {
          field.fieldname = k;
          res.push(this.overrideField(field));
        }
      }
      return res;
    },
  },

  methods: {
    //
    // ----- Fields ----

    on_change({ field, value }) {
      this.record[field] = value;
    },
    switch_yesImSure() {
      this.yesImSure = !this.yesImSure;
    },
    switch_yesIHaveApproval() {
      this.yesIHaveApproval = !this.yesIHaveApproval;
    },

    overrideField(field) {
      if (field.name === 'accountId' && (this.action === 'moveClient' || this.action === 'moveBranch')) {
        return this.overrideAccountIdForMoveClientOrBranch(field);
      } else if (field.name === 'accountId' && this.action === 'moveAccount') {
        return this.overrideAccountIdForMoveAccount(field);
      } else if (field.name === 'accountId' && this.action === 'changeClientIntermediary') {
        return this.overrideAccountIdForChangeClientIntermediary(field);
      } else return field;
    },
    overrideAccountIdForMoveClientOrBranch(field) {
      let newField = merge({}, field);
      let newAccountIdField = merge(newField, {
        datatype: {
          foreignKey: {
            guards: {
              accountLevel: {
                in: ['intermediary', 'intermediaryBranch'],
              },
              intermediaryId(record) {
                return { eq: record.intermediaryId };
              },
              id(record) {
                return { notEq: record.accountId };
              },
            },
          },
        },
      });
      newAccountIdField.label = 'Move to Account';
      return newAccountIdField;
    },
    overrideAccountIdForMoveAccount(field) {
      let newField = merge({}, field);
      let newAccountIdField = merge(newField, {
        datatype: {
          foreignKey: {
            guards: {
              accountLevel: {
                notIn: ['client'],
              },
              id(record) {
                return { notEq: record.accountId };
              },
            },
          },
        },
      });
      newAccountIdField.label = 'Move to Account';
      return newAccountIdField;
    },
    overrideAccountIdForChangeClientIntermediary(field) {
      let newField = merge({}, field);
      let newAccountIdField = merge(newField, {
        datatype: {
          foreignKey: {
            guards: {
              accountLevel: {
                in: ['intermediary'],
              },
              status: { eq: 'active' },
              visibility: { eq: 'visible' },
              intermediaryId(record) {
                return { notEq: record.intermediaryId };
              },
            },
          },
        },
      });
      newAccountIdField.label = 'Move to Intermediary';
      return newAccountIdField;
    },
  },
};
</script>
