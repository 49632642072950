<template>
  <LDBModal result="info" :header="header" size="large">
    <template #body>
      <h3>{{accountRecord?.name}}<br/>{{bankAccountRecord?.accountNumber}}</h3>
      <div v-if="this.balanceStatus === 'none'">
        <p>Balance not available</p>
      </div>
      <div v-else-if="balanceStatus === 'requested'">
        <p>Retrieving Balance...</p>
      </div>
      <div v-else-if="this.balanceStatus === 'failed'">
        <p>Balance Error</p>
        {{ this.balanceError.displayMessage }}
      </div>
      <div v-else-if="this.balanceStatus === 'success'">
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="item"
          :fieldname="field.name"
          :definition="undefined"
          :record="balanceData"
        />
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in buttons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
        @on-api-success="onApiSuccess"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import { doPermissionAction } from '@/lfx_rest/lfx_action';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

export default {
  name: 'LfxBankAccountBalance',
  components: { LDBLoader },
  props: {},
  emits: {},
  async created() {
    this.balanceStatus = 'requested';
    const balanceResult = await doPermissionAction(this, 'balanceEnquiry', {
      accountId: this.accountId,
      bankAccountId: this.bankAccountId,
    });
    if (balanceResult.status === 'success') {
      this.balanceStatus = 'success';
      this.balanceData.currentBalance = balanceResult.response.currentBalance * 100;
      this.balanceData.availableBalance = balanceResult.response.availableBalance * 100;
      this.balanceData.accountStatus = balanceResult.response.accountStatus;
      this.balanceData.holdAmount = balanceResult.response.holdAmount * 100;
    } else {
      this.balanceStatus = 'failed';
      this.balanceError = balanceResult?.response?.response?.data?.error || balanceResult?.response?.response?.data;
      if (this.balanceError.message) {
        this.balanceError.displayMessage = this.balanceError.message
        if (balanceResult?.response?.response?.data?.error.codeReason === 'BANCS-1018') {
          this.isBancsPasswordError = true;
          this.balanceError.displayMessage = this.backsPasswordErrorMessage
        }
        if (balanceResult?.response?.response?.data?.error.codeReason) {
          this.balanceError.displayMessage = balanceResult?.response?.response?.data?.error.codeReason + " - " + this.balanceError.displayMessage
        }
      } else {
        this.balanceError.displayMessage = 'An Unknown Error has Occured';
      }
    }
  },
  mounted() {},
  data() {
    return {
      onSuccess: {
        doStoreUpdate: false,
      },
      balanceStatus: 'none',
      balanceError: '',
      isBancsPasswordError: false,
      balanceData: {
        currentBalance: undefined,
        availableBalance: undefined,
        accountStatus: undefined,
        holdAmount: undefined,
      },
    };
  },
  computed: {
    ...mapGetters(['config']),
    ...mapState(['account','authUser','user']),

    // ----- Account record -----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },

    // ----- Markup record ----

    bankAccountId() {
      return +this.$route.params?.bankAccountId;
    },
    bankAccountRecord() {
      return this.accountRecord?.bankAccounts?.[this.bankAccountId];
    },
    currencyId() {
      return this.bankAccountRecord?.currencyId;
    },
    currencyRecord() {
      return this.config?.currency?.[this.currencyId];
    },
    decimalPlaces() {
      return this.currencyRecord?.decimalPlaces;
    },

    hasBalance() {
      return this.testingData;
    },
    header() {
      return `Bank Account Balance ${this.bankAccountRecord?.accountNumber}`;
    },
    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta?.modal?.cancelRoute : 'todo';
    },
    buttons() {
      let self = this;
      let allButtons = [
        {
          name: 'reportBancsPasswordError',
          label: 'Report',
          type: 'primary',
          guards: this.isBancsPasswordError,
          action: 'url',
          actionParams: {url:this.getReportEmailText()},
        },
        {
          name: 'closeBalanceModal',
          label: 'Close',
          type: 'primary',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
      ];
      return allButtons.filter((button) => button.guards);
    },
    viewFields() {
      const allFields = [
        {
          name: 'currentBalance',
          label: 'Current Balance',
          datatype: { currency: { symbol: this.bankAccountRecord?.currencyId, decimalPlaces: this.decimalPlaces } },
        },
        {
          name: 'availableBalance',
          label: 'Available Balance',
          datatype: { currency: { symbol: this.bankAccountRecord?.currencyId, decimalPlaces: this.decimalPlaces } },
        },
        {
          name: 'holdAmount',
          label: 'Hold Amount',
          datatype: { currency: { symbol: this.bankAccountRecord?.currencyId, decimalPlaces: this.decimalPlaces } },
        },
        { name: 'accountStatus', label: 'Account Status', datatype: 'text' },
      ];
      return allFields;
    },
    backsPasswordErrorMessage() {
      return "An error has occured with your access to the Bancs system. Please report this using the Report button below"
    }
  },
  methods: {
    onApiSuccess() {},
    getReportEmailText() {
      const myUser = this.user[this.authUser.id] || {};
      const mailTo = "dylonnNagel@capitecbank.co.za,eugeneMorris@capitecbank.co.za,michaelparadza@capitecbank.co.za";
      const cc = "kimChikwata@capitecbank.co.za,jimmykena@capitecbank.co.za,luckyMahole@capitecbank.co.za,support@landobyte.com";
      return `mailto:${mailTo}?cc=${cc}&subject=Bancs%20Password%20Reset%20-%20${myUser.displayName}&Body=Good%20Day%20Team,%0d%0a%0d%0aPlease%20assist%20with%20a%20Bancs%20password%20reset%20for%20AD%20User%20${myUser.esbUsername}.%0d%0a%0d%0aKind%20Regards,%0d%0a${myUser.displayName}`
    },
  },
};
</script>
