<template>
  <LFXContent
    :titleBar="titleBar"
    :show_tabs="showTabs"
    :tabs="tabs"
    :show_stepper="showStepper"
    :show_label="showLabel"
    :label="contentLabel"
    :show_actions="showActions"
    :show_banners="showBanners"
    :form_layout="formLayout"
    :show_sidebar="false"
  >
    <template v-if="true" #banners>
      <LfxBanner
        v-for="banner in banners"
        :key="banner.id"
        :title="banner.title"
        :status="banner.status"
        :closable="banner.closable"
        ><div v-html="banner.content"></div
      ></LfxBanner>
    </template>

    <template #actions>
      <LDBButton
        v-for="button in actionBarButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>

    <template #stepper></template>

    <template #content>
      <router-view v-if="includeRefreshCounter" :refreshCounter="refreshCounter"></router-view>
      <router-view v-else></router-view>
      <router-view name="associatedCifs" :key="cifId || undefined"></router-view>
    </template>
  </LFXContent>

  <router-view @bsc-code-selected="bscCodeSelected" @oc-code-selected="oCodeSelected" name="modal"></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXContent from '@/components/layout/LfxContent.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import { doPermissionAction } from '@/lfx_rest/lfx_action';
import { getOptionLabel } from '@/sharedUtils/LdbDefinitionUtils';

export default {
  name: 'LfxClientApplication',
  components: { LFXContent, LDBLoader },
  props: {},
  data() {
    return {
      refreshCounter: 0,
    };
  },
  created() {
    // if (!this.applicationRecord?.isDataComplete) {
    //   await doPermissionAction(this, 'getApplication', { accountId: this.clientId, applicationId: this.applicationId });
    // }
    doPermissionAction(this, 'getClientApplicationDocuments', {
      accountId: this.clientId,
      applicationId: this.applicationId,
    });
    if (this.$route.name === 'clientApplication') {
      this.selectFirstTab();
    }
  },
  mounted() {
    this.printStatuses()
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'clientApplication' && to.params.applicationId === from.params.applicationId) {
      next(false);
      this.selectFirstTab();
    } else {
      next();
    }
    // TODO - add a check so that you can't navigate to the "No Ass CIF" if there are Ass CIFs, and vice versa
  },
  computed: {
    ...mapGetters(['permissions', 'isDevelopment']),
    ...mapState(['account', 'systemSettings', 'authUser']),

    // hasLoaded() {
    //   return this.applicationRecord?.isDataComplete;
    // },

    // ----- Title Bar -----

    titleBar() {
      const titleBar = {
        label: this.titleBarLabel,
        buttons: this.titleBarButtons,
      };
      return titleBar;
    },

    titleBarLabel() {
      // return `Client: ${this.clientRecord.name} / ${this.applicationTypeLabel}: ${this.applicationRecord.reference}`;
      return `${this.clientRecord.name} / ${this.applicationTypeLabel}: ${this.applicationRecord.reference}`;
    },
    applicationTypeLabel() {
      switch (this.applicationRecord.applicationType) {
        case 'newClient':
          return 'New Client Application';
        case 'newAccount':
          return 'New Bank Account Application';
        case 'amendClient':
          return 'Amend Client Application';
        case 'amendAccount':
          return 'Amend Bank Account Application';
        default:
          return 'Application';
      }
    },

    titleBarButtons() {
      let allButtons = [
        {
          name: 'resumeApplicationWizard',
          label: this.resumeApplicationWizardLabel, // TODO - make dynamic based on permission and application status
          type: 'white',
          routeName: 'clientApplicationWizard',
          guards: this.resumeApplicationWizard_buttonGuard && 
              this.applicationRecord.visibility !== 'hidden',
        },
        {
          name: 'backToClient',
          label: 'Back to Client',
          type: 'white',
          routeName: 'accountDetailsView',
          routeParams: { accountId: this.clientId },
          guards: true,
        },
      ];
      return allButtons.filter((button) => button.guards);
    },

    resumeApplicationWizard_buttonGuard() {
      // console.log({
      //   editApplication_buttonGuard:this.applicationRecord.editApplication_buttonGuard,
      //   submitApplicationPortfolio_buttonGuard:this.applicationRecord.submitApplicationPortfolio_buttonGuard,
      //   applicationIsCraSubmitable:this.applicationRecord.applicationIsCraSubmitable,
      //   craStatus:this.applicationRecord.craStatus
      // })
      return (
        !!this.applicationRecord.editApplication_buttonGuard ||
        !!this.applicationRecord.submitApplicationPortfolio_buttonGuard || 
        ((!!this.applicationRecord.applicationIsCraSubmitable || this.applicationRecord.craStatus === 'eddRequested') && this.permissions?.submitApplicationCra)
      );
    },
    resumeApplicationWizardLabel() {
      return this.applicationRecord.editApplication_buttonGuard &&
        (this.applicationRecord.submitApplicationPortfolio_buttonGuard || this.applicationRecord.applicationIsCraSubmitable)
        ? 'Edit and Submit Application'
        : this.applicationRecord.editApplication_buttonGuard
        ? 'Edit Application'
        : ((this.applicationRecord.submitApplicationPortfolio_buttonGuard || this.applicationRecord.applicationIsCraSubmitable) && this.applicationRecord?.thisApplicationDocumentUploadType === 'local' && (['rejected','error'].includes(this.applicationRecord.integrationStatus) || this.applicationRecord?.outstandingDocumentTypes?.length !== 0))
        ? 'Upload Documents or Submit Application'
        : (this.applicationRecord.submitApplicationPortfolio_buttonGuard || this.applicationRecord.applicationIsCraSubmitable)
        ? 'Submit Application'
        : this.applicationRecord.craStatus === 'eddRequested'
        ? 'Upload EDD Document'
        : 'Application';
    },
    includeRefreshCounter() {
      return ['clientApplicationPortfolioLogs', 'clientApplicationLogs'].includes(this.actionGroup);
    },
    // ----- Tabs -----

    showTabs() {
      return true;
    },
    tabs() {
      let tabs = [
        {
          tabName: 'overview',
          routeName: 'clientApplicationOverview',
          guards: true,
          label: 'Overview',
        },
        {
          tabName: 'summary',
          routeName: 'clientApplicationSummary',
          guards: true,
          label: 'Cover Page',
        },
        {
          tabName: 'clientCif',
          routeName: 'clientApplicationClientCifDetailsView',
          routeParams: { cifId: this.fullCifId },
          guards: true,
          label: 'Client Details',
        },
        {
          tabName: 'associatedCifs',
          routeName: this.applicationRecord?.hasAssociatedCifs
            ? 'clientApplicationAssociatedCifDetailsView'
            : 'clientApplicationNoAssociatedCifs',
          routeParams: this.applicationRecord?.hasAssociatedCifs ? { cifId: this.firstAssCifId } : undefined,
          guards: true,
          label: 'Associated Parties',
        },
        {
          tabName: 'bankAccounts',
          routeName: 'clientApplicationBankAccountDetailsView',
          routeParams: { applicationAccountId: this.firstBankAccountApplicationId },
          guards: true,
          label: 'Bank Account',
        },
        {
          tabName: 'signatories',
          routeName: 'clientApplicationSignatories',
          routeParams: undefined, // TODO
          guards: this.applicationRecord?.thisApplicationDocumentUploadType !== 'local',
          label: 'Signatories',
        },
        {
          tabName: 'uploadDocuments',
          routeName: 'clientApplicationDocuments',
          routeParams: { applicationDocumentId: this.firstDocumentId },
          guards: 
            this.applicationRecord?.thisApplicationDocumentUploadType === 'local' 
            || ['eddRequested','eddSubmitted'].includes(this.applicationRecord.craStatus) 
            || (this.applicationRecord.localDocuments && typeof this.applicationRecord.localDocuments === 'object' && Object.keys(this.applicationRecord.localDocuments).length > 0),
          label: this.applicationRecord?.thisApplicationDocumentUploadType === 'signiFlow'? 'EDD Documents':'Documents',
        },
        {
          tabName: 'portfolio',
          routeName: 'todo', // TODO,
          routeParams: undefined, // TODO
          guards: false, // TODO - revisit this when we can portfolio again
          label: 'Portfolio', // TODO
        },
        {
          tabName: 'logs',
          routeName: 'clientApplicationLogs',
          routeParams: undefined,
          guards: true,
          label: 'Logs',
        },
      ];
      return tabs.filter((tab) => tab.guards);
    },

    // ----- Stepper -----

    showStepper() {
      return false;
    },
    steps() {
      return [];
    },

    // ----- Content Label -----

    showLabel() {
      return this.contentLabel ? true : false;
    },
    contentLabel() {
      return this.$route.meta?.content?.label || "Navigation didn't complete, please click tab above to continue";
    },

    // ----- Action Bar -----

    showActions() {
      return this.actionBarButtons.length !== 0 ? true : false;
    },
    submitCifCraButton() {
      let self = this;
      return {
        name: 'submitCifCra',
        label: 'Submit CIF CRA',
        type: 'primary',
        modal: {
          header: `Submit CIF CRA Application`,
          cancelLabel: 'Cancel',
          confirmLabel: 'Submit',
          content: this.submitCifCraModalMessage,
        },
        action: 'submitCifCra',
        params: { accountId: this.clientId, applicationId: this.applicationId, cifId: this.cifId },
        guards:
          (this.applicationRecord?.applicationIsCraSubmitable || this.applicationRecord.craStatus === 'inProgress' && this.cifRecord?.craStatus === 'none') &&
          this.cifRecord?.is_submittable &&
          (this.applicationRecord?.thisApplicationDocumentUploadType !== 'local' || this.applicationRecord?.outstandingDocumentTypes?.length === 0) &&
          ['new','error', 'rejected'].includes(this.cifRecord?.status) &&
          ['none','error',null,undefined].includes(this.cifRecord?.craStatus),
        enabledGuards: (this.isFullCifRecord || this.fullCifRecord?.craStatus !== 'none')
      };
    },
    submitCifButton() {
      let self = this;
      return {
        name: 'submitCif',
        label: 'Submit CIF',
        type: 'primary',
        modal: {
          header: `Submit CIF Application`,
          cancelLabel: 'Cancel',
          confirmLabel: 'Submit',
          content: this.submitCifModalMessage,
        },
        action: 'submitCifApplication',
        params: { accountId: this.clientId, applicationId: this.applicationId, cifId: this.cifId },
        guards:
          this.applicationRecord?.applicationIsSubmitable &&
          this.cifRecord?.is_submittable &&
          (this.applicationRecord?.thisApplicationDocumentUploadType !== 'local' || this.applicationRecord?.outstandingDocumentTypes?.length === 0) &&
          (['error', 'rejected'].includes(this.cifRecord?.status) || //Should not include new or cancelled
            (this.cifRecord?.status === 'new' && this.applicationRecord.status === 'partiallyCompleted') ||
            (this.applicationRecord.status === 'inProgress' && ['none',null,undefined].includes(this.applicationRecord.integrationStatus))),
        enabledGuards: (this.isFullCifRecord || !!this.fullCifRecord?.cifNumber)
      };
    },
    addAssociatedCifButton() {
      let self = this;
      return {
        name: 'addAssociatedCif',
        label: 'Add Associated Party',
        type: '',
        guards:
          this.permissions?.addAssCifApplication &&
          this.applicationRecord?.applicationIsEditable &&
          this.$route.meta?.content?.cifType === 'associated' && 
              this.applicationRecord.visibility !== 'hidden',
        clickEvent() {
          let routeName = self.cifId
            ? 'clientApplicationAssociatedCifsDetailsViewAdd'
            : 'clientApplicationNoAssociatedCifsAdd';
          self.$router.push({ name: routeName });
        },
      };
    },
    cancelCifButton() {
      let self = this;
      return {
        name: 'cancelCif',
        label: 'Cancel Associated Party',
        type: '',
        params: { accountId: this.clientId, applicationId: this.applicationId, cifId: this.cifId },
        guards:
          this.applicationRecord?.applicationIsEditable &&
          ['new', 'error'].includes(this.cifRecord?.status) &&
          this.cifRecord?.cifType === 'associated' &&
          !!this.permissions.cancelCifApplication,
        clickEvent() {
          self.$router.push({ name: 'clientApplicationAssociatedCifDetailsViewCancel' });
        },
      };
    },
    submitBankAccountButton() {
      let self = this;
      return {
        name: 'submitBankAccount',
        label: 'Submit Bank Account',
        type: 'primary',
        action: 'submitAccountApplication',
        modal: {
          header: `Submit Account Application`,
          cancelLabel: 'Cancel',
          confirmLabel: 'Submit',
          content: this.submitAccountModalMessage,
        },
        params: {
          accountId: this.clientId,
          applicationId: this.applicationId,
          applicationAccountId: this.applicationBankAccountId,
        },
        guards:
          this.applicationRecord?.applicationIsSubmitable &&
          this.bankAccountRecord?.is_submittable &&
          (['error', 'rejected'].includes(this.bankAccountRecord?.status) || //Should not include new or cancelled
            (this.bankAccountRecord?.status === 'new' && this.applicationRecord.status === 'partiallyCompleted')),
        // clickEvent: 'onLDBButtonClick', // TODO remove this - avr
      };
    },
    cancelBankAccountButton() {
      let self = this;
      return {
        name: 'cancelBankAccount',
        label: 'Cancel Bank Account',
        type: '',
        params: {
          accountId: this.clientId,
          applicationId: this.applicationId,
          applicationAccountId: this.applicationBankAccountId,
        },
        guards:
          false && // Note: This is for future functionality when more than ZAR account can be applied for
          ['new', 'error'].includes(this.bankAccountRecord?.status) &&
          !!this.permissions.cancelAccountApplication,
        clickEvent() {
          self.$router.push({ name: 'clientApplicationBankAccountDetailsViewCancel' });
        },
      };
    },
    cancelEditButton() {
      let self = this;
      return {
        name: 'cancelEdit',
        label: 'Cancel',
        type: '',
        action: 'click',
        guards: true,
        clickEvent() {
          self.$router.go(-1);
        },
      };
    },

    actionBarButtons() {
      let self = this;
      const resendPortfolioEmailButton = {
        name: 'resendApplicationPortfolioEmails',
        label: 'Resend Portfolio Emails',
        type: '',
        params: { accountId: this.clientId, applicationId: this.applicationId },
        guards: this.permissions?.resendApplicationPortfolioEmails && this.applicationRecord.visibility !== 'hidden' && ['readyForSigning','signed','signedNoMetaData','metaDataNoSignature',].includes(this.applicationRecord.applicationPortfolioStatus),
        clickEvent() {
          self.$router.push({ name: 'resendApplicationPortfolioEmails' });
        },
      }

      let allButtons = {
        //
        // Summary
        clientApplicationOverview: [
          resendPortfolioEmailButton,
          {
            name: 'resetApplicationStatusButton',
            label: 'Reset Application Status',
            type: '',
            params: { accountId: this.clientId, applicationId: this.applicationId },
            guards: this.permissions?.resetApplicationStatus && this.applicationRecord.visibility !== 'hidden' && this.applicationRecord.status !== 'completed',
            clickEvent() {
              self.$router.push({ name: 'resetApplicationStatus' });
            },
          },
          {
            name: 'hideApplication',
            label: 'Hide Application',
            type: '',
            params: { accountId: this.clientId, applicationId: this.applicationId },
            guards: this.permissions?.hideApplication && this.applicationRecord.visibility !== 'hidden' && this.applicationRecord.canHideApplication,
            clickEvent() {
              self.$router.push({ name: 'clientApplicationHide' });
            },
          },
          {
            name: 'unhideApplication',
            label: 'Unhide Application',
            type: '',
            action: 'unhideApplication',
            modal: {
              header: `Unhide Application`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Unhide',
              content: this.unhideApplicationModalMessage,
            },
            params: { accountId: this.clientId, applicationId: this.applicationId },
            guards: this.applicationRecord.visibility === 'hidden',
          },
        ],

        clientApplicationSummary: [
          {
            name: 'unifiedRelease',
            label: 'Release Application',
            type: 'primary',
            guards:
              !['completed'].includes(this.applicationRecord?.status) &&
              (!this.craEnabled || ['approved'].includes(this.applicationRecord.craStatus)) &&
              !!this.permissions.releaseCifApplication &&
              !!this.permissions.releaseAccountApplication && 
              this.applicationRecord.visibility !== 'hidden',
            action: 'click',
            clickEvent() {
              self.$router.push({ name: 'clientApplicationSummaryUnifiedRelease' });
            },
          },
          {
            name: 'wizard',
            label: self.resumeApplicationWizardLabel, // TODO - make dynamic based on permission and application status
            type: 'primary',
            guards: self.resumeApplicationWizard_buttonGuard && 
              this.applicationRecord.visibility !== 'hidden',
            enabledGuards: this.authUser.accountIsActive,
            action: 'click',
            clickEvent() {
              self.$router.push({ name: 'clientApplicationWizard' });
            },
          },
          {
            name: 'submitApplicationCra',
            label: 'Submit Application for CRA',
            type: 'primary',
            action: 'submitApplicationCra',
            modal: {
              header: `Submit Application for CRA`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Submit',
              content: this.submitApplicationCraModalMessage,
            },
            params: { accountId: this.clientId, applicationId: this.applicationId },
            // guards: this.applicationRecord?.applicationPortfolioStatus === 'signed',
            guards: !!this.applicationRecord?.applicationIsCraSubmitable && 
              this.applicationRecord.visibility !== 'hidden',
            enabledGuards:
              this.applicationRecord.is_submittable &&
              ['notStarted','error','reOpened',null,undefined].includes(this.applicationRecord.craStatus) &&
              !['inProgress', 'completed'].includes(this.applicationRecord.status) && //'partiallyCompleted'
              (this.applicationRecord?.thisApplicationDocumentUploadType !== 'local' || this.applicationRecord?.outstandingDocumentTypes?.length === 0) &&
              this.authUser.accountIsActive,
          },
          {
            name: 'submitApplication',
            label: 'Submit Application',
            type: 'primary',
            action: 'submitApplication',
            modal: {
              header: `Submit Application`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Submit',
              content: this.submitApplicationModalMessage,
            },
            params: { accountId: this.clientId, applicationId: this.applicationId },
            // guards: this.applicationRecord?.applicationPortfolioStatus === 'signed',
            guards: !!this.applicationRecord?.applicationIsSubmitable && 
              this.applicationRecord.visibility !== 'hidden',
            enabledGuards:
              this.applicationRecord?.applicationIsSubmitable &&
              this.applicationRecord.is_submittable &&
              (!['inProgress', 'completed'].includes(this.applicationRecord.status) || (this.applicationRecord.status === 'inProgress' &&  ['none',null,undefined].includes(this.applicationRecord.integrationStatus))) &&
              (this.applicationRecord?.thisApplicationDocumentUploadType !== 'local' || this.applicationRecord?.outstandingDocumentTypes?.length === 0) &&
              this.authUser.accountIsActive,
            // clickEvent: 'onLDBButtonClick', // TODO remove this - avr
          },
          {
            name: 'viewPortfolio',
            label: 'View Portfolio',
            type: '',
            guards: this.applicationRecord.viewClientPortfolio_buttonGuard && 
              this.applicationRecord.visibility !== 'hidden',
            async clickEvent() {
              const viewPortfolioResult = await doPermissionAction(
                { onSuccess: { doStoreUpdate: false } },
                'getClientPortfolioLink',
                { accountId: self.clientId, applicationId: self.applicationId }
              );
              if (viewPortfolioResult.status === 'success') {
                window.open(viewPortfolioResult.response.portfolioUrl);
              } else {
                self.showToast({
                  // TODO this method doesn't exist on this component
                  type: 'error',
                  header: 'Error Building Link',
                  message: viewPortfolioResult.response.message,
                  show: true,
                });
              }
            },
            
          },
          resendPortfolioEmailButton,
          {
            name: 'getClientPortfolioSummary',
            label: 'Portfolio Summary',
            type: '',
            guards: this.applicationRecord.clientPortfolioSummary_buttonGuard && 
              this.applicationRecord.visibility !== 'hidden',
            clickEvent() {
              self.$router.push({ name: 'clientApplicationSummaryPortfolioSummary' });
            },
          },
          {
            name: 'addClientPortfolioDocument',
            label: 'Add Document',
            type: '',
            guards: this.applicationRecord.addDocumentPayment_buttonGuard && 
              this.applicationRecord.visibility !== 'hidden',
            enabledGuards: this.authUser.accountIsActive,
            clickEvent() {
              self.$router.push({ name: 'clientApplicationSummaryAddDocument' });
            },
          },
          { name: 'removeDocument' }, // TODO
          {
            name: 'recallClientPortfolio',
            label: 'Recall Application Portfolio',
            type: '',
            guards: this.applicationRecord.recallClientPortfolio_buttonGuard && 
              this.applicationRecord.visibility !== 'hidden',
            enabledGuards: this.authUser.accountIsActive,
            clickEvent() {
              self.$router.push({ name: 'clientApplicationSummaryRecallPortfolio' });
            },
          },
          {
            name: 'updatePortfolioStatus',
            label: 'Update Portfolio Status',
            type: '',
            guards: this.applicationRecord.updateClientPortfolioStatus_buttonGuard && 
              this.applicationRecord.visibility !== 'hidden',
            clickEvent() {
              self.$router.push({ name: 'clientApplicationSummaryUpdatePortfolioStatus' });
            },
          },

          {
            name: 'updateStatus',
            label: 'Recalculate Status',
            type: '',
            // action: 'click',
            action: 'updateApplicationStatus',
            modal: {
              header: `Update Application Status`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Recalculate Status',
              content: this.updateStatusModalMessage,
            },
            params: { accountId: this.clientId, applicationId: this.applicationId },
            guards: true && 
              this.applicationRecord.visibility !== 'hidden',
            // clickEvent() {
            //   self.$router.push({ name: 'clientApplicationReviewUpdateStatus' });
            // },
          },
          {
            name: 'cancelApplication',
            label: 'Cancel Application',
            type: '',
            params: { accountId: this.clientId, applicationId: this.applicationId },
            // TODO for portfolio at signiflow - show disabled button explaining must recall portfolio
            // TODO for in-progess application - show disabled button explaining must contact account opening team to reject application
            guards: false && this.permissions?.cancelApplication && ['new', 'error'].includes(this.applicationRecord?.status) && 
              this.applicationRecord.visibility !== 'hidden',
            enabledGuards:
              ['new', 'readyForSubmission', 'signed', 'cancelled', 'deleted'].includes(
                this.applicationRecord?.applicationPortfolioStatus
              ) && this.authUser.accountIsActive,
            clickEvent() {
              self.$router.push({ name: 'clientApplicationSummaryCancel' });
            },
          },
          {
            name: 'rejectApplication',
            label: 'Reject Application',
            type: '',
            params: { accountId: this.clientId, applicationId: this.applicationId },
            guards:
              ['new', 'inProgress', 'partiallyCompleted'].includes(this.applicationRecord.status) &&
              (!this.craEnabled || ['notStarted','approved','declined' ,'error','reOpened',].includes(this.applicationRecord.craStatus)) &&
              !!this.permissions.rejectApplication && 
              this.applicationRecord.visibility !== 'hidden',
            clickEvent() {
              self.$router.push({ name: 'clientApplicationSummaryReject' });
            },
          },
        ],

        // CIF Details (Full or Associated)

        clientApplication_CifDetailsView: [
          this.submitCifButton,
          this.submitCifCraButton,
          {
            name: 'editCif',
            // label: 'Edit CIF',
            label: self.isFullCifRecord ? 'Edit Client Details' : 'Edit Associated Party',
            type: '',
            params: { accountId: this.clientId, applicationId: this.applicationId, cifId: this.cifId },
            guards:
              this.applicationRecord?.applicationIsEditable &&
              ['new', 'rejected', 'error'].includes(this.cifRecord?.status) &&
              (this.applicationRecord?.application_cif?.[this.fullCifId]?.accountType === 'entity'
                ? this.permissions.updateApplicationCifEntity
                : this.permissions.updateApplicationCifIndividual) && 
              this.applicationRecord.visibility !== 'hidden',
            clickEvent() {
              let routeName = self.isFullCifRecord
                ? 'clientApplicationClientCifDetailsEdit'
                : 'clientApplicationAssociatedCifDetailsEdit';
              self.$router.push({ name: routeName });
            },
          },
          this.addAssociatedCifButton,
          this.cancelCifButton,
          {
            name: 'releaseCif',
            label: 'Release CIF',
            type: 'primary',
            params: { accountId: this.clientId, applicationId: this.applicationId, cifId: this.cifId },
            guards: 
              !['completed'].includes(this.cifRecord?.status) && 
              (!this.craEnabled || ['approved'].includes(this.applicationRecord.craStatus)) &&
              !!this.permissions.releaseCifApplication,
            clickEvent() {
              let routeName = self.isFullCifRecord
                ? 'clientApplicationClientCifDetailsViewRelease'
                : 'clientApplicationAssociatedCifDetailsViewRelease';
              self.$router.push({ name: routeName });
            },
          },
          {
            name: 'rejectCif',
            label: 'Reject CIF',
            type: '',
            params: { accountId: this.clientId, applicationId: this.applicationId, cifId: this.cifId },
            guards:
              // ['new', 'inProgress', 'rejected'].includes(this.cifRecord?.status) &&
              ['new', 'inProgress'].includes(this.cifRecord?.status) && !!this.permissions.rejectCifApplication && 
              (!this.craEnabled || ['notStarted','approved','declined' ,'error','reOpened',].includes(this.applicationRecord.craStatus)) &&
              this.applicationRecord.visibility !== 'hidden',
            clickEvent() {
              let routeName = self.isFullCifRecord
                ? 'clientApplicationClientCifDetailsViewReject'
                : 'clientApplicationAssociatedCifDetailsViewReject';
              self.$router.push({ name: routeName });
            },
          },
          {
            name: 'approveCifCraButton',
            label: `Approve CRA`,
            type: '',
            modal: {
              header: `Approve CRA`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Approve',
              content: this.approveCraModalMessage,
            },
            action: 'approveCifCra',
            params: { accountId: this.clientId, applicationId: this.applicationId, cifId: this.cifId },
            guards: this.approveCifCra_buttonGuard,
          },
          {
            name: 'declineCifCraButton',
            label: `Decline CRA`,
            type: '',
            params: { accountId: this.clientId, applicationId: this.applicationId, cifId: this.cifId },
            guards: this.declineCifCra_buttonGuard && !!this.permissions?.declineCifCra,
            clickEvent() {
              self.$router.push({ name: 'declineCifCra' });
            },
          },
          {
            name: 'recallCifCraButton',
            label: `Recall CRA`,
            type: '',
            modal: {
              header: `Recall CRA`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Recall',
              content: this.recallCifCraModalMessage,
            },
            action: 'recallCifCra',
            params: { accountId: this.clientId, applicationId: this.applicationId, cifId: this.cifId },
            guards: this.recallCifCra_buttonGuard,
          },
          {
            name: 'requestCraEddButton',
            label: `Request CRA EDD`,
            type: '',
            params: { accountId: this.clientId, applicationId: this.applicationId, cifId: this.cifId },
            guards: this.requestCifCraEdd_buttonGuard && !!this.permissions.requestCraEdd,
            clickEvent() {
              self.$router.push({ name: 'requestCraEdd' });
            },
          },
          {
            name: 'submitCraEddButton',
            label: `Submit CRA EDD`,
            type: 'primary',
            modal: {
              header: `Submit CRA EDD`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Submit',
              content: this.submitCifCraEddModalMessage,
            },
            action: 'submitCraEdd',
            params: { accountId: this.clientId, applicationId: this.applicationId, cifId: this.cifId },
            guards: this.submitCifCraEdd_buttonGuard,
          },
        ],

        clientApplication_CifDetailsEdit: [
          {
            name: 'tempBscPicker',
            label: 'Business Sector Code Picker',
            type: '',
            guards: this.cifRecord?.accountType === 'entity' && this.cifRecord?.status !== 'cancelled' && 
              this.applicationRecord.visibility !== 'hidden',
            clickEvent() {
              let routeName =
                self.$route.meta.content?.cifType === 'full'
                  ? 'clientApplicationClientCifDetailsEditBscPicker'
                  : 'clientApplicationAssociatedCifDetailsEditBscPicker';
              self.$router.push({ name: routeName });
            },
          },
          {
            name: 'tempOcPicker',
            label: 'Occupation Code Picker',
            type: '',
            guards: this.cifRecord?.accountType === 'individual' && this.$route.meta.content?.cifType === 'full' && 
              this.applicationRecord.visibility !== 'hidden',
            clickEvent() {
              let routeName =
                self.$route.meta.content?.cifType === 'full'
                  ? 'clientApplicationClientCifDetailsEditOcPicker'
                  : 'clientApplicationAssociatedCifDetailsEditOcPicker';
              self.$router.push({ name: routeName });
            },
          },
          this.cancelEditButton,
        ],

        // No Associated CIFs

        clientApplicationNoAssociatedCifs: [this.addAssociatedCifButton],

        // Bank Account Details View

        clientApplicationBankAccountDetailsView: [
          this.submitBankAccountButton,
          {
            name: 'releaseBankAccount',
            label: 'Release Bank Account',
            type: 'primary',
            params: {
              accountId: this.clientId,
              applicationId: this.applicationId,
              applicationAccountId: this.applicationBankAccountId,
            },
            guards:
              // (!this.craEnabled || ['approved'].includes(this.applicationRecord.craStatus)) &&
              this.applicationBankAccountId &&
              !['completed'].includes(this.bankAccountRecord?.status) &&
              !!this.permissions.releaseAccountApplication && 
              this.applicationRecord.visibility !== 'hidden',
              enabledGuards: this.fullCifRecord.status === 'completed',
            clickEvent() {
              self.$router.push({ name: 'clientApplicationBankAccountDetailsViewRelease' });
            },
          },
          {
            name: 'rejectBankAccount',
            label: 'Reject Bank Account',
            type: '',
            params: {
              accountId: this.clientId,
              applicationId: this.applicationId,
              applicationAccountId: this.applicationBankAccountId,
            },
            guards:
              this.applicationBankAccountId &&
              !['new', 'inProgress'].includes(this.bankAccountRecord?.status) && // TODO discuss with bevan
              (!this.craEnabled || ['notStarted','approved','declined' ,'error','reOpened',].includes(this.applicationRecord.craStatus)) &&
              !!this.permissions.rejectAccountApplication && 
              this.applicationRecord.visibility !== 'hidden',
            clickEvent() {
              self.$router.push({ name: 'clientApplicationBankAccountDetailsViewReject' });
            },
          },
          {
            name: 'editBankAccount',
            label: 'Edit Bank Account',
            type: '',
            params: { accountId: this.clientId, applicationId: this.applicationId, cifId: this.cifId },
            guards:
              this.applicationRecord?.applicationIsEditable &&
              this.applicationBankAccountId &&
              ['new', 'inProgress', 'rejected'].includes(this.bankAccountRecord?.status) && // TODO discuss with bevan
              this.fullCifRecord.fullCifResidencyDetailsAreCaptured &&
              !!this.permissions.updateApplicationAccount && 
              this.applicationRecord.visibility !== 'hidden',
            clickEvent() {
              self.$router.push({ name: 'clientApplicationBankAccountDetailsEdit' });
            },
          },
          this.cancelBankAccountButton, // Note: Has guard === false for now.
        ],

        // Bank Account Details Edit

        clientApplicationBankAccountDetailsEdit: [this.cancelEditButton],

        // Signatories

        clientApplicationSignatories: [
          {
            name: 'addSignatory',
            label: 'Add Signatory',
            type: '',
            guards: this.permissions.createSignatory && this.applicationRecord?.applicationIsEditable && 
              this.applicationRecord.visibility !== 'hidden',
            clickEvent() {
              self.$router.push({ name: 'clientApplicationAddSignatory' });
            },
          },
        ],
        // Documents
        clientApplicationDocuments: [
          {
            name: 'downloadDocument',
            label: 'Download Document',
            type: '',
            guards: true,
            enabledGuards: !!this.applicationDocumentRecord.s3Key,
            action: 'downloadApplicationDocument',
            params: {
              accountId: this.clientId,
              applicationId: this.applicationId,
              applicationDocumentId: this.applicationDocumentRecord.id,
            },
            onSuccess: { doStoreUpdate: false },
          },
        ],

        // Logs

        clientApplicationLogs: [
          {
            name: 'goToPortfolioLogs',
            label: 'Portfolio Logs',
            type: '',
            guards: true,
            clickEvent() {
              self.$router.replace({ name: 'clientApplicationPortfolioLogs' });
            },
          },
          {
            name: 'refreshLogs',
            label: 'Refresh',
            type: '',
            guards: true,
            action: 'click',
            params: {},
            httpParams: {},
            clickEvent: self.incrementRefreshCounter,
          },
        ],
        clientApplicationPortfolioLogs: [
          {
            name: 'goToApplicationLogs',
            label: 'Application Logs',
            type: '',
            guards: true,
            clickEvent() {
              self.$router.replace({ name: 'clientApplicationLogs' });
            },
          },
          {
            name: 'refreshLogs',
            label: 'Refresh',
            type: '',
            guards: true,
            action: 'click',
            params: {},
            httpParams: {},
            clickEvent: self.incrementRefreshCounter,
          },
        ],
      };
      return allButtons[this.actionGroup]?.filter((button) => button.guards) || [];
    },

    actionGroup() {
      return this.$route.meta?.content?.actionGroup;
    },

    // ----- Layout -----

    formLayout() {
      return this.$route.meta?.layout === 'list' ? false : true;
    },

    // ----- Banners -----

    showBanners() {
      return this.banners?.length > 0 || false;
    },
    banners() {
      let allBanners = {
        all: [
        ],
        clientApplicationSummary: [
          {
            id: `applicationCraDenied` + this.applicationId,
            title: 'CRA Declined',
            status: 'error',
            closable: false,
            content: 'Customer Risk Assesment (CRA) has been declined for the client or an associated party.',
            guards: this.applicationRecord.craStatus === 'declined',
          },
          {
            id: `applicationErrorRejectOrCancel` + this.applicationId,
            title: 'Application ' + this.getStatusTextForBanner(this.applicationRecord?.status),
            status: this.applicationRecord?.status === 'cancelled' ? 'info' : 'error',
            closable: false,
            content: `Reason: ${this.applicationRecord.consolidatedRejectedReason}`,
            guards: ['error', 'rejected', 'cancelled'].includes(this.applicationRecord?.status) || ["error"].includes(this.applicationRecord?.craStatus),
          },
        ],
        clientApplication_CifDetailsView: [
          {
            id: `applicationErrorRejectOrCancel` + this.cifId,
            title: 'CIF Application ' + this.getStatusTextForBanner(this.cifRecord?.status),
            status: this.cifRecord?.status === 'cancelled' ? 'info' : 'error',
            closable: false,
            content: `Reason: ${this.cifRecord?.rejectedReason}`,
            guards: ['error', 'rejected', 'cancelled'].includes(this.cifRecord?.status),
          },
          {
            id: `applicationCifCraProblem` + this.cifId,
            title: `CRA ${this.getCraStatusTextForBanner(this.cifRecord?.craStatus)}`,
            status: this.cifRecord?.craStatus === 'eddRequested' ? 'warning' : 'error',
            closable: false,
            content: `Reason: ${this.cifRecord?.craDeclinedReason}`,
            guards: ['declined','error','eddRequested'].includes(this.cifRecord?.craStatus),
          },
        ],
        clientApplication_CifDetailsEdit: [],
        clientApplicationNoAssociatedCifs: [],
        clientApplicationBankAccountDetailsView: [
          {
            id: `applicationErrorRejectOrCancel` + this.applicationBankAccountId,
            title: 'Bank Account Application ' + this.getStatusTextForBanner(this.bankAccountRecord?.status),
            status: this.bankAccountRecord?.status === 'cancelled' ? 'info' : 'error',
            closable: false,
            content: `Reason: ${this.bankAccountRecord?.rejectedReason}`,
            guards: ['error', 'rejected', 'cancelled'].includes(this.bankAccountRecord?.status),
          },
        ],
        clientApplicationBankAccountDetailsEdit: [],
        clientApplicationSignatories: [],
        clientApplicationDocuments: [],
        clientApplicationLogs: [],
      };
      const availableBanners = [...(allBanners[this.actionGroup] || []), ...allBanners.all];
      return availableBanners.filter((banner) => banner.guards);
    },

    // ----- Account Record -----

    clientId() {
      return +this.$route.params?.accountId;
    },
    clientRecord() {
      return this.account?.[this.clientId];
    },

    // ----- Application Record -----

    applicationId() {
      return +this.$route.params?.applicationId;
    },
    applicationRecord() {
      return this.clientRecord?.application?.[this.applicationId];
    },

    // ----- Viewed CIF Record -----

    cifId() {
      return +this.$route.params.cifId;
    },
    cifRecord() {
      return this.applicationRecord?.application_cif?.[this.cifId];
    },

    // ----- Full CIF -----

    fullCifId() {
      return this.applicationRecord?.fullCifId;
    },
    fullCifRecord() {
      return this.applicationRecord?.application_cif?.[this.fullCifId];
    },
    isFullCifRecord() {
      return this.applicationRecord?.fullCifId === this.cifId;
    },

    // ----- Associated CIFs -----

    firstAssCifId() {
      return +Object.keys(this.associatedCifIds)[0] || 0;
    },

    associatedCifIds() {
      return this.applicationRecord?.associatedCifIds;
    },
    numberOfAssociatedCifIds() {
      return Object.keys(this.associatedCifIds).length;
    },

    // ----- Bank Account -----

    bankAccountApplicationIds() {
      return this.applicationRecord?.bankAccountApplicationIds;
    },
    firstBankAccountApplicationId() {
      return Object.keys(this.bankAccountApplicationIds)[0];
    },

    applicationBankAccountId() {
      return +this.$route.params.applicationAccountId;
    },
    bankAccountRecord() {
      return this.applicationRecord?.application_account?.[this.applicationBankAccountId];
    },
    // ----- Documents -----
    applicationDocuments() {
      return this.applicationRecord.localDocuments || {};
    },
    firstDocumentId() {
      const activeDocuments = {};
      for (const id in this.applicationDocuments) {
        if (this.applicationDocuments[id].status === 'uploaded') {
          activeDocuments[id] = this.applicationDocuments[id];
        }
      }
      if (Object.keys(activeDocuments).length > 0) {
        return Object.keys(activeDocuments)[0];
      }
      return;
    },
    applicationDocumentId() {
      return +this.$route.params?.applicationDocumentId;
    },
    applicationDocumentRecord() {
      return this.applicationDocuments?.[this.applicationDocumentId] || {};
    },

    // ----- Button Guards -----
    craEnabled() {
      return !!this.systemSettings?.featureEnable?.craOnboarding
    },
    approveCifCra_buttonGuard() {
      return this.craEnabled &&
      ['new','inProgress','rejected','error'].includes(this.applicationRecord?.status) &&
      ['new','inProgress','rejected','error'].includes(this.cifRecord?.status) && 
      ['requested','complianceReview','declined','error','none','eddRequested','eddSubmitted',null,undefined].includes(this.cifRecord?.craStatus)
    },
    declineCifCra_buttonGuard() {
      return this.craEnabled &&
      ['new','inProgress','rejected','error'].includes(this.applicationRecord?.status) &&
      ['new','inProgress','rejected','error'].includes(this.cifRecord?.status) && 
      ['requested','complianceReview','approved','error','none','eddRequested','eddSubmitted',null,undefined].includes(this.cifRecord?.craStatus)
    },
    recallCifCra_buttonGuard() {
      return this.craEnabled &&
      ['new','inProgress','rejected','error'].includes(this.applicationRecord?.status) &&
      ['new','inProgress','rejected','error'].includes(this.cifRecord?.status) && 
      ['none','error','rejected',null,undefined].includes(this.cifRecord?.integrationStatus) && 
      ['approved','declined'].includes(this.cifRecord?.craStatus) 
      // !this.fullCifRecord.cifNumber
    },
    requestCifCraEdd_buttonGuard() {
      return this.craEnabled &&
      ['new','inProgress','rejected','error'].includes(this.applicationRecord?.status) &&
      ['new','inProgress','rejected','error'].includes(this.cifRecord?.status) && 
      ['requested','complianceReview','approved','declined','eddSubmitted'].includes(this.cifRecord?.craStatus)
    },
    submitCifCraEdd_buttonGuard() {
      return this.craEnabled &&
      ['new','inProgress','rejected','error'].includes(this.applicationRecord?.status) &&
      ['new','inProgress','rejected','error'].includes(this.cifRecord?.status) && 
      ['eddRequested'].includes(this.cifRecord?.craStatus)
    },
    

    // ----- Modal Messages -----

    approveCraModalMessage() {
      return `This will manually mark the Client or Associated Party as CRA Approved.<br/><b>NOTE:</b>This will not update the CRA status in other systems.`
    },
    recallCifCraModalMessage() {
      return `This will reopen the Client or Associated Party for editing. It will invalidate any previously submitted CRA requests.`
    },
    submitCifCraModalMessage() {
      return `Submits or resubmits the Client or Associated party for CRA review.`
    },
    submitCifCraEddModalMessage() {
      return `Submits or resubmits the Client or Associated party for CRA Enhanced Due Diligence review.`
    },
    submitCifModalMessage() {
      //TODO
      return `
        <ol>
          <li>Submit or resubmit the CIF application</li>
        </ol>
      `;
    },
    submitAccountModalMessage() {
      //TODO
      return `
        <ol>
          <li>Submit or resubmit the Bank Account application</li>
        </ol>
      `;
    },
    submitApplicationCraModalMessage() {
      return `Submits or resubmits CRA applications for the client and all associated parties`
    },
    submitApplicationModalMessage() {
      //TODO
      return `
        <ol>
          <li>Submit or resubmit the application and all sub applications</li>
        </ol>
      `;
    },
    submitApplicationPortfolioModalMessage() {
      //TODO
      return `
        <ol>
          <li>Submit or resubmit the application portfolio for Signing</li>
        </ol>
      `;
    },
    updateStatusModalMessage() {
      //TODO
      return `
        <ol>
          <li>Recalculate the status of the application and all sub-applications</li>
        </ol>
      `;
    },
    unhideApplicationModalMessage() {
      return `Unhiding the application will return the application to the status that it was in when it was hidden.<br><b>Note</b>, please check all information captured in the application to ensure that it is still relevant.`
    }
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    getStatusTextForBanner(status) {
      switch (status) {
        case 'error':
          return 'Error';
        case 'rejected':
          return 'Rejected';
        case 'cancelled':
          return 'Cancelled';
        default:
          return status;
      }
    },
    getCraStatusTextForBanner(status) {
      switch (status) {
        case 'declined': return 'Declined'
        case 'error': return 'Error'
        case 'eddRequested': return 'Enhanced Due Dilligence Requested'
        default: return status
      }
    },

    incrementRefreshCounter() {
      this.refreshCounter++;
    },

    // async getApplication() {
    //   let collection_path = `/client/${this.clientId}/application`;
    //   let options = { return_collection_path: `/account/${this.clientId}/application` };
    //   let id = this.applicationId;
    //   this.$store.dispatch('showLoader', true);
    //   let response = await this.$store.dispatch('db_get_document', { collection_path, id, options });
    //   this.$store.dispatch('showLoader', false);
    // },

    bscCodeSelected(evt) {
      let infoObjectkey = Object.keys(this.cifRecord.application_cif_entity)[0];
      this.cifRecord.application_cif_entity[infoObjectkey].businessSectorCode = evt.code;
    },
    oCodeSelected(evt) {
      let infoObjectkey = Object.keys(this.cifRecord.application_cif_individual)[0];
      this.cifRecord.application_cif_individual[infoObjectkey].occupationCode = evt.code;
    },

    // ----- Tabs -----

    selectFirstTab() {
      this.$router.replace({ name: this.tabs[0].routeName });
    },

    // ----- Stepper -----

    selectFirstStep() {
      this.$router.replace({ name: this.steps[0].routeName, params: this.steps[0].routeParams }); // TODO
    },

    onStepClick(evt) {
      let newStepNumber = evt.detail.stepNumber;
      let newStepNumberIndex = newStepNumber - 1; // stepNumber starts at 1
      let newStep = this.steps[newStepNumberIndex];
      this.$router.replace({ name: newStep.routeName, params: newStep.routeParams }); // TODO
    },
    stepBack() {
      let newStepNumber = this.activeStepNumber - 1;
      let newStepNumberIndex = newStepNumber - 1; // stepNumber starts at 1
      let newStep = this.steps[newStepNumberIndex];
      this.$router.replace({ name: newStep.routeName, params: newStep.routeParams }); // TODO
    },
    stepNext() {
      let newStepNumber = this.activeStepNumber + 1;
      let newStepNumberIndex = newStepNumber - 1; // stepNumber starts at 1
      let newStep = this.steps[newStepNumberIndex];
      this.$router.replace({ name: newStep.routeName, params: newStep.routeParams }); // TODO
    },
    printStatuses() {
      console.log("craEnabled",this.craEnabled)
      console.log("applicationRecord",{
        status:this.applicationRecord?.status,
        craStatus:this.applicationRecord?.craStatus,
        integrationStatus:this.applicationRecord?.integrationStatus,
        applicationPortfolioStatus:this.applicationRecord?.applicationPortfolioStatus,
        consolidatedStatus:this.applicationRecord?.consolidatedStatus,
      });
      console.log("cifRecord",{
        status:this.cifRecord?.status,
        craStatus:this.cifRecord?.craStatus,
        integrationStatus:this.cifRecord?.integrationStatus,
        consolidatedStatus:this.cifRecord?.consolidatedStatus
      })
    }
  },
};
</script>
