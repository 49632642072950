<template>
  <div class="notify-button" @click="viewNotifications">
    <capitec-badge class="notifications" color="#C83C37" :number="`+${eventCount}`"> </capitec-badge>
    <capitec-icon class="icon click-icon" icon="system/notify-action" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['events', 'eventsVisible']),
    eventCount() {
      return Object.keys(this.events).length;
    },
  },
  methods: {
    ...mapActions(['showEvents']),
    ...mapMutations([]),
    viewNotifications() {
      this.showEvents(!this.eventsVisible);
    },
  },
};
</script>
<style>
.notify-button {
  position: relative;
  margin-right: 15px;
  margin-top: 20px;
}

.click-icon {
  cursor: pointer;
}

.notifications {
  position: absolute;
  top: -16px;
  right: -16px;
}
</style>
