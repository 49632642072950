<template>
 <div class="capitec-item-view-field signatories">
  <capitec-label class="capitec-item-view-field-label signatory" :label="`${field.label}`"></capitec-label>    
  <div class="lfx-payment-signatory">
    <template v-for="(signatory,index) in value" :key="signatory" >  
      <template v-if="sig(signatory) !== undefined">    
      <capitec-label type="strong" :class="`capitec-item-view-field-value signatory-index ${signatoryClasses(signatory)}`" :label="`#${index + 1}`" />
      <LfxAvatar v-if="sig(signatory) !== undefined" :record="sig(signatory)" :fields="['firstName','surname']" />
      <span v-else />
      <router-link tag="capitec-hyperlink" :to="sig(signatory).link" type="strong" :class="`capitec-hyperlink capitec-item-view-field-value signatory-name ${signatoryClasses(signatory)}`" :label="signatoryName(signatory)">
      {{signatoryName(signatory)}}
      </router-link>
      </template>
    </template>        
    </div>
    </div>
</template>


<script>

import { ldb_field_mixin } from '@/landobyte_vue/ldb_field_mixin.js';

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

export default {
  mixins: [ldb_field_mixin],
  components: {},
  props: {  

  },
  data() {
    return {
    }
  },
  created() {
    
  },
  mounted() {
  },
  computed:{
    ...mapGetters([]),
  	...mapState(['signatory']),
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([]),
    sig(id) {
      return this?.signatory?.[id];  
    },
    signatoryName(id) {
      return (this?.signatory?.[id] !== undefined) ? `${this?.signatory?.[id].firstName} ${this?.signatory?.[id].surname}` : '-' ; 
    },
    signatoryClasses(id) {
      let sig = this.sig(id);
      return (sig !== undefined) ? `required-${sig.signerRequired} status-${sig.status}` : '';
    }
  }
 }
</script>
<style>

div.lfx-payment-signatory{
  display: grid;
  grid-template-columns: max-content 20px auto;
  align-items: center;
  grid-column-gap: 3px;
  grid-row-gap: 3px;
  grid-auto-rows: 25px;
}

.bop-currency{  
  font-weight: bold;
}

.bop-amount{  
  text-align: right;
  font-weight: bold;

  /* font-weight: bold; */
}

capitec-label.required-optional{
  /* opacity: 0.6; */
  /* font-style: regular; */
}

capitec-label.required-mandatory{
  /* font-weight: bold; */
}


.status-disabled{
  font-style: italic;
}


.signatory-index{
  /* border: 1px solid red; */
  padding-top:3px;
}

.signatory-name{
  text-overflow:ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  padding-right:5px;
}

.capitec-item-view-field.signatories{
  flex-direction: column;
}

capitec-label.capitec-item-view-field-label.signatory{
  margin-bottom:5px;
}

</style>


