<template>
  <div class="lfx-forward-reval-report">
    <capitec-content-box>
      <capitec-action-bar>
        <LDBButton
          v-for="button in formButtons"
          :key="button.name"
          :label="button.label"
          :action="button.action"
          :actionParams="button.actionParams"
          :params="button.params"
          :type="button.type"
          :tooltip="button.tooltip"
          :modal="button.modal"
          :viewGuards="button.guards"
          :enabledGuards="button.enabledGuards"
          :onSuccess="button.onSuccess"
          @beforeClick="button.beforeClick"
          @click="button.onClick"
          @on-api-success="button.onApiSuccess"
          @on-api-error="button.onApiError"
        >
          <template v-if="button.modal !== undefined" #modal>
            <div v-html="button.modal.content"></div>
          </template>
        </LDBButton>
      </capitec-action-bar>
      <template v-if="dataStatus === 'retrieving'">
        <LDBLoader/>
      </template>
      <template v-else-if="dataStatus === 'retrieved' && sortedRecords.length > 0">
        <div class="lfx-deal-summary-report-view-table-container">
          <table-lite
            id="lfx-deal-summary-report-view-table"
            :is-static-mode="true"
            :is-slot-mode="true"
            :has-checkbox="false"
            :is-loading="false"
            :is-re-search="false"
            :columns="tableColumns"
            :rows="sortedRecords"
            :total="sortedRecords.length"
          >
            <template v-for="(col, i) of tableColumns" v-slot:[col.field]="data" :key="i">
              <template v-if="col.showAsCurrency">{{+data.value[col.field]/100}}</template>
              <template v-else-if="col.showAsDate">{{data.value[col.field].toISOString ? data.value[col.field].toISOString().substring(0,10) : data.value[col.field].substring(0,10)}}</template>
              <!-- <template v-if="col.field?.amount">ZAR {{data.value[col.field].amount}}</template> -->
              <template v-else>{{data.value[col.field]}}</template>
            </template>
          </table-lite>
        </div>
      </template>
      <template v-else-if="dataStatus === 'retrieved'">
        No Open Forward Deals
      </template>
    </capitec-content-box>

  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBField from '@/landobyte_vue/LDBField.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import TableLite from '@/views/general/TableLite.vue';

import { calculateMarkupsAndRates,getDealLocalAmount,getDealForeignAmount,getCurrencyType,getSpotFromCachedRic,calculateForwardPoints } from '@/sharedUtils/LfxTradeCalc.ts'
import { calculateCounterAmount } from '@/sharedUtils/LfxTradeCalc';

export default {
  name: 'LfxForwardRevalReport',
  components: { LDBField,LDBLoader,TableLite },
  props: {},
  data() {
    return {
      dataStatus: 'none',
      optionsRecord: {
        datePreset: 'today',
        dateFrom: new Date().toISOString(),
        dateTo: new Date().toISOString(),
        portfolioStatusesSetting: 'all',
        statusSelector: undefined,
      },
      reportData: undefined,
      rollUpBranches: true,
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['rates','account']),

    // ----- Buttons -----

    formButtons() {
      let self = this;
      return [
        {
          name: 'generateReport',
          label: "Generate Report",
          type: 'primary',
          action: 'getDeals',
          onSuccess: { doStoreUpdate: false },
          guards: this.dataStatus !== 'retrieved',
          actionParams: {additionalSelector:{where:{
            deliveryType:'FWD',
            status:'released',
            valueDate:{gt:new Date()}
          }}},
          beforeClick() {
            self.dataStatus = "retrieving";
          },
          onApiSuccess: self.onApiSuccess,
          onApiError() {
            self.dataStatus = "error"
          }
        },
      ];
    },

  // ----- TABLE -----
    showBankMarkup() {
      return state.authUser.accountLevel === 'bank';
    },
    tableColumns() {
      let self = this;
      let res = [
        {
          col: "idColumn",
          field: "id",
          label: "Deal",
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false
        },
        {
          col: "accountIdColumn",
          field: "accountId",
          label: "Client",
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false
        },
        {
          col: "branchColumn",
          field: "branchId",
          label: "Branch",
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false
        },
        {
          col: "dealNumberColumn",
          field: "dealNumber",
          label: "Deal Number",
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false
        },
        {
          col: "forwardContractTypeColumn",
          field: "forwardContractType",
          label: "Contract Type",
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false
        },
        {
          col: "optionStartDateColumn",
          field: "optionStartDate",
          label: "Option Start Date",
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false
        },
        {
          col: "valueDateColumn",
          field: "valueDate",
          label: "Value Date",
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
          showAsDate: true
        },
        {
          col: "foreignCurrencyColumn",
          field: "foreignCurrency",
          label: "Foreign Currency",
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false
        },
        {
          col: "foreignAmountColumn",
          field: "foreignAmount",
          label: "Foreign Amount",
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: true
        },
        {
          col: "bookedClientRateColumn",
          field: "bookedClientRate",
          label: "Booked Rate",
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false
        },
        {
          col: "bookedZarAmountColumn",
          field: "bookedZarAmount",
          label: "Booked ZAR Amount",
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: true
        },
        {
          col: "marketClientRateColumn",
          field: "marketClientRate",
          label: "Market Rate",
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false
        },
        {
          col: "marketZarAmountColumn",
          field: "marketZarAmount",
          label: "Market ZAR Amount",
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: true
        },
        {
          col: "markToMarketColumn",
          field: "markToMarket",
          label: "Mark to Market",
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: true
        }
      ];
      return res.filter(f => f.guards === true)
   },
   sortedRecords() {
     // TODO check direction of quote
     const res = [];
     for (const deal of this.reportData) {
      const currencyPair = this.$store.state.config[1].currencyPair?.[deal.currencyPair];
      let currencyType;
      let cachedRic;
      if (currencyPair) {
        currencyType = getCurrencyType(deal.currency, currencyPair);
        cachedRic = this.rates[currencyPair.instrument];
      }
      if (cachedRic && currencyType) {
        const spotRate = getSpotFromCachedRic(cachedRic, currencyType, deal.action);
        const forwardPoints = calculateForwardPoints(
          cachedRic,
          deal.contractType,
          new Date(), // spot date TODO
          new Date(deal.valueDate),
          currencyType,
          deal.action,
          0, // optionAddionalPercentage TODO
          deal.optionStartDate
        )
        const markupObject = {
          currencyType: currencyType,
          action: deal.action === 'Buy' ? 'Sell' : 'Buy',
          valueDate: new Date(deal.valueDate),
          markupType: 'fixedSpread',
          spotRate: spotRate,
          setMarkup: 0,

          accountMarkups: this.accountMarkups(deal.accountId,deal.currencyPair),

          config: {
            maximumMarkupPercentage: 0.1,
            fecAdditionalSpreads: {
              '1month': 30,
              '2month': 60,
              '3month': 90,
              '4month': 120,
              '5month': 160,
              '6month': 200,
              '12month': 200,
            }
          },
          forwardPoints: forwardPoints,
        };
        if(markupObject.accountMarkups) {
          const ratesResult = calculateMarkupsAndRates(markupObject)
          deal.foreignCurrency = deal.currency === 'ZAR' ? deal.counterCurrency : deal.currency;
          deal.foreignAmount = getDealForeignAmount(deal);
          deal.bookedClientRate = deal.clientRate;
          deal.bookedZarAmount = getDealLocalAmount(deal);
          deal.marketClientRate = ratesResult.clientRate;
          deal.marketZarAmount = calculateCounterAmount(deal.foreignAmount,ratesResult.clientRate,'quote');
          deal.markToMarket = deal.marketZarAmount - deal.bookedZarAmount;
          res.push(deal)
        }
      }
    }
    return res;
   },
    definitionName() {return 'deal'},
    definition() {
      return getDefinition(this.definitionName);
    },
    collectionPathTemplate() {return this.definition?.collectionPath;}
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    on_change({ field, value }) {
      this.optionsRecord[field] = value;
    },
    onApiSuccess(response) {
      try {
      this.reportData = [];
      if (Array.isArray(response.response?.rows)) {
        for (const row of response.response.rows) {
          row.collection_path = Mustache.render(this.collectionPathTemplate,row)
          this.reportData.push(this.definition.class(row, this.$store))
        }
      }
      this.dataStatus = "retrieved";
      } catch (err) {
        console.log(err)
      }
    },

    accountMarkups(accountId,currencyPair) {
      let accountMarkups = undefined;
      const accountRecord = this.account[accountId];
      if (currencyPair) {
        let parentMarkups = accountRecord?.parentMarkups;
        if (parentMarkups?.[currencyPair]) {
          accountMarkups = parentMarkups?.[currencyPair];
        }
      }
      const allAccountIds = [accountRecord?.id];
      for (const id of accountRecord?.parentAccountIds) {
        allAccountIds.push(id);
      }
      if (Array.isArray(accountMarkups)) {
        for (let i = 0; i < allAccountIds.length; i++) {
          if (allAccountIds[i] !== 1) {
            let markupFound = false;
            for (const markup of accountMarkups) {
              if (markup.accountId === allAccountIds[i]) {
                markupFound = true;
              }
            }
            if (!markupFound && !!allAccountIds[i + 1]) {
              accountMarkups.push({
                accountId: allAccountIds[i],
                parentId: allAccountIds[i + 1],
                markupType: 'pips',
                pips: 0,
              });
            }
          }
        }
      }
      return accountMarkups;
    },
  }
}

</script>

<style>
.lfx-deal-summary-report-view-table-container{
  flex-grow:1;
  flex-shrink:1;
  position: relative;
}

#lfx-deal-summary-report-view-table{
  display:flex;
  flex-direction:column;
}

.lfx-forward-reval-report{
  position: absolute;
  top:0px;
  left:0px;
  bottom:0px;
  right:0px;
}

</style>

