<template>
  <!-- <div>criticalDataLoaded:{{criticalDataLoaded}}</div>
<div>isAuthenticated:{{isAuthenticated}}</div>
<div>isAuthenticating:{{isAuthenticating}}</div> -->
  <section v-if="!isAuthenticated" id="login">
    <LfxLogin />
  </section>
  <section v-else-if="criticalDataLoaded" id="main" :class="permissionClasses">
    <!-- <LDBModal header="Modal Header" result="success" :closable="false" size="medium" > 
      <template #body>
          <div v-for="n in rows" :key="n" @click="rows++"> 
            {{n}}:<span v-for="p in n" :key="p">XXXX</span>
          </div>
      </template>
      <template #footer>
        <div @click="rows--">XXX</div>
      </template>
    </LDBModal> -->
    <LfxToast v-if="toast.show" />

    <LfxEventPopups />

    <LfxMFAOTP v-if="mfa.otpSent || mfa.requesting" />

    <!-- <section id="rates-feed" @click="contentClick"> -->
    
    <!-- </section> -->
    
    <section id="header">
      <LfxHeader>
        <template #rates>
          <LfxRatesFeed />
        </template>
      </LfxHeader>
    </section>
    
    <aside id="menu" :class="menuClass" @mouseenter="onMouseMenuEnter" @mouseleave="onMouseMenuLeave">
    
      <div v-if="menuIsLocked" class="menu-locked-button menu-locked" @click="saveMenuLock(false)">
        <capitec-icon icon="system/locked" size="small"></capitec-icon>
      </div>

      <div v-else class="menu-locked-button  menu-unlocked" @click="saveMenuLock(true)">  
        <capitec-icon icon="system/unlocked" size="small"></capitec-icon>
      </div>
      <LfxLeftMenu @onMenuItemClick="menuClick"></LfxLeftMenu>
    </aside>
    
    <section id="events" :class="show">
       <div class="lfx-events">
         <LfxEventNotifications />
       </div>
    </section>
    
    <section id="content" @click="contentClick">
      <RouterView v-slot="{ Component }" :key="contentRouterViewKey">
        <template v-if="Component">
          <LDBErrorHandler>
            <LDBDataLoader>
              <component :is="Component"></component>
            </LDBDataLoader>
          </LDBErrorHandler>
        </template>
      </RouterView>
    </section>

    <section id="footer">
      <LfxFooter />
    </section>

  </section>

  <section v-else id="login">
    <!-- <LfxLogin /> -->

    <!-- <LfxLoginPhoneInstruction /> -->

    <capitec-loading-indicator />
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import LfxHeader from './LfxHeader.vue';
import LfxFooter from './LfxFooter.vue';
import LfxLeftMenu from './LfxLeftMenu.vue';
import LfxTitleBar from './LfxTitleBar.vue';
import LfxRatesFeed from '../system/LfxRatesFeed.vue';
import LfxMFAOTP from './LfxMFAOTP.vue';
import LfxToast from './LfxToast.vue';
import LfxLogin from '@/views/auth/LfxLogin.vue';
import LfxLoginPhoneInstruction from './LfxLoginPhoneInstruction.vue';
import LfxEventNotifications from '@/components/events/LfxEventNotifications.vue';
import LfxEventPopups from '@/components/events/LfxEventPopups.vue';
// import LfxFooter from "./LfxFooter.vue";

export default {
  data() {
    return {
      rows: 10,
      menuClickable:true,
      menuTimer:undefined,
      mouseIsOnMenu:false,
    };
  },
  components: {
    LfxLogin,
    LfxHeader,
    LfxFooter,
    LfxMFAOTP,
    LfxToast,
    LfxEventNotifications,
    LfxLoginPhoneInstruction,
    LfxLeftMenu,
    LfxRatesFeed,
    LfxTitleBar,
    LfxEventPopups,
  },
  computed: {
    ...mapState([
      'auth',
      'user',
      'authUser',
      'account',
      'eventPopups',
      'isAuthenticated',
      'isAuthenticating',
      'toast',
      'mfa',
      'eventsVisible',
    ]),
    ...mapGetters(['permissions']),
    menuIsLocked() {
      return this?.authUser?.settings?.uiSettings?.lockMenu === undefined ? false : this?.authUser?.settings?.uiSettings?.lockMenu;
    },
    contentRouterViewKey() {
      return `${this.$route.matched[0].name}_${this.$route.params.accountId}_${this.$route.params.transactionId}_${this.$route.params.applicationId}_${this.$route.params.userId}`;
    },
    menuClass() {
      let res = [];
      if (this.menuClickable) { 
        res.push('clickable');
      } 
      else 
      {
        res.push('not-clickable')
      }
      if ( this.menuIsLocked ) {
        res.push('menu-is-locked')
      } 
        else 
      {
        res.push('menu-is-not-locked')
      }
      return res.join(' ');
    },
    show() {
      return this.eventsVisible ? 'show-events' : 'hide-events';
    },
    requiresMFAOTP() {
      return this.mfa.otpSent === true;
    },
    customInfo() {
      return this.auth;
    },
    criticalDataLoaded() {
      return this.user?.[this.userId] !== undefined && this.account?.[this.accountId] !== undefined;
    },
    accountId() {
      return this.auth?.accountId;
    },
    userId() {
      return this.auth?.id;
    },
    permissionClasses() {
      return this.permissions.ratesFeedView ? 'rates_feed' : 'hide_rates_feed';
    },
  },
  methods: {
    ...mapActions(['showEvents', 'showRatesFeedSettings', 'showWidgetSettings','saveUISettings']),
    saveMenuLock(locked) {
      this.saveUISettings({lockMenu:locked})
    },
    onMouseMenuEnter() {
      this.mouseIsOnMenu = true;
      // this.menuClickable = true;
    },
    onMouseMenuLeave() {
      this.mouseIsOnMenu = false;
      // this.menuClickable = true;
    },
    doOnClick() {
      this.$router.push('/test');
    },
    resetMenu() {
      this.menuClickable = true;
      this.menuTimer = undefined;
    },
    menuClick(event) {
      this.menuClickable = false;
      if (this.menuTimer !== undefined) {
        clearTimeout(this.menuTimer);
      }
      this.menuTimer = setTimeout(this.resetMenu, 500);
      this.showEvents(false);
    },
    contentClick(event) {
      if (!event.srcElement.getAttribute('menu')) {
        this.showEvents(false);
        this.showRatesFeedSettings(false);
        this.showWidgetSettings(false);
      }
    },
  },
};
</script>

<style>
section#main {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: grid;
  grid-template-areas:
    'header header header'
    '. . .'
    'menu content content'
    'menu content content'
    'menu footer footer';
  /* TODO: above is old, below should work */
  /* 'header header'
    'menu content'
    'menu footer'; */
  grid-template-columns: 60px auto 60px;
  /* grid-template-columns: 60px 1fr; */
  grid-template-rows: 80px 0px 1fr 10px 40px;
  /* grid-template-rows: 80px 1fr 40px; */
  overflow: hidden;
}

aside#menu:hover > div.menu-locked-button{
  opacity:0.5;
}


div.menu-locked-button{  
  position: absolute;
  top:3px;
  left:3px;
  cursor: pointer;
  opacity: 0;
  transition:all 2s linear;
}

div.menu-locked-button:hover{
  transition: all 0.3s linear;
  opacity: 1 !important;
}


section#events {
  grid-area: events;
  overflow: visible;
  position: relative;
  z-index: 500;
  transition-property: transform;
  transition-duration: 0.7s;
  position: fixed;
  top: 80px;
  left: calc(100vw - 10px);
  bottom: 5px;
  border-radius: 5px;
}

section#events.show-events {
  transform: translateX(-500px);
}

section#events.hide-events {
  transform: translateX(30px);
}

section#main.hide_rates_feed {
  grid-template-rows: 80px 0px 1fr 10px 40px;
}

:root {
  --debug: 5px;
  --debug: 0px;
  --theme-menu-header-padding-left: 15px !important;
  --theme-menu-item-padding-left: 18px !important;
}

section#header {
  grid-area: header;
  border: var(--debug) dotted yellow;
}

section#rates-feed {
  grid-area: rates-feed;
  display: flex;
  border: var(--debug) dotted magenta;
}

section#main.hide_rates_feed > section#rates-feed {
  display: none;
}

section#title {
  border: var(--debug) dotted red;
  grid-area: title;
}

aside#menu {
  z-index: var(--lfx-z-index-menu);
  position: fixed;
  grid-area: menu;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 58px;
  min-width: 58px;
  box-shadow: var(--theme-menu-box-shadow);
  transition: all 0.3s ease-in-out;
  bottom: 0px;
  top: 80px;
}

aside#menu.menu-is-not-locked:hover{
  max-width: 260px;
  min-width: 260px;
}

aside#menu.not-clickable:hover,
aside#menu.not-clickable {
  pointer-events: none;
  max-width: 58px;
  min-width: 58px;
}

aside#menu.clickable,
aside#menu.clickable:hover {
  pointer-events: auto;
}

section#main.hide_rates_feed > aside#menu {
  top: 80px;
}

section#content {
  border: var(--debug) dotted orange;
  grid-area: content;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column;
  position: fixed;
  top: 80px;
  left: 70px;
  right: 10px;
  bottom: 45px;
}

section#main.hide_rates_feed > section#content {
  top: 80px;
}

section#footer {
  border: var(--debug) dotted green;
  grid-area: footer;
  position: relative;
  background-color: white;
  pointer-events: none;
  background-color: white;
}

div.lfx-events {
  box-shadow: var(
    --theme-card-elevation-hover-box-shadow,
    0 1px 3px 0 rgba(0, 0, 0, 0.25),
    0 2px 4px 0 rgba(0, 0, 0, 0.15)
  );
  min-width: 500px;
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  background-color: white;
}

div.footer-text {
  margin-left: 200px;
  margin-right: 200px;
  font-size: 12px;
  text-align: center;
}

.landobyte-logo {
  position: absolute;
  max-width: 150px;
  transform: scale(0.8);
  right: 10px;
  top: 5px;
}

a.test-link {
  cursor: pointer;
  color: #ccc;
  position: fixed;
  bottom: 10px;
  right: 300px;
  z-index: 100;
  pointer-events: auto;
}

a.popi-link {
  pointer-events: auto;
  color: var(--theme-hyperlink-color);
}
</style>
