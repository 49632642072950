import { RouteRecordRaw } from 'vue-router';

import LfxTodo from '@/components/todo/LfxTodo.vue';
import LfxTodoModal from '@/components/todo/LfxTodoModal.vue';

import LfxConfig from '@/views/config/LfxConfig.vue';

import LfxConfigCountries from '@/views/config/LfxConfigCountries.vue';
// TODO import country

import LfxConfigUserTeams from '@/views/config/LfxConfigUserTeams.vue';
import LfxConfigUserTeamsItemView from '@/views/config/LfxConfigUserTeamsItemView.vue';
import LfxConfigUserTeamAdd from '@/views/config/LfxConfigUserTeamAdd.vue';

import LfxConfigCurrencies from '@/views/config/LfxConfigCurrencies.vue';
// TODO import currency

import LfxConfigCurrencyPairs from '@/views/config/LfxConfigCurrencyPairs.vue';
import LfxConfigCurrencyPairDetails from '@/views/config/LfxConfigCurrencyPairDetails.vue';
// TODO import currencyPair

import LfxConfigPublicHolidays from '@/views/config/LfxConfigPublicHolidays.vue';
import LfxConfigPublicHolidayItemView from '@/views/config/LfxConfigPublicHolidayItemView.vue';
import LfxConfigPublicHolidayAdd from '@/views/config/LfxConfigPublicHolidayAdd.vue';
import LfxConfigPublicHolidayImport from '@/views/config/LfxConfigPublicHolidayImport.vue';
import LfxRecacheValueDates from '@/views/config/LfxRecacheValueDates.vue';

import LfxPermissionsList from '@/views/config/LfxPermissionsList.vue';
import LfxAddPermission from '@/views/config/LfxAddPermission.vue';

// TODO  import Permission Groups
// TODO import permission group

import LfxConfigBopCategoriesList from '@/views/config/LfxConfigBopCategoriesList.vue';
import LfxConfigBopCatDetails from '@/views/config/LfxConfigBopCatDetails.vue';
import LfxConfigBopCatDocuments from '@/views/config/LfxConfigBopCatDocuments.vue';
import LfxConfigBopCatInfo from '@/views/config/LfxConfigBopCatInfo.vue';
// TODO import bop category
// TODO import bop category groups
// TODO import bop category group

import LfxConfigApplicationDocumentTypes from '@/views/config/LfxConfigApplicationDocumentTypes.vue';

import LfxSystemSettings from '@/views/config/LfxSystemSettings.vue';
import LfxSystemSettingsEditTradingConfig from '@/views/config/LfxSystemSettingsEditTradingConfig.vue';
import LfxSystemSettingsEditAccessConfig from '@/views/config/LfxSystemSettingsEditAccessConfig.vue';
import LfxSystemSettingsUserReviewConfig from '@/views/config/LfxSystemSettingsUserReviewConfig.vue';
import LfxSystemSettingsGeneralMessageConfig from '@/views/config/LfxSystemSettingsGeneralMessageConfig.vue'

const routes: RouteRecordRaw[] = [
  {
    path: '/config',
    name: 'config',
    meta: { icon: 'system/filter' },
    component: LfxConfig,
    children: [
      //
      // ----- System Settings -----

      {
        path: 'systemSettings',
        name: 'systemSettings',
        component: LfxSystemSettings,
        meta: { content: { tabName: 'systemSettings', label: 'System Settings', actionGroup: 'systemSettings' } },
        children: [
          {
            path: 'editTradingConfig',
            name: 'systemSettingsEditTradingConfig',
            component: LfxSystemSettingsEditTradingConfig,
          },
          {
            path: 'editAccessConfig',
            name: 'systemSettingsEditAccessConfig',
            component: LfxSystemSettingsEditAccessConfig,
          },
          {
            path: 'editUserReviewConfig',
            name: 'systemSettingsEditUserReviewConfig',
            component: LfxSystemSettingsUserReviewConfig,
          },
          {
            path: 'editGeneralMessage',
            name: 'systemSettingsGeneralMessageConfig',
            component: LfxSystemSettingsGeneralMessageConfig,
          },
        ],
      },

      // ----- Public Holidays

      {
        path: 'public_holidays',
        name: 'configPublicHolidaysView',
        component: LfxConfigPublicHolidays,
        meta: {
          content: { tabName: 'publicHolidays', label: 'Public Holidays', actionGroup: 'configPublicHolidaysView' },
        },
        children: [], // TODO
      },
      {
        path: 'public_holiday/:publicHolidayId',
        name: 'configPublicHolidayEdit',
        components: { default: LfxConfigPublicHolidays, modal: LfxConfigPublicHolidayItemView },
        meta: {
          content: {
            tabName: 'publicHolidays',
            label: 'Public Holidays',
            actionGroup: 'configPublicHolidaysView',
            view: 'edit',
          },
        },
      },
      {
        path: 'public_holidays/add_public_holiday',
        name: 'configPublicHolidaysViewAdd',
        components: { default: LfxConfigPublicHolidays, modal: LfxConfigPublicHolidayAdd },
        meta: {
          content: { tabName: 'publicHolidays', label: 'Public Holidays', actionGroup: 'configPublicHolidaysView' },
        },
      },
      {
        path: 'public_holidays/import_public_holidays',
        name: 'configPublicHolidaysViewImport',
        components: { default: LfxConfigPublicHolidays, modal: LfxConfigPublicHolidayImport },
        meta: {
          content: { tabName: 'publicHolidays', label: 'Public Holidays', actionGroup: 'configPublicHolidaysView' },
        },
      },

      // ----- BOP Categories -----

      {
        path: 'bop_categories',
        name: 'configBopCategoriesView',
        component: LfxConfigBopCategoriesList,
        meta: { content: { tabName: 'bopCategories', label: 'BOP Categories', actionGroup: 'configBopCategoriesView' } },
        children: [
          {
            path: ':bopCatId',
            name: 'configBopCategoriesViewDetailsItem',
            component: LfxConfigBopCatDetails,
          },
          {
            path: 'documents',
            name: 'configBopCategoriesViewDocuments',
            component: LfxConfigBopCatDocuments,
          },
          {
            path: 'info',
            name: 'configBopCategoriesViewInfo',
            component: LfxConfigBopCatInfo,
          },
        ],
      },

      // ----- BOP Category Groups -----

      {
        path: 'bop_category_groups',
        name: 'configBopCategoryGroupsView',
        component: LfxTodo,
        meta: { content: { tabName: 'bopCategoryGroups', label: 'BOP Category Groups' } },
      },

      // ----- Application Documents -----

      {
        path: 'applicationDocumentTypes',
        name: 'configApplicationDocumentTypesView',
        component: LfxConfigApplicationDocumentTypes,
        meta: {
          content: {
            tabName: 'applicationDocumentTypes',
            label: 'Application Document Types',
            actionGroup: 'configApplicationDocumentTypesView',
          },
        },
      },

      // ----- Permissions -----

      {
        path: 'permissions',
        name: 'configPermissionsView',
        component: LfxPermissionsList,
        meta: { content: { tabName: 'permissions', label: 'Permissions', actionGroup: 'configPermissionsView' } },
      },
      {
        path: 'permissions/add_permission',
        name: 'configPermissionsViewAdd',
        components: { default: LfxPermissionsList, modal: LfxAddPermission },
        meta: { content: { tabName: 'permissions', label: 'Permissions', actionGroup: 'configPermissionsView' } },
      },

      {
        path: 'permission/:permissionId',
        name: 'configPermssionView',
        component: LfxTodo,
        meta: { content: { label: 'Permission', actionGroup: 'configPermssionView' } },
      },

      // ----- Permission Groups -----

      {
        path: 'permission_groups',
        name: 'configPermissionGroupsView',
        component: LfxTodo,
        meta: {
          content: {
            tabName: 'permissionGroups',
            label: 'Permission Groups',
            actionGroup: 'configPermissionGroupsView',
          },
        },
      },
      {
        path: 'permission_groups',
        name: 'configPermissionGroupsViewAdd',
        components: { default: LfxTodo, modal: LfxTodoModal },
        meta: {
          content: {
            tabName: 'permissionGroups',
            label: 'Permission Groups',
            actionGroup: 'configPermissionGroupsView',
          },
        },
      },

      // ----- Currency Pairs -----

      {
        path: 'currency_pairs',
        name: 'configCurrencyPairsView',
        component: LfxConfigCurrencyPairs,
        meta: {
          content: {
            tabName: 'currencyPairs',
            label: 'Currency Pairs',
            actionGroup: 'configCurrencyPairsView',
            layout: 'list',
          },
        },
        children: [
          {
            path: ':currencyPairId/edit',
            name: 'configCurrencyPairsViewDetailsEdit',
            component: LfxConfigCurrencyPairDetails,
            meta: { modal: { view: 'edit' } },
          },
          {
            path: 'recache_value_dates',
            name: 'configCurrencyPairsViewRecacheValueDates',
            component: LfxRecacheValueDates,
          },
        ],
      },
      {
        path: 'currency_pairs/add_currency_pair',
        name: 'configCurrencyPairsViewAdd',
        components: { default: LfxConfigCurrencyPairs, modal: LfxTodoModal },
        meta: {
          content: {
            tabName: 'currencyPairs',
            label: 'Currency Pairs',
            actionGroup: 'configCurrencyPairsView',
            layout: 'list',
          },
        },
      },

      // ----- Currencies -----

      {
        path: 'currencies',
        name: 'configCurrenciesView',
        component: LfxConfigCurrencies,
        meta: { content: { tabName: 'currencies', label: 'Currencies' } },
      },

      // ----- Countries -----

      {
        path: 'countries',
        name: 'configCountriesView',
        component: LfxConfigCountries,
        meta: { content: { tabName: 'countries', label: 'Countries' } },
      },

      // ----- User Teams -----

      {
        path: 'userTeams',
        name: 'configUserTeamsView',
        component: LfxConfigUserTeams,
        meta: { content: { tabName: 'userTeams', label: 'User Teams', actionGroup: 'configUserTeamsView' } },
      },
      {
        path: 'userTeam/addUserTeam',
        name: 'configUserTeamViewAdd',
        components: { default: LfxConfigUserTeams, modal: LfxConfigUserTeamAdd },
        meta: { content: { tabName: 'userTeams', label: 'User Teams', actionGroup: 'configUserTeamsView' } },
      },
      {
        path: 'userTeam/:userTeamId',
        name: 'configUserTeamViewEdit',
        components: { default: LfxConfigUserTeams, modal: LfxConfigUserTeamsItemView },
        meta: {
          content: {
            tabName: 'userTeams',
            label: 'User Teams',
            actionGroup: 'configUserTeamsView',
            view: 'edit',
          },
        },
      },
    ],
  },

  { path: '/country/:countryId', name: 'country', component: LfxTodo, meta: { label: 'Country' } },
  { path: '/currency/:currencyId', name: 'currency', component: LfxTodo, meta: { label: 'Currency' } },
  { path: '/currencyPair/:currencyPairId', name: 'currencyPair', component: LfxTodo, meta: { label: 'Currency Pair' } },

  { path: '/permission/:permissionId', name: 'permission', component: LfxTodo, meta: { label: 'Permission' } },
];

export default routes;
