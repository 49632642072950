<template>
  <LFXContent
    :titleBar="titleBar"
    :show_tabs="showTabs"
    :tabs="tabs"
    :show_label="showLabel"
    :label="contentLabel"
    :show_actions="showActions"
    :form_layout="formLayout"
    :show_sidebar="false"
  >
    <template v-if="true" #banners>
      <LfxBanner
        title="Integration Login Error"
        status="error"
        :closable="false"
        id="esbLoginError"
        v-if="record.esbUserStatus === 'error'"
      >
        The user's integration failed, please try to login again using the Integration Login button<br />
        {{ record.esbUserErrorText }}
      </LfxBanner>
      <LfxBanner
        title="Odin Login Error"
        status="error"
        :closable="false"
        id="odnLoginError"
        v-if="record.odnUserStatus === 'error' && !!record.odnUsername"
      >
        The user's Odin failed, please try to login again using the Integration Login button<br />
        {{ record.odnUserErrorText }}
      </LfxBanner>
      <LfxBanner title="User Locked" status="error" :closable="false" id="userLocked" v-if="!!record.userLocked">
        The user has been locked. They will not be able to login or reset their password until they have been unlocked.
      </LfxBanner>
      <LfxBanner title="Bank User No Team" status="warning" :closable="false" id="bankUserNTeam" v-if="!record.userTeamId && record.accountId === 1">
        The user has not been assigned to a User Team.
      </LfxBanner>
    </template>
    <template #actions>
      <LDBButton
        v-for="button in actionBarButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>

    <template #content>
      <router-view v-if="includeRefreshCounter" :refreshCounter="refreshCounter"></router-view>
      <router-view v-else></router-view>
    </template>
  </LFXContent>

  <router-view name="modal"></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXContent from '@/components/layout/LfxContent.vue';

export default {
  name: 'LfxUser',
  components: { LFXContent },
  props: {},
  data() {
    return {
      refreshCounter: 0,
    };
  },
  created() {
    if (this.$route.name === 'user') {
      this.selectFirstTab();
    }
  },
  mounted() {},
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'user' && to.params.userId === from.params.userId) {
      next(false);
      this.selectFirstTab();
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters(['permissions', 'isDevEnv', 'isDevelopment','iAmAnUnmigratedIntermediary','myAccount']),
    ...mapState([]),

    // ------------------------------
    // Permissions and guards
    // ------------------------------

    // ----- Title Bar -----

    titleBar() {
      const titleBar = {
        label: this.titleBarLabel,
        buttons: [],
      };
      return titleBar;
    },
    titleBarLabel() {
      // return `User: ${this.userDisplayName}`;
      return `${this.userDisplayName}`;
    },

    // ----- Tabs -----

    showTabs() {
      return true;
    },
    signatoryTabRouteName() {
      return this.record.hasSignatory ? 'userSignatoryView' : 'userNoSignatoryView';
    },
    signatoryRouteParams() {
      return this.record?.hasSignatory ? { signatoryId: this.record.userSignatory?.id } : {};
    },
    tabs() {
      let tabs = [
        { tabName: 'details', routeName: 'userDetailsView', guards: this.details_tabGuard, label: 'Details' },
        {
          tabName: 'permissions',
          routeName: 'userPermissionsView',
          guards: this.permissions_tabGuard,
          label: 'Permissions',
        },
        {
          tabName: 'signatory',
          routeName: this.signatoryTabRouteName,
          guards: this.signatory_tabGuard,
          label: 'Signatory',
          routeParams: this.signatoryRouteParams,
        },
        { tabName: 'settings', routeName: 'userSettingsView', guards: this.settings_tabGuard, label: 'Settings' }, // TODO
        {
          tabName: 'preferences',
          routeName: 'userPreferencesView',
          guards: this.preferences_tabGuard && this.isDevelopment, // TODO - LEAVE FOR NOW
          label: 'Preferences',
        },
        { tabName: 'access', routeName: 'userLogsView', guards: this.logs_tabGuard, label: 'Access' }, // TODO
      ];
      return tabs.filter((tab) => tab.guards);
    },
    details_tabGuard() {
      return this.permissions.getUser;
    },
    permissions_tabGuard() {
      return this.permissions.getUserPermissions && this.record.notSystemUser;
    },
    signatory_tabGuard() {
      return (
        this.record.notSystemUser &&
        (this.record.userAccountLevel === 'intermediary' || this.record.userAccountLevel === 'intermediaryBranch') &&
        (this.myAccount.userReviewStatus !== 'nonCompliant') // || this.myAccount.accountLevel === 'bank')  //2024-09-19 Bank clients should be disabled if no review done
      );
    },
    settings_tabGuard() {
      return false && this.permissions.getUserSettingsAndPermissions && this.record.notSystemUser;
    },
    preferences_tabGuard() {
      return this.permissions.getUserSettingsAndPermissions && this.record.notSystemUser;
    },
    logs_tabGuard() {
      return this.permissions.getUserAccessLogs && this.record.notSystemUser;
    },

    includeRefreshCounter() {
      return ['userAccessLogs'].includes(this.actionGroup);
    },
    // ----- Action Bar -----

    showActions() {
      return this.actionBarButtons.length !== 0 ? true : false;
    },
    actionBarButtons() {
      let self = this;
      let allButtons = {
        userDetailsView: [
          //
          // ----- User Details View

          {
            name: 'unlockUser',
            label: 'Unlock User',
            type: 'primary',
            modal: {
              header: `Unlock User: ${this.record.userDisplayName}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Unlock',
              content: this.unlockUserModalMessage,
            },
            action: 'unlockUser',
            params: { userId: this.record.id },
            actionParams: { userId: this.record.id },
            guards: this.unlockUser_buttonGuard,
          },
          {
            name: 'unhideUser',
            label: 'Unhide User',
            type: 'primary',
            modal: {
              header: `Unhide User: ${this.record.userDisplayName}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Unhide',
              content: this.unhideUserModalMessage,
            },
            action: 'unhideUser',
            params: { userId: this.record.id },
            actionParams: { userId: this.record.id },
            guards: this.unhideUser_buttonGuard,
          },
          {
            name: 'configureUser',
            label: 'Configure User',
            type: 'primary',
            guards: this.configureUser_buttonGuard && !this.iAmAnUnmigratedIntermediary,
            clickEvent() {
              self.$router.push({ name: 'configureUserDetailsEdit' });
            },
          },
          {
            name: 'enableUser',
            label: 'Enable User',
            type: '',
            modal: {
              header: `Enable User: ${this.record.userDisplayName}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Enable',
              content: this.enableUserModalMessage,
            },
            action: 'enableUser',
            params: { userId: this.record.id },
            actionParams: { userId: this.record.id },
            guards: this.enableUser_buttonGuard,
          },
          {
            name: 'resendWelcomeEmail',
            label: 'Resend Welcome Email',
            type: 'primary',
            modal: {
              header: `Resend Email to User: ${this.record.userDisplayName}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Resend',
              content: this.resendEmailToUserModalMessage,
            },
            action: 'resendCognitoEmail',
            params: { userId: this.record.id },
            actionParams: { userId: this.record.id },
            guards: this.resendWelcomeEmail_buttonGuard,
          },
          {
            name: 'editUserDetails',
            label: 'Edit Personal Details',
            type: '',
            guards: this.editUserDetails_buttonGuard && !this.iAmAnUnmigratedIntermediary,
            clickEvent() {
              self.$router.push({ name: 'userDetailsEdit' });
            },
          },
          {
            name: 'editUserLogin',
            label: 'Edit Login Details',
            type: '',
            guards: this.record.editUserLogin_buttonGuard && !this.iAmAnUnmigratedIntermediary,
            clickEvent() {
              self.$router.push({ name: 'userDetailsEditAuth' });
            },
          },
          {
            name: 'editUserBankLogin',
            label: 'Edit ESB Details',
            type: '',
            guards: this.record.editUserBankLogin_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'userDetailsEditBankLogin' });
            },
          },
          {
            name: 'editUserOdinLogin',
            label: 'Edit Odin Details',
            type: '',
            guards: this.record.editUserBankLogin_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'userDetailsEditOdinLogin' });
            },
          },
          {
            name: 'retryIntegrationLogin',
            label: 'Integration Login',
            type: 'primary',
            modal: {
              header: `Integration Login: ${this.record.userDisplayName}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Login',
              content: this.integrationLoginModalMessage,
            },
            action: 'bankLogin',
            params: { userId: this.record.id },
            actionParams: { userId: this.record.id },
            guards: this.integrationLogin_buttonGuard && !this.iAmAnUnmigratedIntermediary,
          },
          {
            name: 'retryOdinLogin',
            label: 'Odin Login',
            type: 'primary',
            modal: {
              header: `Odin Login: ${this.record.userDisplayName}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Login',
              content: this.odinLoginModalMessage,
            },
            action: 'odinLogin',
            params: { userId: this.record.id },
            actionParams: { userId: this.record.id },
            guards: this.odinLogin_buttonGuard && !this.iAmAnUnmigratedIntermediary,
          },
          {
            name: 'moveUser',
            label: 'Move User',
            type: '',
            guards: this.moveUser_buttonGuard && !this.iAmAnUnmigratedIntermediary,
            clickEvent() {
              self.$router.push({ name: 'userDetailsMove' });
            },
          },
          {
            name: 'disableUser',
            label: 'Disable User',
            type: '',
            modal: {
              header: `Disable User: ${this.record.userDisplayName}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Disable',
              content: this.disableUserModalMessage,
            },
            action: 'disableUser',
            params: { userId: this.record.id },
            actionParams: { userId: this.record.id },
            guards: this.disableUser_buttonGuard,
          },
          {
            name: 'hideUser',
            label: 'Hide User',
            type: '',
            modal: {
              header: `Hide User: ${this.record.userDisplayName}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Hide',
              content: this.hideUserModalMessage,
            },
            action: 'hideUser',
            params: { userId: this.record.id },
            actionParams: { userId: this.record.id },
            guards: this.hideUser_buttonGuard,
          },
          {
            name: 'assignUserTeam',
            label: 'Assign Team',
            type: '',
            guards: this.assignUserTeam_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'userDetailsAssignTeam' });
            },
          },
        ],

        // ----- User Details Edit

        userDetailsEdit: [
          {
            name: 'userDetailsEditCancel',
            label: 'Cancel',
            type: '',
            guards: true,
            clickEvent() {
              self.$router.push({ name: 'userDetailsView' });
            },
          },
        ],

        // ----- User Permissions View

        userPermissionsView: [
          {
            name: 'reviewPendingPermissions',
            label: 'Review Pending Permissions',
            type: 'primary',
            guards: self.reviewPendingPermissions_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'userPermissionsViewReview' });
            },
          },
          {
            name: 'resendUserPermissionsEmail',
            label: 'Resend Permissions Email',
            type: '',
            guards: self.reviewPendingPermissions_buttonGuard,
            action: 'resendUserPermissionsEmail',
            params: { userId: this.record.id },
            actionParams: { userId: this.record.id },
          },
          {
            name: 'editUserPermissions',
            label: 'Edit Permissions',
            type: '',
            guards: self.editUserPermissions_buttonGuard && !this.iAmAnUnmigratedIntermediary,
            clickEvent() {
              self.$router.push({ name: 'userPermissionsEdit' });
            },
          },
          {
            name: 'recacheUserPermissions',
            label: 'Recache Permissions',
            type: '',
            guards: self.recacheUserPermissions_buttonGuard && !this.iAmAnUnmigratedIntermediary,
            clickEvent() {
              self.$router.push({ name: 'userPermissionsRecache' });
            },
          },
        ],

        // ----- User Permissions Edit

        userPermissionsEdit: [
          {
            name: 'userPermissionsEditCancel',
            label: 'Cancel',
            type: '',
            guards: true,
            clickEvent() {
              self.$router.push({ name: 'userPermissionsView' });
            },
          },
        ],

        // ---- User No Signatory

        userNoSignatoryView: [
          {
            name: 'enableUserSignatory',
            label: 'Enable User Signatory',
            type: '',
            guards: this.enableUserSignatory_buttonGuard,
            clickEvent() {
              self.$router.push({
                name: 'userNoSignatoryViewEnableSignatory',
                params: { userId: self.record.id },
              });
            },
          },
        ],

        // ----- User Signatory

        userSignatoryView: [
          // {
          //   name: 'enableUserSignatory',
          //   label: 'Enable User Signatory',
          //   type: 'primary',
          //   modal: {
          //     header: `Enable Signatory for User: ${this.record.userDisplayName}`,
          //     cancelLabel: 'Cancel',
          //     confirmLabel: 'Enable',
          //     content: this.enableUserSignatoryModalMessage,
          //   },
          //   action: 'enableUserSignatory',
          //   params: { userId: this.record.id },
          //   actionParams: { userId: this.record.id },
          //   guards: this.enableUserSignatory_buttonGuard,
          // },
          {
            name: 'manageSignatory',
            label: 'Manage Signatory',
            type: 'primary',
            guards: this.manageUserSignatory_buttonGuard,
            clickEvent() {
              self.$router.push({
                name: 'accountSignatory',
                params: { accountId: self.record.accountId, signatoryId: self.record.userSignatory?.id },
              });
            },
          },
        ],

        // ----- User settings

        userSettingsView: [
          {
            name: 'editUserSettings', //TODO
            label: 'Edit Settings',
            type: 'primary',
            guards: false && self.editUserSettings_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'userSettingsEdit' });
            },
          },
        ],
        userSettingsEdit: [
          {
            name: 'userSettingsEditCancel',
            label: 'Cancel',
            type: '',
            guards: true,
            clickEvent() {
              self.$router.push({ name: 'userSettingsView' });
            },
          },
        ],

        // ----- User Preferences

        userPreferencesView: [
          {
            name: 'editUserPreferences', //TODO
            label: 'Edit Preferences',
            type: 'primary',
            guards: false && self.editUserPreferences_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'userPreferencesEdit' });
            },
          },
        ],
        userPreferencesEdit: [
          {
            name: 'userPreferencesEditCancel',
            label: 'Cancel',
            type: '',
            guards: true,
            clickEvent() {
              self.$router.push({ name: 'userPreferencesView' });
            },
          },
        ],

        // ----- User access

        userAccessLogs: [
          {
            name: 'enableMfa',
            label: 'Enable MFA',
            modal: {
              header: `Enable MFA for: ${this.record.userDisplayName}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Enable',
              content: this.enableMfaModalMessage,
            },
            action: 'enableMfa',
            params: { userId: this.record.id },
            actionParams: { userId: this.record.id },
            guards: this.record.canEditUserAuth && !this.record.mfaEnabled,
          },
          {
            name: 'disableMfa',
            label: 'Disable MFA',
            modal: {
              header: `Disable MFA for ${this.record.userDisplayName}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Disable',
              content: this.disableMfaModalMessage,
            },
            action: 'disableMfa',
            params: { userId: this.record.id },
            actionParams: { userId: this.record.id },
            guards: this.record.canEditUserAuth && this.record.mfaEnabled,
          },
          {
            name: 'addSimHoldExclusion', // TODO make form and redirect to it
            label: 'Confirm SIM Swap',
            modal: {
              header: `Confirm SIM Swap for ${this.record.userDisplayName}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Confirm',
              content: this.addSimHoldExclusionModalMessage,
            },
            action: 'addSimHoldExclusion',
            params: { userId: this.record.id },
            actionParams: { userId: this.record.id },
            guards: this.record.canEditUserAuth && this.record.mfaEnabled && this.record.currentMfaExclusion === 'none',
          },
          {
            name: 'addOtpExclusion',
            label: 'Change to OTP (SMS/Email)',
            guards: this.record.canEditUserAuth && this.record.mfaEnabled && this.record.currentMfaExclusion !== 'otp',
            clickEvent() {
              self.$router.push({ name: 'userAddOtpExclusion' });
            },
          },
          {
            name: 'clearMfaExclusion',
            label: 'Change to USSD',
            modal: {
              header: `Change to USSD for ${this.record.userDisplayName}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Change',
              content: this.clearMfaExclusionModalMessage,
            },
            action: 'clearMfaExclusion',
            params: { userId: this.record.id },
            actionParams: { userId: this.record.id },
            guards: this.record.canEditUserAuth && this.record.mfaEnabled && this.record.currentMfaExclusion === 'otp',
          },

          {
            name: 'refreshLogs',
            label: 'Refresh',
            type: '',
            guards: true,
            action: 'click',
            params: {},
            httpParams: {},
            clickEvent: self.incrementRefreshCounter,
          },
          {
            name: 'userEmailLogs',
            label: 'Emails',
            type: '',
            guards: this.isDevEnv,
            clickEvent() {
              self.$router.push({
                name: 'userEmailLogs',
                params: { userId: self.record.id },
              });
            },
          },
        ],
      };
      if (this.actionGroup) {
        return allButtons[this.actionGroup] ? allButtons[this.actionGroup] : []; //.filter((button) => button.guards) : [];
      } else {
        return allButtons[this.$route.name] !== undefined
          ? allButtons[this.$route.name] //.filter((button) => button.guards)
          : [];
      }
    },
    actionGroup() {
      return this.$route.meta?.content?.actionGroup;
    },
    // ----- Buttons -----

    // User Details

    integrationLogin_buttonGuard() {
      return (
        this.record.userNotHidden &&
        this.record.userNotDisabled &&
        !['na', 'disabled'].includes(this.record.esbUserStatus) &&
        !!this.record.esbUsername &&
        (!this.record.notMyUser || // I can try again for my own user
          this.permissions.editUser ||
          this.permissions.grantUserPermissions) // An administrator can also try for me
      );
    },
    odinLogin_buttonGuard() {
      return (
        this.record.userNotHidden &&
        this.record.userNotDisabled &&
        !['na', 'disabled'].includes(this.record.odnUserStatus) &&
        !!this.record.odnUsername &&
        (!this.record.notMyUser || // I can try again for my own user
          this.permissions.editUser ||
          this.permissions.grantUserPermissions) // An administrator can also try for me
      );
    },
    unhideUser_buttonGuard() {
      return (
        this.record.notMyUser &&
        // this.permissions.unhideUser &&
        !this.record.userNotHidden
      ); // user must be hidden to unhide
    },

    unlockUser_buttonGuard() {
      //@ts-expect-error
      return (
        this.record.userLocked &&
        this.record.notMyUser &&
        this.record.userNotHidden &&
        this.record.userNotDisabled &&
        this.record.notSystemUser
      );
    },
    configureUser_buttonGuard() {
      return (
        this.record.notMyUser &&
        this.record.notSystemUser &&
        (this.permissions.editUser ||
          this.permissions.editUserAuth ||
          this.permissions.getUserPermissions ||
          this.permissions.updateUserPermissions ||
          this.permissions.enableUser) &&
        this.record.userNotHidden &&
        //@ts-ignore
        this.record.status === 'new'
      );
    },

    enableUser_buttonGuard() {
      return (
        this.record.notMyUser &&
        // this.permissions.enableUser &&
        this.record.userNotHidden &&
        this.record.notSystemUser &&
        // @ts-ignore
        (this.record.status === 'new' || this.record.status === 'disabled') && 
        (this.record.accountId !== 1 || !!this.record.userTeamId)
      );
    },

    resendWelcomeEmail_buttonGuard() {
      return (
        this.record.notMyUser &&
        // this.permissions.resendCognitoEmail &&
        this.record.userNotHidden &&
        this.record.notSystemUser &&
        // @ts-ignore
        this.record.status === 'pending'
      );
    },

    editUserDetails_buttonGuard() {
      return (
        this.record.notMyUser && this.permissions.editUser && this.record.userNotHidden && this.record.userNotDisabled
      );
    },

    moveUser_buttonGuard() {
      return (
        this.record.notMyUser &&
        this.permissions.moveUser &&
        this.record.userNotHidden &&
        (this.record.userAccountLevel === 'intermediary' || this.record.userAccountLevel === 'intermediaryBranch') &&
        true &&
        this.record.notSystemUser
      ); // TODO check account exist for user to be moved to - or change button type for UX
    },

    disableUser_buttonGuard() {
      return (
        this.record.notMyUser &&
        // this.permissions.disableUser &&
        this.record.userNotHidden &&
        this.record.userNotDisabled &&
        this.record.notSystemUser
      );
    },

    hideUser_buttonGuard() {
      return (
        this.record.notMyUser &&
        // this.permissions.hideUser &&
        this.record.userNotHidden &&
        !this.record.userNotDisabled &&
        this.record.notSystemUser
      );
    },
    assignUserTeam_buttonGuard() {
      return (
        this.record.notMyUser &&
        this.permissions.updateUserUserTeam &&
        this.record.userNotHidden &&
        this.record.userNotDisabled &&
        this.record.notSystemUser &&
        this.record.userAccountLevel === 'bank'
      )
    },

    // User Permissions

    editUserPermissions_buttonGuard() {
      return (
        //@ts-ignore
        this.record.notMyUser &&
        this.record.userNotHidden &&
        this.record.status !== 'disabled' &&
        this.permissions.updateUserPermissions &&
        this.record.notSystemUser
      );
    },
    recacheUserPermissions_buttonGuard() {
      return this.record.notSystemUser && (this.permissions.grantUserPermissions || this.permissions.rejectUserPermissions)
    },

    reviewPendingPermissions_buttonGuard() {
      return (
        this.record.notMyUser &&
        (this.permissions.grantUserPermissions || this.permissions.rejectUserPermissions) &&
        this.record.userNotHidden &&
        this.record.pendingPermissionsActionable.length > 0 &&
        this.record.notSystemUser
      );
    },
    grantPendingPermissions_buttonGuard() {
      return (
        this.record.notMyUser &&
        this.permissions.grantUserPermissions &&
        this.record.userNotHidden &&
        this.record.pendingPermissionsActionable.length > 0 &&
        this.record.notSystemUser
      );
    },
    rejectPendingPermissions_buttonGuard() {
      return (
        this.record.notMyUser &&
        this.permissions.rejectUserPermissions &&
        this.record.userNotHidden &&
        this.record.pendingPermissionsActionable.length > 0 &&
        this.record.notSystemUser
      );
    },

    // Settings

    editUserSettings_buttonGuard() {
      return (
        this.record.notMyUser &&
        this.permissions.updateUserSettings &&
        this.record.userNotHidden &&
        this.record.status === 'active' &&
        this.notSystemUser
      );
    },
    enableUserSignatory_buttonGuard() {
      return (
        this.record.notMyUser &&
        this.record.userNotHidden &&
        this.record.status === 'active' &&
        this.permissions.enableUserSignatory &&
        this.record.notSystemUser &&
        !this.record.hasSignatory &&
        this.record.hasSignatoryInformation
      );
    },
    manageUserSignatory_buttonGuard() {
      return (
        this.record.userNotHidden &&
        this.record.status !== 'disabled' &&
        this.record.notSystemUser &&
        !!this.record.hasSignatory
      );
    },

    // ----- Preferences

    editUserPreferences_buttonGuard() {
      //@ts-ignore
      return !this.record.notMyUser && this.permissions.updateUserSettings && this.record.notSystemUser; // can only edit own preferences
    },

    // ----- Layout -----

    formLayout() {
      return this.$route.meta?.content?.layout === 'list' ? false : true;
    },

    // ----- Content Label -----

    showLabel() {
      return true;
    },
    contentLabel() {
      return this.$route.meta?.content?.label
        ? this.$route.meta.content.label
        : "Navigation didn't complete, please click tab above to continue"; // TODO
    },

    // ----- User Record -----

    recordName() {
      return 'user';
    },
    recordId() {
      return +this.$route.params.userId;
    },
    record() {
      return this.$store.state?.[this.recordName]?.[this.recordId];
    },
    definition() {
      return getDefinition(this.recordName);
    },
    userStatus() {
      return this.record.status;
    },

    userDisplayName() {
      return this.record?.userDisplayName;
    },

    // ----- Signatory Record -----

    signatoryId() {
      return +this.$route.params.signatoryId;
    },
    signatoryRecord() {
      return this.record?.userSignatory;
    },

    // --------------- Modal Message ---------

    integrationLoginModalMessage() {
      if (this.record.esbUserStatus === 'active') {
        return `
          <ol>
            <li>The user has already successfully logged in. Please only attempt to log in again if there are Session type integration problems.
          </ol>`;
      }
      return `
          <ol>
            <li>This will attempt an integration login for the user.
          </ol>`;
    },
    odinLoginModalMessage() {
      if (this.record.odnUserStatus === 'active') {
        return `
          <ol>
            <li>The user has already successfully logged in. Please only attempt to log in again if there are problems with the Odin Login.
          </ol>`;
      }
      return `
          <ol>
            <li>This will attempt an Odin login for the user.
          </ol>`;
    },

    enableUserSignatoryModalMessage() {
      return `
      <ol>
        <li>Enable the user as a signatory.</li>
      </ol>`;
    },
    unhideUserModalMessage() {
      return `
                        <ol>
        <li>The user will be visible in normal views again.</li>
        <li>This will allow interaction with the user again.</li>
        <li>Unhiding a user will not automatically enable them.</li>
      </ol>`;
    },
    unlockUserModalMessage() {
      return `
      <ol>
        <li>The user will be able to log in again.</li>
      </ol>`;
    },
    enableUserModalMessage() {
      switch (this.record.status) {
        case 'new':
          return `
          <capitec-label type="subtitle" label="If the user was previously in a New status:"></capitec-label>
          <ol>
          <li>Enable the User to log in.</li>
          <li>A Welcome Email will be sent to the User with instructions on how to log in.</li>
          <li>
            User's email address: <strong>${this.record.emailAddress}</strong>
          </li>
        </ol>
          `;
        case 'disabled':
          return `
        <capitec-label type="subtitle" label="If the user was previously in a New status:"></capitec-label>
        <ol>
          <li>User will be set to Pending status.</li>
          <li>A Welcome Email will be automatically sent to the User with instructions on how to log in.</li>
          <li>
            User's email address: <strong>${this.record.emailAddress}</strong>
          </li>
        </ol>
        <capitec-label type="subtitle" label="If the user was previously in a Pending status:"></capitec-label>
        <ol>
          <li>User will be set to Pending status.</li>
          <li>
            A Welcome Email will NOT be automatically sent to the User, but may be sent manually by using the Resend
            Welcome Email button.
          </li>
        </ol>
        <capitec-label type="subtitle" label="If the user was previously in an Active status:"></capitec-label>
        <ol>
          <li>User will be set to Active status.</li>
          <li>
            A Welcome Email will NOT be automatically sent to the User. You will have to let the user know manually
            outside the system.
          </li>
        </ol>
          `;
      }
    },
    resendEmailToUserModalMessage() {
      return `
            <ol>
        <li>
          User's email address: <strong>${this.record.emailAddress}</strong>
        </li>
        <li>The Welcome Email will be resent to the user with their new login details.</li>
        <li>The login details sent with the previous email will no longer be valid.</li>
        <li>If the user is currently locked they will be unlocked before the email is sent</li>
      </ol>
      `;
    },
    disableUserModalMessage() {
      return `
      <ol>
        <li>The user will be disabled.</li>
        <li>They will be unable to log in or perform any actions.</li>
        <li>They will no longer receive any communication.</li>
        <li>They will be disabled as a signatory.</li>
        <li>A user does not need to be disabled to be moved.</li>
        <li>Disabling a user will not allow the same email address to be used elsewhere.</li>
      </ol>
        `;
    },
    hideUserModalMessage() {
      return `
      <ol>
        <li>The user will be hidden from normal views.</li>
        <li>Searching for the user will still display the user.</li>
        <li>Setting a user to "Hidden" willl not allow the same email address to be used elsewhere.</li>
      </ol>`;
    },
    enableMfaModalMessage() {
      return `
      <ol>
        <li> The user will be required to perform multi factor authentication to log in and perform secure actions.
      </ol>
      `;
    },
    disableMfaModalMessage() {
      return `
      <ol>
        <li> The user will no longer be required to perform multi factor authentication to log in and perform secure actions.
      </ol>
      `;
    },
    addSimHoldExclusionModalMessage() {
      return `
      <ol>
        <li> This will confirm that the user has had a recent SIM Swap and bypass the SIM fraud protection checks.
      </ol>
      `;
    },
    clearMfaExclusionModalMessage() {
      return `
      <ol>
        <li> The user will now perform multi factor authentication using USSD
      </ol>
      `;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    incrementRefreshCounter() {
      this.refreshCounter++;
    },
    // ----- Tabs -----

    selectFirstTab() {
      this.$router.replace({ name: this.tabs[0].routeName });
    },
  },
};
</script>
