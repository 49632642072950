import { Component, css, html } from '../../elements';

/**
 * Component that indicates important information to the user about the system status or a process..
 * 
 * ```js
 * import 'platform/components/popups/AlertBanner';
 * ```
 * 
 * ```html
 * <capitec-alert-banner type="info" label="Some Information." closable>
 *   <span><a href="https://google.com">This is a link.</a> More Info and Detail</span>
 * </capitec-alert-banner>
 * ```
 * @prop {"info"|"success"|"warning"|"error"|"unsuccessful"|"unsuccessful-red"|String} type - The type of alert to display.
 */
export class AlertBanner extends Component {

	// --------------
	// INITIALISATION
	// --------------

	/**
	 * Initialises the component.
	 *
	 * @hideconstructor
	 */
	constructor() {

		super();

		this.type = `info`;
		this.label = ``;
		this.closable = false;
	}

	// ----------
	// PROPERTIES
	// ----------

	/**
	 * Registry of all properties defined by the component.
	 * 
	 * @property {String} [label] - The message to display on the alert banner.
	 * @property {String} [type="info"] - The type of alert to display:
	 *  - `info` GlobalOne style variation.
	 *  - `success` GlobalOne style variation.
	 *  - `warning` GlobalOne style variation.
	 *  - `error` GlobalOne style variation.
	 *  - `unsuccessful` GlobalOne style variation.
	 *  - `unsuccessful-red` GlobalOne style variation.
	 * @property {Boolean} [closable=false] - Whether the alert banner can be closed/close button visible.
	 */
	static get properties() {

		return {
			label: { type: String },
			type: { type: String },
			closable: { type: Boolean }
		};
	}

	// -------------------
	// LIFECYCLE OVERRIDES
	// -------------------

	// n/a	

	// --------------
	// EVENT HANDLERS
	// --------------

	_closeClicked(e) {

		// Notify any subscribers that the close button was clicked.
		this.dispatchEvent(new CustomEvent(`click`, {
			detail: {}
		}));
	}

	// --------------
	// PUBLIC METHODS
	// --------------

	// n/a

	// ---------------
	// PRIVATE METHODS
	// ---------------

	// n/a

	// ---------
	// RENDERING
	// ---------

	/**
	 * Generates the component stylesheet.
	 * 
	 * @returns {css} The css content of the component.
	 */
	static get styles() {

		return [
			super.styles,
			css`
				:host {
					display: block;

					border-radius: 0px;
				}

				.container {
					width: 100%;

					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
                    box-sizing: border-box;
                    height: var(--theme-alert-banner-height, 56px);
                    position: relative;
				}
				
				.container.info {
                    background-color: var(--theme-alert-banner-info-background-color, #DAF2F4);
				}
				
				.container.success {
                    background-color: var(--theme-alert-banner-success-background-color, #EAF3D9);
				}
				
				.container.warning {
                    background-color: var(--theme-alert-banner-warning-background-color, #F9EBDD);
				}
				
				.container.error {
                    background-color: var(--theme-alert-banner-error-background-color, #F7E2E1);
				}

				.container.unsuccessful-red {
                    background-color: var(--theme-alert-banner-unsuccessful-red-background-color, #F7E2E1);
				}
                
				.container.unsuccessful {
                    background-color: var(--theme-alert-banner-unsuccessful-background-color, rgba(73,91,101,0.1));
				}
				
                .icon {
                    margin-left: var(--theme-alert-banner-icon-margin-left, 26px);
                    margin-right: var(--theme-alert-banner-icon-margin-right, 18px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .content {
                    font-family: var(--theme-font-family,"Hind Vadodara");
                    font-size: var(--theme-alert-banner-font-size, 14px);
                    font-weight: var(--theme-alert-banner-font-weight, 500);
                    line-height: var(--theme-alert-banner-line-height, 21px);
				}

				.content.info {
                    color: var(--theme-alert-banner-info-font-color, #0083BB);
				}
				
				.content.success {
					color: var(--theme-alert-banner-success-font-color, #70AF00);
				}
				
				.content.warning {
					color: var(--theme-alert-banner-warning-font-color, #E47C28);
				}
				
				.content.error {
					color: var(--theme-alert-banner-error-font-color, #C83C37);
				}

				.content.unsuccessful {
					color: var(--theme-alert-banner-unsuccessful-font-color, #979797);
				}
				
				.content.unsuccessful-red {
					color: var(--theme-alert-banner-unsuccessful-red-font-color, #C83C37);
				}
                
                .close {
                    position: absolute;
					right: var(--theme-alert-banner-close-button-right, 28.86px);
					cursor: pointer;
				}

				::slotted(capitec-hyperlink) {
					--theme-font-weight: 600;
					text-decoration: underline;
				}
				
				.content.info > ::slotted(capitec-hyperlink) {
					--theme-hyperlink-color: var(--theme-alert-banner-info-font-color, #0083BB);
				}

				.content.success > ::slotted(capitec-hyperlink) {
					--theme-hyperlink-color: var(--theme-alert-banner-success-font-color, #70AF00);
				}
				
				.content.warning > ::slotted(capitec-hyperlink) {
					--theme-hyperlink-color: var(--theme-alert-banner-warning-font-color, #E47C28);
				}
				
				.content.error > ::slotted(capitec-hyperlink) {
					--theme-hyperlink-color: var(--theme-alert-banner-error-font-color, #C83C37);
				}

				.content.unsuccessful > ::slotted(capitec-hyperlink) {
					--theme-hyperlink-color: var(--theme-alert-banner-unsuccessful-font-color, #979797);
				}

				.content.unsuccessful-red > ::slotted(capitec-hyperlink) {
					--theme-hyperlink-color: var(--theme-alert-banner-unsuccessful-red-font-color, #C83C37);
				}
			`
		];
	}

	/**
	 * Generates the component template for mobile mode.
	 * 
	 * @returns {html} The html content of the component.
	 */
	_mobileTemplate() {

		return this._webTemplate();
	}

	/**
	 * Generates the component template for web mode.
	 * 
	 * @returns {html} The html content of the component.
	 */
	_webTemplate() {

		return html`
			<div class="container ${this.type}">
                <div class="icon" >
                    <capitec-icon icon="status/${this.type === `unsuccessful` ? `unsuccessful-trans` : `${this.type === `unsuccessful-red` ? `error-trans` : `${this.type}-trans`}`}"></capitec-icon>
                </div>
                <div class="content ${this.type}">
                    <label >${this.label}</label>
                    <slot></slot>
                </div>
                ${this.closable ? html`<capitec-icon @click="${(e) => this._closeClicked(e)}" class="close" size="small" icon="system/close-${this.type}"></capitec-icon>` : ``}
			</div>
		`;
	}

	/**
	 * Generates the component template for kiosk mode.
	 * 
	 * @returns {html} The html content of the component.
	 */
	_kioskTemplate() {

		return this._webTemplate();
	}
}

window.customElements.define(`capitec-alert-banner`, AlertBanner);

/**
 * When the close button is clicked.
 *
 * @example
 * <capitec-alert-banner ... @click="${this._handler}"></capitec-alert-banner>
 *
 * @event AlertBanner#click
 * @type {Object}
 * @property {Object} detail Event object.
 */
