<template>
  <LFXContent
    :titleBar="titleBar"
    :show_tabs="showTabs"
    :show_stepper="showStepper"
    :show_actions="showActions"
    :show_label="showLabel"
    :label="contentLabel"
    :form_layout="formLayout"
    :show_sidebar="false"
  >
    <template #actions>
      <capitec-button
        v-for="button in actionBarButtons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        @click="button.clickEvent"
      ></capitec-button
    ></template>

    <template #stepper
      ><capitec-stepper
        layout="horizontal"
        :steps="steps"
        :active-step-number="activeStepNumber"
        @step-click="onStepClick"
      >
      </capitec-stepper
    ></template>

    <template #content>
      <router-view @step-back="stepBack" @step-next="stepNext"></router-view>
    </template>
  </LFXContent>

  <router-view name="modal"></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXContent from '@/components/layout/LfxContent.vue';

export default {
  name: 'LfxAddBeneficiary',
  components: { LFXContent },
  props: {},
  data() {
    return {};
  },
  created() {
    if (this.$route.name === 'addBeneficiary') {
      this.selectFirstStep();
    }
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    // ----- Title Bar -----

    titleBar() {
      const titleBar = {
        icon: this.$route.meta?.icon?.[this.accountLevel],
        label: this.titleBarLabel,
        buttons: [
          {
            name: 'backToAccount',
            label: `Back to ${this.accountLabel}`,
            type: 'white',
            routeName: 'accountBeneficiariesList',
          },
        ],
      };
      return titleBar;
    },

    titleBarLabel() {
      return this.$route.matched[0].name === 'addBeneficiary'
        ? `${this.accountLabel}: ${this.accountRecord.name} / Add New Beneficiary`
        : `${this.accountLabel}: ${this.accountRecord.name} / Complete Beneficiary: ${this.beneficiaryName}`;
    },

    // ----- Tabs -----

    showTabs() {
      return false;
    },

    // ----- Stepper -----

    showStepper() {
      return true;
    },
    steps() {
      let steps = [
        {
          stepName: 'addBeneficiaryDetails',
          routeName: 'addBeneficiaryDetails',
          guards: !this.$route.params.beneficiaryId,
          isError: true,
          title: 'Details',
        },
        {
          stepName: 'addBeneficiaryBankAccount',
          routeName: 'addBeneficiaryBankAccount',
          guards: !this.$route.params.beneficiaryId,
          isError: false,
          title: 'Bank Account',
        },
        {
          stepName: 'completeBeneficiaryDetails',
          routeName: 'completeBeneficiaryDetails',
          guards: this.$route.params.beneficiaryId,
          isError: false, // TODO
          title: 'Details',
        },
        {
          stepName: 'completeBeneficiaryBankAccount',
          routeName: 'completeBeneficiaryBankAccount',
          guards: this.$route.params.beneficiaryId,
          isError: false,
          title: 'Bank Account',
        },
      ];
      return steps.filter((step) => step.guards);
    },

    routeStepName() {
      return this.$route.meta?.content?.stepName;
    },
    activeStepNumber() {
      let activeStepIndex = this.steps.findIndex((step) => step.stepName === this.routeStepName);
      let activeStepNumber = activeStepIndex + 1; // stepnumber starts at 1
      return activeStepNumber;
    },

    // ----- Action Bar -----

    showActions() {
      return this.actionBarButtons.length !== 0 ? true : false;
    },
    actionBarButtons() {
      let self = this;
      let allButtons = {
        addBeneficiaryDetails: [],
        addBeneficiaryBankAccount: [],
        completeBeneficiaryDetails: [],
        completeBeneficiaryBankAccount: [],
      };
      return allButtons[this.actionGroup] !== undefined
        ? allButtons[this.actionGroup].filter((button) => button.guards)
        : [];
    },

    actionGroup() {
      return this.$route.meta?.content?.actionGroup;
    },

    // ----- Layout -----

    formLayout() {
      return this.$route.meta?.layout === 'list' ? false : true;
    },

    // ----- Content Label -----

    showLabel() {
      return this.contentLabel ? true : false;
    },
    contentLabel() {
      return this.$route.meta?.content?.label || 'TODO';
    },

    // ----- Account Record -----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },
    accountLevel() {
      return this.accountRecord.accountLevel;
    },
    accountLabel() {
      if (this.accountLevel === 'bank') {
        return 'Bank';
      } else if (this.accountLevel === 'intermediary') {
        return 'Intermediary';
      } else if (this.accountLevel === 'intermediaryBranch') {
        return 'Branch';
      } else if (this.accountLevel === 'client') {
        return 'Client';
      } else {
        return 'Account';
      }
    },

    // ----- Beneficiary record -----

    beneficiaryId() {
      return +this.$route.params?.beneficiaryId;
    },
    beneficiaryRecord() {
      return this.accountRecord?.beneficiaries?.[this.beneficiaryId] || {};
    },
    beneficiaryName() {
      return this.beneficiaryRecord?.beneficiaryType === 'entity'
        ? this.beneficiaryRecord?.name
        : `${this.beneficiaryRecord?.individualFirstname} ${this.beneficiaryRecord?.name}`;
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),

    // ----- Stepper -----

    selectFirstStep() {
      this.$router.replace({ name: this.steps[0].routeName });
    },

    onStepClick(evt) {
      let newStepNumber = evt.detail.stepNumber;
      let newStepNumberIndex = newStepNumber - 1; // stepnumber starts at 1
      let newStep = this.steps[newStepNumberIndex];
      this.$router.replace({ name: newStep.routeName });
    },
    stepBack() {
      let newStepNumber = this.activeStepNumber - 1;
      let newStepNumberIndex = newStepNumber - 1; // stepnumber starts at 1
      let newStep = this.steps[newStepNumberIndex];
      this.$router.replace({ name: newStep.routeName });
    },
    stepNext() {
      let newStepNumber = this.activeStepNumber + 1;
      let newStepNumberIndex = newStepNumber - 1; // stepnumber starts at 1
      let newStep = this.steps[newStepNumberIndex];
      this.$router.replace({ name: newStep.routeName });
    },
  },
};
</script>
