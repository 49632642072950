<template>
  <LDBModal result="info" :header="header" size="xx-large">
    <template #body>
      <!-- <LDBLoader v-if="failedTreasuryDeal_retrievalStatus === 'retrieving'" /> -->
      <LDBItemView :view="view" :record="bopCatRecord" />
    </template>
    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxConfigBopCatDetails',
  components: { LDBItemView },
  props: {},
  // data() {
  //   return { failedTreasuryDeal_retrievalStatus: 'retrieving' }; //'retrieved' | 'retrieving'
  // },
  created() {},
  mounted() {
    // if (this.failedTreasuryDeal?.[this.failedTreasuryDealId]) {
    //   this.failedTreasuryDeal_retrievalStatus = 'retrieved';
    // } else {
    //   this.retrieveFailedTreasuryDeal();
    // }
  },
  computed: {
    ...mapGetters(['config']),
    ...mapState([]),

    // ----- Modal -----

    header() {
      return `BOP Category: ${this.bopCatRecord?.displayId}`;
    },

    // ----- View -----

    view() {
      return 'item';
    },

    // ----- BOP Category record -----

    bopCatId() {
      return this.$route.params?.bopCatId;
    },
    bopCatRecord() {
      return this.config?.bopCat?.[this.bopCatId];
    },

    // ----- API action -----
    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'close',
          label: 'Close',
          type: '',
          action: 'click',
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
      ];
      return allButtons;
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    // async retrieveFailedTreasuryDeal() {
    //   const response = await doPermissionAction(
    //     { onSuccess: { doStoreUpdate: true } },
    //     'getFailedTreasuryDeal',
    //     { failedTreasuryDealId: this.failedTreasuryDealId },
    //     {}
    //   );
    //   this.failedTreasuryDeal_retrievalStatus = 'retrieved';
    // },
  },
};
</script>
