<template>
  <!-- <div class="lfx-user-access-report"> -->
    <!-- <capitec-content-box> -->
      <capitec-action-bar class="lfx-user-access-action-bar">
        <LDBButton
          v-for="button in leftButtons"
          :key="button.name"
          :label="button.label"
          :action="button.action"
          :actionParams="button.actionParams"
          :params="button.params"
          :type="button.type"
          :tooltip="button.tooltip"
          :modal="button.modal"
          :viewGuards="button.guards"
          :enabledGuards="button.enabledGuards"
          :onSuccess="button.onSuccess"
          @beforeClick="button.beforeClick"
          @click="button.onClick"
          @on-api-success="button.onApiSuccess"
          @on-api-error="button.onApiError"
        >
          <template v-if="button.modal !== undefined" #modal>
            <div v-html="button.modal.content"></div>
          </template>
        </LDBButton>
        <capitec-spacer> </capitec-spacer>
        <LDBButton
          v-for="button in rightButtons"
          :key="button.name"
          :label="button.label"
          :action="button.action"
          :actionParams="button.actionParams"
          :params="button.params"
          :type="button.type"
          :tooltip="button.tooltip"
          :modal="button.modal"
          :viewGuards="button.guards"
          :enabledGuards="button.enabledGuards"
          :onSuccess="button.onSuccess"
          @beforeClick="button.beforeClick"
          @click="button.onClick"
          @on-api-success="button.onApiSuccess"
          @on-api-error="button.onApiError"
        >
          <template v-if="button.modal !== undefined" #modal>
            <div v-html="button.modal.content"></div>
          </template>
        </LDBButton>
      </capitec-action-bar>
      <template v-if="dataStatus === 'retrieving'">
        <LDBLoader/>
      </template>
      <template v-else-if="dataStatus === 'retrieved'">
        <div class="lfx-user-access-report-view-table-container">
          <table-lite
            id="lfx-user-access-report-view-table"
            :is-static-mode="true"
            :is-slot-mode="true"
            :has-checkbox="false"
            :is-loading="false"
            :is-re-search="false"
            :columns="tableColumns"
            :rows="sortedRecords"
            :total="sortedRecords.length"
            :pageSize="100"
          >
            <template v-for="(col, i) of tableColumns" v-slot:[col.field]="data" :key="i">
              <template v-if="col.permissionColumn">
                <template v-if="data.value[col.field] === 'active'"><img src='/platform/icons/status/success.svg'></template>
                <template v-if="data.value[col.field] === 'pending'"><img src='/platform/icons/status/warning.svg'></template>
                <template v-if="data.value[col.field] === 'disabled'"><img src='/platform/icons/status/unsuccessful-red.svg'></template>
              </template>
              <template v-else-if="col.field === 'statusSummary'">
                <template v-if="data.value[col.field] === 'New'"><p style='font-weight: bold; color:DodgerBlue'>{{data.value[col.field]}}</p></template>
                <template v-else-if="data.value[col.field] === 'Pending'"><p style='font-weight: bold; color:DarkOrange'>{{data.value[col.field]}}</p></template>
                <template v-else-if="data.value[col.field] === 'Active - Locked'"><p style='font-weight: bold; color:DarkOrange'>{{data.value[col.field]}}</p></template>
                <template v-else-if="data.value[col.field] === 'Active - Permissions Requested'"><p style='font-weight: bold; color:DarkOrange'>{{data.value[col.field]}}</p></template>
                <template v-else-if="data.value[col.field] === 'Active'"><p style='font-weight: bold; color:green'>{{data.value[col.field]}}</p></template>
                <template v-else-if="data.value[col.field] === 'Hidden'"><p style='font-weight: bold; color:DarkGray'>{{data.value[col.field]}}</p></template>
                <template v-else-if="data.value[col.field] === 'Disabled'"><p style='font-weight: bold; color:DarkGray'>{{data.value[col.field]}}</p></template>
                <template v-else><p style='font-weight: bold'>{{data.value[col.field]}}</p></template>
              </template>
              <template v-else-if="col.type === 'button' && data.value[col.field]">
                <LDBButton
                  :key="data.value[col.field].name"
                  :label="data.value[col.field].label"
                  :action="data.value[col.field].action"
                  :actionParams="data.value[col.field].actionParams"
                  :params="data.value[col.field].params"
                  :type="data.value[col.field].type"
                  :viewGuards="data.value[col.field].guards"
                  :enabledGuards="data.value[col.field].enabledGuards"
                  :modal="data.value[col.field].modal"
                  :onSuccess="data.value[col.field].onSuccess"
                  @click="data.value[col.field].onClick"
                >
                <template v-if="data.value[col.field].modal !== undefined" #modal>
                  <div v-html="data.value[col.field].modal.content"></div>
                </template></LDBButton>
              </template>
              <p v-else>{{data.value[col.field]}}</p>
            </template>
          </table-lite>
        </div>
      </template>
    <!-- </capitec-content-box> -->

  <!-- </div> -->
  <router-view></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBField from '@/landobyte_vue/LDBField.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import TableLite from '@/views/general/TableLite.vue';
import { createWorkSheet,createWorkbookAndWriteToFile } from '@/utils/LfbFrontEndSpreadSheets'

export default {
  name: 'LfxUserAccessReport',
  components: { LDBField,LDBLoader,TableLite },
  props: {},
  data() {
    return {
      dataStatus: 'retrieved',
      includeInactive: false,
      showBankOnly: false,
      showMyTeamOnly: false,
      mode: 'details' // 'details' | 'permissions'
    };
  },
  created() {},
  mounted() {
  },
  computed: {
    ...mapGetters(['config','permissions']),
    ...mapState(['user','authUser','account']),

    accountId() {
      return +this.$route.params?.accountId;
    },
    // ----- Buttons -----

    leftButtons() {
      let self = this;
      return [
        {
          name: 'toggleMyTeanAll',
          label: this.showMyTeamOnly ? "Show All Users" : "Show My Team Only",
          type: '',
          action: 'click',
          guards: this.dataStatus == 'retrieved' && this.authUser.accountLevel === 'bank' && !self.showBankOnly && !!this.user[this.authUser?.id]?.userTeamId,
          onClick() {
            self.showMyTeamOnly = !self.showMyTeamOnly
          },
        },
        {
          name: 'toggleBankOrAll',
          label: this.showBankOnly ? "Show All Users" : "Show Bank Users Only",
          type: '',
          action: 'click',
          guards: this.dataStatus == 'retrieved' && this.authUser.accountLevel === 'bank' && !self.showMyTeamOnly,
          onClick() {
            self.showBankOnly = !self.showBankOnly
          },
        },
        {
          name: 'toggleIncludeInactive',
          label: this.mode === 'details' ? "Show Permissions" : "Show Details",
          type: '',
          action: 'click',
          guards: this.dataStatus == 'retrieved',
          onClick() {
            if (self.mode === 'details') {
              self.mode = 'permissions'
            } else {
              self.mode = 'details'
            }
          },
        },
        {
          name: 'toggleMode',
          label: this.includeInactive ? "Hide Inactive and Hidden" : "Show Inactive and Hidden",
          type: '',
          action: 'click',
          guards: this.dataStatus == 'retrieved',
          onClick() {
            self.includeInactive = !self.includeInactive
          },
        },
      ];
    },
    rightButtons() {
      let self = this;
      return [
        {
          name: 'generateReport',
          label: "Generate Report",
          type: 'primary',
          action: 'click',
          guards: this.dataStatus !== 'retrieved',
          onClick() {
            self.dataStatus = 'retrieved'
          },
        },
        {
          name: 'confirmUserReview',
          label: 'User Review',
          type: 'primary',
          action: 'click',
          guards: this.dataStatus === 'retrieved' && this.allowUserReview,
          onClick() {
            self.$router.push({ name: 'userReviewConfirmView' });
          },
        },
        {
          name: 'exportData',
          label: 'Download',
          type: 'primary',
          action: 'click',
          guards: this.dataStatus === 'retrieved' && this.downloadMethod === 'frontend',
          onClick() {
            self.createAndServeFile()
          },
        },
        {
          name: 'exportData',
          label: 'Download',
          type: 'primary',
          action: 'userAccessReport',
          actionParams: {
            includeInactive: self.includeInactive,
            accountId: self.accountId,
            showBankOnly: self.showBankOnly,
            showMyTeamOnly: self.showMyTeamOnly,
          },
          guards: this.dataStatus === 'retrieved' && this.downloadMethod === 'backend',
        },
      ];
    },

  // ----- TABLE -----
    allTableColumns() {
      let res = [
        {
          col: "displayNameColumn",
          field: "displayName",
          label: "Name",
          isKey: false,
          sortable: false,
          guards: true,
        },
        {
          col: "emailAddressColumn",
          field: "emailAddress",
          label: "Email Address",
          isKey: false,
          sortable: false,
          guards: this.mode === 'details',
        },
        {
          col: "accountColumn",
          field: "accountName",
          label: "Account",
          isKey: false,
          sortable: false,
          guards: this.mode === 'details',
        },
        {
          col: "intermediaryColumn",
          field: "intermediaryName",
          label: "Intermediary",
          isKey: false,
          sortable: false,
          guards: this.mode === 'details' && this.authUser.accountLevel !== 'bank',
        },
        {
          col: "teamColumn",
          field: "teamName",
          label: "Team",
          isKey: false,
          sortable: false,
          guards: this.mode === 'details' && this.authUser.accountLevel === 'bank',
        },
        {
          col: "statusSummaryColumn",
          field: "statusSummary",
          label: "Status",
          isKey: false,
          sortable: false,
          guards: true,
        },
        {
          col: "createdAtColumn",
          field: "createdAt",
          label: "Created",
          isKey: false,
          sortable: false,
          guards: this.mode === 'details',
        },
        {
          col: "lastLoginColumn",
          field: "lastLogin",
          label: "Last Login",
          isKey: false,
          sortable: false,
          guards: this.mode === 'details',
        },
      ];
      for (const permissionId of this.includedPermissions) {
        res.push(
          {
            col: permissionId + "Column",
            field: permissionId,
            label: this.dbPermissions[permissionId]?.description || permissionId,
            isKey: false,
            sortable: false,
            guards: this.mode === 'permissions',
            permissionColumn:true
          }
        )
      }
      if (!!this.permissions?.disableUser) {
        res.push({
          col: 'disableUserButton',
          field: 'disableUser',
          label: 'Disable',
          isKey: false,
          sortable: false,
          type: 'button',
          guards: this.mode === 'details',
        });
      }
      if (!!this.permissions?.updateUserPermissions) {
        res.push({
          col: 'editPermissionsButton',
          field: 'editPermissions',
          label: 'Edit Permissions',
          isKey: false,
          sortable: false,
          type: 'button',
            guards: this.mode === 'permissions',
        });
      }
      return res
    },
  tableColumns() {
      return this.allTableColumns.filter(f => f.guards === true)
   },

   sortedRecords() {
    const self = this;
     const res = [];
     for (const user of this.includedUsers) {
        res.push({
          displayName:user.displayName,
          emailAddress:user.emailAddress,
          accountName:user.accountName,
          intermediaryName:user.intermediaryName,
          teamName:user.teamName,
          statusSummary:user.statusSummary,
          createdAt:(new Date(user.createdAt)).toLocaleString('en-ZA',{ timeZone: 'Africa/Johannesburg' }).replaceAll(',', ''),
          lastLogin:user.lastLogin ? (new Date(user.lastLogin)).toLocaleString('en-ZA',{ timeZone: 'Africa/Johannesburg' }).replaceAll(',', '') : '',
          ...user.permissionsAsync,
          disableUser: {
            key: 'disableUser' + user.id,
            label: 'Disable',
            type: 'primary',
            modal: {
              header: `Disable User: ${user.userDisplayName}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Disable',
              content: `
      <ol>
        <li>The user will be disabled.</li>
        <li>They will be unable to log in or perform any actions.</li>
        <li>They will no longer receive any communication.</li>
        <li>They will be disabled as a signatory.</li>
        <li>A user does not need to be disabled to be moved.</li>
        <li>Disabling a user will not allow the same email address to be used elsewhere.</li>
      </ol>
        `,
            },
            action: 'disableUser',
            params: { userId: user.id },
            actionParams: { userId: user.id },

            guards: user.status === 'active',
          },
          editPermissions: {
            key: 'editPermissions' + user.id,
            label: 'Edit',
            type: '',
            guards: user.status === 'active' && user.permissionStatus !== 'requested' && user.notMyUser,
            onClick() {
              self.$router.push({ name: 'userPermissionsEdit', params: { userId: user.id } });
            },
          },
        })
     }
     return res
   },
   includedUsers() {
     const res = [];
     for (const userId in this.user) {
       if (this.includeInactive || (this.user[userId].status === 'active' && this.user[userId].visibility === 'visible')) {
        if (!this.accountId || this.accountId === this.user[userId].accountId) {
          if (
            (!this.showBankOnly && !this.showMyTeamOnly) || // If the settings are off, show it
            (this.showBankOnly && ( this.user[userId].accountId === 1 || !!this.account[this.user[userId].accountId]?.bankDirectClients)) || // if we are only showing bank users, check if it is a bank user or the intermediary is a bank direct client
            (this.showMyTeamOnly && this.user[userId].userTeamId === this.user[this.authUser?.id]?.userTeamId)
          ) {
            res.push(this.user[userId])
          } 
        }
       }
     }
     return res
   },
   includedPermissions() {
     const res = [];
     for (const user of this.includedUsers) {
       for (const permissionId in user.permissionsAsync) {
         if (!res.includes(permissionId)) {
           res.push(permissionId)
         }
       }
     }
     return res;
   },
    dbPermissions() {
      let self = this;
      let res = {};
      for (let p in this.config.permission) {
        res[p] = this.config.permission[p];
      }
      return res;
    },
    allowUserReview() {
      return this.permissions.confirmUserReview && ['due','nonCompliant'].includes(this.account?.[this.authUser?.accountId]?.userReviewStatus)
    },
    downloadMethod() {
      if (this.includedUsers?.length > 100) {
        return 'backend'
      }
      return 'frontend'
    }
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    createAndServeFile() {
      const fileArray = [];
      for (const dataLine of this.sortedRecords) {
        const dataLineObject = {};
        for (const column of this.allTableColumns) {
          if (column.type !== 'button') {
            dataLineObject[column.label] = dataLine[column.field] || ""
          }
        }
        fileArray.push(dataLineObject)
      }
      const worksheet = createWorkSheet(fileArray);
      createWorkbookAndWriteToFile("UserAccessReport-" + (new Date).toISOString().substring(0,19) + ".xlsx",worksheet)
    }

  }
}

</script>

<style>

.lfx-user-access-report-view-table-container{
  position:relative;
  --table-container-height:calc(100% - 70px);
  min-height: var(--table-container-height);
  max-height: var(--table-container-height); 
}

</style>

