<template>
  <LDBItemsView
    v-if="numberOfRecords !== 0"
    tileView="default"
    :records="applicationDocuments"
    :record="applicationDocumentRecord"
    view="item"
    :action="undefined"
    :params="params"
    @on-save="onSaveDocument"
    @on-tile-click="onTileClick"
  />
  <div v-if="isWizard">
    <capitec-action-bar>
      <LDBButton
        v-for="button in leftButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
      <capitec-spacer />
      <LDBButton
        v-for="button in rightButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </capitec-action-bar>
  </div>

  <router-view></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import LDBItemsView from '@/landobyte_vue/LDBItemsView.vue';

export default {
  name: 'LfxClientApplicationDocuments',
  components: { LDBItemView, LDBItemsView },
  props: {},
  data() {
    return {};
  },
  created() {
    if (this.applicationDocumentRecord.activate) {
      this.applicationDocumentRecord.activate();
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    // ----- Component -----

    params() {},

    // ----- Account record -----

    clientId() {
      return +this.$route.params?.accountId;
    },
    clientRecord() {
      return this.account?.[this.clientId];
    },

    // ----- Application Record -----

    applicationId() {
      return +this.$route.params?.applicationId;
    },
    applicationRecord() {
      return this.clientRecord?.application?.[this.applicationId];
    },

    // ----- Application Documents -----

    showErrorsAndCancelled() {
      return !!this.$route.meta?.content?.showErrorsAndCancelled;
    },
    applicationDocuments() {
      if (this.showErrorsAndCancelled) {
        return this.applicationRecord.localDocuments || {};
      }
      const documents = {};
      for (const id in this.applicationRecord.localDocuments || {}) {
        if (this.applicationRecord.localDocuments[id].status === 'uploaded') {
          documents[id] = this.applicationRecord.localDocuments[id];
        }
      }
      return documents;
    },
    numberOfRecords() {
      if (!this.applicationDocuments) {
        return 0
      }
      return Object.keys(this.applicationDocuments).length
    },
    applicationDocumentId() {
      return +this.$route.params?.applicationDocumentId;
    },
    applicationDocumentRecord() {
      return this.applicationDocuments?.[this.applicationDocumentId] || {};
    },

    // ----- View -----

    view() {
      return this.$route.meta?.content?.view ? this.$route.meta.content.view : 'item';
    },

    // ----- Buttons -----

    isWizard() {
      return this.$route.meta?.wizard;
    },

    leftButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'back',
          label: 'Back',
          type: 'clear',
          action: 'click',
          clickEvent() {
            self.$emit('on-back');
          },
        },
      ];
      return allButtons;
    },

    // TODO Add a Back button

    rightButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'next',
          label: 'Next',
          action: 'click',
          type: 'primary',
          guards: true,
          enabledGuards: this.applicationRecord?.outstandingDocumentTypes?.length == 0,
          clickEvent() {
            self.$emit('on-next');
          },
        },
      ];
      return allButtons !== undefined ? allButtons : [];
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),

    onTileClick(record) {
      this.$router.replace({
        name: this.$route.name,
        params: { accountId: this.clientId, applicationId: this.applicationId, applicationDocumentId: record.id },
      });
    },
    onSaveDocument() {
      this.$router.go(-1);
    },
  },
};
</script>
