<template>
  <div class="list-group-item" :class="{ 'not-draggable': false}" >
    <div class="lfx-signatory-tile" :class="mandatoryClass">
      <div class="grabber">
        <div class="dot" />
        <div class="dot" />
        <div class="dot" />
      </div>
      <div />
      <div class="index-spinner">
        <div class="up arrow" :class="arrowPosition" @click="arrowUpClick">
          <capitec-icon icon="system/chevron-up" size="xx-small" />
        </div>
        <div class="index">{{ position + 1 }}</div>

        <div class="down arrow" :class="arrowPosition" @click="arrowDownClick">
          <capitec-icon icon="system/chevron-down" size="xx-small" />
        </div>
      </div>
      
      <div class="avatar">
        
        <LfxAvatar :record="record" :fields="['fullName']" />
        <!-- <capitec-avatar :value="avatarText" size="xx-large" :color="avatarColor" /> -->
      </div>

      <div />

      <div class="title">
        <capitec-label type="subtitle" :label="`${nameFields.map((f) => record[f]).join(' ')}`" />
      </div>

      <div class="descriptor">
        <capitec-label type="subtitle" :label="`${descriptionText}`" />
      </div>
      
      
      <div class="subtitle">
        <capitec-label  :label="`${record[fields.subtitle]}`" />
      </div>
      
      <div />

      <div class="info">
        <capitec-label :label="`${record[fields.info]}`" />
      </div>
      
      <div />


      <div class="toggle" v-if="showCheckbox">
        <!-- <capitec-toggle
          :checked="record.checked || !checkEnabled"
          @value-change="checkedRecord"
          :disabled="!checkEnabled"
        /> -->
        <capitec-check
          :checked="record.checked || !checkEnabled"
          @value-change="checkedRecord"
          :disabled="!checkEnabled"
        />
      </div>
    </div>
  </div>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LfxAvatar from '@/components/system/LfxAvatar.vue';

export default {
  components: {LfxAvatar},
  props: {
    position: { type: Number, required: true },
    recordCount: { type: Number, required: true },
    record: { type: Object, required: true },
    fields: { type: Object, required: true },
    checkEnabled: { type: Boolean, default: true },
    showCheckbox: { type: Boolean, default: false },
    nameFields: {
      type: Array,
      default() {
        return ['name'];
      },
    },
  },
  emits: ['arrow-down-click', 'arrow-up-click', 'on-check'],
  data() {
    return {};
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    mandatoryClass() {
      return this.record.signerRequired || '';
    },
    avatarColor() {
      // let colors = ['#C83B37', '#08A6B5', '#5A9600', '#C83C37', '#D4781C', '#00486D', '#4E6066', '#009DE0', '#5D737E'];
      // let index = Number(this.record.id) % colors.length;
      // return colors[index];
      return this.record?.checked || !this.checkEnabled ? '#5A9600' : '#08A6B5';
    },
    avatarText() {
      let values = this.nameFields.map((f) => (this.record?.[f]?.[0] ? this.record?.[f]?.[0] : ''));
      return values.join(' ');
    },
    arrowPosition() {
      let classes = [];
      if (this.position === 0) {
        classes.push('first');
      }
      if (this.position === this.recordCount - 1) {
        classes.push('last');
      }
      return classes.join(' ');
    },
    descriptionText() {
      return this.record[this.fields.description]?.toUpperCase() || ""
    }
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    arrowDownClick() {
      this.$emit('arrow-down-click', this.position);
    },
    arrowUpClick() {
      this.$emit('arrow-up-click', this.position);
    },
    checkedRecord(evt) {
      // this.$emit('checked-record',this.)

      this.checked = evt.detail.new;
      this.record.checked = evt.detail.new;
      this.$emit('on-check', { record: this.record, evt: evt });
    },
  },
};
</script>
<style>

.lfx-signatory-tile {

  flex: 1;
  max-height: 48px;
  min-height: 48px;
  margin-left:10px;
  margin-right:10px;
  margin-top:10px;
  display: grid;
  grid-template-areas:
    'grabber spinner . avatar . title    . descriptor . toggle'
    'grabber spinner . avatar . subtitle . info . toggle';
  grid-template-columns: 10px 25px 5px 30px 5px 1fr 5px 1fr 15px 30px;
  grid-template-rows: 25px auto;
  align-items: stretch;
  justify-content: stretch;
  --signatory-color:var(--theme-type-primary-background);
  border: 1px solid var(--signatory-color);
  border-radius: 5px;
  background-color:white;
}
.lfx-signatory-tile.mandatory{
  --signatory-color:var(--theme-status-success-background);
}

.lfx-signatory-tile > .avatar {
  grid-area: avatar;
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1.3);
}

.lfx-signatory-tile > .title {
  grid-area: title;
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
}

.lfx-signatory-tile > .subtitle {
  grid-area: subtitle;
  display: flex;
  align-items: flex-start;
}

.lfx-signatory-tile > .descriptor {
  grid-area: descriptor;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.lfx-signatory-tile > .info {
  grid-area: info;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.lfx-signatory-tile > .toggle {
  grid-area: toggle;
  display: flex;
  align-items: center;
}

.mandatory{
  --signatory-color:var(--theme-status-success-background);
}

.lfx-signatory-tile > .grabber {
  flex: 1;
  border: 1px solid var(--signatory-color);
  background-color: var(--signatory-color);
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: grab;
  grid-area: grabber;
}

.lfx-signatory-tile > .grabber > .dot {
  background-color: white;
  position: relative;
  top:-1px;
  min-width: 4px;
  max-width: 4px;
  min-height: 4px;
  max-height: 4px;
  margin-top: 4px;
  border-radius: 2px;
  opacity: 0.5;
}

.lfx-signatory-tile > .index-spinner {
  flex: 1;
  /* border: 1px solid var(--signatory-color); */
  align-self: stretch;
  display: flex;
  flex-direction: column;
  grid-area: spinner;
}

.lfx-signatory-tile > .index-spinner > div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--signatory-color);
  color: white;
  font-weight: bold;
}

.index-spinner > .arrow {
  cursor: pointer;
}

.index-spinner > .arrow.up.first > capitec-icon {
  display: none;
}

.index-spinner > .arrow.down.last > capitec-icon {
  display: none;
}

.index-spinner > .arrow > capitec-icon {
  filter: brightness(100);
  transform: scale(0.5);
}
</style>


