//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxUserDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a User record for the LandoFX system
//  Created     : 03 August 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import { intermediaryFieldViewGuard } from '../sharedUtils/LdbDefinitionUtils';
import type { LdbAuthUser, LdbDefinition, LdbViewFunction } from '../definitions/LdbInterfaces';
import { isAlphaWhiteList, msisdnValidator, standardTextWhiteList } from '../sharedUtils/LdbValidations';
import { isBankUser } from './LfxAccountDef';

//----------------------------------------------------------------------------------------------------------------------

export type LfxUserStatus = 'new' | 'pending' | 'active' | 'disabled';
export type EsbUserStatus = 'na' | 'initialPassword' | 'passwordResetRequired' | 'active' | 'disabled' | 'error';
export type OdnUserStatus = 'na' | 'active' | 'disabled' | 'error' | 'initialPassword';
export type LfxUserPermissionStatus = 'na' | 'requested' | 'granted' | 'rejected';
export type mfaExclusionType = 'none' | 'otp' | 'simHold' | 'email';
export type LfxUserVisibility = 'visible' | 'hidden';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxUserInt {
  id?: number;
  uuid?: string;
  cognitoUserId?: string;
  accountId?: number;
  branchId?: number;
  intermediaryId?: number;
  signatoryId?: number;
  userTeamId?: string;
  reference?: string;

  firstName?: string;
  middleNames?: string;
  preferredName?: string;
  surname?: string;
  displayName?: string;
  username?: string;
  emailAddress?: string;
  msisdn?: string;
  idNumber?: string;

  status?: LfxUserStatus;
  permissionStatus?: LfxUserPermissionStatus;
  permissionsRequestedBy?: number;
  permissionsRequestedAt?: Date;
  permissionsReleasedBy?: number;
  permissionsReleasedAt?: Date;

  enabledBy?: number;
  enabledAt?: Date;
  disabledBy?: number;
  disabledAt?: Date;

  systemUser?: boolean;
  lastLogin?: Date;

  unsuccessfulLogins?: number;
  lastUnsuccessfulLogin?: Date;
  unsuccessfulPasswordResets?: number;
  lastUnsuccessfulPasswordReset?: Date;
  userLocked?: boolean;

  mfaEnabled?: boolean;
  mfaExclusion?: mfaExclusionType;
  mfaExclusionExpiry?: Date | null;

  esbUsername?: string;
  esbPasswordEncrypted?: string;
  esbSessionId?: string;
  esbLastLogin?: Date;
  esbPasswordResetDate?: Date;
  esbUserStatus?: EsbUserStatus;
  esbUserErrorText?: string;

  odnUsername?: string;
  odnPasswordEncrypted?: string;
  odnLastLogin?: Date;
  odnLastRefresh?: Date;
  odnUserStatus?: OdnUserStatus;
  odnUserErrorText?: string;

  visibility?: LfxUserVisibility;

  restrictedView?: boolean;

  lastEditedAt?: Date;

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

const addToStoreIfNotExist = (dbObject: LfxUserInt, authUser: LdbAuthUser) => {
  const requriedPermissions = ['manageUser'];
  var hasPermission = false;
  for (const permission of requriedPermissions) {
    if (authUser.permissions.includes(permission)) {
      hasPermission = true;
    }
  }
  if (hasPermission) {
    if (authUser.accountLevel !== 'bank') {
      return true;
    } else {
      return dbObject.accountId === authUser.accountId;
    }
  }
  return hasPermission;
};
//----------------------------------------------------------------------------------------------------------------------

export const isPermissionReviewRoute: LdbViewFunction = (viewFunctionObject) => {
  return viewFunctionObject.routeMetaData.permissionReviewRoute
};
export const isBankUserListView: LdbViewFunction = (viewFunctionObject) => {
  return !!viewFunctionObject.routeMetaData?.isBankUserListView 
};
//----------------------------------------------------------------------------------------------------------------------

export const LfxUserDef: LdbDefinition = {
  table: 'LfxUser',
  title: 'User',
  collectionPath: '/user',
  addToStoreIfNotExist: addToStoreIfNotExist,
  groups: {
    details: { priority: 1, label: 'Personal', color: 'success' },
    system: { priority: 2, label: 'System', color: 'info' },
    access: { priority: 3, label: 'Access', color: 'warning' },
    integration: { priority: 4, label: 'Integration', color: 'error' },
    other: { priority: 5, default: true, label: '', color: 'disabled' },
    changes: { priority: 6, label: 'Changes', color: 'disabled' },
  },
  apiUrl: (parent) => {
    return `/user`;
  },
  view: { create: false, edit: true, item: true, list: true, delete: false, csv: false },
  actions: {
    list: 'getUsers',
    item: 'getUser',
  },
  indexes: ['cognitoUserId', { unique: 'uuid' }, 'branchId'],
  pagingType: 'backEnd',
  fields: {
    id: {
      label: 'Name',
      datatype: { id: { descriptionField: 'displayName' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
    },
    uuid: {
      label: 'UUID',
      datatype: 'uuid',
      default: 'AUTOUUID',
      search: true,
      mandatory: true,
      views: { create: false, edit: false, item: isBankUser, list: false, delete: false, csv: false },
      group: 'system'
    },
    cognitoUserId: {
      label: 'Cognito User ID',
      datatype: 'text',
      default: null,
      search: true,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    accountId: {
      label: 'Account',
      datatype: {
        foreignKey: {
          linkTable: 'LfxAccount',
          linkField: 'id',
          collection: 'fk_accounts',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },
    branchId: {
      label: 'Branch',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediary_branches',

          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },
    intermediaryId: {
      label: 'Intermediary',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediaries',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: intermediaryFieldViewGuard,
        list: intermediaryFieldViewGuard,
        delete: false,
        csv: false,
      },
      group: 'system',
    },
    signatoryId: {
      label: 'Signatory',
      datatype: {
        foreignKey: {
          linkTable: 'LfxSignatory',
          linkField: 'id',
          collection: 'fk_signatories',
          displayField: 'reference',
          linkInSql: false,
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    userTeamId: {
      label: 'User Team',
      datatype: {
        foreignKey: {
          type: 'text',
          linkTable: 'LfxUserTeam',
          linkField: 'id',
          collection: 'fk_userTeam',
          displayField: 'description',
          linkInSql: false,
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: isBankUserListView, delete: false, csv: false },
      group: 'system',
    },
    reference: {
      label: 'Reference',
      datatype: 'text',
      default: null,
      search: true,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },
    firstName: {
      group: 'details',
      label: 'First Name',
      autocomplete:'name',
      datatype: 'text',
      default: null,
      search: true,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alpha charaters', args: [isAlphaWhiteList] },
        len: { msg: 'First Name should be between 1 and 50 characters', args: [1, 50] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false, search: true },
    },
    middleNames: {
      group: 'details',
      label: 'Middle Names',
      autocomplete:'additional-name',
      datatype: 'text',
      default: null,
      search: true,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alpha charaters', args: [isAlphaWhiteList] },
        len: { msg: 'Middle Name should be between 1 and 50 characters', args: [1, 50] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
    },
    preferredName: {
      group: 'details',
      label: 'Preferred Name',
      autocomplete:'name',
      datatype: 'text',
      default: null,
      search: true,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alpha charaters', args: [isAlphaWhiteList] },
        len: { msg: 'Preferred Name should be between 2 and 50 characters', args: [2, 50] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
    },
    surname: {
      autocomplete:'family-name',
      group: 'details',
      label: 'Surname',
      datatype: 'text',
      default: null,
      search: true,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alpha charaters', args: [isAlphaWhiteList] },
        len: { msg: 'Surname should be between 2 and 50 characters', args: [2, 50] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false, search: true },
    },

    displayName: {
      group: 'details',
      label: 'Display Name',
      datatype: 'text',
      search: true,
      property: { source: 'backend', read: true, write: false, sort: 'firstName' },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },

    username: {
      label: 'Username',
      datatype: 'text',

      default: null,
      search: true,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Username should be between 5 and 50 characters', args: [5, 50] },
      },
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'details',
    },
    emailAddress: {
      label: 'Email Address',
      autocomplete:'email',
      datatype: 'text',
      default: null,
      validators: { isEmail: { msg: 'Email is invalid' } },
      search: true,
      mandatory: true,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: false, search: true },
      group: 'details',
    },
    msisdn: {
      label: 'Mobile Number',
      datatype: 'text',
      autocomplete:'mobile',
      default: null,
      validators: { msisdnValidator },
      mandatory: true,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: false, search: true },
      group: 'details',
    },
    idNumber: {
      label: 'ID Number or Passport',
      datatype: 'text',
      default: null,
      search: true,
      mandatory: true,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'ID Number or Passport should be between 5 and 50 characters', args: [5, 50] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false, search: true },
      group: 'details',
    },
    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'new', name: 'New', tagType: 'info' },
            { id: 'pending', name: 'Pending', tagType: 'warning' },
            { id: 'active', name: 'Active', tagType: 'success' },
            { id: 'disabled', name: 'Disabled', tagType: 'cancelled' },
          ],
        },
      },
      default: 'new',
      search: true,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },
    permissionStatus: {
      label: 'Permission Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'na', name: 'None', tagType: 'info' },
            { id: 'requested', name: 'Requested', tagType: 'warning' },
            { id: 'granted', name: 'Granted', tagType: 'success' },
            { id: 'rejected', name: 'Rejected', tagType: 'error' },
          ],
        },
      },
      default: 'na',
      search: true,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },

    permissionsRequestedBy: {
      group: 'integration',
      label: 'Permissions Requested By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          collection: 'fk_users',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues:true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    permissionsRequestedAt: {
      group: 'integration',
      label: 'Permissions Requested At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: isPermissionReviewRoute, delete: false, csv: false },
    },
    permissionsReleasedBy: {
      group: 'integration',
      label: 'Permissions Released By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          collection: 'fk_users',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues:true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    permissionsReleasedAt: {
      group: 'integration',
      label: 'Permissions Released At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    enabledBy: {
      group: 'access',
      label: 'User Enabled By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          collection: 'fk_users',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues:true,
      guards: {status:{eq:'disabled'}},
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    enabledAt: {
      group: 'access',
      label: 'User Enabled At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      guards: {status:{eq:'disabled'}},
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    disabledBy: {
      group: 'access',
      label: 'User Disabled By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          collection: 'fk_users',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues:true,
      guards: {status:{eq:'disabled'}},
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    disabledAt: {
      group: 'access',
      label: 'User Disabled At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      guards: {status:{eq:'disabled'}},
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    systemUser: {
      label: 'Is System User',
      datatype: 'boolean',
      default: false,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: true, csv: true },
      group: 'system',
    },

    lastLogin: {
      label: 'Last Login',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      showIfNull: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'access',
    },

    unsuccessfulLogins: {
      label: 'Unsuccessful Logins',
      datatype: 'integer',
      default: false,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: true, csv: true },
      group: 'access',
    },
    lastUnsuccessfulLogin: {
      label: 'Last Unsuccessful Login',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'access',
    },
    unsuccessfulPasswordResets: {
      label: 'Unsuccessful Password Resets',
      datatype: 'integer',
      default: false,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: true, csv: true },
      group: 'access',
    },
    lastUnsuccessfulPasswordReset: {
      label: 'Last Unsuccessful Password Reset',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'access',
    },
    userLocked: {
      label: 'User Locked',
      datatype: 'boolean',
      displayType: {
        displayAsTag: true,
        tagTypeFunctionName: 'userLockedTagType',
      },
      default: false,
      mandatory: true,
      property: { source: 'backend', read: true, write: false, sort: 'none' },
      views: { create: false, edit: false, item: true, list: false, delete: true, csv: true },
      group: 'access',
    },

    mfaEnabled: {
      label: 'MFA Enabled',
      datatype: 'boolean',
      default: false,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: true, csv: true },
      group: 'access',
    },

    mfaExclusion: {
      label: 'MFA Exclusion',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'none', name: 'None' },
            { id: 'otp', name: 'SMS OTP' },
            { id: 'simHold', name: 'SIM Hold Exclusion' },
            { id: 'disabled', name: 'Disabled' },
            { id: 'email', name: 'Email OTP'}
          ],
        },
      },
      default: 'none',
      search: true,
      mandatory: true,
      write: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'access',
    },
    mfaExclusionExpiry: {
      label: 'MFA Exclusion Expiry',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
          input: 'calendar',
          // Note: below applies to only to 'otp' and 'email' as 'simHold' is hardcoded to 3 days and 'disabled' doesn't ask for expiry.
          minDate: { type: 'day', offset: 1 },
          maxDate: { type: 'month', offset: 6 },
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      validators: {
        isDate: { msg: 'Please Enter a Valid Date', args: true },
        isAfter: { msg: 'Expiry Date must be in the future', args: [undefined] },
      },
      group: 'access',
    },

    esbUsername: {
      label: 'ESB Username',
      datatype: 'text',
      default: null,
      search: true,
      mandatory: false,
      allowNullValues: true,
      validators: { isEmail: { msg: 'Email is invalid' } },
      write: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false, search: true },
      group: 'integration',
    },
    esbPasswordEncrypted: {
      label: 'ESB Password Encrypted',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'integration',
    },
    esbSessionId: {
      label: 'ESB Session ID',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'integration',
    },
    esbLastLogin: {
      label: 'ESB Last Login',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    esbPasswordResetDate: {
      label: 'ESB Password Reset',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    esbUserStatus: {
      label: 'ESB Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'na', name: 'Not Registered', tagType: undefined },
            { id: 'initialPassword', name: 'Initial Password', tagType: 'info' },
            { id: 'passwordResetRequired', name: 'Password Reset Required', tagType: 'warning' },
            { id: 'active', name: 'Active', tagType: 'success' },
            { id: 'disabled', name: 'Disabled', tagType: undefined },
            { id: 'error', name: 'Error', tagType: 'error' },
          ],
        },
      },
      default: 'na',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    esbUserErrorText: {
      label: 'ESB Error',
      datatype: 'memo',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'integration',
    },

    odnUsername: {
      label: 'Odin Username',
      datatype: 'text',
      default: null,
      search: true,
      mandatory: false,
      allowNullValues: true,
      validators: { isEmail: { msg: 'Email is invalid' } },
      write: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false, search: true },
      group: 'integration',
    },
    odnPasswordEncrypted: {
      label: 'Odin Password Encrypted',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'integration',
    },
    odnLastLogin: {
      label: 'Odin Last Login',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    odnLastRefresh: {
      label: 'Odin Last Refresh',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    odnUserStatus: {
      label: 'Odin Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'na', name: 'Not Registered', tagType: undefined },
            { id: 'active', name: 'Active', tagType: 'success' },
            { id: 'disabled', name: 'Disabled', tagType: undefined },
            { id: 'error', name: 'Error', tagType: 'error' },
            { id: 'initialPassword', name: 'Initial Password', tagType: 'info' },
          ],
        },
      },
      default: 'na',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    odnUserErrorText: {
      label: 'Odin Login Error',
      datatype: 'memo',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'integration',
    },


    visibility: {
      label: 'Visibility',
      search: false,
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'visible', name: 'Visible', tagType: 'success' },
            { id: 'hidden', name: 'Hidden', tagType: 'cancelled' },
          ],
        },
      },
      default: 'visible',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },
    restrictedView: {
      label: 'Restricted View',
      datatype: 'text',
      search: true,
      property: { source: 'backend', read: true, write: false, sort: 'none' },
      default: false,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },

    lastEditedAt: {
      label: 'Last Edited At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'changes',
    },

    createdAt: {
      group: 'changes',
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },

    createdBy: {
      group: 'changes',
      label: 'Created By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          collection: 'fk_users',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },

    createdByName: {
      group: 'changes',
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    updatedAt: {
      label: 'Updated At',
      group: 'changes',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    updatedBy: {
      label: 'Updated By',
      group: 'changes',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    updatedByName: {
      group: 'changes',
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
  },
};
