<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <ol>
        <li>
          User's email address: <strong>{{ userEmailAddress }}</strong>
        </li>
        <li>The Welcome Email will be resent to the user with their new login details.</li>
        <li>The login details sent with the previous email will no longer be valid.</li>
      </ol>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in buttons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

export default {
  name: 'LfxResendWelcomeEmail',
  components: {},
  props: {},
  emits: {},
  created() {},
  mounted() {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['user']),
    userId() {
      return this.$route.params.userId;
    },
    record() {
      return this.user?.[this.userId];
    },
    userEmailAddress() {
      return this.record?.emailAddress;
    },
    header() {
      return `Resend the Welcome Email to User: ${this.user[this.userId].userDisplayName}`;
    },
    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : '/';
      //TODO: go to user's home as fallback
    },
    buttons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelResendWelcomeEmail',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.replace({ name: self.cancelRoute });
          },
        },
        {
          name: 'resendWelcomeEmail',
          label: 'Resend Welcome Email',
          type: 'primary',
          guards: self.record.resendWelcomeEmail_buttonGuard,
          clickEvent() {
            self.do_resendWelcomeEmail();
          },
        },
      ];
      return allButtons;
    },
  },
  methods: {
    async do_resendWelcomeEmail() {
      let response = await this.record.resendWelcomeEmail();
      if (response.status === 'success') {
        this.$router.replace({ name: 'userDetailsView' });
      }
    },
  },
};
</script>
