//@ts-nocheck

import { LdbUserPermissionInt, LdbUserPermissionDef } from '@/definitions/LdbUserPermissionDef';

import { LfxModel, LfxDefinition } from './LfxModel';

class LdbUserPermissionMdl extends LfxDefinition {
  constructor(def, dbclass, context, name,config) {
    super(def, dbclass, context, name,config);
  }
}

export class LdbUserPermission extends LfxModel {
  constructor(data, store) {
    super({definition_name:'user_permission',...data}, store);
  }
}

export function Definition(context, name: string) {
  return new LdbUserPermissionMdl(LdbUserPermissionDef, LdbUserPermission, context, name,{});
}
