<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <div v-if="mounted">
        <p>Select Signatories to receive re-sent email</p>
        <LDBField
          v-for="(field, fieldname) in fields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="undefined"
          :record="{}"
          @change="on_change"
        />
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
        @on-api-success="onApiSuccess"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxApplicationHide',
  components: { LDBField },
  emits: ['on-api-success'],
  props: {},
  data() {
    return {
      mounted: true,
      includedSignatories:{}
    };
  },
  created() {
  },
  mounted() {
  },
  beforeUnmount() {
  },
  computed: {
    ...mapGetters(['isTestingSdlc']),
    ...mapState(['account']),
    header() {
      return `Resend Application Portfolio Emails`;
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'manualResendApplicationPortfolioEmails',
          label: this.selectedSignatories.length < 2 ? 'Manual Email' : 'Manual Email - One Email Only',
          action: 'url',
          actionParams: {url:this.getManualEmailText()},
          type: '',
          guards: this.isTestingSdlc,
          enabledGuards: this.selectedSignatories.length === 1,
        },

        {
          name: 'resendApplicationPortfolioEmails',
          label: 'Resend Emails',
          type: 'primary',
          action: 'resendApplicationPortfolioEmails',
          params: { accountId: +this.$route.params.accountId, applicationId: +this.$route.params.applicationId },
          actionParams: {signatoriesToResend:this.selectedSignatories},
          guards: true,
          enabledGuards: this.selectedSignatories.length > 0,
          clickEvent() {
            self.$router.replace({ name: 'clientApplication' });
          },
        }
      ];
      return allButtons !== undefined ? allButtons : [];
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : '/';
    },

    // ----- Fields -----

    selectedSignatories() {
      const res = [];
      for (const signatoryId in this.includedSignatories) {
        if (this.includedSignatories[signatoryId]) {
          res.push(+signatoryId)
        }
      }
      return res
    },
    fields() {
      const res = []
      for (const signatoryId in this.signatories) {
        res.push(
          {
          name: signatoryId.toString(),
          label: this.signatories[signatoryId].fullName + " - " + this.signatories[signatoryId].emailAddress,
          datatype: 'boolean',
          default: false,
          mandatory: false,
        }
        )
      }
      return res
      ;
    },


    // ----- Account Record -----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },

    intermediaryRecord() {
      return this.account[this.accountRecord.intermediaryId]
    },  // ----- Application Record -----

    applicationId() {
      return +this.$route.params?.applicationId;
    },
    applicationRecord() {
      return this.clientRecord?.application?.[this.applicationId];
    },

    signatories() {
      return this.accountRecord?.applicationPortfolioSignatories
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Fields -----

    on_change({ field, value }) {
      this.includedSignatories[field] = value;
    },

    // ----- API calls -----
    async onApiSuccess(_responseObject) {},


    getManualEmailText() {
      const email = 'test';
      const fullName = 'test';
      const url = "https://capitec.transaction.signiflow.com/"; // FIXME: Get from Back End
      const intermediaryName = this.intermediaryRecord?.name;
      const portfolioNumber = this.applicationRecord?.portfolioIntegrationId;
      return `mailto:${email}?subject=Application%20Portfolio%20shared%20with%20you&Body=Dear%20${fullName}%0d%0a%0d%0aWe%20would%20like%20to%20inform%20you%20that%20there%20is%20a%20new%20document%20in%20SigniFlow%20that%20requires%20your%20signature.%0d%0a%0d%0a%0d%0a${intermediaryName}%20has%20uploaded%20a%20Payment%20declaration%20for%20your%20signature%20(or%20approval).%0d%0a%0d%0aOnce%20you%20have%20signed%20the%20document%20and%20uploaded%20the%20required%20documentation%20all%20parties%20will%20be%20automatically%20notified.%0d%0a%0d%0aPlease%20use%20the%20below%20URL%20and%20Portfolio%20Number%20to%20open%20the%20document.%0d%0a%0d%0a${url}%0d%0aPortfolio%20Number:${portfolioNumber}%0d%0a%0d%0aRegards,%0d%0a%0d%0a${intermediaryName}`
    }

  },
};
</script>

<style></style>
