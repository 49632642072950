<template>
  <LDBModal result="info" :header="header" size="x-large">
    <template #body>
      <div v-if="mounted">
        <template v-if="!showDrawdownOption">
          <p><b>Note: This deal can no longer be drawn down.<br/>The value date may be in the past or it may be past Same Day cut off time on the Value Date.</b></p>
        </template>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          :view="getViewForField(field.name)"
          :fieldname="field.name"
          :definition="definition"
          :record="record"
          @change="on_change"
        />
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
        @on-api-success="onApiSuccess"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import lodash from 'lodash';
const merge = lodash.merge;

import LDBField from '@/landobyte_vue/LDBField.vue';
import { getCurrentForwardContractType } from '@/sharedUtils/LfxTradeCalc';

export default {
  name: 'LfxFecModification',
  components: { LDBField },
  emits: ['on-api-success'],
  props: {},
  data() {
    return {
      mounted: true,
    };
  },
  created() {
    this.record.deliveryType = null;
    this.record.forwardContractType = null;
    this.record.forwardModificationType = null;
    this.record.setMarkup = 0;
  },
  mounted() {},
  beforeUnmount() {
    this.record.revert();
  },
  computed: {
    ...mapGetters(['publicHolidayForDate', 'isBeforeSameDayCutOffTimes']),
    ...mapState(['deal', 'transaction', 'authUser', 'config', 'dbIntermediarySettings']),
    header() {
      switch (this.record.forwardModificationType) {
        case 'drawdown':
          return this.currenctContractType === 'fixed' ? 'Request Early Drawdown' : 'Request Drawdown';
        case 'partialDrawdown':
          return 'Request Partial Drawdown';
        case 'balanceCancelation':
          return 'Request Balance Cancelation';
        case 'extension':
          return 'Request Extension';
        default:
          return 'Modify FEC';
      }
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

        {
          name: 'fecModificationButton',
          label: this.actionButtonLabel,
          type: 'primary',
          action: this.action,
          params: {
            transactionId: this.record.transactionFileId,
            dealId: this.record.id,
          },
          actionParams: this.record,
          guards: this.action !== undefined,
          clickEvent(response) {
            // self.$router.replace({ name: 'transactionDealDetailsView' });
            if (response.status === 'success') {
              self.$router.go(-1);
            }
          },
        },
      ];
      return allButtons !== undefined ? allButtons : [];
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : '/';
    },

    // ----- Definition -----

    definition() {
      return getDefinition('deal');
    },

    // ----- Record -----
    recordName() {
      return 'deal';
    },
    recordId() {
      return +this.$route.params.dealId;
    },
    record() {
      let rec = this.$store.state?.deal[this.recordId];
      return rec;
    },
    currenctContractType() {
      return getCurrentForwardContractType(
        this.record.data.forwardContractType,
        this.record.data.optionStartDate,
        this.transactionFileRecord.isValueDateAfterSpotDate,
        this.record.valueDate
      );
    },
    transactionFileId() {
      return this.record.transactionFileId;
    },
    transactionFileRecord() {
      return this.$store.state?.transaction[this.transactionFileId];
    },
    transactionType() {
      return this.transactionFileRecord?.transactionType;
    },
    currencyPair() {
      return this.config[1].currencyPair[this.record?.currencyPair];
    },

    // ----- Fields -----

    action() {
      switch (this.record.forwardModificationType) {
        case 'drawdown':
          return 'fecRequestDrawdown';
        case 'partialDrawdown':
          return 'fecRequestPartialDrawdown';
        case 'balanceCancelation':
          return 'fecRequestBalanceCancelation';
        case 'extension':
          return 'fecRequestExtension';
        default:
          return undefined;
      }
    },
    actionButtonLabel() {
      switch (this.record.forwardModificationType) {
        case 'drawdown':
          return this.currenctContractType === 'fixed' ? 'Request Early Drawdown' : 'Request Drawdown';
        case 'partialDrawdown':
          return 'Request Partial Drawdown';
        case 'balanceCancelation':
          return 'Request Balance Cancelation';
        case 'extension':
          return 'Request Extension';
        default:
          return undefined;
      }
    },
    fields() {
      return this.definition.fields;
    },
    view() {
      return 'requestFecModification';
    },
    viewFieldNames() {
      let requiredFields = ['forwardModificationType'];
      let additionalFields;
      let markupField = undefined
      if (this.authUser.accountLevel !== 'client') {
        console.log("this.dbIntermediarySettings.intermediaryMayTakeMarkupsOnFecMod",this.dbIntermediarySettings.intermediaryMayTakeMarkupsOnFecMod)
        if (!!this.dbIntermediarySettings.intermediaryMayTakeMarkupsOnFecMod) {
          markupField = 'setMarkup'
        }
      } 
      
      switch (this.record.forwardModificationType) {
        case 'drawdown':
          additionalFields = ['deliveryType', 'valueDate'];
          if (this.currenctContractType === 'fixed') {
            additionalFields.push(markupField);
          }
          break;
        case 'partialDrawdown':
          additionalFields = ['amount', 'deliveryType', 'valueDate'];
          break;
        case 'balanceCancelation':
          additionalFields = [];
          break;
        case 'extension':
          additionalFields = ['forwardContractType', 'optionStartDate', 'valueDate', markupField];
          if (this.record.forwardContractType === 'partiallyOptional') {
            additionalFields = ['forwardContractType', 'optionStartDate', 'valueDate', markupField];
          } else {
            additionalFields = ['forwardContractType', 'valueDate', markupField];
          }
          break;
        default:
          additionalFields = [];
          break;
      }

      return [...requiredFields, ...additionalFields];
    },
    viewFields() {
      const res = [];
      for (const fieldName of this.viewFieldNames) {
        const fields = this.fields.filter((field) => field.field === fieldName);
        if (fields.length > 0) {
          const updatedField = this.overrideField(fields[0]);
          res.push(updatedField);
        }
      }
      return res;
    },
    showDrawdownOption() {
      return (this.allowSameDay || this.allowTom || this.allowSpot);
    },
    showPartialDrawdownOption() {
      return (this.allowSameDay || this.allowTom || this.allowSpot);      
    },
    todayDateISOString() {
      let now = new Date();
      let today = now.toISOString();
      let cleanToday = today.substring(0, 10);
      let cleanTodayDate = new Date(cleanToday);
      return cleanTodayDate.toISOString();
    },
    allowSameDay() {
      // return true
      return (
        this.currencyPair?.allowSameDay === true &&
        this.isBeforeSameDayCutOffTimes?.[this.transactionType] &&
        !this.publicHolidayForToday
      );
    },
    allowTom() {
      return this.currencyPair.tomDate && (new Date(this.currencyPair.tomDate)).setHours(0,0,0,0) <= (new Date(this.record.valueDate)).setHours(0,0,0,0)
    },
    allowSpot() {
      return (new Date(this.currencyPair.spotDate)).setHours(0,0,0,0) <= (new Date(this.record.valueDate)).setHours(0,0,0,0)
    },
    publicHolidayForToday() {
      let date = this.todayDateISOString.substring(0, 10);
      return this.currencyPair ? this.publicHolidayForDate(date, this.currencyPair?.id) : undefined;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    on_change({ field, value }) {
      if (field === 'deliveryType' && ['drawdown', 'partialDrawdown'].includes(this.record.forwardModificationType)) {
        let newValueDate;
        switch (value) {
          case 'Cash':
            newValueDate = this.todayDateISOString;
            break;
          case 'Tom':
            newValueDate = this.currencyPair.tomDate;
            break;
          case 'Spot':
            newValueDate = this.currencyPair.spotDate;
            break;
        }
        this.record.valueDate = newValueDate;
      }
      this.record[field] = value;
    },
    getViewForField(fieldName) {
      if (fieldName === 'valueDate' && ['drawdown', 'partialDrawdown'].includes(this.record.forwardModificationType)) {
        return 'item';
      }
      return 'edit';
    },
    overrideField(field) {
      if (field.name === 'forwardModificationType') {
        for (const option of field.datatype?.option?.options || []) {
          if (option.id === 'drawdown') {
            option.guards = this.showDrawdownOption;
          } else if (option.id === 'partialDrawdown') {
            option.guards = this.showPartialDrawdownOption;
          }
        }
      }
      if (['deliveryType', 'setMarkup'].includes(field.name)) {
        const startingField = merge({}, field);
        const overrideObject = this.getFieldOverRideObject(field);
        const newField = merge(startingField, overrideObject);
        newField.datatype = overrideObject.datatype;
        return newField;
      } else {
        return field;
      }
    },
    getFieldOverRideObject(field) {
      switch (field.name) {
        case 'deliveryType':
          return {
            datatype: {
              option: {
                optionType: 'text',
                options: [
                  { id: 'Cash', name: 'Value Today', tagType: 'info', guards: this.allowSameDay },
                  { id: 'Tom', name: 'Value Tomorrow', tagType: 'success', guards: !!this.currencyPair.tomDate && this.allowTom },
                  { id: 'Spot', name: 'Spot', guards: this.allowSpot},
                ],
              },
            },
          };
        case 'setMarkup':
          return {
            default: 0,
            label: 'Markup for New Leg',
            datatype: 'float',
          };

        default:
          return {};
      }
    },
    // ----- API calls -----
    async onApiSuccess(_responseObject) {},
  },
};
</script>

<style></style>
