<template>
  <LFXListView v-if="hasListLoaded" :definition="definition" :records="records" />
  <template v-else>
    <LDBLoader />
  </template>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXListView from '@/views/general/LFXListView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxClientPortfolioHistoryList',
  components: { LFXListView, LDBLoader },
  props:  { refreshCounter: { type: Number, required: false, default: 0}},
  data() {
    return {
      hasListLoaded: false,
    };
  },
  async created() {
    this.loadData()
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),
    // ----- Account Record -----

    clientId() {
      return +this.$route.params.accountId;
    },
    applicationId() {
      return +this.$route.params.applicationId;
    },
    records() {
      return this.account[this.clientId]?.application?.[this.applicationId]?.portfolio || {};
    },
    definition() {
      return getDefinition('client_portfolio');
    },
  },
  watch:{
    refreshCounter(newCount,oldCount) {
      if (newCount !== oldCount) {
        this.loadData();
      }
    }
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    async loadData() {
      this.hasListLoaded = false;
      await doPermissionAction(this, 'getClientPortfolioHistory', {
        accountId: this.clientId,
        applicationId: this.applicationId,
      });
      this.hasListLoaded = true;
    }
  },
};
</script>
