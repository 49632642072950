<template>
  <LfxAdvancedSearch
    :definition="definition"
    :dataAction="dataAction"
    :downloadAction="downloadAction"
    :baseSelectors="baseSelectors"
    @on-search-change="onSearchChange"

  />
  
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LfxAdvancedSearch from '@/views/search/LfxAdvancedSearch.vue';

export default {
  name: 'LfxPaymentSearch',
  emits:['on-search-change'],
  components: { LfxAdvancedSearch },
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),

    // ----- View -----

    definition() {
      return getDefinition('payment');
    },
    dataAction() {
      return 'getPayments'
    },
    downloadAction() {
      return 'downloadPayments'
    },
    baseSelectors() {
      return {}
    }

  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    onSearchChange(searchChangeData) {
      this.$emit('on-search-change',{downloadAction:this.downloadAction,searchCriteria:searchChangeData});
    }
  },
};
</script>
