<template>
  <LDBModal result="info" :header="header" size="x-large">
    <template #body>
      <div v-if="mounted">
        <LDBField
          v-for="(field, fieldname) in clientViewFields"
          :key="fieldname"
          :field="field"
          view="item"
          :fieldname="field.name"
          :definition="clientDefinition"
          :record="clientRecord"
          @change="on_change"
        />
        <br/>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="item"
          :fieldname="field.name"
          :definition="dealDefinition"
          :record="partialDealRecord"
          @change="on_change"
        />
        <br/>
        <LDBField
          v-for="(field, fieldname) in formFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="dealDefinition"
          :record="reviewRecord"
          @change="on_change"
        />
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
        @on-api-success="onApiSuccess"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import { isValidDealPrefixForFecModification } from '@/sharedUtils/LdbValidations';

import lodash from 'lodash';
const merge = lodash.merge;

import LDBField from '@/landobyte_vue/LDBField.vue';
import { getCurrentForwardContractType } from '@/sharedUtils/LfxTradeCalc';

export default {
  name: 'LfxFecModificationPartialDrawdownReview',
  components: { LDBField },
  emits: ['on-api-success'],
  props: {},
  data() {
    return {
      mounted: true,
      actionMode: 'release',
      reviewRecord: {
        dealNumber: undefined, // Used for capturing spot deal number. Kept as dealNumber to be consistent with other modification releases
        rejectionReason: undefined
      },
    };
  },
  created() {
  },
  
  mounted() {
  },
  beforeUnmount() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['deal','account','transaction']),
    header() {
      return 'Release Partial Drawdown Request '+ this.mainDealRecord.dealNumber
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'switchToRejectButton',
          label: 'Reject Modification',
          type: '',
          guards: this.actionMode === 'release',
          clickEvent() {
            self.actionMode = 'reject';
          },
        },
        {
          name: 'switchToReleaseButton',
          label: 'Release Modification',
          type: '',
          guards: this.actionMode === 'reject',
          clickEvent() {
            self.actionMode = 'release';
          },
        },

        {
          name: 'rejectButton',
          label: 'Reject Modification',
          type: 'primary',
          action: 'fecRejectModification',
          params: {
            transactionId: this.mainDealRecord.transactionFileId,
            dealId: this.mainDealRecord.id,
          },
          actionParams: this.reviewRecord,
          guards: this.actionMode === 'reject',
          clickEvent() {
            self.$router.replace({ name: 'transactionDealDetailsView' });
          },
        },

        {
          name: 'releaseButton',
          label: 'Release Modification',
          type: 'primary',
          action: 'fecReleaseModification',
          params: {
            transactionId: this.mainDealRecord.transactionFileId,
            dealId: this.mainDealRecord.id,
          },
          actionParams: this.reviewRecord,
          enabledGuards: !!this.reviewRecord.dealNumber,
          guards: this.actionMode === 'release',
          clickEvent() {
            self.$router.replace({ name: 'transactionDealDetailsView' });
          },
        },
      ];
      return allButtons !== undefined ? allButtons : [];
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : '/';
    },

    // ----- Definition -----

    dealDefinition() {
      return getDefinition('deal');
    },
    clientDefinition() {
      return getDefinition('client')
    },

    // ----- Record -----

    clientId() {
      return +this.$route.params.accountId;
    },
    clientRecord() {
      return this.account[this.clientId]
    },
    recordName() {
      return 'deal';
    },
    mainDealId() {
      return +this.$route.params.dealId;
    },
    mainDealRecord() {
      return this.$store.state?.deal[this.mainDealId];
    },
    transactionFileId() {
      return this.mainDealRecord.transactionFileId;
    },
    transactionFileRecord() {
      return this.$store.state?.transaction[this.transactionFileId];
    },
    currenctContractType() {
      return getCurrentForwardContractType(this.mainDealRecord?.data?.forwardContractType, this.mainDealRecord?.data?.optionStartDate, this.transactionFileRecord.isValueDateAfterSpotDate, this.partialDealRecord.valueDate);
    },
    subDealRecords() {
      const subDeals = [];
      for (const k in this.$store.state?.deal) {
        if (this.$store.state?.deal[k].supersedesDealId) {
        }
        if (
          this.$store.state?.deal[k].supersedesDealId === this.mainDealId &&
          this.$store.state?.deal[k].status !== 'rejected'
        ) {
          subDeals.push(this.$store.state?.deal[k]);
        }
      }
      return subDeals;
    },
    replacementDealRecord() {
      if (!this.subDealRecords) {
        return undefined;
      }
      for (const deal of this.subDealRecords) {
        if (deal.transactionFileId === this.mainDealRecord.transactionFileId) {
          return deal;
        }
      }
      return undefined;
    },
    partialDealRecord() {
      if (!this.subDealRecords) {
        return undefined;
      }
      for (const deal of this.subDealRecords) {
        if (deal.transactionFileId !== this.mainDealRecord.transactionFileId) {
          return deal;
        }
      }
      return undefined;
    },
    // ----- Fields -----


    clientFields() {
      return this.clientDefinition.fields
    },
    clientViewFieldNames() {
      return [
        'name',
        'cifNumber',
        this.clientRecord.bpNumber ? 'bpNumber' : undefined,
        'intermediaryId'
      ]
    },
    clientViewFields() {
      const res = [];
      for (const fieldName of this.clientViewFieldNames) {
        const fields = this.clientFields?.filter((field) => field.field === fieldName);
        if (fields.length > 0) {
          res.push(fields[0]);
        }
      }
      return res;
    },


    dealFields() {
      return this.dealDefinition.fields;
    },

    viewFieldNames() {
      return [
        'bankAction',
        'currencyPair',
        'currency',
        'amount',
        'counterCurrency',
        'counterAmount',
        'clientRate',
        this.partialDealRecord?.forwardContractType === 'partiallyOptional'? 'optionStartDate' : undefined,
        'valueDate',
        'deliveryType',
        this.partialDealRecord?.deliveryType === 'FWD'? 'forwardContractType' : undefined,
        'spotRate',
        'bench',
        'bankRate',
        'bankMarkup',
        'intermediaryMarkup',
        this.partialDealRecord?.deliveryType === 'FWD'? 'forwardPoints' :undefined,
      ]
    },
    viewFields() {
      const res = [];
      for (const fieldName of this.viewFieldNames) {
        const fields = this.dealFields.filter((field) => field.field === fieldName);
        if (fields.length > 0) {
          res.push(fields[0]);
        }
      }
      return res;
    },
    formFieldNames() {
      switch (this.actionMode) {
        case 'release':
          return ['dealNumber'];
        case 'reject':
          return ['rejectionReason'];
      }
    },
    formFields() {
      const res = [];
      for (const fieldName of this.formFieldNames) {
        const fields = this.dealFields.filter((field) => field.field === fieldName);
        if (fields.length > 0) {
          const updatedField = this.overrideField(fields[0]);
          res.push(updatedField);
        }
      }
      return res;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    on_change({ field, value }) {
      this.reviewRecord[field] = value;
    },
    overrideField(field) {
      if (['deliveryType', 'dealNumber'].includes(field.name)) {
        const startingField = merge({}, field);
        const overrideObject = this.getFieldOverRideObject(field);
        const newField = merge(startingField, overrideObject);
        return newField;
      } else {
        return field;
      }
    },
    getFieldOverRideObject(field) {
      switch (field.name) {
        case 'deliveryType':
          return {
            datatype: {
              option: {
                optionType: 'text',
                options: [
                  { id: 'Cash', name: 'Value Today', tagType: 'info' },
                  { id: 'Tom', name: 'Value Tomorrow', tagType: 'success' },
                  { id: 'Spot', name: 'Spot' },
                ],
              },
            },
          };
        case 'dealNumber':
          return {
            label: "Spot Deal - Deal Number",
            validators: {
              custom: {
                name: 'custom',
                args: {
                  vFunction: isValidDealPrefixForFecModification,
                  validatingInterface: 'frontend',
                },
                msg: 'Deal number should start with 3- or 171-',
              },
            },
          };
        default:
          return {};
      }
    },

    // ----- API calls -----
    async onApiSuccess(_responseObject) {},
  },
};
</script>

<style></style>
