<template>
  <LDBLoader v-if="beneficiaryRecords_retrievalStatus === 'retrieving'" />
  <LFXListView
    v-if="beneficiaryRecords_retrievalStatus !== 'failed'"
    :definition="beneficiaryDefinition"
    :records="beneficiaryRecords"
    :defaultSort="defaultSort"
  />
  <p v-else>Failed to retrieve Beneficiaries.</p>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXListView from '@/views/general/LFXListView.vue';

export default {
  name: 'LfxBeneficiariesList',
  components: { LFXListView },
  props: {},
  data() {
    return {};
  },
  created() {
    this.accountRecord?.activate('beneficiaries');
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    // ----- Account Record -----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId] || {};
    },

    beneficiaryRecords_retrievalStatus() {
      return this.accountRecord?.beneficiaries_retrievalStatus;
    },

    // ----- Beneficiary Records -----

    beneficiaryRecords() {
      return this.accountRecord?.beneficiaries;
    },

    beneficiaryDefinition() {
      return getDefinition('beneficiary');
    },

    defaultSort() {
      return { order: 'fullName', sort: 'asc' };
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
