<template>
    <table-lite
    id="lfx-deal-payments-view"
    :is-static-mode="true"
    :is-slot-mode="true"
    :has-checkbox="false"
    :is-loading="false"
    :is-re-search="true"      
    :columns="tableColumns"
    :rows="sortedRecords"
    :pageSize="100"
    :total="sortedRecords.length"
  >
    <template v-for="(col, i) of tableColumns" v-slot:[col.field]="data" :key="i">
      {{formatForCurrency(data.value[col.field])}}
    </template>
  </table-lite>
</template>


<script>

import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import TableLite from '@/views/general/TableLite.vue';
import { formatCurrencyString } from '@/sharedUtils/LdbSharedUtils';

export default {
  name: 'LfxTransactionDealPayments',
  components: { TableLite },
  
  async created() {
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['transaction']),

    // ----- Transaction Record -----

    transactionId() {
      return +this.$route.params?.transactionId;
    },
    transactionRecord() {
      return this.transaction?.[this.transactionId];
    },

    // ----- Records -----

    dealPayments() {
      return this.transaction?.[this.transactionId].dealPayment
    },
    filteredDealPayments() {
      const res = {}
       for (const dealPaymentId in this.dealPayments) {
        if (!['cancelled'].includes(this.dealPayments[dealPaymentId].status))
           {
          res[dealPaymentId] = this.dealPayments[dealPaymentId]
        }
       }
      return res
    },

    filteredDeals() {
      const res = {}
      for (const dealPaymentId in this.filteredDealPayments) {
        const dealId = this.dealPayments[dealPaymentId].dealId;
        if (!res[dealId]) {
          res[dealId] = this.transactionRecord.deals[dealId]
        }
      }
      return res
    },
    filteredPayments() {
      const res = {}
      for (const dealPaymentId in this.filteredDealPayments) {
        const paymentId = this.dealPayments[dealPaymentId].paymentId;
        if (! res[paymentId]) {
          res[paymentId] = this.transactionRecord.payments[paymentId]
        }
      }
      return res
    },
    // ----- TABLE CONSTRUCTORS -----

    tableColumns() {
      let self = this;
      let res = [
        {
          col: "paymentReferenceColumn",
          field: "paymentReference",
          label: "Payment",
          isKey: false,
          sortable: false,
        },
        {
          col: "paymentStatusColumn",
          field: "paymentStatus",
          label: "Payment Status",
          isKey: false,
          sortable: false,
        }
      ];
      for (const dealId in self.filteredDeals) {
        let column = {
          col: "dealId"+dealId,
          field: "dealId"+dealId,
          label: self.filteredDeals[dealId].reference,
          isKey: false,
          sortable: false,
        };
        res.push(column);
      }
      res.push({
          col: "totalColumn",
          field: "paymentTotal",
          label: "Total",
          isKey: false,
          sortable: false,
        })
      res.push({
          col: "paymentAmountColumn",
          field: "paymentAmount",
          label: "Payment Amount",
          isKey: false,
          sortable: false,
        })
      return res;
    },

    sortedRecords() {
      // TODO format amounts
      let self = this;
      let res = [];
      const dealStatusRow = {
        paymentReference:'Deal Status'
      };
      const dealTotalRow = {
        paymentReference:'Deal Total'
      };
      const dealRemainingRow = {
        paymentReference:'Deal Remaining'
      };
      // TODO get deal status text
      if (typeof this.filteredDeals === 'object' && Object.keys(this.filteredDeals).length > 0) { 
        for (const dealId in this.filteredDeals) {
          dealStatusRow["dealId"+dealId] = this.filteredDeals[dealId].statusText;
          dealTotalRow["dealId"+dealId] = 0;
          const dealPaymentAmount = this.filteredDeals[dealId].paymentAmount;
          dealRemainingRow["dealId"+dealId] = dealPaymentAmount;
        }
      }
      res.push(dealStatusRow)
      if (typeof this.filteredPayments === 'object' && Object.keys(this.filteredPayments).length > 0) {
        for (const paymentId in this.filteredPayments) {
          const row = {
            // TODO get payment status text
            paymentReference: this.filteredPayments[paymentId].reference,
            paymentStatus:this.filteredPayments[paymentId].statusText,
            paymentAmount:this.filteredPayments[paymentId].paymentAmount,
            paymentTotal:0,
          }
          if (typeof this.filteredDealPayments === 'object' && Object.keys(this.filteredDealPayments).length > 0) {
            for (const dealPaymentId in this.filteredDealPayments) {
              if (this.filteredDealPayments[dealPaymentId].paymentId === +paymentId) {
                const dealId = this.filteredDealPayments[dealPaymentId].dealId;
                const paymentAmount = this.filteredDealPayments[dealPaymentId].paymentAmount;
                row["dealId"+dealId] = paymentAmount
                row.paymentTotal += paymentAmount
                dealTotalRow["dealId"+dealId] += paymentAmount
                dealRemainingRow["dealId"+dealId] -= paymentAmount
              }
            }
          }
          res.push(row)
        }
      }
      res.push(dealTotalRow);
      res.push(dealRemainingRow)
      return res
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    formatForCurrency(value) {
      if (typeof value === 'number') {
        return formatCurrencyString(value)
      }
      else {
        return value
      }
    }
  },
};
</script>
