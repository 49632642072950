<template>
  <div class="lfx-widget-container" :style="spanStyle" :class="dynamicClass">
    <LfxWidgetHeader :header="header" :subheader="dateRange" :link="allRecordsLink" />
    <template v-if="dealCount > 0">
      <LfxWidgetRow
        v-for="deal in pagedItems"
        :key="deal.id"
        :title="`${deal.reference} ( #${deal.dealNumber} ) - ${dealClientName(deal)}`"
        :subtitle="dealDate(deal)"
        :info="`${deal.currency} ${formatCurrency(deal.originalAmount)}`"
        :link="dealLink(deal)"
        :avatar="dealClientName(deal)"
        :avatarColor="avatarColor(deal)"
      />

      <LfxWidgetScroller
        :totalItems="items.length"
        :itemsPerPage="itemsPerPage"
        :currentPage="pageNumber"
        @on-page="onPage"
      />
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LfxWidgetHeader from './LfxWidgetHeader.vue';
import LfxWidgetRow from './LfxWidgetRow.vue';
import LfxWidgetScroller from './LfxWidgetScroller.vue';
import { colorFromId } from '@/landobyte_vue/ldb_utils.ts';

import lfx_widget_mixin from './lfx_widget_mixin';

export default {
  mixins: [lfx_widget_mixin],
  components: {
    LfxWidgetHeader,
    LfxWidgetRow,
    LfxWidgetScroller,
  },
  props: {},
  data() {
    return {
      pageNumber: 1,
      itemsPerPage: 4,
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['deal', 'account', 'authUser']),
    allRecordsLink() {
      const routeName = '_readyForDrawdownFec';
      return { name: routeName };
    },
    header() {
      return `FEC Ready for Drawdown (${this.dealCount})`;
    },
    // mostRecentDeals() {
    //   let res = [];
    //   let startItem = (this.pageNumber - 1) * this.itemsPerPage;
    //   for (let d = 0; d < this.releasedDeals.length; d++) {
    //     let deal = this.releasedDeals[d];
    //     if (d >= startItem) {
    //       res.push(deal);
    //     }
    //     if (res.length === this.itemsPerPage) {
    //       break;
    //     }
    //   }
    //   return res;
    // },
    items() {
      return this.releasedDeals;
    },
    releasedDeals() {
      return Object.values(this.deal)
        .filter((deal) => {
          return deal.fecReadyForDrawdown;
        })
        .sort((a, b) => new Date(a.releasedTimestamp).getTime() - new Date(b.releasedTimestamp).getTime());
    },
    dealCount() {
      return this.releasedDeals.length;
    },
    minDate() {
      return this.dealCount === 0 || typeof this.releasedDeals[0]?.releasedTimestamp !== 'string'
        ? ''
        : this.releasedDeals[0]?.releasedTimestamp?.split('T')[0];
    },
    maxDate() {
      return this.dealCount === 0 ||
        typeof this.releasedDeals[this.releasedDeals.length - 1]?.releasedTimestamp !== 'string'
        ? ''
        : this.releasedDeals[this.releasedDeals.length - 1]?.releasedTimestamp?.split('T')[0];
    },
    dateRange() {
      if (this.minDate === this.maxDate) {
        return this.minDate;
      } else {
        return `${this.minDate} to ${this.maxDate}`;
      }
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    dealClientName(deal) {
      let clientId = deal.accountId;
      let clientName = this?.account?.[clientId]?.name || '-';
      return clientName;
    },
    avatarColor(deal) {
      return colorFromId(deal.accountId);
    },
    dealDate(deal) {
      return typeof deal.releasedTimestamp === 'string'
        ? `${deal.releasedTimestamp?.split('T').join(' ').split('.')[0]}`
        : '-';
    },
    dealLink(deal) {
      // return `/transaction/${deal.transactionFileId}/deal/${deal.id}`;
      return this.deal?.[deal?.id]?.link;
    },
  },
};
</script>

<style></style>
