//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxSignatoryDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a TODO record for LandoByte TS systems
//  Created     : 14 August 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbAuthUser, LdbDefinition, LdbViewFunction } from './LdbInterfaces';
import { msisdnValidator, standardTextWhiteList } from '../sharedUtils/LdbValidations';

//----------------------------------------------------------------------------------------------------------------------

export type LfxSignatoryType = 'unlinked' | 'user' | 'individual' | 'mainContact';
export type LfxSignatoryStatus = 'active' | 'disabled';
export type LfxSignatorySignerLevel = 'signer' | 'contributor' | 'reviewer' | 'contactOnly';
export type LfxSignatoryRequired = 'mandatory' | 'optional';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxSignatoryInt {
  id?: number;
  uuid?: string;
  accountId?: number;
  reference?: string;

  signatoryType?: LfxSignatoryType;
  userId?: number;

  isGroupMailBox?: boolean;

  firstName?: string;
  middleName?: string;
  surname?: string;
  idNumber?: string;
  capacity?: string;

  msisdn?: string;
  emailAddress?: string;

  accountOpeningSignatory?: boolean;
  paymentSignatory?: boolean;
  fecReviewSignatory?: boolean;

  isDirector?: boolean;
  isSigningDirector?: boolean;

  signerLevel?: LfxSignatorySignerLevel;
  signerRequired?: LfxSignatoryRequired;

  receivesDealRelease?: boolean;
  receivesPaymentConfirmations?: boolean;
  receivesPaymentRejections?: boolean;

  status?: LfxSignatoryStatus;

  restrictedView?: boolean;

  lastEditedAt?: Date;

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

export const signatoryDetailsAreEditable: LdbViewFunction = (viewFunctionObject) => {
  return (viewFunctionObject.record! as LfxSignatoryInt)?.signatoryType === 'unlinked';
};

export const showFecReviewSignatoryFields: LdbViewFunction = (viewFunctionObject) => {
  if (showSignatoryFields(viewFunctionObject)) {
    return viewFunctionObject.routeMetaData?.content?.fecEnabled === true
  }
  return false
};
export const showSignatoryFields: LdbViewFunction = (viewFunctionObject) => {
  const signerTypes = viewFunctionObject.routeMetaData?.content?.signatoryTypes || 'all';
  return signerTypes === 'signers';
};
export const showNotSignatoryFields: LdbViewFunction = (viewFunctionObject) => {
  return !showSignatoryFields(viewFunctionObject);
};
export const isNotGroupMailBox: LdbViewFunction = (viewFunctionObject) => {
  const isGroupMailBox = (viewFunctionObject.record as LfxSignatoryInt)?.isGroupMailBox;
  switch (isGroupMailBox) {
    case true:
      return false;
    case false:
      return true;
    default:
      return true;
  }
};
export const isNotMainContact: LdbViewFunction = (viewFunctionObject) => {
  return (viewFunctionObject.record! as LfxSignatoryInt)?.signatoryType !== 'mainContact';
};
export const signatoryDetailsAreEditableAndIsNotGroupMailBox: LdbViewFunction = (viewFunctionObject) => {
  return signatoryDetailsAreEditable(viewFunctionObject) && isNotGroupMailBox(viewFunctionObject);
};
//----------------------------------------------------------------------------------------------------------------------

const addToStoreIfNotExist = (_dbObject: LfxSignatoryInt, authUser: LdbAuthUser) => {
  return authUser.accountLevel !== 'bank';
};

//----------------------------------------------------------------------------------------------------------------------

export const LfxSignatoryDef: LdbDefinition = {
  title: 'Signatory',
  table: 'LfxSignatory',
  collectionPath: '/signatory',
  addToStoreIfNotExist: addToStoreIfNotExist,
  view: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
  groups: {
    system: { priority: 1, label: 'General', color: 'success' },
    details: { priority: 2, label: 'Details', color: 'info' },
    settings: { priority: 3, label: 'Settings', color: 'info' },
    communications: { priority: 4, label: 'Communications', color: 'info' },
    changes: { priority: 5, label: 'Changes', color: 'disabled' },
  },
  indexes: ['accountId'],
  pagingType: 'backEnd',
  fields: {
    id: {
      label: 'Name',
      datatype: { id: { descriptionField: 'fullName' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
      group: 'system',
    },
    uuid: {
      label: 'UUID',
      datatype: 'uuid',
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    accountId: {
      label: 'Account',
      datatype: {
        foreignKey: {
          collection: 'fk_accounts',

          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },
    reference: {
      label: 'Reference',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },
    signatoryType: {
      label: 'Signatory Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'unlinked', name: 'Normal', tagType: 'info' },
            { id: 'user', name: 'User', tagType: 'info' },
            { id: 'individual', name: 'Individual Client', tagType: 'info' },
            { id: 'mainContact', name: 'Main Contact', tagType: 'info' },
          ],
        },
      },
      default: 'unlinked',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      guards: { signerLevel: { notEq: 'contactOnly' } },
      group: 'system',
    },
    userId: {
      label: 'Linked User',
      datatype: {
        foreignKey: {
          collection: 'fk_users',

          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      guards: { signerLevel: { notEq: 'contactOnly' } },
      group: 'system',
    },
    isGroupMailBox: {
      label: 'Is a Group Mailbox',
      datatype: 'boolean',
      default: false,
      mandatory: false,
      views: { create: true, edit: isNotMainContact, item: true, list: showNotSignatoryFields },
      guards: { signerLevel: { eq: 'contactOnly' } },
      group: 'details',
    },
    firstName: {
      label: 'First Name',
      datatype: 'text',
      default: null,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alpha charaters', args: [standardTextWhiteList] },
        len: { msg: 'First Name should be between 1 and 50 characters', args: [1, 50] },
      },
      views: { create: true, edit: signatoryDetailsAreEditable, item: true, list: false, delete: false, csv: false },
      group: 'details',
    },
    middleName: {
      label: 'Middle Name',
      datatype: 'text',
      default: null,
      validators: {
        is: { msg: 'Please use only alpha charaters', args: [standardTextWhiteList] },
        len: { msg: 'First Name should be between 1 and 50 characters', args: [1, 50] },
      },
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: signatoryDetailsAreEditable, item: true, list: false, delete: false, csv: false },
      guards: { signerLevel: { notEq: 'contactOnly' }, accountOpeningSignatory: { eq: true } },
      group: 'details',
    },
    surname: {
      label: 'Surname',
      datatype: 'text',
      default: null,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alpha charaters', args: [standardTextWhiteList] },
        len: { msg: 'Surname should be between 1 and 50 characters', args: [1, 50] },
      },
      views: { create: true, edit: signatoryDetailsAreEditable, item: true, list: false, delete: false, csv: false },
      group: 'details',
    },
    fullName: {
      label: 'Name',
      datatype: 'text',
      default: '',
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      mandatory: false,
      property: { read: true, write: false, source: 'frontend', sort: 'surname' },
      group: 'details',
    },
    idNumber: {
      label: 'ID Number or Passport',
      datatype: 'text',
      default: null,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'ID Number or Passport should be between 1 and 50 characters', args: [1, 50] },
      },
      views: {
        create: isNotGroupMailBox,
        edit: signatoryDetailsAreEditableAndIsNotGroupMailBox,
        item: isNotGroupMailBox,
        list: true,
        delete: false,
        csv: false,
      },
      group: 'details',
    },
    capacity: {
      label: 'Capacity',
      datatype: 'text',
      default: null,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alpha charaters', args: [standardTextWhiteList] },
        len: { msg: 'Capacity should be between 1 and 50 characters', args: [1, 50] },
      },
      views: {
        create: isNotGroupMailBox,
        edit: signatoryDetailsAreEditableAndIsNotGroupMailBox,
        item: isNotGroupMailBox,
        list: true,
        delete: false,
        csv: false,
      },
      group: 'details',
    },
    msisdn: {
      label: 'Mobile Number',
      datatype: 'text',
      default: null,
      mandatory: true,
      validators: { msisdnValidator },
      views: {
        create: isNotGroupMailBox,
        edit: signatoryDetailsAreEditableAndIsNotGroupMailBox,
        item: isNotGroupMailBox,
        list: true,
        delete: false,
        csv: false,
      },
      group: 'details',
    },
    emailAddress: {
      label: 'Email Address',
      datatype: 'text',
      default: null,
      mandatory: true,
      validators: { isEmail: { msg: 'Email is invalid' } },
      views: { create: true, edit: signatoryDetailsAreEditable, item: true, list: true, delete: false, csv: false },
      group: 'details',
    },

    accountOpeningSignatory: {
      label: 'Account Opening Signatory?',
      datatype: 'boolean',
      default: null,
      mandatory: true,
      views: { create: true, edit: true, item: true, list: showSignatoryFields, delete: false, csv: false },
      guards: { signerLevel: { notEq: 'contactOnly' } },
      group: 'settings',
    },
    paymentSignatory: {
      label: 'Payment Signatory?',
      datatype: 'boolean',
      default: null,
      mandatory: true,
      views: { create: true, edit: true, item: true, list: showSignatoryFields, delete: false, csv: false },
      guards: { signerLevel: { notEq: 'contactOnly' } },
      group: 'settings',
    },
    fecReviewSignatory: {
      label: 'FEC Review Signatory?',
      datatype: 'boolean',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: showFecReviewSignatoryFields, delete: false, csv: false },
      guards: { signerLevel: { notEq: 'contactOnly' } },
      group: 'settings',
    },
    isDirector: {
      label: 'Is the Signatory a Director, Member, Trustee or Partner?',
      datatype: 'boolean',
      default: false,
      mandatory: false,
      views: { create: true, edit: true, item: true, list: false },
      guards: { signerLevel: { notEq: 'contactOnly' }, accountOpeningSignatory: { eq: true } },
      group: 'details',
    },
    isSigningDirector: {
      label: 'Will the director sign the application?',
      datatype: 'boolean',
      default: false,
      mandatory: false,
      views: { create: true, edit: true, item: true, list: false },
      guards: { signerLevel: { notEq: 'contactOnly' }, isDirector: { eq: true } },
      group: 'details',
    },
    signerLevel: {
      label: 'Signer Level',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'signer', name: 'Signer', tagType: 'info' },
            { id: 'contributor', name: 'Contributor', tagType: 'info', guards: {paymentSignatory:{eq:true}} },
            { id: 'reviewer', name: 'Reviewer', tagType: 'info', guards: false },
            { id: 'contactOnly', name: 'Contact Only', tagType: 'info', guards: false },
          ],
        },
      },
      default: null,
      mandatory: true,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      guards: { signerLevel: { notEq: 'contactOnly' } },
      group: 'settings',
    },
    signerRequired: {
      label: 'Signer Required',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'mandatory', name: 'Mandatory', tagType: 'info' },
            { id: 'optional', name: 'Optional', tagType: 'info', guards: {paymentSignatory:{eq:true}}},
          ],
        },
      },
      default: null,
      mandatory: true,
      views: { create: true, edit: true, item: true, list: showSignatoryFields, delete: false, csv: false },
      guards: { signerLevel: { notEq: 'contactOnly' } },
      group: 'settings',
    },
    receivesDealRelease: {
      label: 'Receives Deal Releases',
      datatype: 'boolean',
      default: null,
      mandatory: false,
      views: { create: true, edit: true, item: true, list: showNotSignatoryFields, delete: false, csv: false },
      group: 'communications',
    },
    receivesPaymentConfirmations: {
      label: 'Receives Payment Confirmations',
      datatype: 'boolean',
      default: null,
      mandatory: false,
      views: { create: true, edit: true, item: true, list: showNotSignatoryFields, delete: false, csv: false },
      group: 'communications',
    },
    receivesPaymentRejections: {
      label: 'Receives Payment Rejections',
      datatype: 'boolean',
      default: null,
      mandatory: false,
      views: { create: true, edit: true, item: true, list: showNotSignatoryFields, delete: false, csv: false },
      // views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'communications',
    },
    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'active', name: 'Active', tagType: 'success' },
            { id: 'disabled', name: 'Disabled', tagType: undefined },
          ],
        },
      },
      default: 'active',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'settings',
    },

    restrictedView: {
      label: 'Restricted View',
      datatype: 'text',
      search: true,
      property: { source: 'backend', read: true, write: false, sort: 'none' },
      default: false,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'settings',
    },

    lastEditedAt: {
      label: 'Last Edited At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'changes',
    },

    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
  },
};

//----------------------------------------------------------------------------------------------------------------------

export const requiredFields = (signatory: LfxSignatoryInt) => {
  const requiredFields: (keyof LfxSignatoryInt)[] = ['signerLevel', 'firstName', 'surname', 'emailAddress'];

  switch (signatory.signerLevel!) {
    case 'contactOnly':
      if (signatory.isGroupMailBox) {
        // add nothing
      } else {
        requiredFields.push('idNumber', 'capacity', 'msisdn'); // TODO is this actually required?
      }
      break;
    case 'signer':
    case 'contributor':
    case 'reviewer':
      requiredFields.push('idNumber', 'capacity', 'msisdn', 'signerRequired');
      break;
  }

  return requiredFields;
};

export const outstandingFields = (signatory: LfxSignatoryInt) => {
  const signatoryRequiredFields = requiredFields(signatory);
  const outstandingFields: (keyof LfxSignatoryInt)[] = [];
  for (const field of signatoryRequiredFields) {
    if (!signatory[field]) {
      outstandingFields.push(field);
    }
  }
  return outstandingFields;
};

export const signatoryHasSignatoryOptionCaptured = (signatory: LfxSignatoryInt): boolean => {
  switch (signatory.signerLevel!) {
    case 'contactOnly':
      return true;
    case 'signer':
    case 'contributor':
    case 'reviewer':
      if (signatory.accountOpeningSignatory || signatory.paymentSignatory) {
        return true;
      }
      return false;
    default:
      return false;
  }
};
