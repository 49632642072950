import {http_get} from './api_gateway_http';

// import { LfxUserInt } from '@/definitions/LfxUserDef';

type VuexContext = {
  dispatch:(method:string,paylooad:object) => any,
  getters:{authHeaders:object},
  state:{lfx_auth:object,authUser:{id:number},otp:{username:string,password:string,status:string,otpToken:string}}
}


export function getAuthHeaders(context:VuexContext) {
  return context.getters.authHeaders;
}

async function authGet(context:VuexContext,path:string) {
  let authHeaders = getAuthHeaders(context);
  let http_res = await http_get(path,authHeaders,{});
  return http_res;
}

// export async function  getUserPermissions(context:VuexContext) {
//   return await authGet(lfxUser,`/user/${lfxUser.uuid}/permissions`);
// }

// export async function  getUserSettings(lfxUser:LfxUserInt) {
//   return await authGet(lfxUser,`/user/${lfxUser.uuid}/settings`);
// }

// export async function  getClientSettings(lfxUser:LfxUserInt) {
//   return await authGet(lfxUser,`/account/${lfxUser.uuid}/clientSettings`);
// }

// export async function  getIntermediarySettings(lfxUser:LfxUserInt) {
//   return await authGet(lfxUser,`/account/${lfxUser.uuid}/intermediarySettings`);
// }

// export async function  getGeneralSettings(lfxUser:LfxUserInt) {
//   return await authGet(lfxUser,`/account/${lfxUser.uuid}/generalSettings`);
// }

export async function getSettingsAndPermissions(context:VuexContext) { 
  let res =  await authGet(context,`/user/${context.state.authUser.id}/settingsAndPermissions`);
  return res;
}
