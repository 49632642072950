import VueStore from 'vue-class-store';

import { LdbDbObject, LdbDefinition } from '@/definitions/LdbInterfaces';
import { LfxUserInt, LfxUserDef } from '@/definitions/LfxUserDef';
import { LfxAccountInt } from '@/definitions/LfxAccountDef';
import { LfxModel, LfxDefinition } from './LfxModel';

//@ts-ignore
import { http_patch, http_get, http_put } from '@/lfx_rest';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

@VueStore
//@ts-ignore
class LfxUserMdl extends LfxDefinition {
  //@ts-ignore
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
  async retrieveItem(id: number) {
    await doPermissionAction({}, 'getUser', { userId: id }, {});
  }
}

@VueStore
//@ts-ignore
export class LfxUser extends LfxModel {
  //@ts-ignore
  constructor(data, state) {
    super({ definition_name: 'user', ...data }, state);
    //@ts-ignore
  }
  get link() {
    // @ts-expect-error
    return `/user/${this.id}`;
  }

  // GENERATE BUTTON GUARDS - Used by multiple vues
  get editUserLogin_buttonGuard() {
    return (
      this.notMyUser &&
      this.hasPermission.editUserAuth &&
      this.userNotHidden &&
      this.userNotDisabled &&
      this.notSystemUser
    );
  }

  get editUserBankLogin_buttonGuard() {
    return (
      this.notMyUser &&
      (this.userAccountLevel === 'intermediary' ||
        this.userAccountLevel === 'intermediaryBranch' ||
        this.userAccountLevel === 'client' ||  //TODO: Only show for direct clients
        // @ts-expect-error
        (this.userAccountLevel === 'bank' && this.systemUser)
        ) &&
      this.hasPermission.updateBankLoginDetails &&
      this.userNotHidden &&
      this.userNotDisabled
    );
  }
  // ------------------------------
  // User info
  // ------------------------------
  get displayFKLink() {
    //@ts-expect-error
    return !this.restrictedView;
  }

  // ----- General -----

  get userDisplayName() {
    //@ts-ignore
    return this.preferredName ? `${this.preferredName} ${this.surname}` : `${this.firstName} ${this.surname}`;
  }
  get userAccountLevel() {
    // @ts-ignore
    return this.store?.state?.account?.[this.accountId]?.accountLevel;
  }

  get accountName() {
    //@ts-expect-error
    const account = this.store?.state?.account[this.accountId];
    if (account) {
      return account.name;
    }
    //@ts-expect-error
    return this.accountId;
  }
  get intermediaryName() {
    //@ts-expect-error
    const account = this.store?.state?.account[this.intermediaryId];
    if (account) {
      return account.name;
    }
    //@ts-expect-error
    return this.intermediaryId;
  }
  get teamName() {
    //@ts-expect-error
    const team = this.store?.state?.config[1].userTeam?.[this.userTeamId];
    if (team) {
      return team.description;
    }
    //@ts-expect-error
    return this.userTeamId;
  }
  get statusSummary() {
    //@ts-expect-error
    switch (this.status) {
      case 'new':
        return 'New';
      case 'pending':
        return 'Pending';
      case 'active':
        //@ts-expect-error
        if (this.userLocked) {
          return 'Active - Locked';
        }
        //@ts-expect-error
        if (this.permissionStatus === 'requested') {
          return 'Active - Permissions Requested';
        }
        return 'Active';
      case 'disabled':
        //@ts-expect-error
        if (this.visibility === 'hidden') {
          return 'Hidden';
        }
        return 'Disabled';
      default:
        //@ts-expect-error
        return this.status;
    }
  }

  get userLockedTagType() {
    // @ts-expect-error
    return this.userLocked ? 'error' : 'success';
  }

  // ----- MFA -----
  get currentMfaExclusion() {
    if (
      //@ts-expect-error
      !this.mfaEnabled ||
      //@ts-expect-error
      ['none', null].includes(this.mfaExclusion) ||
      //@ts-expect-error
      (this.mfaExclusionExpiry && new Date(this.mfaExclusionExpiry) < new Date())
    ) {
      return 'none';
    }
    //@ts-expect-error
    return this.mfaExclusion;
  }

  // ----- User permissions -----

  userPermissions_retrievalStatus: 'none' | 'retrieving' | 'retrieved' | 'failed' = 'none';
  userPermissions_retrievalTimestamp: null | Date = null;
  userPermissions_retrievalAttemptsFailed: number = 0;

  async retrieveUserPermissions() {
    this.userPermissions_retrievalStatus = 'retrieving';
    // @ts-expect-error
    let response = await doPermissionAction(this, 'getUserPermissions', { userId: this.id }, {});
    if (response!.status === 'success') {
      this.userPermissions_retrievalStatus = 'retrieved';
      this.userPermissions_retrievalTimestamp = new Date();
      this.userPermissions_retrievalAttemptsFailed = 0;
    } else {
      this.userPermissions_retrievalStatus = 'failed';
      this.userPermissions_retrievalTimestamp = new Date();
      this.userPermissions_retrievalAttemptsFailed++;
    }
  }

  get userPermissions() {
    switch (this.userPermissions_retrievalStatus) {
      case 'none':
        this.retrieveUserPermissions();
        return {};
      case 'failed':
        if (this.userPermissions_retrievalAttemptsFailed <= 2) {
          this.retrieveUserPermissions();
        }
        return {};
      default:
        // @ts-expect-error
        return this.permission || {};
    }
  }

  async getPermissions() {
    //@ts-ignore
    let response = await this.store.dispatch('db_get_collection', {
      //@ts-ignore
      collection_path: `/user/${this.id}/permission`,
      options: { definition_name: 'user_permission' },
    });
    return response;
  }

  get permissionsAsync() {
    //@ts-expect-error
    if (this.permission) {
      return this.permissions;
    }
    this.getPermissions();
    return {};
  }

  get permissions() {
    //@ts-ignore
    let permissions = this.permission ?? {};
    let res = {};
    for (let p in permissions) {
      let perm = permissions[p];
      //@ts-ignore
      res[perm.permissionId] = perm.status;
    }
    return res;
  }
  get pendingPermissions() {
    let permissions = this.permissions;
    let res = [];
    for (let p in permissions) {
      //@ts-ignore
      let perm = permissions[p];
      if (perm === 'pending') {
        //@ts-ignore
        res.push(p);
      }
    }
    return res;
  }

  get hasPendingPermissions() {
    return this.pendingPermissions.length > 0;
  }

  get pendingPermissionsActionable() {
    let pendingPermissions = this.pendingPermissions;
    //@ts-ignore
    let systemPermissions = this.store.getters.config.permission;
    //@ts-ignore
    let authUserPermissions = this.store.state.authUser.permissions;
    let res = pendingPermissions.filter((pp) =>
      //@ts-ignore
      systemPermissions[pp].reviewPermissions.some((rp) => authUserPermissions.includes(rp))
    );
    return res;
  }

  get hasActiveManageUserPermission() {
    let res = false;
    for (const p in this.userPermissions) {
      const permission = this.userPermissions[p];
      if (permission.permissionId === 'manageUser' && permission.status === 'active') {
        res = true;
        break;
      }
    }
    return res;
  }

  // ---- General -----

  get notMyUser() {
    // @ts-ignore
    return this.store.state.authUser.id !== this.id;
  }

  get userNotDisabled() {
    // @ts-ignore
    return this.status !== 'disabled';
  }
  get userNotHidden() {
    // @ts-ignore
    return this.visibility !== 'hidden';
  }
  get notSystemUser() {
    // @ts-ignore
    return !this.systemUser;
  }
  get canEditUserAuth() {
    //@ts-expect-error
    return this.notMyUser && this.userNotHidden && this.status === 'active' && this.notSystemUser;
  }

  // ---- Signatory -----
  get hasSignatoryInformation() {
     //@ts-expect-error
    return (this.firstName && this.surname && this.idNumber && this.msisdn && this.emailAddress) ? true : false
  }

  get hasSignatory() {
    // @ts-expect-error
    return this.signatoryId ? true : false;
  }

  userSignatory_retrievalStatus: 'none' | 'retrieving' | 'retrieved' | 'failed' = 'none';
  userSignatory_retrievalTimestamp: null | Date = null;
  userSignatory_retrievalAttemptsFailed: number = 0;

  get userSignatory() {
    switch (this.userSignatory_retrievalStatus) {
      case 'none':
        if (this.hasSignatory) {
          this.retrieveUserSignatory();
        }
        // return undefined;
        break;
      case 'retrieving':
        break;
      case 'retrieved':
        // TODO add reload logic if old
        break;
      case 'failed':
        if (this.userSignatory_retrievalAttemptsFailed < 3) {
          this.retrieveUserSignatory();
        }
        // return undefined;
        break;
      // default:
      //   return this.userSignatoryFromStore;
    }
    return this.userSignatoryFromStore;
  }

  get userSignatoryFromStore() {
    let userSignatory = {};

    // //@ts-expect-error
    // for (const s in this.store.state.signatory) {
    //   //@ts-expect-error
    //   if (this.store.state.signatory[s].userId === this.id) {
    //     //@ts-expect-error
    //     userSignatory = this.store.state.signatory[s]; // TODO what if more than one signatory for this user?
    //   }
    // }

    // @ts-expect-error
    if (this.hasSignatory && this.store.state?.signatory?.[this.signatoryId]?.userId === this.id) {
      // @ts-expect-error
      userSignatory = this.store.state.signatory[this.signatoryId];
    }
    return userSignatory;
  }

  get userSignatoryIsActive() {
    // return true;
    // @ts-expect-error
    return this.userSignatory?.status === 'active' ? true : false;
  }

  async retrieveUserSignatory() {
    this.userSignatory_retrievalStatus = 'retrieving';
    //@ts-expect-error
    let response = await doPermissionAction(this, 'getUserSignatory', { userId: this.id }, {});
    if (response!.status === 'success') {
      this.userSignatory_retrievalStatus = 'retrieved';
      this.userSignatory_retrievalTimestamp = new Date();
      this.userSignatory_retrievalAttemptsFailed = 0;
    } else {
      this.userSignatory_retrievalStatus = 'failed';
      this.userSignatory_retrievalTimestamp = new Date();
      this.userSignatory_retrievalAttemptsFailed++;
    }
  }
  // ------------------------------
  //  API calls
  // ------------------------------

  // ----- General -----

  on_after_activate() {
    this.getPermissions();
    this.retrieveUserPermissions();
    this.getAccessLogs();
  }

  // ----- User details -----

  async enableUser() {
    // @ts-ignore
    this.store.dispatch('showLoader', true);
    let payload = {};
    let options = { update_state: true, return_collection_path: `/user/` };
    // @ts-ignore
    let response = await this.store.dispatch('db_put_action', {
      //@ts-ignore
      path: `/user/${this.id}/enableUser`,
      payload,
      options,
    });
    //@ts-ignore
    this.store.dispatch('showLoader', false);
    return response;
  }

  async unhideUser() {
    // @ts-ignore
    this.store.dispatch('showLoader', true);
    let payload = {};
    // @ts-ignore
    let options = { update_state: true, return_collection_path: `/user/` };
    // @ts-ignore
    let response = await this.store.dispatch('db_put_action', {
      //@ts-ignore
      path: `/user/${this.id}/unhide`,
      payload,
      options,
    });
    //@ts-ignore
    this.store.dispatch('showLoader', false);
    return response;
  }

  async resendWelcomeEmail() {
    // @ts-ignore
    this.store.dispatch('showLoader', true);
    let payload = {};
    let options = {};
    // @ts-ignore
    let response = await this.store.dispatch('db_put_action', {
      //@ts-ignore
      path: `/user/${this.id}/resendCognitoEmail`,
      payload,
      options,
    });
    //@ts-ignore
    this.store.dispatch('showLoader', false);
    return response;
  }

  // @ts-ignore
  async editAuth(payload) {
    // @ts-ignore
    this.store.dispatch('showLoader', true);
    let options = { update_state: true, return_collection_path: `/user/` };
    // @ts-ignore
    let response = await this.store.dispatch('db_put_action', {
      //@ts-ignore
      path: `/user/${this.id}/authEdit`,
      payload,
      options,
    });
    //@ts-ignore
    this.store.dispatch('showLoader', false);
    return response;
  }

  // @ts-ignore
  async editBankLogin(payload) {
    // @ts-ignore
    this.store.dispatch('showLoader', true);
    let options = { update_state: true, return_collection_path: `/user/` };
    // @ts-ignore
    let response = await this.store.dispatch('db_put_action', {
      //@ts-ignore
      path: `/user/${this.id}/bankLoginDetails`,
      payload,
      options,
    });
    //@ts-ignore
    this.store.dispatch('showLoader', false);
    return response;
  }

  async bankLogin() {
    // @ts-ignore
    this.store.dispatch('showLoader', true);
    let payload = {};
    let options = { update_state: true, return_collection_path: `/user/` };
    // @ts-ignore
    let response = await this.store.dispatch('db_put_action', {
      //@ts-ignore
      path: `/user/${this.id}/bankLogin`,
      payload,
      options,
    });
    //@ts-ignore
    this.store.dispatch('showLoader', false);
    return response;
  }

  // @ts-ignore
  async moveUser(payload) {
    // @ts-ignore
    this.store.dispatch('showLoader', true);
    let options = { update_state: true, return_collection_path: `/user/` };
    // @ts-ignore
    let response = await this.store.dispatch('db_put_action', {
      //@ts-ignore
      path: `/user/${this.id}/moveUser`,
      payload,
      options,
    });
    //@ts-ignore
    this.store.dispatch('showLoader', false);
    return response;
  }

  async disableUser() {
    // @ts-ignore
    this.store.dispatch('showLoader', true);
    let payload = {};
    // @ts-ignore
    let options = { update_state: true, return_collection_path: `/user/` };
    // @ts-ignore
    let response = await this.store.dispatch('db_put_action', {
      //@ts-ignore
      path: `/user/${this.id}/disableUser`,
      payload,
      options,
    });
    //@ts-ignore
    this.store.dispatch('showLoader', false);
    return response;
  }

  async hideUser() {
    // @ts-ignore
    this.store.dispatch('showLoader', true);
    let payload = {};
    // @ts-ignore
    let options = { update_state: true, return_collection_path: `/user/` };
    // @ts-ignore
    let response = await this.store.dispatch('db_put_action', {
      //@ts-ignore
      path: `/user/${this.id}/hide`,
      payload,
      options,
    });
    //@ts-ignore
    this.store.dispatch('showLoader', false);
    return response;
  }

  // ----- User Permissions

  //@ts-ignore
  // async setPermissions(permissions, options) {
  async setPermissions(permissions) {
    //@ts-ignore
    this.store.dispatch('showLoader', true);
    //@ts-ignore
    let options = { update_state: true };
    //@ts-ignore
    let payload = { requiredPermissions: permissions };
    //@ts-ignore
    let response = await this.store.dispatch('db_put_action', { path: `/user/${this.id}/permission`, payload, options });
    //@ts-ignore
    this.store.dispatch('showLoader', false);
    return response;
  }

  // async recachePermissions() {
  //   //@ts-ignore
  //   let response = await http_patch(`/user/${this.id}/permissionRecache`, {});
  // }

  async rejectPermissions() {
    // @ts-ignore
    this.store.dispatch('showLoader', true);
    let payload = {};
    // @ts-ignore
    let options = { update_state: true, return_collection_path: `/user/` };
    // @ts-ignore
    let response = await this.store.dispatch('db_put_action', {
      //@ts-ignore
      path: `/user/${this.id}/permissionReject`,
      payload,
      options,
    });
    //@ts-ignore
    this.store.dispatch('showLoader', false);
    return response;
  }
  async grantPermissions() {
    // @ts-ignore
    this.store.dispatch('showLoader', true);
    let payload = {};
    // @ts-ignore
    let options = { update_state: true, return_collection_path: `/user/` };
    // @ts-ignore
    let response = await this.store.dispatch('db_put_action', {
      //@ts-ignore
      path: `/user/${this.id}/permissionGrant`,
      payload,
      options,
    });
    //@ts-ignore
    this.store.dispatch('showLoader', false);
    return response;
  }

  // ----- Settings -----

  // ----- Preferences -----

  // ----- Access logs -----

  async getAccessLogs() {
    // let response = await this.store.dispatch('db_get_collection', {
    //   collection_path: `/user/${this.id}/accessLogs`,
    //   options: { return_collection_path: `/user/${this.id}/user_access_log`, definition_name: 'user_access_log' },
    // });
    // return response;
    //@ts-ignore
    //@ts-ignore
    const response = await doPermissionAction(this, 'getUserAccessLogs', { userId: this.id }, {});
  }
}

//@ts-ignore
export function Definition(context, name: string) {
  return new LfxUserMdl(LfxUserDef, LfxUser, context, name, {
    fields: {
      accountId: {
        // datatype: {
        //   foreignKey: {
        //     //@ts-ignore
        //     guards: {
        //     },
        //     // additionalSelectors(record:LfxUserInt) { return Object.entries(context.state.account).filter( acc => acc.accountLevel === 'intermediary') }
        //   },
        // },
        views: { moveUser: true },
      },
      emailAddress: { views: { authEdit: true } },
      msisdn: { views: { authEdit: true } },
      esbUsername: { views: { editBankLogin: true } },
      esbPassword: {
        label: 'One-time ESB Password',
        datatype: 'text',
        default: '',
        search: false,
        mandatory: false,
        allowNullValues: true,
        views: { create: false, edit: false, item: false, list: false, delete: false, csv: false, editBankLogin: true },
      },
      odnUsername: { views: { editOdinLogin: true } },
      odnPassword: {
        label: 'Odin Password',
        datatype: 'text',
        default: '',
        search: false,
        mandatory: false,
        allowNullValues: true,
        views: { create: false, edit: false, item: false, list: false, delete: false, csv: false, editOdinLogin: true },
      },
      mfaExclusionExpiry: {
        write: true,
        views: { addOtpExclusion: true },
      },
    },
  });
}
