<template>
  <div :class="`lfx-holiday-row-container ${dayOrCurrencyClass}`">
  <div class="holiday-date">
  <LfxTinyCal :date="holiday.date" :color="specialCurrencyColor"/>
  </div>
  <div  class="holiday-flags">
    <div class="named-holiday" v-for="holiday in groupedByName" :key="holiday.name">
      <div class="holiday-name">
        {{holiday.name}}
      </div>
      <div class="holiday-currencies">
        <div v-for="currency in holiday.currencies" :key="currency" :class="`holiday-currency ${currencyClass(currency)} `">
          <div class="currency-code">
            {{currency}}
          </div>
          <capitec-avatar class="currency-flag" :icon="`/images/square_flags/${currencyFlag(currency)}.svg`" size="x-small" />
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>

import {
  mapState,
  mapActions,
  mapGetters,
  mapMutations
} from "vuex";

import LfxTinyCal from './LfxTinyCal.vue';
const SPECIAL_CURRENCIES = ['USD','ZAR'];

export default {
  components: {LfxTinyCal},
  props: {  
    holiday:{
      type:Object,
      required:true
    }
  },
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {
  },
  computed:{
    ...mapGetters([]),
  	...mapState([]),
    specialCurrencyColor() {
      return this.hasSpecialCurrency ? 'error' : 'info';
    },
  	hasSpecialCurrency() {
      let currencies = this.holiday.currencies;
      let res = false;
      for (let c = 0 ; c < currencies.length ; c ++ ) {
        let currency = currencies[c].currency;
        if (SPECIAL_CURRENCIES.indexOf(currency) > -1 ) {
          res = true;
          break;
        }
      }
      return res;
    },
    isWeekendDay() {
      let date = new Date(this.holiday.date);
      let dow = date.getDay();
      return ( (dow === 0) || (dow === 6) );
    },
    dayOrCurrencyClass() {
      return  this.isWeekendDay ? 'success' : this.hasSpecialCurrency ? 'error' : 'info';
    },

  	groupedByName() {
      let res = {};
      for (let n = 0 ; n < this.holiday.names.length ; n ++ )  {        
        let name = this.holiday.names[n];
        res[name] = {name:name,currencies:[],date:this};
        for (let c = 0 ; c < this.holiday.currencies.length; c ++ ) {
          let currency = this.holiday.currencies[c];
          if (currency.name === name) {
            res[name].date = this.holiday.date;
            res[name].currencies.push(currency.currency);
          }
        }
        let sortedCurrencies = this.sortCurrencies(res[name].currencies);
        res[name].currencies = sortedCurrencies;
      }
      let tmp = this.sortCurrencyGroups(res);
      return tmp;
    }
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([]),
    currencyClass(currency) {
      return SPECIAL_CURRENCIES.indexOf(currency) === -1 ? '' : 'special';
    },
    sortCurrencyGroups(groups) {
      let groupArray = Object.values(groups);
      let self = this;
      return groupArray.sort( (a,b) => self.sortSpecialCurrency(a,b));
    },
    sortSpecialCurrency(currencyA,currencyB) {
      let aBiggestIndex = Math.max(Array.from(new Set(currencyA.currencies.map(c => SPECIAL_CURRENCIES.indexOf(c)))));
      let bBiggestIndex = Math.max(Array.from(new Set(currencyB.currencies.map(c => SPECIAL_CURRENCIES.indexOf(c)))));
      aBiggestIndex = (aBiggestIndex === -1) ? 10 : aBiggestIndex;
      bBiggestIndex = (bBiggestIndex === -1) ? 10 : bBiggestIndex;
      return (aBiggestIndex - bBiggestIndex);
    },
    sortCurrencies(currencies) {
      let res = [];
      if (currencies.indexOf('ZAR') > -1) {
        res.push('ZAR');
      }
      if (currencies.indexOf('USD') > -1) {
        res.push('USD');
      }
      let rest = [];
      for (let c = 0 ; c < currencies.length ; c ++ ) {
        let currency = currencies[c];
        if (SPECIAL_CURRENCIES.indexOf(currency) === -1) {
          rest.push(currency)
        }       
      }
      return res.concat(rest.sort( (a,b) => a > b ? 1 : -1 ));
    },
  	currencyFlag(currency) {
      return currency.substr(0,2).toLowerCase();
    }
  }
 }
</script>

<style>

.lfx-holiday-row-container.info{
  --currency-color:var(--theme-status-info-background);
}

.lfx-holiday-row-container.success{
  --currency-color:var(--theme-status-success-background);
  filter:grayscale(100%);
  opacity: 0.5;
}

.lfx-holiday-row-container.error{
  --currency-color:var(--theme-status-error-background);
}

.holiday-flags{
  grid-area: flags;
  display:flex;
  display:flex;
  align-items: center;
}

.named-holiday{
  margin-right:5px;
  min-height: 50px;
  max-height: 50px;
  border: 1px solid #EEE;
  border-radius: 5px;
  overflow: hidden;
}

.holiday-name{
  font-size:12px;
  font-style:italic;
  padding-left:5px;
  padding-right:5px;
  font-weight: bold;
  background-color:var(--currency-color);
  color:white;
  margin-bottom:5px;
  max-height: 16px;
  display: flex;
  align-items:center;
}

.holiday-currencies{
  display: flex;
}

.holiday-currency{
  margin-left:3px;
  margin-right: 3px;
  background-color:var(--currency-color);
  padding-right:4px;
  display:grid;
  grid-template-areas: 
  'flag . currency';
  border-radius: 100px;
  grid-template-columns: 25px 5px auto;
  grid-template-rows: 25px;
  font-size: 13px;
}

.holiday-currency.special{
  background-color:var(--currency-color);
}

.currency-code{
  display: flex;
  align-items: center;
  color:white;
  font-weight: bold;
  grid-area: currency;
}

.currency-flag{
  grid-area: flag;
}

.lfx-holiday-row-container{
  padding-top:2px;
  margin-bottom:2px;
  border-top:1px solid #EEE;
  display:grid;
  grid-template-areas:
  'date flags flags'
  'date flags flags';
  grid-template-columns:70px 1fr 1fr;
  grid-template-rows: 25px 25px;
  grid-gap:3px;
}

.holiday-date{
  grid-area: date;
  display:flex;
  align-items: center;
  justify-content: center;
}

</style>


