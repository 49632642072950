<template>
  <LDBModal result="info" :header="header" size="x-large">
    <template #body>
      <div>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="definition"
          :record="paymentRecord"
          @change="on_change"
        />
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
        <!-- @on-api-success="onApiSuccess" -->
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import lodash from 'lodash';
const merge = lodash.merge;

// import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxPaymentEditAmount',
  // components: { LDBField },
  // emits: ['on-api-success'],
  // props: {},
  // data() {
  //   return {};
  // },
  // created() {},
  // mounted() {},
  beforeUnmount() {
    this.paymentRecord.revert();
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['payment', 'transaction']),

    header() {
      return this.transactionType === 'outwardPayment' ? `Edit Payment Amount and Date` : 'Edit Payment Date';
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

        {
          name: 'editPaymentAmountAndDate',
          label: 'Edit',
          type: 'primary',
          action: 'editTransferPayment',
          params: { transactionId: this.transactionId, paymentId: this.paymentId },
          actionParams: this.paymentRecord,
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
      ];
      return allButtons !== undefined ? allButtons : [];
    },

    // ----- IDs -----

    transactionId() {
      return +this.$route.params.transactionId;
    },
    transactionRecord() {
      return this.transaction?.[this.transactionId];
    },
    transactionType() {
      return this.transactionRecord?.transactionType;
    },

    paymentId() {
      return +this.$route.params.paymentId;
    },
    paymentRecord() {
      return this.payment?.[this.paymentId];
    },

    // ----- Definition -----

    definition() {
      return getDefinition('payment');
    },

    // ----- Fields -----

    fields() {
      return this.definition.fields;
    },

    viewFieldNames() {
      let fieldNames = [];
      if (this.transactionType === 'outwardPayment') {
        fieldNames.push('paymentAmount');
      }
      fieldNames.push('valueDate');
      return fieldNames;
    },
    viewFields() {
      const res = [];
      for (const fieldName of this.viewFieldNames) {
        const fields = this.fields.filter((field) => field.field === fieldName);
        if (fields.length > 0) {
          // res.push(fields[0]);
          const updatedField = this.overrideField(fields[0]);
          res.push(updatedField);
        }
      }
      return res;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Fields -----

    on_change({ field, value }) {
      this.paymentRecord[field] = value;
    },

    overrideField(field) {
      if (['paymentAmount', 'valueDate'].includes(field.name)) {
        const startingField = merge({}, field);
        const overrideObject = this.getFieldOverRideObject(field);
        const newField = merge(startingField, overrideObject);
        return newField;
      } else {
        return field;
      }
    },
    getFieldOverRideObject(field) {
      switch (field.name) {
        case 'paymentAmount':
          return {
            datatype: { currency: { symbol: this.paymentRecord?.paymentCurrency || '' } },
          };

        case 'valueDate':
          return {
            datatype: {
              datetime: {
                type: 'date',
                format: 'human',
                minDate: { type: 'day', offset: 0 },
                maxDate: { type: 'day', offset: 7 },
              },
            },
          };

        default:
          return {};
      }
    },

    // // ----- API calls -----

    // async onApiSuccess(_responseObject) {},
  },
};
</script>
