//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxBankAccountDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Bank Account record for LandoByte TS systems
//  Created     : 14 August 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbAuthUser, LdbDefinition, LdbField, LdbViewFunction } from '../definitions/LdbInterfaces';
import { intermediaryFieldViewGuard, notIntermediaryFieldViewGuard } from '../sharedUtils/LdbDefinitionUtils';
import { standardTextWhiteList, swiftTextWhiteList } from '../sharedUtils/LdbValidations';
import type { LfxBeneficiaryStatus } from './LfxBeneficiaryDef';

//----------------------------------------------------------------------------------------------------------------------

export type LfxBankAccountStatus = 'active' | 'disabled' | 'closed';
export type LfxBankAccountStatusDetailsOfCharge = 'OUR' | 'SHARE' | 'BEN';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxBankAccountInt {
  id?: number;
  uuid?: string;
  accountId?: number;
  branchId?: number;
  intermediaryId?: number;
  beneficiaryId?: number;
  reference?: string;
  status?: LfxBankAccountStatus;
  beneficiaryStatus?: LfxBeneficiaryStatus;

  description?: string;
  currencyId?: string;
  bankName?: string;
  branch?: string;
  branchCode?: string;
  accountNumber?: string;
  bankAddress?: string;
  bankCity?: string;
  bankCountryId?: string;
  swiftCode?: string;
  correspondentCode?: string;
  detailsOfCharge?: LfxBankAccountStatusDetailsOfCharge;
  beneficyaryReference?: string;
  specialInstructions?: string;
  isCrypto?: boolean;
  isBusinessBankAccount?: boolean;
  externalReference?: string;
  notes?: string;

  restrictedView?: boolean;

  lastEditedAt?: Date;
  lastEditedBy?: number;
  lastEditedByName?: string;

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

export const notBusinessBankAccountGuard: LdbViewFunction = (viewFunctionObject) => {
  return !viewFunctionObject?.routeMetaData?.content?.businessBankaccountsOnly;
};

//----------------------------------------------------------------------------------------------------------------------

const addToStoreIfNotExist = (_dbObject: LfxBankAccountInt, authUser: LdbAuthUser) => {
  return authUser.accountLevel !== 'bank';
};

//----------------------------------------------------------------------------------------------------------------------

const getBeneficiaryForeignKey = (displayField: string, label: string): LdbField => {
  return {
    label: label,
    datatype: {
      foreignKey: {
        collection: 'fk_beneficiaries',
        linkTable: 'LfxBeneficiary',
        linkField: 'id',
        displayField: displayField,
        localForeignKeyLinkField: 'beneficiaryId',
        sequelizeBelongsTo: 'Beneficiary',
      },
    },
    default: null,
    mandatory: false,
    property: { read: true, write: false, source: 'backend', sort: 'none' },
    views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
    group: 'system',
  };
};

//----------------------------------------------------------------------------------------------------------------------

export const LfxBankAccountDef: LdbDefinition = {
  title: 'Bank Account',
  table: 'LfxBankAccount',
  collectionPath: '/bankAccount',
  addToStoreIfNotExist: addToStoreIfNotExist,
  view: {
    create: true,
    edit: true,
    item: true,
    list: true,
    delete: false,
    csv: false,
    tiles: {
      default: {
        layout: [
          ['title', 'title', 'arrow'],
          ['subtitle', 'subtitle', 'arrow'],
          ['subtitle2', 'subtitle2', 'arrow'],
          ['status', 'infoValue', 'arrow'],
          ['status', 'infoLabel', 'arrow'],
        ],
        color: { type: 'field', field: 'status', optionField: 'tagType', colorType: 'class' },
      },
    },
  },
  indexes: ['accountId', 'beneficiaryId', 'currencyId', 'branchId'],
  pagingType: 'backEnd',
  groups: {
    system: { priority: 1, label: 'System' },
    general: { priority: 2, label: 'General' },
    bank: { priority: 3, label: 'Bank Details' },
    contact: { priority: 4, label: 'Bank Contact' },
    bop: { priority: 5, label: 'BOP Defaults' },
    other: { priority: 6, label: 'Notes' },
    changes: { priority: 7, label: 'Changes' },
  },

  fields: {
    id: {
      label: 'Account Number / IBAN',
      datatype: { id: { descriptionField: 'accountNumber' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
      group: 'system',
    },
    uuid: {
      label: 'UUID',
      datatype: 'uuid',
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    accountId: {
      label: 'Parent',
      datatype: {
        foreignKey: {
          collection: 'fk_accounts',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
          sequelizeBelongsTo: 'Client',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: true },
      group: 'system',
    },
    branchId: {
      label: 'Branch',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediary_branches',

          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: notIntermediaryFieldViewGuard, delete: false, csv: false },
      group: 'system',
    },
    intermediaryId: {
      label: 'Intermediary',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediaries',

          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: intermediaryFieldViewGuard,
        list: intermediaryFieldViewGuard,
        delete: false,
        csv: false,
      },
      group: 'system',
    },

    // Beneficiary Properties for Download --- Start 1/2
    beneficiaryReference: getBeneficiaryForeignKey('reference', 'Beneficiary Reference'),
    beneficiaryFKStatus: getBeneficiaryForeignKey('status', 'Beneficiary Status'),
    beneficiaryType: getBeneficiaryForeignKey('beneficiaryType', 'Beneficiary Type'),
    // Beneficiary Properties for Download --- End 1/2

    beneficiaryId: {
      label: 'Beneficiary',
      datatype: {
        foreignKey: {
          collection: 'fk_beneficiaries',
          linkTable: 'LfxBeneficiary',
          linkField: 'id',
          displayField: 'name',
          sequelizeBelongsTo: 'Beneficiary',
        },
      },
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'system',
    },
    // Beneficiary Properties for Download --- Start 2/2
    beneficiaryFirstName: getBeneficiaryForeignKey('individualFirstname', 'Beneficiary First Name'),
    beneficiaryGender: getBeneficiaryForeignKey('individualGender', 'Beneficiary Gender'),
    beneficiaryRsaResident: getBeneficiaryForeignKey('rsaResident', 'Beneficiary RSA Resident'),
    beneficiaryPassportNumber: getBeneficiaryForeignKey('passportNumber', 'Beneficiary Passport Number'),
    beneficiaryPassportCountry: getBeneficiaryForeignKey('passportCountryId', 'Beneficiary Passport Country'),
    beneficiaryAddress: getBeneficiaryForeignKey('address', 'Beneficiary Address'),
    beneficiaryCity: getBeneficiaryForeignKey('city', 'Beneficiary City'),
    beneficiaryProvince: getBeneficiaryForeignKey('province', 'Beneficiary Province'),
    beneficiaryPostalCode: getBeneficiaryForeignKey('postalCode', 'Beneficiary Postal Code'),
    beneficiaryCountry: getBeneficiaryForeignKey('countryId', 'Beneficiary Country'),
    beneficiaryExternalReference: getBeneficiaryForeignKey('externalReference', 'Beneficiary External Reference'),
    beneficiaryNotes: getBeneficiaryForeignKey('notes', 'Beneficiary Notes'),
    beneficiaryCreatedAt: getBeneficiaryForeignKey('createdAt', 'Beneficiary Created At'),
    beneficiaryCreatedBy: getBeneficiaryForeignKey('createdByName', 'Beneficiary Created By'),
    beneficiaryUpdatedAt: getBeneficiaryForeignKey('updatedAt', 'Beneficiary Updated At'),
    beneficiaryUpdatedBy: getBeneficiaryForeignKey('updatedByName', 'Beneficiary Updated By'),
    // Beneficiary Properties for Download --- End 2/2
    reference: {
      label: 'Reference',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: true,
        list: true,
        delete: false,
        csv: true,
        tiles: { default: 'subtitle' },
      },
      group: 'system',
    },
    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'active', name: 'Active', tagType: 'success' },
            { id: 'disabled', name: 'Disabled', tagType: undefined },
            { id: 'closed', name: 'Closed', tagType: undefined },
          ],
        },
      },
      default: 'active',
      mandatory: true,
      allowNullValues: false,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: true },
      group: 'system',
    },
    beneficiaryStatus: {
      label: 'Beneficiary Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'active', name: 'Active', tagType: 'success' },
            { id: 'disabled', name: 'Disabled', tagType: undefined },
          ],
        },
      },
      default: 'active',
      mandatory: true,
      allowNullValues: false,
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
      group: 'system',
    },

    description: {
      label: 'Description',
      datatype: 'text',
      default: null,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alpha charaters', args: [standardTextWhiteList] },
        len: { msg: 'Description should be between 5 and 50 characters', args: [5, 50] },
      },
      views: {
        create: true,
        edit: true,
        item: true,
        list: true,
        delete: false,
        csv: true,
        tiles: { default: 'title' },
      },
      group: 'general',
    },

    currencyId: {
      label: 'Currency',
      datatype: {
        foreignKey: {
          collection: 'fk_currencies',
          linkTable: 'LdbCurrency',
          linkField: 'id',
          displayField: 'id',
          type: 'text',
        },
      },
      default: null,
      mandatory: true,
      views: {
        create: true,
        edit: true,
        item: true,
        list: true,
        delete: false,
        csv: true,
        tiles: { default: 'infoLabel' },
      },
      group: 'general',
    },
    bankName: {
      label: 'Bank Name',
      datatype: 'text',
      default: null,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: swiftTextWhiteList },
        len: { msg: 'Bank Name should be between 1 and 50 characters', args: [1, 50] },
      },
      views: {
        create: true,
        edit: true,
        item: true,
        list: notBusinessBankAccountGuard,
        delete: false,
        csv: true,
        tiles: { default: 'infoValue' },
      },
      group: 'bank',
    },
    branch: {
      label: 'Branch',
      datatype: 'text',
      default: null,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: swiftTextWhiteList },
        len: { msg: 'Branch should be between 1 and 50 characters', args: [1, 50] },
      },
      views: { create: true, edit: true, item: true, list: notBusinessBankAccountGuard, delete: false, csv: true },
      group: 'bank',
    },
    branchCode: {
      label: 'Branch Routing / Transit code',
      datatype: 'text',
      default: null,
      mandatory: false,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: swiftTextWhiteList },
        len: { msg: 'Branch Code should be between 1 and 50 characters', args: [1, 50] },
      },
      views: { create: true, edit: true, item: true, list: notBusinessBankAccountGuard, delete: false, csv: true },
      group: 'bank',
    },
    accountNumber: {
      label: 'Account Number / IBAN',
      datatype: 'text',
      default: null,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: "^[a-zA-Z0-9]*$" },
        len: { msg: 'Account Number should be between 1 and 50 characters', args: [1, 50] },
        custom: {
          vFunction: (value: any, object: any) => {
            return !object.isBusinessBankAccount || new RegExp('^([0-9]{10})$').test(value);
          },
          msg: 'Please enter a valid Capitec Business Bank Account Number',
          validatingInterface: 'frontend',
        },
      },
      views: {
        create: true,
        edit: true,
        item: true,
        list: true,
        delete: false,
        csv: true,
        search: true,
        tiles: { default: 'subtitle2' },
      },
      group: 'bank',
    },
    bankAddress: {
      label: 'Bank Address',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: swiftTextWhiteList },
        len: { msg: 'Bank Address should be between 5 and 250 characters', args: [5, 250] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: true },
      group: 'contact',
    },
    bankCity: {
      label: 'Bank City',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: swiftTextWhiteList },
        len: { msg: 'City should be between 1 and 50 characters', args: [1, 50] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: true },
      group: 'contact',
    },
    bankCountryId: {
      label: 'Bank Country',
      datatype: {
        foreignKey: {
          collection: 'fk_countries',

          linkTable: 'LdbCountry',
          linkField: 'id',
          displayField: 'name',
          type: 'text',
          guards: {status: {eq:"enabled"}}
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: true, edit: true, item: true, list: notBusinessBankAccountGuard, delete: false, csv: true },
      group: 'contact',
    },
    swiftCode: {
      label: 'SWIFT Code',
      datatype: 'text',
      default: null,
      mandatory: true,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: "^[a-zA-Z0-9_'();+,\\-./]*$" },
        len: { msg: 'SWIFT Code should be between 8 and 11 characters (SWIFT Standard)', args: [8, 11] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: true },
      group: 'contact',
    },
    correspondentCode: {
      label: 'Correspondent / Intermediary Bank SWIFT Code',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: "^[a-zA-Z0-9_'();+,\\-./]*$" },
        len: { msg: 'SWIFT Code should be between 8 and 11 characters (SWIFT Standard)', args: [8, 11] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: true },
      group: 'contact',
    },
    detailsOfCharge: {
      label: 'Details of Charge',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'OUR', name: 'All transaction charges are to be borne by the ordering customer' },
            {
              id: 'SHARE',
              name: "Transaction charges on the Sender's side are to be borne by the ordering customer, transaction Charges on the Receiver's side are to be borne by the beneficiary customer.",
            },
            { id: 'BEN', name: 'All transaction charges are to be borne by the beneficiary customer' },
          ],
        },
      },
      default: null,
      allowNullValues: true,
      mandatory: false,
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'bop',
    },
    beneficyaryReference: {
      label: 'Beneficiary Reference',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: swiftTextWhiteList },
        len: { msg: 'Beneficiary Reference should be between 1 and 250 characters', args: [1, 250] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'bop',
    },
    specialInstructions: {
      label: 'Special Instruction',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Special Instructions should be between 1 and 250 characters', args: [1, 250] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'bop',
    },
    isCrypto: {
      label: 'Is Crypto Account',
      datatype: 'boolean',
      default: false,
      mandatory: false,
      allowNullValues: true,
      views: {
        create: notBusinessBankAccountGuard,
        edit: notBusinessBankAccountGuard,
        item: notBusinessBankAccountGuard,
        list: false,
        delete: false,
        csv: false,
      },
      group: 'bop',
    },
    isBusinessBankAccount: {
      label: 'Is Business Bank',
      datatype: 'boolean',
      default: false,
      mandatory: true,
      allowNullValues: false,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    externalReference: {
      label: 'External Reference',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'External Reference should be between 0 and 250 characters', args: [0, 250] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: true },
      group: 'other',
    },
    notes: {
      label: 'Notes',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Notes should be between 1 and 250 characters', args: [1, 250] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: true },
      group: 'other',
    },

    restrictedView: {
      label: 'Restricted View',
      datatype: 'text',
      search: true,
      property: { source: 'backend', read: true, write: false, sort: 'none' },
      default: false,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },

    lastEditedAt: {
      label: 'Details Last Edited At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: true },
      group: 'changes',
    },
    lastEditedBy: {
      label: 'Details Last Edited By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    lastEditedByName: {
      label: 'Details Last Edited By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'changes',
    },

    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'changes',
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'changes',
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'changes',
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'changes',
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },

    // ------------- view fields ---------------

    fromDate: {
      //STATEMENT
      label: 'From Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
          minDate: { type: 'day', offset: -182 },
          maxDate: { type: 'day', offset: 0 },
        },
      },
      default: null,
      mandatory: false,
      property: { source: 'frontend', read: true, write: true, sort: 'none' },
      views: {},
    },
    toDate: {
      //STATEMENT
      label: 'To Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
          minDate: { type: 'day', offset: -182 },
          maxDate: { type: 'day', offset: 0 },
        },
      },
      default: null,
      mandatory: false,
      property: { source: 'frontend', read: true, write: true, sort: 'none' },
      views: {},
    },
  },
};

//----------------------------------------------------------------------------------------------------------------------
