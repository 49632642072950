//@ts-nocheck
import VueStore from 'vue-class-store';

import {
  LfxBankAccountCommissionLevelInt,
  LfxBankAccountCommissionLevelDef,
} from '@/definitions/LfxBankAccountCommissionLevelDef';

import { LfxModel, LfxDefinition } from './LfxModel';

@VueStore
class LfxBankAccountCommissionLevelMdl extends LfxDefinition {
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
export class LfxBankAccountCommissionLevel extends LfxModel {
  constructor(data, state) {
    super({definition_name:'bank_account_commission_level',...data}, state);
  }
}

export function Definition(context, name: string) {
  return new LfxBankAccountCommissionLevelMdl(
    LfxBankAccountCommissionLevelDef,
    LfxBankAccountCommissionLevel,
    context,
    name,
    {}
  );
}
