import { RouteRecordRaw } from 'vue-router';

import LfxTodo from '@/components/todo/LfxTodo.vue';
import LfxTodoModal from '@/components/todo/LfxTodoModal.vue';

import LfxUser from '@/views/users/LfxUser.vue';
import LfxUsers from '@/views/users/LfxUsers.vue';

import LfxUserDetails from '@/views/users/LfxUserDetails.vue';
import LfxUsersList from '@/views/users/LfxUsersList.vue';
import LfxUserPermissionsList from '@/views/users/LfxUserPermissionsList.vue';
import LfxEditUserPermissions from '@/views/users/LfxEditUserPermissions.vue';
import LfxRecacheUserPermissions from '@/views/users/LfxRecacheUserPermissions.vue'
import LfxUserNoSignatory from '@/views/users/LfxUserNoSignatory.vue';
import LfxUserSettings from '@/views/users/LfxUserSettings.vue';
import LfxUserAccessLogsList from '@/views/users/LfxUserAccessLogsList.vue';
import LdbEmailLogList from '@/views/users/LdbEmailLogList.vue'

import LfxAddUser from '@/views/users/LfxAddUser.vue';
import LfxConfigureUser from '@/views/users/LfxConfigureUser.vue';
import LfxUserSummary from '@/views/users/LfxUserSummary.vue';

import LfxUnhideUser from '@/views/users/LfxUnhideUser.vue';
import LfxEnableUser from '@/views/users/LfxEnableUser.vue';
import LfxAssignUserTeam from '@/views/users/LfxAssignUserTeam.vue'
import LfxConfigureUserAssignUserTeam from '@/views/users/LfxConfigureUserAssignUserTeam.vue'
import LfxResendWelcomeEmail from '@/views/users/LfxResendWelcomeEmail.vue';
import LfxEditAuth from '@/views/users/LfxEditAuth.vue';
// import LfxEditBankLogin from '@/views/users/LfxEditBankLogin.vue';
import LfxMoveUser from '@/views/users/LfxMoveUser.vue';
import LfxDisableUser from '@/views/users/LfxDisableUser.vue';
import LfxHideUser from '@/views/users/LfxHideUser.vue';

import LfxReviewUserPermissions from '@/views/users/LfxReviewUserPermissions.vue';

import LfxEnableUserSignatory from '@/views/users/LfxEnableUserSignatory.vue';

import LfxAddOtpExclusion from '@/views/users/LfxAddOtpExclusion.vue';

import LfxSignatoryDetails from '@/views/signatories/LfxSignatoryDetails.vue';

const routes: RouteRecordRaw[] = [
  //
  // ----- Users List -----

  {
    path: '/users',
    name: '_users',
    components: { default: LfxUsers },
    meta: { icon: 'material/local_library' },
    children: [
      { path: '', name: 'users', components: { default: LfxUsersList }, meta: { content: { layout: 'list' } } },
      {
        path: 'add_user',
        name: 'addUser',
        components: { default: LfxUsersList, modal: LfxAddUser },
        meta: { modal: { cancelRoute: 'users' } },
      },
    ],
  },

  // ----- User -----

  {
    path: '/user/:userId(\\d+)',
    name: 'user',
    // components: { user: LfxUser },
    component: LfxUser,
    meta: { icon: 'system/profile-action' },
    children: [
      //
      // User Details

      {
        path: 'details',
        name: 'userDetailsView',
        component: LfxUserDetails,
        meta: {
          content: { tabName: 'details', label: 'User Details', actionGroup: 'userDetailsView' },
        },
        children: [
          {
            name: 'userDetailsEditBankLogin',
            path: 'edit_bank_login',
            component: LfxReviewUserPermissions,
          },
          {
            name: 'userDetailsEditOdinLogin',
            path: 'edit_odin_login',
            component: LfxReviewUserPermissions,
          },
        ],
      },
      {
        path: 'details/unhide',
        name: 'userDetailsUnhide',
        components: { default: LfxUserDetails, modal: LfxUnhideUser },
        meta: {
          content: { tabName: 'details', label: 'User Details', actionGroup: 'userDetailsView' },
          modal: { cancelRoute: 'userDetailsView' },
        },
      },
      {
        path: 'details/unlock',
        name: 'userDetailsUnlock',
        components: { default: LfxUserDetails, modal: LfxTodoModal },
        meta: {
          content: { tabName: 'details', label: 'User Details', actionGroup: 'userDetailsView' },
          modal: { cancelRoute: 'userDetailsView' },
        },
      },
      {
        path: 'details/enable',
        name: 'userDetailsEnable',
        components: { default: LfxUserDetails, modal: LfxEnableUser },
        meta: {
          content: { tabName: 'details', label: 'User Details', actionGroup: 'userDetailsView' },
          modal: { cancelRoute: 'userDetailsView' },
        },
      },
      {
        path: 'details/resend_welcome_email',
        name: 'userDetailsResendWelcomeEmail',
        components: { default: LfxUserDetails, modal: LfxResendWelcomeEmail },
        meta: {
          content: { tabName: 'details', label: 'User Details', actionGroup: 'userDetailsView' },
          modal: { cancelRoute: 'userDetailsView' },
        },
      },

      {
        path: 'details/edit',
        name: 'userDetailsEdit',
        component: LfxUserDetails,
        meta: {
          content: {
            tabName: 'details',
            label: 'Edit User Details',
            view: 'edit',
            actionGroup: 'userDetailsEdit',
            cancelRoute: 'userDetailsView',
          },
        },
      },

      {
        path: 'details/edit_auth',
        name: 'userDetailsEditAuth',
        components: { default: LfxUserDetails, modal: LfxEditAuth },
        meta: {
          content: { tabName: 'details', label: 'User Details', actionGroup: 'userDetailsView' },
          modal: { cancelRoute: 'userDetailsView' },
        },
      },
      {
        path: 'details/move',
        name: 'userDetailsMove',
        components: { default: LfxUserDetails, modal: LfxMoveUser },
        meta: {
          content: { tabName: 'details', label: 'User Details', actionGroup: 'userDetailsView' },
          modal: { cancelRoute: 'userDetailsView' },
        },
      },
      {
        path: 'details/disable',
        name: 'userDetailsDisable',
        components: { default: LfxUserDetails, modal: LfxDisableUser },
        meta: {
          content: { tabName: 'details', label: 'User Details', actionGroup: 'userDetailsView' },
          modal: { cancelRoute: 'userDetailsView' },
        },
      },
      {
        path: 'details/hide',
        name: 'userDetailsHide',
        components: { default: LfxUserDetails, modal: LfxHideUser },
        meta: {
          content: { tabName: 'details', label: 'User Details', actionGroup: 'userDetailsView' },
          modal: { cancelRoute: 'userDetailsView' },
        },
      },
      {
        path: 'details/userDetailsAssignTeam',
        name: 'userDetailsAssignTeam',
        components: { default: LfxUserDetails, modal: LfxAssignUserTeam },
        meta: {
          content: { tabName: 'details', label: 'User Details', actionGroup: 'userDetailsView' },
          modal: { cancelRoute: 'userDetailsView' },
        },
      },

      // User Permissions

      {
        path: 'permissions',
        name: 'userPermissionsView',
        component: LfxUserPermissionsList,
        meta: {
          layout: 'list',
          content: { tabName: 'permissions', label: 'User Permissions', actionGroup: 'userPermissionsView' },
        },
        children: [
          {
            path: 'review',
            name: 'userPermissionsViewReview',
            component: LfxReviewUserPermissions,
          },
          {
            path: 'recache',
            name: 'userPermissionsRecache',
            component: LfxRecacheUserPermissions,
          },
    
        ],
      },
      {
        path: 'permissions/edit',
        name: 'userPermissionsEdit',
        component: LfxEditUserPermissions,
        meta: {
          content: { tabName: 'permissions', label: 'Edit User Permissions', actionGroup: 'userPermissionsEdit' },
        },
      },

      // Signatory

      {
        path: 'signatory',
        name: 'userNoSignatoryView',
        component: LfxUserNoSignatory,
        meta: {
          content: { tabName: 'signatory', label: 'User Signatory', actionGroup: 'userNoSignatoryView' },
        },
        children: [
          {
            name: 'userNoSignatoryViewEnableSignatory',
            path: 'enable',
            component: LfxEnableUserSignatory,
          },
        ],
      },
      {
        path: 'signatory/:signatoryId(\\d+)',
        name: 'userSignatoryView',
        component: LfxSignatoryDetails,
        meta: {
          content: { tabName: 'signatory', label: 'User Signatory', actionGroup: 'userSignatoryView' },
        },
        children: [
          // TODO edit in modal
          // TODO convert to contact
          // TODO disable signatory
          // TODO logs
        ],
      },
      {
        name: 'userSignatoryEdit',
        path: 'signatory/:signatoryId(\\d+)/edit',
        component: LfxSignatoryDetails,
        meta: {
          content: {
            tabName: 'signatory',
            label: 'Edit User Signatory',
            actionGroup: 'userSignatoryEdit',
            view: 'edit',
          },
        },
      },

      // Settings

      {
        path: 'settings',
        name: 'userSettingsView',
        component: LfxUserSettings,
        meta: {
          content: { tabName: 'settings', label: 'User Settings', actionGroup: 'userSettingsView' },
        },
      },
      {
        path: 'settings/edit',
        name: 'userSettingsEdit',
        component: LfxTodo, //TODO
        meta: {
          content: { tabName: 'settings', label: 'User Settings', actionGroup: 'userSettingsEdit', view: 'edit' },
        },
      },
      {
        path: 'settings/add_otp', //TODO
        name: 'userAddOtpExclusion',
        components: { default: LfxUserDetails, modal: LfxAddOtpExclusion },
        meta: {
          content: { tabName: 'access', label: 'User MFA', actionGroup: 'userLogsView' },
          modal: { cancelRoute: 'userLogsView' },
        },
      },

      // Preferences

      {
        path: 'preferences',
        name: 'userPreferencesView',
        component: LfxTodo, //TODO
        meta: {
          content: { tabName: 'preferences', label: 'User Preferences', actionGroup: 'userPreferencesView' },
        },
      },
      {
        path: 'preferences/edit',
        name: 'userPreferencesEdit',
        component: LfxTodo, //TODO
        meta: {
          content: {
            tabName: 'preferences',
            label: 'Edit User Preferences',
            actionGroup: 'userPreferencesEdit',
            view: 'edit',
          },
        },
      },

      // Logs

      {
        path: 'logs',
        name: 'userLogsView',
        component: LfxUserAccessLogsList,
        meta: {
          content: { tabName: 'access', label: 'User Access', layout: 'list', actionGroup: 'userAccessLogs' },
        },
      },
      {
        path: 'emailLogs',
        name: 'userEmailLogs',
        component: LdbEmailLogList,
        meta: {
          listType: 'user',
          content: { tabName: 'access', label: 'User Access', layout: 'list', actionGroup: 'userAccessLogs' },
        },
      },
    ],
  },

  // ----- Configure User -----

  {
    path: '/user/:userId(\\d+)/configure_user',
    name: 'configureUser',
    component: LfxConfigureUser,
    meta: { icon: 'system/profile-action', wizard: true },
    children: [
      //
      // Details

      {
        path: 'details/edit',
        name: 'configureUserDetailsEdit',
        component: LfxUserDetails,
        meta: {
          content: {
            stepName: 'details',
            label: 'Edit User Details',
            actionGroup: 'configureUserDetailsEdit',
            view: 'edit',
            formButtons: { showNextButton: true },
          },
        },
      },
      {
        path: 'details/edit/auth',
        name: 'configureUserDetailsEditAuth',
        components: { default: LfxUserDetails, modal: LfxEditAuth },
        meta: {
          content: {
            stepName: 'details',
            label: 'Edit User Details',
            actionGroup: 'configureUserDetailsEdit',
            view: 'edit',
            formButtons: { showNextButton: true },
          },
        },
      },

      // Permissions

      {
        path: 'permsissions/edit',
        name: 'configureUserPermissionsEdit',
        component: LfxEditUserPermissions,
        meta: {
          content: {
            stepName: 'permissions',
            label: 'Select User Permissions',
            actionGroup: 'configureUserPermissionsEdit',
            view: 'edit',
            formButtons: { showBackButton: true, showNextButton: true },
          },
        },
      },
      {
        path: 'permsissions/edit/confirm_special_permissions',
        name: 'configureUserPermissionsEditConfirmSpecialPermissions',
        components: { default: LfxEditUserPermissions, modal: LfxTodoModal },
        meta: {
          content: {
            stepName: 'permissions',
            label: 'Select User Permissions',
            actionGroup: 'configureUserPermissionsEdit',
            view: 'edit',
            formButtons: { showBackButton: true, showNextButton: true },
          },
          modal: { cancelRoute: 'configureUserPermissionsEdit' },
        },
      },

      // Settings

      {
        path: 'settings/edit',
        name: 'configureUserSettingsEdit',
        component: LfxTodo,
        meta: {
          content: {
            stepName: 'settings',
            label: 'Capture User Settings',
            actionGroup: 'configureUserSettingsEdit',
            view: 'edit',
            formButtons: { showBackButton: true, showNextButton: true },
          },
        },
      },
      // User Team

      {
        path: 'userTeam',
        name: 'configureUserUserTeam',
        component: LfxConfigureUserAssignUserTeam,
        meta: {
          content: {
            stepName: 'userTeam',
            label: 'Assign User Team',
            formButtons: { showBackButton: true },
          },
        },
      },

      // Enable

      {
        path: 'enable',
        name: 'configureUserEnable',
        component: LfxUserSummary,
        meta: {
          content: {
            stepName: 'enable',
            label: 'Enable User',
            formButtons: { showBackButton: true },
          },
        },
      },
    ],
  },
];

export default routes;
