<template>
  <LDBItemView :record="swiftRecord" :view="view" />
  <router-view></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

export default {
  name: 'LfxSwiftDetails',
  components: { LDBItemView, LDBLoader },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['swift']),

    view() {
      return this.$route.meta?.content?.view || 'item';
    },

    // ----- SWIFT Record -----

    swiftId() {
      return +this.$route.params?.swiftId;
    },
    swiftRecord() {
      return this.swift?.[this.swiftId];
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
