<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <capitec-stepper
        v-if="showStepper"
        layout="horizontal"
        :steps="steps"
        :active-step-number="activeStepNumber"
        @step-click="onStepClick"
      >
      </capitec-stepper>

      <template v-if="activeStepName === 'bankLogin'">
        <p>Capture the ESB login details for the User:</p>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          :view="field.view || 'edit'"
          :fieldname="field.name"
          :definition="definition"
          :record="record"
          @change="on_change"
        />
      </template>
      <template v-else-if="activeStepName === 'odinLogin'">
        <p>Capture the Odin login details for the User:</p>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          :view="field.view || 'edit'"
          :fieldname="field.name"
          :definition="definition"
          :record="record"
          @change="on_change"
        />
      </template>

      <template v-if="activeStepName === 'review'">
        <p>The following permissions have been requested for the User:</p>
        <ul>
          <li v-for="permission in pendingPermissionsActionableDescriptions" :key="permission">
            {{ permission }}
          </li>
        </ul>
        <p>Do you wish to Reject or Grant the Permissions?</p>
        <p v-if="stepOneIsError">
          Note: an ESB Username must be captured against the user, and the User's ESB Status must be active, before the
          permissions can be granted.
        </p>
      </template>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in leftButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      />
      <capitec-spacer />
      <LDBButton
        v-for="button in rightButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      />
    </template>
  </LDBModal>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import { getDefinition } from '@/db-interface';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxReviewUserPermissions',
  components: { LDBField },
  props: {},
  data() {
    return {
      activeStepNumber: 1,
    };
  },
  created() {
    this.selectFirstStep();
  },
  mounted() {
    this.record.organisation = this.accountRecord?.organisation || this.intermediaryRecord?.organisation
  },
  beforeUnmount() {
    this.record.revert();
    delete this.record.esbPassword;
    delete this.record.organisation
    delete this.record.odnPassword
  },
  computed: {
    ...mapGetters(['config', 'permissions']),
    ...mapState(['account', 'user','systemSettings']),

    // ----- Header -----

    header() {
      return this.$route.name === 'userPermissionsViewReview'
        ? `Review Permissions for: ${this.userDisplayName}`
        : `Edit ${this.$route.name === 'userDetailsEditOdinLogin' ? 'Odin' : 'ESB'} Details for: ${this.userDisplayName}`;
    },

    // ----- Stepper -----

    showStepper() {
      return this.$route.name === 'userPermissionsViewReview' ? true : false;
    },
    steps() {
      let steps = [
        { index: 1, stepName: 'bankLogin', title: 'Bank Login', isError: this.adLoginIsError, guards:this.adLoginEnabled },
        { index: 2, stepName: 'odinLogin', title: 'Odin Login', isError: this.odinLoginIsError, guards:this.odinLoginEnabled },
        { index: 3, stepName: 'review', title: 'Review Permissions',guards: true },
      ];
      return steps.filter((step) => step.guards);
    },
    activeStepIndex() {
      return this.activeStepNumber - 1; // Steps start at 1
    },
    activeStepName() {
      return this.steps?.[this.activeStepIndex].stepName;
    },
    activeStepLabel() {
      return this.steps?.[this.activeStepIndex].stepLabel;
    },

    adLoginIsError() {
      return this.record?.esbUsername && this.record?.esbUserStatus === 'active' ? false : true;
    },
    odinLoginIsError() {
      return this.record?.odnUsername && this.record?.odnUserStatus === 'active' ? false : true;
    },

    adLoginEnabled() {
      return !!this.systemSettings?.odinEnable?.esbLogin
    },
    odinLoginEnabled() {
      return !!this.systemSettings?.odinEnable?.odinLogin
    },
    // ----- Definition -----

    definition() {
      return getDefinition('user');
    },

    // ----- Record -----

    recordId() {
      return +this.$route.params?.userId;
    },
    record() {
      return this.user?.[this.recordId];
    },
    accountRecord() {
      return this.account?.[this.record?.accountId];
    },

    intermediaryRecord() {
      return this.account?.[this.accountRecord?.intermediaryId];
    },
    userDisplayName() {
      return this.record?.userDisplayName;
    },

    // ----- Fields -----

    fields() {
      return this.definition.fields;
    },
    editDetailsEnableGuards() {
      if (this.credentialEditing === 'odin') {
        return !!(this.record?.odnUsername && this.record?.odnPassword)
      }
      return !!(this.record?.esbUsername && this.record?.esbPassword)
    },
    editDetailsAction() {
      if (this.credentialEditing === 'odin') {
        return 'updateOdinLoginDetails'
      }
      return 'updateBankLoginDetails';
    },
    credentialEditing() {
      if (this.activeStepName === 'odinLogin' || this.$route.name === 'userDetailsEditOdinLogin') {
        return 'odin'
      }
      return 'ad'
    },
    view() {
      if (this.credentialEditing === 'odin') {
        return 'editOdinLogin'
      }
      return 'editBankLogin';
    },
    viewFields() {
      let res = [{
        name: 'organisation',
        label:'Intermediary Organisation',
        datatype:'text',
        view: 'item',
      }];
      for (let k in this.fields) {
        let field = this.fields[k];
        if (field.views[this.view] === true) {
          field.fieldname = k;
          res.push(field);
        }
      }
      return res;
    },

    // ----- Permissions -----

    pendingPermissionsActionable() {
      return this.record?.pendingPermissionsActionable;
    },
    pendingPermissionsActionableDescriptions() {
      let res = [];
      for (let p = 0; p < this.pendingPermissionsActionable.length; p++) {
        let perm = this.pendingPermissionsActionable[p];
        let permDescription = this.config.permission[perm].description;
        res.push(permDescription);
      }
      return res;
    },

    // ----- Buttons -----

    cancelButton() {
      let self = this;
      let button = {
        name: 'cancel',
        label: 'Cancel',
        type: '',
        guards: true,
        clickEvent() {
          self.$router.go(-1);
        },
      };
      return button;
    },
    rejectButton() {
      let self = this;
      let button = {
        name: 'rejectPermissions',
        label: 'Reject Permissions',
        type: '',
        action: 'rejectUserPermissions',
        params: { userId: this.record.id },
        actionParams: { userId: this.record.id },
        guards: this.$route.name === 'userPermissionsViewReview',
        clickEvent(response) {
          if (response.status === 'success') {
            self.$router.go(-1);
          }
        },
      };
      return button;
    },

    leftButtons() {
      let self = this;
      let allButtons = [{
        name: 'previousStep',
        label: 'Back',
        type: 'clear',
        guards: this.activeStepNumber !== 1,
        clickEvent() {
          self.onBackClick();
        }}]
      return allButtons.filter((button) => button.guards);
    },

    rightButtons() {
      let self = this;
      let allButtons = {
        'bankLogin': [
          this.cancelButton,
          this.rejectButton,
          {
            name: 'updateBankLoginDetails',
            label: this.$route.name === 'userPermissionsViewReview' ? 'Next' : 'Save and Login',
            type: 'primary',
            action: this.editDetailsAction,
            params: { userId: this.record?.id },
            actionParams: this.record,
            guards: true,
            enabledGuards: this.editDetailsEnableGuards,
            clickEvent(response) {
              if (response.status === 'success') {
                self.bankLogin();
              }
            },
          },
        ],
        'odinLogin': [
          this.cancelButton,
          this.rejectButton,
          {
            name: 'updateBankLoginDetails',
            label: this.$route.name === 'userPermissionsViewReview' ? 'Next' : 'Save and Login',
            type: 'primary',
            action: this.editDetailsAction,
            params: { userId: this.record?.id },
            actionParams: this.record,
            guards: true,
            enabledGuards: this.editDetailsEnableGuards,
            clickEvent(response) {
              if (response.status === 'success') {
                self.bankLogin();
              }
            },
          },
        ],
        'review': [
          this.cancelButton,
          this.rejectButton,
          {
            name: 'grantPermissions',
            label: 'Grant Permissions',
            type: 'primary',
            action: 'grantUserPermissions',
            params: { userId: this.record?.id },
            guards: true,
            enabledGuards: !this.stepOneIsError,
            clickEvent(response) {
              if (response.status === 'success') {
                self.$router.go(-1);
              }
            },
          },
        ],
      };
      return allButtons[this.activeStepName]?.filter((button) => button.guards);
    },
  },
  methods: {
    ...mapActions(['showLoader', 'showToast']),
    ...mapMutations([]),

    // ----- Stepper -----

    onBackClick() {
      let newStepNumber = this.activeStepNumber - 1;
      let evt = { detail: { stepNumber: newStepNumber } };
      this.onStepClick(evt);
    },
    onStepClick(evt) {
      this.activeStepNumber = evt.detail.stepNumber;
    },

    selectFirstStep() {
      if (this.showStepper) {
        let selectStep = 1;
        if (this.adLoginEnabled) {
          if (!this.adLoginIsError) {
            selectStep ++;
          }
        }
        if (this.odinLoginEnabled) {
          if (!this.odinLoginIsError) {
            selectStep ++;
          }
        }
        this.activeStepNumber = selectStep
      }
    },
    reviewStepIsSelected() {

    },

    // ----- Fields -----

    on_change({ field, value }) {
      this.record[field] = value;
    },

    // ----- API calls -----

    async bankLogin() {
      this.showLoader(true);
      let response
      if (this.credentialEditing === 'odin') {
        response = await doPermissionAction(this, 'odinLogin', { userId: this.recordId });
      } else {
        response = await doPermissionAction(this, 'bankLogin', { userId: this.recordId });
      }
      this.showLoader(false);

      if (response.status === 'success') {
        switch (this.$route.name) {
          case 'userDetailsEditBankLogin':
            this.$router.go(-1);
          case 'userPermissionsViewReview':
            this.activeStepNumber ++;
        }
      }
    },
  },
};
</script>
