

import {
  defineComponent,
  ref,
  reactive,
  computed,
  watch,
  onBeforeUpdate,
  nextTick,
} from "vue";

interface tableSetting {
  isSlotMode: boolean;
  isCheckAll: boolean;
  keyColumn: string;
  page: number;
  pageSize: number;
  maxPage: number;
  offset: number;
  limit: number;
  paging: Array<number>;
  order: string;
  sort: string;
  performLocalSort: boolean;
}

interface column {
  isKey: string;
  field: string;
  col:{
    name:string,
    datatype:object|string,
    align:string
  }
}

export default defineComponent({
  name: "my-table",
  emits: [
    "return-checked-rows", 
    "do-search", 
    "is-finished",
    "on-page-size-change",
    "on-page-change"
  ],
  props: {
    // 是否讀取中 (is data loading)
    isLoading: {
      type: Boolean,
      require: true,
    },
    // 是否執行了重新查詢 (Whether to perform a re-query)
    isReSearch: {
      type: Boolean,
      require: true,
    },
    // 有無Checkbox (Presence of Checkbox)
    hasCheckbox: {
      type: Boolean,
      default: false,
    },
    // 標題 (title)
    title: {
      type: String,
      default: "",
    },
    // 欄位 (Field)
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 資料 (data)
    rows: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 一頁顯示筆數 (Display the number of items on one page)
    pageSize: {
      type: Number,
      default: 10,
    },
    // 總筆數 (Total number of transactions)
    total: {
      type: Number,
      default: 100,
    },
    // 現在頁數 (Current page number)
    page: {
      type: Number,
      default: 1,
    },
    isPagedTable:{
      type:Boolean,
      default:false
    },
    // 排序條件 (Sort condition)
    sortable: {
      type: Object,
      default: () => {
        return {
          order: "id",
          sort: "asc",
          performLocalSort: true
        };
      },
    },
    // 顯示文字 (Display text)
    messages: {
      type: Object,
      default: () => {
        return {
          pageSizeOptions:[
            {size:10,label:'10 Rows'},
            {size:50,label:'50 Rows'},
            {size:100,label:'100 Rows'},
            {size:200,label:'200 Rows'},
          ],
          pageSizeValue:{size:100,label:'100 Rows'},
          show_first_page_arrows:false,
          show_last_page_arrows:false,
          show_go_to_page:false,
          pagingInfo: "{0}-{1} of {2}",
          pageSizeChangeLabel: "Rows",
          gotoPageLabel: "Go to page:",
          noDataAvailable: "No data",
        };
      },
    },
    // 靜態模式 (Static mode(no refresh server data))
    isStaticMode: {
      type: Boolean,
      default: false,
    },
    // 靜態模式 (Static mode(no refresh server data))
    isSlotMode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    // 組件用內部設定值 (Internal set value for components)
    const setting: tableSetting = reactive({
      // 是否啟用Slot模式 (Enable slot mode)
      isSlotMode: props.isSlotMode,
      // 是否全選 (Whether to select all)
      isCheckAll: false,
      // KEY欄位名稱 (KEY field name)
      keyColumn: computed(() => {
        let key = "";
        Object.assign(props.columns).forEach((col: column) => {
          if (col.isKey) {
            key = col.field;
          }
        });
        return key;
      }),
      // 當前頁數 (current page number)
      page: props.page,
      // 每頁顯示筆數 (Display count per page)
      pageSize: props.pageSize,
      // 最大頁數 (Maximum number of pages)
      maxPage: computed(() => {
        if (props.total <= 0) {
          return 0;
        }
        let maxPage = Math.floor(props.total / setting.pageSize);
        let mod = props.total % setting.pageSize;
        if (mod > 0) {
          maxPage++;
        }
        return maxPage;
      }),
      // 該頁數起始值 (The starting value of the page number)
      offset: computed(() => {
        return (setting.page - 1) * setting.pageSize + 1;
      }),
      // 該頁數最大值 (Maximum number of pages0
      limit: computed(() => {
        let limit = setting.page * setting.pageSize;
        return props.total >= limit ? limit : props.total;
      }),
      // 換頁陣列 (Paging array)
      paging: computed(() => {
        let startPage = setting.page - 2 <= 0 ? 1 : setting.page - 2;
        if (setting.maxPage - setting.page <= 2) {
          startPage = setting.maxPage - 4;
        }
        startPage = startPage <= 0 ? 1 : startPage;
        let pages = [];
        for (let i = startPage; i <= setting.maxPage; i++) {
          if (pages.length < 5) {
            pages.push(i);
          }
        }
        return pages;
      }),
      viewRecords:computed(() => { 
        let res:any[] = [];
        for (let r = setting.offset ; r < (setting.offset + setting.page) ; r ++ ) {
          let record = props.rows[r];
          if (record !== undefined) {
            res.push(record);
          }
        }
        return res;
      }),
      // 組件內用排序 (Sortable for local)
      order: props.sortable.order,
      sort: props.sortable.sort,
      performLocalSort: props.sortable.performLocalSort !== false
    });

    // 組件內用資料 (Data rows for local)
    const localRows = computed(() => {
      // sort rows
      let property = setting.order;
      let sort_order = 1;
      if (setting.sort === "desc") {
        sort_order = -1;
      }
      let rows = props.rows as Array<unknown>;
      
      if (setting.performLocalSort) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rows.sort((a: any, b: any): number => {
          let sortProperty = property;
          const labelProperty = sortProperty+"_label"
          let aSort = a[labelProperty] || a[property];
          let bSort = b[labelProperty] || b[property];
          if (typeof aSort === 'string') {
            aSort = aSort.toLowerCase();
          }
          if (typeof bSort === 'string') {
            bSort = bSort.toLowerCase();
          }
          if (aSort < bSort) {
            return -1 * sort_order;
          } else if (aSort > bSort) {
            return sort_order;
          } else {
            return 0;
          }
        });
      }
      
      // return sorted and offset rows
      let result = [];
      if (props.isPagedTable === true) {
        for (let index = 0; index < props.rows.length; index++) {
          if (rows[index]) {
            result.push(rows[index]);
          }
        }
      }
      else 
      {
        for (let index = setting.offset - 1; index < setting.limit; index++) {
          if (rows[index]) {
            result.push(rows[index]);
          }
        }
      }
      return result;
    });
    
    ////////////////////////////
    //
    //  Checkbox 相關操作
    //  (Checkbox related operations)
    //
    // 定義Checkbox參照 (Define Checkbox reference)
    const rowCheckbox = ref([]);
    if (props.hasCheckbox) {
      /**
       * 重新渲染前執行 (Execute before re-rendering)
       */
      onBeforeUpdate(() => {
        // 每次更新前都把值全部清空 (Clear all values before each update)
        rowCheckbox.value = [];
      });

      /**
       * 監聽全勾選Checkbox (Check all checkboxes for monitoring)
       */

      watch(
        () => setting.isCheckAll,
        (state: boolean) => {
          let isChecked: Array<string> = [];
          rowCheckbox.value.forEach((val: HTMLInputElement) => {
            if (val) {
              val.checked = state;
              if (val.checked) {
                isChecked.push(val.value);
              }
            }
          });
          // 回傳畫面上選上的資料 (Return the selected data on the screen)
          emit("return-checked-rows", isChecked);
        }
      );
    }
    
    /**
     * Checkbox點擊事件 (Checkbox click event)
     */
    const checked = () => {
      let isChecked: Array<string> = [];
      rowCheckbox.value.forEach((val: HTMLInputElement) => {
        if (val && val.checked) {
          isChecked.push(val.value);
        }
      });
      // 回傳畫面上選上的資料 (Return the selected data on the screen)
      emit("return-checked-rows", isChecked);
    };

    /**
     * 清空畫面上所有選擇資料 (Clear all selected data on the screen)
     */
    const clearChecked = () => {
      rowCheckbox.value.forEach((val: HTMLInputElement) => {
        if (val && val.checked) {
          val.checked = false;
        }
      });
      // 回傳畫面上選上的資料 (Return the selected data on the screen)
      emit("return-checked-rows", []);
    };

    ////////////////////////////
    //
    //  排序·換頁等 相關操作
    //  (Sorting, page change, etc. related operations)
    //

    /**
     * 呼叫執行排序 (Call execution sequencing)
     */

    const doSort = (order: string) => {
      let sort = "asc";
      if (order == setting.order) {
        // 排序中的項目時 (When sorting items)
        if (setting.sort == "asc") {
          sort = "desc";
        }
      }
      let offset = (setting.page - 1) * setting.pageSize;
      let limit = setting.pageSize;
      setting.order = order;
      setting.sort = sort;
      emit("do-search", offset, limit, order, sort);

      // 清空畫面上選擇的資料 (Clear the selected data on the screen)
      if (setting.isCheckAll) {
        // 取消全選時自然會清空 (It will be cleared when you cancel all selections)
        setting.isCheckAll = false;
      } else {
        if (props.hasCheckbox) {
          clearChecked();
        }
      }
    };

    /**
     * 切換頁碼 (Switch page number)
     *
     * @param page      number  新頁碼    (New page number)
     * @param prevPage  number  現在頁碼  (Current page number)
     */
    const changePage = (page: number, prevPage: number) => {
      setting.isCheckAll = false;
      let order = setting.order;
      let sort = setting.sort;
      let offset = (page - 1) * setting.pageSize;
      let limit = setting.pageSize;
      if (!props.isReSearch || page > 1 || page == prevPage) {
        // 非重新查詢發生的頁碼變動才執行呼叫查詢 (Call query will only be executed if the page number is changed without re-query)
        emit("do-search", offset, limit, order, sort);
      }
    };
    // 監聽頁碼切換 (Monitor page switching)
    watch(() => setting.page, changePage);

    /**
     * 切換顯示筆數 (Switch display number)
     */
    const changePageSize = () => {
      if (setting.page === 1) {
        // 沒自動觸發 changePage()，所以手動觸發

        changePage(setting.page, setting.page);
      } else {
        // 強制返回第一頁,並自動觸發 changePage()
        setting.page = 1;
        setting.isCheckAll = false;
      }
    };
    // 監聽顯示筆數切換 (Monitor display number switch)
    watch(() => setting.pageSize, changePageSize);
    
    const prevPage = () => {
      if (setting.page == 1) {
        return false;
      }
      setting.page--;
      emit('on-page-change',setting.page);
    };
    
     //@ts-ignore
     const onPageSizeChange = (evt) => {
       setting.pageSize = evt.detail.new.size;
       emit('on-page-size-change',setting.pageSize);
    }
    
    const movePage = (page: number) => {
      setting.page = page;
    };
    
    const nextPage = () => {
      if (setting.page >= setting.maxPage) {
        return false;
      }
      setting.page++;
      emit('on-page-change',setting.page);
    };
    
    // 監聽資料變更 (Monitoring data changes)
    watch(
      () => props.rows,
      () => {
        if (props.isReSearch) {
          setting.page = 1;
        }
        nextTick(function () {
          // 資料完成渲染後回傳私有元件 (Return the private components after the data is rendered)
          let localElement = document.getElementsByClassName("is-rows-el");
          emit("is-finished", localElement);
        });
      }
    );
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const stringFormat = (template: string, ...args: any[]) => {
      return template.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != "undefined" ? args[number] : match;
      });
    };

    const getColumnClass = (col:column):string => {
      let align = (col.col.align === undefined) ? 'default' : col.col.align;
      if (typeof col.col.datatype === "object") {
        return Object.keys(col.col.datatype)[0]
      } else 
      return `datatype-${col.col.datatype} align-${align}`;
    }
    
    const pageSizeState = ref({
      showPageSize:false
    });

    if (props.hasCheckbox) {
      // 需要 Checkbox 時 (When Checkbox is needed)
      return {
        localRows,
        setting,
        rowCheckbox,
        pageSizeState,
        getColumnClass,
        checked,
        doSort,
        prevPage,
        movePage,
        nextPage,
        onPageSizeChange,
        stringFormat,
      };
    } else {
      return {
        localRows,
        setting,
        pageSizeState,
        getColumnClass,
        doSort,
        prevPage,
        movePage,
        nextPage,
        onPageSizeChange,
        stringFormat,
      };
    }
  },
});
