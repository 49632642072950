import { RouteRecordRaw } from 'vue-router';

import LfxTodo from '@/components/todo/LfxTodo.vue';
import LfxTodoModal from '@/components/todo/LfxTodoModal.vue';

import LfxEstimateView from '@/views/transactions/LfxEstimateView.vue'

import LfxDashboardList from '@/views/general/LfxDashboardList.vue';

import LfxTransactions from '@/views/transactions/LfxTransactions.vue';
import LfxTransactionFilesList from '@/views/transactions/LfxTransactionFilesList.vue';
import LfxDealList from '@/views/transactions/LfxDealList.vue';
import LfxPaymentList from '@/views/transactions/LfxPaymentList.vue';
import LfxFailedTreasuryDealsList from '@/views/transactions/LfxFailedTreasuryDealsList.vue';
import LfxFailedTreasuryDealItem from '@/views/transactions/LfxFailedTreasuryDealItem.vue';
import LfxSwiftList from '@/views/transactions/LfxSwiftList.vue';

import LfxTransaction from '@/views/transactions/LfxTransaction.vue';
import LfxTransactionDetails from '@/views/transactions/LfxTransactionDetails.vue';
import LfxMergeTransactions from '@/views/transactions/LfxMergeTransactions.vue';

import LfxTransactionDealDetails from '@/views/transactions/LfxTransactionDealDetails.vue';

import LfxFecModification from '@/views/transactions/LfxFecModification.vue';
import LfxFecModificationReview from '@/views/transactions/LfxFecModificationReview.vue';
import LfxFecModificationPartialDrawdownReview from '@/views/transactions/LfxFecModificationPartialDrawdownReview.vue'

import LfxDealRelease from '@/views/transactions/LfxDealRelease.vue';
import LfxDealupdateExternalReference from '@/views/transactions/LfxDealupdateExternalReference.vue'
import LfxDealupdateSettlementAccount from '@/views/transactions/LfxDealupdateSettlementAccount.vue';
import LfxDealModifyValueDate from '@/views/transactions/LfxDealModifyValueDate.vue';
import LfxFecDrawDown from '@/views/transactions/LfxFecDrawDown.vue';
import LfxDealReject from '@/views/transactions/LfxDealReject.vue';

import LfxSplitDealFromTransaction from '@/views/transactions/LfxSplitDealFromTransaction.vue';

import LfxTransactionPaymentDetails from '@/views/transactions/LfxTransactionPaymentDetails.vue';

import LfxManagePayments from '@/views/transactions/LfxManagePayments.vue';
import LfxPaymentEditAmount from '@/views/transactions/LfxPaymentEditAmount.vue';
import LfxCapturePayment from '@/views/transactions/LfxCapturePayment.vue';
import LfxPaymentUpdateStatus from '@/views/transactions/LfxPaymentUpdateStatus.vue';

import LfxPaymentAddDocument from '@/views/transactions/LfxPaymentAddDocument.vue';
import LfxPaymentRecall from '@/views/transactions/LfxPaymentRecall.vue';
import LfxPaymentPortfolioSummary from '@/views/transactions/LfxPaymentPortfolioSummary.vue';
import LfxPaymentPortfolioHistoryList from '@/views/transactions/LfxPaymentPortfolioHistoryList.vue';
import LfxPaymentPortfolioLogList from '@/views/transactions/LfxPaymentPortfolioLogList.vue';
import LfxPaymentPortfolioLogItem from '@/views/transactions/LfxPaymentPortfolioLogItem.vue'

import LfxTransactionDealMarkups from '@/views/transactions/LfxTransactionDealMarkups.vue';

import LfxTransactionDealPayments from '@/views/transactions/LfxTransactionDealPayments.vue';

import LfxTransactionLogList from '@/views/transactions/LfxTransactionLogList.vue';
import LfxDealLogList from '@/views/transactions/LfxDealLogList.vue';
import LdbEmailLogList from '@/views/users/LdbEmailLogList.vue'

import LfxSwift from '@/views/transactions/LfxSwift.vue';
import LfxSwiftDetails from '@/views/transactions/LfxSwiftDetails.vue';
import LfxSwiftLogList from '@/views/transactions/LfxSwiftLogList.vue';
import LfxSwiftAssignToClient from '@/views/transactions/LfxSwiftAssignToClient.vue'
import LfxSwiftAssignToBankAccount from '@/views/transactions/LfxSwiftAssignToBankAccount.vue'

import LfxNewTransactionPicker from '@/views/transactions/LfxNewTransactionPicker.vue';

// ----- New Transaction -----

import LfxNewTransaction from '@/views/transactions/LfxNewTransaction.vue';
import LfxAddTransaction from '@/views/transactions/LfxAddTransaction.vue';

import LfxNewOfflineDealDetails from '@/views/transactions/LfxNewOfflineDealDetails.vue';
import LfxNewDeal from '@/views/transactions/LfxNewDeal.vue';
import LfxNewPayment from '@/views/transactions/LfxNewPayment.vue';

import LfxBankAccountBalance from '@/views/bankAccounts/LfxBankAccountBalance.vue';
import LfxAccountOpenDealLimitEdit from '@/views/accounts/LfxAccountOpenDealLimitEdit.vue';
import LfxAccountUpdateMarkupForNewDeal from '@/views/accounts/LfxAccountUpdateMarkupForNewDeal.vue'
import LfxFecAdditionalMarkupsUpdate from '@/views/accounts/LfxFecAdditionalMarkupsUpdate.vue';

const routes: RouteRecordRaw[] = [
  //
  // ----- Live Views -----

  {
    path: '/estimate',
    name: 'estimate',
    component: LfxEstimateView
  },
  {
    path: '/openDeals',
    name: '_openDeals',
    component: LfxDashboardList,
    meta: { icon: 'product/transact-grey', label: 'Open Deals', content: { table: 'LfxDeal', type: 'openDeals' } },
  },
  {
    path: '/unreleasedDeals',
    name: '_unreleasedDeals',
    component: LfxDashboardList,
    meta: {
      icon: 'product/transact-grey',
      label: 'Unreleased Deals',
      content: { table: 'LfxDeal', type: 'unreleasedDeals' },
    },
  },
  {
    path: '/unreleasedFecs',
    name: '_unreleasedFecs',
    component: LfxDashboardList,
    meta: {
      icon: 'product/transact-grey',
      label: 'Unreleased FECs',
      content: { table: 'LfxDeal', type: 'unreleasedFecs' },
    },
  },
  {
    path: '/unreleasedFecModifications',
    name: '_unreleasedFecModifications',
    component: LfxDashboardList,
    meta: {
      icon: 'product/transact-grey',
      label: 'Unreleased FEC Modification',
      content: { table: 'LfxDeal', type: 'unreleasedFecModifications' },
    },
  },
  {
    path: '/readyForDrawdownFec',
    name: '_readyForDrawdownFec',
    component: LfxDashboardList,
    meta: {
      icon: 'product/transact-grey',
      label: 'FEC Ready for Drawdown',
      content: { table: 'LfxDeal', type: 'readyForDrawdownFec' },
    },
  },
  {
    path: '/openPayments',
    name: '_openPayments',
    component: LfxDashboardList,
    meta: {
      icon: 'product/transact-grey',
      label: 'Open Payments',
      content: { table: 'LfxPayment', type: 'openPayments' },
    },
  },
  {
    path: '/paymentsRequireAttention',
    name: '_paymentsRequireAttention',
    component: LfxDashboardList,
    meta: {
      icon: 'product/transact-grey',
      label: 'Payments Require Attention',
      content: { table: 'LfxPayment', type: 'paymentsRequireAttention' },
    },
  },
  {
    path: '/sentBackPayments',
    name: '_sentBackPayments',
    component: LfxDashboardList,
    meta: {
      icon: 'product/transact-grey',
      label: 'Sent Back Payments',
      content: { table: 'LfxPayment', type: 'sentBackPayments' },
    },
  },

  // ----- Transactions -----

  {
    path: '/transactions',
    name: 'transactions',
    component: LfxTransactions,
    meta: { label: 'Transactions', icon: 'product/transact-grey' },
    children: [
      {
        name: 'transactionsTransactionsList',
        path: 'transaction_files',
        component: LfxTransactionFilesList,
        meta: { content: { tabName: 'transactionFiles', label: 'Transaction Files', actionGroup: 'transactions' } },
      },
      {
        // TODO move to children of 'transactionsTransactionsList'
        name: 'transactionsNewTransaction',
        path: 'new_transaction',
        components: { default: LfxTransactionFilesList, modal: LfxNewTransactionPicker },
      },
      {
        name: 'transactionsDealsList',
        path: 'deals',
        component: LfxDealList,
        meta: { content: { tabName: 'deals', label: 'Deals', actionGroup: 'deals' } },
      },
      {
        name: 'transactionsPaymentsList',
        path: 'payments',
        component: LfxPaymentList,
        meta: { content: { tabName: 'payments', label: 'Payments', actionGroup: 'payments' } },
      },
      {
        name: 'tranasactionsSwiftsList',
        path: 'inward_swifts',
        component: LfxSwiftList,
        meta: { content: { tabName: 'swifts', label: 'SWIFTs', actionGroup: 'swifts' } },
      },
      {
        name: 'transactionsFailedTreasuryDealsList',
        path: 'failedTreasuryDeals',
        component: LfxFailedTreasuryDealsList,
        meta: { content: { tabName: 'failedTreasuryDeals', label: 'Failed Treasury Deals', actionGroup: 'failedTreasuryDeals' } },
        children: [
          {
            name: 'transactionsFailedTreasuryDealsItem',
            path: ':failedTreasuryDealId(\\d+)',
            component: LfxFailedTreasuryDealItem
          }
        ]
      },
    ],
  },

  // ----- Transaction -----

  {
    path: '/account/:accountId(\\d+)/transaction/:transactionId(\\d+)',
    name: 'transaction',
    component: LfxTransaction,
    meta: { icon: 'product/transact-grey' },
    children: [
      //
      // ----- Summary

      {
        path: 'summary',
        name: 'transactionSummaryView',
        component: LfxTransactionDetails,
        meta: {
          content: { tabName: 'summary', label: 'Transaction File Summary', actionGroup: 'transactionSummaryView' },
        },
        children: [
          { path: 'merge', name: 'transactionSummaryViewMerge', component: LfxMergeTransactions },
          {
            path: 'new_transaction_picker',
            name: 'transactionSummaryViewNewTransaction',
            component: LfxNewTransactionPicker,
          },
        ],
      },

      // ----- Inward SWIFT

      {
        path: 'inward_swift/:swiftId(\\d+)',
        name: 'transactionInwardSwiftDetailsView',
        component: LfxSwiftDetails,
        meta: {
          content: {
            tabName: 'inwardSwift',
            label: 'Inward SWIFT Details',
            actionGroup: 'transactionInwardSwiftDetailsView',
          },
        },
      },

      // ----- FECs

      {
        // TODO
        name: 'transactionForwardDetailsView',
        path: 'forward/:dealId(\\d+)',
        component: LfxTransactionDealDetails,
        meta: { content: { tabName: 'forward', label: 'Forward', actionGroup: 'transactionForwardDetailsView' } },
        children: [
          // TODO add routes:
          // fec modification
          // fec modification review
          // release deal
          // modify deal
          // reject deal
        ],
      },

      // ----- Deals

      // Deals list

      {
        path: 'deals',
        name: 'transactionDealsList',
        component: LfxDealList,
        meta: {
          content: { tabName: 'deals', label: 'Transaction File Deals List', actionGroup: 'transactionDealsList' },
        },
      },

      // Deal(s) view

      {
        path: 'deal/:dealId(\\d+)',
        name: 'transactionDealDetailsView',
        component: LfxTransactionDealDetails,
        meta: { content: { tabName: 'deals', label: 'Deal Details', actionGroup: 'transactionDealDetailsView' } },
        children: [
          { name: 'transactionDealDetailsViewSplit', path: 'split', component: LfxSplitDealFromTransaction },
          { name: 'releaseDeal', path: 'releaseDeal', component: LfxDealRelease },
          { name: 'updateDealExternalReference', path: 'updateDealExternalReference', component: LfxDealupdateExternalReference },
          { name: 'updateDealSettlementAccount', path: 'updateDealSettlementAccount', component: LfxDealupdateSettlementAccount },
          { name: 'modifyValueDate', path: 'modifyValueDate', component: LfxDealModifyValueDate },
          { name: 'rejectDeal', path: 'rejectDeal', component: LfxDealReject },
          { name: 'modifyFec', path: 'fecModification', component: LfxFecModification },
          { name: 'reviewFec', path: 'fecModificationReview', component: LfxFecModificationReview },
          { name: 'reviewFecPartialDrawdown', path: 'fecModificationPartialDrawdownReview', component: LfxFecModificationPartialDrawdownReview },
          { name: 'fecDrawDown', path: 'fecDrawDown', component: LfxFecDrawDown},
          { name: 'dealLogs', path: 'logs', component: LfxDealLogList },
          { name: 'dealEmailLogs', path: 'emailLogs', component: LdbEmailLogList, meta: {listType: 'deal'} },
        ],
      },

      // ----- Payments

      // Payments list

      {
        name: 'transactionPaymentsList',
        path: 'payments',
        component: LfxTodo, // TODO
        meta: {
          content: {
            tabName: 'paymentsList',
            label: 'Transaction File Payments List',
            actionGroup: 'transactionPaymentsList',
          },
        },
      },

      // Payment(s) view

      {
        name: 'transactionPaymentRedirectToPaymentCapture',
        path: 'redirect_to_payment_capture/:paymentId(\\d+)',
        redirect: { name: 'transactionPaymentDetailsView' },
      },

      {
        name: 'transactionPaymentDetailsView',
        path: 'payment/:paymentId(\\d+)',
        component: LfxTransactionPaymentDetails,
        meta: {
          content: { tabName: 'payments', label: 'Payment Details', actionGroup: 'transactionPaymentDetailsView' },
        },
        children: [
          { name: 'editTransactionFilePaymentAmount', path: 'editAmount', component: LfxPaymentEditAmount },
          { name: 'transactionPaymentDetailsViewCapture', path: 'capture', component: LfxCapturePayment },
          { name: 'transactionPaymentSubmitPortfolio', path: 'submit', component: LfxCapturePayment },
          { name: 'transactionPaymentBankUpdateStatus', path: 'updateStatus', component: LfxPaymentUpdateStatus },

          { name: 'transactionPaymentDetailsViewAddPayment', path: 'add_payment', component: LfxManagePayments },
          { name: 'transactionPaymentDetailsViewEditAmount', path: 'edit_amount', component: LfxManagePayments },
          { name: 'transactionPaymentDetailsViewCancelPayment', path: 'cancel_payment', component: LfxManagePayments },

          { name: 'transactionPaymentAddDocument', path: 'addDocument', component: LfxPaymentAddDocument },
          { name: 'transactionPaymentRecallPortfolio', path: 'recall', component: LfxPaymentRecall },
          { name: 'transactionPaymentPortfolioSummary', path: 'summary', component: LfxPaymentPortfolioSummary },

          { name: 'paymentPortfolioHistory', path: 'history', component: LfxPaymentPortfolioHistoryList },
          { name: 'paymentEmailLogs', path: 'emailLogs', component: LdbEmailLogList, meta: {listType: 'payment'} },
          // {
          //   name: 'paymentPortfolioHistory',
          //   path: 'history',
          //   components: { default: LfxPaymentPortfolioHistoryList },
          //   meta: { content: { tabName: 'logs', label: 'Portfolio History', actionGroup: 'portfolioHistory' } },
          // },
          {
            name: 'paymentMessageLogs',
            path: 'messageLog',
            component:LfxPaymentPortfolioLogList,
          },
          {
            name: 'paymentMessageLogItem',
            path: 'messageLog/:messageId',
            component:LfxPaymentPortfolioLogItem,
          },
        ],
      },

      // ----- Markups

      {
        name: 'transactionMarkups',
        path: 'markups',
        component: LfxTransactionDealMarkups,
        meta: { content: { tabName: 'markups', label: 'Markup Details', actionGroup: 'transactionMarkups' } },
      },

      // ----- DealPayments

      {
        name: 'dealPayments',
        path: 'dealPayments',
        component: LfxTransactionDealPayments,
        meta: { content: { tabName: 'dealPayments', label: 'Many to Many', actionGroup: 'dealPayments' } },
      },

      // ----- Logs

      {
        path: 'logs',
        name: 'transactionLogs',
        components: { default: LfxTransactionLogList },
        meta: { content: { tabName: 'logs', label: 'Transaction File Logs', actionGroup: 'transactionLogs' } },
      },
    ],
  },

  // ----- Transaction - Add Deal

  // {
  //   // TODO move to own content, i.e. not modal
  //   name: '_addDealToTransactionFile',
  //   path: 'add_deal',
  //   component: LfxAddTransaction,
  //   children: [
  //     {
  //       path: 'capture',
  //       name: 'addDealToTransactionFileCapture',
  //       component: LfxNewDeal,
  //       meta: {
  //         modal: {
  //           stepName: 'captureAddDeal',
  //           label: 'Capture Deal Details',
  //           view: 'create',
  //           transactionType: 'addDeal',
  //         },
  //       },
  //     },
  //     {
  //       path: 'review',
  //       name: 'addDealToTransactionFileReview',
  //       component: LfxNewDeal,
  //       meta: {
  //         modal: {
  //           stepName: 'reviewAddDeal',
  //           label: 'Review Deal Details',
  //           transactionType: 'addDeal',
  //         },
  //       },
  //     },
  //   ],
  // },

  {
    name: '_existingTransaction',
    path: '/account/:accountId(\\d+)/transaction/:transactionId(\\d+)/add_deal',
    component: LfxNewTransaction,
    meta: { icon: 'product/transact-grey', titleBar: { label: 'Add Deal' } },
    children: [
      {
        name: 'addDealToTransactionFileCapture',
        path: 'capture',
        component: LfxNewDeal,
        meta: {
          content: {
            stepName: 'captureAddDeal',
            label: 'Capture Deal Details',
            view: 'create',
            transactionType: 'addDeal',
            actionGroup: 'dealCapture',
          },
        },
        children: [
          {
            name: 'newTransactionAddDealCaptureAccountBalance',
            path: 'bank_account/:bankAccountId(\\d+)/balance',
            component: LfxBankAccountBalance,
          },
          {
            path: 'edit_markup/:currencyPairId(\\d+)/',
            name: 'addDealToTransactionFileUpdateMarkup',
            component: LfxAccountUpdateMarkupForNewDeal,
          },
          {
            path: 'editFecMarkup/',
            name: 'addDealToTransactionFileUpdateFecMarkup',
            component: LfxFecAdditionalMarkupsUpdate,
          },
        ],
      },

      {
        name: 'addDealToTransactionFileReview',
        path: 'review',
        component: LfxNewDeal,
        meta: {
          content: {
            stepName: 'reviewAddDeal',
            label: 'Review Deal Details',
            transactionType: 'addDeal',
            actionGroup: 'dealReview',
          },
        },
        children: [
          {
            name: 'addDealToTransactionFileReviewEditLimit',
            path: 'edit_limit',
            component: LfxAccountOpenDealLimitEdit,
          },
        ],
      },
    ],
  },

  // ----- New Transaction -----

  {
    path: '/account/:accountId(\\d+)/new_transaction',
    name: '_newTransaction',
    component: LfxNewTransaction,
    meta: { icon: 'product/transact-grey' },
    children: [
      //
      // ----- Deals

      //  Offline Deal

      {
        path: 'offlineDeal/capture',
        name: 'newTransactionOfflineDealCapture',
        component: LfxNewOfflineDealDetails,
        meta: {
          titleBar: { label: 'New Offline Deal' },
          content: {
            stepName: 'captureOfflineDeal',
            label: 'Capture Deal Details',
            formButtons: { createButtonLabel: 'Review' },
            transactionType: 'offlineDeal',
          },
        },
      },
      {
        path: 'offlineDeal/review',
        name: 'newTransactionOfflineDealReview',
        component: LfxNewOfflineDealDetails,
        meta: {
          stepName: 'reviewOfflineDeal',
          titleBar: { label: 'New Offline Deal' },
          content: { stepName: 'reviewOfflineDeal', label: 'Review Deal Details', transactionType: 'offlineDeal' },
        },
      },

      //  Inward Deal

      {
        path: 'inward_deal/capture',
        name: 'newTransactionInwardDealCapture',
        component: LfxNewDeal,
        meta: {
          titleBar: { label: 'New Inward Deal' },
          content: {
            stepName: 'captureInwardDeal',
            label: 'Capture Deal Details',
            view: 'create',
            transactionType: 'inwardDeal',
            actionGroup: 'dealCapture',
          },
        },
        children: [
          {
            path: 'bank_account/:bankAccountId(\\d+)/balance',
            name: 'newTransactionInwardDealCaptureAccountBalance',
            component: LfxBankAccountBalance,
          },
          {
            path: 'edit_markup/:currencyPairId(\\d+)/',
            name: 'newTransactionInwardDealUpdateMarkup',
            component: LfxAccountUpdateMarkupForNewDeal,
          },
          {
            path: 'editFecMarkup/',
            name: 'newTransactionInwardDealUpdateFecMarkup',
            component: LfxFecAdditionalMarkupsUpdate,
          },
        ],
      },

      {
        path: 'inward_deal/review',
        name: 'newTransactionInwardDealReview',
        component: LfxNewDeal,
        meta: {
          titleBar: { label: 'New Inward Deal' },
          content: {
            stepName: 'reviewInwardDeal',
            label: 'Review Deal Details',
            transactionType: 'inwardDeal',
            actionGroup: 'dealReview',
          },
        },
        children: [
          {
            path: 'edit_limit',
            name: 'newTransactionInwardDealReviewEditLimit',
            component: LfxAccountOpenDealLimitEdit,
          },
        ],
      },

      //  Transfer Deal

      {
        path: 'transfer_deal/capture',
        name: 'newTransactionTransferDealCapture',
        component: LfxNewDeal,
        meta: {
          titleBar: { label: 'New Transfer Deal' },
          content: {
            stepName: 'captureTransferDeal',
            label: 'Capture Deal Details',
            view: 'create',
            transactionType: 'transferDeal',
            actionGroup: 'dealCapture',
          },
        },
        children: [
          {
            path: 'bank_account/:bankAccountId(\\d+)/balance',
            name: 'newTransactionTransferDealCaptureAccountBalance',
            component: LfxBankAccountBalance,
          },
          {
            path: 'edit_markup/:currencyPairId(\\d+)/',
            name: 'newTransactionTransferDealUpdateMarkup',
            component: LfxAccountUpdateMarkupForNewDeal,
          },
          {
            path: 'editFecMarkup/',
            name: 'newTransactionTransferDealUpdateFecMarkup',
            component: LfxFecAdditionalMarkupsUpdate,
          },
        ],
      },

      {
        path: 'transfer_deal/review',
        name: 'newTransactionTransferDealReview',
        component: LfxNewDeal,
        meta: {
          titleBar: { label: 'New Transfer Deal' },
          content: {
            stepName: 'reviewTransferDeal',
            label: 'Review Deal Details',
            transactionType: 'transferDeal',
            actionGroup: 'dealReview',
          },
        },
        children: [
          {
            path: 'edit_limit',
            name: 'newTransactionTransferDealReviewEditLimit',
            component: LfxAccountOpenDealLimitEdit,
          },
        ],
      },

      // Outward Deal

      {
        path: 'outward_deal/capture',
        name: 'newTransactionOutwardDealCapture',
        component: LfxNewDeal,
        meta: {
          titleBar: { label: 'New Outward Deal' },
          content: {
            stepName: 'captureOutwardDeal',
            label: 'Capture Deal Details',
            view: 'create',
            transactionType: 'outwardDeal',
            actionGroup: 'dealCapture',
          },
        },
        children: [
          {
            path: 'bank_account/:bankAccountId(\\d+)/balance',
            name: 'newTransactionOutwardDealCaptureAccountBalance',
            component: LfxBankAccountBalance,
          },
          {
            path: 'edit_markup/:currencyPairId(\\d+)/',
            name: 'newTransactionOutwardDealUpdateMarkup',
            component: LfxAccountUpdateMarkupForNewDeal,
          },
          {
            path: 'editFecMarkup/',
            name: 'newTransactionOutwardDealUpdateFecMarkup',
            component: LfxFecAdditionalMarkupsUpdate,
          },
        ],
      },

      {
        path: 'outward_deal/review',
        name: 'newTransactionOutwardDealReview',
        component: LfxNewDeal,
        meta: {
          titleBar: { label: 'New Outward Deal' },
          content: {
            stepName: 'reviewOutwardDeal',
            label: 'Review Deal Details',
            transactionType: 'outwardDeal',
            actionGroup: 'dealReview',
          },
        },
        children: [
          {
            path: 'edit_limit',
            name: 'newTransactionOutwardDealReviewEditLimit',
            component: LfxAccountOpenDealLimitEdit,
          },
        ],
      },
    ],
  },

  // ----- Inward SWIFT -----

  {
    path: '/account/:accountId(\\d+)/swift/:swiftId(\\d+)',
    name: 'swift',
    component: LfxSwift,
    meta: { icon: 'product/transact-grey' },
    children: [
      //
      // View SWIFT

      {
        path: 'details',
        name: 'swiftDetails',
        component: LfxSwiftDetails,
        meta: { content: { tabName: 'swift', label: 'SWIFT Details', actionGroup: 'swiftDetails' } },
        children: [
          {
            path: 'payment/capture',
            name: 'swiftPaymentCapture',
            component: LfxNewPayment,
            meta: { modal: { transactionType: 'inwardPayment' } },
          },
          {
            path: 'asignToClient',
            name: 'assignSwiftToClient',
            component: LfxSwiftAssignToClient,
            meta: {  },
          },
          {
            path: 'asignToBankAccount',
            name: 'assignSwiftToBankAccount',
            component: LfxSwiftAssignToBankAccount,
            meta: {  },
          },
        ],
      },
      {
        path: 'transaction/:transactionId(\\d+)/payment/:paymentId(\\d+)',
        name: 'linkedPaymentDetailsView',
        component: LfxTransactionPaymentDetails,
        meta: {
          content: { tabName: 'linkedPayment', label: 'Linked Payment', actionGroup: 'linkedPaymentDetailsView' },
        },
      },
      {
        path: 'swiftLogs',
        name: 'swiftLogs',
        component: LfxSwiftLogList,
        meta: { content: { tabName: 'logs', label: 'Logs', actionGroup: 'swiftLogs' } },
      },

      // Book deal from SWIFT

      {
        path: 'deal/capture',
        name: 'swiftDealCapture',
        component: LfxNewDeal,
        meta: {
          titleBar: { label: 'Book Deal' },
          content: {
            showTabs: false,
            stepName: 'swiftDealCapture',
            label: 'Capture Deal Details',
            view: 'create',
            transactionType: 'inwardDeal',
          },
        },
      },
      {
        path: 'deal/review',
        name: 'swiftDealReview',
        component: LfxNewDeal,
        meta: {
          titleBar: { label: 'Book Deal' },
          content: {
            showTabs: false,
            stepName: 'swiftDealReview',
            label: 'Review Deal Details',
            transactionType: 'inwardDeal',
          },
        },
      },
    ],
  },
];

export default routes;
