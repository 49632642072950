<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <ol>
        <li>This will mark the account as active on the Forex system.</li>
        <li>This will not affect the account status in Core - please ensure that the account is open in Core first.</li>
      </ol>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in buttons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

export default {
  name: 'LfxReopenBankAccount',
  components: {},
  props: {},
  emits: {},
  created() {},
  mounted() {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    // ----- Account record -----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },

    // ----- Markup record ----

    bankAccountId() {
      return +this.$route.params?.bankAccountId;
    },
    bankAccountRecord() {
      return this.accountRecord?.bankAccounts?.[this.bankAccountId];
    },

    header() {
      return `Re-open Bank Account: ${this.bankAccountRecord?.accountNumber}`;
    },
    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta?.modal?.cancelRoute : 'todo';
      //TODO: go to user's home as fallback
    },
    buttons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelReopenBankAccount',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'reopenBankAccount',
          label: `Reopen Bank Account`,
          type: 'primary',
          guards: this.bankAccountRecord.accountBankAccountReopen_buttonGuard,
          clickEvent() {
            self.reopenBankAccount();
          },
        },
      ];
      return allButtons.filter((button) => button.guards);
    },
  },
  methods: {
    async reopenBankAccount() {
      let response = await this.bankAccountRecord.reopenBankAccount();
      if (response.status === 'success') {
        this.$router.replace({ name: 'accountBankAccountView' });
      }
    },
  },
};
</script>
