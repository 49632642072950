<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <ul>
        <li>This information has been retrieved from the SigniFlow System.</li>
      </ul>
      <div v-if="portfolioSummaryRetrievedStatus === 'retrieved'">
        <h3>Overview</h3>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="item"
          :fieldname="field.name"
          :record="portfolioSummaryData"
        />
      </div>
      <div v-else-if="portfolioSummaryRetrievedStatus === 'error'">
        <br />
        Failed to Load Portfolio Summary.
        <br />
        {{ portfolioSummaryData.errorMessage }}
      </div>
      <template v-else>
        <br />
        Loading Portfolio Summary from SigniFlow - This may take some time
        <LDBLoader />
      </template>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import LDBField from '@/landobyte_vue/LDBField.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxClientPortfolioSummary',
  components: { LDBField, LDBLoader },
  props: {},
  data() {
    return {
      portfolioSummaryRetrievedStatus: 'none',
      portfolioSummaryData: {},
    };
  },
  created() {
    this.portfolioSummaryData.integrationId = this.applicationRecord.portfolioIntegrationId;
    this.retrievePortfolioSummaryData();
  },
  computed: {
    ...mapGetters(['permissions', 'config']),
    ...mapState(['account']),
    header() {
      return 'Portfolio Summary';
    },
    portfolioIsEditable() {
      return ['In Progress', 'Ready for Signing'].includes(this.portfolioSummaryData.status);
    },
    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          action: 'click',
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
      ];
      return allButtons;
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : 'todo';
    },

    // ----- Definition -----

    // ----- Account Record -----

    clientId() {
      return +this.$route.params.accountId;
    },
    clientRecord() {
      return this.account[this.clientId];
    },

    // ----- Application Record -----

    applicationId() {
      return +this.$route.params.applicationId;
    },
    applicationRecord() {
      return this.clientRecord?.application?.[this.applicationId];
    },
    // ----- Fields -----

    fields() {
      return this.newRecord.guarded_fields;
    },

    viewFields() {
      let res = [
        {
          label: 'Integration ID',
          name: 'integrationId',
          datatype: 'text',
        },
        {
          label: 'Transaction Type',
          name: 'transactionType',
          datatype: 'text',
        },
        {
          label: 'Status',
          name: 'status',
          datatype: 'text',
        },
        {
          label: 'Status Description',
          name: 'statusDescription',
          datatype: 'text',
        },
        {
          label: 'Status Message',
          name: 'statusMessage',
          datatype: 'text',
        },
        {
          label: 'Created Date',
          name: 'createdDate',
          datatype: 'text',
        },
        {
          label: 'Process Start Date',
          name: 'processStartDate',
          datatype: 'text',
        },
        {
          label: 'Process End Date',
          name: 'processEndDate',
          datatype: 'text',
        },
      ];
      return res;
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),

    async retrievePortfolioSummaryData() {
      this.portfolioSummaryRetrievedStatus = 'retrieving';
      const portfolioSummaryDataResult = await doPermissionAction(
        { onSuccess: { doStoreUpdate: false } },
        'getClientPortfolioStatus',
        { accountId: this.clientId, applicationId: this.applicationId }
      );
      if (portfolioSummaryDataResult.status === 'success') {
        if (portfolioSummaryDataResult.response.status === 'success') {
          this.portfolioSummaryData.transactionType = portfolioSummaryDataResult.response.payload.transactionType;
          this.portfolioSummaryData.status = portfolioSummaryDataResult.response.payload.status;
          this.portfolioSummaryData.statusDescription = portfolioSummaryDataResult.response.payload.statusDescription;
          this.portfolioSummaryData.statusMessage = portfolioSummaryDataResult.response.payload.statusMessage;
          this.portfolioSummaryData.createdDate = portfolioSummaryDataResult.response.payload.createdDate;
          this.portfolioSummaryData.processStartDate = portfolioSummaryDataResult.response.payload.processStartDate;
          this.portfolioSummaryData.processEndDate = portfolioSummaryDataResult.response.payload.processEndDate;
          this.portfolioSummaryRetrievedStatus = 'retrieved';
        } else {
          this.portfolioSummaryData.errorMessage = portfolioSummaryDataResult.response.error.message;
          this.portfolioSummaryRetrievedStatus = 'error';
        }
      } else {
        this.portfolioSummaryData.errorMessage = portfolioSummaryDataResult.message;
        this.portfolioSummaryRetrievedStatus = 'error';
      }
    },
    // ----- Fields -----

    // ----- API calls -----
  },
};
</script>

<style>
.lfx-portfolio-summary-table-container {
  position: relative;
  min-height: 200px;
}

#lfx-portfolio-summary-document-table {
  min-width: 100%;
}

#lfx-portfolio-summary-signatory-table {
  min-width: 100%;
}
</style>
