<template>
  <div class="signup">
    <h1>Signup</h1>
  </div>
  <form @submit.prevent="submitLogin">
    <p>LDB AccountID: {{ ldbAccountId }}</p>
    <div>
      <label for="username">Username: </label>
      <input id="username" type="text" v-model="username" />
    </div>
    <div>
      <label for="password">Password: </label>
      <input id="password" type="password" v-model="password" />
    </div>
    <div>
      <label for="email-address">Email Address: </label>
      <input id="email-address" type="email" v-model="emailAddress" />
    </div>
    <div>
      <label for="msisdn">Cellphone Number: </label>
      <input id="msisdn" type="tel" v-model="msisdn" />
    </div>
    <div>
      <label for="first-name">First Name: </label>
      <input id="first-name" type="text" v-model="firstName" />
    </div>
    <div>
      <label for="surname">Surname: </label>
      <input id="surname" type="text" v-model="surname" />
    </div>
    <div>
      <button>Signup</button>
    </div>
    <p>Test2</p>
    <p>Test2</p>
    <!-- <div>
      <p>LFX AccountID: {{ lfxAccountID }}</p>
      <p>Username: {{ username }}</p>
      <p>Password: {{ password }}</p>
      <p>Email Address: {{ emailAddress }}</p>
      <p>Cellphone Number: {{ msisdn }}</p>
      <p>First Name: {{ firstName }}</p>
      <p>Surname: {{ surname }}</p>
      <p>Sending Form Count: {{ sendingFormCount }}</p>
    </div> -->
  </form>
  <p>{{ errorMessage }}</p>
</template>

<script>
export default {
  data() {
    return {
      ldbAccountId: 1,
      username: 'Yeti',
      password: 'password',
      emailAddress: 'bevan@landobyte.com',
      msisdn: '+27832165576',
      firstName: 'Bevan',
      surname: 'Timm',
      errorMessage: '',
    };
  },
  methods: {
    async submitLogin() {
      const formData = {
        ldbAccountId: this.ldbAccountId,
        username: this.username,
        password: this.password,
        emailAddress: this.emailAddress,
        msisdn: this.msisdn,
        firstName: this.firstName,
        surname: this.surname,
      };
      const responseData = await this.$store.dispatch('submitSignup', formData);
      if (responseData.userCreated) {
        this.$router.push({
          name: 'Confirm Signup',
        });
      } else {
        this.errorMessage = responseData;
      }
      // continue here - check responseData.userCreated then route push to confirmation    <---------
    },
  },
};
</script>
