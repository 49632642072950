//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxApplicationAccountDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a client Account Application for the LandoFX system
//  Created     : 30 August 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import { intermediaryFieldViewGuard } from '../sharedUtils/LdbDefinitionUtils';
import { standardTextWhiteList } from '../sharedUtils/LdbValidations';
import type { LdbDefinition, LdbViewFunction } from './LdbInterfaces';

import type { LfxApplicationIntegrationStatus, LfxApplicationRequiredFieldSet } from './LfxApplicationDef';

//----------------------------------------------------------------------------------------------------------------------

export type LfxApplicationAccountStatus = 'new' | 'inProgress' | 'error' | 'completed' | 'cancelled' | 'rejected';
export type LfxApplicationAccountInterestPaymentType = 'thisAccount' | 'otherCapitecAccount' | 'otherBankAccount';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxApplicationAccountInt {
  id?: number;
  uuid?: string;
  applicationId?: number;
  accountId?: number;
  intermediaryId?: number;
  bankAccountId?: number;
  reference?: string;
  accountNumber?: string;
  status?: LfxApplicationAccountStatus;

  currencyId?: string;

  interestPaymentType?: LfxApplicationAccountInterestPaymentType;
  interestPaymentBranch?: string;
  interestPaymentAccount?: string;
  intermediaryCommissionId?: number;
  intermediaryCommissionAccount?: string;
  thresholdAmountCredit?: number;
  thresholdAmountDebit?: number;
  isResidentialAccount?: boolean;

  purposeOfAccountDropdown?: string;
  purposeOfAccount?: string;
  exchangeControlInfo?: string;
  fCYAccountUtilisastionDetails?: string;
  primaryUse?: string;

  requiredFieldSet?: LfxApplicationRequiredFieldSet;

  integrationId?: string;
  integrationStatus?: LfxApplicationIntegrationStatus;
  submissionCount?: number;
  firstSubmission?: Date;
  requestedAt?: Date;
  requestedBy?: number;
  releasedAt?: Date;
  releasedBy?: number;
  rejectedAt?: Date;
  rejectedBy?: number;
  rejectedReason?: string;

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

export const showResidentialAccount: LdbViewFunction = (viewFunctionObject) => {
  //@ts-expect-error
  const residentOptions = viewFunctionObject.record?.residentOptions;
  if (residentOptions && residentOptions.allowTrue && residentOptions.allowFalse) {
    return true;
  }
  return false;
};

export const LfxApplicationAccountDef: LdbDefinition = {
  title: 'Client Account Application',
  table: 'LfxApplicationAccount',
  collectionPath: '/account/{{accountId}}/application/{{applicationId}}/application_account/',
  view: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
  indexes: ['accountId', 'integrationId'],
  pagingType: 'backEnd',
  groups: {
    outstanding: { priority: 0, label: 'Outstanding' },
    system: { priority: 1, label: 'System' },
    details: { priority: 2, label: 'Details' },
    cfcFca: { priority: 3, label: 'CFC/FCA' },
    interest: { priority: 4, label: 'Interest and Commission' },
    preferences: { priority: 5, label: 'Preferences' },
    integration: { priority: 6, label: 'Integration' },
    other: { priority: 7, default: true, label: '' },
    changes: { priority: 8, label: 'Changes' },
  },
  fields: {
    id: {
      label: 'ID',
      datatype: { id: { descriptionField: 'name' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
      group: 'system',
    },
    uuid: {
      label: 'UUID',
      datatype: 'uuid',
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    applicationId: {
      label: 'Application',
      datatype: {
        foreignKey: {
          collection: 'fk_application_accounts',
          linkTable: 'LfxApplication',
          linkField: 'id',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    accountId: {
      label: 'Account',
      datatype: {
        foreignKey: {
          collection: 'fk_accounts',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    intermediaryId: {
      label: 'Intermediary',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediaries',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: intermediaryFieldViewGuard,
        list: intermediaryFieldViewGuard,
        delete: false,
        csv: false,
      },
      group: 'system',
    },
    bankAccountId: {
      label: 'Bank Account',
      datatype: {
        foreignKey: {
          collection: 'fk_bank_accounts',
          linkTable: 'LfxBankAccount',
          linkField: 'id',
          displayField: 'description',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },

    reference: {
      label: 'Reference',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },
    accountNumber: {
      label: 'Account Number',
      datatype: 'text',
      default: null,
      mandatory: false,
      validators: {
        isNumeric: { msg: 'Please use only numeric charaters' },
        len: { msg: 'Account Number should be 10 characters', args: [10, 10] },
      },
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false, search: true },
      group: 'details',
    },
    currencyId: {
      label: 'Currency',
      datatype: {
        foreignKey: {
          collection: 'fk_currencies',
          linkTable: 'LdbCurrency',
          linkField: 'id',
          displayField: 'id',
          type: 'text',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'details',
    },
    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'new', name: 'New', tagType: 'info' },
            { id: 'inProgress', name: 'In Progress', tagType: 'info' },
            { id: 'error', name: 'Error', tagType: 'error' },
            { id: 'completed', name: 'Completed', tagType: 'success' },
            { id: 'cancelled', name: 'Cancelled', tagType: undefined },
            { id: 'rejected', name: 'Rejected', tagType: 'error' },
          ],
        },
      },
      default: 'new',
      mandatory: true,
      views: { create: true, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },
    consolidatedStatus: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'new', name: 'New', tagType: 'info' },
            { id: 'informationOutstanding', name: 'Information Outstanding', tagType: 'warning' },
            { id: 'readyToSubmit', name: 'Ready to Submit', tagType: 'success' },
            { id: 'inProgress', name: 'In Progress', tagType: 'info' },
            { id: 'sent', name: 'Sent', tagType: 'info' },
            { id: 'active', name: 'Active', tagType: 'success' },
            { id: 'inactive', name: 'Inactive', tagType: undefined },
            { id: 'pendingReview', name: 'Pending Review', tagType: 'warning' },
            { id: 'error', name: 'Error', tagType: 'error' },
            { id: 'rejected', name: 'Rejected', tagType: 'error' },
            { id: 'error', name: 'Error', tagType: 'error' },
            { id: 'completed', name: 'Completed', tagType: 'success' },
            { id: 'cancelled', name: 'Cancelled', tagType: undefined },
            { id: 'rejected', name: 'Rejected', tagType: 'error' },

            { id: 'awaitingPortfolio', name: 'Awaiting Portfolio', tagType: 'info' },
            { id: 'cifExists', name: 'CIF Exists', tagType: 'warning'},
          ],
        },
      },
      property: { read: true, write: false, source: 'frontend', sort: 'status' },
      default: 'new',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },
    OutstandingFields: {
      label: 'Outstanding Information',
      datatype: 'memo',
      search: false,
      property: { source: 'frontend', read: true, write: false, sort: 'none' },
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'outstanding',
    },

    interestPaymentType: {
      label: 'Interest Payment',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'thisAccount', name: 'This Account', tagType: 'info' },
            { id: 'otherCapitecAccount', name: 'Other Business Bank Account', tagType: 'info' },
            { id: 'otherBankAccount', name: 'Account at Another Institution', tagType: 'info' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'interest',
    },
    interestPaymentBranch: {
      label: 'Interest Payment Branch',
      datatype: 'text',
      default: null,
      mandatory: false,
      guards: { interestPaymentType: { eq: 'otherBankAccount' } },
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Interest Payment Branch should be between 5 and 30 characters', args: [5, 30] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'interest',
    },
    interestPaymentAccount: {
      label: 'Interest Payment Account',
      datatype: 'text',
      default: null,
      mandatory: false,
      guards: { interestPaymentType: { in: ['otherBankAccount', 'otherCapitecAccount'] } },
      validators: {
        isNumeric: { msg: 'Please use only numeric charaters' },
        len: { msg: 'Interest Payment Account should be 10 characters', args: [10, 10] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'interest',
    },
    intermediaryCommissionId: {
      label: 'Intermediary Commission Level',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediary_commissions',
          linkTable: 'LfxBankAccountCommissionLevel',
          linkField: 'id',
          displayField: 'label',
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: null,
      mandatory: false,
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'interest',
    },
    intermediaryCommissionAccount: {
      label: 'Intermediary Commission Account',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'interest',
    },
    thresholdAmountCredit: {
      label: 'Threshold Amount - Credit',
      datatype: { currency: { symbol: 'ZAR' } },
      default: 0.01,
      mandatory: false,
      validators: {
        isNumeric: { msg: 'Income should be numeric' },
        min: { msg: 'Threshold Amount - Credit must be greater than 0', args: [0] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      enableGuards: false,
      group: 'preferences',
    },
    thresholdAmountDebit: {
      label: 'Threshold Amount - Debit',
      datatype: { currency: { symbol: 'ZAR' } },
      default: 0.01,
      mandatory: false,
      validators: {
        isNumeric: { msg: 'Income should be numeric' },
        min: { msg: 'Threshold Amount - Debit must be greater than 0', args: [0] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      enableGuards: false,
      group: 'preferences',
    },
    isResidentialAccount: {
      label: 'Residental Account?',
      datatype: {
        option: {
          optionType: 'boolean',
          options: [
            { id: false, name: 'No' },
            { id: true, name: 'Yes' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: false,
      mandatory: false,
      views: {
        create: showResidentialAccount,
        edit: showResidentialAccount,
        item: true,
        list: false,
        delete: false,
        csv: false,
      },
      group: 'preferences',
    },
    purposeOfAccountDropdown: {
      label: 'Purpose of Account',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'credit', name: 'Credit Purposes', tagType: 'info' },
            { id: 'forex', name: 'Foreign Exchange Trade', tagType: 'info' },
            { id: 'investment', name: 'Investment Purposes', tagType: 'info' },
            { id: 'transacting', name: 'Transacting', tagType: 'info' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      // guards: { currencyId: { notEq: 'ZAR' } },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'preferences',
    },
    purposeOfAccount: {
      label: 'Purpose of Account - Detail',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      // guards: { currencyId: { notEq: 'ZAR' } },
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Purpose of Account should be between 5 and 30 characters', args: [5, 30] },
      },
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'preferences',
    },
    exchangeControlInfo: {
      label: 'Exchange Control Information',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      guards: { currencyId: { notEq: 'ZAR' } },
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Exchange Control Information should be between 5 and 30 characters', args: [5, 30] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'cfcFca',
    },
    fCYAccountUtilisastionDetails: {
      label: 'FCY Account Utilisation Details',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      guards: { currencyId: { notEq: 'ZAR' } },
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'FCY Account Utilisation Details should be between 5 and 30 characters', args: [5, 30] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'cfcFca',
    },
    primaryUse: {
      label: 'Primary Use',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      guards: { currencyId: { notEq: 'ZAR' } },
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Primary Use should be between 5 and 30 characters', args: [5, 30] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'cfcFca',
    },

    requiredFieldSet: {
      label: 'Required Field Set',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'full', name: 'Full', tagType: 'info' },
            { id: 'minimum', name: 'Minimum', tagType: 'info' },
          ],
        },
      },
      default: 'full',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'summary',
    },

    integrationId: {
      label: 'Integration ID',
      datatype: 'text',
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    integrationStatus: {
      label: 'Integration Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'none', name: 'None', tagType: undefined },
            { id: 'sent', name: 'Sent', tagType: 'info' },
            { id: 'active', name: 'Active', tagType: 'success' },
            { id: 'inactive', name: 'Inactive', tagType: undefined },
            { id: 'pendingReview', name: 'Pending Review', tagType: 'warning' },
            { id: 'error', name: 'Error', tagType: 'error' },
            { id: 'rejected', name: 'Rejected', tagType: 'error' },
            { id: 'cifExists', name: 'CIF Exists', tagType: 'warning'},
          ],
        },
      },
      default: 'none',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    submissionCount: {
      label: 'Submission Count',
      datatype: 'integer',
      default: 0,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'integration',
    },
    firstSubmission: {
      label: 'Requested At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    requestedAt: {
      label: 'Requested At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    requestedBy: {
      label: 'Requested By',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    releasedAt: {
      label: 'Released At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    releasedBy: {
      label: 'Released By',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    rejectedAt: {
      label: 'Rejected At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    rejectedBy: {
      label: 'Rejected By',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    rejectedReason: {
      label: 'Rejection Reason',
      datatype: 'memo',
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },

    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
  },
};

export const requiredFields = (cifAccountApplication: LfxApplicationAccountInt) => {
  let requiredFields: (keyof LfxApplicationAccountInt)[];
  if (cifAccountApplication.requiredFieldSet !== 'minimum') {
    requiredFields = [
      'intermediaryCommissionId',
      'intermediaryCommissionAccount',
      'isResidentialAccount',
      'interestPaymentType',
    ];
    if (cifAccountApplication.interestPaymentType !== 'thisAccount') {
      requiredFields.push('interestPaymentAccount');
    }
    if (cifAccountApplication.interestPaymentType === 'otherBankAccount') {
      requiredFields.push('interestPaymentBranch');
    }
  } else {
    requiredFields = [];
  }
  return requiredFields;
};
