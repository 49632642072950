<template>
  <capitec-expander-group modeset="single">
    <!-- <capitec-expander expandbuttonalignment="left" label="Authentication Settings">
      <capitec-button-bar>
        <capitec-button label="Edit Auth Details"></capitec-button>
        <capitec-spacer></capitec-spacer>
      </capitec-button-bar>
      <capitec-toggle label="Use Multi Factor Authentication (MFA)" checked></capitec-toggle>
      <p>MFA method</p>
      <capitec-radio-group label="Radio group label" layout="horizontal">
        <capitec-radio label="USSD" checked></capitec-radio>
        <capitec-radio label="SMS"></capitec-radio>
      </capitec-radio-group>
      <capitec-toggle label="Allow SIM Swapped Mobile for MFA" unchecked></capitec-toggle>
    </capitec-expander>
    <capitec-expander
      expandbuttonalignment="left"
      label="Signatory Settings"
      routeName="testRoute"
      @collapse="on_expander_collapse"
      @expand="on_expander_expand"
    >
      <capitec-toggle label="Enable User as BOP Form Signatory" checked></capitec-toggle>
    </capitec-expander> -->
  </capitec-expander-group>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
// import LDBItemView from '@/landobyte_vue/LDBItemView.vue';

export default {
  name: 'LfxUserDetails',
  components: {},
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    recordName() {
      return 'user';
    },
    recordId() {
      return this.$route.params.userId;
    },
    record() {
      return this.$store.state[this.recordName][this.recordId];
    },
    definition() {
      return getDefinition(this.recordName);
    },
    view() {
      return this.$route.meta?.view ? this.$route.meta?.view : 'item';
    },
  },
  watch: {
    view(newView, oldView) {
      // TODO; This causes the old component to become editing when navigating to a new route that is editing on stepper navigation
      if (this.$route.meta.view === 'edit') {
        this.record.editing = true;
      } else {
        this.record.editing = false;
      }
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    // onSaveAccount({ fields, options }) {
    //   this.record.update(fields, options);
    // },
    on_expander_collapse(evt) {
    },
    on_expander_expand(evt) {
    },
  },
};
</script>

<style>
/* div.container {
} */
</style>
