//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2024 LandoByte (Pty) Ltd.
//  File        : LfxUserTeamDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a User Team record for the Capitec Forex System
//  Created     : 26 April 2024 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import { isAlphaWhiteList } from '../sharedUtils/LdbValidations';
import type { LdbDefinition } from '../definitions/LdbInterfaces';
import { LfxAccountAccountLevel } from './LfxAccountDef';

//----------------------------------------------------------------------------------------------------------------------

export type LfxUserTeamStatus = "active"|"disabled";
export type LfxUserTeamType = "bankUserTeam"|"directClient"

//----------------------------------------------------------------------------------------------------------------------

export interface LfxUserTeamInt {
  id?: string,
  accountId?: number;
  description?:string,
  accountLevel?: LfxAccountAccountLevel,
  status?:LfxUserTeamStatus,
  teamType?:LfxUserTeamType,
  lastUserReviewDate?:Date,
  lastUserReviewBy?: number;
  
  createdAt?:Date,
  createdBy?:number,
  createdByName?:string,
  updatedAt?:Date,
  updatedBy?:number,
  updatedByName?:string;
  deletedAt?:Date,
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxUserTeamDef:LdbDefinition = {
  title:"User Team",
  table:'LfxUserTeam',
  collectionPath:"/config/1/userTeam",
  pagingType: 'frontEnd',
  view:{create:true,edit:true,item:true,list:true,delete:false,csv:false},
  indexes:[],
  groups: {
    system: { priority: 1, label: 'General', color: 'success' },
    changes: { priority: 9, label: 'Changes', color: 'disabled' },
  },
  fields:{
    id:{
      label:'Name',
      datatype:{'id':{descriptionField:'description',type:'text'}},
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false},
      group: 'system',
    },
    accountId: {
      label: 'Account',
      datatype: {
        foreignKey: {
          linkTable: 'LfxAccount',
          linkField: 'id',
          collection: 'fk_accounts',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },
    description:{
      label:'Description',
      datatype:'text',
      default:null,
      mandatory:true,
      validators:{
        is: { msg: 'Please use only alpha charaters', args: [isAlphaWhiteList] },
        len: { msg: 'Description should be between 5 and 50 characters', args: [5, 50] },
      },
      views:{create:true,edit:true,item:true,list:false,delete:false,csv:false},
      group: 'system',
    },
    accountLevel: {
      label: 'Account Level',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'bank', name: 'Bank', tagType: 'info' },
            { id: 'intermediary', name: 'Intermediary', tagType: 'info', guards: false },
            { id: 'intermediaryBranch', name: 'Intermediary Branch', tagType: 'info', guards: false },
            { id: 'client', name: 'Client', tagType: 'info', guards: false },
          ],
        },
      },
      default: 'bank',
      mandatory: true,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: true },
      group: 'system',
    },
    status:{
      label:'Status',
      datatype:{option:{optionType:'text',
          options:[
            {id:'active',name:'Active', tagType: 'success'},
            {id:'disabled',name:'Disabled', tagType: 'error'},
          ]
        }
      },
      default:'active',
      mandatory:true,
      views:{create:true,edit:true,item:true,list:true,delete:false,csv:false},
      group: 'system',
    },
    teamType:{
      label:'Team Type',
      datatype:{option:{optionType:'text',
          options:[
            {id:'bankUserTeam',name:'Bank User Team', tagType: 'info'},
            {id:'directClient',name:'Direct Client', tagType: 'info'},
          ]
        }
      },
      default:'active',
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false},
      group: 'system',
    },
    userReviewStatus:{
      label:'User Review Status',
      datatype:{option:{optionType:'text',
          options:[
            {id:'compliant',name:"Compliant", tagType: 'success'},
            {id:'nonCompliant',name:"Non Compliant", tagType: 'error'},
            {id:'due',name:'Review Due', tagType: 'warning'},
          ]
        }
      },
      property: { source: 'frontend', read: true, write: true, sort: 'none' },
      default:'active',
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false},
      group: 'system',
    },
    lastUserReviewDate: {
      label: 'Last User Review Timestamp',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },
    lastUserReviewBy: {
      label: 'Last User Review By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
          linkInSql: false,
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
    },

    createdAt:{
      label:'Created At',
      datatype: {datetime: {
        type: 'datetime',
        format: 'human'
      }},
      default:null,
      mandatory:true,
      allowNullValues:true,
      views:{create:false,edit:false,item:true,list:false,delete:false,csv:false},
      group: 'changes',
    },
    createdBy:{
      label:'Created By',
      datatype:{
        foreignKey: {
          linkTable:'LfxUser',
          linkField:'id',
          displayField:'username',
        }
      },
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:false,delete:false,csv:false},
      group: 'changes',
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: '',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
  }
}