<template>
    <div class="ldb-loader-container">
      <img src="/platform/icons/loader.svg" />
    </div>
</template>

<style>

.ldb-loader-container {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  padding-top: 30px;
  pointer-events: none;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

</style>
