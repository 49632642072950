//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2022 LandoByte (Pty) Ltd.
//  File        : LfxApplicationDocumentDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Application Document record for the LandoFX systems
//  Created     : 16 September 2022 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition } from '../definitions/LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------

export type LfxApplicationDocumentStatus = 'new' | 'uploaded' | 'uploadFailed' | 'cancelled';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxApplicationDocumentInt {
  id?: number;
  applicationId?: number;
  accountId?: number;
  documentTypeId?: number;
  reference?: string;

  originalFileName?: string;
  originalFileSize?: number;
  s3Bucket?: string;
  s3Key?: string;

  status?: LfxApplicationDocumentStatus;

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxApplicationDocumentDef: LdbDefinition = {
  title: 'Application Document',
  table: 'LfxApplicationDocument',
  pagingType: 'frontEnd',
  database: 'sql',
  collectionPath: '/account/{{accountId}}/application/{{applicationId}}/applicationDocument',
  view: {
    create: true,
    edit: true,
    item: true,
    list: true,
    delete: false,
    csv: false,
    tiles: {
      default: {
        layout: [
          ['title', 'arrow'],
          ['subtitle', 'subtitle'],
          ['subtitle2', 'subtitle2'],
          ['status', 'infoValue'],
          ['status', 'infoLabel'],
        ],
        color: { type: 'field', field: 'status', optionField: 'tagType', colorType: 'class' },
      },
    },
  },
  groups: {
    system: { priority: 1, label: 'General', color: 'success' },
    file: { priority: 2, label: 'File', color: 'info' },
    changes: { priority: 3, label: 'Changes', color: 'disabled' },
  },

  indexes: ['accountId', 'applicationId'],
  fields: {
    id: {
      label: 'ID',
      datatype: { id: { descriptionField: 'name' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    applicationId: {
      label: 'Application',
      datatype: {
        foreignKey: {
          linkTable: 'LfxApplication',
          linkField: 'id',
          collection: 'fk_applications',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
      group: 'system',
    },
    accountId: {
      label: 'Account',
      datatype: {
        foreignKey: {
          collection: 'fk_accounts',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: 0,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },
    documentTypeId: {
      label: 'Document Type',
      datatype: {
        foreignKey: {
          collection: 'fk_documentType',
          linkTable: 'LfxApplicationDocumentType',
          linkField: 'id',
          displayField: 'documentName',
        },
      },
      default: 0,
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: true,
        list: true,
        delete: false,
        csv: false,
        tiles: { default: 'subtitle' },
      },
      group: 'system',
    },
    reference: {
      label: 'Reference',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: true,
        list: true,
        delete: false,
        csv: false,
        tiles: { default: 'title' },
      },
      group: 'system',
    },

    originalFileName: {
      label: 'Original File Name',
      datatype: 'text',
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: {
        create: false,
        edit: false,
        item: true,
        list: true,
        delete: false,
        csv: false,
        tiles: { default: 'subtitle2' },
      },
      group: 'file',
    },
    originalFileSize: {
      label: 'Original File Size',
      datatype: 'integer',
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'file',
    },
    s3Bucket: {
      label: 'Specified S3 Bucket',
      datatype: 'text',
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'file',
    },
    s3Key: {
      label: 'S3Key',
      datatype: 'text',
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'file',
    },

    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'new', name: 'Initializing', tagType: 'info' },
            { id: 'uploaded', name: 'Uploaded', tagType: 'success' },
            { id: 'uploadFailed', name: 'Upload Failed', tagType: 'error' },
            { id: 'cancelled', name: 'Cancelled' },
          ],
        },
      },
      default: 'uploaded',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },

    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          collection: 'fk_users',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: '',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          collection: 'fk_users',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
  },
};

//----------------------------------------------------------------------------------------------------------------------
