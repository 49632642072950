<template>
  <div class="lfx-table" @click="pageSizeState.showPageSize = false">
    <div v-if="isLoading" class="loading-mask">
    <div class="loading-content">
    <span style="color: white">Loading...</span>
    </div>
    </div> 

    <div class="table-container">    

            <table
            v-if="rows.length > 0"
              class="table table-hover table-bordered table-responsive-sm"
              id="lfx-dataTables"
            >
            
            <thead class="thead-dark">
          
                <tr>
                  <th v-if="hasCheckbox" class="checkbox-th">
                    <div>
                      <input type="checkbox" v-model="setting.isCheckAll" />
                    </div>
                  </th>
                  <th
                    v-for="(col, index) in columns"
                    :key="index"
                    :class="{
                      sorted: setting.order === col.field
                    }
                    "
                    :style="{ 'width': col.width ? col.width : '300px',  'word-wrap': 'break-word','max-width': '300px'}"
                  >
                    <div
                    class="th-div"
                      :class="{
                        sortable: col.sortable,
                        both: col.sortable && setting.order !== col.field,
                        asc: setting.order === col.field && setting.sort === 'asc',
                        desc: setting.order === col.field && setting.sort === 'desc',
                      }"
                      @click="col.sortable ? doSort(col.field) : false"
                    >
                      {{ col.label }}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody v-if="rows.length > 0">
                <template v-if="isStaticMode">
                  <tr v-for="(row, i) in localRows" :key="i">
                    <td v-if="hasCheckbox">
                      <div>
                        <input
                          type="checkbox"
                          :ref="
                            (el) => {
                              rowCheckbox[i] = el;
                            }
                          "
                          :value="row[setting.keyColumn]"
                          @click="checked"
                        />
                      </div>
                    </td>
                    <td v-for="(col, j) in columns" :key="j" :class="getColumnClass(col)">
                      <div v-if="col.display" v-html="col.display(row)"></div>
                      <template v-else>
                        <div v-if="setting.isSlotMode">
                          <slot :name="col.field" :value="row"></slot>
                        </div>
                        <span v-else>{{ row[col.field] }}</span>
                      </template>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(row, i) in rows" :key="i">
                    <td v-if="hasCheckbox">
                      <div>
                        <input
                          type="checkbox"
                          :ref="
                            (el) => {
                              rowCheckbox[i] = el;
                            }
                          "
                          :value="row[setting.keyColumn]"
                          @click="checked"
                        />
                      </div>
                    </td>
                    <td v-for="(col, j) in columns" :key="j" :class="getColumnClass(col)">
                      <div v-if="col.display" v-html="col.display(row)"></div>
                      <div v-else>
                        <div v-if="setting.isSlotMode">
                        <slot :name="col.field" :value="row"></slot>
                        </div>
                        <span v-else>{{ row[col.field] }}</span>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>

            </div>
        <div class="paging" v-if="rows.length > 0">
        <div class="page-spacer">
        
        </div>
          <div class="page-size">
          
          <div 
          label=""
          :items="messages.pageSizeOptions" 
          id-field="size"
          :value="messages.pageSizeValue"
          display-field="label"
          @value-change="onPageSizeChange"
          @click.stop="pageSizeState.showPageSize = !pageSizeState.showPageSize"
          :class="`table-paging-rows ${pageSizeState.showPageSize ? 'show-dropdown' : 'hide-dropdown'}`"
          autoScrollPosition="end"

          mode="inline">
          <template v-if="pageSizeState.showPageSize">
          <div v-for="page in messages.pageSizeOptions" :key="page"  class="page-size-option" @click="onPageSizeChange({detail:{new:{size:page.size}}})"> {{page.label}} </div>
          </template>
          <template v-else>  
            <div class="current-page-size"> 
              <div class="page-size-label">{{setting.pageSize}} Rows</div>
              <capitec-icon class="page-size-arrow" icon="system/chevron-down-action" size="x-small"/>
            </div>
          </template>
          </div>
            <span v-if="messages.show_go_to_page">{{ messages.gotoPageLabel }}</span>
            <!-- <select v-if="messages.show_go_to_page" v-model="setting.page">
              <option v-for="n in setting.maxPage" :key="n">{{ n }}</option>
            </select> -->
          </div>
          
          <div class="page-position">
            <div role="status" aria-live="polite">
              {{
                stringFormat(messages.pagingInfo, setting.offset, setting.limit, total)
              }}
            </div>
          </div>

          <div class="page-navigation">
              <div class="pagination">
                <div v-if="messages.show_first_page_arrows" class="page-item" :class="{ disabled: setting.page <= 1 }">
                  <a
                    class="page-link"
                    href="javascript:void(0)"
                    aria-label="Previous"
                    @click="setting.page = 1"
                  >
                    <span aria-hidden="true">&laquo;</span>
                    <span class="sr-only">First</span>
                  </a>
                </div>
                <div class="page-item" :class="{ disabled: setting.page <= 1 }">
                  <a
                    class="page-link"
                    href="javascript:void(0)"
                    aria-label="Previous"
                    @click="prevPage"
                    >
                    <span aria-hidden="true">
                    <capitec-icon size="small" icon="system/chevron-left-action"></capitec-icon>
                    </span>
                  
                  </a>
                </div>

                <div
                  class="page-item"
                  :class="{ disabled: setting.page >= setting.maxPage }"
                >
                  <a
                    class="page-link"
                    href="javascript:void(0)"
                    aria-label="Next"
                    @click="nextPage"
                  >
                    <span aria-hidden="true">
                       <capitec-icon size="small" icon="system/chevron-right-action"></capitec-icon>
                    </span>
                  </a>
                </div>
                <div
                v-if="messages.show_last_page_arrows"
                  class="page-item"
                  :class="{ disabled: setting.page >= setting.maxPage }"
                >
                  <a
                    class="page-link"
                    href="javascript:void(0)"
                    aria-label="Next"
                    @click="setting.page = setting.maxPage"
                  >
                    <span aria-hidden="true">&raquo;</span>
                    <span class="sr-only">Last</span>
                  </a>
                </div>
              </div>
          
          </div>
          </div>
          <div class="table-whitespace">
          
          </div>       
  </div>
</template>

<script lang="ts">

import {
  defineComponent,
  ref,
  reactive,
  computed,
  watch,
  onBeforeUpdate,
  nextTick,
} from "vue";

interface tableSetting {
  isSlotMode: boolean;
  isCheckAll: boolean;
  keyColumn: string;
  page: number;
  pageSize: number;
  maxPage: number;
  offset: number;
  limit: number;
  paging: Array<number>;
  order: string;
  sort: string;
  performLocalSort: boolean;
}

interface column {
  isKey: string;
  field: string;
  col:{
    name:string,
    datatype:object|string,
    align:string
  }
}

export default defineComponent({
  name: "my-table",
  emits: [
    "return-checked-rows", 
    "do-search", 
    "is-finished",
    "on-page-size-change",
    "on-page-change"
  ],
  props: {
    // 是否讀取中 (is data loading)
    isLoading: {
      type: Boolean,
      require: true,
    },
    // 是否執行了重新查詢 (Whether to perform a re-query)
    isReSearch: {
      type: Boolean,
      require: true,
    },
    // 有無Checkbox (Presence of Checkbox)
    hasCheckbox: {
      type: Boolean,
      default: false,
    },
    // 標題 (title)
    title: {
      type: String,
      default: "",
    },
    // 欄位 (Field)
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 資料 (data)
    rows: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 一頁顯示筆數 (Display the number of items on one page)
    pageSize: {
      type: Number,
      default: 10,
    },
    // 總筆數 (Total number of transactions)
    total: {
      type: Number,
      default: 100,
    },
    // 現在頁數 (Current page number)
    page: {
      type: Number,
      default: 1,
    },
    isPagedTable:{
      type:Boolean,
      default:false
    },
    // 排序條件 (Sort condition)
    sortable: {
      type: Object,
      default: () => {
        return {
          order: "id",
          sort: "asc",
          performLocalSort: true
        };
      },
    },
    // 顯示文字 (Display text)
    messages: {
      type: Object,
      default: () => {
        return {
          pageSizeOptions:[
            {size:10,label:'10 Rows'},
            {size:50,label:'50 Rows'},
            {size:100,label:'100 Rows'},
            {size:200,label:'200 Rows'},
          ],
          pageSizeValue:{size:100,label:'100 Rows'},
          show_first_page_arrows:false,
          show_last_page_arrows:false,
          show_go_to_page:false,
          pagingInfo: "{0}-{1} of {2}",
          pageSizeChangeLabel: "Rows",
          gotoPageLabel: "Go to page:",
          noDataAvailable: "No data",
        };
      },
    },
    // 靜態模式 (Static mode(no refresh server data))
    isStaticMode: {
      type: Boolean,
      default: false,
    },
    // 靜態模式 (Static mode(no refresh server data))
    isSlotMode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    // 組件用內部設定值 (Internal set value for components)
    const setting: tableSetting = reactive({
      // 是否啟用Slot模式 (Enable slot mode)
      isSlotMode: props.isSlotMode,
      // 是否全選 (Whether to select all)
      isCheckAll: false,
      // KEY欄位名稱 (KEY field name)
      keyColumn: computed(() => {
        let key = "";
        Object.assign(props.columns).forEach((col: column) => {
          if (col.isKey) {
            key = col.field;
          }
        });
        return key;
      }),
      // 當前頁數 (current page number)
      page: props.page,
      // 每頁顯示筆數 (Display count per page)
      pageSize: props.pageSize,
      // 最大頁數 (Maximum number of pages)
      maxPage: computed(() => {
        if (props.total <= 0) {
          return 0;
        }
        let maxPage = Math.floor(props.total / setting.pageSize);
        let mod = props.total % setting.pageSize;
        if (mod > 0) {
          maxPage++;
        }
        return maxPage;
      }),
      // 該頁數起始值 (The starting value of the page number)
      offset: computed(() => {
        return (setting.page - 1) * setting.pageSize + 1;
      }),
      // 該頁數最大值 (Maximum number of pages0
      limit: computed(() => {
        let limit = setting.page * setting.pageSize;
        return props.total >= limit ? limit : props.total;
      }),
      // 換頁陣列 (Paging array)
      paging: computed(() => {
        let startPage = setting.page - 2 <= 0 ? 1 : setting.page - 2;
        if (setting.maxPage - setting.page <= 2) {
          startPage = setting.maxPage - 4;
        }
        startPage = startPage <= 0 ? 1 : startPage;
        let pages = [];
        for (let i = startPage; i <= setting.maxPage; i++) {
          if (pages.length < 5) {
            pages.push(i);
          }
        }
        return pages;
      }),
      viewRecords:computed(() => { 
        let res:any[] = [];
        for (let r = setting.offset ; r < (setting.offset + setting.page) ; r ++ ) {
          let record = props.rows[r];
          if (record !== undefined) {
            res.push(record);
          }
        }
        return res;
      }),
      // 組件內用排序 (Sortable for local)
      order: props.sortable.order,
      sort: props.sortable.sort,
      performLocalSort: props.sortable.performLocalSort !== false
    });

    // 組件內用資料 (Data rows for local)
    const localRows = computed(() => {
      // sort rows
      let property = setting.order;
      let sort_order = 1;
      if (setting.sort === "desc") {
        sort_order = -1;
      }
      let rows = props.rows as Array<unknown>;
      
      if (setting.performLocalSort) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rows.sort((a: any, b: any): number => {
          let sortProperty = property;
          const labelProperty = sortProperty+"_label"
          let aSort = a[labelProperty] || a[property];
          let bSort = b[labelProperty] || b[property];
          if (typeof aSort === 'string') {
            aSort = aSort.toLowerCase();
          }
          if (typeof bSort === 'string') {
            bSort = bSort.toLowerCase();
          }
          if (aSort < bSort) {
            return -1 * sort_order;
          } else if (aSort > bSort) {
            return sort_order;
          } else {
            return 0;
          }
        });
      }
      
      // return sorted and offset rows
      let result = [];
      if (props.isPagedTable === true) {
        for (let index = 0; index < props.rows.length; index++) {
          if (rows[index]) {
            result.push(rows[index]);
          }
        }
      }
      else 
      {
        for (let index = setting.offset - 1; index < setting.limit; index++) {
          if (rows[index]) {
            result.push(rows[index]);
          }
        }
      }
      return result;
    });
    
    ////////////////////////////
    //
    //  Checkbox 相關操作
    //  (Checkbox related operations)
    //
    // 定義Checkbox參照 (Define Checkbox reference)
    const rowCheckbox = ref([]);
    if (props.hasCheckbox) {
      /**
       * 重新渲染前執行 (Execute before re-rendering)
       */
      onBeforeUpdate(() => {
        // 每次更新前都把值全部清空 (Clear all values before each update)
        rowCheckbox.value = [];
      });

      /**
       * 監聽全勾選Checkbox (Check all checkboxes for monitoring)
       */

      watch(
        () => setting.isCheckAll,
        (state: boolean) => {
          let isChecked: Array<string> = [];
          rowCheckbox.value.forEach((val: HTMLInputElement) => {
            if (val) {
              val.checked = state;
              if (val.checked) {
                isChecked.push(val.value);
              }
            }
          });
          // 回傳畫面上選上的資料 (Return the selected data on the screen)
          emit("return-checked-rows", isChecked);
        }
      );
    }
    
    /**
     * Checkbox點擊事件 (Checkbox click event)
     */
    const checked = () => {
      let isChecked: Array<string> = [];
      rowCheckbox.value.forEach((val: HTMLInputElement) => {
        if (val && val.checked) {
          isChecked.push(val.value);
        }
      });
      // 回傳畫面上選上的資料 (Return the selected data on the screen)
      emit("return-checked-rows", isChecked);
    };

    /**
     * 清空畫面上所有選擇資料 (Clear all selected data on the screen)
     */
    const clearChecked = () => {
      rowCheckbox.value.forEach((val: HTMLInputElement) => {
        if (val && val.checked) {
          val.checked = false;
        }
      });
      // 回傳畫面上選上的資料 (Return the selected data on the screen)
      emit("return-checked-rows", []);
    };

    ////////////////////////////
    //
    //  排序·換頁等 相關操作
    //  (Sorting, page change, etc. related operations)
    //

    /**
     * 呼叫執行排序 (Call execution sequencing)
     */

    const doSort = (order: string) => {
      let sort = "asc";
      if (order == setting.order) {
        // 排序中的項目時 (When sorting items)
        if (setting.sort == "asc") {
          sort = "desc";
        }
      }
      let offset = (setting.page - 1) * setting.pageSize;
      let limit = setting.pageSize;
      setting.order = order;
      setting.sort = sort;
      emit("do-search", offset, limit, order, sort);

      // 清空畫面上選擇的資料 (Clear the selected data on the screen)
      if (setting.isCheckAll) {
        // 取消全選時自然會清空 (It will be cleared when you cancel all selections)
        setting.isCheckAll = false;
      } else {
        if (props.hasCheckbox) {
          clearChecked();
        }
      }
    };

    /**
     * 切換頁碼 (Switch page number)
     *
     * @param page      number  新頁碼    (New page number)
     * @param prevPage  number  現在頁碼  (Current page number)
     */
    const changePage = (page: number, prevPage: number) => {
      setting.isCheckAll = false;
      let order = setting.order;
      let sort = setting.sort;
      let offset = (page - 1) * setting.pageSize;
      let limit = setting.pageSize;
      if (!props.isReSearch || page > 1 || page == prevPage) {
        // 非重新查詢發生的頁碼變動才執行呼叫查詢 (Call query will only be executed if the page number is changed without re-query)
        emit("do-search", offset, limit, order, sort);
      }
    };
    // 監聽頁碼切換 (Monitor page switching)
    watch(() => setting.page, changePage);

    /**
     * 切換顯示筆數 (Switch display number)
     */
    const changePageSize = () => {
      if (setting.page === 1) {
        // 沒自動觸發 changePage()，所以手動觸發

        changePage(setting.page, setting.page);
      } else {
        // 強制返回第一頁,並自動觸發 changePage()
        setting.page = 1;
        setting.isCheckAll = false;
      }
    };
    // 監聽顯示筆數切換 (Monitor display number switch)
    watch(() => setting.pageSize, changePageSize);
    
    const prevPage = () => {
      if (setting.page == 1) {
        return false;
      }
      setting.page--;
      emit('on-page-change',setting.page);
    };
    
     //@ts-ignore
     const onPageSizeChange = (evt) => {
       setting.pageSize = evt.detail.new.size;
       emit('on-page-size-change',setting.pageSize);
    }
    
    const movePage = (page: number) => {
      setting.page = page;
    };
    
    const nextPage = () => {
      if (setting.page >= setting.maxPage) {
        return false;
      }
      setting.page++;
      emit('on-page-change',setting.page);
    };
    
    // 監聽資料變更 (Monitoring data changes)
    watch(
      () => props.rows,
      () => {
        if (props.isReSearch) {
          setting.page = 1;
        }
        nextTick(function () {
          // 資料完成渲染後回傳私有元件 (Return the private components after the data is rendered)
          let localElement = document.getElementsByClassName("is-rows-el");
          emit("is-finished", localElement);
        });
      }
    );
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const stringFormat = (template: string, ...args: any[]) => {
      return template.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != "undefined" ? args[number] : match;
      });
    };

    const getColumnClass = (col:column):string => {
      let align = (col.col.align === undefined) ? 'default' : col.col.align;
      if (typeof col.col.datatype === "object") {
        return Object.keys(col.col.datatype)[0]
      } else 
      return `datatype-${col.col.datatype} align-${align}`;
    }
    
    const pageSizeState = ref({
      showPageSize:false
    });

    if (props.hasCheckbox) {
      // 需要 Checkbox 時 (When Checkbox is needed)
      return {
        localRows,
        setting,
        rowCheckbox,
        pageSizeState,
        getColumnClass,
        checked,
        doSort,
        prevPage,
        movePage,
        nextPage,
        onPageSizeChange,
        stringFormat,
      };
    } else {
      return {
        localRows,
        setting,
        pageSizeState,
        getColumnClass,
        doSort,
        prevPage,
        movePage,
        nextPage,
        onPageSizeChange,
        stringFormat,
      };
    }
  },
});
</script>

<style>



div.page-size > div.table-paging-rows{
  flex:1;
  position: absolute;
  width:100%;
 
  /* transform:translateY(0px); */
  transition: transform 0s ease-in-out;
  /* min-height: 40px; */
  min-height: 40px;
  /* border: 1px solid red; */
  bottom:0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  border:1px solid var(--theme-select-container-border-color);
  border-radius: var(--theme-select-focussed-input-field-border-radius);
  overflow: hidden;
}

.current-page-size{
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-size-arrow{
  flex:1;
  max-width: 20px;
  min-width: 20px;
  max-height: 20px;
  min-height: 20px;
  transform:rotateZ(180deg);
}

.page-size-label{
  flex:1;
  padding-right: 10px;
}



div.page-size > div.table-paging-rows.show-dropdown{
 
}

div.page-size > div.table-paging-rows.hide-dropdown{
  border:1px solid transparent;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
}

.page-size-option{
  align-self: stretch;
  /* border: 1px solid blue; */
  background-color:white;
  color:var(--theme-select-option-value-font-color);
  padding-left:16px;
  min-height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}


.page-size-option:hover{
  background-color:var(--theme-system-hover-color);
}

.th-div{
  /* position: relative; */
  /* background-color:skyblue; */

  /* z-index: 10000; */
}

.th-div:hover{
  /* position: relative; */
  /* background-color:skyblue; */
  /* z-index: 10000; */
}


.lfx-table{
  display: inline-flex;
  flex-direction: column;
  pointer-events: auto;
  position: absolute;
  top:0px;
  bottom:0px;
  left:0px;
  right: 0px;
  border-top:var(--theme-data-table-border);
  background-color:var(--theme-app-background-color, #F4F4F4);
  overflow: hidden;
}

.table-container{
  overflow-y:scroll;
  flex: 0 1 auto;
  background-color:white;
  max-width: 100%;
}

.table-responsive-sm{
  max-width: 100%;
}

.paging{
  display: flex;
  flex: 0 1 auto;
  max-height: 64px;
  min-height: 40px;
  border-top:var(--theme-data-table-border);
  border-bottom:var(--theme-data-table-border);
  border-left:var(--theme-data-table-border);
  border-right:var(--theme-data-table-border);
  background-color:white;
}

.table-whitespace{
  flex: 0 1000000 auto;
  overflow: hidden;
  background-color:var(--theme-app-background-color, #F4F4F4);
}

div.page-spacer,
div.page-size,
div.page-position,
div.page-navigation{
  display: flex;
  align-items:center;
  justify-content:center;
  align-content: center;
  flex:1;
  position: relative;
}

div.page-spacer{
  flex:auto;
}

div.page-size{
  flex:1;
  max-width: 125px;
  border-left:var(--theme-data-table-border);
  border-right:var(--theme-data-table-border);
}

div.page-position{
  flex:1;
  max-width: 100px;
}

div.page-navigation{
  flex:1;
  max-width: 40px;
}

div.page-navigation > ul > li{
  border: none;
}

th {
  font-size: var(--theme-data-table-font-size, 12px);
  font-weight: var(--theme-data-table-header-font-weight, normal);
  color: var(--theme-data-table-font-color, #4E6066);
  background-color: var(--theme-data-table-header-background-color, #F5F5F5);
  text-align: left;
  position: var(--theme-data-table-position-header, sticky);
  top: 0;
  cursor: default;
  transform:translateZ(10000);
}

thead.thead-dark > tr > th{
 /* z-index: 15000;   */
 /* overflow: hidden; */
 /* max-width: 50px; */
 /* text-overflow: ellipsis; */
 /* white-space: nowrap; */
 /* border: 1px solid red; */
}

thead.thead-dark > tr > th:hover{
  z-index: 1000;
  /* background-color: red; */
}


table {
  width: 100%;
  color: #212529;
  border-collapse: collapse;
}

div.pagination {
  display: flex;
  list-style: none;
  align-items:center;
  justify-content: center;
  flex:1;
  position: absolute;
  top:0px;
  bottom:0px;
  left:0px;
  right:0px;
}


tr {
  z-index: 10;
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  border-left:var(--theme-data-table-border);
  border-right:var(--theme-data-table-border);
}

.table > .thead-dark {
  position: relative;
  z-index: 100;
}

.table > .thead-dark > th {
  z-index: 1000;
  color: var(--theme-data-table-font-color);
  border-top:var(--theme-data-table-border);
}

.table > thead{
  z-index:20;
}

.table > tbody {
  z-index: 10;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 1px;
  font-weight: normal;
  font-size: 14px;
}


.table td{
  padding: 0.3rem;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
  word-wrap: break-word;
}

/* Used to limit size of ID and Foreign Key fields - Start*/
.table td.id > div > div.ldb-field-container.list,
.table td.foreignKey > div > div.ldb-field-container.list{
  max-width: 300px;
}

.table td.foreignKey > div > div.ldb-field-container.list > a,
.table td.id > div > div.ldb-field-container.list > a{
  white-space:normal;
  word-wrap: break-word;
}
/* Used to limit size of ID and Foreign Key fields - End*/

tr:nth-child(even){
  background-color:#FAFAFA;
}

tr:hover{
  background-color:#E8F4FA;
  z-index: -2;
}

.sortable {
  cursor: pointer;
  background-position: right;
  background-repeat: no-repeat;
  padding-top:10px;
  padding-bottom:10px;
  padding-left: 18px; 
  padding-right: 10px; 
  line-height: 0.9em;
  /* white-space: nowrap; */
}

th.sorted{
  background-color:var(--theme-data-table-header-sorted-background-color, #FFFFFF);
  border-top:var(--theme-data-table-border) !important;
}


table.table > thead{
  transform: translateY(-1px);
  border-bottom:var(--theme-data-table-border);
  border-top:var(--theme-data-table-border);
}


div.option.selected-value
{
  transform: translateY(-200px) rotateZ(10deg) !important;
}


.sortable::after{
  content:'';
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  width:5px;
  position: absolute;
  left: 1px;
  top:calc(50% - 9px - 3px);
  background-position: center center;
}

.sortable::before{
  content:'';
  display: inline-block;
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  width:5px;
  position: absolute;
  left: 1px;
  top:calc(50% - 9px + 3px);
  background-position: center center;
}

.both::after,.asc::after{
  background-image: url(/platform/icons/system/sort-up.svg);
}

.both::before,.desc::before{
  background-image: url(/platform/icons/system/sort-down.svg);
}

.sorted::before,.desc::before{
  background-image: url(/platform/icons/system/sort-down-action.svg);
}

.sorted::after,.asc::after{
  background-image: url(/platform/icons/system/sort-up-action.svg);
}


.page-item{
  flex:1;
  cursor: pointer;
  display:flex;
  align-items: center;
  justify-content: center;
}

.page-item.disabled{
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  filter:grayscale(100);
}

.page-link {
  min-width: 10px;
  max-width: 10px;
  position: absolute;
}


.paging_simple_numbers{
  position: absolute;
  flex:1;
  top:0px;
  left:0px;
  right: 0px;
  bottom:0px;
}


</style>
