
export const LDB_COLORS = ['#01BBF1','#71B62C','#852CB3','#AA1580','#F39001','#006F3C','#F63E02','#FFC122','#70AF00','#08A6B5','#D4781C','#A5132A','#009DE0','#E61414','#00486D','#48AF45','#A4C626','#F15924','#DE130C','#FFBC00'];
export const STATUS_COLORS = {info:'rgb(0, 157, 224)',success:'#70AF00',warning:'#D4781C',error:'#A5132A',disabled:'#7C7C7C'};

export function colorFromId(id:number) {
  let colorLength = LDB_COLORS.length;
  let remId = id % colorLength;
  let color = LDB_COLORS[remId];
  return color;
}


export function thousandSeparate(amountInCent:number,separator:string|undefined) {

  let val = amountInCent ? amountInCent : 0;
  let numValue = Number(val);
  let minor = `${Math.floor(numValue % 100)}`;
  let major = `${Math.floor(numValue / 100)}`;
  if (minor.length < 2) {
    minor = `${minor}0`;
  }
  let decimalStr = `${major}.${minor}`;
  let sep = (separator === undefined) ? ' ' : separator;

  return `${decimalStr}`
  .split(sep)
  .join('')
  .split('')
  .reverse()
  .join('')
  .replace(/([0-9]{3})/g, '$1,')
  .split('')
  .reverse()
  .join('')
  .replace(/^,/, '')
  .split(',')
  .join(sep);
}

//@ts-ignore
window.colorFromId = colorFromId;

