//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2022 LandoByte (Pty) Ltd.
//  File        : LdbTimeUtils.ts
//  Author      : Abraham Van Rooyen
//  Description : This module contains time-based utils for LandoByte TS systems that can be used by both backend and frontend
//  Created     : 29 September 2022 by Abraham Van Rooyen
//----------------------------------------------------------------------------------------------------------------------

import { assertCannotReach, LdbTimeIncrement } from '../definitions/LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------
// TYPES
//----------------------------------------------------------------------------------------------------------------------

// ----- Types -----

export type UtcZeroedDate = Date & { __brand: 'UtcZeroedDate' };
export type ZaZeroedDate = Date & { __brand: 'ZaZeroedDate' };
export type CapitecDateString = string & { __brand: 'CapitecDateString' };

// ----- Type functions -----

function assertUtcZeroedDate(date: Date): asserts date is UtcZeroedDate {
  if (
    date.getUTCHours() !== 0 ||
    date.getUTCMinutes() !== 0 ||
    date.getUTCSeconds() !== 0 ||
    date.getUTCMilliseconds() !== 0
  ) {
    throw new Error(`InvalidArgument: ${date} is not a UTC zeroed date`);
  }
}

function assertZaZeroedDate(date: Date): asserts date is ZaZeroedDate {
  if (
    date.getUTCHours() !== 2 ||
    date.getUTCMinutes() !== 0 ||
    date.getUTCSeconds() !== 0 ||
    date.getUTCMilliseconds() !== 0
  ) {
    throw new Error(`InvalidArgument: ${date} is not a ZA zeroed date`);
  }
}

function assertCapitecDateString(date: string): asserts date is CapitecDateString {
  if (date.length != 10 || date.charAt(5 - 1) !== '/' || date.charAt(8 - 1) !== '/') {
    throw new Error(`InvalidArgument: ${date} is not in the correct 'YYYY/MM/DD' format.`);
  }
}

//----------------------------------------------------------------------------------------------------------------------
// NOW
//----------------------------------------------------------------------------------------------------------------------

export function now(): Date {
  return new Date();
}

//----------------------------------------------------------------------------------------------------------------------
// UTC DATES
//----------------------------------------------------------------------------------------------------------------------

export function newUtcZeroedDate(ms: number): UtcZeroedDate {
  let date = new Date(ms);
  assertUtcZeroedDate(date);
  return date;
}

export function convertDateToUtcZero(date: Date): UtcZeroedDate {
  let ms = date.setUTCHours(0, 0, 0, 0);
  let convertedDate = new Date(ms);
  assertUtcZeroedDate(convertedDate);
  return convertedDate;
}

//----------------------------------------------------------------------------------------------------------------------

export function startOfTodayAtUtcZero(): UtcZeroedDate {
  return convertDateToUtcZero(now());
}

//----------------------------------------------------------------------------------------------------------------------

// ----- Increment functions -----

export function incrementDate(timeIncrement: LdbTimeIncrement, startDate: UtcZeroedDate | undefined): UtcZeroedDate {
  startDate = startDate ? startDate : startOfTodayAtUtcZero();
  let incrementedDate = startOfTodayAtUtcZero();

  switch (timeIncrement.type) {
    case 'day':
      incrementedDate = incrementUtcDateByDays(startDate, timeIncrement.offset);
      break;
    case 'month':
      incrementedDate = incrementUtcDateByMonths(startDate, timeIncrement.offset);
      break;
    case 'year':
      incrementedDate = incrementUtcDateByYears(startDate, timeIncrement.offset);
      break;
    default:
      assertCannotReach(timeIncrement.type);
      break;
  }

  assertUtcZeroedDate(incrementedDate);
  return incrementedDate;
}

function incrementUtcDateByDays(startDate: UtcZeroedDate, incrementDays: number): UtcZeroedDate {
  let newDay = startDate.getUTCDate() + incrementDays;
  let newMs = startDate.setUTCDate(newDay);

  let newDate = new Date(newMs);
  assertUtcZeroedDate(newDate);
  return newDate;
}

function incrementUtcDateByMonths(startDate: UtcZeroedDate, incrementMonths: number): UtcZeroedDate {
  let newMonth = startDate.getUTCMonth() + incrementMonths;
  let normalisedNewMonth = calculateNormalisedNewMonth(newMonth);

  let newDate = new Date(startDate.setUTCMonth(newMonth));
  assertUtcZeroedDate(newDate);

  let actualDate = decreaseDayForMonthOverflow(newDate, normalisedNewMonth);
  return actualDate;
}

function incrementUtcDateByYears(startDate: UtcZeroedDate, incrementYears: number): UtcZeroedDate {
  let newMonth = startDate.getUTCMonth();
  let normalisedNewMonth = calculateNormalisedNewMonth(newMonth);

  let newYear = startDate.getUTCFullYear() + incrementYears;

  let newDate = new Date(startDate.setUTCFullYear(newYear));
  assertUtcZeroedDate(newDate);

  let actualDate = decreaseDayForMonthOverflow(newDate, normalisedNewMonth);
  return actualDate;
}

function calculateNormalisedNewMonth(newMonth: number): number {
  let normalisedNewMonth = ((newMonth + 1) % 12) - 1;
  if (normalisedNewMonth < 0) {
    normalisedNewMonth = normalisedNewMonth + 12;
  }
  return normalisedNewMonth;
}

function decreaseDayForMonthOverflow(newDate: UtcZeroedDate, normalisedNewMonth: number): UtcZeroedDate {
  let actualDate = newDate;
  let resultMonth = newDate.getUTCMonth();

  if (resultMonth > normalisedNewMonth) {
    let firstDayOfNextMonthMs = actualDate.setUTCDate(1);
    let firstDayOfNextMonth = new Date(firstDayOfNextMonthMs);
    assertUtcZeroedDate(firstDayOfNextMonth);
    actualDate = incrementUtcDateByDays(firstDayOfNextMonth, -1);
  }
  return actualDate;
}

//----------------------------------------------------------------------------------------------------------------------
// CAPITEC DATES
//----------------------------------------------------------------------------------------------------------------------

export function convertDateToCapitecFormat(date: UtcZeroedDate): CapitecDateString {
  // let string = date.toLocaleDateString('en-ZA', { dateStyle: 'short' });
  let string = date.toLocaleDateString('en-ZA');
  assertCapitecDateString(string);
  return string;
}

//----------------------------------------------------------------------------------------------------------------------
// ZA DATES
//----------------------------------------------------------------------------------------------------------------------

export function convertDateToZaZero(date: Date): ZaZeroedDate {
  let ms = date.setUTCHours(2, 0, 0, 0);
  let convertedDate = new Date(ms);
  assertZaZeroedDate(convertedDate);
  return convertedDate;
}

export function convertDateToZaZeroIsoString(date: Date): string {
  let convertedDate = convertDateToZaZero(date);
  // let isoString = convertedDate.toISOString();
  let isoString = convertedDate.toLocaleString('en-ZA');
  return isoString;
}

//----------------------------------------------------------------------------------------------------------------------

export function startOfTodayAtZaZeroIsoString(): string {
  return convertDateToZaZeroIsoString(startOfTodayAtUtcZero());
}
