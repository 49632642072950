import { Component, css, html } from '../../elements';

/**
 * A link control that allows a user to indicate an action to be executed.
 * 
 * Typically used for navigational purposes.
 *
 * ```js 
 * import 'platform/components/buttons/Hyperlink'; 
 * ```
 * 
 * ```html
 * <capitec-hyperlink 
 *   href="https://example.com"
 *   label="Click me">
 * </capitec-hyperlink>
 * ```
 * 
 * @prop {"_self`"|"_blank`"|"_parent`"|"_top`"|String} target - Where to load the URL specified in "href"
 */
export class Hyperlink extends Component {

	// --------------
	// INITIALISATION
	// --------------

	/**
	 * Initialises the component.
	 *
	 * @hideconstructor
	 */
	constructor() {

		super();

		// Set the default property values.
		this.label = null;
		this.href = null;
		this.target = `_self`;
		this.disabled = false;
	}

	/**
	 * Registry of all properties defined by the component.
	 *
	 * @property {String} label - The label string to display.
	 * @property {String} [href] - URL to link to. NOTE, suppresses "click" event if specified.
	 * @property {String} [target="_self"] - Where to load the URL specified in "href":
	 *  - `_self` Current browsing context.
	 *  - `_blank` Usually a new tab, but users can configure browsers to open a new window instead.
	 *  - `_parent` Parent browsing context of the current one. If no parent, behaves as "_self".
	 *  - `_top` Topmost browsing context (the "highest" context that’s an ancestor of the current one). If no ancestors, behaves as "_self".
	 * 
	 * @property {Boolean} [disabled=false] - Indicator if the button is disabled.
	 */
	static get properties() {
		return {
			label: { type: String },
			href: { type: String },
			target: { type: String },
			disabled: { type: Boolean }
		};
	}

	// -------------------
	// LIFECYCLE OVERRIDES
	// -------------------

	// n/a	

	// --------------
	// EVENT HANDLERS
	// --------------

	/**
	 * Handles component click events.
	 * 
	 * @param {MouseEvent} event - The event details.
	 * 
	 * @ignore
	 * @returns {void}
	 */
	_linkClicked(event) {

		// Ignore the event if the component is disabled.
		if (this.disabled) {
			return event.stopImmediatePropagation();
		}

		// Notify any subscribers that the link was clicked.
		this.dispatchEvent(new CustomEvent(`click`, {
			detail: {}
		}));

		// Prevent the event from bubbling up.
		event.stopPropagation();
	}

	// --------------
	// PUBLIC METHODS
	// --------------

	// n/a

	// ---------------
	// PRIVATE METHODS
	// ---------------

	// n/a

	// ---------
	// RENDERING
	// ---------

	/**
	 * Generates the component stylesheet.
	 * 
	 * @ignore
	 * @returns {css} The css content of the component.
	 */
	static get styles() {

		return [
			super.styles,
			css`
				:host {
					display: inline-block;
				}
				
				.hyperlink {
					font-size: var(--theme-hyperlink-font-size, 16px);
					font-family: var(--theme-font-family, "Hind Vadodara");
					font-weight: var(--theme-font-weight, 500);
					color: var(--theme-hyperlink-color, #009DE0);
					text-decoration: none;
					outline: none;
				}

				.hyperlink:hover {
					text-decoration: underline;
				}
			`
		];
	}

	/**
	 * Generates the component template for mobile mode.
	 * 
	 * @returns {html} The html content of the component.
	 */
	_mobileTemplate() {

		if (this.href) {
			return html`<a class="hyperlink" href="${this.href}" .target="${this.target}" tabindex="0">${this.label}</a>`;
		}

		return html`<a class="hyperlink" href="javascript:void(0)" @click="${e => this._linkClicked(e)}" .target="${this.target}" tabindex="0">${this.label}</a>`;
	}

	/**
	 * Generates the component template for web mode.
	 * 
	 * @returns {html} The html content of the component.
	 */
	_webTemplate() {

		if (this.href) {
			return html`<a class="hyperlink" href="${this.href}" .target="${this.target}" tabindex="0">${this.label}</a>`;
		}

		return html`<a class="hyperlink" href="javascript:void(0)" @click="${e => this._linkClicked(e)}" .target="${this.target}" tabindex="0">${this.label}</a>`;
	}

	/**
	 * Generates the component template for kiosk mode.
	 * 
	 * @returns {html} The html content of the component.
	 */
	_kioskTemplate() {

		if (this.href) {
			return html`<a class="hyperlink" href="${this.href}" .target="${this.target}" tabindex="0">${this.label}</a>`;
		}

		return html`<a class="hyperlink" href="javascript:void(0)" @click="${e => this._linkClicked(e)}" .target="${this.target}" tabindex="0">${this.label}</a>`;
	}
}

window.customElements.define(`capitec-hyperlink`, Hyperlink);

/**
 * When the hyperlink is clicked. NOTE this is suppressed if "href" is specified.
 *
 * @example
 * <capitec-hyperlink ... @click="${this._handler}"></capitec-hyperlink>
 *
 * @event Hyperlink#click
 * @type {Object}
 * @property {Object} detail Empty object.
*/
