//@ts-nocheck

var Mustache = require('mustache');

import { extendedValidator as validator } from '@/sharedUtils/LdbValidations.ts';

window.validator = validator;

export const db_validator = {
  ...validator,
};

export function validate(values, record) {
  let res = {};
  for (let f in values) {
    let value = values[f];
    if (value !== undefined && value !== '') {
      let field = record.definition.indexed_fields[f];
      try {
        let validate_field_res = validate_field(field, value, record);
        if (validate_field_res !== undefined) {
          res[field.name] = validate_field_res;
        }
      } catch (err) {
        res[field.name] = err.message;
      }
    }
  }
  return res;
}

export function validate_field(field, value, record) {
  let res;
  if (value !== undefined && value !== '' && value !== null) {
    let valueString = value;
    if (typeof value !== 'string') {
      valueString = value.toString();
    }
    let validators = field.validators;
    if (validators !== undefined) {
      for (let v in validators) {
        let validator = validators[v];
        try {
          let validation_result = validate_validator(validator, valueString, record);
          if (validation_result !== true) {
            if (res === undefined) {
              res = {};
            }
            res[v] = validation_result;
          }
        } catch (err) {
          if (res === undefined) {
            res = {};
          }
          res[field.name] = err.message;
        }
      }
    }
  }
  return res;
}

function replace_objecct(msg, record, value) {
  let res = {};
  const re = /{{([a-zA-Z0-9_]+)}}/g;
  while (true) {
    let match = re.exec(msg);
    if (match === null) {
      break;
    } else {
      let tag = match[1];
      if (tag === 'value') {
        res.value = value;
      } else {
        res[tag] = record[tag];
      }
    }
  }
  return res;
}

export function validate_validator(validator, value, record) {
  if (value === null || value === undefined || value == '') {
    return true;
  }
  var validateResult;
  if (validator.name === 'custom') {
    validateResult = validator.args.vFunction(value, record);
  } else {
    validateResult = validator.validate(value, record);
  }
  if (validateResult) {
    return true;
  } else {
    let replaceObject = replace_objecct(validator.msg, record, value);
    return Mustache.render(validator.msg, replaceObject);
  }
}

if (process.env.NODE_ENV === 'development') {
  // window.validate = validate;
}
