<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <div v-if="mounted">
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxAddBranch',
  components: { LDBField },
  props: {},
  data() {
    return {
      mounted: false,
      newRecord: {},
    };
  },
  created() {
    this.newRecord = this.definition.class(
      {
        Table: 'LfxAccount',
        definition_name: 'intermediaryBranch',
        collection_path: '/account',
        accountId: this.initiatingAccountId,
        accountLevel: 'intermediaryBranch',
      },
      this.$store
    );
  },
  mounted() {
    this.populateFieldDefaults();
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['authUser','account']),
    header() {
      return 'Add a Branch';
    },
    initiatingAccountId() {
      return +(this.$route.params.accountId || this.authUser.accountId);
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelAddBranch',
          label: 'Cancel',
          type: '',
          action: 'click',
          clickEvent() {
            // self.$router.replace({ name: self.cancelRoute });
            self.$router.go(-1);
          },
        },
        {
          name: 'addBranch',
          label: 'Add Branch',
          type: 'primary',
          action: 'createBranch',
          actionParams: this.newRecord,
          params: this.newRecord,
          enabledGuards: !(this.newRecord.has_validation_errors || this.requiredFieldsRemaining),
          clickEvent(response) {
            if (response.status === 'success') {
              self.$router.replace({ name: 'accountDetailsView', params: { accountId: response.response.id } });
            }
          },
        },
      ];
      // return allButtons?.filter((button) => button.guards) !== undefined
      //   ? allButtons?.filter((button) => button.guards)
      //   : [];
      return allButtons;
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute
        ? this.$route.meta.modal.cancelRoute
        : this.$route.meta.cancelRoute
        ? this.$route.meta.cancelRoute
        : '/'; //TODO: go to user's home as fallback
    },

    // ----- Definition -----

    definition() {
      return getDefinition('intermediaryBranch');
    },
    table() {
      return 'LfxAccount';
    },

    // ----- Record -----

    recordName() {
      return 'account';
    },
    recordId() {
      return +this.$route.params.accountId;
    },
    record() {
      return this.$store.state?.[this.recordName]?.[this.recordId];
    },

    // ----- Fields -----

    // fields() {
    //   return this.definition.fields;
    // },
    fields() {
      return this.newRecord.guarded_fields;
    },
    viewFields() {
      let res = [];
      for (let k in this.fields) {
        let field = this.fields[k];
        if (field.views.addBranch === true) {
          field.fieldname = k;
          res.push(field);
        }
      }
      return res;
    },
    fieldnames() {
      return this.viewFields.map((field) => field.name);
    },
    requiredFieldsRemaining_list() {
      return this.newRecord.required_fields_remaining.filter((f) => this.fieldnames.indexOf(f) >= 0);
    },
    validationErrors() {
      return this.newRecord.validation_errors;
    },
    requiredFieldsRemaining() {
      return this.newRecord.required_fields_remaining.filter((f) => this.fieldnames.indexOf(f) >= 0).length > 0;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Fields -----

    populateFieldDefaults() {
      for (let f = 0; f < this.viewFields.length; f++) {
        let field = this.viewFields[f];
        if (field.default) {
          this.newRecord[field.name] = field.default;
        }
      }
      this.mounted = true;
    },
    on_change({ field, value }) {
      this.newRecord[field] = value;
    },

    // ----- API calls -----

    // async addBranch() {
    //   [this.newRecord].id = this.initiatingAccountId;
    //   let response = await this.account[this.initiatingAccountId].addBranch(this.newRecord);
    //   if (response?.id) {
    //     this.$router.replace({ name: 'accountDetailsView', params: { accountId: response.id } });
    //   }
    // },
  },
};
</script>
