<template>
  <LDBItemView
    v-if="$route.name === 'newTransactionOfflineDealCapture'"
    :table="table"
    :definition="definition"
    :collection_path="collectionPath"
    :createButtonLabel="createButtonLabel"
    action="captureOfflineDeal"
    view="edit"
    fieldView="offlineDealCapture"
    :showButtons="true"
    :doSubmit="false"
    :params="{ accountId: accountId }"
    :record="dealRecord"
    @on-create="onCreateOfflineDealClick"
    @on-api-success="onApiSuccess"
    :unlinkedMode="true"
    providedTitle="Capture Deal Details"
    :providedFields="providedFields"
    :providedGroups="groups"
  />
  <LDBItemView
    v-else
    :record="dealRecord"
    view="item"
    fieldView="offlineDealCaptureReview"
    :showButtons="true"
    :showBackButton="true"
    saveButtonCaption="Capture"
    action="captureOfflineDeal"
    :checkMandatoryFields="false"
    @on-back="onBack"
    @on-api-success="onApiSuccess"
    @on-api-error="onApiError"
  />
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBCreateView from '@/landobyte_vue/LDBCreateView.vue';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import { calculateCounterAmount, calculateMarkupsAndRates, getCurrencyType } from '@/sharedUtils/LfxTradeCalc';
import { standardTextWhiteList } from '@/sharedUtils/LdbValidations';

export default {
  name: 'LfxNewOfflineDealDetails',
  components: { LDBCreateView, LDBItemView },
  props: {},
  emits: ['step-back', 'step-next', 'allow-review'],
  data() {
    return {
      dealRecord: { accountId: +this.$route.params.accountId },
    };
  },
  created() {
    void this.accountRecord?.parentMarkups;
  },
  mounted() {
    this.dealRecord = { accountId: this.accountId };
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['config', 'account']),

    // ----- Account record -----

    accountId() {
      return +this.$route.params.accountId;
    },
    accountRecord() {
      return this.account[this.accountId];
    },

    // ----- Deal definition -----

    definition() {
      return getDefinition('deal');
    },
    collectionPath() {
      return `/account/${this.accountId}/deal`;
    },
    table() {
      return 'LfxDeal';
    },

    // ----- Form Buttons -----

    createButtonLabel() {
      return this.$route.meta?.content?.formButtons?.createButtonLabel;
    },

    // ----- Calculations -----

    parentMarkupStatus() {
      return this.accountRecord?.parentMarkupStatus;
    },
    accountMarkups() {
      let accountMarkups = [];
      if (this.currencyPair) {
        let parentMarkups = this.accountRecord?.parentMarkups;
        if (this.parentMarkupStatus === 'retrieved') {
          accountMarkups = parentMarkups?.[this.currencyPair.id];
        }
      }
      const allAccountIds = [this.accountRecord.id];
      for (const id of this.accountRecord.parentAccountIds) {
        allAccountIds.push(id);
      }
      if (Array.isArray(accountMarkups)) {
        for (let i = 0; i < allAccountIds.length; i++) {
          if (allAccountIds[i] !== 1) {
            let markupFound = false;
            for (const markup of accountMarkups) {
              if (markup.accountId === allAccountIds[i]) {
                markupFound = true;
              }
            }
            if (!markupFound && !!allAccountIds[i + 1]) {
              accountMarkups.push({
                accountId: allAccountIds[i],
                parentId: allAccountIds[i + 1],
                markupType: 'pips',
                pips: 0,
              });
            }
          }
        }
      }
      return accountMarkups;
    },

    // ----- Definition Replacements -----

    currencyPairOptions() {
      const res = [];
      for (const currencyPairId in this.config?.[1].currencyPair) {
        res.push({
          id: currencyPairId,
          name: this.config?.[1].currencyPair[currencyPairId].name,
          index: this.config?.[1].currencyPair[currencyPairId].index,
        });
      }
      res.sort((currencyPair1, currencyPair2) => {
        return currencyPair1.index - currencyPair2.index;
      });
      return res;
    },
    currencyPair() {
      if (!this.dealRecord.currencyPair) {
        return undefined;
      }
      return this.config?.[1].currencyPair[this.dealRecord.currencyPair];
    },
    currencyOptions() {
      const res = [];
      if (this.currencyPair) {
        res.push({ id: this.currencyPair.baseCurrency, name: this.currencyPair.baseCurrency });
        res.push({ id: this.currencyPair.quoteCurrency, name: this.currencyPair.quoteCurrency });
      }
      return res;
    },
    selectedDeliveryType() {
      return this.dealRecord.deliveryType;
    },
    fromSettlementAccounts() {
      if (!this.currencyPair || !this.dealRecord.currency || !this.dealRecord.action) {
        return [];
      }
      let accountCurrency;
      if (this.dealRecord.action === 'Buy') {
        accountCurrency = this.getCounterCurrency();
      } else {
        accountCurrency = this.dealRecord.currency;
      }
      return this.accountRecord.filter_bankAccounts(undefined, {
        currency: accountCurrency,
        includeAuthUserBankAccounts: false,
        bankAccountTypes: 'own',
        status: undefined,
      });
    },
    toSettlementAccounts() {
      if (!this.currencyPair || !this.dealRecord.currency || !this.dealRecord.action) {
        return [];
      }
      let accountCurrency;
      if (this.dealRecord.action === 'Buy') {
        accountCurrency = this.dealRecord.currency;
      } else {
        accountCurrency = this.getCounterCurrency();
      }
      return this.accountRecord.filter_bankAccounts(undefined, {
        currency: accountCurrency,
        includeAuthUserBankAccounts: false,
        bankAccountTypes: 'own',
        status: undefined,
      });
    },
    fromSettlementAccountOptions() {
      const res = [{ id: undefined, name: 'NOSTRO ACCOUNT' }];
      for (const bankAccountId in this.fromSettlementAccounts) {
        res.push({ id: bankAccountId, name: this.fromSettlementAccounts[bankAccountId].accountNumber });
      }
      return res;
    },
    toSettlementAccountOptions() {
      const res = [{ id: undefined, name: 'NOSTRO ACCOUNT' }];
      for (const bankAccountId in this.toSettlementAccounts) {
        res.push({ id: bankAccountId, name: this.toSettlementAccounts[bankAccountId].accountNumber });
      }
      return res;
    },

    groups() {
      return {
        system: { priority: 1, label: 'General' },
        treasury: { priority: 2, label: 'Treasury' },
        rates: { priority: 3, label: 'Rates' },
        fec: { priority: 4, label: 'FEC' },
        integration: { priority: 5, label: 'Integration' },
        changes: { priority: 7, label: 'Changes' },
      };
    },

    providedFields() {
      const allFields = [
        {
          name: 'dealNumber',
          label: 'Deal Number',
          datatype: 'text',
          default: null,
          mandatory: true,
          validators: {
            is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
            len: { msg: 'Deal Number should be between 5 and 20 characters', args: [5, 20] },
          },
          group: 'treasury',
        },
        {
          name: 'currencyPair',
          label: 'Currency Pair',
          datatype: {
            option: {
              optionType: 'text',
              options: this.currencyPairOptions,
            },
          },
          default: null,
          mandatory: true,
          group: 'treasury',
        },
        {
          name: 'action',
          label: 'Client Action',
          displayType: {
            datatype: 'option',
            display: 'radio',
          },
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'Buy', name: 'Client Buys' },
                { id: 'Sell', name: 'Client Sells' },
              ],
            },
          },
          default: null,
          mandatory: true,
          group: 'treasury',
        },
        {
          name: 'currency',
          label: 'Currency',
          datatype: {
            option: {
              optionType: 'text',
              options: this.currencyOptions,
            },
          },
          default: null,
          mandatory: true,
          group: 'treasury',
        },
        {
          name: 'amount',
          label: 'Amount',
          datatype: { currency: { symbol: this.dealRecord.currency || '' } },
          default: null,
          mandatory: true,
          group: 'treasury',
        },
        {
          name: 'deliveryType',
          label: 'Delivery Type',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'Cash', name: 'Value Today', tagType: 'info' },
                { id: 'Tom', name: 'Value Tomorrow', tagType: 'success' },
                { id: 'Spot', name: 'Spot', tagType: 'info' },
                { id: 'FWD', name: 'Forward', tagType: 'warning' },
              ],
            },
          },
          default: null,
          mandatory: true,
          displayType: {
            datatype: 'option',
            display: 'radio',
          },
          group: 'treasury',
        },
        {
          name: 'forwardContractType',
          label: 'Forward Contract Type',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'fixed', name: 'Fixed' },
                { id: 'optional', name: 'Optional' },
                { id: 'partiallyOptional', name: 'Partially Optional' },
              ],
            },
          },
          displayType: {
            datatype: 'option',
            display: 'radio',
          },
          default: null,
          mandatory: true,
          guards: this.dealRecord.deliveryType === 'FWD',
          group: 'treasury',
        },
        {
          name: 'optionStartDate',
          label: 'Option Start Date',
          datatype: {
            datetime: {
              type: 'date',
              format: 'human',
              minDate: { type: 'day', offset: 0 },
              maxDate: { type: 'year', offset: 2 },
            },
          },
          default: null,
          mandatory: true,
          guards: this.dealRecord.deliveryType === 'FWD' && this.dealRecord.forwardContractType === 'partiallyOptional',
          group: 'treasury',
        },
        {
          name: 'valueDate',
          label: 'Value Date',
          datatype: {
            datetime: {
              type: 'date',
              format: 'human',
              minDate: { type: 'day', offset: 0 },
              maxDate: { type: 'year', offset: 2 },
            },
          },
          displayType: {
            displayAsTag: true,
            tagTypeFunctionName: 'valueDateTagType',
          },
          default: null,
          mandatory: true,
          group: 'treasury',
        },
        {
          name: 'fromSettlementAccountId',
          label: 'From Settlement Account',
          datatype: {
            option: {
              optionType: 'text',
              options: this.fromSettlementAccountOptions,
            },
          },
          default: null,
          mandatory: false,
          group: 'treasury',
        },
        {
          name: 'toSettlementAccountId',
          label: 'To Settlement Account',
          datatype: {
            option: {
              optionType: 'text',
              options: this.toSettlementAccountOptions,
            },
          },
          default: null,
          mandatory: false,
          group: 'treasury',
        },
        {
          name: 'bankRate',
          label: 'Bank Rate',
          datatype: 'float',
          displayType: { float: { decimalPlaces: 7 } },
          default: null,
          mandatory: true,
          group: 'rates',
        },
        {
          name: 'clientRate',
          label: 'Deal Rate',
          datatype: 'float',
          displayType: { float: { decimalPlaces: 7 } },
          default: null,
          mandatory: true,
          group: 'rates',
        },
        {
          name: 'forwardPoints',
          label: 'Forward Points',
          datatype: 'float',
          default: null,
          mandatory: false,
          allowNullValues: true,
          guards: this.dealRecord.deliveryType === 'FWD',
          group: 'rates',
        },
      ];
      const res = [];
      for (const field of allFields) {
        if (field.guards !== false) {
          res.push(field);
        }
      }
      return res;
    },
  },
  watch: {
    selectedDeliveryType(newValue, oldValue) {
      if (this.currencyPair) {
        this.dealRecord.valueDate = this.getValueDate();
      }
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    async onCreateOfflineDealClick(payload) {
      // FIXME: changes to LDBCreateView probably going to break this
      payload.sync();
      this.dealRecord = payload;
      const updateObject = this.getUpdateObject();

      for (const f in updateObject) {
        this.dealRecord[f] = updateObject[f];
        this.dealRecord.data[f] = updateObject[f];
      }

      this.$emit('allow-review', payload);
      this.$emit('step-next', payload);
    },
    async onApiSuccess(responseObject) {
      this.$router.replace({
        name: 'transactionSummaryView',
        params: { transactionId: responseObject.response.id },
      });
    },
    onBack(evt) {
      this.$emit('step-back', evt);
    },
    async onApiError(responseObject) {
      this.$emit('step-back', responseObject);
    },

    // ----- Calculations -----
    getCounterCurrency() {
      let currency = this.dealRecord?.currency;
      let counterCurrency = null;
      if (currency === this.currencyPair.quoteCurrency) {
        counterCurrency = this.currencyPair.baseCurrency;
      } else if (currency === this.currencyPair.baseCurrency) {
        counterCurrency = this.currencyPair.quoteCurrency;
      }
      return counterCurrency;
    },
    getCurrencyType() {
      let currency = this.dealRecord?.currency;
      const currencyPair = thicurrencyPair ? getCurrencyType(currency, this.currencyPair) : null;
    },

    getCounterAmount() {
      let amount = +this.dealRecord?.amount;
      let rate = +this.dealRecord.clientRate;
      let currencyType = this.getCurrencyType();
      let decimals = 2;
      return amount && rate && currencyType ? calculateCounterAmount(amount, rate, currencyType, decimals) : null;
    },
    getValueDate() {
      if (this.dealRecord?.deliveryType === 'Cash') {
        return new Date();
      } else if (this.dealRecord?.deliveryType === 'Tom') {
        return this.currencyPair.tomDate;
      } else if (this.dealRecord?.deliveryType === 'Spot') {
        return this.currencyPair.spotDate;
      } else if (this.dealRecord?.deliveryType === 'FWD') {
        return this.dealRecord.valueDate;
      }
    },
    getMarkupReturn() {
      const markupObject = {
        currencyType:
          this.dealRecord.currency && this.currencyPair
            ? getCurrencyType(this.dealRecord.currency, this.currencyPair)
            : null,
        action: this.dealRecord.action,
        valueDate: this.dealRecord.valueDate,
        markupType: 'fixedSpread',
        bankRate: +this.dealRecord.bankRate,
        clientRate: +this.dealRecord.clientRate,
        forwardPoints: +this.dealRecord.forwardPoints,
        accountMarkups: this.accountMarkups,
      };
      return calculateMarkupsAndRates(markupObject);
    },
    getUpdateObject() {
      const markupReturn = this.getMarkupReturn();
      return {
        valueDate: this.getValueDate(),
        spotRate: markupReturn.spotRate,
        bench: markupReturn.bench,
        counterCurrency: this.getCounterCurrency(),
        counterAmount: this.getCounterAmount(),
      };
    },
  },
};
</script>
