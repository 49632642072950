<template>
  <div>
    <LDBItemView
    :record="record"
    :definition="definition"
    view="item"
    :showNextButton="false"
    :action="undefined"
    :params="undefined"
  />  </div>
  <router-view></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import { LfxSystemSettings } from '@/models/LfxSystemSettingsMdl';

// getRatesFeedStatus
// restartRatesFeed
// enableTradingDealingRoom
// disableTradingDealingRoom

export default {
  name: 'LfxSystemSettings',
  components: {LDBItemView  },
  props: {},
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    record() {
      let objInstance = new LfxSystemSettings(this.systemSettingsRecord, this.$store)
      return objInstance 
    },
    systemSettingsGroups() {
      return ['system','trading','mfa','onboarding','general','slowPortolioMonitor','permissionsRequest','accountCreation','dealOfflineCreate','refinitivNotification','administration','userReview','featureEnable','odinEnable',]
    },
  

    systemSettingsRecord() {
      const req = {Table:'LfxSystemSettings',collection_path:'systemSettings'};
      for (const settingsGroup of this.systemSettingsGroups) {
        for (const field in this.systemSettingsObject[settingsGroup]) {
          const combinedFieldName = settingsGroup+field.substring(0,1).toUpperCase() + field.substring(1,field.length)
          let fieldValue = this.systemSettingsObject[settingsGroup][field];
          if (['tradingTradingStartTime','tradingTradingEndTime','tradingSameDayCutOffTime'].includes(combinedFieldName)) {
            const date = new Date("1970/01/02");
            date.setUTCHours(fieldValue.h,fieldValue.m,fieldValue.s);
            fieldValue = date;
          }
          req[combinedFieldName] = fieldValue
        }
      }
      return req
    },
    systemSettingsObject() {
      return this.$store.state.systemSettings
    },
    definition() {
      return getDefinition('systemSettings');
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>

<style>
/* div.container{  } */
</style>
