<template>
  <LfxAdvancedSearch
    :definition="definition"
    :dataAction="dataAction"
    :downloadAction="downloadAction"
    :baseSelectors="baseSelectors"
    @on-search-change="onSearchChange"
  />

  <!-- <LfxSearch
    v-if="false"
    :definition="definition"
    :dataAction="dataAction"
    :downloadAction="downloadAction"
    :baseSelectors="baseSelectors"
    /> -->
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LfxAdvancedSearch from '@/views/search/LfxAdvancedSearch.vue';
import LfxSearch from '@/views/search/LfxSearch.vue';

export default {
  name: 'LfxDealSearch',
  emits:['on-search-change'],
  components: { LfxAdvancedSearch, LfxSearch },
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    
    // ----- View -----
    
    definition() {
      return getDefinition('deal');
    },
    dataAction() {
      return 'getDeals';
    },
    downloadAction() {
      return 'downloadDeals';
    },
    baseSelectors() {
      return {};
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    onSearchChange(searchChangeData) {
      this.$emit('on-search-change',{downloadAction:this.downloadAction,searchCriteria:searchChangeData});
    }
  },
};
</script>
