//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2022 LandoByte (Pty) Ltd.
//  File        : LfxNavigationOnlyActions.ts
//  Author      : Abraham van Rooyen
//  Description : This file defines the account levels and permissions required for Navigation actions in the LandoFX System
//  Created     : 21 February 2021 by Abraham van Rooyen
//----------------------------------------------------------------------------------------------------------------------

import { LdbApiAccountLevel, LdbApiPermission, LdbAPIAction } from './LdbActionTypes';
import { editAccount, hideAccount, unhideAccount } from './LfxAdminActions'; //getAccounts

type LfxNavAction = {
  description: string;
  accountLevels?: LdbApiAccountLevel[];
  permissions?: LdbApiPermission[];
};

//----------------------------------------------------------------------------------------------------------------------
// RATES FEED
//----------------------------------------------------------------------------------------------------------------------

export const ratesFeedRetrieve: LfxNavAction = {
  description: 'Frontend guard for connecting to the rates feed',
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: ['viewRatesFeed', 'bookDeal', 'bookFec', 'clientBookDeal'],
};
export const ratesFeedView: LfxNavAction = {
  description: 'Frontend guard for viewing the rates feed',
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: ['viewRatesFeed'],
};

//----------------------------------------------------------------------------------------------------------------------
// MENU
//----------------------------------------------------------------------------------------------------------------------

export const clientsMenu: LfxNavAction = {
  description: 'Menu guard for Clients menu item',
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: [],
};
export const intermediariesMenu: LfxNavAction = {
  description: 'Menu guard for Intermediaries menu item',
  accountLevels: ['bank'],
  permissions: [],
};
export const usersMenu: LfxNavAction = {
  description: 'Menu guard for Users menu item',
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageUser','releasePermission'],
};
export const beneficiariesMenu: LfxNavAction = {
  description: 'Menu guard for Beneficiaries menu item',
  accountLevels: ['client'],
  permissions: ['manageBeneficiary'],
};
export const adminMenu: LfxNavAction = {
  description: 'Menu guard for Admin menu item',
  accountLevels: ['bank'],
  permissions: ['releasePermission', 'configureSystem'], // TODO
};
export const configMenu: LfxNavAction = {
  description: 'Menu guard for Config menu item',
  accountLevels: ['bank'],
  permissions: ['configureSystem'], // TODO
};
export const downloadMenu: LfxNavAction = {
  description: 'Menu guard for Upload and Download menu item',
  accountLevels: ['intermediary'],
  permissions: [],
};

//----------------------------------------------------------------------------------------------------------------------
// ADMIN PATHS
//----------------------------------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------------------------------
// ACCOUNT PATHS
//----------------------------------------------------------------------------------------------------------------------

export const editIntermediary: LdbAPIAction = {
  ...editAccount,
  description: 'Frontend only navigation action to distinguish between accountLevels for editAccount API action',
  accountLevels: ['bank'],
  permissions: ['manageIntermediary'],
};
export const editBranch: LdbAPIAction = {
  ...editAccount,
  description: 'Frontend only navigation action to distinguish between accountLevels for editAccount API action',
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageBranch'],
};
export const editClient: LdbAPIAction = {
  ...editAccount,
  description: 'Frontend only navigation action to distinguish between accountLevels for editAccount API action',
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageClient'],
};

export const hideIntermediary: LdbAPIAction = {
  ...hideAccount,
  description: 'Frontend only navigation action to distinguish between accountLevels for hideAccount API action',
  accountLevels: ['bank'],
  permissions: ['manageIntermediary'],
};
export const unhideIntermediary: LdbAPIAction = {
  ...unhideAccount,
  description: 'Frontend only navigation action to distinguish between accountLevels for unhideAccount API action',
  accountLevels: ['bank'],
  permissions: ['manageIntermediary'],
};

export const hideIntermediaryBranch: LdbAPIAction = {
  ...hideAccount,
  description: 'Frontend only navigation action to distinguish between accountLevels for hideAccount API action',
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageBranch'],
};
export const unhideIntermediaryBranch: LdbAPIAction = {
  ...unhideAccount,
  description: 'Frontend only navigation action to distinguish between accountLevels for unhideAccount API action',
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageBranch'],
};

export const hideClient: LdbAPIAction = {
  ...hideAccount,
  description: 'Frontend only navigation action to distinguish between accountLevels for hideAccount API action',
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageClient'],
};
export const unhideClient: LdbAPIAction = {
  ...unhideAccount,
  description: 'Frontend only navigation action to distinguish between accountLevels for unhideAccount API action',
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageClient'],
};

//----------------------------------------------------------------------------------------------------------------------
// USER PATHS
//----------------------------------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------------------------------
// CONFIG PATHS
//----------------------------------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------------------------------
// TRANSACTION PATHS
//----------------------------------------------------------------------------------------------------------------------

export const bookFec: LfxNavAction = {
  description: 'Allows booking of a FEC deal',
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['bookFec'],
};
export const overrideMarkup: LfxNavAction = {
  description: 'Allows overriding of markups on deal booking screen',
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['overrideMarkup'],
};
export const freezeExchangeRate: LfxNavAction = {
  description: 'Allows freezing of exchange rate on deal booking screen',
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['freezeExchangeRate'],
};

//----------------------------------------------------------------------------------------------------------------------
// EXPORT
//----------------------------------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------------------------------
