<template>
  <div class="lfx-widget-container open-payments" :style="spanStyle" :class="dynamicClass">
    <LfxWidgetHeader
      :header="`Payments Requiring Attention (${paymentCount})`"
      :subheader="dateRange"
      :link="allRecordsLink"
    />
    <template v-if="paymentCount > 0">
      <LfxWidgetRow
        v-for="payment in pagedItems"
        :key="payment.id"
        :title="`${payment.reference} - ${paymentClientName(payment)}`"
        :subtitle="paymentDate(payment)"
        :info="`${payment.paymentCurrency} 
        ${formatCurrency(payment.paymentAmount)}`"
        :avatar="paymentClientName(payment)"
        :avatarColor="avatarColor(payment)"
        :link="paymentLink(payment)"
      />
      <LfxWidgetScroller
        :totalItems="items.length"
        :itemsPerPage="itemsPerPage"
        :currentPage="pageNumber"
        @on-page="onPage"
      />
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LfxWidgetHeader from './LfxWidgetHeader.vue';
import LfxWidgetRow from './LfxWidgetRow.vue';
import LfxWidgetScroller from './LfxWidgetScroller.vue';
import { colorFromId } from '@/landobyte_vue/ldb_utils.ts';

import lfx_widget_mixin from './lfx_widget_mixin';

export default {
  mixins: [lfx_widget_mixin],

  components: {
    LfxWidgetHeader,
    LfxWidgetRow,
    LfxWidgetScroller,
  },

  props: {},

  data() {
    return {
      itemsPerPage: 4,
      pageNumber: 1,
    };
  },

  created() {},

  mounted() {},

  computed: {
    ...mapGetters([]),
    ...mapState(['payment', 'account', 'authUser']),
    allRecordsLink() {
      return { name: '_paymentsRequireAttention' };
    },
    items() {
      return this.openPayments;
    },
    // mostRecentPayments() {

    //   let res = [];
    //   let startItem = ( this.pageNumber -1 ) * this.itemsPerPage;
    //   for ( let p = 0 ; p < this.openPayments.length ; p ++  ) {
    //     let payment = this.openPayments[p];
    //     if (p >= startItem) {
    //       res.push(payment);
    //     }
    //     if (res.length === this.itemsPerPage) {
    //       break;
    //     }
    //   }
    //   return res;
    // },
    openPayments() {
      let statusses;
      if (this.authUser.accountLevel === 'bank') {
        statusses = ['signed', 'awaitingSettlement', 'settled', 'awaitingFunds'];
      } else {
        statusses = ['new', 'readyForSubmission', 'readyForSigning'];
      }
      return Object.values(this.payment)
        .filter((payment) => statusses.indexOf(payment.status) > -1)
        .sort((a, b) => new Date(a.mostRecent).getTime() - new Date(b.mostRecent).getTime());
    },
    paymentCount() {
      return this.openPayments.length;
    },
    minDate() {
      if (this.openPayments?.[0]?.mostRecent.split) {
        return this.openPayments?.[0]?.mostRecent?.split('T')[0];
      }
      return undefined;
    },
    maxDate() {
      if (this.openPayments?.[this.openPayments?.length - 1]?.mostRecent.split) {
        return this.openPayments?.[this.openPayments?.length - 1]?.mostRecent?.split('T')[0];
      }
      return undefined;
    },
    dateRange() {
      if (this.minDate === this.maxDate) {
        return this.minDate;
      } else {
        return `${this.minDate} to ${this.maxDate}`;
      }
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    paymentClientName(deal) {
      let clientId = deal.accountId;
      let clientName = this?.account?.[clientId]?.name || '';
      return clientName;
    },
    avatarColor(deal) {
      return colorFromId(deal.accountId);
    },
    paymentDate(payment) {
      if (payment.mostRecent?.split) {
        return `${payment.mostRecent?.split('T').join(' ').split('.')[0]}`;
      }
      return undefined;
    },
    paymentLink(payment) {
      // return `/transaction/${payment.transactionFileId}/payment/${payment.id}`;
      return this.payment?.[payment?.id]?.link;
    },
  },
};
</script>
<style></style>
