<template>
  <LFXContent
    :titleBar="titleBar"
    :show_tabs="showTabs"
    :tabs="tabs"
    :show_actions="true"
    :show_label="showLabel"
    :label="contentLabel"
    :show_sidebar="false"
  >
    <template #actions>

      <LDBButton
        v-for="button in actionBarButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :iconSize="button.iconSize"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        :onSuccess="button.onSuccess"
        @click="button.clickEvent"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>

    <template #content>
      <router-view v-if="actionGroup === 'swifts'" :swiftFilter="swiftFilter"></router-view>
      <router-view v-else @on-search-change="onSearchChange"></router-view>
    </template>
  </LFXContent>

  <router-view name="modal"></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import LFXContent from '@/components/layout/LfxContent.vue';
import LFXListView from '@/views/general/LFXListView.vue';

export default {
  name: 'LfxTransactions',
  components: { LFXListView, LFXContent },
  props: {},
  data() {
    return {
      tableSearchCriteria:{},
      swiftFilter: 'open',
    };
  },
  created() {
    if (this.$route.name === 'transactions') {
      this.selectFirstTab();
    }
  },
  mounted() {},
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'transactions') {
      next(false);
      this.selectFirstTab();
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters(['isDevelopment', 'permissions']),
    ...mapState(['authUser', 'account']),

    // ----- Title Bar -----

    titleBar() {
      const titleBar = {
        label: 'Transactions',
        buttons: this.titleBarButtons,
      };
      return titleBar;
    },
    titleBarButtons() {
      let allButtons = [
        // {
        //   name: 'newTransaction',
        //   label: 'Transact_flat',
        //   type: 'white',
        //   icon: 'product/transact-white',
        //   routeName: 'newTransaction',
        // },
        // {
        //   name: 'newTransactionTest',
        //   label: 'Transact',
        //   type: 'white',
        //   iconSize: 'extra-small',
        //   icon: 'product/transact-white',
        //   guards: this.isDevelopment, // TODO - LEAVE FOR NOW
        //   routeName: 'transactionsNewTransaction',
        // },
        {
          name: 'newTransaction',
          label: 'New Transaction',
          type: 'white',
          guards: this.newTransactaction_buttonGuard,
          routeName: 'transactionsNewTransaction',
          // routeParams: { accountId: this.transactionRecord?.accountId },
        },
      ];
      return allButtons.filter((button) => button.guards);
    },

    newTransactaction_buttonGuard() {
      // console.log(
      //   this.accountRecord?.accountIsVisible,
      //   this.accountRecord?.isClient,
      //   this.accountRecord?.tradingEnabled,
      //   this.permissions?.bookDeal || this.permissions?.clientBookDeal || this.permissions?.bookTransfer,
      //   !this.accountRecord?.restrictedView
      // );
      return (
        this.accountRecord?.accountIsVisible &&
        this.accountRecord?.isClient &&
        this.accountRecord?.tradingEnabled &&
        (this.permissions?.bookDeal || this.permissions?.clientBookDeal || this.permissions?.bookTransfer) &&
        !this.accountRecord?.restrictedView
      );
    },

    // ----- Tabs -----

    showTabs() {
      return true;
    },
    tabs() {
      let tabs = [
        {
          tabName: 'transactionFiles',
          routeName: 'transactionsTransactionsList',
          guards: true,
          label: 'Transaction Files',
        },
        {
          tabName: 'swifts',
          routeName: 'tranasactionsSwiftsList',
          guards: true,
          label: 'Inward SWIFTs',
        },
        {
          tabName: 'deals',
          routeName: 'transactionsDealsList',
          guards: true,
          label: 'Deals',
        },
        {
          tabName: 'payments',
          routeName: 'transactionsPaymentsList',
          guards: true,
          label: 'Payments',
        },
        {
          tabName: 'failedTreasuryDeals',
          routeName: 'transactionsFailedTreasuryDealsList',
          guards: this.authUser?.accountLevel === 'bank',
          label: 'Failed Treasury Deals',
        },
      ];
      return tabs.filter((tab) => tab.guards);
    },

    // ----- Action Bar -----

    showActions() {
      return false;
    },
    actionBarButtons() {
      let self = this;
      let actions = {
        deals: [
          {
            name: 'bookOutwardDeal',
            label: '!!!Book Outward Deal for Me!!!',
            type: '',
            guards: this.isDevelopment, // TODO - Delete this
            action: 'click',
            clickEvent() {
              self.$router.push({
                name: 'newTransactionOutwardDealCapture',
                params: { accountId: self.authUser.accountId },
              });
            },
          },

          {
            name: 'downloadDeals',
            label: 'Download',
            type: '',
            guards: true,
            action: 'downloadDeals',
            params: {},
            onSuccess: { doStoreUpdate: false },
            actionParams: { additionalSelector: this.tableSelectors },
          },
        ],
        payments: [
          {
            name: 'downloadPayments',
            label: 'Download',
            type: '',
            guards: true,
            action: 'downloadPayments',
            params: {},
            onSuccess: { doStoreUpdate: false },
            actionParams: { additionalSelector: this.tableSelectors },
          },
        ],
        swifts: [
          // {
          //   name: 'setFilterToAllButton',
          //   label: 'All',
          //   type: '',
          //   guards: true,
          //   action: 'click',
          //   clickEvent: this.updateSwiftFilterToAll,
          // },
          // {
          //   name: 'setFilterToOpenButton',
          //   label: 'Open',
          //   type: '',
          //   guards: true,
          //   action: 'click',
          //   clickEvent: this.updateSwiftFilterToOpen,
          // },
          // {
          //   name: 'setFilterToAssignedButton',
          //   label: 'Assigned',
          //   type: '',
          //   guards: true,
          //   action: 'click',
          //   clickEvent: this.updateSwiftFilterToAssigned,
          // },
          // {
          //   name: 'setFilterToCompleteButton',
          //   label: 'Complete',
          //   type: '',
          //   guards: true,
          //   action: 'click',
          //   params: {},
          //   httpParams: {},
          //   clickEvent: this.updateSwiftFilterToComplete,
          // },
        ],
      };
      return actions[this.actionGroup] ? actions[this.actionGroup].filter((action) => action.guards) : [];
    },
    actionGroup() {
      return this.$route.meta?.content?.actionGroup;
    },
     tableSelectors() {
       return {...this.tableSearchCriteria} //TODO - Get this from the table
     },
    // ----- Content Label -----

    showLabel() {
      return this.contentLabel ? true : false;
    },

    contentLabel() {
      return this.$route.meta?.content?.label
        ? this.$route.meta.content.label
        : "Navigation didn't complete, please click tab above to continue";
    },
    
    // ----- Account record -----
    
    accountId() {
      return +this.authUser?.accountId;
    },
    accountRecord() {
      return this.authUser?.accountLevel === 'client' ? this.account?.[this.accountId] : {};
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    // ----- Title Bar -----
    onSearchChange(tableSearchCriteria) {
      this.tableSearchCriteria = tableSearchCriteria;
    },
    onTitleBarButtonClick(button) {
      this.$router.push({ name: button.routeName });
    },

    // ----- Tabs -----

    selectFirstTab() {
      if (this.tabs.length) {
        this.$router.replace({ name: this.tabs[0].routeName });
      }
    },
    updateSwiftFilterToAll() {
      this.swiftFilter = 'all';
    },
    updateSwiftFilterToOpen() {
      console.log('Filter is now open...');
      this.swiftFilter = 'open';
    },
    updateSwiftFilterToAssigned() {
      this.swiftFilter = 'assigned';
    },
    updateSwiftFilterToComplete() {
      this.swiftFilter = 'complete';
    },
  },
};
</script>
