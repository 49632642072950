<template>
  <div class="capitec-item-view-field json">
    <capitec-label class="capitec-item-view-field-label json" :label="`${field.label}`"></capitec-label>
    <div v-if="jsonType === 'objectArray'" class="ldb-json" :style="gridColumns">
      <template v-for="element in value" :key="element">
        <template v-for="elementField in elementFields" :key="elementField">
          <capitec-label
            type="strong"
            :class="`capitec-item-view-field-value json ${elementField}`"
            :label="`${elementValue(element, elementField)}`"
          />
        </template>
      </template>
    </div>
    <div v-else-if="jsonType === 'simple'" class="ldb-json-list">
      <ul>
        <li v-for="(element, index) in value" :key="index">
          {{ element }}
        </li>
      </ul>
    </div>
    <div v-else-if="jsonType === 'object'">
      <template v-for="(val, fld) in value" :key="fld">
        <LDBJSONObject :value="val" :label="fld" :labelConfig="field.datatype?.json?.fieldMap" />
      </template>
    </div>
  </div>
</template>

<script>
import { ldb_field_mixin } from '@/landobyte_vue/ldb_field_mixin.js';

import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  mixins: [ldb_field_mixin],
  components: {},
  props: {},
  data() {
    return {};
  },
  created() {
    // console.log("field",this.field)
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    jsonType() {
      if (Array.isArray(this.value) === true) {
        let elementTypes = Array.from(new Set(this.value.map((e) => typeof e)));
        if (elementTypes.length === 1) {
          let elementType = elementTypes[0];
          if (['number', 'string'].indexOf(elementType) >= 0) {
            return 'simple';
          } else {
            return 'objectArray';
          }
        } else {
          return 'mixed';
        }
      } else if (typeof this.value === 'object') {
        return 'object';
      } else return 'object';
    },
    gridColumns() {
      return { 'grid-template-columns': `repeat(${this.elementFieldCount},max-content)` };
    },
    elementFieldCount() {
      return this.elementFields.length;
    },
    fieldsLength() {
      let res = {};
      for (let e = 0; e < this.value.length; e++) {
        let elm = this.value[e];
        for (let f in elm) {
          let v = `${elm[f]}`;
          if (res[f] === undefined) {
            res[f] = 0;
          }
          res[f] = Math.max(v.length, res[f]);
        }
      }
      return res;
    },
    elementFields() {
      let res = [];
      for (let e = 0; e < this.value.length; e++) {
        let elm = this.value[e];
        for (let f in elm) {
          res.push(f);
        }
      }
      let self = this;
      return Array.from(new Set(res)).sort((a, b) => self.fieldsLength[a] - self.fieldsLength[b]);
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    elementValue(element, elementField) {
      return element[elementField] === undefined ? '-' : element[elementField];
    },
  },
};
</script>
<style>
div.ldb-json {
  display: grid;
  align-items: stretch;
  grid-column-gap: 10px;
  grid-row-gap: 3px;
  grid-auto-rows: 23px;
}

.capitec-item-view-field.json {
  flex-direction: column;
}
</style>
