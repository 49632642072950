<template>
  <LFXListView v-if="hasListLoaded" :definition="definition" :records="records" :defaultSort="defaultSort" />
  <template v-else>
    <LDBLoader />
  </template>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXListView from '@/views/general/LFXListView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxClientPortfolioLogList',
  components: { LFXListView, LDBLoader },
  props:  { refreshCounter: { type: Number, required: false, default: 0}},
  data() {
    return {
      hasListLoaded:false
    };
  },
  async created() {
    this.loadData()
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),
    accountId() {
      return +this.$route.params.accountId;
    },
    applicationId() {
      return +this.$route.params.applicationId;
    },
    records() {
      return this.account[this.accountId].application[this.applicationId].messageLog || {}
    },
    definition() {
      return getDefinition('client_message_log');
    },
    defaultSort() {
      return { order: 'timeStamp', sort: 'desc' }
    }
  },
  watch:{
    refreshCounter(newCount,oldCount) {
      if (newCount !== oldCount) {
        this.loadData();
      }
    }
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    async loadData() {
      this.hasListLoaded = false;
      await doPermissionAction(this,'getClientMessageLogs',{accountId:this.accountId,applicationId:this.applicationId});
      this.hasListLoaded = true;
    }
  },
};
</script>

<style>
/* div.container {
} */
</style>
