<template>
  <template v-if="allowUserToSeeButton">
    <a v-if="downloadButton" class="hidden-link" ref="download" :href="downloadUrl" :download="downloadFilename" />
    <template v-if="isModalButton">
      <LDBModal v-if="showModal" type="result" result="info" :header="`${modal.header}`" :size="modalSize">
        <template #body>
          <div>
            <slot name="modal" />
          </div>
        </template>

        <template #footer>
          <capitec-button
            :style="systemButtonStyle"
            type="clear"
            :label="modal.cancelLabel"
            @click="doShowModal(false)"
          ></capitec-button>

          <capitec-button
            :style="systemButtonStyle"
            type="primary"
            :icon="icon"
            :iconSize="iconSize"
            :iconPlacement="iconPlacement"
            :label="modal.confirmLabel"
            :id="buttonId"
            @click="click"
          ></capitec-button>
        </template>
      </LDBModal>

      <capitec-tooltip
        v-if="hasTooltip && hasPermission"
        class="lfx-tooltip"
        :message="useTooltip.message"
        :position="useTooltip.position"
        :width="useTooltip.width"
        :opened="useTooltip.opened"
      >
        <capitec-button
          :style="systemButtonStyle"
          :label="label"
          :icon="icon"
          :class="class"
          :iconSize="iconSize"
          :iconPlacement="iconPlacement"
          @click.stop="doShowModal(true)"
          :disabled="buttonDisabled"
          :type="buttonType"
        ></capitec-button>
      </capitec-tooltip>
      <capitec-button
        v-else-if="hasPermission"
        :style="systemButtonStyle"
        :class="class"
        :label="label"
        :icon="icon"
        :iconSize="iconSize"
        :iconPlacement="iconPlacement"
        @click.stop="doShowModal(true)"
        :disabled="buttonDisabled"
      ></capitec-button>
    </template>

    <template v-else>
      <capitec-tooltip
        v-if="hasTooltip && hasPermission"
        :message="useTooltip.message"
        :position="useTooltip.position"
        :width="useTooltip.width"
        :opened="useTooltip.opened"
      >
        <capitec-button
          :id="buttonId"
          :style="systemButtonStyle"
          :label="label"
          :icon="icon"
          :class="class"
          :iconSize="iconSize"
          :iconPlacement="iconPlacement"
          @click.stop="click"
          :disabled="buttonDisabled"
          :type="buttonType"
        ></capitec-button>
      </capitec-tooltip>

      <capitec-button
        v-else-if="hasPermission && component === 'button'"
        :id="buttonId"
        :style="systemButtonStyle"
        :class="class"
        :label="label"
        :icon="icon"
        :iconSize="iconSize"
        :iconPlacement="iconPlacement"
        :type="buttonType"
        @click.stop="click"
        :disabled="buttonDisabled"
      >
      </capitec-button>

      <capitec-chip
        v-else-if="hasPermission && component === 'chip'"
        :id="buttonId"
        :class="class"
        :label="label"
        :icon="icon"
        :iconSize="iconSize"
        :iconPlacement="iconPlacement"
        :type="buttonType"
        @click.stop="click"
        :disabled="buttonDisabled"
      >
      </capitec-chip>
    </template>
  </template>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import ACTIONS from '@/definitions/actions';
import { evaluate_simple_guards } from '@/db-interface/db_interface_guard';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  emits: ['beforeClick', 'click', 'on-api-success', 'on-api-error'],
  components: {},
  props: {
    buttonId: { type: String, default: 'ldb-button' },
    component: { type: String, default: 'button' },
    label: { type: String },
    icon: { type: String },
    iconSize: { type: String },
    iconPlacement: { type: String },
    type: { type: String, default: '' }, // avr - changed the default
    action: { type: String, default: 'click' }, // click | url | routerlink |  <ActionName>
    executeAction: { type: Boolean, default: true },
    viewGuards: { type: [Object, Boolean], default: true },
    enabledGuards: { type: [Object, Boolean], default: true },
    class: { type: String, default: '' },
    modalSize: { type: String, default: 'large' },
    actionParams: {
      type: Object,
      default() {
        return {};
      },
    },
    params: {
      type: Object,
      default() {
        return {};
      },
    },
    modal: {
      header: { type: String },
      cancelLabel: { type: String, default: 'Cancel' },
      confirmLabel: { type: String, default: 'Confirm' },
    },
    tooltip: {
      message: { type: String },
      position: { type: String, default: 'top' },
      width: { type: String, default: '300px' },
      clickable: { type: Boolean, default: true },
      opened: { type: Boolean, default: false },
    },
    onSuccess: {
      onBeforeStoreUpdate: { type: Function, default: undefined },
      doStoreUpdate: { type: Boolean, default: true },
      onAfterStoreUpdate: { type: Function, default: undefined },
    },
  },
  data() {
    return {
      showModal: false,
      downloadUrl: '',
      downloadFilename: '',
      downloadButton: false,
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters(['permissions', 'authHeaders', 'isDevelopment']),
    ...mapState(['account','authUser']),
    systemButtonStyle() {
      return { '--theme-button-padding-top': '5px', '--theme-button-padding-bottom': '5px' };
    },
    useTooltip() {
      if (this.isDevelopment) {
        return this.paramsMessage;
      } else {
        return this.tooltip;
      }
    },
    // isDevMode() {
    //   return process.env.NODE_ENV === 'development';
    // },
    paramsMessage() {
      if (this.isDevelopment) {
        if (this.allParamsArePresent) {
          let useTooltip = this.hasTooltip
            ? { ...this.tooltip }
            : { message: '', position: 'bottom', width: '300px', clickable: true, opened: false };
          if (this.hasTooltip === true && useTooltip.message === undefined) {
            useTooltip.message = '';
          }
          if (this.missingParams.length === 0) {
            return { message: '' };
          } else {
            useTooltip.message = `${useTooltip.message} [${this.missingParams.join(' ')}]`;
          }
          return useTooltip;
        }
      } else {
        return this.tooltip;
      }
    },
    missingParams() {
      let allParams = {
        ...this.headersPresent,
        ...this.pathParamsPresent,
        ...this.actionParamsPresent,
      };
      let missingParams = Object.entries(allParams)
        .filter(([k, v]) => !v)
        .map(([k, v]) => k);
      let currentParams = Object.entries(allParams).map(([k, v]) => {
        return { k: k, v: v };
      });
      return missingParams;
    },
    allParamsArePresent() {
      return this.allHeadersArePresent && this.allPathParamsArePresent && this.allActionParamsArePresent;
    },
    headersPresent() {
      let paramsPresent = {};
      if (this.isAction) {
        for (let p in this.actionConfig.headers) {
          if (p !== 'mfaToken') {
            paramsPresent[p] = false;
            let param = this.actionConfig.headers[p];
            if (this.params[p] !== undefined) {
              let paramType = typeof this.params[p];
              if (
                (paramType === 'number' && param.type === 'integer') ||
                (paramType === 'float' && param.type === 'number') ||
                paramType === param.type
              ) {
                paramsPresent[p] = true;
              }
            }
          }
        }
      }
      return paramsPresent;
    },
    allHeadersArePresent() {
      return Object.values(this.headersPresent).every((p) => p);
    },
    pathParamsPresent() {
      let paramsPresent = {};
      if (this.isAction) {
        for (let p in this.actionConfig.pathParams) {
          paramsPresent[p] = false;
          let param = this.actionConfig.pathParams[p];
          if (this.params[p] !== undefined) {
            let paramType = typeof this.params[p];
            if (
              (paramType === 'number' && param.type === 'integer') ||
              (paramType === 'float' && param.type === 'number') ||
              paramType === param.type
            ) {
              paramsPresent[p] = true;
            }
          }
        }
      }
      return paramsPresent;
    },
    allPathParamsArePresent() {
      return Object.values(this.pathParamsPresent).every((p) => p);
    },
    actionParamsPresent() {
      let paramsPresent = {};
      if (this.isAction) {
        for (let p in this.actionConfig.params) {
          let param = this.actionConfig.params[p];
          paramsPresent[p] = !(param.required === true);
          if (this.actionParams[p] || this.actionParams[p] === false || this.actionParams[p] === 0) {
            let paramType = typeof this.actionParams[p];
            if (
              param.type === undefined ||
              (['integer', 'float', 'number'].includes(param.type) && !isNaN(this.actionParams[p])) ||
              (paramType === 'object' && param.type === 'array') ||
              paramType === param.type
            ) {
              paramsPresent[p] = true;
            }
          }
        }
      }
      return paramsPresent;
    },
    allActionParamsArePresent() {
      return Object.values(this.actionParamsPresent).every((p) => p);
    },
    viewGuardsAreTrue() {
      let res = evaluate_simple_guards(this.viewGuards, this.params);
      return res;
    },
    enabledGuardsAreTrue() {
      return evaluate_simple_guards(this.enabledGuards, this.params);
    },
    someParamsAreMissing() {
      return !this.allParamsArePresent;
    },
    buttonType() {
      return this.enabledGuardsAreTrue && this.allParamsArePresent ? this.type : 'disabled'; // avr
    },
    buttonDisabled() {
      return this.buttonType === 'disabled';
    },
    isAction() {
      let res = this.action !== 'click' && this.action !== 'routerlink' && this.action !== 'url';
      return res;
    },
    isModalButton() {
      return this.modal !== undefined && this.modal.header !== undefined;
    },
    allowUserToSeeButton() {
      if (this.isAction) {
        return this.hasPermission && this.viewGuardsAreTrue && this.migrationGuardsAreTrue;
      } else {
        return this.viewGuardsAreTrue;
      }
    },
    props() {
      let res = {};
      for (let p in this.$props) {
        res[p] = this.$props[p];
      }
      return res;
    },
    actionConfig() {
      return ACTIONS[this.action];
    },
    httpParams() {
      let res = {};

      for (let p in this.actionConfig.params) {
        // let param = this.actionConfig.params[p];
        // res[p] = this.params[p]; // avr
        res[p] = this.actionParams[p];
      }
      return res;
    },
    tooltipProps() {
      let res = {
        position: 'top',
        width: '300px',
        clickable: true,
        opened: false,
      };
      if (this.tooltip && this.tooltip.message) {
        res = { ...res, ...this.tooltip };
      }
      return res;
    },
    replacedPath() {
      let path = ACTIONS[this.action].path.replace(/{/g, '{{').replace(/}/g, '}}');
      return Mustache.render(path, this.params);
    },
    hasTooltip() {
      return this.useTooltip && this.useTooltip.message !== '';
    },
    hasPermission() {
      if (this.isAction) {
        return this.permissions[this.action];
      } else {
        return true;
      }
    },
    migrationGuardsAreTrue() {
      // const userIsForNonMigratedIntermediary = this.account[this.authUser.accountId]?.migrationStatus === 'notMigrated';
      // const actionIsPutOrPost = !(this.action.method === 'post' || this.action.method === 'put')
      // return !(userIsForNonMigratedIntermediary && actionIsPutOrPost)
      return true
    },
  },
  methods: {
    ...mapActions(['lfx_auth_get_mfa_token', 'showLoader', 'showToast']),
    ...mapMutations([]),
    downloadFile(data, filename) {
      this.downloadButton = true;
      this.downloadUrl = window.URL.createObjectURL(new Blob([data]));
      this.downloadFilename = filename;
      setTimeout(this.doDownloadFile, 200);
    },
    doDownloadFile() {
      let a = this.$refs.download;
      a.click();
      setTimeout(this.clearDownload, 1000);
    },
    clearDownload() {
      this.downloadButton = false;
      this.downloadUrl = '';
      this.downloadFilename = '';
    },
    doShowModal(show) {
      this.showModal = show === true;
    },
    async click(evt) {
      this.doShowModal(false);
      if (this.action === 'routerlink') {
        this.$router.push(this.actionParams.url);
      } else if (this.action === 'url') {
        window.open(this.actionParams.url, this.actionParams.target || '_blank');
      } else if (this.action === 'click' || this.executeAction === false) {
        this.$emit('beforeClick', evt);
        this.$emit('click', evt);
      } else {
        this.$emit('beforeClick', evt);
        this.showLoader(true);
        let permissionActionResponse = await doPermissionAction(this, this.action, this.params, this.httpParams);
        if (this?.actionConfig?.responses?.[permissionActionResponse?.code]?.type === 'download') {
          let filename = permissionActionResponse.headers['x-suggested-filename'];
          let data = permissionActionResponse.response;
          this.downloadFile(data, filename);
        }
        if (permissionActionResponse.status === 'success') {
          this.$emit('on-api-success', permissionActionResponse);
        }
        this.showLoader(false);
        this.$emit('click', permissionActionResponse);
      }
      evt.stopPropagation();
    },
  },
};
</script>

<style>
.hidden-link {
  display: none;
}



</style>
