//@ts-nocheck
import VueStore from 'vue-class-store';

import { LfxInwardSwiftInt, LfxInwardSwiftDef } from '@/definitions/LfxInwardSwiftDef';

import { LfxModel, LfxDefinition } from './LfxModel';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

@VueStore
class LfxInwardSwiftMdl extends LfxDefinition {
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
export class LfxInwardSwift extends LfxModel {
  constructor(data, state) {
    super({ definition_name: 'inward_swift', ...data }, state);
  }
  get link() {
    // @ts-expect-error
    return `/account/${this.accountId}/swift/${this.id}`;
  }
  async getFkbankAccountId() {
    const r = await doPermissionAction(
      {},
      'getBankAccount',
      { accountId: this.accountId, bankAccountId: this.bankAccountId },
      {}
    );
  }
  get warnAboutPotentialRemitterAccountRounding() {
    return this.remitterAccountCouldBeRounded && ['new', 'available', 'assigned'].includes(this.status);
  }
  get remitterAccountCouldBeRounded() {
    if (this.remitterAccount && this.remitterAccount?.length >= 15) {
      const remitterAccountInt = +this.remitterAccount;
      if (!isNaN(remitterAccountInt) && remitterAccountInt > 900719925474099) {
        return true;
      }
    }
    return false;
  }
  get valueDateTagType() {
    return undefined;
    // // @ts-expect-error
    // switch (this.status) {
    //   case 'complete':
    //     return 'success';
    //   case 'cancelled':
    //     return undefined;
    //   default:
    //     // @ts-expect-error
    //     if (new Date(this.valueDate) < new Date()) {
    //       return 'error';
    //     } else {
    //       return 'info';
    //     }
    // }
  }
  get messageDisplay() {
    if (!this.message) {
      return undefined;
    }
    if (this.message[0] === '{') {
      // FIXME JSON.parse rounds long numbers due to 16-bit precision - also in backend - DB entry is wrong
      let json = JSON.parse(this.message);
      delete json.xmlns;
      return json;
    } else {
      const msg = this.message;
      let items = msg
        .split('&nbsp;')
        .join(' ')
        .split('<br/>')
        .filter((s) => s)
        .map((s) => s.trim());
      let res = {};
      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        let kv = item.split(':');
        let key = kv[0];
        let value = kv.slice(1).join(':').trim();
        res[key] = value;
      }
      return res;
    }
  }
}

export function Definition(context, name: string) {
  return new LfxInwardSwiftMdl(LfxInwardSwiftDef, LfxInwardSwift, context, name, {});
}
