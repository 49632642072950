<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <ol>
        <li>The {{ accountLabel }} will be hidden from normal views.</li>
        <li>Searching for the {{ accountLabel }} will still display the {{ accountLabel }}.</li>
      </ol>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in buttons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

export default {
  name: 'LfxHideAccount',
  components: {},
  props: {},
  emits: {},
  created() {},
  mounted() {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),
    recordId() {
      return +this.$route.params.accountId;
    },
    record() {
      return this.account?.[this.recordId];
    },
    accountLevel() {
      return this.record?.accountLevel;
    },
    accountLabel() {
      if (this.record?.accountLevel === 'bank') {
        return 'Bank';
      } else if (this.record?.accountLevel === 'intermediary') {
        return 'Intermediary';
      } else if (this.record?.accountLevel === 'intermediaryBranch') {
        return 'Branch';
      } else if (this.record?.accountLevel === 'client') {
        return 'Client';
      } else {
        return 'Account';
      }
    },
    header() {
      return `Hide ${this.accountLabel}: ${this.record.name}`;
    },
    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta?.modal?.cancelRoute : 'todo';
      //TODO: go to user's home as fallback
    },
    buttons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelHideAccount',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            // self.$router.replace({ name: self.cancelRoute });
            self.$router.go(-1);
          },
        },
        {
          name: 'hideAccount',
          label: `Hide ${this.accountLabel}`,
          type: 'primary',
          guards: this.record.hideAccount_buttonGuard,
          clickEvent() {
            self.hideAccount();
          },
        },
      ];
      return allButtons.filter((button) => button.guards);
    },
  },
  methods: {
    async hideAccount() {
      let response = await this.record.hideAccount();
      if (response.status === 'success') {
        this.$router.replace({ name: 'accountDetailsView' });
      }
    },
  },
};
</script>
