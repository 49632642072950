import { stateDef } from '@/store';
import { LdbDbObject, LdbDefinition } from '@/definitions/LdbInterfaces';
import { DBObject, Definition, getDefinition } from '@/db-interface';

import store from '@/store';

import { evaluate_guards } from '@/system/LfxGuard';

export class LfxModel extends DBObject {
  // @ts-expect-error
  constructor(data, store) {
    super(data, store);
    // @ts-expect-error
    this.editing = false;
    // @ts-expect-error
    this.creating = false;
  }

  // ----- Permissions -----

  get hasPermission() {
    // @ts-expect-error
    return this.store.getters.permissions;
  }
  // get permittedActions() {
  //   return this.store.getters.permittedActions;
  // }

  // hasPermission(permissionName) {
  //   return this.store.getters[permissionName] === true;
  // }

  // @ts-expect-error
  evaluateGuards(guards) {
    // @ts-expect-error
    return evaluate_guards(guards, this, this.store);
  }

  // @ts-expect-error
  isAllowed(guards) {
    return this.evaluateGuards(guards);
    //   let permissions = permissionsAndAccountLevel.permissions;
    //   let accountLevels = permissionsAndAccountLevel.accountLevels;
    //   let requiresMFA = (permissionsAndAccountLevel.mfa === true);
    //   let hasMFA = true;
    //   if ( requiresMFA )  {
    //     let SSE = Math.round(new Date().getTime() / 1000);
    //     hasMFA = (store.state.mfa.expires > SSE);
    //   }
    //   let user = store.state.authUser;
    //   let userPermissions = user.permissions;
    //   let userAccount = store.state.account[user.accountId];
    //   let accountLevel = userAccount.accountLevel;
    //   let hasRequiredAccountLevel = accountLevels.includes(accountLevel);
    //   let hasRequiredPermission = ( hasMFA && hasRequiredAccountLevel && permissions.some( p => userPermissions.includes(p)) );
    //   return hasRequiredPermission;
    // }
  }

  // ----- Foreign keys -----

  get fk_users() {
    // @ts-expect-error
    return this.store.state.user;
  }

  get fk_accounts() {
    // @ts-expect-error
    return this.store.state.account;
  }
  get fk_intermediaries() {
    // @ts-expect-error
    return this.store.state.account;
  }
  get fk_intermediary_branches() {
    // @ts-expect-error
    return this.store.state.account;
  }
  get fk_clients() {
    // @ts-expect-error
    return this.store.state.account;
  }

  get fk_account_levels() {
    let res = {};
    // @ts-expect-error
    let levels = getDefinition('account').indexed_fields.accountLevel.datatype.option.options;
    for (let l = 0; l < levels.length; l++) {
      let level = levels[l];
      // @ts-expect-error
      res[level.id] = level;
    }
    return res;
  }

  get fk_bank_accounts() {
    // @ts-expect-error
    return this.store.state.bankAccount;
  }
  get fk_beneficiaries() {
    // @ts-expect-error
    return this.store.state.beneficiary;
  }

  get fk_currencies() {
    // @ts-expect-error
    return this.store.getters.config.currency;
  }
  get fk_countries() {
    // @ts-expect-error
    return this.store.getters.config.country;
  }
  get fk_currency_pairs() {
    // @ts-expect-error
    return this.store.getters.config.currencyPair;
  }
  get fk_permission_groups() {
    // @ts-expect-error
    return this.store.getters.config.permissionGroup;
  }
  get fk_permissions() {
    // @ts-expect-error
    return this.store.getters.config.permission;
  }
  get fk_bop_cat_groups() {
    // @ts-expect-error
    return this.store.getters.config.bopCatGroup;
  }
  get fk_bopCat() {
    // @ts-expect-error
    return this.store.getters.config.bopCat;
  }
  get fk_documentType() {
    // @ts-expect-error
    return this.store.getters.config.applicationDocumentType;
  }
  get fk_userTeam() {
    // @ts-expect-error
    return this.store.getters.config.userTeam;
  }

  get fk_transaction_files() {
    // @ts-expect-error
    return this.store.state.transaction;
  }
  get fk_inward_swifts() {
    // @ts-expect-error
    return this.store.state.swift;
  }
  get fk_payments() {
    // @ts-expect-error
    return this.store.state.payment;
  }

  get fk_deals() {
    // @ts-expect-error
    return this.store.state.deal;
  }
  get fk_payment_portfolios() {
    return {}; //TODO
  }
  get fk_account_markup() {
    // @ts-expect-error
    return this.store.state.account?.[this.accountId]?.markup || {};
  }
  get fk_applications() {
    return {}; //TODO
  }
  get fk_signatories() {
    return {}; //TODO
  }
  // ----- Views -----

  get view() {
    // @ts-expect-error
    return this.editing && !this.creating
      ? 'edit'
      : // @ts-expect-error
      !this.creating && !this.editing
      ? 'item'
      : // @ts-expect-error
      this.creating
      ? 'create'
      : 'none';
  }

  // ----- Functions -----

  // @ts-expect-error
  create() {
    // @ts-expect-error
    this.creating = true;
  }

  cancelCreate() {
    // @ts-expect-error
    this.creating = false;
  }

  edit() {
    // @ts-expect-error
    this.editing = true;
  }

  cancelEdit() {
    // @ts-expect-error
    this.editing = false;
  }
}

export class LfxDefinition extends Definition {
  // @ts-expect-error
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}
