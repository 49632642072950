//@ts-nocheck

import { LdbPermissionGroupInt, LdbPermissionGroupDef } from '@/definitions/LdbPermissionGroupDef';

import { LfxModel, LfxDefinition } from './LfxModel';

class LdbPermissionGroupMdl extends LfxDefinition {
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

export class LdbPermissionGroup extends LfxModel {
  constructor(data, store) {
    super({definition_name:'permission_group',...data}, store);
  }
  get displayFKLink() {
    return false
  }
}

export function Definition(context, name: string) {
  return new LdbPermissionGroupMdl(LdbPermissionGroupDef, LdbPermissionGroup, context, name, {});
}
