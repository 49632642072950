<template>

<Teleport to="body">
<capitec-toast 
:type="toast.type" 
:header="toast.header" 
:detail="toast.message" 
@click="closeToast"
closable 
style="width: 500px; position:fixed; top:100px; z-index:var(--lfx-z-index-toast); left:calc(50vw - 250px);">

</capitec-toast>
</Teleport>

</template>


<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

export default {
  components: {
    
  },
  props:{  
    
  },
  data() {
    return {
    }
  },
  created() {

  },
  mounted() {
    
  },
  computed:{
    ...mapGetters([]),
  	...mapState(['toast']),
  },
  methods:{
  	...mapActions(['showToast']),
  	...mapMutations([]),
    closeToast() {
      this.showToast({show:false});
    }
  }
 }
</script>
<style>


</style>


