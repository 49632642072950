<template>
  <LDBModal result="info" header="Edit Public Holiday" size="x-large">
    <template #body>
      <LDBItemView :record="publicHolidayRecord" :view="view" action="updatePublicHoliday" :params="submitParams" />
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

export default {
  name: 'configPublicHolidayView',
  components: { LDBItemView, LDBLoader },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),

    view() {
      return this.$route.meta?.content?.view || 'item';
    },
    submitParams() {
      return {
        configId: 1,
        publicHolidayId: this.publicHolidayId,
      };
    },
    // ----- Public Holiday Record -----

    publicHolidayId() {
      return this.$route.params?.publicHolidayId;
    },
    publicHolidayRecord() {
      return this.$store.state.config[1].publicHoliday?.[this.publicHolidayId];
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
