<template>
    <div  :class="`lfx-tiny-cal-container ${color} ${weekDay.toLowerCase()}`">
      <div class="cal-month">{{month}}</div>
      <div class="cal-day">{{day}}</div>
      <div class="cal-weekday">{{weekDay}}</div>
    </div>
</template>

<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

export default {
  components: {},
  props: {  
    date:{type:String,required:true},
    color:{type:String,default:'info'}
  },
  data() {
    return {
    }
  },
  created() {

  },
  mounted() {

  },
  computed:{
    ...mapGetters([]),
  	...mapState([]),

    actualDate() {
      return new Date(this.date.split('T')[0]);
    },
    day() {
      return this.actualDate.getDate();
    },
    weekDay() {
      const DAYS = ['SUN','MON','TUE','WED','THU','FRI','SAT'];
      let day = this.actualDate.getDay();
      return DAYS[day];
    },
    month() {
      const MONTHS = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
      let month = this.actualDate.getMonth();
      return MONTHS[month];
    }
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([])
  }
 }
</script>
<style>



.lfx-tiny-cal-container.error{
  --cal-color:var(--theme-status-error-background);
}

.lfx-tiny-cal-container.info{
  --cal-color:var(--theme-status-info-background);
}

.lfx-tiny-cal-container.sat{
  --cal-color:var(--theme-status-success-background);
}

.lfx-tiny-cal-container.sun{
  --cal-color:var(--theme-status-success-background);
}



.lfx-tiny-cal-container{
  display:grid;
  grid-template-areas: 
  'month month month month'
  '. day day .'
  '. day day .'
  '. day day .'
  ' . weekday weekday .';
  grid-template-columns: 15px 15px 15px 15px;
  grid-template-rows: 15px 7px 0px 12px 15px;
  border: 1px solid #EEE;
  /* var(--cal-color); */
  align-items: stretch;
  border-radius: 5px;
  overflow:hidden;
  background-color:white;
  padding:-1px;
}

.cal-month{
  grid-area: month;
  background-color:var(--cal-color);
  color:white;
  font-weight: bold;
  display:flex;
  align-items: center;
  justify-content:center;
  font-size: 13px;
}

.cal-day{
  grid-area: day;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
}

.cal-weekday{
  grid-area: weekday;
  font-size: 10px;
  color:#999;
  font-style: italic;
  text-align:center;
}

</style>


