<template>
  <div :class="`lfx-banner-container banner-${status} banner-${showing}`">
    <capitec-icon class="banner-icon" size="default" :icon="`status/${status}-16px-white`"></capitec-icon>
    <capitec-label class="banner-title" type="subtitle" :label="`${title}`" />
    <div v-if="closable" class="close-banner" @click="onCloseClick">
      <capitec-icon icon="system/close" class="banner-close-button" size="small" />
    </div>
    <div class="lfx-banner-content">
      <slot />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  emits: ['on-close'],
  components: {},
  props: {
    title: { type: String },
    status: { type: String, default: 'info' },
    id: { type: [Number, String] },
    closable: { type: Boolean, default: true },
  },
  data() {
    return {
      show: true,
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    showing() {
      return this.show ? 'show' : 'hide';
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    onCloseClick() {
      this.$emit('on-close', this.id);
      this.show = false;
    },
  },
};
</script>
<style scoped>
.banner-icon {
  position: absolute;
  transform: scale(0.7);
  margin-left: 5px;
}

.lfx-banner-container.banner-error {
  --dark-color: var(--theme-status-error-background);
  --light-color: var(--theme-alert-banner-unsuccessful-red-background-color);
}

.lfx-banner-container.banner-warning {
  --dark-color: var(--theme-status-warn-background);
  --light-color: var(--theme-alert-banner-warning-background-color);
}

.lfx-banner-container.banner-info {
  --dark-color: var(--theme-status-info-background);
  --light-color: var(--theme-alert-banner-info-background-color);
}

.lfx-banner-container.banner-cancelled {
  --dark-color: '#FFFFFF';
  --light-color: '#7C7C7C';
}

.lfx-banner-container.banner-success {
  --dark-color: var(--theme-status-success-background);
  --light-color: var(--theme-alert-banner-success-background-color);
}

.lfx-banner-container {
  margin: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 5px;
  background-color: var(--light-color);
  color: var(--dark-color);
  position: relative;
  max-height: 200px;
  display: flex;
  flex-direction: column;
}

.lfx-banner-container.banner-hide {
  transition: all 0.5s linear;
  opacity: 0;
  transform: scaleY(0);
  max-height: 0px;
  margin: 0px;
}

.lfx-banner-content {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  overflow: auto;
}

div.lfx-banner-container > capitec-label {
  color: var(--dark-color);
  background-color: var(--dark-color);
  color: white;
  padding-left: 10px;
}

.banner-close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 25px;
  width: 25px;
  filter: brightness(100);
  cursor: pointer;
}

capitec-label.banner-title {
  padding-left: 35px !important;
}
</style>
