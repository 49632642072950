import VueStore from 'vue-class-store';

import { LfxBeneficiaryInt, LfxBeneficiaryDef } from '@/definitions/LfxBeneficiaryDef';

import { LfxModel, LfxDefinition } from './LfxModel';

@VueStore
// @ts-expect-error
class LfxBeneficiaryMdl extends LfxDefinition {
  // @ts-expect-error
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
// @ts-expect-error
export class LfxBeneficiary extends LfxModel {
  // @ts-expect-error
  constructor(data, state) {
    super({ definition_name: 'beneficiary', ...data }, state);
  }

  get fullName() {
    // @ts-expect-error
    return this.beneficiaryType === 'individual' ? `${this.individualFirstname} ${this.name}` : `${this.name}`;
  }

  get link() {
    // @ts-ignore
    return `/account/${this.accountId}/beneficiary/${this.id}`;
  }

  // -------------------------
  // ----- API Calls -----
  // -------------------------

  // ----- Details -----

  async enableBeneficiary() {
    console.warn('OLD API IMPLEMENTATION');
    // @ts-ignore
    this.store.dispatch('showLoader', true);
    let payload = {};
    // @ts-ignore
    let options = { update_state: true, return_collection_path: `/account/${this.accountId}/beneficiary` };
    // @ts-ignore
    let response = await this.store.dispatch('db_put_action', {
      //@ts-ignore
      path: `/account/${this.accountId}/beneficiary/${this.id}/enable`,
      payload,
      options,
    });
    //@ts-ignore
    this.store.dispatch('showLoader', false);
    return response;
  }

  async disableBeneficiary() {
    console.warn('OLD API IMPLEMENTATION');
    // @ts-ignore
    this.store.dispatch('showLoader', true);
    let payload = {};
    // @ts-ignore
    let options = { update_state: true, return_collection_path: `/account/${this.accountId}/beneficiary` };
    // @ts-ignore
    let response = await this.store.dispatch('db_put_action', {
      //@ts-ignore
      path: `/account/${this.accountId}/beneficiary/${this.id}/disable`,
      payload,
      options,
    });
    //@ts-ignore
    this.store.dispatch('showLoader', false);
    return response;
  }

  // ----- Logs -----

  get beneficiaryLogs() {
    const logs = {};
    // @ts-expect-error
    let bankAccountLogs = this.store.state.account[this.accountId].bankAccountLog;
    for (const bankAccountLogId in bankAccountLogs) {
      //@ts-expect-error
      if (bankAccountLogs[bankAccountLogId].beneficiaryId === this.id) {
        //@ts-expect-error
        logs[bankAccountLogId] = bankAccountLogs[bankAccountLogId];
      }
    }
    return logs;
  }

  // -------------------------
  // ----- Bank Accounts -----
  // -------------------------

  get accountCurrencies() {
    const currencyArray = [];
    for (const bankAccountId in this.beneficiary_bankAccounts || {}) {
        //@ts-expect-error
        currencyArray.push(this.beneficiary_bankAccounts?.[bankAccountId].currencyId)
    }
    if (currencyArray.length === 0) {
      return "No Accounts Found"
    } else {
      return currencyArray.join(", ");
    }
  }

  get beneficiary_bankAccounts() {
    let beneficiary_bankAccounts = {};
    // @ts-expect-error
    let bankAccounts = this.store?.state?.account?.[this.accountId]?.beneficiaries_bankAccounts;
    for (let b in bankAccounts) {
      let bankAccount = bankAccounts[b];
      // @ts-expect-error
      if (bankAccount.beneficiaryId === this.id) {
        // @ts-expect-error
        beneficiary_bankAccounts[b] = bankAccount;
      }
    }
    return beneficiary_bankAccounts;
  }

  get firstBeneficiary_bankAccountId() {
    return Object.keys(this.beneficiary_bankAccounts)[0];
  }

  // ----- Old -----

  get bank_account() {
    console.warn('FIXME: ACCESSED OLD BANK ACCOUNT IMPLEMENTATION');
    return this.beneficiary_bankAccounts;
  }

  async getBankAccounts() {
    console.warn('OLD API IMPLEMENTATION');
    // @ts-ignore
    let response = await this.store.dispatch('db_get_collection', {
      // @ts-ignore
      collection_path: `/account/${this.accountId}/beneficiary/${this.id}/bankAccount`,
      // @ts-ignore
      options: {
        // @ts-expect-error
        return_collection_path: `/account/${this.accountId}/beneficiary/${this.id}/bank_account`,
        definition_name: 'bankAccount',
      },
    });
    return response;
  }

  // @ts-ignore
  async addBankAccount(payload) {
    console.warn('OLD API IMPLEMENTATION');
    let record = payload;
    // @ts-expect-error
    record.collection_path = `/account/${this.accountId}/beneficiary`;
    // @ts-ignore
    let options = {
      // @ts-expect-error
      return_collection_path: `/account/${this.accountId}/beneficiary/${this.id}/bank_account`,
      action: 'bankAccount',
      // @ts-expect-error
      parentId: this.id,
    };
    // @ts-ignore
    this.store.dispatch('showLoader', true);
    // @ts-ignore
    let response = await this.store.dispatch('db_create_document', { record, options });
    // @ts-ignore
    this.store.dispatch('showLoader', false);
    return response;
  }
}

// @ts-expect-error
export function Definition(context, name: string) {
  return new LfxBeneficiaryMdl(LfxBeneficiaryDef, LfxBeneficiary, context, name, {});
}
