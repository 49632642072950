<template>
  <LFXPagedListView
    :definition="definition"
    action="getPayments"
    :actionParams="{}"
    :search="searchConfig"
    :defaultSort="defaultSort"
  />
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXPagedListView from '@/views/general/LFXPagedListView.vue';

export default {
  name: 'LfxPaymentList',
  components: { LFXPagedListView },
  props: {},
  data() {
    const todayStart = new Date(new Date().setHours(0, 0, 0, 0));
    const todayEnd = new Date(new Date().setHours(23, 59, 59, 0));
    return {
      searchConfig: {
        searchFields: [
          'reference',
          'externalReference',
          'paymentCurrency',
          'foreignCurrency',
          'beneficiaryReference',
          'specialInstructions',
          'purposeOfPayment',
          'invoiceNumber',
          'integrationId',
        ],
        choices: [
          {
            id: 'status',
            title: '',
            chips: [
              {
                id: 'open',
                label: 'Open',
                selector: { status: { notIn: ['complete', 'cancelled', 'deleted'] } },
                default: true,
              },
              {
                id: 'dueToday',
                label: 'Due Today',
                selector: { and: [{ valueDate: { gte: todayStart } }, { valueDate: { lte: todayEnd } }] },
              },
              {
                id: 'requiersInter',
                label: 'Awaiting Inter',
                selector: { status: { in: ['new', 'readyForSubmission', 'readyForSigning', 'informationQuery'] } },
              },
              {
                id: 'requiersBank',
                label: 'Awaiting Bank',
                selector: {
                  status: {
                    in: ['submitted', 'signed', 'awaitingSettlement', 'settled', 'awaitingFunds', 'recallRequested'],
                  },
                },
              },
              {
                id: 'sentBack',
                label: 'Sent Back',
                selector: { status: { in: ['readyForSubmission', 'informationQuery'] }, submittedCount: { gt: 0 } },
              },
              { id: 'complete', label: 'Completed', selector: { status: { in: ['complete'] } } },
              { id: 'cancelled', label: 'Cancelled', selector: { status: { in: ['cancelled', 'deleted'] } } },
              {
                id: 'overdue',
                label: 'Overdue',
                selector: { valueDate: { lte: todayStart }, status: { notIn: ['complete', 'cancelled', 'deleted'] } },
              },
              { id: 'all', label: 'All', selector: {} },
            ],
          },
        ],
      },
    };
  },
  async created() {
    if (this.authUser?.accountLevel === 'bank') {
      this.searchConfig.choices[0].chips.push({
        id: 'slowPortfolio',
        label: 'Slow Portfolio',
        selector: {
          status: 'submitted',
          submittedAt: { lte: new Date(new Date().getTime() + -this.slowPortfolioMinutes * 60 * 1000) },
        },
      });
      this.searchConfig.choices[0].chips.push({
        id: 'slowPortfolioRecall',
        label: 'Slow Recall',
        selector: {
          status: 'recallRequested',
          mostRecent: { lte: new Date(new Date().getTime() + -this.slowPortfolioMinutes * 60 * 1000) },
        },
      });
    }
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account', 'payment', 'authUser', 'systemSettings']),
    defaultSort() {
      return { order: 'id', sort: 'desc' };
    },
    viewedAccountId() {
      return +this.$route.params.accountId;
    },
    viewedAccountLevel() {
      return this.account[this.viewedAccountId]?.accountLevel;
    },

    definition() {
      return getDefinition('payment');
    },

    slowPortfolioMinutes() {
      return this.systemSettings?.slowPortolioMonitor?.alertPortfolioMinutes || 5;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>

<style></style>
