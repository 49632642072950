//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxTransactionFileLogDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Transaction File Log record for LandoByte TS systems
//  Created     : 28 October 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition } from '../definitions/LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------------------------------

export interface LfxTransactionFileLogInt {
  id?: string;
  transactionFileId?: number;
  dealId?: number;
  paymentId?: number;
  action?: string;
  actionDetails?: {};
  timestamp?: Date;
  userId?: number;
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxTransactionFileLogDef: LdbDefinition = {
  title: 'Transaction File Log',
  table: 'LfxTransactionFileLog',
  collectionPath: '/transaction/{{transactionFileId}}/transaction_file_log',
  database: 'dynamo',
  view: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
  indexes: ['transactionFileId', 'dealId', 'paymentId'],
  pagingType: 'backEnd',
  fields: {
    id: {
      label: 'ID',
      datatype: { id: { descriptionField: 'action', type: 'text' } },
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    transactionFileId: {
      label: 'Transaction File',
      datatype: {
        foreignKey: {
          collection: 'fk_transaction_files',
          linkTable: 'LfxTransactionFile',
          linkField: 'id',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    dealId: {
      label: 'Deal',
      datatype: {
        foreignKey: {
          collection: 'fk_deals',
          linkTable: 'LfxDeal',
          linkField: 'id',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    paymentId: {
      label: 'Payment',
      datatype: {
        foreignKey: {
          collection: 'fk_payments',
          linkTable: 'LfxPayment',
          linkField: 'id',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },

    action: {
      label: 'Action',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },

    actionDetails: {
      label: 'Action Details',
      datatype: { json: {} },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },

    timestamp: {
      label: 'Timestamp',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    userId: {
      label: 'User',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
  },
};
