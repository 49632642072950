<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <ol>
        <li>The beneficiary will be disabled.</li>
        <li>The beneficiary will no longer be shown as an option when booking deals or making payments.</li>
        <li>The beneficiary can be enabled again.</li>
        <li>Deals and payments that are in progress with this beneficiary will not be affected.</li>
      </ol>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in buttons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

export default {
  name: 'LfxDisableBeneficiary',
  components: {},
  props: {},
  emits: {},
  created() {},
  mounted() {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),
    header() {
      return `Disable Beneficiary: ${this.beneficiaryName}`;
    },

    // ----- Account record -----

    accountId() {
      return +this.$route.params.accountId;
    },
    accountRecord() {
      return this.account[this.accountId];
    },

    // ----- Beneficiary record ----

    beneficiaryId() {
      return +this.$route.params.beneficiaryId;
    },
    beneficiaryRecord() {
      return this.accountRecord.beneficiary[this.beneficiaryId];
    },
    beneficiaryName() {
      return this.beneficiaryRecord?.beneficiaryType === 'entity'
        ? this.beneficiaryRecord.name
        : `${this.beneficiaryRecord.individualFirstname} ${this.beneficiaryRecord.name}`;
    },

    // ----- Form buttons -----

    buttons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelDisableBeneficiary',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            // self.$router.replace({ name: self.cancelRoute });
            self.$router.go(-1);
          },
        },
        {
          name: 'disableBeneficiary',
          label: 'Disable Beneficiary',
          type: 'primary',
          guards: this.beneficiaryRecord.disableBeneficiary_buttonGuard, //TODO
          clickEvent() {
            self.disableBeneficiary();
          },
        },
      ];
      return allButtons;
    },
  },
  methods: {
    async disableBeneficiary() {
      let response = await this.beneficiaryRecord.disableBeneficiary();
      if (response.status === 'success') {
        this.$router.replace({ name: 'beneficiaryDetailsView' });
      }
    },
  },
};
</script>
