import { RouteRecordRaw } from 'vue-router';

import LfxUploadAndDownload from '@/views/uploadAndDownload/LfxUploadAndDownload.vue';
import LfxIntermediaryDownloads from '@/views/uploadAndDownload/LfxIntermediaryDownloads.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/uploadsAndDownloads',
    name: 'uploadsAndDownloads',
    meta: { icon: 'system/notes' },
    component: LfxUploadAndDownload,
    children: [
      {
        path: 'itermedairyDownloads',
        name: 'itermedairyDownloads',
        component: LfxIntermediaryDownloads,
        meta: { label: 'Intermediary Downloads', content: { tabName: 'itermedairyDownloads', actionGroup: 'itermedairyDownloads' } },
      },
    ],
  },
];

export default routes;
