import VueStore from 'vue-class-store';

// @ts-ignore
import {  LdbTestDef } from '@/definitions/LdbTestDef';
import { LfxModel, LfxDefinition } from './LfxModel';

@VueStore
// @ts-ignore
class LdbTestMdl extends LfxDefinition {
  // @ts-ignore
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }

  get test_my_foreign_key_collection() {
    return {a:{id:'a',name:'A'},b:{id:'b',name:'B'},c:{id:'c',name:'C'}};
  }
}

@VueStore
// @ts-ignore
export class LdbTest extends LfxModel {
  // @ts-ignore
  constructor(data, state) {
    super({definition_name:'test',...data}, state);
    // @ts-ignore
  }

}

// @ts-ignore
export function Definition(context, name: string) {
  return new LdbTestMdl(LdbTestDef, LdbTest, context, name, {});
}

