<template>
  <template v-if="true">
    <LDBButton
      v-for="button in buttons"
      :key="button.name"
      :label="button.label"
      :type="button.type"
      :action="button.action"
      :params="button.params"
      :actionParams="button.actionParams"
      :viewGuards="button.guards"
      @click="button.clickEvent"
      @on-api-success="button.onApiSuccess"
    ></LDBButton>
  </template>
  <template v-if="true">
    <LfxBanner
      v-for="banner in banners"
      :key="banner.id"
      :title="banner.title"
      :status="banner.status"
      :closable="banner.closable"
      ><div v-html="banner.content"></div
    ></LfxBanner>
  </template>
  <LDBItemView
    :record="markupRecord"
    :definition="markupDefinition"
    action="editAccountMarkups"
    :params="params"
    @on-save="onSave"
    :view="view"
    :saveButtonCaption="saveButtonCaption"
    :cancelButtonCaption="cancelButtonCaption"
    :checkerMode="markupEditMode === 'checker'"
    :forceSaveButtonType="forceSaveButtonType"
  />
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import {
  isMakerCheckerRequiredForMarkupEdit,
  getUpdateAccountMakerCheckerKey,
} from '@/sharedUtils/LfxMakerCheckerUtils';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import { checkValidMarkup } from '@/sharedUtils/LfxTradeCalc';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxAccountMarkupDetails',
  components: { LDBItemView },
  props: {},
  data() {
    return {
      markupError: undefined,
      markupEditMode: 'normal', // 'normal' | 'maker' | 'checker'
      makerCheckerSecurityToken: undefined,
      makingUserId: undefined,
      makingTimestamp: undefined,
    };
  },
  created() {},
  mounted() {
    this.markupIsValid;
    if (this.view === 'edit') {
      if (isMakerCheckerRequiredForMarkupEdit(this.account?.[this.authUser.accountId], this.accountRecord)) {
        this.getPreviouslyCachedObject();
      }
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['authUser', 'account', 'user', 'rates', 'systemSettings']),
    banners() {
      let errorDisplay;
      const percentageMarkupText = 'Percentage must be below ' + this.maximumMarkupPercentage * 100 + '%';
      switch (this.markupError) {
        case undefined:
          errorDisplay = '';
          break;
        case 'Markup has exceded the allowed tollerence.':
        case 'Markup has exceeded the allowed tolerance.':
        case percentageMarkupText:
          errorDisplay =
            'The Total Intermediary Markup can not exceed ' +
            this.maximumMarkupPercentage * 100 +
            '% of the Bank Rate. Reduce the Fixed Client Rate or reconfigure the Intermediary Markup.'; // TODO make dynamic based on maximumMarkupPercentage
          break;
        case 'Markup must be positive':
          errorDisplay = 'The Total Intermediary Markup can not be less than zero.';
          break;
        default:
          errorDisplay = this.markupError;
      }

      const allBanners = [
        {
          id: `invalidMarkup`,
          title: 'Invalid Markup',
          status: 'error',
          closable: false,
          content: `Invalid Markup: ${errorDisplay}`,
          guards: this.view === 'edit' && !this.markupIsValid,
        },
        {
          id: `iAmtryingToCheckMyOwnChange`,
          title: 'You have requested a change',
          status: 'info',
          closable: false,
          content: `You have requested a change to this markup at ${this.makingTimestamp
            ?.toLocaleString('en-ZA', { timeZone: 'Africa/Johannesburg' })
            .replaceAll(
              ',',
              ''
            )}. It will need to be released by another user. You can also cancel the change if required.`,
          guards: this.iAmtryingToCheckMyOwnChange,
        },
        {
          id: `iAmtryingToCheckAnotherUsersChange`,
          title: 'A Change has been requested to this markup',
          status: 'info',
          closable: false,
          content: `A change to this markup has been requested by ${
            this.user?.[this.makingUserId]?.displayName || this.makingUserId
          } at ${this.makingTimestamp
            ?.toLocaleString('en-ZA', { timeZone: 'Africa/Johannesburg' })
            .replaceAll(',', '')}.`,
          guards: this.iAmtryingToCheckAnotherUsersChange,
        },
      ];
      const banners = [];
      for (const banner of allBanners) {
        if (banner.guards) {
          banners.push(banner);
        }
      }
      return banners;
    },
    buttons() {
      return [
        {
          name: 'cancelMakerCheckerEdit',
          label: 'Cancel Requested Change',
          type: '',
          action: 'clearMakerCheckerToken',
          params: { configId: 1 },
          actionParams: { cacheKey: this.cacheKey },
          guards: this.view === 'edit' && this.markupEditMode === 'checker',
          onApiSuccess: () => {
            void this.getPreviouslyCachedObject();
          },
        },
      ];
    },

    // ----- Account record -----

    accountId() {
      return +this.$route.params.accountId;
    },
    accountRecord() {
      return this.account[this.accountId];
    },

    // ----- Markup record ----

    markupId() {
      return +this.$route.params.markupId;
    },
    markupRecord() {
      return this.accountRecord.markup[this.markupId];
    },
    markupDefinition() {
      // return getDefinition(this.markupRecord.definition_name);
      return this.markupRecord.definition;
    },

    // },
    view() {
      return this.$route.meta?.content?.view ? this.$route.meta.content.view : 'item';
    },

    params() {
      return { accountId: this.accountId, markupId: this.markupId };
    },
    spotRate() {
      return this.rates?.[this.markupRecord?.currencyPairObject?.instrument]?.['bid'] || 0;
    },
    maximumMarkupPercentage() {
      return +this.systemSettings?.trading?.maximumMarkupPercentage || 0.1;
    },
    markupIsValid() {
      if (this.view === 'item') {
        this.markupError = undefined;
        return true;
      }
      if (this.markupRecord.markupType === 'percentage') {
        const percentageIsValid =
          !this.markupRecord.percentage || this.markupRecord.percentage < this.maximumMarkupPercentage;
        if (!percentageIsValid) {
          this.markupError = 'Percentage must be below ' + this.maximumMarkupPercentage * 100 + '%';
        } else {
          this.markupError = undefined;
        }
        return percentageIsValid;
      } else {
        if (this.markupRecord?.pips) {
          try {
            checkValidMarkup(this.spotRate, this.markupRecord.pips, this.maximumMarkupPercentage);
            this.markupError = undefined;
            return true;
          } catch (markupError) {
            this.markupError = markupError.message;
            return false;
          }
        }
        this.markupError = undefined;
        return true;
      }
    },
    saveButtonCaption() {
      switch (this.markupEditMode) {
        case 'normal':
          return 'Save';
        case 'maker':
          return 'Request Change';
        case 'checker':
          return 'Confirm Change';
        default:
          return 'Save';
      }
    },
    cancelButtonCaption() {
      switch (this.markupEditMode) {
        case 'normal':
          return 'Cancel';
        case 'maker':
          return 'Cancel';
        case 'checker':
          return 'Back';
        default:
          return 'Cancel';
      }
    },
    forceSaveButtonType() {
      if (this.markupIsValid && !this.iAmtryingToCheckMyOwnChange) {
        return undefined;
      } else {
        return 'disabled';
      }
    },
    cacheKey() {
      return getUpdateAccountMakerCheckerKey(+this.markupId);
    },
    iAmtryingToCheckMyOwnChange() {
      return this.markupEditMode === 'checker' && this.makingUserId === this.authUser.id;
    },
    iAmtryingToCheckAnotherUsersChange() {
      return this.markupEditMode === 'checker' && this.makingUserId !== this.authUser.id;
    },
  },
  watch: {
    view(newView, oldView) {
      if (this.$route.meta?.content?.view === 'edit') {
        if (isMakerCheckerRequiredForMarkupEdit(this.account?.[this.authUser.accountId], this.accountRecord)) {
          this.getPreviouslyCachedObject();
        }
      }
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    onSave() {
      if (isMakerCheckerRequiredForMarkupEdit(this.account?.[this.authUser.accountId], this.accountRecord)) {
        this.getPreviouslyCachedObject();
      }
      this.$router.go(-1);
    },
    async getPreviouslyCachedObject() {
      const response = await doPermissionAction(
        this,
        'getMakerCheckerToken',
        { configId: 1 },
        { cacheKey: this.cacheKey }
      );
      if (+response.code === 204) {
        this.markupEditMode = 'maker';
      } else if (+response.code === 200) {
        this.markupEditMode = 'checker';
        this.makerCheckerSecurityToken = response.response.securityToken;
        this.makingUserId = response.response.makingUserId;
        this.makingTimestamp = new Date(response.response.makingTimestamp);
        if (this.markupRecord) {
          this.markupRecord.securityToken = this.makerCheckerSecurityToken;
          for (const field in response.response.cachedObject) {
            this.markupRecord[field] = response.response.cachedObject[field];
            // this.markupRecord.data.[field] = response.response.cachedObject[field];
          }
        }
      }
    },
  },
};
</script>

<style>
/* div.container {
} */
</style>
