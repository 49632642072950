<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <div>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="definition"
          :record="record"
          @change="on_change"
        />
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in buttons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getDefinition } from '@/db-interface';
import lodash from 'lodash';
const merge = lodash.merge;

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxMoveUser',
  components: { LDBField },
  props: {},
  emits: {},
  created() {},
  mounted() {},
  beforeUnmount() {
    this.record.revert();
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    header() {
      return `Move User: ${this.userDisplayName}`;
    },
    buttons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelMoveUser',
          label: 'Cancel',
          type: '',
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'moveUser',
          label: 'Move User',
          type: 'primary',
          action: 'moveUser',
          actionParams: this.record,
          params: { userId: this.recordId },
          enabledGuards: this.accountIdHasChanged,
          clickEvent(response) {
            if (response.status === 'success') {
              self.$router.go(-1);
            }
          },
        },
      ];
      return allButtons;
    },

    accountIdHasChanged() {
      return this.record?.changed_fields?.some((field) => field.name === 'accountId');
    },

    // ----- Definition -----

    definition() {
      return getDefinition('user');
    },

    // ----- Record -----

    recordName() {
      return 'user';
    },
    recordId() {
      return +this.$route.params.userId;
    },
    record() {
      let rec = this.$store.state?.[this.recordName]?.[this.recordId];
      return rec;
    },

    userDisplayName() {
      return this.record?.userDisplayName;
    },

    // ----- Fields -----

    fields() {
      return this.definition.fields;
    },
    view() {
      return 'moveUser';
    },
    viewFields() {
      let res = [];
      for (let k in this.fields) {
        let field = this.fields[k];
        if (field.views[this.view] === true) {
          field.fieldname = k;
          res.push(this.overrideField(field));
        }
      }
      return res;
    },
  },
  methods: {
    //
    // ----- Fields -----

    on_change({ field, value }) {
      this.record[field] = value;
    },

    overrideField(field) {
      if (field.name === 'accountId') {
        return this.overrideAccountId(field);
      } else return field;
    },
    overrideAccountId(field) {
      let newField = merge({}, field);
      let newAccountIdField = merge(newField, {
        datatype: {
          foreignKey: {
            guards: {
              accountLevel: {
                in: ['intermediary', 'intermediaryBranch'],
              },
              intermediaryId(record) {
                return { eq: record.intermediaryId };
              },
              id(record) {
                return { notEq: record.accountId };
              },
            },
          },
        },
      });
      newAccountIdField.label = 'Move to Account';
      return newAccountIdField;
    },
  },
};
</script>
