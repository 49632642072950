//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxInwardSwiftDef.ts
//  Author      : Bevan Timm
//  Description : This module defines an Inward SWIFT record for the LandoFX System
//  Created     : 12 November 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import { intermediaryFieldViewGuard } from '../sharedUtils/LdbDefinitionUtils';
import type { LdbAuthUser, LdbDefinition } from './LdbInterfaces';
import { transactionBranchFieldGuard, transactionClientFieldGuard, transactionIntermediaryFieldGuard } from './LfxTransactionFileDef';

//----------------------------------------------------------------------------------------------------------------------

export type LfxInwardSwiftStatus = 'new' | 'available' | 'assigned' | 'complete' | 'cancelled' | 'noClient' | 'noBankAccount';
export type LfxInwardSwiftMessageStatus =
  | 'CHECK_FUNDS'
  | 'START'
  | 'FUNDS_UNCLEARED'
  | 'CHECK_FUNDS_EXCEEDED_LIFE_EXPIRY'
  | 'FUNDS_CLEARED'
  | 'FUNDS_CLEARED'
  | 'FUNDS_CLEARED_PLEASE_COMPLETE_THE_BOP_FORM'
  | 'FUNDS_CLEARED_PLEASE_COMPLETE_THE_BOP_FORM'
  | 'BOP_FORM_NOT_SIGNED_15_DAYS'
  | 'BOP_FORM_NOT_SIGNED_25_DAYS'
  | 'FUNDS_RETURNED'
  | 'SWIFT_CANCELLED'
  | 'COMPLETED'
  | 'COMPLETE'
  | 'MANUAL_CAPTURE';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxInwardSwiftInt {
  id?: number;
  uuid?: string;
  accountId?: number;
  bankAccountId?: number;
  intermediaryId?: number;
  branchId?: number;
  paymentId?: number | null;
  transactionFileId?: number | null;

  swiftNumber?: string;
  currencyId?: string;
  amount?: number;
  messageCifNumber?: string;
  messageAccountNumber?:string;

  remitterBic?: string;
  correspondentBic?: string;
  remitterName?: string;
  remitterAccount?: string;
  remitterAddress1?: string;
  remitterAddress2?: string;
  remitterAddress3?: string;

  status?: LfxInwardSwiftStatus;
  messageStatus?: LfxInwardSwiftMessageStatus;
  valueDate?: Date;
  dateReceived?: Date;
  dateAssigned?: Date;
  integrationId?: string;
  message?: string;

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

const addToStoreIfNotExist = (_dbObject: LfxInwardSwiftInt, authUser: LdbAuthUser) => {
  if (authUser.accountLevel !== 'bank') {
    return true;
  }
  const requriedPermissions = ['manageSWIFT'];
  var hasPermission = false;
  for (const permission of requriedPermissions) {
    if (authUser.permissions.includes(permission)) {
      hasPermission = true;
    }
  }
  return hasPermission;
};

//----------------------------------------------------------------------------------------------------------------------

export const LfxInwardSwiftDef: LdbDefinition = {
  title: 'Inward SWIFT',
  table: 'LfxInwardSwift',
  collectionPath: '/swift',
  addToStoreIfNotExist: addToStoreIfNotExist,
  view: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
  actions: {
    list: 'getInwardSwifts',
    item: 'getInwardSwift',
  },
  indexes: ['accountId', 'intermediaryId', 'paymentId'],
  pagingType: 'backEnd',
  groups: {
    system: { priority: 1, label: 'General', color: 'success' },
    amount: { priority: 2, label: 'Amount', color: 'info' },
    remitter: { priority: 3, label: 'Remitter', color: 'info' },
    integration: { priority: 4, label: 'Integration', color: 'disabled' },
    message: { priority: 5, label: 'Message', color: 'disabled' },
    changes: { priority: 6, label: 'Changes', color: 'disabled' },
  },
  fields: {
    id: {
      label: 'SWIFT Number',
      datatype: { id: { descriptionField: 'swiftNumber' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },
    uuid: {
      label: 'UUID',
      datatype: 'uuid',
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    accountId: {
      label: 'Account',
      datatype: {
        foreignKey: {
          collection: 'fk_accounts',

          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: transactionClientFieldGuard, delete: false, csv: false },
      group: 'system',
    },
    bankAccountId: {
      label: 'Bank Account',
      datatype: {
        foreignKey: {
          collection: 'fk_bank_accounts',
          linkTable: 'LfxBankAccount',
          linkField: 'id',
          displayField: 'description',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },
    intermediaryId: {
      label: 'Intermediary',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediaries',

          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: intermediaryFieldViewGuard,
        list: transactionIntermediaryFieldGuard,
        delete: false,
        csv: false,
      },
      group: 'system',
    },
    branchId: {
      label: 'Branch',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediary_branches',

          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: transactionBranchFieldGuard, delete: false, csv: false },
      group: 'system',
    },
    paymentId: {
      label: 'Payment',
      datatype: {
        foreignKey: {
          collection: 'fk_payments',
          linkTable: 'LfxPayment',
          linkField: 'id',
          displayField: 'reference',
          linkInSql: false,
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },
    transactionFileId: {
      label: 'Transaction File',
      datatype: {
        foreignKey: {
          collection: 'fk_transaction_files',

          linkTable: 'LfxTransactionFile',
          linkField: 'id',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },

    swiftNumber: {
      label: 'SWIFT Number',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false, search: true },
      group: 'amount',
    },
    currencyId: {
      label: 'Currency',
      datatype: {
        foreignKey: {
          collection: 'fk_currencies',

          linkTable: 'LdbCurrency',
          linkField: 'id',
          displayField: 'id',
          type: 'text',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false, search: true, },
      group: 'amount',
    },
    amount: {
      label: 'Amount',
      datatype: { currency: { symbol: '' } },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, search: true, csv: false },
      group: 'amount',
    },
    messageCifNumber: {
      label: 'Message CIF Number',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false, search: true },
      group: 'amount',
    },
    messageAccountNumber: {
      label: 'Message Account Number',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false, search: true },
      group: 'amount',
    },

    remitterBic: {
      label: 'Remitter Bank BIC',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'remitter',
    },
    correspondentBic: {
      label: 'Correspondent Bank BIC',
      datatype: 'text',
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'remitter',
    },
    remitterName: {
      label: 'Remitter Name',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'remitter',
    },
    remitterAccount: {
      label: 'Remitter Account',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'remitter',
    },
    remitterAddress1: {
      label: 'Remitter Address 1',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'remitter',
    },
    remitterAddress2: {
      label: 'Remitter Address 2',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'remitter',
    },
    remitterAddress3: {
      label: 'Remitter Address 3',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'remitter',
    },

    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'new', name: 'Funds Not Yet Cleared', tagType: undefined },
            { id: 'available', name: 'Available - Funds Cleared', tagType: 'info' },
            { id: 'assigned', name: 'Assigned to Payment', tagType: undefined },
            { id: 'complete', name: 'Completed', tagType: 'success' },
            { id: 'cancelled', name: 'Cancelled', tagType: undefined },
            { id: 'noClient', name: 'No Client', tagType: 'warning'},
            { id: 'noBankAccount', name: 'No Bank Account', tagType: 'warning'},
          ],
        },
      },
      default: 'new',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, search: true, csv: false },
      group: 'system',
    },
    messageStatus: {
      label: 'Message Status',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    valueDate: {
      label: 'Value Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
        },
      },
      displayType: {
        displayAsTag: true,
        tagTypeFunctionName: 'valueDateTagType',
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, search: true, csv: false,  },
      group: 'integration',
    },
    dateReceived: {
      label: 'Date Received',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false, search: true, },
      group: 'integration',
    },
    dateAssigned: {
      label: 'Date Assigned',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    integrationId: {
      label: 'Integration ID',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    message: {
      label: 'Message',
      datatype: { json: {} },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'message',
    },
    messageDisplay: {
      label: 'Message',
      datatype:  { json: {} },
      default: null,
      mandatory: true,
      property: {source:'frontend',read: true, write: true, sort: 'message'},
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'message',
    },

    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
  },
};
