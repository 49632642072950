<template>
  <LDBModal result="info" header="Add User Team" size="x-large">
    <template #body>
      <LDBCreateView
        :table="table"
        :definition="definition"
        :collection_path="collectionPath"
        :showBackButton="false"
        action="createUserTeam"
        :params="{ configId: 1 }"
        :createButtonLabel="createButtonLabel"
      />
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBCreateView from '@/landobyte_vue/LDBCreateView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

export default {
  name: 'configUserTeamViewAdd',
  components: { LDBLoader, LDBCreateView },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    // ----- User Team Record -----
    table() {
      return 'LfxUserTeam';
    },
    collectionPath() {
      return `/config/1/userTeam`;
    },
    definition() {
      return getDefinition('userTeam');
    },
    createButtonLabel() {
      return 'Create User Team';
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
