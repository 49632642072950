import { RouteRecordRaw } from 'vue-router';

// ----- Todo -----

import LfxTodo from '@/components/todo/LfxTodo.vue';
import LfxTodoModal from '@/components/todo/LfxTodoModal.vue';

// ----- General views -----

import LfxDashboardList from '@/views/general/LfxDashboardList.vue';

// ----- Client Application -----

// Main

import LfxClientApplication from '@/views/clients/LfxClientApplication.vue';
import LfxClientApplicationWizard from '@/views/clients/LfxClientApplicationWizard.vue';

// Application details / summary

import LfxClientApplicationDetails from '@/views/clients/LfxClientApplicationDetails.vue';

import LfxApplicationUnifiedRelease from '@/views/clients/LfxApplicationUnifiedRelease.vue';
import LfxApplicationReject from '@/views/clients/LfxApplicationReject.vue';
import LfxApplicationCancel from '@/views/clients/LfxApplicationCancel.vue';
import LfxApplicationHide from '@/views/clients/LfxApplicationHide.vue';
import LfxApplicationResendPortfolioEmails from '@/views/clients/LfxApplicationResendPortfolioEmails.vue'
import LfxApplicationResetApplicationStatus from '@/views/clients/LfxApplicationResetApplicationStatus.vue'

// CIF (Full and associated)

import LfxClientApplicationCifDetails_v2 from '@/views/clients/LfxClientApplicationCifDetails_v2.vue';

import LfxClientApplicationNoAssociatedCifs from '@/views/clients/LfxClientApplicationNoAssociatedCifs.vue';

import LfxClientApplicationBscPicker from '@/views/clients/LfxClientApplicationBscPicker.vue';
import LfxClientApplicationOcPicker from '@/views/clients/LfxClientApplicationOcPicker.vue';

import LfxAddAssociatedCif from '@/views/clients/LfxAddAssociatedCif.vue';

import LfxCifApplicationRelease from '@/views/clients/LfxCifApplicationRelease.vue';
import LfxCifApplicationReject from '@/views/clients/LfxCifApplicationReject.vue';
import LfxCifApplicationCancel from '@/views/clients/LfxCifApplicationCancel.vue';


import LfxCifApplicationDeclineCra from '@/views/clients/LfxCifApplicationDeclineCra.vue';
import LfxCifApplicationRequestCraEdd from '@/views/clients/LfxCifApplicationRequestCraEdd.vue';

//

import LfxClientApplicationCifDetails from '@/views/clients/LfxClientApplicationCifDetails.vue'; // TODO - replace with LfxClientApplicationCifDetails_v2
import LfxClientApplicationCifInfoDetails from '@/views/clients/LfxClientApplicationCifInfoDetails.vue'; // TODO - replace with LfxClientApplicationCifDetails_v2
import LfxClientApplicationAssociatedCif from '@/views/clients/LfxClientApplicationAssociatedCif.vue'; // TODO - replace with LfxClientApplicationNoAssociatedCifs
import LfxClientApplicationAssociatedCifTileVue from '@/views/clients/LfxClientApplicationAssociatedCifTileVue.vue';

// Bank Account

import LfxClientApplicationBankAccountDetails from '@/views/clients/LfxClientApplicationBankAccountDetails.vue';
import LfxClientApplicationBankAccountSummary from '@/views/clients/LfxClientApplicationBankAccountSummary.vue'; // What is this? - old version of LfxClientApplicationBankAccountDetails // TODO - replace with LfxClientApplicationBankAccountDetails

import LfxAccountApplicatinRelease from '@/views/clients/LfxAccountApplicatinRelease.vue';
import LfxAccountApplicatinReject from '@/views/clients/LfxAccountApplicatinReject.vue';
import LfxAccountApplicatinCancel from '@/views/clients/LfxAccountApplicatinCancel.vue';

// Signatories

import LfxClientApplicationSignatories from '@/views/clients/LfxClientApplicationSignatories.vue';
import LfxAddSignatory from '@/views/signatories/LfxAddSignatory.vue';

// Documents

import LfxClientApplicationPortolioDocuments from '@/views/clients/LfxClientApplicationPortolioDocuments.vue';
import LfxClientApplicationDocuments from '@/views/clients/LfxClientApplicationDocuments.vue';
import LfxClientApplicationUploadDocument from '@/views/clients/LfxClientApplicationUploadDocument.vue';

// Portfolio

import LfxClientPorfileUpdateStatus from '@/views/clients/LfxClientPorfileUpdateStatus.vue';
import LfxClientPortfolioAddDocument from '@/views/clients/LfxClientPortfolioAddDocument.vue';
import LfxClientPortfolioRecall from '@/views/clients/LfxClientPortfolioRecall.vue';
import LfxClientPortfolioSummary from '@/views/clients/LfxClientPortfolioSummary.vue';

// Logs

import LfxClientPortfolioLogList from '@/views/clients/LfxClientPortfolioLogList.vue';
import LfxClientPortfolioHistoryList from '@/views/clients/LfxClientPortfolioHistoryList.vue';

// Review

import LfxClientApplicationReview from '@/views/clients/LfxClientApplicationReview.vue';

//
// Unused
//

import LfxClientBankInfoView from '@/views/clients/LfxClientBankInfoView.vue';
import LfxApplicationSummary from '@/views/clients/LfxApplicationSummary.vue';

const routes: RouteRecordRaw[] = [
  //
  // ----- Live Views -----

  {
    path: '/applicationsRequireAttention',
    name: '_applicationsRequireAttention',
    component: LfxDashboardList,
    meta: {
      icon: 'system/client-insights',
      label: 'Open Clients Applications',
      content: { table: 'LfxApplication', type: 'applicationRequireAttention' },
    },
  },

  // ----- Client application -----

  {
    path: '/client/:accountId(\\d+)/application/:applicationId(\\d+)',
    alias: ['/account/:accountId(\\d+)/application/:applicationId(\\d+)'],
    name: 'clientApplication',
    component: LfxClientApplication,
    meta: { icon: 'system/client-insights' },
    children: [
      //
      // ----- Application Summary
      {
        path: 'overview',
        name: 'clientApplicationOverview',
        component: LfxClientApplicationReview,
        meta: {
          content: { tabName: 'overview', label: 'Overview', actionGroup: 'clientApplicationOverview' },
        },
      },

      {
        path: 'summary',
        name: 'clientApplicationSummary',
        component: LfxClientApplicationDetails,
        meta: {
          content: { tabName: 'summary', label: 'Cover Page', actionGroup: 'clientApplicationSummary' },
        },
        children: [
          // submit
          {
            path: 'unified_release',
            name: 'clientApplicationSummaryUnifiedRelease',
            component: LfxApplicationUnifiedRelease,
          },
          {
            path: 'portfolio_summary',
            name: 'clientApplicationSummaryPortfolioSummary',
            component: LfxClientPortfolioSummary,
          },
          {
            path: 'add_document',
            name: 'clientApplicationSummaryAddDocument',
            component: LfxClientPortfolioAddDocument,
          },
          {
            path: 'recall_portfolio',
            name: 'clientApplicationSummaryRecallPortfolio',
            component: LfxClientPortfolioRecall,
          },
          {
            path: 'update_portfolio_status',
            name: 'clientApplicationSummaryUpdatePortfolioStatus',
            component: LfxClientPorfileUpdateStatus,
          },
          {
            path: 'cancel_application',
            name: 'clientApplicationSummaryCancel',
            component: LfxApplicationCancel,
          },
          {
            path: 'reject_application',
            name: 'clientApplicationSummaryReject',
            component: LfxApplicationReject,
          },
          {
            path: 'hideApplication',
            name: 'clientApplicationHide',
            component: LfxApplicationHide,
          },
          {
            path: 'resendApplicationPortfolioEmails',
            name: 'resendApplicationPortfolioEmails',
            component: LfxApplicationResendPortfolioEmails,
          },
          {
            path: 'resetApplicationStatus',
            name: 'resetApplicationStatus',
            component: LfxApplicationResetApplicationStatus,
          },
        ],
      },

      // ----- Client CIF

      // View

      {
        path: 'client_cif/:cifId(\\d+)',
        name: 'clientApplicationClientCifDetailsView',
        component: LfxClientApplicationCifDetails_v2,
        meta: {
          content: {
            tabName: 'clientCif',
            label: 'Client Details',
            actionGroup: 'clientApplication_CifDetailsView',
            cifType: 'full',
          },
        },
        children: [
          // re-submit
          { path: 'release', name: 'clientApplicationClientCifDetailsViewRelease', component: LfxCifApplicationRelease },
          { path: 'reject', name: 'clientApplicationClientCifDetailsViewReject', component: LfxCifApplicationReject },
          { path: 'declineCifCra', name: 'declineCifCra', component: LfxCifApplicationDeclineCra },
          { path: 'requestCraEdd', name: 'requestCraEdd', component: LfxCifApplicationRequestCraEdd },
        ],
      },

      // Edit

      {
        path: 'client_cif/:cifId(\\d+)/edit',
        name: 'clientApplicationClientCifDetailsEdit',
        component: LfxClientApplicationCifDetails_v2,
        meta: {
          content: {
            tabName: 'clientCif',
            label: 'Edit Client Details',
            actionGroup: 'clientApplication_CifDetailsEdit',
            cifType: 'full',
            view: 'edit',
          },
        },
        children: [
          {
            path: 'bsc',
            name: 'clientApplicationClientCifDetailsEditBscPicker',
            component: LfxClientApplicationBscPicker,
          },
          {
            path: 'oc',
            name: 'clientApplicationClientCifDetailsEditOcPicker',
            component: LfxClientApplicationOcPicker,
          },
        ],
      },

      // ----- Associated CIFs

      // None

      {
        path: 'associated_cifs',
        name: 'clientApplicationNoAssociatedCifs',
        component: LfxClientApplicationNoAssociatedCifs,
        meta: {
          content: {
            tabName: 'associatedCifs',
            label: 'Associated Parties',
            actionGroup: 'clientApplicationNoAssociatedCifs',
            cifType: 'associated',
          },
        },
        children: [
          {
            path: 'add',
            name: 'clientApplicationNoAssociatedCifsAdd',
            component: LfxAddAssociatedCif,
          },
        ],
      },

      // List // TODO - later

      // View

      {
        path: 'associated_cif/:cifId(\\d+)',
        name: 'clientApplicationAssociatedCifDetailsView',
        components: { associatedCifs: LfxClientApplicationCifDetails_v2 },
        meta: {
          content: {
            tabName: 'associatedCifs',
            label: 'Associated Party Details',
            actionGroup: 'clientApplication_CifDetailsView',
            cifType: 'associated',
          },
        },
        children: [
          {
            path: 'add',
            name: 'clientApplicationAssociatedCifsDetailsViewAdd',
            component: LfxAddAssociatedCif,
          },
          {
            path: 'cancel',
            name: 'clientApplicationAssociatedCifDetailsViewCancel',
            component: LfxCifApplicationCancel,
          },
          // re-submit
          {
            path: 'release',
            name: 'clientApplicationAssociatedCifDetailsViewRelease',
            component: LfxCifApplicationRelease,
          },
          {
            path: 'reject',
            name: 'clientApplicationAssociatedCifDetailsViewReject',
            component: LfxCifApplicationReject,
          },
        ],
      },

      // Edit

      {
        path: 'associated_cif/:cifId(\\d+)/edit',
        name: 'clientApplicationAssociatedCifDetailsEdit',
        component: LfxClientApplicationCifDetails_v2,
        meta: {
          content: {
            tabName: 'associatedCifs',
            label: 'Edit Associated Details',
            actionGroup: 'clientApplication_CifDetailsEdit',
            cifType: 'associated',
            view: 'edit',
          },
        },
        children: [
          {
            path: 'bsc',
            name: 'clientApplicationAssociatedCifDetailsEditBscPicker',
            component: LfxClientApplicationBscPicker,
          },
          {
            path: 'oc',
            name: 'clientApplicationAssociatedCifDetailsEditOcPicker',
            component: LfxClientApplicationOcPicker,
          },
        ],
      },

      // ----- Bank Accounts

      // View

      {
        path: 'bank_account/:applicationAccountId(\\d+)',
        name: 'clientApplicationBankAccountDetailsView',
        component: LfxClientApplicationBankAccountDetails,
        meta: {
          content: {
            tabName: 'bankAccounts',
            label: 'Bank Account Details',
            actionGroup: 'clientApplicationBankAccountDetailsView',
          },
        },
        children: [
          // cancel - for later when multiple bank accounts can be applied for
          // re-submit
          {
            path: 'release',
            name: 'clientApplicationBankAccountDetailsViewRelease',
            component: LfxAccountApplicatinRelease,
          },
          {
            path: 'reject',
            name: 'clientApplicationBankAccountDetailsViewReject',
            component: LfxAccountApplicatinReject,
          },
        ],
      },

      // Edit

      {
        path: 'bank_account/:applicationAccountId(\\d+)/edit',
        name: 'clientApplicationBankAccountDetailsEdit',
        component: LfxClientApplicationBankAccountDetails,
        meta: {
          content: {
            tabName: 'bankAccounts',
            label: 'Edit Bank Account Details',
            actionGroup: 'clientApplicationBankAccountDetailsEdit',
            view: 'edit',
          },
        },
      },

      // ----- Signatories

      // List // TODO - later

      // View

      {
        path: 'signatories',
        name: 'clientApplicationSignatories',
        component: LfxClientApplicationSignatories,
        meta: {
          content: {
            tabName: 'signatories',
            label: 'Account Opening Signatories',
            actionGroup: 'clientApplicationSignatories',
          },
        },
        children: [
          {
            path: 'add_signatory',
            name: 'clientApplicationAddSignatory',
            component: LfxAddSignatory,
            meta: { accountOpeningSignatory: true },
          },
        ],
      },

      // ----- Documents

      {
        path: 'document/:applicationDocumentId(\\d+)',
        name: 'clientApplicationDocuments',
        component: LfxClientApplicationDocuments,
        meta: {
          content: {
            stepName: 'documents',
            label: 'Documents',
            actionGroup: 'clientApplicationDocuments',
            showErrorsAndCancelled: false,
          },
        },
      },

      // Edit

      // ----- Portfolio

      // View

      // children: [
      //  submit
      // status
      // add doc - what is this for?
      // remove doc - what is this for?
      // cancel portfolio
      // link

      // reject
      // readyForSignature
      // signed
      // ]

      // ----- Logs

      {
        path: 'logs',
        name: 'clientApplicationLogs',
        component: LfxClientPortfolioLogList,
        meta: {
          content: {
            tabName: 'logs',
            label: 'Client Application Logs',
            actionGroup: 'clientApplicationLogs',
            view: 'list',
          },
        },
      },
      {
        path: 'portfolio_logs',
        name: 'clientApplicationPortfolioLogs',
        component: LfxClientPortfolioHistoryList,
        meta: {
          content: {
            tabName: 'logs',
            label: 'Client Application Portfolio Logs',
            actionGroup: 'clientApplicationPortfolioLogs',
            view: 'list',
          },
        },
      },

      //   // -------------------------- Old stuff

      //   {
      //     path: '_cif/:cifId(\\d+)/submit',
      //     name: '_clientApplicationCifDetailsViewSubmit',
      //     components: {
      //       default: LfxClientApplicationCifInfoDetails,
      //       additional: LfxClientApplicationCifDetails,
      //       modal: LfxTodoModal,
      //     },
      //     meta: {
      //       content: {
      //         stepName: 'clientApplicationCifDetailsView',
      //         label: 'CIF Info',
      //         actionGroup: 'clientApplicationCifDetailsView',
      //       },
      //     },
      //   },
      //   {
      //     path: '_cif/:cifId(\\d+)/cancel',
      //     name: '_clientApplicationCifDetailsViewCancel',
      //     components: {
      //       default: LfxClientApplicationCifInfoDetails,
      //       additional: LfxClientApplicationCifDetails,
      //       modal: LfxCifApplicationCancel,
      //     },
      //     meta: {
      //       content: {
      //         stepName: 'clientApplicationCifDetailsView',
      //         label: 'CIF Info',
      //         actionGroup: 'clientApplicationCifDetailsView',
      //       },
      //     },
      //   },
      //   {
      //     path: '_cif/:cifId(\\d+)/release',
      //     name: '_clientApplicationCifDetailsViewRelease',
      //     components: {
      //       default: LfxClientApplicationCifInfoDetails,
      //       additional: LfxClientApplicationCifDetails,
      //       modal: LfxCifApplicationRelease,
      //     },
      //     meta: {
      //       content: {
      //         stepName: 'clientApplicationCifDetailsView',
      //         label: 'CIF Info',
      //         actionGroup: 'clientApplicationCifDetailsView',
      //       },
      //     },
      //   },
      //   {
      //     path: '_cif/:cifId(\\d+)/reject',
      //     name: '_clientApplicationCifDetailsViewReject',
      //     components: {
      //       default: LfxClientApplicationCifInfoDetails,
      //       additional: LfxClientApplicationCifDetails,
      //       modal: LfxCifApplicationReject,
      //     },
      //     meta: {
      //       content: {
      //         stepName: 'clientApplicationCifDetailsView',
      //         label: 'CIF Info',
      //         actionGroup: 'clientApplicationCifDetailsView',
      //       },
      //     },
      //   },

      //   // Edit

      //   {
      //     path: '_cif/:cifId(\\d+)/edit',
      //     name: '_clientApplicationCifDetailsEdit',
      //     components: { default: LfxClientApplicationCifInfoDetails, additional: LfxClientApplicationCifDetails },
      //     meta: {
      //       content: {
      //         stepName: 'clientApplicationCifDetailsEdit',
      //         label: 'Capture CIF Information',
      //         actionGroup: 'clientApplicationCifDetailsEdit',
      //         view: 'edit',
      //       },
      //     },
      //   },
      //   {
      //     path: '_cif/:cifId(\\d+)/edit/bscPicker',
      //     name: '_clientApplicationCifDetailsEditBscPicker',
      //     components: {
      //       default: LfxClientApplicationCifInfoDetails,
      //       additional: LfxClientApplicationCifDetails,
      //       modal: LfxClientApplicationBscPicker,
      //     },
      //     meta: {
      //       content: {
      //         stepName: 'clientApplicationCifDetailsEdit',
      //         label: 'Capture CIF Information',
      //         actionGroup: 'clientApplicationCifDetailsEdit',
      //         view: 'edit',
      //       },
      //     },
      //   },
      //   {
      //     path: '_cif/:cifId(\\d+)/edit/ocPicker',
      //     name: '_clientApplicationCifDetailsEditOcPicker',
      //     components: {
      //       default: LfxClientApplicationCifInfoDetails,
      //       additional: LfxClientApplicationCifDetails,
      //       modal: LfxClientApplicationOcPicker,
      //     },
      //     meta: {
      //       content: {
      //         stepName: 'clientApplicationCifDetailsEdit',
      //         label: 'Capture CIF Information',
      //         actionGroup: 'clientApplicationCifDetailsEdit',
      //         view: 'edit',
      //       },
      //     },
      //   },

      //   // ----- Add Associated CIF

      //   {
      //     path: '_associated_cif',
      //     name: '_clientApplicationAssociatedCif',
      //     components: { default: LfxClientApplicationAssociatedCif },
      //     meta: {
      //       content: {
      //         stepName: 'clientApplicationAssociatedCif',
      //         label: 'Associated CIF',
      //         actionGroup: 'clientApplicationAssociatedCif',
      //       },
      //     },
      //   },
      //   {
      //     path: '_associated_cif/add',
      //     name: '_clientApplicationAssociatedCifAdd',
      //     components: { default: LfxClientApplicationAssociatedCif, modal: LfxAddAssociatedCif },
      //     meta: {
      //       content: {
      //         stepName: 'clientApplicationAssociatedCif',
      //         label: 'Associated CIF',
      //         actionGroup: 'clientApplicationAssociatedCif',
      //       },
      //     },
      //   },

      //   // ----- Bank account application

      //   // View

      //   {
      //     path: '_bank_account/:applicationAccountId(\\d+)',
      //     name: '_clientApplicationBankAccountDetailsView',
      //     components: { default: LfxClientApplicationBankAccountDetails },
      //     meta: {
      //       content: {
      //         stepName: 'clientApplicationBankAccountDetailsView',
      //         label: 'Bank Account Info',
      //         actionGroup: 'clientApplicationBankAccountDetailsView',
      //       },
      //     },
      //   },
      //   {
      //     path: '_bank_account/:applicationAccountId(\\d+)/submit',
      //     name: '_clientApplicationBankAccountDetailsViewSubmit',
      //     components: { default: LfxClientApplicationBankAccountDetails, modal: LfxTodoModal },
      //     meta: {
      //       content: {
      //         stepName: 'clientApplicationBankAccountDetailsView',
      //         label: 'Bank Account Info',
      //         actionGroup: 'clientApplicationBankAccountDetailsView',
      //       },
      //     },
      //   },
      //   {
      //     path: '_bank_account/:applicationAccountId(\\d+)/cancel',
      //     name: '_clientApplicationBankAccountDetailsViewCancel',
      //     components: { default: LfxClientApplicationBankAccountDetails, modal: LfxAccountApplicatinCancel },
      //     meta: {
      //       content: {
      //         stepName: 'clientApplicationBankAccountDetailsView',
      //         label: 'Bank Account Info',
      //         actionGroup: 'clientApplicationBankAccountDetailsView',
      //       },
      //     },
      //   },
      //   {
      //     path: '_bank_account/:applicationAccountId(\\d+)/release',
      //     name: '_clientApplicationBankAccountDetailsViewRelease',
      //     components: { default: LfxClientApplicationBankAccountDetails, modal: LfxAccountApplicatinRelease },
      //     meta: {
      //       content: {
      //         stepName: 'clientApplicationBankAccountDetailsView',
      //         label: 'Bank Account Info',
      //         actionGroup: 'clientApplicationBankAccountDetailsView',
      //       },
      //     },
      //   },
      //   {
      //     path: '_bank_account/:applicationAccountId(\\d+)/reject',
      //     name: '_clientApplicationBankAccountDetailsViewReject',
      //     components: { default: LfxClientApplicationBankAccountDetails, modal: LfxAccountApplicatinReject },
      //     meta: {
      //       content: {
      //         stepName: 'clientApplicationBankAccountDetailsView',
      //         label: 'Bank Account Info',
      //         actionGroup: 'clientApplicationBankAccountDetailsView',
      //       },
      //     },
      //   },

      //   // Edit

      //   {
      //     path: '_bank_account/:applicationAccountId(\\d+)/edit',
      //     name: '_clientApplicationBankAccountDetailsEdit',
      //     components: {
      //       default: LfxClientApplicationBankAccountDetails,
      //       additional: LfxClientApplicationBankAccountSummary,
      //     },
      //     meta: {
      //       content: {
      //         stepName: 'clientApplicationBankAccountDetailsEdit',
      //         label: 'Capture Bank Account Info',
      //         actionGroup: 'clientApplicationBankAccountDetailsEdit',
      //         view: 'edit',
      //       },
      //     },
      //   },

      //   // ----- Signatories
      //   {
      //     path: '_signatories',
      //     name: '_clientApplicationSignatories',
      //     component: LfxClientApplicationSignatories,
      //     meta: {
      //       content: {
      //         stepName: 'clientApplicationSignatories',
      //         label: 'Signatories',
      //         actionGroup: 'clientApplicationSignatories',
      //       },
      //     },
      //   },
      //   // ----- Review application

      //   {
      //     path: '_review',
      //     name: '_clientApplicationReview',
      //     component: LfxClientApplicationReview,
      //     meta: {
      //       content: {
      //         stepName: 'clientApplicationReview',
      //         label: 'Review and Submit Application',
      //         actionGroup: 'clientApplicationReview',
      //       },
      //     },
      //   },
      //   // {
      //   //   path: 'review/submit',
      //   //   name: 'clientApplicationReviewSubmit',
      //   //   components: { default: LfxTodo, modal: LfxTodoModal },
      //   //   meta: {
      //   //     content: {
      //   //       stepName: 'review',
      //   //       label: 'Review and Submit Application',
      //   //       actionGroup: 'clientApplicationReview',
      //   //     },
      //   //   },
      //   // },
      //   {
      //     path: '_review/cancel',
      //     name: '_clientApplicationReviewCancel',
      //     components: { default: LfxClientApplicationReview, modal: LfxApplicationCancel },
      //     meta: {
      //       content: {
      //         stepName: 'clientApplicationReview',
      //         label: 'Review and Submit Application',
      //         actionGroup: 'clientApplicationReview',
      //       },
      //     },
      //   },
      //   {
      //     path: '_review/reject',
      //     name: '_clientApplicationReviewReject',
      //     components: { default: LfxClientApplicationReview, modal: LfxApplicationReject },
      //     meta: {
      //       content: {
      //         stepName: 'clientApplicationReview',
      //         label: 'Review and Submit Application',
      //         actionGroup: 'clientApplicationReview',
      //       },
      //     },
      //   },
      //   {
      //     path: '_review/update_status',
      //     name: '_clientApplicationReviewUpdateStatus',
      //     components: { default: LfxClientApplicationReview, modal: LfxTodoModal },
      //     meta: {
      //       content: {
      //         stepName: 'clientApplicationReview',
      //         label: 'Review and Submit Application',
      //         actionGroup: 'clientApplicationReview',
      //       },
      //     },
      //   },
      //   {
      //     path: '_review/recall',
      //     name: '_clientPortfolioRecallPortfolio',
      //     components: { default: LfxClientApplicationReview, modal: LfxClientPortfolioRecall },
      //     meta: {
      //       content: {
      //         stepName: 'clientApplicationReview',
      //         label: 'Review and Submit Application',
      //         actionGroup: 'clientApplicationReview',
      //       },
      //     },
      //   },
      //   {
      //     path: '_review/addDocument',
      //     name: '_clientPortfolioAddDocument',
      //     components: { default: LfxClientApplicationReview, modal: LfxClientPortfolioAddDocument },
      //     meta: {
      //       content: {
      //         stepName: 'clientApplicationReview',
      //         label: 'Review and Submit Application',
      //         actionGroup: 'clientApplicationReview',
      //       },
      //     },
      //   },
      //   {
      //     path: '_review/update_status',
      //     name: '_clientPortfolioSummary',
      //     components: { default: LfxClientApplicationReview, modal: LfxClientPortfolioSummary },
      //     meta: {
      //       content: {
      //         stepName: 'clientApplicationReview',
      //         label: 'Review and Submit Application',
      //         actionGroup: 'clientApplicationReview',
      //       },
      //     },
      //   },
      //   {
      //     path: '_review/update_status',
      //     name: '_clientPortfolioBankUpdateStatus',
      //     components: { default: LfxClientApplicationReview, modal: LfxClientPorfileUpdateStatus },
      //     meta: {
      //       content: {
      //         stepName: 'clientApplicationReview',
      //         label: 'Review and Submit Application',
      //         actionGroup: 'clientApplicationReview',
      //       },
      //     },
      //   },
    ],
  },

  // -------------------------------------
  // ----- Client Application Wizard -----
  // -------------------------------------

  {
    path: '/client/:accountId(\\d+)/application/:applicationId(\\d+)/wizard',
    alias: ['/account/:accountId(\\d+)/application/:applicationId(\\d+)/wizard'],
    name: 'clientApplicationWizard',
    component: LfxClientApplicationWizard,
    meta: { icon: 'system/client-insights', wizard: true },
    children: [
      //

      // ----- Client CIF

      {
        path: 'client_cif/:cifId(\\d+)',
        name: 'clientApplicationWizardClientCifDetailsEdit',
        component: LfxClientApplicationCifDetails_v2,
        meta: {
          content: {
            stepName: 'clientCif',
            label: 'Capture Client Details',
            actionGroup: 'clientApplicationWizard_CifDetailsEdit',
            cifType: 'full',
            view: 'edit',
          },
        },
        children: [
          {
            path: 'bsc',
            name: 'clientApplicationWizardClientCifDetailsEditBscPicker',
            component: LfxClientApplicationBscPicker,
          },
          {
            path: 'oc',
            name: 'clientApplicationWizardClientCifDetailsEditOcPicker',
            component: LfxClientApplicationOcPicker,
          },
        ],
      },

      // -----  Associated CIFs

      // None

      {
        path: 'associated_cifs',
        name: 'clientApplicationWizardNoAssociatedCifs',
        component: LfxClientApplicationNoAssociatedCifs,
        meta: {
          content: {
            stepName: 'associatedCifs',
            label: 'Associated Parties',
            actionGroup: 'clientApplicationWizardNoAssociatedCifs',
            cifType: 'associated',
          },
        },
        children: [
          {
            path: 'add',
            name: 'clientApplicationWizardNoAssociatedCifsAdd',
            component: LfxAddAssociatedCif,
          },
        ],
      },

      // Some

      {
        path: 'associated_cif/:cifId(\\d+)',
        name: 'clientApplicationWizardAssociatedCifDetailsEdit',
        components: { associatedCifs: LfxClientApplicationCifDetails_v2 },
        meta: {
          content: {
            stepName: 'associatedCifs',
            label: 'Capture Associated Parties',
            actionGroup: 'clientApplicationWizard_CifDetailsEdit',
            cifType: 'associated',
            view: 'edit',
          },
        },
        children: [
          {
            path: 'bsc',
            name: 'clientApplicationWizardAssociatedCifDetailsEditBscPicker',
            component: LfxClientApplicationBscPicker,
          },
          {
            path: 'oc',
            name: 'clientApplicationWizardAssociatedCifDetailsEditOcPicker',
            component: LfxClientApplicationOcPicker,
          },
          {
            path: 'add',
            name: 'clientApplicationWizardAssociatedCifDetailsEditAdd',
            component: LfxAddAssociatedCif,
          },
          {
            path: 'cancel',
            name: 'clientApplicationWizardAssociatedCifDetailsEditCancel',
            component: LfxCifApplicationCancel,
          },
        ],
      },

      // ----- Bank account application

      {
        path: 'bank_account/:applicationAccountId(\\d+)',
        name: 'clientApplicationWizardBankAccountDetailsEdit',
        component: LfxClientApplicationBankAccountDetails,
        meta: {
          content: {
            stepName: 'bankAccounts',
            label: 'Capture Bank Account Details',
            actionGroup: 'clientApplicationWizardBankAccountDetailsEdit',
            view: 'edit',
          },
        },
      },

      // ----- Signatories

      {
        path: 'signatories',
        name: 'clientApplicationWizardSignatories',
        component: LfxClientApplicationSignatories,
        meta: {
          content: {
            stepName: 'signatories',
            label: 'Account Opening Signatories',
            actionGroup: 'clientApplicationWizardSignatories',
          },
        },
        children: [
          {
            path: 'add_signatory',
            name: 'clientApplicationWizardAddSignatory',
            component: LfxAddSignatory,
            meta: { accountOpeningSignatory: true },
          },
        ],
      },
      // {
      //   path: 'addSignatory',
      //   name: 'clientApplicationWizardAddSignatory',
      //   components: { default: LfxClientApplicationSignatories, modal: LfxAddSignatory },
      //   meta: {
      //     content: {
      //       stepName: 'signatories',
      //       label: 'Account Opening Signatories',
      //       actionGroup: 'clientApplicationWizardSignatories',
      //     },
      //   },
      // },

      // ----- Review application
      // ----- Signatories

      // ----- Documents

      {
        path: 'portfolioDocument',
        name: 'clientApplicationWizardPortfolioDocuments',
        components: { documents: LfxClientApplicationPortolioDocuments },
        meta: {
          content: {
            stepName: 'portfolioDocuments',
            label: 'Documents',
            actionGroup: 'clientApplicationPortfolioDocuments',
            showErrorsAndCancelled: true,
          },
        },
      },

      {
        path: 'document/:applicationDocumentId',
        name: 'clientApplicationWizardUploadDocuments',
        components: { documents: LfxClientApplicationDocuments },
        meta: {
          content: {
            stepName: 'uploadDocuments',
            label: 'Documents',
            actionGroup: 'clientApplicationUploadDocuments',
            showErrorsAndCancelled: true,
          },
        },
        children: [
          {
            path: 'uploadDocument',
            name: 'clientApplicationUploadDocument',
            component: LfxClientApplicationUploadDocument,
          },
        ],
      },

      // ----- Review

      {
        path: 'review',
        name: 'clientApplicationWizardReview',
        component: LfxClientApplicationReview,
        meta: {
          content: {
            stepName: 'reviewAndSubmit',
            label: 'Review and Submit Application',
            actionGroup: 'clientApplicationReview',
          },
        },
      },
      {
        path: 'review/submit',
        name: 'clientApplicationReviewSubmit',
        components: { default: LfxTodo, modal: LfxTodoModal },
        meta: {
          content: {
            stepName: 'review',
            label: 'Review and Submit Application',
            actionGroup: 'clientApplicationReview',
          },
        },
      },
      // {
      //   path: '_review/cancel',
      //   name: '_clientApplicationReviewCancel',
      //   components: { default: LfxClientApplicationReview, modal: LfxApplicationCancel },
      //   meta: {
      //     content: {
      //       stepName: 'clientApplicationReview',
      //       label: 'Review and Submit Application',
      //       actionGroup: 'clientApplicationReview',
      //     },
      //   },
      // },
      // {
      //   path: '_review/reject',
      //   name: '_clientApplicationReviewReject',
      //   components: { default: LfxClientApplicationReview, modal: LfxApplicationReject },
      //   meta: {
      //     content: {
      //       stepName: 'clientApplicationReview',
      //       label: 'Review and Submit Application',
      //       actionGroup: 'clientApplicationReview',
      //     },
      //   },
      // },
      {
        path: '_review/update_status',
        name: '_clientApplicationReviewUpdateStatus',
        components: { default: LfxClientApplicationReview, modal: LfxTodoModal },
        meta: {
          content: {
            stepName: 'clientApplicationReview',
            label: 'Review and Submit Application',
            actionGroup: 'clientApplicationReview',
          },
        },
      },
      // {
      //   path: '_review/recall',
      //   name: '_clientPortfolioRecallPortfolio',
      //   components: { default: LfxClientApplicationReview, modal: LfxClientPortfolioRecall },
      //   meta: {
      //     content: {
      //       stepName: 'clientApplicationReview',
      //       label: 'Review and Submit Application',
      //       actionGroup: 'clientApplicationReview',
      //     },
      //   },
      // },
      // {
      //   path: '_review/addDocument',
      //   name: '_clientPortfolioAddDocument',
      //   components: { default: LfxClientApplicationReview, modal: LfxClientPortfolioAddDocument },
      //   meta: {
      //     content: {
      //       stepName: 'clientApplicationReview',
      //       label: 'Review and Submit Application',
      //       actionGroup: 'clientApplicationReview',
      //     },
      //   },
      // },
      {
        path: '_review/update_status',
        name: '_clientPortfolioSummary',
        components: { default: LfxClientApplicationReview, modal: LfxClientPortfolioSummary },
        meta: {
          content: {
            stepName: 'clientApplicationReview',
            label: 'Review and Submit Application',
            actionGroup: 'clientApplicationReview',
          },
        },
      },
      {
        path: '_review/update_status',
        name: '_clientPortfolioBankUpdateStatus',
        components: { default: LfxClientApplicationReview, modal: LfxClientPorfileUpdateStatus },
        meta: {
          content: {
            stepName: 'clientApplicationReview',
            label: 'Review and Submit Application',
            actionGroup: 'clientApplicationReview',
          },
        },
      },
    ],
  },
];

export default routes;
