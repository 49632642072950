//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxInwardSwiftLogDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Inward SWIFT Log record for LandoByte TS systems
//  Created     : 15 February 2022 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition } from '../definitions/LdbInterfaces';
import { LfxInwardSwiftMessageStatus, LfxInwardSwiftStatus } from './LfxInwardSwiftDef';

//----------------------------------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------------------------------

export interface LfxInwardSwiftLogInt {
  id?: string;
  inwardSwiftId?: number;
  paymentId?: number;
  swiftNumber?: string;
  status?: LfxInwardSwiftStatus;
  messageStatus?: LfxInwardSwiftMessageStatus;
  actionDetails?: {};
  timestamp?: Date;
  userId?: number;
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxInwardSwiftLogDef: LdbDefinition = {
  title: 'Inward SWIFT Log',
  table: 'LfxInwardSwiftLog',
  database: 'dynamo',
  collectionPath: '/swift/{{inwardSwiftId}}/log',
  view: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
  indexes: ['inwardSwiftId'],
  pagingType: 'backEnd',
  fields: {
    id: {
      label: 'ID',
      datatype: { id: { descriptionField: 'action', type: 'text' } },
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    inwardSwiftId: {
      label: 'Inward SWIFT',
      datatype: {
        
        foreignKey: {
          collection: 'fk_inward_swifts',
          linkTable: 'LfxInwardSwift',
          linkField: 'id',
          displayField: 'swiftNumber',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    paymentId: {
      label: 'Payment',
      datatype: {
        foreignKey: {
          collection: 'fk_payments',
          linkTable: 'LfxPayment',
          linkField: 'id',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    swiftNumber: {
      label: 'SWIFT Number',
      datatype: 'text',
      default: 'new',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'new', name: 'Funds Not Yet Cleared', tagType: undefined },
            { id: 'available', name: 'Available - Funds Cleared', tagType: 'info' },
            { id: 'assigned', name: 'Assigned to Payment', tagType: undefined },
            { id: 'complete', name: 'Completed', tagType: 'success' },
            { id: 'cancelled', name: 'Cancelled', tagType: undefined },
            { id: 'noClient', name: 'No Client', tagType: 'warning'},
            { id: 'noBankAccount', name: 'No Bank Account', tagType: 'warning'},
          ],
        },
      },
      default: 'new',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, search: true, csv: false },
      group: 'system',
    },
    messageStatus: {
      label: 'Message Status',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },
    actionDetails: {
      label: 'Action Details',
      datatype: { json: {} },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    timestamp: {
      label: 'Timestamp',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    userId: {
      label: 'User',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
  },
};
