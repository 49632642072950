<template>
  <LDBModal result="info" header="TODO">
    <template #body>
      <strong>TODO</strong>
    </template>

    <template #footer>
      <LDBButton label="Cancel" type="primary" @click="goCancelRoute"></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
export default {
  name: 'LfxTodo',
  computed: {
    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : '';
    },
  },
  methods: {
    goCancelRoute() {
      if (this.cancelRoute) {
        this.$router.replace({ name: this.cancelRoute });
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
