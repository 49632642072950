import { RouteRecordRaw } from 'vue-router';

// ----- Todo -----

import LfxTodo from '@/components/todo/LfxTodo.vue';
import LfxTodoModal from '@/components/todo/LfxTodoModal.vue';

// ----- General views -----

import LfxDashboardList from '@/views/general/LfxDashboardList.vue';

// ----- Main views -----

import LfxAccount from '@/views/accounts/LfxAccount.vue';
import LfxAccounts from '@/views/accounts/LfxAccounts.vue';

// ----- Add account -----

import LfxAddIntermediary from '@/views/intermediaries/LfxAddIntermediary.vue';
import LfxBulkEnableIntermediary from '@/views/intermediaries/LfxBulkEnableIntermediary.vue';
import LfxBulkEnableIntermediaryReport from '@/views/intermediaries/LfxBulkEnableIntermediaryReport.vue'
import LfxAddBranch from '@/views/intermediaryBranches/LfxAddBranch.vue';
import LfxBulkEnableBranch from '@/views/intermediaryBranches/LfxBulkEnableBranch.vue';
import LfxAddClient from '@/views/clients/LfxAddClient.vue';
import LfxBankImportClient from '@/views/clients/LfxBankImportClient.vue'
import LfxBulkChangeClientIntermediary from '@/views/clients/LfxBulkChangeClientIntermediary.vue'
import LfxReleaseMigratedClient from '@/views/clients/LfxReleaseMigratedClient.vue';

// ----- Accounts list -----

import LfxAccountsList from '@/views/accounts/LfxAccountsList.vue';
import LfxApplicationsList from '@/views/accounts/LfxApplicationsList.vue';

// ----- Details -----

import LfxAccountDetails from '@/views/accounts/LfxAccountDetails.vue';

import LfxEnableIntermediary from '@/views/intermediaries/LfxEnableIntermediary.vue';
import LfxDisableIntermediary from '@/views/intermediaries/LfxDisableIntermediary.vue';

import LfxAccountOpenDealLimitEdit from '@/views/accounts/LfxAccountOpenDealLimitEdit.vue';
import LfxClientBankInfoView from '@/views/clients/LfxClientBankInfoView.vue';

// import LfxUnhideAccount from '@/views/accounts/LfxUnhideAccount.vue';
import LfxDisableClientTrading from '@/views/accounts/LfxDisableClientTrading.vue'
import LfxMoveAccount from '@/views/accounts/LfxMoveAccount.vue';
import LfxHideAccount from '@/views/accounts/LfxHideAccount.vue';
import LfxCloseClient from '@/views/clients/LfxCloseClient.vue';
import LfxClientCheckTocMandate from '@/views/clients/LfxClientCheckTocMandate.vue'

// ----- Bank Accounts -----

import LfxBankAccounts from '@/views/bankAccounts/LfxBankAccounts.vue';

import LfxBankAccountsList from '@/views/bankAccounts/LfxBankAccountsList.vue';

import LfxBankAccountDetails from '@/views/bankAccounts/LfxBankAccountDetails.vue';

// import LfxBankAccountsItemList from '@/views/bankAccounts/LfxBankAccountsItemList.vue';
// import LfxBankAccount from '@/views/bankAccounts/LfxBankAccount.vue';

import LfxImportBankAccount from '@/views/bankAccounts/LfxImportBankAccount.vue';
import LfxCloseBankAccount from '@/views/bankAccounts/LfxCloseBankAccount.vue';
import LfxReopenBankAccount from '@/views/bankAccounts/LfxReopenBankAccount.vue';
import LfxBankAccountBalance from '@/views/bankAccounts/LfxBankAccountBalance.vue';
import LfxConsolidatedBankAccountBalance from '@/views/bankAccounts/LfxConsolidatedBankAccountBalance.vue';
import LfxBankAccountStatement from '@/views/bankAccounts/LfxBankAccountStatement.vue';
import LfxCommissionAccountStatement from '@/views/bankAccounts/LfxCommissionAccountStatement.vue'
import LfxStampedBankAccountStatements from '@/views/bankAccounts/LfxStampedBankAccountStatements.vue'
import LfxEditBankAccount from '@/views/bankAccounts/LfxEditBankAccount.vue';

// ----- Transactions -----

import LfxTransactionFilesList from '@/views/transactions/LfxTransactionFilesList.vue';
import LfxNewTransactionPicker from '@/views/transactions/LfxNewTransactionPicker.vue';

// ----- Deals -----

import LfxDealList from '@/views/transactions/LfxDealList.vue';

// ----- Inward SWIFTS -----

import LfxSwiftList from '@/views/transactions/LfxSwiftList.vue';
import LfxNewInwardSwift from '@/views/transactions/LfxNewInwardSwift.vue';

// ----- Beneficiaries -----

// import LfxBeneficiaries from '@/views/beneficiaries/LfxBeneficiaries.vue';
import LfxBeneficiariesList from '@/views/beneficiaries/LfxBeneficiariesList.vue';

import LfxBeneficiary from '@/views/beneficiaries/LfxBeneficiary.vue';
import LfxBeneficiaryDetails from '@/views/beneficiaries/LfxBeneficiaryDetails.vue';

import LfxBeneficiaryLogList from '@/views/beneficiaries/LfxBeneficiaryLogList.vue';
import LfxBankAccountLogList from '@/views/bankAccounts/LfxBankAccountLogList.vue';
import LfxBeneficiaryPaymentList from '@/views/beneficiaries/LfxBeneficiaryPaymentList.vue';

import LfxAddBeneficiary from '@/views/beneficiaries/LfxAddBeneficiary.vue';
import LfxAddBeneficiaryDetails from '@/views/beneficiaries/LfxAddBeneficiaryDetails.vue';
import LfxAddBeneficiaryBankAccount from '@/views/beneficiaries/LfxAddBeneficiaryBankAccount.vue';

import LfxEnableBeneficiary from '@/views/beneficiaries/LfxEnableBeneficiary.vue';
import LfxDisableBeneficiary from '@/views/beneficiaries/LfxDisableBeneficiary.vue';

import LfxEnableBeneficiaryBankAccount from '@/views/beneficiaries/LfxEnableBeneficiaryBankAccount.vue';
import LfxDisableBeneficiaryBankAccount from '@/views/beneficiaries/LfxDisableBeneficiaryBankAccount.vue';

// ----- Markups -----

import LfxAccountMarkupsList from '@/views/accounts/LfxAccountMarkupsList.vue';
import LfxAccountMarkupsLogList from '@/views/accounts/LfxAccountMarkupsLogList.vue';

import LfxAccountMarkup from '@/views/accounts/LfxAccountMarkup.vue';
import LfxAccountMarkupDetails from '@/views/accounts/LfxAccountMarkupDetails.vue';

import LfxAddAccountMarkup from '@/views/accounts/LfxAddAccountMarkup.vue';
import LfxHideAccountMarkup from '@/views/accounts/LfxHideAccountMarkup.vue';
import LfxUnhideAccountMarkup from '@/views/accounts/LfxUnhideAccountMarkup.vue';
import LfxBulkAccountMarkupEdit from '@/views/accounts/LfxBulkAccountMarkupEdit.vue';
import LfxFecAdditionalMarkupsUpdate from '@/views/accounts/LfxFecAdditionalMarkupsUpdate.vue';

// ----- Signatories -----

import LfxSignatories from '@/views/signatories/LfxSignatories.vue';

import LfxSignatoriesList from '@/views/signatories/LfxSignatoriesList.vue';

import LfxAddSignatory from '@/views/signatories/LfxAddSignatory.vue';
import LfxSignatoryConfigure from '@/views/signatories/LfxSignatoryConfigure.vue';
import LfxContactsReceiveCommunication from '@/views/signatories/LfxContactsReceiveCommunication.vue';

import LfxSignatory from '@/views/signatories/LfxSignatory.vue';
import LfxSignatoryDetails from '@/views/signatories/LfxSignatoryDetails.vue';
import LfxEnableIndividualSignatory from '@/views/signatories/LfxEnableIndividualSignatory.vue';
import LfxUpdateIndividualSignatory from '@/views/signatories/LfxUpdateIndividualSignatory.vue';
import LfxSignatoryLogList from '@/views/signatories/LfxSignatoryLogList.vue';

// ----- Users -----

import LfxUsersList from '@/views/users/LfxUsersList.vue';
import LfxAddUser from '@/views/users/LfxAddUser.vue';
import LfxBulkEnableUser from '@/views/users/LfxBulkEnableUser.vue';
import LfxUserAccessReportModal from '@/views/reports/LfxUserAccessReportModal.vue';
import LfxAccountUsersSettings from '@/views/accounts/LfxAccountUsersSettings.vue';

// ----- Applications -----

import LfxClientApplicationsList from '@/views/clients/LfxClientApplicationsList.vue';

// ----- Bulk Fees -----

import LfxBulkFeesList from '@/views/clients/LfxBulkFeesList.vue';
import LfxBulkFeeBatchDetails from '@/views/clients/LfxBulkFeeBatchDetails.vue';
import LfxBulkFeeBatchUpload from '@/views/clients/LfxBulkFeeBatchUpload.vue';

// ----- Uploads -----

import LfxImportBatchList from '@/views/general/LfxImportBatchList.vue';
import LfxImportBatchUploadBeneficiary from '@/views/general/LfxImportBatchUploadBeneficiary.vue';

// ----- Downloads -----

import LfxDownloadableDocuments from '@/views/clients/LfxDownloadableDocuments.vue';

// ----- Logs -----

import LfxAccountLogs from '@/views/clients/LfxAccountLogs.vue';

// ----- Client Application -----

import LfxClientPortfolioLogList from '@/views/clients/LfxClientPortfolioLogList.vue';
import LfxClientPortfolioHistoryList from '@/views/clients/LfxClientPortfolioHistoryList.vue';

import LfxApplicationSummary from '@/views/clients/LfxApplicationSummary.vue';

// ----- New Transaction -----

import LfxNewTransaction from '@/views/transactions/LfxNewTransaction.vue';

import LfxNewPayment from '@/views/transactions/LfxNewPayment.vue';
import LfxNewDeal from '@/views/transactions/LfxNewDeal.vue';
import LfxNewOfflineDealDetails from '@/views/transactions/LfxNewOfflineDealDetails.vue';

const routes: RouteRecordRaw[] = [
  //
  // ----- Live Views -----

  {
    path: '/clientsRequireAttention',
    name: '_clientsRequireAttention',
    component: LfxDashboardList,
    meta: {
      icon: 'system/client-insights',
      label: 'Clients Require Attention',
      content: { table: 'LfxAccount', type: 'clientsRequireAttention' },
    },
  },

  // ----- Intermediaries -----

  {
    path: '/intermediaries',
    name: '_intermediaries',
    component: LfxAccounts,
    meta: {
      icon: 'material/folder_shared',
      label: 'Intermediaries',
      content: {
        recordsAccountLevel: 'intermediary',
        layout: 'list',
        tabName: 'intermediaries',
        label: 'Intermediaries',
        actionGroup: 'intermediaries',
      },
    },
    children: [
      {
        path: '',
        name: 'intermediaries',
        component: LfxAccountsList,
      },
      {
        path: 'add_intermediary',
        name: 'intermediariesAddIntermediary',
        components: { default: LfxAccountsList, modal: LfxAddIntermediary },
        meta: { addAccountLevel: 'intermediary', cancelRoute: 'intermediaries' },
      },
      {
        path: 'bulkEnableIntermediary',
        name: 'intermediariesBulkEnable',
        components: { default: LfxAccountsList, modal: LfxBulkEnableIntermediary },
        meta: { addAccountLevel: 'intermediary', cancelRoute: 'intermediaries' },
      },
      {
        path: 'bulkEnableIntermediaryReports',
        name: 'bulkEnableIntermediaryReports',
        components: { default: LfxAccountsList, modal: LfxBulkEnableIntermediaryReport },
        meta: { addAccountLevel: 'intermediary', cancelRoute: 'intermediaries' },
      },
    ],
  },

  // ----- Intermediary Branches -----

  {
    path: '/branches',
    name: '_branches',
    component: LfxAccounts,
    meta: {
      icon: 'material/folder_shared',
      label: 'Branches',
      content: {
        recordsAccountLevel: 'intermediaryBranch',
        layout: 'list',
        tabName: 'branches',
        label: 'Branches',
        actionGroup: 'branches',
      },
    },
    children: [
      {
        path: '',
        name: 'branches',
        component: LfxAccountsList,
      },
      {
        path: 'add_branch',
        name: 'branchesBranchAdd',
        components: { default: LfxAccountsList, modal: LfxAddBranch },
        meta: { addAccountLevel: 'intermediaryBranch', cancelRoute: 'branches' },
      },
    ],
  },

  // ----- Clients -----

  {
    path: '/clients',
    name: '_clients',
    component: LfxAccounts,
    meta: {
      icon: 'system/client-insights',
      label: 'Clients',
      content: {
        recordsAccountLevel: 'client',
        layout: 'list',
        tabName: 'clients',
        label: 'Clients',
        actionGroup: 'clients',
      },
    },
    children: [
      {
        path: '',
        name: 'clients',
        component: LfxAccountsList,
      },
      {
        path: 'add_client',
        name: 'clientsAddClients',
        components: { default: LfxAccountsList, modal: LfxAddClient },
        meta: { cancelRoute: 'clients', addAccountLevel: 'client' },
      },
      {
        path: 'bulkChangeClientIntermediary',
        name: 'bulkChangeClientIntermediary',
        components: { default: LfxAccountsList, modal: LfxBulkChangeClientIntermediary },
        meta: { cancelRoute: 'clients', addAccountLevel: 'client' },
      },
    ],
  },
  {
    path: '/applications',
    name: '_applications',
    component: LfxAccounts,
    meta: {
      icon: 'system/client-insights',
      label: 'Applications',
      content: {
        layout: 'list',
        tabName: 'applications',
        label: 'Applications',
        actionGroup: 'applications',
      },
    },
    children: [
      {
        path: '',
        name: 'applications',
        component: LfxApplicationsList,
      },
    ],
  },

  // ----- Generic account -----

  {
    path: '/account/:accountId(\\d+)',
    name: 'account',
    component: LfxAccount,
    meta: {
      icon: {
        bank: 'system/settings',
        intermediary: 'material/folder_shared',
        intermediaryBranch: 'material/folder_shared',
        client: 'system/client-insights',
      },
    },
    children: [
      //
      // ------ Account Details

      {
        path: 'details',
        name: 'accountDetailsView',
        component: LfxAccountDetails,
        meta: {
          content: { tabName: 'details', label: 'Details', buttonGroup: 'accountDetailsView' },
        },
        children: [
          {
            name: 'accountDetailsViewEnableIntermediary',
            path: 'enable_intermediary',
            component: LfxEnableIntermediary,
          },
          {
            name: 'releaseMigratedClient',
            path: 'releaseMigratedClient',
            component: LfxReleaseMigratedClient,
          },
          {
            name: 'accountDetailsViewDisableIntermediary',
            path: 'disable_intermediary',
            component: LfxDisableIntermediary,
          },
          {
            path: 'new_transaction_picker',
            name: 'accountDetailsViewNewTransaction',
            component: LfxNewTransactionPicker,
          },
          {
            path: 'new_outward_payment',
            name: 'newTransactionOutwardPaymentCapture',
            component: LfxNewPayment,
            meta: { modal: { transactionType: 'outwardPayment' } },
          },
          {
            path: 'new_inward_payment',
            name: 'newTransactionInwardPaymentCapture',
            component: LfxNewPayment,
            meta: { modal: { transactionType: 'inwardPayment' } },
          },
          {
            path: 'checkTocMandate',
            name: 'checkTocMandate',
            component: LfxClientCheckTocMandate,
          },
        ],
      },
      {
        path: 'details/reopen_client',
        name: 'accountDetailsViewReopen',
        components: { default: LfxAccountDetails, modal: LfxTodoModal },
        meta: {
          content: { tabName: 'details', label: 'Details', buttonGroup: 'accountDetailsView' },
        },
      },
      {
        path: 'details/bank_info',
        name: 'accountDetailsViewBankInfo',
        components: { default: LfxAccountDetails, modal: LfxClientBankInfoView },
        meta: {
          content: { tabName: 'details', label: 'Details', buttonGroup: 'accountDetailsView' },
        },
      },
      {
        path: 'details/edit',
        name: 'accountDetailsEdit',
        component: LfxAccountDetails,
        meta: {
          content: { tabName: 'details', label: 'Details Edit', buttonGroup: 'accountDetailsEdit', view: 'edit' },
        },
      },
      {
        path: 'details/limitEdit',
        name: 'accountOpenDealLimitEdit',
        components: { default: LfxAccountDetails, modal: LfxAccountOpenDealLimitEdit },
        meta: {
          content: { tabName: 'details', label: 'Open Deal Limit Edit', buttonGroup: 'accountDetailsView' },
        },
      },
      {
        path: 'details/move_branch',
        name: 'accountDetailsMoveBranch',
        components: { default: LfxAccountDetails, modal: LfxMoveAccount },
        meta: {
          content: { tabName: 'details', label: 'Details', buttonGroup: 'accountDetailsView' },
          modal: { action: 'moveBranch', buttonLabel: 'Move Branch' },
        },
      },
      {
        path: 'details/move_client',
        name: 'accountDetailsMoveClient',
        components: { default: LfxAccountDetails, modal: LfxMoveAccount },
        meta: {
          content: { tabName: 'details', label: 'Details', buttonGroup: 'accountDetailsView' },
          modal: { action: 'moveClient', buttonLabel: 'Move Client' },
        },
      },
      {
        path: 'details/change_client_intermediary',
        name: 'accountDetailsViewChangeClientIntermediary',
        components: { default: LfxAccountDetails, modal: LfxMoveAccount },
        meta: {
          content: { tabName: 'details', label: 'Details', buttonGroup: 'accountDetailsView' },
          modal: { action: 'changeClientIntermediary', buttonLabel: 'Change Client Intermediary' },
        },
      },
      {
        path: 'details/intermediaryCommissionAccountStatement',
        name: 'intermediaryCommissionAccountStatement',
        components: { default: LfxAccountDetails, modal: LfxCommissionAccountStatement },
        meta: {
          content: { tabName: 'details', label: 'Details', buttonGroup: 'accountDetailsView' },
          modal: { action: 'intermediaryCommissionAccountStatement', buttonLabel: 'Commission Account Balance' },
        },
      },
      {
        path: 'details/close_client',
        name: 'accountDetailsViewCloseClient',
        components: { default: LfxAccountDetails, modal: LfxCloseClient },
        meta: {
          content: { tabName: 'details', label: 'Details', buttonGroup: 'accountDetailsView' },
          modal: { accountLevel: 'client' },
        },
      },
      {
        path: 'details/move_account',
        name: 'accountDetailsMoveAccount',
        components: { default: LfxAccountDetails, modal: LfxMoveAccount },
        meta: {
          content: { tabName: 'details', label: 'Details', buttonGroup: 'accountDetailsView' },
          modal: { action: 'moveAccount', buttonLabel: 'Move Account' },
        },
      },
      {
        path: 'details/disableClientTrading',
        name: 'disableClientTrading',
        components: { default: LfxAccountDetails, modal: LfxDisableClientTrading },
        meta: {
          content: { tabName: 'details', label: 'Details', buttonGroup: 'accountDetailsView' },
          modal: { action: 'disableClientTrading', buttonLabel: 'Disable Client Trading' },
        },
      },
      {
        path: 'details/hide',
        name: 'accountDetailsViewHide',
        components: { default: LfxAccountDetails, modal: LfxHideAccount },
        meta: {
          content: { tabName: 'details', label: 'Details', buttonGroup: 'accountDetailsView' },
        },
      },

      // ----- Account branches

      {
        path: 'branches',
        name: 'accountBranchesView',
        component: LfxAccountsList,
        meta: {
          content: {
            tabName: 'branches',
            label: 'Branches',
            buttonGroup: 'accountBranchesView',
            layout: 'list',
            recordsAccountLevel: 'intermediaryBranch',
          },
        },
      },
      {
        path: 'branches/add_branch',
        name: 'accountBranchAdd',
        components: { default: LfxAccountsList, modal: LfxAddBranch },
        meta: {
          content: {
            tabName: 'branches',
            label: 'Branches',
            buttonGroup: 'accountBranches',
            layout: 'list',
            recordsAccountLevel: 'intermediaryBranch',
          },
          modal: { cancelRoute: 'accountBranchesView' },
        },
      },
      {
        path: 'branches/bulkEnableBranch',
        name: 'bulkEnableBranch',
        components: { default: LfxAccountsList, modal: LfxBulkEnableBranch },
        meta: {
          content: {
            tabName: 'branches',
            label: 'Branches',
            buttonGroup: 'accountBranches',
            layout: 'list',
            recordsAccountLevel: 'intermediaryBranch',
          },
          modal: { cancelRoute: 'accountBranchesView' },
        },
      },

      // ----- Account clients

      {
        path: 'clients',
        name: 'accountClientsView',
        component: LfxAccountsList,
        meta: {
          content: {
            tabName: 'clients',
            label: 'Clients',
            buttonGroup: 'accountClientsView',
            recordsAccountLevel: 'client',
            layout: 'list',
          },
          includeBranchInListView: true,
        },
      },
      {
        path: 'clients/add_client',
        name: 'accountClientsAddClient',
        components: { default: LfxAccountsList, modal: LfxAddClient },
        meta: {
          content: {
            tabName: 'clients',
            label: 'Clients',
            recordsAccountLevel: 'client',
            layout: 'list',
          },
          modal: { cancelRoute: 'accountClientsAddClient' },
        },
      },
      {
        path: 'clients/bankImportClient',
        name: 'accountBankImportClient',
        components: { default: LfxAccountsList, modal: LfxBankImportClient },
        meta: {
          content: {
            tabName: 'clients',
            label: 'Clients',
            recordsAccountLevel: 'client',
            layout: 'list',
          },
          modal: { cancelRoute: 'accountBankImportClient' },
        },
      },

      // ----- Account transactions

      {
        path: 'transactions',
        name: 'accountTransactionsView',
        component: LfxTransactionFilesList,
        meta: {
          content: {
            tabName: 'transactions',
            label: 'Transactions',
            buttonGroup: 'accountTransactionsView',
            layout: 'list',
          },
          includeBranchInListView: true,
        },
        children: [
          {
            path: 'new_transaction_picker',
            name: 'accountTransactionsViewNewTransaction',
            component: LfxNewTransactionPicker,
          },
        ],
      },

      // ----- Account deals

      {
        path: 'deals',
        name: 'accountDealsView',
        component: LfxDealList,
        meta: {
          content: {
            tabName: 'deals',
            label: 'Deals',
            buttonGroup: 'accountDealsView',
            layout: 'list',
          },
          includeBranchInListView: true,
        },
        children: [
          {
            path: 'new_transaction_picker',
            name: 'accountTransactionsViewNewTransaction',
            component: LfxNewTransactionPicker,
          },
        ],
      },
      // ----- Account Inward SWIFTs

      {
        path: 'inwardSwifts',
        name: 'accountInwardSwiftsView',
        component: LfxSwiftList,
        meta: {
          content: {
            tabName: 'inwardSwifts',
            label: 'Inward SWIFTS',
            buttonGroup: 'accountInwardSwiftsView',
            layout: 'list',
          },
          includeBranchInListView: false,
        },
        children: [
          {
            path: 'newInwardSwift',
            name: 'newAccountInwardSwiftsView',
            component: LfxNewInwardSwift,
          },
        ],
      },

      // ----- Account bank accounts

      {
        path: 'bank_accounts',
        name: 'accountBankAccounts',
        component: LfxBankAccounts,
        meta: {
          content: {
            tabName: 'bankAccounts',
            label: 'Bank Accounts',
            buttonGroup: 'accountBankAccounts',
          },
        },
      },

      {
        path: 'bank_accounts_list',
        name: 'accountBankAccountsList',
        component: LfxBankAccountsList,
        meta: {
          content: {
            tabName: 'bankAccounts',
            label: 'Bank Accounts',
            buttonGroup: 'accountBankAccountsList',
            layout: 'list',
          },
        },
        children: [{ path: 'import', name: 'accountBankAccountsListImport', component: LfxImportBankAccount }],
      },

      {
        path: 'bank_account/:bankAccountId(\\d+)',
        name: 'accountBankAccountView',
        component: LfxBankAccountDetails,
        meta: {
          content: { tabName: 'bankAccounts', label: 'Bank Accounts', buttonGroup: 'accountBankAccountView' },
        },
        children: [
          { path: 'import', name: 'accountBankAccountViewImport', component: LfxImportBankAccount },
          { path: 'reopen', name: 'accountBankAccountViewReopen', component: LfxReopenBankAccount },
          {
            path: 'consolidatedBalances',
            name: 'consolidatedAccountBankAccountBalances',
            component: LfxConsolidatedBankAccountBalance,
          },
          { path: 'balance', name: 'accountBankAccountViewBalance', component: LfxBankAccountBalance },
          { path: 'statement', name: 'accountBankAccountViewStatement', component: LfxBankAccountStatement },
          { path: 'stampedStatements', name: 'accountStampedBankAccountViewStatements', component: LfxStampedBankAccountStatements },
          { path: 'close', name: 'accountBankAccountViewClose', component: LfxCloseBankAccount },
          { path: 'edit', name: 'accountBankAccountViewEdit', component: LfxEditBankAccount },
          { path: 'logs', name: 'accountBankAccountViewLogsList', component: LfxBankAccountLogList },
        ],
      },

      // ----- Account beneficiaries

      {
        path: 'beneficiaries_list',
        name: 'accountBeneficiariesList',
        component: LfxBeneficiariesList,
        meta: {
          content: {
            tabName: 'beneficiaries',
            label: 'Beneficiaries',
            buttonGroup: 'accountBeneficiariesList',
            layout: 'list',
          },
        },
      },

      // ----- Account markups

      {
        path: 'markups_list',
        name: 'accountMarkupsList',
        components: { default: LfxAccountMarkupsList },
        meta: {
          content: {
            tabName: 'markups',
            label: 'Markups',
            buttonGroup: 'accountMarkupsList',
            layout: 'list',
          },
        },
      },
      {
        path: 'markups/add',
        name: 'accountMarkupsListAdd',
        components: { default: LfxAccountMarkupsList, modal: LfxAddAccountMarkup },
        meta: {
          content: {
            tabName: 'markups',
            label: 'Markups',
            buttonGroup: 'accountMarkupsList',
            layout: 'list',
          },
        },
      },
      {
        path: 'markups/bulkEdit',
        name: 'bulkAccountMarkupsEdit',
        components: { default: LfxAccountMarkupsList, modal: LfxBulkAccountMarkupEdit },
        meta: {
          content: {
            tabName: 'markups',
            label: 'Markups',
            buttonGroup: 'accountMarkupsList',
            layout: 'list',
          },
        },
      },
      {
        path: 'markups/updateFecAdditionalMarkups',
        name: 'updateFecAdditionalMarkups',
        components: { default: LfxAccountMarkupsList, modal: LfxFecAdditionalMarkupsUpdate },
        meta: {
          content: {
            tabName: 'markups',
            label: 'Markups',
            buttonGroup: 'updateFecAdditionalMarkups',
            layout: 'list',
          },
        },
      },
      {
        path: 'markups/logs',
        name: 'accountMarkupLogs',
        components: { default: LfxAccountMarkupsList, modal: LfxAccountMarkupsLogList },
        meta: {
          content: {
            tabName: 'markups',
            label: 'Markups',
            buttonGroup: 'accountMarkupsList',
            layout: 'list',
          },
        },
      },

      // ----- Account Signatories

      // {
      //   path: 'bank_accounts',
      //   name: 'accountBankAccounts',
      //   component: LfxBankAccounts,
      //   meta: {
      //     content: {
      //       tabName: 'bankAccounts',
      //       label: 'Bank Accounts',
      //       buttonGroup: 'accountBankAccounts',
      //     },
      //   },
      // },

      {
        path: 'signatories',
        name: 'accountSignatories',
        component: LfxSignatories,
        meta: { content: { tabName: 'signatories', label: 'Signatories', buttonGroup: 'accountSignatories' } },
      },

      {
        path: 'signatories_list',
        name: 'accountSignatoriesList',
        component: LfxSignatoriesList,
        meta: {
          content: {
            tabName: 'signatories',
            label: 'Signatories',
            buttonGroup: 'accountSignatoriesList',
            layout: 'list',
            signatoryTypes: 'signers',
          },
        },
        children: [
          {
            path: 'add',
            name: 'accountSignatoriesListAdd',
            component: LfxAddSignatory,
            meta: {
              modal: {
                signatoryType: 'signer',
              },
            },
          },
          {
            path: 'configure',
            name: 'accountSignatoriesConfigure',
            component: LfxSignatoryConfigure,
          },
        ],
      },

      {
        path: 'signatories/enableIndividualSignatory',
        name: 'enableIndividualSignatory',
        components: { default: LfxAccountDetails, modal: LfxEnableIndividualSignatory },
        meta: {
          content: { tabName: 'signatories', label: 'Signatories', buttonGroup: 'accountSignatoriesList' },
        },
      },

      // ----- Account Contacts

      {
        path: 'contacts/list',
        name: 'accountContactsList',
        component: LfxSignatoriesList,
        meta: {
          content: {
            tabName: 'contacts',
            label: 'Contacts',
            buttonGroup: 'accountContactsList',
            layout: 'list',
            signatoryTypes: 'all',
          },
        },
        children: [
          {
            path: 'add',
            name: 'accountContactsListAdd',
            component: LfxAddSignatory,
            meta: {
              modal: {
                signatoryType: 'contact',
              },
            },
          },
          {
            path: 'communication',
            name: 'contactsReceiveCommunication',
            component: LfxContactsReceiveCommunication,
          },
        ],
      },

      // ----- Account Users

      {
        path: 'users',
        name: 'accountUsersView',
        components: { default: LfxUsersList },
        meta: {
          content: {
            tabName: 'users',
            label: 'Users',
            buttonGroup: 'accountUsersView',
            layout: 'list',
          },
        },
        children: [{ name: 'accountUsersViewSettings', path: 'settings', component: LfxAccountUsersSettings }],
      },
      {
        path: 'users/add_user',
        name: 'accountUsersAddUser',
        components: { default: LfxUsersList, modal: LfxAddUser },
        meta: {
          content: {
            tabName: 'users',
            label: 'Users',
            buttonGroup: 'accountUsersView',
            layout: 'list',
          },
          modal: { cancelRoute: 'accountUsersView' },
        },
      },
      {
        path: 'users/bulkEnableUser',
        name: 'bulkEnableUser',
        components: { default: LfxAccountsList, modal: LfxBulkEnableUser },
        meta: {
          content: {
            tabName: 'users',
            label: 'Users',
            buttonGroup: 'accountUsersView',
            layout: 'list',
          },
          modal: { cancelRoute: 'accountUsersView' },
        },
      },
      {
        path: 'users/accessReport',
        name: 'accountUsersAccessReport',
        components: { default: LfxAccountsList, modal: LfxUserAccessReportModal },
        meta: {
          content: {
            tabName: 'users',
            label: 'Users',
            buttonGroup: 'accountUsersView',
            layout: 'list',
          },
          modal: { cancelRoute: 'accountUsersView' },
        },
      },

      // ----- Account settings

      {
        path: 'settings',
        name: 'accountSettingsView',
        component: LfxTodo, //TODO
        meta: {
          content: {
            tabName: 'settings',
            label: 'Settings',
            buttonGroup: 'accountSettingsView',
          },
        },
      },

      // ----- Bulk Fees

      {
        path: 'bulkFees',
        name: 'accountBulkFeesList',
        component: LfxBulkFeesList,
        meta: {
          content: {
            tabName: 'bulkFees',
            label: 'Bulk Fees',
            buttonGroup: 'bulkFees',
            layout: 'list',
          },
        },
      },
      {
        path: 'bulkFee/:bulkFeeBatchId(\\d+)',
        name: 'accountBulkFeesDetails',
        components: { default: LfxBulkFeesList, modal: LfxBulkFeeBatchDetails },
        meta: {
          content: { tabName: 'bulkFees', label: 'Bulk Fees', buttonGroup: 'bulkFees' },
        },
      },
      {
        path: 'bulkFees/upload',
        name: 'uploadBulkFeeBatch',
        components: { default: LfxBulkFeesList, modal: LfxBulkFeeBatchUpload },
        meta: {
          content: { tabName: 'bulkFees', label: 'Bulk Fees', buttonGroup: 'bulkFees' },
        },
      },
      // ------ Account Uploads
      {
        path: 'uploads',
        name: 'accountUploadsList',
        component: LfxImportBatchList,
        meta: {
          content: {
            tabName: 'uploads',
            label: 'Uploads',
            buttonGroup: 'uploads',
            layout: 'list',
          },
        },
      },
      {
        path: 'uploads/beneficiaryImport',
        name: 'uploadBulkBeneficiaryImportBatch',
        components: { default: LfxImportBatchList, modal: LfxImportBatchUploadBeneficiary },
        meta: {
          content: { tabName: 'bulkFees', label: 'Bulk Fees', buttonGroup: 'bulkFees' },
        },
      },
      {
        path: 'downloadableDocuments',
        name: 'accountDownloadableDocuments',
        component: LfxDownloadableDocuments,
        meta: {
          content: {
            tabName: 'downloadableDocuments',
            label: 'Downloads',
            buttonGroup: 'downloadableDocuments',
          },
        },
      },

      // ------ Client Applications

      {
        path: 'applications_list',
        name: 'accountApplicationsList',
        component: LfxClientApplicationsList,
        meta: {
          content: {
            tabName: 'applications',
            label: 'Applications',
            buttonGroup: 'accountApplicationsList',
            layout: 'list',
          },
        },
      },

      {
        path: 'applicationSummary/:applicationId(\\d+)',
        name: 'applicationSummary',
        component: LfxApplicationSummary,
        meta: {
          content: {
            tabName: 'applicationSummary',
            label: 'Current Application',
            buttonGroup: 'applicationSummary',
          },
        },
      },

      {
        path: 'history',
        name: 'clientApplicationHistory',
        component: LfxClientPortfolioHistoryList,
        meta: {
          content: {
            tabName: 'logs',
            label: 'Logs',
            buttonGroup: 'accountLogsView',
            layout: 'list',
          },
        },
      },
      {
        path: 'applicationLogs',
        name: 'clientApplicationLogs',
        component: LfxClientPortfolioLogList,
        meta: {
          content: {
            tabName: 'logs',
            label: 'Logs',
            buttonGroup: 'accountLogsView',
            layout: 'list',
          },
        },
      },

      // ----- Logs

      {
        path: 'logs',
        name: 'accountLogsView',
        component: LfxAccountLogs,
        meta: {
          content: {
            tabName: 'logs',
            label: 'Logs',
            buttonGroup: 'accountLogsView',
            layout: 'list',
          },
        },
      },
    ],
  },

  // ----- Beneficiaries -----

  // FIXME for client beneficiary menu

  // ----- Add Beneficiary

  // Add Beneficiary

  {
    path: '/account/:accountId(\\d+)/add_beneficiary',
    name: 'addBeneficiary',
    component: LfxAddBeneficiary,
    meta: {
      icon: {
        bank: 'system/settings',
        intermediary: 'material/folder_shared',
        intermediaryBranch: 'material/folder_shared',
        client: 'system/client-insights',
      },
    },
    children: [
      {
        path: 'details',
        name: 'addBeneficiaryDetails',
        component: LfxAddBeneficiaryDetails,
        meta: {
          content: {
            stepName: 'addBeneficiaryDetails',
            actionGroup: 'addBeneficiaryDetails',
            label: 'Capture Beneficiary Details',
            formButtons: { createButtonLabel: 'Next' },
          },
        },
      },
      {
        path: 'bank_account',
        name: 'addBeneficiaryBankAccount',
        component: LfxAddBeneficiaryBankAccount,
        meta: {
          content: {
            stepName: 'addBeneficiaryBankAccount',
            actionGroup: 'addBeneficiaryBankAccount',
            label: "Capture Beneficiary's Bank Account",
          },
        },
      },
    ],
  },

  // Complete Beneficiary

  {
    path: '/account/:accountId(\\d+)/beneficiary/:beneficiaryId(\\d+)/complete_beneficiary',
    name: 'completeBeneficiary',
    component: LfxAddBeneficiary,
    meta: {
      icon: {
        bank: 'system/settings',
        intermediary: 'material/folder_shared',
        intermediaryBranch: 'material/folder_shared',
        client: 'system/client-insights',
      },
    },
    children: [
      {
        path: 'details',
        name: 'completeBeneficiaryDetails',
        component: LfxBeneficiaryDetails,
        meta: {
          content: {
            stepName: 'completeBeneficiaryDetails',
            actionGroup: 'completeBeneficiaryDetails',
            label: 'Edit Beneficiary Details',
            view: 'edit',
            formButtons: { showNextButton: true },
          },
        },
      },
      {
        path: 'bank_account',
        name: 'completeBeneficiaryBankAccount',
        component: LfxAddBeneficiaryBankAccount,
        meta: {
          content: {
            stepName: 'completeBeneficiaryBankAccount',
            actionGroup: 'completeBeneficiaryBankAccount',
            label: "Capture Beneficiary's Bank Account",
            formButtons: { showBackButton: true, createButtonLabel: 'Done' },
          },
        },
      },
    ],
  },

  // ----- Account Beneficiary

  {
    path: '/account/:accountId(\\d+)/beneficiary/:beneficiaryId(\\d+)',
    name: 'beneficiary',
    component: LfxBeneficiary,
    meta: {
      icon: {
        bank: 'system/settings',
        intermediary: 'material/folder_shared',
        intermediaryBranch: 'material/folder_shared',
        client: 'system/client-insights',
      },
    },
    children: [
      //
      // ----- Beneficiary Details

      {
        path: 'details',
        name: 'beneficiaryDetailsView',
        component: LfxBeneficiaryDetails,
        meta: {
          content: { tabName: 'details', label: 'Beneficiary Details', buttonGroup: 'beneficiaryDetailsView' },
        },
        children: [
          { path: 'enable', name: 'beneficiaryDetailsViewEnable', component: LfxEnableBeneficiary },
          { path: 'disable', name: 'beneficiaryDetailsViewDisable', component: LfxDisableBeneficiary },
        ],
      },

      {
        path: 'edit',
        name: 'beneficiaryDetailsEdit',
        component: LfxBeneficiaryDetails,
        meta: {
          content: {
            tabName: 'details',
            label: 'Edit Beneficiary Details',
            buttonGroup: 'beneficiaryDetailsEdit',
            view: 'edit',
          },
        },
      },

      // ----- Beneficiary Bank Accounts

      // Add bank account

      {
        path: 'add_bank_account',
        name: 'beneficiaryAddBankAccount',
        component: LfxAddBeneficiaryBankAccount,
        meta: {
          content: {
            tabName: 'bankAccounts',
            label: 'Add Beneficiary Bank Account',
            buttonGroup: 'beneficiaryAddBankAccount',
            formButtons: { createLabel: 'Add Bank Account' },
          },
        },
      },

      // Bank accounts loader

      {
        path: 'bank_accounts',
        name: 'beneficiaryBankAccounts',
        component: LfxBankAccounts,
        meta: {
          content: {
            tabName: 'bankAccounts',
            label: 'Beneficiary Bank Accounts',
            buttonGroup: 'beneficiaryBankAccounts',
          },
        },
      },

      // Bank accounts list

      {
        path: 'bank_accounts_list',
        name: 'beneficiaryBankAccountsList',
        component: LfxBankAccountsList,
        meta: {
          content: {
            tabName: 'bankAccounts',
            label: 'Beneficiary Bank Accounts',
            buttonGroup: 'beneficiaryBankAccountsList',
            layout: 'list',
          },
        },
      },

      // View Bank Account

      {
        path: 'bank_account/:bankAccountId(\\d+)',
        name: 'beneficiaryBankAccountView',
        component: LfxBankAccountDetails,
        meta: {
          content: {
            tabName: 'bankAccounts',
            label: 'Beneficiary Bank Account',
            buttonGroup: 'beneficiaryBankAccountView',
          },
        },
        children: [
          { path: 'enable', name: 'beneficiaryBankAccountViewEnable', component: LfxEnableBeneficiaryBankAccount },
          { path: 'disable', name: 'beneficiaryBankAccountViewDisable', component: LfxDisableBeneficiaryBankAccount },
        ],
      },

      {
        path: 'bank_account/:bankAccountId(\\d+)/edit',
        name: 'beneficiaryBankAccountEdit',
        component: LfxBankAccountDetails,
        meta: {
          content: {
            tabName: 'bankAccounts',
            label: 'Beneficiary Bank Account',
            buttonGroup: 'beneficiaryBankAccountEdit',
            view: 'edit',
          },
        },
      },

      // ----- Logs

      {
        path: 'logs',
        name: 'beneficiaryLogsList',
        component: LfxBeneficiaryLogList,
        meta: {
          content: { tabName: 'logs', label: 'Beneficiary Logs', buttonGroup: 'beneficiaryLogsList', layout: 'list' },
        },
      },

      // ----- Payments

      {
        path: 'payments',
        name: 'beneficiaryPayments',
        components: { beneficiaryPayments: LfxBeneficiaryPaymentList },
        meta: {
          content: {
            tabName: 'payments',
            label: 'Beneficiary Payments',
            buttonGroup: 'beneficiaryPayments',
            layout: 'list',
          },
          viewIsBeneficiaryPaymentList: true,
        },
      },
    ],
  },

  // ----- Account Markup -----

  {
    path: '/account/:accountId(\\d+)/markup/:markupId(\\d+)',
    name: 'accountMarkup',
    component: LfxAccountMarkup,
    meta: {
      icon: {
        bank: 'system/settings',
        intermediary: 'material/folder_shared',
        intermediaryBranch: 'material/folder_shared',
        client: 'system/client-insights',
      },
    },
    children: [
      {
        path: '',
        name: 'accountMarkupView',
        components: { default: LfxAccountMarkupDetails },
        meta: {
          content: {
            tabName: 'markups',
            label: 'Markup',
            buttonGroup: 'accountMarkupView',
          },
        },
      },
      {
        path: 'show',
        name: 'accountMarkupViewUnhide',
        components: { default: LfxAccountMarkupDetails, modal: LfxUnhideAccountMarkup },
        meta: {
          content: {
            tabName: 'markups',
            label: 'Markup',
            buttonGroup: 'accountMarkupView',
          },
        },
      },
      {
        path: 'edit',
        name: 'accountMarkupEdit',
        components: { default: LfxAccountMarkupDetails },
        meta: {
          content: {
            tabName: 'markups',
            label: 'Markup',
            buttonGroup: 'accountMarkupEdit',
            view: 'edit',
          },
        },
      },
      {
        path: 'hide',
        name: 'accountMarkupViewHide',
        components: { default: LfxAccountMarkupDetails, modal: LfxHideAccountMarkup },
        meta: {
          content: {
            tabName: 'markups',
            label: 'Markup',
            buttonGroup: 'accountMarkupView',
          },
        },
      },
    ],
  },

  // ----- Account Signatories -----

  {
    path: '/account/:accountId(\\d+)/signatory/:signatoryId(\\d+)',
    name: 'accountSignatory',
    component: LfxSignatory,
    meta: { icon: 'system/client-insights' },
    children: [
      {
        path: 'details',
        name: 'accountSignatoryDetailsView',
        component: LfxSignatoryDetails,
        meta: { content: { label: 'Signatory Details', buttonGroup: 'signatoryDetailsView' } },
      },
      {
        path: 'details/edit',
        name: 'accountSignatoryDetailsEdit',
        component: LfxSignatoryDetails,
        meta: { content: { label: 'Edit Signatory Details', view: 'edit', buttonGroup: 'signatoryDetailsEdit' } },
      },
      {
        path: 'details/updateIndividualSignatory',
        name: 'updateIndividualSignatory',
        components: { default: LfxSignatoryDetails, modal: LfxUpdateIndividualSignatory },
        meta: {
          content: { tabName: 'signatories', label: 'Signatories', buttonGroup: 'accountSignatoriesList' },
        },
      },
      {
        path: 'details/logs',
        name: 'signatoryLogView',
        components: { default: LfxSignatoryDetails, modal: LfxSignatoryLogList },
        meta: { content: { label: 'Edit Signatory Details', view: 'edit', buttonGroup: 'signatoryDetailsEdit' } },
      },
    ],
  },
];

export default routes;
