<template>
  <LDBModal result="warning" :header="header">
    <template #body>
      <ol>
        <li>The user will be hidden from normal views.</li>
        <li>Searching for the user will still display the user.</li>
        <li>Setting a user to "Hidden" willl not allow the same email address to be used elsewhere.</li>
      </ol>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in buttons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

export default {
  name: 'LfxHideUser',
  components: {},
  props: {},
  emits: {},
  created() {},
  mounted() {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['user']),
    userId() {
      return +this.$route.params.userId;
    },
    record() {
      return this.user?.[this.userId];
    },
    header() {
      return `Hide User: ${this.user[this.userId].userDisplayName}`;
    },
    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta?.modal?.cancelRoute : 'todo';
      //TODO: go to user's home as fallback
    },
    buttons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelHideUser',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.replace({ name: self.cancelRoute });
          },
        },
        {
          name: 'hideUser',
          label: 'Hide User',
          type: 'primary',
          guards: self.record.hideUser_buttonGuard, //TODO
          clickEvent() {
            self.hideUser();
          },
        },
      ];
      return allButtons.filter((button) => button.guards);
    },
  },
  methods: {
    async hideUser() {
      let response = await this.user[this.userId].hideUser();
      if (response.status === 'success') {
        this.$router.replace({ name: 'userDetailsView' });
      }
    },
  },
};
</script>
