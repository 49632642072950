<template>
  <div>
    <p>Click Enable User to send a Welcome Email to the user.</p>
    <p>The Welcome Email will contain the user's login email address, as well as a temporary password.</p>
    <p>The temporary password will be valid for 24 hours.</p>
    <p>
      If the password expires before the user logs in, you can send a new temporary password to the user from the Details
      tab.
    </p>
    <p>The user will be guided through the process of capturing their own password.</p>
    <capitec-action-bar>
      <LDBButton
        v-for="button in leftButtons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        :action="button.action"
        @click="button.clickEvent"
      ></LDBButton>
      <capitec-spacer />
      <LDBButton
        v-for="button in rightButtons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        :action="button.action"
        :params="button.params"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      ></LDBButton>
    </capitec-action-bar>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxUserSummary',
  components: { LDBField },
  props: {},
  emits: ['step-back'],
  created() {},
  mounted() {},
  data() {
    return {
      newRecord: {},
    };
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['user','account']),

    // ----- Buttons -----

    leftButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'back',
          label: 'Back',
          type: 'clear',
          action: 'click',
          guards: true,
          clickEvent() {
            self.$emit('step-back');
          },
        },
      ];
      return allButtons;
    },

    rightButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelConfigureUser',
          label: 'Cancel',
          type: '',
          action: 'click',
          guards: true,
          clickEvent() {
            self.$router.replace({ name: 'userDetailsView' });
          },
        },
        {
          name: 'enableUser',
          label: 'Enable User',
          type: 'primary',
          action: 'enableUser',
          params: { userId: this.recordId },
          guards: true,
          enabledGuards: this.accountRecord?.accountLevel !== 'bank' || !!this.record?.userTeamId,
          clickEvent(response) {
            if (response.status === 'success') {
              self.$router.replace({ name: 'userDetailsView' });
            }
          },
        },
      ];
      return allButtons;
    },

    // ----- Definition -----

    definition() {
      return getDefinition('user');
    },

    // ----- Record -----

    recordId() {
      return +this.$route.params.userId;
    },
    record() {
      let rec = this.user?.[this.recordId];
      return rec;
    },
    accountRecord() {
      return this.account?.[this.record?.accountId]
    }
  },
  methods: {},
};
</script>

<style scoped>
p {
  margin: 20px;
}
</style>
