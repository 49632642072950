import * as admin_actions from './LfxAdminActions';
import * as client_actions from './LfxClientActions';
import * as transaction_actions from './LfxTransactionActions';
import * as navigation_actions from './LfxNavigationActions';

const ACTIONS = {
  ...admin_actions,
  ...client_actions,
  ...transaction_actions,
  ...navigation_actions,
};

export default ACTIONS;
