//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LdbPermissionDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Permission record for LandoByte TS systems
//  Created     : 14 August 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition } from '../definitions/LdbInterfaces';
import { standardTextWhiteList } from '../sharedUtils/LdbValidations';

import type { LfxAccountAccountLevel } from './LfxAccountDef';

//----------------------------------------------------------------------------------------------------------------------

export type LdbPermissionStatus = 'active' | 'disabled';

//----------------------------------------------------------------------------------------------------------------------

export interface LdbPermissionInt {
  id?: string;
  uuid?: string;
  description?: string;
  category?: string;
  sortIndex?: number;
  accountLevels?: LfxAccountAccountLevel[];
  requestPermissions?: string[];
  reviewPermissions?: string[];
  requiresReview?: boolean;
  requiresBankUser?: boolean;
  status?: LdbPermissionStatus;
  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

export const LdbPermissionDef: LdbDefinition = {
  title: 'Permission',
  table: 'LdbPermission',
  collectionPath: '/config/1/permission',
  view: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
  indexes: [],
  pagingType: 'frontEnd',
  fields: {
    id: {
      label: 'Name',
      datatype: { id: { descriptionField: 'description', type: 'text' } },
      default: null,
      mandatory: true,
      validators: {
        isAlpha: { msg: 'Please use only alpha charaters' },
        len: { msg: 'Name should be between 5 and 30 characters', args: [5, 30] },
      },
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
    },
    uuid: {
      label: 'UUID',
      datatype: 'uuid',
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    description: {
      label: 'Description',
      datatype: 'text',
      default: null,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Description should be between 5 and 250 characters', args: [5, 250] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
    },
    category: {
      label: 'Category',
      datatype: 'text',
      default: null,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Category should be between 5 and 50 characters', args: [5, 50] },
      },
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
    },
    sortIndex: {
      label: 'Sort Index',
      datatype: 'integer',
      default: null,
      mandatory: true,
      validators: {
        isNumeric: { msg: 'Sort Index should be numeric' },
        max: { msg: 'Sort Index can not be greater than 1000', args: [1000] },
      },
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
    },
    accountLevels: {
      label: 'Account Levels',
      datatype: {
        json: {},
      },
      displayType: {
        datatype: 'foreignKeyList',
        component: 'LDBFieldForeignKeyList',
        foreignKeyList: {
          linkField: 'id',
          displayField: 'name',
          linkTable: 'LdbAccountLevel',
          collection: 'fk_account_levels',
        },
      },
      default: null,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Account Levels should be between 2 and 250 characters', args: [2, 250] },
      },
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
    },
    requestPermissions: {
      label: 'Request Permissions',
      datatype: {
        json: {},
      },
      displayType: {
        datatype: 'foreignKeyList',
        component: 'LDBFieldForeignKeyList',
        foreignKeyList: {
          linkField: 'id',
          displayField: 'description',
          linkTable: 'LdbPermission',
          collection: 'fk_permissions',
        },
      },
      default: null,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Request Permissions should be between 2 and 250 characters', args: [2, 250] },
      },
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
    },
    reviewPermissions: {
      label: 'Review Permissions',
      datatype: {
        json: {},
      },
      displayType: {
        datatype: 'foreignKeyList',
        // component: 'LDBFieldBulletList',
        component: 'LDBFieldForeignKeyList',
        foreignKeyList: {
          linkField: 'id',
          displayField: 'description',
          linkTable: 'LdbPermission',
          collection: 'fk_permissions',
        },
      },
      default: null,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Review Permissions should be between 2 and 250 characters', args: [2, 250] },
      },
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
    },
    requiresReview: {
      label: 'Requires Review',
      datatype: 'boolean',
      default: false,
      mandatory: true,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
    },
    requiresBankUser: {
      label: 'Requires Bank User',
      datatype: 'boolean',
      default: false,
      mandatory: true,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
    },

    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'active', name: 'Active', tagType: 'success' },
            { id: 'disabled', name: 'Disabled', tagType: undefined },
          ],
        },
      },
      default: 'active',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },

    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
  },
};
