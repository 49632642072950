<template>
  <div class="paged-table-wrapper">
    <template v-if="showSearchBar">
      <div class="paged-table-search-bar">
        <template v-if="mountedComplete">
        <LDBSearchBar        
        @on-selector-change="onSearchChange"        
        @on-search-bar-loaded="onSearchBarLoaded"
        :buttonMode="searchButtonMode"
        :chips="search.chips"
        :choices="search.choices"
        :ranges="search.ranges"
        :options="search.options"
        :searchHint="search.searchHint"
        :searchFields="search.searchFields"
        
        />
        </template>
      </div>
    </template>
    
    <div class="paged-table">

      <div v-if="isLoading" class="table-loader">
        <LDBLoader />
      </div>
      
      <template v-else-if="sortedRecords?.length === 0"> No records found </template>
      
      <table-lite
        v-else
        id="lfx-list-view"
        :is-static-mode="true"
        :is-slot-mode="true"
        :has-checkbox="false"
        :is-loading="false"
        :is-re-search="false"
        :is-paged-table="true"
        :pageSize="pageSize"
        :sortable="tableSortField"
        :columns="tableColumns"
        :page="pageNumber"
        @on-page-change="onPageChange"
        @on-page-size-change="onPageSizeChange"
        @do-search="doSearch"
        :rows="sortedRecords"
        :total="recordCount"
      >
        <template v-for="(col, i) of tableColumns" v-slot:[col.field]="data" :key="i">
          <LDBField view="list" :field="col.col" :fieldname="col.field" :definition="definition" :record="data.value" />
        </template>
        
      </table-lite>
    </div>
  </div>
</template>

<script>

import { ENV_VARIABLES } from '@/store/config_env';

const APIURL = () => ENV_VARIABLES.VUE_REST_API_URL;
import axios from 'axios';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBField from '@/landobyte_vue/LDBField.vue';
import TableLite from './TableLite.vue';
import ACTIONS from '@/definitions/actions/index.ts';

import { doPermissionAction } from '@/lfx_rest/lfx_action.ts';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import { getDefinitionByTable } from '@/db-interface/db_definitions_store';
import { dbObjectEmitter } from '@/db-interface/db_state';
import { mapSorderByField } from '@/sharedUtils/LdbDefinitionUtils';

// eslint-disable-next-line vue/valid-emits-list

export default {
  emits:['on-search-change'],
  components: {
    LDBField,
    TableLite,
    LDBLoader,
  },
  props: {
    // titleBar: { type: Object, required: false },
    definition: { type: Object, required: true },
    // records: { type: Object, required: true },
    actionParams: {
      type: Object,
      default() {
        return {};
      },
    },
    action: { type: String, required: true },
    search: { type: Object },
    defaultWhere: {
      type: Object,
      default() {
        return { };
      },
    },
    tableName: { type: String, required: false },
    defaultSort: { type: Object, required: false },
    searchButtonMode:{ type: Boolean, default:false }
  },
  data() {
    return {
      searchBarLoaded:false,
      searchSelector: undefined,
      sortedRecords: [],
      recordCount: 0,
      sortField: this.defaultSort || { order: 'id', sort: 'asc' },
      pageSize: 100,
      pageNumber: 1,
      mountedComplete:false,
      isLoading: false,
      mergedSelectorString:'',
      defaultWhereString:'',
      currentCallTimestamp:undefined
    };
  },
  created() {},
  mounted() {
    dbObjectEmitter.on(this.eventName, this.updateDataOnDbObjectEmit);
    this.mountedComplete = true;
  },
  beforeUnmount() {
    dbObjectEmitter.off(this.eventName, this.updateDataOnDbObjectEmit);
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['authUser']),
    showSearchBar() {
      return this?.search?.searchFields || this?.search?.chips !== undefined || this?.search?.choices;
    },
    mergedSelector() {
      let res = {};
      if (this.defaultWhere !== undefined) {
        res = { ...this.defaultWhere };
      }
      if (this.searchSelector !== undefined) {
        res = { ...res, ...this.searchSelector };
      }
      return res;
    },
    eventName() {
      if (this.tableName) {
        return this.tableName;
      } 
      return '*';
    },
    
    tableColumns() {
      let res = [];
      let key = true;
      for (let f = 0; f < this.definition.list_view_fields.length; f++) {
        let field = this.definition.list_view_fields[f];
        var fieldDisplayForView = field.views.list;
        if (typeof fieldDisplayForView === 'function') {
          fieldDisplayForView = fieldDisplayForView({
            authUser: this.authUser,
            view: 'list',
            routeMetaData: this.$route.meta,
          });
        }
        if (fieldDisplayForView) {
          let column = {
            col: field,
            field: field.name,
            label: field.label,
            isKey: key,
            sortable: true,
          };
          key = false;
          res.push(column);
        }
      }
      return res;
    },
    collectionPath() {
      return this.actionConfig.collectionPath;
    },

    actionConfig() {
      return ACTIONS[this.action];
    },
    
    selector() {},
    tableSortField() {
      return {order:this.sortField.order, sort:this.sortField.sort, performLocalSort: false}
    },
    dbSortField() {
      return this.sortField.order && this.sortField.sort ? { [mapSorderByField(this.definition,this.sortField.order)]: this.sortField.sort } : undefined
    }
  },
  watch: {
    defaultWhere(newWhere, oldWhere) {
      this.defaultWhereString = JSON.stringify(newWhere);
    },
    mergedSelector(newSelector, oldSelector) {
      this.mergedSelectorString = JSON.stringify(newSelector);
    },
    defaultWhereString(newString,oldString) {
      // console.log('\ndefaultWhere: \nNEW:\n',newString,'\nOLD:\n',oldString);
      this.getRecords('watch.defaultWhereString');
    },
    mergedSelectorString(newString,oldString) {
      // console.log('\nmergedSelector: \nNEW:\n',oldString,'\nOLD:\n',newString);
      this.getRecords('watch.mergedSelectorString');
    }
    
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    onSearchBarLoaded(searchSelector) {
      this.searchBarLoaded = true;
      if (searchSelector !== undefined) {
        this.onSearchChange(searchSelector);
      }
    },
    
    onSearchChange(searchSelector) {
      this.searchSelector = searchSelector;

      let orderBy =  this.sortField.order && this.sortField.sort ? { [this.sortField.order]: this.sortField.sort } : undefined;
      let selector = {where:this.mergedSelector};
      
      if (orderBy === undefined) {
        selector.orderBy = orderBy
      }
      this.$emit('on-search-change',selector);
    },
    doSearch(offset, limit, order, sort) {
      this.pageSize = limit;
      this.sortField.order = order;
      this.sortField.sort = sort;
      this.pageNumber = Math.floor(offset / limit) + 1;
      this.getRecords('doSearch');
    },
    onPageChange(pageNumber) {
      this.pageNumber = pageNumber;
      // this.getRecords();
    },
    onPageSizeChange(pageSize) {
      this.pageSize = pageSize;
      // this.getRecords();
    },

    async http_rest_patch(context, path, body, options) {
      let headers = { ...context.getters.authHeaders };
      let url = `${APIURL()}${path}`;
      let config = { headers: headers };
      const http_res = await axios.patch(url, body, config);
      let data = http_res.data.rows ?? http_res.data;
      this.recordCount = http_res.data.count;
      for (let d = 0; d < data.length; d++) {
        let r = data[d];
      }
      return data;
    },

    async getRecords(source) {
      if (this.searchBarLoaded) {
        const triggerTime = new Date();
        this.currentCallTimestamp = triggerTime;
        this.isLoading = true;
        let pathParams = {};
        let params = {};
        let limit = this.pageSize;
        let offset = (this.pageNumber - 1) * limit;
        let orderBy = this.dbSortField;
        let actionResponse = await doPermissionAction({}, this.action, this.actionParams, {
          additionalSelector: { limit, offset, orderBy, where: this.mergedSelector },
        });
        if (this.currentCallTimestamp === triggerTime) {
          this.recordCount = actionResponse.response.count;
          let response = actionResponse.response.rows;
          let responseLength = response?.length || 0;
          let collection_path = Mustache.render(
            this.actionConfig.collectionPath.replace('{', '{{').replace('}', '}}'),
            this.actionParams
          );
          let definitionName = '';
          this.sortedRecords = [];
          if (responseLength > 0) {
            let definition = getDefinitionByTable(response[0].Table);
            definitionName = definition.name;
          }
          for (let r = 0; r < responseLength; r++) {
            let data = response[r];
            data.collection_path = collection_path;
            let rec = this.definition.class(data, this.$store);
            if (this.sortedRecords.length < responseLength) {
              this.sortedRecords.push(rec);
            } else {
              this.sortedRecords[r] = rec;
            }
          }
          this.sortedRecords.splice(responseLength, this.sortedRecords.length - responseLength);
          this.isLoading = false;
        }
      }
    },
    updateDataOnDbObjectEmit(object) {
      if (object?.Table && this.sortedRecords?.[0]?.Table && object?.Table === this.sortedRecords?.[0]?.Table) {
        for (const record of this.sortedRecords) {
          if (record.Table === object.Table && record.id === object?.id) {
            for (const key in object) {
              record[key] = object[key];
            }
          }
        }
      }
    },
  },
};
</script>

<style>
div.table-loader {
  position: absolute;
  top: 0px;
  z-index: 10000;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  margin-top: 100px;
  justify-content: center;
}

div.paged-table-wrapper {
  padding-top: 8px;
  min-height: calc(100% - 10px);
  max-height: calc(100% - 10px);
  max-width: calc(100% - 0px);
  min-width: calc(100% - 0px);
  display: flex;
  flex-direction: column;
  /* border: 5px dotted red; */
}

div.paged-table-search-bar {
  flex: 1;
  max-height: 62px;
}

div.paged-table {
  flex: 1;
  position: relative;
}


</style>
