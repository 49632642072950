<template>
  <p v-if="hasNoItems">No items to display.</p>
  <div v-else class="ldb-items-view-card">
    <div class="ldb-items-view-card-records" v-if="hasMultipleItems">
      <template v-for="rec in records" :key="rec.id">
        <LDBDefinitionTile :definition="definition" :record="rec" :view="tileView" @click="onTileClick(rec)" />
      </template>
    </div>
    <div class="ldb-items-view-detail" :class="`detail-${view} ${singleOrMultipleClass}`">
      <div v-if="!secondComponentName">
        <component
          :is="componentName"
          :record="record"
          :view="view"
          :showBackButton="showBackButton"
          @on-back="onBack"
          :action="action"
          :params="params"
          @on-save="onSave"
          :showNextButton="showNextButton"
          @on-next="onNext"
        />
      </div>
      <div v-else class="combined-content">
        <component :is="componentName" :record="record" :view="view" />
        <component :is="secondComponentName" :record="secondRecord" :view="secondView" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import LDBDefinitionTile from '@/landobyte_vue/LDBDefinitionTile.vue';

export default {
  name: 'LDBItemsView',
  components: {
    LDBItemView,
    LDBDefinitionTile,
  },
  props: {
    tileView: { type: String, default: 'default' },
    records: { type: [Object, Array], required: true },
    recordsDefinition: { type: Object, required: false },

    componentName: { type: String, default: 'LDBItemView' },
    record: { type: Object, required: true },
    view: { type: String, default: 'item' },
    action: { type: String, default: 'click' },
    params: {
      type: Object,
      default() {
        return {};
      },
    },

    secondComponentName: { type: String, default: undefined, required: false },
    secondRecord: { type: Object, required: false },
    secondView: { type: String, default: 'item' },

    fieldView: { type: String, default: undefined },
    saveButtonCaption: { type: String, default: 'Save' },
    showButtons: { type: Boolean, default: false },
    showBackButton: { type: Boolean, default: false },
    showNextButton: { type: Boolean, default: false },
    checkMandatoryFields: { type: Boolean, default: true },
  },
  emits: ['on-back', 'on-cancel', 'on-save', 'on-next', 'on-api-success', 'on-api-error', 'on-tile-click'],
  data() {
    return {
      // formSubmitted: false,
      // changedFields: {},
      // mounted: false,
    };
  },
  created() {},
  mounted() {
    // this.record.revert();
    // for (let f = 0; f < this.viewFields.length; f++) {
    //   let field = this.viewFields[f];
    //   if (field.default !== undefined) {
    //     this.changedFields[field.name] = this.record[field.name] || '';
    //   }
    // }
    // this.mounted = true;
  },
  onBeforeUnmount() {
    // this.record.revert();
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['authUser']),
    singleOrMultipleClass() {
      return this.hasMultipleItems ? 'multiple' : 'single';
    },
    hasMultipleItems() {
      return this.records !== undefined &&
        ((Array.isArray(this.records) && this.records.length > 1) ||
          (typeof this.records === 'object' && Object.keys(this.records).length > 1))
        ? true
        : false;
    },
    hasNoItems() {
      return !this.records ||
        (Array.isArray(this.records) && this.records.length === 0) ||
        (typeof this.records === 'object' && Object.keys(this.records).length === 0)
        ? true
        : false;
    },
    // useFieldView() {
    //   return this.fieldView || this.view || 'item';
    // },
    // fieldnames() {
    //   return this.viewFields.map((field) => field.name);
    // },

    // requiredFieldsRemaining() {
    //   return this.record.required_fields_remaining.filter((f) => this.fieldnames.indexOf(f) >= 0).length > 0;
    // },

    // mandatoryFieldsRemainingList() {
    //   return this.record.remaining_mandatory_fields;
    // },

    // mandatoryFieldsRemaining() {
    //   return this.record.remaining_mandatory_fields.length > 0;
    // },

    definition() {
      let key = +Object.keys(this.records)[0];
      return this.records[key].definition;
    },
    // title() {
    //   return `View ${this.definition.title}`;
    // },
    // fields() {
    //   return this.record.guarded_fields;
    // },
    // viewFields() {
    //   let res = [];
    //   for (let k in this.fields) {
    //     let field = this.fields[k];
    //     var fieldDisplayForView = field.views[this.useFieldView] || field.views.customViews?.[this.useFieldView];
    //     if (typeof fieldDisplayForView === 'function') {
    //       fieldDisplayForView = fieldDisplayForView(this.authUser, this.useFieldView, this.record);
    //     }
    //     if (fieldDisplayForView) {
    //       if (
    //         this.view === 'edit' ||
    //         field.showIfNull ||
    //         (this.record[field.name] !== null && this.record[field.name] !== undefined)
    //       ) {
    //         field.fieldname = k;
    //         res.push(field);
    //       }
    //     }
    //   }
    //   return res;
    // },

    // fieldsByGroup() {
    //   let res = {};
    //   for (let g in this.definition.groups) {
    //     res[g] = 0;
    //   }
    //   for (let f = 0; f < this.viewFields.length; f++) {
    //     let field = this.viewFields[f];
    //     if (field.group !== undefined) {
    //       res[field.group] = res[field.group] + 1;
    //     }
    //   }
    //   return res;
    // },

    // viewFieldsAndGroups() {
    //   let res = [];
    //   let groups = Object.entries(this.definition.groups)
    //     .map(([k, v]) => {
    //       v.name = k;
    //       return v;
    //     })
    //     .sort((a, b) => a.prority - b.priority);
    //   if (groups.length === 1) {
    //     return this.viewFields;
    //   } else {
    //     for (let g = 0; g < groups.length; g++) {
    //       let group = groups[g];
    //       group.type = 'group';
    //       if (this.fieldsByGroup[group.name] > 0) {
    //         res.push(group);
    //       }
    //       for (let f = 0; f < this.viewFields.length; f++) {
    //         let field = this.viewFields[f];
    //         if (field.group === group.name) {
    //           res.push(field);
    //         } else if (field.group === undefined && group.default === true) {
    //           res.push(field);
    //         }
    //       }
    //     }
    //     return res;
    //   }
    // },

    // // ----- Form Buttons -----

    // // Left buttons

    // leftButtons() {
    //   let self = this;
    //   let allButtons = [
    //     {
    //       name: 'back',
    //       label: 'Back',
    //       type: 'clear',
    //       action: 'click',
    //       get disabled() {
    //         return this.type === 'disabled';
    //       },
    //       guards: this.showBackButton,
    //       clickEvent() {
    //         self.onBackClick();
    //       },
    //     },
    //   ];
    //   return allButtons.filter((button) => button.guards);
    // },

    // // Right buttons

    // rightButtons() {
    //   let self = this;
    //   let allButtons = [
    //     {
    //       name: 'cancel',
    //       action: 'click',
    //       label: 'Cancel',
    //       type: '',
    //       guards: true,
    //       clickEvent() {
    //         self.onCancelClick();
    //       },
    //     },
    //     {
    //       name: 'save',
    //       label: this.saveButtonCaption,
    //       action: this.action,
    //       type: this.saveButtonType,
    //       params: this.params,
    //       get disabled() {
    //         return this.type === 'disabled';
    //       },
    //       guards: true,
    //       clickEvent() {
    //         self.onSaveClick();
    //       },
    //       onApiSuccess(evt) {
    //         self.$emit('on-api-success', evt);
    //       },
    //       onApiError(evt) {
    //         self.$emit('on-api-error', evt);
    //       },
    //     },
    //     {
    //       name: 'next',
    //       label: 'Next',
    //       action: this.action,
    //       type: this.nextButtonType,
    //       params: this.params,
    //       get disabled() {
    //         return this.type === 'disabled';
    //       },
    //       guards: this.showNextButton,
    //       clickEvent() {
    //         self.onNextClick();
    //       },
    //     },
    //   ];
    //   return allButtons.filter((button) => button.guards);
    // },

    // saveButtonType() {
    //   // TODO check mandatory fields and validations
    //   return this.record.has_validation_errors || (this.checkMandatoryFields && this.mandatoryFieldsRemaining > 0)
    //     ? 'disabled'
    //     : this.showNextButton
    //     ? ''
    //     : 'primary';
    // },
    // nextButtonType() {
    //   // TODO check mandatory fields and validations
    //   return this.record.has_validation_errors || this.requiredFieldsRemaining ? 'disabled' : 'primary';
    // },
  },
  watch: {
    // view(new_view, old_view) {
    //   this.record.revert();
    // },
    // $route(to, from) {
    // this.useView = this.view;
    // this.changeView(this.view);
    // },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    onTileClick(record) {
      this.$emit('on-tile-click', record);
    },

    onBack() {
      this.$emit('on-back');
    },
    onSave() {
      this.$emit('on-save');
    },
    onNext() {
      this.$emit('on-next');
    },

    // isActiveRecord(rec) {
    //   return rec.id === this.record.id;
    // },
    // titleField(record) {
    //   return record[this.titleField];
    // },
    // activeRecordClass(recordId) {
    //   return this.record.id === recordId ? 'active' : 'inactive';
    // },
    // onItemClick(record) {
    //   this.$emit('item-click', record);
    // },
    // on_change({ field, value }) {
    //   this.changedFields[field] = value;
    //   this.record[field] = value;
    // },

    // ----- Button -----

    // onBackClick() {
    //   this.record.revert();
    //   this.$emit('on-back');
    // },
    // onCancelClick() {
    //   this.$router.go(-1);
    // },
    // onSaveClick() {
    //   this.formSubmitted = true;
    //   this.$emit('on-save', {
    //     fields: this.changedFields,
    //   });
    // },
    // onNextClick() {
    //   this.formSubmitted = true;
    //   this.$emit('on-next', {
    //     fields: this.changedFields,
    //   });
    // },
  },
};
</script>

<style>
.ldb-items-view-card {
  position: relative;
  display: flex;
  max-height: calc(100% - 0px);
  min-height: calc(100% - 0px);
}

.ldb-items-view-card-records {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  min-width: 250px;
  max-width: 250px;
  overflow-y: auto;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
}

.ldb-items-view-detail {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 260px;
  overflow-y: auto;
}

.ldb-items-view-detail.single {
  left: 0px;
}
</style>
