<template>
  <LDBModal result="info" header="Import Public Holidays" size="xx-large">
    <template #body>
    <div>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          :view="field.view"
          :fieldname="field.name"
          :definition="undefined"
          :record="summaryRecord"
        />
      </div>
    <capitec-file-upload label="Select CSV File" @selection-change="onSelectionChange" :disabled="false" type="" fileTypes="text/csv" hint="Jannie">
    
    </capitec-file-upload>

    <template v-if="fileStatus === 'processing'">
        <LDBLoader />
      </template>
    <template v-else-if="fileStatus === 'missingHeaders'">
      <h1>Some headers are missing:</h1>
      <ul>
        <li v-for="item in missingHeaders" :key="item">{{ item }}</li>
      </ul>
    </template>
    <template v-else-if="fileStatus === 'processed' || fileStatus === 'batchStarted' || fileStatus === 'batchCompleted'">
        <div class="lfx-public-holiday-import-view-table-container">
          <table-lite
            id="lfx-public-holiday-import-view-table"
            :is-static-mode="true"
            :is-slot-mode="true"
            :has-checkbox="false"
            :is-loading="false"
            :is-re-search="false"
            :columns="tableColumns"
            :rows="sortedRecords"
            :pageSize="sortedRecords.length"
            :total="sortedRecords.length"
          >
            <template v-for="(col, i) of tableColumns" v-slot:[col.field]="data" :key="i">
              <template v-if="col.showAsCurrency">{{ formatCurrency(data.value[col.field]) }}</template>
              <template v-else-if="col.col === 'status'">{{ statusLabel(data.value[col.field]) }}</template>
              <!-- <template v-if="col.field?.amount">ZAR {{data.value[col.field].amount}}</template> -->
              <template v-else>{{ data.value[col.field] }}</template>
            </template>
          </table-lite>
        </div>
      </template>

    </template>
    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @on-api-success="button.onApiSuccess"
        @click="button.clickEvent"
      ></LDBButton>
    </template>    
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import TableLite from '@/views/general/TableLite.vue';
import LDBField from '@/landobyte_vue/LDBField.vue';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'configPublicHolidayImport',
  components: { LDBLoader, TableLite, LDBField },
  data() {
    return {
      fileStatus:'none',
      missingHeaders: [],
      dataLines: [],
      file:null
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters(['permissions']),
    ...mapState([]),
    // ----- Public Holiday Record -----
    table() {
      return 'LfxPublicHoliday';
    },
    collectionPath() {
      return `/config/1/publicHoliday`;
    },
    definition() {
      return getDefinition('publicHoliday');
    },

        // ----- Fields -----

    viewFields() {
      const showPreProcessLines = this.fileStatus === "processed" || this.fileStatus === 'batchStarted' || this.fileStatus === 'batchCompleted';
      const showProcessingLines = showPreProcessLines && (this.fileStatus === "batchStarted" || this.fileStatus === 'batchCompleted');
      const allFields = {
        status: {
          name: 'status',
          label: 'Status',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'none', name: 'No File Uploaded' },
                { id: 'processing', name: 'Processing File' },
                { id: 'missingHeaders', name: 'Some Headers Missing' },
                { id: 'processed', name: 'File Processed' },
                { id: 'batchStarted', name: 'Batch Started' },
                { id: 'batchCompleted', name: 'Batch Completed' },
              ],
            },
          },
          view: 'item',
          guards: true,
        },
        totalLines: {
          name: 'totalLines',
          label: 'Total Lines',
          datatype: 'integer',
          view: 'item',
          guards: showPreProcessLines,
        },
        okayLines: {
          name: 'okayLines',
          label: 'Ready to Create Lines',
          datatype: 'integer',
          view: 'item',
          guards: showPreProcessLines,
        },
        errorLines: {
          name: 'errorLines',
          label: 'Error Lines',
          datatype: 'integer',
          view: 'item',
          guards: showPreProcessLines,
        },
        existingLines: {
          name: 'existingLines',
          label: 'Existing Lines',
          datatype: 'integer',
          view: 'item',
          guards: showPreProcessLines,
        },
        submittedLines: {
          name: 'submittedLines',
          label: 'Sumitted Lines',
          datatype: 'integer',
          view: 'item',
          guards: showProcessingLines,
        },
        createdLines: {
          name: 'createdLines',
          label: 'Created Lines',
          datatype: 'integer',
          view: 'item',
          guards: showProcessingLines,
        },
        createErrorLines: {
          name: 'createErrorLines',
          label: 'Create Error Lines',
          datatype: 'integer',
          view: 'item',
          guards: showProcessingLines,
        },
      };
      const returnFields = {};
      for (const f in allFields) {
        if (allFields[f].guards !== false) {
          returnFields[f] = allFields[f];
        }
      }
      return returnFields;
    },
    toMaxDate() {
      return this.summaryRecord.dateType === 'capture' ? { type: 'day', offset: 0 } : { type: 'year', offset: 1 };
    },

    summaryRecord() {
      const totalLines = this.dataLines.length;
      let okayLines = 0;
      let errorLines = 0;
      let existingLines = 0;
      let submittedLines = 0;
      let createdLines = 0;
      let createErrorLines = 0;
      for (const line of this.dataLines) {
        switch (line.status) {
          case "okay": okayLines++; break;
          case "dataError": errorLines++; break;
          case "alreadyExists": existingLines++; break;
          case "submitted": submittedLines++; break;
          case "created": createdLines++; break;
          case "error": createErrorLines++; break;
        }
      }
      return {
        status: this.fileStatus,
        totalLines: totalLines,
        okayLines: okayLines,
        errorLines: errorLines,
        existingLines: existingLines,
        submittedLines: submittedLines,
        createdLines: createdLines,
        createErrorLines: createErrorLines,
      }
    },
    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'processBatch',
          label: 'Batch Create',
          type: '',
          action: 'click',
          params: {},
          guards: this.permissions?.createPublicHoliday && this.fileStatus !== 'batchStarted' && this.fileStatus !== 'batchCompleted',
          enabledGuards: this.fileStatus === "processed" && this.summaryRecord.okayLines > 0,
          clickEvent() {
            self.triggerBatchCreate()
          },
        },
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          action: 'click',
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

      ];
      return allButtons;      
    },
    tableColumns() {
      let self = this;
      let res = [
        {
          col: 'index',
          field: 'index',
          label: '#',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'date',
          field: 'date',
          label: 'Date',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'currency',
          field: 'currency',
          label: 'Currency',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'name',
          field: 'name',
          label: 'Name',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'status',
          field: 'status',
          label: 'Status',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'error',
          field: 'error',
          label: 'Error',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
      ];
      return res.filter((f) => f.guards === true);
    },
    sortedRecords() {
      return this.dataLines
    },
    publicHolidays() {
      return this.$store.state.config[1].publicHoliday || {};
    },
    currencyIds() {
      const res = []
      const currencies = this.$store.state.config[1].currency || {};
      for (const currency in currencies) {
        res.push(currency)
      };
      return res
    }
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    onSelectionChange(evt) {
      self = this;
      this.fileStatus = "processing";
      this.missingHeaders = [];
      this.dataLines = [];

      this.file = evt.detail.files[0];
      let reader = new FileReader();
      reader.readAsText(this.file);
      reader.onload = function() {
        self.processUploadedFile(reader.result);
      };

    },
    processUploadedFile(fileContents) {
      fileContents = fileContents.replaceAll("\r","\n");
      fileContents = fileContents.replaceAll("\n\n","\n");
      const lines = fileContents.split("\n");
      const headers = lines[0].split(",");
      this.checkHeaders(headers);
      if (this.fileStatus === "processing") {
        for (const lineId in lines) {
          if (lineId > 0) {
            this.processLine(lines[lineId],headers,lineId)
          }
        }
        this.fileStatus = "processed"
      }
    },
    checkHeaders(headers) {
      const requiredHeaders = ['date','name','currency'];
      for (const requiredHeader of requiredHeaders) {
        if (!headers.includes(requiredHeader)) {
          this.fileStatus = 'missingHeaders';
          this.missingHeaders.push(requiredHeader);
        }
      }
    },
    processLine(line,headers,index) {
      const lineArray = line.split(",");
      const object = {
        index: index,
        date: lineArray[headers.indexOf('date')],
        name: lineArray[headers.indexOf('name')],
        currency: lineArray[headers.indexOf('currency')]
      };
      object.name = object.name?.replace(/[^A-Za-z0-9 ]/g, "").trim();
      const date = new Date(object.date);
      object.status = 'okay';
      if (object.name.length < 5 || object.name.length > 50) {
        object.status = 'dataError'
        object.error = "Name must be between 5 and 50 characters"
      } else if (!date instanceof Date || isNaN(date)) {
        object.status = 'dataError';
        object.error = "This is not a valid date"
      } else if (!this.currencyIds.includes(object.currency)) {
        object.status = "dataError";
        object.error = "Invalid currency provided"
      } else {
        for (const existingHolidayId in this.publicHolidays)  {
          if (
            this.publicHolidays[existingHolidayId].currencyId === object.currency &&
            new Date(this.publicHolidays[existingHolidayId].date).toISOString() === date.toISOString()
            ) {
              object.status = 'alreadyExists'
              object.error = 'Existing holiday: ' + this.publicHolidays[existingHolidayId].name
          }
        }
      }
      this.dataLines.push(object)
    },

    async triggerBatchCreate() {
      this.fileStatus = 'batchStarted';
      for (const line of this.dataLines) {
        if (line.status === 'okay') {
          await this.submitLine(line)
        }
      }
      this.fileStatus = 'batchCompleted'
    },
    async submitLine(line) {
      line.status = 'submitted';
      await new Promise((r) => setTimeout(r,100))
      try {
        const result = await doPermissionAction(this,'createPublicHoliday',{configId:1},{
          currencyId: line.currency,
          date: line.date,
          name: line.name,
          status: 'active',
        })
        if (result.status === 'success') {
          line.status = 'created'
        } else {
          line.status = 'error';
          line.error = result.message || "Unknown Error"
        }
      } catch {
        line.status = 'error';
        line.error = result.message || "Unknown Error"
      }
    },
    statusLabel(status) {
      switch (status) {
        case "okay": return "Ready to Create";
        case "dataError": return "Data Error";
        case "alreadyExists": return "Holiday Already Exists";
        case "submitted": return "Submitted";
        case "created": return "Created";
        case "error": return "Create Error"
        default: return status
      }
    }

  },
};
</script>
<style>
.lfx-public-holiday-import-view-table-container {
  position: relative;
  min-height: 320px;
}

#lfx-public-holiday-import-view-table {
  min-width: 100%;
}

div.lfx-deal-summary-report {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
</style>

