<template>
  <LFXListView :definition="definition" :records="records" />
  <router-view></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXListView from '@/views/general/LFXListView.vue';

export default {
  name: 'LfxSignatoriesList',
  components: { LFXListView },
  props: {},
  data() {
    return {};
  },
  created() {
    if (this.$route.meta?.content) {
      this.$route.meta.content.fecEnabled = this.parentRecord?.mayBookFec
    }
    this.parentRecord?.loadDataForSignatories();
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    // ----- Parent record -----
    signatoryTypes() {
      return this.$route.meta?.content?.signatoryTypes || 'all';
    },

    parentRecordId() {
      return +this.$route.params?.accountId;
    },
    parentRecord() {
      return this.account?.[this.parentRecordId];
    },

    // ----- List records -----

    definition() {
      return getDefinition('signatory');
    },

    records() {
      if (this.signatoryTypes === 'all') {
        return this.parentRecord?.signatoriesAndContacts;
      }
      const res = {};
      for (const sigId in this.parentRecord?.signatoriesAndContacts) {
        const sigSignerLevel = this.parentRecord?.signatoriesAndContacts?.[sigId]?.signerLevel;
        if (
          (this.signatoryTypes === 'signers' && sigSignerLevel !== 'contactOnly') ||
          (this.signatoryTypes === 'contacts' && sigSignerLevel === 'contactOnly')
        ) {
          res[sigId] = this.parentRecord?.signatoriesAndContacts?.[sigId];
        }
      }
      return res;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
