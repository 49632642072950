<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <div v-if="mounted">
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxAddUser',
  components: { LDBField },
  props: {},
  data() {
    return {
      mounted: false,
      newRecord: {},
    };
  },
  created() {
    this.newRecord = new this.definition.class(
      { Table: 'LdbPermission', collection_path: `/config/${this.config.id}/permission` },
      this.$store
    );
    this.newRecord.accountLevels = ['bank', 'intermediary', 'intermediaryBranch', 'client'];
  },
  mounted() {
    this.populateFieldDefaults();
  },
  computed: {
    ...mapGetters(['config']),
    ...mapState([]),
    header() {
      return 'Add a Permission';
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelAddPermission',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'addPermission',
          label: 'Add User',
          type: 'primary',
          guards: true, //TODO
          clickEvent() {
            self.onAddUserClick();
          },
        },
      ];
      return allButtons?.filter((button) => button.guards);
    },

    // ----- Definition -----

    definition() {
      return getDefinition('permission');
    },

    // ----- Fields -----

    fields() {
      return this.definition.fields;
    },
    view() {
      return 'create';
    },
    viewFields() {
      let res = [];
      for (let k in this.fields) {
        let field = this.fields[k];
        if (field.views[this.view] === true) {
          field.fieldname = k;
          res.push(field);
        }
      }
      return res;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Fields -----

    populateFieldDefaults() {
      for (let f = 0; f < this.viewFields.length; f++) {
        let field = this.viewFields[f];
        if (field.default) {
          this.newRecord[field.name] = field.default;
        }
      }
      this.mounted = true;
    },
    on_change({ field, value }) {
      this.newRecord[field] = value;
      const newRec = this.newRecord;
    },

    // ----- API calls -----

    async onAddUserClick() {
      let userId = await this.addUser();
      if (userId) {
        this.$router.replace({ name: 'configureUserPermissionsEdit', params: { userId: userId } });
      }
    },
    async addUser() {
      const accountId = this.newRecord.accountId;
      const response = await this.account[accountId].createUser(this.newRecord);
      const userId = response.id;
      return userId;
    },
  },
};
</script>
