const definitions = new Map();

import {LdbDefinition} from '@/definitions/LdbInterfaces';

if (process.env.NODE_ENV === 'development') {
  //@ts-ignore
  // window.definitions = definitions;
}

export function addDefinition(name:string, definition:LdbDefinition) {
  definitions.set(name, definition);
  return definitions;
}

export function getDefinition(name:string) {
  return definitions.get(name);
}

export default definitions;

