//@ts-nocheck
//---------------------------------------------------------------------

import { mapState } from 'vuex';

//---------------------------------------------------------------------

export const definition = {
  props:{
  },
  computed:{
    
  },
  mounted() {
  },
  methods:{
    ...mapState(['state']),
    go_to_link(path) {
      let path_tokens = path.split('/').filter(function(p) { return p.length > 0});
      let view = path_tokens.shift();
      let obj = state;
      for (let v = 0 ; v < path_tokens.length ; v = v + 2) {
        let collection = path_tokens[v];
        let doc = path_tokens[v + 1];
        if (doc !== undefined) {
          obj = obj[collection][doc];
          obj.activate()
        }
      }
      this.$router.push(path);
    }
  }
}

//---------------------------------------------------------------------

