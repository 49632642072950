<template>
  <LDBModal result="warning" :header="header">
    <template #body>
      <div>
        <ol>
          <li>Request a cancellation of the current portfolio.</li>
          <li>Once the cancellation has been confirmed the payment will become ediable once more.</li>
        </ol>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="definition"
          :record="record"
          @change="on_change"
        />
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
        @on-api-success="onApiSuccess"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxPaymentRecall',
  components: { LDBField },
  emits: ['on-api-success'],
  props: {},
  data() {
    return {
      mounted: true,
    };
  },
  created() {
    if (this.record?.rejectionReason) {
      this.record.rejectionReason = '';
    }
  },
  mounted() {},
  beforeUnmount() {
    this.record.revert();
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),
    header() {
      return `Request Portfolio Recall`;
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

        {
          name: 'cancelPaymentPortfolio',
          label: 'Request Recall',
          type: 'primary',
          action: 'cancelPaymentPortfolio',
          params: { transactionId: this.record.transactionFileId, paymentId: this.record.id },
          actionParams: this.record,
          guards: true,
          clickEvent(response) {
            if (response.status === 'success') {
              self.$router.go(-1);
            }
          },
        },
      ];
      return allButtons !== undefined ? allButtons : [];
    },

    // ----- Definition -----

    definition() {
      return getDefinition('payment');
    },

    // ----- Record -----

    clientId() {
      return this.$route.params.accountId;
    },

    recordId() {
      return +this.$route.params.paymentId;
    },
    record() {
      return this.$store.state?.payment[this.recordId];
    },

    // ----- Fields -----

    fields() {
      return this.definition.fields;
    },
    viewFields() {
      const displayFields = ['rejectionReason'];
      let res = [];
      for (let k in this.fields) {
        if (displayFields.includes(this.fields[k].name)) {
          res.push(this.fields[k]);
        }
      }
      return res;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Fields -----
    on_change({ field, value }) {
      this.record[field] = value;
    },

    // ----- API calls -----
    async onApiSuccess(_responseObject) {},
  },
};
</script>

<style></style>
