import { Component, css, html } from '../../elements';

/**
 * Component that orchestrates the show and hide of {@link Toast} components.
 * 
 * ```js
 * import 'platform/components/popups/ToastController';
 * ```
 * 
 * ```html
 * <capitec-toast-controller></capitec-toast-controller>
 * ```
 * @param {"info"|"success"|"warning"|"error"|String} type Toast type.
 * @param {"top"|"bottom"|String} location Toast location.
 */
export class ToastController extends Component {

	// --------------
	// INITIALISATION
	// --------------

	/**
	 * Initialises the component.
	 *
	 * @hideconstructor
	 */
	constructor() {

		super();

		window.toastController = this;
		window.showToast = this.showToastMessage;
		window.showToastTimed = this.showToastMessageTimed;
		window.hideToast = this.hideToastMessage;
	}

	// ----------
	// PROPERTIES
	// ----------

	// n/a

	// -------------------
	// LIFECYCLE OVERRIDES
	// -------------------

	// n/a	

	// --------------
	// EVENT HANDLERS
	// --------------

	// n/a

	// --------------
	// PUBLIC METHODS
	// --------------

	/**
	 * Schedules a toast message to show.
	 * 
	 * @param {String} type Toast type:
	 * - `info` Best suited for informational purposes.
	 * - `success` Best suited for successful outcomes.
	 * - `warning` Best suited for warning outcomes.
	 * - `error` Best suited for error outcomes.
	 * @param {String} location Toast location:
	 * - `top` Top of screen.
	 * - `bottom` Bottom of screen.
	 * @param {String} title Title to display on toast
	 * @param {String} message Message to display in toast
	 * @param {Boolean} closable Whether the toast can be closed by clicking close button
	 * @param {Function} closeCallback To be called when toast is closed
	 * @returns {void} Shows toast message
	 */
	showToastMessage(type, location, title, message, closable, closeCallback) {
		window.toastController.toast = {};
		window.toastController.toast.type = type;
		window.toastController.toast.location = location;
		window.toastController.toast.title = title;
		window.toastController.toast.message = message;
		window.toastController.toast.closable = closable;
		window.toastController.toast.closeCallback = closeCallback;
		window.toastController.toast.visible = true;
		window.toastController.requestUpdate();
	}

	/**
	 * Schedules a toast message to show with timeout support.
	 * 
	 * @param {String} type Toast type:
	 * - `info` Best suited for informational purposes.
	 * - `success` Best suited for successful outcomes.
	 * - `warning` Best suited for warning outcomes.
	 * - `error` Best suited for error outcomes.
	 * @param {String} location Toast location:
	 * - `top` Top of screen.
	 * - `bottom` Bottom of screen.
	 * @param {String} title Title to display on toast
	 * @param {String} message Message to display in toast
	 * @param {Number} duration Duration to display in milliseconds
	 * @param {Boolean} closable Whether the toast can be closed by clicking close button
	 * @param {Function} closeCallback To be called when toast is closed
	 * @returns {void} Shows toast message
	 */
	showToastMessageTimed(type, location, title, message, duration, closable, closeCallback) {
		window.toastController.showToastMessage(type, location, title, message, closable, closeCallback);

		const tempToast = window.toastController.toast;
		setTimeout(() => {
			if (tempToast === window.toastController.toast) {
				window.toastController.hideToastMessage();
			}
		}, duration);
		window.toastController.requestUpdate();
	}

	/**
	 * Schedules a toast message to hide.
	 * 
	 * @returns {void}
	 */
	hideToastMessage() {
		if (window.toastController.toast) {
			window.toastController.toast.visible = false;
			if (window.toastController.toast.closeCallback && window.toastController.toast.closeCallback instanceof Function) {
				window.toastController.toast.closeCallback();
			}
			delete window.toastController.toast;
			window.toastController.requestUpdate();
		}
	}

	// ---------------
	// PRIVATE METHODS
	// ---------------

	// n/a

	// ---------
	// RENDERING
	// ---------

	/**
	 * Generates the controller stylesheet.
	 * 
	 * @ignore
	 * @returns {css} The css content of the controller.
	 */
	static get styles() {

		return css`			
			.controller-toast {
				width: 400px;
				position: absolute;
				justify-content: center;
				align-items: center;
				left: calc(50% - 200px);
				z-index: var(--theme-toast-container-z-index, 9999);
			}

			.controller-toast.top {
				top: 70px;
			}

			.controller-toast.bottom {
				bottom: 70px;
			}

			@media screen and (max-width: 380px) { /* Cannot use var in media query */
				.controller-toast {
					width: var(--theme-toast-extra-small-width,380px);
				}					
			}


		`;
	}

	/**
	 * Generates the component template for mobile mode.
	 * 
	 * @returns {html} The html content of the component.
	 */
	_mobileTemplate() {

		return this._webTemplate();
	}

	/**
	 * Generates the component template for web mode.
	 * 
	 * @returns {html} The html content of the component.
	 */
	_webTemplate() {

		return html`
			${this.toast && this.toast.visible
				? this.toast.closable
					? html`<capitec-toast class="controller-toast ${this.toast.location}" type="${this.toast.type}"
				header="${this.toast.title}" detail="${this.toast.message}" closable @click="${(e) => this.hideToastMessage()}">
			</capitec-toast>`
					: html`<capitec-toast class="controller-toast ${this.toast.location}" type="${this.toast.type}"
				header="${this.toast.title}" detail="${this.toast.message}" @click="${(e) => this.hideToastMessage()}">
			</capitec-toast>` : ``}
		`;
	}

	/**
	 * Generates the component template for kiosk mode.
	 * 
	 * @returns {html} The html content of the component.
	 */
	_kioskTemplate() {

		return this._webTemplate();
	}
}

window.customElements.define(`capitec-toast-controller`, ToastController);
