<template>
    <div  class="lfx-enable-widget-container"  menu="widget" @mouseenter="onSettingEnter" @mouseleave="onSettingLeave">
        <div class="widget-grabber" menu="widget">
        <div class="grabber-dot"/>
        <div class="grabber-dot"/>
        <div class="grabber-dot"/>
        </div>

       <capitec-check class="widget-check" menu="widget" :label="widget.title" :checked="widgetIsEnabled" @value-changed="enableWidget"/>
       <!-- <div class="size"></div> -->
       <div class="widget-sizes">
         <div v-for="n in 4" class="widget-size" menu="widget" :class="widgetSizeClass(n)" :key="n" @click="setWidgetSize(n)" @mouseenter="widgetSizeEnter(n)" @mouseleave="widgetSizeLeave(n)" />
       </div>
    </div>
</template>


<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";



export default {
  components: {},
  props: {  
    widget:{type:Object,required:true},
    widgets:{type:Array,required:true}
  },
  data() {
    return {
      tmpSize:0,
      mouseHover:false,
    }
  },
  created() {

  },
  mounted() {
  },
  computed:{
    ...mapGetters([]),
  	...mapState(['authUser']),
  	
    widgetIsEnabled() {
      return (this.widgets.indexOf(this.widget.component) >= 0);
    }
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([]),
    onSettingEnter() {
      this.$emit('on-setting',{setting:true,widget:this.widget.component});      
    },
    onSettingLeave() {
      this.$emit('on-setting',{setting:false,widget:this.widget.component});
    },
    widgetSizeEnter(size) {
      this.tmpSize = size;
      this.mouseHover = true;
    },
    widgetSizeLeave(size) {
      this.tmpSize = this.widget.size;      
      this.mouseHover = false;
    },
    setWidgetSize(size) {
      this.$emit('on-widget-size',{size:size,widget:this.widget.component});
      
    },
    widgetSizeClass(size) {
      if (this.mouseHover) {
        return (this.tmpSize >= size) ? 'has-size' : 'is-smaller';
      }
      else 
      {
        return (this.widget.size >= size)  ? 'has-size' : 'is-smaller'
      }
    },
    enableWidget(evt) {
      let enabled = evt.detail.new;
      this.$emit('on-enable-click',{enabled:enabled,widget:this.widget.component});
    }
  }
 }
 
</script>

<style>

.lfx-enable-widget-container{
  padding-left:5px;
  padding-right: 5px;
  flex:1;
  position:relative;
  max-height: 25px;
  display:flex;
  align-items:center;
  background-color:white;
  padding-top:3px;
  padding-bottom:3px;
  display:flex;
  /* border-top:1px solid #EEE; */
}

.widget-check{
  flex:1;
}

div.widget-sizes{
  display:flex;
  flex:1;
  min-height: 25px;
  max-height: 25px;
  min-width: 80px;
  max-width: 80px;
}

div.widget-size{
  flex:1;
  display:flex;
  padding-left:1px;
  padding-right: 1px;
  cursor:pointer;
  border-radius: 1px;
}

div.widget-size::after{
  content:'';
  border: 1px solid var(--theme-status-info-background);
  border-radius: 3px;
  flex:1;  
  pointer-events: none;
}

div.widget-size.has-size::after{
  background-color:var(--theme-status-info-background);
}

div.widget-grabber{
  display:flex;  
  flex:1;
  min-height: 14px;
  max-width: 15px;
  min-width: 15px;
  border-radius: 4px;
  margin-right: 8px;
  margin-left:3px;
  background-color:white;
  cursor:grab;
  cursor:-moz-grab;
  flex-direction:row;
  justify-content:space-around;
  flex-direction:column;
  padding-left:0px;
  padding-top:0px;
  padding-bottom:0px;
  align-items:center;
  align-content:space-around;
  padding-top:3px;
  padding-bottom:3px;
}


div.widget-grabber > div.grabber-dot{
  min-width:10px;
  max-width: 10px;
  min-height: 5px;
  max-height: 5px; 
  flex:1;
  pointer-events: none;
  position:relative;
  display:flex;
  align-items:center;
  justify-content: space-between;
  /* border: 1px solid red; */
}

div.widget-grabber > div.grabber-dot::before{  
  flex:1;
  content:'';
  top:0px;
  left:0px;
  min-width:3px;
  max-width:3px;
  width:3px;
  height:3px;
  min-height:3px;
  max-height:3px;
  border-radius: 5px;
  background-color:var(--theme-status-info-background);
}

div.widget-grabber > div.grabber-dot::after{  
  flex:1;
  content:'';
  /* position:absolute; */
  /* top:0px; */
  /* right:0px; */
  min-width:3px;
  max-width:3px;
  width:3px;
  height:3px;
  min-height:3px;
  max-height:3px;
  border-radius: 5px;
  background-color:var(--theme-status-info-background);
}


</style>


