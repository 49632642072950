<template>
  <LDBModal result="info" :header="header" size="x-large">
    <template #body>
      <div v-if="mounted">
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="item"
          :fieldname="field.name"
          :definition="definition"
          :record="reviewRecord"
          @change="on_change"
        />
        <LDBField
          v-for="(field, fieldname) in formFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="definition"
          :record="reviewRecord"
          @change="on_change"
        />
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
        @on-api-success="onApiSuccess"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import { isValidDealNumber } from '@/sharedUtils/LdbValidations';

import lodash from 'lodash';
const merge = lodash.merge;

import LDBField from '@/landobyte_vue/LDBField.vue';
import { getCurrentForwardContractType } from '@/sharedUtils/LfxTradeCalc';

export default {
  name: 'LfxFecModificationReview',
  components: { LDBField },
  emits: ['on-api-success'],
  props: {},
  data() {
    return {
      mounted: true,
      reviewRecord: {},
      actionMode: 'release',
    };
  },
  created() {
    this.reviewRecord = this.definition.class({ Table: 'LfxDeal', collection_path: 'deal' }, this.$store);
    const fieldsFromOriginalDeal = ['forwardModificationType'];
    const fieldsFromSupersedingDeal = [
      'forwardContractType',
      'optionStartDate',
      'valueDate',
      'deliveryType',
      'currencyPair',
      'action',
      'currency',
      'amount',
      'counterAmount',
      'spotRate',
      'bench',
      'bankRate',
      'clientRate',
      'forwardPoints',
    ];
    for (const field of fieldsFromOriginalDeal) {
      this.reviewRecord[field] = this.mainDealRecord[field];
    }
    if (this.replacementDealRecord) {
      for (const field of fieldsFromSupersedingDeal) {
        this.reviewRecord[field] = this.replacementDealRecord[field];
      }
    }
  },
  mounted() {},
  beforeUnmount() {
    this.reviewRecord.revert();
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['deal','transaction']),
    header() {
      var actionText;
      switch (this.actionMode) {
        case 'release':
          actionText = 'Release';
          break;
        case 'reject':
          actionText = 'Reject';
          break;
      }
      var modificationTypeText;
      switch (this.reviewRecord.forwardModificationType) {
        case 'drawdown':
          modificationTypeText = this.currenctContractType === 'fixed' ? 'Early Drawdown Request' : 'Drawdown Request';
          break;
        case 'partialDrawdown':
          modificationTypeText = 'Partial Drawdown Request';
          break;
        case 'balanceCancelation':
          modificationTypeText = 'Balance Cancelation Request';
          break;
        case 'extension':
          modificationTypeText = 'Extension Request';
          break;
        default:
          modificationTypeText = 'FEC Modification';
          break;
      }
      return actionText + ' ' + modificationTypeText + ' ' + this.mainDealRecord.dealNumber;
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'switchToRejectButton',
          label: 'Reject Modification',
          type: '',
          guards: this.actionMode === 'release',
          clickEvent() {
            self.actionMode = 'reject';
          },
        },
        {
          name: 'switchToReleaseButton',
          label: 'Release Modification',
          type: '',
          guards: this.actionMode === 'reject',
          clickEvent() {
            self.actionMode = 'release';
          },
        },

        {
          name: 'rejectButton',
          label: 'Reject Modification',
          type: 'primary',
          action: 'fecRejectModification',
          params: {
            transactionId: this.mainDealRecord.transactionFileId,
            dealId: this.mainDealRecord.id,
          },
          actionParams: this.reviewRecord,
          guards: this.actionMode === 'reject',
          clickEvent() {
            self.$router.replace({ name: 'transactionDealDetailsView' });
          },
        },

        {
          name: 'releaseButton',
          label: 'Release Modification',
          type: 'primary',
          action: 'fecReleaseModification',
          params: {
            transactionId: this.mainDealRecord.transactionFileId,
            dealId: this.mainDealRecord.id,
          },
          actionParams: this.reviewRecord,
          enabledGuards:
            this.reviewRecord.forwardModificationType === 'balanceCancelation' || !!this.reviewRecord.dealNumber,
          guards: this.actionMode === 'release',
          clickEvent() {
            self.$router.replace({ name: 'transactionDealDetailsView' });
          },
        },
      ];
      return allButtons !== undefined ? allButtons : [];
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : '/';
    },

    // ----- Definition -----

    definition() {
      return getDefinition('deal');
    },

    // ----- Record -----
    recordName() {
      return 'deal';
    },
    mainDealId() {
      return +this.$route.params.dealId;
    },
    mainDealRecord() {
      return this.$store.state?.deal[this.mainDealId];
    },
    transactionFileId() {
      return this.mainDealRecord.transactionFileId;
    },
    transactionFileRecord() {
      return this.$store.state?.transaction[this.transactionFileId];
    },

    currenctContractType() {
      return getCurrentForwardContractType(this.reviewRecord.data.forwardContractType, this.reviewRecord.data.optionStartDate, this.transactionFileRecord.isValueDateAfterSpotDate, this.reviewRecord.valueDate);
    },
    subDealRecords() {
      const subDeals = [];
      for (const k in this.$store.state?.deal) {
        if (this.$store.state?.deal[k].supersedesDealId) {
        }
        if (
          this.$store.state?.deal[k].supersedesDealId === this.mainDealId &&
          this.$store.state?.deal[k].status !== 'rejected'
        ) {
          subDeals.push(this.$store.state?.deal[k]);
        }
      }
      return subDeals;
    },
    replacementDealRecord() {
      if (!this.subDealRecords) {
        return undefined;
      }
      for (const deal of this.subDealRecords) {
        if (deal.transactionFileId === this.mainDealRecord.transactionFileId) {
          return deal;
        }
      }
      return undefined;
    },
    partialDealRecord() {
      if (!this.subDealRecords) {
        return undefined;
      }
      for (const deal of this.subDealRecords) {
        if (deal.transactionFileId !== this.mainDealRecord.transactionFileId) {
          return deal;
        }
      }
      return undefined;
    },
    // ----- Fields -----

    action() {
      switch (this.reviewRecord.forwardModificationType) {
        case 'drawdown':
          return 'fecRequestDrawdown';
        case 'partialDrawdown':
          return 'fecRequestPartialDrawdown';
        case 'balanceCancelation':
          return 'fecRequestBalanceCancelation';
        case 'extension':
          return 'fecRequestExtension';
        default:
          return undefined;
      }
    },
    fields() {
      return this.definition.fields;
    },
    view() {
      return 'requestFecModification';
    },
    viewFieldNames() {
      let requiredFields = [];
      let additionalFields = [];
      if (this.actionMode === 'release') {
        requiredFields = ['forwardModificationType'];
        switch (this.reviewRecord.forwardModificationType) {
          case 'drawdown':
            additionalFields = ['deliveryType', 'valueDate'];
            break;
          case 'partialDrawdown':
            additionalFields = ['amount', 'deliveryType', 'valueDate'];
            break;
          case 'balanceCancelation':
            additionalFields = [];
            break;
          case 'extension':
            additionalFields = ['forwardContractType', 'valueDate'];
            break;
          default:
            additionalFields = [];
            break;
        }
        if (this.reviewRecord.forwardContractType === 'partiallyOptional') {
          additionalFields.push('optionStartDate');
        }
        if (
          this.reviewRecord.forwardModificationType === 'extension' ||
          (this.reviewRecord.forwardModificationType !== 'balanceCancelation' && this.currenctContractType === 'fixed')
        ) {
          additionalFields = [
            ...additionalFields,
            ...[
              'currencyPair',
              'action',
              'currency',
              'amount',
              'counterAmount',
              'spotRate',
              'bench',
              'bankRate',
              'clientRate',
              'forwardPoints',
            ],
          ];
        }
      }
      return [...requiredFields, ...additionalFields];
    },
    viewFields() {
      const res = [];
      for (const fieldName of this.viewFieldNames) {
        const fields = this.fields.filter((field) => field.field === fieldName);
        if (fields.length > 0) {
          const updatedField = this.overrideField(fields[0]);
          res.push(updatedField);
        }
      }
      return res;
    },
    formFieldNames() {
      switch (this.actionMode) {
        case 'release':
          return ['dealNumber'];
        case 'reject':
          return ['rejectionReason'];
      }
    },
    formFields() {
      const res = [];
      for (const fieldName of this.formFieldNames) {
        const fields = this.fields.filter((field) => field.field === fieldName);
        if (fields.length > 0) {
          const updatedField = this.overrideField(fields[0]);
          res.push(updatedField);
        }
      }
      return res;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    on_change({ field, value }) {
      this.reviewRecord[field] = value;
    },
    overrideField(field) {
      if (['deliveryType', 'dealNumber'].includes(field.name)) {
        const startingField = merge({}, field);
        const overrideObject = this.getFieldOverRideObject(field);
        const newField = merge(startingField, overrideObject);
        return newField;
      } else {
        return field;
      }
    },
    getFieldOverRideObject(field) {
      switch (field.name) {
        case 'deliveryType':
          return {
            datatype: {
              option: {
                optionType: 'text',
                options: [
                  { id: 'Cash', name: 'Value Today', tagType: 'info' },
                  { id: 'Tom', name: 'Value Tomorrow', tagType: 'success' },
                  { id: 'Spot', name: 'Spot' },
                ],
              },
            },
          };
        case 'dealNumber':
          return {
            label: this.reviewRecord.forwardModificationType === 'partialDrawdown' ? "Spot Deal - Deal Number" : "Deal Number",
            validators: {
              custom: {
                name: 'custom',
                args: {
                  vFunction: isValidDealNumber,
                  validatingInterface: 'frontend',
                },
                msg: 'Deal numbers must be in correct format',
              },
            },
          };
        default:
          return {};
      }
    },

    // ----- API calls -----
    async onApiSuccess(_responseObject) {},
  },
};
</script>

<style></style>
