<template>
  <LDBModal result="info" header="Deal Log" size="xx-large">
    <template #body>
      <LDBLoader v-if="failedTreasuryDeal_retrievalStatus === 'retrieving'" />
      <LDBItemView
        v-if="failedTreasuryDeal_retrievalStatus === 'retrieved'"
        :view="view"
        :definition="failedTreasuryDealDefinition"
        :record="failedTreasuryDealRecord"
      />
    </template>
    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      ></LDBButton>
    </template>    

  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxFailedTreasuryDealItem',
  components: { LDBItemView },
  props: {},
  data() {
    return {failedTreasuryDeal_retrievalStatus: 'retrieving'}; //'retrieved' | 'retrieving'
  },
  created() {
  },
  mounted() {
    if (this.failedTreasuryDeal?.[this.failedTreasuryDealId]) {
      this.failedTreasuryDeal_retrievalStatus = 'retrieved'
    } else {
      this.retrieveFailedTreasuryDeal()
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['failedTreasuryDeal']),

    // ----- View -----

    view() {
      return 'item';
    },

    // ----- Account record -----

    failedTreasuryDealId() {
      return +this.$route.params?.failedTreasuryDealId;
    },
    failedTreasuryDealRecord() {
      return this.failedTreasuryDeal?.[this.failedTreasuryDealId] || {};
    },
    failedTreasuryDealDefinition () {
      this.failedTreasuryDealRecord?.definition
    },
    hasFailedTreasuryDealRecordLoaded() {
      return this.failedTreasuryDealDefinition ? true : false;
    },

    // ----- API action -----
    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          action: 'click',
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

      ];
      return allButtons;      
    },


  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    async retrieveFailedTreasuryDeal() {
      const response = await doPermissionAction({onSuccess:{doStoreUpdate:true}}, 'getFailedTreasuryDeal', { failedTreasuryDealId: this.failedTreasuryDealId }, {});
      this.failedTreasuryDeal_retrievalStatus = 'retrieved'
    },
  },
};
</script>
