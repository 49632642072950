<template>
  <div class="lfx-homepage-config">
    <div class="lfx-homepage-welcome">
      <!-- <div class="welcome-label" >Welcome {{ loggedInUser.firstName }}</div> -->
      <div class="welcome-title-bar" :class="welcomLabelClass">
        Welcome {{ loggedInUser.preferredName || loggedInUser.firstName }}
      </div>
      <div class="last-login">Your last login was on {{ lastLogin }}</div>
      <div class="widget-settings-container" menu="widgets">
        <capitec-button-bar
          :style="`max-height:48px; min-height:48px; background-color:transparent; border:none; flex:auto;`"
        >
          <LDBButton
            v-for="button in titleBarButtons"
            :key="button"
            :label="button.label"
            action="click"
            :type="button.type"
            :params="{}"
            :enabledGuards="true"
            :icon="button.icon"
            :viewGuards="true"
            @click="onTitleBarButtonClick(button)"
          />
        </capitec-button-bar>

        <template v-if="false">
          <capitec-button
            v-for="button in titleBarButtons"
            :style="systemButtonStyle"
            :key="button"
            :icon="button.icon"
            :label="button.label"
            :type="button.type"
            @click="onTitleBarButtonClick(button)"
          />
        </template>

        <div class="widget-settings-button" menu="widgets" @click="showHideConfigMenu">
          <capitec-icon icon="system/settings" :style="{ 'pointer-events': 'none', filter: 'brightness(100)' }" />
        </div>
      </div>
      <div class="configure-widgets">
        <div class="configure-widget-container" :class="showMenuClass">
          <div class="configure-widget-header" menu="widget">
            <capitec-label type="subtitle" label="Enabled Widgets" :style="{ 'pointer-events': 'none' }" />
          </div>

          <draggable
            :list="widgetOrder"
            :disabled="false"
            item-key="name"
            class="list-group"
            ghost-class="ghost"
            @start="dragging = true"
            @end="dragEnd"
          >
            <template #item="{ element }">
              <LfxEnableWidget
                :widget="getWidget(element)"
                :widgets="enabledWidgets"
                @on-enable-click="onEnableWidget"
                @on-widget-size="onWidgetSize"
                @on-setting="onWidgetSetting"
              />
            </template>
          </draggable>

          <div class="widget-config-spacer"></div>
        </div>
      </div>
    </div>

    <div class="widget-container">
      <template v-for="widget in enabledWidgetOrder" :key="widget">
        <component :is="widget" :widget="getWidget(widget)" :changingSettings="changingSettingsForWidget(widget)" />
      </template>
    </div>
  </div>
</template>

<script>
import LDBView from '@/landobyte_vue/LDBView.vue';
import LfxEnableWidget from './LfxEnableWidget.vue';
import LfxWidgetOpenPayments from './LfxWidgetOpenPayments.vue';
import LfxWidgetClientRequireAttention from './LfxWidgetClientRequireAttention.vue';
import LfxWidgetApplicationRequiresAttention from './LfxWidgetApplicationRequiresAttention.vue';
import LfxWidgetPaymentRequireAttention from './LfxWidgetPaymentRequireAttention.vue';
import LfxWidgetSentBackPayments from './LfxWidgetSentBackPayments.vue';
import LfxWidgetDeals from './LfxWidgetDeals.vue';
import LfxWidgetHolidays from './LfxWidgetHolidays.vue';
import LfxWidgetPendingFecs from './LfxWidgetPendingFecs.vue';
import LfxWidgetRatesFeed from './LfxWidgetRatesFeed.vue';
import LfxWidgetPendingModifyFec from './LfxWidgetPendingModifyFec.vue';
import LfxWidgetReadyForDrawdownFec from './LfxWidgetReadyForDrawdownFec.vue';

import draggable from 'vuedraggable';

import { defineComponent } from 'vue';

import { mapState, mapActions, mapGetters } from 'vuex';

export default defineComponent({
  components: {
    draggable,
    LDBView,
    LfxEnableWidget,
    LfxWidgetHolidays,
    LfxWidgetOpenPayments,
    LfxWidgetClientRequireAttention,
    LfxWidgetApplicationRequiresAttention,
    LfxWidgetPaymentRequireAttention,
    LfxWidgetSentBackPayments,
    LfxWidgetDeals,
    LfxWidgetRatesFeed,
    LfxWidgetPendingFecs,
    LfxWidgetPendingModifyFec,
    LfxWidgetReadyForDrawdownFec,
  },
  name: 'Home',
  data() {
    return {
      currentlySetting: '',
      widgetOrder: [],
      show: false,
      enabledWidgets: [],
    };
  },
  mounted() {
    this.enabledWidgets = [];
    let enabledWidgets = [
      'LfxWidgetOpenPayments',
      'LfxWidgetClientRequireAttention',
      'LfxWidgetPaymentRequireAttention',
      'LfxWidgetSentBackPayments',
      'LfxWidgetDeals',
      'LfxWidgetHolidays',
      'LfxWidgetRatesFeed',
    ];
    let allConfiguredWidgets = [];
    if (this?.authUser?.settings?.widgetSettings !== undefined) {
      enabledWidgets = Object.values(this.authUser.settings.widgetSettings)
        .filter((w) => w.enabled)
        .map((w) => w.component);
      allConfiguredWidgets = Object.values(this.authUser.settings.widgetSettings).map((w) => w.component);
    }
    for (const widget of enabledWidgets) {
      if (this.allWidgets[widget]) {
        this.enabledWidgets.push(widget);
      }
    }
    for (const widget in this.allWidgets) {
      if (!allConfiguredWidgets.includes(widget)) {
        this.enabledWidgets.push(widget);
      }
    }
    if (this.authUser?.settings?.widgetOrder) {
      const checkedWidgetOrder = [];
      for (const widgetName of this.authUser.settings.widgetOrder) {
        if (!checkedWidgetOrder.includes(widgetName)) {
          checkedWidgetOrder.push(widgetName);
        }
      }
      if (checkedWidgetOrder.length !== this.authUser.settings.widgetOrder.length) {
        this.saveWidgetOrder(checkedWidgetOrder);
      }
    }
    this.setupWidgetOrder();
  },
  watch: {
    widgetOrderNames(newOrder, oldOrder) {
      this.setupWidgetOrder();
    },
  },
  methods: {
    ...mapActions(['showWidgetSettings', 'saveUserSettings', 'saveWidgetOrder', 'saveWidgetSettings']),
    onWidgetSetting({ setting, widget }) {
      if (setting) {
        this.currentlySetting = widget;
      } else {
        this.currentlySetting = '';
      }
    },
    async onWidgetSize({ size, widget }) {
      let allWidgets = { ...this.allWidgets };
      allWidgets[widget].size = size;
      let saveUserSettingsResponse = await this.saveWidgetSettings(allWidgets);
    },
    setupWidgetOrder() {
      this.widgetOrder = [];
      for (let w = 0; w < this.widgetOrderNames.length; w++) {
        let id = this.widgetOrderNames[w];
        if (this.allWidgets[id] !== undefined) {
          if (this.widgetOrder.indexOf(id) === -1) {
            this.widgetOrder.push(id);
          }
        }
      }
      for (let w = 0; w < this.allWidgetNames.length; w++) {
        let id = this.allWidgetNames[w];
        if (this.allWidgets[id] !== undefined) {
          if (this.widgetOrder.indexOf(id) === -1) {
            this.widgetOrder.push(id);
          }
        }
      }
    },

    dragEnd(evt) {
      this.saveWidgetOrder(this.widgetOrder);
    },
    getWidget(element) {
      let widget = this.allWidgets[element];
      let size = this?.authUser?.settings?.widgetSettings?.[element]?.size
        ? this.authUser.settings.widgetSettings[element].size
        : widget.size;
      widget.size = size;
      return widget;
    },
    showHideConfigMenu() {
      this.showWidgetSettings(!this.widgetSettingsVisible);
      // this.show = !this.show;
    },
    changingSettingsForWidget(widget) {
      return widget === this.currentlySetting;
    },

    async onEnableWidget({ enabled, widget }) {
      if (enabled) {
        this.enabledWidgets.push(widget);
      } else {
        this.enabledWidgets.splice(this.enabledWidgets.indexOf(widget), 1);
      }
      let allWidgets = { ...this.allWidgets };
      allWidgets[widget].enabled = enabled;
      let saveUserSettingsResponse = await this.saveWidgetSettings(allWidgets);
      if (!this.widgetOrder.includes(widget)) {
        this.widgetOrder.push(widget);
        this.saveWidgetOrder(this.widgetOrder);
      }
    },
    // ----- Titlebar -----
    onTitleBarButtonClick(evt) {
      const button = evt;
      if (button.routeName) {
        this.$router.push({ name: button.routeName, params: button.routeParams });
      }
    },
    systemButtonStyle() {
      return { '--theme-button-padding-top': '5px', '--theme-button-padding-bottom': '5px' };
    },
  },
  computed: {
    ...mapState(['user', 'authUser', 'widgetSettingsVisible']),
    ...mapGetters(['permissions', 'sdlc', 'isRatesFeedConnected', 'myAccount']),
    titleBarButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'newTransaction',
          label: 'New Transaction',
          type: 'white',
          guards: (this.myAccount.userReviewStatus !== 'nonCompliant') && // || this.myAccount.accountLevel === 'bank') && //2024-09-19 Bank clients should be disabled if no review done
                  !!this.permissions.bookDeal && this.authUser.accountLevel === 'client',
          enabledGuards: this.authUser.accountIsActive,
          routeName: 'transactionsNewTransaction',
          routeParams: { accountId: this.authUser.accountId },
        },
        {
          name: 'transact',
          label: 'Estimate',
          type: 'white',
          guards:
            (this.myAccount.userReviewStatus !== 'nonCompliant') && // || this.myAccount.accountLevel === 'bank') &&  //2024-09-19 Bank clients should be disabled if no review done
            (['intermediary', 'intermediaryBranch'].includes(this.authUser.accountLevel) ||
              !!this.permissions.bookDeal) &&
            this.isRatesFeedConnected, // || !!this.permissions.clientBookDeal,
          enabledGuards: this.authUser.accountIsActive && this.isRatesFeedConnected,
          routeName: 'estimate',
        },
        {
          name: 'setGeneralMessage',
          label: 'Set General Message',
          type: 'white',
          guards:
            (!!this.permissions.updateGeneralSystemMessage),
          routeName: 'systemSettingsGeneralMessageConfig',
        },
      ];
      return allButtons.filter((button) => button.guards);
    },
    enabledWidgetOrder() {
      let res = [];
      for (let w = 0; w < this.widgetOrderNames.length; w++) {
        let widgetName = this.widgetOrderNames[w];
        let widget = this.allWidgets[widgetName];
        if (widget?.enabled) {
          res.push(widget.component);
        }
      }
      return res;
    },
    showMenuClass() {
      return this.widgetSettingsVisible ? 'show' : 'hide';
    },
    welcomLabelClass() {
      switch (this.sdlc) {
        case 'LOCAL':
          return 'welcome-label-local';
        case 'DEV':
          return 'welcome-label-dev';
        case 'INT':
          return 'welcome-label-int';
        case 'QA':
          return 'welcome-label-qa';
        case 'PROD':
          return 'welcome-label';
        default:
          return 'welcome-label';
      }
    },
    loggedInUser() {
      return this.user?.[this?.authUser?.id];
    },
    defaultWidgetOrder() {
      let defaultOrder = ['LfxWidgetRatesFeed', 'LfxWidgetHolidays'];
      let allWidgets = Object.keys(this.allWidgets);
      for (let w = 0; w < allWidgets.length; w++) {
        let widget = allWidgets[w];
        if (defaultOrder.indexOf(widget) === -1) {
          defaultOrder.push(widget);
        }
      }
      return defaultOrder;
    },
    widgetOrderNames() {
      return this.authUser?.settings?.widgetOrder ? this.authUser.settings.widgetOrder : this.defaultWidgetOrder;
    },
    allWidgetNames() {
      let sortedWidgetNames = [];
      let allWidgetNames = Object.keys(this.allWidgets);
      for (let n = 0; n < allWidgetNames.length; n++) {
        let name = allWidgetNames[n];
        if (sortedWidgetNames.indexOf(name) === -1) {
          sortedWidgetNames.push(name);
        }
      }
      return sortedWidgetNames;
    },
    orderedWidgets() {
      let allWidgetNames = this.allWidgetNames;
      let sortedWidgets = this?.authUser?.settings?.widgetOrder ? this?.authUser?.settings?.widgetOrder : [];
      let res = [];
      for (let w = 0; w < sortedWidgets.length; w++) {
        let name = sortedWidgets[w];
        let allWidgetIndex = allWidgetNames.indexOf(name);
        allWidgetNames.splice(allWidgetIndex, 1);
        res.push(name);
      }
      for (let w = 0; w < allWidgetNames.length; w++) {
        let name = allWidgetNames[w];
        res.push(name);
      }
      return res;
    },
    allWidgets() {
      if (this.myAccount.userReviewStatus === 'nonCompliant') { // && this.myAccount.accountLevel !== 'bank') { //2024-09-19 Bank clients should be disabled if no review done
        return {}
      }
      const allWidgets = {
        LfxWidgetOpenPayments: { component: 'LfxWidgetOpenPayments', title: 'Open Payments', size: 2, enabled: true },
        LfxWidgetClientRequireAttention: {
          component: 'LfxWidgetClientRequireAttention',
          title: 'Client Requires Attention',
          accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
          size: 1,
          enabled: true,
        },
        LfxWidgetApplicationRequiresAttention: {
          component: 'LfxWidgetApplicationRequiresAttention',
          title: 'Client Applications',
          accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
          size: 2,
          enabled: true,
        },
        LfxWidgetPaymentRequireAttention: {
          component: 'LfxWidgetPaymentRequireAttention',
          title: 'Payment Requires Attention',
          size: 2,
          enabled: true,
        },
        LfxWidgetSentBackPayments: {
          component: 'LfxWidgetSentBackPayments',
          title: 'Send Back Payment',
          accountLevels: ['client', 'intermediary', 'intermediaryBranch'],
          size: 2,
          enabled: true,
        },
        LfxWidgetDeals: { component: 'LfxWidgetDeals', title: 'Open Deals', size: 2, enabled: true },
        LfxWidgetPendingFecs: {
          component: 'LfxWidgetPendingFecs',
          title: 'Unreleased FECs',
          accountLevels: ['bank'],
          size: 2,
          enabled: true,
        },
        LfxWidgetPendingModifyFec: {
          component: 'LfxWidgetPendingModifyFec',
          title: 'Unreleased FEC Modifications',
          accountLevels: ['bank'],
          size: 2,
          enabled: true,
        },
        LfxWidgetReadyForDrawdownFec: {
          component: 'LfxWidgetReadyForDrawdownFec',
          title: 'FEC Ready for Drawdown',
          accountLevels: ['bank'],
          size: 2,
          enabled: true,
        },
        LfxWidgetHolidays: { component: 'LfxWidgetHolidays', title: 'Upcoming Holidays', size: 4, enabled: true },
      };
      if (this.permissions.ratesFeedView === true) {
        allWidgets.LfxWidgetRatesFeed = {
          component: 'LfxWidgetRatesFeed',
          title: 'Exchange Rates',
          size: 4,
          enabled: true,
        };
      }
      const req = {};
      for (const key in allWidgets) {
        if (!allWidgets[key].accountLevels || allWidgets[key].accountLevels.includes(this.authUser.accountLevel)) {
          req[key] = allWidgets[key];
        }
      }
      let savedWidgetSettings = this?.authUser?.settings?.widgetSettings ? this?.authUser?.settings?.widgetSettings : {};
      const savedWidgetSettingsReq = {};
      for (const key in savedWidgetSettings) {
        if (typeof savedWidgetSettings[key] !== 'string' && req[key] !== undefined) {
          savedWidgetSettingsReq[key] = savedWidgetSettings[key];
        }
      }
      let finalRes = { ...req, ...savedWidgetSettingsReq };
      return finalRes;
    },
    lastLogin() {
      let ts = this.loggedInUser.lastLogin;
      if (ts !== undefined) {
        let date = new Date(ts);
        let dt = date.toString().split(' ');
        return `${dt[0]} ${dt[2]} ${dt[1]} ${dt[3]} @ ${dt[4]}`;
      }
    },
  },
});
</script>

<style>
div.lfx-homepage-config {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
}

div.welcome-title-bar {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 5px;
  right: 0px;
}

div.lfx-homepage-welcome {
  flex: 1;
  max-height: 60px;
  min-height: 60px;
  display: flex;
  position: relative;
  /* display:flex; */
}

div.configure-widget-container {
  flex: 1;

  border: 1px solid #ccc;

  transform-origin: top right;
  transition: all 0.3s linear;
  border-radius: 5px;
  overflow: hidden;
  pointer-events: auto;
  padding-bottom: 5px;
  padding-right: 5px;
  background-color: white;
  padding-top: 5px;
}

.widget-settings-button {
  flex: 1;
}

div.configure-widget-container.show {
  transform: scale(1);
}

div.configure-widget-container.hide {
  transform: scale(0);
}

div.widget-settings-container {
  position: absolute;
  top: 3px;
  right: 10px;
  cursor: pointer;
  min-width: 120px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.welcome-label {
  font-weight: bold;
  font-size: 25px;
  background-color: var(--theme-status-info-background);
  padding: 7px;
  color: white;
  border-radius: 5px;
  padding-top: 8px;
}
.welcome-label-local {
  font-weight: bold;
  font-size: 25px;
  background-color: rgb(0, 72, 109);
  padding: 7px;
  color: white;
  border-radius: 5px;
  padding-top: 8px;
}
.welcome-label-dev {
  font-weight: bold;
  font-size: 25px;
  background-color: rgb(112, 175, 0);
  padding: 7px;
  color: white;
  border-radius: 5px;
  padding-top: 8px;
}
.welcome-label-int {
  font-weight: bold;
  font-size: 25px;
  background-color: rgb(212, 120, 28);
  padding: 7px;
  color: white;
  border-radius: 5px;
  padding-top: 8px;
}

.welcome-label-qa {
  font-weight: bold;
  font-size: 25px;
  background-color: rgb(230, 20, 20);
  padding: 7px;
  color: white;
  border-radius: 5px;
  padding-top: 8px;
  /* margin-bottom:10px; */
}

.last-login {
  font-size: 14px;
  font-weight: 100;
  position: absolute;
  bottom: -15px;
}

div.configure-widgets {
  padding: 10px;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 350px;
  display: flex;
  flex-direction: column;
  box-shadow: var(--theme-lightbox-shadow);
  pointer-events: none;
  z-index: 10000;
}

div.widget-config-spacer {
  flex-grow: auto;
  flex-shrink: 1000;
}

.configure-widget-header {
  background-color: white;
  padding-left: 10px;
  flex: 1;
}

.widget-container {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
  grid-row-gap: 8px;
  margin-right: 5px;
  overflow-y: auto;
  grid-auto-flow: rows;
  /* grid-auto-rows: 400px; */
}

.lfx-widget-container {
  border: 1px solid #ccc;
  min-height: 270px;
  border-radius: 3px;
  background-color: white;
  display: flex;
  flex-direction: column;
  grid-column: span 2;
}

.lfx-holiday-row-container {
  flex: 1;
}

.lfx-widget-spacer {
  flex-grow: 1;
  flex-shrink: 10000;
}

.lfx-widget-scroller {
  flex: 1;
  max-height: 15px;
}
</style>
