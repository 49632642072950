<template>
  <LFXContent
    :titleBar="titleBar"
    :show_tabs="showTabs"
    :tabs="tabs"
    :show_label="showLabel"
    :label="contentLabel"
    :show_actions="showActions"
    :form_layout="formLayout"
    :show_sidebar="false"
  >
    <template #actions>
      <LDBButton
        v-for="button in actionBarButtons"
        :key="button.name"
        :icon="button.icon"
        :label="button.label"
        :type="button.type"
        @click="button.clickEvent"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :modal="button.modal"
        :onSuccess="button.onSuccess"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template> </LDBButton
    ></template>

    <template #content>
      <router-view></router-view>
    </template>
  </LFXContent>

  <router-view name="modal"></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXContent from '@/components/layout/LfxContent.vue';

export default {
  name: 'LfxConfig',
  components: { LFXContent },
  props: {},
  data() {
    return {};
  },
  created() {
    if (this.$route.name === 'config') {
      this.selectFirstTab();
    }
  },
  mounted() {},
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'config') {
      next(false);
      this.selectFirstTab();
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters(['permissions', 'isDevelopment']),
    ...mapState([]),

    // ----- Title Bar -----

    titleBar() {
      const titleBar = {
        label: 'System Config',
      };
      return titleBar;
    },

    // ----- Tabs -----

    showTabs() {
      return true;
    },
    tabs() {
      let tabs = [
        {
          tabName: 'systemSettings',
          routeName: 'systemSettings',
          guards: true,
          label: 'System Settings',
        },
        {
          tabName: 'publicHolidays',
          routeName: 'configPublicHolidaysView',
          guards: true,
          label: 'Public Holidays',
        },
        {
          tabName: 'bopCategories',
          routeName: 'configBopCategoriesView',
          guards: this.permissions?.getBopCats,
          label: 'BOP Categories',
        },
        {
          tabName: 'bopCategoryGroups',
          routeName: 'configBopCategoryGroupsView',
          guards: this.isDevelopment,
          label: 'BOP Category Groups',
        },
        {
          tabName: 'applicationDocumentTypes',
          routeName: 'configApplicationDocumentTypesView',
          guards: true,
          label: 'Application Documents',
        },
        {
          tabName: 'permissions',
          routeName: 'configPermissionsView',
          guards: true,
          label: 'Permissions',
        },
        {
          tabName: 'permissionGroups',
          routeName: 'configPermissionGroupsView',
          guards: this.isDevelopment,
          label: 'Permission Groups',
        },
        {
          tabName: 'currencyPairs',
          routeName: 'configCurrencyPairsView',
          guards: true,
          label: 'Currency Pairs',
        },
        {
          tabName: 'currencies',
          routeName: 'configCurrenciesView',
          guards: true,
          label: 'Currencies',
        },
        {
          tabName: 'countries',
          routeName: 'configCountriesView',
          guards: true,
          label: 'Countries',
        },
        {
          tabName: 'userTeams',
          routeName: 'configUserTeamsView',
          guards: true,
          label: 'User Teams',
        },
      ];
      return tabs.filter((tab) => tab.guards);
    },

    // ----- Content Label -----

    showLabel() {
      return this.contentLabel ? true : false;
    },
    contentLabel() {
      return this.$route.meta?.content?.label
        ? this.$route.meta.content.label
        : "Navigation didn't complete, please click tab above to continue";
    },

    // ----- Action Bar -----

    showActions() {
      return this.actionBarButtons.length > 0 || false;
    },
    actionBarButtons() {
      let self = this;
      let allButtons = {
        //
        // ----- System settings

        systemSettings: [
          {
            name: 'systemSettingsEditUserReviewConfig',
            guards: this.permissions.editSystemSettingsUserReview,
            label: 'Edit User Review Config',
            type: '',
            clickEvent() {
              self.$router.push({ name: 'systemSettingsEditUserReviewConfig' });
            },
          },
          {
            name: 'systemSettingsEditTradingConfig',
            guards: this.permissions.editSystemSettingsTrading,
            label: 'Edit Trading Config',
            type: '',
            clickEvent() {
              self.$router.push({ name: 'systemSettingsEditTradingConfig' });
            },
          },
          {
            name: 'systemSettingsEditAccessConfig',
            guards: this.permissions.editSystemSettingsAccess,
            label: 'Edit Access Config',
            type: '',
            clickEvent() {
              self.$router.push({ name: 'systemSettingsEditAccessConfig' });
            },
          },
          {
            name: 'enableTradingSystem',
            label: 'Enable Trading - System',
            type: 'primary',
            modal: {
              header: `Enable Trading - System`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Enable',
              content: 'Are you sure you want to Enable Trading - System?',
            },
            action: 'enableTradingSystem',
            params: { configId: 1 },
            actionParams: {},
            guards: this.$store.state.systemSettings.trading.enabledSystem === false,
          },
          {
            name: 'disableTradingSystem',
            label: 'Disable Trading - System',
            type: 'primary',
            modal: {
              header: `Disable Trading - System`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Disable',
              content: 'Are you sure you want to Disable Trading - System?',
            },
            action: 'disableTradingSystem',
            params: { configId: 1 },
            actionParams: {},
            guards: this.$store.state.systemSettings.trading.enabledSystem === true,
          },
          {
            name: 'testReportFrontEndEndError',
            label: 'Log Dummy FE Error',
            type: 'primary',
            modal: {
              header: `Log Dummy Front End Error`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Send',
              content: 'Are you sure you want to Log a dummy Front End Error?',
            },
            action: 'logFrontEndError',
            params: { configId: 1 },
            actionParams: {
              errorType: 'Dummy Error for Testing',
              errorReport: {},
            },
            guards: true,
          },
        ],

        // ----- Public Holidays

        configPublicHolidaysView: [
          {
            name: 'addPublicHoliday',
            guards: !!this.permissions.createPublicHoliday,
            label: 'Add Public Holiday',
            type: '',
            clickEvent() {
              self.$router.push({ name: 'configPublicHolidaysViewAdd' });
            },
          },
          {
            name: 'importPublicHolidats',
            guards: !!this.permissions.createPublicHoliday,
            label: 'Import Public Holidays',
            type: '',
            clickEvent() {
              self.$router.push({ name: 'configPublicHolidaysViewImport' });
            },
          },
        ],

        // ----- BOP Categories

        configBopCategoriesView: [
          {
            name: 'bopCatDocs',
            guards: true,
            label: 'BOP Documents',
            type: '',
            clickEvent() {
              self.$router.push({ name: 'configBopCategoriesViewDocuments' });
            },
          },
          {
            name: 'bopCatInfo',
            guards: true,
            label: 'BOP Info',
            type: '',
            clickEvent() {
              self.$router.push({ name: 'configBopCategoriesViewInfo' });
            },
          },
        ],

        // ----- BOP Category Groups

        // ----- Application Documents

        // ----- Permissions

        configPermissionsView: [
          {
            name: 'addPermission',
            guards: true && this.isDevelopment,
            label: 'Add Permission',
            type: '',
            clickEvent() {
              self.$router.push({ name: 'configPermissionsViewAdd' });
            },
          },
        ],

        // ----- Permission Groups

        configPermissionGroupsView: [
          {
            name: 'addPermissionGroup',
            guards: false && this.isDevelopment,
            label: 'Add Permission Group',
            type: '',
            clickEvent() {
              self.$router.push({ name: 'configPermissionGroupsViewAdd' });
            },
          },
        ],

        // ----- Currency Pairs

        configCurrencyPairsView: [
          {
            name: 'recacheValueDates',
            guards: !!this.permissions.recacheValueDates,
            label: 'Recache Value Dates',
            type: '',
            clickEvent() {
              self.$router.push({ name: 'configCurrencyPairsViewRecacheValueDates' });
            },
          },
        ],

        // ----- Currencies

        // ----- Countries

        // ----- User Teams

        configUserTeamsView: [
          {
            name: 'addUserTeam',
            guards: !!this.permissions.createUserTeam,
            label: 'Add User Team',
            type: '',
            clickEvent() {
              self.$router.push({ name: 'configUserTeamViewAdd' });
            },
          },
        ]
      };
      // return actions[this.actionGroup] ? actions[this.actionGroup].filter((action) => action.guards) : [];
      return allButtons[this.actionGroup]?.filter((button) => button.guards) || [];
    },
    actionGroup() {
      return this.$route.meta?.content?.actionGroup;
    },

    // ----- Layout -----

    formLayout() {
      return this.$route.meta?.layout === 'list' ? false : true;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Title Bar -----

    onTitleBarButtonClick(button) {
      this.$router.push({ name: button.routeName });
    },

    // ----- Tabs -----

    selectFirstTab() {
      if (this.tabs.length) {
        this.$router.replace({ name: this.tabs[0].routeName });
      }
    },
  },
};
</script>
