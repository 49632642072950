<template>
  <div class="app-version">
    Frontend: v{{ feSettings.version }} | Backend: v{{ systemSettings.system.version }}

    <span
      ><capitec-toggle
        v-if="isLocalSdlc"
        label="Dev view"
        :checked="devSwitch"
        @value-change="switchDev"
      ></capitec-toggle
    ></span>
  </div>

  <div class="time">
    <p>{{ currentSAST }}</p>
    <p v-if="currentLocalTime !== currentSAST">{{ currentLocalTime }}</p>
  </div>

  <template v-if="isDevEnv">
    <router-link tag="div" class="test-link" to="/test">xgrep {{ current_component }} .ts</router-link>
  </template>
  <div class="footer-text">
    Capitec Bank is an authorised financial services provider (FSP 46669) and registered credit provider (NCRCP13).
    Capitec Bank Limited Reg. No: 1980/003695/06<br />You can access our Privacy Centre on
    <a class="popi-link" target="_blank" href="https://www.capitecbank.co.za/privacy-centre/">
      https://www.capitecbank.co.za/privacy-centre/
    </a>
  </div>
  <capitec-image class="landobyte-logo" alt="LandoByte" src="/developed_by_landobyte_small.png"> </capitec-image>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
  computed: {
    ...mapState(['feSettings', 'systemSettings', 'devSwitch', 'currentSAST', 'currentLocalTime']),
    ...mapGetters(['isDevEnv', 'isLocalSdlc']),

    current_component() {
      let matchedRoutes = this.$route.matched.length;
      let lastRoute = this.$route.matched[matchedRoutes - 1];
      return lastRoute?.name ? lastRoute.name : 'TEST';
    },
  },
  methods: {
    switchDev() {
      this.$store.state.devSwitch = !this.devSwitch;
    },
  },
};
</script>

<style scoped>
.app-version {
  margin: 10px;
  position: absolute;
  font-size: 0.8rem;
}

.time {
  margin: 10px;
  position: absolute;
  left: 15rem;
  top: -10px;
  font-size: 0.8rem;
}
.time > p {
  margin: 0px;
  padding: 0px;
}
</style>
