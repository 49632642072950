import VueStore from 'vue-class-store';

import { LdbDbObject, LdbDefinition } from '@/definitions/LdbInterfaces';
import { LfxApplicationCifEntityInt, LfxApplicationCifEntityDef, requiredFields } from '@/definitions/LfxApplicationCifEntityDef';
import { LfxModel, LfxDefinition } from './LfxModel';


//@ts-ignore
import { http_patch, http_get, http_put } from '@/lfx_rest';

@VueStore
//@ts-ignore
class LfxApplicationCifEntityMdl extends LfxDefinition {
  //@ts-ignore
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
//@ts-ignore
export class LfxApplicationCifEntity extends LfxModel {
  //@ts-ignore
  constructor(data, state) {
    super({definition_name:'application_cif_entity',...data}, state);
    //@ts-ignore
    ;
  }
  get required_fields() {
    return requiredFields(this as LfxApplicationCifEntityInt) as (keyof this)[];
  }

  get outstanding_fields() {
    const outstandingFields = [];
    for (const field of this.required_fields!) {
      if (this[field] === null || this[field] === undefined) {
        outstandingFields.push(field);
      }
    }
    return outstandingFields;
  }

  get is_submittable () {
    return this.outstanding_fields.length === 0 && !this.has_validation_errors
  }

  actbusinessSectorCode(evt:any,state:any,router:any) {
    //@ts-expect-error
    const routeName = this.cifType === 'full'
    ? 'clientApplicationWizardClientCifDetailsEditBscPicker'
    : 'clientApplicationWizardAssociatedCifDetailsEditBscPicker';
    router.push({ name: routeName });
  }  

}

//@ts-ignore
export function Definition(context, name: string) {
  return new LfxApplicationCifEntityMdl(LfxApplicationCifEntityDef, LfxApplicationCifEntity, context, name, {});
}
