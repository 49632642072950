<template>
    <LDBModal result="info" header="Payment Portfolio History" size="xx-large">
      <template #body>
        <LFXListView v-if="hasListLoaded" :definition="definition" :records="records" />
        <template v-else>
          <LDBLoader />
        </template>
      </template>    
    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @on-api-success="button.onApiSuccess"
        @click="button.clickEvent"
      ></LDBButton>
    </template>    
  </LDBModal>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXListView from '@/views/general/LFXListView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxPaymentPortfolioHistoryList',
  components: { LFXListView, LDBLoader },
  props: {},
  data() {
    return {
      hasListLoaded:false
    };
  },
  async created() {
    await doPermissionAction(this,'getPaymentPortfolioHistory',{transactionId:this.transactionId,paymentId:this.paymentId});
    this.hasListLoaded = true;
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['transaction','payment']),
    transactionId() {
      return +this.$route.params.transactionId;
    },
    paymentId() {
      return +this.$route.params?.paymentId;
    },
    records() {
      return this.payment[this.paymentId].portfolio || {}
    },
    definition() {
      return getDefinition('payment_portfolio');
    },

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          action: 'click',
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

      ];
      return allButtons;      
    },

  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
  },
};
</script>

<style>
/* div.container {
} */
</style>
