<template>
  <div class="box">
    <div>
      <strong>TODO: {{ routeName }}</strong>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LfxTodo',
  computed: {
    routeName() {
      return this.$route.meta?.label ? this.$route.meta?.label : this.$route.name;
    },
  },
};
</script>

<style scoped>
.box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 1rem;
}

.box div {
  /* width: 100px; */
  /* height: 100px; */
  border-style: solid;
  border-width: 1px;
  padding: 1rem;
  margin: 1rem;
  color: white;
  /* background-color: var(--theme-color-status--info); */
  background-color: #009de0;
  border-color: black;
  border-radius: 1em;
}
</style>
