<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <ol>
        <li>The markup will be restored to normal views.</li>
        <li>This will also allow trading in the currency pair.</li>
        <li>This will also allow editing of the markup.</li>
      </ol>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in buttons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

export default {
  name: 'LfxUnhideAccountMarkup',
  components: {},
  props: {},
  emits: {},
  created() {},
  mounted() {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    // ----- Account record -----

    accountId() {
      return +this.$route.params.accountId;
    },
    accountRecord() {
      return this.account[this.accountId];
    },

    // ----- Markup record ----

    markupId() {
      return +this.$route.params.markupId;
    },
    markupRecord() {
      return this.accountRecord.markup[this.markupId];
    },

    // recordId() {
    //   return +this.$route.params.accountId;
    // },
    // record() {
    //   return this.account?.[this.recordId];
    // },
    // accountLevel() {
    //   return this.record?.accountLevel;
    // },
    // accountLabel() {
    //   if (this.record?.accountLevel === 'bank') {
    //     return 'Bank';
    //   } else if (this.record?.accountLevel === 'intermediary') {
    //     return 'Intermediary';
    //   } else if (this.record?.accountLevel === 'intermediaryBranch') {
    //     return 'Branch';
    //   } else if (this.record?.accountLevel === 'client') {
    //     return 'Client';
    //   } else {
    //     return 'Account';
    //   }
    // },
    header() {
      return `Unhide Markup: ${this.markupRecord.currencyPair}`;
    },
    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta?.modal?.cancelRoute : 'todo';
      //TODO: go to user's home as fallback
    },
    buttons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelUnhideMarkup',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            // self.$router.replace({ name: self.cancelRoute });
            self.$router.go(-1);
          },
        },
        {
          name: 'unhideMarkup',
          label: `Unhide ${this.markupRecord.currencyPair}`,
          type: 'primary',
          guards: this.markupRecord.accountMarkupUnhide_buttonGuard,
          clickEvent() {
            self.unhideMarkup();
          },
        },
      ];
      return allButtons.filter((button) => button.guards);
    },
  },
  methods: {
    async unhideMarkup() {
      let response = await this.markupRecord.unhideMarkup();
      if (response.status === 'success') {
        this.$router.replace({ name: 'accountMarkupView' });
      }
    },
  },
};
</script>
