//@ts-nocheck
import VueStore from 'vue-class-store';

import { LfxClientPortfolioInt, LfxClientPortfolioDef } from '@/definitions/LfxClientPortfolioDef';

import { LfxModel, LfxDefinition } from './LfxModel';

@VueStore
class LfxClientPortfolioMdl extends LfxDefinition {
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
export class LfxClientPortfolio extends LfxModel {
  constructor(data, state) {
    super({definition_name:'client_portfolio',...data}, state);    
  }
}

export function Definition(context, name: string) {
  return new LfxClientPortfolioMdl(LfxClientPortfolioDef, LfxClientPortfolio, context, name, {});
}
