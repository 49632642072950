//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2023 LandoByte (Pty) Ltd.
//  File        : LfxFailedTreasuryDealDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Failed Treasury Deal record for LandoByte TS systems
//  Created     : 16 March 2023 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition } from '../definitions/LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------

import type { LfxDealAction, LfxDealDeliveryType } from './LfxDealDef';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxFailedTreasuryDealInt {
  id?: number;
  reference?: string;

  accountId?: number;
  branchId?: number;
  intermediaryId?: number;
  integrationId?: string;
  dealNumber?: string;
  correlationId?: string;

  sourceBankAccountID?: number;
  destinationBankAccountID?: number;

  clientCif?: string;
  thirdPartyBp?: string;
  fromSettlementAccount?: string;
  toSettlementAccount?: string;

  currencyPair?: string;
  currency?: string;
  counterCurrency?: string;
  action?: LfxDealAction;
  deliveryType?: LfxDealDeliveryType;
  valueDate?: Date;
  bankRate?: number;
  clientRate?: number;
  amount?: number;

  adUserId?: string;
  adOrganisation?: string;

  messageTimestamp?: Date;
  processedTimestamp?: Date;

  foundClients?: string;
  foundIntermediaries?: string;
  bankDirectClient?: boolean;

  message?: {};
  rejectionReason?:string;

  createdAt?: Date;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxFailedTreasuryDealDef: LdbDefinition = {
  title: 'Failed Treasury Deal',
  table: 'LfxFailedTreasuryDeal',
  collectionPath: '/failedTreasuryDeal',
  database: 'sql',
  pagingType: 'backEnd',
  view: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
  indexes: ['accountId', 'branchId', 'intermediaryId'],
  groups: {
    system: { priority: 1, label: 'General' },
    treasury: { priority: 2, label: 'Treasury' },
    integration: { priority: 4, label: 'Integration' },
    client: { priority: 5, label: 'Client' },

    timing: { priority: 7, label: 'Timing' },
    message: {priority:10, label: 'Message'}

  },

  fields: {
    id: {
      label: 'ID',
      datatype: { id: { descriptionField: 'reference' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
    },
    reference: {
      label: 'Reference',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group:'system'
    },
    accountId: {
      label: 'Client',
      datatype: {
        foreignKey: {
          collection: 'fk_clients',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: true },
      group: 'system',
    },
    intermediaryId: {
      label: 'Intermediary',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediaries',

          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: true },
      group: 'system',
    },
    branchId: {
      label: 'Branch',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediary_branches',

          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'system',
    },
    integrationId: {
      label: 'Integration ID',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, search: true, csv: true },
      group: 'integration',
    },
    dealNumber: {
      label: 'Deal Number',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false },
      group: 'integration',
    },
    correlationId: {
      label: 'Correlation ID',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false },
      group: 'integration',
    },
    sourceBankAccountID: {
      label: 'Source Account',
      datatype: {
        foreignKey: {
          collection: 'fk_bank_accounts',
          linkTable: 'LfxBankAccount',
          linkField: 'id',
          displayField: 'description',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: true },
      group: 'system',
    },
    destinationBankAccountID: {
      label: 'Destination Account',
      datatype: {
        foreignKey: {
          collection: 'fk_bank_accounts',
          linkTable: 'LfxBankAccount',
          linkField: 'id',
          displayField: 'description',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: true },
      group: 'system',
    },

    clientCif: {
      label: 'Client CIF',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false },
      group: 'system',
    },
    thirdPartyBp: {
      label: 'Third Party BP',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false },
      group: 'system',
    },
    fromSettlementAccount: {
      label: 'From Settlement Account',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false },
      group: 'system',
    },
    toSettlementAccount: {
      label: 'To Settlement Account',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false },
      group: 'system',
    },
    currencyPair: {
      label: 'Currency Pair',
      datatype: {
        foreignKey: {
          collection: 'fk_currency_pairs',
          linkTable: 'LfxCurrencyPair',
          linkField: 'id',
          displayField: 'name',
          type: 'text',
        },
      },
      default: null,
      mandatory: true,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: true, },
      group: 'treasury',
    },

    currency: {
      label: 'Currency',
      datatype: {
        foreignKey: {
          collection: 'fk_currencies',

          linkTable: 'LdbCurrency',
          linkField: 'id',
          displayField: 'id',
          type: 'text',
        },
      },
      default: null,
      mandatory: true,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: true, },
      group: 'treasury',
    },
    counterCurrency: {
      label: 'Counter Currency',
      datatype: {
        foreignKey: {
          collection: 'fk_currencies',

          linkTable: 'LdbCurrency',
          linkField: 'id',
          displayField: 'id',
          type: 'text',
        },
      },
      default: null,
      mandatory: true,
      views: { create: true, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'treasury',
    },
    action: {
      label: 'Client Action',
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'Buy', name: 'Client Buys' },
            { id: 'Sell', name: 'Client Sells' },
          ],
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: false,
      views: { create: true, edit: false, item: true, list: true, delete: false, search: true, csv: true },
      group: 'treasury',
    },
    deliveryType: {
      label: 'Delivery Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'Cash', name: 'Value Today', tagType: 'info' },
            { id: 'Tom', name: 'Value Tomorrow', tagType: 'success' },
            { id: 'Spot', name: 'Spot', tagType: 'info' },
            { id: 'FWD', name: 'Forward', tagType: 'warning' },
          ],
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: false,
      views: { create: true, edit: false, item: true, list: false, delete: false, search: true, csv: true },
      group: 'treasury',
    },
    valueDate: {
      label: 'Value Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: true, edit: false, item: true, list: true, delete: false, search: true, csv: true },
      group: 'treasury',
    },
    bankRate: {
      label: 'Bank Rate',
      datatype: 'float',
      displayType: { float: { decimalPlaces: 4 } },
      default: null,
      mandatory: true,
      allowNullValues: false,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'treasury',
    },
    clientRate: {
      label: 'Deal Rate',
      datatype: 'float',
      displayType: { float: { decimalPlaces: 4 } },
      default: null,
      mandatory: true,
      allowNullValues: false,
      views: { create: true, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'treasury',
    },
    amount: {
      label: 'Amount',
      datatype: { currency: { symbol: '' } },
      default: null,
      mandatory: true,
      allowNullValues: false,
      views: { create: true, edit: false, item: true, list: true, delete: false, search: true, csv: true, },
      group: 'treasury',
    },
    adUserId: {
      label: 'AD Username',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false },
      group: 'integration',
    },
    adOrganisation: {
      label: 'AD Organisation',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false },
      group: 'integration',
    },
    messageTimestamp: {
      label: 'Message Timestamp',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'timing'
    },
    processedTimestamp: {
      label: 'Processed Timestamp',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'timing'
    },
    foundClients: {
      label: 'Found Clients',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false },
      group: 'integration',
    },
    foundIntermediaries: {
      label: 'Found Intermediaries',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false },
      group: 'integration',
    },
    bankDirectClient: {
      label: 'Bank Direct Client?',
      datatype: 'boolean',
      default: false,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    message: {
      label: 'Message',
      datatype: {json:{}},
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: true },
      group: 'message',
    },
    rejectionReason: {
      label: 'Rejection Reason',
      datatype: 'memo',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: true },
      group: 'integration',
    },
    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'timing'
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'timing'
    },
  },
};
