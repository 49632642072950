<template>
    <div  class="container">
    
    </div>
</template>


<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

export default {
  components: {
    
  },
  props: {  

  },
  data() {
    return {
      
    }
  },
  created() {
    
  },
  mounted() {
    this.logout();
    this.$router.push('/');
  },
  computed:{
    ...mapGetters([]),
  	...mapState([]),
  },
  methods:{
  	...mapActions(['logout']),
  	...mapMutations([]),
  }
 }

</script>

<style>

div.container{
  
}

</style>

