import { Component, css, html } from '../../elements';

/**
 * A control that allows a user to toggle a value on or off.
 * 
 * ```js 
 * import 'platform/components/inputs/Toggle'; 
 * ```
 * 
 * ```html
 * <capitec-toggle
 *   label="My Toggle Value"
 *   data="{'id': 12345, 'name': 'Test'}"
 *   hint="Required"
 *   error="Field level error message"
 *   checked>
 * </capitec-toggle>
 * ```
 */
export class Toggle extends Component {

	// --------------
	// INITIALISATION
	// --------------

	/**
	 * @hideconstructor
	 */
	constructor() {

		super();

		// Set the default property values.
		this.label = null;
		this.data = null;

		this.hint = null;
		this.error = null;

		this.checked = false;
		this.disabled = false;
	}

	// ----------
	// PROPERTIES
	// ----------	

	/**
	 * Registry of all properties defined by the component.
	 * 
	 * @property {String} [label] - The toggle label text.
	 * @property {Object} [data] - Data associated with the component.
	 * 
	 * @property {String} [hint] - A hint message to assist the user.
	 * @property {String} [error] - An error message to guide users to correct a mistake.
	 * 
	 * @property {Boolean} [checked=false] - Indicator if the component is checked or not.
	 * @property {Boolean} [disabled=false] - Indicator if the component is disabled.
	 */
	static get properties() {

		return {
			label: { type: String },
			data: { type: Object },

			hint: { type: String },
			error: { type: String },

			checked: { type: Boolean },
			disabled: { type: Boolean }
		};
	}

	// -------------------
	// LIFECYCLE OVERRIDES
	// -------------------

	// n/a

	// --------------
	// EVENT HANDLERS
	// --------------

	/**
	 * Handles click events.
	 * 
	 * @param {MouseEvent} event - The event details.
	 * 
	 * @ignore
	 * @returns {void}
	 */
	_click(event) {

		// Ignore the event if the component is disabled.
		if (this.disabled) {
			return event.stopImmediatePropagation();
		}

		// Toggle the component checked state.
		this._toggleChecked();
	}

	// --------------
	// PUBLIC METHODS
	// --------------

	focus() {
		this.shadowRoot.getElementById(`track`).focus();
	}

	// ---------------
	// PRIVATE METHODS
	// ---------------

	/**
	 * Toggles the current checked state of the component.
	 * 
	 * @ignore
	 * @returns {void}
	 */
	_toggleChecked() {

		// Record the previous state.
		const oldValue = this.checked;

		// Invert the checked state.
		this.checked = !oldValue;

		// Notify any subscribers that the value changed.
		this.dispatchEvent(new CustomEvent(`value-changed`, {
			detail: {
				old: oldValue,
				new: this.checked
			}
		}));

		this.dispatchEvent(new CustomEvent(`value-change`, {
			detail: {
				old: oldValue,
				new: this.checked
			}
		}));
	}

	/**
	 * Handles key down events.
	 * 
	 * @param {KeyboardEvent} event - The event details.
	 * 
	 * @ignore
	 * @returns {void}
	 */
	_keyDown(event) {

		// Ignore the event if the component is disabled.
		if (this.disabled) {
			return event.stopImmediatePropagation();
		}

		// Intercept space and enter key events to toggle the component checked state.
		const keyCode = (event.code || ``).toUpperCase();

		if (keyCode === `SPACE` || keyCode === `ENTER` || keyCode === `NUMPADENTER`) {

			// Toggle the component checked state.
			this._toggleChecked();

			// Prevent the key event from propagating further.
			return event.preventDefault();
		}
	}

	// ---------
	// RENDERING
	// ---------	

	static get styles() {

		return [
			super.styles,
			css`

				/* CONTAINER STYLES */

				.container {
					display: flex;
					align-items: center;
				}

				/* LABEL STYLES */
				
				.container > .label {
					color: var(--theme-label-font-color, #4E6066);
					font-family: var(--theme-label-font-family, Arial, Helvetica, sans-serif);
					font-size: var(--theme-label-font-size, 14px);
					font-weight: var(--theme-label-font-weight, 300);

					margin-left: var(--theme-toggle-label-spacing, 8px);

					cursor: default;
				}

				.container > .label > .hint {
					color: var(--theme-input-hint-label-font-color, lightgrey);
					font-family: var(--theme-input-hint-label-font-family, Arial, Helvetica, sans-serif);
					font-size: var(--theme-input-hint-label-font-size, 12px);
					font-weight: var(--theme-input-hint-label-font-weight, 300);

					padding-top: 4px;
				}

				.container > .label > .error {
					color: var(--theme-input-error-label-font-color, red);
					font-family: var(--theme-input-error-label-font-family, Arial, Helvetica, sans-serif);
					font-size: var(--theme-input-error-label-font-size, 12px);
					font-weight: var(--theme-input-error-label-font-weight, 300);

					padding-top: 4px;
				}

				/* TOGGLE BUTTON STYLES */

				.container > #content {
					box-sizing: border-box;
					cursor: pointer;

					display: grid;
					align-items: center;
				}
				
				.container > #content > .track {
					width: var(--theme-toggle-track-width, 23px);
					height: var(--theme-toggle-track-height, 12px);

					grid-row: 1;
					grid-column: 1;
					
					background-color: var(--theme-toggle-track-background-color, #7C7C7C);
					border-radius: var(--theme-toggle-track-border-radius, 16px);

					margin-left: var(--theme-toggle-track-inset, 8px);
					margin-right: var(--theme-toggle-track-inset, 8px);

					outline: 0;
				}

				.container > #content > .knob {
					height: var(--theme-toggle-knob-height, 14px);

					grid-row: 1;
					grid-column: 1;

					position: relative;
				}

				.container > #content > .knob > div {
					width: var(--theme-toggle-knob-width, 14px);
					height: 100%;

					background-color: var(--theme-toggle-knob-background-color, #FFFFFF);
					border-radius: 50%;
					box-shadow: var(--theme-toggle-knob-box-shadow, 0 2px 4px 0 rgba(0, 0, 0, 0.25), 0 1px 3px rgba(0, 0, 0, 0.15));
					
					position: absolute;
					left: 0px;

					margin-top: auto;
					margin-bottom: auto;

					transition: .15s left ease-in-out;
				}
				
				.container > #content:hover > .knob > div {
					box-shadow: var(--theme-toggle-knob-hover-box-shadow, 0 0 3px 3px rgba(0, 157, 224, 0.10));
				}
				
				/* CHECKED STATE STYLES */

				.container.checked > #content > .track {
					background-color: var(--theme-toggle-checked-track-background-color, #7FCAEC);
				}
				
				.container.checked > #content > .knob > div {
					background-color: var(--theme-toggle-checked-knob-background-color, #009DE0);
					left: calc(100% - var(--theme-toggle-knob-width, 14px));
					box-shadow: none;
				}

				.container.checked > #content:hover > .knob > div {
					background-color: var(--theme-toggle-checked-knob-background-color, #009DE0);
					left: calc(100% - var(--theme-toggle-knob-width, 14px));
					box-shadow: var(--theme-toggle-checked-hover-knob-box-shadow, 0 0 3px 3px rgba(0, 157, 224, 0.10));
				}

				/* DISABLED STATE STYLES */

				.container.disabled > #content {
					cursor: default;
				}

				.container.disabled > #content > .track,
				.container.disabled > #content:hover > .track {
					background-color: var(--theme-toggle-disabled-track-background-color, #DEDEDE);
				}

				.container.disabled > #content:hover > .knob > div,
				.container.disabled > #content > .knob > div {
					background-color: var(--theme-toggle-disabled-knob-background-color, #DEDEDE);
					box-shadow: var(--theme-toggle-disabled-knob-box-shadow, 0 2px 4px 0 rgba(0, 0, 0, 0.25), 0 1px 3px rgba(0, 0, 0, 0.15));
				}
			`
		];
	}

	_mobileTemplate() {
		return this._webTemplate();
	}

	_webTemplate() {
		return html`
			<div class="container${this.checked ? ` checked` : ``}${this.disabled ? ` disabled` : ``}">
				<div
					id="content"
					@click="${this._click}"
					@keydown="${this._keyDown}">
					<div id="track" class="track" tabindex="${this.disabled ? `` : 0}"></div>
					<div class="knob">
						<div></div>
					</div>
				</div>

				<label class="label" @click="${this._click}">
					${this.label}
					${this.hint && !this.error ? html`<div class="hint">${this.hint}</div>` : ``}
					${this.error ? html`<div class="error">${this.error}</div>` : ``}
				</label>
			</div>
		`;
	}

	_kioskTemplate() {
		return this._webTemplate();
	}
}

window.customElements.define(`capitec-toggle`, Toggle);

/**
 * When the control value is either changed to either on or off.
 *
 * @example
 * <capitec-toggle ... @value-change="${this._handler}"></capitec-toggle>
 *
 * @event Toggle#value-change
 * @type {Object}
 * @property {Object} detail - Contains the old and new value.
 * @property {String} detail.old - The old value.
 * @property {String} detail.new - The new value.
 */
