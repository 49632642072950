<template>
  <div class="ldb-search-chips-container">
  
  <!-- {{choices}} -->
  <div class="ldb-search-chip-choices">

    <template v-for="choice in choices" :key="choice.id">
      <capitec-label type="strong" class="choice-group-label" :label="choice.title" />
      <template v-for="chip in choice.chips" :key="chip.id">
      
        <LDBOptionChip 
          :style="selectedChipStyle(choice.id,chip.id)"
          :id="chip.id"
          :group="choice.id"
          :label="chip.label"
          :selector="chip.selector"        
          :selected="chipSelected(choice.id,chip.id)"
          @click="onOptionChipClicked(choice.id,chip.id)"
        />
      </template>
    </template>
    </div>
    
    <template v-for="selector in selectors" :key="selector.id">
    
    <LDBSearchChip 
    v-if="deleted[selector.id] === false"
    :id="selector.id"
    :label="selector.label"
   
    :field="selector.field"
    :operator="selector.operator"
    :value="selector.value"
    :mode="mode"
    @on-click="onSelectorClick"
    @on-remove="onSelectorRemove"
    :selected="selected[selector.id]"
    :closable="closable"
  />

  </template>
  
  </div>
</template>

<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

import LDBSearchChip from './LDBSearchChip.vue';

export default {
  emits:['on-selector-change','on-search-state-changed','on-chips-loaded'],
  components: { LDBSearchChip },
  props: {  
    mode:{
      type:String,
      default:'choice'
    },
    choices:{
      type:Object,
      default() {
        return []
      }
    },
    selectors:{
      type:Object,
      default() {
        return {}
      }
    },
    initialState:{
      type:Object,
      default:undefined,
    }
  },
  data() {
    return {
      selected:{},
      deleted:{},
      choice:{}
    }
  },
  created() {
    
  },
  mounted() {
    let initialStateIsEmpty = (this.initialState === undefined || Object.keys(this.initialState).length === 0);
    if ( initialStateIsEmpty ) {
      for ( let s = 0 ; s < this.selectors.length ; s ++ ) {
        let sel = this.selectors[s];
        this.selected[sel.id] = sel.selected;
        this.deleted[sel.id] = false;
      }
      for (let c = 0 ; c < this.choices.length ; c ++ )  {
        let choice = this.choices[c];
        for (let h = 0 ; h < choice.chips.length ; h++ ) {
          let chip = choice.chips[h];
          if (chip.default) {
            this.choice[choice.id] = chip.id;
          }
        }
      }
      
    }
    else {
      this.deleted  = {...this.initialState?.deleted   || {}};
      this.choice   = {...this.initialState?.choice    || {}};
      this.selected = {...this.initialState?.selected  || {}};
    }
    
    let selector = this.selector;
    if (Object.keys(selector).length > 0) {
      this.$emit('on-chips-loaded',{selector:this.selector,chipState:this.currentState})
    }
  },
  computed:{
    ...mapGetters([]),
  	...mapState([]),
    currentState() {
      return {
        choice:{...this.choice},
        deleted:{...this.deleted},
        selected:{...this.selected}
      }
    },
    choiceSeletor() {
      let res = {};
      for (let c = 0 ; c < this.choices.length ; c ++ )  {
        let choice = this.choices[c];
        let choiceId = choice.id;
        let selectedChip = this.choice[choiceId];
        for (let h = 0 ; h < choice.chips.length ; h++ ) {
          if (selectedChip === choice.chips[h].id) {
            res = {...res,...choice.chips[h].selector}
          }
        }
      }
      return res;
    },
    onChoiceSelect({id,group,selector}) {
    },
    closable() {
      return (this.mode === 'multiple');
    },
    selector() {
      return {...this.singleSelector,...this.choiceSeletor};
    },
    singleSelector() {
      let res = {};
      for ( let s = 0 ; s < this.selectors.length ; s ++ ) {
        let sel = this.selectors[s];
        if (this.selected[sel.id] === true && this.deleted[sel.id] === false) {
          if (res[sel.field] === undefined) {
            res[sel.field] = {};
          }
          if (sel.operator === 'in') {
            if (res[sel.field].in === undefined) { 
              res[sel.field].in = [];
            }
            res[sel.field].in.push(sel.value)
          }
          else 
          {
            res[sel.field][sel.operator] = sel.value;
          }
        }
      }
      return res;
    }
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([]),
    selectedChipStyle(group,chip) {
      let res = {};
      if (this.chipSelected(group,chip)) {
        res['--theme-chip-background-color'] = 'var(--theme-status-info-background)';
        res['--theme-chip-label-color'] = 'white';
      }
     return res;
    },
    onOptionChipClicked(group,chip) {
      if (this.choice[group] === undefined) {
        this.choice[group] = {};
      }
      this.choice[group] = chip;      
      this.$emit('on-search-state-changed',this.currentState);
      this.$emit('on-selector-change',this.selector);
    },
    selectedClass(group,chip) {
      return (this.choice?.[group] === chip) ? 'selected' : 'not-selected';
    },
    chipSelected(group,chip) {
      return (this.choice?.[group] === chip);
    },
  	onSelectorRemove(id) {
      this.deleted[id] = true;
      this.$emit('on-search-state-changed',this.currentState);
      this.$emit('on-selector-change',this.selector);
    },
    onSelectorClick(evt) {
      if ( evt.id ) {
        this.selected[evt.id] = evt.selected;
        this.$emit('on-search-state-changed',this.currentState);
        this.$emit('on-selector-change',this.selector);
      }
    }
  }
 }
 
</script>

<style>

div.ldb-search-chips-container{
  display:flex;
  align-items: center;
}

div.ldb-search-chip-choices{
  /* border: 1px solid red; */
  display:flex;
  align-items:center;
  padding-left:5px;
}

div.ldb-search-chip-choices > capitec-chip{
  margin-left:5px;
  margin-right: 5px;
}

capitec-chip.option-chip.selected > * > button.chip{
  border: 3px solid red;
  transform:rotateZ(10deg);
}

.chip{
  --theme-chip-border: 1px solid red !important;
  transform:rotateZ(10deg);
}

</style>


