<template>
  <LDBItemView :record="record" :definition="definition" view="item" />
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';

export default {
  components: { LDBItemView },
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    recordName() {
      return this.$route.params.record;
    },
    recordId() {
      return this.$route.params.id;
    },
    record() {
      return this.$store.state[this.recordName][this.recordId];
    },
    definition() {
      return getDefinition(this.recordName);
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>

<style>
/* div.container {
} */
</style>
