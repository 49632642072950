<template>
  <LDBModal result="info" header="Edit User Team" size="x-large">
    <template #body>
      <LDBItemView :record="userTeamRecord" :view="view" action="editUserTeam" :params="submitParams" />
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

export default {
  name: 'configUserTeamView',
  components: { LDBItemView, LDBLoader },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),

    view() {
      return this.$route.meta?.content?.view || 'item';
    },
    submitParams() {
      return {
        configId: 1,
        userTeamId: this.userTeamId,
      };
    },
    // ----- User Teams Record -----

    userTeamId() {
      return this.$route.params?.userTeamId;
    },
    userTeamRecord() {
      return this.$store.state.config[1].userTeam?.[this.userTeamId];
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
