<template>
  <capitec-content-box class="lfx-content-container" :style="gridStyle">
    <div v-if="show_title" class="content-title">
      <!-- <div class="lfx-content-title-bar" style="background-color: #009de0; color: white"> -->
      <!-- <div class="lfx-content-title-bar" style="background-color: rgb(230, 20, 20); color: white"> -->
      <div class="lfx-content-title-bar" :style="style">
        <div class="lfx-content-title-bar-icon">
          <capitec-icon :icon="icon" style="filter: grayscale(100) brightness(100)" />
        </div>
        <div class="lfx-content-title-bar-title">
          {{ titleBar.label }}
        </div>
        <div class="lfx-content-title-bar-buttons">
          <capitec-button
            v-for="button in titleBar.buttons"
            :style="systemButtonStyle"
            :key="button"
            :icon="button.icon"
            :label="button.label"
            :type="button.type"
            @click="onTitleBarButtonClick(button)"
          />
        </div>

        <div v-if="show_tabs" class="lfx-content-title-bar-tabs">
          <div class="lfx-content-title-bar-tabs-align">
            <capitec-tab-group
              @tab-select="onTabClick"
              :selectedIndex="selectedTabIndex"
              :style="`transform:translateY(-8px) ; --theme-tab-bar-height:30px;`"
            >
              <capitec-tab v-for="tab in tabs" :key="tab.tabName" :label="tab.label" layout="vertical" halign="right" />
            </capitec-tab-group>
          </div>
        </div>
      </div>
    </div>

    <div v-if="show_stepper" class="content-stepper">
      <slot name="stepper" />
    </div>

    <div v-if="show_label || show_actions" class="content-label" :class="{ form_layout }">
      <capitec-label class="lfx-label" type="title" :label="label" />
      <!-- <LDBLoader /> -->
      <capitec-action-bar
        class="lfx-actions"
        v-if="show_actions"
        :style="`--theme-actionbar-min-height:32px; --theme-actionbar-padding:5px;`"
      >
        <capitec-spacer />
        <slot name="actions" />
      </capitec-action-bar>
    </div>

    <div class="content-content" :class="{ form_layout }">
      <div class="content-banners">
        <template v-if="show_banners">
          <template v-for="banner in banners" :key="banner.id">
            <LfxBanner :title="banner.title" :status="banner.status" :closable="banner.closable" :id="banner.id">
              {{ banner.content }}
            </LfxBanner>
          </template>
        </template>

        <slot name="banners" />
      </div>

      <div class="content-content-content">
        <slot name="content" />
      </div>
    </div>

    <!-- <div class="content-spacer"></div> -->

    <div v-if="show_buttons" class="content-buttons">
      <slot name="buttons" />
    </div>

    <div v-if="show_sidebar" class="content-sidebar">
      <slot name="sidebar" />
    </div>
  </capitec-content-box>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'LfxContent',
  components: {},
  props: {
    show_title: { type: Boolean, default: true },
    titleBar: { type: Object, required: true },
    show_tabs: { type: Boolean, default: false },
    tabs: {
      type: Array,
      default() {
        return [];
      },
    },
    show_stepper: { type: Boolean, default: false },
    stepper: {
      type: Array,
      default() {
        return [];
      },
    },
    banners: {
      type: Array,
      default() {
        return [];
      },
    },
    show_label: { type: Boolean, default: true },
    label: { type: String, default: '' },
    show_actions: { type: Boolean, default: true },
    show_content: { type: Boolean, default: true },
    form_layout: { type: Boolean, default: false },
    show_buttons: { type: Boolean, default: false },
    show_sidebar: { type: Boolean, default: false },
    show_banners: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters(['sdlc']),
    ...mapState([]),
    systemButtonStyle() {
      return { '--theme-button-padding-top': '5px', '--theme-button-padding-bottom': '5px' };
    },
    // ----- Titlebar -----

    icon() {
      return this.titleBar.icon
        ? this.titleBar.icon
        : this.$route.meta.icon
        ? this.$route.meta.icon
        : 'system/arrow-left-action';
    },
    style() {
      switch (this.sdlc) {
        case 'LOCAL':
          return 'background-color: rgb(0, 72, 109); color: white';
        case 'DEV':
          return 'background-color: rgb(112, 175, 0); color: white';
        case 'INT':
          return 'background-color: rgb(212, 120, 28); color: white';
        case 'QA':
          return 'background-color: rgb(230, 20, 20); color: white';
        case 'PROD':
          return 'background-color: #009de0; color: white';
        default:
          return 'background-color: #009de0; color: white';
      }
    },

    // ----- Tabs -----

    routeTabName() {
      return this.$route.meta?.content?.tabName;
    },
    selectedTabIndex() {
      return this.tabs.findIndex((tab) => tab.tabName === this.routeTabName);
    },

    // ----- Styling -----

    gridStyle() {
      return {
        'grid-template-rows': this.gridTemplateRows,
        'grid-template-columns': this.gridTemplateColumns,
      };
    },
    gridTemplateRows() {
      const footer = '40px';
      const header = '64px';
      const rates_feed = '10px';
      const header_spacing = '32px';
      let title = this.show_title ? '48px' : '0px';
      title = this.show_title && this.show_tabs ? '80px' : title;
      let banners = this.show_banners ? '0px' : '0px';
      let stepper = this.show_stepper ? '60px' : '0px';
      let label = this.show_label || this.show_actions ? '48px' : '0px';
      // let actions = this.show_actions ? '70px' : '0px';
      let content = this.show_content
        ? `calc(100vh - ${header} - ${footer} + 8px - ${header_spacing} - ${title} - ${stepper} - ${label})`
        : '0px';
      let buttons = this.show_buttons ? '48px' : '0px';
      let spacer = 'auto';
      let templateRows = [title, stepper, label, banners, content, buttons, spacer];
      return templateRows.join(' ');
    },

    gridTemplateColumns() {
      let sidebar = this.show_sidebar ? '0px repeat(12,auto) 200px' : '0px repeat(12,auto) 0px';
      if (this.form_layout === true) {
        sidebar = 'auto repeat(12,minmax(50px,100px)) auto';
      }
      return [sidebar].join(' ');
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Titlebar -----

    onTitleBarButtonClick(evt) {
      const button = evt;
      if (button.routeName) {
        this.$router.push({ name: button.routeName, params: button.routeParams });
      }
    },

    // ---- Tabs ----

    onTabClick(evt) {
      let selectedIndex = evt.detail.selectedIndex;
      let selectedTab = this.tabs[selectedIndex];
      if (selectedTab.routeParams) {
        this.$router.replace({ name: selectedTab.routeName, params: selectedTab.routeParams });
      } else {
        this.$router.replace({ name: selectedTab.routeName });
      }
    },
  },
};
</script>
<style>
div.combined-content {
  display: flex;
}

.lfx-content-container {
  --debug-layout: 1px;
  --debug-layout: 0px;
}

.lfx-content-container {
  --margin: 20px;
  /* max-height: calc(100vh - 120px); */
  display: grid;
  border: var(--debug-layout) dotted black;
  /* margin-right: 16px; */
  grid-template-areas:
    'title   title   title   title   title   title   title    title    title    title    title    title    title    title'
    'stepper stepper stepper stepper stepper stepper stepper  stepper  stepper  stepper  stepper  stepper  stepper  stepper'
    'label   label   label   label   label   label   label    label    label    label    label    label    label    label'
    'banners banners banners banners banners banners banners  banners  banners  banners  banners  banners  banners  banners'
    'content content content content content content content  content  content  content  content  content  content  content'
    'buttons buttons buttons buttons buttons buttons buttons  buttons  buttons  buttons  buttons  buttons  buttons  buttons'
    'spacer  spacer  spacer  spacer  spacer  spacer  spacer   spacer   spacer   spacer   spacer   spacer   spacer   spacer ';
  /* overflow: hidden; */
  /* border: 1px solid green; */
}

:root {
  --theme-tab-border-bottom: white;
  --theme-tab-selected-font-color: white;
  --theme-tab-font-color: white;
  --theme-tab-selected-indicator-color: white;
}

.lfx-label {
  align-self: stretch;
  flex: auto;
  white-space: nowrap;
}

.lfx-actions {
  background-color: white;
  flex: auto;
  overflow: hidden;
  max-height: 70px;
  box-shadow: none;
  min-height: 30px;
  /* border: 1px solid red; */
}

.lfx-content-title-bar {
  display: grid;
  grid-template-areas:
    '. icon . title buttons buttons'
    '. tabs tabs tabs  tabs .';
  grid-template-columns: 16px 32px 10px auto auto;
  grid-template-rows: 50px 32px;
  min-height: 80px;
}

.lfx-content-title-bar-icon {
  grid-area: icon;
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.lfx-content-title-bar-title {
  grid-area: title;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  /* padding-left:20px; */
  font-size: 24px;
  line-height: 32px;
  min-height: 52px;
  max-height: 52px;
}

.lfx-content-title-bar-buttons {
  grid-area: buttons;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 8px;
  /* border: 1px solid red; */
  flex: 1;
  /* width:100%; */
}

.lfx-content-title-bar-buttons > * {
  margin-left: 10px;
}

.lfx-content-title-bar-tabs {
  grid-area: tabs;
  padding-top: 8px;
  min-height: 48px;
  max-height: 48px;
  margin-left: -16px;
  display: block;
  position: relative;
}

.lfx-content-title-bar-tabs-align {
  position: absolute;
}

.content-title {
  grid-area: title;
  border: var(--debug-layout) solid red;
  overflow: hidden;
  border-radius: var(--theme-card-border-radius);
}

.content-tabs {
  grid-area: tabs;
  border: var(--debug-layout) solid green;
  overflow: hidden;
  margin-top: 10px;
}

.content-stepper {
  grid-area: stepper;
  border: var(--debug-layout) solid blue;
}

.content-label {
  grid-area: label;
  border: var(--debug-layout) solid teal;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
}

.content-label.form_layout {
  border-left: var(--theme-data-table-border);
  border-right: var(--theme-data-table-border);
  border-top: var(--theme-data-table-border);
  border-bottom: var(--theme-data-table-border);
}

.content-actions {
  grid-area: actions;
  border: 1px solid red;
  padding-left: 0px !important;
  padding-top: 10px;
  padding-right: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  justify-items: center;
}

.content-content {
  grid-area: content;
  border: var(--debug-layout) solid orange;
  position: relative;
  background-color: white;
  overflow-y: auto;
  /* overflow: hidden; */
  /* border: 3px dotted magenta; */
  display: flex;
  flex-direction: column;
}

.content-spacer {
  grid-area: spacer;
}

.content-banners {
  flex: none;
  grid-area: banners;
  border-bottom: var(--theme-data-table-border);
  border-left: var(--theme-data-table-border);
  border-right: var(--theme-data-table-border);
  background-color: white;
}

div.content-content-content {
  position:relative;
  flex: auto;
}

.content-content.form_layout {
  border-bottom: var(--theme-data-table-border);
  border-left: var(--theme-data-table-border);
  border-right: var(--theme-data-table-border);
}

.content-buttons {
  grid-area: buttons;
  border: var(--debug-layout) solid magenta;
}

.content-tbuttons {
  grid-area: tbuttons;
  border: var(--debug-layout) solid cyan;
}

.content-sidebar {
  grid-area: sidebar;
  border: var(--debug-layout) solid teal;
}

capitec-tab {
  overflow: hidden;
}
</style>
