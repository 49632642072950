<template>
  <template v-if="haveMarkupsLoaded">
      <table-lite
      id="lfx-deal-markup-view"
      :is-static-mode="true"
      :is-slot-mode="true"
      :has-checkbox="false"
      :is-loading="false"
      :is-re-search="true"      
      :columns="tableColumns"
      :rows="sortedRecords"
      :pageSize="100"
      :total="sortedRecords.length"
    >
      <template v-for="(col, i) of tableColumns" v-slot:[col.field]="data" :key="i">
        <template v-if="data.value[col.field].pips !== undefined">ZAR {{data.value[col.field].amount}} ({{data.value[col.field].pips}} pips)</template>
        <template v-else-if="data.value[col.field].amount !== undefined">ZAR {{data.value[col.field].amount}}</template>
        <template v-else>{{data.value[col.field]}}</template>
      </template>
    </table-lite>
  </template>
  <template v-else>
    <LDBLoader/>
  </template>
</template>


<script>

import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import TableLite from '@/views/general/TableLite.vue';

import { doPermissionAction } from '@/lfx_rest/lfx_action';
import { getMarkupAmount } from '@/sharedUtils/LfxTradeCalc';
import { roundTo } from '@/sharedUtils/LdbSharedUtils';


export default {
  name: 'LfxTransactionDealMarkups',
  components: { TableLite, LDBLoader },
  
  props:  { includeCancelledAndRejected: { type: Boolean, required: false, default: false}},
  data() {
    return {
      haveMarkupsLoaded:false,
    };
  },
  async created() {
    // TODO be smarter about this - don't get data if you already have it
    // TODO talk to Abraham about where deals live and how to get them
    await doPermissionAction(this,'getTransactionDeals',{transactionId:this.transactionId});
    for (const dealId in this.deals) {
      await doPermissionAction(this,'getDealMarkups',{transactionId:this.transactionId,dealId:dealId});
    }
    this.haveMarkupsLoaded = true
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['transaction','account']),

    view() {
      return this.$route.meta?.content?.view || 'item';
    },

    // ----- Transaction Record -----

    transactionId() {
      return +this.$route.params?.transactionId;
    },
    transactionRecord() {
      return this.transaction?.[this.transactionId];
    },

    // ----- Deal Records -----

    deals() {
      return this.transaction?.[this.transactionId].deals
    },
    filteredDeals() {
      const res = {}
       for (const dealId in this.deals) {
        if (this.includeCancelledAndRejected || !['rejected','cancelled'].includes(this.deals[dealId].status)) {
          res[dealId] = this.deals[dealId]
        }
       }
      return res
    },

    // ----- Markup Records -----

    allMarkups() {
      const self = this;
      const res = [];
      for (const dealId in self.deals) {
        for (const markupId in self.deals[dealId].markup || {}) {
          res.push(this.deals[dealId].markup[markupId])
        }
      }
      return res
    },

    allMarkupAccountIds() {
      const self = this;
      const res = [1,self.transactionRecord.intermediaryId];
      for (const markupId in self.allMarkups) {
        if (!res.includes(self.allMarkups[markupId].accountId)) {
          res.push (self.allMarkups[markupId].accountId)
        }
      }
      return res
    },
    allMarkupAccountNames() {
      const self = this;
      const res = [];
      for (const accountId of self.allMarkupAccountIds) {
        if (this.account?.[accountId]?.name) {
          res.push({id:accountId,name:this.account[accountId].name})
        } else {
          doPermissionAction(this,'getAccount',{accountId:accountId})
          res.push({id:accountId,name:"Account ID "+accountId})
        }
      }
      return res
    },  

    // ----- TABLE CONSTRUCTORS -----

    tableColumns() {
      let self = this;
      let res = [
        {
          col: "dealColumn",
          field: "dealName",
          label: "Deal",
          isKey: false,
          sortable: false,
        },
        {
          col: "dealStatusColumn",
          field: "dealStatus",
          label: "Deal Status",
          isKey: false,
          sortable: false,
        }
      ];
      for (const accountName of self.allMarkupAccountNames) {
        let column = {
          col: "accountId"+accountName.id,
          field: "accountId"+accountName.id,
          label: accountName.name,
          isKey: false,
          sortable: false,
        };
        res.push(column);
      }
      res.push({
          col: "totalColumn",
          field: "dealTotal",
          label: "Total",
          isKey: false,
          sortable: false,
        })
      return res;
    },

    sortedRecords() {
      let self = this;
      let res = [];
      const totalRow = self.blankRow("Total","",false);
      for (const dealId in self.filteredDeals) {
        const deal = self.deals[dealId];
        const dealRow = self.blankRow(deal.reference,deal.statusText,true);
        if (deal.markup) {
          for (const markupId in deal.markup) {
            const pips = deal.markup[markupId].pips;
            const amount = getMarkupAmount(deal,pips,deal.quoteCurrency) / 100;
            dealRow["accountId"+deal.markup[markupId].accountId].pips = pips;
            dealRow["accountId"+deal.markup[markupId].accountId].amount = amount;

            dealRow.dealTotal.pips = roundTo(dealRow.dealTotal.pips + pips,2);
            dealRow.dealTotal.amount = roundTo(dealRow.dealTotal.amount + amount,2);
            totalRow["accountId"+deal.markup[markupId].accountId].amount = roundTo(totalRow["accountId"+deal.markup[markupId].accountId].amount + amount,2);
            totalRow.dealTotal.amount = roundTo(totalRow.dealTotal.amount + amount,2);
          }
        }
        res.push(dealRow)
      };
      res.push(totalRow)
      return res
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    blankRow(dealName,dealStatus,setDefaultPips) {
      const self = this;
      const defaultPips = setDefaultPips ? 0 : undefined
      const row = {
        dealName: dealName,
        dealStatus: dealStatus,
        dealTotal: {pips:defaultPips,amount:0},
      }
      for (const accountId of self.allMarkupAccountIds) {
        row['accountId'+accountId] = {pips:defaultPips,amount:0}
      }
      return row
    },

  },
};
</script>
