<template>
  <div class="lfx-portfolio-summary-table-container">
    <table-lite
      id="lfx-portfolio-summary-document-table"
      :is-static-mode="true"
      :is-slot-mode="true"
      :has-checkbox="false"
      :is-loading="false"
      :is-re-search="false"
      :columns="columns"
      :rows="records"
    >
      <template v-for="(col, i) of columns" v-slot:[col.field]="data" :key="i">
        <template v-if="col.type === 'button' && data.value[col.field]">
          <LDBButton
            :key="data.value[col.field].name"
            :label="data.value[col.field].label"
            :action="data.value[col.field].action"
            :actionParams="data.value[col.field].actionParams"
            :params="data.value[col.field].params"
            :type="data.value[col.field].type"
            :viewGuards="data.value[col.field].guards"
            :enabledGuards="data.value[col.field].enabledGuards"
            :onSuccess="data.value[col.field].onSuccess"
          ></LDBButton>
        </template>
        <template v-else>{{ data.value[col.field] }}</template>
      </template>
    </table-lite>
  </div>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import TableLite from '@/views/general/TableLite.vue';


export default {
  name: 'LfxDownloadableDocuments',
  components: { TableLite },
  props: {},
  data() {
    return {documentType: 'workAround'};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),
    recordId() {
      return +this.$route.params?.accountId;
    },
    record() {
      return this.$store.state?.account?.[this.recordId] || {};
    },

    columns() {
      const columns = [
        {
          col: 'documentDescriptionColumn',
          field: 'documentDescription',
          label: 'Document',
          isKey: false,
          sortable: false,
        },
        {
          col: 'nameColumn',
          field: 'name',
          label: 'Name',
          isKey: false,
          sortable: false,
        },
        {
          col: 'createdDateColumn',
          field: 'createdDate',
          label: 'Created',
          isKey: false,
          sortable: false,
        },
        {
          col: 'linkExpiryTimeColumn',
          field: 'linkExpiryTime',
          label: 'Expiry',
          isKey: false,
          sortable: false,
        },
        {
          col: 'downloadDocumentColumn',
          field: 'downloadDocument',
          label: 'Request Download',
          isKey: false,
          sortable: false,
          type: 'button',
        }
      ];
      return columns;
    },
    records() {
      const documents = !!this.record?.savedDownloadAbleDocuments ? [...this.record?.savedDownloadAbleDocuments] : [];
      for (const document of documents) {
        let documentAction = 'downloadSavedAccountDownloadDocument';
        let actionParams = {savedDocumentId:document.id};
        if (this.downloadType === 'originalDesigb') {
          switch (document.documentType) {
            case 'accountConfirmation':
              documentAction = 'downloadAccountConfirmation';
              actionParams = {};
              break
            case 'accountConfirmation':
              documentAction = 'downloadStampedStatementFromCallback'
              actionParams = {
                documentId: document.id,
                documentCreated: document.createdDate
              };
              break
          }
        }
        document.downloadDocument = {
          key: 'download' + document.id,
          label: 'Download',
          action: documentAction,
          actionParams: actionParams,
          params: {accountId:this.record.id,bankAccountId:document.additionalParams.bankAccountId},
          
          type: 'primary',
          guards: true,
          enabledGuards: true,
          onSuccess: () => {
            console.log("downloadDocument")
          },
        };
      }
      return  documents
    }

  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
