<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <div v-if="mounted">
        <p><b>NOTE: This will reset all components of this client application back to 'new'. All progress on CRA, portfolio or other submission will be lost.<br/>
        Only continue if you are absolutely sure this is what you intend to do.</b></p>
        <LDBField
          v-for="(field, fieldname) in fields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="undefined"
          :record="{}"
          @change="on_change"
        />
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
        @on-api-success="onApiSuccess"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxApplicationHide',
  components: { LDBField },
  emits: ['on-api-success'],
  props: {},
  data() {
    return {
      mounted: true,
      record:{confirmResetApplicationStatus: false},
    };
  },
  created() {
  },
  mounted() {
  },
  beforeUnmount() {
  },
  computed: {
    ...mapGetters(['isTestingSdlc']),
    ...mapState(['account']),
    header() {
      return `Resend Application Portfolio Emails`;
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

        {
          name: 'resetApplicationStatus',
          label: 'Reset Application Statuses',
          type: 'primary',
          action: 'resetApplicationStatus',
          params: { accountId: +this.$route.params.accountId, applicationId: +this.$route.params.applicationId },
          actionParams: this.record,
          guards: true,
          enabledGuards: this.record.confirmResetApplicationStatus === true,
          clickEvent() {
            self.$router.replace({ name: 'clientApplication' });
          },
        }
      ];
      return allButtons !== undefined ? allButtons : [];
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : '/';
    },

    // ----- Fields -----

    fields() {
      const res = [{
          name: 'confirmResetApplicationStatus',
          label: `I confirm that I wish to reset this application's statuses.`,
          datatype: 'boolean',
          default: false,
          mandatory: true,
        }]
      return res
      ;
    },


    // ----- Account Record -----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },

    intermediaryRecord() {
      return this.account[this.accountRecord.intermediaryId]
    },  // ----- Application Record -----

    applicationId() {
      return +this.$route.params?.applicationId;
    },
    applicationRecord() {
      return this.clientRecord?.application?.[this.applicationId];
    },

    signatories() {
      return this.accountRecord?.applicationPortfolioSignatories
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Fields -----

    on_change({ field, value }) {
      this.record[field] = value;
      console.log("record",this.record)
    },

    // ----- API calls -----
    async onApiSuccess(_responseObject) {},

  },
};
</script>

<style></style>
