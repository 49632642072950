<template>
  <LFXListView :definition="definition" :records="records" />
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXListView from '@/views/general/LFXListView.vue';

export default {
  name: 'LfxPermissionsList',
  components: { LFXListView },
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters(['config']),
    ...mapState([]),
    permission() {
      return this.config.permission;
    },
    records() {
      return this.permission || {};
    },
    definition() {
      return getDefinition('permission');
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
