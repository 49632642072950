<template>
  <div class="ldb-field-container__date">
    <capitec-date-time-picker
      class="ldb-field-date"
      v-if="(view === 'edit' || view === 'create') && dateType === 'single'"
      :date="value"
      :label="`${fieldLabel}`"
      @apply-click="on_change"
      :minDate="minDate"
      :maxDate="maxDate"
      :error="validationError"
      :inputType="inputType"
      :type="dateType"
      :disabled="field.enableGuards === false"
      :mode="mode"
    />

    <capitec-date-time-picker
      class="ldb-field-date"
      v-if="(view === 'edit' || view === 'create') && dateType === 'range'"
      :startDate="value.startDate"
      :endDate="value.endDate"
      :label="`${fieldLabel}`"
      @apply-click="on_change"
      :minDate="minDate"
      :maxDate="maxDate"
      :error="validationError"
      :inputType="inputType"
      :type="dateType"
      :disabled="field.enableGuards === false"
      :mode="mode"
    />

    <LDBItemViewField
      class="ldb-field-date"
      v-else-if="view === 'item'"
      :view="view"
      :field="field"
      :displayValue="displayValue"
      :definition="definition"
      :record="record"
    />

    <LDBListViewField
      v-else-if="view === 'list'"
      :view="view"
      :field="field"
      :displayValue="displayValue"
      :definition="definition"
      :record="record"
    />

  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { ldb_field_mixin } from './ldb_field_mixin.js';
import LDBItemViewField from './LDBItemViewField.vue';
import LDBListViewField from './LDBListViewField.vue';

import { incrementDate, convertDateToCapitecFormat } from '@/sharedUtils/LdbTimeUtils';

export default {
  mixins: [ldb_field_mixin],
  components: {
    LDBItemViewField,
    LDBListViewField,
  },
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    value: {
      get() {
        return this.record[this.field.name];
      },
      set(val) {
        this.record[this.field.name] = val;
      },
    },
    dateType() {
      return this.field?.datatype?.datetime?.range === true || this.field?.datatype?.date?.range === true
        ? 'range'
        : 'single';
    },
    mode() {
      return this.field.datatype.datetime.type || 'date';
    },
    inputType() {
      return this.field.datatype.datetime.input || 'calendar';
    },
    minDate() {
      let res = '1900/01/01';

      if (this.view === 'edit' || this.view === 'create') {
        if (this.field.datatype.datetime.minDate !== undefined) {
          if (typeof this.field.datatype.datetime.minDate === 'function') {
            res = this.field.datatype.datetime.minDate(this.record);
          } else if (this.field.datatype.datetime.minDate.type) {
            let increment = this.field.datatype.datetime.minDate;
            let actualMinDate = incrementDate(increment);
            res = convertDateToCapitecFormat(actualMinDate);
          } else {
            res = this.field.datatype.datetime.minDate;
          }
        } else {
          // res = '1900-01-01T02:00:00.000Z'; // can't select 1 Jan 1900
          // res = '1900-01-01T00:00:00.000Z'; // can't select 1 Jan 1900
          // res = '1900-01-01T00:00:00.000+02:00'; // displays 1899 with no selectable dates, can select 1 Jan 1900
          // res = '1900/01/01, 04:00:00'; // can't select 1 Jan 1900
          // res = '1900/01/01, 02:00:00'; // can't select 1 Jan 1900

          // res = '1900/01/01, 00:00:00'; // WORKS
          res = '1900/01/01'; // WORKS
        }
      }

      // console.log('MIN', res);
      return res;
    },
    maxDate() {
      let res = '2100/12/31';

      if (this.view === 'edit' || this.view === 'create') {
        if (this.field.datatype.datetime.maxDate !== undefined) {
          if (typeof this.field.datatype.datetime.maxDate === 'function') {
            res = this.field.datatype.datetime.maxDate(this.record);
          } else if (this.field.datatype.datetime.maxDate.type) {
            let increment = this.field.datatype.datetime.maxDate;
            let actualMaxDate = incrementDate(increment);
            res = convertDateToCapitecFormat(actualMaxDate);
          } else {
            res = this.field.datatype.datetime.maxDate;
          }
        } else {
          let increment = { type: 'year', offset: 100 };
          let actualMaxDate = incrementDate(increment);
          res = convertDateToCapitecFormat(actualMaxDate);
        }
      }

      // console.log('MAX', res);
      return res;
    },
    dateValue() {
      if (this.value !== null) {
        return new Date(this.value);
      } else {
        return null;
      }
    },
    displayValue() {
      if (this.dateValue !== null) {
        if (this.mode === 'datetime') {
          return this.dateValue.toLocaleString('en-ZA').replaceAll(',', '');
        } else if (this.mode === 'date') {
          return this.dateValue.toLocaleString('en-ZA', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: '2-digit',
          });
        } else if (this.mode === 'time') {
          return this.dateValue.toLocaleString('en-ZA',{hour: '2-digit', minute:'2-digit'});
        }
      } else {
        return '';
      }
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    incMs(val) {
      let tokens = val.split('');
      let z = tokens[22];
      z = Number(`${z}`);
      z = z + 1;
      if (z > 9) {
        z = 0;
      }
      tokens[22] = `${z}`;
      return tokens.join('');
    },
    on_change(evt) {
      if (this.mode === 'time') {
        if (this.$dateType === 'range') {
          let fromStr = new Date(evt.detail.new.startDate).toISOString();
          let toStr = new Date(evt.detail.new.endDate).toISOString();
          this.$emit('change', { startDate: fromStr, endDate: toStr });
          this.$emit('blur', { startDate: fromStr, endDate: toStr });
        } 
        else 
        {
          let dateStr = evt.detail.new.date;
          if (dateStr === null) {
            dateStr = this.incMs(this.value);
          }
          dateStr = new Date(dateStr).toISOString();
          this.$emit('change', dateStr);
          this.$emit('blur', dateStr);
        }
      } else 
      if (this.dateType === 'range') {
        let fromStr = evt.detail.new.startDate;
        let toStr = evt.detail.new.endDate;
        this.$emit('change', { startDate: fromStr, endDate: toStr });
        this.$emit('blur', { startDate: fromStr, endDate: toStr });
      } else {
        let dateStr = evt.detail.new.date;
        if (dateStr === null) {
          dateStr = this.incMs(this.value);
        }
        this.$emit('change', dateStr);
        this.$emit('blur', dateStr);
      }
      
      // if (this.mode === 'date') {
        //   // let date = new Date(dateStr).toISOString().split('T')[0].replace('-', '/');
      //   this.$emit('blur',dateStr);
      //   this.$emit('change', dateStr);
      // } else {
      //   // let dateTime = new Date(dateStr).toISOString();
      //   this.$emit('blur',dateStr);
      //   this.$emit('change', dateStr);
      // }
      
    },
  },
};
</script>
<style>
.ldb-field-container__date {
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}

.ldb-field-date {
  flex: 1;
  --theme-date-time-picker-host-min-width: 360px;
  --theme-date-time-picker-host-max-width: 100%;
}
</style>
