<template>
  <template v-if="applicationLoaded">
    <LDBItemView 
      :record="applicationRecord"
      :definition="applicationDefinition"
      :view="view"
      :showNextButton="showNextButton"
      :action="undefined"
      :params="undefined"
    />
  </template>
  <template v-else>
    <LDBLoader/>
  </template>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxApplicationSummary',
  components: { LDBItemView,LDBLoader },
  props: {},
  emits: ['step-next'],
  data() {
    return {};
  },
  created() {
    if (!this.applicationLoaded) {
      doPermissionAction({}, 'getApplication', { accountId: this.accountId,applicationId: this.applicationId }, {})
    }
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    applicationLoaded() {
      return !!this.applicationRecord
    },

    // ----- Account record -----

    accountId() {
      return +this.$route.params.accountId;
    },
    accountRecord() {
      return this.account[this.accountId];
    },

    // ----- Application record ----

    applicationId() {
      return +this.$route.params.applicationId;
    },
    applicationRecord() {
      return this.accountRecord?.application?.[this.applicationId];
    },
    applicationDefinition() {
      return getDefinition('application')
    },

    // ----- View -----

    view() {
      return 'item';
    },

    // ----- Form Buttons -----

    showNextButton() {
      return false
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
  },
};
</script>
