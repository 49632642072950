<template>
  <router-link
    :style="`font-size:14px`"
    class="capitec-hyperlink"
    v-if="datatypeName === 'foreignKey' && renderLink !== false"
    :to="`${foreignKeyLink}`"
    tag="capitec-hyperlink"
    :label="displayValue"
  >
    {{ displayValue }}
  </router-link>

  <router-link
    :style="`font-size:14px`"
    class="capitec-hyperlink"
    v-else-if="datatypeName === 'id' && renderLink !== false"
    :to="`${recordLink}`"
    tag="capitec-hyperlink"
    @click="$emit('click', $event)"
    :label="displayValue"
  >
    {{ displayValue }}
  </router-link>
  <capitec-hyperlink
    :style="`font-size:14px`"
    class="capitec-hyperlink"
    v-else-if="datatypeName === 'text' && renderFieldLink !== false && renderedFieldLink"
    :href="`${renderedFieldLink}`"
    target="_blank"
    tag="capitec-hyperlink"
    @click="$emit('click', $event)"
    :label="displayValue"
  >
    {{ displayValue }}
  </capitec-hyperlink>

  <capitec-tag
    v-else-if="datatypeName === 'option' && displayValue"
    :label="optionLabel"
    v-bind="optionType"
    :style="optionStyle"
  ></capitec-tag>
  <capitec-tag v-else-if="displayAsTag" :label="`${displayValue}`" v-bind="tagType"></capitec-tag>
  <capitec-label v-else-if="datatypeName === 'boolean'" :label="displayValue"></capitec-label>

  <template v-else-if="datatypeName === 'currency'">
    <capitec-label class="ldb-list-view-currency-label" :label="displayValue" />
    <template v-for="(l, i) in displayValue" :key="i">
      <capitec-label
        v-if="l === '.' || l === ',' || l === ' '"
        class="ldb-single-letter-label thin"
        :key="i"
        :label="l"
      />
      <capitec-label v-else class="ldb-single-letter-label" :label="l" />
    </template>
  </template>

  <template v-else-if="datatypeName === 'datetime' || datatypeName === 'date'">
    <capitec-label class="ldb-list-view-datetime-label" :label="displayValue"></capitec-label>
    <template v-for="(l, i) in displayValue" :key="i">
      <capitec-label v-if="l === ':' || l === ' '" class="ldb-single-letter-label thin" :label="l" />
      <capitec-label v-else class="ldb-single-letter-label" :label="l" />
    </template>
  </template>

  <capitec-label v-else-if="displayValue !== null && displayValue !== undefined" :label="displayValue"> </capitec-label>
</template>

<script>
import { ldb_field_mixin } from './ldb_field_mixin.js';

export default {
  emits: ['click'],
  mixins: [ldb_field_mixin],
  components: {},
  props: {
    displayValue: {
      type: String,
      required: true,
    },
    renderLink: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      editInPlace: false,
      editInPlaceValue: '',
    };
  },
  created() {},
  mounted() {
    this.editInPlaceValue = this.displayValue;
  },
  computed: {
    datatype() {
      return this.field.datatype;
    },
    foreignDefinitionName() {
      return this.field.datatype.foreignKey.linkTable.split('').splice(3).join('').toLowerCase();
    },

    foreignKeyLink() {
      return this.foreignRecord?.link === undefined
        ? `/${this.definition.name}/${this.record.id}`
        : `${this.foreignRecord.link}`;
    },

    allOptions() {
      let foreignKeyCollection = this.field.datatype.foreignKey.collection;
      let allForeignRecords = this.record[foreignKeyCollection];
      return allForeignRecords;
    },

    foreignRecord() {
      let res = undefined;
      if (this.allOptions !== undefined && this.allOptions[this.value] !== undefined) {
        res = this.allOptions[this.value];
      }
      return res;
    },

    recordLink() {
      return this.record.link === undefined ? `/${this.definition.name}/${this.record.id}` : `${this.record.link}`;
    },

    optionStyle() {
      return { position: 'relative', 'z-index': '0' };
    },
    optionLabel() {
      let res = '';
      for (let o = 0; o < this.datatype.option.options.length; o++) {
        let option = this.datatype.option.options[o];
        if (option.id === this.displayValue) {
          res = option.name;
          break;
        }
      }
      return res;
    },

    optionType() {
      let tagType;
      for (let o = 0; o < this.datatype.option.options.length; o++) {
        let option = this.datatype.option.options[o];
        if (option.id === this.displayValue) {
          tagType = option.tagType;
          break;
        }
      }
      return this.propertiesForTagType(tagType);
    },
    displayAsTag() {
      if (typeof this.field.displayType?.displayAsTag === 'string') {
        return this.record[this.field.displayType?.displayAsTag];
      }
      return !!this.field.displayType?.displayAsTag;
    },
    tagStyle() {
      let tagType = undefined;
      if (this.field.displayType?.tagTypeFunctionName) {
        tagType = this.record[this.field.displayType.tagTypeFunctionName];
      }
      return tagType;
    },
    tagType() {
      return this.propertiesForTagType(this.tagStyle);
    },
    renderFieldLink() {
      return !!this.field.displayType?.renderLink;
    },
    renderedFieldLink() {
      return this.record[this.field.name + 'Link'];
    },

    datatypeName() {
      let dt = this.datatype;
      if (typeof dt === 'string') {
        return dt;
      } else if (typeof dt === 'object') {
        return Object.keys(dt)[0];
      }
    },
  },
  methods: {
    propertiesForTagType(tagType) {
      if (!tagType) {
        return { type: 'custom', 'label-color': '#7C7C7C', color: '#F5F5F5' };
      }
      if (tagType.graph) {
        let tag = { type: 'custom', 'label-color': '', color: '' };
        switch (tagType.graph) {
          // 'green' | 'blue' | 'lightGreen' | 'purple' | 'magenta' | 'lightOrange' | 'orange' | 'yellow'
          case 'green':
            tag['label-color'] = '#71B62C';
            tag.color = '#71B62C26';
            break;
          case 'orange':
            tag['label-color'] = '#F63E02';
            tag.color = '#F63E0226';
            break;
          // TODO implement other graph tags
          default:
            tag['label-color'] = '#7C7C7C';
            tag.color = '#F5F5F5';
        }
        return tag;
      }
      if (tagType.custom) {
        return { type: 'custom', 'label-color': tagType.custom.labelColor, color: tagType.custom.color };
      }
      if (tagType === 'cancelled') {
        return { type: 'custom', 'label-color': '#FFFFFF', color: '#AAAAAA' };
      }
      return { type: tagType };
    },

    escapeInlineEdit() {
      this.editInPlace = false;
    },
    enterInlinEdit() {
      this.editInPlace = false;
    },
    onKeyDown(evt) {
      const KEY_ESCAPE = 27;
      const KEY_ENTER = 13;
      const KEY_TAB = 9;
      if (evt.keyCode === KEY_ESCAPE) {
        this.escapeInlineEdit();
      } else if (evt.keyCode === KEY_ENTER || evt.keyCode === KEY_TAB) {
        this.enterInlinEdit();
      }
    },
    doInPlaceEdit() {
      let self = this;
      this.editInPlace = true;
      setTimeout(function () {
        self.$refs.ldb_list_view_text_input.focus();
      }, 100);
    },
  },
};
</script>

<style>
.capitec-hyperlink {
  color: var(--theme-hyperlink-color);
}

.tag-label {
  /* border: 1px solid red; */
}

.ldb-list-view-text-field {
  /* border: 1px solid red; */
  min-height: 30px;
  position: relative;
}

.ldb-list-view-text-input {
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  max-width: 100%;
  outline: none;
  inset: none;
  border: none;
}

.ldb-list-view-datetime-label,
.ldb-list-view-currency-label {
  text-align: right;
  font-family: 'Courier';
  letter-spacing: -0.1em;
  font-size: 0.8em;
  white-space: nowrap;
  position: absolute;
  color: transparent;
  display: flex;
}

.ldb-single-letter-label.thin {
  max-width: 3px;
  min-width: 3px;
}

.ldb-single-letter-label {
  min-width: 6px;
  min-width: 6px;
  text-align: center;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
