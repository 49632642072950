<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <div v-if="mounted">
        <!-- <ol> -->
        <p>This will update the Settlement Accounts of the deal and may change the Transaction Type of the Transaction File.</p>
        <!-- </ol> -->
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="definition"
          :record="record"
          @change="on_change"
        />
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
        @on-api-success="onApiSuccess"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxDealupdateSettlementAccount',
  components: { LDBField },
  emits: ['on-api-success'],
  props: {},
  data() {
    return {
      mounted: true,
    };
  },
  created() {},
  mounted() {},
  beforeUnmount() {
    this.record.revert();
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['deal','account']),
    header() {
      return `Update Deal Settlement Account`;
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

        {
          name: 'updateDealSettlementAccount',
          label: 'Update',
          type: 'primary',
          action: 'updateDealSettlementAccount',
          params: {
            transactionId: this.record.transactionFileId,
            dealId: this.record.id,
          },
          actionParams: this.record,
          guards: true,
          enabledGuards: !!this.record.fromSettlementAccountId || !!this.record.toSettlementAccountId,
          clickEvent() {
            self.$router.replace({ name: 'transactionDealDetailsView' });
          },
        },
      ];
      return allButtons !== undefined ? allButtons : [];
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : '/';
    },

    // ----- Definition -----

    definition() {
      return getDefinition('deal');
    },

    // ----- Record -----
    recordName() {
      return 'deal';
    },
    recordId() {
      return +this.$route.params.dealId;
    },
    record() {
      let rec = this.$store.state?.deal[this.recordId];
      return rec;
    },

    // ----- Account record -----

    accountId() {
      return +this.$route.params.accountId;
    },
    accountRecord() {
      return this.account[this.accountId];
    },

    // ----- Fields -----

    viewFields() {
      let res = [
      {
          name: 'fromSettlementAccountId',
          label: 'From Settlement Account',
          datatype: {
            option: {
              optionType: 'text',
              options: this.fromSettlementAccountOptions
            },
          },
          default: null,
          mandatory: false,
        },
         {
          name: 'toSettlementAccountId',
          label: 'To Settlement Account',
          datatype: {
            option: {
              optionType: 'text',
              options: this.toSettlementAccountOptions
            },
          },
          default: null,
          mandatory: false,
        },
      ];
      return res;
    },
    fromSettlementAccounts() {
      if (!this.record.currencyPair || !this.record.currency || !this.record.action) {
        return []
      }
      let accountCurrency;
      if (this.record.action === 'Buy') {
        accountCurrency = this.record.counterCurrency
      } else {
        accountCurrency = this.record.currency
      }
      return this.accountRecord.filter_bankAccounts(
        undefined,
        {
          currency: accountCurrency,
          includeAuthUserBankAccounts: false,
          bankAccountTypes: 'own',
          status: undefined,
        })
    },
    toSettlementAccounts() {
      if (!this.record.currencyPair || !this.record.currency || !this.record.action) {
        return []
      }
      let accountCurrency;
      if (this.record.action === 'Buy') {
        accountCurrency = this.record.currency
      } else {
        accountCurrency = this.record.counterCurrency
      }
      return this.accountRecord.filter_bankAccounts(
        undefined,
        {
          currency: accountCurrency,
          includeAuthUserBankAccounts: false,
          bankAccountTypes: 'own',
          status: undefined,
        })
    },
    fromSettlementAccountOptions() {
      const res = [{id:null,name:"NOSTRO ACCOUNT"}];
      for (const bankAccountId in this.fromSettlementAccounts) {
        res.push({id:+bankAccountId,name:this.fromSettlementAccounts[bankAccountId].accountNumber})
      }
      return res
    },
    toSettlementAccountOptions() {
      const res = [{id:null,name:"NOSTRO ACCOUNT"}];
      for (const bankAccountId in this.toSettlementAccounts) {
        res.push({id:+bankAccountId,name:this.toSettlementAccounts[bankAccountId].accountNumber})
      }
      return res
    },

  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    on_change({ field, value }) {
      this.record[field] = value;
    },

    // ----- API calls -----
    async onApiSuccess(_responseObject) {},
  },
};
</script>

<style></style>
