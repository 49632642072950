//@ts-nocheck
import VueStore from 'vue-class-store';

import { LfxInwardSwiftLogInt, LfxInwardSwiftLogDef } from '@/definitions/LfxInwardSwiftLogDef';

import { LfxModel, LfxDefinition } from './LfxModel';

@VueStore
class LfxInwardSwiftMdl extends LfxDefinition {
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
export class LfxInwardSwiftLog extends LfxModel {
  constructor(data, state) {
    super({definition_name:'inwardSwiftLog',...data}, state);
  }
  get link() {
    // @ts-expect-error
    return `/swift/${this.inwardSwiftId}/log/${this.id}`;
  }
  get displayFKLink() {
    return false
  }

}

export function Definition(context, name: string) {
  return new LfxInwardSwiftMdl(LfxInwardSwiftLogDef, LfxInwardSwiftLog, context, name, {});
}
