<template>
  <LDBItemView
    :record="beneficiaryRecord"
    :view="view"
    :showNextButton="showNextButton"
    action="updateBeneficiary"
    :params="{ accountId: accountId, beneficiaryId: beneficiaryId }"
    @on-save="onSaveBeneficiary"
    @on-next="onNext"
  />

  <router-view></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';

export default {
  name: 'LfxBeneficiaryDetails',
  components: { LDBItemView },
  props: {},
  emits: ['step-next'],
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    // ----- Account record -----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },

    // ----- Beneficiary record ----

    beneficiaryId() {
      return +this.$route.params?.beneficiaryId;
    },
    beneficiaryRecord() {
      return this.accountRecord?.beneficiaries?.[this.beneficiaryId];
    },
    beneficiaryDefinition() {
      // return getDefinition(this.markupRecord.definition_name);
      // return this.beneficiaryRecord.definition;
      return getDefinition('beneficiary');
    },

    // ----- View -----

    view() {
      return this.$route.meta?.content?.view ? this.$route.meta.content.view : 'item';
    },

    // ----- Form Buttons -----

    showNextButton() {
      return this.$route.meta?.content?.formButtons?.showNextButton;
    },
  },
  // watch: {
  //   // view(newView, oldView) {
  //   //   if (this.$route.meta?.content?.view === 'edit') {
  //   //     this.record.editing = true;
  //   //   } else {
  //   //     this.record.editing = false;
  //   //   }
  //   // },
  // },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    async onSaveBeneficiary({ fields }) {
      // let response = await this.saveBeneficiary(fields);
      // if (response !== undefined && this.$route.matched[0].name === 'beneficiary') {
      this.$router.replace({ name: 'beneficiaryDetailsView' });
      // }
    },
    async onNext({ fields }) {
      let response = await this.saveBeneficiary(fields);
      if (true) {
        // TODO check success response
        this.$emit('step-next');
      }
    },

    // async saveBeneficiary(fields) {
    //   this.showLoader(true);
    //   let record = this.beneficiaryRecord;
    //   let response = await this.$store.dispatch('db_update_document', { record, fields });
    //   this.showLoader(false);
    //   return response;
    // },
  },
};
</script>
