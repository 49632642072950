<template>
  <div class="confirm">
    <h1>Confirm Signup</h1>
  </div>
  <form @submit.prevent="submitConfirmation">
    <div>
      <label for="username">Username: </label>
      <input id="username" type="text" v-model="username" />
    </div>
    <div>
      <label for="confirmation-code">Confirmation Code: </label>
      <input id="confirmation-code" type="tel" v-model="confirmationCode" />
    </div>
    <div>
      <button>Confirm Signup</button>
    </div>
  </form>
  <p>{{ message }}</p>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      confirmationCode: "",
      message: "",
    };
  },
  methods: {
    async submitConfirmation() {
      const formData = {
        username: this.username,
        confirmationCode: this.confirmationCode,
      };
      const responseData = await this.$store.dispatch(
        "submitConfirmation",
        formData
      );
      this.message = responseData;
    },
  },
};
</script>
