<template>
  <template v-if="totalItems > 0">
  <div  class="lfx-widget-spacer"></div>
  <div  ref="scroller" class="lfx-widget-scroller">
  <capitec-icon class="widget-page-scroller" :class="currentPage === 1 ? 'first-page' : '' " icon="system/chevron-left-action"  size="small" @click="onPageDotClick(currentPage - 1)" />
  <div  v-for="page in pages" :key="page" class="page-dot-wrapper"  @click="onPageDotClick(page)">
  <div  :class="`page-dot ${page === currentPage ? 'current' : ''}`" ></div>
  </div>
  <capitec-icon class="widget-page-scroller" :class="currentPage === pages ? 'last-page' : '' " icon="system/chevron-right-action"  size="small" @click="onPageDotClick(currentPage + 1)" />  
  <div class="widget-page-number">{{currentPage}}/{{pages}}</div>
  </div>
  </template>
</template>


<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

export default {
  emits:['on-page'],
  components: {},
  props: {  
    totalItems:{type:Number,required:true},
    itemsPerPage:{type:Number,required:true},
    currentPage:{type:Number,required:true},
  },
  data() {
    return {
      
    }
  },
  created() {

  },
  mounted() {

  },
  computed:{
    ...mapGetters([]),
  	...mapState([]),
    pages() {
      if (this.totalItems === 0) {
        return 0;
      }
      let pages = this.totalItems / this.itemsPerPage;
      let round =  Math.floor(this.totalItems / this.itemsPerPage);
      return (round == pages) ? round : round + 1;
    }
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([]),
    onPageDotClick(page) {
      this.$emit('on-page',page);
    }
  }
 }
</script>
<style>

div.lfx-widget-scroller{
  display:flex;
  align-items:center;
  justify-content: center;
  border-top:1px solid #EEE;
  padding-top:10px;
  padding-bottom:10px;
  position:relative;
}

div.lfx-widget-spacer{
  flex-grow: auto;
}

div.page-dot-wrapper{
  flex:1;
  max-width: 8px;
  min-height: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  overflow: visible;
}

div.page-dot{
  --size:4px;
  min-height: var(--size);
  max-height: var(--size);
  min-width: var(--size);
  max-width: var(--size);
  border: 1px solid var(--theme-status-info-background);
  border-radius: var(--size);
  cursor:pointer;
}

div.page-dot.current{
  background-color:var(--theme-status-info-background);
  border: 1px solid var(--theme-status-info-background);
}

.widget-page-scroller{
  cursor: pointer;
  transform:scale(0.7);
}

.widget-page-scroller.first-page,
.widget-page-scroller.last-page{
  pointer-events: none;
  filter:grayscale(100);
}

.widget-page-number{
  position:absolute;
  right: 5px;
  color:#CCC;
  font-size:12px;
  user-select: none;
  pointer-events: none;
}

</style>


