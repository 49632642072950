<template>
  <LDBModal result="info" :header="`${header}`" :size="modalSize" :closable="closable">
    <template #body>
      <template v-if="routeName === 'transactionPaymentDetailsViewAddPayment'">
        <h1>Add a New Payment</h1>
        <p>The new payment amount will be deducted from the Balance Payment.</p>
        <p>The new Balance Payment amount, as indicated below, must be greater than zero.</p>
        <LDBField
          v-for="field in newPaymentRecord_viewFields"
          :key="field.name"
          :field="field"
          :view="view"
          :fieldname="field.name"
          :definition="definition"
          :record="newPaymentRecord"
          @change="on_change_newPaymentRecord"
        />
        <LDBField
          v-for="field in balancePayment_viewFields"
          :key="field.name"
          :field="field"
          :view="view"
          :fieldname="field.name"
          :definition="definition"
          :record="balancePaymentRecord"
        />
      </template>
      <template v-else-if="routeName === 'transactionPaymentDetailsViewEditAmount'">
        <h1>Edit this Payment Amount</h1>
        <p>The difference between the new and old amounts will be added or deducted from the Balance Payment.</p>
        <p>The new Balance Payment amount, as indicated below, must be greater than zero.</p>
        <LDBField
          v-for="field in payment_viewFields"
          :key="field.name"
          :field="field"
          :view="view"
          :fieldname="field.name"
          :definition="definition"
          :record="paymentRecord"
          @change="on_change_editPaymentAmount"
        />
        <LDBField
          v-for="field in balancePayment_viewFields"
          :key="field.name"
          :field="field"
          :view="view"
          :fieldname="field.name"
          :definition="definition"
          :record="balancePaymentRecord"
        />
      </template>
      <template v-else-if="routeName === 'transactionPaymentDetailsViewCancelPayment'">
        <h1>Delete this Payment</h1>
        <p>The deleted payment amount will be added to the Balance payment as indicated below.</p>
        <LDBField
          v-for="field in balancePayment_viewFields"
          :key="field.name"
          :field="field"
          :view="view"
          :fieldname="field.name"
          :definition="definition"
          :record="balancePaymentRecord"
        />
      </template>
    </template>

    <template #footer>
      <capitec-spacer />
      <LDBButton
        v-for="button in rightButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :viewGuards="button.viewGuards"
        :enabledGuards="button.enabledGuards"
        :tooltip="button.tooltip"
        @click="button.clickEvent"
      >
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import lodash from 'lodash';
const merge = lodash.merge;

export default {
  name: 'LfxManagePayments',
  components: {},
  props: {},
  data() {
    return {
      newPaymentRecord: {
        // paymentCurrency: null,
        // paymentAmount: 0,
      },
    };
  },
  created() {
    this.newPaymentRecord = this.definition.class(
      {
        ...this.definition.default_values,
        collection_path: '/payment',
        definition_name: 'payment',
        Table: 'LfxPayment',
        paymentCurrency: this.transactionRecord.paymentCurrency,
      },
      this.$store
    );


    // this.newPaymentRecord.paymentCurrency = this.transactionRecord.paymentCurrency;

    if (this.routeName === 'transactionPaymentDetailsViewCancelPayment') {
      this.balancePaymentRecord.paymentAmount =
        this.balancePaymentRecord.paymentAmount + this.paymentRecord.paymentAmount;
    }
  },
  mounted() {},
  beforeUnmount() {
    this.paymentRecord.revert();
    this.balancePaymentRecord.revert();
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['transaction', 'payment']),
    //
    // ----- Modal -----

    header() {
      switch (this.$route.name) {
        case 'transactionPaymentDetailsViewAddPayment':
          return 'Add Payment to Transaction File';
        case 'transactionPaymentDetailsViewEditAmount':
          return 'Edit Payment Amount';
        case 'transactionPaymentDetailsViewCancelPayment':
          return 'Delete Payment';
      }
    },
    modalSize() {
      return 'large'; // TODO
    },
    closable() {
      return false;
    },

    // ----- Route -----

    routeName() {
      return this.$route.name;
    },

    // ----- Transaction Record -----

    transactionId() {
      return +this.$route.params?.transactionId;
    },
    transactionRecord() {
      return this.transaction?.[this.transactionId];
    },

    // ----- Payment Record -----

    paymentId() {
      return +this.$route.params?.paymentId;
    },
    paymentRecord() {
      return this.payment?.[this.paymentId];
    },

    definition() {
      return getDefinition('payment');
    },

    // ----- Balance Payment Record -----

    balancePaymentId() {
      return +this.transactionRecord?.balancePaymentId;
    },
    balancePaymentRecord() {
      return this.payment?.[this.balancePaymentId];
    },

    // ----- Form -----

    view() {
      return 'edit';
    },

    // Balance payment

    balancePayment_fields() {
      return this.balancePaymentRecord.guarded_fields;
    },

    balancePayment_viewFieldNames() {
      return ['paymentAmount'];
    },

    balancePayment_viewFields() {
      const res = [];
      for (const fieldName of this.balancePayment_viewFieldNames) {
        const fields = this.balancePayment_fields.filter((field) => field.field === fieldName);
        if (fields.length > 0) {
          const updatedField = this.balancePayment_overrideField(fields[0]);
          res.push(updatedField);
        }
      }
      return res;
    },

    // Payment

    payment_fields() {
      return this.paymentRecord.guarded_fields;
    },

    payment_viewFieldNames() {
      return ['paymentAmount'];
    },

    payment_viewFields() {
      const res = [];
      for (const fieldName of this.payment_viewFieldNames) {
        const fields = this.payment_fields.filter((field) => field.field === fieldName);
        if (fields.length > 0) {
          const updatedField = this.payment_overrideField(fields[0]);
          res.push(updatedField);
        }
      }
      return res;
    },

    // New Payment

    newPaymentRecord_fields() {
      return this.newPaymentRecord.guarded_fields;
    },

    newPaymentRecord_viewFieldNames() {
      return ['paymentAmount'];
    },

    newPaymentRecord_viewFields() {
      const res = [];
      for (const fieldName of this.newPaymentRecord_viewFieldNames) {
        const fields = this.newPaymentRecord_fields.filter((field) => field.field === fieldName);
        if (fields.length > 0) {
          const updatedField = this.newPaymentRecord_overrideField(fields[0]);
          res.push(updatedField);
        }
      }
      return res;
    },

    // ----- Buttons -----

    rightButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          action: 'click',
          guards: true,
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'submit',
          label: this.submitLabel,
          type: 'primary',
          action: this.submitAction,
          guards: true,
          enabledGuards: this.submitEnableGuards, // TODO
          params: this.submitParams,
          actionParams: this.submitActionParams,
          clickEvent(response) {
            if (response.status === 'success') {
              self.$router.go(-1);
            }
          },
        },
      ];
      return allButtons;
    },

    submitLabel() {
      switch (this.$route.name) {
        case 'transactionPaymentDetailsViewAddPayment':
          return 'Add Payment';
        case 'transactionPaymentDetailsViewEditAmount':
          return 'Edit Amount';
        case 'transactionPaymentDetailsViewCancelPayment':
          return 'Delete Payment';
      }
    },
    submitAction() {
      switch (this.$route.name) {
        case 'transactionPaymentDetailsViewAddPayment':
          return 'addTransactionFilePayment';
        case 'transactionPaymentDetailsViewEditAmount':
          return 'editTransactionFilePaymentAmount';
        case 'transactionPaymentDetailsViewCancelPayment':
          return 'deleteTransactionFilePayment';
      }
    },
    submitParams() {
      switch (this.$route.name) {
        case 'transactionPaymentDetailsViewAddPayment':
          return { transactionId: this.transactionId };
        case 'transactionPaymentDetailsViewEditAmount':
        case 'transactionPaymentDetailsViewCancelPayment':
          return { transactionId: this.transactionId, paymentId: this.paymentId };
      }
    },
    submitEnableGuards() {
      switch (this.$route.name) {
        case 'transactionPaymentDetailsViewAddPayment':
          return this.balancePaymentRecord.paymentAmount > 0 &&
            this.newPaymentRecord.paymentAmount > 0 &&
            this.balancePaymentRecord.changed_fields.find((field) => field.name === 'paymentAmount')
            ? true
            : false;
        case 'transactionPaymentDetailsViewEditAmount':
          return this.balancePaymentRecord.paymentAmount > 0 &&
            this.paymentRecord.paymentAmount > 0 &&
            this.paymentRecord.changed_fields.find((field) => field.name === 'paymentAmount') &&
            this.balancePaymentRecord.changed_fields.find((field) => field.name === 'paymentAmount')
            ? true
            : false;
        case 'transactionPaymentDetailsViewCancelPayment':
          return true;
      }
    },
    submitActionParams() {
      switch (this.$route.name) {
        case 'transactionPaymentDetailsViewAddPayment':
          return { paymentAmount: this.newPaymentRecord.paymentAmount };
        case 'transactionPaymentDetailsViewEditAmount':
          return { paymentAmount: this.paymentRecord.paymentAmount };
        case 'transactionPaymentDetailsViewCancelPayment':
          return {};
      }
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Fields -----

    // Balance payment

    balancePayment_overrideField(field) {
      if (['paymentAmount'].includes(field.name)) {
        const startingField = merge({}, field);
        const overrideObject = this.getFieldOverRideObject_balancePayment(field);
        const newField = merge(startingField, overrideObject);
        return newField;
      } else {
        return field;
      }
    },
    getFieldOverRideObject_balancePayment(field) {
      switch (field.name) {
        case 'paymentAmount':
          return {
            label: 'New Balance Payment Amount',
            datatype: { currency: { symbol: this.balancePaymentRecord?.paymentCurrency || '' } },
            enableGuards: false,
          };
      }
    },

    // Payment

    payment_overrideField(field) {
      if (['paymentAmount'].includes(field.name)) {
        const startingField = merge({}, field);
        const overrideObject = this.getFieldOverRideObject_payment(field);
        const newField = merge(startingField, overrideObject);
        return newField;
      } else {
        return field;
      }
    },
    getFieldOverRideObject_payment(field) {
      switch (field.name) {
        case 'paymentAmount':
          return {
            label: 'New Payment Amount',
            datatype: { currency: { symbol: this.paymentRecord?.paymentCurrency || '' } },
            enableGuards: true,
          };
      }
    },

    // New Payment

    newPaymentRecord_overrideField(field) {
      if (['paymentAmount'].includes(field.name)) {
        const startingField = merge({}, field);
        const overrideObject = this.getFieldOverRideObject_newPaymentRecord(field);
        const newField = merge(startingField, overrideObject);
        return newField;
      } else {
        return field;
      }
    },
    getFieldOverRideObject_newPaymentRecord(field) {
      switch (field.name) {
        case 'paymentAmount':
          return {
            label: 'New Payment Amount',
            datatype: { currency: { symbol: this.newPaymentRecord?.paymentCurrency || '' } },
            enableGuards: true,
          };
      }
    },

    // ----- Changes -----

    on_change_newPaymentRecord({ field, value }) {
      this.newPaymentRecord[field] = value;
      this.balancePaymentRecord.paymentAmount =
        this.balancePaymentRecord.data.paymentAmount - this.newPaymentRecord.paymentAmount;
    },

    // on_change_addPaymentAmount({ field, value }) {
    //   this.newPaymentRecord.paymentAmount = value;
    //   this.balancePaymentRecord.paymentAmount =
    //     this.balancePaymentRecord.data.paymentAmount - this.newPaymentRecord.paymentAmount;
    //   // this.paymentRecord[field] = value;
    //   // if (field === 'signatoryOption') {
    //   //   this.populateSignatoryPool();
    //   // }
    // },

    on_change_editPaymentAmount({ field, value }) {
      this.paymentRecord[field] = value;
      this.balancePaymentRecord.paymentAmount =
        this.balancePaymentRecord.data.paymentAmount -
        (this.paymentRecord.paymentAmount - this.paymentRecord.data.paymentAmount);
    },

    // tempClick() {
    //   this.newPaymentRecord.paymentAmount += 500;
    //   this.balancePaymentRecord.paymentAmount =
    //     this.balancePaymentRecord.data.paymentAmount - this.newPaymentRecord.paymentAmount;
    // },
    // tempClickTwo() {
    //   this.paymentRecord.paymentAmount += 500;
    //   this.balancePaymentRecord.paymentAmount =
    //     this.balancePaymentRecord.data.paymentAmount -
    //     (this.paymentRecord.paymentAmount - this.paymentRecord.data.paymentAmount);
    // },
  },
};
</script>
