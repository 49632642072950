<template>
  <template v-if="noErrorToDisplay"><slot /></template>
  <template v-else><p>An error occured.</p></template>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LDBErrorHandler',
  TODO: ['actually implement something here!'],

  errorCaptured(err) {
    console.warn('Caught error:', err);
    return false;
  },

  computed: {
    ...mapGetters(['isDevelopment']),

    noErrorToDisplay() {
      return true;
      // return this.isDevelopment ? false : true;
    },
  },

  methods: {},
};
</script>
