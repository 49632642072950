<template>
  <div class="ldb-field-container" :class="view">
    <component
      :is="component"
      :formSubmitted="formSubmitted"
      :view="view"
      :field="field"
      :record="record"
      :overrideOptions="overrideOptions"
      :overrideOptionsSearchBarNumber="overrideOptionsSearchBarNumber"
      :definition="definition"
      :validationError="`${validationError}`"
      @blur="on_blur"
      @focus="on_focus"
      @change="on_change"
    />

    <LDBButton v-if="showButton" class="ldb-field-button" :icon="fieldButtonIcon" @click="fieldButtonClick"> </LDBButton>

    <slot v-else name="button" />

    <div v-if="showHelp" class="ldb-field-help">
      <capitec-tooltip :message="field.help" position="left" :opened="false">
        <capitec-icon class="ldb-field-help-button" icon="material/help_outline" />
      </capitec-tooltip>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import LDBFieldText from './LDBFieldText.vue';
import LDBFieldOption from './LDBFieldOption.vue';
import LDBFieldDate from './LDBFieldDate.vue';
import LDBFieldForeignKey from './LDBFieldForeignKey.vue';
import LDBFieldBoolean from './LDBFieldBoolean.vue';
import LDBFieldId from './LDBFieldId.vue';
import LDBFieldInteger from './LDBFieldInteger.vue';
import LDBFieldFloat from './LDBFieldFloat.vue';
import LDBFieldPercentage from './LDBFieldPercentage.vue';
import LDBFieldCurrency from './LDBFieldCurrency.vue';
import LDBFieldForeignKeyList from './LDBFieldForeignKeyList.vue';
import LDBFieldMemo from './LDBFieldMemo.vue';
import LDBFieldAction from './LDBFieldAction.vue';
import LDBToDo from './LDBToDo.vue';
import LDBFieldJSONGeneric from './LDBFieldJSONGeneric.vue';

const FIELD_COMPONENTS = {
  id: 'LDBFieldId',
  text: 'LDBFieldText',
  integer: 'LDBFieldInteger',
  float: 'LDBFieldFloat',
  percentage: 'LDBFieldPercentage',
  datetime: 'LDBFieldDate',
  option: 'LDBFieldOption',
  foreignKey: 'LDBFieldForeignKey',
  boolean: 'LDBFieldBoolean',
  currency: 'LDBFieldCurrency',
  memo: 'LDBFieldMemo',
  json: 'LDBFieldJSONGeneric',
  uuid: 'LDBFieldText',
  action: 'LDBFieldAction'
};

const he = require('he');

export default {
  components: {
    LDBFieldText,
    LDBFieldOption,
    LDBFieldDate,
    LDBFieldForeignKey,
    LDBFieldBoolean,
    LDBFieldInteger,
    LDBFieldId,
    LDBFieldMemo,
    LDBFieldCurrency,
    LDBFieldFloat,
    LDBFieldPercentage,
    LDBFieldForeignKeyList,
    LDBFieldJSONGeneric,
    LDBFieldAction,
    LDBToDo,
  },
  props: {
    view: {
      type: String,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    fieldname: {
      type: String,
      required: true,
    },
    definition: {
      type: Object,
      required: false,
    },
    formSubmitted: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      required: true,
    },
    overrideOptions: {
      type: Object,
      required: false,
    },
    overrideOptionsSearchBarNumber: {
      type: [Number, Boolean],
      required: false,
      default: false,
    },
  },
  data() {
    return {
      validations: null,
      validationError: '',
      currentValue: '',
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    showHelp() {
      return (this.view === 'edit' || this.view === 'create') && this.field.help !== '' && this.field.help !== undefined;
    },
    showButton() {
      // if (this.field.button !== undefined) {
      //   console.log(this.field.button.views?.[this.view], this.field.button.views?.customViews?.[this.view]);
      // }
      return (
        this.field.button !== undefined &&
        (this.field.button.views?.[this.view] || this.field.button.views?.customViews?.[this.view])
      );
    },
    fieldButtonIcon() {
      return this?.field?.button?.icon ? this?.field?.button?.icon : 'system/edit-action';
    },
    // fieldButtonLabel() {
    //   // CSS doesn't work for adding label
    //   return this?.field?.button?.label ? this?.field?.button?.icon : 'system/edit-action';
    // },
    // fieldButtonTooltip() {
    // //   // CSS doesn't work for adding label
    //   return this?.field?.button?.tooltip;
    // },
    component() {
      if (this?.field?.displayType?.component !== undefined) {
        return this.field.displayType.component;
      } else if (this?.field?.datatype?.json?.component !== undefined) {
        return this?.field?.datatype?.json?.component;
      } else if (FIELD_COMPONENTS[this?.field?.displayType?.datatype] !== undefined) {
        return FIELD_COMPONENTS[this?.field?.displayType?.datatype];
      } else if (FIELD_COMPONENTS[this.datatypeName] !== undefined) {
        return FIELD_COMPONENTS[this.datatypeName];
      } else {
        return 'LDBToDo';
      }
    },
    // fieldValidationError() {
    //   let res = [];
    //   console.log('this.record?.failed_validations?:',this.record,this.record?.validation_errors);
    //   if (this.record?.validation_errors?.[this.field?.name] !== undefined)  {
    //     for (let v in this.record?.validation_errors?.[this.field?.name]) {
    //       let error = this.record?.validation_errors?.[this.field?.name][v];
    //       res.push(error);
    //     }
    //   }
    //   let str = res.join('\n');
    //   return str;
    // },
    datatypeName() {
      let dt = this.datatype;
      if (typeof dt === 'string') {
        return dt;
      } else if (typeof dt === 'object') {
        return Object.keys(dt)[0];
      }
    },
    datatype() {
      return this.field.datatype;
    },
    actualValue() {
      return this.record[this.fieldname];
    },
    displayValue() {
      return this.record[this.fieldname];
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    fieldButtonClick(evt) {
      let clickFunction = this.field.button.clickFunction;
      if (this.field.button.actionName) {
        clickFunction = this.field.button.actionName;
        this.record[clickFunction](evt, this.$store, this.$router);
      } else {
        clickFunction(evt, this.$store, this.$router);
      }
    },
    on_blur(evt) {
      // console.log('on-blur', evt);
      let value = this.record[this.fieldname];
      if (typeof value === 'string') {
        value = value.trimStart().trimEnd();
        this.record[this.fieldname] = value;
      }
      if (this.view === 'edit' || this.view === 'create' || this.field.write === true) {
        // console.log('DEF', this.definition);
        let validation_errors = !this.definition || this.definition.validate_field(this.field, value, this.record);
        if (validation_errors === true) {
          this.validationError = '';
        } else {
          this.validationError = validation_errors;
        }
        this.$emit('blur', { field: this.field.name, value: value });
      }
    },
    on_focus(evt) {
      let value = this.record[this.fieldname];
      this.$emit('focus', { field: this.field.name, value: value, evt });
    },
    on_change(value) {
      this.currentValue = value;
      if (this.view === 'edit' || this.view === 'create' || this.field.write === true) {
        if (this.validationError !== '') {
          let validation_errors = !this.definition || this.definition.validate_field(this.field, value, this.record);
          if (validation_errors === true) {
            this.validationError = '';
          } else {
            this.validationError = validation_errors;
          }
        }
        this.$emit('change', { field: this.field.name, value: value });
      }
    },
  },
};
</script>

<style>
div.ldb-field-container.edit,
div.ldb-field-container.create {
  user-select: all;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  display: flex;
}

div.ldb-field-container.item {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
  align-items: flex-start;
  display: flex;
}

div.ldb-field-container.list {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.ldb-field-help-button {
  color: var(--theme-button-default-font-color);
  cursor: pointer;
}

.ldb-field-help {
  flex: 1;
  max-width: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(10px);
}

.ldb-field-button {
  max-height: 48px;
  margin-left: 5px;
  max-width: 48px;
}
</style>
