<template>
  <LDBModal result="info" size="xx-large" :header="`${header}`">
    <template #body>
      <div>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          :view="field.view"
          :fieldname="field.name"
          :definition="undefined"
          :record="optionsRecord"
          @change="on_change"
        />
      </div>
      <template v-if="dataStatus === 'retrieved'">
        <div class="table-container">
          <table-lite
            id="table"
            :is-static-mode="true"
            :is-slot-mode="true"
            :has-checkbox="false"
            :is-loading="false"
            :is-re-search="false"
            :columns="tableColumns"
            :rows="sortedRecords"
          >
            <template v-for="(col, i) of tableColumns" v-slot:[col.field]="data" :key="i">
              <template v-if="col.showAsCurrency">{{+data.value[col.field]/100}}</template>
              <template v-else>{{data.value[col.field]}}</template>
            </template>
          </table-lite>
        </div>
      </template>      
        <capitec-action-bar>
        <LDBButton
          v-for="button in bottomButtons"
          :key="button.name"
          :label="button.label"
          :action="button.action"
          :actionParams="button.actionParams"
          :params="button.params"
          :type="button.type"
          :tooltip="button.tooltip"
          :modal="button.modal"
          :viewGuards="button.guards"
          :enabledGuards="button.enabledGuards"
          :onSuccess="button.onSuccess"
          @beforeClick="button.beforeClick"
          @click="button.clickEvent"
          @on-api-success="button.onApiSuccess"
          @on-api-error="button.onApiError"
        >
          <template v-if="button.modal !== undefined" #modal>
            <div v-html="button.modal.content"></div>
          </template>
        </LDBButton>
      </capitec-action-bar>

    </template>
  </LDBModal>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import TableLite from '@/views/general/TableLite.vue';

export default {
  name: 'LfxSignatoriesList',
  components: { TableLite },
  props: {},
  data() {
    return {
      dataStatus: 'none',
      optionsRecord: {

      },
    };
  },
  created() {
    this.accountRecord?.loadDataForSignatories();
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),
    // ----- Header -----

    header() {
      return 'Who will receive communitcations for this client?';
    },

    bottomButtons() {
      const self = this;
      return [
        {
          name: 'cancelView',
          label: 'Cancel',
          type: '',
          action: 'click',
          guards: true,
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
      ]
    },
    // ----- Selector Fields -----
    viewFields() {
      return {
        datePresets: {
          name: "communicationType",
          label: 'Communication Type',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'dealRelease', name: 'Deal Release' },
                { id: 'paymentConfirmation', name: 'Payment Confirmation' },
                { id: 'paymentRejection', name: 'Payment Rejection' },
              ],
            }
          },
          view: 'edit',
          guards: true,
        },
      }
    },

    // ----- Parent record -----
    signatoryTypes() {return this.$route.meta.content.signatoryTypes || 'all'},

    accountRecordId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountRecordId];
    },

    // ----- List records -----
    tableColumns() {
      let self = this;
      let res = [
        {
          col: "accountNameColumn",
          field: "accountName",
          label: "Account",
          isKey: false,
          sortable: false,
          guards: true,
        },
        {
          col: "fullNameColumn",
          field: "fullName",
          label: "Name",
          isKey: false,
          sortable: false,
          guards: true,
        },
        {
          col: "capacityColumn",
          field: "capacity",
          label: "Capacity",
          isKey: false,
          sortable: false,
          guards: true,
        },
        {
          col: "emailAddressColumn",
          field: "emailAddress",
          label: "Email Address",
          isKey: false,
          sortable: false,
          guards: true,
        },
      ];
      return res.filter(f => f.guards === true)
    },
    sortedRecords() {
      const res = [];
      for (const contactId in this.records) {
        if (
          this.optionsRecord.communicationType === 'dealRelease' && this.records[contactId].receivesDealRelease ||
          this.optionsRecord.communicationType === 'paymentConfirmation' && this.records[contactId].receivesPaymentConfirmations ||
          this.optionsRecord.communicationType === 'paymentRejection' && this.records[contactId].receivesPaymentRejections
          ) {
            if (typeof (this.records[contactId].accountName) !== 'string') {
              this.records[contactId].accountName = this.account?.[this.records[contactId].accountId].name
            }
            res.push(this.records[contactId])
          }
      }
      return res
    },

    definition() {
      return getDefinition('signatory');
    },

    records() {
      const res = {}
      for (const sigId in this.accountRecord?.signatoriesAndContacts) {
        if (this.accountRecord?.signatoriesAndContacts[sigId].status === 'active') {
          res[sigId] = this.accountRecord?.signatoriesAndContacts[sigId]
        };
      }
      for (const sigId in this.accountRecord?.parentSignatories) {
        if (this.accountRecord?.parentSignatories[sigId].status === 'active') {
          res[sigId] = this.accountRecord?.parentSignatories[sigId]
        }
      }
      return res;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    on_change({ field, value }) {
      this.optionsRecord[field] = value;
      if (this.dataStatus === 'none') {
        this.dataStatus = 'retrieved'
      }
    },

  },
};
</script>
<style >
  .table-container{
    position: relative;
    min-height:320px;
  }

  #table{
    min-width: 100%;
  }
</style>
