<template>
  <LDBModal type="result" :size="modalSize" result="info" :header="header" class="lfx-account-statement">
    <template #body>
      <h3>{{accountRecord?.name}}<br/>{{bankAccountRecord?.accountNumber}}</h3>
      <div v-if="this.statementStatus === 'none'">
        <h1>Statement not available</h1>
      </div>
      <div v-else-if="this.statementStatus === 'balanceRequested'">
        <h1>Checking if statements are available</h1>
      </div>
      <div v-else-if="this.statementStatus === 'balanceFailed'">
        <h1>Balance Error</h1>
        {{ this.balanceError.displayMessage }}
      </div>
      <div v-else-if="this.statementStatus === 'balanceSuccess'">
        <LDBField
          v-for="(field, fieldname) in balanceViewFields"
          :key="fieldname"
          :field="field"
          view="item"
          :fieldname="field.name"
          :definition="undefined"
          :record="balanceData"
        />
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          :view="field.view"
          :fieldname="field.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
      </div>
      <div v-else-if="this.statementStatus === 'statmentRequested'"><h1>Statement Requested</h1></div>
      <div v-else-if="this.statementStatus === 'statmentFailed'">
        <h1>Statement Error</h1>
        {{ this.balanceError.displayMessage }}
      </div>
      <div v-else-if="['statmentEmailSent', 'statmentSuccess'].includes(this.statementStatus)">
        <LDBField
          v-for="(field, fieldname) in balanceViewFields"
          :key="fieldname"
          :field="field"
          view="item"
          :fieldname="field.name"
          :definition="undefined"
          :record="balanceData"
        />
        <template v-if="sortedRecords.length === 0">
          <h3>There are no transactions in this period.</h3>
        </template>
        <div v-else class="lfx-account-statement-view-table-container">
          <table-lite
            id="lfx-account-statement-view-table"
            :is-static-mode="true"
            :is-slot-mode="true"
            :has-checkbox="false"
            :is-loading="false"
            :is-re-search="false"
            :columns="tableColumns"
            :rows="sortedRecords"
            :pageSize="sortedRecords.length"
            :total="sortedRecords.length"
          >
            <template v-for="(col, i) of tableColumns" v-slot:[col.field]="data" :key="i">
              <template v-if="col.showAsCurrency">{{ data.value[col.field] ? +data.value[col.field] : '' }}</template>
              <template v-else>{{ data.value[col.field] }}</template>
            </template>
          </table-lite>
        </div>
      </div>
    </template>
    <template #footer>
      <LDBButton
        v-for="button in buttons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        :onSuccess="button.onSuccess"
        @click="button.clickEvent"
        @on-api-success="onApiSuccess"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import { doPermissionAction } from '@/lfx_rest/lfx_action';
import LDBField from '@/landobyte_vue/LDBField.vue';
import TableLite from '@/views/general/TableLite.vue';
import { formatCurrencyString } from '@/sharedUtils/LdbSharedUtils';
import { buildStatementPdf } from '@/utils/LfxFrontEndPdfAccountStatement'

export default {
  name: 'LfxBankAccountStatement',
  components: { LDBField, TableLite },
  emits: ['on-api-success'],
  props: {},
  emits: {},
  async created() {
    this.statementStatus = 'balanceRequested';
    const balanceResult = await doPermissionAction(this, 'balanceEnquiry', {
      accountId: this.accountId,
      bankAccountId: this.bankAccountId,
    });
    if (balanceResult.status === 'success') {
      this.statementStatus = 'balanceSuccess';
      this.balanceData.currentBalance = balanceResult.response.currentBalance * 100;
      this.balanceData.availableBalance = balanceResult.response.availableBalance * 100;
      this.balanceData.accountStatus = balanceResult.response.accountStatus;
      this.balanceData.holdAmount = balanceResult.response.holdAmount * 100;
    } else {
      this.statementStatus = 'balanceFailed';
      this.balanceError = balanceResult?.response?.response?.data?.error || balanceResult?.response?.response?.data;
      if (this.balanceError.message) {
        this.balanceError.displayMessage = this.balanceError.message
        if (balanceResult?.response?.response?.data?.error.codeReason === 'BANCS-1018') {
          this.isBancsPasswordError = true;
          this.balanceError.displayMessage = "BaNCS Access Failure"
        }
        if (balanceResult?.response?.response?.data?.error.codeReason) {
          this.balanceError.displayMessage = balanceResult?.response?.response?.data?.error.codeReason + " - " + this.balanceError.displayMessage
        }
      } else {
        this.balanceError.displayMessage = 'An Unknown Error has Occured';
      }
    }
  },
  mounted() {
    this.setDatesForPreset()
  },
  data() {
    return {
      mounted: false,
      newRecord: {
        reportType: 'data',
        datePreset: 'thisMonth'
      },
      onSuccess: {
        doStoreUpdate: false,
      },
      statementStatus: 'none',
      balanceError: '',
      balanceData: {
        currentBalance: undefined,
        availableBalance: undefined,
        accountStatus: undefined,
        holdAmount: undefined,
      },
      statementData: {
        accountNumber: '',
        productName: '',
        currencyCode: '',
        accountName: '',
        balanceBroughtForward: 0,
        transactionDetails: [],
        address:{}
      },
      isBancsPasswordError: false,
    };
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account','authUser','user']),
    modalSize() {
      if (['statmentEmailSent', 'statmentSuccess'].includes(this.statementStatus)) {
        return 'xx-large';
      }
      return 'x-large';
    },
    // ----- Definition -----

    definition() {
      return getDefinition('bankAccount');
    },

    // ----- Account record -----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },

    // ----- Markup record ----

    bankAccountId() {
      return +this.$route.params?.bankAccountId;
    },
    bankAccountRecord() {
      return this.accountRecord?.bankAccounts?.[this.bankAccountId];
    },

    recordName() {
      return 'bankAccount';
    },
    recordId() {
      return +this.$route.params?.bankAccountId;
    },
    record() {
      let rec = this.$store.state?.account[this.accountId][this.recordName]?.[this.recordId];
      return rec;
    },

    header() {
      return `Bank Account Statement: ${this.bankAccountRecord?.accountNumber}`;
    },
    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta?.modal?.cancelRoute : 'todo';
      //TODO: go to user's home as fallback
    },
    buttons() {
      let self = this;
      let allButtons = [
        {
          name: 'reportBancsPasswordError',
          label: 'Report',
          type: 'primary',
          guards: this.isBancsPasswordError,
          action: 'url',
          actionParams: {url:this.getReportEmailText()},
        },
        {
          name: 'closeStatementModal',
          label: 'Close',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'requestStatement',
          label: this.statementStatus === 'statmentSuccess' ? 'Email Statement' : 'Request Statement',
          type: 'primary',
          action: 'accountStatement',
          params: { accountId: this.accountId, bankAccountId: this.bankAccountId },
          actionParams: this.newRecord,
          guards: this.statementStatus !== 'statmentEmailSent' && this.statementStatus !== 'statmentSuccess',
          // clickEvent: 'okay',
          onSuccess: { doStoreUpdate: false },
        },
        {
          name: 'sownloadStatement',
          label: 'Download Statement',
          type:'primary',
          guards: this.statementStatus === 'statmentSuccess',
          clickEvent() {
            buildStatementPdf(self.$store.state?.account[self.accountId].name,self.statementData,self.newRecord.fromDate,self.newRecord.toDate)
          }
        }
      ];
      return allButtons.filter((button) => button.guards);
    },
    // ----- Fields -----

    balanceViewFields() {
      const allFields = [
        {
          name: 'currentBalance',
          label: 'Current Balance',
          datatype: { currency: { symbol: this.bankAccountRecord.currencyId, decimalPlaces: 2 } },
        },
        {
          name: 'availableBalance',
          label: 'Available Balance',
          datatype: { currency: { symbol: this.bankAccountRecord.currencyId, decimalPlaces: 2 } },
        },
        {
          name: 'holdAmount',
          label: 'Hold Amount',
          datatype: { currency: { symbol: this.bankAccountRecord.currencyId, decimalPlaces: 2 } },
        },
        { name: 'accountStatus', label: 'Account Status', datatype: 'text' },
      ];
      return allFields;
    },
    viewFields() {
      let res = [
        {
          name: 'datePreset',
          label: 'Date Preset',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'thisMonth', name: 'This Month' },
                { id: '1Month', name: '1 Month' },
                { id: '3Months', name: '3 Month' },
                { id: '6Months', name: '6 Month' },
                { id: 'other', name: 'Other' },
              ],
            },
          },
          view: 'edit',
          guards: true,
        },

        {
          name: 'fromDate',
          label: 'From Date',
          datatype: {
            datetime: {
              type: 'date',
              format: 'human',
              minDate: { type: 'day', offset: -182 },
              maxDate: { type: 'day', offset: 0 },
            },
          },
          default: null,
          mandatory: false,
          view: this.newRecord.datePreset === 'other' ? 'edit' : 'item',
        },
        {
          name: 'toDate',
          label: 'To Date',
          datatype: {
            datetime: {
              type: 'date',
              format: 'human',
              minDate: { type: 'day', offset: -182 },
              maxDate: { type: 'day', offset: 0 },
            },
          },
          default: null,
          mandatory: false,
          view: this.newRecord.datePreset === 'other' ? 'edit' : 'item',
        },
      ];
      return res;
    },

    //----- Table -----
    tableColumns() {
      let self = this;
      let res = [
        {
          col: 'transactionDateColumn',
          field: 'tranDate',
          label: 'Transaction Date',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'transactionTypeColumn',
          field: 'tranType',
          label: 'Transaction Type',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'referenceColumn',
          field: 'reference',
          label: 'Reference',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'tranAmountColumn',
          field: 'tranAmount',
          label: 'Transaction Amount',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'feesColumn',
          field: 'fees',
          label: 'Fees',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'balanceColumn',
          field: 'balance',
          label: 'Balance',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
      ];
      return res.filter((f) => f.guards === true);
    },

    sortedRecords() {
      const transactionArray = [];
      for (const transaction of this.statementData.transactionDetails) {
        transactionArray.push({
          ...transaction,
        });
      }
      return transactionArray;
    },
  },
  methods: {
    // ----- Fields -----

    on_change({ field, value }) {
      this.newRecord[field] = value;
      if (field === 'datePreset') {
        this.setDatesForPreset()
      }
    },
    setDatesForPreset() {
      const today = new Date();
      switch (this.newRecord.datePreset) {
        case 'thisMonth': 
          this.newRecord.fromDate = new Date(today.getFullYear(), today.getMonth(), 1,12).toISOString();
          this.newRecord.toDate = today.toISOString();
          break;
        case '1Month': 
          this.newRecord.fromDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate(),12).toISOString();
          this.newRecord.toDate = today.toISOString();
          break;
        case '3Months': 
          this.newRecord.fromDate = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate(),12).toISOString();
          this.newRecord.toDate = today.toISOString();
          break;
        case '6Months': 
        this.newRecord.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 182,12).toISOString();
          this.newRecord.toDate = today.toISOString();
          break;
      }
    },
    // ----- API calls -----
    async onApiSuccess(responseObject) {
      if (responseObject.status === 'success') {
        if (responseObject.response.responseType === 'data') {
          (this.statementStatus = 'statmentSuccess'),
            (this.statementData.accountNumber = responseObject.response.accountNumber);
          this.statementData.productName = responseObject.response.productName;
          this.statementData.currencyCode = responseObject.response.currencyCode;
          this.statementData.accountName = responseObject.response.accountName;
          this.statementData.balanceBroughtForward = responseObject.response.balanceBroughtForward;
          this.statementData.address = {
            address1: responseObject.response.address1,
            address2: responseObject.response.address2,
            address3: responseObject.response.address3,
            address4: responseObject.response.address4,
          }
          this.statementData.transactionDetails = [];
          for (const transaction of responseObject.response.transactionDetails) {
            this.statementData.transactionDetails.push({
              tranDate: transaction.tranDate,
              tranType: transaction.tranType,
              reference: typeof transaction.reference === 'string' ? transaction.reference : '',
              tranAmount: this.formatCurrencyStringForStatement(transaction.tranAmount),
              fees: this.formatCurrencyStringForStatement(transaction.fees),
              balance: this.formatCurrencyStringForStatement(transaction.balance),
            });
          }
          this.newRecord.reportType = 'email';
        } else {
          this.statementStatus = 'statmentEmailSent';
        }
      } else {
        this.statementStatus = 'statmentFailed';
      }
    },
    formatCurrencyStringForStatement(amount) {
      if (amount === undefined) {
        return ""
      }
      if (typeof amount === 'string') {
        amount = +amount
      }
      return formatCurrencyString(amount * 100)
    },
    getReportEmailText() {
      const myUser = this.user[this.authUser.id] || {};
      const mailTo = "dylonnNagel@capitecbank.co.za,eugeneMorris@capitecbank.co.za,michaelparadza@capitecbank.co.za";
      const cc = "kimChikwata@capitecbank.co.za,jimmykena@capitecbank.co.za,luckyMahole@capitecbank.co.za,support@landobyte.com";
      return `mailto:${mailTo}?cc=${cc}&subject=Bancs%20Password%20Reset%20-%20${myUser.displayName}&Body=Good%20Day%20Team,%0d%0a%0d%0aPlease%20assist%20with%20a%20Bancs%20password%20reset%20for%20AD%20User%20${myUser.esbUsername}.%0d%0a%0d%0aKind%20Regards,%0d%0a${myUser.displayName}`
    },
  },
};
</script>
<style>
.lfx-account-statement-view-table-container {
  position: relative;
  min-height: 320px;
}

#lfx-account-statement-view-table {
  min-width: 100%;
}

capitec-modal.lfx-account-statement {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
</style>
