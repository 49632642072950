<template>
  <div class="lfx-widget-container sent-back-payments" :style="spanStyle" :class="dynamicClass">
    <LfxWidgetHeader :header="`Sent Back Payments (${paymentCount})`" :subheader="dateRange" :link="allRecordsLink" />
    <template v-if="paymentCount > 0">
      <LfxWidgetRow
        v-for="payment in pagedItems"
        :key="payment.id"
        :title="`${payment.reference} - ${paymentClientName(payment)}`"
        :subtitle="paymentDate(payment)"
        :info="`${payment.paymentCurrency} ${formatCurrency(payment.paymentAmount)}`"
        :avatar="paymentClientName(payment)"
        :avatarColor="avatarColor(payment)"
        :link="paymentLink(payment)"
      />

      <LfxWidgetScroller
        :totalItems="items.length"
        :itemsPerPage="itemsPerPage"
        :currentPage="pageNumber"
        @on-page="onPage"
      />
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LfxWidgetHeader from './LfxWidgetHeader.vue';
import LfxWidgetRow from './LfxWidgetRow.vue';
import LfxWidgetScroller from './LfxWidgetScroller.vue';
import { colorFromId } from '@/landobyte_vue/ldb_utils.ts';

import lfx_widget_mixin from './lfx_widget_mixin';

export default {
  mixins: [lfx_widget_mixin],
  components: {
    LfxWidgetHeader,
    LfxWidgetRow,
    LfxWidgetScroller,
  },
  props: {},
  data() {
    return {
      itemsPerPage: 4,
      pageNumber: 1,
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['payment', 'account']),
    allRecordsLink() {
      return { name: '_sentBackPayments' };
    },

    items() {
      return this.sentBackPayments;
    },
    sentBackPayments() {
      let statusses = ['readyForSubmission','informationQuery' ];
      return Object.values(this.payment)
        .filter((payment) => statusses.indexOf(payment.status) > -1 && payment.submittedCount > 0)
        .sort((a, b) => new Date(a.mostRecent).getTime() - new Date(b.mostRecent).getTime());
    },
    paymentCount() {
      return this.sentBackPayments.length;
    },
    minDate() {
      return this.sentBackPayments[0]?.mostRecent.split('T')[0];
    },
    maxDate() {
      return this.sentBackPayments[this.sentBackPayments.length - 1]?.mostRecent.split('T')[0];
    },
    dateRange() {
      if (this.minDate === this.maxDate) {
        return this.minDate;
      } else {
        return `${this.minDate} to ${this.maxDate}`;
      }
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    paymentClientName(payment) {
      let clientId = payment.accountId;
      let clientName = this?.account?.[clientId]?.name || '-';
      if (clientName === '-') {
      }
      return clientName;
    },
    avatarColor(payment) {
      return colorFromId(payment.accountId);
    },

    onPage(page) {
      this.pageNumber = page;
    },
    paymentDate(payment) {
      return `${payment.mostRecent.split('T').join(' ').split('.')[0]}`;
    },
    paymentLink(payment) {
      // return `/transaction/${payment.transactionFileId}/payment/${payment.id}`;
      return this.payment?.[payment?.id]?.link;
    },
  },
};
</script>
<style></style>
