<template>
  <div>
    <LFXListView :titleBar="titleBar" :definition="definition" :records="records" />
  </div>
  <RouterView />
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXListView from '@/views/general/LFXListView.vue';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxConfigPublicHolidays',
  components: { LFXListView },
  props: {},
  data() {
    return {
      titleBar: {
        label: 'Public Holidays',
        type: 'default',
        buttons: {},
      },
    };
  },
  created() {
    if (!this.$store.state.config[1].publicHoliday) {
      doPermissionAction({}, 'getPublicHolidays', { configId: 1 });
    }
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    records() {
      return this.$store.state.config[1].publicHoliday || {};
    },
    definition() {
      return getDefinition('publicHoliday');
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>

<style>
/* div.container{  } */
</style>
