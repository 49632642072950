//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2022 LandoByte (Pty) Ltd.
//  File        : LfxClientPortfolioDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Client Application Portfolio record for LandoByte TS systems
//  Created     : 21 May 2022 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import { intermediaryFieldViewGuard } from '../sharedUtils/LdbDefinitionUtils';
import type { LdbDefinition } from './LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------

export type LfxClientPortfolioStatus = 'inProgress' | 'rejected' | 'completed' | 'superseded';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxClientPortfolioInt {
  id?: number;
  uuid?: string;
  accountId?: number;
  applicationId?: number;
  intermediaryId?: number;
  reference?: string;

  integrationId?: string;
  rejectionReason?: string;

  submittedAt?: Date;
  submittedBy?: number;
  readyForSigningAt?: Date;
  signedAt?: Date;
  completedAt?: Date;
  rejectedAt?: Date;
  supersededAt?: Date;

  status?: LfxClientPortfolioStatus;
  isCurrent?: boolean;

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxClientPortfolioDef: LdbDefinition = {
  title: 'Portfolio',
  table: 'LfxClientPortfolio',
  collectionPath: '/account/{{accountId}}/application/{{applicationId}}/portfolio',
  pagingType: 'backEnd',
  view: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
  indexes: ['applicationId', 'accountId'],
  fields: {
    id: {
      label: 'ID',
      datatype: { id: { descriptionField: 'reference' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    uuid: {
      label: 'UUID',
      datatype: 'uuid',
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    accountId: {
      label: 'Account',
      datatype: {
        foreignKey: {
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
          collection: 'fk_accounts',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },

    applicationId: {
      label: 'Application',
      datatype: {
        foreignKey: {
          linkTable: 'LfxApplication',
          linkField: 'id',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },

    intermediaryId: {
      label: 'Intermediary',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediaries',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: intermediaryFieldViewGuard,
        list: false,
        delete: false,
        csv: false,
      },
    },

    reference: {
      label: 'Reference',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    integrationId: {
      label: 'Integration ID',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
    },
    rejectionReason: {
      label: 'Rejection Reason',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
    },
    submittedAt: {
      label: 'Submitted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    submittedBy: {
      label: 'Submitted By',
      datatype: {
        foreignKey: {
          collection: 'fk_users',

          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    readyForSigningAt: {
      label: 'Ready for Signing At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    signedAt: {
      label: 'Signed At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    completedAt: {
      label: 'Completed At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    rejectedAt: {
      label: 'Rejected At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    supersededAt: {
      label: 'Superseded At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'inProgress', name: 'In Progress' },
            { id: 'rejected', name: 'Rejected' },
            { id: 'completed', name: 'Completed' },
            { id: 'superseded', name: 'Superseded' },
          ],
        },
      },
      default: 'active',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    isCurrent: {
      label: 'Is Current',
      datatype: 'boolean',
      default: false,
      mandatory: true,
      allowNullValues: false,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },

    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
  },
};
