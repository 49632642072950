<template>
  <LFXContent
    :titleBar="titleBar"
    :show_tabs="showTabs"
    :tabs="tabs"
    @tab-select="onTabSelect"
    :show_actions="showActions"
    :form_layout="formLayout"
    :show_label="showLabel"
    :label="contentLabel"
    :show_sidebar="false"
  >
    <template #actions>
      <LDBButton
        :xstyle="` max-height:32px; font-size:14px; --theme-button-font-size:14px ; ${
          button.style === undefined ? '' : button.style
        }`"
        v-for="button in actionBarButtons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        @click="button.clickEvent"
      ></LDBButton
    ></template>

    <template #content>
      <router-view></router-view>
    </template>
  </LFXContent>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXContent from '@/components/layout/LfxContent.vue';

export default {
  name: 'LfxViewHelp',
  components: { LFXContent },
  props: {},
  data() {
    return {};
  },
  created() {
    if (this.$route.name === 'help') {
      this.$router.replace({ name: 'helpIntermediaryFaq' });
    }
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['titleBarSettings']),

    // ----- Bars -----

    titleBar() {
      const titleBar = {
        label: 'Help',
      };
      return titleBar;
    },

    // ----- Tabs -----

    showTabs() {
      return true;
    },
    tabs() {
      let tabs = [
        { index: 0, routeName: 'helpIntermediaryFaq', guards: true, label: 'Intermediary FAQ' },
        { index: 1, routeName: 'helpClientFaq', guards: true, label: 'Client FAQ' },
        { index: 2, routeName: 'helpContacts', guards: true, label: 'Contacts' },
        { index: 3, routeName: 'helpFraudTraining', guards: true, label: 'Fraud Training' },
      ];
      return tabs.filter((tab) => tab.guards);
    },
    selectedTabName() {
      const tabRouteDepth = this.componentRouteDepth + 1;
      return this.$route.fullPath.split('/')[tabRouteDepth];
    },
    selectedTabIndex() {
      let tabNames = this.tabs.map((t) => t.name);
      return tabNames.indexOf(this.selectedTabName);
    },
    firstAvailableTab() {
      return this.tabs[0];
    },
    areTabsAvailable() {
      if (this.tabs.length > 0) {
        return true;
      } else return false;
    },

    // ----- Action Bar -----

    showActions() {
      return false;
    },

    // ----- Layout -----

    formLayout() {
      return this.$route.meta?.layout === 'list' ? false : true;
    },

    // ----- Content Label -----

    showLabel() {
      return this.contentLabel ? true : false;
    },
    contentLabel() {
      return this.$route.meta?.label ? this.$route.meta.label : 'TODO';
    },

    // ----- Guards -----

    canViewInfo() {
      // TODO
      return true;
    },
    canViewPermissions() {
      // TODO
      return true;
    },
    canViewSettings() {
      // TODO
      return true;
    },
    canViewPreferences() {
      // TODO
      return true;
    },
    canViewLogs() {
      // TODO
      return true;
    },

    // ----- Display -----

    // titleBarLabel() {
    //   return 'Help';
    // },

    // ----- Record -----

    // recordName() {
    //   // return this.$route.params.record;
    //   return 'user';
    // },
    // recordId() {
    //   return this.$route.params.userId;
    // },
    // record() {
    //   return this.$store.state?.[this.recordName]?.[this.recordId];
    // },
    // definition() {
    //   return getDefinition(this.recordName);
    // },

    // ----- Route -----

    componentRoutePath() {
      return 'help';
    },

    componentRouteDepth() {
      let path = this.$route.fullPath;
      let pathSections = path.split('/');
      const componentRouteDepth = pathSections.indexOf(this.componentRoutePath);
      return componentRouteDepth;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Tabs -----

    tabName(index) {
      return this.tabs[index].name;
    },
    onTitleBarButtonClick(button) {
      this.$router.push({ name: button.routeName });
    },
    onTabClick(evt) {
      let tabIndex = evt.detail.selectedIndex;
      let tabName = this.tabName(tabIndex);
      let path = this.$route.fullPath;
      let pathSections = path.split('/');
      const tabRouteDepth = this.componentRouteDepth + 1;
      pathSections[tabRouteDepth] = tabName;
      let newPathSections = [];
      for (let i = 0; i <= tabRouteDepth; i++) {
        newPathSections.push(pathSections[i]);
      }
      let newPath = newPathSections.join('/');
      this.$router.push(newPath);
      // added logic for handling sub-tabs - avr 2021-10-08
    },
    onTabSelect(tab) {
      this.$router.push({ name: tab.routeName });
    },
  },
};
</script>

<style scoped>
/* capitec-title-bar {
  background-color: rgb(127, 214, 210);
} */
</style>
