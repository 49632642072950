<template>
  <LDBModal result="info" header="Occupation Code Picker" size="x-large" :closable="false">
    <template #body>
      <div>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          :view="field.view"
          :fieldname="field.name"
          :definition="undefined"
          :record="codeRecord"
          @change="on_change"
        />
      </div>
    </template>

    <template #footer>
      <capitec-spacer />
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        :onSuccess="button.onSuccess"
        @beforeClick="button.beforeClick"
        @click="button.onClick"
        @on-api-success="button.onApiSuccess"
        @on-api-error="button.onApiError"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBField from '@/landobyte_vue/LDBField.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import TableLite from '@/views/general/TableLite.vue';

export default {
  name: 'LfxClientApplicationOcPicker',
  components: { LDBField, LDBLoader, TableLite },
  // emits: ['oc-code-selected'],
  props: {},
  data() {
    return {
      codeRecord: {
        section: undefined,
        code1: undefined,
        code: undefined,
      },
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    // ----- Account Record -----

    clientId() {
      return +this.$route.params.accountId;
    },
    clientRecord() {
      return this.account[this.clientId];
    },

    // ----- Application Record -----

    applicationId() {
      return +this.$route.params.applicationId;
    },
    applicationRecord() {
      return this.clientRecord?.application?.[this.applicationId];
    },

    // ----- Viewed CIF Record -----

    cifId() {
      return +this.$route.params.cifId;
    },
    cifRecord() {
      return this.applicationRecord?.application_cif?.[this.cifId];
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      return [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          action: 'click',
          onClick() {
            self.$router.go(-1);
          },
        },
        {
          name: 'confirm',
          label: 'Confirm',
          type: 'primary',
          action: 'click',
          enabledGuards: this.codeRecord.code !== undefined,
          onClick() {
            // self.$emit('oc-code-selected', self.codeRecord);
            let infoObjectkey = Object.keys(self.cifRecord.application_cif_individual)[0];
            self.cifRecord.application_cif_individual[infoObjectkey].occupationCode = self.codeRecord.code;
            self.$router.go(-1);
          },
        },
      ];
    },

    // ----- Fields -----

    viewFields() {
      const allFields = {
        section: {
          name: 'section',
          label: 'Section',
          datatype: {
            option: {
              optionType: 'number',
              options: this.sectionOptions,
            },
          },
          view: 'edit',
          guards: true,
        },
        code1: {
          name: 'code1',
          label: 'Code 1',
          datatype: {
            option: {
              optionType: 'number',
              options: this.code1Options,
            },
          },
          view: 'edit',
          guards: this.codeRecord.section !== undefined && this.code1Options.length > 1,
        },
        code: {
          name: 'code',
          label: 'Code',
          datatype: 'text',
          view: 'item',
          guards: this.codeRecord.code !== undefined,
        },
      };
      const returnFields = {};
      for (const f in allFields) {
        if (allFields[f].guards !== false) {
          returnFields[f] = allFields[f];
        }
      }
      return returnFields;
    },
    sectionOptions() {
      const res = [{ id: undefined, name: 'Please Make a Selection...' }];
      for (let i = 0; i < this.ocConfig().length; i++) {
        const section = this.ocConfig()[i];
        res.push({
          id: i,
          name: section.name,
        });
      }
      return res;
    },
    code1Options() {
      const res = [{ id: undefined, name: 'Please Make a Selection...' }];
      const selections = this.ocConfig()[this.codeRecord.section]?.subCodes || [];
      for (let i = 0; i < selections.length; i++) {
        const selection = selections[i];
        res.push({
          id: i,
          name: selection.name,
        });
      }
      return res;
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    on_change({ field, value }) {
      this.codeRecord[field] = value;
      switch (field) {
        case 'section':
          this.codeRecord.code1 = undefined;
          break;
      }
      this.codeRecord.code = this.getCode();
    },
    getCode() {
      if (this.codeRecord.section !== undefined) {
        const section = this.ocConfig()[this.codeRecord.section];
        if (this.codeRecord.code1 !== undefined) {
          const code1 = section.subCodes[this.codeRecord.code1];
          return code1.index;
        }
      }
      return undefined;
    },
    ocConfig() {
      return [
        {
          id: '000',
          index: '000',
          name: '000 - Non-earner',
          subCodes: [
            { id: '001', index: '001', name: '001 - Housewife (Homemaker)' },
            { id: '003', index: '003', name: '003 - Child - Not Scholar (Younger Than 15)' },
            { id: '004', index: '004', name: '004 - Scholar: Student' },
            { id: '006', index: '006', name: '006 - Labour Disabled (15-64 Years Old)' },
            { id: '007', index: '007', name: '007 - Pensioner & Other Not Economically Active 65 Years And Older' },
            { id: '008', index: '008', name: '008 - Foreign Visitor' },
            { id: '009', index: '009', name: '009 - Not Economically Active' },
          ],
        },
        {
          id: '100',
          index: '100',
          name: '100 - Professional',
          subCodes: [
            { id: '111', index: '111', name: '111 - Engineer (Registered)' },
            { id: '113', index: '113', name: '113 - Engineer (Not Registered) Engineering Technologist' },
            { id: '114', index: '114', name: '114 - Engineering Technician' },
            { id: '115', index: '115', name: '115 - Technician: Draughtsman' },
            { id: '116', index: '116', name: '116 - Technical Inspector & Related Occupations' },
            { id: '117', index: '117', name: '117 - Architect And Related Occupations' },
            { id: '118', index: '118', name: '118 - Land Surveyor And Related Occupations' },
            { id: '121', index: '121', name: '121 - Physical Scientist And Related Occupations' },
            { id: '122', index: '122', name: '122 - Geological Sciences Occupations' },
            { id: '123', index: '123', name: '123 - Mathematical & Related Occupations' },
            { id: '124', index: '124', name: '124 - Computer Sciences Occupations' },
            { id: '125', index: '125', name: '125 - Chemical Sciences Occupations' },
            { id: '126', index: '126', name: '126 - Biological Sciences Occupations' },
            { id: '127', index: '127', name: '127 - Agriculture: Forestry And Food Science' },
            { id: '131', index: '131', name: '131 - Natural Sciences Technologists' },
            { id: '133', index: '133', name: '133 - Natural Sciences Technician' },
            { id: '139', index: '139', name: '139 - Natural Sciences Occupations' },
            { id: '141', index: '141', name: '141 - Medical Professions' },
            { id: '142', index: '142', name: '142 - Dental Professions' },
            { id: '143', index: '143', name: '143 - Veterinary Sciences Professions' },
            { id: '144', index: '144', name: '144 - Pharmaceutical Professions' },
            { id: '145', index: '145', name: '145 - Supplementary Medical Professions' },
            { id: '146', index: '146', name: '146 - Nursing' },
            { id: '148', index: '148', name: '148 - Technician: Medical And Related Sciences' },
            { id: '149', index: '149', name: '149 - Health Services Professions' },
            { id: '151', index: '151', name: '151 - Rector: Principal: Inspector Of Education' },
            {
              id: '152',
              index: '152',
              name: '152 - Professor. Lecturer (University - Engineering: Natural: Life And Medical Sciences)',
            },
            { id: '153', index: '153', name: '153 - Professor: Lecturer (University - Humanities)' },
            {
              id: '154',
              index: '154',
              name: '154 - Lecturer (Technikon: Teachers Training: Technical And Other Colleges - Engineering: Natural Life And Medical Sciences)',
            },
            {
              id: '155',
              index: '155',
              name: '155 - Lecturer (Technikon: Teachers Training: Technical And Other Colleges - Humanities)',
            },
            { id: '156', index: '156', name: '156 - Teacher' },
            { id: '157', index: '157', name: '157 - Instructor' },
            { id: '159', index: '159', name: '159 - Education Professions' },
            { id: '161', index: '161', name: '161 - Legal Occupations' },
            { id: '162', index: '162', name: '162 - Religious Professions' },
            { id: '163', index: '163', name: '163 - Social Sciences Occupations' },
            { id: '164', index: '164', name: '164 - Personnel And Management Services' },
            { id: '165', index: '165', name: '165 - Accountant And Related Accounting Occupations' },
            { id: '166', index: '166', name: '166 - Economic Occupations' },
            { id: '169', index: '169', name: '169 - Humanities And Related Occupations' },
            { id: '171', index: '171', name: '171 - Artist: Plastic Arts' },
            { id: '173', index: '173', name: '173 - Designer' },
            { id: '175', index: '175', name: '175 - Artist: Performing Arts And Related' },
            { id: '176', index: '176', name: '176 - Author And Related' },
            { id: '177', index: '177', name: '177 - Sports Occupations' },
            { id: '179', index: '179', name: '179 - Art & Entertainment' },
          ],
        },
        {
          id: '200',
          index: '200',
          name: '200 - Governmental and Managerial',
          subCodes: [
            { id: '211', index: '211', name: '211 - Legislative And Related Official' },
            {
              id: '215',
              index: '215',
              name: '215 - Government Administrator And Related Executive And Managerial Occupations',
            },
            {
              id: '231',
              index: '231',
              name: '231 - Director/Manager-Top Executive Of Company: Public Corporation Or Partnership',
            },
            { id: '233', index: '233', name: '233 - Managerial - Administrative And Related' },
            { id: '235', index: '235', name: '235 - Managerial - Technical And Related' },
            { id: '237', index: '237', name: '237 - Managerial - Trade And Related' },
            { id: '239', index: '239', name: '239 - Managerial - Other' },
            { id: '251', index: '251', name: '251 - Administrative Occupations' },
          ],
        },
        {
          id: '300',
          index: '300',
          name: '300 - Clerical and Retail',
          subCodes: [
            { id: '311', index: '311', name: '311 - Clerical Supervisor And Administrative Official' },
            { id: '312', index: '312', name: '312 - Clerk (General)' },
            { id: '313', index: '313', name: '313 - Bookkeeper And Related Financial Clerk' },
            { id: '315', index: '315', name: '315 - Cashier' },
            { id: '316', index: '316', name: '316 - Secretary: Typist: Information And Related Clerk' },
            { id: '317', index: '317', name: '317 - Business Machine Operator And Computer Operator' },
            { id: '319', index: '319', name: '319 - Clerical Occupations Other' },
            { id: '351', index: '351', name: '351 - Working Proprietor (Retail & Wholesale)' },
            { id: '352', index: '352', name: '352 - Sales Supervisor' },
            { id: '353', index: '353', name: '353 - Technical Sales Occupations' },
            { id: '354', index: '354', name: '354 - Sales Worker: Shop Assistant' },
            { id: '355', index: '355', name: '355 - Sales Worker. Insurance: Property: Services' },
            { id: '357', index: '357', name: '357 - Representative: Agent' },
            { id: '359', index: '359', name: '359 - Sales Worker Other' },
          ],
        },
        {
          id: '400',
          index: '400',
          name: '400 - Transport and Communications',
          subCodes: [
            { id: '411', index: '411', name: '411 - Merchant Navy Occupations' },
            { id: '413', index: '413', name: '413 - Road Transport Occupations' },
            { id: '415', index: '415', name: '415 - Rail Transport Occupations' },
            { id: '417', index: '417', name: '417 - Air Transport Occupations' },
            { id: '419', index: '419', name: '419 - Transport Occupations Other' },
            { id: '451', index: '451', name: '451 - Mail Distribution Worker' },
            { id: '455', index: '455', name: '455 - Telecommunications Occupations' },
          ],
        },
        {
          id: '500',
          index: '500',
          name: '500 - Services',
          subCodes: [
            { id: '511', index: '511', name: '511 - Defence Occupations' },
            { id: '513', index: '513', name: '513 - Law Enforcement And Related Service' },
            { id: '519', index: '519', name: '519 - Other Protective Service' },
            { id: '531', index: '531', name: '531 - Catering Service' },
            { id: '535', index: '535', name: '535 - Accommodation Services' },
            { id: '551', index: '551', name: '551 - Personal Services' },
            { id: '555', index: '555', name: '555 - Health Social & Related Services' },
            { id: '559', index: '559', name: '559 - Personal And Related Services Other' },
            { id: '571', index: '571', name: '571 - Domestic Worker: Private Household (Not Farm)' },
            { id: '572', index: '572', name: '572 - Domestic Worker: Private Household (Farm)' },
            { id: '591', index: '591', name: '591 - Launderer: Dry-Cleaning Worker: Ironer' },
            { id: '599', index: '599', name: '599 - Services Occupations Others' },
          ],
        },
        {
          id: '600',
          index: '600',
          name: '600 - Farming and Forestry',
          subCodes: [
            { id: '611', index: '611', name: '611 - Farmer: Nursery Man (Self-Acting)' },
            { id: '615', index: '615', name: '615 - Farm Manager: Foreman And Related Occupations' },
            { id: '619', index: '619', name: '619 - Farmer Other' },
            { id: '651', index: '651', name: '651 - Farm Worker - Full Time' },
            { id: '652', index: '652', name: '652 - Farm Worker - Part Time' },
            { id: '655', index: '655', name: '655 - Forestry Worker' },
            { id: '657', index: '657', name: '657 - Nursery: Garden Worker' },
            { id: '659', index: '659', name: '659 - Farm And Related Worker Other' },
            { id: '691', index: '691', name: '691 - Fisherman: Hunter And Related Occupations' },
            { id: '699', index: '699', name: '699 - Farming And Related Occupations Other' },
          ],
        },
        {
          id: '700',
          index: '700',
          name: '700 - Artisans and Tradesmen',
          subCodes: [
            { id: '711', index: '711', name: '711 - Metal & Engineering Artisan' },
            { id: '712', index: '712', name: '712 - Electrical & Electronic Artisan' },
            { id: '713', index: '713', name: '713 - Motor Vehicle Artisan' },
            { id: '714', index: '714', name: '714 - Building Trade' },
            { id: '715', index: '715', name: '715 - Printing Tradesman' },
            { id: '716', index: '716', name: '716 - Furniture Tradesman' },
            { id: '718', index: '718', name: '718 - Diamond: Jewellers And Gold Tradesman' },
            { id: '719', index: '719', name: '719 - Tradesman Other' },
            { id: '731', index: '731', name: '731 - Apprentice: Metal & Engineering Trade' },
            { id: '732', index: '732', name: '732 - Apprentice: Electrical & Electronic Trade' },
            { id: '733', index: '733', name: '733 - Apprentice: Motor Vehicle Trade' },
            { id: '734', index: '734', name: '734 - Apprentice: Building Trade' },
            { id: '735', index: '735', name: '735 - Apprentice: Printing Trade' },
            { id: '736', index: '736', name: '736 - Apprentice: Furniture Trade' },
            { id: '738', index: '738', name: "738 - Apprentice: Diamond: Jeweller's And Gold Trade" },
            { id: '739', index: '739', name: '739 - Apprentice: Other' },
            { id: '791', index: '791', name: '791 - Technical Assistant' },
            { id: '793', index: '793', name: '793 - Trade Related Occupations In Food Processing' },
            { id: '799', index: '799', name: '799 - Trade Related Occupations Other' },
          ],
        },
        {
          id: '800',
          index: '800',
          name: '800 - Mining: Manufacture and Construction',
          subCodes: [
            { id: '811', index: '811', name: '811 - Foreman: Shift Boss: Mine & Quarry' },
            { id: '812', index: '812', name: '812 - Supervisor: Mine & Quarry' },
            { id: '813', index: '813', name: '813 - Food: Drink And Tobacco Preparation & Manufacture' },
            { id: '814', index: '814', name: '814 - Clothing & Textile Manufacture' },
            { id: '815', index: '815', name: '815 - Leather. Artificial Leather Preparation & Shoe Manufacture' },
            { id: '817', index: '817', name: '817 - Wood Processor: Paper & Wood Product Manufacturing' },
            {
              id: '818',
              index: '818',
              name: '818 - Chemical: Petroleum: Coal: Rubber & Plastic Products Manufacturing & Processing',
            },
            { id: '821', index: '821', name: '821 - Manufacture & Processing Of Non-Metallic Mineral Products' },
            { id: '823', index: '823', name: '823 - Metal Manufacturing And Processing' },
            { id: '824', index: '824', name: '824 - Metal Products: Machinery & Transport Equipment Manufacturing' },
            { id: '825', index: '825', name: '825 - Electrical Equipment Manufacturing & Maintenance' },
            { id: '826', index: '826', name: '826 - Professional: Scientific: Measurement And Control Instruments' },
            { id: '827', index: '827', name: '827 - Production And Distribution Of Electricity: Gas & Water' },
            { id: '828', index: '828', name: '828 - Building & Construction' },
            { id: '829', index: '829', name: '829 - Other' },
            { id: '831', index: '831', name: '831 - Mining Occupations' },
            { id: '833', index: '833', name: '833 - Mining Auxiliary Services (Above Ground)' },
            { id: '834', index: '834', name: '834 - Mining Auxiliary Services (Underground)' },
            { id: '839', index: '839', name: '839 - Miner: Quarry Worker And Related Workers' },
            { id: '853', index: '853', name: '853 - Food: Beverage And Tobacco Preparation And Manufacture' },
            { id: '854', index: '854', name: '854 - Clothing Textile Manufacture' },
            {
              id: '858',
              index: '858',
              name: '858 - Chemical: Petroleum: Coal: Rubber & Plastic Products Manufacturing',
            },
            { id: '861', index: '861', name: '861 - Manufacture Of Non-Metallic Mineral Products' },
            {
              id: '864',
              index: '864',
              name: '864 - Metal Products: Machinery & Transport Equipment Manufacturing And Maintenance',
            },
            { id: '865', index: '865', name: '865 - Electrical & Electronic Semi-Skilled Worker' },
            { id: '869', index: '869', name: '869 - Operator: Production Worker And Related Semi-Skilled Worker Other' },
            { id: '881', index: '881', name: '881 - Mine & Quarry Worker' },
            { id: '883', index: '883', name: '883 - Building & Construction Worker' },
            { id: '885', index: '885', name: '885 - Material Handler: Packer & Related Unskilled Worker' },
            { id: '889', index: '889', name: '889 - Labourer Or Unskilled Worker Other' },
          ],
        },
        {
          id: '900',
          index: '900',
          name: '900 - Other',
          subCodes: [
            { id: '910', index: '910', name: '910 - Occupation Unspecified' },
            { id: '930', index: '930', name: '930 - Unemployed: Occupation Unspecified' },
            { id: '950', index: '950', name: '950 - Occupation In Informal Sector E.G. Waste Collector: Witch Doctor' },
            { id: '990', index: '990', name: '990 - Occupation Not Elsewhere Classified' },
            { id: '999', index: '999', name: '999 - Financial Sector' },
          ],
        },
      ];
    },
  },
};
</script>

<style></style>
