//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxAccountMarkupDef.ts
//  Author      : Bevan Timm
//  Description : This module defines an Account Markup record for LandoByte TS systems
//  Created     : 14 August 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition, LdbViewFunction } from './LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------

export type LfxAccountMarkupType = 'pips' | 'percentage';
export type LfxAccountMarkupStatus = 'visible' | 'hidden';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxAccountMarkupInt {
  id?: number;
  uuid?: string;
  accountId?: number;
  parentId?: number;
  currencyPair?: string;

  markupType?: LfxAccountMarkupType;
  pips?: number;
  percentage?: number;

  status?: LfxAccountMarkupStatus;

  discountedBankMarkup?: number;
  maximumIntermediaryMarkup?: number;

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

export const canOverrideBankMarkup: LdbViewFunction = (viewFunctionObject) => {
  return viewFunctionObject.authUser.accountLevel === 'bank';
};

//----------------------------------------------------------------------------------------------------------------------

export const LfxAccountMarkupDef: LdbDefinition = {
  title: 'Account Markup',
  table: 'LfxAccountMarkup',
  collectionPath: '/account/{{accountId}}/markup',
  view: { create: false, edit: true, item: true, list: true, delete: false, csv: false },
  indexes: ['accountId', 'currencyPair'],
  pagingType: 'backEnd',
  groups: {
    system: { priority: 1, label: 'General' },
    markup: { priority: 2, label: 'Markup' },
    rate: { priority: 3, label: 'Rates'},
    changes: { priority: 4, label: 'Changes' },
  },
  fields: {
    id: {
      label: 'ID',
      datatype: { id: { descriptionField: 'currencyPairName' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },
    uuid: {
      label: 'UUID',
      datatype: 'uuid',
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    accountId: {
      label: 'Account',
      datatype: {
        foreignKey: {
          collection: 'fk_accounts',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },
    parentId: {
      label: 'Parent',
      datatype: {
        foreignKey: {
          collection: 'fk_accounts',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      property: { read: true, write: true, source: 'backend', sort: 'none' },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    currencyPair: {
      label: 'Currency Pair',
      datatype: {
        foreignKey: {
          collection: 'fk_currency_pairs',
          linkTable: 'LfxCurrencyPair',
          linkField: 'id',
          displayField: 'name',
          type: 'text',
        },
      },
      default: null,
      mandatory: true,
      views: { create: true, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },
    currencyPairName: {
      label: 'Currency Pair',
      datatype: 'text',
      default: null,
      mandatory: true,
      property: {source:'frontend',read:true,write:false, sort: 'currencyPair'},
      views: { create: true, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    markupType: {
      label: 'Markup Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'pips', name: 'PIPs' },
            { id: 'percentage', name: 'Percentage' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: 'requested',
      mandatory: true,
      allowNullValues: false,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'markup',
    },
    pips: {
      label: 'Pips',
      datatype: 'float',
      default: 0,
      mandatory: true,
      allowNullValues: false,
      validators: {
        isNumeric: { msg: 'Pips should be numeric' },
      },
      guards: { markupType: { eq: 'pips' } },
      // enableGuards: { markupType: { eq: 'pips' } },
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'markup',
    },
    percentage: {
      label: 'Percentage',
      datatype: 'percentage',
      default: 0,
      mandatory: true,
      allowNullValues: false,
      validators: {
        isNumeric: { msg: 'Percentage should be numeric' },
      },
      guards: { markupType: { eq: 'percentage' } },
      // enableGuards: { markupType: { eq: 'percentage' } },
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'markup',
    },
    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'visible', name: 'Visible' },
            { id: 'hidden', name: 'Hidden' },
          ],
        },
      },
      default: 'visible',
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'markup',
    },

    discountedBankMarkup: {
      label: 'Discounted Bank Markup',
      datatype: 'float',
      default: 0,
      mandatory: false,
      allowNullValues: true,
      help: "If this is non-zero then it will override the bank's markup when booking a deal. It will only reduce the markup, not increase it.",
      validators: {
        isNumeric: { msg: 'Pips should be numeric' },
      },
      views: {
        create: canOverrideBankMarkup,
        edit: canOverrideBankMarkup,
        item: true,
        list: canOverrideBankMarkup,
        delete: false,
        csv: false,
      },
      group: 'markup',
    },
    maximumIntermediaryMarkup: {
      label: 'Maximum Intermediary Markup',
      datatype: 'float',
      default: 0,
      mandatory: false,
      allowNullValues: true,
      help: 'If this is set it will apply a maximum limit to the markup that an intermediary can take when booking a deal.',
      validators: {
        isNumeric: { msg: 'Pips should be numeric' },
      },
      views: {
        create: canOverrideBankMarkup,
        edit: canOverrideBankMarkup,
        item: true,
        list: false,
        delete: false,
        csv: false,
      },
      group: 'markup',
    },
    totalParentMarkup: {
      label: 'Total Parent Pips (including branches)',
      datatype: 'float',
      default: 0,
      mandatory: false,
      allowNullValues: true,
      property: { read: true, write: false, source: 'frontend', sort: 'none' },
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'markup',
    },
    totalMarkup: {
      label: 'Total Pips (including parents)',
      datatype: 'float',
      default: 0,
      mandatory: false,
      allowNullValues: true,
      property: { read: true, write: false, source: 'frontend', sort: 'none' },
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'markup',
    },
    bidRate: {
      label: 'Bid',
      datatype: 'float',
      property: { read: true, write: false, source: 'frontend', sort: 'none' },
      default: '0',
      mandatory: false,
      allowNullValues: true,
      views: {
        create: false,
        edit: false,
        item: true,
        list: true,
        delete: false,
        csv: false,
      },
      group: 'rate',
    },
    askRate: {
      label: 'Offer',
      datatype: 'float',
      property: { read: true, write: false, source: 'frontend', sort: 'none' },
      default: '0',
      mandatory: false,
      allowNullValues: true,
      views: {
        create: false,
        edit: false,
        item: true,
        list: true,
        delete: false,
        csv: false,
      },
      group: 'rate',
    },


    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },

    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
  },
};
