<template>
  <LDBModal result="info" :header="header" size="x-large">
    <template #body>
      <template v-if="clientAccountType === 'individual'">
        <p>
          <strong>Note: </strong>This is an Individual Client. Adding an Associated Party should be the exeption rather
          than the norm.
        </p>
        <p>Are you sure you want to add an Associated Party?</p>
        <capitec-toggle
          label="Yes, I'm sure"
          :checked="wantToAddAssociatedParty"
          @value-change="switch_wantToAddAssociatedParty"
        ></capitec-toggle>
      </template>
      <template v-if="clientAccountType === 'entity' || wantToAddAssociatedParty">
        <div class="flex-container">
          <div class="flex-item">
            <LDBField
              :key="newIndividualField_key"
              :field="newIndividualField"
              view="edit"
              :fieldname="newIndividualField.name"
              :definition="undefined"
              :record="newRecord"
              @change="on_change"
            />
          </div>
          <div class="flex-item">
            <LDBField
              :key="newEntityField_key"
              :field="newEntityField"
              view="edit"
              :fieldname="newEntityField.name"
              :definition="undefined"
              :record="newRecord"
              @change="on_change"
            />
          </div>
        </div>

        <LDBField
          v-for="(field, fieldname) in customFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
      </template>
    </template>

    <template #footer>
      <capitec-spacer />
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxAddAssociatedCif',
  components: { LDBField },
  props: {},
  data() {
    return {
      newRecord: {
        newIndividual: '',
        newEntity: '',
        accountType: '',
        name: '',
        identificationType: '',
        identification: '',
        firstName: '',
        middleName: '',
        surname: '',
      },
      wantToAddAssociatedParty: false,
    };
  },
  created() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    header() {
      return 'Add an Associated Party';
    },

    // ----- Fields -----

    newIndividualField() {
      let field = {
        label: 'Individual',
        name: 'newIndividual',
        datatype: {
          option: {
            optionType: 'text',
            options: [
              {
                id: 'rsaId',
                name: 'RSA ID Number',
              },
              {
                id: 'passport',
                name: 'Passport',
              },
            ],
          },
        },
        default: null,
        displayType: {
          datatype: 'option',
          display: 'radio',
        },
      };
      return field;
    },
    newIndividualField_key() {
      return `newIndividual-${this.newRecord.newEntity}`;
    },

    newEntityField() {
      let field = {
        label: 'Entity',
        name: 'newEntity',
        datatype: {
          option: {
            optionType: 'text',
            options: [
              {
                id: 'rsaRegistrationNumber',
                name: 'RSA Company',
              },
              {
                id: 'rsaNonCompanyRegistrationNumber',
                name: 'Other RSA Entity (e.g. Trust)',
              },
              {
                id: 'internationalRegistrationNumber',
                name: 'International Company ',
              },
            ],
          },
        },
        default: null,
        displayType: {
          datatype: 'option',
          display: 'radio',
        },
      };
      return field;
    },
    newEntityField_key() {
      return `newEntity-${this.newRecord.newIndividual}`;
    },

    identificationField() {
      let field = {
        name: 'identification',
        label: this.identificationField_label,
        datatype: 'text',
        mandatory: true,
        guards: true,
        enableGuards: this.hasSelectedClientOption,
        // TODO: add validator as applicable to identification selected
        // validators: {
        //   isNumeric: { msg: 'Please use only numeric charaters' },
        //   len: { msg: 'CIF Number should be 8 characters', args: [8, 8] },
        // },
      };
      return field;
    },
    identificationField_label() {
      let label = 'Identification';
      if (this.newRecord.newIndividual === 'rsaId') {
        label = 'RSA ID Number';
      } else if (this.newRecord.newIndividual === 'passport') {
        label = 'Passport Number';
      } else if (this.newRecord.newEntity === 'rsaRegistrationNumber') {
        label = 'RSA Company Registration Number';
      } else if (this.newRecord.newEntity === 'rsaNonCompanyRegistrationNumber') {
        label = 'RSA Non-Company Registration Number';
      } else if (this.newRecord.newEntity === 'internationalRegistrationNumber') {
        label = 'International Company Registration Number';
      }
      return label;
    },
    hasSelectedClientOption() {
      return this.newRecord.newIndividual || this.newRecord.newEntity ? true : false;
    },
    identificationField_key() {
      return `${this.newRecord.newIndividual}-${this.newRecord.newEntity}`;
    },

    customFields() {
      let allFields = [
        this.identificationField,
        {
          name: 'name',
          label: this.newRecord.newEntity ? 'Company Name' : 'Name',
          guards: !this.newRecord.newIndividual,
          enableGuards: this.hasSelectedClientOption,
          datatype: 'text',
        },
        {
          name: 'firstName',
          label: 'First Name',
          guards: this.newRecord.newIndividual,
          enableGuards: this.hasSelectedClientOption,
          datatype: 'text',
        },
        {
          name: 'middleName',
          label: 'Middle Name(s)',
          guards: this.newRecord.newIndividual,
          enableGuards: this.hasSelectedClientOption,
          datatype: 'text',
        },
        {
          name: 'surname',
          label: 'Surname',
          guards: this.newRecord.newIndividual,
          enableGuards: this.hasSelectedClientOption,
          datatype: 'text',
        },
      ];
      return allFields.filter((field) => field.guards);
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelAddAssociatedCif',
          label: 'Cancel',
          type: '',
          action: 'click',
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'addAssociatedCif',
          label: 'Add Associated Party',
          type: 'primary',
          action: 'addAssCifApplication',
          params: { accountId: this.accountId, applicationId: this.applicationId },
          actionParams: this.newRecord_actionParams,
          // enabledGuards: !(this.newRecord.has_validation_errors || this.requiredFieldsRemaining),
          enabledGuards: true,
          clickEvent(response) {
            if (response.status === 'success') {
              let routeName = self.$route.meta?.wizard
                ? 'clientApplicationWizardAssociatedCifDetailsEdit'
                : 'clientApplicationAssociatedCifDetailsEdit'; // TODO - make sure this is the right routeName
              self.$router.replace({ name: routeName, params: { cifId: response.response.id } });
            }
          },
        },
      ];
      return allButtons;
    },

    newRecord_actionParams() {
      let actionParams = {
        accountType: '',
        name: '',
        identificationType: '',
        identification: this.newRecord.identification,
        // additionalInfo: { firstName: '', middleName: '', surname: '' },
      };

      if (this.newRecord.newIndividual === 'rsaId') {
        actionParams.accountType = 'individual';
        actionParams.identificationType = 'rsaId';
        actionParams.additionalInfo = { firstName: '', middleName: '', surname: '' };
        actionParams.additionalInfo.firstName = this.newRecord.firstName;
        actionParams.additionalInfo.middleName = this.newRecord.middleName;
        actionParams.additionalInfo.surname = this.newRecord.surname;
        if (this.newRecord.firstName && this.newRecord.surname) {
          actionParams.name = this.newRecord.surname + ', ' + this.newRecord.firstName;
        }
      } else if (this.newRecord.newIndividual === 'passport') {
        actionParams.accountType = 'individual';
        actionParams.identificationType = 'passport';
        actionParams.additionalInfo = { firstName: '', middleName: '', surname: '' };
        actionParams.additionalInfo.firstName = this.newRecord.firstName;
        actionParams.additionalInfo.middleName = this.newRecord.middleName;
        actionParams.additionalInfo.surname = this.newRecord.surname;
        if (this.newRecord.firstName && this.newRecord.surname) {
          actionParams.name = this.newRecord.surname + ', ' + this.newRecord.firstName;
        }
      } else if (this.newRecord.newEntity === 'rsaRegistrationNumber') {
        actionParams.accountType = 'entity';
        actionParams.identificationType = 'rsaRegistrationNumber';
        actionParams.name = this.newRecord.name;
      } else if (this.newRecord.newEntity === 'rsaNonCompanyRegistrationNumber') {
        actionParams.accountType = 'entity';
        actionParams.identificationType = 'rsaNonCompanyRegistrationNumber';
        actionParams.name = this.newRecord.name;
      } else if (this.newRecord.newEntity === 'internationalRegistrationNumber') {
        actionParams.accountType = 'entity';
        actionParams.identificationType = 'internationalRegistrationNumber';
        actionParams.name = this.newRecord.name;
      }

      return actionParams;
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : 'todo'; //TODO: go to user's home as fallback
    },

    // ----- Definition -----

    definitionName() {
      return this.newRecord.accountType === 'individual'
        ? 'application_cif_individual'
        : this.newRecord.accountType === 'entity'
        ? 'application_cif_entity'
        : undefined;
    },

    definition() {
      // return getDefinition('client');

      return this.definitionName ? getDefinition(this.definitionName) : undefined;
    },
    clientDefinition() {
      return getDefinition('client');
    },

    // ----- API Path -----

    accountId() {
      return +this.$route.params.accountId;
    },
    clientRecord() {
      return this.account[this.accountId];
    },
    clientAccountType() {
      return this.clientRecord?.accountType;
    },

    applicationId() {
      return +this.$route.params.applicationId;
    },
    applicationRecord() {
      return this.account[this.accountId].application[this.applicationId];
    },

    // ----- Fields -----

    fieldNames() {
      let fieldNames = [];
      if (this.newRecord.accountType === 'individual') {
        fieldNames.push('firstName', 'middleName', 'surname');
      } else if (this.newRecord.accountType === 'enityt') {
        fieldNames.push('name');
      }
    },

    fields() {
      return this.definition?.fields;
    },
    view() {
      return 'addAssociatedCif';
    },
    viewFields() {
      console.log('this.fields', this.fields);
      let res = [];
      // for (let k in this.fields) {
      //   let field = this.fields[k];
      //   if (field.views[this.view] === true) {
      //     field.fieldname = k;
      //     res.push(field);
      //   }
      // }
      if (this.definitionName) {
        for (let k in this.fields) {
          let field = this.fields[k];
          if (field.views[this.view] === true) {
            field.fieldname = k;
            res.push(field);
          }
        }
      }
      return res;
    },
    fieldnames() {
      return this.viewFields.map((field) => field.name);
    },
    requiredFieldsRemaining() {
      return this.definitionName
        ? this.newRecord.required_fields_remaining.filter((f) => this.fieldnames.indexOf(f) >= 0).length > 0
        : true;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Fields -----

    // populateFieldDefaults() {
    //   for (let f = 0; f < this.viewFields.length; f++) {
    //     let field = this.viewFields[f];
    //     if (field.default) {
    //       this.newRecord[field.name] = field.default;
    //     }
    //   }
    //   // this.mounted = true;
    // },
    on_change({ field, value }) {
      this.newRecord[field] = value;

      switch (field) {
        case 'newIndividual':
          this.newRecord.newEntity = null;
          this.newRecord.identification = null;
          break;
        case 'newEntity':
          this.newRecord.newIndividual = null;
          this.newRecord.identification = null;
          break;
      }
    },
    switch_wantToAddAssociatedParty() {
      this.wantToAddAssociatedParty = !this.wantToAddAssociatedParty;
    },

    // ----- API calls -----

    async onAddAssociatedCifClick() {
      // let response = await this.applicationRecord.addAssociatedCif(this.newRecord);
      // TODO handle success / failure response
      // if (response.success) {
      //   this.$router.replace({ name: this.cancelRoute });
      // }
    },
  },
};
</script>

<style scoped>
div.flex-container {
  /* border: 1px dotted green; */
  width: 40vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  /* background-color: rgb(242, 241, 241); */
}
div.flex-item {
  /* border: 1px dotted blue; */
  /* margin: 10px; */
  /* padding: 10px; */
  flex-grow: 1;
  flex-basis: 250px;
  /* background-color: white; */
}
</style>
