<template>
  <div class="lfx-payment-processing-report">
    <capitec-content-box>
      <div>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          :view="field.view"
          :fieldname="field.name"
          :definition="undefined"
          :record="optionsAndSummaryRecord"
          @change="on_change"
        />
      </div>
      <capitec-action-bar>
        <LDBButton
          v-for="button in formButtons"
          :key="button.name"
          :label="button.label"
          :action="button.action"
          :actionParams="button.actionParams"
          :params="button.params"
          :type="button.type"
          :tooltip="button.tooltip"
          :modal="button.modal"
          :viewGuards="button.guards"
          :enabledGuards="button.enabledGuards"
          :onSuccess="button.onSuccess"
          @beforeClick="button.beforeClick"
          @click="button.onClick"
          @on-api-success="button.onApiSuccess"
          @on-api-error="button.onApiError"
        >
          <template v-if="button.modal !== undefined" #modal>
            <div v-html="button.modal.content"></div>
          </template>
        </LDBButton>
      </capitec-action-bar>
      <template v-if="dataStatus === 'retrieving'">
        <LDBLoader />
      </template>
      <template v-else-if="dataStatus === 'retrieved'">
        <div class="lfx-payment-processing-report-view-table-container">
          <table-lite
            id="lfx-payment-processing-report-view-table"
            :is-static-mode="true"
            :is-slot-mode="true"
            :has-checkbox="false"
            :is-loading="false"
            :is-re-search="false"
            :columns="tableColumns"
            :rows="sortedRecords"
            :pageSize="sortedRecords.length"
            :total="sortedRecords.length"
          >
          <template v-for="(col, i) of tableColumns" v-slot:[col.field]="data" :key="i">
              <template v-if="col.showAsCurrency">{{ formatCurrency(data.value[col.field]) }}</template>
              <!-- <template v-if="col.field?.amount">ZAR {{data.value[col.field].amount}}</template> -->
              <template v-else>{{ data.value[col.field] }}</template>
            </template>
          </table-lite>
        </div>
      </template>
    </capitec-content-box>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBField from '@/landobyte_vue/LDBField.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import TableLite from '@/views/general/TableLite.vue';
import { formatCurrencyString,formatTimeForHumans, roundTo } from '@/sharedUtils/LdbSharedUtils';

export default {
  name: 'LfxDealSummaryReport',
  components: { LDBField, LDBLoader, TableLite },
  props: {},
  data() {
    const today = new Date();
    return {
      selectorsUpdated: true,
      dataStatus: 'none',
      optionsRecord: {
        datePreset: 'completedThisMonth',
        dateFrom: new Date(today.getFullYear(), today.getMonth(), 1,12).toISOString(),
        dateTo: new Date().toISOString(),
      },
      payments: [],
      reportData: [],
      summaryData: {
        count: 0,
        submittedBeforeOriginalValueDate: 0,
        submittedOnOriginalValueDate: 0,
        averageSignatureToCompletion: 0
      }
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters(['currencies']),
    ...mapState(['account','authUser']),

    zarCurrency() {
      return this.currencies['ZAR']
    },
    optionsAndSummaryRecord() {
      return {
        datePreset: this.optionsRecord.datePreset,
        dateFrom: this.optionsRecord.dateFrom,
        dateTo: this.optionsRecord.dateTo,

        count: this.summaryData.count,
        submittedBeforeOriginalValueDate: this.summaryData.submittedBeforeOriginalValueDate,
        submittedOnOriginalValueDate: this.summaryData.submittedOnOriginalValueDate,
        percentageSubmittedBeforeOriginalValueDate: this.summaryData.count === 0 ? 0 : roundTo((100 * this.summaryData.submittedBeforeOriginalValueDate / this.summaryData.count),0) + " %",
        percentageSubmittedOnOriginalValueDate: this.summaryData.count === 0 ? 0 : roundTo((100 * this.summaryData.submittedOnOriginalValueDate / this.summaryData.count),0) + " %",
        averageSignatureToCompletion: this.summaryData.count === 0 ? 0 : this.formatTime(this.summaryData.averageSignatureToCompletion),
      }
    },
    // ----- Buttons -----

    formButtons() {
      let self = this;
      return [
        {
          name: 'generateReport',
          label: 'Generate Report',
          type: 'primary',
          action: 'paymentProcessingReport',
          onSuccess: { doStoreUpdate: false },
          actionParams: {
            dateFrom: this.optionsRecord.dateFrom,
            dateTo: this.optionsRecord.dateTo,
          },
          enabledGuards: this.selectorsUpdated === true,
          beforeClick() {
            self.dataStatus = 'retrieving';
          },
          onApiSuccess: self.onApiSuccess,
          onApiError() {
            self.dataStatus = 'error';
          },
        },
        {
          name: 'exportData',
          label: 'Download',
          type: 'primary',
          action: 'downloadPaymentProcessingReport',
          onSuccess: { doStoreUpdate: false },
          actionParams: {
            dateFrom: this.optionsRecord.dateFrom,
            dateTo: this.optionsRecord.dateTo,
          },
          guards: this.selectorsUpdated !== true && this.summaryData.count !== 0,
          click() {
            self.selectorsUpdated = true
          },
        },
      ];
    },
    // ----- Fields -----

    viewFields() {
      const allFields = {
        datePresets: {
          name: 'datePreset',
          label: 'Date Preset',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'completedYesterday', name: 'Completed Yesterday' },
                { id: 'completedThisWeek', name: 'Completed This Week' },
                { id: 'completedThisMonth', name: 'Completed This Month' },
                { id: 'completedLastMonth', name: 'Completed Last Month' },
                { id: 'other', name: 'Other' },
              ],
            },
          },
          view: 'edit',
          guards: true,
        },
        dateFrom: {
          name: 'dateFrom',
          label: 'Date From',
          datatype: {
            datetime: {
              type: 'date',
              format: 'human',
              minDate: '2014/01/01',
              maxDate: { type: 'day', offset: 0 },
            },
          },
          enableGuards: this.optionsRecord.datePreset === 'other',
          view: this.optionsRecord.datePreset === 'other' ? 'edit' : 'item',
          guards: true,
        },
        dateTo: {
          name: 'dateTo',
          label: 'Date To',
          datatype: {
            datetime: {
              type: 'date',
              format: 'human',
              minDate: '2014/01/01',
              maxDate: this.toMaxDate,
            },
          },
          enableGuards: this.optionsRecord.datePreset === 'other',
          view: this.optionsRecord.datePreset === 'other' ? 'edit' : 'item',
          guards: true,
        },

        count: {
          name: 'count',
          label: 'Payment Count',
          datatype: 'integer',
          view: 'item',
          guards: this.dataStatus === 'retrieved',
        },
        submittedBeforeOriginalValueDate: {
          name: 'submittedBeforeOriginalValueDate',
          label: 'Submitted Before Value Date',
          datatype: 'integer',
          view: 'item',
          guards: this.dataStatus === 'retrieved',
        },
        submittedOnOriginalValueDate: {
          name: 'submittedOnOriginalValueDate',
          label: 'Submitted On Value Date',
          datatype: 'integer',
          view: 'item',
          guards: this.dataStatus === 'retrieved',
        },
        percentageSubmittedBeforeOriginalValueDate: {
          name: 'percentageSubmittedBeforeOriginalValueDate',
          label: '% Submitted Before Value Date',
          datatype: 'integer',
          view: 'item',
          guards: this.dataStatus === 'retrieved',
        },
        percentageSubmittedOnOriginalValueDate: {
          name: 'percentageSubmittedOnOriginalValueDate',
          label: '% Submitted On Value Date',
          datatype: 'integer',
          view: 'item',
          guards: this.dataStatus === 'retrieved',
        },
        averageSignatureToCompletion: {
          name: 'averageSignatureToCompletion',
          label: 'Ave. Submission to Completion',
          datatype: 'integer',
          view: 'item',
          guards: this.dataStatus === 'retrieved',
        },
      };
      const returnFields = {};
      for (const f in allFields) {
        if (allFields[f].guards !== false) {
          returnFields[f] = allFields[f];
        }
      }
      return returnFields;
    },
    toMaxDate() {
      return { type: 'day', offset: 0 };
    },

    // ----- TABLE -----
    tableColumns() {
      let self = this;
      let res = [
        {
          col: 'paymentColumn',
          field: 'reference',
          label: 'Reference',
          isKey: false,
          sortable: false,
          guards: true,
        },
        {
          col: 'dealNumbersColumn',
          field: 'dealNumbers',
          label: 'Deal Numbers',
          isKey: false,
          sortable: false,
          guards: true,
        },
        {
          col: 'clientColumn',
          field: 'clientName',
          label: 'Client',
          isKey: false,
          sortable: false,
          guards: true,
        },
        {
          col: 'intermediaryColumn',
          field: 'intermediaryName',
          label: 'Intermediary',
          isKey: false,
          sortable: false,
          guards: this.authUser.accountLevel === 'bank',
        },
        {
          col: 'branchColumn',
          field: 'branchName',
          label: 'Branch',
          isKey: false,
          sortable: false,
          guards: this.authUser.accountLevel !== 'bank',
        },
        {
          col: 'paymentCurrencyColumn',
          field: 'paymentCurrency',
          label: 'Currency',
          isKey: false,
          sortable: false,
          guards: true,
        },
        {
          col: 'paymentAmountColumn',
          field: 'paymentAmount',
          label: 'Amount',
          isKey: false,
          sortable: false,
          guards: true,
        },
        {
          col: 'originalValueDateColumn',
          field: 'originalValueDate',
          label: 'Original Value Date',
          isKey: false,
          sortable: false,
          guards: true,
        },
        {
          col: 'valueDateColumn',
          field: 'valueDate',
          label: 'Value Date',
          isKey: false,
          sortable: false,
          guards: true,
        },
        {
          col: 'submittedAtColumn',
          field: 'submittedAt',
          label: 'Submitted At',
          isKey: false,
          sortable: false,
          guards: true,
        },
        {
          col: 'signedAtColumn',
          field: 'signedAt',
          label: 'Signed At',
          isKey: false,
          sortable: false,
          guards: true,
        },
        {
          col: 'completedAtedAtColumn',
          field: 'completedAt',
          label: 'Completed At',
          isKey: false,
          sortable: false,
          guards: true,
        },
        {
          col: 'submittedBeforeOriginalValueDateColumn',
          field: 'submittedBeforeOriginalValueDate',
          label: 'Sub. Before Value Date',
          isKey: false,
          sortable: false,
          guards: true,
        },
        {
          col: 'submittedOnOriginalValueDateColumn',
          field: 'submittedOnOriginalValueDate',
          label: 'Sub. On Value Date',
          isKey: false,
          sortable: false,
          guards: true,
        },
        {
          col: 'signatureToCompletionColumn',
          field: 'signatureToCompletion',
          label: 'Signature To Completion',
          isKey: false,
          sortable: false,
          guards: true,
        },
      ];
      return res.filter((f) => f.guards === true);
    },
    sortedRecords() {
      return this.reportData
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    on_change({ field, value }) {
      this.optionsRecord[field] = value;
      if (field === 'datePreset') {
        this.updateFromPreset(value);
      }
      this.selectorsUpdated = true;
      this.dataStatus = 'none'
      this.reportData = [];
      this.summaryData = {
        count: 0,
        submittedBeforeOriginalValueDate: 0,
        submittedOnOriginalValueDate: 0,
        totalSignatureToCompletion: 0
      }
    },
    onApiSuccess(response) {
      this.payments = response.response?.payments || [];
      this.summaryData = {
        count: response.response.summary.numberOfPayments,
        submittedBeforeOriginalValueDate: response.response.summary.numberSubmittedBeforeVd || 0,
        submittedOnOriginalValueDate: response.response.summary.numberSubmittedOnVd || 0,
        averageSignatureToCompletion: response.response.summary.averageProcessSeconds || 0
      }
      this.dataStatus = 'retrieved';
      this.selectorsUpdated = false;
      this.populateReportData()
    },
    updateFromPreset(newPreset) {
      const today = new Date();
      switch (newPreset) {
        case 'completedYesterday':
          const previousValidTradingDate = this.zarCurrency.previousValidValueDate(today);
          this.optionsRecord.dateFrom = previousValidTradingDate.toISOString();
          this.optionsRecord.dateTo = previousValidTradingDate.toISOString();
          break;
        case 'completedThisWeek':
          this.optionsRecord.dateFrom = new Date(
            new Date(today).setDate(today.getDate() - ((today.getDay() + 6) % 7))
          ).toISOString();
          this.optionsRecord.dateTo = today.toISOString();
          break;
        case 'completedThisMonth':
          this.optionsRecord.dateFrom = new Date(today.getFullYear(), today.getMonth(), 1,12).toISOString();
          this.optionsRecord.dateTo = today.toISOString();
          break;
        case 'completedLastMonth':
          this.optionsRecord.dateFrom = new Date(today.getFullYear(), today.getMonth() - 1, 1,12).toISOString();
          this.optionsRecord.dateTo = new Date(today.getFullYear(), today.getMonth(), 0,12).toISOString();
          break;
      }
    },
    formatCurrency(currencyString) {
      return formatCurrencyString(+currencyString)
    },
    formatTime(miliseconds) {
      return formatTimeForHumans(miliseconds,'hours')
    },
    populateReportData() {
      this.reportData = [];
      const res = []
      for (const payment of this.payments) {
        this.reportData.push({
          paymentId: payment.id,
          clientName: payment.clientName,
          intermediaryName: payment.intermediaryName,
          branchName: payment.branchName,
          reference: payment.reference,
          dealNumbers: this.getDealNumbers(payment),
          paymentCurrency: payment.paymentCurrency,
          paymentAmount: this.formatCurrency(payment.paymentAmount),
          originalValueDate: payment.originalValueDate?.substring(0,10) || "",
          valueDate: payment.valueDate?.substring(0,10) || "",
          submittedAt: payment.submittedAt?.substring(0,16) || "",
          signedAt: payment.signedAt?.substring(0,16) || "",
          completedAt:payment.completedAt?.substring(0,16) || "",
          submittedBeforeOriginalValueDate:payment.submittedBeforeVd ? "Yes" : "No",
          submittedOnOriginalValueDate:payment.submittedOnVd ? "Yes" : "No",
          signatureToCompletion:this.formatTime(payment.processSeconds),
        })
      }
    },
    getDealNumbers(payment) {
      if (!payment.dealNumbers || payment.dealNumbers.length === 0) {
        return "N/A"
      }
      const dealNumberArray = [];
      for (const dealNumber of payment.dealNumbers) {
        dealNumberArray.push(dealNumber)
      }
      return dealNumberArray.join(", ")
    },
  },
};
</script>

<style>
.lfx-payment-processing-report-view-table-container {
  position: relative;
  min-height: 320px;
}

#lfx-payment-processing-report-view-table {
  min-width: 100%;
  min-height: 100px;
}

div.lfx-payment-processing-report {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
</style>
