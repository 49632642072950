// Import the required libraries
import store from '@/store';
import CryptoJS from 'crypto-js';
import Base64 from 'crypto-js/enc-base64';
import { ENV_VARIABLES } from '@/store/config_env';

const ALLOW_URL_SHARING_BETWEEN_USERS_OF_THE_SAME_ORGANIZATION:boolean = true;
const ALLOW_URL_SHARING_BETWEEN_ALL_USERS_OF_THE_SYSTEM:boolean = true;
const ENCODE_URLS_WITH_BASE64_ONLY:boolean = false;

const searchTermUrlEncryptionKey = () => {
  const uuid = store.state.auth!.uuid;
  const accountUuid = store.state.auth!.accountUuid;
  const DEFAULT_KEY_WHEN_NO_KEY_IS_LOADED_IN_THE_CONFIG = 'DB748A3616C1911E1015B18AEB70F151';
  const searchTermKeyFromConfigFile =  ENV_VARIABLES?.SEARCH_TERM_URL_ENCRYPTION_KEY || DEFAULT_KEY_WHEN_NO_KEY_IS_LOADED_IN_THE_CONFIG;
  const SALTU = ["1jK*Vv6C@h","R#Q2y&bP8u","7!ZwM5uNrP","d7N@fU$eMq","X9i@hV6Wcj"]; // Salts for the UUIDs;
  const SALTA = ["5#Gh*ptLzK","4C1^tQa2Lf","9$xKl!eJmA","o@GyS*R8Ts","pY7Jk$3UbB"]; // Salts for the AccountUUIDs;
  const SALTK = ['&1gFp@x9Zq','2!hGP#y8zQ','3$iHr%Y7xR','4*jIs(X6Wt','5(kkJT)y7vU']; // Salts for the keys;
  const SALTE = ['6)lKu-V4Mv','7_mLo+N3nO','8{dDq}[^2sC','9}fEp[1eBS','0[cAr]:0aS']; // Salts for the endings; 
  const partSize = Math.ceil(searchTermKeyFromConfigFile.length / 5); // Calculate the size of each part of the key in the config file;
  const keyParts = [];
  let startIndex = 0;
  // Split the key from the config file into 5 parts like the other arrays. 
  for (let i = 0; i < 5; i++) {
    const part = searchTermKeyFromConfigFile.substring(startIndex, startIndex + partSize); // Extract part from the original text
    keyParts.push(part); // Push the part to the array
    startIndex += partSize; // Move the start index for the next part
  }
  const [KEY1,KEY2,KEY3,KEY4,KEY5] = keyParts; // assign the keyparts
  const scrambledKeys = [KEY4,KEY2,KEY3,KEY5,KEY1]; // scramble the key parts
  let scrambledUIDs:string[];
  let scrambledAIDs:string[];
  if (ALLOW_URL_SHARING_BETWEEN_USERS_OF_THE_SAME_ORGANIZATION) {
    const [UID1,UID2,UID3,UID4,UID5] = uuid.split('-'); // Split the UUIDs
    scrambledUIDs = [UID2, UID4, UID1, UID5, UID3]; //Scramble the UUIDs: : UNCOMMENT THIS LINE TO PREVENT THE SHARING OF URLS BETWEEN USERS
  } else 
  {
    scrambledUIDs = SALTU; // Use the uuid salts as uuids when url sharing is not allowed;
  }
  if (ALLOW_URL_SHARING_BETWEEN_ALL_USERS_OF_THE_SYSTEM) {
    const [AID1,AID2,AID3,AID4,AID5] = accountUuid.split('-');// Split the accountUUIDs
    scrambledAIDs = [AID4, AID2, AID5, AID1, AID3];// Scramble the accountUUIDs; UNCOMMENT THIS LINE TO PREVENT THE SHARING OF URLS BETWEEN USERS;
  } else 
  {
    scrambledAIDs = SALTA; // USE THE Account salts as accountUuid system sharing is enabled;
  }
  let searchEncryptionKeySections = [];
  for (let i = 0; i < 5 ; i ++ ) {
    let section = `${SALTU[i]}${scrambledUIDs[i]}${SALTK[i]}${scrambledKeys[i]}${SALTA[i]}${scrambledAIDs[i]}${SALTE[i]}`;
    searchEncryptionKeySections.push(section);
  }
  let searchEncryptionKey = searchEncryptionKeySections.join('');
  return searchEncryptionKey;
 }

// Function to convert a JSON object to string, encrypt it, and convert to Base64
export const encryptJSONToBase64 = (jsonObj: object): string => {
  if (ENCODE_URLS_WITH_BASE64_ONLY) {
    const jsonString = JSON.stringify(jsonObj);
    const base64 = Base64.stringify(CryptoJS.enc.Utf8.parse(jsonString));
    return base64;  
  }
  else 
  {
    const encryptionKey = searchTermUrlEncryptionKey();
    const jsonString = JSON.stringify(jsonObj);
    const encrypted = CryptoJS.AES.encrypt(jsonString, encryptionKey).toString();
    const base64 = Base64.stringify(CryptoJS.enc.Utf8.parse(encrypted));
    return base64;  
  }
};

// Function to decode Base64, decrypt to string, and convert to JSON object
export const decryptBase64ToJSON = (base64: string): object|undefined => {
  if (ENCODE_URLS_WITH_BASE64_ONLY) {
    const encoded = CryptoJS.enc.Base64.parse(base64).toString(CryptoJS.enc.Utf8);
    try {
      const jsonObj = JSON.parse(encoded);
      return jsonObj;    
    } catch (error) {
      return undefined
    }
  }
  else 
  {
    const encryptionKey = searchTermUrlEncryptionKey();
    const encrypted = CryptoJS.enc.Base64.parse(base64).toString(CryptoJS.enc.Utf8);
    const decrypted = CryptoJS.AES.decrypt(encrypted, encryptionKey).toString(CryptoJS.enc.Utf8);
    try {
      const jsonObj = JSON.parse(decrypted);
      return jsonObj;    
    } catch (error) {
      return undefined
    }
  }
};


if (store.getters.isDevEnv) {
//@ts-ignore
window.enc = encryptJSONToBase64;
//@ts-ignore
window.dec = decryptBase64ToJSON;
//@ts-ignore
window.cjs = CryptoJS
//@ts-ignore 
window.b64 = Base64;
//@ts-ignore 
window.key = searchTermUrlEncryptionKey;
}

