//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LdbTestDef.ts
//  Author      : Bevan Timm
//  Description : This module defines an Account record for the LandoFX system
//  Created     : 15 July 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition, LdbDbObject } from './LdbInterfaces';

// import {isValidRsaId} from '../sharedUtils/LdbValidations';

//----------------------------------------------------------------------------------------------------------------------

export type LdbTestStatus = 'new' | 'submitted' | 'rejected' | 'closed' | 'active';

//----------------------------------------------------------------------------------------------------------------------

export interface LdbTestInt {
  id?: number;
  uuid?: string;
  accountId?: number;
  intermediarySettingsId?: number;
  reference?: string;
  name?: string;
  identification?: string;
  cifNumber?: string;
  externalReference?: string;
  status?: LdbTestStatus;
  tradingEnabled?: boolean;
  lastTradeTimstamp?: Date;
  signatoryOrder?: number[];
  note?: string;
  restrictedView?: boolean;
  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

export const LdbTestDef: LdbDefinition = {
  apiUrl() {
    return `/account`;
  },
  title: 'Account',
  table: 'LdbTest',
  collectionPath: '/test',
  view: { create: false, edit: true, item: true, list: true, delete: false, csv: false },
  indexes: [{ unique: 'uuid' }],
  pagingType: 'backEnd',
  fields: {
    // details:{
    //   datatype:'group',
    //   label:'Details',
    //   property:{read:true,write:false},
    //   views: { create: false, edit: false, item: 1, list: true, delete: false, csv: false },
    //   order: {default:undefined,list:10},
    // },
    id: {
      label: 'Name',
      datatype: { id: { descriptionField: 'name' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      order: { default: undefined, list: 10 },
    },

    accountId: {
      label: 'Parent',
      datatype: {
        foreignKey: {
          linkTable: 'LdbTest',
          linkField: 'id',
          collection: 'fk_test_my_foreign_key_collections',
          displayField: 'name',
          guards: {
            id(record: LdbDbObject) {
              return { notEq: record.id };
            },
          },
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      order: { default: undefined, list: 20 },
    },

    accountLevel: {
      label: 'Account Level',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'bank', name: 'Bank' },
            { id: 'intermediary', name: 'Intermediary' },
            { id: 'intermediaryBranch', name: 'Intermediary Branch' },
            { id: 'client', name: 'Client' },
          ],
        },
      },
      default: 'client',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },

    accountType: {
      label: 'Account Type',
      search: true,
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'entity', name: 'Entity' },
            { id: 'individual', name: 'Individual' },
          ],
        },
      },
      default: 'entity',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },

    reference: {
      label: 'Reference',
      search: true,
      datatype: 'text',
      default: null,
      mandatory: true,
      validators: {},
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },

    currency: {
      label: 'Currency',
      search: false,
      default: null,
      mandatory: true,
      validators: {},
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      datatype: { currency: { symbol: 'ZAR' } },
    },

    float: {
      label: 'Float',
      search: false,
      default: null,
      mandatory: true,
      validators: {},
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      // datatype: {float:{decimalPlaces:10}} // avr 2023-03-14 - removed this datatype as it might blow up the DB
      datatype: 'float',
    },

    percentage: {
      label: 'Percentage',
      autocomplete:'markup',
      search: false,
      default: null,
      mandatory: true,
      validators: {},
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      datatype: 'percentage',
    },

    name: {
      autocomplete:'name',
      label: 'Name',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      validators: {
        jannie: {
          msg: 'Name must be "Jannie"',
          args: function (value: string) {
            return value === 'Jannie';
          },
        },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
    },

    email: {
      autocomplete:'email',
      label: 'E-Mail',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      validators: {
        isEmail: { msg: 'The email address "{{value}}" is not a valid email address for {{name}}.' },
      },
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
    },

    identificationType: {
      label: 'Identification Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'rsaRegistrationNumber', name: 'RSA Company Registration Number' },
            {
              id: 'internationalRegistrationNumber',
              name: 'International Company Registration Number',
            },
            { id: 'rsaId', name: 'RSA ID Number' },
            { id: 'passport', name: 'Passport' },
            { id: 'rsaNonCompanyRegistrationNumber', name: 'Other RSA Registration Number' },
          ],
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: false },
    },
    identification: {
      autocomplete:'IDNumber',
      label: 'Identification',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      // validators:{isValidRsaId:{args:isValidRsaId,msg:'The ID number is invalid'}},
      validators: { isValidRsaId: true },
      views: { create: false, edit: true, item: true, list: true, delete: false, csv: false },
    },

    dateOfBirth: {
      autocomplete:'bday',
      label: 'Date of Birth',
      datatype:'text',
      // datatype: {
      //   datetime: {
      //     type: 'date',
      //     format: 'human',
      //     input: 'calendar',
      //     minDate: (record: LdbDbObject) => {
      //       return new Date((new Date().getTime() / 1000 / 60 / 60 / 24 - 7) * 24 * 60 * 60 * 1000).toISOString();
      //     },
      //     maxDate: (record: LdbDbObject) => {
      //       return new Date((new Date().getTime() / 1000 / 60 / 60 / 24 + 7000) * 24 * 60 * 60 * 1000).toISOString();
      //     },
      //   },
      // },
      search: true,
      default: null,
      mandatory: true,
      // validators:{isValidRsaId:{args:isValidRsaId,msg:'The ID number is invalid'}},
      validators: { isValidRsaId: true },
      views: { create: false, edit: true, item: true, list: true, delete: false, csv: false },
    },

    timeOfBirth: {
      
      label: 'Time of Birth',
      datatype: {
        datetime: {
          type: 'time',
          format: 'human',
          input: 'input'        
        },
      },
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: true, item: true, list: true, delete: false, csv: false },
    },


    dateOfId: {
      label: 'Date of ID',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
          input: 'calendar',
          minDate: (record: LdbDbObject) => {
            return new Date((new Date().getTime() / 1000 / 60 / 60 / 24 - 7) * 24 * 60 * 60 * 1000)
              .toISOString()
              .split('T')[0]
              .split('-')
              .join('/');
          },
          maxDate: (record: LdbDbObject) => {
            return new Date((new Date().getTime() / 1000 / 60 / 60 / 24 + 7) * 24 * 60 * 60 * 1000)
              .toISOString()
              .split('T')[0]
              .split('-')
              .join('/');
          },
        },
      },
      search: true,
      default: null,
      mandatory: true,
      validators: {
        jannie: {
          msg: 'Date must be in the future...',
          args: function (value: string) {
            return new Date(value).getTime() > new Date().getTime();
          },
        },
      },
      views: { create: false, edit: true, item: true, list: true, delete: false, csv: false },
    },

    comissionAccount: {
      label: 'Comission Account',
      datatype: 'text',
      search: true,
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      default: null,
      mandatory: true,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: false },
    },

    organisation: {
      label: 'Organisation',
      datatype: 'text',
      search: true,
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      default: null,
      mandatory: true,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: false },
    },

    externalReference: {
      label: 'External Reference',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      validators: { len: [0, 100] },
      views: { create: false, edit: true, item: true, list: true, delete: false, csv: false },
    },

    status: {
      label: 'Status',
      search: true,
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'new', name: 'New' },
            { id: 'submitted', name: 'Submitted' },
            { id: 'rejected', name: 'Rejected' },
            { id: 'closed', name: 'Closed' },
            { id: 'active', name: 'Active' },
          ],
        },
      },
      default: 'new',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    tradingEnabled: {
      label: 'Trading Enabled',
      datatype: 'boolean',
      default: false,
      mandatory: false,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    lastTradeTimstamp: {
      label: 'Last Trade',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    lastTradeDay: {
      label: 'Last Trade Day',
      datatype: {
        datetime: {
          type: 'date',
          input: 'input',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    bopFormSignatoryOption: {
      label: 'BOP Form Signatory Option',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'client', name: 'Client' },
            { id: 'intermediary', name: 'Intermediary' },
            { id: 'submittingUser', name: 'SubmittingUser' },
            { id: 'intermediaryAndClient', name: 'IntermediaryAndClient' },
          ],
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: true, item: false, list: false, delete: false, csv: false },
    },

    note: {
      label: 'Note',
      datatype: 'memo',
      search: true,
      default: null,
      mandatory: true,
      validators: { len: [5, undefined] },
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: false },
    },
    visibility: {
      label: 'Visibility',
      search: false,
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'visible', name: 'Visible' },
            { id: 'hidden', name: 'Hidden' },
          ],
        },
      },
      default: 'visible',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },

    restrictedView: {
      label: 'Restricted View',
      datatype: 'text',
      search: true,
      property: { source: 'backend', read: true, write: false, sort: 'none' },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },

    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },

    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
  },
};

//----------------------------------------------------------------------------------------------------------------------

// Tracking
// activationTimstamp:Date, // ?
// activatingUser:number, // ?

// rejectionTimstamp:Date, // ?
// rejectingUser:number, // ?
// rejectionReason:string, // ?

// Integration
// esb_kyc_transaction_id
// esb_transaction_id
// esb_kyc_status
// esb_cif_status
// inter_esb_system_user

// General Options
// parent_spread_discount
// default_bop_category_inward
// default_bop_category_outward

// Intermediary Options
// inter_may_freeze_rate
// inter_must_review_bop
// inter_may_process_bulk_fees

// Client Options
// trader_type - importer/exporter/both
// client_allowed_value_dates
// credit_limit???
// account_allow_formward
// inter_may_modify_beneficiaries
// email_client_directly
// intermediary_note
