//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxBopCatGroupDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a BOP Category Group record for LandoByte TS systems
//  Created     : 11 November 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import { standardTextWhiteList } from '../sharedUtils/LdbValidations';
import type { LdbDefinition } from './LdbInterfaces';
import type { LfxPaymentDirection } from './LfxPaymentDef';

//----------------------------------------------------------------------------------------------------------------------

export type LfxBopCatClientType = 'individual' | 'company' | 'either';
export type LfxBopCatGroupStatus = 'active' | 'disabled';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxBopCatGroupInt {
  id?: number;
  name?: string;

  direction?: LfxPaymentDirection;
  clientType?: LfxBopCatClientType;
  status?: LfxBopCatGroupStatus;
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxBopCatGroupDef: LdbDefinition = {
  title: 'BOP Category Group',
  table: 'LfxBopCatGroup',
  collectionPath: '/config/1/bopCatGroup',
  view: { create: false, edit: true, item: true, list: true, delete: false, csv: false },
  indexes: [],
  pagingType: 'frontEnd',
  fields: {
    id: {
      label: 'ID',
      datatype: { id: { descriptionField: 'name' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    name: {
      label: 'Name',
      datatype: 'text',
      default: null,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alpha charaters', args: [standardTextWhiteList] },
        len: { msg: 'Name should be between 5 and 250 characters', args: [5, 250] },
      },
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    direction: {
      label: 'Direction',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'inward', name: 'inward' },
            { id: 'outward', name: 'outward' },
            { id: 'transfer', name: 'transfer' },
          ],
        },
      },
      default: 'inward',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    clientType: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'individual', name: 'Individual' },
            { id: 'company', name: 'Company' },
            { id: 'either', name: 'Either' },
          ],
        },
      },
      default: 'either',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'active', name: 'Active' },
            { id: 'disabled', name: 'Disabled' },
          ],
        },
      },
      default: 'new',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
  },
};
