<template>
  <LDBItemsView
    v-if="hasApplicationRecordLoaded"
    tileView="default"
    :records="cifRecords"
    :record="record"
    :componentName="componentName"
    :view="view"
    :showBackButton="showBackButton"
    @on-back="onBack"
    :action="action"
    :params="params"
    @on-save="onSave"
    :showNextButton="showNextButton"
    @on-next="onNext"
    :secondComponentName="secondComponentName"
    :secondRecord="secondRecord"
    :secondView="secondView"
    @on-tile-click="onTileClick"
  />
  <template v-else>
    <LDBLoader />
  </template>

  <router-view></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import LDBItemsView from '@/landobyte_vue/LDBItemsView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

export default {
  name: 'LfxClientApplicationCifDetails_v2',
  components: { LDBItemView, LDBItemsView, LDBLoader },
  props: {
    // view: { type: String, default: 'item', required: false },
    // record: { type: Object, required: false },
    allowModification: {type: Boolean, default: true, requied: false}
  },
  emits: ['on-back', 'on-next'],
  data() {
    return {};
  },
  created() {
    this.cifRecord?.activate();
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    isWizard() {
      return this.$route.meta?.wizard;
    },

    renderHolder() {
      return false;
    },

    // ----- Component -----

    componentName() {
      return 'LDBItemView';
    },
    record() {
      return this.cifDetailsRecord;
    },
    view() {
      if (!this.allowModification) {
        return 'item'
      }
      return this.cifRecord?.status === 'cancelled' ? 'item' : this.$route.meta?.content?.view || 'item';
    },
    action() {
      return this.cifAccountType === 'individual'
        ? 'updateApplicationCifIndividual'
        : this.cifAccountType === 'entity'
        ? 'updateApplicationCifEntity'
        : 'click';
    },

    // ----- Second component -----

    secondComponentName() {
      return this.view !== 'item' ? undefined : 'LDBItemView';
    },
    secondRecord() {
      return this.view !== 'item' ? undefined : this.cifRecord;
    },
    secondView() {
      return this.view;
    },

    // ----- Account Record -----

    clientId() {
      return +this.$route.params.accountId;
    },
    clientRecord() {
      return this.account[this.clientId];
    },

    // ----- Application Record -----

    applicationId() {
      return +this.$route.params.applicationId;
    },
    applicationRecord() {
      return this.clientRecord?.application?.[this.applicationId];
    },

    hasApplicationRecordLoaded() {
      return this.applicationRecord?.definition ? true : false;
    },

    // ----- CIF Record -----

    cifId() {
      return +this.$route.params.cifId;
    },
    cifRecord() {
      return this.applicationRecord?.application_cif?.[this.cifId];
    },

    isFullCifRecord() {
      return this.applicationRecord?.fullCifId === this.cifId;
    },
    cifAccountType() {
      return this.cifRecord?.accountType;
    },

    // ----- CIF Records -----

    cifRecords() {
      return this.isFullCifRecord
        ? {
            [this.applicationRecord?.fullCifId]:
              this.applicationRecord?.application_cif?.[this.applicationRecord?.fullCifId],
          }
        : this.applicationRecord.associatedCifs;
    },

    // ----- CIF Details Record -----

    cifDetailsKey() {
      return `application_cif_${this.cifAccountType}`;
    },
    cifDetailsId() {
      let obj = this.cifRecord?.[this.cifDetailsKey] || {};
      let key = Object.keys(obj)[0];
      return +key;
    },
    cifDetailsRecord() {
      return this.cifRecord?.[this.cifDetailsKey]?.[this.cifDetailsId];
    },
    cifDetailsDefinition() {
      return getDefinition(this.cifDetailsKey);
    },

    // ----- Form Buttons -----

    showBackButton() {
      return this.$route.meta?.wizard && !this.isFullCifRecord;
    },
    showNextButton() {
      return this.$route.meta?.wizard && true;
    },

    // ----- Action -----

    params() {
      let individualParams = {
        accountId: this.clientId,
        applicationId: this.applicationId,
        cifId: this.cifId,
        cifIndividualId: this.cifDetailsId,
      };
      let entityParams = {
        accountId: this.clientId,
        applicationId: this.applicationId,
        cifId: this.cifId,
        cifEntityId: this.cifDetailsId,
      };
      return this.cifAccountType === 'individual' ? individualParams : entityParams;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    onBack() {
      this.$emit('on-back');
    },
    onSave() {
      if (!this.isWizard) {
        this.$router.go(-1);
      }
    },
    onNext(evt) {
      this.$emit('on-next');
    },

    onTileClick(record) {
      let routeName = 'NotFound';
      switch (record.cifType) {
        case 'full':
          if (this.isWizard) {
            routeName = 'clientApplicationWizardClientCifDetailsEdit';
          } else if (this.view === 'item') {
            routeName = 'clientApplicationClientCifDetailsView';
          } else if (this.view === 'edit') {
            routeName = 'clientApplicationClientCifDetailsEdit'; // TODO - for normal edit view not in wizard
          }
          break;
        case 'associated':
          if (this.isWizard) {
            routeName = 'clientApplicationWizardAssociatedCifDetailsEdit';
          } else if (this.view === 'item') {
            routeName = 'clientApplicationAssociatedCifDetailsView';
          } else if (this.view === 'edit') {
            routeName = 'clientApplicationAssociatedCifDetailsEdit'; // TODO - for normal edit view not in wizard
          }
          break;
      }
      this.$router.replace({ name: routeName, params: { cifId: record.id } });
    },
  },
};
</script>
