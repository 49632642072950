<template>
  <div>
    <template v-if="!hasAssociatedCifs">
      <p>No Associated Parties have been added to this Application.</p>
    </template>

    <!-- <template v-else-if="!hasActiveAssociatedCifs">
      <p>All the Associated Parties that have been added to this Application have been cancelled.</p>
    </template> -->
  </div>

  <div>
    <template v-if="clientAccountType === 'entity'">
      <p>At least one Associated Party must be added to the Application for this Entity Client.</p>
      <p>More Associated Parties may be added as appropriate for the Client.</p>
    </template>

    <template v-else-if="clientAccountType === 'individual' && canAddAssociatedCif && isWizard">
      <p>
        One or more Associated Parties may be added for this Individual Client as appropriate, e.g. a legal guardian, or
        a parent for a minor client.
      </p>
      <p>
        <strong>Note: </strong>Adding Associated Parties for Individual Clients should be the exeption rather than the
        norm.
      </p>
      <p>If no Associated Parties are required, click the <strong>Skip</strong> button to go to the next Step.</p>
    </template>
  </div>

  <template v-if="canAddAssociatedCif && isWizard">
    <capitec-action-bar>
      <capitec-button
        v-for="button in leftButtons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        @click="button.clickEvent"
      ></capitec-button>
      <capitec-spacer />
      <capitec-button
        v-for="button in rightButtons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        @click="button.clickEvent"
      ></capitec-button>
    </capitec-action-bar>
  </template>

  <router-view></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxClientApplicationNoAssociatedCifs',
  components: { LDBField },
  props: {},
  emits: ['on-back', 'on-next'],
  created() {},
  mounted() {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['permissions']),
    ...mapState(['account']),

    // ----- Buttons -----

    leftButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'back',
          label: 'Back',
          type: 'clear',
          guards: true,
          clickEvent() {
            self.onBack();
          },
        },
      ];
      return allButtons;
    },

    rightButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'addAssociatedCif',
          label: 'Add Associated CIF',
          type: this.clientAccountType === 'entity' ? 'primary' : '',
          guards: false && this.applicationRecord?.applicationIsEditable,
          clickEvent() {
            self.$router.push({ name: 'clientApplicationAssociatedCifAdd' });
          },
        },
        {
          name: 'next',
          label: 'Skip',
          type: 'primary',
          guards: this.clientAccountType === 'individual',
          clickEvent() {
            self.onNext();
          },
        },
      ];
      return allButtons.filter((button) => button.guards);
    },

    isWizard() {
      return this.$route.meta?.wizard;
    },

    // ----- Account Record -----

    clientId() {
      return +this.$route.params.accountId;
    },
    clientRecord() {
      return this.account[this.clientId];
    },

    clientAccountType() {
      return this.clientRecord?.accountType;
    },

    // ----- Application Record -----

    applicationId() {
      return +this.$route.params.applicationId;
    },
    applicationRecord() {
      return this.clientRecord?.application?.[this.applicationId];
    },

    hasAssociatedCifs() {
      return this.applicationRecord?.hasAssociatedCifs;
    },
    // hasActiveAssociatedCifs() {
    //   return this.applicationRecord?.hasActiveAssociatedCifs;
    // },

    // ----- Associated CIF -----

    canAddAssociatedCif() {
      return this.permissions.addAssCifApplication &&
        (this.applicationRecord.status === 'new' ||
          this.applicationRecord.status === 'error' ||
          this.applicationRecord.status === 'rejected')
        ? true
        : false;
    },
  },
  methods: {
    onBack() {
      this.$emit('on-back');
    },
    onSave({ fields, options }) {},
    onNext() {
      this.$emit('on-next');
    },
  },
};
</script>

<style scoped>
p {
  padding: 0 1rem;
}
</style>
