<template>
  <LDBModal result="info" size="large" :header="header">
    <template #body>
      <h3>Current Dynamo Permissions</h3>
      <ul>
        <li v-for="permission in dynamoPermissions" :key="permission">
            {{ permission }}
          </li>
      </ul>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in buttons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxRecacheUserPermissions',
  components: {},
  props: {},
  emits: {},
  created() {},
  mounted() {
    void this.getLatestPermissionsFromDynamo()
  },
  data() {
    return {
      dynamoPermissions: []
    };
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['user']),
    userId() {
      return this.$route.params.userId;
    },
    record() {
      return this.user?.[this.userId];
    },
    userStatus() {
      return this.record?.status;
    },
    userEmailAddress() {
      return this.record?.emailAddress;
    },
    header() {
      return `Recache User Permissions: ${this.user[this.userId].userDisplayName}`;
    },
    buttons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'recacheUserPermissions',
          label: 'Recache',
          type: 'primary',
          clickEvent() {
            self.do_recache_permissions();
          },
        },
      ];
      return allButtons;
    },
  },
  methods: {
    async do_recache_permissions() {
      let response = await doPermissionAction(this, 'updatePermissionsInDynamo', { userId: this.userId });
      if (response.status === 'success') {
        this.getLatestPermissionsFromDynamo()
      }
    },
    async getLatestPermissionsFromDynamo() {
      let response = await doPermissionAction(this, 'getUserSettingsAndPermissions', { userId: this.userId });
      if (response.status === 'success') {
        this.dynamoPermissions = [];
        for (const item in (response.response?.user?.permissions || {})) {
          if (response.response?.user?.permissions[item] === true) {
            this.dynamoPermissions.push(item)
          }
        }
      }
    }
  },
};
</script>
