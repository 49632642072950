<template>
  <LDBItemView
    :record="record"
    :definition="definition"
    @on-save="onSaveAccount"
    :view="view"
    action="editAccount"
    :params="{ accountId: recordId }"
  />
  <router-view></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';

export default {
  name: 'LfxAccountDetails',
  components: { LDBItemView },
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    recordName() {
      return 'account';
    },
    recordId() {
      return +this.$route.params.accountId;
    },
    record() {
      return this.$store.state[this.recordName][this.recordId];
    },
    accountLevel() {
      return this.record.accountLevel;
    },
    definition() {
      return getDefinition(this.accountLevel);
    },
    view() {
      return this.$route.meta?.content?.view ? this.$route.meta.content.view : 'item';
    },
  },
  // watch: {
  //   // view(newView, oldView) {
  //   //   if (this.$route.meta?.content?.view === 'edit') {
  //   //     this.record.editing = true;
  //   //   } else {
  //   //     this.record.editing = false;
  //   //   }
  //   // },
  // },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    async onSaveAccount({ fields }) {
      // this.record.update(fields, options);

      // this.showLoader(true);
      // let record = this.record;
      // let response = await this.$store.dispatch('db_update_document', { record, fields });
      // this.showLoader(false);
      // if (response !== undefined) {
      this.$router.replace({ name: 'accountDetailsView' });
      // }
    },
  },
};
</script>

<style>
/* div.container {
} */
</style>
