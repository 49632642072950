<template>
    <div  v-if="view === 'edit' || view === 'create'" class="ldb-field-container__text">
    <capitec-text-box  @value-change='on_change' @key-input="on_change" :label="fieldLabel" :value="value" :error="validationError"></capitec-text-box>
    </div>

    <LDBItemViewField v-else-if="view === 'item' && valueIsShort" :view="view" :field="field" :displayValue="value || ''" :definition="definition" :record="record" />

    <template v-else-if="view === 'item'"> 
      <div class="ldb-field-memo-item-view">
      <capitec-label class="ldb-field-memo-item-view-label" :label="`${field.label}`"></capitec-label>
      <div class="ldb-field-memo-item-view-value">
        <p v-for="(p,n) in paragraphs" :key="n" class="item-view-memo-paragraph">
        {{p}}
        </p>
      </div>
      </div>
    </template>

    <LDBListViewField v-else-if="view === 'list'" :view="view" :field="field" :displayValue="value || ''" :definition="definition" :record="record" />
</template>


<script>

import {
  mapState,
  mapActions,
  mapGetters,
  mapMutations
} from "vuex";

import {ldb_field_mixin} from './ldb_field_mixin.js';
import LDBItemViewField from './LDBItemViewField.vue';
import LDBListViewField from './LDBListViewField.vue';

export default {
  mixins:[ldb_field_mixin],
  components: {LDBItemViewField,LDBListViewField},
  props: {  
    
  },
  data() {
    return {
    }
  },
  created() {

  },
  mounted() {

  },
  computed:{
    ...mapGetters([]),
  	...mapState([]),
    paragraphs() {
      let text = this?.value ? this.value : '';
      let paragraphs = text.split('\n').filter(s => s);
      return paragraphs;
    },
    valueIsShort() {
      return (this?.value?.length &&  this?.value?.length < 50);
    }
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([]),
    on_change(evt) {
      this.$emit('change',evt.detail.new);
    }
  }
 }
</script>
<style>

div.ldb-field-container__text{
  /* border: 3px solid green; */
}

div.ldb-field-memo-item-view-value{
  font-size: var(--theme-label-strong-font-size, 12px);
  text-align: justify;
  color: var(--theme-label-font-color, black);
  /* margin-top:5px; */
  font-weight: var(--theme-label-strong-font-weight, bold);
  font-family: var(--theme-label-font-family, Arial, Helvetica, sans-serif);
  max-height: 250px;
  overflow-y:auto;
  /* padding-bottom:10px; */
  padding-bottom:5px;
  padding-right:10px;
  padding-left:0px;
  padding-top:0px;
  margin-bottom:-10px;

  /* border: 1px solid green; */
}

.ldb-field-memo-item-view{
      border-bottom: 1px solid #eeeeee;
      flex:1;
}

.ldb-field-memo-item-view-label{
  margin-top:5px;

}

p.item-view-memo-paragraph{
  /* border: 1px solid red; */
  padding-top:0px;
  margin-top:0px;
}

</style>


