<template>
  <capitec-page-header :label="label">
    <capitec-spacer />
    <LfxSystemNotification v-if="systemNotification?.show" />
    <capitec-spacer />

    <div class="header-sizer"></div>

    <slot name="rates" />

    <LfxEventIndicator />
  </capitec-page-header>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import LfxEventIndicator from '@/components/events/LfxEventIndicator.vue';
import LfxSystemNotification from './LfxSystemNotification.vue';

export default {
  components: { LfxEventIndicator, LfxSystemNotification },
  computed: {
    ...mapGetters(['systemNotification', 'sdlc', 'permissions']),

    label() {
      switch (this.sdlc) {
        case 'LOCAL':
        case 'DEV':
        case 'INT':
        case 'QA':
          return this.sdlc;
        default:
          return 'Forex';
      }
    },
  },
};
</script>

<style>
.header-sizer {
  min-height: 60px;
}
</style>
