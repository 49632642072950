<template>
  <LFXListView :definition="bankAccountDefinition" :records="bankAccounts" />
  <router-view></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXListView from '@/views/general/LFXListView.vue';

export default {
  name: 'LfxBankAccountsList',
  components: { LFXListView },
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    // ----- Viewed Bank Accounts

    bankAccounts() {
      return this.beneficiaryId ? this.beneficiary_bankAccounts : this.accountBankAccounts;
    },

    // ----- Account Bank Accounts

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },

    accountBankAccounts() {
      return this.accountRecord?.own_bankAccounts;
    },

    // ----- Beneficiary Bank Accounts

    beneficiaryId() {
      return +this.$route.params?.beneficiaryId;
    },
    beneficiaryRecord() {
      return this.accountRecord.beneficiaries?.[this.beneficiaryId];
    },

    beneficiary_bankAccounts() {
      return this.beneficiaryRecord?.beneficiary_bankAccounts || {};
    },

    // ----- Definition -----

    bankAccountDefinition() {
      return getDefinition('bankAccount');
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
