import { RouteRecordRaw } from 'vue-router';

import LfxTodo from '@/components/todo/LfxTodo.vue';
import LfxTodoModal from '@/components/todo/LfxTodoModal.vue';

import LfxHelp from '@/views/help/LfxHelp.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/help',
    name: 'help',
    meta: { icon: 'system/knowledge' },
    component: LfxHelp,
    children: [
      {
        path: 'intermediary_faq',
        name: 'helpIntermediaryFaq',
        component: LfxTodo,
        meta: { label: 'Intermediary FAQ' },
      },
      {
        path: 'client_faq',
        name: 'helpClientFaq',
        component: LfxTodo,
        meta: { label: 'Client FAQ' },
      },
      {
        path: 'contacts',
        name: 'helpContacts',
        component: LfxTodo,
        meta: { label: 'Contacts' },
      },
      {
        path: 'fraud_training',
        name: 'helpFraudTraining',
        component: LfxTodo,
        meta: { label: 'Fraud Training' },
      },
    ],
  },
];

export default routes;
