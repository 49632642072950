<template>
  <LDBModal result="info" header="Add Public Holiday" size="x-large">
    <template #body>
      <LDBCreateView
        :table="table"
        :definition="definition"
        :collection_path="collectionPath"
        :showBackButton="false"
        action="createPublicHoliday"
        :params="{ configId: 1 }"
        :createButtonLabel="createButtonLabel"
      />
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBCreateView from '@/landobyte_vue/LDBCreateView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

export default {
  name: 'configPublicHolidayView',
  components: { LDBLoader, LDBCreateView },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    // ----- Public Holiday Record -----
    table() {
      return 'LfxPublicHoliday';
    },
    collectionPath() {
      return `/config/1/publicHoliday`;
    },
    definition() {
      return getDefinition('publicHoliday');
    },
    createButtonLabel() {
      return 'Create Public Holiday';
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
