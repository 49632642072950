<template>
  <LFXListView v-if="hasListLoaded" :definition="definition" :records="records" :defaultSort="defaultSort" />
  <template v-else>
    <LDBLoader />
  </template>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinitionByTable } from '@/db-interface/db_definitions_store';

import LFXListView from '@/views/general/LFXListView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxSwiftLogList',
  components: { LFXListView, LDBLoader },
  props: {},
  data() {
    return {
      hasListLoaded:false
    };
  },
  async created() {
    await doPermissionAction(this,'getInwardSwiftLogs',{swiftId:this.swiftId});
    this.hasListLoaded = true;
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['swift']),
    swiftId() {
      return +this.$route.params?.swiftId;
    },
    records() {
      return this.swift?.[this.swiftId]?.log || {};
    },
    definition() {
      return getDefinitionByTable('LfxInwardSwiftLog');
    },
    defaultSort() {
      return { order: 'timestamp', sort: 'desc' }
    }
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>

<style>
/* div.container {
} */
</style>
