<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <ol>
        <li>The Client's account will be closed.</li>
      </ol>
      <LDBField
        v-for="(field, fieldname) in viewFields"
        :key="fieldname"
        :field="field"
        :view="field.view || 'edit'"
        :fieldname="field.name"
        :definition="definition"
        :record="postRecord"
        @change="on_change"
      />
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBField from '@/landobyte_vue/LDBField.vue';
import { extendedTextWhiteList } from '@/sharedUtils/LdbValidations';

export default {
  name: 'LfxCloseClient',
  components: { LDBField },
  created() {
    this.postRecord.kycNonCompliant = this.record.kycCompliance === 'nonCompliant';
    this.alreadyKycNonCompliant = this.record.kycCompliance === 'nonCompliant'
  },
  beforeUnmount() {
  },
  data(){
    return {
      postRecord:{
        closeCif: false,
        kycNonCompliant: false,
        note: undefined,
      },
      alreadyKycNonCompliant: false
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),
    header() {
      return `Close Client: ${this.record.name}`;
    },

    // ----- Buttons -----
    submitButtonText() {
      if (!this.postRecord.closeCif && (!this.postRecord.kycNonCompliant || this.alreadyKycNonCompliant)) {
        return "Select Action"
      }
      if (this.postRecord.closeCif && (!this.postRecord.kycNonCompliant || this.alreadyKycNonCompliant)) { 
        return "Close CIF"
      }
      if (!this.postRecord.closeCif && this.postRecord.kycNonCompliant) {
        return "Mark as KYC Non-Compliant"
      }
      if (this.postRecord.closeCif && this.postRecord.kycNonCompliant) {
        return "Close CIF and Mark as KYC Non-Compliant"
      }
    },
    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

        {
          name: 'closeClient',
          label: this.submitButtonText,
          type: 'primary',
          action: 'closeClient',
          params: { accountId: this.record.id },
          actionParams: this.postRecord,
          guards: self.record.closeClient_buttonGuard,
          enabledGuards: this.postRecord.closeCif || (this.postRecord.kycNonCompliant && !this.alreadyKycNonCompliant),
          clickEvent(response) {
            if (response.status === 'success') {
              self.$router.go(-1);
            }
          },
        },
      ];
      return allButtons !== undefined //.filter((button) => button.guards)
        ? allButtons // .filter((button) => button.guards)
        : [];
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : '/';
    },

    // ----- Definition -----

    definition() {
      return this.record.definition;
    },

    // ----- Record -----

    recordName() {
      return 'account';
    },
    recordId() {
      return +this.$route.params.accountId;
    },
    record() {
      let rec = this.$store.state?.[this.recordName]?.[this.recordId];
      return rec;
    },

    // ----- Fields -----

    viewFields() {
      const fields = [
        {
          name:'closeCif',
          fieldName:'closeCif',
          label: 'Close CIF?',
          datatype: 'boolean'
        },
        {
          name:'kycNonCompliant',
          view: this.record.kycCompliance === 'nonCompliant' ? 'item' : 'edit',
          fieldName:'kycNonCompliant',
          label: 'Mark as KYC Non Complient?',
          datatype: 'boolean'
        },
        {
          name: 'note',
          fieldName: 'note',
          label: 'Note',
          datatype: 'memo',
          mandatory: false,
          validators: {
            is: { msg: 'Please use only alphanumeric charaters', args: extendedTextWhiteList },
            len: { msg: 'Note should be between 2 and 250 characters', args: [2, 250] },
          },
      }];
      return fields;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Fields -----

    on_change({ field, value }) {
      this.postRecord[field] = value;
    },

    // ----- API calls -----
  },
};
</script>

<style></style>
