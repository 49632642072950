<template>

 <div role="dialog" aria-modal="true" :class="`event-notification ${eventType} ${canNavigateClass}`">
				<div class="container result">
					
			<div class="header">				
				<capitec-icon class="icon" size="small" :icon="`status/${eventType}-24px-white`"></capitec-icon>
				<div class="header">
				  {{eventHeader}}
        </div>
        <div class="spacer">
        
        </div>
        <div class="close-button" @click.stop="doDeleteEvent">
        <capitec-icon size="xx-small" class="icon click-icon" icon="system/close-white"/>
        </div>

			</div>
		
			<div class="body" @click="goToItemViewAndRetrieveIfNecessary">
        <div class="notification-time">
        	{{time_since}}
        </div>
        <div class="body-content">
        	{{eventText}}
        </div>
        
			</div>
			
			<div class="footer">
			<slot name="footer"></slot>
			</div>

				</div>
			</div>


</template>

<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";
import { deleteMessage } from '@/webSockets/LdbEventsWebSocket.ts';
import {db_check_if_item_exists,getDefinitionByTable} from '@/db-interface';
import {doPermissionAction} from '@/lfx_rest/lfx_action';
const Mustache = require('mustache');
window.Mustache = Mustache;

export default {
  components: {},
  props: {  
    event:{
      type:Object,
      required:true
    }
  },
  data() {
    return {
      canNavigate:true
    }
  },

  created() {
    
  },

  mounted() {
    
  },
  computed:{
    ...mapGetters([]),
  	...mapState(['now']),
    canNavigateClass() {
      return this.canNavigate ? 'can-navigate' : 'cant-navigate';
    },
    eventHeader() {
      let header = (this.event.eventType.notificationTitle || this.event.eventType.notificationText);
      return this.replaceText(header);
    },
    eventText() {
      return this.replaceText(this.event.eventType.notificationText);
    },
    replaceTags() {
      return this.event.tags || {};
    },
    eventType() {
      return this.event?.eventType?.displayType || 'info';
    },
    time_since() {
      let date = Math.floor(new Date(this.event.timeStamp).getTime() / 1000);
      let fmt = {y:'Y ago',m:'M ago',d:'d ago',h:'h ago',n:'m ago',s:'s ago'};
      var seconds = Math.floor((this.now - date));
      var interval = Math.floor(seconds / 31536000);
      if (interval >= 1) {
        return interval + `${fmt.y}`;
      }
      interval = Math.floor(seconds / 2592000);
      if (interval >= 1) {
        return interval + `${fmt.m}`;
      }
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        return interval + `${fmt.d}`;
      }
      interval = Math.floor(seconds / 3600);
      if (interval >= 1) {
        return interval + `${fmt.h}`;
      }
      interval = Math.floor(seconds / 60);
      if (interval >= 1) {
        return interval + `${fmt.n}`;
      }
      return `now`;
    },
    testEvent() {
      return {
        }
      }
    },
  methods:{
  	...mapActions(['deleteEventNotification','showLoader','showEvents']),
  	...mapMutations([]),
    doDeleteEvent() {
      deleteMessage(this.event.id);
    },
    replaceText(text) {
      let taggedText = text.replace(/\[/g, "{{").replace(/\]/g,"}}");
      let tags = this.defaultTags(taggedText);
      return Mustache.render(taggedText,tags);
    },
    defaultTags(text) {
      let defaultTags = Mustache.parse(text).filter(a => a[0] === 'name').map(a => a[1]);
      let defaultTagObject = {};
      for (let t = 0 ; t < defaultTags.length ; t++ ) {
        let tag = defaultTags[t];
        defaultTagObject[tag] = `[${tag}]`;
      }
      let replaceTags = {...defaultTagObject,...this.replaceTags};
      return replaceTags;
    },
    close() {
      this.deleteEventNotification(this.event.id);
    },
    async goToItemViewAndRetrieveIfNecessary(evt) {      
      const object = this.event.dbObject;
      const definition = getDefinitionByTable(object.Table);
      const collectionPath = Mustache.render(definition.collectionPath, object);
      collectionPath.replaceAll('/null/', '/0/');
      const existingObject = db_check_if_item_exists(this.$store, collectionPath + '/' + object.id, '');
      if (existingObject) {
        if (existingObject.link ) {
          this.$router.push(existingObject.link);
        }
      }        
      else {
        object.collection_path = collectionPath;
        const temporartyObject = definition.class(object, this.$store)
        if (temporartyObject && temporartyObject.link) {
          this.$router.push(temporartyObject.link);
        }
        // if (definition.actions?.item) {
        //   const permissionActionResult = await doPermissionAction(this,definition.actions.item,object,object);
        //   const newObject = db_check_if_item_exists(this.$store, collectionPath + '/' + object.id, '');
        //   if (newObject?.link) {
        //     this.$router.push(newObject.link);
        //   } else {
        //     this.canNavigate = false;
        //   }
        // } else {
        //   this.canNavigate = false;
        //  }
       }
       //  hide loader
       this.showLoader(false);
       this.showEvents(false);
    }
  }
 }
</script>
<style scoped>


.lfx-event-container{
  position: relative;
}

.capitec-tile-close{
  position: absolute;
  right:10px;
  top:10px;
}


.event-notification.info{
  --event-color: var(--theme-status-info-background);
}

.event-notification.error{
  --event-color: var(--theme-status-error-background);
}

.event-notification.success{
  --event-color: var(--theme-status-success-background);
}

.event-notification.warning{
  --event-color: var(--theme-status-warn-background);
}

.event-notification{
  min-height: 58px;
  margin-left:10px;
  margin-right:10px;
  border-radius: 5px;
  margin-top:5px;
  border: 1px solid  var(--event-color);  
}

.event-notification:hover{
   box-shadow:var(--theme-product-tile-hover-shadow, 0 1px 3px 0 rgba(0,0,0,0.25), 0 2px 4px 0 rgba(0,0,0,0.15));
}

.event-notification > .container.result > .header{
  display:flex;
  min-height: 20px;
  max-height: 20px;
  font-weight: bold;
  color:white;
  align-items: center;
  position: relative;
}

.event-notification > .container.result > .header > .close-button > .icon{
  margin-right: 5px;
  margin-left: 5px;
  max-width: 13px;
}

.event-notification > .container.result > .header > .icon{
  margin-right: 0px;
  flex:1;
  margin-left: 5px;
  max-width: 15px;
}

.event-notification > .container.result > .header > .header{
  padding-left:5px;
  flex:1;
}

.event-notification > .container.result > .header > .close-button{
  padding-right: 5px;
  flex:1;
  max-width: 20px;
}


.event-notification > .container.result > .body{
  padding: 5px;
  padding-top:0px;
  padding-bottom:15px;
  position: relative;
  font-size: 14px;
}

.event-notification.can-navigate > .container.result > .body{
  cursor: pointer;
}

.event-notification.cant-navigate > .container.result > .body{
  cursor: not-allowed;
}


.notification-time{
  position: absolute;
  right: 10px;
  font-style: italic;
  bottom:0px;
  font-size: 14px;
}

.event-notification > .container.result > .header{
  background-color: var(--event-color);
}

</style>


