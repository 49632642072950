//@ts-nocheck
//---------------------------------------------------------------------

import pluralize from 'pluralize';
import lodash from 'lodash';

//---------------------------------------------------------------------

import {db_string_to_id} from './db_utils';
import {db_validator,validate_field} from './db_validation';

//---------------------------------------------------------------------

const sort_view = function(view,fieldA,fieldB) {
  let valA = fieldA.views[view] || 0;
  let valB = fieldB.views[view] || 0;
  const fieldValueMax = 1000000000;
  if (valA === true) {
    valA = fieldValueMax;
  }
  if (valB === true) {
    valB = fieldValueMax;
  }
  return (valA - valB);
}

export class Definition {
  constructor(initialDef,dbclass,context,name,config) {    
    let cfg = config === undefined ? {} : config;
    let copy = lodash.merge({},initialDef);
    let def = lodash.merge(copy,cfg);
    this.name = name;
    this.table = def.table;
    this.view = def.view;
    this.actions = def.actions;
    this.definition = def;
    this.__internal_fields = def.fields;
    this.class = dbclass;
    this.context = context;
    this.collectionPath = def.collectionPath;
    this.state = context.state;
    // this.requiredFields = def.requiredFields ? def.requiredFields : function(rec) { return []};    
    this.sort_field = (def.sort_field === undefined) ? 'id' : def.sort_field;
    
    this.sort_ascending = (def.sort_ascending === undefined) ? true : def.sort_ascending;
    this.title = (def.title === undefined) ? proper(name) : def.title;
    this.collection_name = (def.collection_name === undefined) ? pluralize.plural(name) : def.collection_name;
    this.get_new_id = (def.get_new_id === undefined)  ? default_get_new_id : def.get_new_id;
    this.search_string = '';
    if (name === 'root') {
      this.user_collection = def.user_collection ?? 'users';
    }
    this.delete_mode = def.delete_mode ? def.delete_mode : 'flag';
    let all_fields = this.definition.fields === undefined ? {id:{}} : this.definition.fields;
    let expanded_fields  = expand_fields(all_fields);
    let unguarded_fields = unguarded(expanded_fields);
    let groups = expand_groups(initialDef.groups);
    this.groups = groups;
    this._fields =  unguarded_fields;
    this.addToStoreIfNotExist = def.addToStoreIfNotExist;
  }
  get description_field_name() {
    let description_field_name = undefined;
    for (let f in this.indexed_fields) {
      let field = this.indexed_fields[f];
      if (field.description === true) {
        description_field_name = field.name;
        break;
      }
    }
    if (description_field_name === undefined) {
      if (this.indexed_fields.name !== undefined ) {
        description_field_name = 'name';
      }
    }
    if (description_field_name === undefined) {
      description_field_name = 'id'
    }
    return description_field_name;
  }
  get description_field() {
    return this.indexed_fields[this.description_field_name];
  }
  get current_sort_field() {
    return this.sort_field === undefined ? 'id' : this.sort_field;
  }
  get sort_direction() {
    return this.sort_ascending === true ? 'ascending' : 'descending';
  }

  get search_string() {
    return this._search_string;
  }
  
  set search_string(value) { 
    this._search_string = value.toLowerCase();
  }


  get views() {
    let all_views = (this.definition.views === undefined) ? {edit:{},create:{},item:{},list:{}} : this.definition.views;
    let unguarded_views = unguarded(all_views);
    let views = expand_views(unguarded_views,this);
    return views;
  }
  get collections() {
    let all_collections = (this.definition.collections === undefined) ? {} : this.definition.collections;
    let unguarded_collections = unguarded(all_collections)
    let collections = expand_collections(unguarded_collections);
    return collections;
  }  
  get documents() {
    let all_documents = (this.definition.documents === undefined) ? {} : this.definition.documents;
    let unguarded_documents = unguarded(all_documents);
    let documents = expand_documents(unguarded_documents);
    return documents;
  }
  get db_field_names() {
    let res = [];
    for (let f in this.indexed_fields) {
      let field = this.indexed_fields[f];
      if (field.read || field.write || field.name === 'id' ) {
        res.push(field.name)
      }
    }
    return res;
  }
  get search_fields() {
    let res = [];
    for (let f in this.indexed_fields) {
      let field = this.indexed_fields[f];
      if (field.search === true) {
        res.push(field.name)
      }
    }
    return res;  
  }
  get default_values() {
    let res = {};
    for (let f = 1 ; f < this.fields.length ; f ++  ) {
      let field = this.fields[f];
      if (field.default !== undefined && !(field.property?.source === 'frontend' || field.property?.write === false)) {
        res[field.name] = field.default;
      }
      // if (field.default !== undefined) {
      //   res[field.name] = field.default;
      // }
    }
    return res;
  }

  get search_field_names() {
    let res = [];
    for (let f in this.indexed_fields) {
      let field = this.indexed_fields[f];
      if (field.search === true) {
        res.push(field.name)
      }
    }
    return res;    
  }
  get collection_fields() {
    let res = {};
    for (let f in this.indexed_fields ) {
      let field = this.indexed_fields[f];
      if (field.datatype_name === 'json_collection') {
        res[f] = field
      }
    }
    return res;
  } 
  get list_view_fields() {
    return this.fields.filter(function(field) { return field.views.list !== false }).sort(function(a,b) { return sort_view('list',a,b) } );
  }
  get item_view_fields() {
    return this.fields.filter(function(field) { return field.views.item !== false }).sort(function(a,b) { return sort_view('item',a,b) } )
  }
  get edit_view_fields() {
    return this.fields.filter(function(field) { return field.views.edit !== false || typeof field.views.edit === 'function' }).sort(function(a,b) { return sort_view('edit',a,b) } )
  }
  get create_view_fields() {
    return this.fields.filter(function(field) { return field.views.create !== false }).sort(function(a,b) { return sort_view('create',a,b) } )
  }
  get db_read_fields() {
    return this.fields.filter(function(field) { return field.read === true || field.write === true })
  }
  get db_write_fields() {
    return this.fields.filter(function(field) { return field.write === true || field.views.edit === true || field.views.create === true })
  }
  get can_create() {
    return (this.create_view_fields.length > 0)
  }
  get db_fields() {
    let res = [];
    for (let f = 0 ; f < this.db_read_fields.length ; f ++ ) {
      let field = this.db_read_fields[f];
      let field_name = field.name;
      if (res.indexOf(field_name ) === -1) {
        res.push(field_name);
      }
    }
    return res;
  }
  get fields() {
    return this._fields;
  }
  
  get indexed_fields() {
    let res = {};
    for (let f = 0 ; f < this.fields.length  ; f ++ )  {
      let field = this.fields[f];
      res[field.name] = field;
    }
    return res;
  }

  get sort_fields() {
    let res = [];
    for (let f in this.indexed_fields) {
      let field = this.indexed_fields[f];
      if (field.sort !== undefined) {
        res.push(field)
      }
    }
    return res;
  }

  sort(field) { 
    if (this.sort_field === field) {
      this.sort_ascending = !this.sort_ascending
    }
    else {
      this.sort_ascending = true;
      this.sort_field = field;
    }
  }

  validate_field(field,value,record) {
    let validate_field_res = validate_field(field,value,record);
    if (validate_field_res === undefined) {
      return true
    } else 
    {
      let errors = [];
      for (let e in validate_field_res ) {
        let error = validate_field_res[e];
        errors.push(error);
      }
      return errors.join(' | ');
    }
  }

}

//---------------------------------------------------------------------
//  INTERNAL FUNCTIONS
//---------------------------------------------------------------------

function default_get_new_id(record,def) { 
  let name = record[def.description_field.name];
  let random = `${Math.round(Math.random() * 100000)}`;
  let id = `${name}_${random}`;
  return db_string_to_id(id);
}

function to_proper(string) {
  let res = string.toLowerCase().split('');
  res[0] = res[0].toUpperCase();
  return res.join('');
}

//---------------------------------------------------------------------

function proper(string) {
  return string.split('_').join(' ').split(' ').filter(function(s) { return s.length > 0}).map(function(s) { return to_proper(s)}).join(' ');
}

//---------------------------------------------------------------------
// GUARD FUNCTIONS
//---------------------------------------------------------------------

export function unguarded(guarded_objects,obj) {
  let is_array = Array.isArray(guarded_objects);
  let res = {};
  if (is_array) {
    res = []
  }
  for (let o in guarded_objects) {
    let guarded_object = guarded_objects[o];
    let guards = (guarded_object.guards === undefined) ? [true] : guarded_object.guards;
    if (passes_guards(guards,obj)) {
      if (is_array) {
        res.push(guarded_object)
      }
      else 
      {
        res[o] = guarded_object;
      }
    }
  }
  return res;
}




//---------------------------------------------------------------------
//  EXPAND FUNCTIONS
//---------------------------------------------------------------------

function expand_groups(groups) {
  if (groups === undefined) {
    return {default:{default:true,priority:1,label:''}};
  }
  else {
    return groups;
  }
}


function expand_views(views,def) {
  let res = {};
  for (let v in views) {
    let view = views[v];
    let expanded_view = expand_view(view,v,def)
    res[v] = expanded_view;
  }
  return res;
}

//---------------------------------------------------------------------

function expand_view(view,view_name,def) {
  return view;
  // if ()
  const DEFAULT_COMPONENTS = {
    item:'XLDBSubItemView',
    list:'XLDBSubListView',
    edit:'XLDBSubEditView',
    custom:'XLDBSubCustomView'
  };
  
  let default_component = (DEFAULT_COMPONENTS[view.type] === undefined) ? 'XLDBUnimplementedView' : DEFAULT_COMPONENTS[view.type];
  view.component = (view.component === undefined) ? default_component : view.component; 
  view.id = view_name;
  view.type = (view.type === undefined) ? view_name : view.type;
  view.title = (view.title === undefined) ? view_name : view.title;
  if (view.type === 'item') {
    view.props = (view.props === undefined) ? function(record) { return {record}} : view.props;
  } else 
  if (view.type === 'list') {
    let collection = view.collection === undefined ? view_name : view.collection;
    let doc_name = pluralize.singular(collection);
    view.props = (view.props === undefined) ? function(record) { return {record:record,collection_document:doc_name,records:record.sort_collection(collection)}} : view.props;
  } else 
  if (view.type === 'edit') {
    view.props = (view.props === undefined) ? function(record) { return {record}} : view.props;    
  } else 
  if (view.type === 'custom') {
    view.props = (view.props === undefined) ? function(record) { return {record}} : view.props; 
  }
  let fields_in_view = get_view_fields(def.fields,view_name);
  view.content = {
    id:view_name,
    contentType:'fields',
    title(record) { return record.description },
    content:fields_in_view,
    buttons:buttons_for_view(view_name,def)
  };
  return view;
}

function buttons_for_view(view_name,def) {
  if (view_name === 'item') {
    return [
      {label:`Edit`,click(record) { record.editView() }}
    ]    
  } else 
  if (view_name === 'edit') {
    return [
      {label:`Save`,click(record) { record.save() }},
      {label:`Cancel`,click(record) { record.itemView() }}
    ]    
  } else 
  if (view_name === 'create') {
    return [{label:`Create`,click(record) { record.create() }}]
  } else 
  if (view_name === 'list') {
    return [{label:`Add ${def.title}`,click(record) { record.createView() }}]
  }  else 
  {
    return [];
  }  
}


function get_view_fields(fields,view_name) {
  return fields.filter(function(field) {return field.views[view_name] === true} );
}

//---------------------------------------------------------------------

function expand_fields(fields) {
  let res = [];
  for (let f in fields) {
    let field = fields[f];
    let expanded_field = expand_field(field,f);
    res.push(expanded_field);
  }
  return res;
}

//---------------------------------------------------------------------

window.cnt = 0;

function expand_field(field,field_name) {
  window.cnt = window.cnt + 1;
  let res = {};
  for (let f in field) {
    res[f] = field[f]
  }
  res.name = field_name;
  res.autocomplete = res.autocomplete || res.name;
  res.field = field_name;
  res.classes = expand_field_datatype_classes(field,field_name);
  res.label = (res.label === undefined ) ? field_name : res.label;
  res.views = expand_field_views(field,field.views);
  res.datatype = (res.datatype === undefined) ?  'string' : res.datatype ;
  let datatype_name = 'text';
  if (typeof res.datatype === 'object') {
    datatype_name = Object.keys(res.datatype)[0];
  }
  else 
  {
    datatype_name = res.datatype;
  }
  if (datatype_name === 'json') {
    if (res.datatype.json.type === 'collection') {
      datatype_name = 'json_collection';
    }
  }
  if (datatype_name === 'option') {
    let options = expand_options(field.datatype.option.options);
    field.datatype.option.options = options;
  }
  res.datatype_name = datatype_name;
  res.read = (field.read === undefined) ? true : (field.read === true);
  res.write = (field.write === undefined) ? false : (field.write === true);
  res.sync = false;
  res.search = (field.search === undefined) ? ((res.read || res.write) && res.datatype === 'string' )  : field.search;
  res.validators = expand_validations(res,field.validators);
  return res;
}

function expand_options(options) {
  return options.map(o => expand_option(o));
}

function expand_option(option) {
  if (option.guard === undefined) {
    option.guard = true;
  }
  return option;
}

function expand_field_datatype_classes(field,field_name) {
  let custom_classes = field.classes ?? [];
  let classes = `datatype-${field.datatype} field-${field_name} ${custom_classes.join(' ')}`;
  return classes;
}

//---------------------------------------------------------------------

function expand_field_views(field,views) {
  let res = {item:true,list:false,edit:false,create:false};
  for (let v in views) {
    res[v] = views[v]
  }
  return res;
}

//---------------------------------------------------------------------

function expand_collections(collections) {
  let res = {};
  for (let c in collections) {
    let partial_collection = collections[c];
    let collection = expand_collection(c,partial_collection)
    res[c] = collection;
  }  
  return res;
}

//---------------------------------------------------------------------

function expand_documents(documents) {
  let res = {};
  for (let c in documents) {
    let partial_document = documents[c];
    let document = expand_document(c,partial_document)
    res[c] = document;
  }  
  return res;
}

//---------------------------------------------------------------------

function expand_collection(collection_name,collection) {
  let res = {};
  let collection_path = (collection.collection === undefined) ? function(record) { return `${record.collection_path === undefined ? '' : record.collection_path}/${record.id === undefined ? '' : record.id}/${collection_name}` } : collection.collection;
  res.load  = (collection.load === undefined) ? 'load' : collection.load;
  res.live  = (collection.live !== false);
  res.where = (collection.where === undefined) ? [['deleted','==',false]] : collection.where;
  if (typeof collection_path !== 'function') {
    res.collection = function(record) {return collection_path}
  }  
  else 
  {
    res.collection = collection_path
  }
  return res;
}

//---------------------------------------------------------------------

function expand_validations(field,validations) {
  let res = {};
  if (validations === undefined) {
    return undefined;
  }
  else 
  {
    for (let v in validations) {
      let validation = validations[v];
      let expanded_validation = expand_validation(field,v,validation);
      res[v] = expanded_validation;
    }
  }
  return res;
}

function expand_validation(field,validation_name,validation) {
  let res = {};
  let msg = validation.msg ? validation.msg : 'Validation failed' ;
  let args = validation.args === undefined ? validation : validation.args;
  let fun = function() { };
  if (typeof args === 'function') {
    fun = function(val,rec) { return args(val,rec)}
  } else 
  if (Array.isArray(args) ) {
    if (args.length === 1) {
      fun = function(val,rec) {
        return db_validator[validation_name](val,args[0]);
      }
    } else 
    if (args.length === 2) {
      fun = function(val,rec) {
        return db_validator[validation_name](val,args[0],args[1]);
      }
    } else 
    if (args.length === 3) {
      fun = function(val,rec) {
        return db_validator[validation_name](val,args[0],args[1],args[2]);
      }
    }
  } else 
  {
    if (args === undefined) {
      fun = function(val,rec) { return db_validator[validation_name](val) }
    } else 
    {
      fun = function(val,rec) { return db_validator[validation_name](val,args) }
    }
  }
  res.fieldname = field.name;
  res.name = validation_name;
  res.args = validation;
  res.validate = fun;
  res.msg = msg;
  return res;
}



function expand_document(document_name,doc) {
  let res = {};
  let collection_name = pluralize.plural(document_name);
  let id_field = (doc.id_field === undefined) ? `${document_name}_id` : doc.id_field;
  let collection_path = (doc.collection === undefined) ? function(record) { return `/${collection_name}` } : doc.collection;
  let document_path = function(record,doc)  { return `/${doc.collection_path}/${record[doc.id_field]}`}
  res.load  = (doc.load === undefined) ? 'load' : doc.load;
  res.live  = (doc.live !== false);
  res.id_field = id_field;
  res.document_path = document_path;
  if (typeof collection_path !== 'function') {
    res.collection = function(record) {return collection_path}
  }  
  else 
  {
    res.collection = collection_path
  }
  return res;
}

//---------------------------------------------------------------------
// GUARD FUNCTIONS
//---------------------------------------------------------------------

function passes_guards(guards,obj)  {
  let guard_result = true;
  for (let g = 0 ; g < guards.length ; g ++ ) {
    let guard = guards[g];
    if (!passes_guard(guard,obj)) {
      guard_result = false;
      break;
    }
  }
  return guard_result;
}

//---------------------------------------------------------------------

function passes_guard(guard,obj) {
  if (typeof guard === "boolean") {
    return guard
  } else 
  if (typeof guard === 'function') {
    return guard(obj) === true
  } else 
  if (typeof guard === 'object') {
    if (Array.isArray(guard) && guard.length === 3) {
      let [field,operator,value] = guard;
      let guard_obj = {field,operator,value};
      return evaluate_condition(guard_obj,obj)
    } else 
    {
      return evaluate_condition(guard,obj)
    }
  } else 
  if (guard === undefined) {
    return false
  }
  if (guard === undefined) {
    return false
  } else 
  return false
}

//---------------------------------------------------------------------

function evaluate_condition({field,operator,value},obj) {
  let field_value = obj[field];
  if (operator === '===') {
    return (field_value === value)
  } else 
  if (operator === '!==') {
    return (field_value !== value)
  } else 
  if (operator === '>') {
    return (field_value > value)
  } else 
  if (operator === '<') {
    return (field_value < value)
  } else 
  if (operator === '>=') {
    return (field_value >= value)
  } else 
  if (operator === '<=') {
    return (field_value <= value)
  } else 
  if (operator === 'isNaN') {
    return (isNaN(value))
  } else 
  if (operator === '!isNaN') {
    return (isNaN(value))
  } else 
  {
    return false
  }
  }

//---------------------------------------------------------------------

