<template>
  <LDBTile
    :layout="tileLayout"
    :layoutFields="tileLayoutFields"
    :values="record"
    :color="tileColor"
    :active="isActiveRecord"
    @on-tile-click="onTileClick"
  >
    <template #[field.name] v-for="field in tileFields" :key="field.name">
      <LDBField :field="field" view="list" :fieldname="field.name" :definition="definition" :record="record" />
    </template>
  </LDBTile>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBTile from './LDBTile.vue';
import LDBField from './LDBField.vue';

export default {
  emits: ['on-tile-click'],
  components: { LDBTile, LDBField },
  props: {
    record: { type: Object, required: true },
    definition: { type: Object, required: true },
    view: { type: String, default: 'default' },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['active']),
    definitionName() {
      return this.definition.name;
    },
    isActiveRecord() {
      return this?.active?.[this.definitionName] === this.record.id;
    },
    tileLayout() {
      let res = this.definition.view.tiles[this.view].layout;
      return res;
    },

    tileColor() {
      let colorDef = this.definition?.view?.tiles?.[this.view]?.color || 'white';
      if (colorDef.type === 'field') {
      }
      if (colorDef.type === 'field') {
        if (this.definition.indexed_fields[colorDef.field].datatype.option.options !== undefined) {
          colorDef.config = this.definition.indexed_fields[colorDef.field].datatype.option.options;
        }
      }
      return colorDef;
    },
    tileLayoutFields() {
      let res = {};
      for (let f in this.tileFields) {
        let field = this.tileFields[f];
        let layoutName = field.views.tiles[this.view];
        res[layoutName] = field.name;
      }
      return res;
    },
    tileFields() {
      let res = {};
      for (let f in this.definition.indexed_fields) {
        let field = this.definition.indexed_fields[f];
        if (field?.views?.tiles?.[this.view] !== undefined) {
          res[f] = field;
        }
      }
      return res;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    onTileClick() {
      this.record.activate();
      this.$emit('on-tile-click', this.record);
    },
  },
};
</script>

<style>
</style>

