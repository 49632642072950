<template>
  <capitec-title-bar type="primary" label="Hello World">
    <capitec-spacer></capitec-spacer>
  </capitec-title-bar>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>


<style>
/* div.container {
} */
</style>
