//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2022 LandoByte (Pty) Ltd.
//  File        : LfxBankAccountLogDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Bank Account Log record for LandoByte TS systems
//  Created     : 22 July 2022 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition } from '../definitions/LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------------------------------

export interface LfxBankAccountLogInt {
  id?: string;
  accountId?: number;
  beneficiaryId?: number;
  bankAccountId?: number;
  action?: string;
  actionDetails?: {};
  timestamp?: Date;
  userId?: number;
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxBankAccountLogDef: LdbDefinition = {
  title: 'Bank Account Log',
  table: 'LfxBankAccountLog',
  database: 'dynamo',
  collectionPath: '/account/{{accountId}}/bankAccountLog',
  view: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
  indexes: ['accountId', 'bankAccountId', 'beneficiaryId'],
  pagingType: 'backEnd',
  fields: {
    id: {
      label: 'ID',
      datatype: { id: { descriptionField: 'action', type: 'text' } },
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    accountId: {
      label: 'Account',
      datatype: {
        foreignKey: {
          collection: 'fk_accounts',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    beneficiaryId: {
      label: 'Beneficiary',
      datatype: {
        foreignKey: {
          collection: 'fk_beneficiaries',
          linkTable: 'LfxBeneficiary',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    bankAccountId: {
      label: 'Bank Account',
      datatype: {
        foreignKey: {
          collection: 'fk_bank_accounts',
          linkTable: 'LfxBankAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    action: {
      label: 'Action',
      datatype: 'text',
      default: '',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    actionDetails: {
      label: 'Action Details',
      datatype: { json: {} },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
    },
    timestamp: {
      label: 'Timestamp',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    userId: {
      label: 'User',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
  },
};
