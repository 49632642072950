import { Component, css, html } from '../../elements';

/**
 * Container layer that attaches to the action area of a display zone, e.g. card, popup, etc.
 * 
 * Can also be used as a standalone bar component.
 * 
 * ```js
 * import 'platform/components/layers/ActionBar';
 * ```
 * 
 * ```html
 * <capitec-action-bar>
 *   <capitec-search-field label="Search"></capitec-search-field>
 *   <capitec-spacer></capitec-spacer>
 *   <capitec-button type="primary" label="Create New"></capitec-button>
 * </capitec-action-bar>
 * ```
 */
export class ActionBar extends Component {

	// --------------
	// INITIALISATION
	// --------------

	/**
	 * Initialises the component.
	 *
	 * @hideconstructor
	 */
	constructor() {

		super();
	}

	// -------------------
	// LIFECYCLE OVERRIDES
	// -------------------

	// n/a

	// ----------
	// PROPERTIES
	// ----------

	// n/a

	// --------------
	// EVENT HANDLERS
	// --------------

	// n/a

	// --------------
	// PUBLIC METHODS
	// --------------

	// n/a

	// ---------------
	// PRIVATE METHODS
	// ---------------

	// n/a

	// ---------
	// RENDERING
	// ---------

	/**
	 * Generates the component stylesheet.
	 * 
	 * @returns {css} The css content of the component.
	 */
	static get styles() {

		return [
			super.styles,

			/* ---------- LAYOUT POLICY ---------- */
			css`
				:host {
					width: 100%;

					min-height: var(--theme-actionbar-min-height, 50px);

					flex-shrink: 0;
				}
			`,

			/* ---------- COMPONENT STYLING ---------- */
			css`
				:host {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: stretch;

					background: var(--theme-actionbar-background, white);

					border-radius: 0px;
					border-bottom: var(--theme-actionbar-border, 1px solid grey);
					box-shadow: var(--theme-actionbar-shadow, 0 1px 3px 0 rgba(0, 0, 0, 0.2));

					padding: var(--theme-actionbar-padding, 10px);
					
					margin-bottom: 1px;
				}

				:host > .wrapper {
					flex-grow: 1;
					
					display: flex;
					flex-wrap: wrap;
					flex-direction: row;
					justify-content: flex-start;
					align-items: stretch;

					margin: calc(var(--theme-actionbar-item-gap, 10px) / 2 * -1);
				}

				:host > .wrapper > ::slotted(*:not(capitec-spacer)) {
					margin: calc(var(--theme-actionbar-item-gap, 10px) / 2);
				}

				* {
					--theme-floating-label-visibility: hidden;
				}
			`
		];
	}

	/**
	 * Generates the component template for mobile mode.
	 * 
	 * @returns {html} The html content of the component.
	 */
	_mobileTemplate() {

		return this._webTemplate();
	}

	/**
	 * Generates the component template for kiosk mode.
	 * 
	 * @returns {html} The html content of the component.
	 */
	_kioskTemplate() {

		return this._webTemplate();
	}

	/**
	 * Generates the component template for web mode.
	 * 
	 * @returns {html} The html content of the component.
	 */
	_webTemplate() {

		return html`
			<div class="wrapper">
				<slot></slot>
			</div>
		`;
	}
}

window.customElements.define(`capitec-action-bar`, ActionBar);
