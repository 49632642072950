<template>
  <ul class="json">
                       
           <template v-if="typeof(value) === 'object'">
           {{label}}

             <template v-for="(val,key) in value" :key="key">
               <template v-if="typeof val === 'object'">
                 <template v-if="Object.keys(val).length > 0">
                   <LDBJSONObject :value="val" :label="key"/> 
                 </template>  
               </template>  
               <template v-else>
                 <li class="json">{{key}} : {{val}}</li>
               </template>
             </template>
           </template>
           <template v-else>
             <li class="json">{{prettyLabel(label)}} : {{value}}</li>
           </template>
           
         </ul>
</template>


<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

export default {
  components: {},
  props: {  
    value:{type:Object,required:true},
    label:{type:String,required:true},
    labelConfig:{type:Object,required:false}
  },
  data() {
    return {
    }
  },
  created() {

  },
  mounted() {
    
  },
  computed:{
    ...mapGetters([]),
  	...mapState([]),
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([]),
    prettyLabel(key) {
      return this.labelConfig?.[key] || key
    },
  }
 }
</script>
<style>

ul.json{
  /* border: 1px solid red; */
  min-height: 10px;
  margin-bottom:3px;
  margin-top:3px;
  margin-left:20px;
  font-weight: bold;
}

li.json{
  /* border: 1px solid green; */
  font-weight: normal;
  margin-left:20px;
}

ul.json > li.json > ul.json{
  margin-left:20px;
}


</style>


