<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <div>
        <ol>
          <li>This is the maximum ZAR value of open deals the client can have at any point in time.</li>
          <li>When a deal is booked, the available amount will be reduced by the ZAR amount.</li>
          <li>When the deal is completed or cancelled, the ZAR amount is returned to the available balance.</li>
        </ol>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="definition"
          :record="record"
          @change="on_change"
        />
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
        @on-api-success="onApiSuccess"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxAccountOpenDealLimitEdit',
  components: { LDBField },
  emits: ['on-api-success'],
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  beforeUnmount() {
    this.record.revert();
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    header() {
      return `Update Open Deal Limit`;
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

        {
          name: 'updateOpenDealLimitEdit',
          label: 'Update',
          type: 'primary',
          action: 'editAccount',
          params: {
            accountId: this.record.id,
          },
          actionParams: this.record,
          guards: true,
          clickEvent(response) {
            if (response.status === 'success') {
              self.$router.go(-1);
            }
          },
        },
      ];
      return allButtons !== undefined ? allButtons : [];
    },

    // ----- Definition -----

    definition() {
      return getDefinition('client');
    },

    // ----- Record -----
    recordName() {
      return 'client';
    },
    recordId() {
      return +this.$route.params?.accountId;
    },
    record() {
      return this.account?.[this.recordId];
    },

    // ----- Fields -----

    fields() {
      return this.definition.fields;
    },
    // view() {
    //   return 'releaseAccountApplication';
    // },
    viewFields() {
      let res = [];
      for (let k in this.fields) {
        let field = this.fields[k];
        if (field.name === 'openDealLimit') {
          field.fieldname = k;
          res.push(field);
        }
      }
      return res;
    },
  },

  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Fields -----

    on_change({ field, value }) {
      this.record[field] = value;
    },

    // ----- API calls -----

    async onApiSuccess(_responseObject) {},
  },
};
</script>
