<template>
  <LFXPagedListView 
    :definition="definition" 
    action="getAccounts" 
    :actionParams="{}" 
    :search="searchConfig"
    :defaultWhere="accountSelector"/>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXPagedListView from '@/views/general/LFXPagedListView.vue';

export default {
  name: 'LfxAccountsList',
  components: { LFXPagedListView },
  data() {

    return {
      searchConfig:{
      }      
    }
  },
  created() {},
  mounted() {
    const statusChips = [
            {id:'all',            label:'All'               ,selector:{ },default:true},
            {id:'inProgress',     label:'In Progress'       ,selector:{ status:{in:['new', 'submitted', 'rejected']}}},
            {id:'active',         label:'Active'            ,selector:{ status:{in:['active']}}},
            {id:'bankNotActive'  ,label:'Not Active at Bank',selector:{ bancsStatus:{in:['blacklisted', 'businessRescue', 'closed', 'debtReview', 'deceased', 'distressedRestructure', 'edcPlacement1', 'ficaNonCompliant', 'fraud', 'inactive', 'judgment', 'legal', 'liquidation', 'litigation', 'litigationPaymentArrangement', 'ncaS129', 'ncrRestructured', 'nonPerforming', 'paymentArrangementDefault', 'privateSale', 'saleInExecution', 'sequestrated', 'underAdministration', 'underPaymentArrangement', 'unemployed', 'whereaboutsUnknown', 'writeOff']}}},
            {id:'kycNotCompliant',label:'KYC Non-Compliant' ,selector:{ kycCompliance:'nonCompliant'}},
          ];
    if (this.authUser?.accountLevel === 'bank') {
      statusChips.push({id:'mandateDatabaseConflict',label:'Mandate Conflict' ,selector:{ status:'mandateDatabaseConflict'}})
    }
    this.searchConfig = 
      {
        searchFields:['reference','name','identification','cifNumber','externalReference','bpNumber'],
        choices:[
          {id:'accountTypes',
          title:'Types',
          chips:[
            {id:'all',        label:'All',       selector:{ },default:true},
            {id:'entity',     label:'Entity',    selector:{ accountType:{in:["entity"]}}},
            {id:'individual', label:'Individual',selector:{ accountType:{in:['individual']}}},
          ]
        },
        {id:'statuses',
          title:'Status',
          chips:statusChips
        },
        {id:'hidden',
          title:'Visibility',
          chips:[
            {id:'visible', label:'Visible',selector:{ visibility:{eq:'visible'}},default:true},
            {id:'hidden', label:'Hidden',selector:{ visibility:{eq:'hidden'}}},
          ]
        }

        ],
      }      
    
  },
  props: {
    includeBranchesInLists: {type: Boolean, required: false, default: false}
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account','authUser']),
    viewedAccountId() {
      return +this.$route.params.accountId;
    },
    viewedAccountRecord() {
      return this.account[this.viewedAccountId]
    },
    recordsAccountLevel() {
      return this.$route.meta.content.recordsAccountLevel;
    },
    accountSelector() {
      const selectorObject = {};
      if (this.viewedAccountId) {
        if (this.includeBranchesInLists === true) {
          if (this.viewedAccountRecord.accountLevel === 'intermediary') {
            selectorObject.intermediaryId = this.viewedAccountId          
          } else {
            selectorObject.accountId = [this.viewedAccountId,...this.viewedAccountRecord.childrenBranchIds]
          }
        } else {
          selectorObject.accountId = this.viewedAccountId
        }
      }
      if (this.recordsAccountLevel) {
        selectorObject.accountLevel = this.recordsAccountLevel
      }
      return selectorObject
    },

    definition() {
      return getDefinition(this.recordsAccountLevel);
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
