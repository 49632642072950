<template>
  <LDBModal result="info" :header="header" size="xx-large">
    <template #body>
      <ul>
        <li>This information has been retrieved from the SigniFlow System.</li>
      </ul>
      <div v-if="portfolioSummaryRetrievedStatus === 'retrieved'">
        <h3>Overview</h3>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="item"
          :fieldname="field.name"
          :record="portfolioSummaryData"
        />
        <h3>Documents</h3>
        <div class="lfx-portfolio-summary-table-container">
          <table-lite
            id="lfx-portfolio-summary-document-table"
            :is-static-mode="true"
            :is-slot-mode="true"
            :has-checkbox="false"
            :is-loading="false"
            :is-re-search="false"
            :columns="documentTableColumns"
            :rows="documentSortedRecords"
          >
            <template v-for="(col, i) of documentTableColumns" v-slot:[col.field]="data" :key="i">
              <template v-if="col.type === 'button' && data.value[col.field]">
                <LDBButton
                  :key="data.value[col.field].name"
                  :label="data.value[col.field].label"
                  :action="data.value[col.field].action"
                  :actionParams="data.value[col.field].actionParams"
                  :params="data.value[col.field].params"
                  :type="data.value[col.field].type"
                  :viewGuards="data.value[col.field].guards"
                  :enabledGuards="data.value[col.field].enabledGuards"
                  :onSuccess="data.value[col.field].onSuccess"
                ></LDBButton>
              </template>
              <template v-else>{{ data.value[col.field] }}</template>
            </template>
          </table-lite>
        </div>
        <h3>Signatories</h3>
        <div class="lfx-portfolio-summary-table-container">
          <table-lite
            id="lfx-portfolio-summary-signatory-table"
            :is-static-mode="true"
            :is-slot-mode="true"
            :has-checkbox="false"
            :is-loading="false"
            :is-re-search="false"
            :columns="signatoryTableColumns"
            :rows="signatorySortedRecords"
          >
            <template v-for="(col, i) of signatoryTableColumns" v-slot:[col.field]="data" :key="i">
              <template v-if="col.type === 'button' && data.value[col.field]">
                <LDBButton
                  :key="data.value[col.field].name"
                  :label="data.value[col.field].label"
                  :action="data.value[col.field].action"
                  :actionParams="data.value[col.field].actionParams"
                  :params="data.value[col.field].params"
                  :type="data.value[col.field].type"
                  :enabledGuards="data.value[col.field].enabledGuards"
                ></LDBButton>
              </template>
              <template v-else>{{ data.value[col.field] }}</template>
            </template>
          </table-lite>
        </div>
      </div>
      <div v-else-if="portfolioSummaryRetrievedStatus === 'error'">
        <br />
        Failed to Load Portfolio Summary.
        <br />
        {{ portfolioSummaryData.errorMessage }}
      </div>
      <template v-else>
        <br />
        Loading Portfolio Summary from SigniFlow - This may take some time
        <LDBLoader />
      </template>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import LDBField from '@/landobyte_vue/LDBField.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import TableLite from '@/views/general/TableLite.vue';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxPaymentPortfolioSummary',
  components: { LDBField, LDBLoader, TableLite },
  props: {},
  data() {
    return {
      portfolioSummaryRetrievedStatus: 'none',
      portfolioSummaryData: {},
    };
  },
  created() {
    this.portfolioSummaryData.integrationId = this.paymentRecord.integrationId;
    this.retrievePortfolioSummaryData();
  },
  mounted() {
    void this.clientRecord.signatoriesAndContacts;
  },
  computed: {
    ...mapGetters(['permissions', 'config']),
    ...mapState(['payment', 'signatory', 'user', 'authUser','account']),
    header() {
      return 'Portfolio Summary';
    },
    portfolioIsEditable() {
      return ['In Progress', 'Ready for Signing'].includes(this.portfolioSummaryData.status);
    },
    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Close',
          type: '',
          action: 'click',
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
      ];
      return allButtons;
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : 'todo';
    },

    // ----- Definition -----

    // ----- Record -----
    transactionId() {
      return +this.$route.params.transactionId;
    },
    paymentId() {
      return +this.$route.params.paymentId;
    },
    paymentRecord() {
      return this.payment[this.paymentId];
    },
    clientRecord() {
      return this.account[this.paymentRecord.accountId]
    },
    intermediaryRecord() {
      return this.account[this.paymentRecord.intermediaryId]
    },    // ----- Fields -----

    fields() {
      return this.newRecord.guarded_fields;
    },

    viewFields() {
      let res = [
        {
          label: 'Integration ID',
          name: 'integrationId',
          datatype: 'text',
        },
        {
          label: 'Status',
          name: 'status',
          datatype: 'text',
        },
        {
          label: 'Last Update Timestamp',
          name: 'lastUpdateTimestamp',
          datatype: 'text',
        },
      ];
      return res;
    },

    // ----- Document Table ----
    documentTableColumns() {
      const columns = [
        {
          col: 'documentTypeColumn',
          field: 'documentType',
          label: 'Document Type',
          isKey: false,
          sortable: false,
        },
        {
          col: 'statusColumn',
          field: 'status',
          label: 'Status',
          isKey: false,
          sortable: false,
        },
        {
          col: 'lastUpdateTimeColumn',
          field: 'lastUpdateTime',
          label: 'Last Updated',
          isKey: false,
          sortable: false,
        },
        // {
        //   // Note: always shows easisign-noreply@mercantile.co.za
        //   col: 'updatedByColumn',
        //   field: 'updatedBy',
        //   label: 'Updated By',
        //   isKey: false,
        //   sortable: false,
        // },
      ];
      if (this.portfolioIsEditable) {
        columns.push({
          col: 'deleteDocumentColumn',
          field: 'removeDocument',
          label: 'Remove Placeholder',
          isKey: false,
          sortable: false,
          type: 'button',
        });
      }
      return columns;
    },
    documentSortedRecords() {
      return this.portfolioSummaryData.documents;
    },

    // ----- Signatory Table ----
    signatoryTableColumns() {
      const columns = [
        {
          col: 'fullNameColumn',
          field: 'fullName',
          label: 'Full Name',
          isKey: false,
          sortable: false,
        },
        {
          col: 'emailColumn',
          field: 'email',
          label: 'Email',
          isKey: false,
          sortable: false,
        },
        {
          col: 'idNumberColumn',
          field: 'idNumber',
          label: 'ID Number',
          isKey: false,
          sortable: false,
        },
        {
          col: 'msisdnColumn',
          field: 'msisdn',
          label: 'Mobile Number',
          isKey: false,
          sortable: false,
        },
        {
          col: 'statusColumn',
          field: 'status',
          label: 'Status',
          isKey: false,
          sortable: false,
        },
        {
          col: 'lastUpdateTimeColumn',
          field: 'lastUpdateTime',
          label: 'Last Updated',
          isKey: false,
          sortable: false,
        },
        {
          col: 'resendEmailColumn',
          field: 'resendEmail',
          label: 'Resend Automated Email',
          isKey: false,
          sortable: false,
          type: 'button',
        },
        {
          col: 'resendEmailColumn',
          field: 'resendEmailManual',
          label: 'Send Manual Email',
          isKey: false,
          sortable: false,
          type: 'button',
        }
      ];
      return columns;
    },
    signatorySortedRecords() {
      return this.portfolioSummaryData.signers;
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),

    async retrievePortfolioSummaryData() {
      this.portfolioSummaryRetrievedStatus = 'retrieving';
      const portfolioSummaryDataResult = await doPermissionAction(
        { onSuccess: { doStoreUpdate: false } },
        'getPaymentPortfolioStatus',
        {
          transactionId: this.transactionId,
          paymentId: this.paymentId,
        }
      );
      if (portfolioSummaryDataResult.status === 'success') {
        if (portfolioSummaryDataResult.response.status === 'success') {
          this.portfolioSummaryData.status = portfolioSummaryDataResult.response.payload.status;
          this.portfolioSummaryData.lastUpdateTimestamp =
            portfolioSummaryDataResult.response.payload.lastUpdateTimestamp;
          this.portfolioSummaryData.documents = portfolioSummaryDataResult.response.payload.documents;
          this.portfolioSummaryData.signers = portfolioSummaryDataResult.response.payload.signers;
          this.populoateButtons();
          this.portfolioSummaryRetrievedStatus = 'retrieved';
        } else {
          this.portfolioSummaryData.errorMessage = portfolioSummaryDataResult.response.error.message;
          this.portfolioSummaryRetrievedStatus = 'error';
        }
      } else {
        this.portfolioSummaryData.errorMessage = portfolioSummaryDataResult.message;
        this.portfolioSummaryRetrievedStatus = 'error';
      }
    },
    populoateButtons() {
      const self = this;
      for (const signer of this.portfolioSummaryData.signers) {
        const signatoryId = this.getSignatoryIdForSigner(signer);
        signer.resendEmail = {
          key: 'resend' + signer.email,
          label: 'Resend',
          action: 'resendPaymentPortfolioEmail',
          actionParams: undefined,
          params: { transactionId: this.transactionId, paymentId: this.paymentId, signatoryId: signatoryId },
          type: 'primary',
          enabledGuards: !!signatoryId && !!(this.signatory[signatoryId]?.signatoryType !== 'user'),
        };
        signer.resendEmailManual = {
          key: 'resendManual' + signer.email,
          label: 'Email',
          action: 'url',
          actionParams: {url:this.getManualEmailText(signer.email,signer.fullName)},
          type: 'primary',
          guards: true,
          enabledGuards: !!signatoryId && !!(this.signatory[signatoryId]?.signatoryType !== 'user'),
        };
      }

      if (!this.portfolioIsEditable) {
        return;
      }
      const myName = this.user?.[this.authUser.id]?.displayName || "";
      for (const document of this.portfolioSummaryData.documents) {
        document.removeDocument = {
          key: 'remove' + document.documentType,
          label: 'Remove',
          action: 'removePaymentPortfolioDocument',
          actionParams: { documentIdentifier: document.documentType, reason: 'Removed by ' + myName }, // FIXME reason should not be hard-coded
          params: { transactionId: this.transactionId, paymentId: this.paymentId },
          
          type: 'primary',
          guards: document.deleteRequested !== true,
          enabledGuards: document.documentType !== 'Cross Border Declaration' && document.status === 'Placeholder Added' && document.deleteRequested !== true,
          onSuccess: {
            onAfterStoreUpdate: () => {
              document.removeDocument = {
                key: 'remove' + document.documentType,
                label: 'Requested...',
                action: undefined,
                type: 'primary',
                enabledGuards: false,
              };
            }
          },
        };
      }
    },
    getSignatoryIdForSigner(signer) {
      for (const signatoryId of this.paymentRecord.signatories) {
        const signatory = this.signatory?.[signatoryId];
        if (signatory?.idNumber == signer?.idNumber) {
          return signatory.id;
        }
      }
      return undefined;
    },
    getManualEmailText(email,fullName) {
      const url = "https://capitec.transaction.signiflow.com/"; // FIXME: Get from Back End
      const intermediaryName = this.intermediaryRecord.name;
      const portfolioNumber = this.portfolioSummaryData.integrationId;
      return `mailto:${email}?subject=Portfolio%20shared%20with%20you&Body=Dear%20${fullName}%0d%0a%0d%0aWe%20would%20like%20to%20inform%20you%20that%20there%20is%20a%20new%20document%20in%20SigniFlow%20that%20requires%20your%20signature.%0d%0a%0d%0a%0d%0a${intermediaryName}%20has%20uploaded%20a%20Payment%20declaration%20for%20your%20signature%20(or%20approval).%0d%0a%0d%0aOnce%20you%20have%20signed%20the%20document%20and%20uploaded%20the%20required%20documentation%20all%20parties%20will%20be%20automatically%20notified.%0d%0a%0d%0aPlease%20use%20the%20below%20URL%20and%20Portfolio%20Number%20to%20open%20the%20document.%0d%0a%0d%0a${url}%0d%0aPortfolio%20Number:${portfolioNumber}%0d%0a%0d%0aRegards,%0d%0a%0d%0a${intermediaryName}`
    }
    // ----- Fields -----

    // ----- API calls -----
  },
};
</script>

<style>
.lfx-portfolio-summary-table-container {
  position: relative;
  min-height: 200px;
}

#lfx-portfolio-summary-document-table {
  min-width: 100%;
}

#lfx-portfolio-summary-signatory-table {
  min-width: 100%;
}
</style>
