//@ts-nocheck

import { LdbCurrencyInt, LdbCurrencyDef } from '@/definitions/LdbCurrencyDef';

import { LfxModel, LfxDefinition } from './LfxModel';

class LdbCurrencytMdl extends LfxDefinition {
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

export class LdbCurrency extends LfxModel {
  constructor(data, state) {
    super({ definition_name: 'currency', ...data }, state);
  }
  get displayFKLink() {
    return false;
  }
  previousValidValueDate(valueDate: Date,itterations?:number) {
    if (!itterations) {
      itterations = 1
    }
    if (itterations > 10) {
      console.log("Could not find previous valid value date in 10 attempts")
      return undefined
    }
    const previousDate = new Date((new Date(valueDate)).setDate(valueDate.getDate() - 1));
    if (this.isValidValueDate(previousDate)) {
      return previousDate
    }
    return this.previousValidValueDate(previousDate,itterations + 1)
  }
  isValidValueDate(valueDate: Date) {
    if (valueDate.getDay() === 0 || valueDate.getDay() === 6) {
      return false;
    }
    // @ts-expect-error
    for (const publicHolidayId in this.store.state.config[1].publicHoliday) {
      // @ts-expect-error
      const publicHoliday = this.store.state.config[1].publicHoliday[publicHolidayId];
      if (
        new Date(publicHoliday.date).toDateString() === valueDate.toDateString() &&
        publicHoliday.currencyId === this.id
      ) {
        return false;
      }
    }
    return true;
  }
}

export function Definition(context, name: string) {
  return new LdbCurrencytMdl(LdbCurrencyDef, LdbCurrency, context, name, {});
}
