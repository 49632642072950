<template>
  <div class="lfx-widget-container open-payments" :style="spanStyle" :class="dynamicClass">
    <LfxWidgetHeader :header="`Open Payments (${paymentCount})`" :subheader="dateRange" :link="allRecordsLink" />
    <template v-if="paymentCount > 0">
      <LfxWidgetRow
        v-for="payment in pagedItems"
        :key="payment.id"
        :title="`${payment.reference} - ${paymentClientName(payment)}`"
        :subtitle="paymentDate(payment)"
        :info="`${payment.paymentCurrency} ${formatCurrency(payment.paymentAmount)}`"
        :avatar="paymentClientName(payment)"
        :avatarColor="avatarColor(payment)"
        :link="paymentLink(payment)"
      />

      <LfxWidgetScroller
        :totalItems="items.length"
        :itemsPerPage="itemsPerPage"
        :currentPage="pageNumber"
        @on-page="onPage"
      />
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LfxWidgetHeader from './LfxWidgetHeader.vue';
import LfxWidgetRow from './LfxWidgetRow.vue';
import LfxWidgetScroller from './LfxWidgetScroller.vue';
import { colorFromId } from '@/landobyte_vue/ldb_utils.ts';

import lfx_widget_mixin from './lfx_widget_mixin';

export default {
  mixins: [lfx_widget_mixin],
  components: {
    LfxWidgetHeader,
    LfxWidgetRow,
    LfxWidgetScroller,
  },
  props: {},
  data() {
    return {
      itemsPerPage: 4,
      pageNumber: 1,
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['payment', 'account']),
    allRecordsLink() {
      return { name: '_openPayments' };
    },

    // mostRecentPayments() {
    //   let res = [];
    //   let startItem = ( this.pageNumber -1 ) * this.itemsPerPage;
    //   for ( let p = 0 ; p < this.openPayments.length ; p ++  ) {
    //     let payment = this.openPayments[p];
    //     if (p >= startItem) {
    //       res.push(payment);
    //     }
    //     if (res.length === this.itemsPerPage) {
    //       break;
    //     }
    //   }
    //   return res;
    // },
    items() {
      return this.openPayments;
    },
    openPayments() {
      let statusses = ['submitted', 'signed', 'awaitingSettlement', 'settled', 'awaitingFunds', 'recallRequested'];
      return Object.values(this.payment)
        .filter((payment) => statusses.indexOf(payment.status) > -1)
        .sort((a, b) => new Date(a.mostRecent).getTime() - new Date(b.mostRecent).getTime());
    },
    paymentCount() {
      return this.openPayments.length;
    },
    minDate() {
      return this.openPayments[0]?.mostRecent.split('T')[0];
    },
    maxDate() {
      return this.openPayments[this.openPayments.length - 1]?.mostRecent.split('T')[0];
    },
    dateRange() {
      if (this.minDate === this.maxDate) {
        return this.minDate;
      } else {
        return `${this.minDate} to ${this.maxDate}`;
      }
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    paymentClientName(payment) {
      let clientId = payment.accountId;
      let clientName = this?.account?.[clientId]?.name || '-';
      if (clientName === '-') {
      }
      return clientName;
    },
    avatarColor(payment) {
      return colorFromId(payment.accountId);
    },

    onPage(page) {
      this.pageNumber = page;
    },
    paymentDate(payment) {
      return `${payment.mostRecent.split('T').join(' ').split('.')[0]}`;
    },
    paymentLink(payment) {
      // return `/transaction/${payment.transactionFileId}/payment/${payment.id}`;
      return this.payment?.[payment?.id]?.link;
    },
  },
};
</script>
<style></style>
