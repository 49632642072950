<template>
  <LFXListView
    v-if="hasListLoaded"
    :definition="definition"
    :records="records"
    :defaultSort="{ order: 'currencyPairIndex', sort: 'asc' }"
  />
  <template v-else>
    <LDBLoader />
  </template>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXListView from '@/views/general/LFXListView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

export default {
  name: 'LfxAccountMarkupsList',
  components: { LFXListView, LDBLoader },
  props: {},
  data() {
    return {};
  },
  created() {
    // this.account[this.viewedAccountId].getMarkups();
    this.account[this.viewedAccountId].parentMarkups;
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),
    viewedAccountId() {
      return +this.$route.params.accountId;
    },
    recordsAccountLevel() {
      return this.$route.meta.content.recordsAccountLevel;
    },
    records() {
      // return this.account?.[this.viewedAccountId]?.markup || {};
      let resArray = [];
      for (let m in this.account[this.viewedAccountId].markup) {
        let mrk = this.account[this.viewedAccountId].markup[m];
        if (mrk.status === 'visible') {
          resArray.push(mrk);
        }
      }
      return resArray;
    },
    hasListLoaded() {
      return this.account[this.viewedAccountId].parentMarkupStatus === 'retrieved';
    },
    definition() {
      return getDefinition('account_markup');
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
