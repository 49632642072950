<template>
  <LDBModal result="info" :header="header" size="x-large">
    <template #body>
      <h1>Client CIF</h1>
      <LDBField
        v-for="(field, fieldname) in viewFields"
        :key="fieldname"
        :field="field"
        :view="fullCifView"
        :fieldname="field.name"
        :definition="cif_definition"
        :record="fullCifRecord"
        @change="on_change_fullCif"
      />

      <h1>Associated Parties</h1>
      <template v-if="numberOfAssociatedCifIds == 0">
        <p>No Associated Parties have been added for this Application.</p>
      </template>

      <template v-else>
        <div v-for="associatedCifId in associatedCifIds" :key="associatedCifId">
          <LDBField
            v-for="(field, fieldname) in getViewFieldsForAssociatedCif(associatedCifId)"
            :key="fieldname"
            :field="field"
            :view="associatedCifView(associatedCifId)"
            :fieldname="field.name"
            :definition="cif_definition"
            :record="getAssociatedCifRecord(associatedCifId)"
            @change="on_change_associatedCif(associatedCifId, $event)"
          />
        </div>
      </template>

      <h1>Bank Accounts</h1>
      <template v-if="numberOfBankAccountApplicationIds == 0">
        <p>No Bank Accounts have been added for this Application.</p>
      </template>

      <template v-else>
        <div v-for="bankAccountApplicationId in bankAccountApplicationIds" :key="bankAccountApplicationId">
          <LDBField
            v-for="(field, fieldname) in getViewFieldsForBankAccountApplication(bankAccountApplicationId)"
            :key="fieldname"
            :field="field"
            :view="bankAccountApplicationView(bankAccountApplicationId)"
            :fieldname="field.name"
            :definition="bankAccount_definition"
            :record="getBankAccountApplicationRecord(bankAccountApplicationId)"
            @change="on_change_bankAccountApplication(bankAccountApplicationId, $event)"
          />
        </div>
      </template>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

import lodash from 'lodash';
const merge = lodash.merge;

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxApplicationUnifiedRelease',
  components: { LDBField },
  props: {},
  data() {
    return {};
  },
  created() {},
  beforeUnmount() {
    // this.record.revert();
    // TODO revert all records
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),
    header() {
      return `Release Application`;
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

        {
          name: 'releaseApplication',
          label: 'Release Application',
          type: 'primary',
          action: 'click',
          guards: true,
          enabledGuards: this.allOutstandFieldsCaptured,
          clickEvent() {
            self.releaseRecordsSequentially();
          },
        },
      ];
      return allButtons !== undefined ? allButtons : [];
    },

    allOutstandFieldsCaptured() {
      if (!this.fullCifRecord?.cifNumber) {
        return false;
      }

      for (const associatedCifId in this.associatedCifIds) {
        const associatedCif = this.getAssociatedCifRecord(associatedCifId);
        if (!associatedCif?.cifNumber) {
          return false;
        }
      }

      for (const bankAccountApplicationId in this.bankAccountApplicationIds) {
        const bankAccountApplication = this.getBankAccountApplicationRecord(bankAccountApplicationId);
        if (!bankAccountApplication?.accountNumber) {
          return false;
        }
      }

      return true;
    },

    // ----- Definitions -----

    cif_definition() {
      return getDefinition('application_cif');
    },
    bankAccount_definition() {
      return getDefinition('application_account');
    },

    // ----- Account Record -----

    clientId() {
      return +this.$route.params?.accountId;
    },
    clientRecord() {
      return this.account?.[this.clientId];
    },

    // ----- Application Record -----

    applicationId() {
      return +this.$route.params?.applicationId;
    },
    applicationRecord() {
      return this.clientRecord?.application?.[this.applicationId];
    },

    // ----- Full CIF -----

    fullCifId() {
      return +this.applicationRecord?.fullCifId;
    },
    fullCifRecord() {
      return this.applicationRecord?.application_cif?.[this.fullCifId];
    },

    fullCifView() {
      return this.fullCifRecord?.data?.cifNumber ? 'item' : 'edit';
    },

    // ----- Associated CIFs -----

    associatedCifIds() {
      const res = {};
      for (const associatedCifId in this.applicationRecord?.associatedCifIds) {
        const associatedCif = this.applicationRecord?.application_cif?.[associatedCifId];
        if (associatedCif?.status !== 'cancelled') {
          res[associatedCifId] = associatedCifId;
        }
      }
      return res;
    },

    numberOfAssociatedCifIds() {
      return Object.keys(this.associatedCifIds).length;
    },

    // ----- Bank Accounts -----

    bankAccountApplicationIds() {
      const res = {};
      for (const bankAccountApplicationId in this.applicationRecord?.bankAccountApplicationIds) {
        const bankAccountApplication = this.applicationRecord?.application_account?.[bankAccountApplicationId];
        if (bankAccountApplication?.status !== 'cancelled') {
          res[bankAccountApplicationId] = bankAccountApplicationId;
        }
      }
      return res;
    },

    numberOfBankAccountApplicationIds() {
      return Object.keys(this.bankAccountApplicationIds).length;
    },

    // ----- Fields -----

    // ----- CIF Fields

    fields() {
      return this.cif_definition.fields;
    },
    view() {
      return 'releaseCifApplication';
    },
    viewFields() {
      let res = [];
      for (let k in this.fields) {
        let field = this.fields[k];
        if (field.views[this.view] === true) {
          field.fieldname = k;
          res.push(field);
        }
      }
      return res;
    },

    // ----- Bank Account Fields

    bankAccountFields() {
      return this.bankAccount_definition.fields;
    },
    bankAccountView() {
      return 'releaseAccountApplication';
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),

    // ----- Records -----

    getAssociatedCifRecord(associatedCifId) {
      return this.applicationRecord?.application_cif?.[associatedCifId];
    },
    associatedCifView(associatedCifId) {
      const record = this.getAssociatedCifRecord(associatedCifId);
      return record?.data?.cifNumber ? 'item' : 'edit';
    },

    getBankAccountApplicationRecord(bankAccountApplicationId) {
      return this.applicationRecord?.application_account?.[bankAccountApplicationId];
    },
    bankAccountApplicationView(bankAccountApplicationId) {
      const record = this.getBankAccountApplicationRecord(bankAccountApplicationId);
      return record?.data?.accountNumber ? 'item' : 'edit';
    },

    // ----- Fields -----

    on_change_fullCif({ field, value }) {
      this.fullCifRecord[field] = value;
    },
    on_change_associatedCif(associatedCifId, evt) {
      const record = this.getAssociatedCifRecord(associatedCifId);
      record[evt.field] = evt.value;
    },
    on_change_bankAccountApplication(bankAccountApplicationId, evt) {
      const record = this.getBankAccountApplicationRecord(bankAccountApplicationId);
      record[evt.field] = evt.value;
    },

    getViewFieldsForAssociatedCif(associatedCifId) {
      let res = [];
      for (let k in this.fields) {
        let field = this.fields[k];
        if (field.views[this.view] === true) {
          field.fieldname = k;
          const updatedField = this.overrideField(associatedCifId, field);
          res.push(updatedField);
        }
      }
      return res;
    },
    overrideField(associatedCifId, field) {
      if (['cifNumber'].includes(field.name)) {
        const startingField = merge({}, field);
        const overrideObject = this.getFieldOverRideObject(associatedCifId, field);
        const newField = merge(startingField, overrideObject);
        return newField;
      } else {
        return field;
      }
    },
    getFieldOverRideObject(associatedCifId, field) {
      switch (field.name) {
        case 'cifNumber':
          const record = this.getAssociatedCifRecord(associatedCifId);

          return {
            label: `${record.displayName} - CIF Number`,
          };

        default:
          return {};
      }
    },

    getViewFieldsForBankAccountApplication(bankAccountApplicationId) {
      let res = [];
      for (let k in this.bankAccountFields) {
        let field = this.bankAccountFields[k];
        if (field.views[this.bankAccountView] === true) {
          field.fieldname = k;
          const updatedField = this.overrideBankAccountApplicationField(bankAccountApplicationId, field);
          res.push(updatedField);
        }
      }
      return res;
    },
    overrideBankAccountApplicationField(bankAccountApplicationId, field) {
      if (['accountNumber'].includes(field.name)) {
        const startingField = merge({}, field);
        const overrideObject = this.getFieldOverRideObjectForBankAccountApplication(bankAccountApplicationId, field);
        const newField = merge(startingField, overrideObject);
        return newField;
      } else {
        return field;
      }
    },
    getFieldOverRideObjectForBankAccountApplication(bankAccountApplicationId, field) {
      switch (field.name) {
        case 'accountNumber':
          const record = this.getBankAccountApplicationRecord(bankAccountApplicationId);

          return {
            label: `${record.currencyId} Account - Account Number`,
          };

        default:
          return {};
      }
    },

    // ----- API calls -----

    // async onApiSuccess(_responseObject) {},

    async releaseRecordsSequentially() {
      // release full cif if required

      if (this.fullCifView === 'edit') {
        const response = await this.releaseFullCif();
        if (response?.status !== 'success') {
          return;
        }
      }

      // release associated cifs if required

      const submittableAssociatedCifIds = [];
      for (const associatedCifId in this.associatedCifIds) {
        const associatedCif = this.getAssociatedCifRecord(associatedCifId);
        if (this.associatedCifView(associatedCifId) === 'edit') {
          submittableAssociatedCifIds.push(associatedCifId);
        }
      }

      for await (const associatedCifId of submittableAssociatedCifIds) {
        const response = await this.releaseAssociatedCif(associatedCifId);
        if (response?.status !== 'success') {
          return;
        }
      }

      // release bank account applications if required

      const submittableBankAccountApplicationIds = [];
      for (const bankAccountApplicationId in this.bankAccountApplicationIds) {
        const bankAccountApplication = this.getBankAccountApplicationRecord(bankAccountApplicationId);
        if (this.bankAccountApplicationView(bankAccountApplicationId) === 'edit') {
          submittableBankAccountApplicationIds.push(bankAccountApplicationId);
        }
      }

      for await (const bankAccountApplicationId of submittableBankAccountApplicationIds) {
        const response = await this.releaseBankAccountApplication(bankAccountApplicationId);
        if (response?.status !== 'success') {
          return;
        }
      }

      // close modal if all successful

      this.$router.go(-1);
    },

    async releaseFullCif() {
      const response = await doPermissionAction(
        this,
        'releaseCifApplication',
        { accountId: this.clientId, applicationId: this.applicationId, cifId: this.fullCifId },
        { cifNumber: this.fullCifRecord?.cifNumber }
      );
      return response;
    },
    async releaseAssociatedCif(associatedCifId) {
      const response = await doPermissionAction(
        this,
        'releaseCifApplication',
        { accountId: this.clientId, applicationId: this.applicationId, cifId: associatedCifId },
        { cifNumber: this.getAssociatedCifRecord(associatedCifId)?.cifNumber }
      );
      return response;
    },
    async releaseBankAccountApplication(bankAccountApplicationId) {
      const response = await doPermissionAction(
        this,
        'releaseAccountApplication',
        { accountId: this.clientId, applicationId: this.applicationId, applicationAccountId: bankAccountApplicationId },
        { accountNumber: this.getBankAccountApplicationRecord(bankAccountApplicationId)?.accountNumber }
      );
      return response;
    },
  },
};
</script>

<style></style>
