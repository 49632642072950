<template>
  <div class="lfx-search-page">
    <capitec-content-box>
        <div>
          <LDBField
            v-for="(field, fieldname) in viewFields"
            :key="fieldname"
            :field="field"
            view="edit"
            :fieldname="field.name"
            :definition="definition"
            :record="selectorRecord"
            @change="on_change"
          />
        </div>
      <capitec-action-bar>
        <LDBButton
          v-for="button in formButtons"
          :key="button.name"
          :label="button.label"
          :action="button.action"
          :actionParams="button.actionParams"
          :params="button.params"
          :type="button.type"
          :tooltip="button.tooltip"
          :modal="button.modal"
          :viewGuards="button.guards"
          :enabledGuards="button.enabledGuards"
          :onSuccess="button.onSuccess"
          @beforeClick="button.beforeClick"
          @click="button.onClick"
          @on-api-success="button.onApiSuccess"
          @on-api-error="button.onApiError"
        >
          <template v-if="button.modal !== undefined" #modal>
            <div v-html="button.modal.content"></div>
          </template>
        </LDBButton>
      </capitec-action-bar>
      <template v-if="dataStatus === 'retrieving'">
        <LDBLoader/>
      </template>
      <div v-else-if="dataStatus === 'retrieved' && reportData && Object.keys(reportData).length > 0" class="lfx-search-table-container">
        {{Object.keys(reportData).length}}
        <LFXListView :definition="definition" :records="reportData" />
      </div>
      <div v-else-if="dataStatus === 'retrieved'" >
        No Data Returned for selectors
      </div>
    </capitec-content-box>

  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBField from '@/landobyte_vue/LDBField.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import LFXListView from '@/views/general/LFXListView.vue';
import { evaluate_simple_guards } from '@/db-interface/db_interface_guard';

export default {
  name: 'LfxSearch',
  components: { LDBField,LDBLoader,LFXListView },
  props: {
    definition: { type: Object, required: true },
    dataAction: { type: String, required: true },
    downloadAction: { type: String, required: false },
    baseSelectors: { type:Object, required: false, default: undefined },
  },
  data() {
    return {
      selectorsUpdated: false,
      dataStatus: 'none',
      fieldRecord: {},
      selectorRecord: {},
      fieldsAndDataTypes:{},
      formSelectors: {},
      reportData: undefined,
    };
  },
  created() {},
  mounted() {
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]),

    // ----- Buttons -----

    formButtons() {
      let self = this;
      return [
        {
          name: 'search',
          label: "Search",
          type: 'primary',
          action: this.dataAction,
          onSuccess: { doStoreUpdate: false },
          enabledGuards: this.selectorsUpdated === true,
          actionParams: {additionalSelector:{where:{
            and:[
              this.baseSelectors,
              this.formSelectors,
            ]
          }}},
          beforeClick() {
            self.dataStatus = "retrieving";
          },
          onApiSuccess: self.onApiSuccess,
          onApiError() {
            self.dataStatus = "error"
          }
        },
        {
          name: 'download',
          label: "Download",
          type: 'primary',
          action: this.downloadAction,
          guards: this.downloadAction && this.dataStatus === "retrieved" && this.selectorsUpdated === false,
          actionParams: {additionalSelector:{where:{
            and:[
              this.baseSelectors,
              this.formSelectors,
            ]
          }}},
        },
      ];
    },

  // ----- Fields -----
    fields() {
      return this.definition.fields
    },
    viewFields() {
      const filteredFields =[];
      for (const field of this.allFields) {
        if (evaluate_simple_guards(field.guards,this.fieldRecord)) {
          filteredFields.push(field)
        }
      }
      return filteredFields;
    },
    allFields() {
      let allFields = [];
      for (let k in this.fields) {
        let field = this.fields[k];
        var fieldDisplayForView = field.views.search || field.views.customViews?.search;
        if (typeof fieldDisplayForView === 'function') {
          fieldDisplayForView = fieldDisplayForView({
            authUser: this.authUser,
            view: this.useFieldView,
            routeMetaData:this.$route.meta,
          });
        }
        if (fieldDisplayForView) {
          switch (field.datatype) {
            case 'text':
              allFields.push({
                    field: field.field,
                    group: field.group,
                    label: field.label,
                    name: field.name,
                    datatype: field.datatype,
                    datatype_name: field.datatype_name,
                  });
              this.fieldsAndDataTypes[field.name] = 'text'
              this.fieldRecord[field.name] = ""
              break
            default:
              if (typeof field.datatype === 'object') {
                if (field.datatype.datetime?.type === "date") {
                  allFields.push({
                    field: field.name+"|include",
                    group: field.group,
                    label: "Filter by "+field.label+"?",
                    name: field.name+"|include",
                    datatype: 'boolean',
                    datatype_name: field.datatype_name
                  });
                  allFields.push({
                    field: field.field+"|from",
                    group: field.group,
                    label: field.label+" Start",
                    name: field.name+"|from",
                    datatype: field.datatype,
                    datatype_name: field.datatype_name,
                    guards: { [field.name+"|include"]: { eq: true } },
                  });
                  allFields.push({
                    field: field.field+"|to",
                    group: field.group,
                    label: field.label+" End",
                    name: field.name+"|to",
                    datatype: field.datatype,
                    datatype_name: field.datatype_name,
                    guards: { [field.name+"|include"]: { eq: true } },
                  });
                  this.fieldsAndDataTypes[field.name+"|include"] = 'includer';
                  this.fieldsAndDataTypes[field.name+"|from"] = 'dateFrom';
                  this.fieldsAndDataTypes[field.name+"|to"] = 'dateTo';
                  this.fieldRecord[field.name+"|include"] = false;
                  this.fieldRecord[field.name+"|from"] = undefined;
                  this.fieldRecord[field.name+"|to"] = undefined;
                } else  if (field.datatype.currency) {
                  allFields.push({
                    field: field.name+"|include",
                    group: field.group,
                    label: "Filter by "+field.label+"?",
                    name: field.name+"|include",
                    datatype: 'boolean',
                    datatype_name: field.datatype_name
                  });
                  allFields.push({
                    field: field.field+"|from",
                    group: field.group,
                    label: field.label+" From",
                    name: field.name+"|from",
                    datatype: field.datatype,
                    datatype_name: field.datatype_name,
                    guards: { [field.name+"|include"]: { eq: true } },
                  });
                  allFields.push({
                    field: field.field+"|to",
                    group: field.group,
                    label: field.label+" To",
                    name: field.name+"|to",
                    datatype: field.datatype,
                    datatype_name: field.datatype_name,
                    guards: { [field.name+"|include"]: { eq: true } },
                  });
                  this.fieldsAndDataTypes[field.name+"|include"] = 'includer';
                  this.fieldsAndDataTypes[field.name+"|from"] = 'currencyFrom';
                  this.fieldsAndDataTypes[field.name+"|to"] = 'currencyTo';
                  this.fieldRecord[field.name+"|include"] = false;
                  this.fieldRecord[field.name+"|from"] = undefined;
                  this.fieldRecord[field.name+"|to"] = undefined;

                } else if (field.datatype.option) {
                  allFields.push({
                    field: field.field,
                    group: field.group,
                    label: field.label,
                    name: field.name,
                    datatype: field.datatype,
                    datatype_name: field.datatype_name,
                  });
                  this.fieldsAndDataTypes[field.name] = 'option'
                  this.fieldRecord[field.name] = undefined
                } else {
                  throw new Error (`Data Type Object ${Object.keys(field.datatype)[0]} not yet implemented`)
                }
              } else {
                throw new Error (`Data Type ${field.datatype} not yet implemented`)
              }
          }
        }
      }
      return allFields
    },
    collectionPathTemplate() {return this.definition?.collectionPath;}

  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    on_change({ field, value }) {
      this.fieldRecord[field] = value
      switch (this.fieldsAndDataTypes[field]) {
        case undefined: break
        case 'text': this.formSelectors[field] = {like:value}; break
        case 'boolean': this.formSelectors[field] = {eq:value}; break
        case 'option': this.formSelectors[field] = {eq:value}; break
        case 'includer': 
          if (!value) {
            this.formSelectors[field.split("|")[0]] = undefined;
          }
          break
        case 'dateFrom': this.formSelectors[field.split("|")[0]] = {gte:value}; break
        case 'dateTo': this.formSelectors[field.split("|")[0]] = {lte:value}; break
        case 'currencyFrom': this.formSelectors[field.split("|")[0]] = {gte:value}; break
        case 'currencyTo': this.formSelectors[field.split("|")[0]] = {lte:value}; break
        default:
          throw new Error (`Data Type ${this.fieldsAndDataTypes[field]} not yet implemented`)
      } 
      this.selectorsUpdated = true
    },
    onApiSuccess(response) {
      this.reportData = {};
      for (const row of response.response?.rows) {
        row.collection_path = Mustache.render(this.collectionPathTemplate,row)
        this.reportData[row.id] = this.definition.class(row, this.$store);
      }
      this.dataStatus = "retrieved";
      this.selectorsUpdated = false;
    },
  }
}

</script>

<style>

.lfx-search-table-container{
  /* flex-grow:1;
  flex-shrink:1;
  position: relative;
  min-height: 1000px; */
}
.lfx-search-page{
  /* position: absolute;
  top:0px;
  bottom:0px;
  left:0px;
  right: 0px; */
}

</style>

