//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2022 LandoByte (Pty) Ltd.
//  File        : LfxApplicationDocumentTypeDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Application Document Type record for the LandoFX systems
//  Created     : 16 September 2022 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition } from '../definitions/LdbInterfaces';
import { LfxAccountAccountType, LfxAccountInt } from './LfxAccountDef';
import { LfxApplicationCifEntityInt, LfxCompanyType } from './LfxApplicationCifEntityDef';
import {
  LfxApplicationCifIndividualInt,
  LfxApplicationIndividualResidentialStatus,
} from './LfxApplicationCifIndividualDef';
import type { LfxApplicationInt, LfxApplicationType } from './LfxApplicationDef';

//----------------------------------------------------------------------------------------------------------------------

export type LfxApplicationDocumentTypeStatus = 'active' | 'disabled';
export type LfxApplicationDocumentTypeDocumentType = 'kyc' | 'signOn' | 'edd';
export type LfxApplicationDocumentTypeUploadType = 'signiFlow' | 'local' | 'both';
export type LfxApplicationDocumentTypeDueDilligenceType =
  | 'either'
  | 'faceToFaceOnly'
  | 'nonFaceToFaceOnly'
  | 'eitherNonFTFMandatory'
  | 'eitherFTFMandatory';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxApplicationDocumentTypeInt {
  id?: number;
  documentName?: string;
  description?: string;
  documentType?: LfxApplicationDocumentTypeDocumentType;
  accountType?: LfxAccountAccountType | 'either';
  residentialStatusOrCompanyTypes?: (LfxApplicationIndividualResidentialStatus | LfxCompanyType | 'soleProp')[];
  applicationType?: LfxApplicationType;
  dueDilligenceType?: LfxApplicationDocumentTypeDueDilligenceType;
  mandatory?: boolean;
  documentUploadType?: LfxApplicationDocumentTypeUploadType;
  requiredForOnboardingWithIncompleteData?: boolean;

  status?: LfxApplicationDocumentTypeStatus;

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxApplicationDocumentTypeDef: LdbDefinition = {
  title: 'Application Document Type',
  table: 'LfxApplicationDocumentType',
  pagingType: 'frontEnd',
  collectionPath: '/config/1/applicationDocumentType',
  database: 'sql',

  view: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
  indexes: [],
  fields: {
    id: {
      label: 'ID',
      datatype: { id: { descriptionField: 'documentName' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    documentName: {
      label: 'Document Name',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
    },
    description: {
      label: 'Description',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
    },
    documentType: {
      label: 'Document Type',
      search: true,
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'kyc', name: 'KYC' },
            { id: 'signOn', name: 'Sign On' },
            { id: 'edd', name: 'Enhanced Due Diligence'}
          ],
        },
      },
      default: 'signOn',
      mandatory: true,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },

    accountType: {
      label: 'Account Type',
      search: true,
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'entity', name: 'Entity' },
            { id: 'individual', name: 'Individual' },
            { id: 'either', name: 'Either' },
          ],
        },
      },
      default: 'entity',
      mandatory: true,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },
    residentialStatusOrCompanyTypes: {
      label: 'Residential Statuses or Company Types',

      datatype: {
        json: {},
      },
      default: [],
      mandatory: true,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'bopCategories',
    },

    applicationType: {
      label: 'Application Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'newClient', name: 'New Client', tagType: 'info' },
            { id: 'newAccount', name: 'New Account', tagType: 'info' },
            { id: 'amendClient', name: 'Amend Client', tagType: 'info' },
            { id: 'amendAccount', name: 'Amend Account', tagType: 'info' },
          ],
        },
      },
      default: 'newClient',
      mandatory: true,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },

    dueDilligenceType: {
      label: 'Due Dilligence Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'either', name: 'Either', tagType: 'info' },
            { id: 'faceToFaceOnly', name: 'Face To Face Only', tagType: 'info' },
            { id: 'nonFaceToFaceOnly', name: 'Non Face To Face Only', tagType: 'info' },
            { id: 'eitherNonFTFMandatory', name: 'Either - Mandatory for Non Face To Face', tagType: 'info' },
            { id: 'eitherFTFMandatory', name: 'Either - Mandatory for Face To Face', tagType: 'info' },
          ],
        },
      },
      default: 'either',
      mandatory: true,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },

    mandatory: {
      label: 'Mandatory',
      datatype: 'boolean',
      default: false,
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'settings',
    },
    documentUploadType: {
      label: 'Document Upload Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'signiFlow', name: 'SigniFlow', tagType: 'info' },
            { id: 'local', name: 'Local', tagType: 'info' },
            { id: 'both', name: 'Both', tagType: 'info' },
          ],
        },
      },
      default: 'newClient',
      mandatory: true,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },
    requiredForOnboardingWithIncompleteData: {
      label: 'Required for Onboarding with Incompelte Data',
      datatype: 'boolean',
      default: false,
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'settings',
    },

    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'active', name: 'Active' },
            { id: 'disabled', name: 'Disabled' },
          ],
        },
      },
      default: 'active',
      mandatory: true,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
    },

    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: '',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
  },
};

//----------------------------------------------------------------------------------------------------------------------

export const filterDocumentTypes = (
  client: LfxAccountInt,
  application: LfxApplicationInt,
  applicationCifInfo: LfxApplicationCifEntityInt | LfxApplicationCifIndividualInt,
  includeKyc: boolean,
  includeEdd: boolean,
  documentUploadType: 'signiFlow' | 'local',
  onboardingWithIncompleteData: boolean,
  documentTypes: LfxApplicationDocumentTypeInt[],
) => {
  const filterDocumentTypes: LfxApplicationDocumentTypeInt[] = [];
  for (const documentType of documentTypes) {
    let includeDocumentType = true;
    if ((!includeKyc && documentType.documentType === 'kyc') || (!includeEdd && documentType.documentType === 'edd')) {
      includeDocumentType = false;
    }
    if (documentType.accountType !== 'either' && documentType.accountType !== client.accountType) {
      includeDocumentType = false;
    }
    if (documentType.applicationType !== application.applicationType) {
      includeDocumentType = false;
    }
    if (documentType.documentUploadType !== 'both' && documentType.documentUploadType !== documentUploadType) {
      includeDocumentType = false;
    }
    if (
      Array.isArray(documentType.residentialStatusOrCompanyTypes) &&
      documentType.residentialStatusOrCompanyTypes.length > 0
    ) {
      if (client.accountType === 'entity') {
        if (
          !documentType.residentialStatusOrCompanyTypes.includes(
            (applicationCifInfo as LfxApplicationCifEntityInt).companyType!
          )
        ) {
          includeDocumentType = false;
        }
      } else {
        if (
          !documentType.residentialStatusOrCompanyTypes.includes(
            (applicationCifInfo as LfxApplicationCifIndividualInt).residentialStatus!
          )
        ) {
          if (
            !(
              documentType.residentialStatusOrCompanyTypes.includes('soleProp') &&
              (applicationCifInfo as LfxApplicationCifIndividualInt).soleProprietor
            )
          ) {
            includeDocumentType = false;
          }
        }
      }
    }
    if (onboardingWithIncompleteData && !documentType.requiredForOnboardingWithIncompleteData) {
      includeDocumentType = false;
    }
    if (
      (documentType.dueDilligenceType === 'faceToFaceOnly' && applicationCifInfo.clientDueDilligence === 'Non-Face-to-Face') ||
      (documentType.dueDilligenceType === 'nonFaceToFaceOnly' && applicationCifInfo.clientDueDilligence === 'Face-to-Face')
    ) {
      includeDocumentType = false;
    }
    if (includeDocumentType) {
      filterDocumentTypes.push(documentType);
    }
  }
  return filterDocumentTypes;
};

//----------------------------------------------------------------------------------------------------------------------
