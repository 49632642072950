<template>
  <LDBModal result="info" header="Business Sector Code Picker" size="x-large" :closable="false">
    <template #body>
      <div>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          :view="field.view"
          :fieldname="field.name"
          :definition="undefined"
          :record="codeRecord"
          @change="on_change"
        />
      </div>
    </template>

    <template #footer>
      <capitec-spacer />
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        :onSuccess="button.onSuccess"
        @beforeClick="button.beforeClick"
        @click="button.onClick"
        @on-api-success="button.onApiSuccess"
        @on-api-error="button.onApiError"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBField from '@/landobyte_vue/LDBField.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import TableLite from '@/views/general/TableLite.vue';

export default {
  name: 'LfxClientApplicationBscPicker',
  components: { LDBField, LDBLoader, TableLite },
  // emits: ['bsc-code-selected'],
  props: {},
  data() {
    return {
      codeRecord: {
        section: undefined,
        code1: undefined,
        code2: undefined,
        code3: undefined,
        code: undefined,
      },
    };
  },
  created() {},
  mounted() {
    console.log('cifRecord', this.cifRecord);
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    // ----- Account Record -----

    clientId() {
      return +this.$route.params.accountId;
    },
    clientRecord() {
      return this.account[this.clientId];
    },

    // ----- Application Record -----

    applicationId() {
      return +this.$route.params.applicationId;
    },
    applicationRecord() {
      return this.clientRecord?.application?.[this.applicationId];
    },

    // ----- Viewed CIF Record -----

    cifId() {
      return +this.$route.params.cifId;
    },
    cifRecord() {
      return this.applicationRecord?.application_cif?.[this.cifId];
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      return [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          action: 'click',
          onClick() {
            self.$router.go(-1);
          },
        },
        {
          name: 'confirm',
          label: 'Confirm',
          type: 'primary',
          action: 'click',
          enabledGuards: this.codeRecord.code !== undefined,
          onClick() {
            if (self.cifRecord.accountType === 'individual') {
              let infoObjectkey = Object.keys(self.cifRecord.application_cif_individual)[0];
              self.cifRecord.application_cif_individual[infoObjectkey].industryCode = self.codeRecord.code;
            } else {
              let infoObjectkey = Object.keys(self.cifRecord.application_cif_entity)[0];
              self.cifRecord.application_cif_entity[infoObjectkey].businessSectorCode = self.codeRecord.code;
            }
            self.$router.go(-1);
          },
        },
      ];
    },

    // ----- Fields -----

    viewFields() {
      const allFields = {
        section: {
          name: 'section',
          label: 'Section',
          datatype: {
            option: {
              optionType: 'number',
              options: this.sectionOptions,
            },
          },
          view: 'edit',
          guards: true,
        },
        code1: {
          name: 'code1',
          label: 'Code 1',
          datatype: {
            option: {
              optionType: 'number',
              options: this.code1Options,
            },
          },
          view: 'edit',
          guards: this.codeRecord.section !== undefined && this.code1Options.length > 1,
        },
        code2: {
          name: 'code2',
          label: 'Code 2',
          datatype: {
            option: {
              optionType: 'number',
              options: this.code2Options,
            },
          },
          view: 'edit',
          guards: this.codeRecord.code1 !== undefined && this.code2Options.length > 1,
        },
        code3: {
          name: 'code3',
          label: 'Code 3',
          datatype: {
            option: {
              optionType: 'number',
              options: this.code3Options,
            },
          },
          view: 'edit',
          guards: this.codeRecord.code2 !== undefined && this.code3Options.length > 1,
        },
        code: {
          name: 'code',
          label: 'Code',
          datatype: 'text',
          view: 'item',
          guards: this.codeRecord.code !== undefined,
        },
      };
      const returnFields = {};
      for (const f in allFields) {
        if (allFields[f].guards !== false) {
          returnFields[f] = allFields[f];
        }
      }
      return returnFields;
    },
    sectionOptions() {
      const res = [{ id: undefined, name: 'Please Make a Selection...' }];
      for (let i = 0; i < this.bscConfig().length; i++) {
        const section = this.bscConfig()[i];
        res.push({
          id: i,
          name: section.id + ': ' + section.name,
        });
      }
      return res;
    },
    code1Options() {
      const res = [{ id: undefined, name: 'Please Make a Selection...' }];
      const selections = this.bscConfig()[this.codeRecord.section]?.subCodes || [];
      for (let i = 0; i < selections.length; i++) {
        const selection = selections[i];
        res.push({
          id: i,
          name: selection.id + ': ' + selection.name,
        });
      }
      return res;
    },
    code2Options() {
      const res = [{ id: undefined, name: 'Please Make a Selection...' }];
      const selections = this.bscConfig()[this.codeRecord.section]?.subCodes?.[this.codeRecord.code1]?.subCodes || [];
      for (let i = 0; i < selections.length; i++) {
        const selection = selections[i];
        res.push({
          id: i,
          name: selection.id + ': ' + selection.name,
        });
      }
      return res;
    },
    code3Options() {
      const res = [{ id: undefined, name: 'Please Make a Selection...' }];
      const selections =
        this.bscConfig()[this.codeRecord.section]?.subCodes?.[this.codeRecord.code1]?.subCodes?.[this.codeRecord.code2]
          ?.subCodes || [];
      for (let i = 0; i < selections.length; i++) {
        const selection = selections[i];
        res.push({
          id: i,
          name: selection.id + ': ' + selection.name,
        });
      }
      return res;
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    on_change({ field, value }) {
      this.codeRecord[field] = value;
      switch (field) {
        case 'section':
          this.codeRecord.code1 = undefined;
        case 'code1':
          this.codeRecord.code2 = undefined;
        case 'code2':
          this.codeRecord.code3 = undefined;
          this.codeRecord.code = undefined;
          break;
      }
      this.codeRecord.code = this.getCode();
    },
    getCode() {
      if (this.codeRecord.section !== undefined) {
        const section = this.bscConfig()[this.codeRecord.section];
        if (this.codeRecord.code1 !== undefined) {
          const code1 = section.subCodes[this.codeRecord.code1];
          if (!code1.subCodes || code1.subCodes.length == 0) {
            return code1.index;
          }
          if (this.codeRecord.code2 !== undefined) {
            const code2 = code1.subCodes[this.codeRecord.code2];
            if (!code2.subCodes || code2.subCodes.length == 0) {
              return code2.index;
            }
            if (this.codeRecord.code3 !== undefined) {
              const code3 = code2.subCodes[this.codeRecord.code3];
              return code3.index;
            }
          }
        }
      }
      return undefined;
    },
    bscConfig() {
      return [
        {
          id: 'A',
          index: 'Section A',
          name: 'Agriculture, forestry and fishing',
          subCodes: [
            {
              id: '01',
              index: '01',
              name: 'Crop and animal production, hunting and related service activities',
              subCodes: [
                {
                  id: '011',
                  index: '011',
                  name: 'Growing of non-perennial crops',
                  subCodes: [
                    {
                      id: '0111',
                      index: '0111',
                      name: 'Growing of cereals (except rice), leguminous crops and oil seeds',
                    },
                    { id: '0112', index: '0112', name: 'Growing of rice' },
                    {
                      id: '0113',
                      index: '0113',
                      name: 'Growing of vegetables and melons, roots and tubers',
                    },
                    { id: '0114', index: '0114', name: 'Growing of sugar cane' },
                    { id: '0115', index: '0115', name: 'Growing of tobacco' },
                    { id: '0116', index: '0116', name: 'Growing of fibre crops' },
                    {
                      id: '0119',
                      index: '0119',
                      name: 'Growing of other non-perennial crops',
                    },
                  ],
                },
                {
                  id: '012',
                  index: '012',
                  name: 'Growing of perennial crops',
                  subCodes: [
                    { id: '0121', index: '0121', name: 'Growing of grapes' },
                    {
                      id: '0123',
                      index: '0123',
                      name: 'Growing of citrus fruits',
                    },
                    {
                      id: '0124',
                      index: '0124',
                      name: 'Growing of pome fruits and stone fruits',
                    },
                    {
                      id: '0125',
                      index: '0125',
                      name: 'Growing of other tree and bush fruits and nuts',
                    },
                    {
                      id: '0126',
                      index: '0126',
                      name: 'Growing of oleaginous fruits',
                    },
                    {
                      id: '0127',
                      index: '0127',
                      name: 'Growing of beverage crops',
                    },
                    {
                      id: '0128',
                      index: '0128',
                      name: 'Growing of spices, aromatic, drug and pharmaceutical crops',
                    },
                    {
                      id: '0129',
                      index: '0129',
                      name: 'Growing of other perennial crops',
                    },
                  ],
                },
                { id: '013', index: '0130', name: 'Plant propagation' },
                {
                  id: '014',
                  index: '014',
                  name: 'Animal production',
                  subCodes: [
                    {
                      id: '0141',
                      index: '0141',
                      name: 'Raising of cattle and buffaloes',
                    },
                    {
                      id: '0142',
                      index: '0142',
                      name: 'Raising of horses and other equines',
                    },
                    {
                      id: '0143',
                      index: '0143',
                      name: 'Raising of camels and camelids',
                    },
                    {
                      id: '0144',
                      index: '0144',
                      name: 'Raising of sheep and goats',
                    },
                    { id: '0145', index: '0145', name: 'Raising of swine/pigs' },
                    { id: '0146', index: '0146', name: 'Raising of poultry' },
                    {
                      id: '0149',
                      index: '0149',
                      name: 'Raising of other animals',
                    },
                  ],
                },
                { id: '015', index: '0150', name: 'Mixed farming  ' },
                {
                  id: '016',
                  index: '016',
                  name: 'Support activities to agriculture and post-harvest crop activities',
                  subCodes: [
                    {
                      id: '0161',
                      index: '0161',
                      name: 'Support activities for crop production',
                    },
                    {
                      id: '0162',
                      index: '0162',
                      name: 'Support activities for animal production',
                    },
                    {
                      id: '0163',
                      index: '0163',
                      name: 'Post-harvest crop activities',
                    },
                    {
                      id: '0164',
                      index: '0164',
                      name: 'Seed processing for propagation',
                    },
                  ],
                },
                {
                  id: '017',
                  index: '0170',
                  name: 'Hunting, trapping and related service activities',
                },
              ],
            },
            {
              id: '02',
              index: '02',
              name: 'Forestry and logging',
              subCodes: [
                {
                  id: '021',
                  index: '0210',
                  name: 'Silviculture and other forestry activities',
                },
                { id: '022', index: '0220', name: 'Logging' },
                {
                  id: '023',
                  index: '0230',
                  name: 'Gathering of non-wood forest products',
                },
                { id: '024', index: '0240', name: 'Support services to forestry' },
              ],
            },
            {
              id: '03',
              index: '03',
              name: 'Fishing and aquaculture',
              subCodes: [
                {
                  id: '031',
                  index: '031',
                  name: 'Fishing',
                  subCodes: [
                    { id: '0311', index: '0311', name: 'Marine fishing' },
                    { id: '0312', index: '0312', name: 'Freshwater fishing' },
                  ],
                },
                {
                  id: '032',
                  index: '032',
                  name: 'Aquaculture',
                  subCodes: [{ id: '0321', index: '0321', name: 'Marine aquaculture' }],
                },
              ],
            },
          ],
        },
        {
          id: 'B',
          index: 'Section B',
          name: 'Mining and quarrying',
          subCodes: [
            {
              id: '05',
              index: '05',
              name: 'Mining of coal and lignite',
              subCodes: [
                { id: '051', index: '0510', name: 'Mining of hard coal' },
                { id: '052', index: '0520', name: 'Mining of lignite' },
              ],
            },
            {
              id: '06',
              index: '06',
              name: 'Extraction of crude petroleum and natural gas',
              subCodes: [
                {
                  id: '061',
                  index: '0610',
                  name: 'Extraction of crude petroleum',
                },
                { id: '062', index: '0620', name: 'Extraction of natural gas' },
              ],
            },
            {
              id: '07',
              index: '07',
              name: 'Mining of metal ores',
              subCodes: [
                { id: '071', index: '0710', name: 'Mining of iron ores' },
                {
                  id: '072',
                  index: '072',
                  name: 'Mining of non-ferrous metal ores',
                  subCodes: [
                    {
                      id: '0721',
                      index: '0721',
                      name: 'Mining of uranium and thorium ores',
                    },
                    {
                      id: '0729',
                      index: '0729',
                      name: 'Mining of other non-ferrous metal ores',
                    },
                  ],
                },
              ],
            },
            {
              id: '08',
              index: '08',
              name: 'Other mining and quarrying',
              subCodes: [
                {
                  id: '081',
                  index: '0810',
                  name: 'Quarrying of stone, sand and clay',
                },
                {
                  id: '089',
                  index: '089',
                  name: 'Mining and quarrying n.e.c.',
                  subCodes: [
                    {
                      id: '0891',
                      index: '0891',
                      name: 'Mining of chemical and fertilizer minerals',
                    },
                    { id: '0892', index: '0892', name: 'Extraction of peat' },
                    {
                      id: '0899',
                      index: '0899',
                      name: 'Other mining and quarrying n.e.c.',
                    },
                  ],
                },
              ],
            },
            {
              id: '09',
              index: '09',
              name: 'Mining support service activities',
              subCodes: [
                {
                  id: '091',
                  index: '0910',
                  name: 'Support activities for petroleum and natural gas extraction',
                },
                {
                  id: '099',
                  index: '0990',
                  name: 'Support activities for other mining and quarrying',
                },
              ],
            },
          ],
        },
        {
          id: 'C',
          index: 'Section C',
          name: 'Manufacturing',
          subCodes: [
            {
              id: '10',
              index: '10',
              name: 'Manufacturing of food products',
              subCodes: [
                {
                  id: '101',
                  index: '1010',
                  name: 'Processing and preserving of meat',
                },
                {
                  id: '102',
                  index: '1020',
                  name: 'Processing and preserving of fish, crustaceans and molluscs',
                },
                {
                  id: '103',
                  index: '1030',
                  name: 'Processing and preserving of fruit and vegetables',
                },
                {
                  id: '104',
                  index: '1040',
                  name: 'Manufacture of vegetable and animal oils and fats',
                },
                {
                  id: '105',
                  index: '1050',
                  name: 'Manufacture of dairy products',
                },
                {
                  id: '106',
                  index: '106',
                  name: 'Manufacture of grain mill products, starches and starch products',
                  subCodes: [
                    {
                      id: '1061',
                      index: '1061',
                      name: 'Manufacture of grain mill products',
                    },
                    {
                      id: '1062',
                      index: '1062',
                      name: 'Manufacture of starches and starch products',
                    },
                  ],
                },
                {
                  id: '107',
                  index: '107',
                  name: 'Manufacture of other products',
                  subCodes: [
                    {
                      id: '1071',
                      index: '1071',
                      name: 'Manufacture of bakery products',
                    },
                    { id: '1072', index: '1072', name: 'Manufacture of sugar' },
                    {
                      id: '1073',
                      index: '1073',
                      name: 'Manufacture of cocoa, chocolate and sugar confectionery',
                    },
                    {
                      id: '1074',
                      index: '1074',
                      name: 'Manufacture of macaroni, noodles, couscous and similar farinaceous products',
                    },
                    {
                      id: '1075',
                      index: '1075',
                      name: 'Manufacture of prepared meals and dishes',
                    },
                    {
                      id: '1079',
                      index: '1079',
                      name: 'Manufacture of other food products n.e.c.',
                    },
                  ],
                },
                {
                  id: '108',
                  index: '1080',
                  name: 'Manufacture of prepared animal feeds',
                },
              ],
            },
            {
              id: '11',
              index: '11',
              name: 'Manufacturing of beverages',
              subCodes: [
                {
                  id: '1101',
                  index: '1101',
                  name: 'Distilling, rectifying and blending of spirits',
                },
                { id: '1102', index: '1102', name: 'Manufacture of wines' },
                {
                  id: '1103',
                  index: '1103',
                  name: 'Manufacture of malt liquors and malt',
                },
                {
                  id: '1104',
                  index: '1104',
                  name: 'Manufacture of soft drinks; production of mineral waters and other bottled waters',
                },
              ],
            },
            { id: '12', index: '1200', name: 'Manufacturing of tobacco products' },
            {
              id: '13',
              index: '13',
              name: 'Manufacturing of textiles',
              subCodes: [
                {
                  id: '131',
                  index: '131',
                  name: 'Spinning, weaving and finishing of textiles',
                  subCodes: [
                    {
                      id: '1311',
                      index: '1311',
                      name: 'Preparation and spinning of textile fibres',
                    },
                    { id: '1312', index: '1312', name: 'Weaving of textiles' },
                    { id: '1313', index: '1313', name: 'Finishing of textiles' },
                  ],
                },
                {
                  id: '139',
                  index: '139',
                  name: 'Manufacture of other textiles',
                  subCodes: [
                    {
                      id: '1391',
                      index: '1391',
                      name: 'Manufacture of knitted and crocheted fabrics',
                    },
                    {
                      id: '1392',
                      index: '1392',
                      name: 'Manufacture of made-up textile articles, except apparel',
                    },
                    {
                      id: '1393',
                      index: '1393',
                      name: 'Manufacture of carpets and rugs',
                    },
                    {
                      id: '1394',
                      index: '1394',
                      name: 'Manufacture of cordage, rope, twine and netting',
                    },
                  ],
                },
              ],
            },
            {
              id: '14',
              index: '14',
              name: 'Manufacturing of wearing apparel',
              subCodes: [
                {
                  id: '141',
                  index: '1410',
                  name: 'Manufacturing of wearing apparel, except fur apparel',
                },
                {
                  id: '142',
                  index: '1420',
                  name: 'Manufacture of articles of fur',
                },
                {
                  id: '143',
                  index: '1430',
                  name: 'Manufacture of knitted and crocheted apparel',
                },
              ],
            },
            {
              id: '15',
              index: '15',
              name: 'Manufacturing of leather and related products',
              subCodes: [
                {
                  id: '151',
                  index: '151',
                  name: 'Tanning and dressing of leather; manufacture of luggage, handbags, saddlery and harness; dressing and dyeing of fur',
                  subCodes: [
                    {
                      id: '1511',
                      index: '1511',
                      name: 'Tanning and dressing of leather; dressing and dyeing of fur',
                    },
                    {
                      id: '1512',
                      index: '1512',
                      name: 'Manufacture of luggage, handbags and the like, saddlery and harness',
                    },
                  ],
                },
                { id: '152', index: '1520', name: 'Manufacture of footwear' },
              ],
            },
            {
              id: '16',
              index: '16',
              name: 'Manufacturing of wood and of product of wood and cork, except furniture; manufacture of articles of straw and plaiting materials  ',
              subCodes: [
                {
                  id: '161',
                  index: '1610',
                  name: 'Sawmilling and planing of wood',
                },
                {
                  id: '162',
                  index: '162',
                  name: 'Manufacture of products of wood, cork, straw and plaiting materials',
                  subCodes: [
                    {
                      id: '1621',
                      index: '1621',
                      name: 'Manufacture of veneer sheets and wood-based panels',
                    },
                    {
                      id: '1622',
                      index: '1622',
                      name: 'Manufacture of builders’ carpentry and joinery',
                    },
                    {
                      id: '1623',
                      index: '1623',
                      name: 'Manufacture of wooden containers',
                    },
                    {
                      id: '1629',
                      index: '1629',
                      name: 'Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials',
                    },
                  ],
                },
              ],
            },
            {
              id: '17',
              index: '17',
              name: 'Manufacturing of paper and paper products',
              subCodes: [
                {
                  id: '1701',
                  index: '1701',
                  name: 'Manufacture of pulp, paper and paperboard',
                },
                {
                  id: '1702',
                  index: '1702',
                  name: 'Manufacture of corrugated paper and paperboard and of containers of paper and paperboard',
                },
                {
                  id: '1709',
                  index: '1709',
                  name: 'Manufacture of other articles of paper and paperboard',
                },
              ],
            },
            {
              id: '18',
              index: '18',
              name: 'Printing and reproduction of recorded media',
              subCodes: [
                {
                  id: '181',
                  index: '181',
                  name: 'Printing and service activities related to printing',
                  subCodes: [
                    { id: '1811', index: '1811', name: 'Printing' },
                    {
                      id: '1812',
                      index: '1812',
                      name: 'Service activities related to printing',
                    },
                  ],
                },
                {
                  id: '182',
                  index: '1820',
                  name: 'Reproduction of recorded media',
                },
              ],
            },
            {
              id: '19',
              index: '19',
              name: 'Manufacture of coke and refined petroleum products',
              subCodes: [
                {
                  id: '191',
                  index: '1910',
                  name: 'Manufacture of coke oven products',
                },
                {
                  id: '192',
                  index: '1920',
                  name: 'Manufacture of refined petroleum products',
                },
              ],
            },
            {
              id: '20',
              index: '20',
              name: 'Manufacture of chemicals and chemical products',
              subCodes: [
                {
                  id: '201',
                  index: '201',
                  name: 'Manufacture of basic chemicals, fertilizers and nitrogen compounds, plastics and synthetic rubber in primary forms',
                  subCodes: [
                    {
                      id: '2011',
                      index: '2011',
                      name: 'Manufacture of basic chemicals',
                    },
                    {
                      id: '2012',
                      index: '2012',
                      name: 'Manufacture of fertilizers and nitrogen compounds',
                    },
                    {
                      id: '2013',
                      index: '2013',
                      name: 'Manufacture of plastics and synthetic rubber in primary forms',
                    },
                  ],
                },
                {
                  id: '202',
                  index: '202',
                  name: 'Manufacture of other chemicals products',
                  subCodes: [
                    {
                      id: '2021',
                      index: '2021',
                      name: 'Manufacture of pesticides and other agrochemical products',
                    },
                    {
                      id: '2022',
                      index: '2022',
                      name: 'Manufacture of paints, varnishes and similar coatings, printing ink and mastics',
                    },
                    {
                      id: '2023',
                      index: '2023',
                      name: 'Manufacture of soap and detergents, cleaning and polishing preparations, perfumes and toilet preparations',
                    },
                    {
                      id: '2029',
                      index: '2029',
                      name: 'Manufacture of other chemical products n.e.c.',
                    },
                  ],
                },
                {
                  id: '203',
                  index: '2030',
                  name: 'Manufacture of man-made fibres',
                },
              ],
            },
            {
              id: '21',
              index: '2100',
              name: 'Manufacture of pharmaceuticals, medicinal chemical and botanical products',
            },
            {
              id: '22',
              index: '22',
              name: 'Manufacture of rubber and plastic products',
              subCodes: [
                {
                  id: '221',
                  index: '221',
                  name: 'Manufacture of rubber products',
                  subCodes: [
                    {
                      id: '2211',
                      index: '2211',
                      name: 'Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres',
                    },
                    {
                      id: '2219',
                      index: '2219',
                      name: 'Manufacture of other rubber products',
                    },
                  ],
                },
                {
                  id: '222',
                  index: '2220',
                  name: 'Manufacture of plastic products',
                },
              ],
            },
            {
              id: '23',
              index: '23',
              name: 'Manufacture of other non-metallic mineral products',
              subCodes: [
                {
                  id: '231',
                  index: '2310',
                  name: 'Manufacture of glass and glass products',
                },
                {
                  id: '239',
                  index: '239',
                  name: 'Manufacture of non-metallic mineral products n.e.c',
                  subCodes: [
                    {
                      id: '2391',
                      index: '2391',
                      name: 'Manufacture of refractory products',
                    },
                    {
                      id: '2392',
                      index: '2392',
                      name: 'Manufacture of clay building materials',
                    },
                    {
                      id: '2393',
                      index: '2393',
                      name: 'Manufacture of other porcelain and ceramic products',
                    },
                    {
                      id: '2394',
                      index: '2394',
                      name: 'Manufacture of cement, lime and plaster',
                    },
                    {
                      id: '2395',
                      index: '2395',
                      name: 'Manufacture of articles of concrete, cement and plaster',
                    },
                    {
                      id: '2396',
                      index: '2396',
                      name: 'Cutting, shaping and finishing of stone',
                    },
                    {
                      id: '2399',
                      index: '2399',
                      name: 'Manufacture of other non-metallic mineral products n.e.c.',
                    },
                  ],
                },
              ],
            },
            {
              id: '24',
              index: '24',
              name: 'Manufacture of basic metals',
              subCodes: [
                {
                  id: '241',
                  index: '2410',
                  name: 'Manufacture of basic iron and steel',
                },
                {
                  id: '242',
                  index: '2420',
                  name: 'Manufacture of basic precious and other non-ferrous metals',
                },
                {
                  id: '243',
                  index: '243',
                  name: 'Casting of metals',
                  subCodes: [
                    {
                      id: '2431',
                      index: '2431',
                      name: 'Casting of iron and steel',
                    },
                    {
                      id: '2432',
                      index: '2432',
                      name: 'Casting of non-ferrous metals',
                    },
                  ],
                },
              ],
            },
            {
              id: '25',
              index: '25',
              name: 'Manufacture of fabricated metal products, except machinery and equipment',
              subCodes: [
                {
                  id: '251',
                  index: '251',
                  name: 'Manufacture of structural metal products, tanks, reservoirs and steam generators',
                  subCodes: [
                    {
                      id: '2511',
                      index: '2511',
                      name: 'Manufacture of structural metal products',
                    },
                    {
                      id: '2512',
                      index: '2512',
                      name: 'Manufacture of tanks, reservoirs and containers of metal',
                    },
                    {
                      id: '2513',
                      index: '2513',
                      name: 'Manufacture of steam generators, except central heating hot water boilers (2512)',
                    },
                  ],
                },
                {
                  id: '252',
                  index: '2520',
                  name: 'Manufacture of weapons and ammunition',
                },
                {
                  id: '259',
                  index: '259',
                  name: 'Manufacture of other fabricated metal products; metalworking service activities',
                  subCodes: [
                    {
                      id: '2591',
                      index: '2591',
                      name: 'Forging, pressing, stamping and roll-forming of metal; powder metallurgy',
                    },
                    {
                      id: '2592',
                      index: '2592',
                      name: 'Treatment and coating of metals; machining',
                    },
                    {
                      id: '2593',
                      index: '2593',
                      name: 'Manufacture of cutlery, hand tools and general hardware (for manufacture of power-driven hand tools, see 2818) (for manufacture of cutlery of precious metal, see 3211)',
                    },
                    {
                      id: '2599',
                      index: '2599',
                      name: 'Manufacture of other fabricated metal products n.e.c.',
                    },
                  ],
                },
              ],
            },
            {
              id: '26',
              index: '26',
              name: 'Manufacture of  computer, electronic and optical products',
              subCodes: [
                {
                  id: '261',
                  index: '2610',
                  name: 'Manufacture of electronic components and boards',
                },
                {
                  id: '262',
                  index: '2620',
                  name: 'Manufacture of computers and peripheral equipment',
                },
                {
                  id: '263',
                  index: '2630',
                  name: 'Manufacture of communication equipment',
                },
                {
                  id: '264',
                  index: '2640',
                  name: 'Manufacture of consumer electronics',
                },
                {
                  id: '265',
                  index: '265',
                  name: 'Manufacture of measuring, testing, navigating and control equipment; watches and clocks (for manufacture of  optical measuring and checking devices and instruments (e.g. fire control equipment, photographic light meters, range finders), see 2670)',
                  subCodes: [
                    {
                      id: '2651',
                      index: '2651',
                      name: 'Manufacture of measuring, testing, navigating and control equipment (for manufacture of  optical measuring and checking devices and instruments (e.g. fire control equipment, photographic light meters, range finders), see 2670)',
                    },
                    {
                      id: '2652',
                      index: '2652',
                      name: 'Manufacture of watches and clocks',
                    },
                  ],
                },
                {
                  id: '266',
                  index: '2660',
                  name: 'Manufacture of irradiation, electromedical and electrotherapeutic equipment',
                },
                {
                  id: '267',
                  index: '2670',
                  name: 'Manufacture of optical instruments and photographic equipment',
                },
                {
                  id: '268',
                  index: '2680',
                  name: 'Manufacture of magnetic and optical media (for reproduction of recorded media (computer media, sound, video, etc.), see 1820)',
                },
              ],
            },
            {
              id: '27',
              index: '27',
              name: 'Manufacture of  electrical equipment',
              subCodes: [
                {
                  id: '271',
                  index: '2710',
                  name: 'Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus',
                },
                {
                  id: '272',
                  index: '2720',
                  name: 'Manufacture of batteries and accumulators (non-rechargeable and rechargeable )',
                },
                {
                  id: '273',
                  index: '273',
                  name: 'Manufacture of wiring and wiring devices',
                  subCodes: [
                    {
                      id: '2731',
                      index: '2731',
                      name: 'Manufacture of fibre optic cables',
                    },
                    {
                      id: '2732',
                      index: '2732',
                      name: 'Manufacture of other electronic and electric wires and cables',
                    },
                    {
                      id: '2733',
                      index: '2733',
                      name: 'Manufacture of wiring devices',
                    },
                  ],
                },
                {
                  id: '274',
                  index: '2740',
                  name: 'Manufacture of electric lighting equipment',
                },
                {
                  id: '275',
                  index: '2750',
                  name: 'Manufacture of domestic appliances (electric, gas or other fuel) (for manufacture of commercial and industrial appliances, see division 28)',
                },
                {
                  id: '279',
                  index: '2790',
                  name: 'Manufacture of other electrical equipment',
                },
              ],
            },
            {
              id: '28',
              index: '28',
              name: 'Manufacture of  machinery and equipment n.e.c.',
              subCodes: [
                {
                  id: '281',
                  index: '281',
                  name: 'Manufacture of general-purpose machinery',
                  subCodes: [
                    {
                      id: '2811',
                      index: '2811',
                      name: 'Manufacture of engines and turbines, except aircraft, vehicle and cycle engines (for manufacture of motor vehicle, aircraft or cycle propulsion engines, see 2910, 3030, 3091)',
                    },
                    {
                      id: '2812',
                      index: '2812',
                      name: 'Manufacture of fluid power equipment',
                    },
                    {
                      id: '2813',
                      index: '2813',
                      name: 'Manufacture of other pumps, compressors, taps and valves (for manufacture of valves of unhardened vulcanized rubber, glass or of ceramic materials, see 2219, 2310 or 2393) (for manufacture of inlet and exhaust valves of internal combustion engines, see 2811)',
                    },
                    {
                      id: '2814',
                      index: '2814',
                      name: 'Manufacture of bearings, gears, gearing and driving elements',
                    },
                    {
                      id: '2815',
                      index: '2815',
                      name: 'Manufacture of ovens, furnaces and furnace burners',
                    },
                    {
                      id: '2816',
                      index: '2816',
                      name: 'Manufacture of lifting and handling equipment',
                    },
                    {
                      id: '2817',
                      index: '2817',
                      name: 'Manufacture of office machinery and equipment (except computers and peripheral equipment)',
                    },
                    {
                      id: '2818',
                      index: '2818',
                      name: 'Manufacture of power-driven hand tools',
                    },
                    {
                      id: '2819',
                      index: '2819',
                      name: 'Manufacture of other general-purpose machinery',
                    },
                  ],
                },
                {
                  id: '282',
                  index: '282',
                  name: 'Manufacture of special-purpose machinery',
                  subCodes: [
                    {
                      id: '2821',
                      index: '2821',
                      name: 'Manufacture of agricultural and forestry machinery (for manufacture of non-power-driven agricultural hand tools, see 2593)',
                    },
                    {
                      id: '2822',
                      index: '2822',
                      name: 'Manufacture of metal-forming machinery and machine tools',
                    },
                    {
                      id: '2823',
                      index: '2823',
                      name: 'Manufacture of machinery for metallurgy',
                    },
                    {
                      id: '2824',
                      index: '2824',
                      name: 'Manufacture of machinery for mining, quarrying and construction',
                    },
                    {
                      id: '2825',
                      index: '2825',
                      name: 'Manufacture of machinery for food, beverage and tobacco processing',
                    },
                    {
                      id: '2826',
                      index: '2826',
                      name: 'Manufacture of machinery for textile, apparel and leather production',
                    },
                    {
                      id: '2829',
                      index: '2829',
                      name: 'Manufacture of other special-purpose machinery (for manufacture of household appliances, see 2750)',
                    },
                  ],
                },
              ],
            },
            {
              id: '29',
              index: '29',
              name: 'Manufacture of motor vehicles, trailers and semi-trailers',
              subCodes: [
                {
                  id: '291',
                  index: '2910',
                  name: 'Manufacture of motor vehicles (for manufacture of bodies for motor vehicles, see 2920)',
                },
                {
                  id: '292',
                  index: '2920',
                  name: 'Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers',
                },
                {
                  id: '293',
                  index: '2930',
                  name: 'Manufacture of parts and accessories for motor vehicles',
                },
              ],
            },
            {
              id: '30',
              index: '30',
              name: 'Manufacture of other transport equipment',
              subCodes: [
                {
                  id: '301',
                  index: '301',
                  name: 'Building of ships and boats',
                  subCodes: [
                    {
                      id: '3011',
                      index: '3011',
                      name: 'Building of ships and floating structures',
                    },
                    {
                      id: '3012',
                      index: '3012',
                      name: 'Building of pleasure and sporting boats (except parts)',
                    },
                    {
                      id: '3020',
                      index: '3020',
                      name: 'Manufacture of railway locomotives and rolling stock ',
                    },
                  ],
                },
                {
                  id: '303',
                  index: '3030',
                  name: 'Manufacture of air and spacecraft and related machinery',
                },
                {
                  id: '304',
                  index: '3040',
                  name: 'Manufacture of military fighting vehicles',
                },
                {
                  id: '309',
                  index: '309',
                  name: 'Manufacture of other transport equipment n.e.c',
                  subCodes: [
                    {
                      id: '3091',
                      index: '3091',
                      name: 'Manufacture of motorcycles (for manufacture of bicycles, see 3092)',
                    },
                    {
                      id: '3092',
                      index: '3092',
                      name: 'Manufacture of bicycles and invalid carriages (for manufacture of bicycles with auxiliary motor, see 3091)',
                    },
                    {
                      id: '3099',
                      index: '3099',
                      name: 'Manufacture of other transport equipment n.e.c.',
                    },
                  ],
                },
              ],
            },
            {
              id: '31',
              index: '3100',
              name: 'Manufacture of furniture (for manufacture of furniture of ceramics, concrete and stone, see 2393, 2395, 2396)',
            },
            {
              id: '32',
              index: '32',
              name: 'Other manufacturing',
              subCodes: [
                {
                  id: '321',
                  index: '321',
                  name: 'Manufacture of jewellery, bijouterie and related articles (for manufacture of imitation jewellery, see 3212)',
                  subCodes: [
                    {
                      id: '3211',
                      index: '3211',
                      name: 'Manufacture of jewellery and related articles composed of precious metals, precious and semi-precious stone and pearls (for manufacture of imitation jewellery, see 3212)',
                    },
                    {
                      id: '3212',
                      index: '3212',
                      name: 'Manufacture of imitation jewellery and related articles (for manufacture of jewellery made from precious metals or clad with precious metals, see 3211)',
                    },
                  ],
                },
                {
                  id: '322',
                  index: '3220',
                  name: 'Manufacture of musical instruments',
                },
                {
                  id: '323',
                  index: '3230',
                  name: 'Manufacture of sports goods (except apparel and footwear)',
                },
                {
                  id: '324',
                  index: '3240',
                  name: 'Manufacture of games and toys',
                },
                {
                  id: '325',
                  index: '3250',
                  name: 'Manufacture of medical and dental instruments and supplies',
                },
                {
                  id: '329',
                  index: '3290',
                  name: 'Other manufacturing n.e.c.',
                },
              ],
            },
            {
              id: '33',
              index: '33',
              name: 'Repair and installation of machinery and equipment',
              subCodes: [
                {
                  id: '331',
                  index: '331',
                  name: 'Repair of fabricated metal products, machinery and equipment',
                  subCodes: [
                    {
                      id: '3311',
                      index: '3311',
                      name: 'Repair of fabricated metal products',
                    },
                    { id: '3312', index: '3312', name: 'Repair of machinery' },
                    {
                      id: '3313',
                      index: '3313',
                      name: 'Repair of electronic and optical equipment',
                    },
                    {
                      id: '3314',
                      index: '3314',
                      name: 'Repair of electrical equipment',
                    },
                    {
                      id: '3315',
                      index: '3315',
                      name: 'Repair of transport equipment, except motor vehicles',
                    },
                    {
                      id: '3319',
                      index: '3319',
                      name: 'Repair of other equipment',
                    },
                  ],
                },
                {
                  id: '332',
                  index: '3320',
                  name: 'Installation of industrial machinery and equipment',
                },
              ],
            },
          ],
        },
        {
          id: 'D',
          index: 'Section D',
          name: 'Electricity, gas, steam and air conditioning supply',
          subCodes: [
            {
              id: '351',
              index: '3510',
              name: 'Electric power generation, transmission and distribution',
            },
            {
              id: '352',
              index: '3520',
              name: 'Manufacture of gas; distribution of gaseous fuels through mains (for manufacture of industrial gases (see 2011),)',
            },
            {
              id: '353',
              index: '3530',
              name: 'Steam and air conditioning supply',
            },
          ],
        },
        {
          id: 'E',
          index: 'Section E',
          name: 'Water supply; sewerage, waste management and remediation activities',
          subCodes: [
            {
              id: '36',
              index: '3600',
              name: 'Water collection, treatment and supply',
            },
            { id: '37', index: '3700', name: 'Sewerage' },
            {
              id: '38',
              index: '38',
              name: 'Waste collection, treatment and disposal activities; materials recovery',
              subCodes: [
                {
                  id: '381',
                  index: '381',
                  name: 'Waste collection',
                  subCodes: [
                    {
                      id: '3811',
                      index: '3811',
                      name: 'Collection of non-hazardous waste (for collection of hazardous waste, see 3812) (for operation of facilities where commingled recoverable materials such as paper, plastics, etc. are sorted into distinct categories, see 3830)',
                    },
                    {
                      id: '3812',
                      index: '3812',
                      name: 'Collection of hazardous waste',
                    },
                  ],
                },
                {
                  id: '382',
                  index: '382',
                  name: 'Waste treatment and disposal',
                  subCodes: [
                    {
                      id: '3821',
                      index: '3821',
                      name: 'Treatment and disposal of non-hazardous waste',
                    },
                    {
                      id: '3822',
                      index: '3822',
                      name: 'Treatment and disposal of hazardous waste (for decontamination, clean up of land and, water; toxic material abatement, see 3900)',
                    },
                  ],
                },
                { id: '383', index: '3830', name: 'Materials recovery' },
              ],
            },
            {
              id: '39',
              index: '3900',
              name: 'Remediation activities and other waste management services',
            },
          ],
        },
        {
          id: 'F',
          index: 'Section F',
          name: 'Construction',
          subCodes: [
            {
              id: '41',
              index: '4100',
              name: 'Construction of buildings (for erection of complete prefabricated constructions from self-manufactured parts not of concrete, see divisions 16 and 25)',
            },
            {
              id: '42',
              index: '42',
              name: 'Civil engineering',
              subCodes: [
                {
                  id: '421',
                  index: '4210',
                  name: 'Construction of roads and railways',
                },
                {
                  id: '422',
                  index: '4220',
                  name: 'Construction of utility projects',
                },
                {
                  id: '429',
                  index: '4290',
                  name: 'Construction of other civil engineering projects',
                },
              ],
            },
            {
              id: '43',
              index: '43',
              name: 'Specialised construction activities',
              subCodes: [
                {
                  id: '431',
                  index: '431',
                  name: 'Demolition and site preparation',
                  subCodes: [
                    { id: '4311', index: '4311', name: 'Demolition' },
                    { id: '4312', index: '4312', name: 'Site preparation' },
                  ],
                },
                {
                  id: '432',
                  index: '432',
                  name: 'Electrical, plumbing and other construction installation activities',
                  subCodes: [
                    { id: '4321', index: '4321', name: 'Electrical installation' },
                    {
                      id: '4322',
                      index: '4322',
                      name: 'Plumbing, heat and air-conditioning installation',
                    },
                    {
                      id: '4329',
                      index: '4329',
                      name: 'Other construction installation',
                    },
                  ],
                },
                {
                  id: '433',
                  index: '4330',
                  name: 'Building completion and finishing',
                },
                {
                  id: '439',
                  index: '4390',
                  name: 'Other specialized construction activities',
                },
              ],
            },
          ],
        },
        {
          id: 'G',
          index: 'Section G',
          name: 'Wholesale and retail trade; repair of motor vehicles and motorcycles',
          subCodes: [
            {
              id: '45',
              index: '45',
              name: 'Wholesale and retail trade and repair of motor vehicles and motorcycles',
              subCodes: [
                { id: '451', index: '4510', name: 'Sale of motor vehicles' },
                {
                  id: '452',
                  index: '4520',
                  name: 'Maintenance and repair of motor vehicles',
                },
                {
                  id: '453',
                  index: '4530',
                  name: 'Sale of motor vehicle parts and accessories',
                },
                {
                  id: '454',
                  index: '4540',
                  name: 'Sale, maintenance and repair of motorcycles and related parts and accessories',
                },
                {
                  id: '455',
                  index: '4550',
                  name: 'Retail of automotive fuel in specialized stores',
                },
              ],
            },
            {
              id: '46',
              index: '46',
              name: 'Wholesale trade, except of motor vehicles and motorcycles (for activities of commission agents for motor vehicles, see 4510)',
              subCodes: [
                {
                  id: '461',
                  index: '4610',
                  name: 'Wholesale on a fee or contract  basis (for wholesale trade in own name, see groups 462 to 469)',
                },
                {
                  id: '462',
                  index: '4620',
                  name: 'Wholesale of agricultural raw materials and live animals',
                },
                {
                  id: '463',
                  index: '4630',
                  name: 'Wholesale of food, beverages and tobacco',
                },
                {
                  id: '464',
                  index: '464',
                  name: 'Wholesale of household goods',
                  subCodes: [
                    {
                      id: '4641',
                      index: '4641',
                      name: 'Wholesale of textiles, clothing and footwear',
                    },
                    {
                      id: '4649',
                      index: '4649',
                      name: 'Wholesale of other household goods',
                    },
                  ],
                },
                {
                  id: '465',
                  index: '465',
                  name: 'Wholesale of machinery, equipment and supplies',
                  subCodes: [
                    {
                      id: '4651',
                      index: '4651',
                      name: 'Wholesale of computers, computer peripheral equipment and software',
                    },
                    {
                      id: '4652',
                      index: '4652',
                      name: 'Wholesale of electronic and telecommunications equipment and parts (for wholesale of consumer electronics, see 4649)',
                    },
                    {
                      id: '4653',
                      index: '4653',
                      name: 'Wholesale of agricultural machinery, equipment and supplies',
                    },
                    {
                      id: '4659',
                      index: '4659',
                      name: 'Wholesale of other machinery and equipment',
                    },
                  ],
                },
                {
                  id: '466',
                  index: '466',
                  name: 'Other specialised wholesale',
                  subCodes: [
                    {
                      id: '4661',
                      index: '4661',
                      name: 'Wholesale of solid, liquid and gaseous fuels and related products',
                    },
                    {
                      id: '4662',
                      index: '4662',
                      name: 'Wholesale of metals and metal ores',
                    },
                    {
                      id: '4663',
                      index: '4663',
                      name: 'Wholesale of construction materials, hardware, plumbing and heating equipment and supplies',
                    },
                    {
                      id: '4669',
                      index: '4669',
                      name: 'Wholesale of waste and scrap and other products n.e.c. (for collection of household and industrial waste, see group 381)',
                    },
                  ],
                },
                {
                  id: '469',
                  index: '4690',
                  name: 'Non-specialised wholesale trade',
                },
              ],
            },
            {
              id: '47',
              index: '47',
              name: 'Retail trade, except of motor vehicles and motorcycles',
              subCodes: [
                {
                  id: '471',
                  index: '471',
                  name: 'Retail sale in non-specialized stores',
                  subCodes: [
                    {
                      id: '4711',
                      index: '4711',
                      name: 'Retail sale in non-specialized stores with food, beverages or tobacco predominating (for retail sale of fuel in combination with food, beverages, etc. with fuel sales dominating, see 4550)',
                    },
                    {
                      id: '4719',
                      index: '4719',
                      name: 'Other retail sale in non-specialized stores',
                    },
                  ],
                },
                {
                  id: '472',
                  index: '472',
                  name: 'Retail sale of food, beverages and tobacco in specialised stores',
                  subCodes: [
                    {
                      id: '4721',
                      index: '4721',
                      name: 'Retail sale of food in specialised stores',
                    },
                    {
                      id: '4722',
                      index: '4722',
                      name: 'Retail sale of beverages in specialised stores',
                    },
                    {
                      id: '4723',
                      index: '4723',
                      name: 'Retail sale of tobacco products in specialised stores',
                    },
                  ],
                },
                {
                  id: '473',
                  index: '473',
                  name: 'Retail sale of information and communications equipment in specialized stores',
                  subCodes: [
                    {
                      id: '4731',
                      index: '4731',
                      name: 'Retail sale of computers, peripheral units, software and telecommunications',
                    },
                    {
                      id: '4732',
                      index: '4732',
                      name: 'Retail sale of audio and video equipment in specialised stores',
                    },
                  ],
                },
                {
                  id: '474',
                  index: '474',
                  name: 'Retail sale of other household equipment in specialized stores',
                  subCodes: [
                    {
                      id: '4741',
                      index: '4741',
                      name: 'Retail sale of textiles in specialized stores',
                    },
                    {
                      id: '4742',
                      index: '4742',
                      name: 'Retail sale of hardware, paints and glass in specialized stores',
                    },
                    {
                      id: '4743',
                      index: '4743',
                      name: 'Retail sale of carpets, rugs, wall and floor coverings in specialized stores',
                    },
                    {
                      id: '4749',
                      index: '4749',
                      name: 'Retail sale of electrical household appliances, furniture, lighting equipment and other household articles in specialized stores',
                    },
                  ],
                },
                {
                  id: '475',
                  index: '475',
                  name: 'Retail sale of cultural and recreation goods in specialized stores',
                  subCodes: [
                    {
                      id: '4751',
                      index: '4751',
                      name: 'Retail sale of books, newspapers and stationary in specialized stores',
                    },
                    {
                      id: '4752',
                      index: '4752',
                      name: 'Retail sale of music and video recordings in specialized stores',
                    },
                    {
                      id: '4753',
                      index: '4753',
                      name: 'Retail sale of sporting equipment in specialized stores',
                    },
                    {
                      id: '4754',
                      index: '4754',
                      name: 'Retail sale of games and toys in specialized stores',
                    },
                  ],
                },
                {
                  id: '476',
                  index: '476',
                  name: 'Retail sale of other goods in specialized stores',
                  subCodes: [
                    {
                      id: '4761',
                      index: '4761',
                      name: 'Retail sale of clothing, footwear and leather articles in specialized stores',
                    },
                    {
                      id: '4762',
                      index: '4762',
                      name: 'Retail sale of pharmaceutical and medical goods, cosmetic and toilet articles in specialized stores',
                    },
                    {
                      id: '4763',
                      index: '4763',
                      name: 'Other retail sale of new goods in specialized stores',
                    },
                    {
                      id: '4764',
                      index: '4764',
                      name: 'Retail sale of second-hand goods',
                    },
                  ],
                },
                {
                  id: '477',
                  index: '477',
                  name: 'Retail sale via stalls and markets',
                  subCodes: [
                    {
                      id: '4771',
                      index: '4771',
                      name: 'Retail sale via stalls and markets of food, beverages and tobacco products (for retail sale of prepared food for immediate consumption (mobile food vendors) (see 5610)',
                    },
                    {
                      id: '4772',
                      index: '4772',
                      name: 'Retail sale via stalls and markets of textiles, clothing and footwear',
                    },
                    {
                      id: '4779',
                      index: '4779',
                      name: 'Retail sale via stalls and markets of other goods',
                    },
                  ],
                },
                {
                  id: '478',
                  index: '478',
                  name: 'Retail trade not in stores, stalls or markets',
                  subCodes: [
                    {
                      id: '4781',
                      index: '4781',
                      name: 'Retail sale via mail order houses or via internet',
                    },
                    {
                      id: '4789',
                      index: '4789',
                      name: 'Other retail sale not in stores, stalls or markets',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 'H',
          index: 'Section H',
          name: 'Transportation and storage',
          subCodes: [
            {
              id: '49',
              index: '49',
              name: 'Land transport and transport via pipelines',
              subCodes: [
                {
                  id: '491',
                  index: '491',
                  name: 'Transport via railways',
                  subCodes: [
                    {
                      id: '4911',
                      index: '4911',
                      name: 'Passenger rail transport, interurban',
                    },
                  ],
                },
                {
                  id: '492',
                  index: '492',
                  name: 'Other land transport',
                  subCodes: [
                    {
                      id: '4921',
                      index: '4921',
                      name: 'Urban and suburban passenger transport (for passenger transport by inter-urban railways, see 4911)',
                    },
                    {
                      id: '4922',
                      index: '4922',
                      name: 'Other passenger land transport',
                    },
                    { id: '4923', index: '4923', name: 'Freight transport by road' },
                  ],
                },
                { id: '493', index: '4930', name: 'Transport via pipeline' },
              ],
            },
            {
              id: '50',
              index: '50',
              name: 'Water transport',
              subCodes: [
                {
                  id: '501',
                  index: '501',
                  name: 'Sea and coastal water transport',
                  subCodes: [
                    {
                      id: '5011',
                      index: '5011',
                      name: 'Sea and coastal passenger water transport',
                    },
                    {
                      id: '5012',
                      index: '5012',
                      name: 'Sea and coastal freight water transport',
                    },
                  ],
                },
                {
                  id: '502',
                  index: '502',
                  name: 'Inland water transport',
                  subCodes: [
                    {
                      id: '5021',
                      index: '5021',
                      name: 'Inland passenger water transport',
                    },
                    {
                      id: '5022',
                      index: '5022',
                      name: 'Inland freight water transport',
                    },
                  ],
                },
              ],
            },
            {
              id: '51',
              index: '51',
              name: 'Air transport',
              subCodes: [
                { id: '511', index: '5110', name: 'Passenger air transport' },
                { id: '512', index: '5120', name: 'Freight air transport' },
              ],
            },
            {
              id: '52',
              index: '52',
              name: 'Warehousing and support activities for transportation',
              subCodes: [
                {
                  id: '521',
                  index: '5210',
                  name: 'Warehousing and storage (for operation of self storage facilities, see 6810)',
                },
                {
                  id: '522',
                  index: '522',
                  name: 'Support activities for transportation',
                  subCodes: [
                    {
                      id: '5221',
                      index: '5221',
                      name: 'Service activities incidental to land transportation',
                    },
                    {
                      id: '5222',
                      index: '5222',
                      name: 'Service activities incidental to water transportation',
                    },
                    {
                      id: '5223',
                      index: '5223',
                      name: 'Service activities incidental to air transportation',
                    },
                    { id: '5224', index: '5224', name: 'Cargo handling' },
                    {
                      id: '5229',
                      index: '5229',
                      name: 'Other transportation support activities',
                    },
                  ],
                },
              ],
            },
            {
              id: '53',
              index: '53',
              name: 'Postal and courier activities',
              subCodes: [
                { id: '531', index: '5310', name: 'Postal activities' },
                {
                  id: '532',
                  index: '5320',
                  name: 'Courier activities (for transport of freight (see, according to mode of transport, 4912, 4923, 5012, 5022, 5120)',
                },
              ],
            },
          ],
        },
        {
          id: 'I',
          index: 'Section I',
          name: 'Accommodation and food service activities',
          subCodes: [
            {
              id: '55',
              index: '55',
              name: 'Accommodation',
              subCodes: [
                {
                  id: '551',
                  index: '5510',
                  name: 'Short term accommodation activities',
                },
                {
                  id: '552',
                  index: '5520',
                  name: 'Camping grounds, recreational vehicle parks and trailer parks',
                },
                { id: '559', index: '5590', name: 'Other accommodation' },
              ],
            },
            {
              id: '56',
              index: '56',
              name: 'Food and beverage service activities',
              subCodes: [
                {
                  id: '561',
                  index: '5610',
                  name: 'Restaurants and mobile food service activities',
                },
                {
                  id: '562',
                  index: '562',
                  name: 'Event catering and other food service activities',
                  subCodes: [
                    { id: '5621', index: '5621', name: 'Event catering' },
                    {
                      id: '5629',
                      index: '5629',
                      name: 'Other food service activities',
                    },
                  ],
                },
                { id: '563', index: '5630', name: 'Beverage serving activities' },
              ],
            },
          ],
        },
        {
          id: 'J',
          index: 'Section J',
          name: 'Information and communication',
          subCodes: [
            {
              id: '58',
              index: '58',
              name: 'Publishing activities',
              subCodes: [
                {
                  id: '581',
                  index: '581',
                  name: 'Publishing of books, periodicals and other publishing activities',
                  subCodes: [
                    { id: '5810', index: '5810', name: 'Book publishing' },
                    {
                      id: '5812',
                      index: '5812',
                      name: 'Publishing of directories and mailing lists',
                    },
                    {
                      id: '5813',
                      index: '5813',
                      name: 'Publishing of newspapers, journals and periodicals',
                    },
                    {
                      id: '5819',
                      index: '5819',
                      name: 'Other publishing activities',
                    },
                  ],
                },
                {
                  id: '582',
                  index: '5820',
                  name: 'Software publishing (for reproduction of software, see 1820) (for on-line provision of software – application hosting and application service provisioning, see 6311)',
                },
              ],
            },
            {
              id: '59',
              index: '59',
              name: 'Motion picture, video and television programme production, sound recording and music publishing activities',
              subCodes: [
                {
                  id: '591',
                  index: '591',
                  name: 'Motion picture, video and television programme activities',
                  subCodes: [
                    {
                      id: '5911',
                      index: '5911',
                      name: 'Motion picture, video and television programme production activities',
                    },
                    {
                      id: '5912',
                      index: '5912',
                      name: 'Motion picture, video and television programme post-production activities',
                    },
                    {
                      id: '5913',
                      index: '5913',
                      name: 'Motion picture, video and television programme distribution activities',
                    },
                    {
                      id: '5914',
                      index: '5914',
                      name: 'Motion picture projection activities',
                    },
                  ],
                },
                {
                  id: '592',
                  index: '5920',
                  name: 'Sound recording and music publishing activities',
                },
              ],
            },
            {
              id: '60',
              index: '60',
              name: 'Programming and broadcasting activities',
              subCodes: [
                { id: '601', index: '6010', name: 'Radio broadcasting' },
                {
                  id: '602',
                  index: '6020',
                  name: 'Television programming and broadcasting activities (for production of television programme elements, e.g. movies, documentaries, commercials, see 5911)',
                },
              ],
            },
            {
              id: '61',
              index: '61',
              name: 'Telecommunications (for telecommunications resellers, see 6190)',
              subCodes: [
                {
                  id: '611',
                  index: '6110',
                  name: 'Wired telecommunications activities (for telecommunications resellers, see 6190)',
                },
                {
                  id: '612',
                  index: '6120',
                  name: 'Wireless telecommunications activities (for telecommunications resellers, see 6190)',
                },
                {
                  id: '613',
                  index: '6130',
                  name: 'Satellite telecommunications activities (for telecommunications resellers, see 6190)',
                },
                {
                  id: '619',
                  index: '6190',
                  name: 'Other telecommunications activities',
                },
              ],
            },
            {
              id: '62',
              index: '62',
              name: 'Computer programming, consultancy and related activities',
              subCodes: [
                {
                  id: '6201',
                  index: '6201',
                  name: 'Computer programming activities',
                },
                {
                  id: '6202',
                  index: '6202',
                  name: 'Computer consultancy and computer facilities management activities',
                },
                {
                  id: '6209',
                  index: '6209',
                  name: 'Other information technology and computer service activities',
                },
              ],
            },
            {
              id: '63',
              index: '63',
              name: 'Information service activities',
              subCodes: [
                {
                  id: '631',
                  index: '6310',
                  name: 'Data processing, hosting and related activities; web portals',
                  // subCodes: [
                  //   {
                  //     id: '6311',
                  //     index: '6311',
                  //     name: 'Data processing, hosting and related activities',
                  //   },
                  //   { id: '6312', index: '6312', name: 'Web portals' },
                  // ],
                },
                {
                  id: '639',
                  index: '639',
                  name: 'Other information service activities',
                  subCodes: [
                    {
                      id: '6391',
                      index: '6391',
                      name: 'News agency activities (for activities of independent journalists, see 9000) (for activities of independent photojournalists, see 7420)',
                    },
                    {
                      id: '6399',
                      index: '6399',
                      name: 'Other information service activities n.e.c.',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 'K',
          index: 'Section K',
          name: 'Financial and insurance activities',
          subCodes: [
            {
              id: '64',
              index: '64',
              name: 'Financial service activities, except insurance and pension funding',
              subCodes: [
                {
                  id: '641',
                  index: '641',
                  name: 'Monetary intermediation',
                  subCodes: [
                    { id: '6411', index: '6411', name: 'Central banking' },
                    {
                      id: '6419',
                      index: '6419',
                      name: 'Other monetary intermediation',
                    },
                  ],
                },
                {
                  id: '642',
                  index: '6420',
                  name: 'Activities of holding companies (for active management of companies and enterprises, strategic planning and decision making of the company, see 7010)',
                },
                {
                  id: '643',
                  index: '6430',
                  name: 'Trusts, funds and similar financial entities',
                },
                {
                  id: '649',
                  index: '649',
                  name: 'Other financial service activities, except insurance and pension funding activities',
                  subCodes: [
                    {
                      id: '6491',
                      index: '6491',
                      name: 'Financial leasing (for operational leasing, see division 77) according to type of goods leased)',
                    },
                    { id: '6492', index: '6492', name: 'Other credit granting' },
                    {
                      id: '6499',
                      index: '6499',
                      name: 'Other financial service activities, except insurance and pension funding activities n.e.c.',
                    },
                  ],
                },
              ],
            },
            {
              id: '65',
              index: '65',
              name: 'Insurance, reinsurance and pension funding, except compulsory social security',
              subCodes: [
                {
                  id: '651',
                  index: '651',
                  name: 'Insurance',
                  subCodes: [
                    { id: '6511', index: '6511', name: 'Life insurance' },
                    { id: '6512', index: '6512', name: 'Non-life insurance' },
                  ],
                },
                { id: '652', index: '6520', name: 'Reinsurance' },
                {
                  id: '653',
                  index: '6530',
                  name: 'Pension funding (for management of pension funds, see 6630) (for compulsory social security schemes, see 8430)',
                },
              ],
            },
            {
              id: '66',
              index: '66',
              name: 'Activities auxiliary to financial service and insurance activities',
              subCodes: [
                {
                  id: '661',
                  index: '661',
                  name: 'Activities auxiliary to financial service activities, except insurance and pension funding',
                  subCodes: [
                    {
                      id: '6611',
                      index: '6611',
                      name: 'Administration of financial markets',
                    },
                    {
                      id: '6612',
                      index: '6612',
                      name: 'Security and commodity contracts brokerage',
                    },
                    {
                      id: '6619',
                      index: '6619',
                      name: 'Other activities auxiliary to financial service activities',
                    },
                  ],
                },
                {
                  id: '662',
                  index: '662',
                  name: 'Activities auxiliary to insurance and pension funding',
                  subCodes: [
                    {
                      id: '6621',
                      index: '6621',
                      name: 'Risk and damage evaluation',
                    },
                    {
                      id: '6622',
                      index: '6622',
                      name: 'Activities of insurance agents and brokers',
                    },
                    {
                      id: '6629',
                      index: '6629',
                      name: 'Other activities auxiliary to insurance and pension funding',
                    },
                  ],
                },
                {
                  id: '663',
                  index: '6630',
                  name: 'Fund management activities',
                },
              ],
            },
          ],
        },

        {
          id: 'L',
          index: 'Section L',
          name: 'Real estate activities',
          subCodes: [
            {
              id: '681',
              index: '6810',
              name: 'Real estate activities with own or leased property',
            },
            {
              id: '682',
              index: '6820',
              name: 'Real estate activities on a fee or contract basis',
            },
          ],
        },

        {
          id: 'M',
          index: 'Section M',
          name: 'Professional, scientific and technical activities',
          subCodes: [
            {
              id: '69',
              index: '69',
              name: 'Legal and accounting activities',
              subCodes: [
                {
                  id: '691',
                  index: '6910',
                  name: 'Legal activities (for law court activities, see 8423)',
                },
                {
                  id: '692',
                  index: '6920',
                  name: 'Accounting, bookkeeping and auditing activities, tax consultancy',
                },
              ],
            },
            {
              id: '70',
              index: '70',
              name: 'Activities of head offices; management consultancy activities',
              subCodes: [
                {
                  id: '701',
                  index: '7010',
                  name: 'Activities of head offices',
                },
                {
                  id: '702',
                  index: '7020',
                  name: 'Management consultancy activities',
                },
              ],
            },
            {
              id: '71',
              index: '71',
              name: 'Architectural and engineering activities; technical testing and analysis',
              subCodes: [
                {
                  id: '711',
                  index: '7110',
                  name: 'Architectural and engineering activities and related technical consultancy',
                },
                {
                  id: '712',
                  index: '7120',
                  name: 'Technical testing and analysis',
                },
              ],
            },
            {
              id: '72',
              index: '72',
              name: 'Scientific research and development',
              subCodes: [
                {
                  id: '721',
                  index: '7210',
                  name: 'Research and experimental development on natural sciences and engineering',
                },
                {
                  id: '722',
                  index: '7220',
                  name: 'Research and experimental development on social sciences and humanities',
                },
              ],
            },
            {
              id: '73',
              index: '73',
              name: 'Advertising and market research',
              subCodes: [
                {
                  id: '731',
                  index: '7310',
                  name: 'Advertising (for graphic design activities, see 7410)',
                },
                {
                  id: '732',
                  index: '7320',
                  name: 'Market research and public opinion polling',
                },
              ],
            },
            {
              id: '74',
              index: '74',
              name: 'Other professional, scientific and technical activities',
              subCodes: [
                {
                  id: '741',
                  index: '7410',
                  name: 'Specialized design activities',
                },
                { id: '742', index: '7420', name: 'Photographic activities' },
                {
                  id: '749',
                  index: '7490',
                  name: 'Other professional, scientific and technical activities n.e.c.',
                },
              ],
            },
            { id: '75', index: '7500', name: 'Veterinary activities' },
          ],
        },
        {
          id: 'N',
          index: 'Section N',
          name: 'Administrative and support activities',
          subCodes: [
            {
              id: '77',
              index: '77',
              name: 'Rental and leasing activities',
              subCodes: [
                {
                  id: '771',
                  index: '7710',
                  name: 'Renting and leasing of motor vehicles (without driver)',
                },
                {
                  id: '772',
                  index: '772',
                  name: 'Renting and leasing of personal and household goods',
                  subCodes: [
                    {
                      id: '7721',
                      index: '7721',
                      name: 'Renting and leasing of recreational and sports goods',
                    },
                    {
                      id: '7722',
                      index: '7722',
                      name: 'Renting of video tapes and disks',
                    },
                    {
                      id: '7729',
                      index: '7729',
                      name: 'Renting and leasing of other personal and household goods',
                    },
                  ],
                },
                {
                  id: '773',
                  index: '7730',
                  name: 'Renting and leasing of other machinery, equipment and tangible goods',
                },
                {
                  id: '774',
                  index: '7740',
                  name: 'Leasing of intellectual property and similar products, except copyrighted works',
                },
              ],
            },
            {
              id: '78',
              index: '78',
              name: 'Employment activities',
              subCodes: [
                {
                  id: '781',
                  index: '7810',
                  name: 'Activities of employment placement agencies',
                },
                {
                  id: '782',
                  index: '7820',
                  name: 'Temporary employment agency activities',
                },
                {
                  id: '783',
                  index: '7830',
                  name: 'Other human resources provision',
                },
              ],
            },
            {
              id: '79',
              index: '79',
              name: 'Travel agency, tour operator, reservation service and related activities',
              subCodes: [
                {
                  id: '791',
                  index: '791',
                  name: 'Travel agency and tour operator activities',
                  subCodes: [
                    {
                      id: '7911',
                      index: '7911',
                      name: 'Travel agency activities',
                    },
                    {
                      id: '7912',
                      index: '7912',
                      name: 'Tour operator activities',
                    },
                  ],
                },
                {
                  id: '799',
                  index: '7990',
                  name: 'Other reservation service and related activities',
                },
              ],
            },
            {
              id: '80',
              index: '80',
              name: 'Security and investigation activities',
              subCodes: [
                { id: '801', index: '8010', name: 'Private security activities' },
                {
                  id: '802',
                  index: '8020',
                  name: 'Security systems service activities',
                },
                { id: '803', index: '8030', name: 'Investigation activities' },
              ],
            },
            {
              id: '81',
              index: '81',
              name: 'Services to buildings and landscape activities',
              subCodes: [
                {
                  id: '811',
                  index: '8110',
                  name: 'Combined facilities support activities',
                },
                {
                  id: '812',
                  index: '812',
                  name: 'Cleaning activities',
                  subCodes: [
                    {
                      id: '8121',
                      index: '8121',
                      name: 'General cleaning of buildings (for specialised interior cleaning activities, such as chimney cleaning, cleaning of fireplaces, stoves, furnaces, incinerators, boilers, ventilation ducts, exhaust units, see 8129)',
                    },
                    {
                      id: '8129',
                      index: '8129',
                      name: 'Other building and industrial cleaning activities',
                    },
                  ],
                },
                {
                  id: '813',
                  index: '8130',
                  name: 'Landscape care and maintenance service activities',
                },
              ],
            },
            {
              id: '82',
              index: '82',
              name: 'Office administrative, office support and other business support activities',
              subCodes: [
                {
                  id: '821',
                  index: '821',
                  name: 'Office administrative and support activities',
                  subCodes: [
                    {
                      id: '8211',
                      index: '8211',
                      name: 'Combined office administrative service activities',
                    },
                    {
                      id: '8219',
                      index: '8219',
                      name: 'Photocopying, document preparation and other specialized office support activities',
                    },
                  ],
                },
                { id: '822', index: '8220', name: 'Activities of call centres' },
                {
                  id: '823',
                  index: '8230',
                  name: 'Organization of conventions and trade shows',
                },
                {
                  id: '829',
                  index: '829',
                  name: 'Business support service activities n.e.c.',
                  subCodes: [
                    {
                      id: '8291',
                      index: '8291',
                      name: 'Activities of collection agencies and credit bureaus',
                    },
                    { id: '8292', index: '8292', name: 'Packaging activities' },
                    {
                      id: '8299',
                      index: '8299',
                      name: 'Other business support service activities n.e.c.',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 'O',
          index: 'Section O',
          name: 'Public administration and defence; compulsory social security',
          subCodes: [
            {
              id: '84',
              index: '84',
              name: 'Administration of the state and the economic and social policy of the community',
              subCodes: [
                {
                  id: '841',
                  index: '841',
                  name: 'Administration of the state and the economic and social policy of the community',
                  subCodes: [
                    {
                      id: '8411',
                      index: '8411',
                      name: 'General public administration',
                    },
                    {
                      id: '8412',
                      index: '8412',
                      name: 'Regulation of the activities of providing health care, education, cultural services and other social services, excluding social security',
                    },
                    {
                      id: '8413',
                      index: '8413',
                      name: 'Regulation of and contribution to more efficient operation of businesses',
                    },
                    {
                      id: '8414',
                      index: '8414',
                      name: 'Extra budgetary account n.e.c. (but, government activities that can be classified according to the activity, must be classified  in the rest of SIC)',
                    },
                  ],
                },
                {
                  id: '842',
                  index: '842',
                  name: 'Provision of services to the community as a whole',
                  subCodes: [
                    { id: '8421', index: '8421', name: 'Foreign affairs' },
                    { id: '8422', index: '8422', name: 'Defence activities' },
                    {
                      id: '8423',
                      index: '8423',
                      name: 'Public order and safety activities',
                    },
                  ],
                },
                {
                  id: '843',
                  index: '8430',
                  name: 'Compulsory social security activities',
                },
              ],
            },
          ],
        },
        {
          id: 'P',
          index: 'Section P',
          name: 'Education',
          subCodes: [
            {
              id: '851',
              index: '8510',
              name: 'Pre-primary and primary education',
            },
            {
              id: '852',
              index: '852',
              name: 'Secondary education',
              subCodes: [
                { id: '8521', index: '8521', name: 'General secondary education' },
                {
                  id: '8522',
                  index: '8522',
                  name: 'Technical and vocational secondary education',
                },
              ],
            },
            { id: '853', index: '8530', name: 'Higher education' },
            {
              id: '854',
              index: '854',
              name: 'Other education',
              subCodes: [
                {
                  id: '8541',
                  index: '8541',
                  name: 'Sports and recreation education',
                },
                { id: '8542', index: '8542', name: 'Cultural education' },
                { id: '8549', index: '8549', name: 'Other education n.e.c.' },
              ],
            },
            { id: '855', index: '8550', name: 'Educational support activities' },
          ],
        },
        {
          id: 'Q',
          index: 'Section Q',
          name: 'Human health and social work activities',
          subCodes: [
            {
              id: '86',
              index: '86',
              name: 'Human health activities',
              subCodes: [
                {
                  id: '861',
                  index: '8610',
                  name: 'Hospital activities (for health activities for military personnel in the field, see 8422)',
                },
                {
                  id: '862',
                  index: '8620',
                  name: 'Medical and dental practice activities',
                },
                {
                  id: '869',
                  index: '8690',
                  name: 'Other human health activities',
                },
              ],
            },
            {
              id: '87',
              index: '87',
              name: 'Residential care activities',
              subCodes: [
                {
                  id: '871',
                  index: '8710',
                  name: 'Residential nursing care facilities',
                },
                {
                  id: '872',
                  index: '8720',
                  name: 'Residential care activities for mental retardation, mental health and substance abuse',
                },
                {
                  id: '873',
                  index: '8730',
                  name: 'Residential care activities for the elderly and disabled',
                },
                {
                  id: '879',
                  index: '8790',
                  name: 'Other residential care activities',
                },
              ],
            },
            {
              id: '88',
              index: '88',
              name: 'Social work activities without accommodation',
              subCodes: [
                {
                  id: '881',
                  index: '8810',
                  name: 'Social work activities without accommodation for the elderly and disabled',
                },
                {
                  id: '889',
                  index: '8890',
                  name: 'Other social work activities without accommodation',
                },
              ],
            },
          ],
        },
        {
          id: 'R',
          index: 'Section R',
          name: 'Arts, entertainment and recreation',
          subCodes: [
            {
              id: '90',
              index: '9000',
              name: 'Creative, arts and entertainment activities',
            },
            {
              id: '91',
              index: '91',
              name: 'Libraries, archives, museums and other cultural activities',
              subCodes: [
                {
                  id: '9101',
                  index: '9101',
                  name: 'Library and archives activities',
                },
                {
                  id: '9102',
                  index: '9102',
                  name: 'Museums activities and operation of historical sites and buildings',
                },
                {
                  id: '9103',
                  index: '9103',
                  name: 'Botanical and zoological gardens and nature reserves activities',
                },
              ],
            },
            {
              id: '92',
              index: '9200',
              name: 'Gambling and betting activities',
            },
            {
              id: '93',
              index: '93',
              name: 'Sports activities and amusement and recreation activities',
              subCodes: [
                {
                  id: '931',
                  index: '931',
                  name: 'Sports activities',
                  subCodes: [
                    {
                      id: '9311',
                      index: '9311',
                      name: 'Operation of sports facilities',
                    },
                    {
                      id: '9312',
                      index: '9312',
                      name: 'Activities of sports clubs',
                    },
                    {
                      id: '9319',
                      index: '9319',
                      name: 'Other sports activities',
                    },
                  ],
                },
                {
                  id: '932',
                  index: '932',
                  name: 'Other amusement and recreation activities',
                  subCodes: [
                    {
                      id: '9321',
                      index: '9321',
                      name: 'Activities of amusement parks and theme parks',
                    },
                    {
                      id: '9329',
                      index: '9329',
                      name: 'Other amusement and recreation activities n.e.c.',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 'S',
          index: 'Section S',
          name: 'Other service activities',
          subCodes: [
            {
              id: '94',
              index: '94',
              name: 'Activities of membership organizations',
              subCodes: [
                {
                  id: '941',
                  index: '941',
                  name: 'Activities of business, employers and professional membership organizations',
                  subCodes: [
                    {
                      id: '9411',
                      index: '9411',
                      name: 'Activities of business and employers membership organizations',
                    },
                    {
                      id: '9412',
                      index: '9412',
                      name: 'Activities of professional membership organizations',
                    },
                  ],
                },
                {
                  id: '942',
                  index: '9420',
                  name: 'Activities of trade unions',
                },
                {
                  id: '949',
                  index: '949',
                  name: 'Activities of other membership organizations',
                  subCodes: [
                    {
                      id: '9491',
                      index: '9491',
                      name: 'Activities of religious organizations',
                    },
                    {
                      id: '9492',
                      index: '9492',
                      name: 'Activities of political organizations',
                    },
                    {
                      id: '9499',
                      index: '9499',
                      name: 'Activities of other membership organizations n.e.c.',
                    },
                  ],
                },
              ],
            },
            {
              id: '95',
              index: '95',
              name: 'Repair of computers and personal and household goods',
              subCodes: [
                {
                  id: '951',
                  index: '951',
                  name: 'Repair of computers and communication equipment',
                  subCodes: [
                    {
                      id: '9511',
                      index: '9511',
                      name: 'Repair of computers and peripheral equipment',
                    },
                    {
                      id: '9512',
                      index: '9512',
                      name: 'Repair of communication equipment',
                    },
                  ],
                },
                {
                  id: '952',
                  index: '952',
                  name: 'Repair of personal household goods',
                  subCodes: [
                    {
                      id: '9521',
                      index: '9521',
                      name: 'Repair of consumer electronics',
                    },
                    {
                      id: '9522',
                      index: '9522',
                      name: 'Repair of household appliances and home and garden equipment',
                    },
                    {
                      id: '9523',
                      index: '9523',
                      name: 'Repair of footwear and leather goods',
                    },
                    {
                      id: '9524',
                      index: '9524',
                      name: 'Repair of furniture and home furnishings',
                    },
                    {
                      id: '9529',
                      index: '9529',
                      name: 'Repair of other personal and household goods',
                    },
                  ],
                },
              ],
            },
            {
              id: '96',
              index: '96',
              name: 'Other personal  service activities',
              subCodes: [
                {
                  id: '9601',
                  index: '9601',
                  name: 'Washing and (dry-) cleaning of textile- and fur products',
                },
                {
                  id: '9602',
                  index: '9602',
                  name: 'Hairdressing and other beauty treatment',
                },
                {
                  id: '9603',
                  index: '9603',
                  name: 'Funeral and related activities',
                },
                {
                  id: '9609',
                  index: '9609',
                  name: 'Other personal service activities n.e.c.',
                },
              ],
            },
          ],
        },
        {
          id: 'T',
          index: 'Section T',
          name: 'Activities of households as employers; undifferentiated goods- and services producing activities of households for own use',
          subCodes: [
            {
              id: '97',
              index: '9700',
              name: 'Activities of households as employers of domestic personnel',
            },
            {
              id: '98',
              index: '98',
              name: 'Undifferentiated goods- and services-producing activities of private households for own use',
              subCodes: [
                {
                  id: '981',
                  index: '9810',
                  name: 'Undifferentiated goods-producing activities of private households for own use',
                },
                {
                  id: '982',
                  index: '9820',
                  name: 'Undifferentiated service-producing activities of private households for own use',
                },
              ],
            },
          ],
        },
        {
          id: 'U',
          index: 'Section U',
          name: 'Activities of extraterritorial organizations and bodies, not economically active people, unemployed people etc.',
          subCodes: [
            {
              id: '99',
              index: '9900',
              name: 'Activities of extraterritorial organizations and bodies, not economically active, people, unemployed people etc.',
            },
          ],
        },
      ];
    },
  },
};
</script>
