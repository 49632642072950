<template>
    <div  class="lfx-currency-pair-container">
    <div class="currency-pair">
    <capitec-label :label="record.id.split('_').join('/')" />
      </div>
    <div class="currency-pair-sell">
    <capitec-button>BUY</capitec-button>
    </div>
    <div class="currency-pair-buy">
     <capitec-button>SELL</capitec-button> </div>
    
    </div>
</template>


<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

export default {
  components: {},
  props: {  
    record:{type:Object,required:true},
    definition:{type:Object,required:true}
  },
  data() {
    return {

    }
  },
  created() {
    
  },
  mounted() {
    
  },
  computed:{
    ...mapGetters([]),
  	...mapState([]),
    titleField() {
      for (let f = 0; f < this.definition.list_view_fields.length; f ++  ) {
        let field = this.definition.list_view_fields[f];
        if (field.views.list === 'title') {
          return field.name;
        }
      }
      return 'id';
    },
    title() {
      return this.record[this.titleField];
    }
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([])
  }
 }
</script>
<style>

div.lfx-currency-pair-container{
  display:flex;
}

div.currency-pair{
  flex:1;
}

div.currency-pair-sell{
  flex:1;
}

div.currency-pair-buy{
  flex:1;
}

</style>


