<template>
  <capitec-content-box>
    <template v-if="signatoryOrder.length === 0">
      <h1>&nbsp;&nbsp; No Account Opening Signatories Captured</h1>
      <p>&nbsp;&nbsp;&nbsp; If signatories have been captured for this client but do not appear here, please ensure that they have <b>Account Opening Signatory</b> set to <b>Yes</b></p>
    </template>
    <LfxSignatoryOrder
      :order="signatoryOrder"
      :records="availableSignatories"
      @on-order-change="onOrderChange"
      :fields="{ info: 'emailAddress', subtitle: 'capacity', description:'signerLevel' }"
      :nameFields="['firstName', 'surname']"
    />
    <div v-if="isWizard">
      <capitec-action-bar>
        <LDBButton
          v-for="button in leftButtons"
          :key="button.name"
          :label="button.label"
          :action="button.action"
          :actionParams="button.actionParams"
          :params="button.params"
          :type="button.type"
          :tooltip="button.tooltip"
          :modal="button.modal"
          :viewGuards="button.guards"
          :enabledGuards="button.enabledGuards"
          @click="button.clickEvent"
        >
          <template v-if="button.modal !== undefined" #modal>
            <div v-html="button.modal.content"></div>
          </template>
        </LDBButton>
        <capitec-spacer />
        <LDBButton
          v-for="button in rightButtons"
          :key="button.name"
          :label="button.label"
          :action="button.action"
          :actionParams="button.actionParams"
          :params="button.params"
          :type="button.type"
          :tooltip="button.tooltip"
          :modal="button.modal"
          :viewGuards="button.guards"
          :enabledGuards="button.enabledGuards"
          @click="button.clickEvent"
        >
          <template v-if="button.modal !== undefined" #modal>
            <div v-html="button.modal.content"></div>
          </template>
        </LDBButton>
      </capitec-action-bar>
    </div>
  </capitec-content-box>

  <router-view></router-view>
</template>

<script>
import { mapState } from 'vuex';

import LfxSignatoryOrder from '@/views/signatories/LfxSignatoryOrder.vue';

export default {
  name: 'LfxClientApplicationSignatories',
  components: { LfxSignatoryOrder },
  emits: ['on-back', 'on-next'],
  data() {
    return {
      signatoryOrder: [],
    };
  },
  created() {},
  mounted() {
    this.setupInitialSignatoryOrder();
  },
  computed: {
    ...mapState(['account']),

    // ----- Buttons -----

    isWizard() {
      return this.$route.meta?.wizard;
    },

    leftButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'back',
          label: 'Back',
          type: 'clear',
          action: 'click',
          clickEvent() {
            self.$emit('on-back');
          },
        },
      ];
      return allButtons;
    },

    rightButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'updateSignatories',
          label: 'Save',
          type: '',
          action: 'setApplicationSignatoryOrder',
          guards: this.applicationRecord?.applicationIsEditable,
          params: {
            accountId: this.accountId,
            applicationId: this.applicationId,
          },
          actionParams: {
            signatoryOrder: this.signatoryOrder,
          },
          clickEvent(evt) {},
        },
        {
          name: 'next',
          label: 'Next',
          action: 'setApplicationSignatoryOrder',
          type: 'primary',
          guards: false,
          params: {
            accountId: this.accountId,
            applicationId: this.applicationId,
          },
          actionParams: {
            signatoryOrder: this.signatoryOrder,
          },
          get disabled() {
            return this.type === 'disabled';
          },
          guards: true,
          clickEvent() {
            self.onNextClick();
          },
        },
      ];
      return allButtons !== undefined ? allButtons : [];
    },

    availableSignatories() {
      return this.accountRecord?.applicationPortfolioSignatories;
      // const res = {};
      // for (const sigId in this.accountRecord?.applicationPortfolioSignatories) {
      //   if (this.accountRecord?.applicationPortfolioSignatories[sigId]?.accountOpeningSignatory) {
      //     res[sigId] = this.accountRecord?.applicationPortfolioSignatories[sigId];
      //   }
      // }
      // return res;
    },
    // ----- Account Record -----
    accountId() {
      return +this.$route.params.accountId;
    },
    accountRecord() {
      let rec = this.account[this.accountId];
      return rec;
    },
    // ----- Application Record -----

    applicationId() {
      return +this.$route.params.applicationId;
    },
    applicationRecord() {
      return this.accountRecord?.application?.[this.applicationId];
    },
  },
  watch: {
    availableSignatories(newValue) {
      if (newValue) {
        this.signatoryOrder = []
        this.setupInitialSignatoryOrder();
      }
    },
  },

  methods: {
    onOrderChange(newOrder) {
      this.signatoryOrder = newOrder;
      this.applicationRecord.signatoryOrder = newOrder;
    },

    setupInitialSignatoryOrder() {
      if (!this.signatoryOrder) {
        if (
          Array.isArray(this.applicationRecord?.signatoryOrder) &&
          this.applicationRecord?.signatoryOrder.length !== 0
        ) {
          this.signatoryOrder = this.applicationRecord.signatoryOrder;
        } else if (Array.isArray(this.accountRecord.signatoryOrder) && this.accountRecord.signatoryOrder.length !== 0) {
          this.signatoryOrder = this.accountRecord.signatoryOrder;
        }
      }
      if (!this.signatoryOrder || Object.keys(this.signatoryOrder).length === 0) {
        for (let s in this.availableSignatories) {
          this.signatoryOrder.push(s);
        }
      }
      //  else
      //  {
      //   this.signatoryOrder = [];
      //    for (let s = 0 ; s < this.accountRecord.signatoryOrder.length ; s ++  ) {
      //      let id = this.accountRecord.signatoryOrder[s];
      //      let signatory = this.availableSignatories[id];
      //      if (signatory) {
      //       signatory.checked = true;
      //       this.signatoryOrder.push(id);
      //      }
      //    }
      //    for (let s in this.availableSignatories ) {
      //      let signatory = this.availableSignatories[s];
      //      if (this.accountRecord.signatoryOrder.indexOf(signatory.id) === -1) {
      //        signatory.checked = false;
      //        this.signatoryOrder.push(signatory.id);
      //      }
      //    }
      //  }
    },
    onNextClick() {
      this.$emit('on-next', {
        fields: this.changedFields,
      });
    },
  },
};
</script>
