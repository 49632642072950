import VueStore from 'vue-class-store';

import { LdbDbObject, LdbDefinition } from '@/definitions/LdbInterfaces';
import {
  LfxApplicationCifIndividualInt,
  requiredFields,
  LfxApplicationCifIndividualDef,
} from '@/definitions/LfxApplicationCifIndividualDef';
import { LfxModel, LfxDefinition } from './LfxModel';

//@ts-ignore
import { http_patch, http_get, http_put } from '@/lfx_rest';

@VueStore
//@ts-ignore
class LfxApplicationCifIndividualMdl extends LfxDefinition {
  //@ts-ignore
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
//@ts-expect-error
export class LfxApplicationCifIndividual extends LfxModel {
  //@ts-expect-error
  constructor(data, state) {
    super({ definition_name: 'application_cif_individual', ...data }, state);
  }

  get required_fields() {
    //@ts-expect-error
    return requiredFields(this as LfxApplicationCifIndividualInt,this.store.state.account[this.accountId]?.applicationDocumentUploadType) as (keyof this)[];
  }

  get outstanding_fields() {
    const outstandingFields = [];
    for (const field of this.required_fields!) {
      if (this[field] === null || this[field] === undefined) {
        outstandingFields.push(field);
      }
    }
    return outstandingFields;
  }

  get is_submittable() {
    return this.outstanding_fields.length === 0 && !this.has_validation_errors;
  }

  actOccupationCode(evt: any, state: any, router: any) {
    // TODO routeName for edit view outside of Wizard
    const routeName =
      //@ts-expect-error
      this.cifType === 'full'
        ? 'clientApplicationWizardClientCifDetailsEditOcPicker'
        : 'clientApplicationWizardAssociatedCifDetailsEditOcPicker';
    router.push({ name: routeName });
  }

  actIndustryCode(evt:any,state:any,router:any) {
    //@ts-expect-error
    const routeName = this.cifType === 'full'
    ? 'clientApplicationWizardClientCifDetailsEditBscPicker'
    : 'clientApplicationWizardAssociatedCifDetailsEditBscPicker';
    router.push({ name: routeName });
  }  

}

//@ts-ignore
export function Definition(context, name: string) {
  return new LfxApplicationCifIndividualMdl(
    LfxApplicationCifIndividualDef,
    LfxApplicationCifIndividual,
    context,
    name,
    {}
  );
}
