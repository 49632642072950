//@ts-nocheck

import { LfxConfigInt,LfxConfigDef } from '@/definitions/LfxConfigDef';

import { LfxModel,LfxDefinition } from './LfxModel';

class LfxConfigtMdl extends LfxDefinition {
  constructor(def,dbclass,context,name,config) {
    super(def,dbclass,context,name,config)
  }
}

export class LfxConfig extends LfxModel {
  constructor(data,state) {
    super({definition_name:'config',...data,state});
  }
}

export function Definition(context,name:string) {
  return new LfxConfigtMdl(LfxConfigDef,LfxConfig,context,name,{})
}

