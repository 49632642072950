<template>
  <div>
    <LDBButton
      :key="field.field+record.id"
      :label="field.datatype.action.label"
      :action="field.datatype.action.action"
      :actionParams="actionParams"
      :params="params"
      type="primary"
      :viewGuards="true"
      :enabledGuards="true"
      :onSuccess="undefined"
    ></LDBButton>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

//@ts-ignore
import { ldb_field_mixin } from './ldb_field_mixin.js';
import LDBItemViewField from './LDBItemViewField.vue';
import LDBListViewField from './LDBListViewField.vue';

export default {
  ...mapState(['authUser']),
  mixins: [ldb_field_mixin],
  components: { 
    LDBItemViewField,
    LDBListViewField
  },
  props: {},
  data() {
    return {
      focussed: false,
      newValue: '',
    };
  },
  created() {
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    actionParams() {
      return this.record[this.field.name + 'ActionParams'] || {};
    },
    params() {
      return this.record[this.field.name + 'Params'] || {};
    },

  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    on_focus(evt) {
      this.focussed = true;
      this.$emit('focus', evt);
    },
    on_blur(evt) {
      this.focussed = false;
      this.$emit('blur', evt);
    },
    on_change(evt) {
      this.newValue = evt.detail.new;
      this.$emit('change', evt.detail.new);
    },
  },
};
</script>

<style>

div.ldb-field-container__text {
  /* border: 1px dotted grey; */
  /* display:flex; */
  flex:1;
}

div.ldb-field-container__text > capitec-memo{
  flex:1;
}

div.ldb-field-container__text > capitec-text-field{
  flex:1;
}

div.ldb-field-container__text > capitec-button{
  flex:1;  
  max-width: 50px;
  min-width: 50px;
  max-height: 48px;
  min-height: 48px;
  margin-left:10px;
}


.ldb-field-container.list > capitec-label{
  white-space: nowrap;
}

</style>


