//@ts-nocheck
import VueStore from 'vue-class-store';

import { LfxClientMessageLogInt, LfxClientMessageLogDef } from '@/definitions/LfxClientMessageLogDef';

import { LfxModel, LfxDefinition } from './LfxModel';

@VueStore
class LfxClientMessageLogMdl extends LfxDefinition {
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
export class LfxClientMessageLog extends LfxModel {
  constructor(data, state) {
    super({definition_name:'client_message_log',...data}, state);    
  }
  get displayFKLink() {
    return false
  }
}

export function Definition(context, name: string) {
  return new LfxClientMessageLogMdl(LfxClientMessageLogDef, LfxClientMessageLog, context, name, {});
}
