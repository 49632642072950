//@ts-nocheck
import VueStore from 'vue-class-store';

import { LfxImportBatchDef } from '@/definitions/LfxImportBatchDef';

import { LfxModel, LfxDefinition } from './LfxModel';

@VueStore
class LfxImportBatchMdl extends LfxDefinition {
  constructor(def, dbclass, context, name,config) {
    super(def, dbclass, context, name,config);
  }
}
@VueStore
export class LfxImportBatch extends LfxModel {
  constructor(data, state) {
    super({definition_name:'importBatch',...data}, state);
  }
  get link() {
    // @ts-expect-error
    return `/admin/dataImportBatch/${this.id}`;
  }
}

export function Definition(context, name: string) {
  return new LfxImportBatchMdl(LfxImportBatchDef, LfxImportBatch, context, name,{});
}
