//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2023 LandoByte (Pty) Ltd.
//  File        : LdbEmailLogDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Email Log record for LandoByte TS systems
//  Created     : 28 September 2023 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition } from '../definitions/LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------

export type emailType =
  | 'paymentConfirmation'
  | 'general'
  | 'notifyOfAuthChange'
  | 'sendResetPendingUserEmail'
  | 'permissionRequested'
  | 'permissionRejected'
  | 'permissionReleased'
  | 'bulkUserEnableConfirmation'
  | 'dealRelease'
  | 'emailStatment'
  | 'failedTreasuryDeal'
  | 'autoImportClient'
  | 'autoImportClientFailure'
  | 'existingCifSubmitted'
  | 'bulkFeeComplete'
  | 'batchImportComplete'
  | 'batchImportError'
  | 'ratesFeedStatusChange'
  | 'paymentProcessingReport'
  | 'invoiceReport'
  | 'download'
  | 'fileSend'
  | 'dealReject'
  | 'paymentReject'
  | 'bulkEnableIntermediaryReports'
  | 'userOtp'
  | 'supportEscalation'
  | 'userDisabled'
  | 'userReviewReminder'
  | 'userReviewConfirmation'
  | 'userReviewMissed'
  | 'tocMandateConflict';

//----------------------------------------------------------------------------------------------------------------------

export interface LdbEmailLogInt {
  id?: string,
  linkedTableId?: string,
  toEmailAddressMonth?: string,
  emailType?: emailType,
  linkedTable?: string,
  linkedId?: number|string,
  emailAddress?: string,
  recipientName?: string,
  toAddresses?: {name:string,email:string}[],
  ccAddresses?: {name:string,email:string}[],
  bccAddresses?: {name:string,email:string}[]
  subject?: string,
  attachments?: {}[],
  queuedTime?: Date,
  sentTime?: Date,
};

//----------------------------------------------------------------------------------------------------------------------

export const LdbEmailLogDef:LdbDefinition = {
  title:"Email Logs",
  table:'LdbEmailLog',
  collectionPath:"logs/emailLog",
  view:{create:false,edit:false,item:true,list:true,delete:false,csv:false},
  indexes:['linkedTableId','toEmailAddressMonth'],
  database:"dynamo",
  pagingType:'backEnd',  
  groups: {
    general: { priority: 1, label: 'General' },
    message: { priority: 2, label: 'Message' },
    recipients: { priority: 3, label: 'Recipients' },
    system: { priority: 9, label: 'System' },
  },
  fields:{
    id:{
      label:'ID',
      datatype:{'id':{descriptionField:'action',type:'text'}},
      default:'AUTOUUID',
      mandatory:true,
      views:{create:false,edit:false,item:false,list:true,delete:false,csv:false},
      group: 'system',
    },

    linkedTableId:{
      label:'Linked Table and ID',
      datatype:'text',
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:false,list:false,delete:false,csv:false},
      group: 'system',
    },
    toEmailAddressMonth:{
      label:'To Email Address and Month',
      datatype:'text',
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:false,list:false,delete:false,csv:false},
      group: 'system',
    },

    emailType:{
      label:'Email Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'paymentConfirmation', name: 'Payment Confirmation', tagType: 'info' },
            { id: 'general', name: 'General Email', tagType: 'info' },
            { id: 'notifyOfAuthChange', name: 'Notification of Auth Change', tagType: 'info' },
            { id: 'sendResetPendingUserEmail', name: 'Send Reset User Email', tagType: 'info' },
            { id: 'permissionRequested', name: 'Permissions Requested', tagType: 'info' },
            { id: 'permissionRejected', name: 'Permissions Rejected', tagType: 'info' },
            { id: 'permissionReleased', name: 'Permissions Released', tagType: 'info' },
            { id: 'bulkUserEnableConfirmation', name: 'Bulk Enable Confirmation', tagType: 'info' },
            { id: 'dealRelease', name: 'Deal Release', tagType: 'info' },
            { id: 'emailStatment', name: 'Email Statement', tagType: 'info' },
            { id: 'failedTreasuryDeal', name: 'Failed Treasury Deal', tagType: 'info' },
            { id: 'existingCifSubmitted', name: 'Existing CIF Submitted', tagType: 'info' },
            { id: 'bulkFeeComplete', name: 'Bulk Fee Batch Copmleted', tagType: 'info' },
            { id: 'batchImportComplete', name: 'Import Batch Completed', tagType: 'info' },
            { id: 'batchImportError', name: 'Import Batch Error', tagType: 'info' },
            { id: 'ratesFeedStatusChange', name: 'Rates Feed Status Change', tagType: 'info' },
            { id: 'paymentProcessingReport', name: 'Payment Processing Report', tagType: 'info' },
            { id: 'invoiceReport', name: 'Invoice Report', tagType: 'info' },
            { id: 'download', name: 'Download', tagType: 'info' },
            { id: 'fileSend', name: 'File Sent', tagType: 'info' },
            { id: 'dealReject', name: 'Deal Rejection', tagType: 'info'},
            { id: 'paymentReject', name: 'Payment Rejection', tagType: 'info'},
            { id: 'bulkEnableIntermediaryReports', name: 'Bulk Enable Intermediary Report', tagType: 'info'},
            { id: 'userOtp', name: 'Email OTP'},
            { id: 'supportEscalation', name: 'Support Escalation'},
            { id: 'userDisabled', name: 'User Disabled'},
            { id: 'userReviewReminder', name: 'User Review Reminder'},
            { id: 'userReviewConfirmation', name: 'User Review Confirmation'},
            { id: 'userReviewMissed', name: 'User Review Missed'},
          ],
        },
      },
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false},
      group: 'general',
    },
    linkedTable:{
      label:'Linked Table',
      datatype:'text',
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:false,delete:false,csv:false},
      group: 'system',
    },
    linkedId:{
      label:'Linked Table ID',
      datatype:'integer',
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:false,delete:false,csv:false},
      group: 'system',
    },
    emailAddress:{
      label:'Address',
      datatype:'text',
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false},
      group: 'general',
    },
    recipientName:{
      label:'Recipient',
      datatype:'text',
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false},
      group: 'general',
    },
    toAddresses:{
      label:'To Addresses',
      datatype:{json:{}},
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:false,delete:false,csv:false},
      group: 'recipients',
    },
    ccAddresses:{
      label:'CC Addresses',
      datatype:{json:{}},
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:false,delete:false,csv:false},
      group: 'recipients',
    },
    bccAddresses:{
      label:'BCC Addresses',
      datatype:{json:{}},
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:false,delete:false,csv:false},
      group: 'recipients',
    },
    subject:{
      label:'Subject',
      datatype:'text',
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:false,delete:false,csv:false},
      group: 'general',
    },
    attachments:{
      label:'Attachmentts',
      datatype:{json:{}},
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:false,delete:false,csv:false},
      group: 'system',
    },

    queuedTime:{
      label:'Queued',
      datatype: {datetime: {
        type: 'datetime',
        format: 'human'
      }},
      default:null,
      mandatory:true,
      allowNullValues:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false},
      group: 'system',
    },
    sentTime:{
      label:'Sent',
      datatype: {datetime: {
        type: 'datetime',
        format: 'human'
      }},
      default:null,
      mandatory:true,
      allowNullValues:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false},
      group: 'system',
    }
  }
}
