import VueStore from 'vue-class-store';

import { LdbDbObject, LdbDefinition, LdbOptionType } from '@/definitions/LdbInterfaces';
import { LfxSignatoryInt, LfxSignatoryDef } from '@/definitions/LfxSignatoryDef';
import { LfxModel, LfxDefinition } from './LfxModel';

//@ts-ignore
import { http_patch, http_get, http_put } from '@/lfx_rest';

@VueStore
//@ts-ignore
class LfxSignatoryMdl extends LfxDefinition {
  //@ts-ignore
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
//@ts-ignore
export class LfxSignatory extends LfxModel {
  //@ts-ignore
  constructor(data, state) {
    super({ definition_name: 'signatory', ...data }, state);
  }
  get fullName() {
    //@ts-expect-error
    return this.firstName ? this.firstName + ' ' + this.surname : this.surname;
  }
  get signerLevelString() {
    for (const option of (LfxSignatoryDef.fields.signerLevel.datatype as LdbOptionType).option.options) {
      //@ts-expect-error
      if (option.id === this.signerLevel) {
        return option.name;
      }
    }
    //@ts-expect-error
    return this.signerLevel;
  }
  get signerRequiredString() {
    for (const option of (LfxSignatoryDef.fields.signerRequired.datatype as LdbOptionType).option.options) {
      //@ts-expect-error
      if (option.id === this.signerRequired) {
        return option.name;
      }
    }
    //@ts-expect-error
    return this.signerRequired;
  }

  get link() {
    // @ts-ignore
    // return this.userId
    //   ? // @ts-ignore
    //     `/user/${this.userId}/signatory/${this.id}` // TODO
    //   : // @ts-ignore
    //     `/account/${this.accountId}/signatory/${this.id}`;
    return this.userId ? `/user/${this.userId}/signatory/${this.id}` : `/account/${this.accountId}/signatory/${this.id}`;
  }
}

//@ts-ignore
export function Definition(context, name: string) {
  return new LfxSignatoryMdl(LfxSignatoryDef, LfxSignatory, context, name, {});
}
