//@ts-nocheck

import { LdbPermissionInt, LdbPermissionDef } from '@/definitions/LdbPermissionDef';

import store from '@/store';

import { LfxModel, LfxDefinition } from './LfxModel';

class LdbPermissiontMdl extends LfxDefinition {
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

export class LdbPermission extends LfxModel {
  constructor(data, store) {
    super({definition_name:'permission',...data}, store);    
  }

  get link() {
    // @ts-ignore
    return `/config/permission/${this.id}`;
  }
  get displayFKLink() {
    return false
  }


}

export function Definition(context, name: string) {
  return new LdbPermissiontMdl(LdbPermissionDef, LdbPermission, context, name, {});
}
