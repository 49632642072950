<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <ol>
        <li>This will mark the account as closed on the Forex system.</li>
        <li>
          This will not affect the account status in Core - please ensure that the account is closed in Core first.
        </li>
        <li>
          Closing the account while an in-flight transaction has it selected as the source or destination account might
          lead to a poor Client experience.
        </li>
        <li>The account may be re-opened at a larger stage.</li>
      </ol>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in buttons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

export default {
  name: 'LfxCloseBankAccount',
  components: {},
  props: {},
  emits: {},
  created() {},
  mounted() {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    // ----- Account record -----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },

    // ----- Markup record ----

    bankAccountId() {
      return +this.$route.params?.bankAccountId;
    },
    bankAccountRecord() {
      return this.accountRecord?.bankAccounts?.[this.bankAccountId];
    },

    header() {
      return `Close Bank Account: ${this.bankAccountRecord?.accountNumber}`;
    },
    buttons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelCloseBankAccount',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'closeBankAccount',
          label: `Close Bank Account`,
          type: 'primary',
          guards: this.bankAccountRecord.accountBankAccountClose_buttonGuard,
          clickEvent() {
            self.closeBankAccount();
          },
        },
      ];
      return allButtons.filter((button) => button.guards);
    },
  },
  methods: {
    async closeBankAccount() {
      let response = await this.bankAccountRecord.closeBankAccount();
      if (response.status === 'success') {
        this.$router.replace({ name: 'accountBankAccountView' });
      }
    },
  },
};
</script>
