// @ts-nocheck
import VueStore from 'vue-class-store';

import { LfxUserTeamInt, LfxUserTeamDef } from '@/definitions/LfxUserTeamDef';

import store from '@/store';

import { LfxModel, LfxDefinition } from './LfxModel';

@VueStore
class LfxUserTeamMdl extends LfxDefinition {
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
export class LfxUserTeam extends LfxModel {
  constructor(data, store) {
    super({definition_name:'userTeam',...data}, store);    
  }

  get link() {
    // @ts-ignore
    return `/config/userTeam/${this.id}`;
  }
  get displayFKLink() {
    return this.teamType === 'bankUserTeam'
  }
  get userReviewStatus(){
    if (this.store?.state?.systemSettings?.userReview?.enabled === false) {
      return 'compliant'
    }
    let previousReviewStartDate
    let previousReviewDate;
    let nextReviewDate;
    let nextReviewStartsDate;
    if (this.teamType === 'bankUserTeam') {
      previousReviewStartDate = this.store?.state?.systemSettings?.userReview?.previousBankReviewStartDate
      ? // @ts-expect-error
        new Date(this.store.state.systemSettings.userReview.previousBankReviewStartDate)
      : undefined;
      previousReviewDate = this.store?.state?.systemSettings?.userReview?.previousBankReviewDate ? new Date(this.store.state.systemSettings.userReview.previousBankReviewDate) : undefined;
      nextReviewDate = new Date(new Date(this.store?.state?.systemSettings?.userReview?.nextBankReviewDate).setHours(23,59,59));
      nextReviewStartsDate = this.store?.state?.systemSettings?.userReview?.nextBankReviewStartDate ?
        new Date(this.store?.state?.systemSettings?.userReview?.nextBankReviewStartDate)
        : new Date(new Date(nextReviewDate).setDate(
            nextReviewDate.getDate() - this.store?.state?.systemSettings?.userReview?.daysBeforeButtonEnable || 21
          ));
    } else {
      previousReviewStartDate = this.store?.state?.systemSettings?.userReview?.previousIntermediaryReviewStartDate
        ? // @ts-expect-error
          new Date(this.store.state.systemSettings.userReview.previousIntermediaryReviewStartDate)
        : undefined;
      previousReviewDate = this.store?.state?.systemSettings?.userReview?.previousIntermediaryReviewDate
        ? // @ts-expect-error
          new Date(this.store.state.systemSettings.userReview.previousIntermediaryReviewDate)
        : undefined;
      nextReviewDate = new Date(
          //@ts-expect-error
          new Date(this.store?.state?.systemSettings?.userReview?.nextIntermediaryReviewDate).setHours(23, 59, 59)
        );
      nextReviewStartsDate = this.store?.state?.systemSettings?.userReview?.nextIntermediaryReviewStartDate ?
        //@ts-expect-error 
          new Date(this.store?.state?.systemSettings?.userReview?.nextIntermediaryReviewStartDate)
          : new Date(new Date(nextReviewDate).setDate(
              //@ts-expect-error
              nextReviewDate.getDate() - this.store?.state?.systemSettings?.userReview?.daysBeforeButtonEnable || 21
            ));
    }
    const lastUserReviewDate = this.lastUserReviewDate ? new Date(this.lastUserReviewDate) : undefined;
    const createdDate = new Date(this.createdAt)
    console.log("ACCOUNT "+this.id,{
      createdDate,
      lastUserReviewDate,
      previousReviewStartDate,
      previousReviewDate,
      nextReviewStartsDate,
      nextReviewDate,
      a:(previousReviewStartDate && (!lastUserReviewDate || lastUserReviewDate < previousReviewStartDate) && ( previousReviewDate && createdDate < previousReviewDate)),
      b:new Date() < nextReviewStartsDate,
      c:(!lastUserReviewDate || (lastUserReviewDate < nextReviewStartsDate)),
      //@ts-expect-error
      settings:this.store?.state?.systemSettings?.userReview
    })
    if (previousReviewStartDate && (!lastUserReviewDate || lastUserReviewDate < previousReviewStartDate) && ( previousReviewDate && createdDate < previousReviewDate)) {
      return 'nonCompliant'
    }
    if (new Date() < nextReviewStartsDate) {
      return 'compliant'
    }
    if (!lastUserReviewDate || (lastUserReviewDate < nextReviewStartsDate)) {
      return 'due'
    }
    return 'compliant'
  }


}

export function Definition(context, name: string) {
  return new LfxUserTeamMdl(LfxUserTeamDef, LfxUserTeam, context, name, {});
}
