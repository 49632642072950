import VueStore from 'vue-class-store';

import { LfxCurrencyPairInt, LfxCurrencyPairDef } from '@/definitions/LfxCurrencyPairDef';
import { LfxModel, LfxDefinition } from './LfxModel';

@VueStore
// @ts-expect-error
class LfxCurrencyPairMdl extends LfxDefinition {
  // @ts-expect-error
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
// @ts-expect-error
export class LfxCurrencyPair extends LfxModel {
  // @ts-expect-error
  constructor(data, state) {
    super({ definition_name: 'currencyPair', ...data }, state);
  }

  get link() {
    // @ts-expect-error
    return `/config/currency_pairs/${this.id}/edit`;
  }
  get displayFKLink() {
    if (this.hasPermission.updateCurrencyPair) {
      return true;
    }
    return false;
  }
}

// @ts-expect-error
export function Definition(context, name: string) {
  return new LfxCurrencyPairMdl(LfxCurrencyPairDef, LfxCurrencyPair, context, name, {});
}
