<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <p v-if="iAmtryingToCheckMyOwnChange">
        You have requested a change to one or more of these markups.<br/>They will need to be released by another user.<br/>You can also cancel the change request if required.
      </p>
      <p v-else-if="iAmtryingToCheckAnotherUsersChange">
        Changes have been requested by one or more users. You can Release or Cancel the change request.
      </p>
      <template v-if="markupEditMode === 'checker'">
        <p><b>Changes Requested by:</b></p>
        <ul>
          <li v-for="userName in editingUserNames" :key="userName">{{userName}}</li>
        </ul>
      </template>
      <LDBField
      v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          :view="field.view"
          :fieldname="field.name"
          :definition="undefined"
          :record="markupsRecord"
          @change="field.on_change"
      />
    </template>

    <template #footer>
      <LDBButton
        v-for="button in buttons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { doPermissionAction } from '@/lfx_rest/lfx_action';
import { mapState, mapGetters } from 'vuex';
import { isMakerCheckerRequiredForMarkupEdit, getUpdateAccountMakerCheckerKey } from '@/sharedUtils/LfxMakerCheckerUtils'
// TODO - Add a nice table at the top to tell what has changed, track submit process, etc.
// TODO - Lock all fields on submit and then process prettily
// TODO - Add 'group' updator... i.e. all big currencyes to 150, all middle to 15, all small to 1.5
// TODO - Make this smart enough to create markups that don't exist (it will first need to get all possible currency pairs instead of using the markups)
// TODO - Somehow handle percentages as well as pips
// TODO - Unlock this feature for all users
// TODO - And of course, make it look pretty
export default {
  name: 'LfxBulkAccountMarkupEdit',
  components: {},
  props: {},
  emits: {},
  created() {},
  mounted() {
    for (const markupId in this.records) {
          this.currencyPairNames.push(this.records[markupId].currencyPair);
          this.markupRecords.push(
            {
              markupId:markupId,
              currencyPair:this.records[markupId].currencyPair,
              initialValue:this.records[markupId].pips,
              newValue:this.records[markupId].pips,

            }
          )
        }
      if (isMakerCheckerRequiredForMarkupEdit(this.account?.[this.authUser.accountId],this.accountRecord)) {
        this.getPreviouslyCachedObjects()
      }
  },
  data() {
    return {
      markupEditMode: 'normal', // 'normal' | 'maker' | 'checker'
      status: 'capture', // 'capture' | 'submitted' | 'completed'// TODO, update to make more sense
      currencyPairNames: [],
      lockedFields: [],
      markupRecords:[]
    };
  },
  computed: {
    ...mapGetters(['currencyPairs']),
    ...mapState(['authUser','account','user']),

    // ----- Account record -----

    accountId() {
      return +this.$route.params.accountId;
    },
    accountRecord() {
      return this.account[this.accountId];
    },
    records() {
      let res = {};
      for (let m in this.accountRecord.markup) {
        let mrk = this.accountRecord.markup[m];
        if (mrk.status === 'visible') {
          res[mrk.id] = mrk;
        }
      }
      return res;
    },
    markupsRecord() {
      const res = {};
      for (const markupRecord of this.markupRecords) { 
        res[markupRecord.currencyPair] =markupRecord.newValue
      }
    return res
    },
    viewFields() {
      const res = [];
      const self = this;
      for (const markupRecord of this.markupRecords) {
        const currencyPairName = markupRecord.currencyPair;
        const currencyPair = this.currencyPairs[currencyPairName];
          if (!currencyPair.parityPair && (['normal','maker'].includes(this.markupEditMode) || !!markupRecord.makerCheckerSecurityToken)) {
            res.push({
            fieldname:currencyPairName,
            name:currencyPairName,
            label:currencyPair.name,
            view:(this.lockedFields.includes(currencyPairName) || !['normal','maker'].includes(this.markupEditMode)) ? 'item' : 'edit',
            datatype:'integer',
            on_change:(update) =>{self.updateMarkupForCurrencyPair(update)}
          })
        }
      }
      return res;
    },
    haveNumbersChanged() {
      for (const markupRecord of this.markupRecords) {
        if (markupRecord.newValue !== markupRecord.initialValue) {
          return true
        }
      }
      return false
    },
    editingUserNames() {
      const res = [];
      for (const markupRecord of this.markupRecords) { 
        if (markupRecord.makingUserId) {
          const user = this.user?.[markupRecord.makingUserId]
          if (!res.includes(user.displayName)) {
            res.push(user.displayName)
          }
        }
      }
      return res
    },
    iAmtryingToCheckMyOwnChange() {
      if (!this.markupEditMode === 'checker') {
        return false
      }
      for (const markupRecord of this.markupRecords) { 
        if (markupRecord.makingUserId === this.authUser.id) {
          return true
        }
      }
      return false
    },
    iAmtryingToCheckAnotherUsersChange() {
      return this.markupEditMode === 'checker' && !this.iAmtryingToCheckMyOwnChange
    },

    header() {
      return `Bulk Update Account Markups`;
    },
    buttons() {
      let self = this;
      let allButtons = [
        {
            name: 'cancelMakerCheckerEdit',
            label: 'Cancel Requested Change',
            type: '',
            guards: this.markupEditMode === 'checker',
            clickEvent() {
              self.submitClearMakerCheckerMarkups();
            },
          },
        {
          name: 'cancelUnhideMarkup',
          label: 'Close',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'bulkUpdate',
          label: this.markupEditMode === 'checker' ? `Confirm Bulk Update` : 'Request Bulk Update',
          type: 'primary',
          guards: true,
          enabledGuards: (this.status === 'capture' && this.haveNumbersChanged) && !this.iAmtryingToCheckMyOwnChange,
          clickEvent() {
            self.submitChangedMarkups();
          },
        },
      ];
      return allButtons.filter((button) => button.guards);
    },
  },
  methods: {
    async unhideMarkup() {
      let response = await this.markupRecord.unhideMarkup();
      if (response.status === 'success') {
        this.$router.replace({ name: 'accountMarkupView' });
      }
    },
    updateMarkupForCurrencyPair(update) {
      for (const markupRecord of this.markupRecords) { 
        if (markupRecord.currencyPair === update.field) {
          markupRecord.newValue = update.value;          
        }
      }
    },
    async submitChangedMarkups  () {
      this.status = 'submitted';
      for (const markupRecord of this.markupRecords) {
        this.lockedFields.push(markupRecord.currencyPair)
        if (markupRecord.newValue !== markupRecord.initialValue) {
          await doPermissionAction(this, 'editAccountMarkups', 
            { accountId: this.accountId, markupId: markupRecord.markupId }, 
            {pips:markupRecord.newValue,securityToken:markupRecord.makerCheckerSecurityToken}
          );
        }
      }
      await this.getPreviouslyCachedObjects()
    },
    async submitClearMakerCheckerMarkups () {
      for (const markupRecord of this.markupRecords) {
        if (!!markupRecord.makerCheckerSecurityToken) {
          await doPermissionAction(this, 'clearMakerCheckerToken', 
            {configId:1}, 
            {cacheKey:getUpdateAccountMakerCheckerKey(markupRecord.markupId)}
          );
        }
        markupRecord.newValue = markupRecord.initialValue
      }
      this.lockedFields = [];
      await this.getPreviouslyCachedObjects()
    },
    async getPreviouslyCachedObjects() {
      this.markupEditMode = 'maker'
      for (const markupRecord of this.markupRecords) {
        const response = await doPermissionAction(this, 'getMakerCheckerToken', { configId: 1 }, {cacheKey:getUpdateAccountMakerCheckerKey(markupRecord.markupId)});
        if (+response.code === 200) {
          this.markupEditMode = 'checker'
          markupRecord.makerCheckerSecurityToken = response.response.securityToken
          markupRecord.makingUserId = response.response.makingUserId
          markupRecord.makingTimestamp = new Date(response.response.makingTimestamp)
          markupRecord.newValue = response.response?.cachedObject?.pips ||  markupRecord.newValue
        }
      }
    },

  },
};
</script>
