<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <div v-if="mounted">
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="definition"
          :record="accountRecord"
          @change="on_change"
        />
      </div>

      <div v-if="accountRecord.bopFormSignatoryOption === 'submittingUser'">
        <p>The user who submits the Payment will automatically be set up as the signer on the BOP Portfolio.</p>
      </div>
      <template v-else>
        <LfxSignatoryOrder
          :order="signatoryOrder"
          :records="availableSignatories"
          @on-order-change="onOrderChange"
          :fields="{ info: 'emailAddress', subtitle: 'capacity', description:'signerLevel' }"
          :nameFields="['firstName', 'surname']"
        />
        <!-- <div class="lfx-configure-signatory-table-container">
          <table-lite
            id="lfx-configure-signatory-table"
            :is-static-mode="true"
            :is-slot-mode="true"
            :has-checkbox="false"
            :is-loading="false"
            :is-re-search="false"
            :columns="tableColumns"
            :rows="sortedRecords"
          >
            <template v-for="(col, i) of tableColumns" v-slot:[col.field]="data" :key="i">
              {{data.value[col.field]}}
            </template>
          </table-lite> -->
        <!-- </div> -->
      </template>
    </template>
    <template #footer>
      <div class="form-item-action-bar signatory-order-action-bar">
        <LDBButton
          v-for="button in formButtons"
          :key="button.name"
          :label="button.label"
          :action="button.action"
          :actionParams="button.actionParams"
          :params="button.params"
          :type="button.type"
          :tooltip="button.tooltip"
          :modal="button.modal"
          :viewGuards="button.guards"
          :enabledGuards="button.enabledGuards"
          @click="button.clickEvent"
          @on-api-success="onApiSuccess"
        >
          <template v-if="button.modal !== undefined" #modal>
            <div v-html="button.modal.content"></div>
          </template>
        </LDBButton>
      </div>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBField from '@/landobyte_vue/LDBField.vue';
import TableLite from '@/views/general/TableLite.vue';

import LfxSignatoryOrder from '@/views/signatories/LfxSignatoryOrder.vue';

export default {
  name: 'LfxSignatoryConfigure',
  components: { LDBField, TableLite, LfxSignatoryOrder },
  emits: ['on-api-success'],
  props: {},
  data() {
    return {
      mounted: true,
      signatoryOrder: [],
    };
  },
  created() {
    void this.accountRecord.activeParentSignatories; //Ensure it is loaded
    // void this.accountRecord?.configuredSignatories;
  },
  mounted() {
    this.setupInitialSignatoryOrder();
  },
  beforeUnmount() {
    this.signatoryOrder = [];
    this.accountRecord.revert();
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),
    enabledSignatories: {
      cache: false,
      get() {
        let res = [];
        for (let s = 0; s < this.signatoryOrder.length; s++) {
          let order = this.signatoryOrder[s];
          let signatory = this.availableSignatories[order];
          if (signatory.checked || signatory.signerRequired === 'mandatory') {
            res.push(signatory.id);
          }
        }
        return res;
      },
    },
    header() {
      return 'Configure Signatories';
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

        {
          name: 'updateSignatories',
          label: 'Update',
          type: 'primary',
          action: 'editAccount',
          params: {
            accountId: this.accountRecord.id,
          },
          actionParams: {
            bopFormSignatoryOption: this.accountRecord.bopFormSignatoryOption,
            signatoryOrder: this.signatoryOrder,
          },
          clickEvent(response) {
            if (response.status === 'success') {
              self.$router.replace({ name: 'accountSignatoriesList' });
            }
          },
        },
      ];
      return allButtons !== undefined ? allButtons : [];
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : '/';
    },

    // ----- Definition -----

    definition() {
      return getDefinition('client');
    },

    // ----- Record -----
    accountId() {
      return +this.$route.params.accountId;
    },
    accountRecord() {
      let rec = this.$store.state?.account[this.accountId];
      return rec;
    },

    // ----- Fields -----
    fields() {
      return this.definition.fields;
    },
    viewFieldNames() {
      return ['bopFormSignatoryOption'];
    },
    viewFields() {
      const res = [];
      for (const fieldName of this.viewFieldNames) {
        const fields = this.fields.filter((field) => field.field === fieldName);
        if (fields.length > 0) {
          const updatedField = this.overrideField(fields[0]);
          res.push(updatedField);
        }
      }
      return res;
    },
    xsignatoryOrder() {
      return Object.keys(this.availableSignatories);

      // let res = [];
      // for (let s in this.availableSignatories) {
      //   res.push(s);
      // }
      // return res;

      // if (this.accountRecord.bopFormSignatoryOption === 'submittingUser') {
      //   return undefined
      // }
      // const signatoryIds = [];
      // for (const signatory of this.sortedRecords) {
      //   signatoryIds.push(signatory.id)
      // }
      // if (signatoryIds.length === 0) {
      //   return undefined
      // }
      // return signatoryIds
    },
    availableSignatories() {
      return this.accountRecord?.configuredSignatories || {};
    },
    // ----- TABLE -----
    tableColumns() {
      return [
        {
          col: 'firstNameColumn',
          field: 'firstName',
          label: 'First Name',
          isKey: false,
          sortable: false,
        },
        {
          col: 'surnameColumn',
          field: 'surname',
          label: 'Surname',
          isKey: false,
          sortable: false,
        },
        {
          col: 'capacityColumn',
          field: 'capacity',
          label: 'Capacity',
          isKey: false,
          sortable: false,
        },
        {
          col: 'emailAddressColumn',
          field: 'emailAddress',
          label: 'Email Address',
          isKey: false,
          sortable: false,
        },
      ];
    },
    sortedRecords() {
      const signatoriesArray = [];
      for (const signatoryId in this.availableSignatories) {
        signatoriesArray.push(this.availableSignatories[signatoryId]);
      }
      return signatoriesArray;
    },
  },
  watch: {
    availableSignatories(newValue, oldValue) {
      if (newValue) {
        this.setupInitialSignatoryOrder();
      }
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    setupInitialSignatoryOrder() {
      this.signatoryOrder = [];
      if (!this.accountRecord.signatoryOrder) {
        for (let s in this.availableSignatories) {
          let signatory = this.availableSignatories[s];
          this.signatoryOrder.push(signatory.id);
        }
      } else {
        for (let s = 0; s < this.accountRecord.signatoryOrder.length; s++) {
          let id = this.accountRecord.signatoryOrder[s];
          let signatory = this.availableSignatories[id];
          if (signatory) {
            signatory.checked = true;
            this.signatoryOrder.push(id);
          }
        }
        for (let s in this.availableSignatories) {
          let signatory = this.availableSignatories[s];
          if (this.accountRecord.signatoryOrder.indexOf(signatory.id) === -1) {
            signatory.checked = false;
            this.signatoryOrder.push(signatory.id);
          }
        }
      }
    },
    onOrderChange(newOrder) {
      this.signatoryOrder = newOrder;
      this.accountRecord.signatoryOrder = newOrder;
    },
    on_change({ field, value }) {
      this.accountRecord[field] = value;
    },
    overrideField(field) {
      return field;
    },

    // ----- API calls -----
    async onApiSuccess(_responseObject) {},
  },
};
</script>


<style>
.lfx-configure-signatory-table-container {
  position: relative;
  min-height: 200px;
}

#lfx-configure-signatory-table {
  min-width: 100%;
}

.signatory-order-action-bar{
  margin-top:0px;
  padding-top:0px;
  align-items:flex-end;
  display:flex;
  justify-content: flex-end;
  padding-right:0px;
  margin-right: -3px;
  margin-left: 30px;
}

</style>
