<template>
  <LFXContent
    :titleBar="titleBar"
    :show_tabs="showTabs"
    :tabs="tabs"
    :show_stepper="showStepper"
    :show_label="showLabel"
    :label="contentLabel"
    :show_banners="showBanners"
    :show_actions="showActions"
    :form_layout="formLayout"
    :show_sidebar="false"
  >
    <template v-if="true" #banners>
      <LfxBanner
        v-for="banner in banners"
        :key="banner.id"
        :title="banner.title"
        :status="banner.status"
        :closable="banner.closable"
        ><div v-html="banner.content"></div
      ></LfxBanner>
    </template>
    <template #actions>
      <LDBButton
        v-for="button in actionBarButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>

    <template #content>
      <router-view></router-view>
      <RouterView name="beneficiaryPayments" @on-search-change="onSearchChange"></RouterView>
    </template>
  </LFXContent>

  <router-view name="modal"></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import { swiftTextWhiteList } from '@/sharedUtils/LdbValidations';

import LFXContent from '@/components/layout/LfxContent.vue';

export default {
  name: 'LfxBeneficiary',
  components: { LFXContent },
  props: {},
  data() {
    return {
      tableSearchCriteria: {},
    };
  },
  created() {
    void this.accountRecord?.bankAccounts;

    if (this.$route.name === 'beneficiary') {
      this.selectFirstTab();
    }
  },
  mounted() {},
  computed: {
    ...mapGetters(['permissions']),
    ...mapState(['account']),

    // ----- Title Bar -----

    titleBar() {
      const titleBar = {
        icon: this.$route.meta?.icon?.[this.accountLevel],
        // label: `${this.accountLabel}: ${this.accountRecord?.name} / Beneficiary: ${this.beneficiaryName}`,
        label: `${this.accountRecord?.name} / Beneficiary: ${this.beneficiaryName}`,
        buttons: [
          {
            name: 'backToAccount',
            label: `Back to ${this.accountLabel}`,
            type: 'white',
            routeName: 'accountBeneficiariesList',
          },
        ],
      };
      return titleBar;
    },

    // ----- Banners -----

    showBanners() {
      return this.banners?.length > 0 || false;
    },
    banners() {
      let allBanners = {
        all: [
          {
            id: `beneficiaryDetailsIncorrect`,
            title: 'Beneficiary Requires Attention',
            status: 'warning',
            closable: false,
            content: `The Beneficiary details do not conform to the SWIFT standards. ${this.beneficiaryDetailsNonCompliantText}`,
            guards: this.beneficiaryDetailsNonCompliant,
          }, 
        ],
        beneficiaryDetailsView: [],
        beneficiaryBankAccountView: [
          {
            id: `tradingEnabled`,
            title: 'Trading Disabled',
            status: 'warning',
            closable: false,
            content: `The Bank Account details do not conform to the SWIFT standards. ${this.bankAccountDetailsNonCompliantText}`,
            guards: this.bankAccountDetailsNonCompliant
          },
        ]
      };
      const availableBanners = [...(allBanners[this.buttonGroup] || []), ...allBanners.all];
      return availableBanners.filter((banner) => banner.guards);
    },
    // ----- Tabs -----

    showTabs() {
      return true;
    },
    tabs() {
      let tabs = [
        { tabName: 'details', routeName: 'beneficiaryDetailsView', guards: true, label: 'Details' },
        {
          tabName: 'bankAccounts',
          routeName: this.bankAccountsTabRouteName,
          guards: true,
          label: this.bankAccountsTabLabel,
        },
        {
          tabName: 'logs',
          routeName: 'beneficiaryLogsList',
          guards: true,
          label: 'Logs',
        },
        {
          tabName: 'payments',
          routeName: 'beneficiaryPayments',
          guards: true,
          label: 'Beneficiary Payments',
        },
      ];
      return tabs.filter((tab) => tab.guards);
    },
    bankAccountsTabRouteName() {
      return 'beneficiaryBankAccounts'; // TODO
    },
    bankAccountsTabLabel() {
      return 'Bank Accounts'; // TODO
    },

    // ----- Stepper -----

    showStepper() {
      return false;
    },

    // ----- Action Bar -----

    // -------------------------
    // ----- Guards and permissions -----
    // -------------------------

    // ----- Buttons -----

    // Details

    enableBeneficiary_buttonGuard() {
      // @ts-expect-error
      return this.beneficiaryRecord?.status === 'disabled';
    },
    editBeneficiary_buttonGuard() {
      return this.permissions.updateBeneficiary;
    },
    disableBeneficiary_buttonGuard() {
      // @ts-expect-error
      return this.beneficiaryRecord?.status === 'active';
    },

    // Bank Accounts

    addBeneficiaryBankAccount_buttonGuard() {
      // @ts-expect-error
      return this.beneficiaryRecord?.status === 'active' && this.permissions.createBeneficiaryBankAccount;
    },

    // Beneficiary bank accounts

    enableBeneficiaryBankAccount_buttonGuard() {
      // @ts-expect-error
      return this.bankAccountRecord?.status === 'disabled';
    },
    editBeneficiaryBankAccount_buttonGuard() {
      return this.permissions.updateBeneficiaryBankAccount;
    },
    disableBeneficiaryBankAccount_buttonGuard() {
      // @ts-expect-error
      return this.bankAccountRecord?.status === 'active';
    },

    beneficiaryDetailsNonCompliant() {
      // if (this.beneficiaryRecord?.fullName?.length > 35) return true  //SWIFTValidationRemoved
      // if (this.beneficiaryRecord?.address?.length > 35) return true //SWIFTValidationRemoved
      if (!swiftTextWhiteList.test(this.beneficiaryRecord?.fullName)) return true
      if (!swiftTextWhiteList.test(this.beneficiaryRecord?.address)) return true
      return false
    },
    beneficiaryDetailsNonCompliantText() {
      const errorTextArray = [];
      // if (this.beneficiaryRecord?.fullName?.length > 35) errorTextArray.push("<b>Name</b> is more than 35 characters.")  //SWIFTValidationRemoved
      // if (this.beneficiaryRecord?.address?.length > 35) errorTextArray.push("<b>Address</b> is more than 35 characters.")  //SWIFTValidationRemoved
      if (!swiftTextWhiteList.test(this.beneficiaryRecord?.fullName)) errorTextArray.push("<b>Name</b> contains special characters")
      if (!swiftTextWhiteList.test(this.beneficiaryRecord?.address)) errorTextArray.push("<b>Address</b> contains special characters")
      return errorTextArray.join(", ")
    },

    bankAccountDetailsNonCompliant() {
      if (!(new RegExp("^[a-zA-Z0-9]*$")).test(this.bankAccountRecord?.accountNumber)) return true
      return false
    },
    bankAccountDetailsNonCompliantText() {
      const errorTextArray = [];
      if (!(new RegExp("^[a-zA-Z0-9]*$")).test(this.bankAccountRecord?.accountNumber)) errorTextArray.push("<b>Account Number</b> contains special characters.")
      return errorTextArray.join(", ")
    },

    showActions() {
      return this.actionBarButtons.length !== 0 ? true : false;
    },
    actionBarButtons() {
      let self = this;
      let allButtons = {
        //
        // Details

        beneficiaryDetailsView: [
          {
            name: 'enableBeneficiary',
            label: 'Enable Beneficiary',
            type: 'primary',
            modal: {
              header: `Enable Beneficiary: ${this.beneficiaryRecord.name}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Enable',
              content: this.enableBeneficiaryModalMessage,
            },
            action: 'enableBeneficiary',
            params: { accountId: this.beneficiaryRecord.accountId, beneficiaryId: this.beneficiaryRecord.id },
            guards: this.enableBeneficiary_buttonGuard,
            // clickEvent: 'onLDBButtonClick', // TODO remove this - avr
          },
          {
            name: 'editBeneficiary',
            label: 'Edit Beneficiary',
            type: '',
            guards: this.editBeneficiary_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'beneficiaryDetailsEdit' });
            },
          },
          {
            name: 'disableBeneficiary',
            label: 'Disable Beneficiary',
            type: '',
            modal: {
              header: `Disable Beneficiary: ${this.beneficiaryRecord.name}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Disable',
              content: this.disableBeneficiaryModalMessage,
            },
            action: 'disableBeneficiary',
            params: { accountId: this.beneficiaryRecord.accountId, beneficiaryId: this.beneficiaryRecord.id },
            guards: this.disableBeneficiary_buttonGuard,
            // clickEvent: 'onLDBButtonClick', // TODO remove this - avr
          },
        ],
        beneficiaryDetailsEdit: [
          // TODO cancel button for consistency
        ],

        // Bank Accounts

        beneficiaryAddBankAccount: [
          // TODO cancel button for consistency
        ],
        beneficiaryBankAccounts: [],
        beneficiaryBankAccountsList: [
          {
            name: 'addBeneficiaryBankAccount',
            label: 'Add Beneficiary Bank Account',
            type: '',
            guards: this.addBeneficiaryBankAccount_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'beneficiaryAddBankAccount' });
            },
          },
        ],
        beneficiaryBankAccountView: [
          {
            name: 'addBeneficiaryBankAccount',
            label: 'Add Beneficiary Bank Account',
            type: '',
            guards: this.addBeneficiaryBankAccount_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'beneficiaryAddBankAccount' });
            },
          },
          {
            name: 'enableBeneficiaryBankAccount',
            label: 'Enable Bank Account',
            type: 'primary',
            modal: {
              header: `Enable Bank Account: ${this.bankAccountRecord?.name}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Enable',
              content: this.enableBankAccountModalMessage,
            },
            action: 'enableBankAccount',
            params: {
              accountId: this.beneficiaryRecord?.accountId,
              beneficiaryId: this.beneficiaryRecord?.id,
              bankAccountId: this.bankAccountRecord?.id,
            },
            guards: this.enableBeneficiaryBankAccount_buttonGuard,
            // clickEvent: 'onLDBButtonClick', // TODO remove this - avr
          },
          {
            name: 'editBeneficiaryBankAccount',
            label: 'Edit Bank Account',
            type: '',
            guards: this.editBeneficiaryBankAccount_buttonGuard && !!this.bankAccountRecord,
            clickEvent() {
              self.$router.push({ name: 'beneficiaryBankAccountEdit' });
            },
          },
          {
            name: 'disableBeneficiaryBankAccount',
            label: 'Disable Bank Account',
            type: '',
            modal: {
              header: `Disable Bank Account: ${this.bankAccountRecord?.name}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Disable',
              content: this.disableBankAccountModalMessage,
            },
            action: 'disableBankAccount',
            params: {
              accountId: this.beneficiaryRecord?.accountId,
              beneficiaryId: this.beneficiaryRecord?.id,
              bankAccountId: this.bankAccountRecord?.id,
            },
            guards: this.disableBeneficiaryBankAccount_buttonGuard,
            // clickEvent: 'onLDBButtonClick', // TODO remove this - avr
          },
        ],
        beneficiaryBankAccountEdit: [
          // TODO cancel button for consistency
        ],
        beneficiaryLogsList: [],
        beneficiaryPayments: [
          {
            name: 'downloadBeneficiaryPayments',
            label: 'Download',
            type: '',
            guards: true,
            action: 'downloadPayments',
            params: {},
            onSuccess: { doStoreUpdate: false },
            actionParams: { additionalSelector: this.tableSelectors },
          },
        ],
      };
      return allButtons[this.buttonGroup] !== undefined
        ? allButtons[this.buttonGroup] //.filter((button) => button.guards)
        : [];
    },

    buttonGroup() {
      return this.$route.meta?.content?.buttonGroup;
    },

    tableSelectors() {
      return { ...this.tableSearchCriteria }; //TODO - Get this from the table
    },

    // ----- Layout -----

    formLayout() {
      return this.$route.meta?.layout === 'list' ? false : true;
    },

    // ----- Content Label -----

    showLabel() {
      return this.contentLabel ? true : false;
    },
    // contentLabel() {
    //   return this.$route.meta?.content?.label || 'Loading...';
    // },
    contentLabel() {
      return this.$route.meta?.content?.label
        ? `${this.accountLabel} ${this.$route.meta.content.label}`
        : "Navigation didn't complete, please click tab above to continue"; // TODO
    },

    // ----- Account Record -----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },
    accountLevel() {
      return this.accountRecord?.accountLevel;
    },
    accountLabel() {
      if (this.accountLevel === 'bank') {
        return 'Bank';
      } else if (this.accountLevel === 'intermediary') {
        return 'Intermediary';
      } else if (this.accountLevel === 'intermediaryBranch') {
        return 'Branch';
      } else if (this.accountLevel === 'client') {
        return 'Client';
      } else {
        return 'Account';
      }
    },

    // ----- Beneficiary Record -----

    beneficiaryId() {
      return +this.$route.params?.beneficiaryId;
    },
    beneficiaryRecord() {
      return this.accountRecord?.beneficiaries?.[this.beneficiaryId] || {};
    },
    beneficiaryName() {
      return this.beneficiaryRecord?.beneficiaryType === 'entity'
        ? this.beneficiaryRecord?.name
        : `${this.beneficiaryRecord?.individualFirstname} ${this.beneficiaryRecord?.name}`;
    },

    // ----- Beneficiary Bank Accounts -----

    numberOfBankAccounts() {
      return this.beneficiaryRecord?.beneficiary_bankAccounts
        ? Object.keys(this.beneficiaryRecord?.beneficiary_bankAccounts).length
        : 0;
    },

    // ----- Beneficiary Bank Account Record -----

    bankAccountId() {
      return +this.$route.params?.bankAccountId;
    },
    bankAccountRecord() {
      return this.bankAccountId ? this.beneficiaryRecord?.beneficiary_bankAccounts?.[this.bankAccountId] : {};
    },

    // --------------- Modal Message ---------

    enableBeneficiaryModalMessage() {
      return `
      <ol>
        <li>The beneficiary will be enabled.</li>
        <li>The beneficiary will be shown as an option when booking deals or making payments.</li>
      </ol>
      `;
    },
    disableBeneficiaryModalMessage() {
      return `
      <ol>
        <li>The beneficiary will be disabled.</li>
        <li>The beneficiary will no longer be shown as an option when booking deals or making payments.</li>
        <li>The beneficiary can be enabled again.</li>
        <li>Deals and payments that are in progress with this beneficiary will not be affected.</li>
      </ol>
      `;
    },
    enableBankAccountModalMessage() {
      return `
      <ol>
        <li>This bank account of the beneficiary will be enabled.</li>
        <li>The bank account will be shown as an option when booking deals or making payments.</li>
        <li>Enabling the bank account will not affect the status of the beneficiary.</li>
      </ol>
      `;
    },
    disableBankAccountModalMessage() {
      return `
      <ol>
        <li>This bank account of the beneficiary will be disabled.</li>
        <li>The bank account will no longer be shown as an option when booking deals or making payments.</li>
        <li>The bank account can be enabled again.</li>
        <li>Deals and payments that are in progress with this bank account will not be affected.</li>
        <li>Disabling the bank account will not affect the status of the beneficiary.</li>
      </ol>
      `;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Tabs -----

    selectFirstTab() {
      this.$router.replace({ name: this.tabs[0].routeName });
    },

    // ----- Search -----

    onSearchChange(tableSearchCriteria) {
      this.tableSearchCriteria = tableSearchCriteria;
    },

    // -----

    // async getApplication() {
    //   let collection_path = `/client/${this.clientId}/application`;
    //   let options = { return_collection_path: `/account/${this.clientId}/application` };
    //   let id = this.applicationId;
    //   this.$store.dispatch('showLoader', true);
    //   let response = await this.$store.dispatch('db_get_document', { collection_path, id, options });
    //   this.$store.dispatch('showLoader', false);
    // },

    // ----- Stepper -----

    // onStepClick(evt) {
    //   this.activeStepNumber = evt.detail.stepNumber;
    //   const activeStep = this.steps.find((step) => step.index === this.activeStepNumber);
    //   this.$router.push({ name: activeStep.routeName });
    // },
  },
};
</script>
