<template>
  <LDBModal result="info" header="Edit System Settings - Access" size="medium" :closable="false">
    <template #body>
      <div>
        <LDBItemView
        :record="editableRecord"
        :definition="definition"
        view="edit"
        fieldView="editSystemSettingsAccess"
        :showNextButton="false"
        :action="'editSystemSettingsAccess'"
        :params="{ configId: 1 }"
        :unlinkedMode="true"
        providedTitle="Edit System Settings - Access"
        :providedFields="fields"
        :providedGroups="definition.groups"
      />  </div>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import { LfxSystemSettings } from '@/models/LfxSystemSettingsMdl';

export default {
  name: 'LfxSystemSettingsEditAccessConfig',
  components: { LDBItemView },
  props: {},
  emits: [],
  data() {
    return {};
  },
  created() {
  },
  mounted() {},
  computed: {
    fieldNameArray() {
      return [
      'mfaMfaEnabled',
      'mfaMfaType',

      'generalLoginEnabled',
      'generalAccessEnabled',
      // 'generalGeneralSystemMessage', // replaced by updateGeneralSystemMessage
      ]
    },
    fields() {
        const fields = [];
        for (const field in this.definition.fields) {
          if (this.fieldNameArray.includes(this.definition.fields[field].field)) {
            fields.push(this.definition.fields[field]);
          }
        }
        return fields
    },
    editableRecord() {
      const req = {};
      for (const field of this.fieldNameArray) {
        req[field] = this.record[field];
      }
      return req
    },
    record() {
      let objInstance = new LfxSystemSettings(this.systemSettingsRecord, this.$store)
      return objInstance 
    },
    systemSettingsGroups() {
      return ['system','trading','mfa','onboarding','general']
    },
    systemSettingsRecord() {
      const req = {Table:'LfxSystemSettings',collection_path:'systemSettings'};
      for (const settingsGroup of this.systemSettingsGroups) {
        for (const field in this.systemSettingsObject[settingsGroup]) {
          const combinedFieldName = settingsGroup+field.substring(0,1).toUpperCase() + field.substring(1,field.length)
          let fieldValue = this.systemSettingsObject[settingsGroup][field];
          if (['tradingTradingStartTime','tradingTradingEndTime','tradingSameDayCutOffTime'].includes(combinedFieldName)) {
            const date = new Date("1970/01/02");
            date.setUTCHours(fieldValue.h,fieldValue.m,fieldValue.s);
            fieldValue = date;
          }
          req[combinedFieldName] = fieldValue
        }
      }
      return req
    },
    systemSettingsObject() {
      return this.$store.state.systemSettings
    },
    definition() {
      return getDefinition('systemSettings');
    },

  },
  methods: {},
};
</script>
