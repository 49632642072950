//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxDealLogDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Deal record for LandoByte TS systems
//  Created     : 14 August 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition } from '../definitions/LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------

import { LfxDealAction, LfxDealDeliveryType, LfxDealMarkupType } from "./LfxDealDef.js";

//----------------------------------------------------------------------------------------------------------------------

export interface LfxDealLogInt {
  id?: string,
  transactionFileId?: number,
  dealId?: number,
  dealNumber?:string,
  currencyPair?:string,
  action?:LfxDealAction,
  currency?:string,
  counterCurrency?:string,
  amount?:number,
  counterAmount?:number,
  deliveryType?:LfxDealDeliveryType,
  valueDate?:Date,
  markupType?:LfxDealMarkupType,
  bench?:number,
  bankRate?:number,
  clientRate?:number,
  releasedTimestamp?:Date,
};

//----------------------------------------------------------------------------------------------------------------------

export const LfxDealLogDef:LdbDefinition = {
  title:"Deal Logs",
  table:'LfxDealLog',
  collectionPath:"/deal/{{dealId}}/deal_log",
  view:{create:false,edit:false,item:true,list:true,delete:false,csv:false},
  indexes:['dealId'],
  database:"dynamo",
  pagingType:'backEnd',
  fields:{
    id:{
      label:'ID',
      datatype:{'id':{descriptionField:'action',type:'text'}},
      default:'AUTOUUID',
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    transactionFileId:{
      label:'Transaction File',
      datatype:{
        foreignKey: {
          collection:'fk_transaction_files',
          linkTable:'LfxTransactionFile',
          linkField:'id',
          displayField:'reference',
        }
      },
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false},
    },
    dealId:{
      label:'Deal',
      datatype:{
        foreignKey: {
          collection:'fk_deals',
          linkTable:'LfxDeal',
          linkField:'id',
          displayField:'reference',
        }
      },
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false},
    },
    dealNumber:{
      label:'Deal Number',
      datatype:'text',
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    currencyPair:{
      label:'Currency Pair',
      datatype:{
        foreignKey: {
          collection:'fk_currency_pairs',
          linkTable:'LfxCurrencyPair',
          linkField:'id',
          displayField:'id',
          type:'text',
        }
      },
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    action:{
      label:'Status',
      datatype:{option:{optionType:'text',
          options:[
            {id:'Buy',name:'Buy'},
            {id:'Sell',name:'Sell'},
          ]
        }
      },
      default:'requested',
      mandatory:true,
      allowNullValues:false,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    currency:{
      label:'Currency',
      datatype:{
        foreignKey: {
          collection:'fk_currencies',
          linkTable:'LdbCurrency',
          linkField:'id',
          displayField:'id',
          type:'text',
        }
      },
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    counterCurrency:{
      label:'Counter Currency',
      datatype:{
        foreignKey: {
          collection:'fk_currencies',
          linkTable:'LdbCurrency',
          linkField:'id',
          displayField:'id',
          type:'text',
        }
      },
      default:null,
      mandatory:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    amount: {
      label:'Amount',
      datatype:{currency:{symbol:""}},
      default:0,
      mandatory:true,
      allowNullValues:false,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    counterAmount: {
      label:'Counter Amount',
      datatype:{currency:{symbol:""}},
      default:0,
      mandatory:true,
      allowNullValues:false,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    deliveryType:{
      label:'Status',
      datatype:{option:{optionType:'text',
          options:[
            {id:'Cash',name:'Value Today'},
            {id:'Tom',name:'Value Tomorrow'},
            {id:'Spot',name:'Spot'},
            {id:'FWD',name:'Forward'}
          ]
        }
      },
      default:'requested',
      mandatory:true,
      allowNullValues:false,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    valueDate:{
      label:'value Date',
      datatype: {datetime: {
        type: 'date',
        format: 'human'
      }},
      default:null,
      mandatory:true,
      allowNullValues:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    markupType:{
      label:'Markup Type',
      datatype:{option:{optionType:'text',
          options:[
            {id:'fixedRate',name:'Fixed Rate'},
            {id:'fixedSpread',name:'Fixed Spread'}
          ]
        }
      },
      default:'fixedRate',
      mandatory:true,
      allowNullValues:false,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    bench: {
      label:'Bench Rate',
      datatype:"float",
      default:0,
      mandatory:true,
      allowNullValues:false,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    bankRate: {
      label:'Bank Rate',
      datatype:"float",
      default:0,
      mandatory:true,
      allowNullValues:false,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    clientRate: {
      label:'Deal Rate',
      datatype:"float",
      default:0,
      mandatory:true,
      allowNullValues:false,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    },
    releasedTimestamp:{
      label:'Released',
      datatype: {datetime: {
        type: 'datetime',
        format: 'human'
      }},
      default:null,
      mandatory:true,
      allowNullValues:true,
      views:{create:false,edit:false,item:true,list:true,delete:false,csv:false}
    }
  }
}
