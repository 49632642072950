//@ts-nocheck
import VueStore from 'vue-class-store';

import { LfxSignatoryLogInt, LfxSignatoryLogDef } from '@/definitions/LfxSignatoryLogDef';

import { LfxModel, LfxDefinition } from './LfxModel';

@VueStore
class LfxSignatoryLogMdl extends LfxDefinition {
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
export class LfxSignatoryLog extends LfxModel {
  constructor(data, state) {
    super({definition_name:'signatoryLog',...data}, state);    
  }
  get displayFKLink() {
    return false
  }
}

export function Definition(context, name: string) {
  return new LfxSignatoryLogMdl(LfxSignatoryLogDef, LfxSignatoryLog, context, name, {});
}
