<template>
  <LDBModal :result="headerResult" :header="header">
    <template #body>
      <template v-for="(field, fieldname) in viewFields" :key="fieldname">
        <LDBField
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
          @blur="checkForDuplicateIntermediary(field.name)"
        />
        <template v-if="displayDuplicate(field.name)">
          <p v-if="duplicateChecks[field.name]?.length == 1">
            The following Intermediary with the same <strong>{{ getFieldLabel(field.name) }}</strong> already exists:
          </p>
          <p v-else>
            The following Intermediaries with the same <strong>{{ getFieldLabel(field.name) }}</strong> already exist:
          </p>
          <ol>
            <li v-for="(intermediary, index) in duplicateChecks[field.name]" :key="index">
              {{ intermediary.name }} - {{ getOptionLabel('status', intermediary.status)
              }}<em v-if="intermediary.visibility === 'hidden'">
                ( {{ getOptionLabel('visibility', intermediary.visibility) }} )</em
              >
            </li>
          </ol>
        </template>
      </template>

      <template
        v-if="
          hasAnyDuplications &&
          !(newRecord.has_validation_errors || requiredFieldsRemaining) &&
          newRecord.bopFormSignatoryOption
        "
      >
        <p>Are you sure you wish to Add the Intermediary with duplicated information?</p>
        <capitec-toggle
          label="Yes, I'm sure"
          :checked="continueWithDuplicates"
          @value-change="switch_continueWithDuplicates"
        ></capitec-toggle>
      </template>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :enabledGuards="button.enabledGuards"
        :definitionName="button.definitionName"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxAddIntermediary',
  components: { LDBField },
  // props: {},
  data() {
    return {
      newRecord: {},
      duplicateChecks: {
        name: [],
        cifNumber: [],
        bpNumber: [],
        commissionAccount: [],
        organisation: [],
      },
      continueWithDuplicates: false,
    };
  },
  created() {
    this.newRecord = this.definition.class(
      {
        Table: 'LfxAccount',
        ...this.definition.default_values,
        collection_path: '/account',
        accountLevel: 'intermediary',
        definition_name: 'intermediary',
        accountId: 1,
      },
      this.$store
    );
    // this.populateFieldDefaults();
  },
  // mounted() {},
  computed: {
    ...mapGetters(['intermediaries']),
    ...mapState(['account']),

    // ----- Header -----

    header() {
      return 'Add an Intermediary';
    },
    headerResult() {
      if (this.hasAnyDuplications && !this.continueWithDuplicates) {
        return 'warning';
      }
      return 'info'; // 'info' | 'warning' | 'error'
    },
    // initiatingAccountId() {
    //   return 1;
    // },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelAddIntermediary',
          label: 'Cancel',
          type: '',
          action: 'click',
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'addIntermediary',
          label: 'Add Intermediary',
          type: 'primary',
          action: 'createIntermediary',
          actionParams: this.newRecord,
          params: this.newRecord,
          enabledGuards:
            !(
              this.newRecord.has_validation_errors ||
              this.requiredFieldsRemaining ||
              (this.hasAnyDuplications && !this.continueWithDuplicates)
            ) && !!this.newRecord.bopFormSignatoryOption,
          clickEvent(response) {
            if (response.response.id) {
              self.$router.replace({ name: 'accountDetailsView', params: { accountId: response.response.id } });
            }
          },
        },
      ];
      return allButtons;
    },

    // ----- Definition -----

    definition() {
      return getDefinition('intermediary');
    },

    // ----- Fields -----

    fields() {
      return this.newRecord.guarded_fields;
    },
    viewFields() {
      let res = [];
      for (let k in this.fields) {
        let field = this.fields[k];
        if (field.views.addIntermediary === true) {
          field.fieldname = k;
          res.push(field);
        }
      }
      return res;
    },
    fieldnames() {
      return this.viewFields.map((field) => field.name);
    },
    requiredFieldsRemaining() {
      return this.newRecord.required_fields_remaining.filter((f) => this.fieldnames.indexOf(f) >= 0).length > 0;
    },

    hasAnyDuplications() {
      for (const fieldName in this.duplicateChecks) {
        if (this.duplicateChecks[fieldName].length > 0) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Fields -----

    // populateFieldDefaults() {
    //   for (let f = 0; f < this.viewFields.length; f++) {
    //     let field = this.viewFields[f];
    //     if (field.default) {
    //       console.log(field.default);
    //       this.newRecord[field.name] = field.default;
    //     }
    //   }
    // },
    on_change({ field, value }) {
      this.newRecord[field] = value;
      if (this.displayDuplicate(field)) {
        this.checkForDuplicateIntermediary(field);
      }
    },

    switch_continueWithDuplicates() {
      this.continueWithDuplicates = !this.continueWithDuplicates;
    },

    getFieldLabel(fieldName) {
      const fullField = this.viewFields.find((field) => field.field === fieldName);
      return fullField.label;
    },
    getOptionLabel(fieldName, selectedOption) {
      const fullField = this.newRecord?.definition?.fields?.find((field) => field.field === fieldName);
      const option = fullField?.datatype?.option?.options?.find((option) => option.id === selectedOption);
      return option?.name || selectedOption;
    },

    // ----- API calls -----

    // async addIntermediary() {
    //   [this.newRecord].id = this.initiatingAccountId;
    //   let response = await this.account[this.initiatingAccountId].addIntermediary(this.newRecord);
    //   if (response?.id) {
    //     this.$router.replace({ name: 'accountDetailsView', params: { accountId: response.id } });
    //   }
    // },

    // ----- Validations -----

    displayDuplicate(fieldName) {
      return this.duplicateChecks[fieldName]?.length > 0;
    },

    checkForDuplicateIntermediary(fieldName) {
      this.duplicateChecks[fieldName] = [];

      if (Object.keys(this.duplicateChecks).includes(fieldName)) {
        for (const accountId in this.intermediaries) {
          const intermediary = this.intermediaries[accountId];
          if (this.newRecord[fieldName] === intermediary[fieldName]) {
            this.duplicateChecks[fieldName].push({
              name: intermediary.name,
              status: intermediary.status,
              visibility: intermediary.visibility,
            });
          }
        }
      }
    },
  },
};
</script>
