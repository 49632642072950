<template>
  <section id="login-content">
    <capitec-toast v-if="showToast" :type="toastType" :header="toastHeader" :detail="toastMessage" closable style="position:fixed; top:10px; width: 500px; left:calc(50% - 250px)" @click="hideToast"></capitec-toast>
    <capitec-page-header class="login-header" label="Forex"> </capitec-page-header>
    <capitec-card class="login-form">
      <capitec-title-bar :label="`Login`"></capitec-title-bar>
      <capitec-spacer></capitec-spacer>
      <capitec-text-field
      v-if="showUsernameField"
        label="Username"
        @key-input="onUsernameChange"
        @value-change="onPasswordChange"
        :value="_username"
      ></capitec-text-field>
       <capitec-spacer></capitec-spacer>
       <capitec-text-field
       v-if="showConfirmationCodeField"
        label="Confirmation Code"
        @key-input="onConfirmationCodeChange"
        @value-change="onConfirmationCodeChange"
        :value="_confirmationCode"
      ></capitec-text-field>
      <capitec-spacer v-if="showConfirmationCodeField"></capitec-spacer>
      
<capitec-text-field 
v-if="showOtpField"
label="OTP"
@key-input="onOtpChange"
@value-change="onOtpChange"
:value="otp"
></capitec-text-field>
<capitec-spacer v-if="showOtpField"></capitec-spacer>

      <capitec-text-field
       v-if="showPasswordField"
       :label="`password: ${passwordFieldLabel}`"
        @key-input="onPasswordChange"
        @value-change="onPasswordChange"
        @keypress.enter="doSubmitLogin"
        :value="_password"
        type="password"
      ></capitec-text-field>
      
      <capitec-spacer v-if="showPasswordField"></capitec-spacer>
      <capitec-text-field
        v-if="isFirstLogin && !forgotPassword"
        label="New Password"
        @key-input="onNewPasswordChange"
        @value-change="onNewPasswordChange"
        @keypress.enter="doSubmitLogin"
        :value="_new_password"
        type="password"
      >
      </capitec-text-field>
      
      
      <capitec-spacer></capitec-spacer>
      
      <capitec-button-bar>
        <capitec-button v-if="showBackButton" label="Back" type="" @click="goBack" />
        
        <capitec-button v-if="!forgotPassword" label="Forgot Password"  type="" @click="doForgotPassword" />
        
        <capitec-button v-if="showLoginButton" label="Login" type="primary" :disabled="!loginButtonEnabled" @click="doSubmitLogin" />
        
        <capitec-button v-if="showOtpField" label="Login" type="primary" :disabled="!otpButtonEnabled" @click="doSubmitOtp" />
        
        <capitec-button v-if="showSetFirstPasswordButton" label="Reset Password" type="primary" :disabled="!loginButtonEnabled" @click="doSubmitFirstLogin" />
        
        <capitec-button v-if="showSendOTPButton" label="Send Reset Password Token" :disabled="!sendResetPasswordButtonEnabled" type="primary" @click="submitForgotPassword" />
        
        <capitec-button v-if="showSubmitConfirmResetPasswordButton" label="Reset Password" :disabled="!resetPasswordButtonEnabled" type="primary" @click="submitConfirmForgotPassword" />
        
      </capitec-button-bar>
    </capitec-card>
  </section>
  
  <!-- <div class="login">
    <h1>Login</h1>
  </div>

  <form @submit.prevent="submitLogin">
    <div>
      <label for="username">Username: </label>
      <input id="username" type="text" v-model="username" />
    </div>
    <div>
      <label for="password">Password: </label>
      <input id="password" type="password" v-model="password" />
    </div>
    <div>
      <button>Login</button>
    </div>
  </form> -->
  <!-- <p>{{ authMessage }}</p> -->
</template>


<script>

import {mapState,mapActions} from 'vuex';
import {LfxAuthFirstLogin} from '@/store/lfx_auth';

export default {
  data() {
    return {
      // authMessage: "",
      otp:'',
      otpSent:false,
      otpToken:'',
      forgotPassword:false,
      confirmForgotPassword:false,
      toastMessage:'',
      toastHeader:'',
      toastType:'',
      session:'',
      showToast:false,
      username: '',
      password: '',
      new_password:'',
      confirmationCode:''
    };
  },
  mounted() {
  },
  computed: {
    ...mapState(['isFirstLogin']),
    // routeName() {
    //   return this.$route.name;
    // },
    showOtpField() {
      return this.otpSent; 
    },
    showUsernameField() {
      return !this.showConfirmationCodeField;
    },
    showBackButton() {
      return this.showSendOTPButton || this.showSubmitConfirmResetPasswordButton;
    },
    showPasswordField() {
      return (!this.forgotPassword ) && (!this.isFirstLogin) || this.showConfirmationCodeField;
    },
    showResetPasswordField() {
      return (!this.forgotPassword && this.isFirstLogin) || this.showConfirmationCodeField;
    },
    showConfirmationCodeField() {
      return (this.forgotPassword && this.confirmForgotPassword);
    },
    showLoginButton() {
      return (!this.forgotPassword && !this.isFirstLogin);
    },
    showSendOTPButton() {
      return (this.forgotPassword && !this.confirmForgotPassword);
    },
    showSubmitConfirmResetPasswordButton() {
      return (this.forgotPassword && this.confirmForgotPassword);
    },
    showSetFirstPasswordButton() {
      return this.isFirstLogin;
    },
    loginButtonEnabled() {
      return ((this.username.length > 2) && (this.password.length > 5));
    },
    otpButtonEnabled() {
      return this.showOtpField && this.otp.length > 4;
    },
    sendResetPasswordButtonEnabled() {
      return this.username.length > 5;
    },
    resetPasswordButtonEnabled() {
      return this.confirmationCode.length > 5 && this.password.length > 5 ;
    },
    passwordFieldLabel() {
      if (this.isFirstLogin) {
        return 'Old Password'
      } else 
      if (this.confirmForgotPassword) {
        return 'New Password' 
      } else 
      {
        return 'Password'
      }
      // return this.isFirstLogin ? 'Old Password' : 'Password';
    },
    _username() {
      return this.username;
    },
    _password() {
      return this.password;
    },
    auth() {
      return this.$store.getters['auth'];
    },
    firstname() {
      return this.auth?.idToken?.payload?.given_name;
    },
    myHomePath() {
      // TODO: get from user settings
      return '/dashboards';
    },
  },
  methods: {
    ...mapActions([
      'lfx_auth_confirm_forgot_password',
      'lfx_auth_complete_first_login',
      'lfx_auth_forgot_password',
      'showLoader',
      'submitLogin'
    ]),
    hideToast() {
      this.showToast = false;
    },
    showLoginFailedToast(type,header,error) {
      this.showToast = true;
      this.toastType    = type;
      this.toastMessage = error;
      this.toastHeader = header;
      setTimeout(this.hideToast,3000);
      // this.showToast = true;
    },
    goBack() {
      if ( this.confirmForgotPassword )  {
        this.confirmForgotPassword = false 
      } else 
      if (this.forgotPassword ) {
        this.forgotPassword = false
      }
    },
    onOtpChange(event) {
      this.otp = event.detail.new;
    },
    onNewPasswordChange(event) {
      this.new_password = event.detail.new;
    },
    onUsernameChange(event) {
      this.username = event.detail.new;
    },
    onPasswordChange(event) {
      this.password = event.detail.new;
    },
    onConfirmationCodeChange(event) {
      this.confirmationCode = event.detail.new;
    },
    doForgotPassword() {
      this.password = '';
    },
    async doSubmitLogin() {
      const formData = {
        username: this.username,
        password: this.password,
      };
      let loginResponse = await this.submitLogin(formData);
      // this.$router.push({ name: 'Home' });
      if ( loginResponse.status === 'failed' ) {
        this.showLoginFailedToast('error','Login Failed',loginResponse.response.errorMessage);
      }
      else 
      if (loginResponse.status === 'passwordResetRequired') {
        this.session = loginResponse.session;
        this.confirmForgotPassword = true;
        this.password = '';
        
      } else 
      if (loginResponse.status === 'otpSent') {
        this.session = loginResponse.session;
        this.otpSent = true;
        this.otpToken = loginResponse.otpToken;
        this.password = '';
      } else 
      if (loginResponse.status === 'success') {
        this.loginError = '';        
        this.$router.push({ path: this.myHomePath });
      }
    },
    async doSubmitOtp() {
      let payload = {otp:this.otp,otpToken:this.otpToken};
    },
    async doSubmitFirstLogin() {
      this.showLoader(true);
      let payload = {username:this.username,newPassword:this.new_password,session:this.session};
      let setFirstPasswordResponse = await this.lfx_auth_complete_first_login(payload);
      if (setFirstPasswordResponse.response.loginResult === 'success') {
        let response = setFirstPasswordResponse.response;
        LfxAuthFirstLogin(this.$store,this.username,response);
        this.$router.push({ path: '/' });
      }
      this.showLoader(false);
      
    },
    async submitForgotPassword() {
      let forgotPasswordResult = await this.lfx_auth_forgot_password({username:this.username});
      this.confirmForgotPassword = true;
      this.password = '';
    },
    async submitConfirmForgotPassword() {
      let confirmForgotPasswordResult = await this.lfx_auth_confirm_forgot_password({username:this.username,password:this.password,confirmationCode:this.confirmationCode});
      this.forgotPassword = false;
      this.confirmForgotPassword = false;
      this.password = '';
    },
    goToForgotPassword() {
      this.forgotPassword = true;
    },
  },
  // created() {
  //   this.authMessage = this.$route.params.authMessage;
  // },
};

</script>


<style>

section#login-content {
  display: grid;
  grid-template-areas:
    'header header header'
    '. . .'
    '. form .';
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 70px 24px auto;
}

.login-form {
  max-width: 1000px;
  min-width: 500px;
  align-self: center;
  justify-self: center;
  grid-area: form;
}

.login-form > capitec-text-field {
  margin-left: 16px;
  margin-right: 16px;
}


.login-header {
  grid-area: header;
  align-self: stretch;
  justify-self: stretch;
}


</style>
