<template>
  <LDBModal type="result" result="info" :header="header">
    <template #body>
    <LDBField
      v-for="(field, fieldname) in allFields"
      :key="fieldname"
      :field="field"
      view="edit"
      :fieldname="field.name"
      :definition="definition"
      :record="record"
      @change="on_change"
    />
    <br/>
    <capitec-file-upload v-if="record.fileSource === 'upload'" :label="uploadButtonName" @selection-change="onSelectionChange" :disabled="uploadDisabled" :type="uploadButtonType" :fileTypes="fileTypes" hint="Jannie">
    
    </capitec-file-upload>
    </template>
    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :enabledGuards="button.enabledGuards"
        @on-api-success="button.onApiSuccess"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>


<script>
import { getDefinition } from '@/db-interface';

import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import LDBButton from '@/landobyte_vue/LDBButton.vue';

export default {
  name: 'LfxImportBatchUpload',
  components: { LDBItemView,LDBButton },
  data() {
    return {
      files:null,
      record:{}
    }
  },
  computed: {
    header() {
      return "Data Import Upload"
    },
    uploadDisabled() {
      return !this.record.importType
    },
    uploadButtonType() {
      if (this.uploadDisabled) {
        return 'disabled'
      } else {
        return ''
      }
    },
    uploadButtonName() {
      if (!this.record.importType) {
        return "Select Import Type"        
      } else if (this.record.importType === 'combined') {
        return "Select Zip File"       
      } else if (this.record.importType === 'uploadOnly') {
        return "Select Zip or CSV File"
      } else {
        return "Select CSV File"
      }
    },
    fileTypes() {
      if (!this.record.importType) {
        return "text/csv"        
      } else if (this.record.importType === 'combined') {
        return "application/zip"        
      } else if (this.record.importType === 'uploadOnly') {
        return "application/zip, text/csv"
      } else {
        return "text/csv"
      }
    }, 
    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          action: 'click',
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'uploadImportBatchFile',
          label: 'Submit',
          type: 'primary',
          action: 'uploadImportBatch',
          params: {},
          enabledGuards: this.fileSourceIsCaptured,
          onApiSuccess(response) {
            const batchId = response.response.id;
            self.$router.push({
                name: 'adminDataImportBatchDetails',
                params: { importBatchId: batchId },
              })
          },
          actionParams: {
            fileName:this.files,
            importType:this.record.importType,
            fileSource:this.record.fileSource,
            s3Bucket:this.record.s3Bucket,
            s3Key:this.record.s3Key
          },
          clickEvent(response) {
  
          }
        },
      ];
      return allButtons;      
    },
    fileSourceIsCaptured() {
      if (this.record.fileSource === 'upload') {
        return !!this.files
      } else if (this.record.fileSource) {
        return !!this.record.s3Bucket && !!this.record.s3Key
      }
      return false
    },
    definition() {
      return getDefinition('importBatch');
    },
    fields() {
      return this.definition.fields;
    },

    viewFieldNames() {
      return ['importType'];
    },
    viewFields() {
      const res = [];
      for (const fieldName of this.viewFieldNames) {
        const fields = this.fields.filter(field => field.field === fieldName);
        res.push(fields[0]);
      }
      return res;
    },
    allFields() {
      const res = []
      for (const field of this.viewFields) {
        res.push(field)
      };
      res.push(
        {
          name: 'fileSource',
          label: 'File Source',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'upload', name: 'Upload'},
                { id: 's3', name: 'S3'}
              ],
            },
          },
        },
      )
      if (this.record.fileSource === 's3') {
        res.push(
          {
            name: 's3Bucket',
            label: 'S3 Bucket',
            datatype: 'text'
          }),
        res.push(
          {
            name: 's3Key',
            label: 'S3 Key',
            datatype: 'text'
          },
        )
      }
      return res
    }

    
  },
  methods: {
    onSelectionChange(evt) {
      this.files = evt.detail.files[0];
    },
    on_change({ field, value }) {
      this.record[field] = value;
    },

  },
};
</script>
