// Copy of key functions from removed south-africa-regex npm modules
export const isValidIdNumber = (idNumber:string) => {
    idNumber = idNumber.trim();
    const numbersRegex = /^\d+$/;
    const isNumbers = numbersRegex.test(idNumber);
    if (!isNumbers) {
        return { error: true, msg: "Must only contain numbers" };
    }
    if (idNumber.length !== 13) {
        return { error: true, msg: "Must be 13 digits" };
    }
    var dateString:string;
    if (+idNumber.substring(0,2) > +(new Date()).toISOString().substring(2,4)) {
      dateString = "19"
    } else {
      dateString = "20"
    }
    dateString = dateString + idNumber.substring(0,2) + "-" + idNumber.substring(2,4) + "-" + idNumber.substring(4,6);
    const date = new Date(dateString);
    if ((!(date instanceof Date) || date.toString() === "Invalid Date" || date.toISOString().substring(5,7) !== idNumber.substring(2,4)) ) {
        return { error: true, msg: "First 6 digits must be valid date" };
    }
    // check sum calculation
    const isValidId = LuhnAlgorithm(idNumber);
    if (isValidId) {
        return {
            error: false,
            msg: "",
        };
    }
    return {
        error: true,
        msg: "Not a valid ID",
    };
};
export function isValidTaxNumber(taxNumber:string) {
    taxNumber = taxNumber.trim();
    const numbersRegex = /^\d+$/;
    const isNumbers = numbersRegex.test(taxNumber);
    if (!isNumbers) {
        return { error: true, msg: "Must only contain numbers" };
    }
    if (taxNumber.length !== 10) {
        return { error: true, msg: "Must be 10 digits long" };
    }
    const firstDigit = taxNumber.slice(0, 1);
    if (!["0", "1", "2", "3", "9"].includes(firstDigit)) {
        return { error: true, msg: "Must begin with one of 0, 1, 2, 3, 9" };
    }
    const isValid = LuhnAlgorithm(taxNumber);
    if (isValid) {
        return {
            error: false,
            msg: "",
        };
    }
    return {
        error: true,
        msg: "Not a valid tax number",
    };
}

export function isValidVatNumber(vatNumber:string) {
    vatNumber = vatNumber.trim();
    const numbersRegex = /^\d+$/;
    const isNumbers = numbersRegex.test(vatNumber);
    if (!isNumbers) {
        return { error: true, msg: "Must only contain numbers" };
    }
    const startWithReg = /^[4]/;
    const startsWithFour = startWithReg.test(vatNumber);
    if (!startsWithFour) {
        return { error: true, msg: "Must start with a 4" };
    }
    if (vatNumber.length !== 10) {
        return { error: true, msg: "Must be 10 digits long" };
    }
    const isValid = LuhnAlgorithm(vatNumber);
    if (isValid) {
        return {
            error: false,
            msg: "",
        };
    }
    return { error: true, msg: "Not a valid VAT number" };
}

const LuhnAlgorithm = (inputNumber:string) => {
    inputNumber = inputNumber.trim();
    const arr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
    let len = inputNumber.length;
    let bit = 1;
    let sum = 0;
    let val;
    while (len) {
        val = parseInt(inputNumber.charAt(--len), 10);
        bit = bit ^ 1;
        sum += bit === 1 ? arr[val] : val;
    }
    return sum && sum % 10 === 0;
};