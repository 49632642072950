<template>
  <LFXListView v-if = "dataLoaded" :definition="definition" :records="records" />
   <LDBLoader v-else/>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXListView from '@/views/general/LFXListView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxBulkFeesList',
  components: { LFXListView,LDBLoader },
  props: {},
  data() {
    return {
      dataLoaded:false,
    };
  },
  async created() {
    await doPermissionAction({},'getAccountBulkFeeBatches',{ accountId: this.viewedAccountId },{additionalSelector:{where:{accountId:this.viewedAccountId}}})
    this.dataLoaded = true;
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),
    viewedAccountId() {
      return +this.$route.params.accountId;
    },
    records() {
      let res = {};
      for (let b in this.account[this.viewedAccountId].bulkFeeBatch) {
        let batch = this.account[this.viewedAccountId].bulkFeeBatch[b];
        res[batch.id] = batch;
      }
      return res;
    },

    definition() {
      return getDefinition('bulkFeeBatch');
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
