<template>
  <LFXPagedListView  
    :definition="definition" 
    action="getFailedTreasuryDeals" 
    :actionParams="{}"
    :search="searchConfig" 
    :defaultSort="defaultSort" />
  <router-view></router-view>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXPagedListView from '@/views/general/LFXPagedListView.vue';

export default {
  emits:['on-search-change'],
  name: 'LfxFailedTreasuryDealsList',
  components: { LFXPagedListView },
  props: {},
 data() {
    return {
      searchConfig:{
        searchFields:['reference','dealNumber','correlationId','clientCif','thirdPartyBp','fromSettlementAccount','toSettlementAccount' ],
        choices:[
          
        ],
      }      
    }
  },
   async created() {
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),
    defaultSort() {
      return { order: 'id', sort: 'desc' }
    },
    definition() {
      return getDefinition('failedTreasuryDeal');
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>

<style>
</style>
