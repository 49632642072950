<template>
  <div>
    <p>Select User Team for User:</p>
    <LDBField
      v-for="(field, fieldname) in viewFields"
      :key="fieldname"
      :field="field"
      view="edit"
      :fieldname="field.name"
      :definition="definition"
      :record="record"
      @change="on_change"
    />
    <capitec-action-bar>
      <LDBButton
        v-for="button in leftButtons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        :action="button.action"
        @click="button.clickEvent"
      ></LDBButton>
      <capitec-spacer />
      <LDBButton
        v-for="button in rightButtons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      ></LDBButton>
    </capitec-action-bar>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxAssignUserTeam',
  components: { LDBField },
  emits: ['on-api-success','step-back', 'step-next'],
  props: {},
  data() {
    return {
      mounted: true,
    };
  },
  created() {},
  mounted() {},
  beforeUnmount() {
    this.record.revert();
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['user','account']),
    header() {
      return `Assign User Team for User: ${this.userDisplayName}`;
    },

    // ----- Buttons -----
    leftButtons() {
      let self = this;
      return [
        {
          name: 'previousStep',
          label: 'Back',
          type: 'clear',
          guards: true,
          clickEvent() {
            self.onBackClick();
          },
        }
      ]
    },
    rightButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true, //TODO
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'editBankLogin',
          label: 'Save',
          type: 'primary',
          action: 'updateUserUserTeam',
          params: { userId: this.record.id },
          actionParams: this.record,
          guards: true,
        },
        {
          name: 'next',
          label: 'Next',
          type: 'primary',
          action: 'updateUserUserTeam',
          params: { userId: this.record.id },
          actionParams: this.record,
          clickEvent(response) {
            if (response.status === 'success') {
              self.$emit('step-next');
            }
          },
        }
      ];
      return allButtons;
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : '/';
    },

    onBackClick() {
      this.$emit('step-back');
    },
    // ----- Definition -----

    definition() {
      return getDefinition('user');
    },

    // ----- Record -----

    recordName() {
      return 'user';
    },
    recordId() {
      return +this.$route.params.userId;
    },
    record() {
      let rec = this.$store.state?.[this.recordName]?.[this.recordId];
      return rec;
    },

    userDisplayName() {
      return this.record?.userDisplayName;
    },

    // ----- Fields -----

    fields() {
      return this.definition.fields;
    },
    view() {
      return 'editBankLogin';
    },
    viewFields() {
      return [
          {
          label: 'User Team',
          name:'userTeamId',
          field:'userTeamId',
          fieldname:'userTeamId',
          datatype: {
            foreignKey: {
              linkTable: 'LfxUserTeam',
              linkField: 'id',
              collection: 'fk_userTeam',
              displayField: 'description',
              guards: {status:{eq:'active'}}
            },
          },
          group: 'system',
        },
      ];
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Fields -----

    on_change({ field, value }) {
      this.record[field] = value;
    },

    // ----- API calls -----

    async onApiSuccess(_responseObject) {
    },
  },
};
</script>

<style scoped>
p {
  margin: 20px;
}
</style>
