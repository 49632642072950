<template>
  <div class="lfx-widget-container rates-feed" :style="spanStyle" :class="dynamicClass">
    <LfxWidgetHeader :header="`Exchange Rates`" :subheader="''" />
    <LfxRateRow v-for="rate in pagedItems" :key="rate.ric" :rate="rate" />
    <LfxWidgetScroller
      :totalItems="items.length"
      :itemsPerPage="itemsPerPage"
      :currentPage="pageNumber"
      @on-page="onPage"
    />
  </div>
</template>

<script>
import LfxWidgetHeader from './LfxWidgetHeader.vue';
import LfxWidgetRow from './LfxWidgetRow.vue';
import LfxWidgetScroller from './LfxWidgetScroller.vue';
import LfxRateRow from './LfxRateRow.vue';

import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import lfx_widget_mixin from './lfx_widget_mixin';

export default {
  mixins: [lfx_widget_mixin],
  components: {
    LfxWidgetHeader,
    LfxWidgetRow,
    LfxRateRow,
    LfxWidgetScroller,
  },
  props: {
    widget: { type: Object, required: true },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  watch() {},
  computed: {
    ...mapGetters(['sortedAllowedToViewRics']),
    ...mapState(['rates', 'authUser']),

    // sortedRates() {
    //   return Object.values(this.rates).sort((a, b) => {
    //     let sortResA = this.ratesFeedOrderIds.indexOf(a.ric);
    //     let sortResB = this.ratesFeedOrderIds.indexOf(b.ric);

    //     return sortResA === -1 ? 100 : sortResA > sortResB ? 1 : -1;
    //   });
    // },

    // sortedAllowedToViewRates() {
    //   const sortedAllowedToViewRates = [];
    //   for (const ricId of this.sortedAllowedToViewRics) {
    //     if (this.rates?.[ricId]) {
    //       sortedAllowedToViewRates.push(this.rates[ricId]);
    //     }
    //   }
    //   return sortedAllowedToViewRates;
    // },

    // defaultRatesFeedOrder() {
    //   return Object.keys(this.rates);
    // },
    // ratesFeedOrderIds() {
    //   return this.authUser?.settings?.ratesFeedOrder ? this.authUser.settings.ratesFeedOrder : [];
    // },
    items() {
      const sortedAllowedToViewRates = [];
      for (const ricId of this.sortedAllowedToViewRics) {
        if (this.rates?.[ricId]) {
          sortedAllowedToViewRates.push(this.rates[ricId]);
        }
      }
      return sortedAllowedToViewRates;
      // return this.sortedAllowedToViewRates;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>

<style>
div.lfx-widget-container.rates-feed {
  min-height: 280px;
}
</style>
