import VueStore from 'vue-class-store';

import { LfxAccountMarkupInt, LfxAccountMarkupDef } from '@/definitions/LfxAccountMarkupDef';

import { LfxModel, LfxDefinition } from './LfxModel';
import { calculateMarkedUpRate, calculateMarkupFromBankAndPercentage } from '@/sharedUtils/LfxTradeCalc'

import store from '@/store';
import { roundTo } from '@/sharedUtils/LdbSharedUtils';

@VueStore
// @ts-ignore
class LfxAccountMarkupMdl extends LfxDefinition {
  // @ts-ignore
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
// @ts-ignore
export class LfxAccountMarkup extends LfxModel {
  // @ts-ignore
  constructor(data, state) {
    super({ definition_name: 'account_markup', ...data }, state);
  }

  get link() {
    // @ts-ignore
    return `/account/${this.accountId}/markup/${this.id}`;
  }

  // ----------------------------------
  // ----- Guards and Permissions -----
  // ----------------------------------

  // ----- General -----

  get notMyAccount() {
    // @ts-ignore
    return this.store.state.authUser.accountId !== this.accountId;
  }

  // ----- Buttons -----

  get accountMarkupUnhide_buttonGuard() {
    // @ts-ignore
    return this.notMyAccount && this.status === 'hidden' && this.hasPermission.showAccountMarkup;
  }
  get accountMarkupEdit_buttonGuard() {
    // @ts-ignore
    return this.notMyAccount && this.status === 'visible' && this.hasPermission.editAccountMarkups && this.currencyPairObject?.parityPair !== true;
  }
  get accountMarkupHide_buttonGuard() {
    // @ts-ignore
    return this.notMyAccount && this.status === 'visible' && this.hasPermission.hideAccountMarkup;
  }

  // ----------------------------------
  // ----- API calls -----
  // ----------------------------------

  async hideMarkup() {
    // @ts-ignore
    this.store.dispatch('showLoader', true);
    let payload = {};
    // @ts-ignore
    let options = { update_state: true, return_collection_path: `/account/${this.accountId}/markup` };
    // @ts-ignore
    let response = await this.store.dispatch('db_put_action', {
      //@ts-ignore
      path: `/account/${this.accountId}/markup/${this.id}/hide`,
      payload,
      options,
    });
    //@ts-ignore
    this.store.dispatch('showLoader', false);
    return response;
  }

  async unhideMarkup() {
    // @ts-ignore
    this.store.dispatch('showLoader', true);
    let payload = {};
    // @ts-ignore
    let options = { update_state: true, return_collection_path: `/account/${this.accountId}/markup` };
    // @ts-ignore
    let response = await this.store.dispatch('db_put_action', {
      //@ts-ignore
      path: `/account/${this.accountId}/markup/${this.id}/show`,
      payload,
      options,
    });
    //@ts-ignore
    this.store.dispatch('showLoader', false);
    return response;
  }
  get intermediaryId() {
    //@ts-expect-error
    return this.store.state.account[this.accountId].intermediaryId
  }
  get currencyPairObject() {
    //@ts-expect-error
    return this.store.state.config[1].currencyPair[this.currencyPair]
  }
  get currencyPairName() {
    return this.currencyPairObject.name
  }
  get currencyPairIndex() {
    return this.currencyPairObject.index
  }
  get totalMarkup() {
    if (this.calculatedMarkup !== undefined && this.totalParentMarkup !== undefined ) {
      return roundTo(this.calculatedMarkup + this.totalParentMarkup,2)
    }
    return "Loading..."
  }
  get totalParentMarkup() {
    //@ts-expect-error
    if (this.accountId === this.intermediaryId) {
      return 0
    }
    //@ts-expect-error
    const parentMarkupStatus = this.store.state.account[this.accountId].parentMarkupStatus
    //@ts-expect-error
    const parentMarkups = this.store.state.account[this.accountId]?.parentMarkups?.[this.currencyPair] || []
    if (parentMarkupStatus !== 'retrieved') {
      return undefined
    }
    let bankMarkup = 0;
    let totalInterMarkup = 0;
    //@ts-expect-error
    let bestDiscountedBankMarkup = this.discountedBankMarkup;
    for (const parentMarkup of parentMarkups) {
      //@ts-expect-error
      if (parentMarkup.id !== this.id) {
        if (parentMarkup.calculatedMarkup === undefined) {
          return undefined
        }
        if (parentMarkup.accountId === this.intermediaryId) {
          bankMarkup += parentMarkup.calculatedMarkup; // TODO, check for discounts
        } else {
          totalInterMarkup += parentMarkup.calculatedMarkup;
        }
        if (parentMarkup.discountedBankMarkup) {
          if (!bestDiscountedBankMarkup || parentMarkup.discountedBankMarkup < bestDiscountedBankMarkup) {
            bestDiscountedBankMarkup = parentMarkup.discountedBankMarkup
          }
        }
      }
    }
    if (bestDiscountedBankMarkup && bestDiscountedBankMarkup < bankMarkup) {
      bankMarkup = +bestDiscountedBankMarkup
    }
    return +bankMarkup + +totalInterMarkup
  }
  get calculatedMarkup() {
    //@ts-expect-error
    if (this.accountId === this.intermediaryId && this.discountedBankMarkup) {
      //@ts-expect-error
      return this.discountedBankMarkup      
    }

    //@ts-expect-error
    if (this.markupType === 'pips') {
      //@ts-expect-error
      return +this.pips
    } else {
      //@ts-expect-error
      const bidSpot = this.store.state.rates?.[this.currencyPairObject.instrument]?.bid
      if (!bidSpot) {
        return undefined
      }
      //@ts-expect-error
      return calculateMarkupFromBankAndPercentage(bidSpot,+this.percentage,'quote','Sell')
    }
  }
  get bidRate() {
    return this.getMarkedUpRate('bid')
  }
  get askRate() {
    return this.getMarkedUpRate('ask')
  }
  getMarkedUpRate(type:'bid'|'ask') {
    if (this.totalMarkup === undefined || this.totalMarkup === 'Loading...') {
      return 'Loading'
    }
    //@ts-expect-error
    const spotRate = this.store.state.rates?.[this.currencyPairObject.instrument]?.[type]
    if (!spotRate) {
      return "Loading..."
    }

    const currencyType = 'quote';
    const dealAction = type === 'ask' ? 'Buy' : 'Sell';
    const markedUpRate = calculateMarkedUpRate(spotRate,this.totalMarkup,currencyType,dealAction)
    return markedUpRate
  }
  // async hideAccount() {
  //   // @ts-ignore
  //   this.store.dispatch('showLoader', true);
  //   let payload = {};
  //   // @ts-ignore
  //   let options = { update_state: true, return_collection_path: `/account/` };
  //   // @ts-ignore
  //   let response = await this.store.dispatch('db_put_action', {
  //     //@ts-ignore
  //     path: `/account/${this.id}/hide`,
  //     payload,
  //     options,
  //   });
  //   //@ts-ignore
  //   this.store.dispatch('showLoader', false);
  //   return response;
  // }
}

// @ts-ignore
export function Definition(context, name: string) {
  return new LfxAccountMarkupMdl(LfxAccountMarkupDef, LfxAccountMarkup, context, name, {});
}
