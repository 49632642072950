<template>
  <LDBItemView :record="applicationRecord" :view="view" />
  <router-view></router-view>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

export default {
  name: 'LfxClientApplicationDetails',
  components: { LDBItemView, LDBLoader },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    view() {
      return this.$route.meta?.content?.view || 'item';
    },

    // ----- Client Record -----

    clientId() {
      return +this.$route.params.accountId;
    },
    clientRecord() {
      return this.account[this.clientId];
    },

    // ----- Application Record -----

    applicationId() {
      return +this.$route.params.applicationId;
    },
    applicationRecord() {
      return this.clientRecord?.application?.[this.applicationId];
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
