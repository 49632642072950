//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LdbPdf.ts
//  Author      : Bevan Timm
//  Description : This file contains all pdf code for use in the front end in LandoByte TS Systems
//  Created     : 11 May 2022 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------
//https://www.npmjs.com/package/pdfmake
//https://pdfmake.github.io/docs/0.1/getting-started/

import { TDocumentDefinitions } from 'pdfmake/interfaces';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from 'pdfmake/build/vfs_fonts.js';
//----------------------------------------------------------------------------------------------------------------------

type pdfSettings = {
  documentDefinition: TDocumentDefinitions;
  target: {
    fileName: string;
  };
  encryption?: {
    userPassword?: string;
    ownerPassword?: string;
  }
};
//----------------------------------------------------------------------------------------------------------------------

const defaultFonts = {
  Courier: {
    normal: 'Courier',
    bold: 'Courier-Bold',
    italics: 'Courier-Oblique',
    bolditalics: 'Courier-BoldOblique',
  },
  Helvetica: {
    normal: 'Helvetica',
    bold: 'Helvetica-Bold',
    italics: 'Helvetica-Oblique',
    bolditalics: 'Helvetica-BoldOblique',
  },
  Times: {
    normal: 'Times-Roman',
    bold: 'Times-Bold',
    italics: 'Times-Italic',
    bolditalics: 'Times-BoldItalic',
  },
  Symbol: {
    normal: 'Symbol',
  },
  ZapfDingbats: {
    normal: 'ZapfDingbats',
  },
};
// pdfMake.fonts = defaultFonts;
pdfMake.vfs = pdfFonts.pdfMake.vfs;


//----------------------------------------------------------------------------------------------------------------------

export const renderPdf = async (pdfSettings: pdfSettings) => {
  var filePrefix = new Date().toISOString().slice(0, 23).replace(':', '-').replace(':', '-').replace('.', '-');
  pdfSettings.target.fileName = filePrefix + "-" + pdfSettings.target.fileName + '.pdf';
  if (pdfSettings.encryption) {
    pdfSettings.documentDefinition.userPassword = pdfSettings.encryption.userPassword;
    pdfSettings.documentDefinition.ownerPassword = pdfSettings.encryption.ownerPassword;
  }
  await doRenderPdf(pdfSettings);
  return;
};

//----------------------------------------------------------------------------------------------------------------------

const doRenderPdf = async (pdfSettings: pdfSettings) => {
  pdfMake.createPdf(pdfSettings.documentDefinition).download(pdfSettings.target.fileName)
  // pdfMake.createPdf(pdfSettings.documentDefinition).open() //Opens in a new window but can't set filename
};

//----------------------------------------------------------------------------------------------------------------------

export const testPdf = async (req: Request) => {
  const clientName = 'CLIENT NAME';
  const address1 = 'ADDRESS 1';
  const address2 = 'ADDRESS 2';
  const address3 = 'ADDRESS 3';
  const address4 = 'ADDRESS 4';
  const startDate = new Date();
  const endDate = new Date();
  const accountNumber = 'ACCOUNT NUMBER';
  const accountName = 'ACCOUNT NAME';
  const currency = 'CURRENCY';
  const balanceBroughtForward = 100;

  const docDefinition: TDocumentDefinitions = {
    defaultStyle: {
      font: 'Roboto',
      fontSize: 10,
      margin: [3, 3, 3, 3],
    },
    styles: {
      header: {
        fontSize: 22,
        bold: true,
      },
      disclamerText: {
        alignment: 'center',
        fontSize: 6,
        margin: [10, 10, 10, 3],
      },
      footerText: {
        fontSize: 8,
      },
      rightme:
        {   
            alignment: 'right'
        },
      bold: { bold: true },
    },
    header: {
      columns: [
        { text: 'Transaction History', style: 'header', alignment: 'left' },
        { text: 'IMAGE!!!', alignment: 'right' },
      ],
    },
    footer: (currentPage: number, pageCount: number) => {
      return {
        stack: [
          {
            text: 'Capitec Business Bank a division of Capitec Bank Limited Registration Number: 1980/003695/06. An authorised financial services provider (FSP46669) and registered credit provider (NCRCP13).',
            style: 'disclamerText',
          },
          {
            columns: [
              { text: `Page ${currentPage}/${pageCount}`, style:'footerText', alignment: 'left' },
              { text: `${(new Date()).toDateString()}`, style:'footerText', alignment: 'right' },
            ]
          }
        ],
      };
    },
    content: [
      { text: clientName },
      { text: address1 },
      { text: address2 },
      { text: address3 },
      { text: address4 },
      {
        text: [
          'Transaction From ',
          { text: startDate.toDateString(), style: 'bold' },
          ' To ',
          { text: endDate.toDateString(), style: 'bold' },
        ],
        margin: [3, 10, 3, 3],
      },
      { text: ['Account Number: ', { text: `${accountNumber} ${accountName}`, style: 'bold' }] },
      { text: ['CURRENCY: ', { text: `${currency}`, style: 'bold' }] },

      {text: `Balance brought forward: ${balanceBroughtForward}`,margin:[3,10,3,3]},
      {
        layout: 'lightHorizontalLines',
        margin:[3,10,3,3],
        table: {
          headerRows: 1,
          
          widths: ['*', 'auto', 100, '*', '*','*'],
          body: [
            ['Date', 'Description', 'Reference', 'Amount', 'Fees', 'Balance'],
            ['Value 1', 'Value 2', 'Value 3', 'Value 4', 'Value 5', 'Value 6'],
            [{ text: 'Total', bold: true }, '', '', { text: 'Total', bold: true },{ text: 'Total', bold: true },{ text: 'Total', bold: true }],
          ],
        },
      },
    ],
  };
  const pdfSettings: pdfSettings = {
    documentDefinition: docDefinition,
    target: {
      fileName: 'testPdf',
    },
  };
  await renderPdf(pdfSettings);
  ;
};

//----------------------------------------------------------------------------------------------------------------------
