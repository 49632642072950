//@ts-nocheck
import VueStore from 'vue-class-store';

import { LfxPaymentPortfolioInt, LfxPaymentPortfolioDef } from '@/definitions/LfxPaymentPortfolioDef';

import { LfxModel, LfxDefinition } from './LfxModel';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

@VueStore
class LfxPaymentPortfolioMdl extends LfxDefinition {
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
export class LfxPaymentPortfolio extends LfxModel {
  constructor(data, state) {
    super({definition_name:'payment_portfolio',...data}, state);    
  }
  get displayFKLink() {
    return false
  }
  get portfolioButtons() {
    switch (this.portfolioLinkStatus) {
      case "none": case "retrieving": return "Retrieving...";
      case "retreived": return "View Portfolio";
      case "error": return "Error"
    }
    return `Portfolio`
  }
  get portfolioButtonsLink() {
    if (this.portfolioLinkStatus === "retreived") {
      return this.portfolioLink
    } else if (this.portfolioLinkStatus === "none") {
      this.retrievePortfolioLink()
    }
    return undefined
  }
  portfolioLinkStatus = "none"; // "retrieving" | "retreived" | "error"
  portfolioLink = undefined;
  async retrievePortfolioLink() {
    this.portfolioLinkStatus = "retrieving";
    const viewPortfolioResult = await doPermissionAction(
      { onSuccess: { doStoreUpdate: false } },
      'getPortfolioLink',
      {
        transactionId: this.transactionFileId,
        paymentId: this.paymentId,
        paymentPortfolioId: this.id
      },
    );
    if (viewPortfolioResult.status === 'success') {
      this.portfolioLinkStatus = "retreived"
      this.portfolioLink = viewPortfolioResult.response.portfolioUrl
    } else {
      this.portfolioLinkStatus = "error"
    }
  }
}

export function Definition(context, name: string) {
  return new LfxPaymentPortfolioMdl(LfxPaymentPortfolioDef, LfxPaymentPortfolio, context, name, {});
}
