import type { LdbDefinition } from '@/definitions/LdbInterfaces';

const definitions = new Map();
const definitionTableIndex = new Map();

if (process.env.NODE_ENV === 'development') {
  // window.definitions = definitions;
}

export function addDefinition(name: string, definition: LdbDefinition) {
  definitions.set(name, definition);
  definitionTableIndex.set(definition.table, definition);
  return definitions;
}

export function getDefinition(name: string): LdbDefinition {
  return definitions.get(name);
}

export function getDefinitionByTable(tableName: string): LdbDefinition {
  return definitionTableIndex.get(tableName);
}

export function getDefinitionByData(data: { Table: string }): LdbDefinition {
  let definition = definitionTableIndex.get(data.Table);
  if (definition !== undefined && definition.getDefinitionName !== undefined) {
    return getDefinition(definition.getDefinitionName(data));
  } else {
    return definition;
  }
}

if (process.env.NODE_ENV === 'development') {
  //@ts-expect-error
  window.getDefinition = getDefinition; //@ts-expect-error
  window.getDefinitionByTable = getDefinitionByTable;
}
export default definitions;
