import {stateDef} from '@/store';

type DataStore = {
  state:stateDef
}

import {LdbPermissionGuard,LdbConditions,LdbDbObject} from '@/definitions/LdbInterfaces';

export function evaluate_guards( guard:LdbPermissionGuard, record:LdbDbObject,{state}:DataStore) {
  let hasRequiredPermission = true;
  let hasRequiredAccountLevel = true;
  let hasMFA = true;
  let conditionsAreMet = true;
  let requiresMFA = (guard.mfa === true);
  let user = state.authUser;
  if (guard.permissions !== undefined) {
    let userPermissions = user?.permissions;
    hasRequiredPermission = guard.permissions.some( p => userPermissions.includes(p))
  } 
  if (hasRequiredPermission === true && guard.accountLevels !== undefined) { 
    let userAccount = state.account[user.accountId];
  //@ts-ignore
    let accountLevel = userAccount.accountLevel;
    hasRequiredAccountLevel = ( guard.accountLevels.includes(accountLevel));
  }
  if (hasRequiredAccountLevel && hasRequiredPermission && requiresMFA ) {
    let SSE = Math.round(new Date().getTime() / 1000);
    hasMFA = (state.mfa.expires > SSE);
  } 
  
  if ( hasRequiredAccountLevel && hasRequiredPermission && hasMFA && guard.conditions !== undefined) {
    conditionsAreMet = evaluate_conditions(guard.conditions,record);
  }
  return (hasRequiredPermission && hasRequiredAccountLevel &&  hasMFA && conditionsAreMet );
}


function evaluate_conditions(conditions:LdbConditions,record:LdbDbObject):boolean {
  if (conditions === undefined) {
    return true
  } else 
  if ( typeof conditions === "function" ) {
    return conditions({},record)
  } else 
  if ( typeof conditions === "boolean") {
    return conditions
  } else 
  if ( typeof conditions === "object" ) {
    if (Array.isArray(conditions) )  {
      //@ts-ignore
      return conditions.every(function(condition:LdbCondition ) { return evaluate_conditions(condition,record)})
    } 
    else {
      return false
    }
  } else 
  {
    return true;
  }
}

