<template>
  <LFXContent
    :titleBar="titleBar"
    :show_tabs="showTabs"
    :show_actions="showActions"
    :form_layout="formLayout"
    :show_label="showLabel"
    :show_sidebar="false"
  >
    <template #actions>
      <LDBButton
        v-for="button in actionBarButtons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        :viewGuards="button.guards"
        @click="button.clickEvent"
      ></LDBButton>
    </template>

    <template #content>
      <router-view :includeSystemUsers="includeSystemUsers"></router-view>
    </template>
  </LFXContent>

  <router-view name="modal"></router-view>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXContent from '@/components/layout/LfxContent.vue';
import LFXListView from '@/views/general/LFXListView.vue';

export default {
  name: 'LfxUsers',
  components: { LFXListView, LFXContent },
  props: {},
  data() {
    return {
       includeSystemUsers: false
    };
  },
  created() {
  },
  mounted() {},
  computed: {
    ...mapGetters(['permissions','iAmAnUnmigratedIntermediary']),
    ...mapState(['user','authUser']),

    // ----- Title Bar -----

    titleBar() {
      const titleBar = {
        label: 'Users',
        buttons: this.titleBarButtons,
      };
      return titleBar;
    },
    titleBarButtons() {
      let allButtons = [
        {
          name: 'addUser',
          label: 'Add User',
          type: 'white',
          guards: this.permissions.createUser && !this.iAmAnUnmigratedIntermediary,
          routeName: 'addUser',
        },
      ];
      return allButtons.filter((button) => button.guards);
    },

    // ----- Tabs -----

    showTabs() {
      return false;
    },

    // ----- Action Bar -----

    showActions() {
      return true;
    },
    actionBarButtons() {
      return [
          {
            name: 'showHideSystem',  
            label: this.includeSystemUsers === false ? "Show System Users" : "Hide System Users",
            type: '',
            guards: this.authUser.accountLevel === 'bank',
            action: "click",
            params: {},
            httpParams: {},
            clickEvent:this.toggleSystem,
          }

      ]
    },

    // ----- Content Label -----

    showLabel() {
      return false;
    },

    // ----- Layout -----

    formLayout() {
      return this.$route.meta?.content?.layout === 'list' ? false : true;
    },

    // ----- Records -----

    definition() {
      return getDefinition('user');
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    onSearchChange(evt) {
      this.definition.search_string = evt.detail.new;
    },
    toggleSystem() {
      if (this.includeSystemUsers === true) {
        this.includeSystemUsers = false
      } else {
        this.includeSystemUsers = true
      }
    }
  },
};
</script>

<style>
/* div.container {
} */
</style>
