<template>
  <LDBItemViewField
    v-if="view === 'item'"
    :view="view"
    :field="field"
    :displayValue="displayValue"
    :definition="definition"
    :record="record"
  />
  <LDBListViewField
    v-else-if="view === 'list'"
    :view="view"
    :field="field"
    :displayValue="displayValue"
    :definition="definition"
    :record="record"
    @click="onIdClick"
    :renderLink="renderLink"
  />
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import { ldb_field_mixin } from './ldb_field_mixin.js';
import LDBItemViewField from './LDBItemViewField.vue';
import LDBListViewField from './LDBListViewField.vue';

export default {
  emits: ['click'],
  mixins: [ldb_field_mixin],
  components: {
    LDBListViewField,
    LDBItemViewField,
  },
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    displayValue() {
      return this.record[this.field.datatype.id.descriptionField]
        ? `${this.record[this.field.datatype.id.descriptionField]}`
        : `${this.record.id}`;
    },
    renderLink() {
      if (this.record.displayFKLink === undefined) {
        return true;
      }
      return this.record.displayFKLink;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    on_change(evt) {
      this.$emit('change', evt.detail.new);
    },
    onIdClick() {
      this.record.activate();
    },
  },
};
</script>

<style></style>
