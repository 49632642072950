import VueStore from 'vue-class-store';

import { LdbDbObject, LdbDefinition } from '@/definitions/LdbInterfaces';
import { LfxTransactionFileLogInt, LfxTransactionFileLogDef } from '@/definitions/LfxTransactionFileLogDef';
import { LfxModel, LfxDefinition } from './LfxModel';

//@ts-ignore
import { http_patch, http_get, http_put } from '@/lfx_rest';

@VueStore
//@ts-ignore
class LfxTransactionFileLogMdl extends LfxDefinition {
  //@ts-ignore
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
//@ts-ignore
export class LfxTransactionFileLog extends LfxModel {
  //@ts-ignore
  constructor(data, state) {
    super({definition_name:'transaction_file_log',...data}, state);
    //@ts-ignore
    
  }
  get displayFKLink() {
    return false
  }

}

//@ts-ignore
export function Definition(context, name: string) {
  return new LfxTransactionFileLogMdl(LfxTransactionFileLogDef, LfxTransactionFileLog, context, name, {});
}
