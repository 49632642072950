import type { LdbAPIAction } from './LdbActionTypes';

//------------------- CLIENT ACTIONS ---------------//
//----------------------------------------------

export const createClient: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['captureClient'],
  description: 'Creates an LfxAccount for a new client',
  method: 'post',
  collectionPath: '/client',
  path: '/client',
  tags: ['client'],
  headers: {},
  responses: {
    200: { description: 'lfxAccount', message: 'Client successfully created', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    accountType: { type: 'string', required: true }, // "entity"|"individual"
    name: { type: 'string', required: true },
    identificationType: { type: 'string', required: true }, //"rsaRegistrationNumber"|"internationalRegistrationNumber"|"rsaId"|"passport"
    identification: { type: 'string', required: true },
    externalReference: { type: 'string' },
    bopFormSignatoryOption: { type: 'string' },
    clientPublicInfoToken: { type: 'string' },
    additionalInfo: { type: 'object' },
  },
  pathParams: {},
  implementedByFrontEnd: true,
};
export const createClientForAccount: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['captureClient'],
  description: 'Creates an LfxAccount for a new client',
  method: 'post',
  collectionPath: '/account/{accountId}/client',
  path: '/account/{accountId}/client',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'lfxAccount', message: 'Client successfully created', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    accountType: { type: 'string', required: true }, // "entity"|"individual"
    name: { type: 'string', required: true },
    identificationType: { type: 'string', required: true }, //"rsaRegistrationNumber"|"internationalRegistrationNumber"|"rsaId"|"passport"
    identification: { type: 'string', required: true },
    externalReference: { type: 'string' },
    bopFormSignatoryOption: { type: 'string' },
    clientPublicInfoToken: { type: 'string' },
    additionalInfo: { type: 'object' },
  },
  pathParams: { accountId: { type: 'number' } },
  implementedByFrontEnd: true,
};

export const checkNewClientID: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['captureClient'],
  description: 'Checks a new client ID to make sure that it is valid and not already registered',
  method: 'put',
  collectionPath: '/client',
  path: '/client/checkId',
  tags: ['client'],
  headers: {},
  responses: {
    200: {
      description: 'OK',
      properties: {
        status: { type: 'string' },
        existingAccount: { type: 'object' },
        cifNumber: { type: 'string' },
        company: { type: 'object' },
        individual: { type: 'object' },
      },
    },
    400: { description: 'ID Validation Failed' },
    401: { description: 'AuthError' },
    404: { description: 'CIPC/Home Affairs Search failed', properties: { error: { type: 'object' } } },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    accountType: { type: 'string' },
    identificationType: { type: 'string' },
    identification: { type: 'string' },
  },
  pathParams: {},
  implementedByFrontEnd: true,
};

export const importClient: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['captureClient'],
  description: 'Imports a client from the Bank',
  method: 'put',
  collectionPath: '/client',
  path: '/client/import',
  tags: ['client'],
  headers: {},
  responses: {
    200: { description: 'New LfxAccount', message: 'Client successfully imported', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    403: { description: 'Client Not Imported' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    cifNumber: { type: 'string', required: true },
    clientName: { type: 'string' },
    externalReference: { type: 'string' },
  },
  pathParams: {},
  implementedByFrontEnd: true,
};
export const bankImportClient: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['manageTocMandates'],
  description: 'Imports a client from the Bank as a bank user',
  method: 'put',
  collectionPath: '/client',
  path: '/client/importByBank',
  tags: ['client'],
  headers: {},
  responses: {
    200: { description: 'New LfxAccount', message: 'Client successfully imported', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    403: { description: 'Client Not Imported' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    parentId: { type: 'number', required: true },
    cifNumber: { type: 'string', required: true },
    clientName: { type: 'string' },
  },
  pathParams: {},
  implementedByFrontEnd: true,
};
export const getAllClientApplications: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'bank'],
  permissions: [],
  description: 'Get a list of all applications for a the current account',
  method: 'patch',
  collectionPath: '/client/application',
  path: '/client/application',
  tags: ['application'],
  headers: {},
  responses: {
    200: { description: 'Array of LfxApplication' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  implementedByFrontEnd: true,
};

export const saveBulkChangeClientIntermediaryStructure: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['bulkChangeClientIntermediary'],
  description:
    'Saves the structure of a bulk maker checker request. This is the first step in the Maker/Checker process',
  method: 'put',
  collectionPath: '/client/saveBulkChangeClientIntermediaryStructure',
  path: '/client/saveBulkChangeClientIntermediaryStructure',
  tags: ['client'],
  headers: {},
  responses: {
    200: {
      description: 'Okay',
      message: 'Maker Checker Request Saved',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { bulkChangeInterStructure: { type: 'object', required: true } },
  pathParams: {  },
  implementedByFrontEnd: true,
};
export const changeClientIntermediary: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['manageTocMandates'],
  description:
    'Creates or reactivates a client under the new intermediary and deactivates the client under the old intermediary',
  method: 'put',
  collectionPath: '/client/{accountId}/changeIntermediary',
  path: '/client/{accountId}/changeIntermediary',
  tags: ['client'],
  headers: {},
  responses: {
    200: {
      description: 'New LfxAccount',
      message: 'Client successfully moved to new Intermediary',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { accountId: { type: 'number' } },
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const closeClient: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseClient'],
  description: 'Closes an active client',
  method: 'put',
  collectionPath: '/client/{accountId}/close',
  path: '/client/{accountId}/close',
  tags: ['client'],
  headers: {},
  responses: {
    200: { description: 'LfxAccount', message: 'Client successfully updated', header: 'Success' },
    400: { description: 'Client Update Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    closeCif: { type: 'boolean' },
    kycNonCompliant: { type: 'boolean' },
    note: { type: 'string' },
  },
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const reopenClient: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseClient'],
  description: 'Reopens a closed client',
  method: 'put',
  collectionPath: '/client/{accountId}/reopen',
  path: '/client/{accountId}/reopen',
  tags: ['client'],
  headers: {},
  responses: {
    200: { description: 'LfxAccount', message: 'Client successfully re-opened', header: 'Success' },
    400: { description: 'Client Reopen Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const releaseMigratedClient: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseClient'],
  description: 'Releases a Client that was migrated in the submitted or rejected state',
  method: 'put',
  collectionPath: '/client/{accountId}/releaseMigratedClient',
  path: '/client/{accountId}/releaseMigratedClient',
  tags: ['bankAccount'],
  headers: {},
  responses: {
    200: {
      description: 'LfxBankAccount',
      message: 'Bank account successfully re-opened',
      header: 'Success',
    },
    400: { description: 'Account Reopen Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    cifNumber: { type: 'string' },
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const getBankInformation: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['captureClient'],
  description: 'Retrieves client information from bank system',
  method: 'put',
  collectionPath: '/client/{accountId}/bankInformation',
  path: '/client/{accountId}/bankInformation',
  tags: ['client'],
  headers: {},
  responses: {
    200: { description: 'CIF Information' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    403: { description: 'Error' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const getBankInformationByCif: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['manageTocMandates'],
  description: 'Retrieves client information from bank system',
  method: 'put',
  collectionPath: '/client/getBankInformationByCif',
  path: '/client/getBankInformationByCif',
  tags: ['client'],
  headers: {},
  responses: {
    200: { description: 'CIF Information' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    403: { description: 'Error' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { cifNumber: {required:true, type: 'string'}},
  pathParams: { },
  implementedByFrontEnd: true,
};

export const getClientConfiguredSignatories: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Get a list of the signatories configured for a client',
  method: 'patch',
  collectionPath: '/client/{accountId}/configuredSignatories',
  path: '/client/{accountId}/configuredSignatories',
  tags: ['client'],
  headers: {},
  responses: {
    200: { description: 'Array of LfxSignatory' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    409: { description: 'Invalid configuration' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { accountId: { type: 'integer', required: true } },
};

export const createNewClientApplication: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['captureClient'],
  description: 'Creates a new application for a client or account',
  method: 'post',
  collectionPath: '/client/{accountId}/application',
  path: '/client/{accountId}/application',
  tags: ['application'],
  headers: {},
  responses: {
    200: { description: 'New LfxApplication' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    403: { description: 'Application Not Created' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { accountId: { type: 'integer', required: true } },
};

export const getClientApplications: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'bank'],
  permissions: [],
  description: 'Get a list of applications for a client',
  method: 'patch',
  collectionPath: '/client/{accountId}/application',
  path: '/client/{accountId}/application',
  tags: ['application'],
  headers: {},
  responses: {
    200: { description: 'Array of LfxApplication' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const getApplication: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'bank'],
  permissions: [],
  description: 'Gets an application and all its associated information',
  method: 'get',
  collectionPath: '/client/{accountId}/application',
  path: '/client/{accountId}/application/{applicationId}',
  tags: ['application'],
  headers: {},
  responses: {
    200: { description: 'LfxApplication' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const getClientMessageLogs: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets logs for an applicationId',
  method: 'patch',
  collectionPath: '/client/{accountId}/application/{applicationId}/logs',
  path: '/client/{accountId}/application/{applicationId}/logs',
  tags: ['application'],
  headers: {},
  responses: {
    200: { description: 'Array of LfxClientMessageLog' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { accountId: { type: 'integer', required: true }, applicationId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const getClientPortfolioHistory: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets portfolio history for a applicationId',
  method: 'patch',
  collectionPath: '/client/{accountId}/application/{applicationId}/history',
  path: '/client/{accountId}/application/{applicationId}/history',
  tags: ['application'],
  headers: {},
  responses: {
    200: { description: 'Array of LfxClientPortfolio' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { accountId: { type: 'integer', required: true }, applicationId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const setApplicationSignatoryOrder: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['submitClient'],
  description: 'Updates Signatories for Client Application',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/updateSignatoryOrder',
  path: '/client/{accountId}/application/{applicationId}/updateSignatoryOrder',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplication',
      message: 'Application signatories successfully update',
      header: 'Success',
    },
    400: { description: 'Signatory Update Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    signatoryOrder: { type: 'array', items: { type: 'number' } },
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const setApplicationPortfolioDocuments: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['submitClient'],
  description: 'Updates Documents for a LfxApplication',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/updateDocuments',
  path: '/client/{accountId}/application/{applicationId}/updateDocuments',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplication',
      message: 'Application documents successfully update',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    documents: {
      type: 'array',
      items: { type: 'string' },
      required: true,
    },
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const submitApplicationCra: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['submitClient'],
  description: 'Submits the CRA check for an application and all sub applications',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/submitApplicationCra',
  path: '/client/{accountId}/application/{applicationId}/submitApplicationCra',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplication',
      message: 'Application CRA successfully submitted',
      header: 'Success',
    },
    400: { description: 'Application CRA Submit Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const submitApplicationPortfolio: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['submitClient'],
  description: 'Submits a portfolio for an application and all sub applications',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/portfolioSubmit',
  path: '/client/{accountId}/application/{applicationId}/portfolioSubmit',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplication',
      message: 'Application successfully submitted',
      header: 'Success',
    },
    400: { description: 'Application Submit Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const getClientPortfolioStatus: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['submitClient'],
  description: 'Gets the complete status of a portfolio',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/portfolioStatus',
  path: '/client/{accountId}/application/{applicationId}/portfolioStatus',
  tags: ['application'],
  headers: {},
  responses: {
    200: { description: 'LfxPaymentStatus' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { supersededPortfolioId: { type: 'string', required: true } },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const getClientPortfolioLink: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: [],
  description: "Gets an encoded link to the clinet application's portfolio",
  method: 'get',
  collectionPath: '/client/{accountId}/application/{applicationId}/getPortfolioLink',
  path: '/client/{accountId}/application/{applicationId}/getPortfolioLink',
  tags: ['application'],
  headers: {},
  responses: {
    200: { description: 'Link' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const cancelClientPortfolio: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['submitClient'],
  description: 'Requests the cancellation of a portfolio',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/cancelPortfolio',
  path: '/client/{accountId}/application/{applicationId}/cancelPortfolio',
  tags: ['application'],
  headers: {},
  responses: {
    200: { description: 'LfxPayment' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { rejectedReason: { type: 'string', required: true } },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const addClientPortfolioDocument: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['submitClient'],
  description: 'Adds a document to an existing portfolio',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/addDocument',
  path: '/client/{accountId}/application/{applicationId}/addDocument',
  tags: ['application'],
  headers: {},
  responses: {
    200: { description: 'LfxApplication', message: 'Portfolio document successfully updated', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    documentIdentifier: { type: 'string', required: true },
    reason: { type: 'string', required: true },
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const removeClientPortfolioDocument: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['submitClient'],
  description: 'Removes a document from an existing portfolio',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/removeDocument',
  path: '/client/{accountId}/application/{applicationId}/removeDocument',
  tags: ['application'],
  headers: {},
  responses: {
    200: { description: 'LfxApplication' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    documentIdentifier: { type: 'string', required: true },
    reason: { type: 'string', required: true },
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const rejectClientPortfolio: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseClient'],
  description: 'Rejects a submitted portfolio',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/rejectClientPortfolio',
  path: '/client/{accountId}/application/{applicationId}/rejectClientPortfolio',
  tags: ['application'],
  headers: {},
  responses: {
    200: { description: 'LfxApplication' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    rejectionReason: { type: 'string', required: true },
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const clientPortfolioReadyForSignature: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseClient'],
  description: 'Marks a portfolio as ready for signature',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/clientPortfolioReadyForSignature',
  path: '/client/{accountId}/application/{applicationId}/clientPortfolioReadyForSignature',
  tags: ['application'],
  headers: {},
  responses: {
    200: { description: 'LfxApplication' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const clientPortfolioSigned: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseClient'],
  description: 'Marks a portfolio as signed',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/clientPortfolioSigned',
  path: '/client/{accountId}/application/{applicationId}/clientPortfolioSigned',
  tags: ['application'],
  headers: {},
  responses: {
    200: { description: 'LfxApplication' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const submitApplication: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['submitClient'],
  description: 'Submits an application and all sub applications to the bank',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/submit',
  path: '/client/{accountId}/application/{applicationId}/submit',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplication',
      message: 'Application successfully submitted',
      header: 'Success',
    },
    400: { description: 'Application Submit Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const rejectApplication: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseClient'],
  description: 'Rejects an application and all sub applications',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/reject',
  path: '/client/{accountId}/application/{applicationId}/reject',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplication',
      message: 'Application successfully rejected',
      header: 'Success',
    },
    400: { description: 'Application Reject Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { rejectedReason: { type: 'string', required: true } },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const cancelApplication: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['submitClient'],
  description: 'Cancels an application and all sub applications',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/cancel',
  path: '/client/{accountId}/application/{applicationId}/cancel',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplication',
      message: 'Application successfully cancelled',
      header: 'Success',
    },
    400: { description: 'Application Cancel Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { rejectedReason: { type: 'string', required: true } },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const updateApplicationStatus: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['captureClient', 'releaseClient','configureSystem'],
  description: "Triggers a recalculation and update of the Aplication's status",
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/updateStatus',
  path: '/client/{accountId}/application/{applicationId}/updateStatus',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplication',
      message: 'Application status successfully updated',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const resendApplicationPortfolioEmails: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['manageClient'],
  description: 'Instructs SigniFlow to resend emails to Signatories',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/resendApplicationPortfolioEmails',
  path: '/client/{accountId}/application/{applicationId}/resendApplicationPortfolioEmails',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplication',
      message: 'Emails Sent',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    signatoriesToResend: { type: 'array', required: true },
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const hideApplication: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageClient', 'releaseClient'],
  description: 'Marks an Application as Hidden',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/hideApplication',
  path: '/client/{accountId}/application/{applicationId}/hideApplication',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplication',
      message: 'Application status successfully hidden',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    hideClient: { type: 'boolean', required: true },
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const unhideApplication: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageClient', 'releaseClient'],
  description: 'Marks an Application as Visible',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/unhideApplication',
  path: '/client/{accountId}/application/{applicationId}/unhideApplication',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplication',
      message: 'Application status successfully unhidden',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const resetApplicationStatus: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: [ 'configureSystem'],
  description: 'Resets all application statuses to return application to the beginning',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/resetApplicationStatus',
  path: '/client/{accountId}/application/{applicationId}/resetApplicationStatus',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplication',
      message: 'Application status successfully hidden',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    confirmResetApplicationStatus: { type: 'boolean', required: true },
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const addAssCifApplication: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['captureClient'],
  description: 'Adds an Assiciated CIF Application to an existing Open Application',
  method: 'post',
  collectionPath: '/account/{accountId}/application/{applicationId}/application_cif',
  path: '/client/{accountId}/application/{applicationId}/assCif',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'New LfxApplicationCif',
      message: 'Associated CIF successfully added',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    403: { description: 'Application Not Created' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    accountType: { type: 'string', required: true },
    name: { type: 'string', required: true },
    identificationType: { type: 'string', required: true },
    identification: { type: 'string', required: true },
    additionalInfo: { type: 'object' },
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const getApplicationCif: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'bank'],
  permissions: [],
  description: 'Gets an application CIF and all its associated information',
  method: 'get',
  collectionPath: '/client/{accountId}/application/{applicationId}/cif',
  path: '/client/{accountId}/application/{applicationId}/cif/{cifId}',
  tags: ['application'],
  headers: {},
  responses: {
    200: { description: 'LfxApplicationCif' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
    cifId: { type: 'integer', required: true },
  },
};

export const submitCifCra: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['submitClient'],
  description: 'Submits the CRA for a CIF application',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/cif/{cifId}/submitCifCra',
  path: '/client/{accountId}/application/{applicationId}/cif/{cifId}/submitCifCra',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplicationCif',
      message: 'CIF CRA application successfully submitted',
      header: 'Success',
    },
    400: { description: 'Application CRA Submit Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
    cifId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const approveCifCra: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['checkClientCompliance'],
  description: 'Manually Apporve the CRA for a CIF application',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/cif/{cifId}/approveCifCra',
  path: '/client/{accountId}/application/{applicationId}/cif/{cifId}/approveCifCra',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplicationCif',
      message: 'CIF CRA application successfully approved',
      header: 'Success',
    },
    400: { description: 'Application CRA Submit Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
    cifId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const declineCifCra: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['checkClientCompliance'],
  description: 'Manually Decline the CRA for a CIF application',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/cif/{cifId}/declineCifCra',
  path: '/client/{accountId}/application/{applicationId}/cif/{cifId}/declineCifCra',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplicationCif',
      message: 'CIF CRA application successfully declined',
      header: 'Success',
    },
    400: { description: 'Application CRA Submit Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    declinedReason: {type:'string', required:true},
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
    cifId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const recallCifCra: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['submitClient'],
  description: 'Recalls the CRA for a CIF application',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/cif/{cifId}/recallCifCra',
  path: '/client/{accountId}/application/{applicationId}/cif/{cifId}/recallCifCra',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplicationCif',
      message: 'CIF CRA application successfully recalled',
      header: 'Success',
    },
    400: { description: 'Application CRA Recall Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
    cifId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const requestCraEdd: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['checkClientCompliance','releaseClient'],
  description: 'Requests Enhanced Due Diligence for the CRA on a CIF application',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/cif/{cifId}/requestCraEdd',
  path: '/client/{accountId}/application/{applicationId}/cif/{cifId}/requestCraEdd',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplicationCif',
      message: 'CIF CRA EDD Submitted',
      header: 'Success',
    },
    400: { description: 'Application CRA Submit Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    eddReason: {type:'string', required:true},
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
    cifId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const submitCraEdd: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['submitClient'],
  description: 'Submits Enhanced Due Diligence for the CRA on a CIF application',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/cif/{cifId}/requestCraEdd',
  path: '/client/{accountId}/application/{applicationId}/cif/{cifId}/submitCraEdd',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplicationCif',
      message: 'CIF CRA EDD Submitted',
      header: 'Success',
    },
    400: { description: 'Application CRA Submit Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
    cifId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const submitCifApplication: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['submitClient'],
  description: 'Submits a CIF application to the bank',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/cif/{cifId}/submit',
  path: '/client/{accountId}/application/{applicationId}/cif/{cifId}/submit',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplicationCif',
      message: 'CIF application successfully submitted',
      header: 'Success',
    },
    400: { description: 'Application Submit Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
    cifId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const releaseCifApplication: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseClient'],
  description: 'Releases a CIF application',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/cif/{cifId}/release',
  path: '/client/{accountId}/application/{applicationId}/cif/{cifId}/release',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplicationCif',
      message: 'CIF application successfully released',
      header: 'Success',
    },
    400: { description: 'Application Release Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { cifNumber: { type: 'string', required: true } },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
    cifId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const rejectCifApplication: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseClient'],
  description: 'Rejects a CIF application',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/cif/{cifId}/reject',
  path: '/client/{accountId}/application/{applicationId}/cif/{cifId}/reject',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplicationCif',
      message: 'CIF application successfully rejected',
      header: 'Success',
    },
    400: { description: 'Application Reject Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { rejectedReason: { type: 'string', required: true } },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
    cifId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const cancelCifApplication: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['submitClient'],
  description: 'Cancels a CIF application',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/cif/{cifId}/cancel',
  path: '/client/{accountId}/application/{applicationId}/cif/{cifId}/cancel',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplicationCif',
      message: 'CIF application successfully cancelled',
      header: 'Success',
    },
    400: { description: 'Application Cancel Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { rejectedReason: { type: 'string', required: true } },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
    cifId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const updateApplicationCifIndividual: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['captureClient'],
  description: 'Updates an application CIF Individual',
  method: 'post',
  collectionPath: '/client/{accountId}/application/{applicationId}/cif/{cifId}/individual',
  path: '/client/{accountId}/application/{applicationId}/cif/{cifId}/individual/{cifIndividualId}',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'Updated LfxApplicationCifIndividual',
      message: 'CIF application successfully updated',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    403: { description: 'Application CIF Individual Not Updated' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    firstName: { type: 'string' },
    middleName: { type: 'string' },
    surname: { type: 'string' },
    residentialStatus: { type: 'string' },
    idNumber: { type: 'string' },
    idIssueDate: { type: 'string', format: 'date' },
    passportNumber: { type: 'string' },
    passportIssueDate: { type: 'string', format: 'date' },
    passportExpiryDate: { type: 'string', format: 'date' },
    passportCountryId: { type: 'number' },
    taxNumber: { type: 'string' },
    reasonForNoId: { type: 'string' },
    title: { type: 'string' },
    nationality: { type: 'number' },
    dateOfBirth: { type: 'string', format: 'date' },
    countryOfBirth: { type: 'string' },
    gender: { type: 'string' },
    civilStatus: { type: 'string' },
    msisdn: { type: 'string' },
    homePhone: { type: 'string' },
    workPhone: { type: 'string' },
    preferredContact: { type: 'string' },
    faxNumber: { type: 'string' },
    emailAddress: { type: 'string' },
    address: { type: 'string' },
    suburb: { type: 'string' },
    city: { type: 'string' },
    province: { type: 'string' },
    postalCode: { type: 'string' },
    country: { type: 'number' },
    geoCode: { type: 'string' },
    industryCode: {type: 'string'},
    occupation: { type: 'string' },
    occupationCode: { type: 'string' },
    designation: { type: 'string' },
    employerName: { type: 'string' },
    employerNatureOfBusiness: { type: 'string' },
    incomeFrequency: { type: 'string' },
    income: { type: 'number' },
    sourceOfIncomeOption: { type: 'string' },
    sourceOfIncome: { type: 'string' },
    rentalIncomeFrequency: { type: 'string' },
    rentalIncome: { type: 'number' },
    homeOwnership: { type: 'boolean' },
    soleProprietor: { type: 'boolean' },
    soleProprietorTradingAs: { type: 'string' },
    solePropTradingAddressIsAddress: { type: 'boolean' },
    solePropTradingAddress: { type: 'string' },
    solePropTradingSuburb: { type: 'string' },
    solePropTradingCity: { type: 'string' },
    solePropTradingProvince: { type: 'string' },
    solePropTradingPostalCode: { type: 'string' },
    solePropTradingCountry: { type: 'string' },
    customsClientNumber: { type: 'string' },
    clientDueDilligence: { type: 'string' },
    isUSATaxResident: { type: 'string' },
    isRSATaxResident: { type: 'string' },
    taxCountry: { type: 'string' },
    taxIdentificationNumber: { type: 'string' },
    digitalSignatureReferences: { type: 'string' },
    internetBanking: { type: 'boolean' },
    bankingAlerts: { type: 'string' },
    emigratingBlock: { type: 'boolean' },
    requiresFecFacility: { type: 'boolean' },
    isUbo: { type: 'boolean' },
    uboOwnershipPercentage: { type: 'boolean' },
    uboIndividualOwnershipType: { type: 'boolean' },
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
    cifId: { type: 'integer', required: true },
    cifIndividualId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const getApplicationCifIndividual: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'bank'],
  permissions: [],
  description: 'Gets an application CIF Individual',
  method: 'get',
  collectionPath: '/client/{accountId}/application/{applicationId}/cif/{cifId}/individual',
  path: '/client/{accountId}/application/{applicationId}/cif/{cifId}/individual/{cifIndividualId}',
  tags: ['application'],
  headers: {},
  responses: {
    200: { description: 'LfxApplicationCifIndividual' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
    cifId: { type: 'integer', required: true },
    cifIndividualId: { type: 'integer', required: true },
  },
};

export const updateApplicationCifEntity: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['captureClient'],
  description: 'Updates an application CIF Entity',
  method: 'post',
  collectionPath: '/client/{accountId}/application/{applicationId}/cif/{cifId}/entity',
  path: '/client/{accountId}/application/{applicationId}/cif/{cifId}/entity/{cifEntityId}',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'Updated LfxApplicationCifEntity',
      message: 'CIF application successfully updated',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    403: { description: 'Application CIF Entity Not Updated' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    companyType: { type: 'string' },
    name: { type: 'string' },
    registrationNumber: { type: 'string' },
    registrationDate: { type: 'string', format: 'date' },
    taxNumber: { type: 'string' },
    vatRegistered: { type: 'boolean' },
    vatNumber: { type: 'string' },
    contactNumber: { type: 'string' },
    contactPersonName: { type: 'string' },
    emailAddress: { type: 'string' },
    faxNumber: { type: 'string' },
    address: { type: 'string' },
    suburb: { type: 'string' },
    city: { type: 'string' },
    province: { type: 'string' },
    postalCode: { type: 'string' },
    country: { type: 'number' },
    geoCode: { type: 'string' },
    businessSectorCode: { type: 'string' },
    natureOfBusiness: { type: 'string' },
    annualIncome: { type: 'number' },
    cashPercentage: { type: 'number' },
    sourceOfFundsOption: { type: 'string' },
    sourceOfFunds: { type: 'string' },
    customsClientNumber: { type: 'string' },
    signingAuthority: { type: 'string' },
    clientDueDilligence: { type: 'string' },
    isUSATaxResident: { type: 'string' },
    isRSATaxResident: { type: 'string' },
    taxCountry: { type: 'string' },
    taxIdentificationNumber: { type: 'string' },
    digitalSignatureReferences: { type: 'string' },
    internetBanking: { type: 'boolean' },
    bankingAlerts: { type: 'string' },
    requiresFecFacility: { type: 'boolean' },
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
    cifId: { type: 'integer', required: true },
    cifEntityId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const getApplicationCifEntity: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'bank'],
  permissions: [],
  description: 'Gets an application CIF Entity',
  method: 'get',
  collectionPath: '/client/{accountId}/application/{applicationId}/cif/{cifId}/entity',
  path: '/client/{accountId}/application/{applicationId}/cif/{cifId}/entity/{cifEntityId}',
  tags: ['application'],
  headers: {},
  responses: {
    200: { description: 'LfxApplicationCifEntity' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
    cifId: { type: 'integer', required: true },
    cifEntityId: { type: 'integer', required: true },
  },
};

export const updateApplicationAccount: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['captureClient'],
  description: 'Updates an application Account',
  method: 'post',
  collectionPath: '/client/{accountId}/application/{applicationId}/account',
  path: '/client/{accountId}/application/{applicationId}/account/{applicationAccountId}',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'Updated LfxApplicationCifAccount',
      message: 'Bank account application successfully updated',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    403: { description: 'Application Account Not Updated' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    interestPaymentType: { type: 'string', required: true },
    interestPaymentBranch: { type: 'string' },
    interestPaymentAccount: { type: 'string' },
    intermediaryCommissionId: { type: 'number', required: true },
    thresholdAmountCredit: { type: 'number' },
    thresholdAmountDebit: { type: 'number' },
    isResidentialAccount: { type: 'boolean', required: true },
    purposeOfAccountDropdown: { type: 'string' },
    purposeOfAccount: { type: 'string' },
    exchangeControlInfo: { type: 'string' },
    fCYAccountUtilisastionDetails: { type: 'string' },
    primaryUse: { type: 'string' },
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
    applicationAccountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const getApplicationAccount: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'bank'],
  permissions: [],
  description: 'Gets an application account',
  method: 'get',
  collectionPath: '/client/{accountId}/application/{applicationId}/account',
  path: '/client/{accountId}/application/{applicationId}/account/{applicationAccountId}',
  tags: ['application'],
  headers: {},
  responses: {
    200: { description: 'LfxApplicationAccount' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
    applicationAccountId: { type: 'integer', required: true },
  },
};

export const submitAccountApplication: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['submitClient'],
  description: 'Submits an Account application to the bank',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/account/{applicationAccountId}/submit',
  path: '/client/{accountId}/application/{applicationId}/account/{applicationAccountId}/submit',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplicationAccount',
      message: 'Bank account application successfully submitted',
      header: 'Success',
    },
    400: { description: 'Application Submit Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
    applicationAccountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const releaseAccountApplication: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseClient'],
  description: 'Releases an Account application',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/account/{applicationAccountId}/release',
  path: '/client/{accountId}/application/{applicationId}/account/{applicationAccountId}/release',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplicationAccount',
      message: 'Bank account application successfully released',
      header: 'Success',
    },
    400: { description: 'Application Release Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    accountNumber: {
      type: 'string',
      required: true,
    },
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
    applicationAccountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const rejectAccountApplication: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseClient'],
  description: 'Rejects an account application',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/account/{applicationAccountId}/reject',
  path: '/client/{accountId}/application/{applicationId}/account/{applicationAccountId}/reject',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplicationAccount',
      message: 'Bank account application successfully rejected',
      header: 'Success',
    },
    400: { description: 'Application Reject Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { rejectedReason: { type: 'string', required: true } },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
    applicationAccountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const cancelAccountApplication: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['submitClient'],
  description: 'Cancels an account application',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/account/{applicationAccountId}/cancel',
  path: '/client/{accountId}/application/{applicationId}/account/{applicationAccountId}/cancel',
  tags: ['application'],
  headers: {},
  responses: {
    200: {
      description: 'LfxApplicationAccount',
      message: 'Bank account application successfully cancelled',
      header: 'Success',
    },
    400: { description: 'Application Cancel Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { rejectedReason: { type: 'string', required: true } },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
    applicationAccountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const getClientApplicationDocuments: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a list of LfxApplicationDocument',
  method: 'patch',
  collectionPath: '/client/{accountId}/application/{applicationId}/document',
  path: '/client/{accountId}/application/{applicationId}/document',
  tags: ['application'],
  headers: {},
  responses: {
    200: { description: 'Array of LfxApplicationDocument' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
  },
};
export const uploadApplicationDocument: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['captureClient'],
  description: 'Uploads an Application Document',
  method: 'post',
  collectionPath: '/client/{accountId}/application/{applicationId}/document',
  path: '/client/{accountId}/application/{applicationId}/document',
  tags: ['application'],
  headers: {},
  responses: {
    200: { description: 'LfxApplicationDocument' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    documentTypeId: {
      type: 'number',
      required: true,
    },
    fileName: {
      type: 'object',
      format: 'binary',
      required: true,
    },
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
  reportAction: true,
};
export const cancelApplicationDocument: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['captureClient'],
  description: 'Cancels a client document',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/cancel',
  path: '/client/{accountId}/application/{applicationId}/documents/{applicationDocumentId}/cancel',
  tags: ['application'],
  headers: {},
  responses: {
    200: { description: 'LfxApplicationDocument' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
    applicationDocumentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};
export const downloadApplicationDocument: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: [],
  description: 'Downloads the Application Document',
  method: 'put',
  collectionPath: '/client/{accountId}/application/{applicationId}/documents',
  path: '/client/{accountId}/application/{applicationId}/documents/{applicationDocumentId}/download',
  tags: ['application'],
  headers: {},
  responses: {
    200: { type: 'download', description: 'File Stream' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
    applicationDocumentId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
  reportAction: true,
};

export const getClientApplicationDocument: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets an LfxApplicationDocument',
  method: 'get',
  collectionPath: '/client/{accountId}/application/{applicationId}/document/',
  path: '/client/{accountId}/application/{applicationId}/document/{applicationDocumentId}',
  tags: ['application'],
  headers: {},
  responses: {
    200: { description: 'Array of LfxApplicationDocument' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: {
    accountId: { type: 'integer', required: true },
    applicationId: { type: 'integer', required: true },
    applicationDocumentId: { type: 'integer', required: true },
  },
};

export const importBankAccount: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['captureClient'],
  description: 'Imports a bank account from Core for a client',
  method: 'put',
  collectionPath: '/client/{accountId}/bankAccountImport',
  path: '/client/{accountId}/bankAccountImport',
  tags: ['bankAccount'],
  headers: {},
  responses: {
    200: {
      description: 'Account Statement Results',
      message: 'Bank account successfully imported',
      header: 'Success',
    },
    400: { description: 'Account Statement Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    accountNumber: { type: 'string', required: true },
    description: { type: 'string', required: true },
  },
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const bankImportBankAccount: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['manageTocMandates'],
  description: 'Imports a bank account from Core for a client',
  method: 'put',
  collectionPath: '/client/{accountId}/bankAccountImportBank',
  path: '/client/{accountId}/bankAccountImportBank',
  tags: ['bankAccount'],
  headers: {},
  responses: {
    200: {
      description: 'Account Statement Results',
      message: 'Bank account successfully imported',
      header: 'Success',
    },
    400: { description: 'Account Statement Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    accountNumber: { type: 'string', required: true },
    description: { type: 'string', required: true },
  },
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const getAllClientBankAccounts: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: [],
  description: 'Gets all clients own bank accounts',
  method: 'patch',
  collectionPath: '/client/bankAccount',
  path: '/client/bankAccount',
  tags: ['bankAccount'],
  headers: {},
  responses: {
    200: { description: 'List of lfxBankAccount' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: {},
  implementedByFrontEnd: true,
};

export const getBankAccounts: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: [],
  description: 'Gets all bank accounts for a given account',
  method: 'patch',
  collectionPath: '/client/{accountId}/bankAccount',
  path: '/client/{accountId}/bankAccount',
  tags: ['bankAccount'],
  headers: {},
  responses: {
    200: { description: 'List of lfxBankAccount' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const downloadBankAccounts: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Generates a CSV of bank accounts available to the logged in user',
  method: 'put',
  collectionPath: '/client/{accountId}/bankAccountDownload',
  path: '/client/{accountId}/bankAccountDownload',
  tags: ['bankAccount'],
  headers: {},
  responses: {
    200: { type: 'download', description: 'File Stream' },
    202: {
      description: 'In Progress',
      message:
        'Your report is being generated and will be emailed to you when it is complete. Note that this report will be in .csv format.',
      header: 'Report in Progress',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
  reportAction: true,
};
export const getBankAccountsLogs: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: [],
  description: 'Gets all bank accounts logs for a given account',
  method: 'patch',
  collectionPath: '/client/{accountId}/bankAccountLogs',
  path: '/client/{accountId}/bankAccountLogs',
  tags: ['bankAccount'],
  headers: {},
  responses: {
    200: { description: 'List of lfxBankAccountLogs' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const getBankAccount: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: [],
  description: 'Gets a Bank Account by ID',
  method: 'get',
  collectionPath: '/client/{accountId}/bankAccount',
  path: '/client/{accountId}/bankAccount/{bankAccountId}',
  tags: ['bankAccount'],
  headers: {},
  responses: {
    200: { description: 'lfxBankAccount' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    bankAccountId: { type: 'integer', required: true },
  },
};
export const getBankAccountLogs: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: [],
  description: 'GetsBank account logs for a Bank Account by ID',
  method: 'patch',
  collectionPath: '/client/{accountId}/bankAccount/log',
  path: '/client/{accountId}/bankAccount/{bankAccountId}/log',
  tags: ['bankAccount'],
  headers: {},
  responses: {
    200: { description: 'List of lfxBankAccountLog' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    bankAccountId: { type: 'integer', required: true },
  },
};

export const updateClientBankAccount: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageClient'],
  description: 'Updates a clients own bank account',
  method: 'put',
  collectionPath: '/client/{accountId}//bankAccount/{bankAccountId}',
  path: '/client/{accountId}/bankAccount/{bankAccountId}',
  tags: ['bankAccount'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: {
      description: 'LfxBankAccount',
      message: 'Client Bank Account successfully updated',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    description: { type: 'string' },
    externalReference: { type: 'string' },
    notes: { type: 'string' },
    accountNumber: { type: 'string' },
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    bankAccountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const closeAccount: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseClient'],
  description: 'Closes an active BB Bank Account',
  method: 'put',
  collectionPath: '/client/{accountId}/bankAccount/{bankAccountId}/close',
  path: '/client/{accountId}/bankAccount/{bankAccountId}/close',
  tags: ['bankAccount'],
  headers: {},
  responses: {
    200: {
      description: 'LfxBankAccount',
      message: 'Bank account successfully closed',
      header: 'Success',
    },
    400: { description: 'Account Close Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    bankAccountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const reopenAccount: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseClient'],
  description: 'Reopens a closed BB Bank Account',
  method: 'put',
  collectionPath: '/client/{accountId}/bankAccount/{bankAccountId}/reopen',
  path: '/client/{accountId}/bankAccount/{bankAccountId}/reopen',
  tags: ['bankAccount'],
  headers: {},
  responses: {
    200: {
      description: 'LfxBankAccount',
      message: 'Bank account successfully re-opened',
      header: 'Success',
    },
    400: { description: 'Account Reopen Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    bankAccountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const downloadAccountConfirmation: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['viewAccountBalance'],
  description: 'Downloads the confirmation letter for the account',
  method: 'put',
  collectionPath: '/client/{accountId}/bankAccount/{bankAccountId}/downloadAccountConfirmation',
  path: '/client/{accountId}/bankAccount/{bankAccountId}/downloadAccountConfirmation',
  tags: ['bankAccount'],
  headers: {},
  responses: {
    200: { type: 'download', description: 'File Stream' },
    202: {
      description: 'File Requested',
      message:
        'Your file has been requested you will be notified when it is avilable.',
      header: 'File Requested',
    },
    400: { description: 'Account Confirmation Download Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    bankAccountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
  reportAction: true,
};
export const balanceEnquiry: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['viewAccountBalance'],
  description: 'Retrieves the balance of a bank account',
  method: 'put',
  collectionPath: '/client/{accountId}/bankAccount/{bankAccountId}/balance',
  path: '/client/{accountId}/bankAccount/{bankAccountId}/balance',
  tags: ['bankAccount'],
  headers: {},
  responses: {
    200: { description: 'Balance Enquirey Results' },
    400: { description: 'Balance Enquiry Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    bankAccountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const accountStatement: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['viewAccountBalance'],
  description: 'Retrieves a statement for a bank account',
  method: 'put',
  collectionPath: '/client/{accountId}/bankAccount/{bankAccountId}/statement',
  path: '/client/{accountId}/bankAccount/{bankAccountId}/statement',
  tags: ['bankAccount'],
  headers: {},
  responses: {
    200: { description: 'Account Statement Results' },
    202: {
      description: 'In Progress',
      message:
        'Your report is being generated and will be emailed to you when it is complete. Note that this report will be in .csv format.',
      header: 'Report in Progress',
    },
    400: { description: 'Account Statement Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    fromDate: { type: 'string', format: 'date', required: true },
    toDate: { type: 'string', format: 'date', required: true },
    reportType: { type: 'string', required: true },
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    bankAccountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
  reportAction: true,
};

export const stampedStatementList: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['viewAccountBalance'],
  description: 'Retrieves a list of available stamped statements for a bank account',
  method: 'put',
  collectionPath: '/client/{accountId}/bankAccount/{bankAccountId}/stampedStatementList',
  path: '/client/{accountId}/bankAccount/{bankAccountId}/stampedStatementList',
  tags: ['bankAccount'],
  headers: {},
  responses: {
    200: { description: 'Stamped Statement Results' },
    400: { description: 'Stamped Statement Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    fromDate: { type: 'string', format: 'date', required: true },
    toDate: { type: 'string', format: 'date', required: true },
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    bankAccountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
  reportAction: true,
};
export const downloadStampedStatement: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['viewAccountBalance'],
  description: 'Downloads the a stamped statment for the account',
  method: 'put',
  collectionPath: '/client/{accountId}/bankAccount/{bankAccountId}/downloadStampedStatement',
  path: '/client/{accountId}/bankAccount/{bankAccountId}/downloadStampedStatement',
  tags: ['bankAccount'],
  headers: {},
  responses: {
    200: { type: 'download', description: 'File Stream' },
    202: {
      description: 'File Requested',
      message:
        'Your file has been requested you will be notified when it is avilable.',
      header: 'File Requested',
    },
    400: { description: 'Stamped Statement Download Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    statementId: { type: 'string', required: true },
    token: {type: 'string', required: true}
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    bankAccountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
  reportAction: true,
};
export const downloadStampedStatementFromCallback: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['viewAccountBalance'],
  description: 'Downloads the a stamped statment for the account',
  method: 'put',
  collectionPath: '/client/{accountId}/bankAccount/{bankAccountId}/downloadStampedStatementFromCallback',
  path: '/client/{accountId}/bankAccount/{bankAccountId}/downloadStampedStatementFromCallback',
  tags: ['bankAccount'],
  headers: {},
  responses: {
    200: { type: 'download', description: 'File Stream' },
    202: {
      description: 'File Requested',
      message:
        'Your file has been requested you will be notified when it is avilable.',
      header: 'File Requested',
    },
    400: { description: 'Stamped Statement Download Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    documentId: { type: 'string', required: true },
    documentCreated: {type: 'string', required: true}
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    bankAccountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
  reportAction: true,
};
export const ensureBankAccountMandate: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['manageTocMandates'],
  description: 'Ensures that a bank accounts mandate is in place',
  method: 'put',
  collectionPath: '/client/{accountId}/bankAccount/{bankAccountId}/ensureBankAccountMandate',
  path: '/client/{accountId}/bankAccount/{bankAccountId}/ensureBankAccountMandate',
  tags: ['bankAccount'],
  headers: {},
  responses: {
    200: {
      description: 'Account Mandate Results',
      message: 'Bank account mandate updated',
      header: 'Success',
    },
    400: { description: 'Account Statement Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: { accountId: { type: 'integer', required: true }, bankAccountId: { type: 'integer', required: true }, },
  implementedByFrontEnd: true,
};

export const downloadSavedAccountDownloadDocument: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: [],
  description: 'Downloads a saved document generated for this client',
  method: 'put',
  collectionPath: '/client/{accountId}/downloadSavedAccountDownloadDocument',
  path: '/client/{accountId}/downloadSavedAccountDownloadDocument',
  tags: ['bankAccount'],
  headers: {},
  responses: {
    200: { type: 'download', description: 'File Stream' },
    202: {
      description: 'File Requested',
      message:
        'Your file has been requested you will be notified when it is avilable.',
      header: 'File Requested',
    },
    400: { description: 'Download Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    savedDocumentId: {type: 'string', required: true}
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
  reportAction: true,
};

export const getTocMandate: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: [],
  description: 'Gets an application and all its associated information',
  method: 'get',
  collectionPath: '/client/{accountId}',
  path: '/client/{accountId}/tocMandate',
  tags: ['client'],
  headers: {},
  responses: {
    200: { description: 'Mandate Info' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const updateTocMandate: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['manageTocMandates'],
  description: 'Updates the TOC in the mandates database',
  method: 'put',
  collectionPath: '/client/{accountId}/',
  path: '/client/{accountId}/tocMandate',
  tags: ['client'],
  headers: {},
  responses: {
    200: { description: 'Mandate' },
    400: { description: 'Error Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true
};