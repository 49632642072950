<template>
  <div class="lfx-widget-row-container-rate" :style="getRatesFeedStyle">
    <!-- {{rate}} -->
    <!-- <capitec-avatar v-if="avatar !== ''" class="widget-row-avatar" type="strong" :value="avatarLetters" :color="avatarColor" size="small" />   -->

    <div class="ask-flag" />

    <capitec-label class="widget-row-title ask-label" type="strong" label="ASK" />
    <capitec-label class="widget-row-subtitle ask-value" type="default" :label="useRate.ask" />
    <capitec-label class="widget-row-subtitle ask-currency" type="subtitle" :label="sellCurrency" />

    <div class="bid-flag" />
    <capitec-label class="widget-row-title bid-label" type="strong" label="BID" />
    <capitec-label class="widget-row-subtitle bid-value" type="default" :label="useRate.bid" />
    <capitec-label class="widget-row-subtitle bid-currency" type="subtitle" :label="buyCurrency" />

    <!-- <capitec-label class="widget-row-info" type="strong" :label="info" />  -->

    <!-- <router-link class="widget-row-arrow capitec-hyperlink widget"  :to="`${link}`" tag="capitec-hyperlink" > -->

    <!-- <capitec-icon icon="system/chevron-right-action"  size="small" /> -->

    <!-- </router-link> -->
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  components: {},
  props: {
    rate: { type: Object, required: true },
    // link:{type:String,required:true},
    // title:{type:String,required:true},
    // subtitle:{type:String,default:''},
    // info:{type:String,default:''},
    // avatar:{type:String,default:''},
    // avatarColor:{type:String,default:'#CCC'}
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['authUser', 'account']),

    // ----- Account -----

    myAccount() {
      return this.account?.[this.authUser?.accountId];
    },

    // ----- Markup -----

    currencyPairId() {
      return this.pair?.split('/')?.join('_');
    },
    markup() {
      const markups = this.myAccount?.markup || {};
      for (const markupId in markups) {
        const markup = markups[markupId];
        if (markup.currencyPair === this.currencyPairId) {
          return markup;
        }
      }
      return undefined;
    },

    // ----- Rate -----

    useRate() {
      if (this.authUser?.accountLevel === 'client') {
        return {
          bid: this.markup ? this.markup?.bidRate : 'Loading...',
          ask: this.markup ? this.markup?.askRate : 'Loading...',
        };
      }
      return this.rate;
    },

    // ----- ------

    title() {
      return this.rate.ric;
    },
    subtitle() {
      return this.rate.bid;
    },
    info() {
      return this.rate.ask;
    },
    link() {
      return this.rate.ric;
    },
    avatarLetters() {
      let res = this.avatar
        .split(' ')
        .map((s) => s[0])
        .join('')
        .toUpperCase();
      return res;
    },

    getRatesFeedStyle() {
      return {
        '--sell-currency-image': `url(/images/square_flags/${this.sellCurrencyCountry}.svg)`,
        '--buy-currency-image': `url(/images/square_flags/${this.buyCurrencyCountry}.svg)`,
      };
    },
    buyCurrencyCountry() {
      return this.buyCurrency.substr(0, 2).toLowerCase();
    },
    sellCurrencyCountry() {
      return this.sellCurrency.substr(0, 2).toLowerCase();
    },
    direction() {
      if (this.midRate < this.firstMidRate) {
        return 'down';
      } else {
        return 'up';
      }
    },
    midRate() {
      return (this.rate.ask + this.rate.bid) / 2;
    },
    firstMidRate() {
      return (this.rate.todayFirstTickBid + this.rate.todayFirstTickAsk) / 2;
    },
    pair() {
      let rateCurrencies = this.rate.ric.split('=')[0];
      if (rateCurrencies.length === 3) {
        rateCurrencies = `USD${rateCurrencies}`;
      }
      let buy = rateCurrencies.substr(0, 3);
      let sell = rateCurrencies.substr(3, 6);
      return `${buy}/${sell}`;
    },
    buyCurrency() {
      return this.pair.split('/')[0];
    },
    sellCurrency() {
      return this.pair.split('/')[1];
    },
    buy() {
      let buy = `${Math.floor((this.rate.rate + this.spread) * 1000) / 1000}`;
      while (buy.length < 6) {
        buy = buy + '0';
      }
      return buy;
    },
    sell() {
      let sell = `${Math.floor((this.rate.rate - this.spread) * 1000) / 1000}`;
      while (sell.length < 6) {
        sell = sell + '0';
      }
      return sell;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
<style>
div.lfx-widget-row-container-rate {
  /* max-width: 300px; */
  border-top: 1px solid #eee;
  display: grid;
  grid-template-areas:
    'bid-flag bid-currency bid-label ask-label ask-currency ask-flag'
    'bid-flag bid-currency bid-value ask-value ask-currency ask-flag'
    'bid-flag bid-currency bid-value ask-value ask-currency ask-flag' !important;
  grid-template-columns: 40px auto auto auto auto 40px;
  grid-template-rows: 15px 15px 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  /* border: 1px solid red; */
  align-content: center;
  grid-column-gap: 5px;
}

.ask-label {
  grid-area: ask-label;
  /* border:1px solid green; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.ask-value {
  grid-area: ask-value;
  /* border:1px solid blue; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.bid-label {
  /* border: 1px solid teal; */
  grid-area: bid-label;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bid-value {
  grid-area: bid-value;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bid-currency {
  grid-area: bid-currency;
  align-items: center;
  justify-content: center;
  /* border: 1px solid green; */
}

.ask-currency {
  /* border: 1px solid green; */
  grid-area: ask-currency;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bid-flag {
  max-height: 30px;
  min-height: 30px;
  max-width: 30px;
  min-width: 30px;
  grid-area: bid-flag;
  background: var(--buy-currency-image);
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 100px;
  border: 1px solid #ccc;
}

.ask-flag {
  grid-area: ask-flag;
  max-height: 30px;
  min-height: 30px;
  max-width: 30px;
  min-width: 30px;
  background: var(--sell-currency-image);
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 100px;
  border: 1px solid #ccc;
}
</style>
