<template>
  <LDBModal result="info" :header="header" size="xx-large">
    <template #body>
      <LfxUserAccessReport/>
    </template>
    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :enabledGuards="button.enabledGuards"
        :definitionName="button.definitionName"
        @click="button.clickEvent"
      ></LDBButton>
    </template>

  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LfxUserAccessReport from '@/views/reports/LfxUserAccessReport.vue'

export default {
  name: 'LfxUserAccessReportModal',
  components: { LfxUserAccessReport },
  props: {},
  data() {
  },
  created() {
  },
  mounted() {
  },
  computed: {
        // ----- Buttons -----

        formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelAddIntermediary',
          label: 'Cancel',
          type: '',
          action: 'click',
          clickEvent() {
            self.$router.go(-1);
          },
        },
      ];
      return allButtons;
    },
  },
  methods: {
  },
};
</script>

<style></style>
