<template>
  <div>
    <LFXListView :titleBar="titleBar" :definition="definition" :records="records" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXListView from '@/views/general/LFXListView.vue';
//
export default {
  name: 'LfxConfigUserTeams',
  components: { LFXListView },
  props: {},
  data() {
    return {
      titleBar: {
        label: 'UserTeams',
        type: 'default',
        buttons: {},
      },
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    // ...mapState(['country']),
    // ...mapState({ country: 'config[1].country' }),
    records() {
      return this.$store.state.config[1].userTeam;
    },
    definition() {
      return getDefinition('userTeam');
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>

<style>
/* div.container {} */
</style>
