//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2022 LandoByte (Pty) Ltd.
//  File        : LfxPdfAccountStatement.ts
//  Author      : Bevan Timm
//  Description : This module builds an Account Statement PDF for the LandoFX System
//  Created     : 15 June 2022 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { TDocumentDefinitions } from 'pdfmake/interfaces';

import { renderPdf } from '@/utils/LdbFrontEndPdf';
import { formatCurrencyString, roundTo } from '../sharedUtils/LdbSharedUtils';

//----------------------------------------------------------------------------------------------------------------------

export const buildStatementPdf = async (
  clientName: string,
  statementData: {
    accountNumber: string,
    productName: string,
    currencyCode: string,
    accountName: string,
    address: {
      address1: string,
      address2: string,
      address3: string,
      address4: string,
    }
    balanceBroughtForward: number,
    transactionDetails: {
      tranDate: string,
      tranType: string,
      reference: string,
      tranAmount: string,
      fees: string,
      balance: string|"",
    }[],
  },
  fromDate: Date,
  toDate: Date
) => {
  const fileName =
    new Date().toISOString().slice(0, 23).replace(':', '-').replace(':', '-').replace('.', '-') +
    statementData.accountNumber;
  const s3Key = `statements/${statementData.accountNumber}/${fileName}.pdf`;

  const currencyCode = statementData.currencyCode;
  const accountName = statementData.accountName;
  const address1 = statementData.address?.address1;
  const address2 = statementData.address?.address2;
  const address3 = statementData.address?.address3;
  const address4 = statementData.address?.address4;
  const balanceBroughtForward = formatCurrencyStringForPdf(statementData.balanceBroughtForward);


  var totalAmount = 0;
  var totalFees = 0;
  var lastBalance;
  const tableRows: any = [['Date', 'Description', 'Reference', 'Amount', 'Fees', 'Balance']];
  for (const transaction of statementData.transactionDetails.reverse()) {
    tableRows.push([
      transaction.tranDate || '',
      transaction.tranType || '',
      transaction.reference || '',
      formatCurrencyStringForPdf(transaction.tranAmount) || '',
      formatCurrencyStringForPdf(transaction.fees) || '',
      formatCurrencyStringForPdf(transaction.balance || undefined) || '',
    ]);
    if (transaction.tranAmount) {
      totalAmount += (+(transaction.tranAmount.replaceAll(",","")));
    }
    if (transaction.fees) {
      totalFees += +transaction.fees;
    }
    if (transaction.balance !== undefined && transaction.balance !== "") {
      lastBalance = transaction.balance;
    }
  }
  tableRows.push([
    { text: 'Total', style: 'bold' },
    { text: '', style: 'bold' },
    { text: '', style: 'bold' },
    { text: formatCurrencyStringForPdf(roundTo(totalAmount, 2)), style: 'bold' },
    { text: formatCurrencyStringForPdf(roundTo(totalFees, 2)), style: 'bold' },
    { text: formatCurrencyStringForPdf(lastBalance), style: 'bold' },
  ]);

  const docDefinition: TDocumentDefinitions = {
    pageSize: 'A4',
    pageMargins: [40, 100, 40, 60],
    defaultStyle: {
      font: 'Roboto',
      fontSize: 10,
      margin: [3, 3, 3, 3],
    },
    styles: {
      header: {
        fontSize: 22,
        bold: true,
      },
      disclamerText: {
        alignment: 'center',
        fontSize: 6,
        margin: [10, 10, 10, 3],
      },
      footerText: {
        fontSize: 8,
      },
      rightme:
        {   
            alignment: 'right'
        },
      bold: { bold: true },
    },
    images: {
      capitecLogo: `${window.location.origin}/images/CapitecLogo_Primary_Colour2x.png`,
    },
    header: {
      columns: [
        { text: 'Transaction History', style: 'header', alignment: 'left', margin: [30, 30, 3, 3] },
        {          
          columns: [
            {
              image: 'capitecLogo',
              fit: [175, 175],
              alignment: 'right'
            },
          ],
        },
      ],
    },
    footer: (currentPage: number, pageCount: number) => {
      return {
        stack: [
          {
            text: 'Capitec Business Bank a division of Capitec Bank Limited Registration Number: 1980/003695/06. An authorised financial services provider (FSP46669) and registered credit provider (NCRCP13).',
            style: 'disclamerText',
          },
          {
            columns: [
              {
                text: `Page ${currentPage}/${pageCount}`,
                style: 'footerText',
                alignment: 'left',
                margin: [30, 3, 3, 10],
              },
              { text: `${new Date().toDateString()}`, style: 'footerText', alignment: 'right', margin: [3, 3, 30, 10] },
            ],
          },
        ],
      };
    },
    content: [
      { text: clientName },
      { text: address1 },
      { text: address2 },
      { text: address3 },
      { text: address4 },
      {
        text: [
          'Transaction From ',
          { text: (new Date(fromDate)).toDateString(), style: 'bold' },
          ' To ',
          { text: (new Date(toDate)).toDateString(), style: 'bold' },
        ],
        margin: [3, 10, 3, 3],
      },
      { text: ['Account Number: ', { text: `${statementData.accountNumber} ${accountName}`, style: 'bold' }], margin: [3, 3, 3, 3] },
      { text: ['CURRENCY: ', { text: `${currencyCode}`, style: 'bold' }], margin: [3, 3, 3, 3] },

      { text: `Balance brought forward: ${balanceBroughtForward}`, margin: [3, 10, 3, 3] },
      {
        layout: 'lightHorizontalLines',
        margin: [3, 10, 3, 3],
        table: {
          headerRows: 1,
          widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
          body: tableRows,
        },
      },
    ],
  };
  await renderPdf({
    encryption: {
      userPassword: statementData.accountNumber.toString()
    },
    documentDefinition: docDefinition,
    target: {
      fileName: fileName,
    },
  });
  return s3Key;
};

const formatCurrencyStringForPdf = (currencyAmount:number|string|undefined) => {
  if (typeof currencyAmount === 'number') {
    return formatCurrencyString(currencyAmount * 100)
  }
  return currencyAmount
}
