<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <div v-if="userCanBeEnabled">
        <p>Enable the user as a signatory.</p>
      </div>
      <div v-else>
        <p>The user cannot be enabled as a signatory because the following information is outstanding.</p>
        <ul>
          <li v-for="field in missingFields" :key="field">
            {{field}}
          </li>
        </ul>
      </div>
      
    </template>
    <template #footer>
      <LDBButton
        v-for="button in buttons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getDefinition } from '@/db-interface';
import lodash from 'lodash';
const merge = lodash.merge;

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxEnableUserSignatory',
  components: { LDBField },
  props: {},
  emits: {},
  created() {},
  mounted() {},
  // beforeUnmount() {
  //   this.record.revert();
  // },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    header() {
      // return `Enable Signatory for User: ${this.record.userDisplayName}`;
      return 'Enable User as Signatory';
    },
    buttons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelEnableUserSignatory',
          label: 'Cancel',
          type: '',
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'enableUserSignatory',
          label: 'Enable User Signatory',
          type: 'primary',
          action: 'enableUserSignatory',
          params: { userId: this.record.id },
          actionParams: { userId: this.record.id },
          enabledGuards: this.userCanBeEnabled,
          clickEvent(response) {
            if (response.status === 'success') {
              self.$router.replace({
                name: 'userSignatoryView',
                params: { userId: self.recordId, signatoryId: response?.response?.id },
              });
            }
          },
        },
      ];
      return allButtons;
    },

    // accountIdHasChanged() {
    //   return this.record?.changed_fields?.some((field) => field.name === 'accountId');
    // },

    // // ----- Definition -----

    // definition() {
    //   return getDefinition('user');
    // },

    // ----- Record -----

    recordName() {
      return 'user';
    },
    recordId() {
      return +this.$route.params.userId;
    },
    record() {
      let rec = this.$store.state?.[this.recordName]?.[this.recordId];
      return rec;
    },
    userCanBeEnabled() {
      return this.missingFields.length === 0
    },

    userDisplayName() {
      return this.record?.userDisplayName;
    },

    requiredFields() {return ['firstName','surname','emailAddress','msisdn','idNumber'] },
    missingFields() {
      const missingFields = [];
      for (const requiredField of this.requiredFields) {
        if (!this.record[requiredField]) {
          missingFields.push(requiredField)
        }
      }
    return missingFields
   },

    // // ----- Fields -----

    // fields() {
    //   return this.definition.fields;
    // },
    // view() {
    //   return 'moveUser';
    // },
    // viewFields() {
    //   let res = [];
    //   for (let k in this.fields) {
    //     let field = this.fields[k];
    //     if (field.views[this.view] === true) {
    //       field.fieldname = k;
    //       res.push(this.overrideField(field));
    //     }
    //   }
    //   return res;
    // },
  },
  methods: {
    //   //
    //   // ----- Fields -----
    //   on_change({ field, value }) {
    //     this.record[field] = value;
    //   },
    //   overrideField(field) {
    //     if (field.name === 'accountId') {
    //       return this.overrideAccountId(field);
    //     } else return field;
    //   },
    //   overrideAccountId(field) {
    //     let newField = merge({}, field);
    //     let newAccountIdField = merge(newField, {
    //       datatype: {
    //         foreignKey: {
    //           guards: {
    //             accountLevel: {
    //               in: ['intermediary', 'intermediaryBranch'],
    //             },
    //             intermediaryId(record) {
    //               return { eq: record.intermediaryId };
    //             },
    //             id(record) {
    //               return { notEq: record.accountId };
    //             },
    //           },
    //         },
    //       },
    //     });
    //     newAccountIdField.label = 'Move to Account';
    //     return newAccountIdField;
    //   },
  },
};
</script>
