<template>
  <LDBModal type="result" result="info" :header="header">
    <template #body>
    <capitec-file-upload label="Select CSV File" @selection-change="onSelectionChange" fileTypes="text/csv" hint="Jannie">
    
    </capitec-file-upload>
    </template>
    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :enabledGuards="button.enabledGuards"
        @on-api-success="button.onApiSuccess"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>


<script>

import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import LDBButton from '@/landobyte_vue/LDBButton.vue';

export default {
  name: 'LfxBulkFeeBatchUpload',
  components: { LDBItemView,LDBButton },
  data() {
    return {files:null}
  },
  computed: {
    header() {
      return "Bulk Fee Upload"
    },
    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          action: 'click',
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'uploadBulkFeeBatchFile',
          label: 'Submit',
          type: 'primary',
          action: 'uploadBulkFees',
          params: { accountId: this.accountId},
          onApiSuccess(response) {
            const batchId = response.response.id;
            self.$router.push({
                name: 'accountBulkFeesDetails',
                params: { accountId: self.accountId, bulkFeeBatchId: batchId },
              })
          },
          actionParams: {fileName:this.files},
          clickEvent(response) {
  
          }
        },
      ];
      return allButtons;
    },
    accountId() {
      return +this.$route.params.accountId;
    },
    
  },
  methods: {
    onSelectionChange(evt) {
      this.files = evt.detail.files[0];
    }
  },
};
</script>
