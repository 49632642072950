<template>
  <component :is="whiteLabelComponent" >
  <slot>  </slot>
  </component>
</template>

<script>

import {mapState} from "vuex";

import LfxWhiteLabelDefault from './LfxWhiteLabelDefault.vue';

export default {
  components: {LfxWhiteLabelDefault},
  props: {
  },
  data() {
    return {
    }
  },
  created() {
    
  },
  mounted() {

  },
  computed:{
  	...mapState(['whitelabel']),
    whiteLabelComponent() {
      return 'LfxWhiteLabelDefault';
    }
  },
  methods:{
  }
 }

</script>

<style>

</style>
