//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxIntermediarySettingsDef.ts
//  Author      : Bevan Timm
//  Description : This module defines the intermediary specific settings for the LandoFX System
//  Created     : 14 August 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import { standardTextWhiteList } from '../sharedUtils/LdbValidations';
import type { LdbDefinition } from './LdbInterfaces';
import { isBankUser } from './LfxAccountDef';

//----------------------------------------------------------------------------------------------------------------------

export type LfxIntermediarySettingsSimplifiedTradingOptions = 'none' | 'fixesMarkup' | 'fixedRate';
export type LfxIntermediarySettingsMigrationStatus = 'notMigrated' | 'migrated' | 'createdLocally';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxIntermediarySettingsInt {
  id?: number;
  uuid?: string;
  accountId?: number;
  systemUserId?: number;

  bpNumber?: string;
  commissionAccount?: string;
  organisation?: string;
  mayCompleteOwnKyc?: boolean;
  mayProcessBulkFees?: boolean;
  allowClientsToSubmitPortfolios?: boolean;
  bankDirectClients?: boolean;
  allowOnboardingWithIncompleteData?: boolean;
  enforceSimplifiedTrading?: LfxIntermediarySettingsSimplifiedTradingOptions;

  migrationStatus?: LfxIntermediarySettingsMigrationStatus;
  lastUserReviewDate?: Date;
  lastUserReviewBy?: number;

  clientReportEnable?: boolean;
  clientReportS3Key?: string;
  clientReportTimestamp?: Date;
  accountBalanceReportEnable?: boolean;
  accountBalanceReportS3Key?: string;
  accountBalanceReportTimestamp?: Date;
  dailyDealReportEnable?: boolean;
  dailyDealReportS3Key?: string;
  dailyDealReportTimestamp?: Date;
  // Temporary Feature Switches
  intermediaryHasOwnSignUpForm?: boolean; // Temporarily used to switch onboardingDocumentUpload between Local and SigniFlow
  intermediaryCanSeeStampedStatements?: boolean,
  intermediaryCanSeeAccountConfirmations?: boolean,
  intermediaryMayTakeMarkupsOnFecMod?:boolean,
  // End

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxIntermediarySettingsDef: LdbDefinition = {
  title: 'Intermediary Settings',
  table: 'LfxIntermediarySettings',
  // collectionPath: null,
  collectionPath:"/IntermediarySettings",
  addToStoreIfNotExist:true,
  view: { create: false, edit: true, item: true, list: false, delete: false, csv: false },
  indexes: [],
  pagingType: 'backEnd',
  fields: {
    id: {
      label: 'ID',
      datatype: { id: { descriptionField: 'name' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    uuid: {
      label: 'UUID',
      datatype: 'uuid',
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
    },
    accountId: {
      label: 'Account',
      datatype: 'integer',
      // datatype:{
      //   foreignKey: {
      //     linkTable:'LfxAccount',
      //     linkField:'id',
      //     displayField:'name',
      //   }
      // },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    systemUserId: {
      label: 'System User',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
          linkInSql: false,
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },

    bpNumber: {
      label: 'BP Number',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      validators: {
        isNumeric: { msg: 'Please use only numeric charaters' },
      },
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
    },
    commissionAccount: {
      label: 'Commission Account',
      datatype: 'text',
      default: null,
      mandatory: true,
      validators: {
        isNumeric: { msg: 'Please use only numeric charaters' },
        len: { msg: 'Commission Account should be 10 characters', args: [10, 10] },
      },
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
    },
    organisation: {
      label: 'Intermediary Organisation',
      datatype: 'text',
      default: null,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alpha charaters', args: [standardTextWhiteList] },
        len: { msg: 'Organisation should be between 2 and 50 characters', args: [2, 50] },
      },
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
    },

    mayCompleteOwnKyc: {
      label: 'May Complete Own KYC',
      datatype: 'boolean',
      default: false,
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
    },
    mayProcessBulkFees: {
      label: 'May Process Bulk Fees',
      datatype: 'boolean',
      default: false,
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
    },
    allowClientsToSubmitPortfolios: {
      label: 'Allow Clients to Submit Portfilios - Bank Only',
      datatype: 'boolean',
      default: false,
      mandatory: false,
      allowNullValues: true,
      views: { create: isBankUser, edit: isBankUser, item: isBankUser, list: isBankUser, delete: false, csv: false },
    },
    bankDirectClients: {
      label: 'Bank Own Clients - Bank Only',
      datatype: 'boolean',
      default: false,
      mandatory: false,
      allowNullValues: true,
      views: { create: isBankUser, edit: isBankUser, item: isBankUser, list: isBankUser, delete: false, csv: false },
    },
    allowOnboardingWithIncompleteData: {
      label: 'Allow Onboarding with Incomplete Data - Bank Only',
      datatype: 'boolean',
      default: false,
      mandatory: false,
      allowNullValues: true,
      views: { create: isBankUser, edit: isBankUser, item: isBankUser, list: isBankUser, delete: false, csv: false },
    },
    enforceSimplifiedTrading: {
      label: 'Enforce Simplified Trading - Bank Only',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'none', name: 'None', tagType: 'info' },
            { id: 'fixesMarkup', name: 'Fixed Markup', tagType: 'warning' },
            { id: 'fixedRate', name: 'Fixed Rate', tagType: 'warning' },
          ],
        },
      },
      default: 'none',
      mandatory: true,
      allowNullValues: true,
      views: {
        create: isBankUser,
        edit: isBankUser,
        item: isBankUser,
        list: false,
        delete: false,
        search: false,
        csv: false,
      },
    },
    migrationStatus: {
      label: 'Migration Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'notMigrated', name: 'Not Migrated', tagType: 'info' },
            { id: 'migrated', name: 'Migrated', tagType: 'success' },
            { id: 'createdLocally', name: 'Created Locally', tagType: 'success' },
          ],
        },
      },
      default: 'createdLocally',
      mandatory: true,
      allowNullValues: true,
      views: {
        create: isBankUser,
        edit: isBankUser,
        item: isBankUser,
        list: false,
        delete: false,
        search: false,
        csv: false,
      },
    },
    lastUserReviewDate: {
      label: 'Last User Review Timestamp',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },
    lastUserReviewBy: {
      label: 'Last User Review By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
          linkInSql: false,
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    clientReportEnable: {
      label: 'Enable Client Report',
      datatype: 'boolean',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: true, item: true, list: true, delete: false, csv: false },
    },
    clientReportS3Key: {
      label: 'Client Report',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    clientReportTimestamp: {
      label: 'Latest Client Report',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    accountBalanceReportEnable: {
      label: 'Enable Account Balance Report',
      datatype: 'boolean',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: true, item: true, list: true, delete: false, csv: false },
    },
    accountBalanceReportS3Key: {
      label: 'Account Balance Report',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    accountBalanceReportTimestamp: {
      label: 'Latest Account Balance Report',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    dailyDealReportEnable: {
      label: 'Enable Daily Deal Report',
      datatype: 'boolean',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: true, item: true, list: true, delete: false, csv: false },
    },
    dailyDealReportS3Key: {
      label: 'Daily Deal Report',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    dailyDealReportTimestamp: {
      label: 'Latest Daily Deal Report',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },

    // Temporary Feature Switches
    intermediaryHasOwnSignUpForm: {
      label: 'Intermediary uses Local Onboarding.', //'Intermediary has own Sign Up Form',
      datatype: 'boolean',
      default: false,
      mandatory: false,
      allowNullValues: true,
      views: { create: isBankUser, edit: isBankUser, item: isBankUser, list: isBankUser, delete: false, csv: false },
    },
    intermediaryCanSeeStampedStatements: {
      label: 'Intermediary can see Stamped Statements.',
      datatype: 'boolean',
      default: false,
      mandatory: false,
      allowNullValues: true,
      views: { create: isBankUser, edit: isBankUser, item: isBankUser, list: isBankUser, delete: false, csv: false },
    },
    intermediaryCanSeeAccountConfirmations: {
      label: 'Intermediary can see Account Confirmations',
      datatype: 'boolean',
      default: false,
      mandatory: false,
      allowNullValues: true,
      views: { create: isBankUser, edit: isBankUser, item: isBankUser, list: isBankUser, delete: false, csv: false },
    },
    intermediaryMayTakeMarkupsOnFecMod: {
      label: 'Intermediary can take a markup on FEC Modifications',
      datatype: 'boolean',
      default: false,
      mandatory: false,
      allowNullValues: true,
      views: { create: isBankUser, edit: isBankUser, item: isBankUser, list: isBankUser, delete: false, csv: false },
    },
    // End

    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
          linkInSql: false,
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
          linkInSql: false,
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
  },
};
