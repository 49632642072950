<template>
  <LFXListView :definition="definition" :records="records" />
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXListView from '@/views/general/LFXListView.vue';

export default {
  name: 'LfxAccountLogs',
  components: { LFXListView },
  props: {},
  data() {
    return {};
  },
  created() {
    this.account[this.viewedAccountId].getApplications();
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),
    viewedAccountId() {
      return +this.$route.params.accountId;
    },
    records() {
      let res = {};
      for (let b in this.account[this.viewedAccountId].application) {
        let ben = this.account[this.viewedAccountId].application[b];
        res[ben.id] = ben;
      }
      return res;
    },

    definition() {
      return getDefinition('application');
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
