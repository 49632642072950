import VueStore from 'vue-class-store';

import { LdbDbObject, LdbDefinition } from '@/definitions/LdbInterfaces';
import { LfxIntermediarySettingsInt, LfxIntermediarySettingsDef } from '@/definitions/LfxIntermediarySettingsDef';
import { LfxModel, LfxDefinition } from './LfxModel';

//@ts-ignore
import { http_patch, http_get, http_put } from '@/lfx_rest';

@VueStore
//@ts-ignore
class LfxIntermediarySettingsMdl extends LfxDefinition {
  //@ts-ignore
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
//@ts-ignore
export class LfxIntermediarySettings extends LfxModel {
  //@ts-ignore
  constructor(data, state) {
    super({ definition_name: 'intermediarySettings', ...data }, state);
  }
  processAfterEvent() {

    //@ts-expect-error
    const account = this.store.state.account[this.accountId];
    if (account) {
      if (!account.IntermediarySettings) {
        account.IntermediarySettings = {}
      }
      //@ts-expect-error
      for (const field in this.data) {
        
        if (['commissionAccount','organisation','mayCompleteOwnKyc','mayProcessBulkFees','allowClientsToSubmitPortfolios',
          'bankDirectClients','allowOnboardingWithIncompleteData','enforceSimplifiedTrading','intermediaryHasOwnSignUpForm',
          'intermediaryCanSeeStampedStatements','intermediaryCanSeeAccountConfirmations','intermediaryMayTakeMarkupsOnFecMod',
          'migrationStatus','clientReportEnable','clientReportS3Key','clientReportTimestamp','accountBalanceReportEnable',
          'accountBalanceReportS3Key','accountBalanceReportTimestamp','dailyDealReportEnable','dailyDealReportS3Key','dailyDealReportTimestamp',
          'lastUserReviewDate', 'lastUserReviewBy']
          //@ts-expect-error
          .includes(field) && typeof this.data[field] !== 'function') {
          //@ts-expect-error
          account[field] = this.data[field]
          //@ts-expect-error
          account.IntermediarySettings[field] = this.data[field]
        }
      }
      //@ts-expect-error
      if (this.store?.state?.authUser?.accountId && this.store.state.authUser.accountId === this.accountId )
        {
          //@ts-expect-error
          if (!this.store?.state?.dbIntermediarySettings?.updatedAt || this.store.state.dbIntermediarySettings.updatedAt < this.updatedAt && this.data) {
            //@ts-expect-error
            this.store.state.dbIntermediarySettings = {...this.data}
          }
        }  
    }
  }
}

//@ts-ignore
export function Definition(context, name: string) {
  return new LfxIntermediarySettingsMdl(LfxIntermediarySettingsDef, LfxIntermediarySettings, context, name, {});
}
