<template>
  <div>
    <LFXListView :definition="definition" :records="records" />
  </div>
  <RouterView />
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXListView from '@/views/general/LFXListView.vue';
import { doPermissionAction } from '@/lfx_rest/lfx_action';

export default {
  name: 'LfxConfigBopCategoriesList',
  components: { LFXListView },
  props: {},
  created() {
    if (!this.$store.state.config[1].bopCat) {
      doPermissionAction({}, 'getBopCats', { configId: 1 });
    }
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    records() {
      return this.$store.state.config[1].bopCat || {};
    },
    definition() {
      return getDefinition('bopCat');
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>

<style>
/* div.container{  } */
</style>
