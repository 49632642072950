import { Component, css, html } from '../../elements';

/**
 * Component that contains updates or messages about the status of the system or process. 
 * These are usually considered as feedback from the system based on user input.
 * 
 * ```js
 * import 'platform/components/popups/Toast';
 * ```
 * 
 * ```html
 * <capitec-toast type="info" header="Toast Title" detail="Short Description Text" closable>
 * </capitec-toast>
 * ```
 * @prop {"info"|"success"|"warning"|"error"|String} type - The type of toast to display.
 */
export class Toast extends Component {

	// --------------
	// INITIALISATION
	// --------------

	/**
	 * Initialises the component.
	 *
	 * @hideconstructor
	 */
	constructor() {

		super();

		this.type = `info`;
		this.header = ``;
		this.detail = ``;
		this.closable = false;
	}

	// ----------
	// PROPERTIES
	// ----------

	/**
	 * Registry of all properties defined by the component.
	 * 
	 * @property {String} [header] - The title to display on the toast.
	 * @property {String} [detail] - The detail to display on the toast.
	 * @property {String} [type="info"] - The type of toast to display:
	 * - `info` Best suited for informational purposes.
	 * - `success` Best suited for successful outcomes.
	 * - `warning` Best suited for warning outcomes.
	 * - `error` Best suited for error outcomes.
	 * @property {Boolean} [closable=false] - Whether the toast can be closed/close button visible.
	 */
	static get properties() {

		return {
			header: { type: String },
			detail: { type: String },
			type: { type: String },
			closable: { type: Boolean }
		};
	}

	// -------------------
	// LIFECYCLE OVERRIDES
	// -------------------

	// n/a    

	// --------------
	// EVENT HANDLERS
	// --------------

	_closeClicked(e) {

		// Notify any subscribers that the close button was clicked.
		this.dispatchEvent(new CustomEvent(`click`, {
			detail: {}
		}));
	}

	// --------------
	// PUBLIC METHODS
	// --------------

	// n/a

	// ---------------
	// PRIVATE METHODS
	// ---------------

	// n/a

	// ---------
	// RENDERING
	// ---------

	/**
	 * Generates the component stylesheet.
	 * 
	 * @returns {css} The css content of the component.
	 */
	static get styles() {

		return [
			super.styles,
			css`
                :host {
                    display: block;

                    border-radius: 0px;
                }

                .container {
                    min-width: var(--theme-toast-min-width, 300px);
                    max-width: var(--theme-toast-max-width, 640px);
                    width: auto;

                    box-sizing: border-box;
										min-height:var(--theme-toast-height, 64px);
                   
                    position: relative;
                    box-shadow: var(--theme-toast-box-shadow, 0 6px 10px 0 rgba(0,0,0,0.25));
                    border-radius: var(--theme-toast-border-radius, 4px);
                }
                
                .content {
					        
                    top: var(--theme-toast-icon-top, 13px);
                    font-family: var(--theme-font-family,"Hind Vadodara");
                    display: flex;
                    flex-direction: column;
                    margin-left: var(--theme-toast-content-margin-left, 56px);
                }
                
                .content .title {
									  margin-top: 13px;
                    font-size: var(--theme-toast-title-font-size, 16px);
                    font-weight: var(--theme-toast-title-font-weight, 600);
                    line-height: var(--theme-toast-title-line-height, 20px);
                }

                .content .detail {
									  padding-bottom:10px;
                    font-size: var(--theme-toast-detail-font-size, 14px);
                    line-height: var(--theme-toast-detail-line-height, 16px);
										text-align:justify;
										max-height:250px; 
										overflow-y:auto;
										margin-right: var(--theme-toast-icon-left, 16px);
										margin-top:5px;
                }
                
                .container.info {
                    background-color: var(--theme-toast-info-background-color, #DAF2F4);
                }
                
                .content.info .title {
                    color: var(--theme-toast-info-color, #05829B);
                }
                
                .content.info .detail {
                    color: var(--theme-toast-info-color, #05829B);
                }
                
                .container.success {
                    background-color: var(--theme-toast-success-background-color, #EAF3D9);
                }
                
                .content.success .title {
                    color: var(--theme-toast-success-color, #588A00);
                }
                
                .content.success .detail {
                    color: var(--theme-toast-success-color, #588A00);
                }
                
                .container.warning {
                    background-color: var(--theme-toast-warning-background-color, #F9EBDD);
                }
                
                .content.warning .title {
                    color: var(--theme-toast-warning-color, #C16C18);
                }
                
                .content.warning .detail {
                    color: var(--theme-toast-warning-color, #C16C18);
                }
                
                .container.error {
                    background-color: var(--theme-toast-error-background-color, #F7E2E1);
                }
                
                .content.error .title {
                    color: var(--theme-toast-error-color, #AC282F);
                }
                
                .content.error .detail {
                    color: var(--theme-toast-error-color, #AC282F);
                }
                
                .icon {
                    position: absolute;
                    left: var(--theme-toast-icon-left, 16px);
                    top: var(--theme-toast-icon-top, 13px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                
                .close {
                    position: absolute;
                    right:  var(--theme-toast-icon-left, 16px);
                    cursor: pointer;
										top:17px;
                }
            `
		];
	}

	/**
	 * Generates the component template for mobile mode.
	 * 
	 * @returns {html} The html content of the component.
	 */
	_mobileTemplate() {

		return this._webTemplate();
	}

	/**
	 * Generates the component template for web mode.
	 * 
	 * @returns {html} The html content of the component.
	 */
	_webTemplate() {

		return html`
            <div class="container ${this.type}">
                <div class="icon" >
                    <capitec-icon icon="status/${this.type}-trans"></capitec-icon>
                </div>
                <div class="content ${this.type}">
                    <label class="title">${this.header}</label>
                    <label class="detail">${this.detail}</label>
                </div>
                ${this.closable ? html`<capitec-icon @click="${(e) => this._closeClicked(e)}" class="close" size="small" icon="system/close-${this.type}"></capitec-icon>` : ``}
            </div>
        `;
	}

	/**
	 * Generates the component template for kiosk mode.
	 * 
	 * @returns {html} The html content of the component.
	 */
	_kioskTemplate() {

		return this._webTemplate();
	}
}

window.customElements.define(`capitec-toast`, Toast);

/**
 * When the close button is clicked.
 *
 * @example
 * <capitec-toast ... @click="${this._handler}"></capitec-toast>
 *
 * @event Toast#click
 * @type {Object}
 * @property {Object} detail Event detail.
 */
