<template>
  <LDBModal result="info" :header="header" size="xx-large">
    <template #body>
      <div>
        <LDBField
          v-for="(field, fieldname) in editViewFields"
          :key="fieldname"
          :field="field"
          :view="field.view"
          :fieldname="field.name"
          :definition="undefined"
          :record="estimateObject"
          @change="on_change"
        />
        <br />
        <LDBField
          v-for="(field, fieldname) in itemViewFields"
          :key="fieldname"
          :field="field"
          :view="field.view"
          :fieldname="field.name"
          :definition="undefined"
          :record="estimateObject"
          @change="on_change"
        />
      </div>
      <br />
      <template v-if="estimateObject.currencyPair !== undefined">
        <div class="lfx-estimate-view-table-container">
          <table-lite
            id="lfx-account-statement-view-table"
            :is-static-mode="true"
            :is-slot-mode="true"
            :has-checkbox="false"
            :is-loading="false"
            :is-re-search="false"
            :columns="tableColumns"
            :rows="sortedRecords"
            :pageSize="sortedRecords.length"
            :total="sortedRecords.length"
          >
            <template v-for="(col, i) of tableColumns" v-slot:[col.field]="data" :key="i">
              <template v-if="col.showAsCurrency">{{ data.value[col.field] ? +data.value[col.field] : '' }}</template>
              <template v-else>{{ data.value[col.field] }}</template>
            </template>
          </table-lite>
        </div>
      </template>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
        @on-api-success="onApiSuccess"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import lodash from 'lodash';
const merge = lodash.merge;

import LDBField from '@/landobyte_vue/LDBField.vue';
import TableLite from '@/views/general/TableLite.vue';
import { calculateCounterAmount, calculateForwardPoints, calculateMarkedUpRate } from '@/sharedUtils/LfxTradeCalc';
import { formatCurrencyString, roundTo } from '@/sharedUtils/LdbSharedUtils';

export default {
  name: 'LfxEstimateView',
  components: { LDBField, TableLite },
  emits: ['on-api-success'],
  props: {},
  data() {
    return {
      estimateObjectSave: {
        currencyPair: undefined,
        clientMarkup: 0,
        quoteCurrencyAmount: 0,
        baseCurrencyAmount: 0,
        setCurrencyType: undefined, //'base'|'quote'

        deliveryType: 'Spot',
      },
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters(['allowedToViewRics']),
    ...mapState(['config', 'rates', 'account', 'authUser', 'systemSettings']),
    header() {
      return 'Estimate';
    },

    myAccount() {
      return this.account[this.authUser.accountId];
    },
    showMarkups() {
      return this.myAccount.accountLevel !== 'client';
    },
    showFecs() {
      return (this.myAccount.accountLevel !== 'client' || this.myAccount.mayBookFec) && !!this.currencyPair.allowForward;
    },
    estimateObject() {
      return {
        currencyPair: this.estimateObjectSave.currencyPair,
        parentMarkup: this.parentMarkup,
        parentMarkupString: this.parentMarkup !== undefined ? this.parentMarkup : 'Loading...',
        totalMarkup: this.totalMarkup,
        totalMarkupString: this.totalMarkup !== undefined ? this.totalMarkup : 'Loading...',
        clientMarkup: this.clientMarkup,
        quoteCurrencyAmount: this.estimateObjectSave.quoteCurrencyAmount,
        baseCurrencyAmount: this.estimateObjectSave.baseCurrencyAmount,
        forwardPoints: this.forwardPoints,
        forwardPointsString: this.forwardPointsString,

        deliveryType: this.estimateObjectSave.deliveryType,
        forwardContractType: this.estimateObjectSave.forwardContractType,
        optionStartDate: this.estimateObjectSave.optionStartDate,
        valueDate: this.estimateObjectSave.valueDate,
      };
    },

    myAccountMarkup() {
      if (!this.estimateObjectSave.currencyPair) {
        return undefined;
      }
      for (const markupId in this.myAccount.markup) {
        if (this.myAccount.markup[markupId].currencyPair === this.estimateObjectSave.currencyPair) {
          return this.myAccount.markup[markupId];
        }
      }
      return undefined;
    },
    clientMarkup() {
      if (this.myAccount.accountLevel === 'client' && this.myAccountMarkup) {
        return this.myAccountMarkup.calculatedMarkup;
      } else {
        return this.estimateObjectSave.clientMarkup || 0;
      }
    },
    parentMarkup() {
      if (this.myAccountMarkup) {
        if (this.myAccount.accountLevel === 'client') {
          return this.myAccountMarkup.totalParentMarkup;
        } else {
          return this.myAccountMarkup.totalParentMarkup + this.myAccountMarkup.calculatedMarkup;
        }
      }
      return this.manualParentMarkup;
    },
    manualParentMarkup() {
      if (!this.estimateObjectSave.currencyPair) {
        return undefined;
      }
      const parentMarkupStatus = this.myAccount.parentMarkupStatus;
      const parentMarkups = this.myAccount.parentMarkups?.[this.estimateObjectSave.currencyPair] || [];
      if (parentMarkupStatus !== 'retrieved') {
        return undefined;
      }
      let totalMarkup = 0;
      for (const parentMarkup of parentMarkups) {
        //@ts-expect-error
        if (parentMarkup.id !== this.myAccount.id) {
          if (parentMarkup.calculatedMarkup === undefined) {
            return undefined;
          }
          totalMarkup += parentMarkup.calculatedMarkup;
        }
      }
      return totalMarkup;
    },
    totalMarkup() {
      if (!this.parentMarkup) {
        return undefined;
      }
      return +this.parentMarkup + +(this.clientMarkup || 0);
    },
    forwardPoints() {
      if (
        this.estimateObjectSave.deliveryType !== 'FWD' ||
        !this.estimateObjectSave.forwardContractType ||
        (this.estimateObjectSave.forwardContractType === 'partiallyOptional' &&
          !this.estimateObjectSave.optionStartDate) ||
        !this.estimateObjectSave.valueDate
      ) {
        return 0.0;
      }
      try {
        return calculateForwardPoints(
          this.cachedRic,
          this.estimateObjectSave.forwardContractType,
          new Date(this.currencyPair?.spotDate),
          new Date(this.estimateObjectSave.valueDate),
          'base',
          'Sell',
          +this.systemSettings?.trading?.forwardOptionalPercentage,
          new Date(this.estimateObjectSave.optionStartDate)
        );
      } catch {
        return 0.0;
      }
    },
    forwardPointsString() {
      if (this.estimateObjectSave.deliveryType !== 'FWD') {
        return 'Spot Deal, no forward points.';
      }
      if (!this.estimateObjectSave.forwardContractType) {
        return 'Please capture Forward Contract Type.';
      }
      if (
        this.estimateObjectSave.forwardContractType === 'partiallyOptional' &&
        !this.estimateObjectSave.optionStartDate
      ) {
        return 'Please capture Option Start Date.';
      }
      if (!this.estimateObjectSave.valueDate) {
        return 'Please capture Value Date.';
      }
      return this.forwardPoints.toString();
    },
    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
      ];
      return allButtons !== undefined ? allButtons : [];
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : '/';
    },

    // ----- Fields -----

    editViewFields() {
      const res = [
        {
          name: 'currencyPair',
          label: 'Currency Pair',
          datatype: {
            option: {
              optionType: 'text',
              options: this.currencyPairOptions,
            },
          },
          guards: true,
          view: 'edit',
        },
        {
          name: 'deliveryType',
          label: 'Delivery Type',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'Cash', name: 'Value Today', tagType: 'info' },
                { id: 'Tom', name: 'Value Tomorrow', tagType: 'success' },
                { id: 'Spot', name: 'Spot', tagType: 'info' },
                { id: 'FWD', name: 'Forward', tagType: 'warning' },
              ],
            },
          },
          guards: this.estimateObject.currencyPair !== undefined && this.showFecs,
          view: 'edit',
        },
        {
          name: 'forwardContractType',
          label: 'Forward Contract Type',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'fixed', name: 'Fixed' },
                { id: 'optional', name: 'Optional' },
                { id: 'partiallyOptional', name: 'Partially Optional' },
              ],
            },
          },
          guards:
            this.estimateObject.currencyPair !== undefined &&
            this.showFecs &&
            this.estimateObject.deliveryType === 'FWD',
          view: 'edit',
        },
        {
          name: 'optionStartDate',
          label: 'Option Start Date',
          datatype: {
            datetime: {
              type: 'date',
              format: 'human',
            },
          },
          guards:
            this.estimateObject.currencyPair !== undefined &&
            this.showFecs &&
            this.estimateObject.deliveryType === 'FWD' &&
            this.estimateObject.forwardContractType === 'partiallyOptional',
          view: 'edit',
        },
        {
          name: 'valueDate',
          label: 'Value Date',
          datatype: {
            datetime: {
              type: 'date',
              format: 'human',
              minDate: this.currencyPair?.spotDate,
              maxDate: { type: 'month', offset: 6 },
            },
          },
          guards:
            this.estimateObject.currencyPair !== undefined &&
            this.showFecs &&
            this.estimateObject.deliveryType === 'FWD',
          view: 'edit',
        },

        {
          name: 'baseCurrencyAmount',
          label: this.currencyPair?.baseCurrency || 'Base',
          datatype: 'float',
          guards: true,
          guards: this.estimateObject.currencyPair !== undefined,
          view: 'edit',
        },
        {
          name: 'quoteCurrencyAmount',
          label: this.currencyPair?.quoteCurrency || 'Quote',
          datatype: 'float',
          guards: true,
          guards: this.estimateObject.currencyPair !== undefined,
          view: 'edit',
        },
        {
          name: 'clientMarkup',
          label: 'Client Markup',
          datatype: 'float',
          guards: this.estimateObject.currencyPair !== undefined && this.showMarkups,
          view: 'edit',
        },
      ];
      return res.filter((f) => f.guards === true);
    },
    itemViewFields() {
      const res = [
        {
          name: 'parentMarkupString',
          label: 'Parent Markup',
          datatype: 'text',
          guards: this.estimateObject.currencyPair !== undefined && this.showMarkups,
          view: 'item',
        },
        {
          name: 'totalMarkupString',
          label: 'Total Markup',
          datatype: 'text',
          guards: this.estimateObject.currencyPair !== undefined && this.showMarkups,
          view: 'item',
        },
        {
          name: 'forwardPointsString',
          label: 'Forward Points',
          datatype: 'text',
          guards:
            this.estimateObject.currencyPair !== undefined &&
            this.showFecs &&
            this.estimateObject.deliveryType === 'FWD',
          view: 'item',
        },
      ];
      return res.filter((f) => f.guards === true);
    },
    currencyPairOptions() {
      const res = [];
      for (const currencyPairId in this.config?.[1].currencyPair) {
        const currencyPair = this.config?.[1].currencyPair?.[currencyPairId];
        if (this.allowedToViewRics?.includes(currencyPair.instrument)) {
          res.push({
            id: currencyPairId,
            name: this.config?.[1].currencyPair[currencyPairId].name,
            index: this.config?.[1].currencyPair[currencyPairId].index,
          });
        }
      }
      res.sort((currencyPair1, currencyPair2) => {
        return currencyPair1.index - currencyPair2.index;
      });
      return res;
    },
    currencyPair() {
      if (!this.estimateObjectSave.currencyPair) {
        return undefined;
      }
      return this.config?.[1].currencyPair[this.estimateObjectSave.currencyPair];
    },
    cachedRic() {
      return this.rates?.[this.currencyPair?.instrument];
    },
    // ----- Table -----

    tableColumns() {
      let self = this;
      let res = [
        {
          col: 'rateType-col',
          field: 'rateType',
          label: '',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'spotRate-col',
          field: 'spotRate',
          label: 'Spot',
          isKey: false,
          sortable: false,
          guards: this.showMarkups && this.showFecs && this.estimateObjectSave.deliveryType === 'FWD',
          showAsCurrency: false,
        },
        {
          col: 'benchRate-col',
          field: 'benchRate',
          label: 'Bench',
          isKey: false,
          sortable: false,
          guards: this.showMarkups,
          showAsCurrency: false,
        },
        {
          col: 'bankRate-col',
          field: 'bankRate',
          label: 'Bank Rate',
          isKey: false,
          sortable: false,
          guards: this.showMarkups,
          showAsCurrency: false,
        },
        {
          col: 'dealRate-col',
          field: 'dealRate',
          label: 'Deal Rate',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'baseCurrencyAmount-col',
          field: 'baseCurrencyAmount',
          label: this.currencyPair?.baseCurrency || 'Base',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
        {
          col: 'quoteCurrencyAmount-col',
          field: 'quoteCurrencyAmount',
          label: this.currencyPair?.quoteCurrency || 'Quote',
          isKey: false,
          sortable: false,
          guards: true,
          showAsCurrency: false,
        },
      ];

      return res.filter((f) => f.guards === true);
    },
    sortedRecords() {
      const askSpotRate = this.cachedRic?.ask || 0.0;
      const askBenchRate = roundTo(askSpotRate + this.forwardPoints / 10000, 4);
      const bidSpotRate = this.cachedRic?.bid || 0.0;
      const bidBenchRate = roundTo(bidSpotRate + this.forwardPoints / 10000, 4);
      return [
        {
          rateType: this.currencyPair
            ? `Buy ${this.currencyPair?.quoteCurrency} / Sell ${this.currencyPair?.baseCurrency}`
            : 'Bid',
          spotRate: askSpotRate,
          benchRate: askBenchRate,
          bankRate:
            this.parentMarkup !== undefined
              ? calculateMarkedUpRate(askBenchRate, this.parentMarkup, 'quote', 'Buy')
              : 'Loading...',
          dealRate:
            this.totalMarkup !== undefined
              ? calculateMarkedUpRate(askBenchRate, this.totalMarkup, 'quote', 'Buy')
              : 'Loading...',
          baseCurrencyAmount: this.formatCurrencyStringForEstimate(
            this.getTableCurrencyAmount('base', calculateMarkedUpRate(askBenchRate, this.totalMarkup, 'quote', 'Buy'))
          ),
          quoteCurrencyAmount: this.formatCurrencyStringForEstimate(
            this.getTableCurrencyAmount('quote', calculateMarkedUpRate(askBenchRate, this.totalMarkup, 'quote', 'Buy'))
          ),
        },
        {
          rateType: this.currencyPair
            ? `Buy ${this.currencyPair?.baseCurrency} / Sell ${this.currencyPair?.quoteCurrency}`
            : 'Bid',
          spotRate: bidSpotRate,
          benchRate: bidBenchRate,
          bankRate:
            this.parentMarkup !== undefined
              ? calculateMarkedUpRate(bidBenchRate, this.parentMarkup, 'quote', 'Sell')
              : 'Loading...',
          dealRate:
            this.totalMarkup !== undefined
              ? calculateMarkedUpRate(bidBenchRate, this.totalMarkup, 'quote', 'Sell')
              : 'Loading...',
          baseCurrencyAmount: this.formatCurrencyStringForEstimate(
            this.getTableCurrencyAmount('base', calculateMarkedUpRate(bidBenchRate, this.totalMarkup, 'quote', 'Sell'))
          ),
          quoteCurrencyAmount: this.formatCurrencyStringForEstimate(
            this.getTableCurrencyAmount('quote', calculateMarkedUpRate(bidBenchRate, this.totalMarkup, 'quote', 'Sell'))
          ),
        },
      ];
    },
  },

  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    on_change({ field, value }) {
      this.estimateObjectSave[field] = value;

      if (field === 'baseCurrencyAmount') {
        this.estimateObjectSave.quoteCurrencyAmount = 0;
        this.estimateObjectSave.setCurrencyType = 'base';
      }

      if (field === 'quoteCurrencyAmount') {
        this.estimateObjectSave.baseCurrencyAmount = 0;
        this.estimateObjectSave.setCurrencyType = 'quote';
      }
    },
    getTableCurrencyAmount(currencyType, rate) {
      if (this.estimateObjectSave.setCurrencyType === undefined) {
        return 0.0;
      }
      if (this.estimateObjectSave.setCurrencyType === currencyType) {
        if (currencyType === 'quote') {
          return this.estimateObjectSave.quoteCurrencyAmount;
        }
        return this.estimateObjectSave.baseCurrencyAmount;
      } else {
        let otherAmount = 0;
        let otherCurrencyType = 'base';
        if (currencyType === 'quote') {
          otherAmount = this.estimateObjectSave.baseCurrencyAmount;
          otherCurrencyType = 'base';
        } else {
          otherAmount = this.estimateObjectSave.quoteCurrencyAmount;
          otherCurrencyType = 'quote';
        }
        return calculateCounterAmount(otherAmount, rate, otherCurrencyType, 2);
      }
    },

    formatCurrencyStringForEstimate(amount) {
      if (amount === undefined) {
        return '';
      }
      if (typeof amount === 'string') {
        amount = +amount;
      }
      return formatCurrencyString(amount * 100);
    },
    onApiSuccess() {
      return;
    },
  },
};
</script>

<style>
.lfx-estimate-view-table-container {
  position: relative;
  min-height: 320px;
}

#lfx-estimate-view-table {
  min-width: 100%;
}

capitec-modal.lfx-estimate {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
</style>
