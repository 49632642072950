<template>
  <LfxModal result="info" :header="header" size="x-large">
    <template #body>
    <LDBField
      v-for="(field, fieldname) in viewFields"
      :key="fieldname"
      :field="field"
      view="edit"
      :fieldname="field.name"
      :definition="definition"
      :record="record"
      @change="on_change"
    />
    <br/>
    <capitec-file-upload :label="uploadButtonName" @selection-change="onSelectionChange" :disabled="uploadDisabled" :type="uploadButtonType" :fileTypes="fileTypes" hint="Jannie">
    
    </capitec-file-upload>
    </template>
    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :enabledGuards="button.enabledGuards"
        @on-api-success="button.onApiSuccess"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LfxModal>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import LDBButton from '@/landobyte_vue/LDBButton.vue';

export default {
  name: 'LfxClientApplicationUploadDocument',
  components: { LDBItemView,LDBButton },
  data() {
    return {
      files:null,
      record:{}
    }
  },
  computed: {
    ...mapState(['account']),
    header() {
      return "Upload Application Document"
    },
    uploadDisabled() {
      return !this.record.documentTypeId
    },
    uploadButtonType() {
      if (this.uploadDisabled) {
        return 'disabled'
      } else {
        return ''
      }
    },
    uploadButtonName() {
      if (!this.record.documentTypeId) {
        return "Select Document Type"        
      } else {
        return "Select File"
      }
    },
    fileTypes() {
      return undefined
    }, 
    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          action: 'click',
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'uploadApplicationDocument',
          label: 'Upload',
          type: 'primary',
          action: 'uploadApplicationDocument',
          params: { accountId: +this.$route.params.accountId, applicationId: +this.$route.params.applicationId},
          onApiSuccess(response) {
            const documentId = response.response.id;
            self.$router.push({
                name: 'clientApplicationWizardUploadDocuments',
                params: { accountId: +self.$route.params.accountId, applicationId: +self.$route.params.applicationId, applicationDocumentsId: documentId },
              })
          },
          actionParams: {fileName:this.files,documentTypeId:this.record.documentTypeId},
          clickEvent(response) {
            self.$router.replace({
              name: 'clientApplicationWizardUploadDocuments',
              params: { accountId: self.clientId, applicationId: self.applicationId, applicationDocumentId: response.response.id },
            });
          }
        },
      ];
      return allButtons;      
    },
    clientId() {
      return +this.$route.params.accountId;
    },
    clientRecord() {
      return this.account[this.clientId];
    },


    applicationId() {
      return +this.$route.params.applicationId;
    },
    applicationRecord() {
      return this.clientRecord?.application?.[this.applicationId];
    },

    definition() {
      return getDefinition('applicationDocument');
    },
    viewFields() {
      const availableDocumentTypes = this.applicationRecord.availableDocumentTypes;
      const options = [];
      for (const documentType of availableDocumentTypes) {
        options.push({
          id:documentType.id,
          name:documentType.documentName
        })
      }
      const res = [
    {
      name: 'documentTypeId',
      field: 'documentTypeId',
      label: 'Document Type',
      datatype: {
        option: {
          optionType: 'text',
          options: options,
        },
      },
    },
      ];

      return res;
    },

    
  },
  methods: {
    onSelectionChange(evt) {
      this.files = evt.detail.files[0];
    },
    on_change({ field, value }) {
      this.record[field] = value;
    },

  },
};
</script>