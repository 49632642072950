<template>
  <template v-if="['none', 'retrieving'].includes(bankAccounts_retrievalStatus)">
    <LDBLoader />
    <p v-if="bankAccounts_retrievalAttemptsFailed == 0">Loading...</p>
    <p v-else>Load failed. Retrying...{{ bankAccounts_retrievalAttemptsFailed }}</p>
  </template>

  <template v-else-if="bankAccounts_retrievalStatus === 'failed'">
    <p>Failed to load Bank Accounts after {{ bankAccounts_retrievalAttemptsFailed + 1 }} attempts.</p>
  </template>
</template>

<script>

import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

export default {
  name: 'LfxBankAccounts',
  description: 'Placeholder while loading bank accounts and then redirect',
  components: {},
  created() {
    void this.accountRecord?.bankAccounts;

    if (this.bankAccounts_retrievalStatus === 'retrieved') {
      this.redirect();
    }
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    // ----- Account Record ----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },

    bankAccounts_retrievalStatus() {
      return this.accountRecord?.bankAccounts_retrievalStatus;
    },
    bankAccounts_retrievalAttemptsFailed() {
      return this.accountRecord?.bankAccounts_retrievalAttemptsFailed;
    },

    firstOwn_bankAccountId() {
      return this.accountRecord?.firstOwn_bankAccountId;
    },

    // ----- Beneficiary Record -----

    beneficiaryId() {
      return +this.$route.params?.beneficiaryId;
    },
    beneficiaryRecord() {
      return this.accountRecord?.beneficiaries?.[this.beneficiaryId];
    },

    firstBeneficiary_bankAccountId() {
      return this.beneficiaryRecord?.firstBeneficiary_bankAccountId;
    },
  },
  watch: {
    bankAccounts_retrievalStatus(newValue, oldValue) {
      if (newValue === 'retrieved') {
        this.redirect();
      }
    },
  },
  methods: {
    redirect() {
      if (this.beneficiaryId) {
        if (this.firstBeneficiary_bankAccountId) {
          this.$router.replace({
            name: 'beneficiaryBankAccountView', // TODO
            params: {
              accountId: this.accountId,
              beneficiaryId: this.beneficiaryId,
              bankAccountId: this.firstBeneficiary_bankAccountId,
            },
          });
        } else {
          this.$router.replace({
            name: 'beneficiaryBankAccountsList', // TODO
            params: {
              accountId: this.accountId,
              beneficiaryId: this.beneficiaryId,
            },
          });
        }
      } else {
        if (this.firstOwn_bankAccountId) {
          this.$router.replace({
            name: 'accountBankAccountView',
            params: {
              accountId: this.accountId,
              bankAccountId: this.firstOwn_bankAccountId,
            },
          });
        } else {
          this.$router.replace({
            name: 'accountBankAccountsList',
            params: {
              accountId: this.accountId,
            },
          });
        }
      }
    },
  },
};
</script>
