<template>
  <LDBModal result="info" header="Confirm User Review" size="small">
    <template #body>
      <p>I confirm that the user review has been successfully completed.</p>
    </template>
    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';

export default {
  name: 'LfxUserAccessReportReviewConfirmation',
  components: { LDBItemView },
  props: {},
  // data() {
  // },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['authUser', 'user']),

    // ----- View -----

    // ----- API action -----
    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          action: 'click',
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'confirmUserReviewButton',
          label: 'Confirm',
          type: 'primary',
          action: 'confirmUserReview',
          params: {
            accountId: this.authUser.accountId,
          },
          actionParams: { userTeamId: this.user?.[this.authUser?.id]?.userTeamId },
          guards: true,
          clickEvent(response) {
            if (response.status === 'success') {
              self.$router.go(-1);
            }
          },
        },
      ];
      return allButtons;
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
  },
};
</script>
