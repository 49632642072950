<template>
  <LDBModal result="info" :header="header" size="x-large">
    <template #body>
      <LDBField
        v-for="(field, fieldname) in viewFields"
        :key="fieldname"
        :field="field"
        view="item"
        :fieldname="field.name"
        :definition="definition"
        :record="accountRecord"
      />
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :enabledGuards="button.enabledGuards"
        :definitionName="button.definitionName"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import lodash from 'lodash';
const merge = lodash.merge;

export default {
  name: 'LfxEnableIntermediary',
  created() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account']),

    // ----- Header ----

    header() {
      return `Enable Intermediary: ${this.accountRecord?.name}`;
    },

    // ----- Definition -----

    definition() {
      return getDefinition('intermediary');
    },

    // ----- Intermediary record -----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },

    // ----- Fields -----

    fields() {
      return this.accountRecord.guarded_fields;
    },
    includeFieldNames() {
      return [
        'name',
        'bpNumber',
        'cifNumber',
        'commissionAccount',
        'organisation',
        'numberOf_active_intermediaryUsers',
        'numberOf_active_intermediaryUsers_withActiveManageUser',
        'numberOf_active_systemUser',
      ]; // TODO add 'mayCompleteOwnKyc' - how to handle null vs false? - avr 2022-12-12
    },
    viewFields() {
      let res = [];
      for (let k in this.fields) {
        let field = this.fields[k];
        if (this.includeFieldNames.includes(field.name)) {
          field.fieldname = k;
          const updatedField = this.overrideField(field);
          // res.push(field);
          res.push(updatedField);
        }
      }
      return res;
    },
    fieldnames() {
      return this.viewFields.map((field) => field.name);
    },
    requiredFieldsRemaining() {
      return this.accountRecord.required_fields_remaining.filter((f) => this.fieldnames.indexOf(f) >= 0).length > 0;
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelEnableIntermediary',
          label: 'Cancel',
          type: '',
          action: 'click',
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'enableIntermediary',
          label: 'Enable Intermediary',
          type: 'primary',
          action: 'enableIntermediary',
          actionParams: this.accountRecord,
          params: { accountId: this.accountId },
          enabledGuards: this.enableIntermediary_enableGuards,
          clickEvent(response) {
            if (response.response.id) {
              self.$router.replace({ name: 'accountDetailsView', params: { accountId: response.response.id } });
            }
            // self.addIntermediary();
          },
        },
      ];
      // return allButtons?.filter((button) => button.guards) !== undefined
      //   ? allButtons?.filter((button) => button.guards)
      //   : [];
      return allButtons;
    },
    enableIntermediary_enableGuards() {
      return this.accountRecord.name &&
        this.accountRecord.cifNumber &&
        this.accountRecord.bpNumber &&
        this.accountRecord.commissionAccount &&
        this.accountRecord.organisation
        ? true
        : false;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Fields -----

    overrideField(field) {
      if (
        [
          'name',
          'cifNumber',
          'bpNumber',
          'commissionAccount',
          'organisation',
          'numberOf_active_intermediaryUsers',
          'numberOf_active_intermediaryUsers_withActiveManageUser',
          'numberOf_active_systemUser',
        ].includes(field.name) // TODO 'mayCompleteOwnKyc'
      ) {
        const startingField = merge({}, field);
        const overrideObject = this.getFieldOverRideObject(field);
        const newField = merge(startingField, overrideObject);
        return newField;
      } else {
        return field;
      }
    },
    getFieldOverRideObject(field) {
      switch (field.name) {
        case 'name':
          return { displayType: { displayAsTag: true, tagTypeFunctionName: 'enableIntermediaryNameTag' } };
        case 'cifNumber':
          return { displayType: { displayAsTag: true, tagTypeFunctionName: 'enableIntermediaryCifTag' } };
        case 'cifNumber':
          return { displayType: { displayAsTag: true, tagTypeFunctionName: 'enableIntermediaryCifTag' } };
        case 'bpNumber':
          return { displayType: { displayAsTag: true, tagTypeFunctionName: 'enableIntermediaryBpTag' } };
        case 'commissionAccount':
          return { displayType: { displayAsTag: true, tagTypeFunctionName: 'enableIntermediaryCommissionAccountTag' } };
        case 'organisation':
          return { displayType: { displayAsTag: true, tagTypeFunctionName: 'enableIntermediaryOrganisationTag' } };
        // case 'mayCompleteOwnKyc': // TODO
        //   return { forceDisplay: true };
        case 'numberOf_active_intermediaryUsers':
          return {
            displayType: {
              displayAsTag: true,
              tagTypeFunctionName: 'enableIntermediaryNumberOfActiveIntermediaryUsers',
            },
          };
        case 'numberOf_active_intermediaryUsers_withActiveManageUser':
          return {
            displayType: {
              displayAsTag: true,
              tagTypeFunctionName: 'enableIntermediaryNumberOf_active_intermediaryUsers_withActiveManageUser',
            },
          };
        case 'numberOf_active_systemUser':
          return {
            displayType: {
              displayAsTag: true,
              tagTypeFunctionName: 'enableIntermediaryNumberOf_active_systemUser',
            },
          };
        default:
          return {};
      }
    },
  },
};
</script>
