<template>
  <LDBModal result="info" :header="header" size="x-large">
    <template #body>
      <LDBField
        v-for="(field, fieldname) in viewFields"
        :key="fieldname"
        :field="field"
        :view="field.view"
        :fieldname="field.name"
        :definition="undefined"
        :record="newPaymentRecord"
        @change="on_change"
      />
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
        <!-- @on-api-success="onApiSuccess" -->
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxNewPayment',
  components: { LDBField },
  // emits: ['on-api-success'],
  props: {},
  data() {
    return {
      // mounted: true,
      newPaymentRecord: {
        clientId: undefined,
        currency: undefined,
        valueDate: undefined,
        amount: undefined,
        externalReference: undefined,
        sourceBankAccountID: undefined,
        destinationBankAccountID: undefined,
        inwardSwiftId: undefined,

        availableSwifts: {},
        availableBankAccounts: {},
      },
      swiftId: undefined,
      swiftRecord: undefined,
      bankAccountId: undefined,
      bankAccountRecord: undefined,
      swiftSetInPath: false,
    };
  },
  created() {
    this.newPaymentRecord.clientId = this.accountId;
    this.newPaymentRecord.availableSwifts = this.accountRecord.clientAvailableSwifts;
    if (this.$route.params.swiftId) {
      this.swiftSetInPath = true;
      this.setNewSwift(+this.$route.params.swiftId);
    } else {
      this.setAvailableBankAccounts(undefined);
    }
  },
  computed: {
    ...mapGetters(['permissions']),
    ...mapState(['account', 'swift', 'dbIntermediarySettings']),
    header() {
      switch (this.transactionType) {
        case 'inwardPayment':
          return 'New Inward Payment';
        case 'outwardPayment':
          return 'New Outward Payment';
        default:
          return 'Capture Payment';
      }
    },
    intermediaryIsBranchClients() {
      return !!this.dbIntermediarySettings.bankDirectClients && !this.dbIntermediarySettings.allowOnboardingWithIncompleteData
    },
    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

        {
          name: 'capturePayment',
          label: 'Add Payment',
          type: 'primary',
          action: 'bookTransfer',
          params: {
            account: this.accountRecord.id,
          },
          actionParams: this.newPaymentRecord,
          clickEvent(response) {
            if (response.status === 'success') {
              if (
                self.permissions.updatePayment &&
                response.response?.Payments?.length === 1 &&
                response.response?.Payments?.[0]?.id
              ) {
                self.$router.replace({
                  name: 'transactionPaymentRedirectToPaymentCapture',
                  params: { transactionId: response.response.id, paymentId: response.response.Payments[0].id },
                });
              } else {
                self.$router.replace({ name: 'transaction', params: { transactionId: response.response.id } });
              }
            }
          },
        },
      ];
      return allButtons !== undefined ? allButtons : [];
    },

    // ----- Record -----

    accountId() {
      return +this.$route.params.accountId;
    },
    accountRecord() {
      return this.$store.state?.account[this.accountId];
    },

    // ----- Fields -----

    transactionType() {
      return this.$route.meta?.content?.transactionType || this.$route.meta?.modal?.transactionType;
    },

    viewFields() {
      const allFields = {
        inwardSwiftId: {
          name: 'inwardSwiftId',
          label: 'Inward SWIFT',
          datatype: {
            foreignKey: {
              collection: 'availableSwifts',
              linkTable: 'LfxInwardSWIFT',
              linkField: 'id',
              displayField: 'swiftNumber',
            },
          },
          view: this.swiftSetInPath === false ? 'edit' : 'item',
          guards: this.transactionType === 'inwardPayment',
        },
        sourceBankAccountID: {
          name: 'sourceBankAccountID',
          label: 'Source Account',
          datatype: {
            foreignKey: {
              collection: 'availableBankAccounts',

              linkTable: 'LfxBankAccount',
              linkField: 'id',
              displayField: 'description',
            },
          },
          view: 'edit',
          guards: this.transactionType === 'outwardPayment',
        },
        destinationBankAccountID: {
          name: 'destinationBankAccountID',
          label: 'Destination Account',
          datatype: {
            foreignKey: {
              collection: 'availableBankAccounts',

              linkTable: 'LfxBankAccount',
              linkField: 'id',
              displayField: 'description',
            },
          },
          view: 'edit',
          guards: (!!this.swiftId || !!this.intermediaryIsBranchClients) && this.transactionType === 'inwardPayment',
        },

        currency: {
          name: 'currency',
          label: 'Payment Currency',
          datatype: 'text',
          view: 'item',
          // guards: this.transactionType === 'inwardPayment' && !!this.bankAccountId ? true : false,
          guards: false,
        },
        amount: {
          name: 'amount',
          label: 'Payment Amount',
          datatype: { currency: { symbol: this.newPaymentRecord.currency } },
          view: (this.transactionType === 'outwardPayment' || !!this.intermediaryIsBranchClients && !this.swiftId) ? 'edit' : 'item',
          guards: !!this.bankAccountId,
        },
        valueDate: {
          // TODO: change to radio buttons, disabled for invalid dates
          name: 'valueDate',
          label: 'Value Date',
          datatype: {
            datetime: {
              type: 'date',
              format: 'human',
              minDate: { type: 'day', offset: 0 },
              maxDate: { type: 'day', offset: 7 },
            },
          },
          view: 'edit',
          guards: !!this.bankAccountId,
        },
        externalReference: {
          name: 'externalReference',
          label: 'External Reference',
          datatype: 'text',
          view: 'edit',
          guards: !!this.bankAccountId,
        },
      };
      const returnFields = {};
      for (const f in allFields) {
        if (allFields[f].guards !== false) {
          returnFields[f] = allFields[f];
        }
      }
      return returnFields;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    setNewSwift(swiftId) {
      this.swiftId = swiftId;
      this.swiftRecord = this.$store.state?.swift[swiftId];
      this.newPaymentRecord.inwardSwiftId = swiftId;
      this.newPaymentRecord.currency = this.swiftRecord.currencyId;
      this.newPaymentRecord.amount = this.swiftRecord.amount;
      this.setAvailableBankAccounts(this.swiftRecord.currencyId);
    },
    setAvailableBankAccounts(currencyId) {
      // this.newPaymentRecord.availableBankAccounts = this.accountRecord.filteredBankAccounts({
      this.newPaymentRecord.availableBankAccounts = this.accountRecord.filter_bankAccounts(
        this.accountRecord.ownActive_bankAccounts,
        {
          currency: currencyId,
          includeAuthUserBankAccounts: false,
          bankAccountTypes: 'own',
        }
      );
    },
    on_change({ field, value }) {
      this.newPaymentRecord[field] = value;
      if (field === 'inwardSwiftId') {
        this.setNewSwift(value);
      } else if (field === 'destinationBankAccountID') {
        this.bankAccountId = value;
        this.bankAccountRecord = this.accountRecord.bankAccounts[value];
        if (!!this.intermediaryIsBranchClients && !this.swiftId) {
          this.newPaymentRecord.currency = this.bankAccountRecord.currencyId;
        }
      } else if (field === 'sourceBankAccountID') {
        this.bankAccountId = value;
        this.bankAccountRecord = this.accountRecord.bankAccounts[value];
        this.newPaymentRecord.currency = this.bankAccountRecord.currencyId;
      }
    },
    // // ----- API calls -----
    // async onApiSuccess(_responseObject) {},
  },
};
</script>
