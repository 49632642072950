<template>
  <LDBItemsView
    v-if="hasPaymentRecordLoaded"
    :records="paymentRecords"
    :record="paymentRecord"
    tileView="default"
    :view="view"
    @on-tile-click="onTileClick"
  />
  <template v-else>
    <LDBLoader />
  </template>

  <router-view></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LDBItemsView from '@/landobyte_vue/LDBItemsView.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

export default {
  name: 'LfxTransactionPaymentDetails',
  components: { LDBItemsView, LDBLoader },
  data() {
    return {};
  },
  // beforeRouteEnter(to, from, next) {
  //   console.log('FROM', from);

  //   next((vm) => {
  //     if (from.matched?.[0]?.name === '_newTransaction') {
  //       // TODO add check on payment status and user permission
  //       vm.$router.push({ name: 'transactionPaymentDetailsViewCapture', params: vm.$route.params });
  //     }
  //   });
  // },
  created() {
    // console.log(this.$route.query, this.$route.query?.redirectTo);
    // if (this.$route.query?.redirectTo === 'transactionPaymentDetailsViewCapture') {
    //   this.$route.query = {};
    //   this.$router.push({ name: 'transactionPaymentDetailsViewCapture', params: this.$route.params });
    // }

    // console.log('ROUTE', this.$route);
    // console.log('ROUTER', this.$router);

    if (this.$route.redirectedFrom?.name === 'transactionPaymentRedirectToPaymentCapture') {
      this.$router.push({ name: 'transactionPaymentDetailsViewCapture', params: this.$route.params });
    }

    this.paymentRecord?.activate();
    void this.paymentRecord?.portfolios;
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['transaction', 'payment']),

    view() {
      return this.$route.meta?.content?.view || 'item';
    },

    // ----- Transaction Record -----

    transactionId() {
      return +this.$route.params?.transactionId;
    },
    transactionRecord() {
      return this.transaction?.[this.transactionId];
    },

    // ----- Payment Record -----

    paymentRecords() {
      return this.transactionRecord?.payments;
    },

    paymentId() {
      return +this.$route.params?.paymentId;
    },
    paymentRecord() {
      return this.payment?.[this.paymentId] || {};
    },

    hasPaymentRecordLoaded() {
      return this.paymentRecord?.definition ? true : false;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    onTileClick(record) {
      this.$router.replace({ name: 'transactionPaymentDetailsView', params: { paymentId: record.id } });
    },
  },
};
</script>
