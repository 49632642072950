//@ts-nocheck
import VueStore from 'vue-class-store';

import { LfxPaymentMessageLogInt, LfxPaymentMessageLogDef } from '@/definitions/LfxPaymentMessageLogDef';

import { LfxModel, LfxDefinition } from './LfxModel';

@VueStore
class LfxPaymentMessageLogMdl extends LfxDefinition {
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
export class LfxPaymentMessageLog extends LfxModel {
  constructor(data, state) {
    super({definition_name:'payment_message_log',...data}, state);    
  }
  get displayFKLink() {
    return true
  }
  get link() {
    // @ts-expect-error
    return `/account/${this.accountId}/transaction/${this.transactionFileId}/payment/${this.paymentId}/messageLog/${this.id}`;
  }
}

export function Definition(context, name: string) {
  return new LfxPaymentMessageLogMdl(LfxPaymentMessageLogDef, LfxPaymentMessageLog, context, name, {});
}
