<template>
  <div class="ldb-item-view-container">
    <div v-if="mounted" class="lfx-form" :class="view">
      <div v-for="group in viewFieldsAndGroups" class="ldb-form-group" :key="group.name">
        <div class="ldb-field-group-label" :style="groupStyle(group)">
          <capitec-label type="subtitle" class="ldb-field-group" :label="group.group.label" />
        </div>
        <LDBField
          :key="field.name"
          v-for="field in group.fields"
          :field="field"
          view="create"
          :fieldname="field.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
      </div>
    </div>
    
    <div class="form-item-action-bar create" >
        <template v-if="view === 'create' || showButtons === true">
          <LDBButton
            v-for="button in leftButtons"
            :key="button.name"
            :label="button.label"
            :type="button.type"
            :action="button.action"
            :params="button.params"
            :actionParams="newRecord"
            @click="button.clickEvent"
            @on-api-success="button.onApiSuccess"
            @on-api-error="button.onApiError"
          />
          <capitec-spacer> </capitec-spacer>

          <LDBButton
            v-for="button in rightButtons"
            :key="button.name"
            :label="button.label"
            :type="button.type"
            :action="button.action"
            :params="button.params"
            :actionParams="newRecord"
            @click="button.clickEvent"
            @on-api-success="button.onApiSuccess"
            @on-api-error="button.onApiError"
          />
        </template>
      </div>      
     <div class="empty-spacer" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { STATUS_COLORS } from '@/landobyte_vue/ldb_utils.ts';

import LDBField from './LDBField.vue';

export default {
  components: { LDBField },
  props: {
    definition: { type: Object, required: true },
    collection_path: { type: String, required: true },
    table: { type: String, required: true },
    action: { type: String, default: 'click' },
    doSubmit: { type: Boolean, default: true },
    showButtons: { type: Boolean, default: false },
    params: {
      type: Object,
      default() {
        return {};
      },
    },
    initialObject: {
      type: Object,
      default() {
        return {};
      },
    },
    showBackButton: { type: Boolean, default: false },
    createButtonLabel: { type: String, default: 'Create' },
    view: { type: String, default: 'create' },
  },
  emits: ['on-back', 'on-cancel', 'on-create', 'on-api-success'],
  data() {
    return {
      newRecord: {},
      mounted: false,
    };
  },
  created() {
    this.newRecord = this.definition.class({ Table: this.table, collection_path: this.collection_path }, this.$store);
    for (const f in this.initialObject) {
      this.newRecord[f] = this.initialObject[f];
      this.newRecord.data[f] = this.initialObject[f];
    }
  },
  mounted() {
    for (let f = 0; f < this.viewFields.length; f++) {
      let field = this.viewFields[f];
      if (field.default && !this.newRecord[field.name]) {
        this.newRecord[field.name] = field.default;
      }
    }
    this.mounted = true;
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['authUser']),
    fields() {
      return this.newRecord.guarded_fields;
    },

    groups() {
      let baseGroups;
      if (this.unlinkedMode) {
        baseGroups = this.providedGroups;
      } else {
        baseGroups = this.definition.groups;
      }
      return baseGroups;
    },

    defaultGroup() {
      for (let g in this.definition.groups) {
        let group = this.definition.groups[g];
        if (group.default === true) {
          return group;
        }
      }
      return { priority: 1, default: true, label: '' };
    },

    viewFields() {
      let res = [];
      for (let k in this.fields) {
        let field = this.fields[k];
        var fieldDisplayForView = field.views[this.view];
        if (typeof fieldDisplayForView === 'function') {
          fieldDisplayForView = fieldDisplayForView({
            authUser: this.authUser,
            view: this.view,
            routeMetaData: this.$route.meta,
            record: this.newRecord,
          });
        }
        if (fieldDisplayForView) {
          // if (field.showIfNull || (this.newRecord[field.name] !== null && this.newRecord[field.name] !== undefined)) {
          field.fieldname = k;
          res.push(field);
          // }
        }
      }
      return res;
    },

    fieldsByGroup() {
      let res = {};
      for (let g in this.definition.groups) {
        res[g] = 0;
      }
      for (let f = 0; f < this.viewFields.length; f++) {
        let field = this.viewFields[f];
        if (field.group !== undefined) {
          res[field.group] = res[field.group] + 1;
        }
      }
      return res;
    },

    viewFieldsAndGroups() {
      let res = [];
      var groups = Object.entries(this.groups)
        .map(([k, v]) => {
          v.name = k;
          return v;
        })
        .sort((a, b) => a.prority - b.priority);
      for (let g = 0; g < groups.length; g++) {
        let group = groups[g];
        group.type = 'group';
        if (this.fieldsByGroup[group.name] > 0) {
          res.push(group);
        }
        for (let f = 0; f < this.viewFields.length; f++) {
          let field = this.viewFields[f];
          if (field.group === group.name) {
            res.push(field);
          } else if (field.group === undefined && group.default === true) {
            res.push(field);
          }
        }
      }
      let grouped = { default: { priority: this.defaultGroup.priority, fields: [], group: this.defaultGroup } };
      let priority = 1;
      for (let g = 0; g < res.length; g++) {
        let group = res[g];
        if (group.type === 'group') {
          grouped[group.name] = {};
          grouped[group.name].group = group;
          grouped[group.name].fields = [];
          grouped[group.name].priority = priority;
          priority = priority + 1;
        }
      }

      for (let f = 0; f < res.length; f++) {
        let field = res[f];
        let groupName = field.group === undefined ? 'default' : field.group;
        if (field.type !== 'group') {
          grouped[groupName].fields.push(field);
        }
      }
      let groupedArray = Object.values(grouped).sort((a, b) => a.priority - b.priority);
      return groupedArray.filter((g) => g.fields.length > 0);

      // let res = [];
      // let groups = Object.entries(this.definition.groups)
      //   .map(([k, v]) => {
      //     v.name = k;
      //     return v;
      //   })
      //   .sort((a, b) => a.prority - b.priority);
      // if (groups.length === 1) {
      //   return this.viewFields;
      // } else {
      //   for (let g = 0; g < groups.length; g++) {
      //     let group = groups[g];
      //     group.type = 'group';
      //     if (this.fieldsByGroup[group.name] > 0) {
      //       res.push(group);
      //     }
      //     for (let f = 0; f < this.viewFields.length; f++) {
      //       let field = this.viewFields[f];
      //       if (field.group === group.name) {
      //         res.push(field);
      //       } else if (field.group === undefined && group.default === true) {
      //         res.push(field);
      //       }
      //     }
      //   }
      //   return res;
      // }
    },

    // ----- Form Buttons -----

    // Left buttons

    leftButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'back',
          label: 'Back',
          type: 'clear',
          action: 'click',
          guards: this.showBackButton,
          clickEvent() {
            self.onBackClick();
          },
        },
      ];
      return allButtons.filter((button) => button.guards);
    },

    // Right buttons

    rightButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          action: 'click',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.onCancelClick();
          },
        },
        {
          name: 'create',
          label: this.createButtonLabel,
          action: this.action,
          executeAction: this.doSubmit,
          type: this.createButtonType,
          params: this.params,
          get disabled() {
            return this.type === 'disabled';
          },
          guards: true,
          clickEvent(response) {
            self.onCreateClick(response);
          },
        },
      ];
      return allButtons.filter((button) => button.guards);
    },

    createButtonType() {
      // TODO check mandatory fields and field validations
      return 'primary';
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    groupStyle(group) {
      let groupColor = group.color === undefined ? 'info' : group.color;
      let actualColor = STATUS_COLORS[groupColor] === undefined ? groupColor : STATUS_COLORS[groupColor];
      return { '--group-color': actualColor };
    },

    on_change({ field, value }) {
      this.newRecord[field] = value;
      this.newRecord.data[field] = value;
    },
    
    // ----- Button -----

    onBackClick() {
      this.$emit('on-back');
    },
    onCancelClick() {
      // this.$emit('on-cancel');
      this.$router.go(-1);
    },
    onCreateClick(response) {
      if (response.type === 'click') {
        this.newRecord.sync();
        this.$emit('on-create', this.newRecord);
        return;
      } else if (response.status === 'success') {
        console.log('RESPONSE', response);
        this.$emit('on-create', response);
      }
    },
  },
};
</script>

<style>
</style>
