//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxCurrencyPairDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a currencyPair record for LandoByte TS systems
//  Created     : 14 August 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition } from '../definitions/LdbInterfaces';
import { standardTextWhiteList } from '../sharedUtils/LdbValidations';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxCurrencyPairInt {
  id?: string;
  index?: number;
  baseCurrency?: string;
  quoteCurrency?: string;
  name?: string;
  instrument?: string;

  baseAdjustmentSpread?: number;

  parityPair?: boolean;
  allowTrading?: boolean;
  allowSameDay?: boolean;
  allowForward?: boolean;

  defaultSpread?: number;

  tomDate?: Date;
  spotDate?: Date;

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxCurrencyPairDef: LdbDefinition = {
  title: 'Currency Pair',
  table: 'LfxCurrencyPair',
  collectionPath: '/config/1/currencyPair',
  view: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
  indexes: [],
  pagingType: 'backEnd',
  groups: {
    currencyPair: { priority: 1, label: 'Currency Pair' },
    tradeSettings: { priority: 2, label: 'Trade Settings' },
    spreads: { priority: 3, label: 'Spreads' },
    valueDates: { priority: 4, label: 'Value Dates' },
    changes: { priority: 5, label: 'Changes' },
  },
  fields: {
    id: {
      label: 'Name',
      datatype: { id: { descriptionField: 'name', type: 'text' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'currencyPair',
    },
    index: {
      label: 'Index',
      datatype: 'integer',
      default: 0,
      mandatory: true,
      validators: {
        isNumeric: { msg: 'Priority should be numeric' },
        max: { msg: 'Priority can not be greater than 1000', args: [1000] },
      },
      allowNullValues: false,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'currencyPair',
    },
    baseCurrency: {
      label: 'Base Currency',
      datatype: {
        foreignKey: {
          collection: 'fk_currencies',

          linkTable: 'LdbCurrency',
          linkField: 'id',
          displayField: 'id',
          type: 'text',
        },
      },
      default: null,
      mandatory: true,
      views: { create: true, edit: false, item: true, list: 'base', delete: false, csv: false },
      group: 'currencyPair',
    },
    quoteCurrency: {
      label: 'Quote Currency',
      datatype: {
        foreignKey: {
          collection: 'fk_currencies',

          linkTable: 'LdbCurrency',
          linkField: 'id',
          displayField: 'id',
          type: 'text',
        },
      },
      default: null,
      mandatory: true,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'currencyPair',
    },
    name: {
      label: 'Name',
      datatype: 'text',
      default: false,
      mandatory: true,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alpha charaters', args: [standardTextWhiteList] },
        len: { msg: 'Name should be between 5 and 20 characters', args: [5, 20] },
      },

      views: { create: true, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'currencyPair',
    },
    instrument: {
      label: 'Instrument',
      datatype: 'text',
      default: false,
      mandatory: true,
      allowNullValues: false,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Instrument should be between 4 and 20 characters', args: [4, 20] },
      },
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'currencyPair',
    },

    baseAdjustmentSpread: {
      label: 'Base Adjustment Spread',
      datatype: 'float',
      default: 0,
      mandatory: true,
      allowNullValues: false,
      validators: {
        isNumeric: { msg: 'Priority should be numeric' },
        max: { msg: 'Priority can not be greater than 1000', args: [1000] },
      },
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'spreads',
    },
    parityPair: {
      label: 'Parity Pair',
      datatype: 'boolean',
      default: false,
      mandatory: true,
      allowNullValues: false,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'tradeSettings',
    },
    allowTrading: {
      label: 'Allow Trading',
      datatype: 'boolean',
      default: false,
      mandatory: true,
      allowNullValues: false,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'tradeSettings',
    },
    allowSameDay: {
      label: 'Allow Same Day Trading',
      datatype: 'boolean',
      default: false,
      mandatory: true,
      allowNullValues: false,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'tradeSettings',
    },
    allowForward: {
      label: 'Allow Forward Trading',
      datatype: 'boolean',
      default: false,
      mandatory: true,
      allowNullValues: false,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'tradeSettings',
    },
    defaultSpread: {
      label: 'Default Spread',
      datatype: 'integer',
      default: 0,
      mandatory: true,
      allowNullValues: false,
      validators: {
        isNumeric: { msg: 'Default Spread should be numeric' },
        min: { msg: 'Default Spread must be greater than 0', args: [0] },
        max: { msg: 'Default Spread must be less than 2000', args: [2000] },
      },
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'spreads',
    },
    tomDate: {
      label: 'Tomorrow Value Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      property: { source: 'backend', read: true, write: false, sort: 'none' },
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'valueDates',
    },
    spotDate: {
      label: 'Spot Value Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      property: { source: 'backend', read: true, write: false, sort: 'none' },
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'valueDates',
    },

    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
          linkInSql: false,
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
          linkInSql: false,
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
  },
};
