import axios from 'axios';
import { ENV_VARIABLES } from '@/store/config_env';

const APIGatewayURL = () => ENV_VARIABLES.VUE_REST_API_URL;

type AWSHTTPResponse = {
  Item:Object
}

function mapResponse(response:AWSHTTPResponse):Object {
  return response;
  // let res = [];
  // for ( let i in response ) {
  //   if ( i === 'Item' ) {
  //     let item:any = response[i];
  //     let item_res:any = {};
  //     for ( let f in item) {
  //       let value = item[f];
  //       let dt = Object.keys(value)[0];
  //       let v = value[dt];
  //       item_res[f] = v;
  //     }
  //     res.push(item_res)
  //   }
  // }
  // return res;
}

export async function http_get(path:string,headers:object,body:object)  {
  let url = `${APIGatewayURL()}${path}`;
  let config = {params:body,headers:headers};
  const http_res  = await axios.get(url,config);
  let data = http_res.data;
  let mappedResponse = mapResponse(data);
  return mappedResponse;
}

