//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxApplicationDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a client Application for the LandoFX system
//  Created     : 30 August 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import { intermediaryFieldViewGuard, notIntermediaryFieldViewGuard } from '../sharedUtils/LdbDefinitionUtils';
import type { LdbDefinition, LdbDbObject } from './LdbInterfaces';
import { isBankUser } from './LfxAccountDef';

//----------------------------------------------------------------------------------------------------------------------

export type LfxApplicationType = 'newClient' | 'newAccount' | 'amendClient' | 'amendAccount';
export type LfxApplicationStatus =
  | 'new'
  | 'inProgress'
  | 'error'
  | 'completed'
  | 'partiallyCompleted'
  | 'cancelled'
  | 'rejected';
export type LfxApplicationDueDilligence = 'Face-to-Face' | 'Non-Face-to-Face';
export type LfxApplicationIntegrationStatus =
  | 'none'
  | 'sent'
  | 'active'
  | 'inactive'
  | 'pendingReview'
  | 'retrievedFromBank'
  | 'error'
  | 'rejected'
  | 'cifExists';
export type LfxApplicationPortfolioStatus =
  | 'new'
  | 'readyForSubmission'
  | 'submitted'
  | 'readyForSigning'
  | 'signed'
  | 'recallRequested'
  | 'cancelled'
  | 'deleted'
  | 'notRequired'
  | 'signedNoMetaData'
  | 'metaDataNoSignature';
export type LfxApplicationCraStatus = 
  | 'notStarted'
  | 'inProgress'
  | 'approved'
  | 'declined' 
  | 'error'
  | 'reOpened'
  | 'eddRequested'
  | 'eddSubmitted'
export type LfxApplicationRequiredFieldSet = 'full' | 'minimum';
export type LfxApplicationVisibility = 'visible' | 'hidden';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxApplicationInt {
  id?: number;
  uuid?: string;
  accountId?: number;
  intermediaryId?: number;
  branchId?: number;
  reference?: string;
  applicationType?: LfxApplicationType;

  signatoryOrder?: number[];
  documents?: { documentName: string; count: number }[];

  status?: LfxApplicationStatus;
  craStatus?: LfxApplicationCraStatus;
  integrationStatus?: LfxApplicationIntegrationStatus;
  applicationPortfolioStatus?: LfxApplicationPortfolioStatus;

  requiredFieldSet?: LfxApplicationRequiredFieldSet;

  portfolioIntegrationId?: string;
  currentPortfolioId?: number;
  portfolioLink?: string;

  visibility?: LfxApplicationVisibility;

  requestedAt?: Date;
  requestedBy?: number;
  releasedAt?: Date;
  releasedBy?: number;
  rejectedAt?: Date;
  rejectedBy?: number;
  rejectedReason?: string;

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxApplicationDef: LdbDefinition = {
  title: 'Client Application',
  table: 'LfxApplication',
  view: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
  actions: {
    list: 'getClientApplications',
    item: 'getApplication',
  },
  collectionPath: '/account/{{accountId}}/application',
  indexes: ['accountId', 'branchId'],
  pagingType: 'backEnd',
  groups: {
    system: { priority: 1, label: 'General', color: 'success' },
    portfolio: { priority: 2, label: 'Portfolio', color: 'info' },
    signatories: { priority: 3, label: 'Signatories', color: 'info' },
    documents: { priority: 4, label: 'Documents' },
    integration: { priority: 5, label: 'Integration', color: 'disabled' },
    changes: { priority: 6, label: 'Changes', color: 'disabled' },
  },
  fields: {
    id: {
      label: 'Reference',
      datatype: { id: { descriptionField: 'reference' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },
    uuid: {
      label: 'UUID',
      datatype: 'uuid',
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    accountId: {
      label: 'Account',
      datatype: {
        foreignKey: {
          collection: 'fk_clients',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },

    AccountApplications: {
      label: 'Account Applications',
      datatype: {
        json: {
          type: 'collection',
          path(record: LdbDbObject) {
            //@ts-ignore
            return `/account/${record.accountId}/application/${record.id}/application_account`;
          },
        },
      },
      search: true,
      property: { source: 'backend', read: true, write: false, sort: 'none' },
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    CifApplications: {
      label: 'CIF Applications',
      datatype: {
        json: {
          type: 'collection',
          path(record: LdbDbObject) {
            //@ts-ignore
            return `/account/${record.accountId}/application/${record.id}/application_cif`;
          },
        },
      },
      search: true,
      property: { source: 'backend', read: true, write: false, sort: 'none' },
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },

    intermediaryId: {
      label: 'Intermediary',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediaries',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: intermediaryFieldViewGuard,
        list: intermediaryFieldViewGuard,
        delete: false,
        csv: false,
      },
      group: 'system',
    },
    branchId: {
      label: 'Branch',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediary_branches',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: notIntermediaryFieldViewGuard,
        list: notIntermediaryFieldViewGuard,
        delete: false,
        csv: false,
      },
      group: 'system',
    },
    reference: {
      label: 'Reference',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    applicationType: {
      label: 'Application Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'newClient', name: 'New Client', tagType: 'info' },
            { id: 'newAccount', name: 'New Account', tagType: 'info' },
            { id: 'amendClient', name: 'Amend Client', tagType: 'info' },
            { id: 'amendAccount', name: 'Amend Account', tagType: 'info' },
          ],
        },
      },
      default: 'newClient',
      mandatory: true,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },
    signatoryOrder: {
      label: 'Signatory Order',
      datatype: {
        json: {},
      },
      default: [],
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'signatories',
    },
    documents: {
      label: 'Documents',
      datatype: {
        json: {
          structure: [
            {
              documentName: 'string',
              count: 'number',
            },
          ],
        },
      },
      default: [],
      mandatory: true,
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'documents',
    },
    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'new', name: 'New', tagType: 'info' },
            { id: 'inProgress', name: 'In Progress', tagType: 'info' },
            { id: 'partiallyCompleted', name: 'Partially Completed', tagType: 'info' },
            { id: 'error', name: 'Error', tagType: 'error' },
            { id: 'completed', name: 'Completed', tagType: 'success' },
            { id: 'cancelled', name: 'Cancelled', tagType: undefined },
            { id: 'rejected', name: 'Rejected', tagType: 'error' },
          ],
        },
      },
      default: 'new',
      mandatory: true,
      views: { create: true, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    craStatus:{
      label: 'CRA Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'notStarted', name: 'Not Started', tagType: 'info' },
            { id: 'inProgress', name: 'In Progress', tagType: 'info' },
            { id: 'approved', name: 'Approved', tagType: 'success' },
            { id: 'declined', name: 'Declined', tagType: 'error' },
            { id: 'error', name: 'Error', tagType: 'error' },
            { id: 'reOpened', name: 'Re-Opened', tagType: 'info' },
            { id: 'eddRequested', name: 'EDD Requested', tagType: 'warning' },
            { id: 'eddSubmitted', name: 'EDD Submitted', tagType: 'info' },
          ],
        },
      },
      default: 'new',
      mandatory: true,
      views: { create: true, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },
    integrationStatus: {
      label: 'Integration Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'none', name: 'None', tagType: undefined },
            { id: 'sent', name: 'Sent', tagType: 'info' },
            { id: 'active', name: 'Active', tagType: 'success' },
            { id: 'inactive', name: 'Inactive', tagType: undefined },
            { id: 'pendingReview', name: 'Pending Review', tagType: 'warning' },
            { id: 'error', name: 'Error', tagType: 'error' },
            { id: 'rejected', name: 'Rejected', tagType: 'error' },
            { id: 'cifExists', name: 'CIF Exists', tagType: 'warning'},
          ],
        },
      },
      default: 'none',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },

    applicationPortfolioStatus: {
      label: 'Application Portfolio Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'new', name: 'New', tagType: 'info' },
            { id: 'readyForSubmission', name: 'Ready For Submission', tagType: 'info' },
            { id: 'submitted', name: 'Submitted', tagType: 'warning' },
            { id: 'readyForSigning', name: 'Ready For Signing', tagType: 'info' },
            { id: 'signed', name: 'Signed and Meta Data Received', tagType: 'success' },
            { id: 'recallRequested', name: 'Recall Requested', tagType: 'warning' },
            { id: 'cancelled', name: 'Cancelled', tagType: undefined },
            { id: 'deleted', name: 'Deleted', tagType: undefined },
            { id: 'notRequired', name: 'Portfolio not Required' },
            { id: 'signedNoMetaData', name: 'Signed - No Meta Data', tagType: 'info' },
            { id: 'metaDataNoSignature', name: 'Meta Data Received - No Signature', tagType: 'info' },
          ],
        },
      },
      default: 'new',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'portfolio',
    },
    consolidatedStatus: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'new', name: 'Details Incomplete', tagType: 'info' },
            { id: 'readyForSubmission', name: 'Ready For Submission', tagType: 'info' },
            { id: 'portfolioSubmitted', name: 'Portfolio Submitted', tagType: 'warning' },
            { id: 'readyForSigning', name: 'Ready For Signing', tagType: 'info' },
            { id: 'signed', name: 'Signed', tagType: 'success' },
            { id: 'recallRequested', name: 'Recall Requested', tagType: 'warning' },
            { id: 'applicationSubmitted', name: 'Application Submitted', tagType: 'warning' },
            { id: 'partiallyCompleted', name: 'Partially Completed', tagType: 'info' },
            { id: 'pendingReview', name: 'Pending Review', tagType: 'warning' },
            { id: 'error', name: 'Error', tagType: 'error' },
            { id: 'completed', name: 'Completed', tagType: 'success' },
            { id: 'cancelled', name: 'Cancelled', tagType: undefined },
            { id: 'rejected', name: 'Rejected', tagType: 'error' },
            { id: 'retrievedFromBank', name: 'Retrieved From Bank', tagType: 'success' },
            { id: 'signedNoMetaData', name: 'Signed - No Meta Data', tagType: 'info' },
            { id: 'metaDataNoSignature', name: 'Meta Data Received - No Signature', tagType: 'info' },
            { id: 'cifExists', name: 'CIF Exists', tagType: 'warning'},

            { id: 'craInProgress', name: 'CRA in Progress', tagType: 'info'},
            { id: 'craDeclined', name: 'CRA Declined', tagType: 'error'},
            { id: 'craError', name: 'CRA Error', tagType: 'error'},
            { id: 'craExpired', name: 'CRA Expired', tagType: 'warning'},
            { id: 'craReOpened', name: 'CRA Re-Opened', tagType: 'info'},
            { id: 'craEddRequested', name: 'EDD Requested', tagType: 'warning' },
            { id: 'craEddSubmitted', name: 'EDD Submitted', tagType: 'info' },
          ],
        },
      },
      property: { read: true, write: false, source: 'frontend', sort: 'status' },
      default: 'new',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },

    requiredFieldSet: {
      label: 'Required Field Set',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'full', name: 'Full', tagType: 'info' },
            { id: 'minimum', name: 'Minimum', tagType: 'info' },
          ],
        },
      },
      default: 'full',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },

    portfolioIntegrationId: {
      label: 'Portfolio Integration ID',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'portfolio',
    },
    currentPortfolioId: {
      label: 'Current Portfolio',
      datatype: {
        foreignKey: {
          linkTable: 'LfxClientPortfolioId',
          linkField: 'id',
          displayField: 'reference',
          linkInSql: false,
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'portfolio',
    },
    portfolioLink: {
      label: 'Portfolio Link',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: isBankUser, list: false, delete: false, csv: false },
      group: 'portfolio',
    },
    visibility: {
      label: 'Visibility',
      search: false,
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'visible', name: 'Visible' },
            { id: 'hidden', name: 'Hidden' },
          ],
        },
      },
      default: 'visible',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },

    requestedAt: {
      label: 'Requested At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      displayType: {
        displayAsTag: 'displayRequestedAtAsTag',
        tagTypeFunctionName: 'requestedAtTagType',
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'integration',
    },
    requestedBy: {
      label: 'Requested By',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    releasedAt: {
      label: 'Released At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'integration',
    },
    releasedBy: {
      label: 'Released By',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    rejectedAt: {
      label: 'Rejected At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    rejectedBy: {
      label: 'Rejected By',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    rejectedReason: {
      label: 'Rejection Reason',
      datatype: 'memo',
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },

    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
  },
};
