<template>
  <div  class="ldb-search-bar-container">
    <!-- <div class="ldb-search-box-and-options"> -->
      <div class="ldb-search-edit-container">
      <capitec-text-field 
      type="search"
      size="small"  
      label="Search..." 
      :value="searchText"
      @keyup.enter="onEnterPress"   
      @value-change="onSearchChange" 
      @input="onInputChange" 
      :hint="searchHint" 
      :style="{'--theme-input-padding-left':'10px'}"
      />    
    </div>
    <div class="ldb-search-rest-container">
    
    <template v-if="mountComplete">
      <template v-for="option in options" :key="option.field">
        <div class="ldb-search-bar-option">
          <capitec-select
          id-field="id"
          display-field="name"
          :label="option.label"
          :value="initialOption(option)"
          :items="getOptions(option)"
          @value-change="on_option_change($event,option)"
          />
        </div>
      </template>
      </template>
      <!-- </div> -->
      <template v-if="mountComplete">
      <LDBSearchChips :selectors="chips" :choices="choices" :initialState="chipState" mode="select" @on-selector-change="onSearchChipChange" @on-search-state-changed="onSearchChipsStateChanged" @on-chips-loaded="onChipsLoaded" />
      </template>
      <template v-if="hasRanges">
      <capitec-spacer />

      <template v-if="buttonMode"> 
      <div class="ldb-search-bar-search-button">
      <capitec-button label="Search" type="primary" @click="onSearchButtonClick"></capitec-button> 
      </div>
      </template>
      

      <div class="ldb-range-filter-button-bar">
        <capitec-button  v-if="showFilters" icon="system/filter-action" iconSize="small" :style="{'max-height':'40px','max-width':'48px','margin-right':'0px'}"  @click="doShowFilters(!showFilters)" />
        <capitec-button  v-else icon="system/filter" iconSize="small" :style="{'max-height':'40px','max-width':'48px','--theme-button-default-border-color':'grey','margin-right':'0px'}" @click="doShowFilters(!showFilters)" />
        </div>
      </template> 
      
  </div>

 
  </div>
  <template v-if="mountComplete && hasRanges">
  <div class="ldb-search-bar-ranges-container" :class="showFiltersClass" :style="minHeightFilterStyle">   
    <div class="ldb-search-bar-range" v-for="range in ranges" :key="range.id">
      <LDBSearchRange  :field="range" :initialState="initialRangeSelected(range)" @on-range-change="onRangeChange($event,range)"  />
    </div>
  </div>
   </template> 

  

</template>

<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";
import {encryptJSONToBase64,decryptBase64ToJSON} from '@/utils/ldb_url_encrypt.ts';


export default {
  emits:[
    'on-search',
    'on-selector-change',
    'on-search-bar-loaded'
  ],
  components: {},
  props: {
    searchFields:{type:Array,default() { return [ ] } },
    options:{type:Array,default() { return [ ] } },
    chips:  {type:Array,default() { return [ ] } },
    choices:{type:Array,default() { return [ ] } },
    ranges: {type:Array,default() { return [ ] } },
    searchHint:{type:String,default:''},
    saveSearchStateToUrl:{type:Boolean,default:true},
    eventTimeout:{type:Number,default:50},
    buttonMode:{type:Boolean,default:false}
    // initialState:{type:Object},
  },
  data() {
    return {
      loadingEventSent:false,
      lastLoadInitiator:"NONE",
      loadedWaitTime:50,
      searchBarLoadedTimer:undefined,
      searchSelector:undefined,
      searchState:undefined,
      mountComplete:false,
      chipState:{},
      optionsResult:{},
      showingFilters:false,
      showingTimer:undefined,
      searchText:'',
      showFilters:false,
      rangeResult:{},
      searchChipSelector:{

      }
    }
  },
  created() {
    
  },
  mounted() {
    if (this.saveSearchStateToUrl && this.$route?.query?.s) {
      try {
        let searchStateAndSelector = decryptBase64ToJSON(this.$route.query.s);
        this.searchSelector = searchStateAndSelector.s;
        this.searchState = searchStateAndSelector.st;
      }
      catch(err) {
        this.searchSelector = undefined;
        this.searchState = undefined;
      }
      if (this.searchSelector && this.searchState) {
        this.searchText  = this.searchState?.searchText || '';
        this.chipState   = {...this.searchState?.chipState};
        this.rangeResult  = {...this.searchState?.rangeState};
        this.optionsResult = {...this.searchState?.optionState};
      }
    }
    this.mountComplete = true;
    this.doSearchBarLoaded('mounted');
  },
  computed:{
    ...mapGetters([]),
  	...mapState([]),
    currentState() {
      return {
        searchText:this.searchText,
        chipState:this.chipState,
        rangeState:this.rangeResult,
        optionState:this.optionsResult
      };
    },
    urlContainsSearchState() { 
      // console.log('this.$route.query.s:',this.$route.query.s);
      return (this.$route.query.s !== null && this.$route.query.s !== undefined)
    },
    searchLabel() {
      return 'Search: ' + this.searchFields.join(', ');
    },
    minHeightFilterStyle() {
      let filters = this.ranges.length;
      return {'--min-filter-height':`${filters*60}px`};
    },
    showFiltersClass() {
      let showFiltersClass    =  this.showFilters   ? 'show-filters'    : 'hide-filters';
      let showingFiltersClass = this.showingFilters ? 'showing-filters' : 'hiding-filters';
      return `${showFiltersClass} ${showingFiltersClass}`;
    },
    hasRanges() {
      return (this.ranges.length > 0);
    },
    selector() {
      let combinedSelector = {
        ...this.searchChipSelector,
        ...this.searchTextSelector,
        ...this.optionSelector,
        ...this.rangeSelector,
      };
      return combinedSelector;
    },
    rangeSelector() {
      let res = {};
      for (let r in this.rangeResult ) {
        let range = this.rangeResult[r];
        if (range.include === true)  {
          res[range.id] = {between:[range.from,range.to]};
        }
      }      
      return res;
    },
    optionSelector() {
      let res = {};
      for (let f = 0 ; f < this.options.length ; f ++  ) {        
        let option = this.options[f];
        let optionName = option.id || option.name;
        if (this.optionsResult[optionName] !== undefined)  {
          res[optionName] = {eq:this.optionsResult[optionName]};
        }
      }
      return res;
    },
    searchTextSelector() {
      let res = [];
      let lowerText = this.searchText;
      if (lowerText !== '') {
        for (let f = 0 ; f < this.searchFields.length ; f ++ ) {     
          let field = this.searchFields[f];
          let q = {};
          q[field] = {like:`${lowerText.trim()}`};
          res.push(q);
        }
      }
      if (res.length === 0) {
        return {};
      } else 
      if (res.length === 1) {
        return res[0];
      } else 
      {
        return {or:res};
      }
    },
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([]),
    onSearchButtonClick(evt) {
      this.sendEvent('onSearchButtonClicked');
    },
    initialOption(option) {
      let fieldName = option.id || option.name;
      return this.optionsResult[fieldName];
    },
    getOptions(option) {
      let options =  (option?.datatype?.option?.options === undefined) ? option.options : option?.datatype?.option?.options;
      return options;
    },
    onChipsLoaded(chipSelectorAndState) {
      this.chipState = chipSelectorAndState.chipState;
      this.searchChipSelector = chipSelectorAndState.selector;
      this.doSearchBarLoaded('chips');
    },
    onRangeLoaded(range) {
      this.doSearchBarLoaded('range');
    },
    doSearchBarLoaded(initiator) {     
      // console.log('doSearchBarLoaded:',initiator) ;
      if (this.loadingEventSent) {
      }
      else {
        let previousTimer = this.searchBarLoadedTimer;
        if (this.searchBarLoadedTimer !== undefined) {
          clearTimeout(this.searchBarLoadedTimer);
          this.searchBarLoadedTimer = undefined;
        }
        this.sendSearchBarLoadedEvent(initiator);
        this.lastLoadInitiator = initiator;
      }
    },
    sendSearchBarLoadedEvent(initiator) {
      let self = this;
      let timerReference = setTimeout(function() {
        self.doSendSearchBarLoadedEvent(initiator)
      },this.loadedWaitTime);
      this.searchBarLoadedTimer = timerReference;
      return timerReference;
    },
    doSendSearchBarLoadedEvent(initiator) {   
      // console.log('SENDING EVENT: ',initiator,this.urlContainsSearchState,this.buttonMode);
      let selector = undefined;
      if ((this.buttonMode  && this.urlContainsSearchState) || ( this.buttonMode === false ) ) {
        selector = this.selector;
      }
      // console.log('REALLY SENDING EVENT: ',initiator,this.urlContainsSearchState,this.buttonMode);
      this.$emit('on-search-bar-loaded',selector);
      this.loadingEventSent = true;
    },
    onSearchChipsStateChanged(chipState) { 
      this.chipState = chipState;
    },
    doUpdateShowing() {
      this.showingFilters = this.showFilters;
      this.showingTimer = undefined;
    },
    doShowFilters(show) {
      this.showFilters = show;
      if (this.showingTimer !== undefined) {
        this.clearTimeout(this.showingTimer);
      }
      else 
      {
        if (show === true) {
          this.showingTimer = setTimeout(this.doUpdateShowing,300);
        }
        else 
        {
          this.showingFilters = false;
        }
      }
    },
    initialRangeSelected(range) {
      let initialStateForRange = this.rangeResult[range.name];
      return initialStateForRange;
    },
    onRangeChange(evt,range) {
      this.rangeResult[evt.id] = evt;
      if (this.loadingEventSent === true) {
        this.sendEvent('onRangeChange');
      }
      else 
      {
        this.onRangeLoaded(range)
      }
    },
    on_option_change(evt,option) {
      let selectedOption = evt.detail.new.id;
      let fieldName = option.id || option.name;
      this.optionsResult[fieldName] = selectedOption;
      this.sendEvent('onOptionChange');
    },
    
    onInputChange(evt) {
      if (evt.detail.action === 'clear') {
        this.searchText = '';
        this.sendEvent('onInputChange');
      }
    },
    onSearchChipChange(searchChipChange) {
      this.searchChipSelector = searchChipChange;
      this.sendEvent('onSearchChipChange');
    },
  	onEnterPress(evt) {
      this.$emit('on-search',this.searchText);
      this.sendEvent('onEnterPress');
    },
    onSearchChange(evt) {
      this.searchText = evt.detail.new;      
      this.sendEvent('onSearchChange');
    },
    doSendEvent(source) {
      // console.log('doSendingEvent EMIT:',source,this.selector);
      this.$emit('on-selector-change',this.selector);
      const s = this.selector;
      const st = this.currentState;
      if (this.selector === undefined) {
        this.$router.replace({query:{s:null}});
      }
      else {
        // console.log('searchState:',st,'search:',s);
        this.$router.replace({query:{s:encryptJSONToBase64({s,st})}});
      }
      this.sendEventTimer = undefined;
    },
    isSearchButtonEvent(event) {
      return (event === 'onSearchButtonClicked') || (event === 'onEnterPress');
    },
    sendEvent(source) {
      // console.log('buttonMode:',this.buttonMode,'isSearchButtonEvent:',this.isSearchButtonEvent(source),' event:',source);
      if ( (this.buttonMode && this.isSearchButtonEvent(source) ) || ( !this.buttonMode ) ) {
        if (this.sendEventTimer !== undefined) {
          clearTimeout(this.sendEventTimer);
        }
        let self = this;
        this.sendEventTimer = setTimeout(() => {self.doSendEvent(source)},this.eventTimeout);
       }
    }
  }
 }
</script>

<style>

.ldb-search-bar-search-button{
  flex:1;
  max-width: 80px;
  max-height: 40px;
  display: flex;
}

label.search.label.idle{
  white-space: nowrap !important;
}

.ldb-range-filter-button-bar{
  display:flex;
}

div.ldb-search-bar-container{
  min-height: 5px;
  display:flex;
  padding:3px;
  padding-top:0px;
  padding-left:5px;
  border-radius: 3px;
  z-index: 1000;
  /* border: 3px solid green; */
  position: relative;
  min-height: 56px;
  max-height: 56px;
  background-color: white;
  align-items: flex-start;
  padding-bottom:10px;

}

.ldb-search-rest-container{
  flex:auto;
  /* border: 2px dotted green; */
  min-height: 45px;
  align-items:center;
  justify-content: flex-start;
  display: flex;
}

.ldb-search-bar-option{
  margin-left:5px;
  margin-right:5px;
  flex:1;
}

div.ldb-search-bar-option:first-child{
  margin-left:10px;
}

div.ldb-search-bar-ranges-container{
  position:relative;
  overflow:hidden;
  transition:all 300ms ease-in-out;
  z-index: 1000;
  background-color:white;
  /* border: 1px solid red; */
}

div.showing-filters{
  overflow: visible;
}

div.ldb-search-bar-ranges-container.show-filters{
  max-height: var(--min-filter-height);
  min-height: var(--min-filter-height);
  height: var(--min-filter-height);
  box-shadow: var(--theme-product-tile-hover-shadow);

}

div.ldb-search-bar-ranges-container.hide-filters{
  max-height: 0px;
  min-height: 0px;
  height:0px;
}


</style>


