//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2023 LandoByte (Pty) Ltd.
//  File        : LfbFrontEndSpreadSheets.ts
//  Author      : Bevan Timm
//  Description : This module processes .xlsx files for the front end in LandoByte TS systems
//  Created     : 07 July 2023 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

// https://www.npmjs.com/package/xlsx
import {utils,writeFile} from 'xlsx';
import type {WorkSheet,WorkBook} from 'xlsx';

//----------------------------------------------------------------------------------------------------------------------

export const createWorkSheet = (initialData:Object[]) => {
  const workSheet = utils.json_to_sheet(initialData,{skipHeader:false});
  return workSheet
}

export const appendToWorksheet = (workSheet:WorkSheet,data:Object[],origin?:number) => {
  utils.sheet_add_json(workSheet,data,{skipHeader:true,origin:'A'})
}

export const createWorkbook = (workSheet:WorkSheet) => {
  const workBook = utils.book_new();
  utils.book_append_sheet(workBook,workSheet);
  return workBook
}

export const writeXlsFile = async(fileName:string,workBook:WorkBook) => {
  return await writeFile(workBook,fileName,{});
}

export const createWorkbookAndWriteToFile = async(fileName:string,workSheet:WorkSheet) => {
  const workBook = createWorkbook(workSheet);
  return await writeXlsFile(fileName,workBook)
}

//----------------------------------------------------------------------------------------------------------------------
