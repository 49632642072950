import VueStore from 'vue-class-store';

import { LfxPublicHolidayInt, LfxPublicHolidayDef } from '@/definitions/LfxPublicHolidayDef';

import { LfxModel, LfxDefinition } from './LfxModel';

@VueStore
//@ts-expect-error
class LfxPublicHolidayMdl extends LfxDefinition {
  //@ts-expect-error
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
//@ts-expect-error
export class LfxPublicHoliday extends LfxModel {
  //@ts-expect-error
  constructor(data, state) {
    super({ definition_name: 'publicHoliday', ...data }, state);
  }
  get link() {
    // @ts-expect-error
    return `/config/public_holiday/${this.id}`;
  }
  // get displayFKLink() {
  //   return false;
  // }
}

//@ts-expect-error
export function Definition(context, name: string) {
  return new LfxPublicHolidayMdl(LfxPublicHolidayDef, LfxPublicHoliday, context, name, {});
}
