//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2022 LandoByte (Pty) Ltd.
//  File        : LfxClientMessageLogDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Client Portfolio and Application Message Log record for LandoByte TS systems
//  Created     : 20 May 2022 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import { intermediaryFieldViewGuard } from '../sharedUtils/LdbDefinitionUtils';
import type { LdbDefinition } from '../definitions/LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------

export type LfxClientMessageLogStatus = 'active' | 'disabled';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxClientMessageLogInt {
  id?: string;

  accountId?: number;
  applicationId?: number;
  intermediaryId?: number;
  branchId?: number;

  integrationId?: string;
  direction?: 'inward' | 'outward';
  category?: string;
  type?: string;
  status?: string;
  message?: string;
  landobyteAction?: string;

  actingUserEmail?: string;
  actingUserId?: number;
  timeStamp?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxClientMessageLogDef: LdbDefinition = {
  title: 'Client Portfolio Message',
  table: 'LfxClientMessageLog',
  collectionPath: '/account/{{accountId}}/application/{{applicationId}}/messageLog',
  database: 'dynamo',
  pagingType: 'backEnd',
  view: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
  indexes: ['accountId', 'applicationId'],
  fields: {
    id: {
      label: 'ID',
      datatype: { id: { descriptionField: 'landobyteAction', type: 'text' } },
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    accountId: {
      label: 'Account',
      datatype: {
        foreignKey: {
          collection: 'fk_accounts',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    applicationId: {
      label: 'Application',
      datatype: {
        foreignKey: {
          collection: 'fk_applications',
          linkTable: 'LfxApplication',
          linkField: 'id',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    intermediaryId: {
      label: 'Intermediary',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediaries',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: intermediaryFieldViewGuard, list: false, delete: false, csv: false },
    },
    branchId: {
      label: 'Branch',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediary_branches',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    integrationId: {
      label: 'Integration ID',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    direction: {
      label: 'Direction',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'inward', name: 'Inward' },
            { id: 'outward', name: 'Outward' },
          ],
        },
      },
      default: 'outward',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    category: {
      label: 'Category',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    type: {
      label: 'Type',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    status: {
      label: 'status',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    message: {
      label: 'Message',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },

    landobyteAction: {
      label: 'Local Action',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    actingUserEmail: {
      label: 'Acting User Email',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },

    actingUserId: {
      label: 'Acting User',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },

    timeStamp: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
  },
};
