<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <p v-if="iAmtryingToCheckMyOwnChange">
        You have requested a change to this markup at {{this.makingTimestamp?.toLocaleString('en-ZA',{ timeZone: 'Africa/Johannesburg' }).replaceAll(',', '')}}. It will need to be released by another user. You can also cancel the change if required.
      </p>
      <p v-else-if="iAmtryingToCheckAnotherUsersChange">
        A change to this markup has been requested by {{this.user?.[this.makingUserId]?.displayName || this.makingUserId}} at {{this.makingTimestamp?.toLocaleString('en-ZA',{ timeZone: 'Africa/Johannesburg' }).replaceAll(',', '')}}.
      </p>
      <LDBField
      v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          :view="markupEditMode === 'checker' ? 'item' : field.view"
          :fieldname="field.name"
          :definition="undefined"
          :record="fecMarkupsRecord"
          @change="on_change"
      />
    </template>

    <template #footer>
      <LDBButton
        v-for="button in buttons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        :action="button.action"
        :params="button.params"
        :actionParams="button.actionParams"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
        @on-api-success="button.onApiSuccess"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getUpdateAccountFecMarkupMakerCheckerKey, isMakerCheckerRequiredForFecMarkupEdit } from '@/sharedUtils/LfxMakerCheckerUtils';
import { doPermissionAction } from '@/lfx_rest/lfx_action';
export default {
  name: 'LfxBulkAccountMarkupEdit',
  components: {},
  props: {},
  emits: {},
  created() {},
  mounted() {
    this.fecMarkupsRecord  = {...this.accountRecord?.fecMarkups}
      if (isMakerCheckerRequiredForFecMarkupEdit(this.account?.[this.authUser.accountId],this.accountRecord)) {
        this.getPreviouslyCachedObject()
      }
  },
  data() {
    return {
      fecMarkupsRecord:{},
      markupEditMode: 'normal', // 'normal' | 'maker' | 'checker'
      makerCheckerSecurityToken: undefined,
      makingUserId: undefined,
      makingTimestamp: undefined,
    };
  },
  computed: {
    ...mapState(['account','authUser','user']),

    // ----- Account record -----

    accountId() {
      return +this.$route.params.accountId;
    },
    accountRecord() {
      return this.account[this.accountId];
    },
    viewFields() {
      const res = [
        {
          fieldname:'1month',
          name:'1month',
          label:'1 Month',
          view:'edit',
          datatype:'integer',
        },
        {
          fieldname:'2month',
          name:'2month',
          label:'2 Month',
          view:'edit',
          datatype:'integer',
        },
        {
          fieldname:'3month',
          name:'3month',
          label:'3 Month',
          view:'edit',
          datatype:'integer',
        },
        {
          fieldname:'4month',
          name:'4month',
          label:'4 Month',
          view:'edit',
          datatype:'integer',
        },
        {
          fieldname:'5month',
          name:'5month',
          label:'5 Month',
          view:'edit',
          datatype:'integer',
        },
        {
          fieldname:'6month',
          name:'6month',
          label:'6 Month',
          view:'edit',
          datatype:'integer',
        },
        {
          fieldname:'12month',
          name:'12month',
          label:'12 Month',
          view:'edit',
          datatype:'integer',
        },
        ];
      return res;
    },

    header() {
      return `Bulk FEC Additional Markups`;
    },
    saveButtonCaption() {
      switch (this.markupEditMode) {
        case 'normal': return `Update FEC Markups`
        case 'maker': return 'Request Change'
        case 'checker': return 'Confirm Change'
        default: return `Update FEC Markups`
      }
    },
    cancelButtonCaption() {
      switch (this.markupEditMode) {
        case 'normal': return 'Cancel'
        case 'maker': return 'Cancel'
        case 'checker': return 'Back'
        default: return 'Cancel'
      }
    },
    forceSaveButtonType() {
      if (!this.iAmtryingToCheckMyOwnChange) {
        return 'primary'
      } else {
        return 'disabled'
      }
    },
    cacheKey() {
      return getUpdateAccountFecMarkupMakerCheckerKey(+this.accountId)
    },
    iAmtryingToCheckMyOwnChange() {
      return this.markupEditMode === 'checker' && this.makingUserId === this.authUser.id
    },
    iAmtryingToCheckAnotherUsersChange() {
      return this.markupEditMode === 'checker' && this.makingUserId !== this.authUser.id
    },
    buttons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancelUnhideMarkup',
          label: this.cancelButtonCaption,
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'cancelMakerCheckerEdit',
          label: 'Cancel Requested Change',
          type: '',
          action: 'clearMakerCheckerToken',
          params: {configId:1},
          actionParams: {cacheKey:this.cacheKey},
          guards: this.markupEditMode === 'checker',
          onApiSuccess: () => {
            void self.getPreviouslyCachedObject()
            self.accountRecord?.reloadAccountSettings()
            console.log("self.accountRecord",self.accountRecord)
            self.fecMarkupsRecord  = {...self.accountRecord?.fecMarkups}
          }
        },
        {
          name: 'editAccountFecMarkups',
          label: this.saveButtonCaption,
          type: this.forceSaveButtonType,
          guards: true,
          enabledGuards: true,
          action: 'editAccountFecMarkups',
          params: {accountId:this.accountId},
          actionParams: {fecMarkupsRecord:this.fecMarkupsRecord,securityToken:this.makerCheckerSecurityToken},
          clickEvent() {
            self.onSave()
          },
        },
      ];
      return allButtons.filter((button) => button.guards);
    },
    
  },
  methods: {
    on_change({ field, value }) {
      this.fecMarkupsRecord[field] = value;
    },    
    onSave() {
      if (isMakerCheckerRequiredForFecMarkupEdit(this.account?.[this.authUser.accountId],this.accountRecord)) {
          this.getPreviouslyCachedObject()
        }
        this.accountRecord?.reloadAccountSettings()
      this.$router.go(-1);
    },

    async getPreviouslyCachedObject() {
      const response = await doPermissionAction(this, 'getMakerCheckerToken', { configId: 1 }, {cacheKey:this.cacheKey});
      console.log("response",response)
      if (+response.code === 204) {
        this.markupEditMode = 'maker'
      } else if (+response.code === 200) {
        this.markupEditMode = 'checker'
        this.makerCheckerSecurityToken = response.response.securityToken
        this.makingUserId = response.response.makingUserId
        this.makingTimestamp = new Date(response.response.makingTimestamp)
        if (this.fecMarkupsRecord) {
          for (const field in response.response.cachedObject) {
            this.fecMarkupsRecord[field] = response.response.cachedObject[field];
          }
        }
      }
      console.log("this.markupEditMode ",this.markupEditMode )
    },
  },
};
</script>
