import { Component, css, html } from '../../elements';
import '../buttons/KeyboardButton';

/**
 * An on-screen keyboard control component that is only available in Kiosk projects.
 *
 * ```js 
 * import 'platform/components/inputs/Keyboard'; 
 * ```
 * 
 * ```html
 * <capitec-keyboard
 *  mode="alpha"
 *  displayValue="A">
 *  </capitec-keyboard>
 * ```
 * @prop {"alpha"|"numeric"|"alpha-numeric"|"none"|String} mode - The mode to display.
 * 
 * @ignore
 */

export class Keyboard extends Component {

	// --------------
	// INITIALISATION
	// --------------

	/**
	 * Initialises the component.
	 *
	 * @hideconstructor
	 */
	constructor() {

		super();

		// Set the default property values.
		this.mode = `alpha`;
		this.currentCase = `lower`;
		this.state = `alpha`;
		this.displayValue = ``;
		this.addEventListener(`focus`, this._focusGained.bind(this));
	}

	// ----------
	// PROPERTIES
	// ----------

	/**
	 * Registry of all properties defined by the component.
	 * 
	 * @property {String} [mode="alpha"] - The mode to display:
	 * - `alpha` Alphabetical mode.
	 * - `numeric` Numerical mode.
	 * - `alpha-numeric` Alphanumerical mode.
	 * - `none` No keyboard visible.
	 * @property {String} [displayValue] - The value for the Keyboard to display.
	 */
	static get properties() {

		return {
			mode: { type: String },
			displayValue: { type: String }
		};
	}

	// -------------------
	// LIFECYCLE OVERRIDES
	// -------------------

	// n/a

	// --------------
	// EVENT HANDLERS
	// --------------

	/**
	 * Handles closing of the Keyboard component
	 * 
	 * @ignore
	 * @returns {void}
	 */
	_close() {

		this.dispatchEvent(new CustomEvent(`keyboard-close`, {
			detail: {}
		}));

	}

	/**
	 * Handles component key down events.
	 * 
	 * @param {KeyboardEvent} event - The event details.
	 * 
	 * @ignore
	 * @returns {void}
	 */
	_keypress(event) {

		const data = {
			value: event.detail.value
		};
		this._notifyKeyboardFocus();
		this.dispatchEvent(new CustomEvent(`keyboard-key-press`, {
			detail: data
		}));

		event.stopPropagation();
		if (this.currentCase === `upper-single`) {
			this.currentCase = `lower`;
			this.requestUpdate();
		}

	}

	_focusGained(event) {
		this._notifyKeyboardFocus();
	}

	_notifyKeyboardFocus() {
		this.dispatchEvent(new CustomEvent(`keyboard-focused`, {
			detail: {}
		}));

		this.dispatchEvent(new CustomEvent(`keyboard-focus`, {
			detail: {}
		}));
	}

	_toggleCase() {
		if (this.currentCase === `lower`) {
			this.currentCase = `upper-single`;
		} else if (this.currentCase === `upper-single`) {
			this.currentCase = `upper`;
		} else {
			this.currentCase = `lower`;
		}
		this._notifyKeyboardFocus();
		this.requestUpdate();
	}

	_toggleState() {
		if (this.state === `numeric`) {
			this.state = `alpha`;
		} else {
			this.state = `numeric`;
		}
		this._notifyKeyboardFocus();
		this.requestUpdate();
	}

	// --------------
	// PUBLIC METHODS
	// --------------

	// n/a

	// ---------------
	// PRIVATE METHODS
	// ---------------

	// n/a

	// ---------
	// RENDERING
	// ---------

	static get styles() {

		return [
			super.styles,
			css`
				:host {
					/* Keyboard needs to have a very high z index in order to be always on top */
					z-index: var(--theme-keyboard-z-index, 9999);
				}

				.footer{
					position: fixed;
					left: 0;
					bottom: 0;
					width: 100%;
					background-color: transparent;
					color: white;
					text-align: center;
				}

				.topbar{
					height: var(--theme-keyboard-top-bar-height,48px);
					width: var(--theme-keyboard-width,1024px);
					padding-left: var(--theme-keyboard-top-bar-padding-left,24px); 
					padding-right: var(--theme-keyboard-top-bar-padding-right,24px); 
					background-color: var(--theme-keyboard-top-bar-color,#F7F7F7);
					list-style: none;
					display: var(--theme-keyboard-flex-display, flex);
					align-items: center;
					justify-content: space-between;
				}

				.closeButton{
					cursor: pointer; 
					font-weight: 500; 
					color: var(--theme-keyboard-close-button-color,#009DE0); 
					font-size: var(--theme-keyboard-close-button-font-size,16px);
				}

				.closer{
					display: var(--theme-keyboard-flex-display, flex);
					flex-direction: row; 
					align-items: center;
					justify-content: space-between;
					width:var(--theme-keyboard-closer-width,68px);				
				}

				.items{
					padding: 0;
					margin: 0;
					list-style: none;
					display: var(--theme-keyboard-flex-display, flex);
				}

				.firstItem{
					justify-content: flex-start; 
				}

				.secondItem{
					justify-content: flex-end; 
				}

				.wrapperContainer{
					display: flex;
					flex-direction: column; 
					align-items: center; 
					background-color: transparent;
				}

				.subWrapper{
					display: flex;
					flex-direction: row;
					justify-content: space-between;
				}

				.wrapper {
					width:var(--theme-keyboard-width,1024px);
					padding: var(--theme-keyboard-wrapper-padding,10px 14px);
					background: var(--theme-keyboard-input-border-color,#E1E1E1);
				}
				
				.wrapper:first-child {
					margin-top: 0;
				}
				
				.keyrow {
					display: flex; 
					justify-content: center;
				}
				.keyrow > {
					margin: var(--theme-keyboard-key-row-margin,6px 7px);
				}
			`
		];
	}

	_webTemplate() {
		return html``;
	}

	_mobileTemplate() {
		return html``;
	}

	/**
	 * Generates the component template for kiosk mode.
	 * 
	 * @returns {html} The html content of the component.
	 */
	_kioskTemplate() {

		if (this.mode === `alpha` || (this.mode === `alpha-numeric` && this.state === `alpha`)) {
			this.state = `alpha`;
			return html`
			<div class="footer">
				<div class="wrapperContainer">
					<div class="topbar">
						<capitec-label label="${this.displayValue}"></capitec-label>
						<div class="closer" @click="${this._close}" style="cursor: pointer;">
							<capitec-label class="closeButton" label="Close"></capitec-label>
							<capitec-icon size="extra-small" icon="system/chevron-down-action"></capitec-icon>
						</div>
					</div>
					<div class="wrapper">
						<div class="keyrow">
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="q"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="w"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="e"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="r"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="t"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="y"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="u"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="i"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="o"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="p"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
						</div>
						<div class="keyrow">
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="a"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="s"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="d"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="f"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="g"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="h"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="j"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="k"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="l"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
						</div>
						<div class="keyrow">
							<capitec-keyboard-button @keyboard-click="${this._toggleCase}" mode="action"
								icon="${this.currentCase === `lower` ? `system/caps-off-action` : ``}${this.currentCase === `upper-single` ? `system/caps-on` : ``}${this.currentCase === `upper` ? `system/caps-on-permanent` : ``}"
								character="caps" case="custom"></capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="z"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="x"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="c"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="v"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="b"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="n"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="alpha" label="m"
								case="${this.currentCase === `upper` || this.currentCase === `upper-single` ? `upper` : `lower`}">
							</capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="action"
								icon="system/backspace-action" character="backspace" case="custom"></capitec-keyboard-button>
						</div>
						<div class="keyrow">
							${this.mode === `alpha-numeric` ? html`<capitec-keyboard-button @keyboard-click="${this._toggleState}"
								mode="action" label="123" case="custom"></capitec-keyboard-button>` : html`<div
								style="background-color: transparent; height: 56px; width: 135px; margin: 6px 7px; border: 1.5px; ">
							</div>`}
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="space" label="Space" character=" "
								case="custom"></capitec-keyboard-button>
							<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="return" label="Enter"
								character="return" case="custom"></capitec-keyboard-button>
						</div>
					</div>
				</div>
			</div>
			`;
		} else if (this.mode === `numeric` || (this.mode === `alpha-numeric` && this.state === `numeric`)) {
			this.state = `numeric`;
			return html`
			<div class="footer">
				<div class="wrapperContainer">
					<div class="topbar">
						<capitec-label label="${this.displayValue}"></capitec-label>
						<div class="closer" @click="${this._close}" style="cursor: pointer;">
							<capitec-label class="closeButton" label="Close"></capitec-label>
							<capitec-icon size="extra-small" icon="system/chevron-down-action"></capitec-icon>
						</div>
					</div>
					<div class="wrapper">
						<div class="subWrapper">
							<div style="margin-top: auto; ">
								${this.mode === `alpha-numeric` ? html`<capitec-keyboard-button
									@keyboard-click="${this._toggleState}" mode="action" label="ABC" case="custom">
								</capitec-keyboard-button>` : html`<div
									style="background-color: transparent; height: 56px; width: 135px; margin: 6px 7px; border: 1.5px; ">
								</div>`}
							</div>
							<div>
								<div class="keyrow">
									<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="numeric" label="1">
									</capitec-keyboard-button>
									<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="numeric" label="2">
									</capitec-keyboard-button>
									<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="numeric" label="3">
									</capitec-keyboard-button>
								</div>
								<div class="keyrow">
									<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="numeric" label="4">
									</capitec-keyboard-button>
									<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="numeric" label="5">
									</capitec-keyboard-button>
									<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="numeric" label="6">
									</capitec-keyboard-button>
								</div>
								<div class="keyrow">
									<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="numeric" label="7">
									</capitec-keyboard-button>
									<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="numeric" label="8">
									</capitec-keyboard-button>
									<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="numeric" label="9">
									</capitec-keyboard-button>
								</div>
								<div class="keyrow">
									<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="numeric" label="Clear"
										character="clear" case="custom"></capitec-keyboard-button>
									<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="numeric" label="0">
									</capitec-keyboard-button>
									<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="numeric"
										icon="system/backspace-action" character="backspace" case="custom">
									</capitec-keyboard-button>
								</div>
							</div>
							<div style="margin-top: auto;">
								<capitec-keyboard-button @keyboard-click="${this._keypress}" mode="return" label="Enter"
									character="return" case="custom"></capitec-keyboard-button>
							</div>
						</div>
					</div>
				</div>
			</div>
			`;
		}

		return html``;
	}
}

window.customElements.define(`capitec-keyboard`, Keyboard);

/**
 * When a keyboard key is pressed.
 *
 * @example
 * <capitec-keyboard ... @keyboard-key-press="${this._handler}"></capitec-keyboard>
 *
 * @event Keyboard#keyboard-key-press
 * @type {Object}
 * @property {Object} detail details of the keyboard button that was pressed
 */

/**
 * When the keyboard component is closed.
 *
 * @example
 * <capitec-keyboard ... @keyboard-close="${this._handler}"></capitec-keyboard>
 *
 * @event Keyboard#keyboard-close
 * @type {Object}
 * @property {Object} detail
 */

/**
 * When the keyboard is focussed.
 *
 * @example
 * <capitec-keyboard ... @keyboard-focus="${this._handler}"></capitec-keyboard>
 *
 * @event Keyboard#keyboard-focus
 * @type {Object}
 * @property {Object} detail - The component that is focussed
 */
