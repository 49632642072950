//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2022 LandoByte (Pty) Ltd.
//  File        : LdbWebSocketsFe.ts
//  Author      : Bevan Timm
//  Description : This module handles the functioning of the Events web socket connections for a LandoByte Vue system
//  Created     : 7 March 2022 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------
import type { stateDef, } from '@/store';
import type { Store } from 'vuex';

import { db_assign_object_to_state } from '@/db-interface/db_state';
import type { LdbFeWebSocket, LdbWebSocketEvent } from './LdbWebSocketsFe';
import type { LdbEventInt, LdbEventStatus } from '@/definitions/LdbEventDef';
import { String } from 'aws-sdk/clients/batch';

//----------------------------------------------------------------------------------------------------------------------

var eventswebsocket: LdbFeWebSocket | undefined;

export const initialiseEventWebsocket = (webSocket: LdbFeWebSocket, _store: Store<stateDef>) => {
  eventswebsocket = webSocket;
};

//----------------------------------------------------------------------------------------------------------------------

function isPopupEvent(store:Store<stateDef>,eventObject:LdbEventInt) {
  return (eventObject.eventType?.showNotification && eventObject?.eventType?.prioritory || 10 < 3);
}

export const handleEventsMessage = (webSocket: LdbFeWebSocket, event: LdbWebSocketEvent, store: Store<stateDef>) => {
  if (event.data !== 'AUTH FAILED') {
    const eventObject = JSON.parse(event.data) as LdbEventInt;
    if (eventObject.eventType!.category === 'deleteEvent') {
      store.commit('deleteEvent', eventObject);
    } else if (eventObject.eventType?.showNotification) {
      store.commit('deleteOutOfDateEvents', eventObject);
      store.commit('saveEvent', eventObject);
    }
    if (eventObject.eventType?.id === 'systemSettingsUpdate' && eventObject.dbObject) {
      store.commit('setSystemSettings', eventObject.dbObject)
    }
    else if (eventObject.dbObject && eventObject.timeStamp) {
      const eventTime = new Date(eventObject.timeStamp).getTime();
      if (eventTime > webSocket.connectedTimestamp!.getTime()) {         
        if (isPopupEvent(store,eventObject)) {
          store.commit('showEventAsPopup',eventObject);
        }
        db_assign_object_to_state(store, eventObject.dbObject);
      }
    }
  }
};

//----------------------------------------------------------------------------------------------------------------------

export const updateMessageStatus = (eventId: String, newStatus: LdbEventStatus) => {
  eventswebsocket?.connection?.send(
    JSON.stringify({
      type: 'updateStatus',
      id: eventId,
      newStatus: newStatus,
    })
  );
};

//----------------------------------------------------------------------------------------------------------------------

export const deleteMessage = (eventId: String) => {
  eventswebsocket?.connection?.send(
    JSON.stringify({
      type: 'delete',
      id: eventId,
    })
  );
};

//----------------------------------------------------------------------------------------------------------------------

export const getMoreHistoricEvents = (mostRecent: Date) => {
  eventswebsocket?.connection?.send(
    JSON.stringify({
      type: 'historic',
      mostRecent: mostRecent,
    })
  );
};

//----------------------------------------------------------------------------------------------------------------------
