import VueStore from 'vue-class-store';

import { LdbDbObject, LdbDefinition } from '@/definitions/LdbInterfaces';
import { LfxUserAccessLogInt, LfxUserAccessLogDef } from '@/definitions/LfxUserAccessLogDef';
import { LfxModel, LfxDefinition } from './LfxModel';

//@ts-ignore
import { http_patch, http_get, http_put } from '@/lfx_rest';

@VueStore
//@ts-ignore
class LfxUserAccessLogMdl extends LfxDefinition {
  //@ts-ignore
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }


}

@VueStore
//@ts-ignore
export class LfxUserAccessLog extends LfxModel {
  //@ts-ignore
  constructor(data, state) {
    super({definition_name:'user_access_log',...data}, state);
    //@ts-ignore
  }

  get errorMessage () {
    //@ts-expect-error
    const actionDetails = this.actionDetails
    if (actionDetails) {
      if(actionDetails.errorMessage) {
        return actionDetails.errorMessage
      }
      return [actionDetails.code,actionDetails.codeReason,actionDetails.message].filter((v)=>{return !!v}).join(" - ")
    }
    return '';
  }
}

//@ts-ignore
export function Definition(context, name: string) {
  return new LfxUserAccessLogMdl(LfxUserAccessLogDef, LfxUserAccessLog, context, name, {});
}
